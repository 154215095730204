import React, { Component, Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import { NetworkStatus } from '../../common/constants';
import { connect } from 'react-redux';
import Tool from '../../components/ToolBar/Tool';
import Enrollment from '../../routes/enrollment.component';
import ToolsPanel from '../../components/ToolsPanel';
import { Glyphicon } from 'react-bootstrap';
import { history } from '../../utils/history';
import { enrollmentsDeletingReset } from './enrollments.actions';
import { companyIdSelector } from '../login/login.selectors';
import { enrollmentsAttributes } from './enrollments.constants';
import { deleteEnrollments, loadEnrollments } from './enrollments.thunk';
import { Button } from '../../components/button.component';
import PopupActions from '../popup/popup.actions';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';
import UserHelpers from '../../common/utilities/user';
import { CreateEnrollmentRoute } from '../createEnrollment/CreateEnrollment.constants';
import { showStatusChangePopup } from '../../common/utilities/common';
import DataTable from '../../components/DataTable/DataTable.component';
import { Pages } from '../../constants/pages';

class Enrollments extends Component {
  state = {
    selected: new Set(),
  };

  tableRef = React.createRef();

  componentDidMount() {
    const { status, companyId, offset, loadEnrollments } = this.props;
    if (status !== NetworkStatus.STARTED && status !== NetworkStatus.DONE) {
      loadEnrollments({
        companyId,
        offset,
        INFINITE_SCROLL_LIMIT,
        isInitialLoad: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.enrollmentsDeletingStatus !==
        prevProps.enrollmentsDeletingStatus &&
      (this.props.enrollmentsDeletingStatus === NetworkStatus.DONE ||
        this.props.enrollmentsDeletingStatus === NetworkStatus.ERROR)
    ) {
      showStatusChangePopup({
        status: this.props.enrollmentsDeletingStatus,
        errorText: this.props.enrollmentsDeletingError,
        title: I18n.t('enrollments.deletingEnrollments'),
        onClose: () => {
          this.props.enrollmentsDeletingReset();
          this.resetSelectedEnrollments();
        },
      });
    }
  }

  loadEnrollments = () => {
    const { companyId, offset, loadEnrollments, hasMore } = this.props;
    if (hasMore) {
      loadEnrollments({
        companyId,
        offset,
        INFINITE_SCROLL_LIMIT,
        isInitialLoad: false,
      });
    }
  };

  renderCreateEnrollmentButton = () =>
    this.props.hasAdminPrivileges ? (
      <div style={{ display: 'inline-flex' }}>
        <Button onClick={() => history.push(CreateEnrollmentRoute)}>
          <span style={{ display: 'inline-flex', marginRight: '5px' }}>
            {I18n.t('enrollments.enrollmentCreationButtonText')}
          </span>
          <Glyphicon glyph="plus" />
        </Button>
      </div>
    ) : null;

  render() {
    const { hasAdminPrivileges } = this.props;
    const contentClassName = this.state.selected.size
      ? 'app__content app__content--with-toolbar'
      : 'app__content';

    return (
      <Fragment>
        <div className={contentClassName}>
          <div className={'page-content'}>
            <div className={'card'}>
              <DataTable
                allColumns={enrollmentsAttributes}
                data={this.props.data}
                loadingStatus={this.props.status}
                selectable
                tableRef={this.tableRef}
                selected={this.state.selected}
                onSelect={this.select}
                pageId={Pages.ENROLLMENTS}
                sortable
                useFlex
                useFilter
                useCSVExport
                rightCustomHeaderAction={this.renderCreateEnrollmentButton()}
                infiniteScrollProps={{
                  loadMore: this.loadEnrollments,
                  hasMore: this.props.hasMore,
                  nextPageLoadingStatus: this.props.nextPageStatus,
                }}
              />
            </div>
          </div>
        </div>
        {this.state.selected.size && !this.props.match.params.childRoute ? (
          <ToolsPanel
            selectedItems={this.state.selected}
            title={I18n.t('enrollments.enrollmentsSelected')}
          >
            <Tool
              title={I18n.t('enrollments.removeEnrollments')}
              onClick={this.onDelete}
              hidden={!hasAdminPrivileges}
            >
              <TrashEmpty className={'icon'} />
            </Tool>
          </ToolsPanel>
        ) : null}
        {this.renderEnrollment()}
      </Fragment>
    );
  }

  renderEnrollment = () => {
    if (this.props.match.params.childRoute) {
      return (
        <Enrollment
          onClose={this.resetSelectedEnrollments}
          enrollmentId={this.props.match.params.childRoute}
        />
      );
    }
  };

  resetSelectedEnrollments = () =>
    this.tableRef.current?.toggleAllRowsSelected?.(false);

  select = (selectedIds) =>
    this.setState(() => ({ selected: new Set(selectedIds) }));

  onDelete = () =>
    PopupActions.showConfirm({
      onConfirm: () =>
        this.props.deleteEnrollments(
          [...this.state.selected.values()],
          this.props.companyId
        ),
      text: I18n.t('enrollments.enrollmentDeletionConfirmationText'),
    });
}

const mapStateToProps = (state) => {
  const {
    enrollments: {
      nextUrl,
      data,
      status,
      nextPageStatus,
      error,
      deletingStatus,
      deletingError,
    } = {},
  } = state;
  const { offset } = RouteHelpers.getURLQueryParams(nextUrl);
  return {
    enrollmentsDeletingStatus: deletingStatus,
    enrollmentsDeletingError: deletingError,
    companyId: companyIdSelector(state),
    hasMore: Boolean(nextUrl),
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    data,
    offset,
    status,
    nextPageStatus,
    error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteEnrollments: (enrollments, companyId) =>
      dispatch(deleteEnrollments(enrollments, companyId)),
    enrollmentsDeletingReset: () => dispatch(enrollmentsDeletingReset),
    loadEnrollments: (params) => dispatch(loadEnrollments(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Enrollments);
