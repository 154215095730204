import React, { useState } from 'react';
import CheckBox from '../CheckBox';

const MultiCheckBox = ({
  allOptions = [],
  defaultSelectedOptions = [],
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    defaultSelectedOptions
  );

  const handleToggle = ({ event, id }) => {
    let newSelectedOptions;
    if (event.target.checked) {
      newSelectedOptions = [...selectedOptions, id];
    } else {
      newSelectedOptions = selectedOptions.filter((option) => option !== id);
    }
    setSelectedOptions(newSelectedOptions);
    onChange?.(newSelectedOptions);
  };

  return (
    <>
      {allOptions.map(({ id, label }) => (
        <CheckBox
          id={id}
          key={`checkbox-${id}`}
          label={label}
          checked={selectedOptions.includes(id)}
          onChange={(event) =>
            handleToggle({
              id,
              event,
            })
          }
        />
      ))}
    </>
  );
};

export default MultiCheckBox;
