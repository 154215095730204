import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { InputField } from '../../../components/inputField.component';
import { AttributeType, NetworkStatus } from '../../../common/constants';
import PermissionProperty from '../../users/components/permissionProperty.component';
import PermissionCheckBox from '../../users/components/permissionCheckBox.component';
import FleetsTree from '../../fleets/components/fleetsTree.component';
import { ACCESS_TYPES } from '../../../constants/accessTypes';
import DataTable from '../../../components/DataTable/DataTable.component';

class Permission extends React.Component {
  renderPermissionControl = ({ item, field }) => {
    const { groupGlobalPermissionsConfig = {} } = this.props;
    let { id: configId } = item;
    let { key: fieldKey } = field;
    let checked =
      fieldKey === ACCESS_TYPES.READ
        ? groupGlobalPermissionsConfig[configId] === ACCESS_TYPES.READ ||
          groupGlobalPermissionsConfig[configId] === ACCESS_TYPES.READ_WRITE
        : fieldKey === ACCESS_TYPES.READ_WRITE
        ? groupGlobalPermissionsConfig[configId] === ACCESS_TYPES.READ_WRITE
        : false;
    return (
      <PermissionCheckBox
        key={fieldKey + configId}
        checked={checked}
        onChange={() =>
          this.props.onGlobalPermissionsChange({ configId, value: fieldKey })
        }
      />
    );
  };

  permissionTableColumns = [
    {
      key: 'title',
      type: AttributeType.TEXT,
      titleCode: 'users.globalPermissionsTableDescriptionHeader',
      renderer: PermissionProperty,
    },
    {
      key: ACCESS_TYPES.READ,
      type: AttributeType.BOOLEAN,
      titleCode: 'users.globalPermissionsTableReadOnlyHeader',
      renderer: this.renderPermissionControl,
    },
    {
      key: ACCESS_TYPES.READ_WRITE,
      type: AttributeType.BOOLEAN,
      titleCode: 'users.globalPermissionsTableReadWriteHeader',
      renderer: this.renderPermissionControl,
    },
  ];

  onChange = ({ target: { value, name } }) => {
    this.props.onGlobalPermissionsChange({ target: { value, name } });
  };

  render() {
    const {
      globalPermissionsList = [],
      allFleets = [],
      fleetsViewSpecificList = [],
      fleetsRemoteControlEnabledList = [],
    } = this.props;
    return (
      <Fragment>
        <div className={'permission'}>
          <div className={'dialog-content-section'}>
            <SectionDescription
              title={I18n.t('users.userGroupSectionDescription')}
            />
            <InputField
              value={this.props.name}
              name={'name'}
              onChange={this.props.onChangeName}
              label={I18n.t('users.userGroupCreationGroupNameLabel')}
            />
          </div>
          <div className={'dialog-content-section'}>
            <SectionDescription
              title={I18n.t('users.globalPermissionsSectionDescription')}
            />
            <DataTable
              allColumns={this.permissionTableColumns}
              data={globalPermissionsList}
              loadingStatus={NetworkStatus.DONE}
            />
          </div>
          <div className={'dialog-content-section'}>
            <SectionDescription
              title={I18n.t('users.fleetAccessSectionDescription')}
            />
          </div>

          <SectionDescription
            title={I18n.t('users.remoteControlSectionDescription')}
            isSmall
          />
          <div className="fleet-tree-height-limiter-wrapper">
            <FleetsTree
              fleets={allFleets}
              onSelect={(fleet) =>
                this.props.onFleetPermissionsListChange(
                  fleet,
                  'fleetsRemoteControlEnabledList'
                )
              }
              defaultSelectedKeys={fleetsRemoteControlEnabledList}
              selectedKeys={fleetsRemoteControlEnabledList}
              onSelectAllClick={() =>
                this.props.onFleetsSelectAllClick(
                  'fleetsRemoteControlEnabledList'
                )
              }
              showSelectAll
              multiple
              hideHeader
            />
          </div>

          <SectionDescription
            title={I18n.t('users.viewSpecificSectionDescription')}
            isSmall
          />
          <div className="fleet-tree-height-limiter-wrapper">
            <FleetsTree
              fleets={allFleets}
              onSelect={(fleet) =>
                this.props.onFleetPermissionsListChange(
                  fleet,
                  'fleetsViewSpecificList'
                )
              }
              defaultSelectedKeys={fleetsViewSpecificList}
              selectedKeys={fleetsViewSpecificList}
              onSelectAllClick={() =>
                this.props.onFleetsSelectAllClick('fleetsViewSpecificList')
              }
              showSelectAll
              multiple
              hideHeader
            />
          </div>
        </div>
      </Fragment>
    );
  }

  onSelect = (fleet) => {
    return '';
  };
}

export default Permission;
