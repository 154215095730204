import { I18n } from 'react-redux-i18n';
import { ApplicationsRoutes, RootRoutes } from '../../utils/routes';

export const uploadTypes = {
  APK: 'apk',
  FILES: 'files',
  CERTIFICATES: 'certificates',
  HTML_KIOSK: 'html-kiosk',
};

export const saveLocations = {
  APPLICATIONS: 'applications',
  HTML: 'html',
  CERTIFICATES: 'certificates',
  FILES: 'files',
};

export const AddFileRoute = `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.UPLOAD}`;

export const successfulUploadMessageText = {
  [saveLocations.APPLICATIONS]: I18n.t(
    'fileSystem.applicationsSuccessfulUploadMessageText'
  ),
  [saveLocations.HTML]: I18n.t(
    'fileSystem.htmlFilesSuccessfulUploadMessageText'
  ),
  [saveLocations.CERTIFICATES]: I18n.t(
    'fileSystem.certificatesSuccessfulUploadMessageText'
  ),
  [saveLocations.FILES]: I18n.t('fileSystem.filesSuccessfulUploadMessageText'),
};

export const duplicateUploadFailureMessageText = {
  [saveLocations.APPLICATIONS]: I18n.t(
    'fileSystem.applicationsDuplicateUploadFailureMessageText'
  ),
  [saveLocations.HTML]: I18n.t(
    'fileSystem.htmlFilesDuplicateUploadFailureMessageText'
  ),
  [saveLocations.CERTIFICATES]: I18n.t(
    'fileSystem.certificatesDuplicateUploadFailureMessageText'
  ),
  [saveLocations.FILES]: I18n.t(
    'fileSystem.filesDuplicateUploadFailureMessageText'
  ),
};

export const uploadFailureMessageText = {
  [saveLocations.APPLICATIONS]: I18n.t(
    'fileSystem.applicationsUploadFailureMessageText'
  ),
  [saveLocations.HTML]: I18n.t('fileSystem.htmlFilesUploadFailureMessageText'),
  [saveLocations.CERTIFICATES]: I18n.t(
    'fileSystem.certificatesUploadFailureMessageText'
  ),
  [saveLocations.FILES]: I18n.t('fileSystem.filesUploadFailureMessageText'),
};

export const addFileSystemItemConfigs = {
  [uploadTypes.APK]: {
    uploadRejectionMessage: 'fileSystem.appUploadRejectionMessage',
    dropZoneTitle: 'fileSystem.apkDropZoneTitle',
    addLinkTitle: 'fileSystem.apkAddLinkTitle',
    uploadingSuccessMessage: 'fileSystem.apkUploadingSuccessMessage',
    maxSize: 262_144_000, // 250 mb
    allowMultipleFiles: true,
    allowedTypes: ['application/vnd.android.package-archive'],
  },
  [uploadTypes.CERTIFICATES]: {
    uploadRejectionMessage: 'fileSystem.certificateUploadRejectionMessage',
    dropZoneTitle: 'fileSystem.certificatesDropZoneTitle',
    addLinkTitle: 'fileSystem.certificatesAddLinkTitle',
    uploadingSuccessMessage: 'fileSystem.certificatesUploadingSuccessMessage',
    maxSize: 2_097_152, // 2 mb
    allowedTypes: [],
  },
  [uploadTypes.FILES]: {
    uploadRejectionMessage: 'fileSystem.fileUploadRejectionMessage',
    dropZoneTitle: 'fileSystem.filesDropZoneTitle',
    addLinkTitle: 'fileSystem.filesAddLinkTitle',
    uploadingSuccessMessage: 'fileSystem.filesUploadingSuccessMessage',
    maxSize: 10_485_760, // 10 mb
    allowedTypes: [],
  },
  [uploadTypes.HTML_KIOSK]: {
    uploadRejectionMessage: 'fileSystem.htmlUploadRejectionMessage',
    dropZoneTitle: 'fileSystem.htmlKioskFilesDropZoneTitle',
    addLinkTitle: 'fileSystem.htmlKioskAddLinkTitle',
    uploadingSuccessMessage: 'fileSystem.htmlKioskFilesUploadingSuccessMessage',
    maxSize: 2_097_152, // 2 mb
    allowedTypes: ['text/html'],
  },
};

export const addFilesMenuStructure = [
  {
    key: uploadTypes.APK,
    route: `${AddFileRoute}/${uploadTypes.APK}`,
    title: I18n.t('fileSystem.apkFilesUploadMenuItem'),
    tabTitle: I18n.t('fileSystem.apkFilesUploadTab'),
  },
  {
    key: uploadTypes.FILES,
    route: `${AddFileRoute}/${uploadTypes.FILES}`,
    title: I18n.t('fileSystem.filesUploadMenuItem'),
    tabTitle: I18n.t('fileSystem.filesUploadTab'),
  },
  {
    key: uploadTypes.CERTIFICATES,
    route: `${AddFileRoute}/${uploadTypes.CERTIFICATES}`,
    title: I18n.t('fileSystem.certificatesUploadMenuItem'),
    tabTitle: I18n.t('fileSystem.certificatesUploadTab'),
  },
  {
    key: uploadTypes.HTML_KIOSK,
    route: `${AddFileRoute}/${uploadTypes.HTML_KIOSK}`,
    title: I18n.t('fileSystem.HTMLKioskFilesUploadMenuItem'),
    tabTitle: I18n.t('fileSystem.HTMLKioskFilesUploadTab'),
  },
];
