export const FLEETS_LOAD_ALL_STARTED = 'FLEETS_LOAD_ALL_STARTED';
export const FLEETS_LOAD_ALL_FAILED = 'FLEETS_LOAD_ALL_FAILED';
export const FLEETS_LOAD_ALL_SUCCESS = 'FLEETS_LOAD_ALL_SUCCESS';
export const FLEET_DELETE_FAILED = 'FLEET_DELETE_FAILED';
export const FLEET_DELETE_RESET = 'FLEET_DELETE_RESET';
export const FLEET_DELETE_STARTED = 'FLEET_DELETE_STARTED';
export const FLEET_DELETE_SUCCESS = 'FLEET_DELETE_SUCCESS';
export const FLEET_LOAD_STARTED = 'FLEET_LOAD_STARTED';
export const FLEET_LOAD_FAILED = 'FLEET_LOAD_FAILED';
export const FLEET_LOAD_SUCCESS = 'FLEET_LOAD_SUCCESS';
export const FLEET_LOAD_RESET = 'FLEET_LOAD_RESET';

export const FLEET_PROFILES_V1_LOAD_STARTED = 'FLEET_PROFILES_V1_LOAD_STARTED';
export const FLEET_PROFILES_V1_LOAD_FAILED = 'FLEET_PROFILES_V1_LOAD_FAILED';
export const FLEET_PROFILES_V1_LOAD_SUCCESS = 'FLEET_PROFILES_V1_LOAD_SUCCESS';
export const FLEET_PROFILES_V1_LOAD_RESET = 'FLEET_PROFILES_V1_LOAD_RESET';

export const FLEET_PROFILES_V2_LOAD_STARTED = 'FLEET_PROFILES_V2_LOAD_STARTED';
export const FLEET_PROFILES_V2_LOAD_FAILED = 'FLEET_PROFILES_V2_LOAD_FAILED';
export const FLEET_PROFILES_V2_LOAD_SUCCESS = 'FLEET_PROFILES_V2_LOAD_SUCCESS';
export const FLEET_PROFILES_V2_RESET = 'FLEET_PROFILES_V2_RESET';

export const DEVICES_ASSIGN_FLEET_STARTED = 'DEVICES_ASSIGN_FLEET_STARTED';
export const DEVICES_ASSIGN_FLEET_FAILED = 'DEVICES_ASSIGN_FLEET_FAILED';
export const DEVICES_ASSIGN_FLEET_SUCCESS = 'DEVICES_ASSIGN_FLEET_SUCCESS';
export const DEVICES_ASSIGN_FLEET_RESET = 'DEVICES_ASSIGN_FLEET_RESET';
export const FLEET_CREATE_STARTED = 'FLEET_CREATE_STARTED';
export const FLEET_CREATE_SUCCESS = 'FLEET_CREATE_SUCCESS';
export const FLEET_CREATE_FAILED = 'FLEET_CREATE_FAILED';
export const FLEET_CREATE_RESET = 'FLEET_CREATE_RESET';

export const DEVICES_RESET = 'DEVICES_RESET';
