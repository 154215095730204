import React from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { deviceSelector } from '../device.selectors';
import DataTable from '../../../components/DataTable/DataTable.component';
import { activityLogsTableColumns } from '../device.constants';
import { Pages } from '../../../constants/pages';
import { useThunks } from '../../../hooks/useThunks';
import { SectionDescription } from '../../../components/sectionDescription.component';

const ActivityLogs = ({ device }) => {
  const { loadDeviceActivityLogs } = useThunks();
  const { activityLogs, activityLogsLoadingStatus } = useSelector(
    deviceSelector
  );
  const handleDateRangeChange = (startDate, endDate) => {
    loadDeviceActivityLogs({
      deviceId: device.id,
      startDate: startDate?.format(),
      endDate: endDate?.format(),
    });
  };

  return (
    <DataTable
      headerTitle={<SectionDescription title={I18n.t('device.activityLogs')} />}
      allColumns={activityLogsTableColumns}
      data={activityLogs}
      pageId={Pages.DEVICE_ACTIVITY_LOGS}
      loadingStatus={activityLogsLoadingStatus}
      sortable
      useCSVExport
      useFilter
      useResizableColumns
      useFlex
      useDateRangePicker
      onDateRangeChange={handleDateRangeChange}
    />
  );
};

export default ActivityLogs;
