export default function useMyFlexLayout(hooks: any) {
  const getRowStyles = (props: any, { instance }: any) => [
    props,
    {
      style: {
        display: 'flex',
      },
    },
  ];

  const getHeaderProps = (props: any, { column }: any) => [
    props,
    {
      style: {
        flex: column.width,
        minWidth: column.minWidth,
      },
    },
  ];

  const getCellProps = (props: any, { cell }: any) => {
    return [
      props,
      {
        style: {
          flex: cell.column.width,
          minWidth: cell.column.minWidth,
        },
      },
    ];
  };

  hooks.getRowProps.push(getRowStyles);
  hooks.getHeaderGroupProps.push(getRowStyles);
  hooks.getHeaderProps.push(getHeaderProps);
  hooks.getCellProps.push(getCellProps);
}
