import { deserializeApplication } from '../features/application/application.serializer';
import { deserializeDevice } from '../features/device/device.serializer';
import { deserializeEnrollment } from '../features/enrollment/enrollment.serializer';
import { deserializeFleet } from '../features/fleet/fleet.serializer';
import { deserializeProfile } from '../features/profile/profile.serializer';

export const deserializeSearchResults = (data = {}) => {
  const { devices, fleets, apps, profiles, enrollments } = data;

  return {
    devices: devices && devices.length ? devices.map(deserializeDevice) : [],
    fleets: fleets && fleets.length ? fleets.map(deserializeFleet) : [],
    apps: apps && apps.length ? apps.map(deserializeApplication) : [],
    profiles:
      profiles && profiles.length ? profiles.map(deserializeProfile) : [],
    enrollments:
      enrollments && enrollments.length
        ? enrollments.map(deserializeEnrollment)
        : [],
  };
};
