import {
  DEVICE_LOAD_APPLICATIONS_FAILED,
  DEVICE_LOAD_APPLICATIONS_STARTED,
  DEVICE_LOAD_APPLICATIONS_SUCCESS,
  DEVICE_LOAD_LOGS_FAILED,
  DEVICE_LOAD_LOGS_STARTED,
  DEVICE_LOAD_LOGS_SUCCESS,
  DEVICE_LOAD_PROFILES_FAILED,
  DEVICE_LOAD_PROFILES_STARTED,
  DEVICE_LOAD_PROFILES_SUCCESS,
  DEVICE_UNINSTALL_APPLICATIONS_FAILED,
  DEVICE_UNINSTALL_APPLICATIONS_RESET,
  DEVICE_UNINSTALL_APPLICATIONS_STARTED,
  DEVICE_UNINSTALL_APPLICATIONS_SUCCESS,
  DEVICE_REMOTE_CONTROL_REQUEST_FAILED,
  DEVICE_REMOTE_CONTROL_REQUEST_STARTED,
  DEVICE_REMOTE_CONTROL_REQUEST_SUCCESS,
  UPDATE_DEVICE_PROPERTY_STARTED,
  UPDATE_DEVICE_PROPERTY_FAILED,
  UPDATE_DEVICE_PROPERTY_SUCCESS,
  UPDATE_DEVICE_PROPERTY_RESET,
  SET_DEVICE_DATA,
  RESET_DEVICE_DATA,
  LOAD_DEVICE_STARTED,
  LOAD_DEVICE_FAILED,
  DEVICE_LOAD_LOGS_RESET,
  DEVICE_LOAD_APPLICATIONS_RESET,
  DEVICE_LOAD_ACTIVITY_LOGS_STARTED,
  DEVICE_LOAD_ACTIVITY_LOGS_SUCCESS,
  DEVICE_LOAD_ACTIVITY_LOGS_FAILED,
  DEVICE_LOAD_ACTIVITY_LOGS_RESET,
} from './device.actionTypes';
import { NetworkStatus } from '../../common/constants';

export default function device(
  state = {
    data: {},
    deviceDataLoadingStatus: NetworkStatus.NONE,
    deviceDataLoadingError: undefined,
    profiles: [],
    profilesLoading: false,
    profilesError: null,
    applicationsLoadingStatus: NetworkStatus.NONE,
    applications: [],
    applicationsLoadingError: undefined,
    locationLogs: [],
    locationLogsLoadingStatus: NetworkStatus.NONE,
    locationLogsLoadingError: undefined,

    activityLogs: [],
    activityLogsLoadingStatus: NetworkStatus.NONE,
    activityLogsLoadingError: undefined,

    requestDeviceRemoteControlStatus: NetworkStatus.NONE,
    applicationsUninstallStatus: NetworkStatus.NONE,
    updateDevicePropertyStatus: NetworkStatus.NONE,
    updateDevicePropertyError: undefined,
  },
  action
) {
  switch (action.type) {
    case LOAD_DEVICE_STARTED:
      return {
        ...state,
        deviceDataLoadingStatus: NetworkStatus.STARTED,
        deviceDataLoadingError: undefined,
        data: {},
      };
    case LOAD_DEVICE_FAILED:
      return {
        ...state,
        deviceDataLoadingStatus: NetworkStatus.ERROR,
        deviceDataLoadingError: action.payload,
        data: {},
      };
    case SET_DEVICE_DATA:
      return {
        ...state,
        deviceDataLoadingStatus: NetworkStatus.DONE,
        deviceDataLoadingError: undefined,
        data: action.payload,
      };
    case RESET_DEVICE_DATA:
      return {
        ...state,
        data: {},
      };
    case DEVICE_LOAD_PROFILES_STARTED:
      return Object.assign({}, state, {
        profilesLoading: true,
        profiles: [],
      });
    case DEVICE_LOAD_PROFILES_SUCCESS:
      return Object.assign({}, state, {
        profilesLoading: false,
        profiles: action.payload,
      });
    case DEVICE_LOAD_PROFILES_FAILED:
      return Object.assign({}, state, {
        profilesLoading: false,
        profilesError: action.payload,
      });
    case DEVICE_LOAD_APPLICATIONS_STARTED:
      return Object.assign({}, state, {
        applicationsLoadingStatus: NetworkStatus.STARTED,
        applications: [],
        applicationsLoadingError: undefined,
      });
    case DEVICE_LOAD_APPLICATIONS_SUCCESS:
      return Object.assign({}, state, {
        applicationsLoadingStatus: NetworkStatus.DONE,
        applications: action.payload,
        applicationsLoadingError: undefined,
      });
    case DEVICE_LOAD_APPLICATIONS_RESET:
      return {
        ...state,
        applicationsLoadingStatus: NetworkStatus.NONE,
        applications: [],
        applicationsLoadingError: undefined,
      };
    case DEVICE_LOAD_APPLICATIONS_FAILED:
      return Object.assign({}, state, {
        applicationsLoadingStatus: NetworkStatus.ERROR,
        applicationsLoadingError: action.payload,
      });
    case DEVICE_LOAD_LOGS_STARTED:
      return Object.assign({}, state, {
        locationLogsLoadingStatus: NetworkStatus.STARTED,
        locationLogs: [],
        locationLogsLoadingError: undefined,
      });
    case DEVICE_LOAD_LOGS_SUCCESS:
      return Object.assign({}, state, {
        locationLogsLoadingStatus: NetworkStatus.DONE,
        locationLogs: action.payload,
        locationLogsLoadingError: undefined,
      });
    case DEVICE_LOAD_LOGS_FAILED:
      return Object.assign({}, state, {
        locationLogsLoadingStatus: NetworkStatus.ERROR,
        locationLogsLoadingError: action.payload,
      });
    case DEVICE_LOAD_LOGS_RESET:
      return {
        ...state,
        locationLogs: [],
        locationLogsLoadingStatus: NetworkStatus.NONE,
        locationLogsLoadingError: undefined,
      };
    case DEVICE_LOAD_ACTIVITY_LOGS_STARTED:
      return {
        ...state,
        activityLogs: [],
        activityLogsLoadingStatus: NetworkStatus.STARTED,
        activityLogsLoadingError: undefined,
      };
    case DEVICE_LOAD_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        activityLogs: action.payload,
        activityLogsLoadingStatus: NetworkStatus.DONE,
        activityLogsLoadingError: undefined,
      };
    case DEVICE_LOAD_ACTIVITY_LOGS_FAILED:
      return {
        ...state,
        activityLogs: [],
        activityLogsLoadingStatus: NetworkStatus.ERROR,
        activityLogsLoadingError: action.payload,
      };
    case DEVICE_LOAD_ACTIVITY_LOGS_RESET:
      return {
        ...state,
        activityLogs: [],
        activityLogsLoadingStatus: NetworkStatus.NONE,
        activityLogsLoadingError: undefined,
      };
    case DEVICE_REMOTE_CONTROL_REQUEST_STARTED:
      return Object.assign({}, state, {
        requestDeviceRemoteControlStatus: NetworkStatus.STARTED,
      });
    case DEVICE_REMOTE_CONTROL_REQUEST_FAILED:
      return Object.assign({}, state, {
        requestDeviceRemoteControlStatus: NetworkStatus.ERROR,
      });
    case DEVICE_REMOTE_CONTROL_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        requestDeviceRemoteControlStatus: NetworkStatus.DONE,
      });
    case DEVICE_UNINSTALL_APPLICATIONS_STARTED:
      return Object.assign({}, state, {
        applicationsUninstallStatus: NetworkStatus.STARTED,
      });
    case DEVICE_UNINSTALL_APPLICATIONS_FAILED:
      return Object.assign({}, state, {
        applicationsUninstallStatus: NetworkStatus.ERROR,
      });
    case DEVICE_UNINSTALL_APPLICATIONS_SUCCESS:
      return Object.assign({}, state, {
        applicationsUninstallStatus: NetworkStatus.DONE,
      });
    case DEVICE_UNINSTALL_APPLICATIONS_RESET:
      return Object.assign({}, state, {
        applicationsUninstallStatus: NetworkStatus.NONE,
      });

    case UPDATE_DEVICE_PROPERTY_STARTED:
      return {
        ...state,
        updateDevicePropertyStatus: NetworkStatus.STARTED,
        updateDevicePropertyError: undefined,
      };
    case UPDATE_DEVICE_PROPERTY_FAILED:
      return {
        ...state,
        updateDevicePropertyStatus: NetworkStatus.ERROR,
        updateDevicePropertyError: action.payload,
      };
    case UPDATE_DEVICE_PROPERTY_SUCCESS:
      return {
        ...state,
        updateDevicePropertyStatus: NetworkStatus.DONE,
        updateDevicePropertyError: undefined,
      };
    case UPDATE_DEVICE_PROPERTY_RESET:
      return {
        ...state,
        updateDevicePropertyStatus: NetworkStatus.NONE,
        updateDevicePropertyError: undefined,
      };
    default:
      return state;
  }
}
