import { getWebSocketHost, getWebsocketProto } from './env';

export const wsApiUrl = `${getWebsocketProto()}//${getWebSocketHost()}/uis/v1/ws/`;
export const wsApiUrlV2 = `${getWebsocketProto()}//${getWebSocketHost()}/uis/v2/ws/`;

const wsServices = {
  subscribeDevicesUpdates: `${wsApiUrl}devices/company/`,
  subscribeFleetDevicesUpdates: `${wsApiUrl}devices/getall/`,
  subscribeFleetDevicesV2Updates: `${wsApiUrlV2}devices/getall/`,
  subscribeGeofencingNotifications: `${wsApiUrl}alerts/`,
  deviceUpdatesStream: `${wsApiUrl}location_stream/`,
};
export default wsServices;
