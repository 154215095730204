import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import EntityCreationWizardStepsNavigation from '../../../components/EntityCreationWizardStepsNavigation/entityCreationWizardStepsNavigation.component';
import {
  alertTypeTitles,
  AlertWizardRoutes,
  batteryConditionComparisonOperatorOptions,
  CreateAlertRoute,
} from '../createAlert.constants';
import { newAlertSelector } from '../../alerts/alerts.selectors';
import { AlertTypeTypes } from '../../alerts/alerts.constants';
import { createAlert } from '../../alerts/alerts.thunk';
import { getFleetsById } from '../../../common/utilities/fleets';
import { allCompanyFleetsV2Selector } from '../../../selectors/fleetsSelector';

const getAlertConditionValue = ({
  alertType,
  alertCondition: { conditionType, conditionValues: { percentage } = {} } = {},
}) => {
  switch (alertType) {
    case AlertTypeTypes.BATTERY:
      return I18n.t('alerts.alertConditionPreviewDescriptionBattery', {
        conditionType:
          batteryConditionComparisonOperatorOptions[conditionType].title,
        percentage,
      });

    case AlertTypeTypes.OUT_OF_CONTACT:
      return I18n.t('alerts.alertConditionPreviewDescriptionOutOfContact', {
        conditionType: I18n.t(`alerts.${conditionType}`),
      });
    case AlertTypeTypes.EXIT_GEOFENCE:
      return I18n.t('alerts.alertConditionPreviewDescriptionExitsGeofence', {
        conditionType: I18n.t(`alerts.${conditionType}`),
      });
    case AlertTypeTypes.ENTER_GEOFENCE:
      return I18n.t('alerts.alertConditionPreviewDescriptionEntersGeofence', {
        conditionType: I18n.t(`alerts.${conditionType}`),
      });
    default:
      return null;
  }
};

const renderPreviewItem = ({ title, editLink, value, valueList }) => (
  <section key={editLink} className="creation-wizard-review-step__review-item">
    <section className="creation-wizard-review-step__review-item-title-area">
      <Translate
        value={title}
        className="creation-wizard-review-step__review-item-title"
      />
      <Link
        to={editLink}
        className="creation-wizard-review-step__review-item-edit-nav"
      >
        <Translate value="alerts.edit" />
      </Link>
    </section>
    {valueList ? (
      valueList.map((item) => (
        <section
          key={item}
          className="creation-wizard-review-step__review-item-value"
        >
          {item}
        </section>
      ))
    ) : (
      <section className="creation-wizard-review-step__review-item-value">
        {value}
      </section>
    )}
  </section>
);

const getPreviewItems = ({
  name,
  allFleets,
  fleets,
  alertType,
  alertCondition,
  alertChannel,
}) => {
  const selectedFleets = getFleetsById(allFleets, fleets);
  const fleetNames = selectedFleets.map(({ name }) => name);
  return [
    {
      title: I18n.t('alerts.alertName'),
      editLink: CreateAlertRoute,
      value: name,
    },
    {
      title: I18n.t('alerts.chooseAFleet'),
      editLink: `${CreateAlertRoute}${AlertWizardRoutes.FLEET}`,
      valueList: fleetNames,
    },
    {
      title: I18n.t('alerts.alertType'),
      editLink: `${CreateAlertRoute}${AlertWizardRoutes.ALERT_TYPE}`,
      value: alertTypeTitles[alertType],
    },
    {
      title: I18n.t('alerts.alertCondition'),
      editLink: `${CreateAlertRoute}${AlertWizardRoutes.CONDITION}`,
      value: getAlertConditionValue({ alertType, alertCondition }),
    },
    // {
    //   title: I18n.t('alerts.alertChannel'),
    //   editLink: `${CreateAlertRoute}${AlertWizardRoutes.COMMUNICATION_CHANNEL}`,
    //   value: 'test name',
    // },
  ];
};

const AlertPreviewAndSave = ({ newAlert, createNewAlert, allFleets }) => {
  const handleConfirmClick = () =>
    createNewAlert({
      alert: newAlert,
    });

  const previewItems = getPreviewItems({ ...newAlert, allFleets });

  return (
    <section className="creation-wizard-step-container">
      {previewItems.map(renderPreviewItem)}
      <EntityCreationWizardStepsNavigation
        primaryNav={{
          second: {
            text: I18n.t('alerts.confirm'),
            onClick: handleConfirmClick,
          },
        }}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  const { data: allCompanyFleetsData } = allCompanyFleetsV2Selector(state);
  return {
    newAlert: newAlertSelector(state),
    allFleets: allCompanyFleetsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNewAlert: (params) => dispatch(createAlert(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertPreviewAndSave);
