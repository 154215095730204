import client from '../utils/client';
import services from '../utils/services';

export const deserializeFile = (data) => {
  return {
    // TODO: validate date, move deserialization
    key: data.key ?? data.filename,
    size: data.size || null,
    versionId: data.version_id || null,
    getLink: data.link_get || null,
    // putLink: data.link_put || null,
    filename: data.filename,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    contentType: data.content_type || 'text/plain',
    id: data.id || null,
    external_link: data.external_link || null,
    uploadedByName: data.uploaded_by_name,
    uploadedByEmail: data.uploaded_by_email,
    uploadedOn: data.uploaded_on,
  };
};

export const serializeFile = ({
  contentType,
  createdAt,
  updatedAt,
  filename,
  getLink,
  id,
  key,
  size,
  versionId,
  external_link,
}) => ({
  content_type: contentType,
  created_at: createdAt,
  updated_at: updatedAt,
  link_get: getLink,
  key: key ?? filename,
  version_id: versionId,
  external_link: external_link ?? null,
  filename,
  id,
  size,
});

export async function loadAllFilesApi() {
  return client.get(services.getFiles);
}

export async function deleteFilesApi(data) {
  return client.post(services.deleteFiles, {
    files: data,
  });
}

export async function assignFilesToProfilesApi(data) {
  return client.post(services.assignFilesToProfiles, data);
}
