import { NetworkStatus } from '../../common/constants';
import {
  ALL_USERS_LOAD_FAILED,
  ALL_USERS_LOAD_STARTED,
  ALL_USERS_LOAD_SUCCESS,
  COMPANY_FLEETS_FULL_LIST_LOAD_FAILED,
  COMPANY_FLEETS_FULL_LIST_LOAD_STARTED,
  COMPANY_FLEETS_FULL_LIST_LOAD_SUCCESS,
  GROUP_CREATE_FAILED,
  GROUP_CREATE_RESET,
  GROUP_CREATE_STARTED,
  GROUP_CREATE_SUCCESS,
  GROUP_DELETE_FAILED,
  GROUP_DELETE_RESET,
  GROUP_DELETE_STARTED,
  GROUP_DELETE_SUCCESS,
  GROUP_EDIT_FAILED,
  GROUP_EDIT_STARTED,
  GROUP_EDIT_SUCCESS,
  GROUPS_LOAD_FAILED,
  GROUPS_LOAD_STARTED,
  GROUPS_LOAD_SUCCESS,
  GROUPS_PERMISSIONS_LOAD_FAILED,
  GROUPS_PERMISSIONS_LOAD_STARTED,
  GROUPS_PERMISSIONS_LOAD_SUCCESS,
} from './group.actionTypes';

export default function groups(
  state = {
    createGroupStatus: NetworkStatus.NONE,
    createGroupError: undefined,
    createGroupData: {},

    editGroupStatus: NetworkStatus.NONE,
    editGroupError: undefined,
    editGroupData: {},

    deleteGroupStatus: NetworkStatus.NONE,
    deleteGroupError: undefined,

    modelLevelPermissionsStatus: NetworkStatus.NONE,
    modelLevelPermissionsError: undefined,
    modelLevelPermissionsData: {},

    allUsersStatus: NetworkStatus.NONE,
    allUsersError: undefined,
    allUsersData: [],

    companyFleetsFullListStatus: NetworkStatus.NONE,
    companyFleetsFullListError: undefined,
    companyFleetsFullListData: [],

    userGroupsStatus: NetworkStatus.NONE,
    userGroupsError: undefined,
    userGroups: [],
  },
  action
) {
  switch (action.type) {
    case GROUPS_LOAD_STARTED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              userGroupsStatus: NetworkStatus.STARTED,
              userGroupsError: undefined,
              userGroups: [],
            }
          : {
              userGroupsNextPageStatus: NetworkStatus.STARTED,
              userGroupsNextPageError: undefined,
            }),
      };
    case GROUPS_LOAD_FAILED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              userGroupsStatus: NetworkStatus.ERROR,
              userGroupsError: action.payload?.error,
            }
          : {
              userGroupsNextPageStatus: NetworkStatus.ERROR,
              userGroupsNextPageError: action.payload?.error,
            }),
      };

    case GROUPS_LOAD_SUCCESS: {
      const { payload: { data, isInitialLoad, ...rest } = {} } = action;
      return {
        ...state,
        ...rest,
        userGroups: [...(isInitialLoad ? [] : state.userGroups), ...data],
        ...(isInitialLoad
          ? {
              userGroupsStatus: NetworkStatus.DONE,
              userGroupsError: undefined,
            }
          : {
              userGroupsNextPageStatus: NetworkStatus.DONE,
              userGroupsNextPageError: undefined,
            }),
        nextUrl: action.payload.nextUrl,
      };
    }

    case GROUPS_PERMISSIONS_LOAD_STARTED:
      return {
        ...state,
        modelLevelPermissionsStatus: NetworkStatus.STARTED,
        modelLevelPermissionsError: undefined,
      };
    case GROUPS_PERMISSIONS_LOAD_FAILED:
      return {
        ...state,
        modelLevelPermissionsData: {},
        modelLevelPermissionsStatus: NetworkStatus.ERROR,
        modelLevelPermissionsError: action.payload,
      };
    case GROUPS_PERMISSIONS_LOAD_SUCCESS:
      return {
        ...state,
        modelLevelPermissionsData: action.payload,
        modelLevelPermissionsStatus: NetworkStatus.DONE,
        modelLevelPermissionsError: undefined,
      };

    case GROUP_CREATE_STARTED:
      return {
        ...state,
        createGroupStatus: NetworkStatus.STARTED,
        createGroupError: undefined,
      };
    case GROUP_CREATE_FAILED:
      return {
        ...state,
        createGroupData: {},
        createGroupStatus: NetworkStatus.ERROR,
        createGroupError: action.payload,
      };
    case GROUP_CREATE_SUCCESS:
      return {
        ...state,
        createGroupData: {},
        createGroupStatus: NetworkStatus.DONE,
        createGroupError: undefined,
      };
    case GROUP_CREATE_RESET:
      return {
        ...state,
        createGroupStatus: NetworkStatus.NONE,
        createGroupError: undefined,
      };

    case GROUP_EDIT_STARTED:
      return {
        ...state,
        editGroupStatus: NetworkStatus.STARTED,
        editGroupError: undefined,
      };
    case GROUP_EDIT_FAILED:
      return {
        ...state,
        editGroupData: {},
        editGroupStatus: NetworkStatus.ERROR,
        editGroupError: action.payload,
      };
    case GROUP_EDIT_SUCCESS:
      return {
        ...state,
        editGroupData: {},
        editGroupStatus: NetworkStatus.DONE,
        editGroupError: undefined,
      };

    case GROUP_DELETE_STARTED:
      return {
        ...state,
        deleteGroupStatus: NetworkStatus.STARTED,
        deleteGroupError: undefined,
      };
    case GROUP_DELETE_FAILED:
      return {
        ...state,
        deleteGroupStatus: NetworkStatus.ERROR,
        deleteGroupError: action.payload,
      };
    case GROUP_DELETE_SUCCESS:
      return {
        ...state,
        deleteGroupStatus: NetworkStatus.DONE,
        deleteGroupError: undefined,
      };

    case GROUP_DELETE_RESET:
      return {
        ...state,
        deleteGroupStatus: NetworkStatus.NONE,
        deleteGroupError: undefined,
      };
    case ALL_USERS_LOAD_STARTED:
      return {
        ...state,
        allUsersStatus: NetworkStatus.STARTED,
        allUsersError: undefined,
      };
    case ALL_USERS_LOAD_FAILED:
      return {
        ...state,
        allUsersData: [],
        allUsersStatus: NetworkStatus.ERROR,
        allUsersError: action.payload,
      };
    case ALL_USERS_LOAD_SUCCESS:
      return {
        ...state,
        allUsersData: action.payload,
        allUsersStatus: NetworkStatus.DONE,
        allUsersError: undefined,
      };
    case COMPANY_FLEETS_FULL_LIST_LOAD_STARTED:
      return {
        ...state,
        companyFleetsFullListStatus: NetworkStatus.STARTED,
        companyFleetsFullListError: undefined,
      };
    case COMPANY_FLEETS_FULL_LIST_LOAD_FAILED:
      return {
        ...state,
        companyFleetsFullListData: [],
        companyFleetsFullListStatus: NetworkStatus.ERROR,
        companyFleetsFullListError: action.payload,
      };
    case COMPANY_FLEETS_FULL_LIST_LOAD_SUCCESS:
      return {
        ...state,
        companyFleetsFullListData: action.payload,
        companyFleetsFullListStatus: NetworkStatus.DONE,
        companyFleetsFullListError: undefined,
      };
    default:
      return state;
  }
}
