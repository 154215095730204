export const ALERTS_LOAD_STARTED = 'ALERTS_LOAD_STARTED';
export const ALERTS_LOAD_FAILED = 'ALERTS_LOAD_FAILED';
export const ALERTS_LOAD_SUCCESS = 'ALERTS_LOAD_SUCCESS';

export const ALERT_CREATION_STARTED = 'ALERT_CREATION_STARTED';
export const ALERT_CREATION_FAILED = 'ALERT_CREATION_FAILED';
export const ALERT_CREATION_SUCCESS = 'ALERT_CREATION_SUCCESS';
export const ALERT_CREATION_RESET = 'ALERT_CREATION_RESET';

export const ALERT_DELETION_STARTED = 'ALERT_DELETION_STARTED';
export const ALERT_DELETION_FAILED = 'ALERT_DELETION_FAILED';
export const ALERT_DELETION_SUCCESS = 'ALERT_DELETION_SUCCESS';
export const ALERT_DELETION_RESET = 'ALERT_DELETION_RESET';

export const RESET_ALERT_CREATION = 'RESET_ALERT_CREATION';
export const CHANGE_ALERT_PROPERTY = 'CHANGE_ALERT_PROPERTY';
export const SET_NEW_ALERT_DEFAULT_VALUES = 'SET_NEW_ALERT_DEFAULT_VALUES';
