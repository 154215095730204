const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  // change background colour if dragging
  // isDragging ? 'lightgray' : 'white',
  borderBottom: '1px solid lightgray',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgray' : '',
  padding: grid,
  width: 250,
});

export const getSelectedListStyle = (isDraggingOver) => ({
  ...getListStyle(isDraggingOver),
  width: '100%',
  minWidth: '250px',
  height: '90%',
  minHeight: '120px',
  padding: '4px 20px',
  overflow: 'hidden',
  overflowY: 'auto',
});

export const getAvailableListStyle = (isDraggingOver) => ({
  ...getListStyle(isDraggingOver),
  padding: '4px 20px',
  width: '100%',
  minWidth: '250px',
  minHeight: '100px',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  overflowY: 'auto',
});

export const getListItemStyle = (isDragging, style) => ({
  ...getItemStyle(isDragging, style),
  ...styles.listItem,
  maxWidth: isDragging ? '250px' : '100%',
});

export const styles = {
  bundleIdColumnHeader: {
    width: '200px',
  },
};
