import classNames from 'classnames';

const mergeClasses = (...classes) => classNames(classes);

const mergeModifiers = (baseClass, modifiers, ...rest) => {
  let cssModifiers = [];

  if (modifiers) {
    cssModifiers = modifiers instanceof Array ? modifiers.slice() : [modifiers];
  }

  cssModifiers = cssModifiers.concat(rest);

  let result = baseClass;
  cssModifiers.forEach((modifier) => {
    if (typeof modifier === 'string') {
      result += ` ${baseClass}--${modifier}`;
    }
  });

  return result;
};

const replaceModifiersWithACommonModifier = (
  baseClass,
  modifiers = {},
  replacementModifier = ''
) => {
  const modifierList = Object.values(modifiers).every(Boolean)
    ? [replacementModifier]
    : Object.entries(modifiers).reduce(
        (acc, [key, value]) => (value ? [...acc, key] : acc),
        []
      );
  return mergeModifiers(baseClass, modifierList);
};

const StyleUtils = {
  mergeClasses,
  mergeModifiers,
  replaceModifiersWithACommonModifier,
};

export default StyleUtils;
