import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { withRouter, RouteProps } from 'react-router';
import Popup from 'reactjs-popup';
import { Button, Input, Label } from 'reactstrap';
import { compose } from 'redux';
import { cloneDeep } from 'lodash';
import { ReactComponent as CrestCircle } from '../assets/images/crest-circle.svg';
import { ReactComponent as BarsHorizontal } from '../assets/images/bars-horizontal.svg';
import { AttributeType } from '../common/constants';
import Fence from '../features/profile/components/Fence';
import {
  setFilters,
  setFiltersStrictness,
  setFiltersUpdating,
} from '../redux/filters.actions';
import { initializeFilters, updateFilters } from '../redux/filters.thunk';
import { companyIdSelector } from '../features/login/login.selectors';
import { filterConditionOptions } from '../redux/filters.constants';
import * as moment from 'moment-timezone';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { selectForCurrentBrand } from '../common/helpers';
import { I18n, Translate } from 'react-redux-i18n';
import { SwitchField } from './switchField.component';

// interface FilterProps {
//     columns: Column[],
//     initializeFilters: (searchQuery: string, columns: object[]) => void,
//     setFiltersStrictness: (isCriteriaStrict: 'notStrict' | 'strict') => void,
//     setFiltersUpdating: (shouldUpdate: boolean) => void,
//     setFilters: (filters: object[]) => void,
//     updateFilters: (params: object) => void,
//     filters: object[],
//     shouldUpdate: boolean,
//     isCriteriaStrict: 'notStrict' | 'strict',
// }

// class Filter extends Component<FilterProps & RouteProps> {
class Filter extends Component {
  componentDidMount() {
    if (this.props.location.search) {
      this.props.initializeFilters(
        this.props.location.search,
        this.props.columns
      );
    }
    // restore the view state when navigating through the history, see
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
    window.addEventListener('popstate', this.popStateListener);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popStateListener);
    this.props.setFiltersUpdating(true);
    this.props.setFiltersStrictness('notStrict');
    this.props.setFilters([]);
  }

  popStateListener = (event) => {
    if (event.state === null) {
      return;
    }
    this.props.initializeFilters(
      this.props.location.search,
      this.props.columns
    );
    this.props.setFiltersUpdating(false);
  };

  toggleCriteriaStrictness = (e) => {
    const {
      target: { value, checked },
    } = e;
    if (checked) {
      this.props.setFiltersStrictness(value);
      const {
        shouldUpdate,
        filters,
        location: { pathname },
      } = this.props;
      this.props.updateFilters({
        shouldUpdate,
        filters,
        pathname,
        isCriteriaStrict: value,
      });
    }
  };

  onChangeFilter = (index, { target: { value, name } }) => {
    const { shouldUpdate, isCriteriaStrict, updateFilters } = this.props;
    const filters = cloneDeep(this.props.filters);
    const currentFilter = filters[index];
    let condition = currentFilter?.condition;
    let defaultBooleanValue = null;
    let column;
    if (name === 'attribute') {
      column = this.props.columns.find((column) => column.key === value);
      if (column) {
        condition = column.availableFilterConditions[0];
        if (column.type === AttributeType.BOOLEAN) {
          defaultBooleanValue = true;
        }
      }
    }

    filters.splice(index, 1, {
      ...(filters[index] || {}),
      ...(column?.customParams && name === 'attribute'
        ? { customParams: column?.customParams }
        : {}),
      condition,
      value:
        name === 'attribute' ? defaultBooleanValue ?? '' : filters[index].value,
      [name]: value,
    });

    updateFilters({
      shouldUpdate,
      filters,
      isCriteriaStrict,
      pathname: this.props.location.pathname,
    });
  };

  onAddAttribute = () => {
    this.props.setFilters([
      ...this.props.filters,
      { condition: 'EQ', attribute: undefined, value: '' },
    ]);
  };

  removeFilter = (index) => {
    let filters = [...this.props.filters];
    filters.splice(index, 1);
    const {
      shouldUpdate,
      isCriteriaStrict,
      location: { pathname },
    } = this.props;
    this.props.updateFilters({
      shouldUpdate,
      filters,
      isCriteriaStrict,
      pathname,
    });
  };

  // clearAllFilters = () => {
  //     this.setState(state => ( {...state, filters: new Map()} ));
  // };

  renderFilter = () => {
    return <div style={{ minWidth: '' }}></div>;
  };

  render() {
    // TODO: get rid of popup module for better alternative, get rid of reactstrap components
    return (
      <Popup
        closeOnDocumentClick
        position="bottom right"
        contentStyle={{
          minWidth: '200px',
          maxWidth: 'calc(80vw - 100px)',
          width: '100%',
        }}
        repositionOnResize
        trigger={
          <div
            style={{
              marginLeft: '3px',
              marginRight: '3px',
              textTransform: 'uppercase',
              width: 'auto',
              minWidth: '80px',
              minHeight: '32px',
              height: '32px',
              borderRadius: '2px',
              padding: '7px 16px',
              display: 'flex',
              alignItems: 'center',
            }}
            className={`create-enrollment-wizard__control-button 
                                ${selectForCurrentBrand({
                                  original: 'button--secondary',
                                  janam: 'button--janam--secondary',
                                  incube: 'button--incube--secondary',
                                })}`}
          >
            <div>
              <Translate value="common.filter" />
              {this.props.filters.length ? (
                <BarsHorizontal
                  style={{
                    width: '16px',
                    height: '14px',
                    minWidth: '16px',
                    minHeight: '16px',
                    marginLeft: '4px',
                    verticalAlign: 'bottom',
                  }}
                  alt={''}
                />
              ) : (
                <BarsHorizontal
                  style={{
                    width: '16px',
                    height: '14px',
                    minWidth: '16px',
                    minHeight: '16px',
                    marginLeft: '4px',
                    verticalAlign: 'bottom',
                    color: '#959595',
                  }}
                  alt={''}
                />
              )}
            </div>
          </div>
        }
      >
        <div
          style={{
            outline: 'none !important',

            boxShadow: 'none',
            minWidth: '200px',
            width: '100%',
            maxWidth: '80vw',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              fontSize: '12px',
              letterSpacing: 'normal',
            }}
          >
            <div
              style={{
                marginTop: '38px',
                marginLeft: '21px',

                fontSize: '21px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: '1.1',
              }}
            >
              <Translate value="common.filterDescriptionTitle" />
            </div>
            {/*<div style={{*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    marginLeft: 'auto',*/}
            {/*    height: '32px',*/}
            {/*    minHeight: '32px'*/}
            {/*}}>*/}
            {/*    <div*/}
            {/*        style={{*/}
            {/*            textDecoration: 'underline',*/}
            {/*            padding: '20px',*/}
            {/*            color: this.state.filters.size*/}
            {/*                ? 'rgb(0, 35, 64)'*/}
            {/*                : 'lightgray',*/}
            {/*            whiteSpace: 'nowrap',*/}
            {/*            userSelect: 'none',*/}
            {/*            cursor: this.state.filters.size*/}
            {/*                ? 'pointer'*/}
            {/*                : 'initial',*/}
            {/*        }}*/}
            {/*        onClick={this.clearAllFilters}*/}
            {/*    >*/}
            {/*        {'Clear All'}*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
          <form
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              flexFlow: 'row',
              marginLeft: '21px',
              marginTop: '33px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Label
                  check
                  style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: '1.17',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Input
                    type="radio"
                    name="radio1"
                    value={'strict'}
                    checked={this.props.isCriteriaStrict === 'strict'}
                    onChange={this.toggleCriteriaStrictness}
                    style={{ marginRight: '4px' }}
                  />{' '}
                  <Translate value="common.filterDescriptionOptionAll" />
                </Label>
              </div>
              <div style={{ marginLeft: '28px' }}>
                <Label
                  check
                  style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: '1.17',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '4px',
                    cursor: 'pointer',
                  }}
                >
                  <Input
                    type="radio"
                    name="radio1"
                    value={'notStrict'}
                    checked={this.props.isCriteriaStrict === 'notStrict'}
                    onChange={this.toggleCriteriaStrictness}
                    style={{ marginRight: '4px' }}
                  />{' '}
                  <Translate value="common.filterDescriptionOptionAny" />
                </Label>
              </div>
            </div>
          </form>
          <div
            style={{
              marginTop: '38px',
              marginLeft: '21px',

              fontSize: '21px',
              fontWeight: 'normal',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: '1.1',
            }}
          >
            <Translate value="common.addCriteria" />
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '14px 24px',
            }}
          >
            {/*<div style={{*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'center',*/}
            {/*    width: '100%',*/}
            {/*    height: '24px',*/}
            {/*    minHeight: '24px',*/}
            {/*    padding: '20px',*/}
            {/*    color: this.state.selectedAttribute*/}
            {/*        ? 'rgb(0, 35, 64)'*/}
            {/*        : 'lightgray',*/}
            {/*    whiteSpace: 'nowrap',*/}
            {/*    userSelect: 'none',*/}
            {/*    cursor: this.state.selectedAttribute*/}
            {/*        ? 'pointer'*/}
            {/*        : 'initial',*/}
            {/*}}*/}
            {/*     */}
            {/*> {'Apply filter'}*/}
            {/*</div>*/}
          </div>
          {!this.props.filters.length ? (
            <div
              style={{
                padding: '12px 24px',
                color: 'rgb(0, 35, 64)',
              }}
            >
              <Translate value="common.noFiltersApplied" />
            </div>
          ) : null}

          <form
            style={{
              overflowY: 'auto',
              height: '200px',
              maxHeight: '200px',
            }}
          >
            {this.props.filters.map(this.renderFilterSection)}
            <Button
              style={{
                backgroundColor: 'white',
                color: '#003562', // --incube primary color
                border: '1px solid #003562', // --incube primary color
                marginRight: '3px',
                textTransform: 'uppercase',
                width: 'auto',
                minWidth: '80px',
                minHeight: '32px',
                borderRadius: '2px',
                // padding: '7px 16px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '0.6px',
                marginLeft: '21px',
              }}
              className={'create-enrollment-wizard__control-button'}
              onClick={this.onAddAttribute}
            >
              <Translate value="common.addCriteria" />
            </Button>
          </form>
        </div>
      </Popup>
    );
  }

  renderFilterSection = (filter, index) => {
    const foundSearchableColumn = this.props.columns.find(
      (column) => column.key === filter.attribute
    );
    return (
      <div
        key={`filter_controls_${index}`}
        style={{
          minHeight: '24px',
          minWidth: '320px',
          width: 'auto',
          borderRadius: '2px',
          padding: '14px 24px',
          backgroundColor: 'white',
          color: '#002340', // --incube dark navi
          margin: '5px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <select
          className="form-control"
          value={filter.attribute || ''}
          name={'attribute'}
          onChange={(e) => this.onChangeFilter(index, e)}
          placeholder={'Select attribute'}
        >
          {filter.attribute ? null : <option />}
          {this.props.columns.map(({ key, titleCode }) => {
            return <option value={key}>{I18n.t(titleCode)}</option>;
          })}
        </select>
        <select
          className="form-control"
          value={filter.condition || ''}
          name={'condition'}
          onChange={(e) => this.onChangeFilter(index, e)}
          placeholder={I18n.t('common.selectCondition')}
          disabled={!filter.attribute || !foundSearchableColumn}
        >
          {filter.attribute &&
          foundSearchableColumn &&
          foundSearchableColumn.availableFilterConditions
            ? foundSearchableColumn.availableFilterConditions.map(
                this.renderOptions
              )
            : null}
        </select>

        {foundSearchableColumn?.filterValueTypeOptions ? (
          <select
            className="form-control"
            value={filter.filterValueType || ''}
            name={'filterValueType'}
            onChange={(e) => this.onChangeFilter(index, e)}
            placeholder={I18n.t('common.selectValueType')}
          >
            <option />
            {foundSearchableColumn.filterValueTypeOptions.map(
              ({ key, titleCode }) => (
                <option value={key}>{I18n.t(titleCode)}</option>
              )
            )}
          </select>
        ) : null}
        {this.renderFilterControls(filter, foundSearchableColumn, index)}
        <CrestCircle
          alt=""
          title={<Translate value="common.removeFilter" />}
          onClick={() => this.removeFilter(index)}
          style={{
            width: '18px',
            opacity: '0.8',
            height: '18px',
            minWidth: '18px',
            minHeight: '18px',
            cursor: 'pointer',
            display: 'flex',
            // ":hover": "opacity: 1; background-color: #f2f2f2;",
          }}
        />
      </div>
    );
  };

  renderOptions = (key) => {
    let item = filterConditionOptions.find((c) => c.key === key);
    let titleCode = item ? item.titleCode : '';
    return (
      <option value={key} key={key}>
        {I18n.t(titleCode)}
      </option>
    );
  };

  renderFilterControls = (filter, column, index) => {
    if (
      filter.attribute &&
      column &&
      column.availableOptions &&
      column.availableOptions.length
    ) {
      return (
        <select
          className="form-control"
          value={filter.value || ''}
          name={'value'}
          style={{ marginRight: '24px' }}
          onChange={(e) => this.onChangeFilter(index, e)}
          placeholder={I18n.t('common.selectValue')}
          disabled={!filter.attribute}
        >
          <option value={''}>{''}</option>
          {column.availableOptions.map(({ key, value, titleCode, title }) => {
            return (
              <option key={key ?? value} value={key ?? value}>
                {title ?? titleCode}
              </option>
            );
          })}
        </select>
      );
    }
    if (column?.type === AttributeType.TEXT) {
      return (
        <FormControl
          type={'text'}
          name={'value'}
          value={filter.value || ''}
          onChange={(e) => this.onChangeFilter(index, e)}
          style={{ marginRight: '24px' }}
          disabled={!filter.attribute}
        />
      );
    }
    if (column?.type === AttributeType.BOOLEAN) {
      const onToggle = () => {
        this.onChangeFilter(index, {
          target: { value: !filter.value, name: 'value' },
        });
      };

      return (
        <SwitchField
          on={filter.value}
          onClick={onToggle}
          additionalClasses={['switch-field switch-field--block-display']}
        />
      );
    }
    if (column?.type === AttributeType.TIME_OFFSET) {
      return (
        <FormControl
          type={'number'}
          name={'value'}
          max={100}
          min={1}
          value={filter.value || ''}
          onChange={(e) => this.onChangeFilter(index, e)}
          style={{ marginRight: '24px' }}
          disabled={!filter.attribute}
        />
      );
    }
    if (column && column.type === AttributeType.PERCENTAGE) {
      return (
        <FormControl
          type={'number'}
          name={'value'}
          max={100}
          min={1}
          value={filter.value || ''}
          onChange={(e) => this.onChangeFilter(index, e)}
          style={{ marginRight: '24px' }}
          disabled={!filter.attribute}
        />
      );
    }
    if (column && column.type === AttributeType.SIZE) {
      return (
        <FormControl
          type={'number'}
          name={'value'}
          max={100_000_000_000_000}
          min={1}
          value={filter.value || ''}
          onChange={(e) => this.onChangeFilter(index, e)}
          style={{ marginRight: '24px' }}
          disabled={!filter.attribute}
        />
      );
    }
    if (column && column.type === AttributeType.DATE) {
      return (
        <div style={{ width: '33.333%', height: '32px', marginRight: '24px' }}>
          <Datetime
            value={
              filter.value &&
              !isNaN(Number(filter.value)) &&
              moment(Number(filter.value)).isValid()
                ? moment(Number(filter.value))
                : undefined
            }
            dateFormat={'YYYY-MM-DD'}
            timeFormat={false}
            onChange={(date) =>
              this.onChangeFilter(index, {
                target: {
                  name: 'value',
                  value: moment(date).valueOf(),
                },
              })
            }
            className={'filter-control-date-picker'}
            inputProps={{
              style: {
                width: 'calc(100% - 64px)',
                minWidth: 'calc(100% - 64px)',
                maxWidth: 'calc(100% - 64px)',
              },
            }}
            closeOnSelect={true}
          />
        </div>
      );
    }
    if (column && column.type === AttributeType.COORDINATES) {
      return (
        <div style={{ width: '33.333%', height: '32px', marginRight: '24px' }}>
          {this.renderLocationEditor(index, filter)}
        </div>
      );
    }
    return (
      <FormControl
        type={'text'}
        name={'value'}
        value={''}
        style={{ marginRight: '24px' }}
        disabled={true}
      />
    );
  };

  renderLocationEditor = (index, filter) => {
    const hasGeometry = filter.value && filter.value.length;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
        }}
      >
        {hasGeometry ? (
          <div style={{ padding: '0 15px' }}>
            <Translate value="common.location" />
          </div>
        ) : (
          <div style={{ padding: '0 15px' }}>
            <Translate value="common.noLocationSet" />
          </div>
        )}
        <Button
          onClick={() =>
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: 'auto',
                      width: '800px',
                      maxWidth: '100vw',
                      maxHeight: '100vh',
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row-reverse nowrap',
                        }}
                      >
                        <Button secondary onClick={() => onClose()}>
                          <Translate value="common.close" />
                        </Button>
                      </div>
                      <Fence
                        index={index}
                        fence={filter.value}
                        onChangeSettings={(index, type, value) => {
                          this.onChangeFilter(index, {
                            target: {
                              name: 'value',
                              value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              },
            })
          }
        >
          {hasGeometry ? (
            <Translate value="common.edit" />
          ) : (
            <Translate value="common.set" />
          )}
        </Button>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    companyId: companyIdSelector(state),
    filters: state.filters.filters,
    shouldUpdate: state.filters.shouldUpdate,
    isCriteriaStrict: state.filters.isCriteriaStrict,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeFilters: (searchQuery, columns) =>
      dispatch(initializeFilters(searchQuery, columns)),
    updateFilters: ({ shouldUpdate, filters, isCriteriaStrict, pathname }) =>
      dispatch(
        updateFilters({ shouldUpdate, filters, isCriteriaStrict, pathname })
      ),
    setFilters: (filters) => dispatch(setFilters(filters)),
    setFiltersStrictness: (isCriteriaStrict) =>
      dispatch(setFiltersStrictness(isCriteriaStrict)),
    setFiltersUpdating: (shouldUpdate) =>
      dispatch(setFiltersUpdating(shouldUpdate)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Filter);

// export default compose(
//     withRouter,
//     connect(mapStateToProps, mapDispatchToProps)
// )(Filter) as React.ComponentType<any>;
