import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Button } from '../../../components/button.component';
import { CreateEnrollmentDeviceNaming } from '../../../components/Enrollment/createEnrollmentDeviceNaming';
import { changeEnrollmentProperty } from '../../enrollment/enrollment.actions';
import { EnrollmentWizardRoutes } from '../CreateEnrollment.constants';
import { history } from '../../../utils/history';
import { DevicesRoutes, RootRoutes } from '../../../utils/routes';
import { debounce, get } from 'lodash';

class createEnrollmentDeviceNaming extends React.Component {
  state = {
    name: 'Device',
    sequence: ['name', ...Array(9).fill('None')],
    separator: '_',
    startingNumber: '0',
  };

  componentDidMount() {
    this.initState();
  }

  initState = () => {
    this.setState(
      {
        name: get(this.props, 'newEnrollment.deviceNaming.name', 'Device'),
        sequence: get(this.props, 'newEnrollment.deviceNaming.sequence', {})
          .length
          ? this.props.newEnrollment.deviceNaming.sequence
          : [
              'name',
              'None',
              'None',
              'None',
              'None',
              'None',
              'None',
              'None',
              'None',
              'None',
            ],
        separator: get(this.props, 'newEnrollment.deviceNaming.separator', '_'),
        startingNumber: get(
          this.props,
          'newEnrollment.deviceNaming.startingNumber',
          '0'
        ),
      },
      this.changeEnrollment
    );
  };

  changeEnrollment = () => {
    this.props.changeEnrollmentProperty({
      key: 'deviceNaming',
      value: {
        ...get(this.props, 'newEnrollment.deviceNaming', {}),
        sequence: this.state.sequence,
        name: this.state.name,
        separator: this.state.separator,
        startingNumber: this.state.startingNumber,
      },
    });
  };

  changeEnrollmentDebounced = debounce(this.changeEnrollment, 300);

  handleChange = ({ target: { id, value } }) => {
    this.setState(
      {
        [id]: value,
      },
      this.changeEnrollmentDebounced
    );
  };

  onChangeSequence = (index, { target: { value } }) => {
    this.setState((state) => {
      const sequence = [...state.sequence];
      sequence.splice(index, 1, value);
      return { sequence };
    }, this.changeEnrollmentDebounced);
  };

  render() {
    const { name, sequence, separator, startingNumber } = this.state || {};

    return (
      <div
        className={''}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <ControlLabel
          style={{
            fontSize: '21px',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#003562', // --incube primary color
            marginBottom: '45px',
            fontWeight: 'normal',
          }}
        >
          {I18n.t('enrollments.namingSequenceHint')}
        </ControlLabel>
        <CreateEnrollmentDeviceNaming
          className={'enrollment-device-naming__content'}
          name={name}
          separator={separator}
          startingNumber={startingNumber}
          onChange={this.handleChange}
          sequence={sequence}
          onChangeSequence={this.onChangeSequence}
        />
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: 'auto',
            minHeight: '32px',
            height: '32px',
          }}
        >
          <Button
            secondary
            className={
              'create-enrollment-wizard__control-button create-enrollment-wizard__control-button--first'
            }
            onClick={this.goBack}
          >
            {`⟵ ${I18n.t('enrollments.prevStepButton')}`}
          </Button>
          <Button
            secondary
            className={'create-enrollment-wizard__control-button'}
            disabled={!this.state.name}
            onClick={this.goNext}
          >
            {`${I18n.t('enrollments.forwardStepButton')} ⟶`}
          </Button>
        </div>
      </div>
    );
  }

  goBack = () => {
    history.push(
      `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create${EnrollmentWizardRoutes.FLEET}`
    );
  };

  goNext = () => {
    history.push(
      `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create${EnrollmentWizardRoutes.REVIEW}`
    );
  };
}

const mapStateToProps = (state) => {
  return {
    newEnrollment: state.enrollment.newEnrollment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeEnrollmentProperty: ({ key, value }) =>
      dispatch(changeEnrollmentProperty({ key, value })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createEnrollmentDeviceNaming);
