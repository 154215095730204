import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../components/NotFound';
import { FleetV1Route } from '../utils/routes';
import Fleets from '../features/fleets/fleets.component';

const _Fleets = (props) => (
  <Switch>
    <Route exact path={`${FleetV1Route}/:childRoute?`} component={Fleets} />
    <Route
      exact
      path={`${FleetV1Route}/:childRoute/devices/:deviceId`}
      component={Fleets}
    />
    <Route component={NotFound} />
  </Switch>
);

export default _Fleets;
