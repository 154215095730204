import React from 'react';
import { Translate } from 'react-redux-i18n';
import { AlertRowAlertTypeIcons } from '../alerts.constants';

const AlertType = ({ item: { alertType } = {} }) => {
  const icon = AlertRowAlertTypeIcons[alertType];
  return (
    <div className="alert-row__alert-type">
      {icon}
      <Translate value={`alerts.${alertType}`} />
    </div>
  );
};

export default AlertType;
