import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import EntityCreationWizardStepsNavigation from '../../../components/EntityCreationWizardStepsNavigation/entityCreationWizardStepsNavigation.component';
import { history } from '../../../utils/history';
import { AlertWizardRoutes, CreateAlertRoute } from '../createAlert.constants';
import { newAlertSelector } from '../../alerts/alerts.selectors';
import { changeAlertProperty } from '../../alerts/alerts.actions';
import AlertChannel from '../../../components/alertCreationAndEditing/CommunicationChannel.component';

const AlertChannelStep = (props) => {
  const {
    newAlert,
    newAlert: { alertChannel: { communicationChannel } = {} } = {},
  } = props;

  const goToNextStep = () =>
    history.push(`${CreateAlertRoute}${AlertWizardRoutes.REVIEW}`);
  return (
    <div className="creation-wizard-step-container">
      <AlertChannel
        alert={newAlert}
        onPropertyChange={props.changeAlertProperty}
      />
      <EntityCreationWizardStepsNavigation
        primaryNav={{
          first: {
            isBack: true,
          },
          second: {
            onClick: goToNextStep,
            disabled: !communicationChannel,
            text: `${I18n.t('alerts.next')} ⟶`,
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  newAlert: newAlertSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeAlertProperty: ({ key, value }) =>
    dispatch(changeAlertProperty({ key, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertChannelStep);
