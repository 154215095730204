import client, { clientBC } from '../../utils/client';
import services from '../../utils/services';
import {
  assignProfilesToFleetsFailed,
  assignProfilesToFleetsStarted,
  assignProfilesToFleetsSuccess,
  loadProfilesFailed,
  loadProfilesStarted,
  profilesDeleted,
  profilesDeletingFailed,
  profilesDeletingStarted,
  profilesLoaded,
} from './profiles.actions';
import { deserializeProfile } from '../profile/profile.serializer';
import PopupActions from '../popup/popup.actions';
import { history } from '../../utils/history';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import { companyIdSelector } from '../login/login.selectors';
import { getState } from '../../store/store';
import { loadProfilesV2 } from '../profilesV2/profilesV2.thunk';
import { handleRequestResponse } from '../../common/utilities/errorHandling';
import { I18n } from 'react-redux-i18n';

export function loadProfiles() {
  return async (dispatch) => {
    try {
      dispatch(loadProfilesStarted());
      const companyId = companyIdSelector(getState());
      let response = await client.get(
        `${services.getAllProfiles}${companyId}`,
        {}
      );
      if (response.status === 200 && response.data) {
        let result = response.data.map(deserializeProfile);
        dispatch(profilesLoaded(result));
      } else {
        dispatch(loadProfilesFailed(I18n.t('profiles.failedToLoadProfiles')));
      }
    } catch (error) {
      dispatch(
        loadProfilesFailed(new Error(I18n.t('profiles.failedToLoadProfiles')))
      );
    }
  };
}

export function assignProfilesToFleets(profiles, fleets) {
  return async (dispatch) => {
    try {
      dispatch(assignProfilesToFleetsStarted());
      let data = {
        profiles,
        fleets,
      };
      let response = await client.post(services.assignProfiles, data);
      if (response.status === 200) {
        dispatch(assignProfilesToFleetsSuccess());
      } else if (response.status === 400) {
        dispatch(assignProfilesToFleetsFailed(response.status.text));
      } else {
        dispatch(
          assignProfilesToFleetsFailed(
            I18n.t('profiles.failedToAssignProfilesToFleets')
          )
        );
      }
    } catch (error) {
      dispatch(
        assignProfilesToFleetsFailed(
          I18n.t('profiles.failedToAssignProfilesToFleets')
        )
      );
    }
  };
}

export function unassignProfiles(profiles, companyId) {
  return async (dispatch) => {
    try {
      let response = await client.post(services.unassignProfiles, { profiles });
      if (response.status === 204) {
        dispatch(loadProfiles());
      }
    } catch (error) {}
  };
}

export function cloneProfiles(profiles, companyId) {
  return async (dispatch) => {
    try {
      let response = await client.post(services.cloneProfiles, {
        profile_list: profiles,
      });
      if (response.status === 204) {
        dispatch(loadProfiles());
      }
    } catch (error) {}
  };
}

export function deleteProfiles(profiles) {
  return async (dispatch) => {
    try {
      dispatch(profilesDeletingStarted());
      let response = await client.delete(services.deleteProfile, {
        data: { profiles },
      });
      handleRequestResponse({
        response,
        successStatusCode: 204,
        onSuccess: () => {
          dispatch(profilesDeleted());
          dispatch(loadProfiles());
        },
        successTextCode: 'profiles.profilesSuccessfullyDeleted',
        onError: () => {
          dispatch(profilesDeletingFailed(response.status.text));
        },
        defaultErrorMessageTextCode: 'profiles.profilesNotSuccessfullyDeleted',
      });
    } catch (error) {
      dispatch(
        profilesDeletingFailed(
          new Error(I18n.t('profiles.failedToDeleteProfiles'))
        )
      );
    }
  };
}

export const migrateProfileToNewVersion = ({ profileIds }) => async (
  dispatch
) => {
  try {
    const { status } = await clientBC.post(services.migrateV1ProfilesToV2, {
      profiles_v1: profileIds,
    });
    if (status === 200) {
      PopupActions.showAlert({
        text: I18n.t('profiles.migrationSuccessText'),
        onClose: () => {
          dispatch(
            loadProfilesV2({
              loadFullList: true,
              isInitialLoad: true,
            })
          );
          history.push(`${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}`);
        },
      });
    } else {
      PopupActions.showAlert({
        text: I18n.t('profiles.migrationFailureText'),
      });
    }
  } catch (e) {}
};
