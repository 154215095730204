import React from 'react';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../../../components/inputField.component';
import { policySettingsValueChangeHandler } from '../../../common/utilities/profile';
import { getObjectByValue, isEmpty, notEmpty } from '../../../common/helpers';
import PolicyActivationToggle from './PolicyActivationToggle.component';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { SelectField } from '../../../components/selectField.component';
import { SwitchField } from '../../../components/switchField.component';
import { MOBILE_COUNTRY_CODES } from '../../../constants/mobileCountryCodes';
import { MOBILE_OPERATORS } from '../../../constants/mobileOperators';
import { AttributeType } from '../../../common/constants';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';

const initialSettings = {};

const initialPolicy = {
  policyName: 'new',
  policyType: 'apn',
  isActive: false,
  policyData: null,
};

const accessPointTypes = {
  MMS: 'mms',
  INTERNET: 'internet',
  MMS_AND_INTERNET: 'mmsAndInternet',
  ANY: 'any',
};

const accessPointTypeOptions = [
  {
    value: accessPointTypes.MMS,
    title: I18n.t('profiles.apn.accessPointTypeMMS'),
  },
  {
    value: accessPointTypes.INTERNET,
    title: I18n.t('profiles.apn.accessPointTypeInternet'),
  },
  {
    value: accessPointTypes.MMS_AND_INTERNET,
    title: I18n.t('profiles.apn.accessPointTypeMMSAndInternet'),
  },
  {
    value: accessPointTypes.ANY,
    title: I18n.t('profiles.apn.accessPointTypeAny'),
  },
];

const MVNOTypes = {
  NONE: 'none',
  SPN: 'spn',
  IMSI: 'imsi',
  GID: 'gid',
};

const MVNOTypeOptions = [
  { value: MVNOTypes.NONE, title: I18n.t('profiles.apn.MVNOTypeNone') },
  { value: MVNOTypes.SPN, title: I18n.t('profiles.apn.MVNOTypeSPN') },
  { value: MVNOTypes.IMSI, title: I18n.t('profiles.apn.MVNOTypeIMSI') },
  { value: MVNOTypes.GID, title: I18n.t('profiles.apn.MVNOTypeGID') },
];

const authenticationTypes = {
  NONE: 'none',
  PAP: 'pap',
  CHAP: 'chap',
  PAP_CHAP: 'pap-chap',
};

const authenticationTypeOptions = [
  {
    value: authenticationTypes.NONE,
    title: I18n.t('profiles.apn.authenticationTypeNone'),
  },
  {
    value: authenticationTypes.PAP,
    title: I18n.t('profiles.apn.authenticationTypePAP'),
  },
  {
    value: authenticationTypes.CHAP,
    title: I18n.t('profiles.apn.authenticationTypeCHAP'),
  },
  {
    value: authenticationTypes.PAP_CHAP,
    title: I18n.t('profiles.apn.authenticationTypePAPCHAP'),
  },
];

const mobileCountryCodeOptions = MOBILE_COUNTRY_CODES.map(
  ({ country, id, mcc, flag }) => ({
    title: `${flag ?? ''}${country} (${mcc})`,
    value: id,
  })
);

const APN = ({
  isEditingMode,
  policy,
  onChange,
  onChangePolicyProperty,
  policyKey,
}) => {
  // todo refactor
  const isPolicyActive = policy.some((item) => item.isActive);

  const onValueChange = ({
    value,
    key,
    isValueBoolean = false,
    customUpdateObject,
  }) =>
    policySettingsValueChangeHandler({
      key,
      value,
      customUpdateObject,
      initialPolicy,
      initialSettings,
      isValueBoolean,
      onChange,
      policy,
      policyDetailKey: policyKey,
    });

  const renderSettings = ({ settings = {}, isActive }, index) => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      onChangePolicyProperty({
        key: policyKey,
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });

    return (
      <>
        <SectionDescription title={I18n.t('profiles.apn.policyTitle')} />
        <PolicyActivationToggle
          key="policyActivation"
          policy={policy}
          titleCode="profiles.apn.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
          informationIconText={I18n.t('profiles.apn.apnSettingsAvailability')}
        />
        {isActive ? (
          <>
            <InputField
              label={I18n.t('profiles.apn.apnDisplayNameLabel')}
              value={settings.apnDisplayName || ''}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'apnDisplayName',
                  value: e.target.value,
                })
              }
              placeholder={I18n.t('profiles.apn.apnDisplayNameLabel')}
              required
            />
            <InputField
              label={I18n.t('profiles.apn.apnNameLabel')}
              value={settings.apnName || ''}
              onChange={(e) =>
                onValueChange({ index, key: 'apnName', value: e.target.value })
              }
              placeholder={I18n.t('profiles.apn.apnNameLabel')}
            />

            <SelectField
              title={I18n.t('profiles.apn.mobileCountryCodeLabel')}
              value={settings.mccCountryISOId}
              onChange={(e) => {
                const value = e.target.value;
                const { mcc } =
                  MOBILE_COUNTRY_CODES.find(({ id }) => id === value) ?? {};
                onValueChange({
                  index,
                  customUpdateObject: {
                    mccCountryISOId: value,
                    mobileCountryCode: mcc,
                    mobileNetworkCode: '',
                  },
                });
              }}
              options={mobileCountryCodeOptions}
              showEmptyOption
            />

            {settings.mccCountryISOId ? (
              <>
                <SelectField
                  title={I18n.t('profiles.apn.mobileNetworkCodeLabel')}
                  value={settings.mobileNetworkCode}
                  onChange={(e) =>
                    onValueChange({
                      index,
                      key: 'mobileNetworkCode',
                      value: e.target.value,
                    })
                  }
                  options={MOBILE_OPERATORS[`${settings.mccCountryISOId}`]?.map(
                    ({ mnc, network }) => ({
                      value: mnc,
                      title: `${network} (${mnc})`,
                    })
                  )}
                  showEmptyOption
                />
              </>
            ) : null}

            <SelectField
              title={I18n.t('profiles.apn.accessPointTypeLabel')}
              value={settings.accessPointType}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'accessPointType',
                  value: e.target.value,
                })
              }
              options={accessPointTypeOptions}
              showEmptyOption
            />
            <SelectField
              title={I18n.t('profiles.apn.mvnoTypeLabel')}
              value={settings.mvnoType}
              onChange={(e) =>
                onValueChange({ index, key: 'mvnoType', value: e.target.value })
              }
              options={MVNOTypeOptions}
              showEmptyOption
            />
            {/*<InputField*/}
            {/*  label={I18n.t('profiles.apn.mvnoValueLabel')}*/}
            {/*  value={settings.mvnoValue || ''}*/}
            {/*  onChange={(e) =>*/}
            {/*    onValueChange({*/}
            {/*      index,*/}
            {/*      key: 'mvnoValue',*/}
            {/*      value: e.target.value,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  placeholder={I18n.t('profiles.apn.mvnoValueLabel')}*/}
            {/*/>*/}
            {/*<SwitchField*/}
            {/*  onClick={(e) =>*/}
            {/*    onValueChange({*/}
            {/*      index,*/}
            {/*      key: 'isDefaultNetwork',*/}
            {/*      isValueBoolean: true,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  on={settings.isDefaultNetwork || false}*/}
            {/*  title={I18n.t('profiles.apn.defaultNetworkLabel')}*/}
            {/*/>*/}
            <InputField
              label={I18n.t('profiles.apn.accessPointConnectionUsernameLabel')}
              value={settings.accessPointConnectionUsername || ''}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'accessPointConnectionUsername',
                  value: e.target.value,
                })
              }
              placeholder={I18n.t(
                'profiles.apn.accessPointConnectionUsernameLabel'
              )}
            />
            <InputField
              label={I18n.t('profiles.apn.accessPointConnectionPasswordLabel')}
              value={settings.accessPointConnectionPassword || ''}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'accessPointConnectionPassword',
                  value: e.target.value,
                })
              }
              placeholder={I18n.t(
                'profiles.apn.accessPointConnectionPasswordLabel'
              )}
            />
            {/*<InputField*/}
            {/*  label={I18n.t(*/}
            {/*    'profiles.apn.accessPointConnectionHostAddressLabel'*/}
            {/*  )}*/}
            {/*  value={settings.accessPointConnectionHostAddress || ''}*/}
            {/*  onChange={(e) =>*/}
            {/*    onValueChange({*/}
            {/*      index,*/}
            {/*      key: 'accessPointConnectionHostAddress',*/}
            {/*      value: e.target.value,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  placeholder={I18n.t(*/}
            {/*    'profiles.apn.accessPointConnectionHostAddressLabel'*/}
            {/*  )}*/}
            {/*/>*/}
            {/*<InputField*/}
            {/*  label={I18n.t('profiles.apn.accessPointConnectionHostPortLabel')}*/}
            {/*  value={settings.accessPointConnectionHostPort || ''}*/}
            {/*  onChange={(e) =>*/}
            {/*    onValueChange({*/}
            {/*      index,*/}
            {/*      key: 'accessPointConnectionHostPort',*/}
            {/*      value: e.target.value,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  placeholder={I18n.t(*/}
            {/*    'profiles.apn.accessPointConnectionHostPortLabel'*/}
            {/*  )}*/}
            {/*/>*/}
            <InputField
              label={I18n.t('profiles.apn.proxyHostAddressLabel')}
              value={settings.proxyHostAddress || ''}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'proxyHostAddress',
                  value: e.target.value,
                })
              }
              placeholder={I18n.t('profiles.apn.proxyHostAddressLabel')}
            />
            <InputField
              label={I18n.t('profiles.apn.proxyHostPortLabel')}
              value={settings.proxyHostPort || ''}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'proxyHostPort',
                  value: e.target.value,
                })
              }
              placeholder={I18n.t('profiles.apn.proxyHostPortLabel')}
            />
            <InputField
              label={I18n.t('profiles.apn.mmsProxyHostAddressLabel')}
              value={settings.mmsProxyHostAddress || ''}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'mmsProxyHostAddress',
                  value: e.target.value,
                })
              }
              placeholder={I18n.t('profiles.apn.mmsProxyHostAddressLabel')}
            />
            <InputField
              label={I18n.t('profiles.apn.mmsHostAddressLabel')}
              value={settings.mmsHostAddress || ''}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'mmsHostAddress',
                  value: e.target.value,
                })
              }
              placeholder={I18n.t('profiles.apn.mmsHostAddressLabel')}
            />
            <InputField
              label={I18n.t('profiles.apn.mmsHostPortLabel')}
              value={settings.mmsHostPort || ''}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'mmsHostPort',
                  value: e.target.value,
                })
              }
              placeholder={I18n.t('profiles.apn.mmsHostPortLabel')}
            />
            <SelectField
              title={I18n.t('profiles.apn.authenticationTypeLabel')}
              value={settings.authenticationType}
              onChange={(e) =>
                onValueChange({
                  index,
                  key: 'authenticationType',
                  value: e.target.value,
                })
              }
              options={authenticationTypeOptions}
            />
          </>
        ) : null}
      </>
    );
  };

  const renderContent = () =>
    notEmpty(policy)
      ? policy.map(renderSettings)
      : renderSettings(
          {
            ...initialPolicy,
            settings: {
              ...initialSettings,
            },
          },
          0
        );

  const renderAPNSettingsReadOnly = (apn, index) => {
    const {
      settings: {
        apnDisplayName = '',
        apnName = '',
        mccCountryISOId = '',
        mobileNetworkCode = '',
        accessPointType = '',
        mvnoType = '',
        mvnoValue = '',
        isDefaultNetwork = false,
        accessPointConnectionUsername = '',
        accessPointConnectionPassword = '',
        accessPointConnectionHostAddress = '',
        accessPointConnectionHostPort = '',
        proxyHostAddress = '',
        proxyHostPort = '',
        mmsProxyHostAddress = '',
        mmsHostAddress = '',
        mmsHostPort = '',
        authenticationType = '',
      } = {},
    } = apn;
    const { title: accessPointTypeTitle } = getObjectByValue(
      accessPointTypeOptions,
      accessPointType
    );
    const { title: mvnoTypeTitle } = getObjectByValue(
      MVNOTypeOptions,
      mvnoType
    );
    const { title: authenticationTypeTitle } = getObjectByValue(
      authenticationTypeOptions,
      authenticationType
    );
    const { title: mobileCountryCodeTitle } = getObjectByValue(
      mobileCountryCodeOptions,
      mccCountryISOId
    );
    const { network, mnc } = getObjectByValue(
      MOBILE_OPERATORS[mccCountryISOId] ?? [],
      mobileNetworkCode,
      'mnc'
    );
    const mobileNetworkCodeTitle = `${network} (${mnc})`;
    return (
      <ReadOnlyForm
        key={`read-only-form-${index}`}
        items={[
          {
            title: I18n.t('profiles.apn.apnDisplayNameLabel'),
            value: apnDisplayName,
            type: AttributeType.TEXT,
            key: 'apnDisplayName',
          },
          {
            title: I18n.t('profiles.apn.apnNameLabel'),
            value: apnName,
            type: AttributeType.TEXT,
            key: 'apnName',
          },
          {
            title: I18n.t('profiles.apn.mobileCountryCodeLabel'),
            value: mobileCountryCodeTitle,
            type: AttributeType.TEXT,
            key: 'mobileCountryCode',
          },
          {
            title: I18n.t('profiles.apn.mobileNetworkCodeLabel'),
            value: mobileNetworkCodeTitle,
            type: AttributeType.TEXT,
            key: 'mobileNetworkCode',
          },
          {
            title: I18n.t('profiles.apn.accessPointTypeLabel'),
            value: accessPointTypeTitle,
            type: AttributeType.TEXT,
            key: 'accessPointType',
          },
          {
            title: I18n.t('profiles.apn.mvnoTypeLabel'),
            value: mvnoTypeTitle,
            type: AttributeType.TEXT,
            key: 'mvnoType',
          },
          // {
          //   title: I18n.t('profiles.apn.mvnoValueLabel'),
          //   value: mvnoValue,
          //   type: AttributeType.TEXT,
          //   key: 'mvnoValue',
          // },
          // {
          //   title: I18n.t('profiles.apn.defaultNetworkLabel'),
          //   value: isDefaultNetwork,
          //   type: AttributeType.BOOLEAN,
          //   key: 'isDefaultNetwork',
          // },
          {
            title: I18n.t('profiles.apn.accessPointConnectionUsernameLabel'),
            value: accessPointConnectionUsername,
            type: AttributeType.TEXT,
            key: 'accessPointConnectionUsername',
          },
          {
            title: I18n.t('profiles.apn.accessPointConnectionPasswordLabel'),
            value: accessPointConnectionPassword,
            type: AttributeType.PASSWORD,
            key: 'accessPointConnectionPassword',
          },
          // {
          //   title: I18n.t('profiles.apn.accessPointConnectionHostAddressLabel'),
          //   value: accessPointConnectionHostAddress,
          //   type: AttributeType.TEXT,
          //   key: 'accessPointConnectionHostAddress',
          // },
          // {
          //   title: I18n.t('profiles.apn.accessPointConnectionHostPortLabel'),
          //   value: accessPointConnectionHostPort,
          //   type: AttributeType.TEXT,
          //   key: 'accessPointConnectionHostPort',
          // },
          {
            title: I18n.t('profiles.apn.proxyHostAddressLabel'),
            value: proxyHostAddress,
            type: AttributeType.TEXT,
            key: 'proxyHostAddress',
          },
          {
            title: I18n.t('profiles.apn.proxyHostPortLabel'),
            value: proxyHostPort,
            type: AttributeType.TEXT,
            key: 'proxyHostPort',
          },
          {
            title: I18n.t('profiles.apn.mmsProxyHostAddressLabel'),
            value: mmsProxyHostAddress,
            type: AttributeType.TEXT,
            key: 'mmsProxyHostAddress',
          },
          {
            title: I18n.t('profiles.apn.mmsHostAddressLabel'),
            value: mmsHostAddress,
            type: AttributeType.TEXT,
            key: 'mmsHostAddress',
          },
          {
            title: I18n.t('profiles.apn.mmsHostPortLabel'),
            value: mmsHostPort,
            type: AttributeType.TEXT,
            key: 'mmsHostPort',
          },
          {
            title: I18n.t('profiles.apn.authenticationTypeLabel'),
            value: authenticationTypeTitle,
            type: AttributeType.TEXT,
            key: 'authenticationType',
          },
        ]}
      />
    );
  };

  const renderReadOnlyContent = () => {
    if (isEmpty(policy)) {
      return <p>{I18n.t('profiles.apn.emptyPolicyListMessage')}</p>;
    }

    return (
      <>
        <ReadOnlyForm
          headerTitle={I18n.t('profiles.apn.policyTitle')}
          items={[
            {
              title: I18n.t('profiles.apn.policyStatusTitle'),
              informationIconText: I18n.t(
                'profiles.apn.apnSettingsAvailability'
              ),
              value: isPolicyActive,
              type: AttributeType.BOOLEAN,
              key: 'isPolicyActive',
            },
          ]}
        />
        {policy.map(renderAPNSettingsReadOnly)}
      </>
    );
  };

  return (
    <section className="profile-content-wrapper--half-width">
      {isEditingMode ? renderContent() : renderReadOnlyContent()}
    </section>
  );
};

export default APN;
