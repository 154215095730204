import { I18n } from 'react-redux-i18n';

export const USER_SETTINGS = {
  requirePasswordChangeOnNextLogin: {
    id: 'requirePasswordChangeOnNextLogin',
    title: I18n.t('users.fields.requirePasswordChangeOnNextLogin'),
    on: false,
  },
  disablePasswordChange: {
    id: 'disablePasswordChange',
    title: I18n.t('users.fields.disablePasswordChange'),
    on: false,
  },
  disableEmailAddressChange: {
    id: 'disableEmailAddressChange',
    title: I18n.t('users.fields.disableEmailAddressChange'),
    on: false,
  },
  disableAccount: {
    id: 'disableAccount',
    title: I18n.t('users.fields.disableAccount'),
    on: false,
  },
};
