import React from 'react';
import PageContainer from '../../components/PageContainer/PageContainer.component';
import Card from '../../components/Card/Card.component';
import SingleCard from '../../components/Card/SingleCard.component';
import { SectionDescription } from '../../components/sectionDescription.component';
import moment from 'moment';
import DataTable from '../../components/DataTable/DataTable.component';
import { AttributeType } from '../../common/constants';
import { I18n } from 'react-redux-i18n';

const response = {
  startDate: '2022-01-25',
  endDate: '2022-01-30',
  usage: [
    [100, 900],
    [200, 700],
    [50, 650],
    [150, 500],
    [300, 200],
  ],
};

const usageData = response.usage.map(([consumed, remainder], index) => ({
  date: moment(response.startDate).add(index, 'days').format(),
  consumed,
  remainder,
}));

const ApiUsageHistory = () => {
  return (
    <PageContainer>
      <Card>
        <SingleCard>
          <DataTable
            headerTitle={
              <SectionDescription title={I18n.t('api.usageTabTitle')} />
            }
            allColumns={[
              {
                key: 'date',
                type: AttributeType.DATE,
                titleCode: 'api.usageDateColumnTitle',
                custom: {
                  dateFormat: 'Do MMMM YYYY',
                },
              },
              {
                key: 'consumed',
                type: AttributeType.TEXT,
                titleCode: 'api.usageConsumedColumnTitle',
              },
              {
                key: 'remainder',
                type: AttributeType.TEXT,
                titleCode: 'api.usageRemainderColumnTitle',
              },
            ]}
            data={usageData}
            pageId="API_USAGE"
            useFilter
            useCSVExport
            useResizableColumns
            useFlex
            useDateRangePicker
            onDateRangeChange={() => {}}
          />
        </SingleCard>
      </Card>
    </PageContainer>
  );
};

export default ApiUsageHistory;
