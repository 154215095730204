import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MapView from '../features/mapView/mapView.component';
import NotFound from '../components/NotFound';
// TODO: move into route sublayer
import { HomeRoutes, RootRoutes } from '../utils/routes';
import Dashboard from '../components/Dashboard';

const Home = (props) => {
  const routes = [
    <Redirect
      exact
      from={RootRoutes.HOME}
      to={`${RootRoutes.HOME}${HomeRoutes.DASHBOARD}`}
    />,
    <Route
      exact
      path={`${RootRoutes.HOME}${HomeRoutes.DASHBOARD}`}
      component={Dashboard}
    />,
  ];
  if (props.hasAdminPrivileges) {
    routes.push(
      <Route
        exact
        path={`${RootRoutes.HOME}${HomeRoutes.MAP}`}
        component={MapView}
      />
    );
  }
  return (
    <Switch>
      {routes}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Home;
