// sources:
// https://en.wikipedia.org/wiki/Mobile_country_code
// https://github.com/pbakondy/mcc-mnc-list

export const MOBILE_COUNTRY_CODES = [
  {
    country: 'Abkhazia',
    flag: '🏴󠁧󠁥󠁡󠁢󠁿',
    id: '_289ABKHAZIA',
    mcc: '289',
  },
  {
    country: 'Afghanistan',
    flag: '🇦🇫',
    id: '_412AF',
    mcc: '412',
  },
  {
    country: 'Albania',
    flag: '🇦🇱',
    id: '_276AL',
    mcc: '276',
  },
  {
    country: 'Algeria',
    flag: '🇩🇿',
    id: '_603DZ',
    mcc: '603',
  },
  {
    country: 'American Samoa',
    flag: '🇦🇸',
    id: '_544AS',
    mcc: '544',
  },
  {
    country: 'Andorra',
    flag: '🇦🇩',
    id: '_213AD',
    mcc: '213',
  },
  {
    country: 'Angola',
    flag: '🇦🇴',
    id: '_631AO',
    mcc: '631',
  },
  {
    country: 'Anguilla',
    flag: '🇦🇮',
    id: '_365AI',
    mcc: '365',
  },
  {
    country: 'Antigua and Barbuda',
    flag: '🇦🇬',
    id: '_344AG',
    mcc: '344',
  },
  {
    country: 'Argentina Republic',
    flag: '🇦🇷',
    id: '_722AR',
    mcc: '722',
  },
  {
    country: 'Armenia',
    flag: '🇦🇲',
    id: '_283AM',
    mcc: '283',
  },
  {
    country: 'Aruba',
    flag: '🇦🇼',
    id: '_363AW',
    mcc: '363',
  },
  {
    country: 'Australia',
    flag: '🇦🇺',
    id: '_505AU',
    mcc: '505',
  },
  {
    country: 'Austria',
    flag: '🇦🇹',
    id: '_232AT',
    mcc: '232',
  },
  {
    country: 'Azerbaijan',
    flag: '🇦🇿',
    id: '_400AZ',
    mcc: '400',
  },
  {
    country: 'Bahamas',
    flag: '🇧🇸',
    id: '_364BS',
    mcc: '364',
  },
  {
    country: 'Bahrain',
    flag: '🇧🇭',
    id: '_426BH',
    mcc: '426',
  },
  {
    country: 'Bangladesh',
    flag: '🇧🇩',
    id: '_470BD',
    mcc: '470',
  },
  {
    country: 'Barbados',
    flag: '🇧🇧',
    id: '_342BB',
    mcc: '342',
  },
  {
    country: 'Belarus',
    flag: '🇧🇾',
    id: '_257BY',
    mcc: '257',
  },
  {
    country: 'Belgium',
    flag: '🇧🇪',
    id: '_206BE',
    mcc: '206',
  },
  {
    country: 'Belize',
    flag: '🇧🇿',
    id: '_702BZ',
    mcc: '702',
  },
  {
    country: 'Benin',
    flag: '🇧🇯',
    id: '_616BJ',
    mcc: '616',
  },
  {
    country: 'Bermuda',
    flag: '🇧🇲',
    id: '_350BM',
    mcc: '350',
  },
  {
    country: 'Bhutan',
    flag: '🇧🇹',
    id: '_402BT',
    mcc: '402',
  },
  {
    country: 'Bolivia',
    flag: '🇧🇴',
    id: '_736BO',
    mcc: '736',
  },
  {
    country: 'Bosnia &amp; Herzegov.',
    flag: '🇧🇦',
    id: '_218BA',
    mcc: '218',
  },
  {
    country: 'Botswana',
    flag: '🇧🇼',
    id: '_652BW',
    mcc: '652',
  },
  {
    country: 'Brazil',
    flag: '🇧🇷',
    id: '_724BR',
    mcc: '724',
  },
  {
    country: 'Brunei Darussalam',
    flag: '🇧🇳',
    id: '_528BN',
    mcc: '528',
  },
  {
    country: 'Bulgaria',
    flag: '🇧🇬',
    id: '_284BG',
    mcc: '284',
  },
  {
    country: 'Burkina Faso',
    flag: '🇧🇫',
    id: '_613BF',
    mcc: '613',
  },
  {
    country: 'Burundi',
    flag: '🇧🇮',
    id: '_642BI',
    mcc: '642',
  },
  {
    country: 'Cambodia',
    flag: '🇰🇭',
    id: '_456KH',
    mcc: '456',
  },
  {
    country: 'Cameroon',
    flag: '🇨🇲',
    id: '_624CM',
    mcc: '624',
  },
  {
    country: 'Canada',
    flag: '🇨🇦',
    id: '_302CA',
    mcc: '302',
  },
  {
    country: 'Cape Verde',
    flag: '🇨🇻',
    id: '_625CV',
    mcc: '625',
  },
  {
    country: 'Cayman Islands',
    flag: '🇰🇾',
    id: '_346KY',
    mcc: '346',
  },
  {
    country: 'Central African Rep.',
    flag: '🇨🇫',
    id: '_623CF',
    mcc: '623',
  },
  {
    country: 'Chad',
    flag: '🇹🇩',
    id: '_622TD',
    mcc: '622',
  },
  {
    country: 'Chile',
    flag: '🇨🇱',
    id: '_730CL',
    mcc: '730',
  },
  {
    country: 'China',
    flag: '🇨🇳',
    id: '_460CN',
    mcc: '460',
  },
  {
    country: 'Colombia',
    flag: '🇨🇴',
    id: '_732CO',
    mcc: '732',
  },
  {
    country: 'Comoros',
    flag: '🇰🇲',
    id: '_654KM',
    mcc: '654',
  },
  {
    country: 'Congo, Republic',
    flag: '🇨🇬',
    id: '_629CG',
    mcc: '629',
  },
  {
    country: 'Congo, Dem. Rep.',
    flag: '🇨🇩',
    id: '_630CD',
    mcc: '630',
  },
  {
    country: 'Cook Islands',
    flag: '🇨🇰',
    id: '_548CK',
    mcc: '548',
  },
  {
    country: 'Costa Rica',
    flag: '🇨🇷',
    id: '_712CR',
    mcc: '712',
  },
  {
    country: 'Ivory Coast',
    flag: '🇨🇮',
    id: '_612CI',
    mcc: '612',
  },
  {
    country: 'Croatia',
    flag: '🇭🇷',
    id: '_219HR',
    mcc: '219',
  },
  {
    country: 'Cuba',
    flag: '🇨🇺',
    id: '_368CU',
    mcc: '368',
  },
  {
    country: 'Cyprus',
    flag: '🇨🇾',
    id: '_280CY',
    mcc: '280',
  },
  {
    country: 'Czech Rep.',
    flag: '🇨🇿',
    id: '_230CZ',
    mcc: '230',
  },
  {
    country: 'Curacao',
    flag: '🇨🇼',
    id: '_362CW',
    mcc: '362',
  },
  {
    country: 'Denmark',
    flag: '🇩🇰',
    id: '_238DK',
    mcc: '238',
  },
  {
    country: 'Djibouti',
    flag: '🇩🇯',
    id: '_638DJ',
    mcc: '638',
  },
  {
    country: 'Dominica',
    flag: '🇩🇲',
    id: '_366DM',
    mcc: '366',
  },
  {
    country: 'Dominican Republic',
    flag: '🇩🇴',
    id: '_370DO',
    mcc: '370',
  },
  {
    country: 'Ecuador',
    flag: '🇪🇨',
    id: '_740EC',
    mcc: '740',
  },
  {
    country: 'Egypt',
    flag: '🇪🇬',
    id: '_602EG',
    mcc: '602',
  },
  {
    country: 'El Salvador',
    flag: '🇸🇻',
    id: '_706SV',
    mcc: '706',
  },
  {
    country: 'Equatorial Guinea',
    flag: '🇬🇶',
    id: '_627GQ',
    mcc: '627',
  },
  {
    country: 'Eritrea',
    flag: '🇪🇷',
    id: '_657ER',
    mcc: '657',
  },
  {
    country: 'Estonia',
    flag: '🇪🇪',
    id: '_248EE',
    mcc: '248',
  },
  {
    country: 'Ethiopia',
    flag: '🇪🇹',
    id: '_636ET',
    mcc: '636',
  },
  {
    country: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    id: '_750FK',
    mcc: '750',
  },
  {
    country: 'Faroe Islands',
    flag: '🇫🇴',
    id: '_288FO',
    mcc: '288',
  },
  {
    country: 'Fiji',
    flag: '🇫🇯',
    id: '_542FJ',
    mcc: '542',
  },
  {
    country: 'Finland',
    flag: '🇫🇮',
    id: '_244FI',
    mcc: '244',
  },
  {
    country: 'France',
    flag: '🇫🇷',
    id: '_208FR',
    mcc: '208',
  },
  {
    country: 'French Guiana',
    flag: '🇬🇫',
    id: '_340GF',
    mcc: '340',
  },
  {
    country: 'French Polynesia',
    flag: '🇵🇫',
    id: '_547PF',
    mcc: '547',
  },
  {
    country: 'Gabon',
    flag: '🇬🇦',
    id: '_628GA',
    mcc: '628',
  },
  {
    country: 'Gambia',
    flag: '🇬🇲',
    id: '_607GM',
    mcc: '607',
  },
  {
    country: 'Georgia',
    flag: '🇬🇪',
    id: '_282GE',
    mcc: '282',
  },
  {
    country: 'Germany',
    flag: '🇩🇪',
    id: '_262DE',
    mcc: '262',
  },
  {
    country: 'Ghana',
    flag: '🇬🇭',
    id: '_620GH',
    mcc: '620',
  },
  {
    country: 'Gibraltar',
    flag: '🇬🇮',
    id: '_266GI',
    mcc: '266',
  },
  {
    country: 'Greece',
    flag: '🇬🇷',
    id: '_202GR',
    mcc: '202',
  },
  {
    country: 'Greenland',
    flag: '🇬🇱',
    id: '_290GL',
    mcc: '290',
  },
  {
    country: 'Grenada',
    flag: '🇬🇩',
    id: '_352GD',
    mcc: '352',
  },
  {
    country: 'Guadeloupe',
    flag: '🇬🇵',
    id: '_340GP',
    mcc: '340',
  },
  {
    country: 'Guam',
    flag: '🇬🇺',
    id: '_310GU',
    mcc: '310',
  },
  {
    country: 'Guam',
    flag: '🇬🇺',
    id: '_311GU',
    mcc: '311',
  },
  {
    country: 'Guatemala',
    flag: '🇬🇹',
    id: '_704GT',
    mcc: '704',
  },
  {
    country: 'Guernsey',
    flag: '🇬🇬',
    id: '_234GG',
    mcc: '234',
  },
  {
    country: 'Guinea',
    flag: '🇬🇳',
    id: '_611GN',
    mcc: '611',
  },
  {
    country: 'Guinea-Bissau',
    flag: '🇬🇼',
    id: '_632GW',
    mcc: '632',
  },
  {
    country: 'Guyana',
    flag: '🇬🇾',
    id: '_738GY',
    mcc: '738',
  },
  {
    country: 'Haiti',
    flag: '🇭🇹',
    id: '_372HT',
    mcc: '372',
  },
  {
    country: 'Honduras',
    flag: '🇭🇳',
    id: '_708HN',
    mcc: '708',
  },
  {
    country: 'Hongkong, China',
    flag: '🇭🇰',
    id: '_454HK',
    mcc: '454',
  },
  {
    country: 'Hungary',
    flag: '🇭🇺',
    id: '_216HU',
    mcc: '216',
  },
  {
    country: 'Iceland',
    flag: '🇮🇸',
    id: '_274IS',
    mcc: '274',
  },
  {
    country: 'India',
    flag: '🇮🇳',
    id: '_404IN',
    mcc: '404',
  },
  {
    country: 'India',
    flag: '🇮🇳',
    id: '_405IN',
    mcc: '405',
  },
  {
    country: 'Indonesia',
    flag: '🇮🇩',
    id: '_510ID',
    mcc: '510',
  },
  {
    country: 'Iran ',
    flag: '🇮🇷',
    id: '_432IR',
    mcc: '432',
  },
  {
    country: 'Iraq',
    flag: '🇮🇶',
    id: '_418IQ',
    mcc: '418',
  },
  {
    country: 'Ireland',
    flag: '🇮🇪',
    id: '_272IE',
    mcc: '272',
  },
  {
    country: 'Isle of Man',
    flag: '🇮🇲',
    id: '_234IM',
    mcc: '234',
  },
  {
    country: 'Israel',
    flag: '🇮🇱',
    id: '_425IL',
    mcc: '425',
  },
  {
    country: 'Italy',
    flag: '🇮🇹',
    id: '_222IT',
    mcc: '222',
  },
  {
    country: 'Jamaica',
    flag: '🇯🇲',
    id: '_338JM',
    mcc: '338',
  },
  {
    country: 'Japan',
    flag: '🇯🇵',
    id: '_440JP',
    mcc: '440',
  },
  {
    country: 'Japan',
    flag: '🇯🇵',
    id: '_441JP',
    mcc: '441',
  },
  {
    country: 'Jersey',
    flag: '🇯🇪',
    id: '_234JE',
    mcc: '234',
  },
  {
    country: 'Jordan',
    flag: '🇯🇴',
    id: '_416JO',
    mcc: '416',
  },
  {
    country: 'Kazakhstan',
    flag: '🇰🇿',
    id: '_401KZ',
    mcc: '401',
  },
  {
    country: 'Kenya',
    flag: '🇰🇪',
    id: '_639KE',
    mcc: '639',
  },
  {
    country: 'Kiribati',
    flag: '🇰🇮',
    id: '_545KI',
    mcc: '545',
  },
  {
    country: "Korea N., Dem. People's Rep.",
    flag: '🇰🇵',
    id: '_467KP',
    mcc: '467',
  },
  {
    country: 'Korea S, Republic of',
    flag: '🇰🇷',
    id: '_450KR',
    mcc: '450',
  },
  {
    country: 'Kosovo',
    flag: '🇽🇰',
    id: '_221XK',
    mcc: '221',
  },
  {
    country: 'Kuwait',
    flag: '🇰🇼',
    id: '_419KW',
    mcc: '419',
  },
  {
    country: 'Kyrgyzstan',
    flag: '🇰🇬',
    id: '_437KG',
    mcc: '437',
  },
  {
    country: 'Laos P.D.R.',
    flag: '🇱🇦',
    id: '_457LA',
    mcc: '457',
  },
  {
    country: 'Latvia',
    flag: '🇱🇻',
    id: '_247LV',
    mcc: '247',
  },
  {
    country: 'Lebanon',
    flag: '🇱🇧',
    id: '_415LB',
    mcc: '415',
  },
  {
    country: 'Lesotho',
    flag: '🇱🇸',
    id: '_651LS',
    mcc: '651',
  },
  {
    country: 'Liberia',
    flag: '🇱🇷',
    id: '_618LR',
    mcc: '618',
  },
  {
    country: 'Libya',
    flag: '🇱🇾',
    id: '_606LY',
    mcc: '606',
  },
  {
    country: 'Liechtenstein',
    flag: '🇱🇮',
    id: '_295LI',
    mcc: '295',
  },
  {
    country: 'Lithuania',
    flag: '🇱🇹',
    id: '_246LT',
    mcc: '246',
  },
  {
    country: 'Luxembourg',
    flag: '🇱🇺',
    id: '_270LU',
    mcc: '270',
  },
  {
    country: 'Macao, China',
    flag: '🇲🇴',
    id: '_455MO',
    mcc: '455',
  },
  {
    country: 'Macedonia',
    flag: '🇲🇰',
    id: '_294MK',
    mcc: '294',
  },
  {
    country: 'Madagascar',
    flag: '🇲🇬',
    id: '_646MG',
    mcc: '646',
  },
  {
    country: 'Malawi',
    flag: '🇲🇼',
    id: '_650MW',
    mcc: '650',
  },
  {
    country: 'Malaysia',
    flag: '🇲🇾',
    id: '_502MY',
    mcc: '502',
  },
  {
    country: 'Maldives',
    flag: '🇲🇻',
    id: '_472MV',
    mcc: '472',
  },
  {
    country: 'Mali',
    flag: '🇲🇱',
    id: '_610ML',
    mcc: '610',
  },
  {
    country: 'Malta',
    flag: '🇲🇹',
    id: '_278MT',
    mcc: '278',
  },
  {
    country: 'Martinique',
    flag: '🇲🇶',
    id: '_340MQ',
    mcc: '340',
  },
  {
    country: 'Mauritania',
    flag: '🇲🇷',
    id: '_609MR',
    mcc: '609',
  },
  {
    country: 'Mauritius',
    flag: '🇲🇺',
    id: '_617MU',
    mcc: '617',
  },
  {
    country: 'Mexico',
    flag: '🇲🇽',
    id: '_334MX',
    mcc: '334',
  },
  {
    country: 'Micronesia',
    flag: '🇫🇲',
    id: '_550FM',
    mcc: '550',
  },
  {
    country: 'Moldova',
    flag: '🇲🇩',
    id: '_259MD',
    mcc: '259',
  },
  {
    country: 'Monaco',
    flag: '🇲🇨',
    id: '_212MC',
    mcc: '212',
  },
  {
    country: 'Mongolia',
    flag: '🇲🇳',
    id: '_428MN',
    mcc: '428',
  },
  {
    country: 'Montenegro',
    flag: '🇲🇪',
    id: '_297ME',
    mcc: '297',
  },
  {
    country: 'Montserrat',
    flag: '🇲🇸',
    id: '_354MS',
    mcc: '354',
  },
  {
    country: 'Morocco',
    flag: '🇲🇦',
    id: '_604MA',
    mcc: '604',
  },
  {
    country: 'Mozambique',
    flag: '🇲🇿',
    id: '_643MZ',
    mcc: '643',
  },
  {
    country: 'Myanmar (Burma)',
    flag: '🇲🇲',
    id: '_414MM',
    mcc: '414',
  },
  {
    country: 'Namibia',
    flag: '🇳🇦',
    id: '_649NA',
    mcc: '649',
  },
  {
    country: 'Nepal',
    flag: '🇳🇵',
    id: '_429NP',
    mcc: '429',
  },
  {
    country: 'Netherlands',
    flag: '🇳🇱',
    id: '_204NL',
    mcc: '204',
  },
  {
    country: 'Netherlands Antilles',
    flag: '🇦🇳',
    id: '_362AN',
    mcc: '362',
  },
  {
    country: 'New Caledonia',
    flag: '🇳🇨',
    id: '_546NC',
    mcc: '546',
  },
  {
    country: 'New Zealand',
    flag: '🇳🇿',
    id: '_530NZ',
    mcc: '530',
  },
  {
    country: 'Nicaragua',
    flag: '🇳🇮',
    id: '_710NI',
    mcc: '710',
  },
  {
    country: 'Niger',
    flag: '🇳🇪',
    id: '_614NE',
    mcc: '614',
  },
  {
    country: 'Nigeria',
    flag: '🇳🇬',
    id: '_621NG',
    mcc: '621',
  },
  {
    country: 'Niue',
    flag: '🇳🇺',
    id: '_555NU',
    mcc: '555',
  },
  {
    country: 'Norway',
    flag: '🇳🇴',
    id: '_242NO',
    mcc: '242',
  },
  {
    country: 'Oman',
    flag: '🇴🇲',
    id: '_422OM',
    mcc: '422',
  },
  {
    country: 'Pakistan',
    flag: '🇵🇰',
    id: '_410PK',
    mcc: '410',
  },
  {
    country: 'Palau (Republic of)',
    flag: '🇵🇼',
    id: '_552PW',
    mcc: '552',
  },
  {
    country: 'Palestinian Territory',
    flag: '🇵🇸',
    id: '_425PS',
    mcc: '425',
  },
  {
    country: 'Panama',
    flag: '🇵🇦',
    id: '_714PA',
    mcc: '714',
  },
  {
    country: 'Papua New Guinea',
    flag: '🇵🇬',
    id: '_537PG',
    mcc: '537',
  },
  {
    country: 'Paraguay',
    flag: '🇵🇾',
    id: '_744PY',
    mcc: '744',
  },
  {
    country: 'Peru',
    flag: '🇵🇪',
    id: '_716PE',
    mcc: '716',
  },
  {
    country: 'Philippines',
    flag: '🇵🇭',
    id: '_515PH',
    mcc: '515',
  },
  {
    country: 'Poland',
    flag: '🇵🇱',
    id: '_260PL',
    mcc: '260',
  },
  {
    country: 'Portugal',
    flag: '🇵🇹',
    id: '_268PT',
    mcc: '268',
  },
  {
    country: 'Puerto Rico',
    flag: '🇵🇷',
    id: '_330PR',
    mcc: '330',
  },
  {
    country: 'Qatar',
    flag: '🇶🇦',
    id: '_427QA',
    mcc: '427',
  },
  {
    country: 'Romania',
    flag: '🇷🇴',
    id: '_226RO',
    mcc: '226',
  },
  {
    country: 'Russian Federation',
    flag: '🇷🇺',
    id: '_250RU',
    mcc: '250',
  },
  {
    country: 'Rwanda',
    flag: '🇷🇼',
    id: '_635RW',
    mcc: '635',
  },
  {
    country: 'Reunion',
    flag: '🇷🇪',
    id: '_647RE',
    mcc: '647',
  },
  {
    country: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    id: '_356KN',
    mcc: '356',
  },
  {
    country: 'Saint Lucia',
    flag: '🇱🇨',
    id: '_358LC',
    mcc: '358',
  },
  {
    country: 'Satellite Networks',
    flag: '',
    id: '_901SATELLITE',
    mcc: '901',
  },
  {
    country: 'St. Pierre &amp; Miquelon',
    flag: '🇵🇲',
    id: '_308PM',
    mcc: '308',
  },
  {
    country: 'St. Vincent &amp; Gren.',
    flag: '🇻🇨',
    id: '_360VC',
    mcc: '360',
  },
  {
    country: 'Samoa',
    flag: '🇼🇸',
    id: '_549WS',
    mcc: '549',
  },
  {
    country: 'San Marino',
    flag: '🇸🇲',
    id: '_292SM',
    mcc: '292',
  },
  {
    country: 'Sao Tome &amp; Principe',
    flag: '🇸🇹',
    id: '_626ST',
    mcc: '626',
  },
  {
    country: 'Saudi Arabia',
    flag: '🇸🇦',
    id: '_420SA',
    mcc: '420',
  },
  {
    country: 'Senegal',
    flag: '🇸🇳',
    id: '_608SN',
    mcc: '608',
  },
  {
    country: 'Serbia ',
    flag: '🇷🇸',
    id: '_220RS',
    mcc: '220',
  },
  {
    country: 'Seychelles',
    flag: '🇸🇨',
    id: '_633SC',
    mcc: '633',
  },
  {
    country: 'Sierra Leone',
    flag: '🇸🇱',
    id: '_619SL',
    mcc: '619',
  },
  {
    country: 'Singapore',
    flag: '🇸🇬',
    id: '_525SG',
    mcc: '525',
  },
  {
    country: 'Slovakia',
    flag: '🇸🇰',
    id: '_231SK',
    mcc: '231',
  },
  {
    country: 'Slovenia',
    flag: '🇸🇮',
    id: '_293SI',
    mcc: '293',
  },
  {
    country: 'Solomon Islands',
    flag: '🇸🇧',
    id: '_540SB',
    mcc: '540',
  },
  {
    country: 'Somalia',
    flag: '🇸🇴',
    id: '_637SO',
    mcc: '637',
  },
  {
    country: 'South Africa',
    flag: '🇿🇦',
    id: '_655ZA',
    mcc: '655',
  },
  {
    country: 'South Sudan (Republic of)',
    flag: '🇸🇸',
    id: '_659SS',
    mcc: '659',
  },
  {
    country: 'Spain',
    flag: '🇪🇸',
    id: '_214ES',
    mcc: '214',
  },
  {
    country: 'Sri Lanka',
    flag: '🇱🇰',
    id: '_413LK',
    mcc: '413',
  },
  {
    country: 'Sudan',
    flag: '🇸🇩',
    id: '_634SD',
    mcc: '634',
  },
  {
    country: 'Suriname',
    flag: '🇸🇷',
    id: '_746SR',
    mcc: '746',
  },
  {
    country: 'Swaziland',
    flag: '🇸🇿',
    id: '_653SZ',
    mcc: '653',
  },
  {
    country: 'Sweden',
    flag: '🇸🇪',
    id: '_240SE',
    mcc: '240',
  },
  {
    country: 'Switzerland',
    flag: '🇨🇭',
    id: '_228CH',
    mcc: '228',
  },
  {
    country: 'Syrian Arab Republic',
    flag: '🇸🇾',
    id: '_417SY',
    mcc: '417',
  },
  {
    country: 'Taiwan',
    flag: '🇹🇼',
    id: '_466TW',
    mcc: '466',
  },
  {
    country: 'Tajikistan',
    flag: '🇹🇯',
    id: '_436TJ',
    mcc: '436',
  },
  {
    country: 'Tanzania',
    flag: '🇹🇿',
    id: '_640TZ',
    mcc: '640',
  },
  {
    country: 'Thailand',
    flag: '🇹🇭',
    id: '_520TH',
    mcc: '520',
  },
  {
    country: 'Timor-Leste',
    flag: '🇹🇱',
    id: '_514TL',
    mcc: '514',
  },
  {
    country: 'Togo',
    flag: '🇹🇬',
    id: '_615TG',
    mcc: '615',
  },
  {
    country: 'Tonga',
    flag: '🇹🇴',
    id: '_539TO',
    mcc: '539',
  },
  {
    country: 'Trinidad and Tobago',
    flag: '🇹🇹',
    id: '_374TT',
    mcc: '374',
  },
  {
    country: 'Tunisia',
    flag: '🇹🇳',
    id: '_605TN',
    mcc: '605',
  },
  {
    country: 'Turkey',
    flag: '🇹🇷',
    id: '_286TR',
    mcc: '286',
  },
  {
    country: 'Turkmenistan',
    flag: '🇹🇲',
    id: '_438TM',
    mcc: '438',
  },
  {
    country: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    id: '_376TC',
    mcc: '376',
  },
  {
    country: 'Tuvalu',
    flag: '🇹🇻',
    id: '_553TV',
    mcc: '553',
  },
  {
    country: 'Uganda',
    flag: '🇺🇬',
    id: '_641UG',
    mcc: '641',
  },
  {
    country: 'Ukraine',
    flag: '🇺🇦',
    id: '_255UA',
    mcc: '255',
  },
  {
    country: 'United Arab Emirates',
    flag: '🇦🇪',
    id: '_424AE',
    mcc: '424',
  },
  {
    country: 'United Arab Emirates',
    flag: '🇦🇪',
    id: '_431AE',
    mcc: '431',
  },
  {
    country: 'United Arab Emirates',
    flag: '🇦🇪',
    id: '_430AE',
    mcc: '430',
  },
  {
    country: 'United Kingdom',
    flag: '🇬🇧',
    id: '_234GB',
    mcc: '234',
  },
  {
    country: 'United Kingdom',
    flag: '🇬🇧',
    id: '_235GB',
    mcc: '235',
  },
  {
    country: 'United States',
    flag: '🇺🇸',
    id: '_310US',
    mcc: '310',
  },
  {
    country: 'United States',
    flag: '🇺🇸',
    id: '_312US',
    mcc: '312',
  },
  {
    country: 'United States',
    flag: '🇺🇸',
    id: '_311US',
    mcc: '311',
  },
  {
    country: 'United States',
    flag: '🇺🇸',
    id: '_316US',
    mcc: '316',
  },
  {
    country: 'Uruguay',
    flag: '🇺🇾',
    id: '_748UY',
    mcc: '748',
  },
  {
    country: 'Uzbekistan',
    flag: '🇺🇿',
    id: '_434UZ',
    mcc: '434',
  },
  {
    country: 'Vanuatu',
    flag: '🇻🇺',
    id: '_541VU',
    mcc: '541',
  },
  {
    country: 'Venezuela',
    flag: '🇻🇪',
    id: '_734VE',
    mcc: '734',
  },
  {
    country: 'Vietnam',
    flag: '🇻🇳',
    id: '_452VN',
    mcc: '452',
  },
  {
    country: 'British Virgin Islands',
    flag: '🇻🇬',
    id: '_348VG',
    mcc: '348',
  },
  {
    country: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    id: '_376VI',
    mcc: '376',
  },
  {
    country: 'Yemen',
    flag: '🇾🇪',
    id: '_421YE',
    mcc: '421',
  },
  {
    country: 'Zambia',
    flag: '🇿🇲',
    id: '_645ZM',
    mcc: '645',
  },
  {
    country: 'Zimbabwe',
    flag: '🇿🇼',
    id: '_648ZW',
    mcc: '648',
  },
];
