import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Button } from '../../components/button.component';
import { changeEnrollmentProperty } from '../enrollment/enrollment.actions';
import {
  CreateEnrollmentRoute,
  EnrollmentWizardRoutes,
} from './CreateEnrollment.constants';
import { history } from '../../utils/history';
import FleetsTree from '../fleets/components/fleetsTree.component';
import { companyIdSelector } from '../login/login.selectors';
import { fleetsV2Selector } from '../fleets/fleetsV2.selectors';

class ChooseFleet extends Component {
  render() {
    const { fleets } = this.props;
    const defaultSelectedKeys = this.props.newEnrollment.fleetId
      ? [this.props.newEnrollment.fleetId]
      : [];
    return (
      <div className={'creation-wizard-step-container'}>
        <FleetsTree
          fleets={fleets || []}
          onSelect={this.onChange}
          defaultSelectedKeys={defaultSelectedKeys}
          selectedKeys={defaultSelectedKeys}
        />
        <Button
          secondary
          className={
            'create-enrollment-wizard__control-button create-enrollment-wizard__control-button--first'
          }
          onClick={this.goNext}
          disabled={!this.props.newEnrollment.fleetId}
        >
          {`${I18n.t('common.next')} ⟶`}
        </Button>
      </div>
    );
  }

  goNext = () =>
    history.push(`${CreateEnrollmentRoute}${EnrollmentWizardRoutes.NAMING}`);

  onChange = (fleet) => {
    let name;
    let id;
    if (fleet) {
      name = fleet.name || undefined;
      id = fleet.id || undefined;
    }
    this.props.changeEnrollmentProperty({ key: 'fleetName', value: name });
    this.props.changeEnrollmentProperty({ key: 'fleetId', value: id });
  };
}

const mapStateToProps = (state) => {
  const { data: fleets, status: fleetsStatus } = fleetsV2Selector(state);
  return {
    newEnrollment: state.enrollment.newEnrollment,
    fleets,
    fleetsStatus,
    companyId: companyIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeEnrollmentProperty: ({ key, value }) =>
      dispatch(changeEnrollmentProperty({ key, value })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseFleet);
