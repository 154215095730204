export const zh_ = {
  loginForm: {
    email: '電子郵件地址',
    password: '密碼',
    login: '登錄',
    wait: '請稍等...',
    success: '成功...',
  },
  login: {
    multiTenantProceedButtonText: '登錄',
    multiTenantCancelButtonText: '取消',
    loginErrorTitle: '登錄錯誤',
    multiTenantPopupTitle: '用戶帳口選擇',
    multiTenantPopupText: '您的戶口有多個帳戶。請選擇您要登錄的帳戶。',
    SAMLLoginModalTitle: '使用 SAML 登錄',
    loginWithAnSSOProvideModalText: '使用 SSO 提供商登錄',
    signInWithAnSSOProviderButtonText: '使用 SSO 提供商登錄',
    organizationIDFieldTitle: '組織 ID',
    loginWithSAMLHint: '請輸入您的組織 ID 以使用 SAML 登錄',
  },
  session: {
    sessionExpiryWarningMessage: '由於沒有進一步活動，您的會話即將到期。',
    sessionExpiryWarningTitle: '會話到期警告',
    logoutNow: '立即登出',
    stayLoggedIn: '保持登錄狀態',
    sessionExpiryTitle: '會話到期',
    sessionExpiryMessage: '您的會話已過期。請重新登錄。',
    logoutFailure: '登出失敗。請再試一次。',
  },
  errors: {
    unauthorized: '認證失敗',
    invalidCredentials: '電子郵件和密碼無效',
    serviceUnavailable: '服務不可用',
    loggedOut: '登出',
    invalidPermissionsOrAccessLevel:
      '您沒有執行此操作所需的權限或訪問級別。請聯繫管理員。',
  },
  back: '後退',
  search: '搜索',
  searchDialog: {
    hint: '你要找什麼？',
    resultsInTotal: '結果總數',
    resultsWith: '結果與',
    searchInProgress: '搜索中',
    fleets: 'Fleet',
    apps: '應用',
    devices: '設備',
    profiles: '配置文件',
    enrollments: '注册',
    failedToPerformSearch: '無法執行搜索',
  },
  profileBar: {
    changeAccountSettings: '更改帳戶設置',
    logout: '登出',
    addAProfile: '添加配置文件',
    addAFleet: '添加 Fleet',
    uploadAnApp: '上傳應用程序',
    createEnrollmentMethod: '創建註冊方法',
    help: '幫助',
  },
  notifications: {
    markAllRead: '標記所有已讀',
    removeAll: '移除所有',
    noNewNotifications: '没有新通知',
    markAsRead: '標記為已讀',
    remove: '消除',
    deviceHasLeftTheGeofence: '設備 %{deviceName} 已離開地理圍欄',
    deviceHasEnteredTheGeofence: '設備 %{deviceName} 已進入地理圍欄',
    deviceIsSecurityCompliant: '%{deviceName} 是否符合安全標準',
    deviceIsNotSecurityCompliant: '%{deviceName} 不符合安全標準',
    deviceSecurityComplianceStatusUnknown: '%{deviceName} 安全合規狀態未知',
    deviceHasALowBatteryLevel: '%{deviceName} 電池電量低',
    deviceHasANormalBatteryLevel: '%{deviceName} 電池電量正常',
    deviceHasAnUnknownBatteryLevel: '%{deviceName} 電池電量未知',
  },
  certificates: {
    filename: '證書文件名',
    lastUpdated: '最近更新時間',
    deletionConfirmation: '您確定要刪除選定的證書嗎？',
    removeFiles: '删除文件',
    selectedFiles: '精選證書',
    certificateDeletionError: '刪除證書時出錯',
    certificatesLoadError: '獲取證書時出錯',
    uploadedBy: 'Uploaded By',
    uploadedOn: 'Uploaded On',
  },
  htmlKioskFiles: {
    filename: '文檔名稱',
    lastUpdated: '最近更新時間',
    createdAt: '已創建',
    deletionConfirmation: '您確定要刪除選定的 HTML 文件嗎？',
    removeFiles: '删除文件',
    selectedFiles: '選定的文件',
    uploadedBy: 'Uploaded By',
  },
  attributiveTable: {
    notFound: '未找到',
    noData: '沒有數據',
  },
  dataTable: {
    noMatchingFilterResults: '沒有符合您過濾條件的項目。',
    noData: '沒有數據',
    csvExportIconHint: '將數據導出到 CSV 文件',
  },
  columnsToggle: {
    successfullySavedChanges: '更改已成功保存。',
    failedToSaveChanges: '未能保存數據。',
    unableToGetSavedColumns: '無法獲取此用戶的保存表列。',
  },
  company: {
    failedToLoadInfo: '加載信息失敗',
    failedToSetPeriod: '設置期限失敗',
    failedToLoadCompanySettings: '無法加載公司設置',
  },
  filter: {
    notContainedIn: '不包含在',
    contains: '包含',
    doesNotContain: '不含',
    beginsWith: '開始於',
    doesNotBeginWith: '開始不以',
    endsWith: '以這結束',
    doesNotEndWith: '不以這結束',
    notEqual: '不相等',
    lessThanOrEqual: '小於或等於',
    greaterThanOrEqual: '大於或等於',
    lessThan: '少於',
    greaterThan: '大於',
    equal: '等於',
  },
  installationStatus: {
    successful: '成功',
    relatedApplicationsTitle: '與此任務相關的應用程序',
    pendingInstallationsTitle: '待安裝',
    successfulInstallationsTitle: '成功安裝',
    informationRelatedToTask: '與此任務相關的信息',
    successfulAndPendingInstallationTitle: '成功和待定安裝',
    numberOfSuccessfullyInstalledDevices: '設備安裝成功',
    numberOfPendingInstallations: '待安裝的設備',
    lastUpdated: '最近更新',
    overviewTitleText: '概述',
    allDevicesPendingInstallationOfThisProfileText:
      '等待安裝此配置文件的所有設備',
    allDevicesThatSuccessfullyInstalledThisProfileText:
      '成功安裝此配置文件的所有設備',
    failedToLoadTaskDevices: '加載任務設備失敗',
    failedToLoadTask: '加載任務失敗',
    failedToLoadTasks: '加載任務失敗',
    devicesInstalled: '已安裝的設備',
    devicesFailed: '已安裝的設備',
    status: '狀態',
    taskDate: '任務日期',
    installed: '已安裝',
    pending: '待辦的',
  },
  alerts: {
    title: '警報',
    createAlert: '創建警報',
    alertCreated: '警報已創建',

    chooseAFleet: '選擇 FLEET',
    alertType: '警報類型',
    alertCondition: '警報條件',
    alertChannel: '警報通道',
    previewAndSave: '預覽和保存',
    done: '完畢',

    alertName: '警報名稱',
    alertStatus: '狀態',
    linkedFleets: '已鏈接 FLEETS',
    totalAlerts: '總警報',
    inactive: '不活躍',
    active: '活躍',
    createAnAlert: '創建警報',
    nameThisAlert: '為此警報命名',
    alertDashboard: '警報儀表板',

    battery: '電池',
    outOfContact: '失聯',
    exitGeoFence: '退出地理圍欄',
    enterGeoFence: '進入地理圍欄',

    numberOfLinkedFleets: '%{numberOfLinkedFleets} Fleets',

    on: '開啟',
    off: '關閉',
    chooseFleet: '選擇 Fleet',
    createFleet: '創建 FLEET',
    back: '後退',
    next: '下一個',
    alertTypeHeading: '警報類型',
    basicAlerts: '基本警報',
    setCondition: '設置條件',
    condition: '條件',
    alertWhenDeviceBatteryIs: '設備電池電量不足時發出警報',
    alertWhenDeviceNetworkIs: '設備網絡丟失時發出警報',
    alertWhenDeviceExitsGeofence: '設備退出地理圍欄時發出警報',
    alertWhenDeviceEntersGeofence: '設備進入地理圍欄時發出警報',
    alertWhenDeviceIsOutOfContact: '當設備失去聯繫時發出警報',
    alertWhenDeviceBluetoothIs: '設備藍牙連接時發出警報',
    lessThanOrEqualTo: '小於或等於',
    greaterThanOrEqualTo: '大於或等於',
    setCommunicationChannel: '設置通訊通道',
    singleEmailCommunicationChannel: '單一電子郵件地址',
    singleEmailCommunicationChannelHint: '請每行輸入單一電子郵件地址。',
    userGroupsCommunicationChannel: '用戶組',
    userGroupsCommunicationChannelHint:
      '按住“Control”或在 Mac 上按住“Command”可選擇多個。',
    communicationChannelEmailLabel: '電子郵件',
    communicationChannelEmailPlaceholder: '電子郵件地址',
    communicationChannelGroupLabel: '組',
    communicationChannelGroupPlaceholder: '選擇用戶組',
    emailAlertFrequency: '電子郵件提醒頻率',
    emailAlertFrequencyDescription:
      '重複 %{alertFrequencyRepeatValue} 時間間隔為 %{alertFrequencyHours} 小時。',
    repeat: '重複',
    timesAtAnIntervalOf: '時間間隔為',
    hours: '小時',
    minutes: '分鐘',
    percentage: '百分比',
    emailAlertFrequencyValuesDescription:
      '通知 %{alertFrequencyRepeatValue} 時間間隔為 %{alertFrequencyHours} 小時。',
    alertChannelSettingFootNote: '警報通知最多可重複10次，間隔最長為1個月。',
    alertConditionPreviewDescriptionBattery:
      '設備電池電量不足時發出警報 %{conditionType} %{percentage}%',
    alertConditionPreviewDescriptionOutOfContact:
      '當設備脫離接觸時發出警報 %{conditionType}',
    alertConditionPreviewDescriptionExitsGeofence:
      '當設備退出地理圍欄時發出警報 %{conditionType}',
    alertConditionPreviewDescriptionEntersGeofence:
      '設備進入地理圍欄時發出警報 %{conditionType}',

    edit: '編輯',
    confirm: '確認',
    alertsDeletionConfirmation: '您確定要刪除選定的警報嗎？',
    removeAlerts: '刪除警報',
    alertsSelected: '已選擇警報',

    youAreEditing: '您正在编辑',
    youAreViewing: '您正在查看',

    alertLoadFailureMessage: '無法加載警報。',
    alertUpdateFailureMessage: '無法加載警報。',
    alertsDeletionFailureMessage: '警報刪除失敗',
    alertsLoadFailureMessage: '獲取警報時出錯',
    alertCreationFailureMessage: '警報創建失敗',
  },
  api: {
    title: 'API Manager',
    usageTabTitle: 'API Usage History',
    usageQuotaTitle: 'Quota',
    usageDateColumnTitle: 'Date',
    usageConsumedColumnTitle: 'Consumed',
    usageRemainderColumnTitle: 'Remainder',
    apiKeyCopiedSuccessMessageText: 'API key successfully copied to clipboard.',
    currentPlanTitle: 'Current Plan',
    currentPlanDetails: 'Plan Details',
    starterPlanDetails:
      'This plan allows for %{numberOfAPICalls} API calls every month, included with your license fee. Usage above this quota will require additional fees.',
    documentationTitle: 'Documentation',
    documentationHint: 'Open API documentation in a new tab',
    APIKeyTitle: 'API Key',
    uniqueIDsTitle: 'Device Unique IDs',
    uniqueIDsDownloadButtonText: 'Download Device Unique IDs',
  },
  enrollments: {
    editingTitle: '您正在編輯',
    viewingTitle: '您正在查看',
    fields: {
      status: '狀態',
      statusActive: '活躍',
      statusInactive: '不活躍',
      statusDraft: '草稿',
      createdAt: '創建於',
      lastUpdated: '最近更新',
      name: '註冊名',
    },
    enrollmentCreationButtonText: '創建註冊',
    enrollmentsSelected: '已選擇的註冊',
    removeEnrollments: '刪除註冊',
    enrollmentDeletionConfirmationText: '您確定要刪除選定的註冊嗎？',
    deletingEnrollments: '刪除註冊',
    enrollmentsList: '註冊名單',
    createAnEnrollmentMethod: '創建註冊方法',
    nameThisEnrollmentMethod: '將此註冊方法命名為...',
    chooseAFleet: '選擇 fleet',
    deviceNaming: '設備命名',
    ReviewAndConfirm: '查看並確認',
    done: '完畢',
    loadEnrollmentError: '獲取所選設備註冊時出錯',
    updateEnrollmentError: '更新此設備註冊時出錯',
    updateEnrollmentUniqueNameErrorText: '註冊名稱應該是唯一的',
    createEnrollmentErrorText: '註冊創建錯誤',
    deleteEnrollmentErrorText: '註冊刪除失敗',
    loadEnrollmentsErrorText: '獲取註冊數據時出錯',

    QRCodeEnrollmentIdText: '註冊編號：',
    QRCode: '二維碼',
    selectedFleet: '已選 Fleet',
    selectedProfilesText: '選定的配置文件',
    deviceNamingSectionTitle: '設備命名',
    deviceNamingSectionHint: '為此處註冊的設備設置命名順序',
    selectedProfilesName: '配置文件名',
    selectedProfilesLastUpdated: '最後更新',

    deviceNamingSequence: '序列：',
    deviceNamingSeparator: '分隔:',
    deviceCurrentCount: ' 當前計數:',
    namingExampleHeader: '例子: ',
    startingSerialNumber: '起始序列號:',

    // device naming params
    deviceNamingParamNone: '没有任何',
    deviceNamingParamNumberedSequence: '自動遞增編號序列',
    deviceNamingParamName: '自定義文本',
    deviceNamingParamIMEI: 'IMEI',
    deviceNamingParamMACAddress: 'MAC 地址',
    deviceNamingParamManufacturer: '製造商',
    deviceNamingParamModel: '型號',
    deviceNamingParamDeviceName: '個性化設備名稱',
    deviceNamingParamPhoneNumber: '電話號碼',
    deviceNamingParamPlatform: '平台',
    deviceNamingParamSerialNumber: '序列號',

    // enrollment creation
    allVersionsOptionText: '所有版本',
    prevStepButton: '後退',
    forwardStepButton: '往下',
    exit: '退出',
    edit: '退出',
    confirm: '確認',
    namingSequenceHint: '為此處註冊的設備創建命名序列。',
    enrollmentCreationSuccessTitle: '立即發送此註冊方法。',
    enrollmentCreationSuccessSubTitle: '註冊信息',
    preview: '預覽:',
    notAvailable: '無法使用',
    noQRCodeAvailableText: '沒有可用的二維碼。',
    enrollmentName: '註冊名稱',
    startingFromPrefix: '從...開始',

    // staging
    stagingTabTitle: 'Staging',
    stagingTabHint:
      '要生成暫存二維碼，請選擇配置文件，然後選擇要連接的 WiFi 網絡。請注意，WiFi 字段僅在選擇配置文件後顯示。',
    stagingProfileFieldTitle: '配置文件',
    stagingLocaleFieldTitle: '語言環境',
    stagingTimezoneFieldTitle: '時區',
    stagingWiFiNetworkFieldTitle: 'WiFi 網絡',
    stagingSkipDeviceEncryptionFieldTitle: '跳過設備加密',
    stagingLeaveAllSystemAppsEnabledFieldTitle: '啟用所有系統應用程序',
    stagingUseMobileDataFieldTitle: '使用移動數據',
    stagingUseManualWifiSettingsFieldTitle: '使用手動 Wifi 設置',
    stagingManualWifiSSIDFieldTitle: 'WiFi SSID',
    stagingManualWifiPasswordFieldTitle: 'Wifi 密碼',
    stagingManualWiFiTypeFieldTitle: 'Wifi 類型',
    stagingManualHiddenNetworkFieldTitle: '隱藏網絡',
    stagingQRCodeGenerationButtonText: '生成二維碼',
    noStagingConfigurationsMessage: '當前沒有與此註冊關聯的暫存配置。',
  },
  routes: {
    myFleets: '我的 Fleets',
    myFleetsV2: '我的 Fleets',
    enrollments: '註冊',
    allDevices: '所有設備',
    myApplications: '我的應用程序',
    upload: 'Add',
    myFiles: '我的文件',
    myCertificates: '我的證書',
    myHTMLKioskFiles: '我的 HTML Kiosk 文件',
    allProfiles: '所有配置文件 V1',
    allProfilesV2: '所有配置文件',
    tasks: '安裝狀態',
    accountSettings: '帳戶',
    commonSettings: '常用設置',
    companyInfo: '公司信息',
    SSO: 'SSO',
    preferences: 'Preferences',
    dashboard: '儀錶盤',
    mapView: '視圖',
    usersAll: '管理',
    usersLogs: '活動場記',
    alerts: '警報',
    apiOverviewTitle: 'Overview',
    apiUsageHistoryTitle: 'Usage History',
  },
  statistics: {
    numberNewDevices: `%{number} 新設備`,
    numberDevices: `%{number} 設備`,
    numberTasks: `%{number} 任務`,
    enrolledDevices: '已註冊設備',
    inLowBattery: '低電量',
    online: '在線的',
    recentlyActive: '最近活躍',
    quickDetails: '快速詳情',
    onlineDevicesPast: `過去最近活躍的設備 `,
    lastDay: '24小時',
    week: '星期',
    month: '月',
    last: '最後的',
    installing: '正在安裝',
    enrolling: '正在註冊',
    lowBattery: '低電量',
    failedEnrollments: '註冊失敗',
    nonCompliantDevices: '不合規設備',
    androidDevices: 'Android 設備',
    linuxDevices: 'Linux 設備',
    lastConnectDates: `上次連接日期（所有時間）`,
    manufacturer: '製造商',
    connections: '連接',
    devices: '設備',
    deviceFamilyOSVersion: '設備系列操作系統版本',
    failedToLoadStatistics: '無法加載統計信息',
  },
  device: {
    title: '設備',
    deviceInfo: '設備信息',
    deviceInfoHint: '與此設備相關的信息',
    actions: '行動',
    overview: '概述',
    applications: '應用程序',
    profilesAssigned: '分配的配置文件',
    activityLogs: '場記',
    location: '地點',
    updateHistory: '更新',
    updateRequestConfirmationText: 'Update request sent.',
    noHistoryLogEntries: '當前沒有位置日誌條目。',
    noHistoryLogEntriesForRange: '在所選擇日期範圍設置內並沒有位置日誌記錄。',
    locationHistory: '位置記錄',
    currentLocation: '當前位置',
    locationLogsStartDate: '開始日期',
    locationLogsEndDate: '結束日期',
    csvExportIconHint: '將日誌導出到 CSV 文件',
    locationHistoryMapViewToggleText: '地圖視圖',
    export: '輸出',
    mapFullScreenControlLabelTip: '切換全屏',
    remoteControl: '遥控',
    logsMenuItem: '日誌',
    updatingDeviceProperty: '更新設備屬性:',
    sendingRemoteCommand: '發送遠程命令:',
    applicationsSelected: '選擇的應用程序',
    uninstallApps: '卸載應用',

    security: '保安',
    compliant: '合規',
    notCompliant: '不合規',

    encrypted: '加密',
    OSSecure: '操作系统保安',
    passCodeEnabled: '啟用密碼',
    androidSecurityPatchLevel: 'Android 安全補丁級別',
    securityCompliance: '安全性合規',
    latestAgent: '最新 Agent',

    isNowOnline: '現已上線',
    lastKnownLocation: '最後已知位置',
    lastOnline: '最後上線',
    fleet: 'Fleet',
    fleetPath: 'Fleet 路徑',
    active: '活躍',
    inactive: '不活躍',
    enrolled: '已註冊',
    unenrolled: '脫離註冊',
    offline: '離線',
    online: '在線的',
    deviceConfigurationLocked: 'Device Configuration Locked',
    lastSeenDescription: '上次見於 %{lastSeenFormatted}',
    timeWithAgoAffix: '%{time} 前',

    status: '狀態',
    manufacturer: '製造商',
    model: '型號',
    activeConnection: '活躍連接',
    networkBssid: 'BSSID',
    networkSsid: 'SSID',
    networkDNS: 'DNS',
    networkRssi: 'RSSI (網絡信號電平)',
    roaming: '漫游',
    customNotes: '自定義筆記',
    developerMode: '開發者模式',
    ipAddress: 'IP 地址',
    wifiStatus: 'WiFi 狀態',
    nfcStatus: 'NFC 狀態',
    batteryLevel: '電池電量',
    powerStatus: '電源狀態',
    discharging: '放電中',
    charging: '充電中',
    pauseMode: '暫停模式',
    phoneNumber: '電話號碼',
    platform: '平台',
    ethernetMacAddress: '以太網 MAC 地址',
    macAddress: 'MAC 地址',
    checkinPeriod: 'Checkin 時期',
    lastUpdated: '最近更新時間',
    updatePeriod: '更新周期',
    lastFleetCheckin: '最後的 Fleet Checkin',
    automaticAgentUpdatesEnabled: 'Automatic Agent Updates Enabled',

    profilesAttachedToThisDevice: '附加到此設備的配置文件',
    noProfilesAttachedToDevice: '當前沒有配置文件附加到此設備',
    profileAssignedName: '配置文件名稱',
    profileAssignedStatus: '狀態',

    connectingToTheServer: '連接到服務器...',
    connectingToTheServerWaitingForTheDevice: '連接到服務器，等待設備',
    disconnectedFromTheServerTryingReconnect: '與服務器斷開連接，嘗試重新連接',
    deviceConnected: '設備已連接',
    deviceDisconnectedWaitingForTheDevice: '設備斷開，等待設備',
    remoteControlCurrentFocusTextTitle: '當前焦點文本',
    mediaProjection: '媒體投影',
    screenCaptureType: '屏幕截圖類型:',
    chooseScreenCaptureType: '選擇屏幕截圖類型',
    exitFullScreenMode: '退出全屏模式',
    fullScreenMode: '全屏模式',
    swipeTop: '滑動上頂部',
    volumeUp: '提高音量',
    volumeDown: '音量减小',
    power: 'Power',
    enterKioskMode: '進入 Kiosk 模式',
    exitKioskMode: '退出 Kiosk 模式',
    secureInput: '安全輸入',
    slowNetwork: '網速慢',
    imageQuality: '畫面質量:',
    imageSize: 'Image Size:',

    logsTitle: '日志',
    logsTitleHint: '為此設備存儲的日誌',
    dateTime: '日期時間',
    type: '類型',
    details: '細節',
    generatedBy: '生成者',

    addAndUpdateNoteTitle: '添加/更新自定義註釋',
    customNoteTitle: '自定義筆記',
    deviceLoadFailureMessage: '加載設備信息失敗',
    logs: {
      dateTime: '日期/時間',
      message: '信息',
      coordinates: '坐標',
      longitude: 'Longitude',
      latitude: 'Latitude',
    },

    fields: {
      fleet: 'Fleet',
      serialNumber: '序列號.',
      deviceType: '設備類型',
      osVersion: '操作系統版本',
      imei: 'IMEI',
      agentVersion: 'Agent 版本',
      availableStorage: '可用存儲空間',
      availableMemory: '有效内存',
      totalMemory: '總內存',
      totalStorage: '總存儲量',
      haveUpdates: '最新的 Agent',
      latestAgent: '最新的 Agent',
      compliance: 'Agent 兼容compliance',
    },
  },
  profiles: {
    title: '配置文件',
    published: '已發表',
    unpublished: '未發表',
    draft: '草案',
    draftAvailable: '草稿可用',
    version: '版本',
    createProfile: '創建配置文件',
    newProfileDefaultName: '新的配置文件',
    unableToCreateProfileErrorText: '無法創建配置文件',
    profileNameFieldPlaceholder: '輸入配置文件',
    moreOptionsTitleHeader: '更多選擇',
    assignmentSettingsTitle: '作業設置',
    deviceSideTimeLabel: '設備端時間',
    timezone: '時區',
    schedules: '時刻表',
    assignmentStartDateLabel: '關聯開始於',
    assignmentEndDateLabel: '關聯結束於',

    profileViewingHint: '您正在查看',
    profileAssociationHint: '您正在關聯',
    profileEditingHint: '您正在編輯',
    profileCreatingHint: '你正在創造',
    datetimeOffsetPlaceholder: '選擇值',
    assignmentButtonText: '關聯現在開始',
    saveButtonText: '保存',
    createButtonText: '創建',

    unassignProfiles: '將所選配置文件與所有關聯的 fleets 分離',
    unpublishAndClearFleetsSuccess: '已成功取消發布所選配置文件',
    unpublishAndClearFleetsFailure: '無法取消發布所選配置文件',
    unpublishAndClearListedFleetsSuccess:
      'Successfully unpublished the following profiles:',
    unpublishAndClearListedFleetsFailure:
      'Failed to unpublish the following profiles:',
    cloneProfiles: '克隆配置文件',
    removeProfiles: '删除配置文件',
    profileActions: '行動',
    assignFleetsAndPublish: '關聯 Fleets 並發布',
    associateFleetsTitle: '關聯 Fleets',
    unPublishProfile: '取消發布',
    assign: '關聯',
    migrateToVersionTwo: '遷移到版本 2',
    migrationSuccessText: '已成功將所選配置文件遷移到 Profiles V2',
    migrationFailureText: '無法將所選配置文件遷移到 Profiles V2',
    profileAssignmentToFleetsTitle: '將配置文件與 fleets 相關聯',
    unpublishProfilesTitle: '取消發布配置文件',
    unpublishProfilesConfirmationText: '您確定要取消發布所選的配置文件嗎?',
    unpublishProfilesConfirmationConsequence:
      '此操作將從設備中刪除選定的配置文件.',
    unassignProfilesConfirmationText: '您確定要取消關聯選定的配置文件嗎?',
    cloneProfilesConfirmationText: '您確定要克隆選定的配置文件嗎？',
    removeProfilesConfirmationText: '您確定要刪除選定的配置文件嗎？',
    migrateProfilesConfirmationText:
      '您確定要將選定的配置文件遷移到新版本 2 嗎？這個動作是不可逆的!',
    toolsPanelTitle: '選定的配置文件',
    loadProfilesErrorText: '加載配置文件時出錯',
    profileRemovalNotAllowedForPublishedProfiles:
      '一個或多個選定的配置文件當前已發布且無法刪除。請取消發布這些個人資料，然後重試',
    unavailableFeatureMessage:
      '該功能僅在新版 Profiles (v2) 中可用。請考慮遷移或創建新的 v2 配置文件。',
    cannotDeleteProfilesAssignedToFleets:
      '無法刪除當前分配有 Fleets 的配置文件。',
    cannotDeletePublishedProfile: '無法刪除已發布的配置文件',
    profileTreeHasMoreThanOnePublishedProfile:
      '配置文件樹有多個已發布的配置文件',
    profileTreeHasMoreThanOneLatestVersion:
      '配置文件樹有多個最新版本的配置文件',
    profileTreeCannotBeDeletedBecauseTheProfileIsPublished:
      '不能刪除已發布配置文件的配置文件樹。',
    profileHasMoreThanOneNewestVersion: '配置文件樹有多個最新的配置文件版本',
    cannotAssignFleetsToUnpublishedProfiles:
      '無法將 fleets 與未發布的草稿配置文件相關聯。請發布配置文件並重試.',
    profilesSuccessfullyDeleted: '配置文件已成功删除',
    profilesNotSuccessfullyDeleted: '刪除配置文件失敗',
    profilesSuccessfullyCloned: '配置文件成功克隆',
    profilesNotSuccessfullyCloned: '無法克隆配置文件',
    failedToLoadProfiles: '无法加载配置文件',
    failedToPublishProfile: '未能發布配置文件',
    profileSuccessfullyPublished: '配置文件已成功發布',
    fleetsToProfileAssignmentSuccess: 'Fleet 成功分配到當前發布的版本',
    fleetsToProfileAssignmentFailure: '無法將 fleets 與配置文件關聯.',
    failedToUnpublishProfile: '配置文件已成功發布',
    profileSuccessfullyUnpublished: '已成功取消發布配置文件',
    unpublishProfileConfirmationText: '您確定要取消發布此配置文件嗎?',
    profileCreationDefaultErrorText: '未能創建配置文件。',
    profileCreationAndPublishingDefaultErrorText: '未能創建和發布配置文件。',
    profileCreationSuccessText: '未能创建配置文件。',
    profileCreationAndPublishingSuccessText: '配置文件已成功創建和發布。',
    profileNewVersionCreationDefaultErrorText: '無法創建配置文件的新版本。',
    profileNewVersionCreationAndPublishingDefaultErrorText:
      '未能創建和發布配置文件的新版本。',
    saveAndPublishProfileDefaultErrorText: '無法創建配置文件的新版本。',
    profileNewVersionCreationSuccessText: '無法創建配置文件的新版本。',
    profileNewVersionCreationAndPublishingSuccessText:
      '已成功創建並發布配置文件的新版本。',
    saveAndPublishProfileSuccessText: '配置文件已成功保存和發布。',
    profileSaveAsDraftSuccessText: '已成功將配置文件保存為草稿。',
    profileSaveAsDraftDefaultErrorText: '無法將配置文件保存為草稿。',
    saveAsDraftButtonText: '保存為草稿',
    createNewVersionButtonText: '創建新版本',
    saveAndPublishButtonText: '保存並發布',
    unableToPublishProfileWithoutAssignedFleets:
      '無法發布配置文件，因為它沒有與之關聯的  fleets。請關聯一個或多個 fleet，然後重試.',
    profileVersionPublicationConfirmation:
      '您確定要發布此配置文件的第 %{version} 版嗎?',

    failedToLoadProfileErrorText: '配置文件加載失敗',
    failedToCreateProfileErrorText: '配置文件創建失敗',
    failedToUpdateProfileErrorText: '配置文件更新失敗',
    failedToLoadProfileApplicationsErrorText: '獲取配置文件應用程序時出錯',
    failedToLoadProfileById: '無法加載選定的配置文件',
    failedToLoadProfileApps: '無法加載配置文件應用程序',
    failedToAssignProfilesToFleets: '無法將所選配置文件與所選 fleets 相關聯',
    failedToDeleteProfiles: '未能删除配置文件',

    profileDetailsTitle: '配置文件詳情',
    profileDetailsHint: '與此配置文件相關的信息',
    addConfigurationLabel: '添加配置',
    addLabel: '添加',
    assignedFleetsLabel: '已分配的 fleets',

    fields: {
      name: '配置文件名稱',
      lastUpdated: '最近更新',
      updatedBy: 'Updated By',
      createdAt: '創建於',
      newestVersion: '最新版本',
      version: '版本',
      published: '已發表',
      policies: '政策',
      packageId: 'Package Id',
      status: '狀態',
      size: '大小',
    },

    networkTitle: '網絡',
    applicationsTitle: '應用',

    featureControl: {
      title: '功能控制',
      statusHeader: '功能控制已啟用',
      description: '設置功能控制。',
      noFeatureControlRules: '此配置文件中當前沒有功能控制規則。',
      fields: {
        disableBluetooth: '禁用藍牙',
        allowInstallationsFromUnknownSources: '允許從未知來源安裝',
        disableCamera: '禁用相機',
        disableUsb: '禁用 USB',
        disableGooglePlay: '禁用 Google Play',
        disableSafeBoot: '禁用 Safe Boot',
        disableWifiHotspot: '禁止或關閉網絡共享',
        disableFactoryReset: '禁用恢復出廠設置',
        disableHardReset: '阻止斷電並重新啟動（僅限 Knox）',
        disableTurnOffWifi: '阻止關閉 WiFi',
        disableAirplaneMode: '阻止飛行模式',
      },
    },
    kiosk: {
      title: 'Kiosk',
      htmlKioskTitle: 'HTML Kiosk',
      undo: '撤銷',
      save: '保存',
      htmlKiosk: 'HTML KIOSK',
      htmlKioskWhiteList: 'HTML KIOSK 白名單',
      passwordDoesNotMatch: `密碼不匹配`,
      backdoorPasswordDescription: '請設置後門密碼。',
      kioskBuilder: 'Kiosk Builder',
      kioskBuilderHint1: '將應用程序從列表拖放到設備界面。',
      kioskBuilderHint2: '將應用程序拖出設備界面即可移除。',

      HTMLKioskBuilder: 'HTML Kiosk Builder',
      HTMLKioskBuilderHint: `從右側“我的 HTML 文件”部分拖放一個 HTML 文件。`,
      myHtmlFiles: '我的 HTML 文件',
      enterHtmlKioskBuilder: '進入 HTML KIOSK BUILDER',
      enterKioskBuilder: '進入 KIOSK BUILDER',
      kioskMode: 'Kiosk 模式',
      statusTitle: 'Kiosk Mode 啟用',
      kioskModeEmptyMessage: '目前沒有自動安裝的應用程序。',
      back: '後退',
      security: '安全',
      confirmPassword: '確認密碼',
      password: '密碼',
      addPackageByID: '用 Package ID 添加 Package',
      statusBar: '狀態欄',
      whitelistServices: '白名單服務',
      autoLaunchApp: '自動啟動應用程序',
      myApplications: '我的應用程序',
      useHTMLKioskMode: '使用 HTML Kiosk 模式',
      hideStatusBarSetting: 'Hide Status Bar',
      backButtonSetting: '返回鍵',
      homeButtonSetting: '主頁按鈕',
      menuButtonSetting: '菜單按鈕',
      singleAppModeSetting: '單一應用模式',
      showExitButtonSetting: '顯示退出按鈕',
      onlyKioskNotificationsSetting: '允許僅通知',
      hideNavigationBarSetting: '隱藏導航欄（僅限三星）',
      backKeyText: '後退',
      homeKeyText: '主頁',
      menuKeyText: '菜單',
    },
    wallpaperSettings: {
      wallpaperSettings: 'Wallpaper 設置',
      policyStatusTitle: 'Wallpaper 已啟用',
      homeScreenWallpaperLandscape: '主屏幕 Wallpaper - 横向模式',
      homeScreenWallpaperPortrait: '主屏幕 Wallpaper - 縱向',
      lockScreenWallpaperLandscape: '锁屏 Wallpaper - 横向模式',
      lockScreenWallpaperPortrait: '锁屏 Wallpaper - 縱向',
      myImages: '我的圖片',
      useHomeScreenWallpaperAsLockScreenWallpaper:
        '使用主屏幕 Wallpaper 作為鎖屏 Wallpaper',
      wallpaperSettingsAvailability:
        '僅適用於 Android 7.0（API 級別 24）及更高版本。',
    },
    wifi: {
      title: 'WiFi',
      wifiPolicyEnabledTitle: '啟用 WiFi 策略',
      wifiNetworkTitle: 'WiFi 網絡',
      policyHint: '單擊 + 圖標添加 WiFi 網絡',
      descriptionHint: '為設備設置 WiFi 設置。',
      removeConfigurationActionButtonText: '删除配置',
      proxyBypassTitle: '繞過 proxy',
      proxyBypassHint: '請每行輸入一個主機名/網站。',
      proxyAutoConfigURLTitle: 'Proxy 自動配置 URL',
      proxyPortTitle: 'Proxy 端口',
      proxyHostnameTitle: 'Proxy 主機名',
      proxyTypeTitle: 'Proxy',
      frequencyHint: '僅適用於 Android 7 及更低版本。',
      proxyConfigTypeNone: '没有任何',
      proxyConfigTypeManual: '手動的',
      proxyConfigTypeAutoConfig: 'Proxy 自動配置',
      SSID: 'SSID',
      SSIDPlaceholder: '輸入 SSID 名稱',
      SSIDPassword: 'SSID 密碼',
      SSIDPasswordPlaceholder: '輸入密碼',
      WiFiFrequency: 'WiFi頻率',
      hiddenNetwork: '隱藏網絡',
      securityType: '安全類型',
      acceptedEAPTypes: '接受的 EAP 類型',
      identity: '身份',
      password: '密碼',
      passwordPlaceholder: '輸入密碼',
      clientCertificate: '客戶證書',
      CACertificate: 'CA 證書',
      CACertificateDomain: 'CA 證書域',
      domainSuffixMatch: '域名 Suffix 匹配',
      alternateSubjectMatch: '替代主題匹配',
      phase2AuthenticationMethod: '第 2 階段身份驗證方法',
      anonymousIdentity: '匿名身份',
      publicLandMobileNetwork: '公共陸地移動網絡',
      realm: '领域',
      noWiFiNetworksInProfileText: '此配置文件中當前有 WIFI 網絡。',
    },
    webFilter: {
      webFilter: '網頁過濾器',
      policyStatusTitle: '啟用網頁過濾器',
      noWebFilterPolicies: '尚未創建任何 Web 過濾器策略。',
      hint: '设置网络过滤器白名单或黑名单。',
      webFilterType: '網頁過濾器類型',
      urls: 'URLS',
      urlHint:
        '每行輸入一個網站 URL。請在每個網站前面加上相應的協議，例如 http://',
      webFilterTypeWhitelist: '白名單',
      webFilterTypeBlacklist: '黑名單',
    },
    apn: {
      emptyPolicyListMessage: '此配置文件中當前有 APN 設置。',
      policyStatusTitle: '啟用 APN 策略',
      apnSettingsAvailability:
        '僅適用於 Android 9.0（API 級別 28）及更高版本。',
      policyTitle: 'APN',
      apnNameLabel: '接入點名稱',
      apnDisplayNameLabel: '接入點顯示名稱',
      mobileCountryCodeLabel: '移動國家代碼 (MCC)',
      mobileNetworkCodeLabel: '移動網絡代碼',
      accessPointTypeMMS: 'MMS',
      accessPointTypeInternet: '互聯網',
      accessPointTypeMMSAndInternet: 'MMS + 互聯網',
      accessPointTypeAny: '任何',
      accessPointTypeLabel: '接入點類型',
      mvnoTypeLabel: 'MVNO 類型',
      mvnoValueLabel: 'MVNO 價值',
      MVNOTypeNone: '没有任何',
      MVNOTypeSPN: 'SPN',
      MVNOTypeIMSI: 'IMSI',
      MVNOTypeGID: 'GID',
      defaultNetworkLabel: '默認網絡',
      authenticationTypeLabel: '認證類型',

      authenticationTypeNone: '没有任何',
      authenticationTypePAP: 'PAP',
      authenticationTypeCHAP: 'CHAP',
      authenticationTypePAPCHAP: 'PAP/CHAP',

      accessPointConnectionUsernameLabel: '接入點連接用戶名',
      accessPointConnectionPasswordLabel: '接入點連接密碼',
      accessPointConnectionHostAddressLabel: '接入點連接主機地址',
      accessPointConnectionHostPortLabel: '接入點連接主機端口',

      proxyHostAddressLabel: 'Proxy 主機地址',
      proxyHostPortLabel: 'Proxy 主機端口',
      mmsProxyHostAddressLabel: 'MMS Proxy 主機端口',
      mmsHostAddressLabel: 'MMS 主機地址',
      mmsHostPortLabel: 'MMS 主機端口',
    },
    fileSync: {
      policyStatusTitle: '啟用文件同步',
      policyTitle: '文件同步',
      directionOptionSyncFilesFromServerToDevice: '將文件從服務器同步到設備',
      hint: '單擊 + 圖標添加文件同步配置',
      fileSyncConfigurationTitle: '文件同步配置',
      removeConfigurationText: '删除配置',
      directionFieldTitle: '方向',
      devicePathLabelText: '設備文件/文件夾名稱',
      devicePathPlaceholderText: '輸入文件夾路徑',
      fileSynchronizationOptionsTitle: '文件同步選項',
      syncSubFoldersFieldLabel: '同步子文件夹',
      filesTitle: '文件',
      noFileSyncRules: '此配置文件中當前沒有文件同步規則。',
      noFilesAssigned: '此配置文件中没有文件。',
      filesAssignmentHint: '請關聯您右側列表中的文件.',
    },
    geofencing: {
      policyTitle: '地理圍欄',
      policyStatusTitle: '地理圍欄',
      clearGeometryText: '清晰的幾何圖形',
      searchFieldPlaceholderText: '搜索 ...',
      geofencingRuleTitle: '地理圍欄規則',
      removeRuleActionButtonTitle: '刪除規則',
      notifyWhenDevicesLeaveTheFence: '設備離開圍欄區域時通知',
      moveDeviceToFleetIfItLeavesGeofence:
        '如果設備離開地理圍欄，則將設備移至 fleet',
      hint: '單擊 + 圖標添加地理圍欄規則',
      changeFleetButtonText: '改變 Fleet',
      removeFleetButtonText: '删除 Fleet',
      selectAFleetButtonText: '選擇一個 Fleet',
      fleetNotDefinedText: 'Fleet 沒有定義',
      policyHint: '繪製圍欄區域多邊形以設置地理圍欄規則',
      textButtonHint: '添加規則',
      noRulesInProfile: '此配置文件中當前沒有地理圍欄規則。',
    },
    password: {
      policyTitle: '密碼',
      policyHint: '設置密碼要求。',
      policyStatusTitle: '密碼已啟用',
      passwordQualityOptionPIN: 'PIN',
      passwordQualityOptionPattern: '安全模式',
      passwordQualityOptionAlphabetic: '字母',
      passwordQualityOptionAlphanumeric: '字母數字',

      passwordQualityLabel: '密碼質量',
      minimumLengthLabel: '密碼長度',
      complexCharactersRequiredLabel: '所需的最少複雜字符數',
    },
    settings: {
      policyTitle: '設置',
      policyStatusTitle: '設置已啟用',
      deviceLanguageTitle: '設備語言',
      syncNTPLabel: '與 NTP 服務器同步時間',
      setDeviceTimezone: '設置設備時區',
      timezone: '時區',
      useDaylightSavingsTime: '使用夏令時',
      syncDeviceOverWiFi: '僅通過 WiFi 同步到設備',
      downloadFilesOverWifi: '僅通過 Wi-Fi 下載文件',
      policyHint: '設置同步設置。',
      deviceVolumeTitle: '設備音量',
      deviceBrightnessTitle: '設備亮度',
      disableAndroidBeamTitle: '禁用 Android Beam',
      autoRotateScreenTitle: '自動旋轉屏幕',
      displaySectionTitle: 'Display',
      timeSectionTitle: '時間',
      languageSectionTitle: '語言',
      synchronizationSectionTitle: '同步',
      systemSectionTitle: '系統',
    },
    certificates: {
      policyTitle: '證書',
      policyStatusTitle: '已啟用證書管理',
      certificatesTitle: '配置文件證書',
      certificateNameTitle: '證書名稱',
      lastUpdated: '最近更新時間',
      noCertificatesAttachedToProfile: '此配置文件沒有附加證書。',
      howToAddCertificateRecommendationText: '請關聯您右側列表中的證書',
    },
    apps: {
      policyTitle: '應用管理',
      policyStatusTitle: '已啟用應用程序管理',
      policyTitleHint: '附加到此配置文件的應用程序將自動安裝這些應用程序。',
      profileAppsTitle: '配置文件應用程序',
      noAppsAttachedToProfile: '當前沒有附加到此配置文件的應用程序。',
      howToAddAppsRecommendationText: '請關聯您右側列表中的應用程序.',
      myApplicationsTitle: '我的應用程序',
      autoInstalledAppsTitle: '自動安裝的應用程序',
      noAutoInstalledAppsText: '目前沒有自動安裝的應用程序。',
      networkRestrictionsSectionTitle: '網絡限制',
      installOverWiFiTitle: '通過 Wifi 安裝',
      installOverCellularTitle: '通過移動網絡安裝',
      installOverCellularConditionTitle: '在移動網絡條件下安裝',
      installOverLANTitle: '通過局域網安裝',
      noneInstallOverCellularConditionTitle: '沒有任何',
      cellularInstallOverCellularConditionTitle: '移動網絡',
      cellularWhileRoamingInstallOverCellularConditionTitle: '漫遊時移動網絡',
    },
    blacklist: {
      policyTitle: '應用黑名單管理',
      policyStatusTitle: '应用黑名单管理',
      blacklistTitle: '黑名單',
      blacklistedAppsTitle: 'Blacklisted Package IDs',
      noBlacklistedAppsMessage: '目前沒有列入黑名單的應用程序。',
      blacklistUpdateHint:
        'Please associate apps from the list on your right。',
      blacklistDescription: '附加到此配置文件的應用程序將被列入黑名單。',
      addPackageByID: '按 Package ID 添加 Package',
    },
    security: {
      policyTitle: '安全性',
      policyStatusTitle: '啟用安全管理',
      policyHint: '設置安全設置。',
      secureOSTitle: '安全操作系统',
    },
    location: {
      policyTitle: '位置',
      policyStatusTitle: '位置設置已啟用',
      locationPolicyHint: '設置位置服務設置。',
      GPSAlwaysOnSetting: 'GPS 始終開啟',
    },
    locationServices: {
      policyTitle: '位置服務',
    },
    authentication: {
      policyTitle: '驗證',
      policyTitleHint: '設置用於註冊新設備的密碼。',
      passwordFieldLabel: '密碼',
      confirmPasswordFieldLabel: '確認密碼',
    },
  },
  applications: {
    title: '應用程序',
    myApplicationsTitle: '我的應用程序',
    applicationInfoTabHeading: '應用信息',
    allVersionsListTabHeading: '所有版本列表',
    allVersionsListTabHint: '此應用程序的所有版本',
    settingsTabHeading: '設置',
    assignProfilesHeading: '配置文件',
    assignProfilesHint: '選擇配置文件並按關聯',
    assignProfilesButtonText: '現在關聯',
    assignProfilesFailureDefaultMessage: '無法將應用程序與配置文件相關聯',
    assignProfilesSuccessDefaultMessage: '與所選配置文件關聯的應用程序',

    applicationCodeNameHint: '開始輸入定義',

    applicationVersionsTitle: '應用程序',
    appsRelatedToTaskTitle: '與此任務相關的應用程序',
    editApplicationDetails: '編輯應用程序詳細信息',

    applicationDataUpdateError: '更新應用程序數據時出錯',
    applicationDataFetchError: '獲取應用程序數據時出錯',
    applicationAssignmentToProfilesErrorMessage: '應用程序與配置文件關聯時出錯',

    fields: {
      applicationName: '應用名稱',
      applicationFileName: '應用程序文件名',
      label: '標籤',
      latestVersion: '最新版本',
      latestVersionCode: '最新版本代碼',
      versionCode: '版本代碼',
      version: '版本',
      packageId: 'Package ID',
      status: '狀態',
      size: '大小',
      lastUpdated: '最近更新時間',
      uploadedBy: 'Uploaded By',
      uploadedOn: 'Uploaded On',
      installOnly: '僅安裝',
      managed: '受管理',
      installedOnThisDevice: '安裝在此設備上',
    },
    applicationsDeletionTitle: '應用程序刪除',
    applicationsDeletionFailed: '應用程序刪除失敗',
    applicationsFetchFailed: '獲取應用程序時出錯',
    appsNotDeletedByAssignedToProfileTitle: `以下應用程序未被刪除，因為它們仍被分配給配置文件 (%{profile})`,
    appsDeletedTitle: '以下應用程序已成功刪除',
    addBundleTitle: '添加 Package',
    addBundleFieldPlaceholderText: '輸入 Package ID',
  },
  devices: {
    title: '設備',
    csvExportIconHint: '將表格導出為 CSV 文件',
    devicesSelected: '選擇的設備',
    removeDevices: '移除設備',
    selectFleet: '選擇 Fleet',
    associationHint: '您正在關聯',
    assignmentButtonText: '立即關聯',
    selectedFleet: '已選 Fleet',
    deviceUpdateTitle: '最新的 Agent',
    deviceLogsLoadingFailed: `設備的日誌加載失敗`,
    applicationsLoadingFailed: '應用程序加載失敗',
    deviceProfilesLoadingFailed: '配置文件加載失敗',
    devicePropertyUpdateFailed: '無法更新設備屬性',
    devicePropertyValueMustBeUnique: '設備屬性應該是唯一的',
    applicationsUninstallFailed: '應用程序卸載失敗',
    sendingRemoteCommandToSelectedDevicesErrorMessage:
      '向所選設備發送遠程命令時出錯',
    errorOnFetchingAllCompanyDevices: '獲取設備時出錯',
    errorOnDeletingSelectedDevices: '刪除所選設備時出錯',
    removeDeviceTitle: '移除設備',
    sendingRemoteCommandTitle: '發送遠程命令',
    fields: {
      fleetName: 'Fleet',
      haveUpdates: '最新的 Agent',
      latestAgent: '最新的 Agent',
      status: '狀態',
      lastSeen: '最後一次看到',
      deviceName: '設備',
      agentVersion: 'Agent 版本',
      platform: '平台',
      location: '位置',
      longitude: 'Longitude',
      latitude: 'Latitude',
      batteryLevel: '電池電量',
      lastOnline: '最後上線',
      enrollmentDatetime: '註冊 日期/時間',
      manufacturer: '製造商',
      model: '型號',
      macAddress: 'MAC 地址',
      ethernetMacAddress: '以太網 MAC 地址',
      ipAddress: 'IP 地址',
      totalMemory: '總內存',
      availableMemory: '有效内存',
      totalStorage: '總存儲量',
      availableStorage: '可用存儲空間',
      serialNumber: '序列號',
      imei: 'IMEI',
      sendingAnalyticsData: '發送分析數據',
      compliance: 'Agent 兼容',
      compliantAndLatestVersion: '兼容和最新版本',
      compliantAndNotLatestVersion: '兼容但不是最新版本',
      notCompliantVersion: '不符合版本',
      deviceConfigurationLockStatus: 'Device Configuration Lock Status',
      automaticAgentUpdatesEnabled: 'Automatic Agent Updates Enabled',
    },
  },
  home: {
    title: '主頁',
    search: '搜索',
  },
  users: {
    userGroups: '用戶組',
    title: '用户',
    allUsers: '全部用户',
    admins: '管理員',
    manage: '管理',
    groupName: '團體',
    moveUsers: '移動用戶:',
    userStatusChange: '用戶狀態變化',
    makeUsersInactiveConfirmation: '您想讓選定的用戶處於非活動狀態嗎？',
    activateUsersConfirmation: '您確定要激活選定的用戶嗎？',
    cannotDeleteGroupContainingUsersMessage:
      '您無法刪除仍有用戶的組。請從該組中刪除所有用戶，然後重試。',
    deleteGroupConfirmation: '您确定要删除 %{groupName} 嗎？ ',

    createAGroup: '創建一個群組',
    createUser: '創建用戶',
    editGroup: '編輯組',
    deleteGroup: '刪除群組',
    chooseAUserGroup: '選擇用戶組',
    move: '移動',
    changeUserGroup: '更改用戶組',
    activateUsers: '激活用户',
    deactivateUsers: '停用用户',
    removeUsers: '删除用户',
    usersSelected: '選擇的用戶',

    newUserGroup: '新用戶組',
    permissions: '權限',
    userManagement: '用户管理',
    groupCreationFailureDefaultErrorText: '無法創建群組',
    groupCreationHeading: '你正在創造',
    groupEditingHeading: '您正在編輯',
    update: '更新',
    create: '創建',

    globalPermissionsTableDescriptionHeader: '描述',
    globalPermissionsTableReadOnlyHeader: '只讀',
    globalPermissionsTableReadWriteHeader: '讀/寫',

    userGroupSectionDescription: '用戶組',
    globalPermissionsSectionDescription: '全局權限',
    fleetAccessSectionDescription: 'Fleet 訪問管理',
    remoteControlSectionDescription: '遠程控制權限',
    viewSpecificSectionDescription: '查看特定權限',
    userGroupCreationGroupNameLabel: '用戶組名稱:',
    allUsersEmptyText: '您已選擇所有用戶。',
    allUsersListHeaderEmail: '電子郵件',
    allUsersListHeaderUsername: '用户名',
    noUsersInGroupText: '沒有用戶附加到此用戶組。',
    hintToAddUsers: '請關聯您右側列表中的用戶。',
    existingUsersTitle: '現有用戶',
    usernameTitle: '用户名',
    failedToCreateNewUserMessage: '無法創建新用戶',
    updateUserTitle: '更新用户',
    createNewUserButton: '創建',
    newUserText: '新用户',
    youAreAdding: '您正在添加',

    groupsLoadErrorText: '無法加載群組',
    groupCreationError: '無法創建新組',
    groupUpdateError: '更新組失敗',
    groupDeletionError: '刪除群組失敗',
    loadGroupsModelLevelPermissionsError: '加載權限失敗',
    loadAllUsersError: '加载用户失败',
    loadCompanyFleetsFullListError: `无法加载公司的完整 fleets 列表`,
    loadUsersLogsError: '无法加载用户日志',
    updateUserError: '未能更新用户详细信息',
    loadUserError: '无法加载用户详细信息',
    loadUsersError: '加載用戶失敗',
    deleteUsersError: '刪除用戶失敗',
    moveUsersError: '無法移動用戶',
    changeUserStatusError: '更改用戶狀態失敗',
    loadUserGroupPermissionError: '加載用戶組權限失敗',

    userTabHeading: '細節',
    userDetailsSectionHeading: '用戶詳情',
    adminControlsSectionHeading: '管理控制',
    adminRoleAssigmentToggleLabel: '管理員角色',
    partnerRoleAssigmentToggleLabel: '伙伴角色',
    userRoleSectionHeading: '用户角色',

    deleteAccountToggleLabel: '刪除帳戶',
    userUpdateErrorTitle: '用戶更新錯誤',

    groupListCSVExportIconHint: '將組列表導出到 CSV 文件',

    devicePermissionTitle: '設備',
    fleetTreePermissionTitle: 'Fleets 樹',
    profilePermissionTitle: '配置文件',

    fleetAccessTitle: 'Fleet 訪問',
    profileAccessTitle: '配置文件訪問',

    permissionTitleUploadsAndApplicationsAccessTitle: '上傳和應用程序訪問',
    permissionTitleUploadsTitle: '上傳（文件、證書、應用程序）',

    permissionTitleApplicationsAccess: '應用程序訪問',
    permissionTitleViewDeviceLocation: '查看設備位置',
    permissionTitleLiveDeviceLocationTracking: '實時設備位置跟踪',
    permissionTitleViewCurrentLocation: '查看設備當前位置',
    permissionTitleViewLocationHistory: '查看設備位置歷史記錄',

    globalPermissionsAccessTypeReadOnly: '只讀',
    globalPermissionsAccessTypeReadWrite: '讀/寫',
    globalPermissionsAccessTypeNone: '没有任何',

    globalPermissionsHeadingDescription: '描述',
    globalPermissionsHeadingAccess: '使用權',

    userCreationTabTitle: '創建個人用戶',
    failedToLoadPartners: '無法加載合作夥伴',
    failedToLoadPartnerCompanies: '無法加載合作夥伴公司',

    enrollmentEmailTemplateTitle: '註冊電子郵件模板',

    fields: {
      username: '用户名',
      firstName: '名',
      lastName: '姓',
      email: '電子郵件',
      emailPlaceholder: '輸入電子郵件地址',
      password: '密碼',
      active: '活躍',
      inactive: '不活躍',
      status: '狀態',
      lastLoginTitle: '上次登錄',
      creationDateTitle: '創建於',
      creatorTitle: '由你創造',
      requirePasswordChangeOnNextLogin: '要求用戶在下次登錄時更改密碼',
      disablePasswordChange: '禁止用戶更改密碼',
      disableEmailAddressChange: '禁止用戶更改電子郵件地址',
      disableAccount: '暫時停用帳號',
      companyName: '公司名',
      partnerNameFieldText: '合作夥伴名稱',
      enrollmentEmailHint:
        '我們已經起草了一封電子郵件，您可以將其發送給您的員工，讓他們註冊。他們需要導航到註冊鏈接以下載設備 agent。',
      emailContentFieldTitle: '電子郵件內容',
      emailSubjectFieldTitle: '電子郵件主題',
    },
    userLogs: {
      type: '類型',
      date: '日期',
      messageLog: '消息日志',
      name: '姓名',
      ipAddress: 'IP 地址',
      userGroupsTitle: '用戶組',
      startDate: '開始日期',
      endDate: '結束日期',
    },
  },
  user: {
    admin: '行政',
    partner: '伙伴',
    partneruser: '伙伴用户',
    superuser: '超級用戶',
    customer: '顧客',
    user: '用户',
    unknownAccessLevel: '未知訪問級別',
  },
  settings: {
    title: '設置',
    accountSettings: '帳號設定',
    accountSettingsLoadFailure: '無法加載帳戶信息',
    globalPermissionsLoadFailureMessage: '無法為登錄用戶加載全局權限。',
    failedToLoadTimezones: '無法加載時區',
    failedToGetSSOSAMLSettings: '無法獲取 SAML 設置',
    fields: {
      name: '姓名',
      namePlaceholder: '我的名字',
      email: '電子郵件地址',
      emailPlaceholder: '電子郵件',
    },
    passwordChangeTitle: '密碼更改',
    changePassword: '更改密碼',
    changingPassword: '在更改密碼',
    currentPasswordTitle: '當前密碼',
    currentPasswordPlaceholder: '輸入當前密碼',
    newPasswordTitle: '新密碼',
    newPasswordPlaceholder: '輸入新密碼',
    reTypeNewPasswordTitle: '重新輸入新密碼',
    validationAllPasswordFieldRequired: '所有字段必填',
    passwordValidationCantBePreviouslyUsed: '新密碼需要與舊密碼不同',
    passwordValidationDoesNotMatch: '密碼不匹配',
    passwordValidationMinimumLength: '密碼長度必須至少為 8 個字符',
    passwordChangeFailureText: '修改密碼失敗',

    companyInfo: '公司信息',
    companyInfoHint: '與這家公司相關的信息',
    companyName: '公司名',
    totalNumberOfLicenses: 'licenses 總數',
    licensesInUse: '正在使用的 Licenses',
    licensesAvailable: '可用的 Licenses',
    commentTitle: '評論',

    commonSettings: '常用設置',
    commonSettingsHint: '與該公司相關的設備設置',
    enableAutomaticAgentUpdates: '啟用自動更新 agent',
    setupSettings: '建立設置',
    updatePopupTitle: '通用設置更新',
    updatePopupDefaultError: '不能設置期間',

    loginURLLabel: '登錄 URL',
    organizationIdLabel: '組織 ID',
    azureADEntityIdLabel: 'Azure AD 標識符',
    applicationNameLabel: '應用名稱',
    federationMetadataXMLFileLabel: 'Federation 元數據 XML',

    invalidXMLFileErrorMessage: '無效的 XML 文件',
    ssoAccountLinkSuccessMessage: '帳號關聯成功。',
    ssoAccountSettingsUpdateSuccessMessage: '帳戶已成功更新。',
    failedToLinkSSOAccountMessage: `抱歉，我們暫時無法關聯您的帳戶`,
    azureSAMLLinkAccountText: '鏈接 Azure 帳戶 (SAML)',
    azureSAMLUpdateAccountText: '更新 Azure 帳戶 (SAML)',
    updateWarningText: '更新 Azure 帳戶設置會影響以下公司',
    azureSAMLLoginButtonText: '使用 Microsoft Azure (SAML 2.0) 登錄',
    OIDCLinkAccountHeading: 'OIDC 2.0',
    SAMLLinkAccountHeading: 'SAML 2.0',
    azureADLinkHeading: 'Azure AD',
    azureOIDCLinkAccountText: '鏈接 Azure 帳戶 (OIDC)',
    azureOAuthLoginButtonText: '使用 Microsoft Azure (OAuth 2.0) 登錄',
    SSOSettingsSectionHeading: 'SSO 設置',
    SSOSettingsSectionHint: '請輸入以下信息以使用 SAML 2.0 鏈接您的 Azure 帳戶',
    preferences: 'Preferences',
    setTimezoneFieldTitle: 'Time Zone',
    setLocaleFieldTitle: 'Locale',
    savePreferences: 'Save',
    preferencesFetchFailed: 'Failed to fetch preferences',
    preferencesUpdateFailed: 'Failed to update preferences',
    preferencesUpdateSucceeded: 'Preferences successfully updated ',
  },
  common: {
    noData: '沒有數據',
    send: '發送',
    ok: 'OK',
    cancel: '取消',
    saveChanges: '保存更改',
    save: '保存',
    undo: '撤消',
    close: '關閉',
    filter: '篩選',
    edit: '編輯',
    set: '設置',
    yes: '是的',
    no: '不',
    add: '添加',
    enabled: '啟用',
    disabled: '已禁用',
    connected: '連接的',
    disconnected: '斷開連接',
    next: '下一個',
    back: '後退',
    confirm: '確認',
    continue: '繼續',
    getStarted: '開始',
    inProgress: '進行中',
    successful: '成功的',
    success: '成功',
    error: '錯誤',
    failed: '失敗的',
    filterDescriptionTitle: '過濾器將滿足以下條件:',
    filterDescriptionOptionAll: '以下所有',
    filterDescriptionOptionAny: '以下任何一項',
    addCriteria: '添加條件',
    noFiltersApplied: '未應用過濾器',
    selectCondition: '選擇條件',
    removeFilter: '移除過濾器',
    selectValue: '選擇值',
    selectValueType: '選擇值類型',
    location: '位置',
    noLocationSet: '未設置位置',
    noLocationData: '沒有數據',
    completed: '已完成',
    somethingWentWrong: '出了些問題',
    proceedConfirmationText: '你要繼續嗎？',
    confirmationDefaultTitle: '確認',
    confirmationDefaultText: '你確定要這麼做嗎？',
    alertDefaultTitle: '重要信息',
    optionModalDefaultTitle: '所需資料',
    optionModalDefaultText: '請選擇以下選項之一',
    select: '選擇',
    promptDefaultTitle: '提示',
    minutes: '分鐘',
    hours: '小時',
    days: '天',
    weeks: '周',
    months: '月',
    years: '年',
    notFound: '未找到',
    toolsPanelSelectedTitle: '已選',
    or: '或者',
    startDate: '開始日期',
    endDate: '結束日期',
    systemMessageLoadFailure: '加載系統消息失敗',
  },
  fleets: {
    dropdownToggleText: '行動',
    exportTable: '導出表',
    rename: '改名',
    delete: '删除',
    fleet: 'Fleet',
    assignProfiles: '關聯配置文件',
    profileAssignmentModalConfirmationButtonText: '關聯',
    fleetDetails: 'Fleet 細節',
    profiles: '配置文件',
    areYouSureYouWantToDeleteThisFleet: '你確定要刪除這個 fleet 嗎?',
    assignDevicesToFleet: '將設備與 fleet 關聯:',

    enterAFleetName: '輸入 fleet 的名稱',

    fleetsTitle: 'Fleets',
    createAFleet: '創建一個 FLEET',
    creationInProgress: '創作中...',
    success: '成功: ',
    fleetCreated: 'Fleet 已創造',
    error: '錯誤: ',
    cannotPerform: '無法執行',
    fleetMovementConfirmation: `您確定要讓“%{fleetName}”成為“%{targetFleetName}”的直接 child fleet 嗎?`,
    fleetMovementSecondaryConfirmation: '保留初始 (%{fleetName}) 配置文件.',
    fleetNamePlaceholder: '輸入新的 fleet 名稱',
    createFleet: '創建 fleet',
    allFleets: '全部 fleets',
    selectAllFleets: '全選 Fleets',
    failedToMoveDevicesErrorText: '無法移動設備',
    fleetDeletionError: '删除 fleet 失敗',
    fleetNotEmptyDeletionError:
      '該 fleet 具有與之關聯的配置文件。您必須先取消關聯所有配置文件，然後才能將其刪除。',

    remoteCommandStatusPopupTitle: '發送遠程命令',
    fleetDeletingStatusPopupTitle: '删除 fleet',

    fleetSelectionHint: '從樹中選擇一個 fleet 以顯示 Fleet 設備。',
    fleetMovementModalTitle: '選擇一個 fleet',
    fleetMovementModalConfirmationButtonText: '移動設備',
    toolsPanelTitle: '所選設備',
    moveDevicesToolTitle: '移動設備',
    unenrollDevicesToolTitle: '取消註冊設備',
    pauseAgentUpdateToolTitle: '暫停 Agent 更新',
    resumeAgentUpdateToolTitle: '恢復 Agent 更新',
    updateDPCAppTitle: '將 DPC 更新到最新版本',
    identifyDevicesToolTitle: '識別設備',
    increaseLocationTrackingFrequencyToolTitle: '跟踪設備',
    forceDeviceCheckInToolTitle: '強制設備 Check-in',
    remoteRebootDevicesToolTitle: '遠程重啟設備',
    remoteWipeDevicesToolTitle: '遠程擦除設備',
    remoteLockDevicesToolTitle: '遙控鎖裝置',
    resetDevicesPasswordToolTitle: '重置設備密碼',
    clearApplicationDataAndCacheToolTitle: '清除應用程序數據和緩存（僅限三星）',
    sendMessageToolTitle: '發信息',
    lockDeviceConfigurationToolTitle: '鎖定設備配置',
    unlockDeviceConfigurationToolTitle: '解鎖設備配置',
    removeDevicesToolTitle: '移除設備',

    unEnrollmentConfirmationText: '您確定要取消註冊所選設備嗎?',
    lockDeviceConfigurationConfirmationText:
      '您確定要鎖定所選設備上的當前配置嗎?',
    unlockDeviceConfigurationConfirmationText: '您確定要配置選定的設備嗎?',
    removeDevicesConfirmationText: '您確定要刪除所選設備嗎?',
    rebootDevicesConfirmationText: '您確定要重新啟動選定的設備嗎?',
    wipeDevicesPrimaryConfirmationText: '您確定要遠程擦除所選設備嗎?',
    wipeDevicesSecondaryConfirmationText: '也擦SD卡',
    lockDevicesConfirmationText: '您確定要遠程鎖定所選設備嗎?',
    pauseAgentUpdatesConfirmationText: '您確定要暫停所選設備上的 agent 更新嗎?',
    resumeAgentUpdatesConfirmationText:
      '您確定要在所選設備上恢復 agent 更新嗎?',
    increaseLocationTrackingFrequencyConfirmationText:
      '您確定要在接下來的 10 分鐘內增加所選設備的位置跟踪頻率嗎?',

    identifyDevicesConfirmationText: '您確定要識別所選設備嗎?',
    updateDPCAppConfirmationText:
      '您確定要將所選設備上的 DPC 應用程序更新到最新版本嗎?',
    forceCheckinConfirmationText: '您確定要強制選定的設備 check in 嗎?',
    clearDevicesAppDataConfirmationText: '您確定要清除所選設備上的應用數據嗎?',
    resetDevicesPasswordConfirmationText: '您確定要在所選設備上重置密碼嗎?',

    fleetToProfilesAssignmentSuccessMessage: '已成功分配新選擇的配置文件。',
    fleetToProfilesUnassignmentSuccessMessage: '配置文件已成功分離。',
    fleetToProfilesAssignmentFailureMessage: '無法關聯一個或多個配置文件。',
    fleetToProfilesUnassignmentFailureMessage:
      '無法取消關聯一個或多個配置文件。',
    fleetDeletionFailure: '刪除 fleet 失敗。',
    notEmptyFleetTitle: 'Fleet 不是空的',
    fleetDeletionFailureDueToDevicesContentMessage:
      '無法刪除此隊列，因為它當前包含一台或多台設備.',
    fleetDeletionFailureDueToDevicesContentTip:
      '請刪除此隊列包含的所有設備，然後重試.',

    fleetDeletionFailureDueToAssignedProfiles:
      '無法刪除此 fleet，因為它當前分配有一個或多個配置文件。請取消關聯這些配置文件，然後重試。',
    fleetDeletionConfirmation:
      '刪除此 fleet 將刪除它的所有版本。這個動作是不可逆的。你確定你要繼續嗎?',
    lockDeviceConfigurationSuccessMessage: '配置鎖定命令成功發送到所選設備',
    unlockDeviceConfigurationSuccessMessage: '配置解鎖命令成功發送到所選設備',
    lockDeviceConfigurationErrorMessage: '無法向所選設備發送配置鎖定命令',
    unlockDeviceConfigurationErrorMessage: '無法向所選設備發送配置解鎖命令',
    failedToLoadProfilesAssignedToFleet: '無法加載分配給此 fleet 的配置文件',
    fleetCreationFailedMessage: 'Fleet 創建失敗',
    fleetCreationTitle: 'Fleet 創建',
    loadFleetConfigByIdErrorMessage: '無法加載 fleet 配置',
    loadAllFleetsErrorMessage: '未能加載 fleets',
    failedToFetchSelectedFleet: '無法加載選定的 fleet',
    unenrollmentFailedText: '無法取消註冊所選設備',
  },
  fileSystem: {
    title: '文件系統',
    applicationsSuccessfulUploadMessageText: '以下應用程序已成功上傳:',
    htmlFilesSuccessfulUploadMessageText: '以下 HTML 文件已成功上傳:',
    certificatesSuccessfulUploadMessageText: '以下證書已成功上傳:',
    filesSuccessfulUploadMessageText: '以下文件已成功上傳:',

    applicationsDuplicateUploadFailureMessageText:
      '以下應用程序未上傳，因為此（這些）應用程序的相同版本之前已上傳。請嘗試上傳不同的版本。',
    htmlFilesDuplicateUploadFailureMessageText:
      '以下 HTML 文件未上傳，因為之前上傳了具有相同文件名的其他文件。請重命名文件並再次嘗試上傳。',
    certificatesDuplicateUploadFailureMessageText:
      '以下證書未上傳，因為之前上傳了其他具有相同文件名的證書。請重命名證書並再次嘗試上傳。',
    filesDuplicateUploadFailureMessageText:
      '以下文件未上傳，因為之前上傳了其他具有相同文件名的文件。請重命名文件並再次嘗試上傳。',

    applicationsUploadFailureMessageText: '以下應用程序上傳失敗。',
    htmlFilesUploadFailureMessageText: '以下 HTML 文件上傳失敗。',
    certificatesUploadFailureMessageText: '以下證書上傳失敗。',
    filesUploadFailureMessageText: '以下文件上傳失敗。',

    apkFilesUploadMenuItem: 'APK 文件',
    filesUploadMenuItem: '文件',
    certificatesUploadMenuItem: '證書',
    HTMLKioskFilesUploadMenuItem: 'HTML Kiosk 文件',

    apkFilesUploadTab: '添加 APK 文件',
    filesUploadTab: '添加文件',
    certificatesUploadTab: '添加證書',
    HTMLKioskFilesUploadTab: '添加 HTML Kiosk 文件',

    apkUploading: 'APK 上傳中:',
    filesUploading: '文件上傳中:',
    certificatesUploading: '證書上傳中:',
    htmlKioskFilesUploading: 'HTML Kiosk 文件上傳中:',

    apkUploadingSuccessMessage: 'APK 成功上傳!',
    filesUploadingSuccessMessage: '文件上傳成功！',
    certificatesUploadingSuccessMessage: '證書上傳成功!',
    htmlKioskFilesUploadingSuccessMessage: 'HTML Kiosk 文件成功上傳!',

    apkDropZoneTitle: '從您的電腦拖放您的應用程序',
    filesDropZoneTitle: '從電腦中拖放文件',
    certificatesDropZoneTitle: '從您的電腦拖放您的證書',
    htmlKioskFilesDropZoneTitle: '從電腦中拖放 HTML Kiosk 文件',

    apkAddLinkTitle: '添加指向APK文件存儲位置的鏈接',
    filesAddLinkTitle: '添加指向文件存儲位置的鏈接',
    certificatesAddLinkTitle: '添加指向證書文件存儲位置的鏈接',
    htmlKioskAddLinkTitle: '添加指向 HTML kiosk 文件存儲位置的鏈接',

    uploading: '上傳中:',
    registeringFilesOnServer: '在服務器上註冊文件...',
    browse: '瀏覽',
    uploadFailed: '上傳失敗',

    appUploadRejectionMessage:
      '無法上傳以下應用程序，因為它們的大小超過了允許的最大大小 (%{max})，或者它們的文件類型與允許的類型之一 (%{allowedFileTypes}) 不匹配',
    certificateUploadRejectionMessage:
      '無法上傳以下證書，因為它們的大小超過了允許的最大大小 (%{max}) 或它們的文件類型與允許的類型之一 (%{allowedFileTypes}) 不匹配',
    fileUploadRejectionMessage:
      '無法上傳以下文件，因為它們的大小超過了允許的最大大小 (%{max}) 或它們的文件類型與允許的類型之一 (%{allowedFileTypes}) 不匹配',
    htmlUploadRejectionMessage:
      '無法上傳以下 HTML 文件，因為它們的大小超過了允許的最大大小 (%{max}) 或它們的文件類型與允許的類型之一 (%{allowedFileTypes}) 不匹配',

    applicationsSelected: '應用選擇',
    removeApplications: ' 移除應用程序',
    deletingApplications: '刪除應用程序',

    versionsDeletionTitle: 'Versions Deletion',
    versionsDeletionFailed: 'Versions deletion failed',
    versionsSelected: 'Versions Selected',
    removeVersions: 'Remove Versions',
    deletingVersions: 'Deleting Versions',
    deleteVersionsConfirmation:
      'This is going to delete the selected versions. Are you sure that you want to continue?',
    versionsNotDeletedByAssignedToProfileTitle: `The following version(s) was (were) NOT deleted because they are still assigned to a profile (%{profile})`,
    versionsDeletedTitle:
      'The following version(s) was (were) successfully deleted',

    fileName: '文檔名稱',
    contentType: '內容類型',
    assignFiles: '關聯文件:',
    uploadedBy: 'Uploaded By',

    selectedFiles: '選定的文件',
    removeFiles: '删除文件',
    deletionConfirmation: '您確定要刪除選定的文件嗎?',
    failedToAssignFilesToProfile: '無法將文件與配置文件關聯',
    failedToDeleteFiles: '刪除文件失敗',
    failedToLoadFiles: '加载文件失败',

    sendRemoteLinkFailure: '無法將鏈接發送到服務器進行處理。',
    sendRemoteLinkSuccess: '鏈接成功發送到服務器進行處理。',
  },
};
