import _ from 'lodash';
import { CHECK_DEVICES_UPDATES_INTERVAL } from '../features/devices/components/Devices/Devices.constants';

let sockets = new Map();

let timers = new Map();

const connect = (url, act, params = {}) => {
  if (!_.isUndefined(sockets.get(url)) || !url) return;
  try {
    // Websocket over ws from https page can cause DOMException!
    const socket = new WebSocket(`${url}`);
    socket.onopen = (e) => {
      console.info('socket connected');
    };
    socket.onmessage = act;
    if (!params.withoutPing) {
      ping(url, () => {
        if (socket.readyState === socket.OPEN) {
          socket.send(null);
        }
      });
    }

    socket.onclose = (e) => {
      console.info('socket closed unexpected, trying to reconnect in 5000ms');
      clearTimeout(timers.get(url));
      sockets.delete(url);
      setTimeout(() => {
        connect(url, act, !params.withoutPing);
      }, 5000);
    };

    sockets.set(url, socket);
  } catch (e) {
    console.warn(e);
  }
};

export const createSocketMiddleware = () => {
  return socketMiddleWare;
};

export function createSocketActions(type, destination, callback) {
  const subsribeActionCreator = (payload, event, params) => {
    return new SubscribeAction({
      type: `${type}_SUBSCRIBE`,
      destination: payload,
      event,
      callback,
      params,
    });
  };

  const unsubsribeActionCreator = (payload) => {
    return new UnsubscribeAction({
      type: `${type}_UNSUBSCRIBE`,
      destination: payload,
    });
  };

  return [subsribeActionCreator, unsubsribeActionCreator];
}

export class SocketAction {
  type;

  token() {
    return this.type;
  }
}

export class UnsubscribeAction extends SocketAction {
  constructor(data = {}) {
    super();
    const { type, destination } = data;
    this.type = type;
    this.destination = destination;
  }
}

export class SubscribeAction extends SocketAction {
  type;
  destination;
  event;
  params;

  constructor(data) {
    super();
    this.type = data.type;
    this.callback = data.callback;
    this.destination = data.destination;
    this.event = data.event;
    this.params = data.params;
  }
}

const processAction = (action, dispatch) => {
  if (action instanceof UnsubscribeAction) {
    if (action.destination) {
      if (sockets.size) {
        clearTimeout(timers.get(action.destination));
        let socket = sockets.get(action.destination);
        if (
          socket &&
          (socket.readyState === socket.OPEN ||
            socket.readyState === socket.CONNECTING)
        ) {
          socket.onclose = function (e) {
            console.info('socket closed as expected');
          };
          socket.close();
          sockets.delete(action.destination);
        }
      }
    } else {
      [...sockets.entries()].forEach((entry) => {
        clearTimeout(timers.get(entry[0]));
        let socket = entry[1];
        if (
          socket &&
          (socket.readyState === socket.OPEN ||
            socket.readyState === socket.CONNECTING)
        ) {
          socket.onclose = function (e) {
            console.info('socket closed as expected');
          };
          socket.close();
        }
      });
      sockets.clear();
    }
  }

  if (action instanceof SubscribeAction) {
    let socket = sockets.get(action.destination);

    let act = (frame) => {
      try {
        let data = JSON.parse(frame.data);
        dispatch(
          action.callback.call(
            action,
            { data, event: action.event, params: action.params },
            dispatch
          )
        );
      } catch (e) {
        console.error(e);
      }
    };

    if (!socket || socket.readyState !== socket.OPEN) {
      connect(action.destination, act, action.params);
    }
  }
};

const ping = (url, cb) => {
  cb();
  let timer = setTimeout(() => ping(url, cb), CHECK_DEVICES_UPDATES_INTERVAL);
  timers.set(url, timer);
};

const socketMiddleWare = (store) => (next) => (action) => {
  const isSub = action instanceof SubscribeAction;
  const isUnSub = action instanceof UnsubscribeAction;

  if (!isSub && !isUnSub) return next(action);

  processAction(action, store.dispatch);
};
