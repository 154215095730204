import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import {
  resetUpload,
  setUploadInitialProgress,
  setUploadProgress,
  uploadFailed,
  uploadStarted,
  uploadSuccess,
} from './upload.action';
import client, { clientFS } from '../../utils/client';
import services from '../../utils/services';
import { notEmpty } from '../../common/helpers';
import PopupActions from '../popup/popup.actions';
import {
  getAPKMetaData,
  sortAppFilesMetaByNameAndVersionCode,
} from '../../common/utilities/applications';
import {
  AddFileRoute,
  duplicateUploadFailureMessageText,
  saveLocations,
  successfulUploadMessageText,
  uploadFailureMessageText,
} from './upload.constants';
import { FILE_TYPES } from '../../constants/fileTypes';
import { companyIdSelector } from '../login/login.selectors';
import { getState } from '../../store/store';
import { loadAllFiles } from '../../redux/files.actions';
import { loadCertificates } from '../certificates/certificates.thunk';
import { loadApplications } from '../applications/applications.thunk';
import { history } from '../../utils/history';

export function uploadX(files, saveLocation) {
  return async (dispatch) => {
    try {
      dispatch(uploadStarted());
      const companyId = companyIdSelector(getState());

      let filesMeta = await Promise.all(
        files.map(async (file) => {
          const isFileAPK = file.type === FILE_TYPES.APK;
          if (isFileAPK) {
            return getAPKMetaData(file, { saveLocation });
          }

          return {
            save_to: saveLocation,
            content_type: file.type?.length ? file.type : FILE_TYPES.PLAIN,
            key: file.name,
            filename: file.name,
          };
        })
      );

      if (saveLocation === saveLocations.APPLICATIONS) {
        filesMeta = sortAppFilesMetaByNameAndVersionCode(filesMeta);
      }

      const {
        status,
        data: { upload_links = [], duplicated_files = [] } = {},
      } = await client.put(services.uploadFiles, filesMeta);

      const duplicatedFiles = duplicated_files.map(({ file }) => file);
      let uploaded = [];
      let failed = [];
      let successfullySavedOnServer = false;

      if (status === 200 && upload_links?.length > 0) {
        dispatch(
          setUploadInitialProgress(
            upload_links.map((r) => ({ key: r.file, uploadPercentage: 0 }))
          )
        );
        const uploadFileProgressTracking = async function l(r) {
          let file = files.find((file) => file.name === r.file);
          return await uploadFileS3Api(
            r.link,
            file,
            companyId,
            (progressEvent) => {
              const uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total),
                10
              );
              dispatch(setUploadProgress({ key: r.file, uploadPercentage }));
            }
          );
        };
        const uploadingToBucketResult = await Promise.all(
          upload_links.map(uploadFileProgressTracking)
        );

        let {
          uploaded: uploadedToBucket,
          failed: failedToUploadToBucket,
        } = uploadingToBucketResult.reduce(
          (acc, { config, status }) => {
            const filename = config?.data?.name;
            if (status === 200) {
              return {
                ...acc,
                uploaded: [...acc.uploaded, filename],
              };
            }
            return {
              ...acc,
              failed: [...acc.failed, filename],
            };
          },
          {
            uploaded: [],
            failed: [],
          }
        );

        const uploadedFilesMeta = filesMeta.reduce((acc, curr) => {
          if (uploadedToBucket.includes(curr.filename)) {
            return [...acc, curr];
          }
          return acc;
        }, []);

        failed = failedToUploadToBucket;

        if (notEmpty(uploadedToBucket)) {
          const {
            status: saveToServerStatus,
            data: {
              failed: failedToSaveToServer = [],
              saved: savedToServer = [],
            } = {},
          } = await client.post(services.uploadFiles, uploadedFilesMeta);
          if (saveToServerStatus === 200) {
            successfullySavedOnServer = true;
            dispatch(uploadSuccess());
          } else {
            dispatch(uploadFailed(I18n.t('fileSystem.uploadFailed')));
          }
          uploaded = savedToServer;
          failed = [...failed, ...failedToSaveToServer];
        }
      }

      const textList = [
        successfullySavedOnServer && uploaded.length
          ? `${
              successfulUploadMessageText[saveLocation] ??
              successfulUploadMessageText[saveLocations.FILES]
            }`
          : null,
        ...uploaded,

        ...(duplicatedFiles.length
          ? [
              `${
                duplicateUploadFailureMessageText[saveLocation] ??
                duplicateUploadFailureMessageText[saveLocations.FILES]
              }`,
            ]
          : []),
        ...(duplicatedFiles.length ? duplicatedFiles : []),

        ...(failed.length
          ? [
              `${
                uploadFailureMessageText[saveLocation] ??
                uploadFailureMessageText[saveLocations.FILES]
              }`,
            ]
          : []),
        ...(failed.length ? failed : []),
      ];

      PopupActions.showAlert({
        textList,
        onClose: () => {
          const companyId = companyIdSelector(getState());
          const reloadEntityListFunctions = {
            [saveLocations.APPLICATIONS]: () => loadApplications(),
            [saveLocations.HTML]: () => loadAllFiles(companyId),
            [saveLocations.CERTIFICATES]: () => loadCertificates(companyId),
            [saveLocations.FILES]: () => loadAllFiles(companyId),
          };
          dispatch(resetUpload());
          dispatch(reloadEntityListFunctions[saveLocation]?.());
        },
      });
    } catch (error) {
      dispatch(uploadFailed(I18n.t('fileSystem.uploadFailed')));
    }
  };
}

export async function uploadFileS3Api(
  signedUrl,
  file,
  company_id,
  onUploadProgress
) {
  const instance = axios.create();
  return instance.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type && file.type.length ? file.type : 'text/plain',
      'x-amz-acl': 'bucket-owner-full-control',
    },
    params: { company_id },
    onUploadProgress,
  });
}

export function sendLocationLinks(fileLinks, saveLocation) {
  return async (dispatch) => {
    try {
      const externalLinks = fileLinks.map(({ value }) => value);
      const payload = {
        save_to: saveLocation,
        external_links: externalLinks,
      };

      const { status } = await clientFS.post(
        services.createRemoteFiles,
        payload
      );
      if (status === 204) {
        PopupActions.showAlert({
          text: I18n.t('fileSystem.sendRemoteLinkSuccess'),
          onClose: () => {
            history.push(`${AddFileRoute}/apk`);
          },
        });
      } else {
        PopupActions.showAlert({
          text: I18n.t('fileSystem.sendRemoteLinkFailure'),
        });
      }
    } catch (e) {}
  };
}
