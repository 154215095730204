import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Button } from '../../components/button.component';
import { changeEnrollmentProperty } from '../enrollment/enrollment.actions';
import { createEnrollment } from '../enrollment/enrollment.thunk';
import { companyIdSelector } from '../login/login.selectors';
import { EnrollmentWizardRoutes } from './CreateEnrollment.constants';
import { history } from '../../utils/history';
import { DevicesRoutes, RootRoutes } from '../../utils/routes';
import { get } from 'lodash';
import { generateDeviceNamingExample } from '../../common/utilities/devices';
import { fleetsV2Selector } from '../fleets/fleetsV2.selectors';

class NewEnrollmentReview extends Component {
  render() {
    const startingNumber = get(
      this.props,
      'newEnrollment.deviceNaming.startingNumber'
    );
    const resultNumber =
      startingNumber != null && !isNaN(Number(startingNumber))
        ? Number(startingNumber)
        : 0;
    const naming = this.generateExample(resultNumber);
    const namingDescription = naming
      ? `${naming} (${I18n.t(
          'enrollments.startingFromPrefix'
        )} ${resultNumber})`
      : '';
    const examples = naming ? (
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <div>{I18n.t('enrollments.namingExampleHeader')}</div>
        <div style={{ marginTop: '15px' }}>{`${naming},`}</div>
        <div style={{ marginTop: '15px' }}>{`${this.generateExample(
          resultNumber + 1
        )},`}</div>
        <div style={{ marginTop: '15px' }}>{`${this.generateExample(
          resultNumber + 2
        )}...`}</div>
      </div>
    ) : null;

    return (
      <div className="creation-wizard-step-container creation-wizard-step-container--centered">
        <div className="creation-wizard-step-inner-container">
          <div className="creation-wizard-review-step__review-item">
            <div className="creation-wizard-review-step__review-item-title-area">
              <div className="creation-wizard-review-step__review-item-title">
                {I18n.t('enrollments.enrollmentName')}
              </div>
              <Link
                to={`${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create`}
                className="creation-wizard-review-step__review-item-edit-nav"
              >
                {I18n.t('enrollments.edit')}
              </Link>
            </div>
            <div className="creation-wizard-review-step__review-item-value">
              {this.props.newEnrollment?.name ?? ''}
            </div>
          </div>

          <div className="creation-wizard-review-step__review-item creation-wizard-review-step__review-item--with-top-margin">
            <div className="creation-wizard-review-step__review-item-title-area">
              <div className="creation-wizard-review-step__review-item-title">
                {I18n.t('enrollments.selectedFleet')}
              </div>
              <Link
                to={`${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create${EnrollmentWizardRoutes.FLEET}`}
                className="creation-wizard-review-step__review-item-edit-nav"
              >
                {I18n.t('enrollments.edit')}
              </Link>
            </div>
            <div className="creation-wizard-review-step__review-item-value">
              {this.getRelatedFleetName()}
            </div>
          </div>

          <div className="creation-wizard-review-step__review-item creation-wizard-review-step__review-item--with-top-margin">
            <div className="creation-wizard-review-step__review-item-title-area">
              <div className="creation-wizard-review-step__review-item-title">
                {I18n.t('enrollments.deviceNamingSectionTitle')}
              </div>
              <Link
                to={`${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create${EnrollmentWizardRoutes.NAMING}`}
                className="creation-wizard-review-step__review-item-edit-nav"
              >
                {I18n.t('enrollments.edit')}
              </Link>
            </div>
            <div className="creation-wizard-review-step__review-item-value">
              {namingDescription}
            </div>

            {examples ? (
              <div className="creation-wizard-review-step__review-item-value">
                {examples}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 'auto' }}>
          <Button
            secondary
            className={
              'create-enrollment-wizard__control-button create-enrollment-wizard__control-button--first'
            }
            onClick={this.goBack}
          >
            ⟵ {I18n.t('enrollments.prevStepButton')}
          </Button>
          <Button
            className={'create-enrollment-wizard__control-button'}
            onClick={this.createEnrollment}
          >
            {I18n.t('enrollments.confirm')}
          </Button>
        </div>
      </div>
    );
  }

  goBack = () => {
    history.push(
      `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create${EnrollmentWizardRoutes.NAMING}`
    );
  };

  generateExample = (startingNumber = 0) => {
    const {
      newEnrollment: { deviceNaming: { sequence, separator, name } = {} } = {},
    } = this.props;
    return generateDeviceNamingExample({
      sequence,
      separator,
      startingNumber,
      name,
    });
  };

  getRelatedFleetName = () => {
    if (this.props.newEnrollment.fleetName) {
      return this.props.newEnrollment.fleetName;
    }
    if (this.props.newEnrollment.fleetId && this.props.fleets) {
      let foundFleet = this.props.fleets.find(
        (fleet) => fleet.id === this.props.newEnrollment.fleetId
      );
      if (foundFleet) {
        return this.props.fleets.find(
          (fleet) => fleet.id === this.props.newEnrollment.fleetId
        ).name;
      }
    }
    return '';
  };

  createEnrollment = () => {
    this.props.createEnrollment({
      ...this.props.newEnrollment,
      companyId: this.props.companyId,
      status: 'active',
    });
  };
}

const mapStateToProps = (state) => {
  const { data: fleets } = fleetsV2Selector(state);
  return {
    companyId: companyIdSelector(state),
    newEnrollment: state.enrollment.newEnrollment,
    fleets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeEnrollmentProperty: ({ key, value }) =>
      dispatch(changeEnrollmentProperty({ key, value })),
    createEnrollment: (data) => dispatch(createEnrollment(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEnrollmentReview);
