export const loginSelector = (state) => state.login;

export const logoutStatusSelector = (state) => state.login.logoutStatus;

export const loginDataSelector = (state) => loginSelector(state).data;

export const companyIdSelector = (state) => {
  const data = loginDataSelector(state);
  return data && data.companyId && data.companyId.length
    ? data.companyId
    : undefined;
};

export const partnerIdSelector = (state) => {
  const data = loginDataSelector(state);
  return data && data.partnerId && data.partnerId.length
    ? data.partnerId
    : undefined;
};

export const authenticationSelector = (state) => {
  const login = loginSelector(state);
  if (login) {
    return login.isAuthenticated;
  } else {
    return undefined;
  }
};

export const roleSelector = (state) => {
  const data = loginDataSelector(state);
  return data ? data.role : undefined;
};
