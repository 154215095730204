export const RootRoutes = {
  ROOT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  HOME: '/home',
  DEVICES: '/devices',
  PROFILES: '/profiles',
  SETTINGS: '/settings',
  APPLICATIONS: '/applications',
  FILE_SYSTEM: '/file-system',
  HELP: '/help',
  USERS: '/user-manager',
  ALERTS: '/alert-manager',
  API: '/api-manager',
};

export const SSOResponseRoutes = {
  AZURE_OAUTH2: '/azure_oauth2',
  AZURE_SAML2: '/azure_saml/pre_login',
};

export const HomeRoutes = {
  DASHBOARD: '/dashboard',
  MAP: '/mapview',
};

export const DevicesRoutes = {
  ALL: '/all',
  FLEETS: '/fleetsV1',
  FLEETS_V2: '/fleetsV2',
  ENROLLMENTS: '/enrollments',
};

export const DeviceRoutes = `${RootRoutes.DEVICES}${DevicesRoutes.ALL}`;
export const FleetV1Route = `${RootRoutes.DEVICES}${DevicesRoutes.FLEETS}`;
export const FleetRoute = `${RootRoutes.DEVICES}${DevicesRoutes.FLEETS_V2}`;

export const ApplicationsRoutes = {
  MY: '/my',
  UPLOAD: '/upload',
  FILES: '/files',
  CERTIFICATES: '/certificates',
  HTML_KIOSK_FILES: '/html-kiosk-files',
};

export const ProfilesRoutes = {
  ALL: '/allV1',
  ALL_V2: '/allV2',
  TASKS: '/tasks',
};

export const SettingsRoutes = {
  ACCOUNT: '/account',
  PASSWORD: '/password',
  INFO: '/company',
  COMMON: '/common',
  SSO: '/sso',
  PREFERENCES: '/preferences',
};

export const HelpRoutes = {
  DOCS: '/docs',
};
export const UsersRoutes = {
  GROUPS: '/groups',
  CREATE: '/create',
  EDIT: '/edit',
  LOGS: '/logs',
  NEWUSER: '/newuser',
};

export const AlertRoutes = {
  ALERTS: '/alerts',
  CREATE: '/create',
};

export const APIRoutes = {
  OVERVIEW: '/overview',
  USAGE_HISTORY: '/usage-history',
};

export const SystemRoutes = new Set()
  .add(RootRoutes.ROOT)
  .add(RootRoutes.LOGIN)
  .add(RootRoutes.LOGOUT);

export const PublicRoutes = new Set([
  RootRoutes.LOGIN,
  ...Object.values(SSOResponseRoutes),
]);
