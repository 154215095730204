import React from 'react';
import { selectForCurrentBrand } from '../common/helpers';

export class ActionButton extends React.Component {
  render() {
    const { title, onClick, children, className, style } = this.props;
    return (
      <div
        className={`action-button ${
          className
            ? className
            : selectForCurrentBrand({
                original: 'icon--original',
                incube: 'icon--incube',
                janam: 'icon--janam',
              })
        }`}
        title={title}
        onClick={onClick}
        style={style}
      >
        {children}
      </div>
    );
  }
}
