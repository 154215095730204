import React, { Component, Fragment } from 'react';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import { UncontrolledCollapse } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import { companyIdSelector } from '../../../login/login.selectors';
import Dialog from '../../../../components/Dialog';
import FleetsTree from '../../../fleets/components/fleetsTree.component';
import { Button } from '../../../../components/button.component';
import { selectForCurrentBrand } from '../../../../common/helpers';
import { fleetsV2Selector } from '../../../fleets/fleetsV2.selectors';
import { loadAllFleetsV2 } from '../../../fleets/fleetsV2.thunk';

export const menuStructure = [{ key: 'Select Fleet' }];

export class FleetsSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: [],
      selectedCategory: I18n.t('devices.selectFleet'),
    };
  }

  componentDidMount() {
    this.props.loadAllFleets();
  }

  onSelect = (fleetIds) => this.setState(() => ({ ids: fleetIds }));

  onAssign = () => {
    this.props.onAssign(this.state.ids);
    this.props.onBack();
  };

  selectCategory = (selectedCategory) => {
    this.setState({ selectedCategory });
  };

  render() {
    if (!this.props.selected || !this.props.selected.size) {
      return null;
    }

    return this.renderDialog();
  }

  renderDialog = () => {
    return (
      <Dialog
        header={this.renderHeader}
        menu={this.renderMenu}
        content={this.renderContent}
        onBack={this.props.onBack}
      />
    );
  };

  renderHeader = () => {
    const selectedDevices = [...this.props.selected.values()]
      .map((device) => device.profile_name || '')
      .filter(Boolean)
      .join(', ');
    return (
      <Fragment>
        <div className={'profile-header'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <h6 className={'profile-header__title'}>
              {I18n.t('devices.associationHint')}
            </h6>
            <div className={'profile-header__caption-text'}>
              {selectedDevices || ''}
            </div>
          </div>
        </div>

        <div className={'profile-actions__section'}>
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Button
              disabled={!this.state.ids || !this.state.ids.length}
              onClick={this.onAssign}
            >
              {I18n.t('devices.assignmentButtonText')}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  };

  renderMenu = () => {
    return (
      <React.Fragment>{menuStructure.map(this.renderMenuItem)}</React.Fragment>
    );
  };

  renderMenuItem = (item) => {
    const hasSubItems = item.sub && item.sub.length;
    const itemClass = selectForCurrentBrand({
      original: 'profile-menu__item',
      incube: 'profile-menu__item--incube',
      janam: 'profile-menu__item--janam',
    });
    const activeItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--active',
      incube: 'profile-menu__item--incube--active',
      janam: 'profile-menu__item--janam--active',
    });
    const intermediateItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--indeterminate',
      incube: 'profile-menu__item--incube--indeterminate',
      janam: 'profile-menu__item--janam--indeterminate',
    });
    const itemClasses = ClassNames(itemClass, {
      [activeItemClass]: this.state.selectedCategory === item.key,
      [intermediateItemClass]:
        hasSubItems &&
        item.sub.some((subItem) => subItem.key === this.state.selectedCategory),
    });
    const clickHandler = (e) =>
      this.selectCategory(hasSubItems ? item.sub[0].key : item.key);
    return (
      <Fragment>
        <h4
          id={hasSubItems ? `${item.key}` : undefined}
          className={itemClasses}
          onClick={clickHandler}
        >
          {item.key ? item.key : ''}
        </h4>
        {hasSubItems && (
          <div style={{ marginLeft: '10px' }}>
            <UncontrolledCollapse toggler={`#${item.key}`}>
              {item.sub.map((item) => this.renderMenuItem(item))}
            </UncontrolledCollapse>
          </div>
        )}
      </Fragment>
    );
  };

  renderContent = () => {
    switch (this.state.selectedCategory) {
      case 'Select Fleet': {
        return (
          <div
            style={{
              width: '75%',
              maxWidth: '600px',
              minWidth: '200px',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              paddingRight: '20px',
              padding: '38px 60px',
            }}
          >
            <div style={{ marginBottom: '15px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '18px' }}>
                  {I18n.t('devices.selectedFleet')}
                </div>
              </div>
            </div>
            <FleetsTree
              fleets={this.props.fleets || []}
              onSelect={this.onSelect}
              defaultSelectedKeys={this.state.ids || []}
              selectedKeys={this.state.ids || []}
              multiple={true}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };
}

const mapStateToProps = (state) => {
  const { data: fleets, status: fleetsStatus } = fleetsV2Selector(state);
  return {
    fleets,
    fleetsStatus,
    companyId: companyIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllFleets: () => dispatch(loadAllFleetsV2()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetsSelection);
