import { I18n } from 'react-redux-i18n';
import client from '../../utils/client';
import services from '../../utils/services';
import { deserializeApplication } from '../application/application.serializer';
import {
  applicationsDeleted,
  applicationsDeletingFailed,
  applicationsDeletingStarted,
  applicationsLoaded,
  loadApplicationsFailed,
  loadApplicationsFullListFailed,
  loadApplicationsFullListStarted,
  loadApplicationsFullListSuccess,
  loadApplicationsStarted,
} from './applications.actions';
import PopupActions from '../popup/popup.actions';
import { deleteApplicationsApi } from './applications.api';
import { getState } from '../../store/store';
import {
  getObjectFromListBySpecificProperty,
  notEmpty,
} from '../../common/helpers';
import { loadApplication } from '../application/application.thunk';
import { history } from '../../utils/history';
import { ApplicationsRoutes, RootRoutes } from '../../utils/routes';
import { profilesSelector, profilesV2Selector } from '../../selectors/profiles';

export function loadApplications() {
  return async (dispatch) => {
    try {
      dispatch(loadApplicationsStarted());
      let response = await client.get(services.getAllApplication);
      if (response.status === 200 && response.data) {
        let result = response.data.map(deserializeApplication);
        dispatch(applicationsLoaded(result));
      } else {
        dispatch(
          loadApplicationsFailed(I18n.t('applications.applicationsFetchFailed'))
        );
      }
    } catch (error) {
      dispatch(
        loadApplicationsFailed(I18n.t('applications.applicationsFetchFailed'))
      );
    }
  };
}

export const loadApplicationsFullList = () => {
  return async (dispatch) => {
    try {
      dispatch(loadApplicationsFullListStarted());
      let { status, data = [] } = await client.get(
        services.getAllApplicationNewProfile
      );
      if (status === 200) {
        const result = data.map(deserializeApplication);
        dispatch(loadApplicationsFullListSuccess(result));
      } else {
        dispatch(
          loadApplicationsFullListFailed('Failed to load app full list')
        );
      }
    } catch (error) {
      dispatch(loadApplicationsFullListFailed('Failed to load app full list'));
    }
  };
};

export const deleteApplications = (
  apps,
  displayedVersionId,
  allApplicationVersionsIds
) => {
  return async (dispatch) => {
    try {
      dispatch(applicationsDeletingStarted());
      const APPLICATION_DELETE_LOADER_ID = 'APPLICATION_DELETE_LOADER';
      PopupActions.showLoader(APPLICATION_DELETE_LOADER_ID);
      let response = await deleteApplicationsApi({ apps });
      if ([200, 400].includes(response.status)) {
        const {
          data: { failed: failedToDelete, deleted: successfullyDeleted } = {},
        } = response;
        const { data: profiles } = profilesSelector(getState());
        const { data: profilesV2 } = profilesV2Selector(getState());
        const profilesById = getObjectFromListBySpecificProperty(profiles);
        const profilesV2ById = getObjectFromListBySpecificProperty(profilesV2);

        const textList = [];
        const deserialized = successfullyDeleted.map(deserializeApplication);
        const successfullyDeletedIds = deserialized.map((item) => item.id);

        successfullyDeleted.length &&
          textList.push(
            I18n.t('fileSystem.versionsDeletedTitle'),
            ...deserialized.map((version) => version.version)
          );

        const failedToDeleteV1 = failedToDelete?.profiles_v1;
        const failedToDeleteV2 = failedToDelete?.profiles_v2;

        failedToDeleteV1 &&
          Object.keys(failedToDeleteV1).forEach((profile) => {
            textList.push(
              I18n.t('fileSystem.versionsNotDeletedByAssignedToProfileTitle', {
                profile: profilesById[profile]?.name,
              }),
              ...failedToDeleteV1[profile].map(
                ({ app_version: version }) => version
              )
            );
          });
        failedToDeleteV2 &&
          Object.keys(failedToDeleteV2).forEach((profile) => {
            textList.push(
              I18n.t('fileSystem.versionsNotDeletedByAssignedToProfileTitle', {
                profile: profilesV2ById[profile]?.name,
              }),
              ...failedToDeleteV2[profile].map(
                ({ app_version: version }) => version
              )
            );
          });

        PopupActions.hideLoader(APPLICATION_DELETE_LOADER_ID).then(() => {
          PopupActions.showAlert({
            title: I18n.t('fileSystem.versionsDeletionTitle'),
            textList,
            onClose: () => {
              const appIdsExcludingSuccessfulIds = allApplicationVersionsIds.filter(
                (id) => !successfullyDeletedIds.includes(id)
              );
              const id = successfullyDeletedIds.includes(displayedVersionId)
                ? appIdsExcludingSuccessfulIds[0]
                : displayedVersionId;

              if (successfullyDeleted.length) {
                dispatch(loadApplication(id));
                dispatch(loadApplications());
                if (
                  successfullyDeleted.length ===
                  allApplicationVersionsIds.length
                ) {
                  history.push(
                    `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}`
                  );
                }
              }
              dispatch(applicationsDeleted());
            },
          });
        });
      } else {
        dispatch(
          applicationsDeletingFailed(
            I18n.t('fileSystem.versionsDeletionFailed')
          )
        );
      }
    } catch (error) {
      dispatch(
        applicationsDeletingFailed(I18n.t('fileSystem.versionsDeletionFailed'))
      );
    }
  };
};
