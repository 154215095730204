import React, { FC, useState, useMemo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { isNil } from 'lodash';
import {
  filterItemsByAttributes,
  getAvailableFilters,
  getVisibleTableColumns,
  notEmpty,
  sortItemsByAttribute,
} from '../../common/helpers';
import DataTableInnerWrapper from './DataTableInnerWrapper.component';
import { filtersSelector } from '../../selectors/filters';
import ColumnsToggle from '../ColumnsToggle/ColumnsToggle.component';
import Filter from '../Filter';
import ExportToCSV from '../ExportToCSV/ExportToCSV.component';
import { columnsToggleSelector } from '../../selectors/columnsToggle';
import { IColumn, ISortBy } from './DataTable.types';
import { NetworkStatusType } from '../../common/types';
import DateRangePicker from '../DateRangePicker/DateRangePicker.component';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import SingleCard from '../Card/SingleCard.component';

interface DataTableProps {
  allColumns: IColumn[];
  data: object[];
  pageId: string;
  useCSVExport?: boolean;
  useColumnsToggle?: boolean;
  useFilter?: boolean;
  useFlex?: boolean;
  leftCustomHeaderAction?: JSX.Element;
  rightCustomHeaderAction?: JSX.Element;
  headerTitle?: ReactNode;
  tableRef?: React.RefObject<HTMLDivElement>;
  loadingStatus?: NetworkStatusType;
  selectable?: boolean;
  useResizableColumns?: boolean;
  sortable?: boolean;
  useDateRangePicker?: boolean;
  allColumnsResizable?: boolean;
  customCSVFileName?: string;
  onDateRangeChange?: (
    greaterThanOrEqual: Moment,
    lessThanOrEqual: Moment
  ) => void;
  dateRangePickerAdditionalProps?: object;
}

const DataTable: FC<DataTableProps> = ({
  allColumns,
  data: items = [],
  pageId = null,
  useCSVExport = false,
  useColumnsToggle = false,
  useFilter = false,
  useDateRangePicker,
  onDateRangeChange,
  dateRangePickerAdditionalProps = {},
  leftCustomHeaderAction = null,
  rightCustomHeaderAction = null,
  headerTitle = null,
  tableRef,
  customCSVFileName,
  ...restProps
}) => {
  const [sortBy, setSortBy] = useState<ISortBy[]>(null);
  const { isCriteriaStrict, filters } = useSelector(filtersSelector);
  const { visibleColumnsLists = {} } = useSelector(columnsToggleSelector);

  if ([useCSVExport, useColumnsToggle].some(Boolean) && !pageId) {
    throw new Error(
      'In order to use the ExportToCSV or ColumnsToggle feature, you must provide a pageId prop.'
    );
  }

  if (!isNil(restProps.selectable) && !tableRef) {
    throw new Error(
      `A selectable table (prop 'selectable' set to true) must also have a tableRef passed in as a prop`
    );
  }

  if (!isNil(useDateRangePicker) && !onDateRangeChange) {
    throw new Error(
      `In order to use the DateRangePicker feature, an onDateRangeChange callback function must be passed in as a prop`
    );
  }

  if (!isNil(restProps.allColumnsResizable) && !restProps.useResizableColumns) {
    throw new Error(
      `In order to make all columns resizable, the table must first be set to allow resizable columns. ('useResizableColumns' must be passed in as true)`
    );
  }

  const visibleColumnsList = visibleColumnsLists[pageId];
  const columns = useMemo<IColumn[]>(
    () =>
      useColumnsToggle
        ? getVisibleTableColumns(allColumns, visibleColumnsList)
        : allColumns,
    [
      useColumnsToggle,
      getVisibleTableColumns,
      JSON.stringify(allColumns),
      JSON.stringify(visibleColumnsList),
    ]
  );

  const availableFilters = useMemo(() => getAvailableFilters(filters), [
    filters,
  ]);
  let data = useMemo(
    () =>
      filterItemsByAttributes(
        items,
        columns,
        isCriteriaStrict,
        availableFilters
      ),
    [JSON.stringify(items), columns, availableFilters, isCriteriaStrict]
  );

  if (sortBy?.length) {
    const [{ id: columnId, desc }] = sortBy;
    data = sortItemsByAttribute({
      items: data,
      attributes: columns,
      sortedAttributeKey: columnId,
      order: desc ? 'DSC' : 'ASC',
    });
  }

  const searchableColumns = useMemo<IColumn[]>(
    () => columns.filter(({ searchable }) => searchable),
    [columns]
  );

  const areSearchableColumnsValid = useMemo(
    () =>
      searchableColumns.every((column) =>
        notEmpty(column.availableFilterConditions)
      ),
    [searchableColumns]
  );

  if (useFilter && !areSearchableColumnsValid) {
    throw new Error(
      `All searchable columns must have a property of 'availableFilterConditions', which is an array of allowed filter conditions.
       Please check the list provided as the 'allColumns' prop to make sure all the columns provided a property of searchable with a value
        of true have availableFilterConditions also provided.
        `
    );
  }

  const showHeader = useMemo(() => {
    return [
      headerTitle,
      leftCustomHeaderAction,
      useCSVExport,
      useColumnsToggle,
      useFilter,
      useDateRangePicker,
      rightCustomHeaderAction,
    ].some(Boolean);
  }, [
    headerTitle,
    leftCustomHeaderAction,
    rightCustomHeaderAction,
    useCSVExport,
    useColumnsToggle,
    useFilter,
    useDateRangePicker,
  ]);

  const handleDateRangeChange = ({ startDate, endDate }) => {
    onDateRangeChange?.(startDate, endDate);
  };

  return (
    <>
      {showHeader && (
        <section className="single-card-header">
          <div>{headerTitle}</div>
          <section className="card-header-actions">
            <section className="card-header--custom-action">
              {leftCustomHeaderAction}
            </section>
            {useDateRangePicker && (
              <section className="card-header--custom-action">
                <DateRangePicker
                  onRangeChange={handleDateRangeChange}
                  startDatePlaceholderText={I18n.t('common.startDate')}
                  endDatePlaceholderText={I18n.t('common.endDate')}
                  isOutsideRange={() => false}
                  showClearDates
                  showDefaultInputIcon
                  inputIconPosition="after"
                  anchorDirection="right"
                  allowSameDayRange
                  small
                  {...dateRangePickerAdditionalProps}
                />
              </section>
            )}
            {useCSVExport && (
              <ExportToCSV
                title={I18n.t('dataTable.csvExportIconHint')}
                data={data}
                columns={columns}
                fileName={
                  customCSVFileName
                    ? `${customCSVFileName}.csv`
                    : `${pageId}.csv`
                }
                disabled={!data.length}
              />
            )}
            {useColumnsToggle && (
              <div style={{ marginRight: '10px' }}>
                <ColumnsToggle allColumns={allColumns} page={pageId} />
              </div>
            )}
            {/** @ts-ignore */}
            {useFilter && <Filter columns={searchableColumns} />}
            <section className="card-header--custom-action">
              {rightCustomHeaderAction}
            </section>
          </section>
        </section>
      )}
      <SingleCard>
        <DataTableInnerWrapper
          data={data}
          columns={columns}
          setSortBy={setSortBy}
          availableFilters={availableFilters}
          ref={tableRef}
          {...restProps}
        />
      </SingleCard>
    </>
  );
};

export default DataTable;
