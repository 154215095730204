import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as TrashEmpty } from '../assets/images/trash_empty.svg';
import Tool from '../components/ToolBar/Tool';
import {
  assignFilesToProfiles,
  assignFilesToProfilesReset,
  deleteFiles,
  loadAllFiles,
} from '../redux/files.actions';
import { companyIdSelector } from '../features/login/login.selectors';
import ToolsPanel from '../components/ToolsPanel';
import {
  filesLoadingStatusSelector,
  filesSelector,
} from '../redux/files.selectors';
import { AttributeType } from '../common/constants';
import PopupActions from '../features/popup/popup.actions';
import UserHelpers from '../common/utilities/user';
import { isDataLoadingRequired } from '../common/helpers';
import { Pages } from '../constants/pages';
import DataTable from '../components/DataTable/DataTable.component';
import { filterConditions } from '../constants/filter';

export const filesColumns = [
  {
    key: 'filename',
    type: AttributeType.TEXT,
    titleCode: 'fileSystem.fileName',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'contentType',
    type: AttributeType.TEXT,
    titleCode: 'fileSystem.contentType',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'uploadedByName',
    titleKey: 'uploadedByEmail',
    type: AttributeType.TEXT,
    titleCode: 'fileSystem.uploadedBy',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
];

const searchableColumns = filesColumns.filter(({ searchable }) => searchable);

const AllFiles = ({
  status,
  loadFiles,
  companyId,
  files,
  hasAdminPrivileges,
  deleteFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(() => new Set());
  const tableRef = useRef();

  useEffect(() => {
    if (isDataLoadingRequired(status)) {
      loadFiles(companyId);
    }
  }, [status, loadFiles, companyId]);

  const onSelect = (selectedIds) => {
    const selected = new Set(selectedIds);
    setSelectedFiles(selected);
  };

  const resetSelectedFiles = () => setSelectedFiles(() => new Map());

  const onDelete = () =>
    PopupActions.showConfirm({
      onConfirm: () => {
        deleteFiles([...selectedFiles.keys()]);
        resetSelectedFiles();
      },
      text: I18n.t('fileSystem.deletionConfirmation'),
    });

  const showToolBar = selectedFiles.size;
  const contentClassName = showToolBar
    ? 'app__content app__content--with-toolbar'
    : 'app__content';

  return (
    <>
      <section className={contentClassName}>
        <section className="page-content">
          <section className="card">
            <DataTable
              allColumns={filesColumns}
              data={files}
              onSelect={onSelect}
              pageId={Pages.MY_FILES}
              tableRef={tableRef}
              selectable
              useResizableColumns
              allColumnsResizable
              loadingStatus={status}
              sortable
              useFlex
              useFilter
            />
          </section>
        </section>
      </section>
      {showToolBar ? (
        <ToolsPanel
          title={I18n.t('fileSystem.selectedFiles')}
          selectedItems={selectedFiles}
        >
          <Tool
            title={I18n.t('fileSystem.removeFiles')}
            onClick={onDelete}
            hidden={!hasAdminPrivileges}
          >
            <TrashEmpty className="icon" />
          </Tool>
        </ToolsPanel>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    files: filesSelector(state),
    status: filesLoadingStatusSelector(state),
    companyId: companyIdSelector(state),
    assignFilesStatus: state.files.assignFilesStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadFiles: (companyId) => dispatch(loadAllFiles(companyId)),
    deleteFiles: (files, companyId) => dispatch(deleteFiles(files, companyId)),
    assignFilesToProfiles: (companyId, policy, profiles) =>
      dispatch(assignFilesToProfiles(companyId, policy, profiles)),
    assignFilesToProfilesReset: () => dispatch(assignFilesToProfilesReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllFiles);
