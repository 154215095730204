import { I18n } from 'react-redux-i18n';
import client from './client';
import services from './services';
import RouteHelpers from '../common/utilities/routeHelpers';
import {
  devRedirectUris,
  SSOOptionIds,
  SSOOptions,
  SSOProtocols,
} from '../constants/login';
import PopupActions from '../features/popup/popup.actions';
import url from 'url';
import { authenticationSelector } from '../features/login/login.selectors';
import { getState } from '../store/store';
import queryString from '../common/utilities/queryString';
import { SSOLogin } from '../features/login/login.actions';
import { linkSSOAccount } from '../features/account/account.thunk';
import { compact } from '../common/helpers';

const isDevEnv = process.env.NODE_ENV === 'development';

const getRedirectUri = (optionId) =>
  isDevEnv
    ? {
        redirect_uri: devRedirectUris[optionId],
      }
    : {};

const payloadSerializers = {
  [SSOOptionIds.AZURE_OAUTH2]: ({ code, providerId, optionId }) => ({
    code,
    provider_name: providerId,
    ...getRedirectUri(optionId),
  }),
  [SSOOptionIds.AZURE_SAML2]: ({ uuid, providerId }) => ({
    provider_name: providerId,
    uuid,
  }),
};

const azureSAMLLinkPayloadSerializer = (
  {
    entityId,
    loginUrl,
    organizationId,
    applicationName,
    federationMetadataXMLFile,
    provider,
  },
  withoutXMLFile = false
) => ({
  organization_id: organizationId,
  app_name: applicationName,
  entity_id: entityId,
  sso_url: loginUrl,
  ...(withoutXMLFile ? {} : { xml_file: federationMetadataXMLFile }),
  provider,
});

const getSSOSAMLRedirectUrl = ({ data, protocol, provider }) => {
  const url = `${services.SSO}${SSOProtocols.SAML2}/get_saml_redirect/`;
  // const url = `${services.SSO}${protocol}/${provider}/`;
  return client.post(url, data);
};

const getSSORedirectUrl = ({
  id,
  protocol,
  provider,
  queryParams = {},
  data = {},
}) => {
  const isSAML = protocol === SSOProtocols.SAML2;
  if (isSAML) {
    return getSSOSAMLRedirectUrl({ data, protocol, provider });
  }

  const urlFragment = `${services.SSO}${protocol}/${provider}`;
  const url = RouteHelpers.formatUrlWithQuery(urlFragment, {
    ...(isDevEnv ? { redirect_uri: devRedirectUris[id] } : {}),
    ...queryParams,
  });
  return client.get(url);
};

const redirectToSSOProvider = (params) => {
  return async () => {
    try {
      const response = await getSSORedirectUrl(params);
      if (
        response.status === 200 &&
        response.data &&
        response.data.redirect_url
      ) {
        window.location.assign(response.data.redirect_url);
      } else {
        const errorText =
          response.detail || I18n.t('common.somethingWentWrong');
        throw new Error(errorText);
      }
    } catch (error) {
      PopupActions.showAlert({
        text: error.message,
      });
    }
  };
};

const handleSSOProviderRedirect = (requestUrl) => {
  return async (dispatch) => {
    try {
      const { pathname = '', query = '' } = url.parse(requestUrl);

      const isAccountLinking = authenticationSelector(getState());
      let data;
      let submissionUrl;
      let loginUrl;

      for (const {
        responsePath,
        provider,
        id,
        linkingSubmissionUrl,
        preLoginSubmissionUrl,
        loginSubmissionUrl,
      } of SSOOptions) {
        if (pathname.includes(responsePath)) {
          data = {
            ...queryString.parse(query),
            providerId: provider,
            optionId: id,
          };
          submissionUrl = isAccountLinking
            ? linkingSubmissionUrl
            : preLoginSubmissionUrl;

          loginUrl = loginSubmissionUrl;
          break;
        }
      }
      if (requestUrl) {
        if (isAccountLinking) {
          // link account
          dispatch(
            linkSSOAccount(
              payloadSerializers[data.optionId](data),
              submissionUrl
            )
          );
        } else {
          // login
          dispatch(
            SSOLogin({
              data: payloadSerializers[data.optionId](data),
              preLoginUrl: submissionUrl,
              loginUrl,
            })
          );
        }
      }
    } catch (e) {
      console.log('something went wrong');
    }
  };
};

export const linkAzureSAML2 = (params) => {
  return async () => {
    try {
      const isSettingsUpdate = params.isAzureAdSAMLAlreadySet;
      const withoutXMLFile =
        isSettingsUpdate && !params.federationMetadataXMLFile;
      const data = {
        ...(isSettingsUpdate ? { id: params.id } : {}),
        ...azureSAMLLinkPayloadSerializer(params, withoutXMLFile),
      };
      const formData = new FormData();
      Object.keys(compact(data)).forEach((fieldName) =>
        formData.append(fieldName, data[fieldName])
      );
      const handleLinkingFormSubmission = async () => {
        const { status } = await client[isSettingsUpdate ? 'put' : 'post'](
          services.SSOAzureSAML2LinkUserAccountSettings,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
        if (status === 200) {
          PopupActions.showAlert({
            text: isSettingsUpdate
              ? I18n.t('settings.ssoAccountSettingsUpdateSuccessMessage')
              : I18n.t('settings.ssoAccountLinkSuccessMessage'),
          });
        } else if (status === 400) {
          throw new Error(I18n.t('settings.invalidXMLFileErrorMessage'));
        } else {
          throw new Error(I18n.t('settings.failedToLinkSSOAccountMessage'));
        }
      };
      if (isSettingsUpdate) {
        const {
          status: getCompaniesStatus,
          data: companyList,
        } = await client.get(`${services.SSOSAML2GetCompanies}${params.id}`);
        if (getCompaniesStatus === 200) {
          await PopupActions.showConfirm({
            textList: [
              `${I18n.t('settings.updateWarningText')}:`,
              ...companyList,
              I18n.t('common.proceedConfirmationText'),
            ],
            onConfirm: async () => await handleLinkingFormSubmission(),
          });
        } else {
          throw new Error(I18n.t('common.somethingWentWrong'));
        }
      } else {
        await handleLinkingFormSubmission();
      }
    } catch (error) {
      PopupActions.showAlert({
        text: error.message,
      });
    }
  };
};

const SSO = {
  redirectToSSOProvider,
  handleSSOProviderRedirect,
};

export default SSO;
