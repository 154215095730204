export function userSerializer(data) {
  return {
    username: data.username,
    first_name: data.firstName,
    last_name: data.lastName,
    password: data.password,
    company_name: data.companyName,
    partner_name: data.partnerName,
    group_name: data.groupName,
    is_admin: typeof data.isAdmin === 'boolean' ? data.isAdmin : false,
    is_partner: typeof data.isPartner === 'boolean' ? data.isPartner : false,
  };
}

export const deserializePartnerNameOptions = (data) => {
  return {
    partnerId: data.partner_id,
    partnerName: data.partner_name,
  };
};

export const deserializeCompany = (data) => {
  return {
    id: data.company_id || '',
    name: data.company_name || '',
  };
};
