import { I18n } from 'react-redux-i18n';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Cell, Label, Pie, PieChart, Sector, Text } from 'recharts';
import {
  DEFAULT_DATE_TIME_FORMAT,
  NetworkStatus,
} from '../../../common/constants';
import { Throbber } from '../../../components/Throbber';
import { history } from '../../../utils/history';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
};

export class TaskOverview extends Component {
  state = {
    activeIndex: undefined,
  };

  render() {
    if (!this.props.item) {
      if (this.props.status === NetworkStatus.STARTED) {
        return <Throbber />;
      }
      return null;
    }

    const data = [
      {
        index: 0,
        name: I18n.t('installationStatus.successful'),
        value: isFinite(Number(this.props.item.devicesDeliveredCount))
          ? Number(this.props.item.devicesDeliveredCount)
          : 0,
        fill: '#55d8fe',
      },
      {
        index: 1,
        name: I18n.t('installationStatus.pendingInstallationsTitle'),
        value: isFinite(Number(this.props.item.devicesFailedCount))
          ? Number(this.props.item.devicesFailedCount)
          : 0,
        fill: '#ff8373',
      },
    ];

    return (
      <div style={styles.root}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '15px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '21px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: '0.86',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000000',
                }}
              >
                Overview
              </div>
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: '1.5',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000000',
                  marginTop: '5px',
                }}
              >
                {I18n.t('installationStatus.informationRelatedToTask')}
              </div>
            </div>
            {this.renderAttributes()}
          </div>
          <div
            style={{
              margin: '15px 0',
              borderTop: '1px lightgray solid',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '38px 0 15px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '21px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: '0.86',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000000',
                  marginBottom: '15px',
                }}
              >
                {I18n.t(
                  'installationStatus.successfulAndPendingInstallationTitle'
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'row',
                }}
              >
                {this.renderChart(data)}
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    marginTop: '15px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'row nowrap',
                      alignItems: 'center',
                      marginTop: '15px',
                    }}
                    onMouseEnter={() => this.onPieEnter(undefined, 0)}
                    onMouseLeave={this.onPieLeave}
                  >
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        minWidth: '20px',
                        minHeight: '20px',
                        border: '4px solid #55d8fe',
                        background: '#fff',
                        borderRadius: '50%',
                        marginRight: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    ></div>
                    {data[0].value ? (
                      <NavLink
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: '15px',
                          fontWeight: 'normal',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: '1.67',
                          letterSpacing: 'normal',
                          color: '#43425d',
                          textDecoration:
                            this.state.activeIndex === 0
                              ? 'underline'
                              : undefined,
                        }}
                        to={`success`}
                      >
                        {`${data[0].value} ${I18n.t(
                          'installationStatus.numberOfSuccessfullyInstalledDevices'
                        )}`}
                      </NavLink>
                    ) : (
                      <div
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: '15px',
                          fontWeight: 'normal',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: '1.67',
                          letterSpacing: 'normal',
                          color: '#43425d',
                          textDecoration: 'none',
                        }}
                      >
                        {`${data[0].value} ${I18n.t(
                          'installationStatus.numberOfSuccessfullyInstalledDevices'
                        )}`}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'row nowrap',
                      alignItems: 'center',
                      marginTop: '15px',
                    }}
                    onMouseEnter={() => this.onPieEnter(undefined, 1)}
                    onMouseLeave={this.onPieLeave}
                  >
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        minWidth: '20px',
                        minHeight: '20px',
                        border: '4px solid #ff8373',
                        background: '#fff',
                        borderRadius: '50%',
                        marginRight: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    ></div>
                    {data[1].value ? (
                      <NavLink
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: '15px',
                          fontWeight: 'normal',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: '1.67',
                          letterSpacing: 'normal',
                          color: '#43425d',
                          textDecoration:
                            this.state.activeIndex === 1
                              ? 'underline'
                              : undefined,
                        }}
                        to={`failed`}
                      >
                        {`${data[1].value} ${I18n.t(
                          'installationStatus.numberOfPendingInstallations'
                        )}`}
                      </NavLink>
                    ) : (
                      <div
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: '15px',
                          fontWeight: 'normal',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: '1.67',
                          letterSpacing: 'normal',
                          color: '#43425d',
                          textDecoration: 'none',
                        }}
                      >
                        {`${data[1].value} ${I18n.t(
                          'installationStatus.numberOfPendingInstallations'
                        )}`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAttributes = () => {
    if (this.props.status === NetworkStatus.STARTED) {
      return <Throbber />;
    }

    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
          }}
        >
          <div style={{ marginBottom: '5px' }}>
            {I18n.t('profiles.fields.name')}
          </div>
          <div style={{ fontWeight: 'bold' }}>
            {this.props.item && this.props.item.name
              ? this.props.item.name
              : ''}
          </div>
        </div>
        {/*<div style={{display: 'flex', flexDirection: 'column', marginBottom: '15px'}}>*/}
        {/*    <div style={{marginBottom: '5px'}}>Status</div>*/}
        {/*    <div style={{fontWeight: 'bold'}}>{this.props.item && this.props.item.status ? this.props.item.status : ''}</div>*/}
        {/*</div>*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
          }}
        >
          <div style={{ marginBottom: '5px' }}>
            {I18n.t('installationStatus.lastUpdated')}
          </div>
          <div style={{ fontWeight: 'bold' }}>
            {this.props.item &&
            this.props.item.lastUpdated &&
            moment(this.props.item.lastUpdated).isValid()
              ? moment(this.props.item.lastUpdated).format(
                  DEFAULT_DATE_TIME_FORMAT
                )
              : ''}
          </div>
        </div>
      </Fragment>
    );
  };

  renderChart = (data = []) => {
    return (
      <PieChart width={270} height={270}>
        {/*{ 400 400 for labels}*/}
        <Pie
          activeIndex={this.state.activeIndex}
          activeShape={this.renderActiveShape}
          data={data}
          cx={130}
          cy={130} // {/*{ 200 200 for labels}*/}
          innerRadius={66}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={this.onPieEnter}
          onMouseLeave={this.onPieLeave}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              cursor="pointer"
              fill={entry.fill}
              onClick={() => this.onClick(index)}
            />
          ))}
          {this.state.activeIndex === undefined ? (
            <Label
              content={(props) => {
                const {
                  viewBox: { cx, cy },
                } = props;
                const positioningProps = {
                  x: cx,
                  y: cy,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                };
                const presentationProps = {
                  fill: '#000000',
                  fontFamily: 'Open Sans',
                  fontSize: '15px',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: '1.67',
                  letterSpacing: 'normal',
                  color: '#43425d',
                };

                return (
                  <Text {...positioningProps} {...presentationProps}>
                    {`${data.reduce(
                      (result, next) => result + next.value,
                      0
                    )} ${I18n.t('devices.title')}`}
                  </Text>
                );
              }}
            />
          ) : null}
        </Pie>
      </PieChart>
    );
  };

  renderActiveShape = (props) => {
    // const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      /* midAngle,*/ innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent /*value,*/,
    } = props;
    // const sin = Math.sin(-RADIAN * midAngle);
    // const cos = Math.cos(-RADIAN * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    // const mx = cx + (outerRadius + 30) * cos;
    // const my = cy + (outerRadius + 30) * sin;
    // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    // const ey = my;
    // const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        {/*{<Label*/}
        {/*    content={props => {*/}
        {/*        const {viewBox: {cx, cy}} = props;*/}
        {/*        const positioningProps = {*/}
        {/*            x: cx,*/}
        {/*            y: cy,*/}
        {/*            dy: 8,*/}
        {/*            textAnchor: 'middle',*/}
        {/*            verticalAnchor: 'middle',*/}
        {/*        };*/}
        {/*        const presentationProps = {*/}
        {/*            fill: fill,*/}
        {/*        };*/}

        {/*        return (*/}
        {/*            <Text {...positioningProps} {...presentationProps}>*/}
        {/*                {`${(percent * 100).toFixed(2)}% of Devices ${payload.name}`}*/}
        {/*            </Text>*/}
        {/*        )*/}
        {/*    }}*/}
        {/*/>}*/}
        {
          <text
            x={cx}
            y={cy}
            textAnchor="middle"
            {...{
              fontFamily: 'Open Sans',
              fontSize: '15px',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.67',
              letterSpacing: 'normal',
            }}
            fill={fill}
          >{`${(percent * 100).toFixed(2)}%`}</text>
        }
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cursor="pointer"
          onClick={() => this.onClick(payload.index)}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
          cursor="pointer"
          onClick={() => this.onClick(payload.index)}
        />
        {/*<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />*/}
        {/*<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />*/}
        {/*<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} Devices`}</text>*/}
        {/*<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">*/}
        {/*    {`${(percent * 100).toFixed(2)}%`}*/}
        {/*</text>*/}
      </g>
    );
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  onPieLeave = (e) => {
    this.setState({
      activeIndex: undefined,
    });
  };

  onClick = (index) => {
    if (
      this.props.item &&
      ((!index && this.props.item.devicesDeliveredCount) ||
        (index && this.props.item.devicesFailedCount))
    ) {
      history.push(index ? 'failed' : 'success');
    }
  };
}

export default TaskOverview;
