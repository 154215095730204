export const zh_cn = {
  loginForm: {
    email: '电子邮件地址',
    password: '密码',
    login: '登录',
    wait: '请稍等...',
    success: '成功...',
  },
  login: {
    multiTenantProceedButtonText: '登录',
    multiTenantCancelButtonText: '取消',
    loginErrorTitle: '登录错误',
    multiTenantPopupTitle: '用户帐口选择',
    multiTenantPopupText: '您的户口有多个帐户。请选择您要登录的帐户。',
    SAMLLoginModalTitle: '使用 SAML 登录',
    loginWithAnSSOProvideModalText: '使用 SSO 提供商登录',
    signInWithAnSSOProviderButtonText: '使用 SSO 提供商登录',
    organizationIDFieldTitle: '组织 ID',
    loginWithSAMLHint: '请输入您的组织 ID 以使用 SAML 登录',
  },
  session: {
    sessionExpiryWarningMessage: '由于没有进一步活动，您的会话即将到期。',
    sessionExpiryWarningTitle: '会话到期警告',
    logoutNow: '立即登出',
    stayLoggedIn: '保持登录状态',
    sessionExpiryTitle: '会话到期',
    sessionExpiryMessage: '您的会话已过期。请重新登录。',
    logoutFailure: '登出失败。请再试一次。',
  },
  errors: {
    unauthorized: '认证失败',
    invalidCredentials: '电子邮件和密码无效',
    serviceUnavailable: '服务不可用',
    loggedOut: '登出',
    invalidPermissionsOrAccessLevel:
      '您没有执行此操作所需的权限或访问级别。请联系管理员。',
  },
  back: '后退',
  search: '搜索',
  searchDialog: {
    hint: '你要找什么？',
    resultsInTotal: '结果总数',
    resultsWith: '结果与',
    searchInProgress: '搜索中',
    fleets: 'Fleet',
    apps: '应用',
    devices: '设备',
    profiles: '配置文件',
    enrollments: '注册',
    failedToPerformSearch: '无法执行搜索',
  },
  profileBar: {
    changeAccountSettings: '更改帐户设置',
    logout: '登出',
    addAProfile: '添加配置文件',
    addAFleet: '添加 Fleet',
    uploadAnApp: '上传应用程序',
    createEnrollmentMethod: '创建注册方法',
    help: '帮助',
  },
  notifications: {
    markAllRead: '标记所有已读',
    removeAll: '移除所有',
    noNewNotifications: '没有新通知',
    markAsRead: '标记为已读',
    remove: '消除',
    deviceHasLeftTheGeofence: '设备 %{deviceName} 已离开地理围栏',
    deviceHasEnteredTheGeofence: '设备 %{deviceName} 已进入地理围栏',
    deviceIsSecurityCompliant: '%{deviceName} 是否符合安全标准',
    deviceIsNotSecurityCompliant: '%{deviceName} 不符合安全标准',
    deviceSecurityComplianceStatusUnknown: '%{deviceName} 安全合规状态未知',
    deviceHasALowBatteryLevel: '%{deviceName} 电池电量低',
    deviceHasANormalBatteryLevel: '%{deviceName} 电池电量正常',
    deviceHasAnUnknownBatteryLevel: '%{deviceName} 电池电量未知',
  },
  certificates: {
    filename: '证书文件名',
    lastUpdated: '最近更新时间',
    deletionConfirmation: '您确定要删除选定的证书吗？',
    removeFiles: '删除文件',
    selectedFiles: '精选证书',
    certificateDeletionError: '删除证书时出错',
    certificatesLoadError: '获取证书时出错',
    uploadedBy: 'Uploaded By',
    uploadedOn: 'Uploaded On',
  },
  htmlKioskFiles: {
    filename: '文档名称',
    lastUpdated: '最近更新时间',
    createdAt: '已创建',
    deletionConfirmation: '您确定要删除选定的 HTML 文件吗？',
    removeFiles: '删除文件',
    selectedFiles: '选定的文件',
    uploadedBy: 'Uploaded By',
  },
  attributiveTable: {
    notFound: '未找到',
    noData: '没有数据',
  },
  dataTable: {
    noMatchingFilterResults: '没有符合您过滤条件的项目。',
    noData: '没有数据',
    csvExportIconHint: '将数据导出到 CSV 文件',
  },
  columnsToggle: {
    successfullySavedChanges: '更改已成功保存。',
    failedToSaveChanges: '未能保存数据。',
    unableToGetSavedColumns: '无法获取此用户的保存表列。',
  },
  company: {
    failedToLoadInfo: '加载信息失败',
    failedToSetPeriod: '设置期限失败',
    failedToLoadCompanySettings: '无法加载公司设置',
  },
  filter: {
    notContainedIn: '不包含在',
    contains: '包含',
    doesNotContain: '不含',
    beginsWith: '开始于',
    doesNotBeginWith: '开始不以',
    endsWith: '以这结束',
    doesNotEndWith: '不以这结束',
    notEqual: '不相等',
    lessThanOrEqual: '小于或等于',
    greaterThanOrEqual: '大于或等于',
    lessThan: '少于',
    greaterThan: '大于',
    equal: '等于',
  },
  installationStatus: {
    successful: '成功',
    relatedApplicationsTitle: '与此任务相关的应用程序',
    pendingInstallationsTitle: '待安装',
    successfulInstallationsTitle: '成功安装',
    informationRelatedToTask: '与此任务相关的信息',
    successfulAndPendingInstallationTitle: '成功和待定安装',
    numberOfSuccessfullyInstalledDevices: '设备安装成功',
    numberOfPendingInstallations: '待安装的设备',
    lastUpdated: '最近更新',
    overviewTitleText: '概述',
    allDevicesPendingInstallationOfThisProfileText:
      '等待安装此配置文件的所有设备',
    allDevicesThatSuccessfullyInstalledThisProfileText:
      '成功安装此配置文件的所有设备',
    failedToLoadTaskDevices: '加载任务设备失败',
    failedToLoadTask: '加载任务失败',
    failedToLoadTasks: '加载任务失败',
    devicesInstalled: '已安装的设备',
    devicesFailed: '无法安装成功的设备',
    status: '状态',
    taskDate: '任务日期',
    installed: '已安装',
    pending: '待办的',
  },
  alerts: {
    title: '警报',
    createAlert: '创建警报',
    alertCreated: '警报已创建',

    chooseAFleet: '选择 FLEET',
    alertType: '警报类型',
    alertCondition: '警报条件',
    alertChannel: '警报通道',
    previewAndSave: '预览和保存',
    done: '完毕',

    alertName: '警报名称',
    alertStatus: '状态',
    linkedFleets: '已链接 FLEETS',
    totalAlerts: '总警报',
    inactive: '不活跃',
    active: '活跃',
    createAnAlert: '创建警报',
    nameThisAlert: '为此警报命名',
    alertDashboard: '警报仪表板',

    battery: '电池',
    outOfContact: '失联',
    exitGeoFence: '退出地理围栏',
    enterGeoFence: '进入地理围栏',

    numberOfLinkedFleets: '%{numberOfLinkedFleets} Fleets',

    on: '开启',
    off: '关闭',
    chooseFleet: '选择 Fleet',
    createFleet: '创建 FLEET',
    back: '后退',
    next: '下一个',
    alertTypeHeading: '警报类型',
    basicAlerts: '基本警报',
    setCondition: '设置条件',
    condition: '条件',
    alertWhenDeviceBatteryIs: '设备电池电量不足时发出警报',
    alertWhenDeviceNetworkIs: '设备网络丢失时发出警报',
    alertWhenDeviceExitsGeofence: '设备退出地理围栏时发出警报',
    alertWhenDeviceEntersGeofence: '设备进入地理围栏时发出警报',
    alertWhenDeviceIsOutOfContact: '当设备失去联系时发出警报',
    alertWhenDeviceBluetoothIs: '设备蓝牙连接时发出警报',
    lessThanOrEqualTo: '小于或等于',
    greaterThanOrEqualTo: '大于或等于',
    setCommunicationChannel: '设置通讯通道',
    singleEmailCommunicationChannel: '单一电子邮件地址',
    singleEmailCommunicationChannelHint: '请每行输入单一电子邮件地址。',
    userGroupsCommunicationChannel: '用户组',
    userGroupsCommunicationChannelHint:
      '按住“Control”或在 Mac 上按住“Command”可选择多个。',
    communicationChannelEmailLabel: '电子邮件',
    communicationChannelEmailPlaceholder: '电子邮件地址',
    communicationChannelGroupLabel: '组',
    communicationChannelGroupPlaceholder: '选择用户组',
    emailAlertFrequency: '电子邮件提醒频率',
    emailAlertFrequencyDescription:
      '重复 %{alertFrequencyRepeatValue} 时间间隔为 %{alertFrequencyHours} 小时。',
    repeat: '重复',
    timesAtAnIntervalOf: '时间间隔为',
    hours: '小时',
    minutes: '分钟',
    percentage: '百分比',
    emailAlertFrequencyValuesDescription:
      '通知 %{alertFrequencyRepeatValue} 时间间隔为 %{alertFrequencyHours} 小时。',
    alertChannelSettingFootNote: '警报通知最多可重复10次，间隔最长为1个月。',
    alertConditionPreviewDescriptionBattery:
      '设备电池电量不足时发出警报 %{conditionType} %{percentage}%',
    alertConditionPreviewDescriptionOutOfContact:
      '当设备脱离接触时发出警报 %{conditionType}',
    alertConditionPreviewDescriptionExitsGeofence:
      '当设备退出地理围栏时发出警报 %{conditionType}',
    alertConditionPreviewDescriptionEntersGeofence:
      '设备进入地理围栏时发出警报 %{conditionType}',

    edit: '编辑',
    confirm: '确认',
    alertsDeletionConfirmation: '您确定要删除选定的警报吗？',
    removeAlerts: '删除警报',
    alertsSelected: '已选择警报',

    youAreEditing: '您正在编辑',
    youAreViewing: '您正在查看',

    alertLoadFailureMessage: '无法加载警报。',
    alertUpdateFailureMessage: '无法加载警报。',
    alertsDeletionFailureMessage: '警报删除失败',
    alertsLoadFailureMessage: '获取警报时出错',
    alertCreationFailureMessage: '警报创建失败',
  },
  api: {
    title: 'API Manager',
    usageTabTitle: 'API Usage History',
    usageQuotaTitle: 'Quota',
    usageDateColumnTitle: 'Date',
    usageConsumedColumnTitle: 'Consumed',
    usageRemainderColumnTitle: 'Remainder',
    apiKeyCopiedSuccessMessageText: 'API key successfully copied to clipboard.',
    currentPlanTitle: 'Current Plan',
    currentPlanDetails: 'Plan Details',
    starterPlanDetails:
      'This plan allows for %{numberOfAPICalls} API calls every month, included with your license fee. Usage above this quota will require additional fees.',
    documentationTitle: 'Documentation',
    documentationHint: 'Open API documentation in a new tab',
    APIKeyTitle: 'API Key',
    uniqueIDsTitle: 'Device Unique IDs',
    uniqueIDsDownloadButtonText: 'Download Device Unique IDs',
  },
  enrollments: {
    editingTitle: '您正在编辑',
    viewingTitle: '您正在查看',
    fields: {
      status: '状态',
      statusActive: '活跃',
      statusInactive: '不活跃',
      statusDraft: '草稿',
      createdAt: '创建于',
      lastUpdated: '最近更新',
      name: '注册名',
    },
    enrollmentCreationButtonText: '创建注册',
    enrollmentsSelected: '已选择的注册',
    removeEnrollments: '删除注册',
    enrollmentDeletionConfirmationText: '您确定要删除选定的注册吗？',
    deletingEnrollments: '删除注册',
    enrollmentsList: '注册名单',
    createAnEnrollmentMethod: '创建注册方法',
    nameThisEnrollmentMethod: '将此注册方法命名为...',
    chooseAFleet: '选择 fleet',
    deviceNaming: '设备命名',
    ReviewAndConfirm: '查看并确认',
    done: '完毕',
    loadEnrollmentError: '获取所选设备注册时出错',
    updateEnrollmentError: '更新此设备注册时出错',
    updateEnrollmentUniqueNameErrorText: '注册名称应该是唯一的',
    createEnrollmentErrorText: '注册创建错误',
    deleteEnrollmentErrorText: '注册删除失败',
    loadEnrollmentsErrorText: '获取注册数据时出错',

    QRCodeEnrollmentIdText: '注册编号：',
    QRCode: '二维码',
    selectedFleet: '已选 Fleet',
    selectedProfilesText: '选定的配置文件',
    deviceNamingSectionTitle: '设备命名',
    deviceNamingSectionHint: '为此处注册的设备设置命名顺序',
    selectedProfilesName: '配置文件名',
    selectedProfilesLastUpdated: '最后更新',

    deviceNamingSequence: '序列：',
    deviceNamingSeparator: '分隔:',
    deviceCurrentCount: ' 当前计数:',
    namingExampleHeader: '例子: ',
    startingSerialNumber: '起始序列号:',

    // device naming params
    deviceNamingParamNone: '没有任何',
    deviceNamingParamNumberedSequence: '自动递增编号序列',
    deviceNamingParamName: '自定义文本',
    deviceNamingParamIMEI: 'IMEI',
    deviceNamingParamMACAddress: 'MAC 地址',
    deviceNamingParamManufacturer: '制造商',
    deviceNamingParamModel: '型号',
    deviceNamingParamDeviceName: '个性化设备名称',
    deviceNamingParamPhoneNumber: '电话号码',
    deviceNamingParamPlatform: '平台',
    deviceNamingParamSerialNumber: '序列号',

    // enrollment creation
    allVersionsOptionText: '所有版本',
    prevStepButton: '后退',
    forwardStepButton: '往下',
    exit: '退出',
    edit: '退出',
    confirm: '确认',
    namingSequenceHint: '为此处注册的设备创建命名序列。',
    enrollmentCreationSuccessTitle: '立即发送此注册方法。',
    enrollmentCreationSuccessSubTitle: '注册信息',
    preview: '预览:',
    notAvailable: '无法使用',
    noQRCodeAvailableText: '没有可用的二维码。',
    enrollmentName: '注册名称',
    startingFromPrefix: '从...开始',

    // staging
    stagingTabTitle: 'Staging',
    stagingTabHint:
      '要生成暂存二维码，请选择配置文件，然后选择要连接的 WiFi 网络。请注意，WiFi 字段仅在选择配置文件后显示。',
    stagingProfileFieldTitle: '配置文件',
    stagingLocaleFieldTitle: '语言环境',
    stagingTimezoneFieldTitle: '时区',
    stagingWiFiNetworkFieldTitle: 'WiFi 网络',
    stagingSkipDeviceEncryptionFieldTitle: '跳过设备加密',
    stagingLeaveAllSystemAppsEnabledFieldTitle: '启用所有系统应用程序',
    stagingUseMobileDataFieldTitle: '使用移动数据',
    stagingUseManualWifiSettingsFieldTitle: '使用手动 Wifi 设置',
    stagingManualWifiSSIDFieldTitle: 'WiFi SSID',
    stagingManualWifiPasswordFieldTitle: 'Wifi 密码',
    stagingManualWiFiTypeFieldTitle: 'Wifi 类型',
    stagingManualHiddenNetworkFieldTitle: '隐藏网络',
    stagingQRCodeGenerationButtonText: '生成二维码',
    noStagingConfigurationsMessage: '当前没有与此注册关联的暂存配置。',
  },
  routes: {
    myFleets: '我的 Fleets',
    myFleetsV2: '我的 Fleets',
    enrollments: '注册',
    allDevices: '所有设备',
    myApplications: '我的应用程序',
    upload: 'Add',
    myFiles: '我的文件',
    myCertificates: '我的证书',
    myHTMLKioskFiles: '我的 HTML Kiosk 文件',
    allProfiles: '所有配置文件 V1',
    allProfilesV2: '所有配置文件',
    tasks: '安装状态',
    accountSettings: '帐户',
    commonSettings: '常用设置',
    companyInfo: '公司信息',
    SSO: 'SSO',
    preferences: 'Preferences',
    dashboard: '仪表盘',
    mapView: '视图',
    usersAll: '管理',
    usersLogs: '活动场记',
    alerts: '警报',
    apiOverviewTitle: 'Overview',
    apiUsageHistoryTitle: 'Usage History',
  },
  statistics: {
    numberNewDevices: `%{number} 新设备`,
    numberDevices: `%{number} 设备`,
    numberTasks: `%{number} 任务`,
    enrolledDevices: '已注册设备',
    inLowBattery: '低电量',
    online: '在线的',
    recentlyActive: '最近活跃',
    quickDetails: '快速详情',
    onlineDevicesPast: `过去最近活跃的设备 `,
    lastDay: '24小时',
    week: '星期',
    month: '月',
    last: '最后的',
    installing: '正在安装',
    enrolling: '正在注册',
    lowBattery: '低电量',
    failedEnrollments: '注册失败',
    nonCompliantDevices: '不合规设备',
    androidDevices: 'Android 设备',
    linuxDevices: 'Linux 设备',
    lastConnectDates: `上次连接日期（所有时间）`,
    manufacturer: '制造商',
    connections: '连接',
    devices: '设备',
    deviceFamilyOSVersion: '设备系列操作系统版本',
    failedToLoadStatistics: '无法加载统计信息',
  },
  device: {
    title: '设备',
    deviceInfo: '设备信息',
    deviceInfoHint: '与此设备相关的信息',
    actions: '行动',
    overview: '概述',
    applications: '应用程序',
    profilesAssigned: '分配的配置文件',
    activityLogs: '场记',
    location: '地点',
    updateHistory: '更新',
    updateRequestConfirmationText: 'Update request sent.',
    noHistoryLogEntries: '当前没有位置日志条目。',
    noHistoryLogEntriesForRange: '在所选择日期范围设置内并没有位置日志记录。',
    locationHistory: '位置记录',
    currentLocation: '当前位置',
    locationLogsStartDate: '开始日期',
    locationLogsEndDate: '结束日期',
    csvExportIconHint: '将日志导出到 CSV 文件',
    locationHistoryMapViewToggleText: '地图视图',
    export: '导出',
    mapFullScreenControlLabelTip: '切换全屏',
    remoteControl: '遥控',
    logsMenuItem: '日志',
    updatingDeviceProperty: '更新设备属性:',
    sendingRemoteCommand: '发送远程命令:',
    applicationsSelected: '选择的应用程序',
    uninstallApps: '卸载应用',

    security: '保安',
    compliant: '合规',
    notCompliant: '不合规',

    encrypted: '加密',
    OSSecure: '操作系统保安',
    passCodeEnabled: '启用密码',
    androidSecurityPatchLevel: 'Android 安全补丁级别',
    securityCompliance: '安全性合规',
    latestAgent: '最新 Agent',

    isNowOnline: '现已上线',
    lastKnownLocation: '最后已知位置',
    lastOnline: '最后上线',
    fleet: 'Fleet',
    fleetPath: 'Fleet 路径',
    active: '活跃',
    inactive: '不活跃',
    enrolled: '已注册',
    unenrolled: '脱离注册',
    offline: '离线',
    online: '在线的',
    deviceConfigurationLocked: 'Device Configuration Locked',
    lastSeenDescription: '上次见于 %{lastSeenFormatted}',
    timeWithAgoAffix: '%{time} 前',

    status: '状态',
    manufacturer: '制造商',
    model: '型号',
    activeConnection: '活跃连接',
    networkBssid: 'BSSID',
    networkSsid: 'SSID',
    networkDNS: 'DNS',
    networkRssi: 'RSSI (网络信号电平)',
    roaming: '漫游',
    customNotes: '自定义笔记',
    developerMode: '开发者模式',
    ipAddress: 'IP 地址',
    wifiStatus: 'WiFi 状态',
    nfcStatus: 'NFC 状态',
    batteryLevel: '电池电量',
    powerStatus: '电源状态',
    discharging: '放电中',
    charging: '充电中',
    pauseMode: '暂停模式',
    phoneNumber: '电话号码',
    platform: '平台',
    ethernetMacAddress: '以太网 MAC 地址',
    macAddress: 'MAC 地址',
    checkinPeriod: 'Checkin 时期',
    lastUpdated: '最近更新时间',
    updatePeriod: '更新周期',
    lastFleetCheckin: '最后的 Fleet Checkin',
    automaticAgentUpdatesEnabled: 'Automatic Agent Updates Enabled',

    profilesAttachedToThisDevice: '附加到此设备的配置文件',
    noProfilesAttachedToDevice: '当前没有配置文件附加到此设备',
    profileAssignedName: '配置文件名称',
    profileAssignedStatus: '状态',

    connectingToTheServer: '连接到服务器...',
    connectingToTheServerWaitingForTheDevice: '连接到服务器，等待设备',
    disconnectedFromTheServerTryingReconnect: '与服务器断开连接，尝试重新连接',
    deviceConnected: '设备已连接',
    deviceDisconnectedWaitingForTheDevice: '设备断开，等待设备',
    remoteControlCurrentFocusTextTitle: '当前焦点文本',
    mediaProjection: '媒体投影',
    screenCaptureType: '屏幕截图类型:',
    chooseScreenCaptureType: '选择屏幕截图类型',
    exitFullScreenMode: '退出全屏模式',
    fullScreenMode: '全屏模式',
    swipeTop: '滑动上顶部',
    volumeUp: '提高音量',
    volumeDown: '音量减小',
    power: 'Power',
    enterKioskMode: '进入 Kiosk 模式',
    exitKioskMode: '退出 Kiosk 模式',
    secureInput: '安全输入',
    slowNetwork: '网速慢',
    imageQuality: '画面质量:',
    imageSize: 'Image Size:',

    logsTitle: '日志',
    logsTitleHint: '为此设备存储的日志',
    dateTime: '日期时间',
    type: '类型',
    details: '细节',
    generatedBy: '生成者',

    addAndUpdateNoteTitle: '添加/更新自定义注释',
    customNoteTitle: '自定义笔记',
    deviceLoadFailureMessage: '加载设备信息失败',
    logs: {
      dateTime: '日期/时间',
      message: '信息',
      coordinates: '坐标',
      longitude: '经度',
      latitude: '纬度',
    },

    fields: {
      fleet: 'Fleet',
      serialNumber: '序列号.',
      deviceType: '设备类型',
      osVersion: '操作系统版本',
      imei: 'IMEI',
      agentVersion: 'Agent 版本',
      availableStorage: '可用存储空间',
      availableMemory: '有效内存',
      totalMemory: '总内存',
      totalStorage: '总存储量',
      haveUpdates: '最新的 Agent',
      latestAgent: '最新的 Agent',
      compliance: 'Agent 兼容compliance',
    },
  },
  profiles: {
    title: '配置文件',
    published: '已发表',
    unpublished: '未发表',
    draft: '草案',
    draftAvailable: '草稿可用',
    version: '版本',
    createProfile: '创建配置文件',
    newProfileDefaultName: '新的配置文件',
    unableToCreateProfileErrorText: '无法创建配置文件',
    profileNameFieldPlaceholder: '输入配置文件',
    moreOptionsTitleHeader: '更多选择',
    assignmentSettingsTitle: '作业设置',
    deviceSideTimeLabel: '设备端时间',
    timezone: '时区',
    schedules: '时刻表',
    assignmentStartDateLabel: '关联开始',
    assignmentEndDateLabel: '结束关联',

    profileViewingHint: '您正在查看',
    profileAssociationHint: '您正在关联',
    profileEditingHint: '您正在编辑',
    profileCreatingHint: '你正在创造',
    datetimeOffsetPlaceholder: '选择值',
    assignmentButtonText: '立即关联',
    saveButtonText: '保存',
    createButtonText: '创建',

    unassignProfiles: '将所选配置文件与所有关联的 fleets 分离',
    unpublishAndClearFleetsSuccess: '已成功取消发布所选配置文件',
    unpublishAndClearFleetsFailure: '无法取消发布所选配置文件',
    unpublishAndClearListedFleetsSuccess:
      'Successfully unpublished the following profiles:',
    unpublishAndClearListedFleetsFailure:
      'Failed to unpublish the following profiles:',
    cloneProfiles: '克隆配置文件',
    removeProfiles: '删除配置文件',
    profileActions: '行动',
    assignFleetsAndPublish: '关联 Fleets 并发布',
    associateFleetsTitle: '关联 Fleets',
    unPublishProfile: '取消发布',
    assign: '关联',
    migrateToVersionTwo: '迁移到版本 2',
    migrationSuccessText: '已成功将所选配置文件迁移到 Profiles V2',
    migrationFailureText: '无法将所选配置文件迁移到 Profiles V2',
    profileAssignmentToFleetsTitle: '将配置文件与 fleets 相关联',
    unpublishProfilesTitle: '取消发布配置文件',
    unpublishProfilesConfirmationText: '您确定要取消发布所选的配置文件吗?',
    unpublishProfilesConfirmationConsequence:
      '此操作将从设备中删除选定的配置文件。',
    unassignProfilesConfirmationText: '您确定要取消关联选定的配置文件吗?',
    cloneProfilesConfirmationText: '您确定要克隆选定的配置文件吗？',
    removeProfilesConfirmationText: '您确定要删除选定的配置文件吗？',
    migrateProfilesConfirmationText:
      '您确定要将选定的配置文件迁移到新版本 2 吗？这个动作是不可逆的!',
    toolsPanelTitle: '选定的配置文件',
    loadProfilesErrorText: '加载配置文件时出错',
    profileRemovalNotAllowedForPublishedProfiles:
      '一个或多个选定的配置文件当前已发布且无法删除。请取消发布这些个人资料，然后重试',
    unavailableFeatureMessage:
      '该功能仅在新版 Profiles (v2) 中可用。请考虑迁移或创建新的 v2 配置文件。',
    cannotDeleteProfilesAssignedToFleets:
      '无法删除当前分配有 Fleets 的配置文件。',
    cannotDeletePublishedProfile: '无法删除已发布的配置文件',
    profileTreeHasMoreThanOnePublishedProfile:
      '配置文件树有多个已发布的配置文件',
    profileTreeHasMoreThanOneLatestVersion:
      '配置文件树有多个最新版本的配置文件',
    profileTreeCannotBeDeletedBecauseTheProfileIsPublished:
      '不能删除已发布配置文件的配置文件树。',
    profileHasMoreThanOneNewestVersion: '配置文件树有多个最新的配置文件版本',
    cannotAssignFleetsToUnpublishedProfiles:
      '无法将车队与未发布的草稿配置文件相关联。请发布配置文件并重试。',
    profilesSuccessfullyDeleted: '配置文件已成功删除',
    profilesNotSuccessfullyDeleted: '删除配置文件失败',
    profilesSuccessfullyCloned: '配置文件成功克隆',
    profilesNotSuccessfullyCloned: '无法克隆配置文件',
    failedToLoadProfiles: '无法加载配置文件',
    failedToPublishProfile: '未能发布配置文件',
    profileSuccessfullyPublished: '配置文件已成功发布',
    fleetsToProfileAssignmentSuccess: 'Fleet 已成功分配到当前发布的版本',
    fleetsToProfileAssignmentFailure: '无法将 fleet 与配置文件关联。',
    failedToUnpublishProfile: '未能取消发布配置文件',
    profileSuccessfullyUnpublished: '已成功取消发布配置文件',
    unpublishProfileConfirmationText: '您确定要取消发布此配置文件吗?',
    profileCreationDefaultErrorText: '未能创建配置文件。',
    profileCreationAndPublishingDefaultErrorText: '未能创建和发布配置文件.',
    profileCreationSuccessText: '配置文件已成功创建。',
    profileCreationAndPublishingSuccessText: '配置文件已成功创建和发布。',
    profileNewVersionCreationDefaultErrorText: '无法创建配置文件的新版本。',
    profileNewVersionCreationAndPublishingDefaultErrorText:
      '未能创建和发布配置文件的新版本。',
    saveAndPublishProfileDefaultErrorText: '未能保存和发布配置文件。',
    profileNewVersionCreationSuccessText: '已成功创建配置文件的新版本。',
    profileNewVersionCreationAndPublishingSuccessText:
      '已成功创建并发布配置文件的新版本。',
    saveAndPublishProfileSuccessText: '配置文件已成功保存和发布。',
    profileSaveAsDraftSuccessText: '已成功将配置文件保存为草稿。',
    profileSaveAsDraftDefaultErrorText: '无法将配置文件保存为草稿。',
    saveAsDraftButtonText: '保存为草稿',
    createNewVersionButtonText: '创建新版本',
    saveAndPublishButtonText: '保存并发布',
    unableToPublishProfileWithoutAssignedFleets:
      '无法发布配置文件，因为它没有与之关联的 fleets。请关联一个或多个 fleet，然后重试。',
    profileVersionPublicationConfirmation:
      '您确定要发布此配置文件的第 %{version} 版吗?',

    failedToLoadProfileErrorText: '配置文件加载失败',
    failedToCreateProfileErrorText: '配置文件创建失败',
    failedToUpdateProfileErrorText: '配置文件更新失败',
    failedToLoadProfileApplicationsErrorText: '获取配置文件应用程序时出错',
    failedToLoadProfileById: '无法加载选定的配置文件',
    failedToLoadProfileApps: '无法加载配置文件应用程序',
    failedToAssignProfilesToFleets: '无法将所选配置文件与所选 fleets 相关联',
    failedToDeleteProfiles: '未能删除配置文件',

    profileDetailsTitle: '配置文件详情',
    profileDetailsHint: '与此配置文件相关的信息',
    addConfigurationLabel: '添加配置',
    addLabel: '添加',
    assignedFleetsLabel: '已分配的 fleets',

    fields: {
      name: '配置文件名称',
      lastUpdated: '最近更新',
      updatedBy: '更新者',
      createdAt: '创建于',
      newestVersion: '最新版本',
      version: '版本',
      published: '已发表',
      policies: '政策',
      packageId: 'Package Id',
      status: '状态',
      size: '大小',
    },

    networkTitle: '网络',
    applicationsTitle: '应用',

    featureControl: {
      title: '功能控制',
      statusHeader: '功能控制已启用',
      description: '设置功能控制。',
      noFeatureControlRules: '此配置文件中当前没有功能控制规则。',
      fields: {
        disableBluetooth: '禁用蓝牙',
        allowInstallationsFromUnknownSources: '允许从未知来源安装',
        disableCamera: '禁用相机',
        disableUsb: '禁用 USB',
        disableGooglePlay: '禁用 Google Play',
        disableSafeBoot: '禁用 Safe Boot',
        disableWifiHotspot: '禁止或关闭网络共享',
        disableFactoryReset: '禁用恢复出厂设置',
        disableHardReset: '阻止断电并重新启动（仅限 Knox）',
        disableTurnOffWifi: '阻止关闭 WiFi',
        disableAirplaneMode: '阻止飞行模式',
      },
    },
    kiosk: {
      title: 'Kiosk',
      htmlKioskTitle: 'HTML Kiosk',
      undo: '撤销',
      save: '保存',
      htmlKiosk: 'HTML KIOSK',
      htmlKioskWhiteList: 'HTML KIOSK 白名单',
      passwordDoesNotMatch: `密码不匹配`,
      backdoorPasswordDescription: '请设置后门密码。',
      kioskBuilder: 'Kiosk Builder',
      kioskBuilderHint1: '将应用程序从列表拖放到设备界面。',
      kioskBuilderHint2: '将应用程序拖出设备界面即可移除。',

      HTMLKioskBuilder: 'HTML Kiosk Builder',
      HTMLKioskBuilderHint: `从右侧“我的 HTML 文件”部分拖放一个 HTML 文件。`,
      myHtmlFiles: '我的 HTML 文件',
      enterHtmlKioskBuilder: '进入 HTML KIOSK BUILDER',
      enterKioskBuilder: '进入 KIOSK BUILDER',
      kioskMode: 'Kiosk 模式',
      statusTitle: 'Kiosk Mode 启用',
      kioskModeEmptyMessage: '目前没有自动安装的应用程序。',
      back: '后退',
      security: '安全',
      confirmPassword: '确认密码',
      password: '密码',
      addPackageByID: '用 Package ID 添加 Package',
      statusBar: '状态栏',
      whitelistServices: '白名单服务',
      autoLaunchApp: '自动启动应用程序',
      myApplications: '我的应用程序',
      useHTMLKioskMode: '使用 HTML Kiosk 模式',
      hideStatusBarSetting: 'Hide Status Bar',
      backButtonSetting: '返回键',
      homeButtonSetting: '主页按钮',
      menuButtonSetting: '菜单按钮',
      singleAppModeSetting: '单一应用模式',
      showExitButtonSetting: '显示退出按钮',
      onlyKioskNotificationsSetting: '允许仅通知',
      hideNavigationBarSetting: '隐藏导航栏（仅限三星）',
      backKeyText: '后退',
      homeKeyText: '主页',
      menuKeyText: '菜单',
    },
    wallpaperSettings: {
      wallpaperSettings: 'Wallpaper 设置',
      policyStatusTitle: 'Wallpaper 已启用',
      homeScreenWallpaperLandscape: '主屏幕 Wallpaper - 横向模式',
      homeScreenWallpaperPortrait: '主屏幕 Wallpaper - 纵向',
      lockScreenWallpaperLandscape: '锁屏 Wallpaper - 横向模式',
      lockScreenWallpaperPortrait: '锁屏 Wallpaper - 纵向',
      myImages: '我的图片',
      useHomeScreenWallpaperAsLockScreenWallpaper:
        '使用主屏幕 Wallpaper 作为锁屏 Wallpaper',
      wallpaperSettingsAvailability:
        '仅适用于 Android 7.0（API 级别 24）及更高版本。',
    },
    wifi: {
      title: 'WiFi',
      wifiPolicyEnabledTitle: '启用 WiFi 策略',
      wifiNetworkTitle: 'WiFi 网络',
      policyHint: '单击 + 图标添加 WiFi 网络',
      descriptionHint: '为设备设置 WiFi 设置。',
      removeConfigurationActionButtonText: '删除配置',
      proxyBypassTitle: '绕过 proxy',
      proxyBypassHint: '请每行输入一个主机名/网站。',
      proxyAutoConfigURLTitle: 'Proxy 自动配置 URL',
      proxyPortTitle: 'Proxy 端口',
      proxyHostnameTitle: 'Proxy 主机名',
      proxyTypeTitle: 'Proxy',
      frequencyHint: '仅适用于 Android 7 及更低版本。',
      proxyConfigTypeNone: '没有任何',
      proxyConfigTypeManual: '手动的',
      proxyConfigTypeAutoConfig: 'Proxy 自动配置',
      SSID: 'SSID',
      SSIDPlaceholder: '输入 SSID 名称',
      SSIDPassword: 'SSID 密码',
      SSIDPasswordPlaceholder: '输入密码',
      WiFiFrequency: 'WiFi频率',
      hiddenNetwork: '隐藏网络',
      securityType: '安全类型',
      acceptedEAPTypes: '接受的 EAP 类型',
      identity: '身份',
      password: '密码',
      passwordPlaceholder: '输入密码',
      clientCertificate: '客户证书',
      CACertificate: 'CA 证书',
      CACertificateDomain: 'CA 证书域',
      domainSuffixMatch: '域名 Suffix 匹配',
      alternateSubjectMatch: '替代主题匹配',
      phase2AuthenticationMethod: '第 2 阶段身份验证方法',
      anonymousIdentity: '匿名身份',
      publicLandMobileNetwork: '公共陆地移动网络',
      realm: '领域',
      noWiFiNetworksInProfileText: '此配置文件中当前有 WIFI 网络。',
    },
    webFilter: {
      webFilter: '网页过滤器',
      policyStatusTitle: '启用网页过滤器',
      noWebFilterPolicies: '尚未创建任何 Web 过滤器策略。',
      hint: '设置网络过滤器白名单或黑名单。',
      webFilterType: '网页过滤器类型',
      urls: 'URLS',
      urlHint:
        '每行输入一个网站 URL。 请在每个网站前面加上相应的协议，例如 http://',
      webFilterTypeWhitelist: '白名单',
      webFilterTypeBlacklist: '黑名单',
    },
    apn: {
      emptyPolicyListMessage: '此配置文件中当前有 APN 设置。',
      policyStatusTitle: '启用 APN 策略',
      apnSettingsAvailability:
        '仅适用于 Android 9.0（API 级别 28）及更高版本。',
      policyTitle: 'APN',
      apnNameLabel: '接入点名称',
      apnDisplayNameLabel: '接入点显示名称',
      mobileCountryCodeLabel: '移动国家代码 (MCC)',
      mobileNetworkCodeLabel: '移动网络代码',
      accessPointTypeMMS: 'MMS',
      accessPointTypeInternet: '互联网',
      accessPointTypeMMSAndInternet: 'MMS + 互联网',
      accessPointTypeAny: '任何',
      accessPointTypeLabel: '接入点类型',
      mvnoTypeLabel: 'MVNO 类型',
      mvnoValueLabel: 'MVNO 价值',
      MVNOTypeNone: '没有任何',
      MVNOTypeSPN: 'SPN',
      MVNOTypeIMSI: 'IMSI',
      MVNOTypeGID: 'GID',
      defaultNetworkLabel: '默认网络',
      authenticationTypeLabel: '认证类型',

      authenticationTypeNone: '没有任何',
      authenticationTypePAP: 'PAP',
      authenticationTypeCHAP: 'CHAP',
      authenticationTypePAPCHAP: 'PAP/CHAP',

      accessPointConnectionUsernameLabel: '接入点连接用户名',
      accessPointConnectionPasswordLabel: '接入点连接密码',
      accessPointConnectionHostAddressLabel: '接入点连接主机地址',
      accessPointConnectionHostPortLabel: '接入点连接主机端口',

      proxyHostAddressLabel: 'Proxy 主机地址',
      proxyHostPortLabel: 'Proxy 主机端口',
      mmsProxyHostAddressLabel: 'MMS Proxy 主机端口',
      mmsHostAddressLabel: 'MMS 主机地址',
      mmsHostPortLabel: 'MMS 主机端口',
    },
    fileSync: {
      policyStatusTitle: '启用文件同步',
      policyTitle: '文件同步',
      directionOptionSyncFilesFromServerToDevice: '将文件从服务器同步到设备',
      hint: '单击 + 图标添加文件同步配置',
      fileSyncConfigurationTitle: '文件同步配置',
      removeConfigurationText: '删除配置',
      directionFieldTitle: '方向',
      devicePathLabelText: '设备文件/文件夹名称',
      devicePathPlaceholderText: '输入文件夹路径',
      fileSynchronizationOptionsTitle: '文件同步选项',
      syncSubFoldersFieldLabel: '同步子文件夹',
      filesTitle: '文件',
      noFileSyncRules: '此配置文件中当前没有文件同步规则。',
      noFilesAssigned: '此配置文件中没有文件。',
      filesAssignmentHint: '请将列表中的文件关联到右侧。',
    },
    geofencing: {
      policyTitle: '地理围栏',
      policyStatusTitle: '启用地理围栏',
      clearGeometryText: '清晰的几何图形',
      searchFieldPlaceholderText: '搜索 ...',
      geofencingRuleTitle: '地理围栏规则',
      removeRuleActionButtonTitle: '删除规则',
      notifyWhenDevicesLeaveTheFence: '设备离开围栏区域时通知',
      moveDeviceToFleetIfItLeavesGeofence:
        '如果设备离开地理围栏，则将设备移至 fleet',
      hint: '单击 + 图标添加地理围栏规则',
      changeFleetButtonText: '改变 Fleet',
      removeFleetButtonText: '删除 Fleet',
      selectAFleetButtonText: '选择一个 Fleet',
      fleetNotDefinedText: 'Fleet 没有定义',
      policyHint: '绘制围栏区域多边形以设置地理围栏规则',
      textButtonHint: '添加规则',
      noRulesInProfile: '此配置文件中当前没有地理围栏规则。',
    },
    password: {
      policyTitle: '密码',
      policyHint: '设置密码要求。',
      policyStatusTitle: '密码已启用',
      passwordQualityOptionPIN: 'PIN',
      passwordQualityOptionPattern: '安全模式',
      passwordQualityOptionAlphabetic: '字母',
      passwordQualityOptionAlphanumeric: '字母数字',

      passwordQualityLabel: '密码质量',
      minimumLengthLabel: '密码长度',
      complexCharactersRequiredLabel: '所需的最少复杂字符数',
    },
    settings: {
      policyTitle: '设置',
      policyStatusTitle: '设置已启用',
      deviceLanguageTitle: '设备语言',
      syncNTPLabel: '与 NTP 服务器同步时间',
      setDeviceTimezone: '设置设备时区',
      timezone: '时区',
      useDaylightSavingsTime: '使用夏令时',
      syncDeviceOverWiFi: '仅通过 WiFi 同步到设备',
      downloadFilesOverWifi: '仅通过 Wi-Fi 下载文件',
      policyHint: '设置同步设置。',
      deviceVolumeTitle: '设备音量',
      deviceBrightnessTitle: '设备亮度',
      disableAndroidBeamTitle: '禁用 Android Beam',
      autoRotateScreenTitle: '自动旋转屏幕',
      displaySectionTitle: 'Display',
      timeSectionTitle: '时间',
      languageSectionTitle: '语言',
      synchronizationSectionTitle: '同步',
      systemSectionTitle: '系统',
    },
    certificates: {
      policyTitle: '证书',
      policyStatusTitle: '已启用证书管理',
      certificatesTitle: '配置文件证书',
      certificateNameTitle: '证书名称',
      lastUpdated: '最近更新时间',
      noCertificatesAttachedToProfile: '此配置文件没有附加证书。',
      howToAddCertificateRecommendationText: '请关联您右侧列表中的证书',
    },
    apps: {
      policyTitle: '应用管理',
      policyStatusTitle: '已启用应用程序管理',
      policyTitleHint: '附加到此配置文件的应用程序将自动安装这些应用程序。',
      profileAppsTitle: '配置文件应用程序',
      noAppsAttachedToProfile: '当前没有附加到此配置文件的应用程序。',
      howToAddAppsRecommendationText: '请关联您右侧列表中的应用程序。',
      myApplicationsTitle: '我的应用程序',
      autoInstalledAppsTitle: '自动安装的应用程序',
      noAutoInstalledAppsText: '目前没有自动安装的应用程序。',
      networkRestrictionsSectionTitle: '网络限制',
      installOverWiFiTitle: '通过 Wifi 安装',
      installOverCellularTitle: '通过移动网络安装',
      installOverCellularConditionTitle: '安装在移动网络条件',
      installOverLANTitle: '通过局域网安装',
      noneInstallOverCellularConditionTitle: '没有任何',
      cellularInstallOverCellularConditionTitle: '移动网络',
      cellularWhileRoamingInstallOverCellularConditionTitle: '漫游时移动网络',
    },
    blacklist: {
      policyTitle: '应用黑名单管理',
      policyStatusTitle: '已启用应用黑名单管理',
      blacklistTitle: '黑名单',
      blacklistedAppsTitle: 'Blacklisted Package IDs',
      noBlacklistedAppsMessage: '目前没有列入黑名单的应用程序。',
      blacklistUpdateHint: '请关联您右侧列表中的应用程序。',
      blacklistDescription: '附加到此配置文件的应用程序将被列入黑名单。',
      addPackageByID: '按 Package ID 添加 Package',
    },
    security: {
      policyTitle: '安全性',
      policyStatusTitle: '启用安全管理',
      policyHint: '设置安全设置。',
      secureOSTitle: '安全操作系统',
    },
    location: {
      policyTitle: '位置',
      policyStatusTitle: '位置设置已启用',
      locationPolicyHint: '设置位置服务设置。',
      GPSAlwaysOnSetting: 'GPS 始终开启',
    },
    locationServices: {
      policyTitle: '位置服务',
    },
    authentication: {
      policyTitle: '验证',
      policyTitleHint: '设置用于注册新设备的密码。',
      passwordFieldLabel: '密码',
      confirmPasswordFieldLabel: '确认密码',
    },
  },
  applications: {
    title: '应用程序',
    myApplicationsTitle: '我的应用程序',
    applicationInfoTabHeading: '应用信息',
    allVersionsListTabHeading: '所有版本列表',
    allVersionsListTabHint: '此应用程序的所有版本',
    settingsTabHeading: '设置',
    assignProfilesHeading: '配置文件',
    assignProfilesHint: '选择配置文件并按关联',
    assignProfilesButtonText: '现在关联',
    assignProfilesFailureDefaultMessage: '无法将应用程序与配置文件相关联',
    assignProfilesSuccessDefaultMessage: '与所选配置文件关联的应用程序',

    applicationCodeNameHint: '开始输入定义',

    applicationVersionsTitle: '应用程序',
    appsRelatedToTaskTitle: '与此任务相关的应用程序',
    editApplicationDetails: '编辑应用程序详细信息',

    applicationDataUpdateError: '更新应用程序数据时出错',
    applicationDataFetchError: '获取应用程序数据时出错',
    applicationAssignmentToProfilesErrorMessage: '应用程序与配置文件关联时出错',

    fields: {
      applicationName: '应用名称',
      applicationFileName: '应用程序文件名',
      label: '标签',
      latestVersion: '最新版本',
      latestVersionCode: '最新版本代码',
      versionCode: '版本代码',
      version: '版本',
      packageId: 'Package ID',
      status: '状态',
      size: '大小',
      lastUpdated: '最近更新时间',
      uploadedBy: 'Uploaded By',
      uploadedOn: 'Uploaded On',
      installOnly: '仅安装',
      managed: '受管理',
      installedOnThisDevice: '安装在此设备上',
    },
    applicationsDeletionTitle: '应用程序删除',
    applicationsDeletionFailed: '应用程序删除失败',
    applicationsFetchFailed: '获取应用程序时出错',
    appsNotDeletedByAssignedToProfileTitle: `以下应用程序未被删除，因为它们仍被分配给配置文件 (%{profile})`,
    appsDeletedTitle: '以下应用程序已成功删除',
    addBundleTitle: '添加 Package',
    addBundleFieldPlaceholderText: '输入 Package ID',
  },
  devices: {
    title: '设备',
    csvExportIconHint: '将表格导出为 CSV 文件',
    devicesSelected: '选择的设备',
    removeDevices: '移除设备',
    selectFleet: '选择 Fleet',
    associationHint: '您正在关联',
    assignmentButtonText: '立即关联',
    selectedFleet: '已选 Fleet',
    deviceUpdateTitle: '最新的 Agent',
    deviceLogsLoadingFailed: `设备的日志加载失败`,
    applicationsLoadingFailed: '应用程序加载失败',
    deviceProfilesLoadingFailed: '配置文件加载失败',
    devicePropertyUpdateFailed: '无法更新设备属性',
    devicePropertyValueMustBeUnique: '设备属性应该是唯一的',
    applicationsUninstallFailed: '应用程序卸载失败',
    sendingRemoteCommandToSelectedDevicesErrorMessage:
      '向所选设备发送远程命令时出错',
    errorOnFetchingAllCompanyDevices: '获取设备时出错',
    errorOnDeletingSelectedDevices: '删除所选设备时出错',
    removeDeviceTitle: '移除设备',
    sendingRemoteCommandTitle: '发送远程命令',
    fields: {
      fleetName: 'Fleet',
      haveUpdates: '最新的 Agent',
      latestAgent: '最新的 Agent',
      status: '状态',
      lastSeen: '最后一次看到',
      deviceName: '设备',
      agentVersion: 'Agent 版本',
      platform: '平台',
      location: '位置',
      longitude: 'Longitude',
      latitude: 'Latitude',
      batteryLevel: '电池电量',
      lastOnline: '最后上线',
      enrollmentDatetime: '注册 日期/时间',
      manufacturer: '制造商',
      model: '型号',
      macAddress: 'MAC 地址',
      ethernetMacAddress: '以太网 MAC 地址',
      ipAddress: 'IP 地址',
      totalMemory: '总内存',
      availableMemory: '有效内存',
      totalStorage: '总存储量',
      availableStorage: '可用存储空间',
      serialNumber: '序列号',
      imei: 'IMEI',
      sendingAnalyticsData: '发送分析数据',
      compliance: 'Agent 兼容',
      compliantAndLatestVersion: '兼容和最新版本',
      compliantAndNotLatestVersion: '兼容但不是最新版本',
      notCompliantVersion: '不符合版本',
      deviceConfigurationLockStatus: 'Device Configuration Lock Status',
      automaticAgentUpdatesEnabled: 'Automatic Agent Updates Enabled',
    },
  },
  home: {
    title: '主页',
    search: '搜索',
  },
  users: {
    userGroups: '用户组',
    title: '用户',
    allUsers: '全部用户',
    admins: '管理员',
    manage: '管理',
    groupName: '团体',
    moveUsers: '移动用户:',
    userStatusChange: '用户状态变化',
    makeUsersInactiveConfirmation: '您想让选定的用户处于非活动状态吗？',
    activateUsersConfirmation: '您确定要激活选定的用户吗？',
    cannotDeleteGroupContainingUsersMessage:
      '您无法删除仍有用户的组。 请从该组中删除所有用户，然后重试。',
    deleteGroupConfirmation: '您确定要删除 %{groupName} 吗？ ',

    createAGroup: '创建一个群组',
    createUser: '创建用户',
    editGroup: '编辑组',
    deleteGroup: '删除群组',
    chooseAUserGroup: '选择用户组',
    move: '移动',
    changeUserGroup: '更改用户组',
    activateUsers: '激活用户',
    deactivateUsers: '停用用户',
    removeUsers: '删除用户',
    usersSelected: '选择的用户',

    newUserGroup: '新用户组',
    permissions: '权限',
    userManagement: '用户管理',
    groupCreationFailureDefaultErrorText: '无法创建群组',
    groupCreationHeading: '你正在创造',
    groupEditingHeading: '您正在编辑',
    update: '更新',
    create: '创建',

    globalPermissionsTableDescriptionHeader: '描述',
    globalPermissionsTableReadOnlyHeader: '只读',
    globalPermissionsTableReadWriteHeader: '读/写',

    userGroupSectionDescription: '用户组',
    globalPermissionsSectionDescription: '全局权限',
    fleetAccessSectionDescription: 'Fleet 访问管理',
    remoteControlSectionDescription: '远程控制权限',
    viewSpecificSectionDescription: '查看特定权限',
    userGroupCreationGroupNameLabel: '用户组名称:',
    allUsersEmptyText: '您已选择所有用户。',
    allUsersListHeaderEmail: '电子邮件',
    allUsersListHeaderUsername: '用户名',
    noUsersInGroupText: '没有用户附加到此用户组。',
    hintToAddUsers: '请关联您右侧列表中的用户。',
    existingUsersTitle: '现有用户',
    usernameTitle: '用户名',
    failedToCreateNewUserMessage: '无法创建新用户',
    updateUserTitle: '更新用户',
    createNewUserButton: '创建',
    newUserText: '新用户',
    youAreAdding: '您正在添加',

    groupsLoadErrorText: '无法加载群组',
    groupCreationError: '无法创建新组',
    groupUpdateError: '更新组失败',
    groupDeletionError: '删除群组失败',
    loadGroupsModelLevelPermissionsError: '加载权限失败',
    loadAllUsersError: '加载用户失败',
    loadCompanyFleetsFullListError: `无法加载公司的完整 fleets 列表`,
    loadUsersLogsError: '无法加载用户日志',
    updateUserError: '未能更新用户详细信息',
    loadUserError: '无法加载用户详细信息',
    loadUsersError: '加载用户失败',
    deleteUsersError: '删除用户失败',
    moveUsersError: '无法移动用户',
    changeUserStatusError: '更改用户状态失败',
    loadUserGroupPermissionError: '加载用户组权限失败',

    userTabHeading: '细节',
    userDetailsSectionHeading: '用户详情',
    adminControlsSectionHeading: '管理控制',
    adminRoleAssigmentToggleLabel: '管理员角色',
    partnerRoleAssigmentToggleLabel: '伙伴角色',
    userRoleSectionHeading: '用户角色',

    deleteAccountToggleLabel: '删除帐户',
    userUpdateErrorTitle: '用户更新错误',

    groupListCSVExportIconHint: '将组列表导出到 CSV 文件',

    devicePermissionTitle: '设备',
    fleetTreePermissionTitle: 'Fleets 树',
    profilePermissionTitle: '配置文件',

    fleetAccessTitle: 'Fleet 访问',
    profileAccessTitle: '配置文件访问',

    permissionTitleUploadsAndApplicationsAccessTitle: '上传和应用程序访问',
    permissionTitleUploadsTitle: '上传（文件、证书、应用程序）',

    permissionTitleApplicationsAccess: '应用程序访问',
    permissionTitleViewDeviceLocation: '查看设备位置',
    permissionTitleLiveDeviceLocationTracking: '实时设备位置跟踪',
    permissionTitleViewCurrentLocation: '查看设备当前位置',
    permissionTitleViewLocationHistory: '查看设备位置历史记录',

    globalPermissionsAccessTypeReadOnly: '只读',
    globalPermissionsAccessTypeReadWrite: '读/写',
    globalPermissionsAccessTypeNone: '没有任何',

    globalPermissionsHeadingDescription: '描述',
    globalPermissionsHeadingAccess: '使用权',

    userCreationTabTitle: '创建个人用户',
    failedToLoadPartners: '无法加载合作伙伴',
    failedToLoadPartnerCompanies: '无法加载合作伙伴公司',

    enrollmentEmailTemplateTitle: '注册电子邮件模板',

    fields: {
      username: '用户名',
      firstName: '名',
      lastName: '姓',
      email: '电子邮件',
      emailPlaceholder: '输入电子邮件地址',
      password: '密码',
      active: '活跃',
      inactive: '不活跃',
      status: 'Status',
      lastLoginTitle: 'Last Login',
      creationDateTitle: 'Created on',
      creatorTitle: 'Created By',
      requirePasswordChangeOnNextLogin: '要求用户在下次登录时更改密码',
      disablePasswordChange: '禁止用户更改密码',
      disableEmailAddressChange: '禁止用户更改电子邮件地址',
      disableAccount: '暂时停用帐号',
      companyName: '公司名',
      partnerNameFieldText: '合作伙伴名称',
      enrollmentEmailHint:
        '我们已经起草了一封电子邮件，您可以将其发送给您的员工，让他们注册。 他们需要导航到注册链接以下载设备 agent。',
      emailContentFieldTitle: '电子邮件内容',
      emailSubjectFieldTitle: '电子邮件主题',
    },
    userLogs: {
      type: '类型',
      date: '日期',
      messageLog: '消息日志',
      name: '姓名',
      ipAddress: 'IP 地址',
      userGroupsTitle: '用户组',
      startDate: '开始日期',
      endDate: '结束日期',
    },
  },
  user: {
    admin: '行政',
    partner: '伙伴',
    partneruser: '伙伴用户',
    superuser: '超级用户',
    customer: '顾客',
    user: '用户',
    unknownAccessLevel: '未知访问级别',
  },
  settings: {
    title: '设置',
    accountSettings: '帐号设定',
    accountSettingsLoadFailure: '无法加载帐户信息',
    globalPermissionsLoadFailureMessage: '无法为登录用户加载全局权限。',
    failedToLoadTimezones: '无法加载时区',
    failedToGetSSOSAMLSettings: '无法获取 SAML 设置',
    fields: {
      name: '姓名',
      namePlaceholder: '我的名字',
      email: '电子邮件地址',
      emailPlaceholder: '电子邮件',
    },
    passwordChangeTitle: '密码更改',
    changePassword: '更改密码',
    changingPassword: '在更改密码',
    currentPasswordTitle: '当前密码',
    currentPasswordPlaceholder: '输入当前密码',
    newPasswordTitle: '新密码',
    newPasswordPlaceholder: '输入新密码',
    reTypeNewPasswordTitle: '重新输入新密码',
    validationAllPasswordFieldRequired: '所有字段必填',
    passwordValidationCantBePreviouslyUsed: '新密码需要与旧密码不同',
    passwordValidationDoesNotMatch: '密码不匹配',
    passwordValidationMinimumLength: '密码长度必须至少为 8 个字符',
    passwordChangeFailureText: '修改密码失败',

    companyInfo: '公司信息',
    companyInfoHint: '与这家公司相关的信息',
    companyName: '公司名',
    totalNumberOfLicenses: 'licenses 总数',
    licensesInUse: '正在使用的 Licenses',
    licensesAvailable: '可用的 Licenses',
    commentTitle: '评论',

    commonSettings: '常用设置',
    commonSettingsHint: '与该公司相关的设备设置',
    enableAutomaticAgentUpdates: '启用自动更新 agent',
    setupSettings: '建立设置',
    updatePopupTitle: '通用设置更新',
    updatePopupDefaultError: '不能设置期间',

    loginURLLabel: '登录 URL',
    organizationIdLabel: '组织 ID',
    azureADEntityIdLabel: 'Azure AD 标识符',
    applicationNameLabel: '应用名称',
    federationMetadataXMLFileLabel: 'Federation 元数据 XML',

    invalidXMLFileErrorMessage: '无效的 XML 文件',
    ssoAccountLinkSuccessMessage: '帐号关联成功。',
    ssoAccountSettingsUpdateSuccessMessage: '帐户已成功更新。',
    failedToLinkSSOAccountMessage: `抱歉，我们暂时无法关联您的帐户`,
    azureSAMLLinkAccountText: '链接 Azure 帐户 (SAML)',
    azureSAMLUpdateAccountText: '更新 Azure 帐户 (SAML)',
    updateWarningText: '更新 Azure 帐户设置会影响以下公司',
    azureSAMLLoginButtonText: '使用 Microsoft Azure (SAML 2.0) 登录',
    OIDCLinkAccountHeading: 'OIDC 2.0',
    SAMLLinkAccountHeading: 'SAML 2.0',
    azureADLinkHeading: 'Azure AD',
    azureOIDCLinkAccountText: '链接 Azure 帐户 (OIDC)',
    azureOAuthLoginButtonText: '使用 Microsoft Azure (OAuth 2.0) 登录',
    SSOSettingsSectionHeading: 'SSO 设置',
    SSOSettingsSectionHint: '请输入以下信息以使用 SAML 2.0 链接您的 Azure 帐户',
    preferences: 'Preferences',
    setTimezoneFieldTitle: 'Time Zone',
    setLocaleFieldTitle: 'Locale',
    savePreferences: 'Save',
    preferencesFetchFailed: 'Failed to fetch preferences',
    preferencesUpdateFailed: 'Failed to update preferences',
    preferencesUpdateSucceeded: 'Preferences successfully updated ',
  },
  common: {
    noData: '没有数据',
    send: '发送',
    ok: 'OK',
    cancel: '取消',
    saveChanges: '保存更改',
    save: '保存',
    undo: '撤消',
    close: '关闭',
    filter: '筛选',
    edit: '编辑',
    set: '设置',
    yes: '是的',
    no: '不',
    add: '添加',
    enabled: '启用',
    disabled: '已禁用',
    connected: '连接的',
    disconnected: '断开连接',
    next: '下一个',
    back: '后退',
    confirm: '确认',
    continue: '继续',
    getStarted: '开始',
    inProgress: '进行中',
    successful: '成功的',
    success: '成功',
    error: '错误',
    failed: '失败的',
    filterDescriptionTitle: '过滤器将满足以下条件:',
    filterDescriptionOptionAll: '以下所有',
    filterDescriptionOptionAny: '以下任何一项',
    addCriteria: '添加条件',
    noFiltersApplied: '未应用过滤器',
    selectCondition: '选择条件',
    removeFilter: '移除过滤器',
    selectValue: '选择值',
    selectValueType: '选择值类型',
    location: '位置',
    noLocationSet: '未设置位置',
    noLocationData: '没有数据',
    completed: '已完成',
    somethingWentWrong: '出了些问题',
    proceedConfirmationText: '你要继续吗？',
    confirmationDefaultTitle: '确认',
    confirmationDefaultText: '你确定要这么做吗？',
    alertDefaultTitle: '重要信息',
    optionModalDefaultTitle: '所需资料',
    optionModalDefaultText: '请选择以下选项之一',
    select: '选择',
    promptDefaultTitle: '提示',
    minutes: '分钟',
    hours: '小时',
    days: '天',
    weeks: '周',
    months: '月',
    years: '年',
    notFound: '未找到',
    toolsPanelSelectedTitle: '已选',
    or: '或者',
    startDate: '开始日期',
    endDate: '结束日期',
    systemMessageLoadFailure: '加载系统消息失败',
  },
  fleets: {
    dropdownToggleText: '行动',
    exportTable: '导出表',
    rename: '改名',
    delete: '删除',
    fleet: 'Fleet',
    assignProfiles: '关联配置文件',
    profileAssignmentModalConfirmationButtonText: '关联',
    fleetDetails: 'Fleet 细节',
    profiles: '配置文件',
    areYouSureYouWantToDeleteThisFleet: '你确定要删除这个 fleet 吗?',
    assignDevicesToFleet: '将设备与 fleet 关联:',
    enterAFleetName: '输入 fleet 的名称',

    fleetsTitle: 'Fleets',
    createAFleet: '创建一个 FLEET',
    creationInProgress: '创作中...',
    success: '成功: ',
    fleetCreated: 'Fleet 已创造',
    error: '错误: ',
    cannotPerform: '无法执行',
    fleetMovementConfirmation: `您确定要让“%{fleetName}”成为“%{targetFleetName}”的直接 child fleet 吗?`,
    fleetMovementSecondaryConfirmation: '保留初始 (%{fleetName}) 配置文件.',
    fleetNamePlaceholder: '输入新的 fleet 名称',
    createFleet: '创建 fleet',
    allFleets: '全部 fleets',
    selectAllFleets: '全选 Fleets',
    failedToMoveDevicesErrorText: '无法移动设备',
    fleetDeletionError: '删除 fleet 失败',
    fleetNotEmptyDeletionError:
      '该车队具有与之关联的配置文件。您必须先取消关联所有配置文件，然后才能将其删除。',

    remoteCommandStatusPopupTitle: '发送远程命令',
    fleetDeletingStatusPopupTitle: '删除 fleet',

    fleetSelectionHint: '从树中选择一个 fleet 以显示 Fleet 设备。',
    fleetMovementModalTitle: '选择一个 fleet',
    fleetMovementModalConfirmationButtonText: '移动设备',
    toolsPanelTitle: '所选设备',
    moveDevicesToolTitle: '移动设备',
    unenrollDevicesToolTitle: '取消注册设备',
    pauseAgentUpdateToolTitle: '暂停 Agent 更新',
    resumeAgentUpdateToolTitle: '恢复 Agent 更新',
    updateDPCAppTitle: '将 DPC 更新到最新版本',
    identifyDevicesToolTitle: '识别设备',
    increaseLocationTrackingFrequencyToolTitle: '跟踪设备',
    forceDeviceCheckInToolTitle: '强制设备 Check-in',
    remoteRebootDevicesToolTitle: '远程重启设备',
    remoteWipeDevicesToolTitle: '远程擦除设备',
    remoteLockDevicesToolTitle: '遥控锁装置',
    resetDevicesPasswordToolTitle: '重置设备密码',
    clearApplicationDataAndCacheToolTitle: '清除应用程序数据和缓存（仅限三星）',
    sendMessageToolTitle: '发信息',
    lockDeviceConfigurationToolTitle: '锁定设备配置',
    unlockDeviceConfigurationToolTitle: '解锁设备配置',
    removeDevicesToolTitle: '移除设备',

    unEnrollmentConfirmationText: '您确定要取消注册所选设备吗?',
    lockDeviceConfigurationConfirmationText:
      '您确定要锁定所选设备上的当前配置吗?',
    unlockDeviceConfigurationConfirmationText: '您确定要配置选定的设备吗?',
    removeDevicesConfirmationText: '您确定要删除所选设备吗?',
    rebootDevicesConfirmationText: '您确定要重新启动选定的设备吗?',
    wipeDevicesPrimaryConfirmationText: '您确定要远程擦除所选设备吗?',
    wipeDevicesSecondaryConfirmationText: '也擦SD卡',
    lockDevicesConfirmationText: '您确定要远程锁定所选设备吗?',
    pauseAgentUpdatesConfirmationText: '您确定要暂停所选设备上的 agent 更新吗?',
    resumeAgentUpdatesConfirmationText:
      '您确定要在所选设备上恢复 agent 更新吗?',
    increaseLocationTrackingFrequencyConfirmationText:
      '您确定要在接下来的 10 分钟内增加所选设备的位置跟踪频率吗？',
    identifyDevicesConfirmationText: '您确定要识别所选设备吗?',
    updateDPCAppConfirmationText:
      '您确定要将所选设备上的 DPC 应用程序更新到最新版本吗?',
    forceCheckinConfirmationText: '您确定要强制选定的设备 check in 吗?',
    clearDevicesAppDataConfirmationText: '您确定要清除所选设备上的应用数据吗?',
    resetDevicesPasswordConfirmationText: '您确定要在所选设备上重置密码吗?',

    fleetToProfilesAssignmentSuccessMessage: '已成功分配新选择的配置文件。',
    fleetToProfilesUnassignmentSuccessMessage: '配置文件已成功分离。',
    fleetToProfilesAssignmentFailureMessage: '无法关联一个或多个配置文件。',
    fleetToProfilesUnassignmentFailureMessage:
      '无法取消关联一个或多个配置文件。',
    fleetDeletionFailure: '删除 fleet 失败。',
    notEmptyFleetTitle: 'Fleet 不空',
    fleetDeletionFailureDueToDevicesContentMessage:
      '无法删除此 fleet，因为它当前包含一台或多台设备.',
    fleetDeletionFailureDueToDevicesContentTip:
      '请删除此 fleet 包含的所有设备，然后重试.',
    fleetDeletionFailureDueToAssignedProfiles:
      '无法删除此 fleet，因为它当前分配有一个或多个配置文件。请取消关联这些配置文件，然后重试。',
    fleetDeletionConfirmation:
      '删除此 fleet 将删除它的所有版本。 这个动作是不可逆的。 你确定你要继续吗?',
    lockDeviceConfigurationSuccessMessage: '配置锁定命令成功发送到所选设备',
    unlockDeviceConfigurationSuccessMessage: '配置解锁命令成功发送到所选设备',
    lockDeviceConfigurationErrorMessage: '无法向所选设备发送配置锁定命令',
    unlockDeviceConfigurationErrorMessage: '无法向所选设备发送配置解锁命令',
    failedToLoadProfilesAssignedToFleet: '无法加载分配给此 fleet 的配置文件',
    fleetCreationFailedMessage: 'Fleet 创建失败',
    fleetCreationTitle: 'Fleet 创建',
    loadFleetConfigByIdErrorMessage: '无法加载 fleet 配置',
    loadAllFleetsErrorMessage: '未能加载 fleets',
    failedToFetchSelectedFleet: '无法加载选定的 fleet',
    unenrollmentFailedText: '无法取消注册所选设备',
  },
  fileSystem: {
    title: '文件系统',
    applicationsSuccessfulUploadMessageText: '以下应用程序已成功上传:',
    htmlFilesSuccessfulUploadMessageText: '以下 HTML 文件已成功上传:',
    certificatesSuccessfulUploadMessageText: '以下证书已成功上传:',
    filesSuccessfulUploadMessageText: '以下文件已成功上传:',

    applicationsDuplicateUploadFailureMessageText:
      '以下应用程序未上传，因为此（这些）应用程序的相同版本之前已上传。 请尝试上传不同的版本。',
    htmlFilesDuplicateUploadFailureMessageText:
      '以下 HTML 文件未上传，因为之前上传了具有相同文件名的其他文件。 请重命名文件并再次尝试上传。',
    certificatesDuplicateUploadFailureMessageText:
      '以下证书未上传，因为之前上传了其他具有相同文件名的证书。 请重命名证书并再次尝试上传。',
    filesDuplicateUploadFailureMessageText:
      '以下文件未上传，因为之前上传了其他具有相同文件名的文件。 请重命名文件并再次尝试上传。',

    applicationsUploadFailureMessageText: '以下应用程序上传失败。',
    htmlFilesUploadFailureMessageText: '以下 HTML 文件上传失败。',
    certificatesUploadFailureMessageText: '以下证书上传失败。',
    filesUploadFailureMessageText: '以下文件上传失败。',

    apkFilesUploadMenuItem: 'APK 文件',
    filesUploadMenuItem: '文件',
    certificatesUploadMenuItem: '证书',
    HTMLKioskFilesUploadMenuItem: 'HTML Kiosk 文件',

    apkFilesUploadTab: '添加 APK 文件',
    filesUploadTab: '添加文件',
    certificatesUploadTab: '添加证书',
    HTMLKioskFilesUploadTab: '添加 HTML Kiosk 文件',

    apkUploading: 'APK 上传中:',
    filesUploading: '文件上传中:',
    certificatesUploading: '证书上传中:',
    htmlKioskFilesUploading: 'HTML Kiosk 文件上传中:',

    apkUploadingSuccessMessage: 'APK 成功上传!',
    filesUploadingSuccessMessage: '文件上传成功！',
    certificatesUploadingSuccessMessage: '证书上传成功!',
    htmlKioskFilesUploadingSuccessMessage: 'HTML Kiosk 文件成功上传!',

    apkDropZoneTitle: '从您的电脑拖放您的应用程序',
    filesDropZoneTitle: '从电脑中拖放文件',
    certificatesDropZoneTitle: '从您的电脑拖放您的证书',
    htmlKioskFilesDropZoneTitle: '从电脑中拖放 HTML Kiosk 文件',

    apkAddLinkTitle: '添加指向 APK 文件存储位置的链接',
    filesAddLinkTitle: '添加指向文件存储位置的链接',
    certificatesAddLinkTitle: '添加指向证书文件存储位置的链接',
    htmlKioskAddLinkTitle: '添加指向 HTML kiosk 文件存储位置的链接',

    uploading: '上传中:',
    registeringFilesOnServer: '在服务器上注册文件...',
    browse: '浏览',
    uploadFailed: '上传失败',

    appUploadRejectionMessage:
      '无法上传以下应用程序，因为它们的大小超过了允许的最大大小 (%{max})，或者它们的文件类型与允许的类型之一 (%{allowedFileTypes}) 不匹配',
    certificateUploadRejectionMessage:
      '无法上传以下证书，因为它们的大小超过了允许的最大大小 (%{max}) 或它们的文件类型与允许的类型之一 (%{allowedFileTypes}) 不匹配',
    fileUploadRejectionMessage:
      '无法上传以下文件，因为它们的大小超过了允许的最大大小 (%{max}) 或它们的文件类型与允许的类型之一 (%{allowedFileTypes}) 不匹配',
    htmlUploadRejectionMessage:
      '无法上传以下 HTML 文件，因为它们的大小超过了允许的最大大小 (%{max}) 或它们的文件类型与允许的类型之一 (%{allowedFileTypes}) 不匹配',

    applicationsSelected: '应用选择',
    removeApplications: ' 移除应用程序',
    deletingApplications: '删除应用程序',

    versionsDeletionTitle: 'Versions Deletion',
    versionsDeletionFailed: 'Versions deletion failed',
    versionsSelected: 'Versions Selected',
    removeVersions: 'Remove Versions',
    deletingVersions: 'Deleting Versions',
    deleteVersionsConfirmation:
      'This is going to delete the selected versions. Are you sure that you want to continue?',
    versionsNotDeletedByAssignedToProfileTitle: `The following version(s) was (were) NOT deleted because they are still assigned to a profile (%{profile})`,
    versionsDeletedTitle:
      'The following version(s) was (were) successfully deleted',

    fileName: '文档名称',
    contentType: '内容类型',
    assignFiles: '关联文件：',
    uploadedBy: 'Uploaded By',

    selectedFiles: '选定的文件',
    removeFiles: '删除文件',
    deletionConfirmation: '您确定要删除选定的文件吗?',
    failedToAssignFilesToProfile: '无法将文件与配置文件关联',
    failedToDeleteFiles: '删除文件失败',
    failedToLoadFiles: '加载文件失败',

    sendRemoteLinkFailure: '无法将链接发送到服务器进行处理.',
    sendRemoteLinkSuccess: '链接成功发送到服务器进行处理。',
  },
};
