import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  isDataLoadingRequired,
  isEmpty,
  isLoadingStatusError,
  isLoadingStatusStarted,
  notEmpty,
} from '../../common/helpers';
import { Throbber } from '../Throbber';
import { DataConstants } from '../../constants/data';
import { profilesSelector, profilesV2Selector } from '../../selectors/profiles';
import { useThunks } from '../../hooks/useThunks';
import { fleetsV2Selector } from '../../features/fleets/fleetsV2.selectors';
import { allCompanyFleetsV2Selector } from '../../selectors/fleetsSelector';

const AsyncRendererWithDataFetching = ({
  children,
  dataList = [],
  customDataForceFetch = {},
  customDataFetchFunctions = {},
  customDataStatus = {},
  customDataError = {},
  ignoreRerenders = false,
}) => {
  const [renderedOnce, setRenderedOnce] = useState(false);
  const { status: profilesV1Status, error: profilesV1Error } = useSelector(
    profilesSelector
  );
  const {
    status: profilesV2Status,
    error: profilesV2Error,
    isFullList: isProfilesV2FullList,
  } = useSelector(profilesV2Selector);
  const { error: fleetsV2Error, status: fleetsV2Status } = useSelector(
    fleetsV2Selector
  );

  const {
    error: allCompanyFleetsError,
    status: allCompanyFleetsStatus,
  } = useSelector(allCompanyFleetsV2Selector);

  const {
    loadProfilesV2,
    loadProfiles,
    loadAllFleetsV2,
    loadCompanyFleetsFullList,
  } = useThunks();

  const dataSources = {
    [DataConstants.PROFILES_V2]: {
      status: profilesV2Status,
      error: profilesV2Error,
      forceFetch: !isProfilesV2FullList,
      fetchFunction: (params) => loadProfilesV2({ ...params }),
    },
    [DataConstants.PROFILES_V1]: {
      status: profilesV1Status,
      error: profilesV1Error,
      fetchFunction: loadProfiles,
    },
    [DataConstants.FLEETS_V2]: {
      status: fleetsV2Status,
      error: fleetsV2Error,
      fetchFunction: loadAllFleetsV2,
    },
    [DataConstants.ALL_COMPANY_FLEETS_V2]: {
      status: allCompanyFleetsStatus,
      error: allCompanyFleetsError,
      fetchFunction: loadCompanyFleetsFullList,
    },
  };

  useDeepCompareEffect(() => {
    for (const fetchFunctionsKey in customDataFetchFunctions) {
      if (
        customDataForceFetch[fetchFunctionsKey] ||
        isDataLoadingRequired(customDataStatus[fetchFunctionsKey])
      ) {
        customDataFetchFunctions[fetchFunctionsKey]?.();
      }
    }
  }, [customDataStatus]);

  useEffect(() => {
    setRenderedOnce(true);
  }, []);

  useEffect(() => {
    for (const data of dataList) {
      const { id, params, forceFetch = false } = data;
      const dataSource = dataSources[id] ?? {};
      if (
        isDataLoadingRequired(dataSource.status) ||
        forceFetch ||
        dataSource.forceFetch
      ) {
        dataSource.fetchFunction?.(params);
      }
    }
  }, []);

  const renderContent = () => {
    const shouldIgnoreRerenders = renderedOnce && ignoreRerenders;
    if (
      shouldIgnoreRerenders ||
      (isEmpty(customDataStatus) && isEmpty(dataList))
    ) {
      return children;
    }
    let content = null;

    if (notEmpty(customDataStatus)) {
      for (const statusKey in customDataStatus) {
        if (isLoadingStatusError(customDataStatus[statusKey])) {
          content = customDataError[statusKey];
          break;
        }

        if (isLoadingStatusStarted(customDataStatus[statusKey])) {
          content = <Throbber />;
          break;
        }
      }
    }

    if (content) {
      return content;
    }

    if (notEmpty(dataList)) {
      for (const data of dataList) {
        const { id } = data;
        const dataSource = dataSources[id] ?? {};
        if (isLoadingStatusError(dataSource.status)) {
          content = dataSource.error;
          break;
        }

        if (isLoadingStatusStarted(dataSource.status)) {
          content = <Throbber />;
          break;
        }
      }
    }

    if (content) {
      return content;
    }

    return children;
  };

  return (
    <section className="async-renderer-with-data-fetching__wrapper">
      {renderContent()}
    </section>
  );
};

export default AsyncRendererWithDataFetching;
