import { AttributeType } from '../../common/constants';
import { AlertRoutes, RootRoutes } from '../../utils/routes';
import AlertStatus from './components/alertStatus.component';
import AlertType from './components/alertType.component';
import LinkedFleets from './components/linkedFleets.components';
import TotalAlerts from './components/totalAlerts.component';
import { AlertConstants } from './alerts.constants';
import { filterConditions } from '../../constants/filter';

export const alertsColumns = [
  {
    key: AlertConstants.NAME,
    type: AttributeType.TEXT,
    titleCode: 'alerts.alertName',
    ref: `${RootRoutes.ALERTS}${AlertRoutes.ALERTS}/alertType`,
    refId: 'id',
    useQueryParam: true,
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  {
    key: AlertConstants.STATUS,
    type: AttributeType.TEXT,
    // hidden: true,
    titleCode: 'alerts.alertStatus',
    renderer: AlertStatus,
    // searchable: true,
    // availableFilterConditions: [
    //     filterConditions.EQUAL,
    //     filterConditions.NOT_EQUAL
    // ]
    // availableOptions: [
    //   {key: AlertStatusTypes.ACTIVE, titleCode: 'alerts.active'},
    //   {key: AlertStatusTypes.INACTIVE, titleCode: 'alerts.inactive'},
    // ]
  },
  {
    key: AlertConstants.ALERT_TYPE,
    type: AttributeType.TEXT,
    titleCode: 'alerts.alertType',
    renderer: AlertType,
  },
  // {
  //   key: AlertConstants.ALERT_CONDITION,
  //   type: AttributeType.TEXT,
  //   titleCode: 'alerts.alertCondition',
  //   searchable: true,
  //   availableFilterConditions: [
  //       filterConditions.CONTAINS,
  //       filterConditions.EQUAL
  //   ]
  // },
  {
    key: AlertConstants.LINKED_FLEETS,
    type: AttributeType.TEXT,
    titleCode: 'alerts.linkedFleets',
    renderer: LinkedFleets,
  },
  {
    key: AlertConstants.TOTAL_ALERTS,
    type: AttributeType.TEXT,
    titleCode: 'alerts.totalAlerts',
    renderer: TotalAlerts,
  },
];
