import client from '../../utils/client';
import services from '../../utils/services';

export async function getDeviceProfilesApi(id) {
  return client.get(services.getDeviceProfiles + id);
}

export async function getDeviceApplicationsApi(id) {
  return client.get(services.getDeviceApps + id);
}

export async function getDeviceLogsApi(deviceId, params) {
  return client.get(`${services.devices}location_history/${deviceId}`, {
    params,
  });
}

export async function getDeviceActivityLogsApi(deviceId, params) {
  return client.get(`${services.devices}device_logs/${deviceId}`, {
    params,
  });
}

export async function requestDeviceRemoteControlApi(id) {
  return client.post(`${services.devices}${id}/sharing`);
}

export async function pingDeviceLocationApi(id) {
  return client.post(`${services.devices}${id}/tracking`);
}

export async function uninstallApplicationsApi(data, companyId) {
  return client.post(
    `${services.devices}${companyId}${services.applications}`,
    data
  );
}
