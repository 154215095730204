import {
  COLUMNS_TOGGLE_INITIALIZE_ALL_AVAILABLE_COLUMNS,
  COLUMNS_TOGGLE_REORDER_COLUMNS,
  COLUMNS_TOGGLE_TOGGLE_COLUMN,
  GET_SAVED_TABLE_COLUMNS_FAILED,
  GET_SAVED_TABLE_COLUMNS_STARTED,
  GET_SAVED_TABLE_COLUMNS_SUCCESS,
  SAVE_TABLE_COLUMNS_FAILED,
  SAVE_TABLE_COLUMNS_STARTED,
  SAVE_TABLE_COLUMNS_SUCCESS,
} from './columnsToggle.actionsTypes';
import { NetworkStatus } from '../common/constants';
import { compact } from '../common/helpers';
import { Pages } from '../constants/pages';
import { getDefaultDevicesTableVisibleColumns } from '../features/devices/components/Devices/devicesColumns';

const defaultDevicesTableVisibleColumns = getDefaultDevicesTableVisibleColumns();

export default function columnsToggle(
  state = {
    loadingVisibleColumnsListsStatus: NetworkStatus.NONE,
    loadingVisibleColumnsListsError: null,

    visibleColumnsLists: {
      [Pages.FLEET_DEVICES]: defaultDevicesTableVisibleColumns,
      [Pages.ALL_DEVICES]: defaultDevicesTableVisibleColumns,

      saveTableColumnsStatus: NetworkStatus.NONE,
      saveTableColumnsError: undefined,

      getSaveTableColumnsStatus: NetworkStatus.NONE,
      getSaveTableColumnsError: undefined,
    },

    allAvailableColumns: {},
  },
  { type, payload }
) {
  switch (type) {
    case COLUMNS_TOGGLE_INITIALIZE_ALL_AVAILABLE_COLUMNS:
      return {
        ...state,
        allAvailableColumns: {
          ...state.allAvailableColumns,
          [payload.page]: payload.allColumns,
        },
      };
    case COLUMNS_TOGGLE_TOGGLE_COLUMN: {
      const { page, key } = payload;
      const currentVisibleList = state.visibleColumnsLists[page];
      const isToggledVisible = currentVisibleList.includes(key);
      const newVisibleList = isToggledVisible
        ? currentVisibleList.filter((item) => item !== key)
        : state.allAvailableColumns[page].reduce((acc, column) => {
            if (currentVisibleList.includes(column.key)) {
              return [...acc, column.key];
            }
            if (column.key === key) {
              return [...acc, key];
            }
            return acc;
          }, []);
      return {
        ...state,
        visibleColumnsLists: {
          ...state.visibleColumnsLists,
          [page]: newVisibleList,
        },
      };
    }

    case COLUMNS_TOGGLE_REORDER_COLUMNS: {
      const { page, reorderedAllColumns, reorderedVisibleColumns } = payload;
      return {
        ...state,
        visibleColumnsLists: {
          ...state.visibleColumnsLists,
          [page]: [...reorderedVisibleColumns],
        },
        allAvailableColumns: {
          ...state.allAvailableColumns,
          [page]: [...reorderedAllColumns],
        },
      };
    }

    case SAVE_TABLE_COLUMNS_STARTED:
      return {
        ...state,
        saveTableColumnsStatus: NetworkStatus.STARTED,
        saveTableColumnsError: undefined,
      };

    case SAVE_TABLE_COLUMNS_FAILED:
      return {
        ...state,
        saveTableColumnsStatus: NetworkStatus.ERROR,
        saveTableColumnsError: payload,
      };

    case SAVE_TABLE_COLUMNS_SUCCESS:
      return {
        ...state,
        saveTableColumnsStatus: NetworkStatus.DONE,
        saveTableColumnsError: undefined,
      };

    case GET_SAVED_TABLE_COLUMNS_STARTED:
      return {
        ...state,
        visibleColumnsLists: {
          ...state.visibleColumnsLists,
          getSaveTableColumnsStatus: NetworkStatus.STARTED,
          getSaveTableColumnsError: undefined,
        },
      };

    case GET_SAVED_TABLE_COLUMNS_FAILED:
      return {
        ...state,
        visibleColumnsLists: {
          ...state.visibleColumnsLists,
          getSaveTableColumnsStatus: NetworkStatus.ERROR,
          getSaveTableColumnsError: payload,
        },
      };

    case GET_SAVED_TABLE_COLUMNS_SUCCESS: {
      const { settings = {} } = payload;
      return {
        ...state,
        visibleColumnsLists: {
          ...state.visibleColumnsLists,
          getSaveTableColumnsStatus: NetworkStatus.DONE,
          getSaveTableColumnsError: undefined,
          ...compact(settings),
        },
      };
    }

    default:
      return state;
  }
}
