import React from 'react';

import { ReactComponent as BatteryIcon } from '../../assets/images/battery.svg';
import { ReactComponent as GeoFencingExitIcon } from '../../assets/images/geoFencingExit.svg';
import { ReactComponent as GeoFencingEnterIcon } from '../../assets/images/geoFencingEnter.svg';
import { ReactComponent as OutOfContactIcon } from '../../assets/images/outOfContact.svg';

export const AlertConstants = {
  NAME: 'name',
  STATUS: 'status',
  ALERT_TYPE: 'alertType',
  ALERT_CONDITION: 'alertCondition',
  LINKED_FLEETS: 'fleets',
  TOTAL_ALERTS: 'totalAlerts',

  COMMUNICATION_CHANNEL: 'communication_channel',
  COMMUNICATION_CHANNEL_DETAILS: 'communication_channel_details',
};

export const AlertStatusTypes = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const AlertTypeTypes = {
  BATTERY: 'battery',
  OUT_OF_CONTACT: 'out_of_contact',
  ENTER_GEOFENCE: 'enter_geofence',
  EXIT_GEOFENCE: 'exit_geofence',
};

export const AlertRowAlertTypeIcons = {
  [AlertTypeTypes.BATTERY]: (
    <BatteryIcon className="alert-row__alert-type-icon" />
  ),
  [AlertTypeTypes.OUT_OF_CONTACT]: (
    <OutOfContactIcon className="alert-row__alert-type-icon" />
  ),
  [AlertTypeTypes.ENTER_GEOFENCE]: (
    <GeoFencingEnterIcon className="alert-row__alert-type-icon" />
  ),
  [AlertTypeTypes.EXIT_GEOFENCE]: (
    <GeoFencingExitIcon className="alert-row__alert-type-icon" />
  ),
};
