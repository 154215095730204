import React from 'react';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';
import { ReactComponent as Cross } from '../../assets/images/crest.svg';
import StyleUtils from '../../utils/styleUtils';
import BannerActions from './banner.actions';
import { bannerSelector } from './banner.selector';

const Banner = ({ bannerData: { text, isVisible } }) => {
  const closeBanner = () => BannerActions.closeBanner();

  return isVisible && text ? (
    <Portal node={document?.getElementById('system-message-container')}>
      <section className="banner__container">
        <div className="banner__inner-container">
          <Cross
            className={StyleUtils.mergeClasses(
              'icon--fixed-square',
              'banner__close-icon'
            )}
            onClick={closeBanner}
          />
          <div className="banner__text">{text}</div>
        </div>
      </section>
    </Portal>
  ) : null;
};

const mapStateToProps = (state) => ({
  bannerData: bannerSelector(state),
});

export default connect(mapStateToProps)(Banner);
