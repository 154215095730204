import React from 'react';
import { I18n } from 'react-redux-i18n';

const KioskBuilderSideBar = ({
  onBack,
  settings,
  validationError,
  onKioskBackdoorPwdChange,
  passwordConfirmation,
  onChangePasswordConfirmation,
  sectionDescription,
}) => (
  <div className={'kiosk-builder-side-bar'}>
    <div className={'kiosk-builder-side-bar__back-button'} onClick={onBack}>
      <div>⟵</div>
      <div>{I18n.t('profiles.kiosk.back')}</div>
    </div>
    <div className={'kiosk-builder-side-bar-section-description'}>
      {sectionDescription}
    </div>
    <div className={'kiosk-builder-side-bar__section-title'}>
      {I18n.t('profiles.kiosk.security')}
    </div>
    <div className={'kiosk-builder-side-bar-input-field'}>
      {validationError ? (
        <div className={'kiosk-builder-side-bar-input-field__error'}>
          {validationError}
        </div>
      ) : null}
      <input
        className="field-form-control"
        type="password"
        value={settings.kioskBackdoorPwd || ''}
        onChange={onKioskBackdoorPwdChange}
        placeholder={I18n.t('profiles.kiosk.password')}
        style={validationError ? { borderColor: '#ff0000' } : {}}
        autoComplete="new-password"
      />
    </div>
    <div className={'kiosk-builder-side-bar-input-field'}>
      {validationError ? (
        <div className={'kiosk-builder-side-bar-input-field__error'}>
          {validationError}
        </div>
      ) : null}
      <input
        className="field-form-control"
        type="password"
        placeholder={I18n.t('profiles.kiosk.confirmPassword')}
        value={passwordConfirmation || ''}
        onChange={onChangePasswordConfirmation}
        style={validationError ? { borderColor: '#ff0000' } : {}}
        autoComplete="new-password"
      />
    </div>
  </div>
);

export default KioskBuilderSideBar;
