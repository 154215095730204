import client from '../../utils/client';
import {
  createAlertFailed,
  createAlertStarted,
  createAlertSuccess,
  deleteAlertFailed,
  deleteAlertStarted,
  deleteAlertSuccess,
  loadAlertsFailed,
  loadAlertsStart,
  loadAlertsSuccess,
} from './alerts.actions';
import services from '../../utils/services';
import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';
import { serializeAlert, deserializeAlert } from './alerts.serializer';
import { I18n } from 'react-redux-i18n';

export function loadAlerts({ offset = 0, isInitialLoad = false } = {}) {
  return async (dispatch) => {
    try {
      dispatch(loadAlertsStart(isInitialLoad));
      const { status, data, text } = await client.get(`${services.alertList}`, {
        params: {
          offset: isInitialLoad ? 0 : offset,
          limit: INFINITE_SCROLL_LIMIT,
        },
      });

      if (status === 200 && data && Array.isArray(data.results)) {
        const { results, next: nextUrl, count } = data;
        dispatch(
          loadAlertsSuccess({
            data: results.map(deserializeAlert),
            nextUrl,
            count,
            isInitialLoad,
          })
        );
      } else {
        dispatch(loadAlertsFailed({ error: text }, isInitialLoad));
      }
    } catch (error) {
      dispatch(
        loadAlertsFailed({
          error: I18n.t('alerts.alertsLoadFailureMessage'),
          isInitialLoad,
        })
      );
    }
  };
}

export function createAlert({ alert }) {
  return async (dispatch) => {
    try {
      dispatch(createAlertStarted());
      const alertSerialized = serializeAlert(alert);
      const { status, data } = await client.post(
        services.createAlert,
        alertSerialized
      );
      if (status === 200 && data) {
        dispatch(createAlertSuccess(data));
      } else {
        dispatch(
          createAlertFailed(I18n.t('alerts.alertCreationFailureMessage'))
        );
      }
    } catch (error) {
      dispatch(createAlertFailed(I18n.t('alerts.alertCreationFailureMessage')));
    }
  };
}

export function deleteAlerts(alerts) {
  return async (dispatch) => {
    try {
      dispatch(deleteAlertStarted());
      let response = await client.post(services.alertDelete, {
        alert_id_list: alerts,
      });
      if (response.status === 200) {
        dispatch(deleteAlertSuccess());
        dispatch(
          loadAlerts({
            offset: 0,
            limit: INFINITE_SCROLL_LIMIT,
            isInitialLoad: true,
          })
        );
      } else {
        dispatch(deleteAlertFailed(response.text));
      }
    } catch (error) {
      dispatch(
        deleteAlertFailed(
          new Error(I18n.t('alerts.alertsDeletionFailureMessage'))
        )
      );
    }
  };
}
