import {
  DEVICE_LOAD_ACTIVITY_LOGS_FAILED,
  DEVICE_LOAD_ACTIVITY_LOGS_RESET,
  DEVICE_LOAD_ACTIVITY_LOGS_STARTED,
  DEVICE_LOAD_ACTIVITY_LOGS_SUCCESS,
  DEVICE_LOAD_APPLICATIONS_FAILED,
  DEVICE_LOAD_APPLICATIONS_RESET,
  DEVICE_LOAD_APPLICATIONS_STARTED,
  DEVICE_LOAD_APPLICATIONS_SUCCESS,
  DEVICE_LOAD_LOGS_FAILED,
  DEVICE_LOAD_LOGS_RESET,
  DEVICE_LOAD_LOGS_STARTED,
  DEVICE_LOAD_LOGS_SUCCESS,
  DEVICE_LOAD_PROFILES_FAILED,
  DEVICE_LOAD_PROFILES_STARTED,
  DEVICE_LOAD_PROFILES_SUCCESS,
  DEVICE_UNINSTALL_APPLICATIONS_FAILED,
  DEVICE_UNINSTALL_APPLICATIONS_RESET,
  DEVICE_UNINSTALL_APPLICATIONS_STARTED,
  DEVICE_UNINSTALL_APPLICATIONS_SUCCESS,
  LOAD_DEVICE_FAILED,
  LOAD_DEVICE_STARTED,
  RESET_DEVICE_DATA,
  SET_DEVICE_DATA,
  UPDATE_DEVICE_PROPERTY_FAILED,
  UPDATE_DEVICE_PROPERTY_RESET,
  UPDATE_DEVICE_PROPERTY_STARTED,
  UPDATE_DEVICE_PROPERTY_SUCCESS,
} from './device.actionTypes';

export const loadDeviceStarted = () => ({
  type: LOAD_DEVICE_STARTED,
});

export const loadDeviceFailed = () => ({
  type: LOAD_DEVICE_FAILED,
});

export function setDeviceData(data) {
  return {
    type: SET_DEVICE_DATA,
    payload: data,
  };
}

export function resetDeviceData() {
  return {
    type: RESET_DEVICE_DATA,
  };
}

export function loadDeviceProfilesStarted() {
  return {
    type: DEVICE_LOAD_PROFILES_STARTED,
  };
}

export function loadDeviceProfilesFailed(payload) {
  return {
    type: DEVICE_LOAD_PROFILES_FAILED,
    payload,
  };
}

export function loadDeviceProfilesSuccess(payload) {
  return {
    type: DEVICE_LOAD_PROFILES_SUCCESS,
    payload,
  };
}

export function loadDeviceApplicationsStarted() {
  return {
    type: DEVICE_LOAD_APPLICATIONS_STARTED,
  };
}

export function loadDeviceApplicationsFailed(payload) {
  return {
    type: DEVICE_LOAD_APPLICATIONS_FAILED,
    payload,
  };
}

export function loadDeviceApplicationsSuccess(payload) {
  return {
    type: DEVICE_LOAD_APPLICATIONS_SUCCESS,
    payload,
  };
}

export function resetLoadDeviceApplications() {
  return {
    type: DEVICE_LOAD_APPLICATIONS_RESET,
  };
}

export function loadDeviceLogsStarted() {
  return {
    type: DEVICE_LOAD_LOGS_STARTED,
  };
}

export function loadDeviceLogsFailed(payload) {
  return {
    type: DEVICE_LOAD_LOGS_FAILED,
    payload,
  };
}

export function loadDeviceLogsSuccess(payload) {
  return {
    type: DEVICE_LOAD_LOGS_SUCCESS,
    payload,
  };
}

export function loadDeviceLogsReset() {
  return {
    type: DEVICE_LOAD_LOGS_RESET,
  };
}

export function loadDeviceActivityLogsStarted() {
  return {
    type: DEVICE_LOAD_ACTIVITY_LOGS_STARTED,
  };
}

export function loadDeviceActivityLogsFailed(payload) {
  return {
    type: DEVICE_LOAD_ACTIVITY_LOGS_FAILED,
    payload,
  };
}

export function loadDeviceActivityLogsSuccess(payload) {
  return {
    type: DEVICE_LOAD_ACTIVITY_LOGS_SUCCESS,
    payload,
  };
}

export function loadDeviceActivityLogsReset() {
  return {
    type: DEVICE_LOAD_ACTIVITY_LOGS_RESET,
  };
}

export function applicationsUninstallStarted() {
  return {
    type: DEVICE_UNINSTALL_APPLICATIONS_STARTED,
  };
}

export function applicationsUninstallFailed() {
  return {
    type: DEVICE_UNINSTALL_APPLICATIONS_FAILED,
  };
}

export function applicationsUninstallSuccess() {
  return {
    type: DEVICE_UNINSTALL_APPLICATIONS_SUCCESS,
  };
}

export function applicationsUninstallReset() {
  return {
    type: DEVICE_UNINSTALL_APPLICATIONS_RESET,
  };
}

export function updateDevicePropertyStarted() {
  return {
    type: UPDATE_DEVICE_PROPERTY_STARTED,
  };
}

export function updateDevicePropertyFailed(payload) {
  return {
    type: UPDATE_DEVICE_PROPERTY_FAILED,
    payload,
  };
}

export function updateDevicePropertySuccess() {
  return {
    type: UPDATE_DEVICE_PROPERTY_SUCCESS,
  };
}

export function updateDevicePropertyReset() {
  return {
    type: UPDATE_DEVICE_PROPERTY_RESET,
  };
}
