import React from 'react';
import 'ol/ol.css';
import './assets/styles/index.scss';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import AppConnected from './App';
import { store } from './store/store';
import { history } from './utils/history';
import { selectForCurrentBrand } from './common/helpers';

const favicon = document.querySelector('[rel="shortcut icon"]');

const headerData = selectForCurrentBrand({
  original: {
    title: 'Springdel MDM',
    href: '/favicon.ico',
  },
  janam: {
    title: 'Janam MDM',
    href: '/janamFavicon.ico',
  },
  incube: {
    title: 'Incube Ping',
    href: '/incubeFavicon.ico',
  },
});

document.title = headerData.title;
favicon.href = headerData.href;

render(
  <Provider store={store}>
    <Router history={history}>
      <AppConnected />
    </Router>
  </Provider>,
  document.getElementById('root')
);
