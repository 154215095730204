import { NetworkStatus } from '../../common/constants';
import {
  PROFILE_CREATE_ERROR,
  PROFILE_CREATE_RESET,
  PROFILE_CREATE_STARTED,
  PROFILE_CREATE_SUCCESS,
  PROFILE_LOAD_APPLICATIONS_FAILED,
  PROFILE_LOAD_APPLICATIONS_STARTED,
  PROFILE_LOAD_APPLICATIONS_SUCCESS,
  PROFILE_LOAD_FAILED,
  PROFILE_LOAD_STARTED,
  PROFILE_LOAD_SUCCESS,
  PROFILE_RESET,
  PROFILE_UPDATE_ERROR,
  PROFILE_UPDATE_RESET,
  PROFILE_UPDATE_STARTED,
  PROFILE_UPDATE_SUCCESS,
} from './profile.actions';

export default function profile(
  state = {
    data: undefined,
    status: NetworkStatus.NONE,
    error: undefined,
    createProfileStatus: NetworkStatus.NONE,
    createProfileError: undefined,
    updateProfileStatus: NetworkStatus.NONE,
    updateProfileError: undefined,
    applications: [],
    applicationsStatus: NetworkStatus.NONE,
    applicationsError: undefined,
  },
  action
) {
  switch (action.type) {
    case PROFILE_CREATE_STARTED:
      return Object.assign({}, state, {
        createProfileStatus: NetworkStatus.STARTED,
        createProfileError: undefined,
      });
    case PROFILE_CREATE_SUCCESS:
      return Object.assign({}, state, {
        createProfileStatus: NetworkStatus.DONE,
        createProfileError: undefined,
        data: action.payload,
      });
    case PROFILE_CREATE_ERROR:
      return Object.assign({}, state, {
        createProfileStatus: NetworkStatus.ERROR,
        createProfileError: action.payload,
      });
    case PROFILE_CREATE_RESET:
      return Object.assign({}, state, {
        createProfileStatus: NetworkStatus.NONE,
        createProfileError: undefined,
      });
    case PROFILE_UPDATE_STARTED:
      return Object.assign({}, state, {
        updateProfileStatus: NetworkStatus.STARTED,
        updateProfileError: undefined,
      });
    case PROFILE_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updateProfileStatus: NetworkStatus.DONE,
        updateProfileError: undefined,
        data: action.payload,
      });
    case PROFILE_UPDATE_ERROR:
      return Object.assign({}, state, {
        updateProfileStatus: NetworkStatus.ERROR,
        updateProfileError: action.payload,
      });
    case PROFILE_UPDATE_RESET:
      return Object.assign({}, state, {
        updateProfileStatus: NetworkStatus.NONE,
        updateProfileError: undefined,
      });
    case PROFILE_LOAD_STARTED:
      return Object.assign({}, state, {
        data: undefined,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case PROFILE_LOAD_FAILED:
      return Object.assign({}, state, {
        data: undefined,
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case PROFILE_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    case PROFILE_RESET:
      return Object.assign({}, state, {
        data: undefined,
        status: NetworkStatus.NONE,
        error: undefined,
        applications: [],
        applicationsStatus: NetworkStatus.NONE,
        applicationsError: undefined,
      });
    case PROFILE_LOAD_APPLICATIONS_STARTED:
      return Object.assign({}, state, {
        applicationsStatus: NetworkStatus.STARTED,
        applicationsError: undefined,
      });
    case PROFILE_LOAD_APPLICATIONS_SUCCESS:
      return Object.assign({}, state, {
        applicationsStatus: NetworkStatus.DONE,
        applicationsError: undefined,
        applications: action.payload,
      });
    case PROFILE_LOAD_APPLICATIONS_FAILED:
      return Object.assign({}, state, {
        applicationsStatus: NetworkStatus.ERROR,
        applicationsError: action.payload,
      });

    default:
      return state;
  }
}
