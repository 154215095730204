export const serializeGroup = (data, isEdit = false) => {
  if (isEdit) {
    return {
      name: data.name,
      // for adding items
      user_list: data.userList || [],
      model_global_permissions_list: data.modelGlobalPermissionsList || [],
      global_read_permissions_list: data.globalReadPermissionsList || [],
      global_read_write_permissions_list:
        data.globalReadWritePermissionsList || [],

      // v1
      // REVERT_POINT
      // fleets_view_specific_list: data.fleetsViewSpecificList || [],
      // fleets_rc_enabled_list: data.fleetsRemoteControlEnabledList || [],

      // v2
      fleets_v2_view_specific_list: data.fleetsViewSpecificList || [],
      fleets_v2_rc_enabled_list: data.fleetsRemoteControlEnabledList || [],

      // for removing items
      remove_user_list: data.removeUserList || [],
      remove_model_global_permissions_list:
        data.removeModelGlobalPermissionsList || [],
      remove_global_read_permissions_list:
        data.removeGlobalReadPermissionsList || [],
      remove_global_read_write_permissions_list:
        data.removeGlobalReadWritePermissionsList || [],

      // v1
      // REVERT_POINT
      // remove_fleets_view_specific_list: data.removeFleetsViewSpecificList || [],
      // remove_fleets_rc_enabled_list:
      //   data.removeFleetsRemoteControlEnabledList || [],

      // v2
      remove_fleets_v2_view_specific_list:
        data.removeFleetsViewSpecificList || [],
      remove_fleets_v2_rc_enabled_list:
        data.removeFleetsRemoteControlEnabledList || [],
    };
  }
  return {
    name: data.name,
    user_list: data.userList || [],
    model_global_permissions_list: data.modelGlobalPermissionsList || [],
    global_read_permissions_list: data.globalPermissionsReadListFiltered || [],
    global_read_write_permissions_list:
      data.globalPermissionsReadWriteList || [],
    // v1
    // REVERT_POINT
    // fleets_view_specific_list: data.fleetsViewSpecificList || [],
    // fleets_rc_enabled_list: data.fleetsRemoteControlEnabledList || [],

    // v2
    fleets_v2_view_specific_list: data.fleetsViewSpecificList || [],
    fleets_v2_rc_enabled_list: data.fleetsRemoteControlEnabledList || [],
  };
};
