import { I18n } from 'react-redux-i18n';
import { formatAttributiveDataFormat } from './attributiveDataFormat';
import { AttributeType } from '../constants';

export const generateTableCSV = (items, attributes) =>
  attributes
    /* header row */
    .reduce(
      (result, attribute) => {
        if (!attribute.hidden && !attribute.displayOnUIOnly) {
          result[0].push(
            attribute.titleCode ? I18n.t(attribute.titleCode) : attribute?.title
          );
        }
        return result;
      },
      [[]]
    )
    /* rows */
    .concat(
      items.map((item) => {
        return attributes.reduce((row, attribute, index) => {
          if (!attribute.hidden && !attribute.displayOnUIOnly) {
            let content = '';
            // TODO: investigate how to get values without rendering cells, or add a custom method
            // if (attribute.renderer) {
            //     let cellElement = document.createElement('div');
            //     render(<attribute.renderer item={item}/>, cellElement, () => {
            //         content = cellElement.innerText;
            //         // document.removeChild(cellElement);
            //     });
            //
            // } else
            if (
              (item[attribute.key] !== null &&
                item[attribute.key] !== undefined) ||
              attribute.type === AttributeType.CUSTOM
            ) {
              content = formatAttributiveDataFormat({
                attribute,
                values: item,
                options: { dataOnly: true },
              });
            }
            row.push(`"${content}"`);
          }
          return row;
        }, []);
      })
    )
    .map((e) => e.join(','))
    .join('\n');
