import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { SwitchField } from '../../../components/switchField.component';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../../common/constants';
import { kioskPolicyTypes } from '../profile.constants';
import PolicyActivationToggle from './PolicyActivationToggle.component';

export const initialSettings = {
  backButton: false,
  homeButton: false,
  menuButton: false,
  hideStatusBar: false,
  singleAppMode: false,
  bsSetDimension: '',
  bsExactDimension: '',
  bsSetOrientation: '',
  kioskBackdoorPwd: '',
  hasKioskBackdoorPwd: true,
  showExitButton: false,
  autolaunch: false,
  autolaunchApp: undefined,
  apps: [],
  whitelisted: [],
  onlyKioskNotifications: false,
  hideNavigationBar: false,
  htmlKioskFile: null,
  useHTMLKioskMode: false,
  kioskBuilder: false,
};

export const getInitialPolicy = (policyType) => ({
  policyName: 'new',
  isActive: false,
  policyData: null,
  policyType,
});

export class Kiosk extends Component {
  get policyType() {
    return kioskPolicyTypes.KIOSK;
  }

  get basicSettings() {
    return [
      {
        key: 'hideStatusBar',
        title: I18n.t('profiles.kiosk.hideStatusBarSetting'),
      },
      {
        key: 'backButton',
        title: I18n.t('profiles.kiosk.backButtonSetting'),
      },
      {
        key: 'homeButton',
        title: I18n.t('profiles.kiosk.homeButtonSetting'),
      },
      {
        key: 'menuButton',
        title: I18n.t('profiles.kiosk.menuButtonSetting'),
      },
      {
        key: 'singleAppMode',
        title: I18n.t('profiles.kiosk.singleAppModeSetting'),
      },
      {
        key: 'showExitButton',
        title: I18n.t('profiles.kiosk.showExitButtonSetting'),
      },
      {
        key: 'onlyKioskNotifications',
        title: I18n.t('profiles.kiosk.onlyKioskNotificationsSetting'),
      },
      {
        key: 'hideNavigationBar',
        title: I18n.t('profiles.kiosk.hideNavigationBarSetting'),
      },
    ];
  }

  getKioskModeReadOnlyData = () => {
    const { policy: [{ settings = {}, isActive } = {}] = [] } = this.props;
    return [
      {
        title: <Translate value="profiles.kiosk.statusTitle" />,
        value: isActive,
        type: AttributeType.BOOLEAN,
        key: 'isActive',
      },
      {
        title: <Translate value="profiles.kiosk.useHTMLKioskMode" />,
        value: settings['useHTMLKioskMode'] || false,
        type: AttributeType.BOOLEAN,
        id: 'useHTMLKioskMode',
      },
      ...this.basicSettings.map(({ key, title }) => ({
        title,
        value: settings[key] || false,
        type: AttributeType.BOOLEAN,
        id: key,
      })),
    ];
  };

  onToggle = (index, key) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...getInitialPolicy(this.policyType) };
    let settings = item.settings ? item.settings : { ...initialSettings };
    settings[key] = !settings[key];
    item.settings = settings;
    this.props.onChange(this.policyType, index, item);
  };

  renderSettings = ({ settings = {}, isActive }, index) => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      this.props.onChangePolicyProperty({
        key: 'kiosk',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy: getInitialPolicy(this.policyType),
        initialSettings,
      });

    return (
      <div className={'kiosk'}>
        <div className={'section-description'}>
          <div className={'section-description__title--small'}>
            <Translate value="profiles.kiosk.kioskMode" />
          </div>
        </div>
        <div className={'kiosk__switch-container'}>
          <PolicyActivationToggle
            key="policyActivation"
            policy={this.props.policy}
            titleCode="profiles.kiosk.statusTitle"
            handleToggle={onEnableButtonToggle}
          />
        </div>

        {isActive ? (
          <>
            <SwitchField
              onClick={() => this.onToggle(index, 'useHTMLKioskMode')}
              on={settings['useHTMLKioskMode'] || false}
              title={<Translate value="profiles.kiosk.useHTMLKioskMode" />}
            />
            {this.basicSettings.map(({ key, title }) => (
              <SwitchField
                onClick={() => this.onToggle(index, key)}
                on={settings[key] || false}
                title={title}
                key={key}
              />
            ))}
            {settings?.useHTMLKioskMode ? (
              <Button
                onClick={this.props.showHtmlKioskBuilder}
                className="kiosk__builder-button"
                secondary
              >
                {I18n.t('profiles.kiosk.enterHtmlKioskBuilder')}
              </Button>
            ) : (
              <Button
                onClick={this.props.showBuilder}
                className="kiosk__builder-button"
                secondary
              >
                {I18n.t('profiles.kiosk.enterKioskBuilder')}
              </Button>
            )}
          </>
        ) : null}
      </div>
    );
  };

  renderContent = () => {
    return this.props.policy.length
      ? [this.props.policy[0]].map(this.renderSettings)
      : this.renderSettings(
          {
            ...getInitialPolicy(this.policyType),
            settings: { ...initialSettings },
          },
          0
        );
  };

  renderReadOnlyContent = () => {
    const items = this.getKioskModeReadOnlyData();
    return (
      <ReadOnlyForm
        items={items}
        headerTitle={<Translate value="profiles.kiosk.kioskMode" />}
        emptyListMessage={
          <Translate value="profiles.kiosk.kioskModeEmptyMessage" />
        }
      />
    );
  };

  render() {
    const { isEditingMode } = this.props;
    return (
      <div className={'kiosk-wrapper'}>
        {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
      </div>
    );
  }
}

export default Kiosk;

Kiosk.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
  showBuilder: PropTypes.func,
};
