import { deserializeCertificate } from './certificates.serializer';
import {
  certificatesLoadingFailed,
  certificatesLoadingStarted,
  certificatesLoadingSuccess,
  deleteCertificatesFailed,
  deleteCertificatesStarted,
  // deleteCertificatesSuccess,
} from './certificates.actions';
import client from '../../utils/client';
import services from '../../utils/services';
import { companyIdSelector } from '../login/login.selectors';
import { getState } from '../../store/store';
import { I18n } from 'react-redux-i18n';

export function loadCertificates(companyId) {
  return async (dispatch) => {
    try {
      dispatch(certificatesLoadingStarted());
      let response = await client.get(services.certificates);
      if (
        response.status === 200 &&
        response.data &&
        response.data.length >= 0
      ) {
        let result = response.data.map(deserializeCertificate);
        dispatch(certificatesLoadingSuccess(result));
      } else {
        dispatch(
          certificatesLoadingFailed(
            response.text ?? I18n.t('certificates.certificatesLoadError')
          )
        );
      }
    } catch (error) {
      dispatch(
        certificatesLoadingFailed(
          new Error(I18n.t('certificates.certificatesLoadError'))
        )
      );
    }
  };
}

export function deleteCertificates(certificates) {
  return async (dispatch) => {
    try {
      dispatch(deleteCertificatesStarted());
      let response = await client.post(services.deleteCertificates, {
        cert_list: certificates,
      });
      if (response.status === 200) {
        const companyId = companyIdSelector(getState());
        dispatch(loadCertificates(companyId));
        // let result = response.data.map(deserializeCertificate);
        // dispatch(deleteCertificatesSuccess(result));
      } else {
        dispatch(
          deleteCertificatesFailed(
            response.text ?? I18n.t('certificates.certificateDeletionError')
          )
        );
      }
    } catch (error) {
      dispatch(
        deleteCertificatesFailed(
          new Error(I18n.t('certificates.certificateDeletionError'))
        )
      );
    }
  };
}
