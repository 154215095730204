import { AttributeType } from '../../common/constants';
import { filterConditions } from '../../constants/filter';

export const htmlKioskFilesColumns = [
  {
    key: 'filename',
    type: AttributeType.TEXT,
    titleCode: 'htmlKioskFiles.filename',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  // {
  //   key: 'lastUpdated',
  //   type: AttributeType.DATE,
  //   titleCode: 'htmlKioskFiles.lastUpdated',
  //   searchable: true,
  //   availableFilterConditions: [
  //       filterConditions.EQUAL,
  //       filterConditions.GREATER_THAN,
  //       filterConditions.LESS_THAN
  //   ]
  // },
  {
    key: 'createdAt',
    type: AttributeType.DATE,
    titleCode: 'htmlKioskFiles.createdAt',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'uploadedByName',
    titleKey: 'uploadedByEmail',
    type: AttributeType.TEXT,
    titleCode: 'htmlKioskFiles.uploadedBy',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
];
