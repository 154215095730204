export const PROFILE_LOAD_STARTED = 'PROFILE_LOAD_STARTED';
export const PROFILE_LOAD_FAILED = 'PROFILE_LOAD_FAILED';
export const PROFILE_LOAD_SUCCESS = 'PROFILE_LOAD_SUCCESS';
export const PROFILE_CREATE_STARTED = 'PROFILE_CREATE_STARTED';
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS';
export const PROFILE_CREATE_ERROR = 'PROFILE_CREATE_ERROR';
export const PROFILE_CREATE_RESET = 'PROFILE_CREATE_RESET';
export const PROFILE_UPDATE_STARTED = 'PROFILE_UPDATE_STARTED';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
export const PROFILE_UPDATE_RESET = 'PROFILE_UPDATE_RESET';
export const PROFILE_LOAD_APPLICATIONS_STARTED =
  'PROFILE_LOAD_APPLICATIONS_STARTED';
export const PROFILE_LOAD_APPLICATIONS_FAILED =
  'PROFILE_LOAD_APPLICATIONS_FAILED';
export const PROFILE_LOAD_APPLICATIONS_SUCCESS =
  'PROFILE_LOAD_APPLICATIONS_SUCCESS';
export const PROFILE_RESET = 'PROFILE_RESET';

export function profileLoadingStarted() {
  return {
    type: PROFILE_LOAD_STARTED,
  };
}

export function profileReset() {
  return {
    type: PROFILE_RESET,
  };
}

export function profileLoadingFailed(payload) {
  return {
    type: PROFILE_LOAD_FAILED,
    payload,
  };
}

export function profileLoaded(payload) {
  return {
    type: PROFILE_LOAD_SUCCESS,
    payload,
  };
}

export function createProfileStarted() {
  return {
    type: PROFILE_CREATE_STARTED,
  };
}

export function createProfileSuccess(payload) {
  return {
    type: PROFILE_CREATE_SUCCESS,
    payload,
  };
}

export function createProfileError(payload) {
  return {
    type: PROFILE_CREATE_ERROR,
    payload,
  };
}

export function createProfileReset() {
  return {
    type: PROFILE_CREATE_RESET,
  };
}

export function updateProfileStarted() {
  return {
    type: PROFILE_UPDATE_STARTED,
  };
}

export function updateProfileSuccess(payload) {
  return {
    type: PROFILE_UPDATE_SUCCESS,
    payload,
  };
}

export function updateProfileError(payload) {
  return {
    type: PROFILE_UPDATE_ERROR,
    payload,
  };
}

export function updateProfileReset() {
  return {
    type: PROFILE_UPDATE_RESET,
  };
}

export function loadProfileApplicationsStarted() {
  return {
    type: PROFILE_LOAD_APPLICATIONS_STARTED,
  };
}

export function loadProfileApplicationsFailed(payload) {
  return {
    type: PROFILE_LOAD_APPLICATIONS_FAILED,
    payload,
  };
}

export function profileApplicationLoaded(payload) {
  return {
    type: PROFILE_LOAD_APPLICATIONS_SUCCESS,
    payload,
  };
}
