import {
  ALERT_CREATION_FAILED,
  ALERT_CREATION_RESET,
  ALERT_CREATION_STARTED,
  ALERT_CREATION_SUCCESS,
  ALERT_DELETION_FAILED,
  ALERT_DELETION_STARTED,
  ALERT_DELETION_SUCCESS,
  ALERTS_LOAD_FAILED,
  ALERTS_LOAD_STARTED,
  ALERTS_LOAD_SUCCESS,
  CHANGE_ALERT_PROPERTY,
  RESET_ALERT_CREATION,
  SET_NEW_ALERT_DEFAULT_VALUES,
} from './alerts.actionTypes';

// load alerts
export function loadAlertsStart(isInitialLoad = false) {
  return {
    type: ALERTS_LOAD_STARTED,
    payload: {
      isInitialLoad,
    },
  };
}

export function loadAlertsFailed(payload) {
  return {
    type: ALERTS_LOAD_FAILED,
    payload,
  };
}

export function loadAlertsSuccess(payload) {
  return {
    type: ALERTS_LOAD_SUCCESS,
    payload,
  };
}

export const resetAlertCreation = () => ({
  type: RESET_ALERT_CREATION,
});

export const changeAlertProperty = (payload) => ({
  type: CHANGE_ALERT_PROPERTY,
  payload,
});

export const setNewAlertDefaultValues = (payload) => ({
  type: SET_NEW_ALERT_DEFAULT_VALUES,
  payload,
});

// create alert
export const createAlertStarted = () => ({
  type: ALERT_CREATION_STARTED,
});

export const createAlertSuccess = (payload) => ({
  type: ALERT_CREATION_SUCCESS,
  payload,
});

export const createAlertFailed = (payload) => ({
  type: ALERT_CREATION_FAILED,
  payload,
});

export const createAlertReset = () => ({
  type: ALERT_CREATION_RESET,
});

// delete alerts
export const deleteAlertStarted = () => ({
  type: ALERT_DELETION_STARTED,
});

export const deleteAlertSuccess = (payload) => ({
  type: ALERT_DELETION_SUCCESS,
  payload,
});

export const deleteAlertFailed = (payload) => ({
  type: ALERT_DELETION_FAILED,
  payload,
});
