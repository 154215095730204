export const USER_CREATE_STARTED = 'USER_CREATE_STARTED';
export const USER_CREATE_FAILED = 'USER_CREATE_FAILED';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_RESET = 'USER_CREATE_RESET';

export const LOAD_PARTNER_OPTIONS_STARTED = 'LOAD_PARTNER_OPTIONS_STARTED';
export const LOAD_PARTNER_OPTIONS_FAILED = 'LOAD_PARTNER_OPTIONS_FAILED';
export const LOAD_PARTNER_OPTIONS_SUCCESS = 'LOAD_PARTNER_OPTIONS_SUCCESS';

export const USER_CREATE_LOAD_COMPANIES_STARTED =
  'USER_CREATE_LOAD_COMPANIES_STARTED';
export const USER_CREATE_LOAD_COMPANIES_FAILED =
  'USER_CREATE_LOAD_COMPANIES_FAILED';
export const USER_CREATE_LOAD_COMPANIES_SUCCESS =
  'USER_CREATE_LOAD_COMPANIES_SUCCESS';

export function createUsersStart() {
  return {
    type: USER_CREATE_STARTED,
  };
}

export function createUsersFailed(payload) {
  return {
    type: USER_CREATE_FAILED,
    payload,
  };
}

export function createUsersSuccess(payload) {
  return {
    type: USER_CREATE_SUCCESS,
    payload,
  };
}

export function createUsersReset(payload) {
  return {
    type: USER_CREATE_RESET,
    payload,
  };
}

export function loadPartnerNameOptionsStart() {
  return {
    type: LOAD_PARTNER_OPTIONS_STARTED,
  };
}

export function loadPartnerNameOptionsFailed(payload) {
  return {
    type: LOAD_PARTNER_OPTIONS_FAILED,
    payload,
  };
}

export function loadPartnerNameOptionsSuccess(payload) {
  return {
    type: LOAD_PARTNER_OPTIONS_SUCCESS,
    payload,
  };
}

export function loadCompaniesStart() {
  return {
    type: USER_CREATE_LOAD_COMPANIES_STARTED,
  };
}

export function loadCompaniesFailed(payload) {
  return {
    type: USER_CREATE_LOAD_COMPANIES_FAILED,
    payload,
  };
}

export function loadCompaniesSuccess(payload) {
  return {
    type: USER_CREATE_LOAD_COMPANIES_SUCCESS,
    payload,
  };
}
