import {
  LOGGED_OUT,
  LOGGING_IN_FAILED,
  LOGGING_IN_STARTED,
  LOGGED_IN,
  LOG_IN_RESET,
  LOG_OUT_STARTED,
} from './login.actionTypes';
import { NetworkStatus } from '../../common/constants';

export default function login(
  state = {
    data: undefined,
    isAuthenticated: false,
    loading: false,
    error: undefined,

    logoutStatus: NetworkStatus.NONE,
  },
  action
) {
  switch (action.type) {
    case LOGGING_IN_STARTED:
      return Object.assign({}, state, {
        data: undefined,
        error: undefined,
        loading: true,
        isAuthenticated: false,
        logoutStatus: NetworkStatus.NONE,
      });

    case LOGGED_IN:
      return Object.assign({}, state, {
        loading: false,
        isAuthenticated: true,
        error: undefined,
        data: action.payload,
      });

    case LOGGING_IN_FAILED:
      return Object.assign({}, state, {
        data: undefined,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      });
    case LOG_IN_RESET:
      return {
        ...state,
        data: undefined,
        isAuthenticated: false,
        loading: false,
        error: undefined,
      };
    case LOG_OUT_STARTED:
      return {
        ...state,
        logoutStatus: NetworkStatus.STARTED,
      };
    case LOGGED_OUT:
      return Object.assign({}, state, {
        error: action.payload,
        data: undefined,
        loading: false,
        isAuthenticated: false,
        logoutStatus: NetworkStatus.DONE,
      });
    default:
      return state;
  }
}
