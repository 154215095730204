import React, {
  useState,
  useLayoutEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  useTable,
  useBlockLayout,
  useRowSelect,
  useResizeColumns,
  useSortBy,
  SortingRule,
} from 'react-table';
import { I18n } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { Throbber } from '../Throbber';
import { isLoadingStatusStarted, notEmpty } from '../../common/helpers';
import { useNonInitialEffect } from '../../hooks/useNonInitialEffect';
import StyleUtils from '../../utils/styleUtils';
import { AttributeType } from '../../common/constants';
import { ReactComponent as CaretDown } from '../../assets/images/caret_down.svg';
import { ReactComponent as CaretUp } from '../../assets/images/caret_up.svg';
import { ReactComponent as CaretUpDown } from '../../assets/images/caret_up_down.svg';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { formatAttributiveDataFormat } from '../../common/utilities/attributiveDataFormat';
import { NetworkStatus } from '../../common/types';
import { IColumn } from './DataTable.types';
import useCustomFlexLayout from '../../hooks/useCustomFlexLayout';

// This allows you to define how many items outside of the visible "window" to render at all times.
const DEFAULT_OVER_SCAN_COUNT = 30;
const DEFAULT_TABLE_BODY_HEIGHT = 400;
const DEFAULT_TABLE_BODY_WIDTH = 1_000;
const DEFAULT_BODY_HEIGHT_OFFSET = 100;

interface InfiniteScrollProps {
  loadMore: () => void;
  hasMore: boolean;
  count?: number;
  // nextPageLoadingStatus?: NetworkStatusType,
  nextPageLoadingStatus?: NetworkStatus;
  threshold?: number;
  minimumBatchSize?: number;
}

interface DataTableInnerWrapperProps {
  columns: IColumn[];
  data: object[];
  loadingStatus?: NetworkStatus;
  bodyHeight?: number;
  bodyWidth?: number;
  bodyHeightOffset?: number;
  rowHeight?: number;
  infiniteScrollProps?: InfiniteScrollProps;
  onSelect?: (selectedOriginalIds) => void;
  selectable?: boolean;
  useResizableColumns?: boolean;
  sortable?: boolean;
  allColumnsResizable?: boolean;
  useFlex?: boolean;
  idPropertyName?: string;
  customIdName?: string;
  onOpenItem?: (item) => void;
  availableFilters: any[];
  setSortBy?: React.Dispatch<React.SetStateAction<SortingRule<any>[]>>;
  overScanCount?: number;
  noDataMessage?: React.ReactNode;
}

interface IndeterminateCheckboxProps {
  indeterminate?: boolean;
  name?: string;
}

const IndeterminateCheckbox = React.forwardRef(
  (
    { indeterminate, ...rest }: IndeterminateCheckboxProps,
    ref: React.RefObject<HTMLInputElement>
  ): JSX.Element => {
    const defaultRef = useRef(null);
    const resolvedRef = ref ?? defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate ?? false;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const getDefaultHeaderProps = (props, { column }) =>
  getStyles(props, column.align);

const getDefaultCellProps = (props, { cell }) =>
  getStyles(props, cell.column.align);

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
  },
];

const getSelectedOriginalIds = (selectedFlatRows = [], idPropertyName = 'id') =>
  selectedFlatRows.map(({ original = {} }) => original[idPropertyName]);

export type TableRef = React.RefObject<HTMLDivElement>;

const DataTableInnerWrapper = React.forwardRef(
  (
    {
      columns,
      data = [],
      loadingStatus,
      bodyHeight,
      bodyWidth,
      bodyHeightOffset,
      rowHeight = 50,
      infiniteScrollProps,
      onSelect,
      selectable,
      useResizableColumns = false,
      allColumnsResizable = false,
      sortable,
      onOpenItem,
      availableFilters,
      setSortBy,
      useFlex = false,
      overScanCount = DEFAULT_OVER_SCAN_COUNT,
      noDataMessage = I18n.t('dataTable.noData'),
      idPropertyName,
      customIdName,
    }: DataTableInnerWrapperProps,
    tableRef: TableRef
  ): JSX.Element => {
    const [{ tableBodyWidth, tableBodyHeight }, setTableDimensions] = useState(
      () => ({
        tableBodyWidth: bodyWidth ?? DEFAULT_TABLE_BODY_WIDTH,
        tableBodyHeight: bodyHeight ?? DEFAULT_TABLE_BODY_HEIGHT,
      })
    );

    if (!tableRef) {
      const message = 'Please provide a ref prop for the DataTable';
      console.error(message);
      // throw new Error(message);
    }

    // tableBodyHeightOffset accounts for the height on the table header and bottom margin;
    const tableBodyHeightOffset =
      bodyHeightOffset ?? DEFAULT_BODY_HEIGHT_OFFSET;

    const tableHeadRef = useRef(null);

    useLayoutEffect(() => {
      const tableBodyHeight = tableRef?.current
        ? tableRef?.current?.parentElement?.clientHeight - tableBodyHeightOffset
        : 0;
      const tableBodyWidth = tableHeadRef?.current?.clientWidth;
      (tableBodyHeight || tableBodyWidth) &&
        Boolean(data.length) &&
        setTableDimensions((prevState) => ({
          ...prevState,
          ...(tableBodyHeight ? { tableBodyHeight } : {}),
          ...(tableBodyWidth ? { tableBodyWidth } : {}),
        }));
    }, [
      tableHeadRef,
      tableRef,
      tableHeadRef?.current?.clientWidth,
      tableRef?.current?.parentElement?.clientHeight,
      tableBodyHeightOffset,
      tableBodyWidth,
      data.length,
    ]);

    const defaultColumn = useMemo(
      () => ({
        // useFlexLayout options:
        minWidth: 30, // minWidth is only used as a limit for resizing
        width: 150, // width is used for both the flex-basis and flex-grow
        // maxWidth: 200, // maxWidth is only used as a limit for resizing
      }),
      []
    );

    const reactTableColumns = useMemo(
      () =>
        columns.map((originalColumn: IColumn) => {
          const {
            titleCode,
            title,
            renderer: Renderer,
            key,
            customColumnOptions = {},
          } = originalColumn || {};
          return {
            Header: title ?? I18n.t(titleCode),
            id: key,
            originalColumn,
            accessor: (values) => {
              if (Renderer) {
                return <Renderer item={values} field={originalColumn} />;
              }
              return formatAttributiveDataFormat({
                attribute: originalColumn,
                values,
              });
            },
            ...customColumnOptions,
          };
        }),
      [columns]
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      selectedFlatRows,
      toggleAllRowsSelected,
    } = useTable(
      {
        columns: reactTableColumns,
        data,
        defaultColumn,
        autoResetSelectedRows: false,
        manualSortBy: true,
        disableMultiSort: true,
      },
      useResizeColumns,
      useFlex ? useCustomFlexLayout : useBlockLayout,
      useSortBy,
      useRowSelect,
      (hooks) => {
        selectable &&
          hooks.allColumns.push((allColumns) => [
            // column for selection
            {
              id: 'selection',
              disableResizing: true,
              minWidth: 35,
              width: 35,
              maxWidth: 35,
              // The header uses the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell uses the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...allColumns,
          ]);
        hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
          // fix the parent group of the selection button to not be resizable
          const selectionGroupHeader = headerGroups[0].headers[0];
          selectionGroupHeader.canResize = false;
        });
      }
    );

    // @ts-ignore
    React.useImperativeHandle(tableRef, () => ({
      toggleAllRowsSelected: (value) => toggleAllRowsSelected(value),
    }));

    useDeepCompareEffect(
      () => setSortBy(state?.sortBy),
      // useDeepCompareEffect will do a deep comparison and your callback is only
      // run when the variables object actually has changes.
      [state?.sortBy]
    );

    useNonInitialEffect(() => {
      onSelect?.(getSelectedOriginalIds(selectedFlatRows, customIdName));
    }, [selectedFlatRows.length]);

    const { nextPageLoadingStatus } = infiniteScrollProps || {};
    const RenderRow = useCallback(
      ({ index, style }) => {
        if (
          index >= rows.length &&
          nextPageLoadingStatus &&
          isLoadingStatusStarted(nextPageLoadingStatus)
        ) {
          return (
            <div style={style} className="data-table__row">
              <Throbber />
            </div>
          );
        }
        const row = rows[index];
        if (!row) {
          return null;
        }
        prepareRow(row);
        return (
          <div
            {...row.getRowProps({
              style,
            })}
            className="data-table__row"
          >
            {row.cells.map((cell) => {
              const {
                column: {
                  originalColumn: {
                    ref,
                    useQueryParam,
                    refId,
                    additionalRefParams = {},
                    dynamicRefParams,
                    hidden: isColumnHidden,
                    key: columnKey,
                    titleKey,
                  } = {},
                } = {},
              } = cell;
              const { original: item = {} } = row;

              if (isColumnHidden || item.hiddenCells?.includes(columnKey)) {
                return (
                  <div
                    {...cell.getCellProps(getDefaultCellProps)}
                    className="data-table__data"
                  />
                );
              }

              const hasValidRef = onOpenItem && ref;
              const onClick = hasValidRef ? () => onOpenItem(item) : undefined;
              let content;
              if (ref) {
                const dynamicRefParameters =
                  dynamicRefParams?.reduce(
                    (accumulatedParams, currentParam) => ({
                      ...accumulatedParams,
                      [currentParam.key]: item[currentParam.paramValueKey],
                    }),
                    {}
                  ) ?? {};
                const defaultPathWithoutParams = `${ref}${
                  item[refId] ? '/' + item[refId] : ''
                }`;
                const defaultPath =
                  notEmpty(additionalRefParams) ||
                  notEmpty(dynamicRefParameters)
                    ? RouteHelpers.formatUrlWithQuery(
                        defaultPathWithoutParams,
                        {
                          ...additionalRefParams,
                          ...dynamicRefParameters,
                        }
                      )
                    : defaultPathWithoutParams;
                const path = useQueryParam
                  ? RouteHelpers.formatUrlWithQuery(ref, {
                      ...additionalRefParams,
                      ...dynamicRefParameters,
                      [refId]: item[refId],
                    })
                  : defaultPath;
                content = (
                  <NavLink
                    title={cell.value}
                    className="data-table__truncatable-data"
                    to={path}
                  >
                    {cell.render('Cell')}
                  </NavLink>
                );
              } else {
                content = (
                  <div className="data-table__truncatable-data">
                    {cell.render('Cell')}
                  </div>
                );
              }

              const additionalAttributes = {
                ...(titleKey ? { title: item[titleKey] } : {}),
              };

              return (
                <div
                  {...cell.getCellProps(getDefaultCellProps)}
                  {...additionalAttributes}
                  onClick={onClick}
                  className="data-table__data"
                >
                  {content}
                </div>
              );
            })}
          </div>
        );
      },
      // state added to the dependency array here is away of getting around selection not
      // working properly when using virtualization (windowing)
      // see https://github.com/tannerlinsley/react-table/issues/2090
      [prepareRow, rows, nextPageLoadingStatus, state]
    );

    if (isLoadingStatusStarted(loadingStatus)) {
      return <Throbber />;
    }

    const tableHead = (
      <>
        {headerGroups.map((headerGroup) => (
          <div
            {...headerGroup.getHeaderGroupProps({
              // style: { paddingRight: '15px' },
            })}
            ref={tableHeadRef}
            className="data-table__row"
          >
            {headerGroup.headers.map((column) => {
              const {
                originalColumn: {
                  searchable,
                  sortable: sortableColumnProp,
                  resizable,
                  type,
                } = {},
              } = column;
              const isColumnResizable = resizable ?? allColumnsResizable;
              const isColumnSortable =
                type !== AttributeType.COORDINATES &&
                sortable &&
                (sortableColumnProp || searchable);
              const sortByToggleProps = column.getHeaderProps(
                column.getSortByToggleProps()
              );
              const defaultHeaderProps = column.getHeaderProps(
                getDefaultHeaderProps
              );

              const sortByProps = isColumnSortable ? sortByToggleProps : {};

              let caret = null;
              if (isColumnSortable) {
                if (column.isSorted) {
                  caret = column.isSortedDesc ? <CaretDown /> : <CaretUp />;
                } else {
                  caret = <CaretUpDown />;
                }
              }

              return (
                <div {...defaultHeaderProps} className="data-table__head">
                  <div {...sortByProps}>
                    {column.render('Header')}
                    {/* sort direction indicator */}
                    {caret}
                  </div>

                  {/* column.getResizerProps used to hook up the events correctly */}
                  {column.canResize && isColumnResizable && (
                    <div
                      {...column.getResizerProps()}
                      className={
                        useResizableColumns
                          ? StyleUtils.mergeModifiers(
                              'data-table__resizer',
                              column.isResizing ? 'is-resizing' : ''
                            )
                          : ''
                      }
                    />
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </>
    );
    let tableBody;
    if (notEmpty(infiniteScrollProps)) {
      const {
        loadMore,
        hasMore,
        nextPageLoadingStatus,
        // count,
        threshold = 5,
        minimumBatchSize = 15,
      } = infiniteScrollProps;

      // If there are more items to be loaded then add an extra row to hold a loading indicator.
      const itemCount = hasMore ? data.length + 1 : data.length;

      // Only load 1 page of items at a time.
      // an empty callback is passed to InfiniteLoader in case it asks us to load more than once.
      const loadMoreItems = isLoadingStatusStarted(nextPageLoadingStatus)
        ? () => {}
        : loadMore;

      // Every row is loaded except for the loading indicator row.
      const isItemLoaded = (index) => !hasMore || index < data.length;

      tableBody = (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
          threshold={threshold}
          minimumBatchSize={minimumBatchSize}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              height={tableBodyHeight}
              itemCount={itemCount}
              itemSize={rowHeight}
              onItemsRendered={onItemsRendered}
              ref={ref}
              overScanCount={overScanCount}
              width={tableBodyWidth}
            >
              {RenderRow}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      );
    } else {
      tableBody = (
        <FixedSizeList
          height={tableBodyHeight}
          itemCount={rows.length}
          itemSize={rowHeight}
          overScanCount={overScanCount}
          width={tableBodyWidth}
        >
          {RenderRow}
        </FixedSizeList>
      );
    }

    const hasNoData = !data.length;
    const hasNoFilterResults = availableFilters.length > 0 && hasNoData;
    const noFilterResultsMessage = (
      <div className="data-table__message-container">
        {hasNoFilterResults
          ? I18n.t('dataTable.noMatchingFilterResults')
          : noDataMessage}
      </div>
    );

    // Render UI
    return (
      <div {...getTableProps()} className="data-table" ref={tableRef}>
        {hasNoData ? noFilterResultsMessage : tableHead}
        <div {...getTableBodyProps()}>{tableBody}</div>
      </div>
    );
  }
);

export default DataTableInnerWrapper;
