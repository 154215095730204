import { NetworkStatus } from '../common/constants';
import {
  STATISTICS_LOAD_FAILED,
  STATISTICS_LOAD_STARTED,
  STATISTICS_LOAD_SUCCESS,
} from './statistics.actionTypes';

export default function statistics(
  state = {
    data: null,
    status: NetworkStatus.NONE,
    error: null,
  },
  action
) {
  switch (action.type) {
    case STATISTICS_LOAD_STARTED:
      return Object.assign({}, state, {
        data: null,
        status: NetworkStatus.STARTED,
        error: null,
      });
    case STATISTICS_LOAD_FAILED:
      return Object.assign({}, state, {
        data: null,
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case STATISTICS_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: null,
      });

    default:
      return state;
  }
}
