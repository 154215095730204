import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import StyleUtils from '../utils/styleUtils';
import { NetworkStatus } from '../common/constants';
import { Throbber } from './Throbber';
import { I18n } from 'react-redux-i18n';

export class Dialog extends Component {
  render() {
    const { isFullViewportVisible, children, content, status } = this.props;
    let dialogContent;

    if (status === NetworkStatus.STARTED) {
      dialogContent = <Throbber />;
    } else {
      if (children && !content) {
        dialogContent = children;
      } else {
        dialogContent = (
          <div className="dialog__content">
            {this.renderHeader()}
            {this.renderBody()}
          </div>
        );
      }
    }

    return (
      <div
        className={StyleUtils.mergeModifiers(
          'dialog-base',
          isFullViewportVisible && 'with-full-viewport'
        )}
      >
        <div className={'dialog-fade'} />
        <div
          className={StyleUtils.mergeModifiers(
            'dialog',
            isFullViewportVisible && 'with-full-viewport'
          )}
        >
          {dialogContent}
        </div>
      </div>
    );
  }

  renderHeader = () => {
    return (
      <div
        className={StyleUtils.mergeModifiers(
          'dialog__header-container',
          this.props.showMiniHeader && 'mini-header-container'
        )}
      >
        <div className="dialog__back-control-area">
          {this.props.backRoute ? (
            <NavLink
              to={this.props.backRoute}
              onClick={this.props.onBack}
              className="dialog__back-control"
            >
              <div>⟵</div>
              <div>{I18n.t('common.back')}</div>
            </NavLink>
          ) : (
            <div onClick={this.props.onBack} className="dialog__back-control">
              <div>⟵</div>
              <div>{I18n.t('common.back')}</div>
            </div>
          )}
        </div>
        <div className="dialog__header">
          {this.props.header ? this.props.header() : null}
        </div>
      </div>
    );
  };

  renderBody = () => {
    return (
      <div
        style={{ display: 'inline-flex', flexWrap: 'nowrap', height: '100%' }}
      >
        {this.renderMenu()}
        {this.renderContentSection()}
      </div>
    );
  };

  renderMenu = () => {
    if (!this.props.menu) {
      return null;
    }
    return <div className="dialog__menu">{this.props.menu()}</div>;
  };

  renderContentSection = () => {
    return (
      <div
        className={StyleUtils.mergeClasses(
          'dialog__content-section',
          this.props.contentDisabled && 'read-only-wrapper'
        )}
      >
        {this.props.content ? this.props.content() : null}
        {this.props.children ? this.props.children : null}
      </div>
    );
  };
}

export default Dialog;

Dialog.propTypes = {
  style: PropTypes.object,
  onBack: PropTypes.func,
  backRoute: PropTypes.string,
  contentDisabled: PropTypes.bool,
};
