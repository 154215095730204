import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Translate, I18n } from 'react-redux-i18n';
import { ReactComponent as Plus } from '../assets/images/plus.svg';
import { ReactComponent as Bell } from '../assets/images/bell.svg';
import { ApplicationsRoutes, DevicesRoutes, RootRoutes } from '../utils/routes';
import NotificationListItem from './Notifications/NotificationListItem';
import { ReactComponent as QuestionmarkCircle } from '../assets/images/questionmark-circle.svg';
import Popup from 'reactjs-popup';
import ProfileBar from './profileBar.component';
import {
  loadNotifications,
  updateNotifications,
} from '../redux/notifications.thunk';
import { notEmpty } from '../common/helpers';
import { notificationUpdateTypes } from '../constants/notifications';
import RouteHelpers from '../common/utilities/routeHelpers';
import { INFINITE_SCROLL_LIMIT } from '../constants/infiniteScroll';
import { NetworkStatus } from '../common/constants';
import { Throbber } from './Throbber';
import UserHelpers from '../common/utilities/user';
import { newProfileCreationPath } from '../features/profilesV2/profilesV2.constants';
import LocaleSwitch from './localeSwitch/localeSwitch.component';
import { AddFileRoute } from '../features/upload/upload.constants';

const styles = {
  button: {
    border: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'white',
    boxShadow: 'none',
    height: '14px',
    width: '14px',
    cursor: 'pointer',
  },
  notificationsIcon: {
    border: '2px solid transparent',
    borderColor: 'transparent',
    width: '18px',
    height: '18px',
    boxShadow: 'none',
  },
  notificationsIconActive: {
    width: '18px',
    height: '18px',
    border: '1px solid #ffff00a3',
    borderColor: '#ffff00a3',
    boxShadow: '0 0 4px #ffff00a3',
  },
  notificationsList: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: '50vh',
    overflow: 'hidden',
    overflowY: 'auto',
    flexDirection: 'column',
    fontSize: '12px',
    letterSpacing: 'normal',
  },
  toolbarIcons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginRight: '12px',
  },
  notificationsStab: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '48px',
    display: 'flex',
  },
};

const UNREAD_NOTIFICATIONS_DISPLAY_LIMIT = 10;

const addMenuItems = [
  {
    text: I18n.t('profileBar.addAProfile'),
    route: newProfileCreationPath,
    id: 'add-profile-menu-item',
  },
  {
    text: I18n.t('profileBar.addAFleet'),
    route: `${RootRoutes.DEVICES}${DevicesRoutes.FLEETS_V2}`,
    id: 'add-fleet-menu-item',
  },
  {
    text: I18n.t('profileBar.uploadAnApp'),
    route: `${AddFileRoute}/`,
    id: 'app-upload-menu-item',
  },
  {
    text: I18n.t('profileBar.createEnrollmentMethod'),
    route: '/devices/enrollments/create',
    id: 'add-enrollment-menu-item',
  },
];

export class HeaderDropdown extends React.Component {
  state = {
    show: undefined,
  };

  toggleOpen = (show) => this.setState({ show });

  render() {
    const { hasAdminPrivileges } = this.props;
    return (
      <div style={styles.toolbarIcons}>
        {this.renderNotifications()}
        {this.renderHelp()}
        {hasAdminPrivileges ? this.renderAddMenu() : null}
        <LocaleSwitch />
        <ProfileBar />
      </div>
    );
  }

  renderAddMenu = () => (
    <Popup
      closeOnDocumentClick
      position="bottom right"
      contentStyle={{
        minWidth: '220px',
        width: '220px',
      }}
      repositionOnResize
      trigger={
        <div
          style={{ padding: '0 15px', display: 'flex', alignItems: 'center' }}
          onClick={() => this.toggleOpen('menu')}
        >
          <Plus style={styles.button} />
        </div>
      }
    >
      {(close, idOpen) => (
        <div
          style={{
            outline: 'none !important',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
          }}
          onClick={() => close()}
        >
          {addMenuItems.map(({ text, route, id }) => (
            <NavLink
              onClick={() => close()}
              style={{
                padding: '15px',
                textAlign: 'center',
                borderBottom: '1px solid #f2f2f2',
              }}
              to={route}
              key={id}
            >
              {text}
            </NavLink>
          ))}
        </div>
      )}
    </Popup>
  );

  renderHelp = () => (
    <NavLink
      to={`${RootRoutes.HELP}`}
      style={{
        width: '16px',
        height: '16px',
        display: 'flex',
        cursor: 'pointer',
        overflow: 'hidden',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '12px',
      }}
      title={I18n.t('profileBar.help')}
    >
      <QuestionmarkCircle
        alt={''}
        title={I18n.t('profileBar.help')}
        className={'sidebar-icon__img'}
        style={{
          maxWidth: '16px',
          maxHeight: '16px',
          width: '100%',
          heigth: '100%',
        }}
      />
    </NavLink>
  );

  renderNotifications = () => {
    const {
      hasAdminPrivileges,
      persistedNotifications = [],
      hasMoreNotifications,
      loadNotificationsStatus,
    } = this.props;
    const unreadNotifications = persistedNotifications.filter(
      ({ isRead }) => !isRead
    );
    const hasUnreadNotifications = Boolean(unreadNotifications.length);
    const badgeValue =
      unreadNotifications.length < UNREAD_NOTIFICATIONS_DISPLAY_LIMIT
        ? unreadNotifications.length
        : '9+';
    const notificationsIconStyle = {
      ...styles.notificationsIcon,
      ...(hasUnreadNotifications ? styles.notificationsIconActive : {}),
    };
    return (
      <Popup
        closeOnDocumentClick
        position="bottom right"
        contentStyle={{
          minWidth: '220px',
          width: 'auto',
        }}
        closeOnEscape
        repositionOnResize
        trigger={
          <div
            style={{
              color: 'white',
              textDecoration: 'none',
              position: 'relative',
              display: 'flex',
              borderRadius: '2px',
              width: '40px',
              height: '40px',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 20px',
            }}
            onClick={() => this.toggleOpen()}
          >
            {hasUnreadNotifications ? (
              <div
                style={{
                  position: 'absolute',
                  top: '-3px',
                  right: ' -3px',
                  padding: '3px 6px',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  color: 'white',
                  cursor: 'pointer',
                }}
              >
                {badgeValue}
              </div>
            ) : null}
            <Bell style={{ ...styles.button, ...notificationsIconStyle }} />
          </div>
        }
      >
        <div
          style={{
            outline: 'none !important',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
          }}
        >
          {persistedNotifications.length ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                height: '40px',
                minHeight: '40px',
              }}
            >
              <div
                style={{
                  textDecoration: 'underline',
                  padding: '20px',
                  color: this.props.persistedNotifications.some(
                    (notification) => !notification.isRead
                  )
                    ? 'rgb(0, 35, 64)'
                    : 'lightgray',
                  whiteSpace: 'nowrap',
                  userSelect: 'none',
                  cursor: hasUnreadNotifications ? 'pointer' : 'initial',
                }}
                onClick={this.markAllNotificationsAsRead}
              >
                <Translate value="notifications.markAllRead" />
              </div>
              {hasAdminPrivileges ? (
                <div
                  style={{
                    textDecoration: 'underline',
                    color: 'rgb(0, 35, 64)',
                    cursor: 'pointer',
                    padding: '20px',
                    flexWrap: 'nowrap',
                    whiteSpace: 'nowrap',
                    userSelect: 'none',
                  }}
                  onClick={this.removeAllNotifications}
                >
                  <Translate value="notifications.removeAll" />
                  {''}
                </div>
              ) : null}
            </div>
          ) : null}
          <div style={styles.notificationsList}>
            <InfiniteScroll
              loadMore={this.loadMoreNotifications}
              // loading status check here because of request duplication.
              // the same request was always sent twice
              hasMore={
                hasMoreNotifications &&
                loadNotificationsStatus !== NetworkStatus.STARTED
              }
              pageStart={0}
              loader={
                <div
                  key="notifications-loader"
                  style={{ height: '15px', width: '100%' }}
                >
                  <Throbber />
                </div>
              }
              useWindow={false}
              initialLoad={false}
            >
              {persistedNotifications.length ? (
                persistedNotifications.map(this.renderNotificationListItem)
              ) : (
                <div style={styles.notificationsStab}>
                  <Translate value="notifications.noNewNotifications" />
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </Popup>
    );
  };

  loadMoreNotifications = () => {
    const {
      hasMoreNotifications,
      loadNotifications,
      notificationsOffset,
    } = this.props;
    if (hasMoreNotifications) {
      loadNotifications({
        offset: notificationsOffset,
        limit: INFINITE_SCROLL_LIMIT,
      });
    }
  };

  renderNotificationListItem = (notification, index) => {
    const { hasAdminPrivileges } = this.props;
    return (
      <NotificationListItem
        key={index}
        notification={notification}
        hasAdminPrivileges={hasAdminPrivileges}
      />
    );
  };

  removeAllNotifications = () => {
    const { updateNotifications, persistedNotifications } = this.props;
    if (notEmpty(persistedNotifications)) {
      updateNotifications(
        persistedNotifications,
        notificationUpdateTypes.REMOVE
      );
    }
  };

  markAllNotificationsAsRead = () => {
    const { persistedNotifications, updateNotifications } = this.props;
    if (notEmpty(persistedNotifications)) {
      updateNotifications(persistedNotifications, notificationUpdateTypes.READ);
    }
  };
}

const mapStateToProps = (state) => {
  const {
    notifications: {
      persistedNotifications,
      nextUrl,
      loadNotificationsStatus,
    } = {},
  } = state;
  const { offset } = RouteHelpers.getURLQueryParams(nextUrl);

  return {
    currentLocale: state.i18n.locale,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    hasMoreNotifications: Boolean(nextUrl),
    persistedNotifications,
    loadNotificationsStatus,
    notificationsOffset: offset,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadNotifications: (params) => dispatch(loadNotifications(params)),
    updateNotifications: (notifications, updateType) =>
      dispatch(updateNotifications(notifications, updateType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDropdown);
