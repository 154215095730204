import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Button } from '../button.component';
import { ReactComponent as SelectAndOrderIcon } from '../../assets/images/SelectAndOrder.svg';

import CheckBox from '../CheckBox';
import {
  initializeAllAvailableColumns,
  reorderColumns,
  toggleColumn,
} from '../../redux/columnsToggle.actions';
import { saveTableColumns } from '../../redux/columnsToggle.thunk';

import './ColumnsToggle.styles.scss';
import { reorder } from '../../common/helpers';
import Tool from '../ToolBar/Tool';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,

  background: isDragging ? '#fff' : '#F2F2F2',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgrey' : 'white',
  padding: grid,
  width: '100%',
});

class ColumnsToggle extends Component {
  componentDidMount() {
    const { page, allColumns } = this.props;
    this.props.initializeAllAvailableColumns({ page, allColumns });
  }

  renderColumnsList = () => {
    const {
      allAvailableColumns,
      visibleColumns,
      toggleColumn,
      page,
    } = this.props;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                ...getListStyle(snapshot.isDraggingOver),
              }}
            >
              {allAvailableColumns.map(({ key, titleCode }, index) => (
                <Draggable key={key} draggableId={key} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        ),
                        position: 'static',
                      }}
                    >
                      <CheckBox
                        id={key}
                        key={`column-toggle-item-${key}`}
                        label={<Translate value={titleCode} />}
                        checked={visibleColumns.includes(key)}
                        onChange={() =>
                          toggleColumn({
                            key,
                            page,
                          })
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  onDragEnd = (result) => {
    const { allAvailableColumns, visibleColumns, page } = this.props;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedAllColumns = reorder(
      allAvailableColumns,
      result.source.index,
      result.destination.index
    );

    const reorderedVisibleColumns = reorderedAllColumns.reduce(
      (acc, { key }) => {
        if (visibleColumns.includes(key)) {
          return [...acc, key];
        }
        return acc;
      },
      []
    );
    this.props.reorderColumns({
      page,
      reorderedAllColumns,
      reorderedVisibleColumns,
    });
  };

  renderActions = () => {
    const { visibleColumns, page } = this.props;
    const saveColumns = () => this.props.saveTableColumns(page, visibleColumns);
    return (
      <div className="columns-toggle__actions">
        <Button onClick={saveColumns}>
          <Translate value="common.save" />
        </Button>
      </div>
    );
  };

  render() {
    return (
      <Popup
        position="bottom right"
        contentStyle={{
          minWidth: '200px',
          maxWidth: '400px',
        }}
        repositionOnResize
        closeOnEscape
        closeOnDocumentClick
        trigger={
          <div>
            <Tool className="icon--fixed-square-2x">
              <SelectAndOrderIcon className="icon" />
            </Tool>
          </div>
        }
      >
        <div
          style={{
            maxHeight: '70vh',
            overflow: 'scroll',
          }}
        >
          {this.renderColumnsList()}
          {this.renderActions()}
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state, { page }) => ({
  visibleColumns: state.columnsToggle.visibleColumnsLists[page],
  allAvailableColumns: state.columnsToggle.allAvailableColumns[page] || [],
});

const mapDispatchToProps = (dispatch) => ({
  initializeAllAvailableColumns: (params) =>
    dispatch(initializeAllAvailableColumns(params)),
  toggleColumn: (params) => dispatch(toggleColumn(params)),
  saveTableColumns: (page, columns) =>
    dispatch(saveTableColumns(page, columns)),
  reorderColumns: (params) => dispatch(reorderColumns(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnsToggle);
