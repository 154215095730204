import { CLOSE_BANNER, SHOW_BANNER } from './banner.actionTypes';

export default function banner(
  state = {
    isVisible: false,
    id: '',
    text: '',
  },
  { type, payload }
) {
  switch (type) {
    case SHOW_BANNER: {
      return {
        ...state,
        ...payload,
        isVisible: true,
      };
    }

    case CLOSE_BANNER: {
      return {
        text: '',
        isVisible: false,
      };
    }
    default:
      return state;
  }
}
