import React from 'react';
import PropTypes from 'prop-types';
import { selectForCurrentBrand } from '../common/helpers';
import { I18n } from 'react-redux-i18n';

const styles = {
  sidebar: {
    position: 'fixed',
    zIndex: 999999, // TODO: get max index and increment
    top: 'auto', // "calc(100vh - 75px)",
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '75px',
    padding: 0,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 1px',
  },
  content: {
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    backgroundColor: '#f2f2f2',
    color: '#003562', // --incube 1a1e35
  },
  infoSection: {
    width: '30%',
    display: 'inline-flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingLeft: '5%',
    borderRight: '1px solid #c7c7c7',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: selectForCurrentBrand({
      original: '#003562',
      incube: '#1a1e35',
      janam: '#ff7600',
    }),
  },
  infoCount: {
    fontWeight: 'bold',
    padding: '1rem',
  },
  infoTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '1rem',
  },
  actionsSection: {
    width: '70%',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '16px',
    paddingLeft: '1rem',
    overflow: 'auto hidden',
  },
  actionIcon: {
    padding: '2rem',
    cursor: 'pointer',
  },
};

export const ToolsPanel = (props) => {
  return (
    <div style={styles.sidebar}>
      <div style={styles.content}>
        <div style={styles.infoSection}>
          <h4 style={styles.infoCount}>
            {props.selectedItems ? props.selectedItems.size : ''}
          </h4>
          <h6 style={styles.infoTitle}>
            {props.title && props.title.length
              ? props.title
              : I18n.t('common.toolsPanelSelectedTitle')}
          </h6>
        </div>
        <div style={styles.actionsSection}>{props.children}</div>
      </div>
    </div>
  );
};

ToolsPanel.propTypes = {
  style: PropTypes.object,
  selectedItems: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ToolsPanel;
