import 'ol/ol.css';
import { Map, Feature } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Stroke, Fill, Icon, Text } from 'ol/style';
import { Point } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import React from 'react';
import { fromLonLat } from 'ol/proj';
import pin from '../../../assets/images/pin.svg';
import { defaults as defaultControls, FullScreen } from 'ol/control';
import { I18n } from 'react-redux-i18n';
import CanvasTitle from 'ol-ext/control/CanvasTitle';
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine';
import PrintDialog from 'ol-ext/control/PrintDialog';
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';

class Location extends React.Component {
  trackingTimeout;
  mapRef = React.createRef();
  map;
  source = new VectorSource();
  defaultStyle = new Style({
    stroke: new Stroke({
      fill: new Fill({
        color: '#003562', // --incube primary color
      }),
      color: '#dfe0e4',
      width: 2,
    }),
    fill: new Fill({
      color: '#003562', // --incube primary color
    }),
    image: new Icon({
      anchor: [0.5, 24],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: pin,
      fill: '#003562', // --incube primary color
    }),
  });
  featureOverlay = new VectorLayer({
    source: this.source,
    style: this.defaultStyle,
  });

  componentDidMount() {
    this.map = new Map({
      target: '||||||||||||||||||map',
      controls: defaultControls().extend([
        new FullScreen({
          tipLabel: I18n.t('device.mapFullScreenControlLabelTip'),
        }),
      ]),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
    });

    this.map.addControl(
      new PrintDialog({
        jsPDF,
        saveAs,
        size: 'A4',
        title: I18n.t('device.export'),
      })
    );

    // Add a title control
    this.map.addControl(
      new CanvasTitle({
        title: I18n.t('device.locationHistory'),
        visible: false,
        style: new Style({
          text: new Text({
            font:
              '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
          }),
        }),
      })
    );

    // Add a ScaleLine control
    this.map.addControl(new CanvasScaleLine());

    if (this.props.coordinates) {
      const coordinates = fromLonLat([
        this.props.coordinates.longitude,
        this.props.coordinates.latitude,
      ]);
      const geometry = new Point(coordinates);
      const feature = new Feature(geometry);
      this.source.addFeature(feature);
      this.featureOverlay.setMap(this.map);
      const newView = this.map.getView();
      newView.setCenter(coordinates);
      newView.setZoom(14);
    }
    this.sendPing();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.coordinates !== prevProps.coordinates) {
      if (this.props.coordinates) {
        const geometry = new Point(
          fromLonLat([
            this.props.coordinates.longitude,
            this.props.coordinates.latitude,
          ])
        );
        const feature = new Feature(geometry);
        this.source.addFeature(feature);
        this.featureOverlay.setMap(this.map);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.trackingTimeout);
  }

  sendPing = () => {
    this.props.pingDeviceLocation();
    this.trackingTimeout = setTimeout(this.sendPing, 10000);
  };

  render() {
    return (
      <div
        style={{ width: '100%', minWidth: '150px', minHeight: '150px' }}
        id="||||||||||||||||||map"
        ref={this.mapRef}
      />
    );
  }
}

export default Location;
