export const Pages = {
  FLEET_DEVICES: 'myFleetDevicesPage',
  ALL_DEVICES: 'allDevicesPages',
  ALERTS: 'alertsPage',
  PROFILES: 'profiles',
  ENROLLMENTS: 'enrollments',
  USERS: 'users',
  ACTIVITY_LOGS: 'activityLogs',
  APPLICATION_PROFILES_SELECTION: 'applicationProfilesSelection',
  APPS_INSTALLED_ON_DEVICE: 'appsInstalledOnDevice',
  DEVICE_ACTIVITY_LOGS: 'deviceActivityLogs',
  MY_FILES: 'myFiles',
  CERTIFICATES: 'certificates',
  INSTALLATION_STATUS: 'installationStatus',
  FILE_SYSTEM_APPS_PAGE: 'applications',
};

// export enum Pages {
//   FLEET_DEVICES = 'myFleetDevicesPage',
//   ALL_DEVICES = 'allDevicesPages',
//   ALERTS = 'alertsPage',
//   PROFILES = 'profiles',
// }

// export type PagesType = Pages.PROFILES | Pages.ALERTS | Pages.ALL_DEVICES | Pages.FLEET_DEVICES;
// export type PagesType = Record<Pages, string>;
