import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import Dialog from '../../components/Dialog';
import { history } from '../../utils/history';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import { Button } from '../../components/button.component';
import { SectionDescription } from '../../components/sectionDescription.component';
import { SwitchField } from '../../components/switchField.component';
import { InputField } from '../../components/inputField.component';
import { connect } from 'react-redux';
import { NetworkStatus } from '../../common/constants';
import { loadUser, updateUser } from './user.thunk';
import { Throbber } from '../../components/Throbber';
import { loadUserReset, updateUserReset } from './user.actions';
import { roleSelector } from '../login/login.selectors';
import Helpers, {
  isLoadingStatusStarted,
  notEmpty,
} from '../../common/helpers';
import { USER_SETTINGS } from './user.constants';
import UserHelpers from '../../common/utilities/user';
import { showStatusChangePopup } from '../../common/utilities/common';

class ChangeUserDialog extends Component {
  state = {
    settings: { ...USER_SETTINGS },
    deleteUser: false,
    changed: false,
    sourceData: null,
    data: {},
  };

  componentDidMount() {
    this.props.loadUser(this.props.match.params.username);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.status === NetworkStatus.DONE &&
      prevProps.status === NetworkStatus.STARTED
    ) {
      this.setState({ data: this.props.data, sourceData: this.props.data });
      this.props.loadUserReset();
    }
    if (
      prevProps.updateStatus === NetworkStatus.STARTED &&
      this.props.updateStatus === NetworkStatus.ERROR
    ) {
      showStatusChangePopup({
        onClose: this.props.updateUserReset,
        status: this.props.updateStatus,
        title: I18n.t('users.userUpdateErrorTitle'),
        errorText: this.props.updateError,
      });
    }
  }

  componentWillUnmount() {
    this.props.loadUserReset();
    this.props.updateUserReset();
  }

  getSettingsObject = (settings = {}) =>
    Object.keys(settings).reduce(
      (acc, curr) => ({ ...acc, [curr]: settings[curr]['on'] }),
      {}
    );

  handleChange = ({ target: { value, name } }) => {
    this.setState({ changed: true });
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    }));
  };

  updateUser = () => {
    this.props.updateUser(this.state.sourceData.username, this.state.data);
    this.setState({ changed: false, sourceData: this.state.data });
  };

  render() {
    return (
      <Dialog
        header={this.renderHeader}
        menu={this.renderMenu}
        content={this.renderContent}
        onBack={() => {
          history.goBack();
        }}
      />
    );
  }

  renderMenu = () => {
    const dialogMenuItems = [
      {
        key: 'details',
        title: I18n.t('users.userTabHeading'),
        route: this.props.match.url,
      },
    ];

    return this.props.updateStatus &&
      this.props.updateStatus === NetworkStatus.STARTED ? (
      ''
    ) : (
      <DialogMenu structure={dialogMenuItems} />
    );
  };

  renderHeader = () => {
    const didUserSettingsChange = Helpers.didValuesChange(
      this.getSettingsObject(USER_SETTINGS),
      this.getSettingsObject(this.state.settings)
    );
    return (
      <Fragment>
        <div className={'profile-header'}>
          <div>
            <div className={'profile-header__caption-text'}>
              {notEmpty(this.state.data.firstName) ||
              notEmpty(this.state.data.firstName)
                ? `${this.state.data.firstName ?? ''} ${
                    this.state.data.lastName ?? ''
                  }`
                : null}
            </div>
          </div>
        </div>
        <div className={'profile-actions__section'}>
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Button
              disabled={!didUserSettingsChange && !this.state.changed}
              onClick={() => this.setState({ data: this.state.sourceData })}
              secondary
            >
              <Translate value="common.undo" />
            </Button>
            <Button
              disabled={!didUserSettingsChange && !this.state.changed}
              style={{ marginLeft: '5px' }}
              onClick={this.updateUser}
            >
              <Translate value="common.save" />
            </Button>
          </div>
        </div>
      </Fragment>
    );
  };

  renderSettings = () => {
    const { settings } = this.state;
    return (
      <Fragment>
        <SectionDescription
          title={I18n.t('users.adminControlsSectionHeading')}
        />
        {Object.values(settings).map(({ id, title, on }, index) => {
          return (
            <SwitchField
              key={`user_settings_${id}_${index}`}
              title={title}
              on={on}
              onClick={() =>
                this.setState((prevState) => {
                  const setting = prevState.settings[id];
                  return {
                    settings: {
                      ...prevState.settings,
                      [id]: {
                        ...setting,
                        on: !setting['on'],
                      },
                    },
                  };
                })
              }
            />
          );
        })}
        <div style={{ color: 'red', fontSize: '18px' }}>
          <SwitchField
            title={I18n.t('users.deleteAccountToggleLabel')}
            on={this.state.deleteUser}
            onClick={() =>
              this.setState({ deleteUser: !this.state.deleteUser })
            }
          />
        </div>
      </Fragment>
    );
  };

  renderControls = () => {
    if (this.props.role) {
      if (this.props.isSuperUser) {
        return (
          <div className={'dialog-content-section'}>
            <SectionDescription
              title={I18n.t('users.userRoleSectionHeading')}
            />
            <SwitchField
              title={I18n.t('users.adminRoleAssigmentToggleLabel')}
              on={this.state.data && this.state.data.isAdmin}
              onClick={() =>
                this.setState((state) => ({
                  ...state,
                  changed: true,
                  data: {
                    ...(state.data || {}),
                    isAdmin: state.data ? !state.data.isAdmin : false,
                    isPartner: state.isPartner ? false : state.isPartner,
                  },
                }))
              }
            />
            <SwitchField
              title={I18n.t('users.partnerRoleAssigmentToggleLabel')}
              on={this.state.data?.isPartner}
              onClick={() =>
                this.setState((state) => ({
                  ...state,
                  changed: true,
                  data: {
                    ...(state.data || {}),
                    isPartner: state.data ? !state.data.isPartner : false,
                    isAdmin: state.isAdmin ? false : state.isAdmin,
                  },
                }))
              }
            />
          </div>
        );
      }
      if (this.props.isPartnerOrSuperUser) {
        return (
          <div className={'dialog-content-section'}>
            <SectionDescription
              title={I18n.t('users.userRoleSectionHeading')}
            />
            <SwitchField
              title={I18n.t('users.adminRoleAssigmentToggleLabel')}
              on={this.state.data.isAdmin}
              onClick={() =>
                this.setState((state) => ({
                  ...state,
                  changed: true,
                  data: {
                    ...state.data,
                    isAdmin: !state.data.isAdmin,
                  },
                }))
              }
            />
          </div>
        );
      }
    }
  };

  renderContent = () => {
    return isLoadingStatusStarted(this.props.updateStatus) ||
      isLoadingStatusStarted(this.props.status) ? (
      <Throbber />
    ) : (
      <div
        className={'dialog-content'}
        style={{ padding: '20px 24px', maxWidth: '650px' }}
      >
        <div className={'dialog-content-section'}>
          <SectionDescription
            title={I18n.t('users.userDetailsSectionHeading')}
          />
          <InputField
            label={I18n.t('users.fields.firstName')}
            name={'firstName'}
            value={this.state.data.firstName}
            onChange={this.handleChange}
          />
          <InputField
            label={I18n.t('users.fields.lastName')}
            name={'lastName'}
            value={this.state.data.lastName}
            onChange={this.handleChange}
          />
          <InputField
            label={I18n.t('users.fields.email')}
            value={this.state.data.email}
            name={'email'}
            onChange={this.handleChange}
          />
          <InputField
            label={I18n.t('users.fields.password')}
            type={'password'}
            name="password"
            onChange={this.handleChange}
          />
        </div>
        {/*{this.renderControls()}*/}
        {/*{this.renderSettings()}*/}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    data: state.user.data,
    status: state.user.status,
    updateStatus: state.user.updateStatus,
    updateError: state.user.updateError,
    isPartnerOrSuperUser: UserHelpers.isPartnerOrSuperUser(state),
    isSuperUser: UserHelpers.isSuperUser(state),
    role: roleSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: (username) => dispatch(loadUser(username)),
    loadUserReset: () => dispatch(loadUserReset()),
    updateUser: (username, data) => dispatch(updateUser(username, data)),
    updateUserReset: () => dispatch(updateUserReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserDialog);
