export const ProfileV2ActionTypes = {
  PROFILE_V2_CREATION_STARTED: 'PROFILE_V2_CREATION_STARTED',
  PROFILE_V2_CREATION_FAILED: 'PROFILE_V2_CREATION_FAILED',
  PROFILE_V2_CREATION_SUCCESS: 'PROFILE_V2_CREATION_SUCCESS',
  PROFILE_V2_CREATION_RESET: 'PROFILE_V2_CREATION_RESET',

  PROFILE_V2_LOAD_APPLICATIONS_STARTED: 'PROFILE_V2_LOAD_APPLICATIONS_STARTED',
  PROFILE_V2_LOAD_APPLICATIONS_FAILED: 'PROFILE_V2_LOAD_APPLICATIONS_FAILED',
  PROFILE_V2_LOAD_APPLICATIONS_SUCCESS: 'PROFILE_V2_LOAD_APPLICATIONS_SUCCESS',

  PROFILE_V2_LOAD_BY_ID_STARTED: 'PROFILE_V2_LOAD_BY_ID_STARTED',
  PROFILE_V2_LOAD_BY_ID_FAILED: 'PROFILE_V2_LOAD_BY_ID_FAILED',
  PROFILE_V2_LOAD_BY_ID_SUCCESS: 'PROFILE_V2_LOAD_BY_ID_SUCCESS',

  CREATE_BRAND_NEW_PROFILE_V2_STARTED: 'CREATE_BRAND_NEW_PROFILE_V2_STARTED',
  CREATE_BRAND_NEW_PROFILE_V2_FAILED: 'CREATE_BRAND_NEW_PROFILE_V2_FAILED',
  CREATE_BRAND_NEW_PROFILE_V2_SUCCESS: 'CREATE_BRAND_NEW_PROFILE_V2_SUCCESS',

  CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_STARTED:
    'CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_STARTED',
  CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_FAILED:
    'CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_FAILED',
  CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_SUCCESS:
    'CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_SUCCESS',

  CREATE_NEW_PROFILE_VERSION_STARTED: 'CREATE_NEW_PROFILE_VERSION_STARTED',
  CREATE_NEW_PROFILE_VERSION_FAILED: 'CREATE_NEW_PROFILE_VERSION_FAILED',
  CREATE_NEW_PROFILE_VERSION_SUCCESS: 'CREATE_NEW_PROFILE_VERSION_SUCCESS',

  CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_STARTED:
    'CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_STARTED',
  CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_FAILED:
    'CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_FAILED',
  CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_SUCCESS:
    'CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_SUCCESS',

  SAVE_AND_PUBLISH_PROFILE_STARTED: 'SAVE_AND_PUBLISH_PROFILE_STARTED',
  SAVE_AND_PUBLISH_PROFILE_FAILED: 'SAVE_AND_PUBLISH_PROFILE_FAILED',
  SAVE_AND_PUBLISH_PROFILE_SUCCESS: 'SAVE_AND_PUBLISH_PROFILE_SUCCESS',

  SAVE_AS_DRAFT_STARTED: 'SAVE_AS_DRAFT_STARTED',
  SAVE_AS_DRAFT_FAILED: 'SAVE_AS_DRAFT_FAILED',
  SAVE_AS_DRAFT_SUCCESS: 'SAVE_AS_DRAFT_SUCCESS',

  UNPUBLISH_PROFILES_V2_STARTED: 'UNPUBLISH_PROFILES_V2_STARTED',
  UNPUBLISH_PROFILES_V2_FAILED: 'UNPUBLISH_PROFILES_V2_FAILED',
  UNPUBLISH_PROFILES_V2_SUCCESS: 'UNPUBLISH_PROFILES_V2_SUCCESS',

  PROFILE_V2_UPDATE_STARTED: 'PROFILE_V2_UPDATE_STARTED',
  PROFILE_V2_UPDATE_FAILED: 'PROFILE_V2_UPDATE_FAILED',
  PROFILE_V2_UPDATE_SUCCESS: 'PROFILE_V2_UPDATE_SUCCESS',
  PROFILE_V2_UPDATE_RESET: 'PROFILE_V2_UPDATE_RESET',

  UNASSIGN_FLEETS_FROM_PROFILE_V2_STARTED:
    'UNASSIGN_FLEETS_FROM_PROFILE_V2_STARTED',
  UNASSIGN_FLEETS_FROM_PROFILE_FAILED: 'UNASSIGN_FLEETS_FROM_PROFILE_FAILED',
  UNASSIGN_FLEETS_FROM_PROFILE_SUCCESS: 'UNASSIGN_FLEETS_FROM_PROFILE_SUCCESS',

  PROFILE_V2_RESET: 'PROFILE_V2_RESET',
};
