import {
  FILTERS_SET,
  FILTERS_SET_CRITERIA_STRICTNESS,
  FILTERS_SET_UPDATING,
} from './filters.actionTypes';

export function setFilters(payload) {
  return {
    type: FILTERS_SET,
    payload,
  };
}

export function setFiltersUpdating(payload) {
  return {
    type: FILTERS_SET_UPDATING,
    payload,
  };
}

export function setFiltersStrictness(payload) {
  return {
    type: FILTERS_SET_CRITERIA_STRICTNESS,
    payload,
  };
}
