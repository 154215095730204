import { deserializeApplication } from '../application/application.serializer';
import { TaskStatusTypes } from './tasks.constants';

export const deserializeTask = (data) => {
  return {
    devicesFailedId: data.devices_failed_id || undefined,
    devicesDeliveredId: data.devices_delivered_id || undefined,
    devicesFailedCount: data.devices_failed_count || 0,
    devicesDeliveredCount: data.devices_delivered_count || 0,
    latestVersion:
      typeof data.latest_version === 'boolean' ? data.latest_version : false,
    lastUpdated:
      typeof data.last_updated === 'string' ? data.last_updated : undefined,
    parentId: data.parent_id || undefined,
    id: data.profile_id || undefined,
    name: data.profile_name || undefined,
    status:
      data.status != null && TaskStatusTypes[data.status] !== undefined
        ? TaskStatusTypes[data.status]
        : undefined,
    datetime: data.task_datetime || undefined, // "YYYY-MM-DDThh:mm:ssss"
    version: typeof data.version === 'number' ? data.version : undefined,
    apps:
      data.apps && data.apps.length
        ? data.apps.map(deserializeApplication)
        : [],
  };
};

export const serializeTask = (data) => {
  return {
    devices_failed: 9,
    devices_installed: 0,
    latest_version: false,
    parent_id: '067bd534-4a4b-4cbe-a693-66e6003fb30a',
    profile_id: '067bd534-4a4b-4cbe-a693-66e6003fb30a',
    profile_name: 'fleet_1_profile_4',
    status: 'SCHEDULED',
    task_datetime: '2019-11-29T11:00:00+00:00',
    version: 1,
  };
};

export const taskMock = {
  devices_failed: 9,
  devices_installed: 0,
  latest_version: false,
  parent_id: '067bd534-4a4b-4cbe-a693-66e6003fb30a',
  profile_id: '067bd534-4a4b-4cbe-a693-66e6003fb30a',
  profile_name: 'fleet_1_profile_4',
  status: 'SCHEDULED',
  task_datetime: '2019-11-29T11:00:00+00:00',
  version: 1,
};
