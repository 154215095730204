import {
  DEVICES_LOAD_FAILED,
  DEVICES_LOAD_STARTED,
  DEVICES_LOAD_SUCCESS,
  DEVICES_RECEIVED_UPDATES,
  DEVICES_REMOVE_FAILED,
  DEVICES_REMOVE_RESET,
  DEVICES_REMOVE_STARTED,
  DEVICES_REMOVE_SUCCESS,
  DEVICES_SEND_REMOTE_COMMAND_FAILED,
  DEVICES_SEND_REMOTE_COMMAND_RESET,
  DEVICES_SEND_REMOTE_COMMAND_STARTED,
  DEVICES_SEND_REMOTE_COMMAND_SUCCESS,
} from './devices.actionTypes';
import {
  DEVICE_REMOTE_CONTROL_REQUEST_FAILED,
  DEVICE_REMOTE_CONTROL_REQUEST_STARTED,
  DEVICE_REMOTE_CONTROL_REQUEST_SUCCESS,
} from '../device/device.actionTypes';

export function devicesRemoveStarted() {
  return {
    type: DEVICES_REMOVE_STARTED,
  };
}

export function devicesRemoveFailed(payload) {
  return {
    type: DEVICES_REMOVE_FAILED,
    payload,
  };
}

export function devicesRemoveDone() {
  return {
    type: DEVICES_REMOVE_SUCCESS,
  };
}

export function devicesRemoveStatusReset() {
  return {
    type: DEVICES_REMOVE_RESET,
  };
}

export function loadDevicesStarted() {
  return {
    type: DEVICES_LOAD_STARTED,
  };
}

export function loadDevicesFailed() {
  return {
    type: DEVICES_LOAD_FAILED,
  };
}

export function devicesLoaded(payload) {
  return {
    type: DEVICES_LOAD_SUCCESS,
    payload,
  };
}

export function devicesReceivedUpdates(payload) {
  return {
    type: DEVICES_RECEIVED_UPDATES,
    payload,
  };
}

export function devicesSendRemoteCommandStarted() {
  return {
    type: DEVICES_SEND_REMOTE_COMMAND_STARTED,
  };
}

export function devicesSendRemoteCommandSuccess() {
  return {
    type: DEVICES_SEND_REMOTE_COMMAND_SUCCESS,
  };
}

export function devicesSendRemoteCommandFailed(payload) {
  return {
    type: DEVICES_SEND_REMOTE_COMMAND_FAILED,
    payload,
  };
}

export function devicesSendRemoteCommandReset() {
  return {
    type: DEVICES_SEND_REMOTE_COMMAND_RESET,
  };
}

export function requestDeviceRemoteControlStarted() {
  return {
    type: DEVICE_REMOTE_CONTROL_REQUEST_STARTED,
  };
}

export function requestDeviceRemoteControlFailed(payload) {
  return {
    type: DEVICE_REMOTE_CONTROL_REQUEST_FAILED,
    payload,
  };
}

export function requestDeviceRemoteControlDone() {
  return {
    type: DEVICE_REMOTE_CONTROL_REQUEST_SUCCESS,
  };
}
