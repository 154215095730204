import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { TextButton } from '../../../components/textButton.component';
import { ReactComponent as PlusCircle } from '../../../assets/images/plus-circle.svg';

const PolicyHeader = ({
  descriptionTitle,
  descriptionHint,
  descriptionProps,
  textButtonProps: {
    hint: textButtonHint = I18n.t('profiles.addConfigurationLabel'),
    title: textButtonTitle = I18n.t('profiles.addLabel'),
    handleTextButtonClick,
  } = {},
  actionButton = null,
}) => (
  <section className="policy-header">
    <SectionDescription
      {...descriptionProps}
      title={descriptionTitle}
      hint={descriptionHint}
    />
    {actionButton ?? (
      <TextButton
        onClick={handleTextButtonClick}
        hint={textButtonHint}
        title={textButtonTitle}
      >
        <PlusCircle />
      </TextButton>
    )}
  </section>
);

export default PolicyHeader;
