import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle-icon.svg';
import { ReactComponent as CrestCircle } from '../../assets/images/crest-circle.svg';
import { ReactComponent as Exclamation } from '../../assets/images/exclamation.svg';
import { I18n } from 'react-redux-i18n';
import StyleUtils from '../../utils/styleUtils';
import Icon from '../Icon';

const SubmissionInput = ({
  placeholder,
  onKeyUp,
  onInputChange,
  defaultValue = '',
  onSubmit,
  onReset,
  submitIconTitle = I18n.t('common.saveChanges'),
  resetIconTitle = I18n.t('common.cancel'),
  showResetIcon,
  hideSubmitIcon,
  noEmptyValueAllowed,
  submitDisabled = false,
  ...otherProps
}) => {
  const [value, setValue] = useState(defaultValue);
  const [errorText, setErrorText] = useState('');
  const isEmptyValueValidationFailed = noEmptyValueAllowed && !value;

  // useEffect(() => {
  //   if(isEmptyValueValidationFailed) {
  //     setErrorText('Value cant be empty');
  //   } else {
  //     setErrorText('');
  //   }
  // }, [isEmptyValueValidationFailed]);

  const handleInputChange = (e) => {
    const { target: { value } = {} } = e;
    onInputChange?.(e);
    setValue(value);
  };

  const handleSubmit = (e) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if (isEmptyValueValidationFailed || submitDisabled) {
      return;
    }
    onSubmit?.(value, e);
  };
  const handleReset = () => {
    setValue('');
    onReset?.();
  };

  const handleInputKeyup = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      return handleSubmit(e);
    }
    if (e.keyCode === 27) {
      // Esc key
      return setValue('');
    }
    onKeyUp?.(e);
  };

  return (
    <div className="submission-input">
      <input
        {...otherProps}
        type={'text'}
        className={'form-control submission-input__input'}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyUp={handleInputKeyup}
        value={value || ''}
      />
      <div className={'submission-input__actions'}>
        {!hideSubmitIcon ? (
          <CheckCircle
            onClick={handleSubmit}
            title={submitIconTitle}
            className={StyleUtils.mergeClasses(
              'submission-input-action',
              StyleUtils.mergeModifiers(
                'icon',
                isEmptyValueValidationFailed || submitDisabled
                  ? 'disabled'
                  : 'success'
              )
            )}
          />
        ) : null}
        {showResetIcon ? (
          <CrestCircle
            onClick={handleReset}
            title={resetIconTitle}
            className={'submission-input-action icon--danger'}
          />
        ) : null}
      </div>
      {isEmptyValueValidationFailed ? (
        <div className="submission-input__error-icon">
          <Icon icon={Exclamation} className="icon--danger">
            {errorText}
          </Icon>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(SubmissionInput);
