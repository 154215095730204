import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Tool from '../../components/ToolBar/Tool';
import Application from '../application/application.component';
import {
  applicationAttributes,
  applicationsColumns,
} from './applications.constants';
import { deleteApplications, loadApplications } from './applications.thunk';
import ToolsPanel from '../../components/ToolsPanel';
import { applicationsDeletingReset } from './applications.actions';
import { companyIdSelector } from '../login/login.selectors';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import Filter from '../../components/Filter';
import {
  groupAppsWithChildrenByKey,
  isAppManagementAllowed,
} from '../../common/utilities/applications';
import { globalPermissionsForUserSelector } from '../../selectors/accountSelectors';
import UserHelpers from '../../common/utilities/user';
// import { showStatusChangePopup } from '../../common/helpers/common';
// import { NetworkStatus } from '../../common/constants';
import { applicationsSelector } from './applications.selectors';
import { isDataLoadingRequired } from '../../common/helpers';
import DataTable from '../../components/DataTable/DataTable.component';
import { Pages } from '../../constants/pages';

class Applications extends Component {
  state = {
    selected: new Set(),
  };

  tableRef = React.createRef();

  componentDidMount() {
    if (isDataLoadingRequired(this.props.status)) {
      this.props.loadApplications();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.deletingStatus !== prevProps.deletingStatus &&
      (this.props.deletingStatus === 'done' ||
        this.props.deletingStatus === 'error')
    ) {
      this.setState(() => ({ selected: new Set() }));
      // showStatusChangePopup({
      //   status: this.props.deletingStatus,
      //   title: I18n.t('fileSystem.deletingApplications'),
      //   onClose: () => {
      //     this.props.applicationsDeletingReset();
      //     if (this.props.deletingStatus === NetworkStatus.DONE) {
      //       this.setState({ selected: new Set() });
      //     }
      //   },
      // });
    }
  }

  render() {
    const {
      globalPermissionsForUser = {},
      hasAdminPrivileges = false,
    } = this.props;
    const showToolbar =
      this.state.selected.size && !this.props.match.params.childRoute;
    const contentClassName = showToolbar
      ? 'app__content app__content--with-toolbar'
      : 'app__content';
    const items = groupAppsWithChildrenByKey(this.props.data, 'idName');
    return (
      <Fragment>
        <div className={contentClassName}>
          <div className={'page-content'}>
            <div className={'card'}>
              <DataTable
                allColumns={applicationsColumns}
                data={items}
                loadingStatus={this.props.status}
                tableRef={this.tableRef}
                sortable
                pageId={Pages.FILE_SYSTEM_APPS_PAGE}
                onSelect={this.select}
                useCSVExport
                useResizableColumns
                allColumnsResizable
                useFilter
                useFlex
              />
            </div>
          </div>
        </div>
        {this.renderDialog()}
      </Fragment>
    );
  }

  renderDialog = () => {
    const { hasAdminPrivileges } = this.props;
    if (this.props.match.params.childRoute) {
      return (
        <Application
          hasAdminPrivileges={hasAdminPrivileges}
          attributes={applicationAttributes}
        />
      );
    }
  };
}

const mapStateToProps = (state) => {
  const globalPermissionsForUser = globalPermissionsForUserSelector(state);
  // const globalPermissionsForUser = globalPermissionsMock;
  const { data } = applicationsSelector(state);

  return {
    data,
    status: state.applications.status,
    deletingStatus: state.applications.deletingStatus,
    companyId: companyIdSelector(state),
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    globalPermissionsForUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    applicationsDeletingReset: () => dispatch(applicationsDeletingReset()),
    loadApplications: () => dispatch(loadApplications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
