import React, { FC } from 'react';
import { v4 as uuid } from 'uuid';

import './styles/radioButtonGroup.scss';
import StyleUtils from '../../utils/styleUtils';

interface RadioButtonOption {
  name: string;
  label: React.ReactNode;
  value: string | number;
  id?: string;
}

interface RadioButtonGroupProps {
  checkedValue?: string;
  options: RadioButtonOption[];
  groupLabel?: React.ReactNode;
  onChange: (e) => void;
  withColumnView?: boolean;
  radioButtonGroupId?: string;
}

const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  checkedValue,
  options = [],
  groupLabel,
  onChange,
  withColumnView = false,
  radioButtonGroupId,
}) => {
  const groupId = radioButtonGroupId ?? uuid();
  const renderOption = ({ value, label, id, name }) => (
    <label
      key={id || value}
      className="radio-button-group__option"
      htmlFor={id || label}
    >
      <input
        type="radio"
        id={id || label}
        name={name || label || id}
        value={value}
        checked={checkedValue === value}
        onChange={onChange}
        className="radio-button-group__radio"
      />
      <div className="radio-button-group__option-label">{label}</div>
    </label>
  );

  return (
    <div className="radio-button-group">
      <label htmlFor={groupId} className="radio-button-group__group-label">
        {groupLabel}
      </label>
      <div
        className={StyleUtils.mergeModifiers(
          'radio-button-group__options-container',
          withColumnView && 'column-view'
        )}
      >
        {options.map(renderOption)}
      </div>
    </div>
  );
};

export default RadioButtonGroup;
