import {
  fleetLoadingDone,
  fleetLoadingFailed,
  fleetLoadingStarted,
  fleetProfilesV1LoadFailed,
  fleetProfilesV1LoadStarted,
  fleetProfilesV1LoadSuccess,
  fleetProfilesV2LoadFailed,
  fleetProfilesV2LoadStarted,
  fleetProfilesV2LoadSuccess,
} from './fleets.actions';
import { deserializeFleet, serializeFleet } from '../fleet/fleet.serializer';
import { clientBC, clientV2 } from '../../utils/client';
import services from '../../utils/services';
import {
  FLEET_V2_CREATE_FAILED,
  FLEET_V2_CREATE_STARTED,
  FLEET_V2_CREATE_SUCCESS,
  FLEETS_V2_CONFIG_LOAD_FAILED,
  FLEETS_V2_CONFIG_LOAD_RESET,
  FLEETS_V2_CONFIG_LOAD_STARTED,
  FLEETS_V2_CONFIG_LOAD_SUCCESS,
  FLEETS_V2_LOAD_ALL_FAILED,
  FLEETS_V2_LOAD_ALL_STARTED,
  FLEETS_V2_LOAD_ALL_SUCCESS,
} from './fleetsV2.actionTypes';
import { I18n } from 'react-redux-i18n';
import { deserializeDevice } from '../device/device.serializer';
import {
  isLoadingStatusError,
  isLoadingStatusNone,
  notEmpty,
} from '../../common/helpers';
import PopupActions from '../popup/popup.actions';
import { getState } from '../../store/store';
import { devicesSelector } from '../devices/devices.selectors';
import { deserializeProfile } from '../profile/profile.serializer';

export function loadFleetDevicesV2({
  id,
  offset = 0,
  limit,
  isInitialLoad = false,
}) {
  return async (dispatch) => {
    try {
      dispatch(fleetLoadingStarted(id));
      let { status, data, text = '' } = await clientV2.get(
        `${services.getFleetDevicesById}${id}/`,
        {
          // params: {
          //     offset: isInitialLoad ? 0 : offset,
          //     limit,
          // },
        }
      );
      if (status === 200 && data && Array.isArray(data.results)) {
        const { results, next, count } = data;
        dispatch(
          fleetLoadingDone({
            devices: results.map(deserializeDevice),
            id,
            next,
            count,
            isInitialLoad,
          })
        );
      } else {
        dispatch(
          fleetLoadingFailed(I18n.t('fleets.failedToFetchSelectedFleet'))
        );
      }
    } catch (error) {
      dispatch(fleetLoadingFailed(I18n.t('fleets.failedToFetchSelectedFleet')));
    }
  };
}

export function loadAllFleetsV2() {
  return async (dispatch) => {
    try {
      dispatch({
        type: FLEETS_V2_LOAD_ALL_STARTED,
      });
      let response = await clientV2.get(services.fleetListByCompany);
      if (response.status === 200 && response.data?.results?.length >= 0) {
        dispatch({
          type: FLEETS_V2_LOAD_ALL_SUCCESS,
          payload: response.data.results
            .sort((fleetA = '', fleetB = '') => {
              const nameA = fleetA.name.toUpperCase();
              const nameB = fleetB.name.toUpperCase();
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
            .map(deserializeFleet),
        });
      } else {
        dispatch({
          type: FLEETS_V2_LOAD_ALL_FAILED,
          payload: I18n.t('fleets.loadAllFleetsErrorMessage'),
        });
      }
    } catch (error) {
      dispatch({
        type: FLEETS_V2_LOAD_ALL_FAILED,
        payload: new Error(I18n.t('fleets.loadAllFleetsErrorMessage')),
      });
    }
  };
}

export function loadFleetConfigById({ id }) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FLEETS_V2_CONFIG_LOAD_STARTED,
      });
      let { status, data } = await clientV2.get(`${services.fleetById}${id}/`);
      if (status === 200 && notEmpty(data)) {
        dispatch({
          type: FLEETS_V2_CONFIG_LOAD_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: FLEETS_V2_CONFIG_LOAD_FAILED,
          payload: I18n.t('fleets.loadFleetConfigByIdErrorMessage'),
        });
      }
    } catch (error) {
      dispatch({
        type: FLEETS_V2_CONFIG_LOAD_FAILED,
        payload: I18n.t('fleets.loadFleetConfigByIdErrorMessage'),
      });
    }
  };
}

export function resetFleetConfigById() {
  return {
    type: FLEETS_V2_CONFIG_LOAD_RESET,
  };
}

export function createFleetV2({ name, parentId }) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FLEET_V2_CREATE_STARTED,
      });
      let response = await clientV2.post(services.createNewFleet, {
        ...(parentId ? { parent_id: parentId } : {}),
        name,
      });
      if (response.status === 200) {
        dispatch({
          type: FLEET_V2_CREATE_SUCCESS,
        });
        dispatch(loadAllFleetsV2());
      } else if (response.status === 400) {
        dispatch({
          type: FLEET_V2_CREATE_FAILED,
          payload: `${I18n.t('fleets.fleetCreationTitle')}: ${
            response.data.detail
          }`,
        });
      } else if (response.status > 200) {
        dispatch({
          type: FLEET_V2_CREATE_FAILED,
          payload: I18n.t('fleets.fleetCreationFailedMessage'),
        });
      }
    } catch (error) {
      dispatch({
        type: FLEET_V2_CREATE_FAILED,
        payload: I18n.t('fleets.fleetCreationFailedMessage'),
      });
    }
  };
}

export function updateFleetV2(fleet) {
  return async (dispatch) => {
    try {
      const response = await clientV2.put(
        services.fleetUpdate,
        serializeFleet(fleet)
      );
      if (response.status === 200) {
        dispatch(loadAllFleetsV2());
      } else {
        // todo: handle error
      }
    } catch (error) {
      // todo: handle error
    }
  };
}

export const moveV2Fleet = ({
  fleetId,
  targetFleetId,
  retainProfiles = false,
}) => async (dispatch) => {
  try {
    const { status } = await clientV2.post(services.moveFleet, {
      moving_fleet_id: fleetId,
      to_what_fleet_id: targetFleetId,
      retain_profiles: retainProfiles,
    });
    if (status === 200) {
      dispatch(loadAllFleetsV2());
    } else {
      PopupActions.showAlert({
        text: I18n.t('common.somethingWentWrong'),
      });
    }
  } catch (e) {}
};

export const loadProfilesByFleetV2 = ({ fleetId }) => async (dispatch) => {
  try {
    dispatch(fleetProfilesV2LoadStarted());
    const { data, status } = await clientV2.get(
      `${services.profileListByFleet}${fleetId}`
    );
    if (status === 200 && Array.isArray(data)) {
      dispatch(fleetProfilesV2LoadSuccess(data.map(deserializeProfile)));
    } else {
      dispatch(
        fleetProfilesV2LoadFailed(
          I18n.t('fleets.failedToLoadProfilesAssignedToFleet')
        )
      );
    }
  } catch (e) {
    dispatch(
      fleetProfilesV2LoadFailed(
        I18n.t('fleets.failedToLoadProfilesAssignedToFleet')
      )
    );
  }
};

export const loadProfilesByFleetV1 = ({ fleetId }) => async (dispatch) => {
  try {
    dispatch(fleetProfilesV1LoadStarted());
    const { data, status } = await clientBC.get(
      `${services.getV1ProfilesAssignedToV2Fleet}${fleetId}`
    );
    if (status === 200 && Array.isArray(data)) {
      dispatch(fleetProfilesV1LoadSuccess(data.map(deserializeProfile)));
    } else {
      dispatch(
        fleetProfilesV1LoadFailed(
          I18n.t('fleets.failedToLoadProfilesAssignedToFleet')
        )
      );
    }
  } catch (e) {
    dispatch(
      fleetProfilesV1LoadFailed(
        I18n.t('fleets.failedToLoadProfilesAssignedToFleet')
      )
    );
  }
};

export const profilesAssignmentToFleet = ({
  fleetId,
  onClose,
  profilesForAssignmentV2,
  profilesForUnassignmentV2,
  profilesForAssignmentV1,
  profilesForUnassignmentV1,
}) => async (dispatch) => {
  try {
    const requests = [];
    notEmpty(profilesForAssignmentV2) &&
      requests.push(
        await clientV2.post(services.assignProfilesV2ToFleetV2, {
          id: fleetId,
          profiles: profilesForAssignmentV2,
        })
      );
    notEmpty(profilesForUnassignmentV2) &&
      requests.push(
        await clientV2.post(services.unassignProfilesV2FromFleetV2, {
          id: fleetId,
          profiles: profilesForUnassignmentV2,
        })
      );

    notEmpty(profilesForAssignmentV1) &&
      requests.push(
        await clientBC.post(services.assignV1ProfilesToV2Fleets, {
          id: fleetId,
          profiles_v1: profilesForAssignmentV1,
        })
      );
    notEmpty(profilesForUnassignmentV1) &&
      requests.push(
        await clientBC.post(services.unassignV1ProfilesFromV2Fleets, {
          id: fleetId,
          profiles_v1: profilesForUnassignmentV1,
        })
      );
    const response = await Promise.all(requests);
    const actionStatus = response.reduce(
      (acc, { config: { url } = {}, status }) => {
        if (
          url === services.assignProfilesV2ToFleetV2 ||
          url === services.assignV1ProfilesToV2Fleets
        ) {
          if (status === 200) {
            return { ...acc, assigned: true };
          } else {
            return { ...acc, failedToAssign: true };
          }
        }
        if (
          url === services.unassignProfilesV2FromFleetV2 ||
          services.unassignV1ProfilesFromV2Fleets
        ) {
          if (status === 200) {
            return { ...acc, unassigned: true };
          } else {
            return { ...acc, failedToUnassign: true };
          }
        }
      },
      {
        assigned: false,
        unassigned: false,
        failedToAssign: false,
        failedToUnassign: false,
      }
    );

    const messages = {
      assigned: 'fleets.fleetToProfilesAssignmentSuccessMessage',
      unassigned: 'fleets.fleetToProfilesUnassignmentSuccessMessage',
      failedToAssign: 'fleets.fleetToProfilesAssignmentFailureMessage',
      failedToUnassign: 'fleets.fleetToProfilesUnassignmentFailureMessage',
    };

    const operationResultMessages = Object.entries(actionStatus).reduce(
      (acc, [key, value]) => {
        if (value) {
          return [...acc, I18n.t(messages[key])];
        }
        return acc;
      },
      []
    );
    PopupActions.showAlert({
      textList: operationResultMessages,
      onClose: () => {
        onClose?.();
        if (actionStatus.assigned || actionStatus.unassigned) {
          dispatch(loadProfilesByFleetV2({ fleetId }));
          dispatch(loadProfilesByFleetV1({ fleetId }));
        }
      },
    });
  } catch (e) {}
};

export function deleteFleetV2({ id, handleDeleteReset }) {
  return async (dispatch) => {
    try {
      const { fleetProfilesV2Status, fleetProfilesV1Status } = devicesSelector(
        getState()
      );

      if (
        isLoadingStatusNone(fleetProfilesV2Status) ||
        isLoadingStatusNone(fleetProfilesV1Status)
      ) {
        PopupActions.showLoader();
        const requests = [];

        if (isLoadingStatusNone(fleetProfilesV2Status)) {
          requests.push(
            await dispatch(
              loadProfilesByFleetV2({
                fleetId: id,
              })
            )
          );
        }

        if (isLoadingStatusNone(fleetProfilesV1Status)) {
          requests.push(
            await dispatch(
              loadProfilesByFleetV1({
                fleetId: id,
              })
            )
          );
        }

        await Promise.all(requests);

        PopupActions.hideLoader();

        const {
          fleetProfilesV2,
          fleetProfilesV1,
          fleetProfilesV2Status: fleetProfilesV2StatusAfterRequests,
          fleetProfilesV1Status: fleetProfilesV1StatusAfterRequests,
        } = devicesSelector(getState());

        if (
          isLoadingStatusError(fleetProfilesV2StatusAfterRequests) ||
          isLoadingStatusError(fleetProfilesV1StatusAfterRequests)
        ) {
          return PopupActions.showAlert({
            text: I18n.t('fleets.fleetDeletionFailure'),
            onClose: handleDeleteReset,
          });
        }

        if (notEmpty(fleetProfilesV2) || notEmpty(fleetProfilesV1)) {
          return PopupActions.showAlert({
            text: I18n.t('fleets.fleetDeletionFailureDueToAssignedProfiles'),
            onClose: handleDeleteReset,
          });
        }

        const deleteFleet = async () => {
          const response = await clientV2.post(services.fleetDelete, { id });
          if (response.status === 204) {
            dispatch(loadAllFleetsV2());
          } else if (response.status === 409) {
            return PopupActions.showAlert({
              text: I18n.t('fleets.fleetNotEmptyDeletionError'),
              onClose: handleDeleteReset,
            });
          } else {
            return PopupActions.showAlert({
              text: I18n.t('fleets.fleetDeletionFailure'),
              onClose: handleDeleteReset,
            });
          }
        };

        return PopupActions.showConfirm({
          text: I18n.t('fleets.fleetDeletionConfirmation'),
          onConfirm: () => {
            deleteFleet();
            handleDeleteReset();
          },
          onCancel: handleDeleteReset,
        });
      }
    } catch (error) {
      return PopupActions.showAlert({
        text: I18n.t('fleets.fleetDeletionFailure'),
      });
    }
  };
}
