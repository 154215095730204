import React, { Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import * as moment from 'moment-timezone';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import { UsersLogsTableColumns } from './userLogs.constants';
import { loadUsersLogs } from './usersLogs.thunk';
import { connect } from 'react-redux';
import { NetworkStatus } from '../../common/constants';
import { RootRoutes, UsersRoutes } from '../../utils/routes';
import { ADMINS_GROUP_ID, ALL_USERS_GROUP_ID } from '../../constants/users';
import { getUserManagementDialogStructure } from '../../common/utilities/users';
import UserHelpers from '../../common/utilities/user';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { loadGroups } from '../group/groups.thunk';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker.component';
import DataTable from '../../components/DataTable/DataTable.component';
import { Pages } from '../../constants/pages';
import { usersLogsSelector } from '../../selectors/usersLogs';
import { isDataLoadingRequired } from '../../common/helpers';

class UsersLogs extends React.Component {
  state = {
    logsRequestParams: {
      gte: null,
      lte: null,
    },
  };

  tableRef = React.createRef();

  get hasReadWritePermissions() {
    const { isUserManagementEnabled = false } = this.props;
    return isUserManagementEnabled;
  }

  get allUsersGroups() {
    const { userGroups = [], hasAdminPrivileges = false } = this.props;
    const groupNames = userGroups.map((group) => group.name);
    return getUserManagementDialogStructure({
      hasReadWritePermissions: this.hasReadWritePermissions,
      allUsersProps: {
        route: `${RootRoutes.USERS}${UsersRoutes.LOGS}/${ALL_USERS_GROUP_ID}`,
        exact: true,
      },
      adminsGroupProps: {
        route: `${RootRoutes.USERS}${UsersRoutes.LOGS}/${ADMINS_GROUP_ID}`,
        exact: false,
      },
      nonAdminGroupRoute: `${RootRoutes.USERS}${UsersRoutes.LOGS}`,
      isAdminsGroupHidden: !hasAdminPrivileges,
      userGroups: groupNames,
    });
  }

  componentDidMount() {
    const { userGroupsStatus, loadGroups } = this.props;
    if (userGroupsStatus !== NetworkStatus.DONE) {
      loadGroups();
    }
    if (isDataLoadingRequired(this.props.status)) {
      this.loadUsersLogs({
        isInitialLoad: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.groupId !== prevProps.match.params.groupId) {
      this.loadUsersLogs({
        isInitialLoad: true,
      });
    }
  }

  loadUsersLogs = (otherParams = {}) => {
    const groupId = RouteHelpers.getUrlParameter(this.props, 'groupId');
    const group = groupId !== ALL_USERS_GROUP_ID ? groupId : undefined;
    this.props.loadUsersLogs({ group, ...otherParams });
  };

  onDateRangeChange = ({ startDate, endDate }) => {
    const gte = startDate?.format();
    const lte = endDate?.format();

    this.setState(
      (prevState) => ({
        logsRequestParams: {
          ...prevState.logsRequestParams,
          gte,
          lte,
        },
      }),
      () =>
        this.loadUsersLogs({
          isInitialLoad: true,
          gte,
          lte,
        })
    );
  };

  renderDatePicker = () => (
    <DateRangePicker
      onRangeChange={this.onDateRangeChange}
      startDatePlaceholderText={I18n.t('users.userLogs.startDate')}
      endDatePlaceholderText={I18n.t('users.userLogs.endDate')}
      isOutsideRange={() => false}
      showClearDates
      showDefaultInputIcon
      inputIconPosition="after"
      anchorDirection="right"
      allowSameDayRange
      small
    />
  );

  loadMoreUserLogs = () => {
    const { offset, hasMore } = this.props;
    const { logsRequestParams } = this.state;

    if (hasMore) {
      this.loadUsersLogs({
        isInitialLoad: false,
        offset,
        ...logsRequestParams,
      });
    }
  };

  render() {
    const { userGroupsStatus, nextPageStatus, hasMore } = this.props;
    return (
      <Fragment>
        <div className={'app__content'}>
          <div className={'page-content'}>
            <div className={'card card--left-hand'}>
              <div className={'left-hand-pane-card__header'}>
                <Translate value="users.userLogs.userGroupsTitle" />
              </div>
              <div
                className={'left-hand-pane-card__content'}
                style={{ flexFlow: 'column' }}
              >
                {userGroupsStatus === NetworkStatus.DONE ? (
                  <DialogMenu structure={this.allUsersGroups} />
                ) : null}
              </div>
            </div>
            <div className={'card card--right-hand'}>
              <DataTable
                allColumns={UsersLogsTableColumns}
                data={this.props.data}
                loadingStatus={this.props.status}
                pageId={Pages.ACTIVITY_LOGS}
                rowHeight={80}
                tableRef={this.tableRef}
                useResizableColumns
                allColumnsResizable
                sortable
                useFlex
                useFilter
                useCSVExport
                headerTitle={
                  <div className={'card-header__title'}>
                    {this.renderGroupName(this.props.data)}
                  </div>
                }
                rightCustomHeaderAction={this.renderDatePicker()}
                infiniteScrollProps={{
                  nextPageLoadingStatus: nextPageStatus,
                  loadMore: this.loadMoreUserLogs,
                  hasMore,
                }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  renderGroupName = (items) => {
    const { groupId } = this.props.match.params;
    const currentGroupName =
      groupId === ALL_USERS_GROUP_ID ? I18n.t('users.allUsers') : groupId;
    const postfix =
      this.props.status === NetworkStatus.DONE && items
        ? `(${items.length})`
        : '';
    return `${currentGroupName || ''} ${postfix}`;
  };

  onSort = (sortBy, order) =>
    this.setState((state) => ({ ...state, sortBy, order }));
}
const mapStateToProps = (state) => {
  const hasAdminPrivileges = UserHelpers.hasAdminPrivileges(state);
  const isUserManagementEnabled = hasAdminPrivileges;
  const {
    data,
    status,
    error,
    nextPageStatus,
    nextPageError,
    hasMore,
    offset,
  } = usersLogsSelector(state);
  return {
    userGroups: state.groups.userGroups,
    userGroupsStatus: state.groups.userGroupsStatus,
    data,
    status,
    error,
    nextPageStatus,
    nextPageError,
    offset,
    hasMore,
    hasAdminPrivileges,
    isUserManagementEnabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsersLogs: (params) => dispatch(loadUsersLogs(params)),
    loadGroups: () => dispatch(loadGroups()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersLogs);
