import React, { FC } from 'react';
import StyleUtils from '../../utils/styleUtils';

interface CardProps {
  withPadding?: boolean;
}

const Card: FC<CardProps> = ({ children, withPadding }) => {
  return (
    <div
      className={StyleUtils.mergeModifiers(
        'card',
        withPadding && 'with-padding'
      )}
    >
      {children}
    </div>
  );
};

export default Card;
