import {
  ALERT_EDITING_FAILED,
  ALERT_EDITING_STARTED,
  ALERT_EDITING_SUCCESS,
  ALERT_LOAD_FAILED,
  ALERT_LOAD_STARTED,
  ALERT_LOAD_SUCCESS,
} from './alert.actionTypes';

export function loadAlertStart() {
  return {
    type: ALERT_LOAD_STARTED,
  };
}

export function loadAlertFailed(payload) {
  return {
    type: ALERT_LOAD_FAILED,
    payload,
  };
}

export function loadAlertSuccess(payload) {
  return {
    type: ALERT_LOAD_SUCCESS,
    payload,
  };
}

export function editAlertStart() {
  return {
    type: ALERT_EDITING_STARTED,
  };
}

export function editAlertFailed(payload) {
  return {
    type: ALERT_EDITING_FAILED,
    payload,
  };
}

export function editAlertSuccess(payload) {
  return {
    type: ALERT_EDITING_SUCCESS,
    payload,
  };
}
