const PopupConstants = {
  CONFIRM: 'CONFIRM',
  ALERT: 'ALERT',
  OPTIONS: 'OPTIONS',
  PROMPT: 'PROMPT',
  LOADER: 'LOADER',
};

export const PopupTextTypeConstants = {
  DEFAULT: 'default',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

export const DEFAULT_LOADER_POPUP_ID = 'DEFAULT_LOADER_POPUP_ID';

export default PopupConstants;
