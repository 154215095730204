export function generateEnrollmentConfig(policies, id) {
  if (!id || !id.length) {
    return null;
  }
  let policiesSettings = '';
  if (policies && policies.length) {
    let index = 1;
    policies.forEach((profilePolicies) => {
      if (profilePolicies && profilePolicies.length) {
        profilePolicies.forEach((policy) => {
          if (policy.policy_settings && policy.policy_name) {
            let settings = [...Object.entries(policy.policy_settings)].reduce(
              (res, entry) => {
                return res + `${entry[0]}=${entry[1]}\n`;
              },
              ''
            );
            policiesSettings = `${policiesSettings}[WLAN${index}]\n${settings}\n`;
            index = index + 1;
          }
        });
      }
    });
  }
  return `${policiesSettings}[Enrollment]\nkey=${id}`;
}
