import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import ToolsPanel from '../../components/ToolsPanel';
import Tool from '../../components/ToolBar/Tool';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import UserHelpers from '../../common/utilities/user';
import { deleteCertificates, loadCertificates } from './certificates.thunk';
import { certificatesColumns } from './certificates.constants';
import { companyIdSelector } from '../login/login.selectors';
import PopupActions from '../popup/popup.actions';
import { isDataLoadingRequired } from '../../common/helpers';
import { Pages } from '../../constants/pages';
import DataTable from '../../components/DataTable/DataTable.component';

const Certificates = ({
  hasAdminPrivileges,
  certificates,
  companyId,
  loadCertificates,
  certificatesLoadingStatus,
  deleteCertificates,
}) => {
  const tableRef = useRef();
  const [selectedCertificates, setSelectedCertificates] = useState(
    () => new Set()
  );

  useEffect(() => {
    if (isDataLoadingRequired(certificatesLoadingStatus)) {
      loadCertificates(companyId);
    }
  }, [loadCertificates, companyId, certificatesLoadingStatus]);

  const onSelect = (selectedIds) => {
    const selected = new Set(selectedIds);
    setSelectedCertificates(selected);
  };

  const resetSelectedCertificates = () =>
    setSelectedCertificates(() => new Set());

  const onDelete = () =>
    PopupActions.showConfirm({
      onConfirm: () => {
        deleteCertificates([...selectedCertificates.values()]);
        resetSelectedCertificates();
      },
      text: I18n.t('certificates.deletionConfirmation'),
    });

  const showToolBar = selectedCertificates.size;
  const contentClassName = showToolBar
    ? 'app__content app__content--with-toolbar'
    : 'app__content';

  return (
    <>
      <section className={contentClassName}>
        <section className="page-content">
          <section className="card">
            <DataTable
              allColumns={certificatesColumns}
              data={certificates}
              loadingStatus={certificatesLoadingStatus}
              onSelect={onSelect}
              pageId={Pages.CERTIFICATES}
              tableRef={tableRef}
              sortable
              selectable
              useFlex
              useFilter
            />
          </section>
        </section>
      </section>
      {showToolBar ? (
        <ToolsPanel
          title={I18n.t('certificates.selectedFiles')}
          selectedItems={selectedCertificates}
        >
          <Tool
            title={I18n.t('certificates.removeFiles')}
            onClick={onDelete}
            hidden={!hasAdminPrivileges}
          >
            <TrashEmpty className="icon" />
          </Tool>
        </ToolsPanel>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    certificates: state.certificates.data || [],
    certificatesLoadingStatus: state.certificates.loading,
    companyId: companyIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteCertificates: (certificates) =>
    dispatch(deleteCertificates(certificates)),
  loadCertificates: (companyId) => dispatch(loadCertificates(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
