export const deserializeFleet = (fleet) => {
  return {
    // TODO: validate date, move deserialization
    children: fleet.children && fleet.children.length ? fleet.children : [],
    companyId: fleet.company_id || null,
    devices: fleet.devices || [],
    lastUpdated: fleet.last_updated || null,
    parentId: fleet.parent || fleet.parent_id || null,
    profileId: fleet.profile_id || [],
    profiles: fleet.profiles && fleet.profiles.length ? fleet.profiles : [],
    rc_enabled: fleet.rc_enabled || null, // a flag for checking if remote control permission is available for this fleet

    // v2
    id: fleet.fleet_id || fleet.id || null,
    name: fleet.fleet_name || fleet.name || '',
    createdAt: fleet.created_at || null,
    updatedAt: fleet.updated_at || null,
  };
};

export const serializeFleet = (fleet) => {
  return {
    // TODO: validate date, move deserialization
    children:
      fleet.children && fleet.children.length ? fleet.children : undefined,
    company_id: fleet.companyId || undefined,
    devices: fleet.devices || undefined,
    fleet_id: fleet.id || undefined,
    fleet_name: fleet.name || undefined,
    parent_id: fleet.parentId || null,
    parent: fleet.parentId || null,
    profile_id: fleet.profileId || [],
    profiles:
      fleet.profiles && fleet.profiles.length ? fleet.profiles : undefined,
    rc_enabled: fleet.rc_enabled || null,

    // v2 properties
    id: fleet.id || undefined,
    name: fleet.name || undefined,
  };
};
