import React, { Component } from 'react';
import 'ol/ol.css';
import { Map, View, Feature } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Stroke, Fill, Circle, Text } from 'ol/style';
import { Point } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { connect } from 'react-redux';
import {
  subscribeChanges,
  unsubscribeChanges,
} from '../devices/devices.sockets';
import { DeviceRoutes } from '../../utils/routes';
import wsServices from '../../utils/websocketsServices';
import { history } from '../../utils/history';
import { sendTrackingPing } from './mapView.thunk';
import * as moment from 'moment';
import { NetworkStatus } from '../../common/constants';
import { companyIdSelector } from '../login/login.selectors';
import { loadDevices } from '../devices/devices.thunk';
import * as Observable from 'ol/Observable';
import Select from 'ol/interaction/Select';
import { pointerMove } from 'ol/events/condition';
import { fromLonLat, transformExtent, get } from 'ol/proj';
import { defaults as defaultControls, FullScreen } from 'ol/control';
import { I18n } from 'react-redux-i18n';
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine';
import PrintDialog from 'ol-ext/control/PrintDialog';
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import CanvasTitle from 'ol-ext/control/CanvasTitle';

class MapView extends Component {
  trackingTimeout;
  state = {};

  defaultStyle = (feature) => {
    let getProperty = (key, feature) => {
      const properties = feature.getProperties();
      let text = '';
      if (properties && properties.hasOwnProperty(key)) {
        text = properties[key];
      }
      const type = typeof text;
      if (type === 'string' || (type === 'number' && !isNaN(text))) {
        return `${text}`;
      }
      return undefined;
    };

    return [
      new Style({
        text: new Text({
          offsetY: -12,
          textAlign: 'center',
          textBaseline: 'middle',
          font: '15px Verdana',
          text: getProperty('name', feature),
          fill: new Fill({
            color: getProperty('colorCode', feature) || '#003562',
          }), // --incube primary color
          stroke: new Stroke({ color: 'white', width: 3 }),
        }),
        stroke: new Stroke({
          // fill: new Fill({
          //     color: getProperty('colorCode', feature) || "#003562"
          // }),
          color: getProperty('colorCode', feature) || '#003562', // --incube primary color
          width: 2,
        }),
        fill: new Fill({
          color: getProperty('colorCode', feature) || '#003562', // --incube primary color
        }),
        // image: new Icon({
        //     anchor: [0.5, 24],
        //     anchorXUnits: 'fraction',
        //     anchorYUnits: 'pixels',
        //     src: pin,
        //     fill: "#003562",
        // })
      }),
      new Style({
        image: new Circle({
          radius: 4,
          fill: new Fill({
            color: '#fff',
          }),
          stroke: new Stroke({
            color: '#003562',
            width: 3,
          }),
        }),
      }),
    ];
  };

  selectedStyle = (feature) => {
    let getProperty = (key, feature) => {
      const properties = feature.getProperties();
      let text = '';
      if (properties && properties.hasOwnProperty(key)) {
        text = properties[key];
      }
      const type = typeof text;
      if (type === 'string' || (type === 'number' && !isNaN(text))) {
        return `${text}`;
      }
      return undefined;
    };

    return [
      new Style({
        text: new Text({
          offsetY: -15,
          textAlign: 'center',
          textBaseline: 'middle',
          font: '17px Verdana',
          text: getProperty('name', feature),
          fill: new Fill({
            color: getProperty('colorCode', feature) || '#003562',
          }), // --incube primary color
          stroke: new Stroke({ color: 'white', width: 3 }),
        }),
        stroke: new Stroke({
          // fill: new Fill({
          //     color: getProperty('colorCode', feature) || "#003562"
          // }),
          color: getProperty('colorCode', feature) || '#003562', // --incube primary color
          width: 2,
        }),
        fill: new Fill({
          color: getProperty('colorCode', feature) || '#003562', // --incube primary color
        }),
        // image: new Icon({
        //     anchor: [0.5, 24],
        //     anchorXUnits: 'fraction',
        //     anchorYUnits: 'pixels',
        //     src: pin,
        //     fill: "#003562",
        // })
      }),
      new Style({
        image: new Circle({
          radius: 6,
          fill: new Fill({
            color: '#fff',
          }),
          stroke: new Stroke({
            color: '#003562',
            width: 4,
          }),
        }),
      }),
    ];
  };

  mapRef = React.createRef();
  map;
  source = new VectorSource();
  zoom = undefined;
  center = undefined;
  rotation = 0;
  shouldUpdate = true;
  featureOverlay = new VectorLayer({
    source: this.source,
    style: this.defaultStyle,
  });
  features = [];
  mapListeners = [];
  selectInteraction;
  hoverInteraction;
  extent = transformExtent(
    get('EPSG:4326').getWorldExtent(),
    'EPSG:4326',
    'EPSG:3857'
  );

  componentDidMount() {
    if (
      this.props.status !== NetworkStatus.STARTED &&
      this.props.status !== NetworkStatus.DONE
    ) {
      this.props.loadDevices();
    }
    // this.props.subscribeChanges(this.props.companyId);
    if (window.location.hash) {
      // try to restore center, zoom-level and rotation from the URL
      let hash = window.location.hash.replace('#map=', '');
      let parts = hash.split('/');
      if (parts.length === 4) {
        this.zoom = parseInt(parts[0], 10);
        this.center = [parseFloat(parts[1]), parseFloat(parts[2])];
        this.rotation = parseFloat(parts[3]);
      }
    }
    this.map = new Map({
      target: 'mapview',
      controls: defaultControls().extend([
        new FullScreen({
          tipLabel: I18n.t('device.mapFullScreenControlLabelTip'),
        }),
      ]),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: this.center ? this.center : [0, 0],
        zoom: this.zoom !== undefined ? this.zoom : 3,
      }),
    });

    if (this.props.data && this.props.data.length) {
      this.features = this.props.data.reduce((result, device) => {
        if (device.coordinates) {
          const geometry = new Point(
            fromLonLat([
              device.coordinates.longitude,
              device.coordinates.latitude,
            ])
          );
          if (geometry.intersectsExtent(this.extent)) {
            const feature = new Feature(geometry);
            feature.setProperties(device);
            return [...result, feature];
          }
        }
        return result;
      }, []);
      this.source = new VectorSource({ features: this.features || [] });
      this.featureOverlay = new VectorLayer({
        source: this.source,
        style: this.defaultStyle,
      });
      this.featureOverlay.setMap(this.map);

      // Add a title control
      this.map.addControl(
        new CanvasTitle({
          title: I18n.t('device.locationHistory'),
          visible: false,
          style: new Style({
            text: new Text({
              font:
                '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
            }),
          }),
        })
      );

      // Add a ScaleLine control
      this.map.addControl(new CanvasScaleLine());

      this.map.addControl(
        new PrintDialog({
          jsPDF,
          saveAs,
          size: 'A4',
          title: I18n.t('device.export'),
        })
      );

      // window.addEventListener('resize', this.refreshCenter);
    }

    if (window.location.hash) {
      this.map.getView().setCenter(this.center);
      this.map.getView().setZoom(this.zoom);
    } else if (this.source.getFeatures().length) {
      let extent = this.source.getExtent();
      if (extent.every(isFinite)) {
        this.map.getView().fit(extent);
      }
    }

    this.mapListeners.push(this.map.on('moveend', this.updatePermalink));
    // restore the view state when navigating through the history, see
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
    window.addEventListener('popstate', this.popStateListener);

    this.selectInteraction = new Select();
    this.map.addInteraction(this.selectInteraction);
    this.selectInteraction.on('select', this.onDeviceClickHandler);
    this.hoverInteraction = new Select({
      condition: pointerMove,
      style: this.selectedStyle,
    });
    this.map.addInteraction(this.hoverInteraction);
    this.hoverInteraction.on('select', this.onPointerMoveHandler);
    this.sendPing();
  }

  popStateListener = (event) => {
    if (event.state === null) {
      return;
    }
    if (event.state.center) {
      this.map.getView().setCenter(event.state.center);
    }
    if (event.state.zoom) {
      this.map.getView().setZoom(event.state.zoom);
    }
    this.shouldUpdate = false;
  };

  onPointerMoveHandler = (evt) => {
    if (evt.mapBrowserEvent && !evt.mapBrowserEvent.dragging) {
      this.map.getTargetElement().style.cursor = evt.selected.length
        ? 'pointer'
        : '';
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.data !== prevProps.data && this.props.data.length) {
      let featuresWasEmpty = !this.features || !this.features.length;
      this.features = this.props.data.reduce((result, device) => {
        if (device.coordinates) {
          const coordinates = fromLonLat([
            device.coordinates.longitude,
            device.coordinates.latitude,
          ]);
          const geometry = new Point(coordinates);

          if (geometry.intersectsExtent(this.extent)) {
            const feature = new Feature(geometry);
            feature.setProperties(device);
            return [...result, feature];
          }
        }
        return result;
      }, []);
      this.source = new VectorSource({ features: this.features });
      this.featureOverlay.setMap(null);
      this.featureOverlay = new VectorLayer({
        source: this.source,
        style: this.defaultStyle,
      });
      this.featureOverlay.setMap(this.map);
      if (featuresWasEmpty) {
        let extent = this.source.getExtent();
        if (extent.every(isFinite)) {
          this.map.getView().fit(extent);
        }
      }

      // if (!prevProps.devices || !prevProps.devices.length){
      //     this.map.getView().fit(this.source.getExtent(), this.map.getSize());
      // }
      // window.addEventListener('resize', this.refreshCenter);
      // this.refreshCenter();
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.refreshCenter);\
    clearTimeout(this.trackingTimeout);
    this.props.unsubscribeChanges();
    Observable.unByKey(this.mapListeners);
    window.removeEventListener('popstate', this.popStateListener);

    if (this.selectInteraction) {
      this.selectInteraction.un('select', this.onDeviceClickHandler);
      this.map.removeInteraction(this.selectInteraction);
    }
  }

  updatePermalink = () => {
    if (!this.shouldUpdate) {
      // do not update the URL when the view was changed in the 'popstate' handler
      this.shouldUpdate = true;
      return;
    }
    let view = this.map.getView();
    let center = view.getCenter();
    let hash =
      '#map=' +
      view.getZoom() +
      '/' +
      Math.round(center[0] * 100) / 100 +
      '/' +
      Math.round(center[1] * 100) / 100 +
      '/' +
      view.getRotation();
    let state = {
      zoom: view.getZoom(),
      center: view.getCenter(),
      rotation: view.getRotation(),
    };
    window.history.pushState(state, 'map', hash);
  };

  onDeviceClickHandler = (e) => {
    let clicked = false;
    (e.selected || []).forEach((feature) => {
      const properties = feature.getProperties();
      let id = '';
      if (properties && properties.hasOwnProperty('id')) {
        id = properties['id'];
      }
      if (id && !clicked) {
        history.push(`${DeviceRoutes}/${id}`);
        clicked = true;
      }
    });
  };

  // refreshCenter = () => {
  //     if (this.map && this.props.coordinates) {
  //         this.map.getView().setCenter(fromLonLat([this.props.coordinates.longitude, this.props.coordinates.latitude]));
  //     }
  // };

  sendPing = () => {
    this.props.sendTrackingPing(this.props.companyId);
    this.trackingTimeout = setTimeout(this.sendPing, 10000);
  };

  onChangeEnd = (endMoment) =>
    this.setState((state) => {
      let end;
      let start = state.start;
      if (moment.isMoment(endMoment)) {
        const startMoment = start ? moment.unix(start) : undefined;
        if (startMoment && startMoment.isValid()) {
          end = endMoment.isAfter(startMoment) ? endMoment.unix() : start;
        } else {
          end = endMoment.unix();
          start = end;
        }
      } else {
        end = state.end ? state.end : start;
      }
      return {
        ...state,
        start,
        end,
      };
    });

  onChangeStart = (startMoment) =>
    this.setState((state) => {
      let start;
      let end = state.end;
      if (moment.isMoment(startMoment)) {
        const endMoment = end ? moment.unix(end) : undefined;
        if (endMoment && endMoment.isValid()) {
          start = startMoment.isBefore(endMoment) ? startMoment.unix() : end;
        } else {
          start = endMoment.unix();
          end = start;
        }
      } else {
        start = state.start ? state.start : end;
      }
      return {
        ...state,
        start,
        end,
      };
    });

  //
  //
  // renderControls = () => (
  //     <div style={{position: 'absolute', zIndex: 10, top: '10px', right: '10px',  borderRadius: '4px',
  //         boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.16)', display: 'flex', flexFlow: 'row-reverse', height: '34px'}}>
  //         <Button
  //             secondary
  //             onClick={() => this.setState(state => {
  //                 if (state.start || state.end) {
  //                     this.features = [];
  //                     this.source = new VectorSource({features: this.features});
  //                     this.featureOverlay.setMap(null);
  //                     this.featureOverlay = new VectorLayer({
  //                             source: this.source,
  //                             style: this.defaultStyle,
  //                         }
  //                     );
  //                     return ( {start: undefined, end: undefined} );
  //                 } else {
  //                     return ( {
  //                         start: moment(new Date()).unix(),
  //                         end:   moment(new Date()).unix(),
  //                     } );
  //                 }
  //             })}
  //         >
  //             <Glyphicon
  //                 style={{padding: "2px"}}
  //                 glyph="calendar"
  //
  //             />
  //         </Button>
  //         { this.state.stack || this.state.end ?
  //             <React.Fragment>
  //                 <Datetime
  //                     value={moment.unix(this.state.end).isValid()
  //                         ? moment.unix(this.state.end)
  //                         : undefined
  //                     }
  //                     dateFormat={"YYYY-MM-DD"}
  //                     timeFormat={"hh:mm A"}
  //                     onChange={this.onChangeEnd}
  //                     closeOnSelect={true}
  //                     inputProps={{placeholder: 'Select end'}}
  //                 />
  //                 <Datetime
  //                     value={moment.unix(this.state.start).isValid()
  //                         ? moment.unix(this.state.start)
  //                         : undefined
  //                     }
  //                     dateFormat={"YYYY-MM-DD"}
  //                     timeFormat={"HH:mm A"}
  //                     onChange={this.onChangeStart}
  //                     closeOnSelect={true}
  //                     inputProps={{placeholder: 'Select start'}}
  //                 />
  //             </React.Fragment>
  //             : null }
  //     </div>
  // );

  render() {
    return (
      <div className={'app__content'}>
        <div className={'page-content'}>
          <div className={'card'} style={{ position: 'relative' }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                minWidth: '150px',
                minHeight: '150px',
              }}
              id="mapview"
              ref={this.mapRef}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.devices.devices,
    status: state.devices.devicesLoadingStatus,
    companyId: companyIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDevices: () => dispatch(loadDevices()),
    subscribeChanges: (companyId) =>
      dispatch(
        subscribeChanges(
          `${wsServices.subscribeDevicesUpdates}${companyId}/`,
          'devices.update'
        )
      ),
    unsubscribeChanges: (companyId) =>
      dispatch(
        unsubscribeChanges(`${wsServices.subscribeDevicesUpdates}${companyId}/`)
      ),
    sendTrackingPing: (companyId) => dispatch(sendTrackingPing(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapView);
