import { I18n } from 'react-redux-i18n';
import { DevicesRoutes, RootRoutes } from '../../utils/routes';

export const EnrollmentWizardRoutes = {
  FLEET: '/fleet',
  NAMING: '/naming',
  REVIEW: '/review',
  DONE: '/done',
};
export const CreateEnrollmentRoute = `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create`;

export const EnrollmentWizardTitles = new Map([
  [EnrollmentWizardRoutes.FLEET, I18n.t('enrollments.chooseAFleet')],
  [EnrollmentWizardRoutes.NAMING, I18n.t('enrollments.deviceNaming')],
  [EnrollmentWizardRoutes.REVIEW, I18n.t('enrollments.ReviewAndConfirm')],
  [EnrollmentWizardRoutes.DONE, I18n.t('enrollments.done')],
]);
