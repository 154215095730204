import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { cloneDeep, get } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  initialSettings,
  getInitialPolicy,
  kioskPolicyTypes,
} from '../profile.constants';
import { reorder } from '../../../common/helpers';
import KioskBuilderSideBar from './KioskBuilderSideBar.component';
import DragNDropDestinationListHeader from '../../../components/DragNDropComponents/DragNDropDestinationListHeader.component';
import DragNDropList from '../../../components/DragNDropList/DragNDropList.component';
import StyleUtils from '../../../utils/styleUtils';
import DragNDropListItem from '../../../components/DragNDropListItemComponents/DragNDropListItem.component';
import ApplicationBundle from './ApplicationBundle';
import { confirmAlert } from 'react-confirm-alert';
import { FILE_TYPES } from '../../../constants/fileTypes';

const DragNDropIds = {
  FILES: 'files',
  KIOSK: 'kiosk',
  APPLICATIONS: 'all_applications',
  HTML_KIOSK_WHITELIST: 'whitelisted',
};

class HTMLKioskBuilder extends Component {
  state = {
    passwordConfirmation: '',
    validationError: null,
  };

  componentDidMount() {
    const { policy } = this.props;
    if (
      policy[0] &&
      policy[0].settings &&
      policy[0].settings.kioskBackdoorPwd
    ) {
      this.setState(() => ({
        passwordConfirmation: policy[0].settings.kioskBackdoorPwd,
      }));
    }
  }

  get HTMLFiles() {
    const { files = [] } = this.props;
    return files.filter(({ contentType }) => contentType === FILE_TYPES.HTML);
  }

  onChange = (index, key, { target: { value } }) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...getInitialPolicy(kioskPolicyTypes.KIOSK) };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    settings[key] = value;
    item.settings = settings;
    this.props.onChange(kioskPolicyTypes.KIOSK, index, item);
  };

  setValidationError = () =>
    this.setState({
      validationError: I18n.t('profiles.kiosk.passwordDoesNotMatch'),
    });

  onSave = (isValid) =>
    isValid ? this.props.onSave() : this.setValidationError();

  onShowAssignFleets = (isValid) =>
    isValid ? this.props.onShowAssignFleets() : this.setValidationError();

  createNewVersion = (isValid) =>
    isValid ? this.props.onCreateNewVersion() : this.setValidationError();

  validateKioskBackDoorPassword = (value) => {
    const { policy } = this.props;
    const { validationError } = this.state;
    if (
      policy[0] &&
      policy[0].settings &&
      policy[0].settings.kioskBackdoorPwd
    ) {
      if (value === policy[0].settings.kioskBackdoorPwd && validationError) {
        this.setState({ validationError: null });
      }
      if (value !== policy[0].settings.kioskBackdoorPwd && !validationError) {
        this.setState({
          validationError: I18n.t('profiles.kiosk.passwordDoesNotMatch'),
        });
      }
    }
  };

  onChangePasswordConfirmation = ({ target: { value } }) => {
    this.setState({ passwordConfirmation: value }, () =>
      this.validateKioskBackDoorPassword(value)
    );
  };

  renderSectionDescription = () => (
    <React.Fragment>
      <Translate
        value="profiles.kiosk.HTMLKioskBuilder"
        className="section-description__title--small"
      />
      <Translate value="profiles.kiosk.HTMLKioskBuilderHint" />
    </React.Fragment>
  );

  renderSettings = ({ settings }, index) => {
    const { onBack, applicationsFullList } = this.props;
    return (
      <div className="kiosk-builder">
        <KioskBuilderSideBar
          onBack={onBack}
          settings={settings}
          validationError={this.state.validationError}
          onKioskBackdoorPwdChange={(e) =>
            this.onChange(index, 'kioskBackdoorPwd', e)
          }
          passwordConfirmation={this.state.passwordConfirmation}
          onChangePasswordConfirmation={this.onChangePasswordConfirmation}
          sectionDescription={this.renderSectionDescription()}
        />
        <div className={'kiosk-builder-content-wrapper-container'}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className={'kiosk-builder-content-wrapper'}>
              <div className={'kiosk-builder-content'}>
                <div className={'kiosk-builder-content-actions-section'}>
                  <div />
                  <div>
                    <Button
                      disabled={!this.props.changed}
                      secondary
                      onClick={this.props.onUndo}
                    >
                      <Translate value="profiles.kiosk.undo" />
                    </Button>
                    <Button
                      style={{ marginLeft: '5px' }}
                      disabled={!this.props.changed}
                      onClick={() =>
                        this.onSave(
                          settings.kioskBackdoorPwd ===
                            this.state.passwordConfirmation
                        )
                      }
                    >
                      <Translate value="profiles.saveButtonText" />
                    </Button>
                    {this.props.profile?.id ? (
                      <Button
                        style={{ marginLeft: '5px' }}
                        disabled={!this.props.changed}
                        onClick={() =>
                          this.onShowAssignFleets(
                            settings.kioskBackdoorPwd ===
                              this.state.passwordConfirmation
                          )
                        }
                      >
                        <Translate value="profiles.associateFleetsTitle" />
                      </Button>
                    ) : null}
                  </div>
                </div>

                <DragNDropList
                  listHeader={
                    <DragNDropDestinationListHeader
                      listTitle={I18n.t('profiles.kiosk.htmlKiosk')}
                      isIconHidden
                    />
                  }
                  droppableListId={DragNDropIds.KIOSK}
                  items={
                    settings.htmlKioskFile ? [settings.htmlKioskFile] : null
                  }
                  itemOptions={{
                    actionButtonProps: {
                      onClick: this.clearKioskFile,
                    },
                    displayTextKeys: {
                      leftCell: ['filename', 'key'],
                    },
                  }}
                />
                <DragNDropList
                  listHeader={
                    <DragNDropDestinationListHeader
                      listTitle={I18n.t('profiles.kiosk.htmlKioskWhiteList')}
                      iconProps={{
                        onClick: () =>
                          confirmAlert({
                            customUI: this.getCustomUI(
                              DragNDropIds.HTML_KIOSK_WHITELIST
                            ),
                          }),
                      }}
                    />
                  }
                  droppableListId={DragNDropIds.HTML_KIOSK_WHITELIST}
                  items={get(settings, 'whitelisted', [])}
                  itemComponent={DragNDropListItem}
                  itemOptions={{
                    displayTextKeys: {
                      leftCell: ['idName', 'name'],
                    },
                  }}
                  idSubstitute="idName"
                />
              </div>

              <div className="kiosk-builder-content-wrapper-side-bar-stack">
                <div
                  className={StyleUtils.mergeModifiers(
                    'kiosk-builder-content-wrapper-side-bar',
                    'half-full-height'
                  )}
                >
                  <div
                    className={
                      'kiosk-builder-content-wrapper-side-bar-section-description'
                    }
                  >
                    <div className={'section-description__title--small'}>
                      <Translate value="profiles.kiosk.myHtmlFiles" />
                    </div>
                  </div>
                  <div className="kiosk-builder-content-wrapper-side-bar-list">
                    <DragNDropList
                      droppableListId={DragNDropIds.FILES}
                      items={this.HTMLFiles}
                      itemOptions={{
                        displayTextKeys: {
                          leftCell: ['filename', 'key'],
                        },
                      }}
                      hasNoBorders
                    />
                  </div>
                </div>
                <div
                  className={StyleUtils.mergeModifiers(
                    'kiosk-builder-content-wrapper-side-bar',
                    'half-full-height'
                  )}
                >
                  <div className="kiosk-builder-content-wrapper-side-bar-list">
                    <DragNDropList
                      listHeader={
                        <div className="kiosk-builder-content-wrapper-side-bar-section-description">
                          <div className={'section-description__title--small'}>
                            <Translate value="profiles.kiosk.myApplications" />
                          </div>
                        </div>
                      }
                      droppableListId={DragNDropIds.APPLICATIONS}
                      idSubstitute="idName"
                      items={applicationsFullList}
                      itemOptions={{
                        displayTextKeys: {
                          leftCell: ['idName', 'name'],
                        },
                      }}
                      hasNoBorders
                    />
                  </div>
                </div>
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    );
  };

  onChangeArray = ({
    index,
    key,
    value,
    doNotRemove,
    result: { source = {}, destination = {} } = {},
  }) => {
    const isMovedWithinSameList =
      source &&
      destination &&
      source.droppableId &&
      destination.droppableId &&
      source.droppableId === destination.droppableId;
    const policy = [...this.props.policy];
    const item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...getInitialPolicy(kioskPolicyTypes.KIOSK) };
    const settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    const setting = get(settings, key, []);
    settings[key] = cloneDeep(setting);
    const fileKey = value.id ? 'id' : 'idName';
    let foundIndex = settings[key].findIndex(
      (item) => item[fileKey] === value[fileKey]
    );
    if (foundIndex === -1) {
      settings[key].splice(destination.index, 0, value);
    } else if (isMovedWithinSameList) {
      settings[key] = reorder(settings[key], source.index, destination.index);
    } else if (!doNotRemove) {
      // this part is for when item is dropped outside the list
      settings[key].splice(foundIndex, 1);
    }
    item.settings = settings;
    this.props.onChange(kioskPolicyTypes.KIOSK, index, item);
  };

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    const expression = new RegExp(`^${source.droppableId}_`);

    // if dropped outside the list
    if (
      (source.droppableId === DragNDropIds.KIOSK ||
        source.droppableId === DragNDropIds.HTML_KIOSK_WHITELIST) &&
      (!destination || source.droppableId !== destination.droppableId)
    ) {
      const existingApp = this.props.applicationsFullList.find(
        (app) => app.id === draggableId.replace(expression, '')
      );
      if (existingApp) {
        const { id, name, idName, version, versionCode } = existingApp;
        this.onChangeArray({
          index: 0,
          key: source.droppableId,
          value: { id, name, idName, version, versionCode },
          doNotRemove: false,
        });
      } else {
        this.onChangeArray({
          index: 0,
          key: source.droppableId,
          value: { idName: draggableId.replace(expression, '') },
          doNotRemove: false,
        });
      }
    }

    if (
      destination &&
      destination.droppableId === DragNDropIds.KIOSK &&
      source.droppableId !== destination.droppableId &&
      source.droppableId === DragNDropIds.FILES
    ) {
      const id = draggableId.replace(expression, '');
      const file = this.props.files.find((file) => file.id === id);
      this.onChange(0, 'htmlKioskFile', {
        target: {
          value: {
            ...file,
          },
        },
      });
    }

    if (
      destination &&
      destination.droppableId === DragNDropIds.HTML_KIOSK_WHITELIST &&
      source.droppableId !== destination.droppableId &&
      source.droppableId === DragNDropIds.APPLICATIONS
    ) {
      const itemId = draggableId.replace(expression, '');
      const existingApp = this.props.applicationsFullList.find(
        (app) => app.id === itemId.replace(expression, '')
      );
      if (existingApp) {
        const { id, name, idName, version, versionCode } = existingApp;
        this.onChangeArray({
          index: 0,
          key: destination.droppableId,
          value: { id, name, idName, version, versionCode },
          doNotRemove: true,
          result,
        });
      } else {
        this.onChangeArray({
          index: 0,
          key: destination.droppableId,
          value: { idName: itemId },
          doNotRemove: true,
          result,
        });
      }
    }
  };

  clearKioskFile = () =>
    this.onChange(0, 'htmlKioskFile', { target: { value: undefined } });

  getCustomUI = (destination) => ({ onClose }) => {
    return (
      <ApplicationBundle
        destinationIndex={0}
        onSubmit={(destinationIndex, value) =>
          this.addBundle(destination, destinationIndex, value)
        }
        onCancel={onClose}
      />
    );
  };

  addBundle = (destination, destinationIndex, value) => {
    if (destination === DragNDropIds.HTML_KIOSK_WHITELIST) {
      this.onChangeArray({
        index: 0,
        key: destination,
        value,
        doNotRemove: true,
      });
    } else {
      this.onChange(0, destination, { target: { value } });
    }
  };

  renderContent = () =>
    this.props.policy.length
      ? this.renderSettings(
          {
            ...getInitialPolicy(kioskPolicyTypes.KIOSK),
            settings: { ...initialSettings },
            ...this.props.policy[0],
          },
          0
        )
      : this.renderSettings(
          {
            ...getInitialPolicy(kioskPolicyTypes.KIOSK),
            settings: { ...initialSettings },
          },
          0
        );

  render() {
    return <section className="dialog-base">{this.renderContent()}</section>;
  }
}

export default HTMLKioskBuilder;
