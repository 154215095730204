import { AttributeType } from '../../common/constants';
import { I18n } from 'react-redux-i18n';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import { notEmpty } from '../../common/helpers';
import { getPolicyTypes } from '../../common/utilities/profile';
import { attributiveDataCustomFormatters } from '../../common/utilities/attributiveDataCustomFormatters';
import { filterConditions } from '../../constants/filter';

export const menuConstants = {
  OVERVIEW: 'OVERVIEW',
  APPLICATIONS: 'APPLICATIONS',
  PROFILES_ASSIGNED: 'PROFILES_ASSIGNED',
  ACTIVITY_LOGS: 'ACTIVITY_LOGS',
  LOCATION: 'LOCATION',
  CURRENT_LOCATION: 'CURRENT_LOCATION',
  LOCATION_HISTORY: 'LOCATION_HISTORY',
  REMOTE_CONTROL: 'REMOTE_CONTROL',
  SECURITY: 'SECURITY',
};

export const locationLogsTableColumns = [
  {
    key: 'actionTimestamp',
    type: AttributeType.DATE,
    titleCode: I18n.t('device.logs.dateTime'),
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN_OR_EQUAL,
      filterConditions.LESS_THAN_OR_EQUAL,
    ],
  },
  {
    key: 'coordinates',
    type: AttributeType.COORDINATES,
    titleCode: I18n.t('device.logs.coordinates'),
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN_OR_EQUAL,
      filterConditions.LESS_THAN_OR_EQUAL,
    ],
    displayOnUIOnly: true,
  },
  {
    key: 'longitude',
    type: AttributeType.CUSTOM,
    titleCode: I18n.t('device.logs.longitude'),
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN_OR_EQUAL,
      filterConditions.LESS_THAN_OR_EQUAL,
    ],
    custom: {
      formatterFunction: attributiveDataCustomFormatters.coordinate,
    },
  },
  {
    key: 'latitude',
    type: AttributeType.CUSTOM,
    titleCode: I18n.t('device.logs.latitude'),
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN_OR_EQUAL,
      filterConditions.LESS_THAN_OR_EQUAL,
    ],
    custom: {
      formatterFunction: attributiveDataCustomFormatters.coordinate,
    },
  },
];

export const activityLogsTableColumns = [
  {
    key: 'actionTimestamp',
    type: AttributeType.DATE,
    titleCode: I18n.t('device.logs.dateTime'),
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN_OR_EQUAL,
      filterConditions.LESS_THAN_OR_EQUAL,
    ],
  },
  {
    key: 'context',
    type: AttributeType.TEXT,
    titleCode: I18n.t('device.logs.message'),
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
];

export const deviceProfilesAssignedColumns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'profiles.fields.name',
    // ref: `${RootRoutes.PROFILES}${ProfilesRoutes.ALL}`,
    // refId: 'id',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'published',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.fields.published',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'createdAt',
    type: AttributeType.DATE,
    titleCode: 'profiles.fields.createdAt',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'profiles.fields.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'version',
    type: AttributeType.TEXT,
    titleCode: 'profiles.fields.version',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'newestVersion',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.fields.newestVersion',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'policies',
    type: AttributeType.CUSTOM,
    titleCode: 'profiles.fields.policies',
    custom: {
      formatterFunction: ({ values, key }) => {
        const policies = values[key];
        if (notEmpty(policies)) {
          return getPolicyTypes(Object.values(policies)).join(', ');
        }
      },
    },
  },
];

export const newSampleLocationLogMay12 = [
  {
    action_timestamp: '2021-04-12T19:33:01',
    latitude: 53.216221,
    longitude: 50.186321,
  },
  {
    action_timestamp: '2021-04-13T19:48:06',
    latitude: 53.226707,
    longitude: 50.193414,
  },
  {
    action_timestamp: '2021-04-14T20:03:06',
    latitude: 53.242828,
    longitude: 50.210046,
  },
  {
    action_timestamp: '2021-04-15T19:33:01',
    latitude: 53.217894,
    longitude: 50.241097,
  },
  {
    action_timestamp: '2021-04-16T19:33:01',
    latitude: 53.211677,
    longitude: 50.190363,
  },
];

export const sampleDeviceActivityLog = [
  {
    action_timestamp: '2021-04-12T19:33:01',
    message: 'message 1',
    level: 'info',
    metadata: 'metadata 1',
  },
  {
    action_timestamp: '2021-04-13T19:48:06',
    message: 'message 2',
    level: 'info',
    metadata: 'metadata 2',
  },
  {
    action_timestamp: '2021-04-14T20:03:06',
    message: 'message 3',
    level: 'info',
    metadata: 'metadata 3',
  },
  {
    action_timestamp: '2021-04-15T19:33:01',
    message: 'message 4',
    level: 'info',
    metadata: 'metadata 4',
  },
  {
    action_timestamp: '2021-04-16T19:33:01',
    message: 'message 5',
    level: 'info',
    metadata: 'metadata 5',
  },
];
