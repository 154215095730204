import { NetworkStatus } from '../common/constants';
import {
  SEARCH_PERFORM_FAILED,
  SEARCH_PERFORM_STARTED,
  SEARCH_PERFORM_SUCCESS,
  SEARCH_RESET,
  SEARCH_SET_KEYS,
  SEARCH_SET_TEXT,
} from './search.actionTypes';

export const searchInitialState = {
  devices: [],
  fleets: [],
  apps: [],
  profiles: [],
  enrollments: [],
  status: NetworkStatus.NONE,
  error: null,
  searchText: '',
  selectedKeys: new Set(),
};

export default function search(state = searchInitialState, action) {
  switch (action.type) {
    case SEARCH_RESET:
      return Object.assign({}, state, searchInitialState, {
        searchText: '',
        selectedKeys: state.selectedKeys,
      });
    case SEARCH_PERFORM_STARTED:
      return Object.assign({}, state, {
        status: NetworkStatus.STARTED,
        error: null,
      });
    case SEARCH_PERFORM_FAILED:
      return Object.assign({}, state, {
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case SEARCH_PERFORM_SUCCESS:
      return Object.assign({}, state, {
        devices: action.payload.devices,
        fleets: action.payload.fleets,
        apps: action.payload.apps,
        profiles: action.payload.profiles,
        enrollments: action.payload.enrollments,
        status: NetworkStatus.DONE,
        error: null,
      });
    case SEARCH_SET_TEXT:
      return Object.assign({}, state, {
        searchText: action.payload,
      });
    case SEARCH_SET_KEYS:
      return Object.assign({}, state, {
        selectedKeys: new Set(action.payload),
      });

    default:
      return state;
  }
}
