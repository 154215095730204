import {
  REMOTE_CONTROL_APPLICATION_NAME,
  REMOTE_CONTROL_APPLICATION_PACKAGE_ID,
} from '../../constants/applications';
import { notEmpty } from '../../common/helpers';

export const deserializeApplication = (data) => {
  const isRemoteControlApp =
    data.app_id_name === REMOTE_CONTROL_APPLICATION_PACKAGE_ID;
  return {
    companyId: data.company_id || undefined,
    description: data.description || '',
    fileChecksum: data.file_checksum || null,
    category: data.app_category || undefined,
    id: data.app_id,
    isRemoteControlApp,
    idName: isRemoteControlApp
      ? REMOTE_CONTROL_APPLICATION_NAME
      : data.app_id_name || undefined,
    signed: data.signed ?? false,
    signedForDeviceType: data.signed_for_device_type ?? '',
    name: data.app_name || undefined,
    label: data.label ?? '',
    installOnly: data.install_only || false,
    size: typeof data.app_size === 'number' ? data.app_size : undefined,
    status: data.app_status || undefined,
    version: data.app_version || undefined,
    versionCode: data.app_version_code || 0,
    external_link: data.external_link ?? null,
    currentVersion:
      typeof data.current_version === 'boolean'
        ? data.current_version
        : undefined,
    // description: null
    // device_type: null
    devicesFailed:
      typeof data.devices_failed === 'number' ? data.devices_failed : undefined,
    devicesInstalled:
      typeof data.devices_installed === 'number'
        ? data.devices_installed
        : undefined,
    file: data.file || undefined,
    lastUpdated: data.last_updated || undefined,
    uploadedByName: data.uploaded_by_name,
    uploadedByEmail: data.uploaded_by_email,
    uploadedOn: data.uploaded_on,
    ...(notEmpty(data.versions)
      ? { versions: data.versions.map(deserializeApplication) }
      : {}),
  };
};

export const serializeApplication = (data) => {
  return {
    app_id: data.id || null,
    app_id_name:
      data.idName === REMOTE_CONTROL_APPLICATION_NAME
        ? REMOTE_CONTROL_APPLICATION_PACKAGE_ID
        : data.idName || null,
    app_name: data.name || '',
    label: data.label ?? '',
    install_only: data.installOnly || false,
    external_link: data.external_link ?? null,
    app_version: data.version || null,
    app_version_code: data.versionCode || null,
    company_id: data.companyId || undefined,
    description: data.description || undefined,
    file: data.file || undefined,
    file_checksum: data.fileChecksum || undefined,
    last_updated: data.lastUpdated || undefined,
  };
};

export const serializePolicyApplication = (data) => {
  return {
    app_id: data.id || null,
    app_id_name:
      data.idName === REMOTE_CONTROL_APPLICATION_NAME
        ? REMOTE_CONTROL_APPLICATION_PACKAGE_ID
        : data.idName || null,
    app_name: data.name || '',
    external_link: data.external_link || null,
    app_version: data.version || '',
    installOnly: data.install_only || false,
  };
};
