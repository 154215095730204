export const LOAD_DEVICE_STARTED = 'LOAD_DEVICE_STARTED';
export const LOAD_DEVICE_FAILED = 'LOAD_DEVICE_FAILED';
export const SET_DEVICE_DATA = 'SET_DEVICE_DATA';
export const RESET_DEVICE_DATA = 'RESET_DEVICE_DATA';
export const DEVICE_LOAD_PROFILES_STARTED = 'DEVICE_LOAD_PROFILES_STARTED';
export const DEVICE_LOAD_PROFILES_SUCCESS = 'DEVICE_LOAD_PROFILES_SUCCESS';
export const DEVICE_LOAD_PROFILES_FAILED = 'DEVICE_LOAD_PROFILES_FAILED';
export const DEVICE_LOAD_APPLICATIONS_STARTED =
  'DEVICE_LOAD_APPLICATIONS_STARTED';
export const DEVICE_LOAD_APPLICATIONS_SUCCESS =
  'DEVICE_LOAD_APPLICATIONS_SUCCESS';
export const DEVICE_LOAD_APPLICATIONS_FAILED =
  'DEVICE_LOAD_APPLICATIONS_FAILED';
export const DEVICE_LOAD_APPLICATIONS_RESET = 'DEVICE_LOAD_APPLICATIONS_RESET';
export const DEVICE_LOAD_LOGS_STARTED = 'DEVICE_LOAD_LOGS_STARTED';
export const DEVICE_LOAD_LOGS_SUCCESS = 'DEVICE_LOAD_LOGS_SUCCESS';
export const DEVICE_LOAD_LOGS_FAILED = 'DEVICE_LOAD_LOGS_FAILED';
export const DEVICE_LOAD_LOGS_RESET = 'DEVICE_LOAD_LOGS_RESET';

export const DEVICE_LOAD_ACTIVITY_LOGS_STARTED =
  'DEVICE_LOAD_ACTIVITY_LOGS_STARTED';
export const DEVICE_LOAD_ACTIVITY_LOGS_SUCCESS =
  'DEVICE_LOAD_ACTIVITY_LOGS_SUCCESS';
export const DEVICE_LOAD_ACTIVITY_LOGS_FAILED =
  'DEVICE_LOAD_ACTIVITY_LOGS_FAILED';
export const DEVICE_LOAD_ACTIVITY_LOGS_RESET =
  'DEVICE_LOAD_ACTIVITY_LOGS_RESET';

export const DEVICE_REMOTE_CONTROL_REQUEST_STARTED =
  'DEVICE_REMOTE_CONTROL_REQUEST_STARTED';
export const DEVICE_REMOTE_CONTROL_REQUEST_FAILED =
  'DEVICE_REMOTE_CONTROL_REQUEST_FAILED';
export const DEVICE_REMOTE_CONTROL_REQUEST_SUCCESS =
  'DEVICE_REMOTE_CONTROL_REQUEST_SUCCESS';
export const DEVICE_UNINSTALL_APPLICATIONS_STARTED =
  'DEVICE_UNINSTALL_APPLICATIONS_STARTED';
export const DEVICE_UNINSTALL_APPLICATIONS_FAILED =
  'DEVICE_UNINSTALL_APPLICATIONS_FAILED';
export const DEVICE_UNINSTALL_APPLICATIONS_SUCCESS =
  'DEVICE_UNINSTALL_APPLICATIONS_SUCCESS';
export const DEVICE_UNINSTALL_APPLICATIONS_RESET =
  'DEVICE_UNINSTALL_APPLICATIONS_RESET';

export const UPDATE_DEVICE_PROPERTY_STARTED = 'UPDATE_DEVICE_PROPERTY_STARTED';
export const UPDATE_DEVICE_PROPERTY_SUCCESS = 'UPDATE_DEVICE_PROPERTY_SUCCESS';
export const UPDATE_DEVICE_PROPERTY_FAILED = 'UPDATE_DEVICE_PROPERTY_FAILED';
export const UPDATE_DEVICE_PROPERTY_RESET = 'UPDATE_DEVICE_PROPERTY_RESET';
