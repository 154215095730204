export const deserializeStatistics = (data) => {
  return {
    online: data.online_devices || null,
    lowBattery: data.low_battery_devices || null,
    active: data.active_devices || null,
    android: data.android_devices || null,
    compliant: data.compliant_devices || null,
    linux: data.linux_devices || null,
    prolonged: data.prolonged_statistic
      ? deserializeProlongedStatistic(data.prolonged_statistic || {})
      : null,
    manufacturer: data.manufacturer_chart || [],
    os: data.os_version_chart || [],
    lastCheckin: data.last_checkin_chart || [],
  };
};

export const deserializeProlongedStatistic = (data) => {
  return {
    active: data.array_active_devices
      ? deserializeActiveDevices(data.array_active_devices)
      : {},
    motion: data.motion_of_devices || 0,
    tasksInstalled: data.tasks_installed || 0,
    enrolled: data.enrolled_devices || 0,
    lowBattery: data.low_battery_devices || 0,
    failed: data.failed_devices || 0,
  };
};

export const deserializeActiveDevices = (data) => {
  return {
    start: data.start_datetime || null,
    unit: data.unit || null,
    quantity: data.devices_quantity || [],
  };
};

export const statisticsMock = {
  online_devices: 7,
  low_battery_devices: 0,
  active_devices: 0,
  android_devices: 14,
  compliant_devices: 0,
  linux_devices: 1,
  prolonged_statistic: {
    array_active_devices: {
      start_datetime: '2019-04-02T07:00:00',
      unit: 'hour',
      devices_quantity: [0, 5, 1],
    },
    motion_of_devices: 1,
    tasks_installed: 0,
    enrolled_devices: 1,
    low_battery_devices: 0,
    failed_devices: 0,
  },
};
