import React from 'react';
import { Portal } from 'react-portal';
import { I18n } from 'react-redux-i18n';

/**
 *  Modal component
 * @param {Number} numberInStack Determines the placement of
 * the modal in the stack (using z-index) if there are more than one open at a time
 * @param title
 * @param onBack
 * @param actionsSection
 * @param children
 * @returns {*}
 * @constructor
 */

export const Modal = ({
  numberInStack,
  title,
  onBack,
  actionsSection,
  children,
}) => {
  return (
    <Portal>
      <div
        className={'modal'}
        style={numberInStack ? { zIndex: `${numberInStack}0` } : {}}
      >
        <div className={'modal__fade'} />
        <div className={'modal-window-container'}>
          <div className={'modal-window'}>
            <div className={'modal-window-header'}>
              <div
                onClick={onBack}
                className={'modal-window-header__left-action'}
              >
                <div>⟵ </div>
                <div>{I18n.t('common.back')}</div>
              </div>
              <div className={'modal-window-header__title'}>{title}</div>
              {actionsSection ?? <div />}
            </div>
            <div className={'modal-window-content-wrapper'}>
              <div className={'modal-window__content'}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
