export const serializeAPKFileMeta = ({
  appName,
  label,
  file,
  appVersion,
  appVersionCode,
  appIdName,
  fileChecksum,
  appSize,
  saveLocation,
  contentType,
  key,
}) => ({
  app_name: appName,
  label,
  app_version: appVersion,
  app_version_code: appVersionCode,
  app_id_name: appIdName,
  file_checksum: fileChecksum,
  app_size: appSize,
  save_to: saveLocation,
  content_type: contentType,
  filename: file,
  file,
  key,
});
