import { NetworkStatus } from '../../common/constants';
import {
  USER_CREATE_STARTED,
  USER_CREATE_FAILED,
  USER_CREATE_SUCCESS,
  LOAD_PARTNER_OPTIONS_FAILED,
  LOAD_PARTNER_OPTIONS_STARTED,
  LOAD_PARTNER_OPTIONS_SUCCESS,
  USER_CREATE_RESET,
  USER_CREATE_LOAD_COMPANIES_STARTED,
  USER_CREATE_LOAD_COMPANIES_FAILED,
  USER_CREATE_LOAD_COMPANIES_SUCCESS,
} from '../newUser/newUser.actions';
import {
  USER_LOAD_FAILED,
  USER_LOAD_RESET,
  USER_LOAD_STARTED,
  USER_LOAD_SUCCESS,
  USER_UPDATE_FAILED,
  USER_UPDATE_RESET,
  USER_UPDATE_STARTED,
  USER_UPDATE_SUCCESS,
} from './user.actionTypes';

export default function user(
  state = {
    companies: [],
    companiesStatus: NetworkStatus.NONE,
    companiesError: undefined,
    partnerNameOptionsData: [],
    partnerNameOptionsStatus: NetworkStatus.NONE,
    partnerNameOptionsError: undefined,
    createUserStatus: NetworkStatus.NONE,
    createUserError: undefined,
    updateStatus: NetworkStatus.NONE,
    updateError: undefined,
    status: NetworkStatus.NONE,
    error: undefined,
    data: [],
  },
  action
) {
  switch (action.type) {
    case USER_LOAD_STARTED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case USER_LOAD_FAILED:
      return Object.assign({}, state, {
        ...state,
        data: [],
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case USER_LOAD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    case USER_LOAD_RESET:
      return Object.assign({}, state, {
        ...state,
        data: [],
        status: NetworkStatus.NONE,
        error: undefined,
      });
    case USER_UPDATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        updateStatus: NetworkStatus.STARTED,
        updateError: undefined,
      });
    case USER_UPDATE_FAILED:
      return Object.assign({}, state, {
        ...state,
        updateStatus: NetworkStatus.ERROR,
        updateError: action.payload,
      });
    case USER_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        updateStatus: NetworkStatus.DONE,
        updateError: undefined,
      });
    case USER_UPDATE_RESET:
      return Object.assign({}, state, {
        ...state,
        updateStatus: NetworkStatus.NONE,
        updateError: undefined,
      });
    case LOAD_PARTNER_OPTIONS_STARTED:
      return Object.assign({}, state, {
        ...state,
        partnerNameOptionsData: [],
        partnerNameOptionsStatus: NetworkStatus.STARTED,
        partnerNameOptionsError: undefined,
      });
    case LOAD_PARTNER_OPTIONS_FAILED:
      return Object.assign({}, state, {
        ...state,
        partnerNameOptionsData: [],
        partnerNameOptionsStatus: NetworkStatus.ERROR,
        partnerNameOptionsError: action.payload,
      });
    case LOAD_PARTNER_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        partnerNameOptionsData: action.payload,
        partnerNameOptionsStatus: NetworkStatus.DONE,
        partnerNameOptionsError: undefined,
      });
    case USER_CREATE_LOAD_COMPANIES_STARTED:
      return Object.assign({}, state, {
        ...state,
        companies: [],
        companiesStatus: NetworkStatus.STARTED,
        companiesError: undefined,
      });
    case USER_CREATE_LOAD_COMPANIES_FAILED:
      return Object.assign({}, state, {
        ...state,
        companies: [],
        companiesStatus: NetworkStatus.ERROR,
        companiesError: action.payload,
      });
    case USER_CREATE_LOAD_COMPANIES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        companies: action.payload,
        companiesStatus: NetworkStatus.DONE,
        companiesError: undefined,
      });
    case USER_CREATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        createUserStatus: NetworkStatus.STARTED,
        createUserError: undefined,
      });
    case USER_CREATE_FAILED:
      return Object.assign({}, state, {
        ...state,
        createUserStatus: NetworkStatus.ERROR,
        createUserError: action.payload,
      });
    case USER_CREATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        createUserStatus: NetworkStatus.DONE,
        createUserError: undefined,
      });
    case USER_CREATE_RESET:
      return Object.assign({}, state, {
        ...state,
        createUserStatus: NetworkStatus.NONE,
        createUserError: undefined,
      });
    default:
      return state;
  }
}
