import client from '../../utils/client';
import { loadUsers } from '../users/users.thunk';
import {
  createUsersFailed,
  createUsersStart,
  createUsersSuccess,
  loadCompaniesFailed,
  loadCompaniesStart,
  loadCompaniesSuccess,
  loadPartnerNameOptionsFailed,
  loadPartnerNameOptionsStart,
  loadPartnerNameOptionsSuccess,
} from './newUser.actions';
import { history } from '../../utils/history';
import { RootRoutes, UsersRoutes } from '../../utils/routes';
import {
  deserializeCompany,
  deserializePartnerNameOptions,
  userSerializer,
} from './newUser.serializer';
import { I18n } from 'react-redux-i18n';

export function createUsers(userData) {
  return async (dispatch) => {
    try {
      dispatch(createUsersStart());
      let { status, data } = await client.post(
        `user-management/users/`,
        userSerializer(userData)
      );
      if (status === 200) {
        dispatch(createUsersSuccess());
        dispatch(loadUsers());
        history.push(
          `${RootRoutes.USERS}${UsersRoutes.GROUPS}/all/users/${userData.username}/details`
        );
      } else {
        let error = I18n.t('users.failedToCreateNewUserMessage');
        if (data) {
          let validationErrors = Object.values(data).reduce(
            (result, item, index) => {
              let separator = index ? ', ' : '';
              if (typeof item === 'string') {
                return `${result}${separator}${item}`;
              }
              if (Array.isArray(item)) {
                return `${result}${separator}${item.join(', ')}`;
              }
              return result;
            },
            ''
          );
          if (validationErrors) {
            error = validationErrors;
          }
        }
        dispatch(createUsersFailed(error));
      }
    } catch (error) {
      dispatch(createUsersFailed(I18n.t('users.failedToCreateNewUserMessage')));
    }
  };
}

export function loadPartnerNameOptions() {
  return async (dispatch) => {
    try {
      dispatch(loadPartnerNameOptionsStart());
      let { status, data } = await client.get('/partners/');
      if (status === 200) {
        const result = data.map(deserializePartnerNameOptions);
        dispatch(loadPartnerNameOptionsSuccess(result));
      } else {
        dispatch(
          loadPartnerNameOptionsFailed(I18n.t('users.failedToLoadPartners'))
        );
      }
    } catch (error) {
      dispatch(
        loadPartnerNameOptionsFailed(I18n.t('users.failedToLoadPartners'))
      );
    }
  };
}

export function loadCompanies(partner) {
  return async (dispatch) => {
    try {
      dispatch(loadCompaniesStart());
      if (partner) {
        let { status, data } = await client.get('/company/', {
          params: { partner },
        });
        if (status === 200) {
          const result = data.map(deserializeCompany);
          dispatch(loadCompaniesSuccess(result));
        } else {
          dispatch(
            loadCompaniesFailed(I18n.t('users.failedToLoadPartnerCompanies'))
          );
        }
      } else {
        dispatch(loadCompaniesSuccess([]));
      }
    } catch (error) {
      dispatch(
        loadCompaniesFailed(I18n.t('users.failedToLoadPartnerCompanies'))
      );
    }
  };
}
