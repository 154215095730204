import { isNil, omit } from 'lodash';
import {
  deserializeApplication,
  serializeApplication,
  serializePolicyApplication,
} from '../application/application.serializer';
import {
  deserializeCertificate,
  serializeCertificate,
} from '../certificates/certificates.serializer';
import * as moment from 'moment-timezone';
import { kioskPolicyTypes } from './profile.constants';
import { deserializeFile, serializeFile } from '../../redux/files.api';
import { compact, notEmpty } from '../../common/helpers';
import { proxyConfigTypes, wiFiNetworkTypes } from '../../common/wifiConstants';
import { policyType } from '../../constants/profiles';
import { installOverCellularConditionConstants } from '../../constants/applications';

function deserializeKioskPolicySettingsCommonData(policy) {
  return {
    backButton:
      policy.policy_settings && !isNil(policy.policy_settings.backButton)
        ? policy.policy_settings.backButton
        : false,
    homeButton:
      policy.policy_settings && !isNil(policy.policy_settings.homeButton)
        ? policy.policy_settings.homeButton
        : false,
    menuButton:
      policy.policy_settings && !isNil(policy.policy_settings.menuButton)
        ? policy.policy_settings.menuButton
        : false,
    //  hideStatusBar is mapped to showStatusBar to support backward compatibility, since hideStatusBar was neither added on the backend nor device services side.
    hideStatusBar:
      policy.policy_settings && !isNil(policy.policy_settings.showStatusBar)
        ? !policy.policy_settings.showStatusBar
        : false,
    singleAppMode:
      policy.policy_settings && !isNil(policy.policy_settings.singleAppMode)
        ? policy.policy_settings.singleAppMode
        : false,
    bsSetDimension:
      policy.policy_settings && !isNil(policy.policy_settings.bsSetDimension)
        ? policy.policy_settings.bsSetDimension
        : '',
    bsExactDimension:
      policy.policy_settings && !isNil(policy.policy_settings.bsExactDimension)
        ? policy.policy_settings.bsExactDimension
        : '',
    bsSetOrientation:
      policy.policy_settings && !isNil(policy.policy_settings.bsSetOrientation)
        ? policy.policy_settings.bsSetOrientation
        : '',
    kioskBackdoorPwd:
      policy.policy_settings && !isNil(policy.policy_settings.kioskBackdoorPwd)
        ? policy.policy_settings.kioskBackdoorPwd
        : '',
    hasKioskBackdoorPwd:
      policy.policy_settings &&
      !isNil(policy.policy_settings.hasKioskBackdoorPwd)
        ? policy.policy_settings.hasKioskBackdoorPwd
        : true,
    showExitButton:
      policy.policy_settings && !isNil(policy.policy_settings.showExitButton)
        ? policy.policy_settings.showExitButton
        : false,
    apps:
      policy.policy_settings &&
      policy.policy_settings.apps &&
      policy.policy_settings.apps.length
        ? policy.policy_settings.apps.map(deserializeApplication)
        : [],
    whitelisted:
      policy.policy_settings &&
      policy.policy_settings.whitelisted &&
      policy.policy_settings.whitelisted.length
        ? policy.policy_settings.whitelisted.map(deserializeApplication)
        : [],
    autolaunch:
      policy.policy_settings && !isNil(policy.policy_settings.autolaunch)
        ? policy.policy_settings.autolaunch
        : false,
    autolaunchApp:
      policy.policy_settings && !isNil(policy.policy_settings.autolaunch_app)
        ? deserializeApplication(policy.policy_settings.autolaunch_app)
        : undefined,
    onlyKioskNotifications:
      policy.policy_settings &&
      typeof policy.policy_settings.onlyKioskNotifications === 'boolean'
        ? policy.policy_settings.onlyKioskNotifications
        : false,
    hideNavigationBar:
      policy.policy_settings &&
      typeof policy.policy_settings.hideNavigationBar === 'boolean'
        ? policy.policy_settings.hideNavigationBar
        : false,
    kioskBuilder:
      policy.policy_settings && !isNil(policy.policy_settings.kioskBuilder)
        ? policy.policy_settings.kioskBuilder
        : false,
    useHTMLKioskMode:
      policy.policy_settings &&
      typeof policy.policy_settings.useHTMLKioskMode === 'boolean'
        ? policy.policy_settings.useHTMLKioskMode
        : false,
    htmlKioskFile:
      policy.policy_settings && !isNil(policy.policy_settings.htmlKioskFile)
        ? policy.policy_settings.htmlKioskFile
        : undefined,
  };
}

export function deserializePolicies(policies) {
  let result = {
    wifi: [],
    webFilter: [],
    authentication: [],
    featureControl: [],
    location: [],
    apps: [],
    apn: [],
    kiosk: [],
    fileSync: [],
    geofencing: [],
    certificates: [],
    applist: [],
    password: [],
    security: [],
    settings: [],
    wallpaperSettings: [],
  };

  policies.forEach((policy) => {
    let data = {
      // TODO: validate date
      lastUpdated: !isNil(policy.last_updated) ? policy.last_updated : null,
      policyData: !isNil(policy.policy_data) ? policy.policy_data : null,
      policyId: !isNil(policy.policy_id) ? policy.policy_id : null,
      isActive: !isNil(policy.is_active) ? policy.is_active : null,
      policyName: !isNil(policy.policy_name) ? policy.policy_name : '',
      policyType: !isNil(policy.policy_type) ? policy.policy_type : null,
    };
    if (data.policyId) {
      switch (data.policyType) {
        case 'authentication': {
          result.authentication.push({
            ...data,
            settings: {
              password:
                policy.policy_settings && policy.policy_settings.password
                  ? policy.policy_settings.password
                  : '',
            },
          });
          break;
        }
        case policyType.FEATURE_CONTROL: {
          result.featureControl.push({
            ...data,
            settings: {
              allowInstallationsFromUnknownSources:
                policy.policy_settings &&
                !isNil(
                  policy.policy_settings
                    .allow_installations_from_unknown_sources
                )
                  ? policy.policy_settings
                      .allow_installations_from_unknown_sources
                  : false,
              disableBluetooth:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disable_bluetooth)
                  ? policy.policy_settings.disable_bluetooth
                  : false,
              disableCamera:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disable_camera)
                  ? policy.policy_settings.disable_camera
                  : false,
              disableUsb:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disable_usb)
                  ? policy.policy_settings.disable_usb
                  : false,
              disableGooglePlay:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disable_google_play)
                  ? policy.policy_settings.disable_google_play
                  : false,
              disableSafeBoot:
                policy.policy_settings &&
                !isNil(policy.policy_settings.safeBootDisabled)
                  ? policy.policy_settings.safeBootDisabled
                  : false,
              disableWifiHotspot:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disable_wifi_hotspot)
                  ? policy.policy_settings.disable_wifi_hotspot
                  : false,
              disableFactoryReset:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disable_factory_reset)
                  ? policy.policy_settings.disable_factory_reset
                  : false,
              disableHardReset:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disable_hard_reset)
                  ? policy.policy_settings.disable_hard_reset
                  : false,
              disableTurnOffWifi:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disable_turning_off_wifi)
                  ? policy.policy_settings.disable_turning_off_wifi
                  : false,
              disableAirplaneMode:
                policy.policy_settings &&
                !isNil(policy.policy_settings.block_airplane_mode)
                  ? policy.policy_settings.block_airplane_mode
                  : false,
            },
          });
          break;
        }
        case policyType.WIFI: {
          const auth =
            policy.policy_settings && !isNil(policy.policy_settings['AUTH'])
              ? policy.policy_settings['AUTH']
              : 'WEP';
          result.wifi.push({
            ...data,
            settings: {
              auth,
              wifiFrequency:
                policy.policy_settings &&
                !isNil(policy.policy_settings['wifi_frequency'])
                  ? policy.policy_settings['wifi_frequency']
                  : '',
              hiddenNetwork:
                policy.policy_settings &&
                !isNil(policy.policy_settings['hidden_network'])
                  ? policy.policy_settings['hidden_network']
                  : false,
              proxy:
                policy.policy_settings &&
                !isNil(policy.policy_settings['proxy'])
                  ? policy.policy_settings['proxy']
                  : proxyConfigTypes.NONE,
              ...(policy.policy_settings?.proxy === proxyConfigTypes.MANUAL
                ? {
                    proxyHostName:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings['proxy_host_name'])
                        ? policy.policy_settings['proxy_host_name']
                        : '',
                    proxyPort:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings['proxy_port'])
                        ? policy.policy_settings['proxy_port']
                        : null,
                    proxyBypassList:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings['proxy_bypass_list']) &&
                      Array.isArray(policy.policy_settings['proxy_bypass_list'])
                        ? policy.policy_settings['proxy_bypass_list']?.join(
                            '\n'
                          )
                        : '',
                  }
                : {}),
              ...(policy.policy_settings?.proxy === proxyConfigTypes.AUTO_CONFIG
                ? {
                    proxyAutoConfigURL:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings['proxy_auto_config_url'])
                        ? policy.policy_settings['proxy_auto_config_url']
                        : '',
                  }
                : {}),
              key:
                policy.policy_settings && !isNil(policy.policy_settings['KEY'])
                  ? policy.policy_settings['KEY']
                  : '',
              ssid:
                policy.policy_settings && !isNil(policy.policy_settings['SSID'])
                  ? policy.policy_settings['SSID']
                  : '',
              certificateId:
                policy.policy_settings &&
                !isNil(policy.policy_settings.certificateId)
                  ? policy.policy_settings.certificateId
                  : undefined,
              filename:
                policy.policy_settings &&
                !isNil(policy.policy_settings.filename)
                  ? policy.policy_settings.filename
                  : undefined,
              lastUpdated:
                policy.policy_settings &&
                !isNil(policy.policy_settings.lastUpdated)
                  ? policy.policy_settings.lastUpdated
                  : undefined,
              ...(auth === wiFiNetworkTypes._802__1X_ENTERPRISE
                ? {
                    acceptedEAPType:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.accepted_EAP_type)
                        ? policy.policy_settings.accepted_EAP_type
                        : undefined,
                    identity:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.identity)
                        ? policy.policy_settings.identity
                        : undefined,
                    password:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.password)
                        ? policy.policy_settings.password
                        : undefined,
                    clientCertificateId:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.client_certificate_id)
                        ? policy.policy_settings.client_certificate_id
                        : undefined,
                    caCertificateName:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.ca_certificate_name)
                        ? policy.policy_settings.ca_certificate_name
                        : undefined,
                    caCertificateId:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.ca_certificate_id)
                        ? policy.policy_settings.ca_certificate_id
                        : undefined,
                    clientCertificateName:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.client_certificate_name)
                        ? policy.policy_settings.client_certificate_name
                        : undefined,
                    caCertificateDomain:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.ca_certificate_domain)
                        ? policy.policy_settings.ca_certificate_domain
                        : undefined,
                    domainSuffixMatch:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.domain_suffix_match)
                        ? policy.policy_settings.domain_suffix_match
                        : undefined,
                    alternateSubjectMatch:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.alternate_subject_match)
                        ? policy.policy_settings.alternate_subject_match
                        : undefined,
                    phase2AuthenticationMethod:
                      policy.policy_settings &&
                      !isNil(
                        policy.policy_settings.phase_2_authentication_method
                      )
                        ? policy.policy_settings.phase_2_authentication_method
                        : undefined,
                    anonymousIdentity:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.anonymous_identity)
                        ? policy.policy_settings.anonymous_identity
                        : undefined,
                    publicLandMobileNetwork:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.public_land_mobile_network)
                        ? policy.policy_settings.public_land_mobile_network
                        : undefined,
                    realm:
                      policy.policy_settings &&
                      !isNil(policy.policy_settings.realm)
                        ? policy.policy_settings.realm
                        : undefined,
                  }
                : {}),
            },
          });
          break;
        }
        case policyType.WEB_FILTER: {
          result.webFilter.push({
            ...data,
            settings: {
              webFilterType: policy.policy_settings.webFilterType,
              urls: policy.policy_settings.urls,
            },
          });
          break;
        }
        case policyType.APP: {
          result.apps.push({
            ...data,
            settings: {
              apps: policy.policy_settings?.apps?.length
                ? policy.policy_settings.apps
                : [],
              apps_v2: policy.policy_settings?.apps_v2?.length
                ? policy.policy_settings.apps_v2
                : [],
              installOverWiFi:
                policy.policy_settings?.install_over_wifi ?? false,
              installOverCellular:
                policy.policy_settings?.install_over_cellular ?? false,
              installOverCellularCondition:
                policy.policy_settings?.install_over_cellular_condition ??
                installOverCellularConditionConstants.NONE,
              installOverLAN: policy.policy_settings?.install_over_lan ?? false,
            },
          });
          break;
        }
        case policyType.APP_LIST: {
          result.applist.push({
            ...data,
            settings: {
              apps:
                policy.policy_settings &&
                policy.policy_settings.apps &&
                policy.policy_settings.apps.length
                  ? policy.policy_settings.apps.map(deserializeApplication)
                  : [],
            },
          });
          break;
        }
        case policyType.CERTIFICATE: {
          result.certificates.push({
            ...data,
            settings: {
              certificates: policy.policy_settings?.certificates?.length
                ? policy.policy_settings.certificates.map(
                    deserializeCertificate
                  )
                : [],
            },
          });
          break;
        }
        case policyType.APN: {
          result.apn.push({
            ...data,
            settings: {
              apnDisplayName: policy.policy_settings?.apnDisplayName ?? '',
              apnName: policy.policy_settings?.apnName ?? '',
              mccCountryISOId: policy.policy_settings?.mccCountryISOId ?? '',
              mobileCountryCode:
                policy.policy_settings?.mobileCountryCode ?? '',
              mobileNetworkCode:
                policy.policy_settings?.mobileNetworkCode ?? '',
              accessPointType: policy.policy_settings?.accessPointType ?? '',
              mvnoType: policy.policy_settings?.mvnoType ?? '',
              // mvnoValue: policy.policy_settings?.mvnoValue ?? '',
              // isDefaultNetwork:
              //   policy.policy_settings?.isDefaultNetwork ?? false,
              accessPointConnectionUsername:
                policy.policy_settings?.accessPointConnectionUsername ?? '',
              accessPointConnectionPassword:
                policy.policy_settings?.accessPointConnectionPassword ?? '',
              // accessPointConnectionHostAddress:
              //   policy.policy_settings?.accessPointConnectionHostAddress ?? '',
              // accessPointConnectionHostPort:
              //   policy.policy_settings?.accessPointConnectionHostPort ?? '',
              proxyHostAddress: policy.policy_settings?.proxyHostAddress ?? '',
              proxyHostPort: policy.policy_settings?.proxyHostPort ?? '',
              mmsProxyHostAddress:
                policy.policy_settings?.mmsProxyHostAddress ?? '',
              mmsHostAddress: policy.policy_settings?.mmsHostAddress ?? '',
              mmsHostPort: policy.policy_settings?.mmsHostPort ?? '',
              authenticationType:
                policy.policy_settings?.authenticationType ?? '',
            },
          });
          break;
        }
        case policyType.LOCATION: {
          result.location.push({
            ...data,
            settings: {
              gpsAlwaysOn:
                policy.policy_settings &&
                !isNil(policy.policy_settings.gpsAlwaysOn)
                  ? policy.policy_settings.gpsAlwaysOn
                  : false,
            },
          });
          break;
        }
        case policyType.KIOSK: {
          result.kiosk.push({
            ...data,
            settings: {
              ...deserializeKioskPolicySettingsCommonData(policy),
            },
          });
          break;
        }
        case policyType.FILE_SYNC: {
          result.fileSync.push({
            ...data,
            settings: {
              direction:
                policy.policy_settings &&
                !isNil(policy.policy_settings.direction)
                  ? policy.policy_settings.direction
                  : 'server_to_device_only',
              devicePath:
                policy.policy_settings &&
                !isNil(policy.policy_settings.device_path)
                  ? policy.policy_settings.device_path
                  : '',
              // serverPath: policy.policy_settings && !isNil(policy.policy_settings.server_path) ? policy.policy_settings.server_path : "",
              // syncWhenDeviceConnect: policy.policy_settings && !isNil(policy.policy_settings.sync_when_device_connect) ? policy.policy_settings.sync_when_device_connect : false,
              // value hard coded at Afzal's request. MVP-1263
              onlyTransmitWhen: 'files_are_different',
              syncSubFolders:
                policy.policy_settings &&
                !isNil(policy.policy_settings.sync_sub_folders)
                  ? policy.policy_settings.sync_sub_folders
                  : false,
              networkRestriction:
                policy.policy_settings &&
                !isNil(policy.policy_settings.network_restriction) &&
                policy.policy_settings.network_restriction.length
                  ? policy.policy_settings.network_restriction[0]
                  : 'wifi',
              syncOnDeviceAdditionAndRelocation:
                policy.policy_settings &&
                !isNil(
                  policy.policy_settings.sync_on_device_addition_and_relocation
                )
                  ? policy.policy_settings
                      .sync_on_device_addition_and_relocation
                  : false,
              useCommonCacheMode:
                policy.policy_settings &&
                !isNil(policy.policy_settings.use_common_cache_mode)
                  ? policy.policy_settings.use_common_cache_mode
                  : false,
              deleteUnmatched:
                policy.policy_settings &&
                !isNil(policy.policy_settings['delete_un-matched'])
                  ? policy.policy_settings['delete_un-matched']
                  : false,
              enableFileSharing:
                policy.policy_settings &&
                !isNil(policy.policy_settings.enable_file_sharing)
                  ? policy.policy_settings.enable_file_sharing
                  : false,
              files:
                policy.policy_settings &&
                !isNil(policy.policy_settings.files) &&
                policy.policy_settings.files.length
                  ? policy.policy_settings.files
                  : [],
              files_v2:
                policy.policy_settings &&
                !isNil(policy.policy_settings.files_v2) &&
                policy.policy_settings.files_v2.length
                  ? policy.policy_settings.files_v2.map(deserializeFile)
                  : [],
            },
          });
          break;
        }
        case policyType.GEOFENCING: {
          result.geofencing.push({
            ...data,
            settings: {
              fence:
                policy.policy_settings && !isNil(policy.policy_settings.fence)
                  ? policy.policy_settings.fence
                  : undefined,
              active:
                policy.policy_settings && !isNil(policy.policy_settings.active)
                  ? policy.policy_settings.active
                  : false,
              move:
                policy.policy_settings && !isNil(policy.policy_settings.move)
                  ? policy.policy_settings.move
                  : false,
              fleet:
                policy.policy_settings && !isNil(policy.policy_settings.fleet)
                  ? policy.policy_settings.fleet
                  : undefined,
            },
          });
          break;
        }
        case policyType.PASSWORD: {
          result.password.push({
            ...data,
            settings: {
              complexCharactersRequired:
                policy.policy_settings &&
                !isNil(policy.policy_settings.complexCharactersRequired)
                  ? `${policy.policy_settings.complexCharactersRequired}`
                  : '',
              minimumLength:
                policy.policy_settings &&
                !isNil(policy.policy_settings.minimumLength)
                  ? `${policy.policy_settings.minimumLength}`
                  : '',
              quality:
                policy.policy_settings && !isNil(policy.policy_settings.quality)
                  ? policy.policy_settings.quality
                  : '',
              passwordEnforcement:
                policy.policy_settings != null
                  ? policy.policy_settings.passwordEnforcement
                  : false,
            },
          });
          break;
        }
        case policyType.SECURITY: {
          result.security.push({
            ...data,
            settings: {
              enableEncryption:
                policy.policy_settings &&
                !isNil(policy.policy_settings.enableEncryption)
                  ? policy.policy_settings.enableEncryption
                  : false,
              enableSecurityCompliance:
                policy.policy_settings &&
                !isNil(policy.policy_settings.enableSecurityCompliance)
                  ? policy.policy_settings.enableSecurityCompliance
                  : false,
            },
          });
          break;
        }
        case policyType.SETTINGS: {
          result.settings.push({
            ...data,
            settings: {
              syncNTP:
                policy.policy_settings && !isNil(policy.policy_settings.syncNTP)
                  ? policy.policy_settings.syncNTP
                  : false,
              useDaylightSavingsTime:
                policy.policy_settings &&
                !isNil(policy.policy_settings.useDaylightSavingsTime)
                  ? policy.policy_settings.useDaylightSavingsTime
                  : false,
              syncTZ:
                policy.policy_settings &&
                policy.policy_settings.syncTZ &&
                policy.policy_settings.syncTZ.length
                  ? policy.policy_settings.syncTZ
                  : undefined,
              deviceLanguage:
                policy.policy_settings &&
                !isNil(policy.policy_settings.deviceLanguage)
                  ? policy.policy_settings.deviceLanguage
                  : undefined,
              deviceVolume:
                policy.policy_settings &&
                !isNil(policy.policy_settings.deviceVolume)
                  ? policy.policy_settings.deviceVolume
                  : 100,
              deviceBrightness:
                policy.policy_settings &&
                !isNil(policy.policy_settings.deviceBrightness)
                  ? policy.policy_settings.deviceBrightness
                  : 100,
              autoRotateScreen:
                policy.policy_settings &&
                !isNil(policy.policy_settings.autoRotateScreen)
                  ? policy.policy_settings.autoRotateScreen
                  : false,
              disableAndroidBeam:
                policy.policy_settings &&
                !isNil(policy.policy_settings.disableAndroidBeam)
                  ? policy.policy_settings.disableAndroidBeam
                  : false,
              downloadFilesOverWifi:
                policy.policy_settings &&
                !isNil(policy.policy_settings.downloadFilesOverWifi)
                  ? policy.policy_settings.downloadFilesOverWifi
                  : false,
              syncDeviceOverWiFi:
                policy.policy_settings &&
                !isNil(policy.policy_settings.syncDeviceOverWiFi)
                  ? policy.policy_settings.syncDeviceOverWiFi
                  : false,
            },
          });
          break;
        }
        case policyType.WALLPAPER_SETTINGS: {
          result.wallpaperSettings.push({
            ...data,
            settings: {
              useHomeScreenWallpaperAsLockScreenWallpaper:
                policy.policy_settings
                  ?.useHomeScreenWallpaperAsLockScreenWallpaper || false,
              homeScreenWallpaperLandscape:
                policy.policy_settings?.homeScreenWallpaperLandscape || null,
              homeScreenWallpaperPortrait:
                policy.policy_settings?.homeScreenWallpaperPortrait || null,
              lockScreenWallpaperLandscape:
                policy.policy_settings?.lockScreenWallpaperLandscape || null,
              lockScreenWallpaperPortrait:
                policy.policy_settings?.lockScreenWallpaperPortrait || null,
            },
          });
          break;
        }
        default:
          break;
      }
    }
  });
  return result;
}

export function serializePolicyData(data) {
  return {
    last_updated: data.lastUpdated,
    policy_data: data.policyData ? data.policyData : null,
    policy_id: data.policyId,
    is_active: data.isActive,
    policy_name:
      data.policyName && data.policyName.length ? data.policyName : 'new',
    policy_type: data.policyType,
  };
}

function serializeKioskPolicySettingsCommonData(policy) {
  const { settings: { autolaunchApp, ...restSettings } = {} } = policy;
  const apps = policy.settings.apps
    ? policy.settings.apps.map(serializeApplication)
    : [];

  const cleanRestSettings = omit(restSettings, ['hideStatusBar']);

  return {
    ...cleanRestSettings,
    apps,
    applications: apps,
    whitelisted: policy.settings.whitelisted
      ? policy.settings.whitelisted.map(serializeApplication)
      : [],
    autolaunch_app: policy.settings.autolaunchApp
      ? serializeApplication(policy.settings.autolaunchApp)
      : undefined,
    autolaunch: !!policy.settings.autolaunchApp,
    backButton:
      policy.settings && !isNil(policy.settings.backButton)
        ? policy.settings.backButton
        : false,
    homeButton:
      policy.settings && !isNil(policy.settings.homeButton)
        ? policy.settings.homeButton
        : false,
    menuButton:
      policy.settings && !isNil(policy.settings.menuButton)
        ? policy.settings.menuButton
        : false,
    // hideStatusBar is mapped to showStatusBar to support backward compatibility, since hideStatusBar was neither added on the backend nor device services side.
    showStatusBar:
      policy.settings && !isNil(policy.settings.hideStatusBar)
        ? !policy.settings.hideStatusBar
        : false,
    kioskBackdoorPwd:
      policy.settings && !isNil(policy.settings.kioskBackdoorPwd)
        ? policy.settings.kioskBackdoorPwd
        : '',
    hasKioskBackdoorPwd:
      policy.settings && !isNil(policy.settings.hasKioskBackdoorPwd)
        ? policy.settings.hasKioskBackdoorPwd
        : true,
    showExitButton:
      policy.settings && !isNil(policy.settings.showExitButton)
        ? policy.settings.showExitButton
        : false,
    onlyKioskNotifications:
      policy.settings &&
      typeof policy.settings.onlyKioskNotifications === 'boolean'
        ? policy.settings.onlyKioskNotifications
        : false,
    hideNavigationBar:
      policy.settings && typeof policy.settings.hideNavigationBar === 'boolean'
        ? policy.settings.hideNavigationBar
        : false,
    useHTMLKioskMode:
      policy.settings && typeof policy.settings.useHTMLKioskMode === 'boolean'
        ? policy.settings.useHTMLKioskMode
        : false,
    htmlKioskFile:
      policy.settings && !isNil(policy.settings.htmlKioskFile)
        ? policy.settings.htmlKioskFile
        : undefined,
    kioskBuilder: policy.isActive,
  };
}

export function serializePolicies(policies) {
  let result = [];
  policies.wifi &&
    policies.wifi.length &&
    policies.wifi.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          KEY: policy.settings.key,
          AUTH: policy.settings.auth,
          SSID: policy.settings.ssid,
          certificate_id: policy.settings.certificateId || null,
          filename: policy.settings.filename,
          wifi_frequency: policy.settings.wifiFrequency,
          hidden_network: policy.settings.hiddenNetwork,
          proxy: policy.settings.proxy ?? proxyConfigTypes.NONE,
          ...(policy.settings?.proxy === proxyConfigTypes.MANUAL
            ? {
                proxy_host_name: policy.settings.proxyHostName || '',
                proxy_port: policy.settings.proxyPort || null,
                proxy_bypass_list: policy.settings.proxyBypassList
                  ? compact(policy.settings.proxyBypassList?.split(/\r?\n/))
                  : null,
              }
            : {}),
          ...(policy.settings?.proxy === proxyConfigTypes.AUTO_CONFIG
            ? {
                proxy_auto_config_url: policy.settings.proxyAutoConfigURL || '',
              }
            : {}),
          last_updated: policy.settings.lastUpdated,
          ...(policy.settings.auth === wiFiNetworkTypes._802__1X_ENTERPRISE
            ? {
                accepted_EAP_type: policy.settings.acceptedEAPType,
                identity: policy.settings.identity,
                password: policy.settings.password,
                client_certificate_id: policy.settings.clientCertificateId,
                client_certificate_name: policy.settings.clientCertificateName,
                ca_certificate_id: policy.settings.caCertificateId,
                ca_certificate_domain: policy.settings.caCertificateDomain,
                ca_certificate_name: policy.settings.caCertificateName,
                domain_suffix_match: policy.settings.domainSuffixMatch,
                alternate_subject_match: policy.settings.alternateSubjectMatch,
                phase_2_authentication_method:
                  policy.settings.phase2AuthenticationMethod,
                anonymous_identity: policy.settings.anonymousIdentity,
                public_land_mobile_network:
                  policy.settings.publicLandMobileNetwork,
                realm: policy.settings.realm,
              }
            : {}),
        },
      })
    );
  policies.authentication &&
    policies.authentication.length &&
    policies.authentication.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          ...policy.settings,
        },
      })
    );
  policies.featureControl &&
    policies.featureControl.length &&
    policies.featureControl.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          allow_installations_from_unknown_sources:
            policy.settings.allowInstallationsFromUnknownSources,
          disable_bluetooth: policy.settings.disableBluetooth,
          disable_camera: policy.settings.disableCamera,
          disable_usb: policy.settings.disableUsb,
          disable_google_play: policy.settings.disableGooglePlay,
          safeBootDisabled: policy.settings.disableSafeBoot,
          disable_wifi_hotspot: policy.settings.disableWifiHotspot,
          disable_factory_reset: policy.settings.disableFactoryReset,
          disable_hard_reset: policy.settings.disableHardReset,
          disable_turning_off_wifi: policy.settings.disableTurnOffWifi,
          block_airplane_mode: policy.settings.disableAirplaneMode,
        },
      })
    );

  policies.webFilter?.length &&
    policies.webFilter.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          webFilterType: policy.settings.webFilterType,
          urls: policy.settings.urls,
        },
      })
    );

  policies.apps &&
    policies.apps.length &&
    policies.apps.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          apps: policy.settings.apps || [],
          apps_v2: policy.settings.apps_v2 || [],
          install_over_wifi: policy.settings.installOverWiFi ?? false,
          install_over_cellular: policy.settings.installOverCellular ?? false,
          install_over_cellular_condition:
            policy.settings.installOverCellularCondition ??
            installOverCellularConditionConstants.NONE,
          install_over_lan: policy.settings.installOverLAN ?? false,
        },
      })
    );
  policies.applist &&
    policies.applist.length &&
    policies.applist.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          apps: policy.settings.apps
            ? policy.settings.apps.map(serializePolicyApplication)
            : [],
        },
      })
    );

  policies.certificates &&
    policies.certificates.length &&
    policies.certificates.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          certificates:
            policy.settings.certificates.map(serializeCertificate) || [],
        },
      })
    );
  policies.apn &&
    policies.apn.length &&
    policies.apn.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          apnDisplayName: policy.settings?.apnDisplayName ?? '',
          apnName: policy.settings?.apnName ?? '',
          mccCountryISOId: policy.settings?.mccCountryISOId ?? '',
          mobileCountryCode: policy.settings?.mobileCountryCode ?? '',
          mobileNetworkCode: policy.settings?.mobileNetworkCode ?? '',
          accessPointType: policy.settings?.accessPointType ?? '',
          mvnoType: policy.settings?.mvnoType ?? '',
          // mvnoValue: policy.settings?.mvnoValue ?? '',
          // isDefaultNetwork: policy.settings?.isDefaultNetwork ?? false,
          accessPointConnectionUsername:
            policy.settings?.accessPointConnectionUsername ?? '',
          accessPointConnectionPassword:
            policy.settings?.accessPointConnectionPassword ?? '',
          // accessPointConnectionHostAddress:
          //   policy.settings?.accessPointConnectionHostAddress ?? '',
          accessPointConnectionHostPort:
            policy.settings?.accessPointConnectionHostPort ?? '',
          proxyHostAddress: policy.settings?.proxyHostAddress ?? '',
          proxyHostPort: policy.settings?.proxyHostPort ?? '',
          mmsProxyHostAddress: policy.settings?.mmsProxyHostAddress ?? '',
          mmsHostAddress: policy.settings?.mmsHostAddress ?? '',
          mmsHostPort: policy.settings?.mmsHostPort ?? '',
          authenticationType: policy.settings?.authenticationType ?? '',
        },
      })
    );
  policies.location &&
    policies.location.length &&
    policies.location.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          ...policy.settings,
        },
      })
    );
  policies.kiosk?.length &&
    policies.kiosk.forEach((policy) => {
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          ...serializeKioskPolicySettingsCommonData(policy),
        },
      });
    });

  policies.fileSync &&
    policies.fileSync.length &&
    policies.fileSync.forEach((policy) =>
      result.push(serializeFileSyncPolicy(policy))
    );
  policies.geofencing &&
    policies.geofencing.length &&
    policies.geofencing.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          ...policy.settings,
          fence: policy.settings.fence || undefined,
          active: policy.settings.active || undefined,
          move: policy.settings.move || undefined,
          fleet: policy.settings.fleet || undefined,
        },
      })
    );
  policies.security &&
    policies.security.length &&
    policies.security.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          enableEncryption: policy.settings.enableEncryption,
          enableSecurityCompliance: policy.settings.enableSecurityCompliance,
        },
      })
    );
  policies.password &&
    policies.password.length &&
    policies.password.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          complexCharactersRequired: !isNaN(
            Number(policy.settings.complexCharactersRequired)
          )
            ? Number(policy.settings.complexCharactersRequired)
            : 0,
          minimumLength: !isNaN(Number(policy.settings.minimumLength))
            ? Number(policy.settings.minimumLength)
            : 0,
          quality: policy.settings.quality,
          passwordEnforcement: policy.isActive,
        },
      })
    );
  policies.settings &&
    policies.settings.length &&
    policies.settings.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          ...policy.settings,
          useDaylightSavingsTime: policy.settings.useDaylightSavingsTime,
          syncTZ:
            policy.settings.syncTZ && policy.settings.syncTZ.length
              ? policy.settings.syncTZ
              : undefined,
          deviceLanguage: policy.settings.deviceLanguage,
          deviceVolume: policy.settings.deviceVolume,
          deviceBrightness: policy.settings.deviceBrightness,
          autoRotateScreen: policy.settings.autoRotateScreen,
          disableAndroidBeam: policy.settings.disableAndroidBeam,
          syncDeviceOverWiFi: policy.settings.syncDeviceOverWiFi,
          downloadFilesOverWifi: policy.settings.downloadFilesOverWifi,
        },
      })
    );
  policies.wallpaperSettings?.length &&
    policies.wallpaperSettings?.forEach((policy) =>
      result.push({
        ...serializePolicyData(policy),
        policy_settings: {
          useHomeScreenWallpaperAsLockScreenWallpaper:
            policy.settings.useHomeScreenWallpaperAsLockScreenWallpaper ??
            false,
          homeScreenWallpaperLandscape:
            policy.settings.homeScreenWallpaperLandscape ?? null,
          homeScreenWallpaperPortrait:
            policy.settings.homeScreenWallpaperPortrait ?? null,
          lockScreenWallpaperLandscape:
            policy.settings.lockScreenWallpaperLandscape ?? null,
          lockScreenWallpaperPortrait:
            policy.settings.lockScreenWallpaperPortrait ?? null,
        },
      })
    );
  return result;
}

export function serializeNewProfile(profile) {
  return {
    name: profile.name,
    fleets: profile.fleets,
    // updated_by_name : profile.updatedByName,
    // updated_by_email: profile.updatedByEmail,
    policies: serializePolicies(profile.policies ? profile.policies : {}),
  };
}

export function serializeProfile(profile) {
  return {
    company_id: profile.companyId,
    devices: profile.devices,
    last_updated: profile.lastUpdated,
    fleets: profile.fleets || [],
    fleet_names: profile.fleetNames || [],
    policies: serializePolicies(profile.policies ? profile.policies : {}),
    profile_id: profile.id,
    profile_name: profile.name,
    datetime_offset: !(
      profile.datetimeOffset !== undefined &&
      (profile.beginDate || profile.endDate)
    )
      ? null
      : profile.beginDate
      ? {
          offset:
            moment.tz
              .zone(profile.datetimeOffset)
              .parse(
                Date.UTC(
                  moment(profile.beginDate, 'YYYY-MM-DD').year(),
                  moment(profile.beginDate, 'YYYY-MM-DD').month(),
                  moment(profile.beginDate, 'YYYY-MM-DD').date(),
                  moment(profile.beginTime, 'hh:mm').hour(),
                  moment(profile.beginTime, 'hh:mm').minute()
                )
              ) * -60,
          timezone: profile.datetimeOffset,
        }
      : {
          offset:
            moment.tz
              .zone(profile.datetimeOffset)
              .parse(
                Date.UTC(
                  moment(profile.endDate, 'YYYY-MM-DD').year(),
                  moment(profile.endDate, 'YYYY-MM-DD').month(),
                  moment(profile.endDate, 'YYYY-MM-DD').date(),
                  moment(profile.endTime, 'hh:mm').hour(),
                  moment(profile.endTime, 'hh:mm').minute()
                )
              ) * -60,
          timezone: profile.datetimeOffset,
        },
    begin_date: profile.beginDate == null ? null : profile.beginDate,
    begin_time: profile.beginTime == null ? null : profile.beginTime,
    end_date: profile.endDate == null ? null : profile.endDate,
    end_time: profile.endTime == null ? null : profile.endTime,

    // new fields added in v2
    updated_at: profile.updatedAt ?? undefined,
    created_at: profile.createdAt ?? undefined,
    // apps: profile.apps ?? undefined,
    // versions: profile.versions ?? undefined,
    draft: profile.draft ?? false,
    published: profile.published ?? false,
    newest_version: profile.newestVersion ?? false,
    previous_version: profile.previousVersion,
    version: profile.version,
    // id: profile.id,
    name: profile.name,
    ...(profile.id ? { id: profile.id } : {}),
    // updated_by_name : profile.updatedByName,
    // updated_by_email: profile.updatedByEmail,
  };
}

export function deserializeProfile(data) {
  return {
    companyId: data.company_id || null,
    devices: data.devices || [],
    fleets: data.fleets || [],
    fleetNames: data.fleet_names || [],
    lastUpdated: data.updated_at || data.last_updated || null,
    policies: deserializePolicies(
      data.policies && data.policies.length ? data.policies : []
    ),
    id: data.profile_id || data.id || null,
    name: data.profile_name || data.name || '',
    datetimeOffset:
      data.datetime_offset == null || !data.datetime_offset.timezone
        ? undefined
        : data.datetime_offset.timezone,
    beginDate: data.begin_date == null ? undefined : data.begin_date,
    beginTime: data.begin_time == null ? undefined : data.begin_time,
    endDate: data.end_date == null ? undefined : data.end_date,
    endTime: data.end_time == null ? undefined : data.end_time,

    // new fields added in v2
    updatedAt: data.updated_at ?? data.last_updated,
    createdAt: data.created_at ?? undefined,
    apps: Array.isArray(data.apps)
      ? data.apps.map(deserializeApplication)
      : undefined,
    versions: notEmpty(data.versions)
      ? data.versions.map(
          ({ id, version, published, draft, newest_version }) => ({
            newestVersion: newest_version,
            id,
            version,
            published,
            draft,
          })
        )
      : [],
    draft: data.draft ?? false,
    published: data.published ?? false,
    newestVersion: data.newest_version ?? false,
    previousVersion: data.previous_version,
    version: data.version,
    updatedByName: data.updated_by_name,
    updatedByEmail: data.updated_by_email,
  };
}

export function serializeFileSyncPolicy(policy) {
  return {
    ...serializePolicyData(policy),
    policy_settings: {
      ...serializeFileSyncPolicySettings(policy.settings),
    },
  };
}

export function serializeFileSyncPolicySettings(settings) {
  return {
    direction: settings.direction,
    device_path: settings.devicePath,
    // server_path: policy.settings.serverPath,
    // sync_when_device_connect: policy.settings.syncWhenDeviceConnect,
    only_transmit_when: settings.onlyTransmitWhen,
    sync_sub_folders: settings.syncSubFolders,
    network_restriction: [settings.networkRestriction],
    sync_on_device_addition_and_relocation:
      settings.syncOnDeviceAdditionAndRelocation,
    files: settings.files && settings.files.length ? settings.files : [],
    files_v2:
      settings.files_v2 && settings.files_v2.length
        ? settings.files_v2.map(serializeFile)
        : [],
    use_common_cache_mode: settings.useCommonCacheMode,
    'delete_un-matched': settings.deleteUnmatched,
    enable_file_sharing: settings.enableFileSharing,
  };
}
