import React from 'react';
import { selectForCurrentBrand } from '../common/helpers';
import StyleUtils from '../utils/styleUtils';

export class TextButton extends React.Component {
  render() {
    const {
      title,
      onClick,
      hint,
      children,
      className,
      style,
      noMargin,
    } = this.props;
    return (
      <div
        className={StyleUtils.mergeModifiers(
          'text-button',
          noMargin && 'no-margin'
        )}
        onClick={onClick}
        title={hint}
        style={style}
      >
        <div
          style={{ width: '18px', height: '18px', marginRight: '3px' }}
          className={`${
            className
              ? className
              : selectForCurrentBrand({
                  original: 'icon--original',
                  incube: 'icon--incube',
                  janam: 'icon--janam',
                })
          }
                    `}
        >
          {children}
        </div>
        {`${'  '} ${title}`}
      </div>
    );
  }
}
