import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { profilesV2Selector } from '../../selectors/profiles';
import DataTable from '../../components/DataTable/DataTable.component';
import {
  newProfileCreationPath,
  profilesColumns,
} from './profilesV2.constants';
import { Pages } from '../../constants/pages';
import ProfilesActions from './components/ProfilesActions';
import { useThunks } from '../../hooks/useThunks';
import { Route } from 'react-router-dom';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import ProfileV2 from '../profile/profileV2.component';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { Translate } from 'react-redux-i18n';
import { Glyphicon } from 'react-bootstrap';
import UserHelpers from '../../common/utilities/user';
import { history } from '../../utils/history';
import { isDataLoadingRequired } from '../../common/helpers';
import { profileV2Reset } from '../profile/profileV2.actions';

export const ProfilesV2 = () => {
  const dispatch = useDispatch();
  const [selectedProfiles, setSelectedProfiles] = useState(new Set());
  const tableRef = useRef(null);

  const resetSelection = () => tableRef.current?.toggleAllRowsSelected?.(false);

  const { data, status, offset, hasMore, nextPageStatus } = useSelector(
    profilesV2Selector
  );
  const hasAdminPrivileges = useSelector(UserHelpers.hasAdminPrivileges);

  const {
    loadProfilesV2,
    cloneProfilesV2,
    deleteProfilesV2,
    unpublishAndClearFleets,
  } = useThunks();
  useEffect(() => {
    if (isDataLoadingRequired(status)) {
      loadProfilesV2({
        isInitialLoad: true,
        showErrorPopup: true,
      });
    }
  }, [status, loadProfilesV2]);

  const loadMoreProfiles = () => {
    if (hasMore) {
      return loadProfilesV2({
        isInitialLoad: false,
        offset,
      });
    }
  };

  const onSelect = useCallback(
    (selectedIds) => setSelectedProfiles(new Set(selectedIds)),
    []
  );

  const renderToolbar = (showToolbar) =>
    showToolbar ? (
      <ProfilesActions
        selectedProfiles={selectedProfiles}
        cloneProfiles={cloneProfilesV2}
        deleteProfiles={deleteProfilesV2}
        resetSelection={resetSelection}
        unpublishAndClearFleets={unpublishAndClearFleets}
        profilesData={data}
      />
    ) : null;

  const handleCreateProfile = () => {
    dispatch(profileV2Reset());
    history.push(newProfileCreationPath);
  };

  const profileCreationButton = () =>
    hasAdminPrivileges ? (
      <ButtonWithIcon
        onClick={handleCreateProfile}
        text={<Translate value="profiles.createProfile" />}
        rightIcon={<Glyphicon glyph="plus" />}
      />
    ) : null;

  const showToolbar = selectedProfiles?.size;
  const contentClassName = showToolbar
    ? 'app__content app__content--with-toolbar'
    : 'app__content';

  return (
    <>
      <section className={contentClassName}>
        <section className="page-content">
          <section className="card">
            <DataTable
              allColumns={profilesColumns}
              data={data}
              loadingStatus={status}
              pageId={Pages.PROFILES}
              onSelect={onSelect}
              tableRef={tableRef}
              selectable
              sortable
              useFilter
              useCSVExport
              useFlex
              useResizableColumns
              allColumnsResizable
              infiniteScrollProps={{
                nextPageLoadingStatus: nextPageStatus,
                loadMore: loadMoreProfiles,
                hasMore,
              }}
              rightCustomHeaderAction={profileCreationButton()}
            />
          </section>
        </section>
      </section>
      {renderToolbar(showToolbar)}
      <Route
        exact
        path={`${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/:childRoute/:subRoute?`}
        component={ProfileV2}
      />
    </>
  );
};
