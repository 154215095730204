import { AttributeType } from '../../common/constants';
import { filterConditions } from '../../constants/filter';

export const UsersLogsTableColumns = [
  // {
  //     key: 'type',
  //     type: AttributeType.TEXT,
  //         titleCode: 'users.userLogs.type',
  //     searchable: true,
  //     availableFilterConditions: [
  //       filterConditions.CONTAINS,
  //       filterConditions.EQUAL
  //     ]
  // },
  {
    key: 'actionTimestamp',
    type: AttributeType.DATE,
    titleCode: 'users.userLogs.date',
    searchable: false,
    sortable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN_OR_EQUAL,
      filterConditions.LESS_THAN_OR_EQUAL,
    ],
  },
  {
    key: 'logDetails',
    type: AttributeType.TEXT,
    titleCode: 'users.userLogs.messageLog',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  {
    key: 'username',
    type: AttributeType.TEXT,
    titleCode: 'users.userLogs.name',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  {
    key: 'ipAddress',
    titleCode: 'users.userLogs.ipAddress',
    type: AttributeType.TEXT,
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
];
