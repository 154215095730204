import { I18n } from 'react-redux-i18n';
import { ReactComponent as MobileArrow } from '../../assets/images/mobile_arrow.svg';
import { ReactComponent as MobileCrestCircle } from '../../assets/images/mobile_crest_circle.svg';
import { ReactComponent as PauseUpdate } from '../../assets/images/pause_update.svg';
import { ReactComponent as ResumeUpdate } from '../../assets/images/resume_update.svg';
import { ReactComponent as ForceCheckin } from '../../assets/images/force_checkin.svg';
import { ReactComponent as RemoteReboot } from '../../assets/images/remote-reboot.svg';
import { ReactComponent as RemoteWipe } from '../../assets/images/remote-wipe.svg';
import { ReactComponent as RemoteLock } from '../../assets/images/remote-lock.svg';
import { ReactComponent as ShieldRefresh } from '../../assets/images/shield_refresh.svg';
import { ReactComponent as ClearFile } from '../../assets/images/clear-file.svg';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import { ReactComponent as UpdateDPC } from '../../assets/images/app-update.svg';
import { ReactComponent as FindDevice } from '../../assets/images/magnifying-lens.svg';
import { ReactComponent as LockSettings } from '../../assets/images/lockSettings.svg';
import { ReactComponent as UnlockSettings } from '../../assets/images/unlockSettings.svg';
import { ReactComponent as LocationTracking } from '../../assets/images/location-tracking.svg';
import { ReactComponent as Mail } from '../../assets/images/mail.svg';
import PopupActions from '../popup/popup.actions';
import { isDevOrQAEnvironment, isDevServer } from '../../common/helpers';
import {
  LockDeviceConfigurationStatuses,
  RemoteCommandsTypes,
} from './devices.constants';

export const getDeviceCommandItems = ({
  hasAdminPrivileges,
  companyId,
  sendRemoteCommand,
  isGlobalFleetAccessReadWriteAccess,
  devices,
  unEnrollDevices,
  removeDevices,
  changeDeviceStatus,
  customConfirmParams,
  hideMoveDeviceTool = false,
  clearSelectionOnSuccessFunction,
  updateListFunction,
}) => {
  const devicesIds = Array.isArray(devices) ? devices : [devices];

  const toolBarItems = [
    {
      titleCode: 'fleets.moveDevicesToolTitle',
      customOnClick: () => {
        customConfirmParams?.toggleMoveDevicesModal?.();
      },
      hidden:
        hideMoveDeviceTool ||
        (!hasAdminPrivileges && !isGlobalFleetAccessReadWriteAccess),
      icon: MobileArrow,
    },
    {
      titleCode: 'fleets.unenrollDevicesToolTitle',
      confirmationTextCode: 'fleets.unEnrollmentConfirmationText',
      customOnConfirm: () => {
        unEnrollDevices(devicesIds, customConfirmParams?.fleetId);
      },
      hidden: !hasAdminPrivileges,
      icon: MobileCrestCircle,
    },
    {
      titleCode: 'fleets.pauseAgentUpdateToolTitle',
      confirmationTextCode: 'fleets.pauseAgentUpdatesConfirmationText',
      command: RemoteCommandsTypes.PAUSE_AGENT_UPDATE,
      hidden: !hasAdminPrivileges,
      icon: PauseUpdate,
    },
    {
      titleCode: 'fleets.resumeAgentUpdateToolTitle',
      confirmationTextCode: 'fleets.resumeAgentUpdatesConfirmationText',
      command: RemoteCommandsTypes.RESUME_AGENT_UPDATE,
      hidden: !hasAdminPrivileges,
      icon: ResumeUpdate,
    },
    ...(isDevServer()
      ? [
          {
            titleCode: 'fleets.updateDPCAppTitle',
            confirmationTextCode: 'fleets.updateDPCAppConfirmationText',
            command: RemoteCommandsTypes.DPC_UPDATE,
            hidden: !hasAdminPrivileges,
            icon: UpdateDPC,
          },
        ]
      : []),
    {
      titleCode: 'fleets.identifyDevicesToolTitle',
      confirmationTextCode: 'fleets.identifyDevicesConfirmationText',
      command: RemoteCommandsTypes.IDENTIFY_DEVICE,
      hidden: !hasAdminPrivileges,
      icon: FindDevice,
    },
    {
      titleCode: 'fleets.increaseLocationTrackingFrequencyToolTitle',
      confirmationTextCode:
        'fleets.increaseLocationTrackingFrequencyConfirmationText',
      command: RemoteCommandsTypes.INCREASE_LOCATION_TRACKING_FREQUENCY,
      hidden: !hasAdminPrivileges,
      icon: LocationTracking,
    },
    {
      titleCode: 'fleets.forceDeviceCheckInToolTitle',
      confirmationTextCode: 'fleets.forceCheckinConfirmationText',
      command: RemoteCommandsTypes.FORCE_CHECKIN,
      hidden: !hasAdminPrivileges,
      icon: ForceCheckin,
    },
    {
      titleCode: 'fleets.remoteRebootDevicesToolTitle',
      confirmationTextCode: 'fleets.rebootDevicesConfirmationText',
      command: RemoteCommandsTypes.REBOOT,
      hidden: !hasAdminPrivileges,
      icon: RemoteReboot,
    },
    {
      titleCode: 'fleets.remoteWipeDevicesToolTitle',
      confirmationTextCode: 'fleets.wipeDevicesPrimaryConfirmationText',
      command: RemoteCommandsTypes.WIPE,
      secondaryCommand: RemoteCommandsTypes.WIPE_SD_CARD,
      confirmationPopupAdditionalParams: {
        secondaryConfirmationParams: {
          text: I18n.t('fleets.wipeDevicesSecondaryConfirmationText'),
        },
      },
      hidden: !hasAdminPrivileges,
      icon: RemoteWipe,
    },
    {
      titleCode: 'fleets.remoteLockDevicesToolTitle',
      confirmationTextCode: 'fleets.lockDevicesConfirmationText',
      command: RemoteCommandsTypes.LOCK,
      hidden: !hasAdminPrivileges,
      icon: RemoteLock,
    },
    {
      titleCode: 'fleets.resetDevicesPasswordToolTitle',
      confirmationTextCode: 'fleets.resetDevicesPasswordConfirmationText',
      command: RemoteCommandsTypes.RESET_DEVICE_PASSWORD,
      hidden: !hasAdminPrivileges,
      icon: ShieldRefresh,
    },
    {
      titleCode: 'fleets.clearApplicationDataAndCacheToolTitle',
      confirmationTextCode: 'fleets.clearDevicesAppDataConfirmationText',
      command: RemoteCommandsTypes.CLEAR_DATA_AND_CACHE,
      hidden: !hasAdminPrivileges,
      icon: ClearFile,
    },
    ...(isDevOrQAEnvironment()
      ? [
          {
            titleCode: 'fleets.sendMessageToolTitle',
            promptTitleCode: 'fleets.sendMessageToolTitle',
            confirmButtonTextCode: 'common.send',
            showPromptUponClick: true,
            promptValueKey: 'message',
            command: RemoteCommandsTypes.SEND_MESSAGE,
            hidden: !hasAdminPrivileges,
            icon: Mail,
          },
        ]
      : []),
    {
      titleCode: 'fleets.lockDeviceConfigurationToolTitle',
      confirmationTextCode: 'fleets.lockDeviceConfigurationConfirmationText',
      customOnConfirm: () => {
        changeDeviceStatus({
          onSuccess: () => {
            clearSelectionOnSuccessFunction?.();
            updateListFunction?.();
          },
          status: LockDeviceConfigurationStatuses.LOCKED,
          devicesIds,
        });
      },
      hidden: !hasAdminPrivileges,
      icon: LockSettings,
    },
    {
      titleCode: 'fleets.unlockDeviceConfigurationToolTitle',
      confirmationTextCode: 'fleets.unlockDeviceConfigurationConfirmationText',
      customOnConfirm: () => {
        changeDeviceStatus({
          onSuccess: () => {
            clearSelectionOnSuccessFunction?.();
            updateListFunction?.();
          },
          status: LockDeviceConfigurationStatuses.ACTIVE,
          devicesIds,
        });
      },
      hidden: !hasAdminPrivileges,
      icon: UnlockSettings,
    },
    {
      titleCode: 'fleets.removeDevicesToolTitle',
      confirmationTextCode: 'fleets.removeDevicesConfirmationText',
      customOnConfirm: () => {
        removeDevices(devicesIds, customConfirmParams?.fleetId);
        customConfirmParams?.onRemoveDevicesConfirm?.();
      },
      hidden: !hasAdminPrivileges,
      icon: TrashEmpty,
    },
  ].filter(({ hidden }) => !hidden);

  return toolBarItems.map(
    ({
      titleCode,
      hidden,
      icon: Icon,
      command,
      secondaryCommand,
      confirmationTextCode,
      customOnConfirm,
      customOnClick,
      showPromptUponClick,
      promptTitleCode,
      promptValueKey,
      confirmButtonTextCode,
      confirmationPopupAdditionalParams = {},
    }) => {
      const defaultClickHandler = () => {
        const sendCommand = (commandDetails) =>
          sendRemoteCommand({
            companyId,
            command,
            devicesIds,
            commandDetails,
            onSuccess: () => {
              clearSelectionOnSuccessFunction?.();
              // updateListFunction?.();
            },
          });

        if (showPromptUponClick) {
          PopupActions.showPrompt({
            title: promptTitleCode ? I18n.t(promptTitleCode) : null,
            id: 'DEVICE_COMMAND_PROMPT',
            useTextArea: true,
            confirmButtonText: confirmButtonTextCode
              ? I18n.t(confirmButtonTextCode)
              : null,
            onConfirm: ({ promptValue }) =>
              sendCommand({
                [promptValueKey ?? 'key']: promptValue,
              }),
          });
        } else {
          PopupActions.showConfirm({
            ...confirmationPopupAdditionalParams,
            id: command,
            onConfirm: ({
              secondaryConfirmationParams: {
                isSecondaryActionConfirmed = false,
              } = {},
            }) => {
              if (customOnConfirm) {
                customOnConfirm();
              } else {
                sendCommand({
                  ...(secondaryCommand
                    ? { [secondaryCommand]: isSecondaryActionConfirmed }
                    : {}),
                });
              }
            },
            text: I18n.t(confirmationTextCode),
            confirmButtonText: confirmButtonTextCode
              ? I18n.t(confirmButtonTextCode)
              : null,
          });
        }
      };

      const onClick = customOnClick ?? defaultClickHandler;

      return {
        title: I18n.t(titleCode),
        onClick,
        hidden,
        key: titleCode,
        icon: Icon,
      };
    }
  );
};
