import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import Profiles from './profiles.component';
import Tasks from '../tasks/tasks.component';
import { ProfilesV2 } from '../profilesV2/profilesV2.component';

const profilesRoutes = (props) => (
  <Switch>
    <Redirect
      exact
      from={RootRoutes.PROFILES}
      to={`${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}`}
    />
    <Route
      exact
      path={`${RootRoutes.PROFILES}${ProfilesRoutes.ALL}/:childRoute?/:subRoute?`}
      component={Profiles}
    />
    <Route
      exact
      path={`${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/:childRoute?/:subRoute?`}
      component={ProfilesV2}
    />
    <Route
      exact
      path={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/:childRoute?/:subRoute?`}
      component={Tasks}
    />
    <Route component={NotFound} />
  </Switch>
);

export default profilesRoutes;
