import { I18n } from 'react-redux-i18n';
import client from '../../utils/client';
import services from '../../utils/services';
import {
  loadSSOSAMLSettingFailed,
  loadSSOSAMLSettingStarted,
  loadSSOSAMLSettingSuccess,
  loadTimezonesFailed,
  loadTimezonesStarted,
  loadTimezonesSuccess,
} from './settings.actions';
import { deserializeSSOSAMLSettings } from './settings.serializer';

export function loadTimezones() {
  return async (dispatch) => {
    try {
      dispatch(loadTimezonesStarted());
      let { status, data } = await client.get(services.timezones);
      if (status === 200 && data) {
        dispatch(loadTimezonesSuccess(data));
      } else {
        dispatch(loadTimezonesFailed(I18n.t('settings.failedToLoadTimezones')));
      }
    } catch (error) {
      dispatch(
        loadTimezonesFailed(new Error(I18n.t('settings.failedToLoadTimezones')))
      );
    }
  };
}

export const getSSOSAMLSettings = ({ companyId: company, provider }) => {
  return async (dispatch) => {
    try {
      dispatch(loadSSOSAMLSettingStarted());
      let { status, data } = await client.get(
        services.SSOAzureSAML2LinkUserAccountSettings,
        {
          params: {
            provider,
            company,
          },
        }
      );
      if (status === 200 && data) {
        dispatch(
          loadSSOSAMLSettingSuccess({
            data: deserializeSSOSAMLSettings(data),
            isAzureAdSAMLAlreadySet: Boolean(data.id),
          })
        );
      } else {
        dispatch(
          loadSSOSAMLSettingFailed(
            I18n.t('settings.failedToGetSSOSAMLSettings')
          )
        );
      }
    } catch (error) {}
  };
};
