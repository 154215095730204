import React, { FC } from 'react';
import StyleUtils from '../../utils/styleUtils';

interface PageContainerProps {
  isToolbarVisible?: boolean;
}
const PageContainer: FC<PageContainerProps> = ({
  isToolbarVisible,
  children,
}) => {
  const contentClassName = StyleUtils.mergeModifiers(
    'app__content',
    isToolbarVisible && 'with-toolbar'
  );

  return (
    <section className={contentClassName}>
      <section className="page-content">{children}</section>
    </section>
  );
};

export default PageContainer;
