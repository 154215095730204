import React from 'react';
import { Translate } from 'react-redux-i18n';
import { AlertStatusTypes } from '../alerts.constants';

const AlertStatus = ({ item: { status } = {} }) => {
  const alertStatus = status
    ? AlertStatusTypes.ACTIVE
    : AlertStatusTypes.INACTIVE;
  return (
    <Translate
      value={`alerts.${alertStatus}`}
      className={`alert-row__status--${alertStatus}`}
    />
  );
};
export default AlertStatus;
