import { en_us } from './en_us';
import { zh_cn } from './zh_cn';
import { zh_ } from './zh_';
import { fr_fr } from './fr_fr';

export const translations = {
  en_us,
  zh_cn,
  zh_,
  fr_fr,
};

export const locales = {
  EN_US: 'en_us',
  // simplified Chinese
  ZH_CN: 'zh_cn',
  // traditional Chinese
  ZH_: 'zh_',
  FR_FR: 'fr_fr',
};

export const localizationLocales = {
  [locales.EN_US]: {
    code: locales.EN_US,
    name: 'English',
    momentCode: 'en',
  },
  [locales.ZH_CN]: {
    code: locales.ZH_CN,
    name: '中文（简）',
    momentCode: 'zh-cn',
  },
  [locales.ZH_]: {
    code: locales.ZH_,
    name: '中文(繁）',
    momentCode: 'zh-tw',
  },
  [locales.FR_FR]: {
    code: locales.FR_FR,
    name: 'Française',
    momentCode: 'fr',
  },
};
