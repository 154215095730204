export const USERS_LOGS_LOAD_STARTED = 'USERS_LOGS_LOAD_STARTED';
export const USERS_LOGS_LOAD_FAILED = 'USERS_LOGS_LOAD_FAILED';
export const USERS_LOGS_LOAD_SUCCESS = 'USERS_LOGS_LOAD_SUCCESS';

export function loadUsersLogsStart(isInitialLoad) {
  return {
    type: USERS_LOGS_LOAD_STARTED,
    payload: { isInitialLoad },
  };
}

export function loadUsersLogsFailed(payload) {
  return {
    type: USERS_LOGS_LOAD_FAILED,
    payload,
  };
}

export function loadUsersLogsSuccess(payload) {
  return {
    type: USERS_LOGS_LOAD_SUCCESS,
    payload,
  };
}
