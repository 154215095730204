import { NetworkStatus } from '../../common/constants';
import {
  USERS_DELETE_FAILED,
  USERS_DELETE_RESET,
  USERS_DELETE_STARTED,
  USERS_DELETE_SUCCESS,
  USERS_LOAD_FAILED,
  USERS_LOAD_STARTED,
  USERS_LOAD_SUCCESS,
  USERS_MAKE_INACTIVE_FAILED,
  USERS_MAKE_INACTIVE_RESET,
  USERS_MAKE_INACTIVE_STARTED,
  USERS_MAKE_INACTIVE_SUCCESS,
  USERS_MOVE_FAILED,
  USERS_MOVE_RESET,
  USERS_MOVE_STARTED,
  USERS_MOVE_SUCCESS,
} from './users.action';
import {
  USER_GROUP_PERMISSIONS_LOAD_FAILED,
  USER_GROUP_PERMISSIONS_LOAD_STARTED,
  USER_GROUP_PERMISSIONS_LOAD_SUCCESS,
} from './users.actionTypes';

export default function users(
  state = {
    status: NetworkStatus.NONE,
    error: undefined,
    data: [],

    deleteStatus: NetworkStatus.NONE,
    deleteError: undefined,

    moveStatus: NetworkStatus.NONE,
    moveError: undefined,

    makeUsersInactiveStatus: NetworkStatus.NONE,
    makeUsersInactiveError: undefined,

    userGroupPermissionsData: {},
    userGroupPermissionsStatus: NetworkStatus.NONE,
    userGroupPermissionsError: undefined,
  },
  action
) {
  switch (action.type) {
    case USERS_LOAD_STARTED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case USERS_LOAD_FAILED:
      return Object.assign({}, state, {
        data: [],
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case USERS_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    case USERS_DELETE_STARTED:
      return Object.assign({}, state, {
        ...state,
        deleteStatus: NetworkStatus.STARTED,
        deleteError: undefined,
      });
    case USERS_DELETE_FAILED:
      return Object.assign({}, state, {
        ...state,
        deleteStatus: NetworkStatus.ERROR,
        deleteError: action.payload,
      });
    case USERS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        deleteStatus: NetworkStatus.DONE,
        deleteError: undefined,
      });
    case USERS_DELETE_RESET:
      return Object.assign({}, state, {
        ...state,
        deleteStatus: NetworkStatus.NONE,
        deleteError: undefined,
      });
    case USERS_MOVE_STARTED:
      return Object.assign({}, state, {
        ...state,
        moveStatus: NetworkStatus.STARTED,
        moveError: undefined,
      });
    case USERS_MOVE_FAILED:
      return Object.assign({}, state, {
        ...state,
        moveStatus: NetworkStatus.ERROR,
        moveError: action.payload,
      });
    case USERS_MOVE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        moveStatus: NetworkStatus.DONE,
        moveError: undefined,
      });
    case USERS_MOVE_RESET:
      return Object.assign({}, state, {
        ...state,
        moveStatus: NetworkStatus.NONE,
        moveError: undefined,
      });
    case USERS_MAKE_INACTIVE_STARTED:
      return {
        ...state,
        makeUsersInactiveStatus: NetworkStatus.STARTED,
        makeUsersInactiveError: undefined,
      };
    case USERS_MAKE_INACTIVE_FAILED:
      return {
        ...state,
        makeUsersInactiveStatus: NetworkStatus.ERROR,
        makeUsersInactiveError: action.payload,
      };
    case USERS_MAKE_INACTIVE_SUCCESS:
      return {
        ...state,
        makeUsersInactiveStatus: NetworkStatus.DONE,
        makeUsersInactiveError: undefined,
      };
    case USERS_MAKE_INACTIVE_RESET:
      return {
        ...state,
        makeUsersInactiveStatus: NetworkStatus.NONE,
        makeUsersInactiveError: undefined,
      };
    case USER_GROUP_PERMISSIONS_LOAD_STARTED:
      return {
        ...state,
        userGroupPermissionsStatus: NetworkStatus.STARTED,
        userGroupPermissionsError: undefined,
      };
    case USER_GROUP_PERMISSIONS_LOAD_FAILED:
      return {
        ...state,
        userGroupPermissionsData: {},
        userGroupPermissionsStatus: NetworkStatus.ERROR,
        userGroupPermissionsError: action.payload,
      };
    case USER_GROUP_PERMISSIONS_LOAD_SUCCESS:
      return {
        ...state,
        userGroupPermissionsData: action.payload,
        userGroupPermissionsStatus: NetworkStatus.DONE,
        userGroupPermissionsError: undefined,
      };
    default:
      return state;
  }
}
