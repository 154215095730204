import { v4 as uuid } from 'uuid';
import { I18n } from 'react-redux-i18n';
import { AttributeType } from '../../common/constants';
import { RootRoutes, UsersRoutes } from '../../utils/routes';
import { filterConditions } from '../../constants/filter';

export const getUsersTableColumns = ({
  isUsernameLinkEnabled = false,
  isUserGroupShown = false,
}) => [
  {
    key: 'username',
    type: AttributeType.TEXT,
    titleCode: 'users.fields.email',
    refId: 'username',
    ref: isUsernameLinkEnabled
      ? `${RootRoutes.USERS}${UsersRoutes.GROUPS}/all/users`
      : '',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  // {
  //     key: 'email',
  //     type: AttributeType.TEXT,
  //     titleCode: 'users.fields.email',
  //     searchable: true,
  //     availableFilterConditions: [
  //        filterConditions.CONTAINS,
  //        filterConditions.EQUAL
  //     ]
  // },
  {
    key: 'firstName',
    type: AttributeType.TEXT,
    titleCode: 'users.fields.firstName',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  {
    key: 'lastName',
    type: AttributeType.TEXT,
    titleCode: 'users.fields.lastName',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  // {
  //     key: 'groupId',
  //     type: AttributeType.TEXT,
  //     titleCode: 'Group',
  //     searchable: true,
  //     renderer: UsersGroupCell,
  // },
  {
    key: 'status',
    type: AttributeType.ENUMERABLE,
    titleCode: 'users.fields.status',
    searchable: true,
    availableFilterConditions: [filterConditions.EQUAL],
    availableOptions: [
      {
        title: I18n.t('users.fields.active'),
        value: 'Active',
      },
      {
        title: I18n.t('users.fields.inactive'),
        value: 'Inactive',
      },
    ],
  },
  {
    key: 'lastLogin',
    type: AttributeType.DATE,
    titleCode: 'users.fields.lastLoginTitle',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'createdOn',
    type: AttributeType.DATE,
    titleCode: 'users.fields.creationDateTitle',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'createdBy',
    type: AttributeType.TEXT,
    titleCode: 'users.fields.creatorTitle',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  ...(isUserGroupShown
    ? [
        {
          key: 'groupName',
          type: AttributeType.TEXT,
          titleCode: 'users.groupName',
          searchable: true,
          availableFilterConditions: [
            filterConditions.CONTAINS,
            filterConditions.EQUAL,
          ],
        },
      ]
    : []),
];
export const groupsColumn = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'AVAILABLE GROUPS',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  {
    key: 'dateCreated',
    type: AttributeType.DATE,
    titleCode: 'DATE CREATED',
    searchable: true,
    availableFilterConditions: [filterConditions.EQUAL],
  },
];

// Mock data
export const groups = [
  {
    id: '5626fada-c8fa-4ffc-9d2c-62b1a7d16d91',
    name: 'Owners',
    dateCreated: new Date(2015, 11, 2).valueOf(),
  },
  {
    id: 'acf178d0-4e87-4493-befe-9d6b04ff7f90',
    name: 'Administrators',
    dateCreated: new Date(2015, 11, 2).valueOf(),
  },
  {
    id: 'f36a76a4-bc1a-4df6-841e-14bf25f85a8b',
    name: 'Technicians',
    dateCreated: new Date(2016, 6, 12).valueOf(),
  },
  {
    id: '87a7c098-7dcc-4615-bfaf-33af46df573f',
    name: 'Users',
    dateCreated: new Date(2015, 11, 3).valueOf(),
  },
];

export const users = [
  {
    id: uuid(),
    name: 'John',
    email: 'john@mail.com',
    groupId: groups[3].id,
    devices: '6',
    status: 'Active',
    lastActive: new Date(2016, 1, 3).valueOf(),
  },
  {
    id: uuid(),
    name: 'Bill',
    email: 'bill@mail.com',
    groupId: '',
    devices: '2',
    status: 'Inactive',
    lastActive: new Date(2016, 1, 4).valueOf(),
  },
  {
    id: uuid(),
    name: 'Mike',
    email: 'mike@mail.com',
    groupId: groups[3].id,
    devices: '42',
    status: 'Inactive',
    lastActive: new Date(2016, 1, 4).valueOf(),
  },
  {
    id: uuid(),
    name: 'Ash',
    email: 'ash@mail.com',
    groupId: groups[3].id,
    devices: '10',
    status: 'Active',
    lastActive: new Date(2016, 1, 6).valueOf(),
  },
  {
    id: uuid(),
    name: 'Paul',
    email: 'paul@mail.com',
    groupId: groups[2].id,
    devices: '0',
    status: 'Active',
    lastActive: new Date(2016, 1, 5).valueOf(),
  },
  {
    id: uuid(),
    name: 'Andre',
    email: 'andre@mail.com',
    groupId: groups[2].id,
    devices: '41',
    status: 'Inactive',
    lastActive: new Date(2016, 1, 23).valueOf(),
  },
  {
    id: uuid(),
    name: 'Ryan',
    email: 'ryan@mail.com',
    groupId: groups[1].id,
    devices: '1',
    status: 'Active',
    lastActive: new Date(2016, 2, 23).valueOf(),
  },
  {
    id: uuid(),
    name: 'Steve',
    email: 'steve@mail.com',
    groupId: groups[0].id,
    devices: '123',
    status: 'Active',
    lastActive: new Date(2016, 2, 28).valueOf(),
  },
];
export const usersLogs = [
  {
    id: uuid(),
    userId: users[0].id,
    groupId: users[0].groupId,
    userName: users[0].name,
    date: new Date(2016, 1, 3).valueOf(),
    type: 'Info',
    message: 'User created',
  },
  {
    id: uuid(),
    userId: users[1].id,
    groupId: users[1].groupId,
    userName: users[1].name,
    date: new Date(2016, 1, 4).valueOf(),
    type: 'Info',
    message: 'User created',
  },
  {
    id: uuid(),
    userId: users[2].id,
    groupId: users[2].groupId,
    userName: users[2].name,
    date: new Date(2016, 1, 4).valueOf(),
    type: 'Info',
    message: 'User created',
  },
  {
    id: uuid(),
    userId: users[3].id,
    groupId: users[3].groupId,
    userName: users[3].name,
    date: new Date(2016, 1, 6).valueOf(),
    type: 'Info',
    message: 'User created',
  },
  {
    id: uuid(),
    userId: users[4].id,
    groupId: users[4].groupId,
    userName: users[4].name,
    date: new Date(2016, 1, 5).valueOf(),
    type: 'Info',
    message: 'User created',
  },
  {
    id: uuid(),
    userId: users[5].id,
    groupId: users[5].groupId,
    userName: users[5].name,
    date: new Date(2016, 1, 23).valueOf(),
    type: 'Info',
    message: 'User created',
  },
  {
    id: uuid(),
    userId: users[6].id,
    groupId: users[6].groupId,
    userName: users[6].name,
    date: new Date(2016, 2, 23).valueOf(),
    type: 'Info',
    message: 'User created',
  },
  {
    id: uuid(),
    userId: users[7].id,
    groupId: users[7].groupId,
    userName: users[7].name,
    date: new Date(2016, 2, 28).valueOf(),
    type: 'Info',
    message: 'User created',
  },
];
