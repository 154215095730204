import React from 'react';
import { selectForCurrentBrand } from '../common/helpers';

export const Throbber = () => {
  const classNames = selectForCurrentBrand({
    original: 'throbber__background',
    incube: 'throbber__background throbber__background--incube',
    janam: 'throbber__background throbber__background--janam',
  });
  return (
    <div className={'throbber'}>
      <div className={classNames}></div>
    </div>
  );
};
