import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect } from 'react-router-dom';
import EntityCreationWizard from '../../components/EntityCreationWizard/entityCreationWizard.component';
import { RootRoutes, AlertRoutes } from '../../utils/routes';
import {
  AlertWizardRoutes,
  AlertWizardTitles,
  communicationChannels,
  CreateAlertRoute,
} from './createAlert.constants';
import { companyIdSelector } from '../login/login.selectors';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { NetworkStatus } from '../../common/constants';
import { history } from '../../utils/history';
import PopupActions from '../popup/popup.actions';
import {
  changeAlertProperty,
  resetAlertCreation,
  setNewAlertDefaultValues,
} from '../alerts/alerts.actions';
import ChooseFleetsStep from './steps/chooseFleetsStep.component';
import AlertTypeStep from './steps/alertTypeStep.component';
import AlertConditionStep from './steps/alertConditionStep.component';
import AlertChannelStep from './steps/alertChannelStep.component';
import AlertPreviewAndSave from './steps/alertPreviewAndSave.component';
import AlertCreated from './steps/alertCreated.component';
import { AlertTypeTypes } from '../alerts/alerts.constants';
import { loadAllFleetsV2 } from '../fleets/fleetsV2.thunk';
import { fleetsStatusSelector } from '../../selectors/fleetsSelector';
import { DataConstants } from '../../constants/data';
import AsyncRendererWithDataFetching from '../../components/AsyncRenderer/AsyncRendererWithDataFetching.component';
import { loadGroups } from '../group/groups.thunk';

const steps = [
  {
    routeProps: {
      path: `${CreateAlertRoute}${AlertWizardRoutes.FLEET}`,
      component: ChooseFleetsStep,
      exact: true,
    },
    stepRoute: AlertWizardRoutes.FLEET,
  },
  {
    routeProps: {
      path: `${CreateAlertRoute}${AlertWizardRoutes.ALERT_TYPE}`,
      component: AlertTypeStep,
      exact: true,
    },
    stepRoute: AlertWizardRoutes.ALERT_TYPE,
  },
  {
    routeProps: {
      path: `${CreateAlertRoute}${AlertWizardRoutes.CONDITION}`,
      component: AlertConditionStep,
      exact: true,
    },
    stepRoute: AlertWizardRoutes.CONDITION,
  },
  {
    routeProps: {
      path: `${CreateAlertRoute}${AlertWizardRoutes.COMMUNICATION_CHANNEL}`,
      component: AlertChannelStep,
      exact: true,
    },
    stepRoute: AlertWizardRoutes.COMMUNICATION_CHANNEL,
  },
  {
    routeProps: {
      path: `${CreateAlertRoute}${AlertWizardRoutes.REVIEW}`,
      component: AlertPreviewAndSave,
      exact: true,
    },
    stepRoute: AlertWizardRoutes.REVIEW,
  },
  {
    routeProps: {
      path: `${CreateAlertRoute}${AlertWizardRoutes.DONE}`,
      component: AlertCreated,
      exact: true,
    },
    stepRoute: AlertWizardRoutes.DONE,
  },
];

class CreateAlert extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.createAlertStatus === NetworkStatus.STARTED &&
      this.props.createAlertStatus === NetworkStatus.ERROR
    ) {
      this.onError();
    }
  }

  componentWillUnmount() {
    this.resetAlertCreation();
  }

  resetAlertCreation = () => this.props.resetAlertCreation();

  render() {
    const { createAlertStatus, changeAlertProperty } = this.props;
    const step = RouteHelpers.getUrlParameter(this.props, 'step');

    if (
      createAlertStatus === NetworkStatus.DONE &&
      `/${step}` !== AlertWizardRoutes.DONE
    ) {
      return <Redirect to={`${CreateAlertRoute}${AlertWizardRoutes.DONE}`} />;
    }

    return (
      <AsyncRendererWithDataFetching
        dataList={[
          {
            id: DataConstants.ALL_COMPANY_FLEETS_V2,
          },
        ]}
        customDataStatus={{
          [DataConstants.USER_GROUPS]: this.props.userGroupsStatus,
        }}
        customDataError={{
          [DataConstants.USER_GROUPS]: this.props.userGroupsError,
        }}
        customDataFetchFunctions={{
          [DataConstants.USER_GROUPS]: this.props.loadGroups,
        }}
      >
        <EntityCreationWizard
          backArrowText={I18n.t('alerts.alertDashboard')}
          backArrowRoute={`${RootRoutes.ALERTS}${AlertRoutes.ALERTS}`}
          initialScreenInputFieldLabel={I18n.t('alerts.createAnAlert')}
          initialScreenInputFieldLabelPlaceholder={I18n.t(
            'alerts.nameThisAlert'
          )}
          entity={this.props.newAlert}
          onProceedClick={this.onProceedClick}
          handleCreationPropertyChange={changeAlertProperty}
          resetEntityCreation={this.resetAlertCreation}
          creationStepTitles={AlertWizardTitles}
          currentStep={step}
          entityCreationStatus={createAlertStatus}
          entityCreationRoute={CreateAlertRoute}
          steps={steps}
        />
      </AsyncRendererWithDataFetching>
    );
  }

  onProceedClick = () => {
    const { setNewAlertDefaultValues } = this.props;
    let path = `${CreateAlertRoute}${AlertWizardRoutes.FLEET}`;
    if (this.props.newAlert.fleetId) {
      path = `${CreateAlertRoute}${AlertWizardRoutes.REVIEW}`;
    } else {
      setNewAlertDefaultValues({
        alertType: AlertTypeTypes.OUT_OF_CONTACT,
        alertChannel: {
          communicationChannel: communicationChannels.USER_GROUPS,
        },
      });
    }
    history.push(path);
  };

  onError = () =>
    PopupActions.showAlert({
      text: this.props.createAlertError || I18n.t('common.somethingWentWrong'),
    });
}

const mapStateToProps = (state) => ({
  newAlert: state.alerts.newAlert,
  createAlertStatus: state.alerts.createAlertStatus,
  createAlertError: state.alerts.createAlertError,
  companyId: companyIdSelector(state),
  fleetsStatus: fleetsStatusSelector(state),
  userGroupsStatus: state.groups.userGroupsStatus,
  userGroupsError: state.groups.userGroupsError,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllFleets: () => dispatch(loadAllFleetsV2()),
  resetAlertCreation: () => dispatch(resetAlertCreation()),
  setNewAlertDefaultValues: (defaultValues) =>
    dispatch(setNewAlertDefaultValues(defaultValues)),
  changeAlertProperty: ({ key, value }) =>
    dispatch(changeAlertProperty({ key, value })),
  loadGroups: () => dispatch(loadGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAlert);
