import { I18n } from 'react-redux-i18n';

export const wiFiNetworkFrequencyTypes = {
  TWO_POINT_FOUR: '2.4',
  FIVE_POINT_ZERO: '5.0',
};

export const wiFiNetworkFrequencyOptions = [
  { value: '0.0', title: 'Auto' },
  { value: wiFiNetworkFrequencyTypes.TWO_POINT_FOUR, title: '2.4 GHz' },
  { value: wiFiNetworkFrequencyTypes.FIVE_POINT_ZERO, title: '5.0 GHz' },
];

export const proxyConfigTypes = {
  NONE: 'none',
  MANUAL: 'manual',
  AUTO_CONFIG: 'auto-config',
};

export const proxyOptions = [
  {
    value: proxyConfigTypes.NONE,
    title: I18n.t('profiles.wifi.proxyConfigTypeNone'),
  },
  {
    value: proxyConfigTypes.MANUAL,
    title: I18n.t('profiles.wifi.proxyConfigTypeManual'),
  },
  {
    value: proxyConfigTypes.AUTO_CONFIG,
    title: I18n.t('profiles.wifi.proxyConfigTypeAutoConfig'),
  },
];

export const wiFiNetworkTypes = {
  WEP: 'wep',
  WPA2_PSK: 'wpa2-psk',
  WPA2_ENTERPRISE: 'wpa2-enterprise',
  _802__1X_ENTERPRISE: '802-1x-enterprise',
};

export const EAPTypes = {
  PEAP: 'peap',
  TLS: 'tls',
  TTLS: 'ttls',
  PWD: 'pwd',
};

export const phase2AuthenticationMethods = {
  PAP: 'pap',
  MSCHAP: 'mschap',
  MSCHAPV2: 'mschapv2',
  GTC: 'gtc',
};

export const acceptedEAPTypeOptions = [
  { value: '', title: '' },
  { value: EAPTypes.PEAP, title: 'PEAP' },
  { value: EAPTypes.TLS, title: 'TLS' },
  { value: EAPTypes.TTLS, title: 'TTLS' },
  { value: EAPTypes.PWD, title: 'PWD' },
];

export const securitySettingsOptions = [
  { value: wiFiNetworkTypes.WEP, title: 'WEP' },
  { value: wiFiNetworkTypes.WPA2_PSK, title: 'WPA-PSK/WPA2-PSK' },
  { value: wiFiNetworkTypes.WPA2_ENTERPRISE, title: 'WPA/WPA2 Enterprise' },
  { value: wiFiNetworkTypes._802__1X_ENTERPRISE, title: '802.1x Enterprise' },
];

export const phase2AuthenticationMethodOptions = [
  { value: '', title: '' },
  { value: phase2AuthenticationMethods.PAP, title: 'PAP' },
  { value: phase2AuthenticationMethods.MSCHAP, title: 'MSCHAP' },
  { value: phase2AuthenticationMethods.MSCHAPV2, title: 'MSCHAPV2' },
  { value: phase2AuthenticationMethods.GTC, title: 'GTC' },
];

export const allowedStagingWiFiTypes = [
  wiFiNetworkTypes.WEP,
  wiFiNetworkTypes.WPA2_PSK,
  wiFiNetworkTypes.WPA2_ENTERPRISE,
];

export const stagingWiFiTypeOptions = securitySettingsOptions.filter(
  ({ value }) => allowedStagingWiFiTypes.includes(value)
);
