import React, { Fragment, Component } from 'react';
import { NavLink } from 'react-router-dom';
import { UncontrolledCollapse } from 'reactstrap';
import { withRouter } from 'react-router';
import { selectForCurrentBrand } from '../../common/helpers';
import { Throbber } from '../Throbber';

class DialogMenu extends Component {
  render() {
    const { isLoading } = this.props;
    if (isLoading) {
      return <Throbber />;
    }
    return <Fragment>{this.props.structure.map(this.renderMenuItem)}</Fragment>;
  }

  renderMenuItem = (item) => {
    if (item.hidden) {
      return null;
    }
    const hasSubItems = item.sub && item.sub.length;
    // const itemClass = ClassNames(
    // 'profile-menu__item',
    //
    //     {
    //         'profile-menu__item--active': this.state.selectedCategory === item.key,
    //         'profile-menu__item--indeterminate': hasSubItems && item.sub.some(subItem => subItem.key === this.state.selectedCategory),
    //     }
    // );
    // const clickHandler = (e) => this.selectCategory(hasSubItems ? item.sub[0].key : item.key);
    const route = hasSubItems ? item.sub[0].route : item.route;
    const isActive = hasSubItems ? item.sub[0].isActive : item.isActive;
    const itemClass = selectForCurrentBrand({
      original: 'profile-menu__item',
      incube: 'profile-menu__item--incube',
      janam: 'profile-menu__item--janam',
    });
    const activeItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--active',
      incube: 'profile-menu__item--incube--active',
      janam: 'profile-menu__item--janam--active',
    });
    return (
      <Fragment key={item.key}>
        <NavLink
          to={route}
          className={itemClass}
          activeClassName={activeItemClass}
          activeStyle={{ textDecoration: 'none' }}
          exact={item.exact}
          {...(isActive ? { isActive } : {})}
        >
          <h4
          // id={hasSubItems ? `${item.key.replace(/\s/, '_')}` : undefined}
          // onClick={clickHandler}
          >
            {item.title ? item.title : item.key}
          </h4>
        </NavLink>
        {hasSubItems && (
          <div style={{ marginLeft: '10px' }}>
            <UncontrolledCollapse toggler={`${item.key.replace(/\s/, '_')}`}>
              {item.sub.map((item) => this.renderMenuItem(item))}
            </UncontrolledCollapse>
          </div>
        )}
      </Fragment>
    );
  };
}

export default withRouter(DialogMenu);
