import { v4 as uuid } from 'uuid';
import { I18n } from 'react-redux-i18n';
import { dispatch } from '../../store/store';
import PopupActionTypes from './popup.actionTypes';
import PopupConstants, { DEFAULT_LOADER_POPUP_ID } from './popup.constants';

const showConfirm = (params = {}) => {
  return new Promise((resolve, reject) =>
    dispatch({
      type: PopupActionTypes.SHOW_POPUP,
      payload: {
        type: PopupConstants.CONFIRM,
        id: params.id || uuid(),
        title: params.title || I18n.t('common.confirmationDefaultTitle'),
        text: params.text || I18n.t('common.confirmationDefaultText'),
        textList: params.textList,
        textType: params.textType,
        priority: params.priority,
        confirmButtonText: params.confirmButtonText || I18n.t('common.yes'),
        cancelButtonText: params.cancelButtonText || I18n.t('common.no'),
        secondaryConfirmationParams: params.secondaryConfirmationParams,
        onConfirm: (confirmationParams) => {
          resolve(true);
          if (params.onConfirm) {
            params.onConfirm(confirmationParams);
          }
        },
        onCancel: () => {
          params.onCancel && params.onCancel();
          reject();
        },
      },
    })
  );
};

const showAlert = (params = {}) => {
  return new Promise((resolve, reject) =>
    dispatch({
      type: PopupActionTypes.SHOW_POPUP,
      payload: {
        type: PopupConstants.ALERT,
        id: params.id || uuid(),
        title: params.title || I18n.t('common.alertDefaultTitle'),
        text: params.text,
        textType: params.textType,
        textList: params.textList,
        priority: params.priority,
        closeButtonText: params.closeButtonText || I18n.t('common.ok'),
        onClose: () => {
          params.onClose?.();
          reject();
        },
      },
    })
  );
};

const showOptions = (params = {}) => {
  return new Promise((resolve, reject) =>
    dispatch({
      type: PopupActionTypes.SHOW_POPUP,
      payload: {
        type: PopupConstants.OPTIONS,
        id: params.id || uuid(),
        title: params.title || I18n.t('common.optionModalDefaultTitle'),
        text: params.text || I18n.t('common.optionModalDefaultText'),
        textList: params.textList,
        options: params.options,
        optionsListParams: params.optionsListParams,
        selectMultiple: params.selectMultiple,
        priority: params.priority,
        proceedButtonText: params.proceedButtonText || I18n.t('common.select'),
        cancelButtonText: params.cancelButtonText || I18n.t('common.cancel'),
        secondaryConfirmationParams: params.secondaryConfirmationParams,
        closeOnProceed: params.closeOnProceed,
        onProceed: (proceedParams) => {
          resolve(true);
          if (params.onProceed) {
            params.onProceed(proceedParams);
          }
        },
        onCancel: () => {
          params.resolveOnCancel ? resolve(true) : reject();
          params.onCancel?.();
        },
      },
    })
  );
};

const showPrompt = (params = {}) => {
  return new Promise((resolve, reject) =>
    dispatch({
      type: PopupActionTypes.SHOW_POPUP,
      payload: {
        type: PopupConstants.PROMPT,
        id: params.id || uuid(),
        title: params.title ?? I18n.t('common.promptDefaultTitle'),
        text: params.text ?? '',
        textList: params.textList,
        priority: params.priority,
        useTextArea: params.useTextArea,
        allowEmptyValue: params.allowEmptyValue,
        defaultValue: params.defaultValue,
        confirmButtonText: params.confirmButtonText || I18n.t('common.save'),
        cancelButtonText: params.cancelButtonText || I18n.t('common.cancel'),
        onConfirm: (confirmationParams) => {
          resolve(true);
          if (params.onConfirm) {
            params.onConfirm(confirmationParams);
          }
        },
        onCancel: () => {
          params.onCancel && params.onCancel();
          reject();
        },
      },
    })
  );
};

const closePopup = (id) =>
  new Promise((resolve) => {
    dispatch({
      type: PopupActionTypes.CLOSE_POPUP,
      payload: id,
    });
    resolve();
  });

const showLoader = (id = DEFAULT_LOADER_POPUP_ID) => {
  return new Promise((resolve, reject) =>
    dispatch({
      type: PopupActionTypes.SHOW_POPUP,
      payload: {
        type: PopupConstants.LOADER,
        id,
      },
    })
  );
};

const hideLoader = (id = DEFAULT_LOADER_POPUP_ID) => closePopup(id);

export default {
  showConfirm,
  showAlert,
  showOptions,
  showPrompt,
  closePopup,
  showLoader,
  hideLoader,
};
