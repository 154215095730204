import React, { Component } from 'react';
import { ProfilesRoutes, RootRoutes } from '../../../utils/routes';
import { NavLink } from 'react-router-dom';

class failedDevices extends Component {
  render() {
    const { devicesFailedCount, id } = this.props.item;
    return (
      <NavLink
        to={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/${id}/failed`}
      >
        {`${devicesFailedCount}`}
      </NavLink>
    );
  }
}

export default failedDevices;
