import { I18n } from 'react-redux-i18n';
import PopupActions from '../../features/popup/popup.actions';
import { ERROR_TEXT_CODES_BY_CODE_NUMBER } from '../../constants/errorMessages';
import Helpers from '../helpers';

function getErrorText({
  errorTextsByInternalCode = ERROR_TEXT_CODES_BY_CODE_NUMBER,
  internalCode,
  defaultErrorMessageTextCode,
}) {
  return errorTextsByInternalCode[internalCode] ?? defaultErrorMessageTextCode;
}

function getSuccessDeterminer({
  customSuccessDeterminer,
  successStatusCode,
  status,
}) {
  const statusCodeDeterminer = Array.isArray(successStatusCode)
    ? successStatusCode.includes(status)
    : status === successStatusCode;
  return Helpers.isNotNull(customSuccessDeterminer)
    ? customSuccessDeterminer
    : statusCodeDeterminer;
}

export function handleRequestResponse({
  response,
  successStatusCode,
  customSuccessDeterminer,
  onSuccess,
  successTextCode,
  customSuccessText,
  showSuccessPopup = true,
  successPopupAdditionalParams = {},
  onError,
  errorTextsByInternalCode,
  defaultErrorMessageTextCode,
  showErrorPopup = true,
  errorPopupAdditionalParams = {},
}) {
  const isSuccess = getSuccessDeterminer({
    customSuccessDeterminer,
    successStatusCode,
    status: response.status,
  });

  let popupParams;
  let showPopup;
  let onFulfilled;

  if (isSuccess) {
    showPopup = showSuccessPopup;
    const successText =
      customSuccessText ?? successTextCode
        ? I18n.t(successTextCode)
        : I18n.t('common.completed');
    popupParams = {
      title: I18n.t('common.success'),
      text: successText,
      ...successPopupAdditionalParams,
    };
    onFulfilled = () =>
      onSuccess?.({
        responseData: response.data,
        successText,
      });
  } else {
    showPopup = showErrorPopup;
    const errorText =
      getErrorText({
        errorTextsByInternalCode,
        internalCode: response.data?.internal_code,
        defaultErrorMessageTextCode,
      }) ?? 'common.somethingWentWrong';
    popupParams = {
      title: I18n.t('common.error'),
      text: I18n.t(errorText),
      ...errorPopupAdditionalParams,
    };
    onFulfilled = () =>
      onError?.({
        responseData: response.data,
        errorText,
      });
  }

  if (showPopup) {
    PopupActions.showAlert({
      ...popupParams,
      onClose: onFulfilled,
    });
  } else {
    onFulfilled?.();
  }
}
