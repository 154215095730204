import { RootRoutes } from '../utils/routes';
import React from 'react';
import { ReactComponent as HouseGradient } from '../assets/images/house_gradient.svg';
import { ReactComponent as House } from '../assets/images/house.svg';
import { ReactComponent as PhoneAndroidGradient } from '../assets/images/phone_android_gradient.svg';
import { ReactComponent as PhoneAndroidIc } from '../assets/images/phone_android_ic.svg';
import { ReactComponent as FileGradient } from '../assets/images/file_gradient.svg';
import { ReactComponent as File } from '../assets/images/file.svg';
import { ReactComponent as LayersGradient } from '../assets/images/layers_gradient.svg';
import { ReactComponent as Layers } from '../assets/images/layers.svg';
import { ReactComponent as Persons } from '../assets/images/persons.svg';
import { ReactComponent as PersonsGradient } from '../assets/images/persons_gradient.svg';
import { ReactComponent as GearWheelGradient } from '../assets/images/gear_wheel_gradient.svg';
import { ReactComponent as GearWheel } from '../assets/images/gear_wheel.svg';
import { ReactComponent as BellWhite } from '../assets/images/bell-white.svg';
import { ReactComponent as APIIcon } from '../assets/images/api.svg';

const sidebarItems = [
  {
    route: RootRoutes.HOME,
    icon: <HouseGradient style={{ width: '32px' }} />,
    activeIcon: <House style={{ width: '32px' }} />,
    titleCode: 'home.title',
  },
  {
    route: RootRoutes.DEVICES,
    icon: <PhoneAndroidGradient style={{ width: '20px' }} />,
    activeIcon: <PhoneAndroidIc style={{ width: '20px' }} />,
    titleCode: 'devices.title',
  },
  {
    route: RootRoutes.PROFILES,
    icon: <FileGradient style={{ width: '26px', color: '#fff' }} />,
    activeIcon: <File style={{ width: '26px', color: '#fff' }} />,
    titleCode: 'profiles.title',
  },
];

export default sidebarItems;

export const applicationsMenuItem = {
  route: RootRoutes.FILE_SYSTEM,
  icon: <LayersGradient style={{ width: '30px', color: '#fff' }} />,
  activeIcon: <Layers style={{ width: '30px', color: '#fff' }} />,
  titleCode: 'fileSystem.title',
};

export const usersManagementItem = {
  route: RootRoutes.USERS,
  icon: <PersonsGradient style={{ width: '33px' }} />,
  activeIcon: <Persons style={{ width: '33px' }} />,
  titleCode: 'users.title',
};

export const alertsItem = {
  route: RootRoutes.ALERTS,
  icon: <BellWhite style={{ width: '33px' }} />,
  activeIcon: <BellWhite style={{ width: '33px' }} />,
  titleCode: 'alerts.title',
};

export const APIItem = {
  route: RootRoutes.API,
  icon: <APIIcon style={{ width: '33px' }} />,
  activeIcon: <APIIcon style={{ width: '33px' }} />,
  titleCode: 'api.title',
};

export const sidebarSettingsItem = {
  route: RootRoutes.SETTINGS,
  icon: <GearWheelGradient style={{ width: '31px' }} />,
  activeIcon: <GearWheel style={{ width: '31px' }} />,
  titleCode: 'settings.title',
};
