import React, { FC, ReactNode } from 'react';

import StyleUtils from '../../utils/styleUtils';

import './styles/buttonWithIcon.scss';
import { Button } from '../button.component';

interface ButtonWithIconProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  className?: string;
  text?: ReactNode;
  textClassName?: string;
  onClick?: () => void;
  secondary?: boolean;
}

const ButtonWithIcon: FC<ButtonWithIconProps> = ({
  leftIcon = null,
  rightIcon = null,
  className = '',
  text,
  onClick,
  textClassName = '',
  ...rest
}) => (
  <Button
    className={StyleUtils.mergeClasses('button-with-icon', className)}
    onClick={onClick}
    {...rest}
  >
    {leftIcon}
    <span
      className={StyleUtils.mergeClasses(
        'button-with-icon__text',
        textClassName
      )}
    >
      {text}
    </span>
    {rightIcon}
  </Button>
);

export default ButtonWithIcon;
