import React from 'react';
import { useSelector } from 'react-redux';
import { locationLogsTableColumns } from '../device.constants';
import { deviceSelector } from '../device.selectors';
import DataTable from '../../../components/DataTable/DataTable.component';

const LocationHistoryLogs = () => {
  const { locationLogs, locationLogsLoadingStatus } = useSelector(
    deviceSelector
  );
  return (
    <section className="location-history__view">
      <DataTable
        allColumns={locationLogsTableColumns}
        data={locationLogs}
        loadingStatus={locationLogsLoadingStatus}
        sortable
        useFlex
      />
    </section>
  );
};

export default LocationHistoryLogs;
