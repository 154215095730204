import { I18n } from 'react-redux-i18n';
import { ACCESS_TYPES } from './accessTypes';

export const MODEL_GLOBAL_PERMISSIONS = {
  ADD_FLEET_TREE: 'add_fleettree',
  CHANGE_FLEET_TREE: 'change_fleettree',
  DELETE_FLEET_TREE: 'delete_fleettree',
  VIEW_FLEET_TREE: 'view_fleettree',

  ADD_PROFILE: 'add_profile',
  CHANGE_PROFILE: 'change_profile',
  DELETE_PROFILE: 'delete_profile',
  VIEW_PROFILE: 'view_profile',

  ADD_DEVICE: 'add_device',
  CHANGE_DEVICE: 'change_device',
  DELETE_DEVICE: 'delete_device',
  VIEW_DEVICE: 'view_device',

  ADD_FLEET_V2: 'add_fleetv2',
  CHANGE_FLEET_V2: 'change_fleetv2',
  DELETE_FLEET_V2: 'delete_fleetv2',
  VIEW_FLEET_V2: 'view_fleetv2',

  ADD_PROFILE_V2: 'add_profilev2',
  CHANGE_PROFILE_V2: 'change_profilev2',
  DELETE_PROFILE_V2: 'delete_profilev2',
  VIEW_PROFILE_V2: 'view_profilev2',
};

export const FLEET_TREE_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.VIEW_FLEET_TREE,
];

// REVERT_POINT
export const FLEET_TREE_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.ADD_FLEET_TREE,
  MODEL_GLOBAL_PERMISSIONS.CHANGE_FLEET_TREE,
  MODEL_GLOBAL_PERMISSIONS.DELETE_FLEET_TREE,
  MODEL_GLOBAL_PERMISSIONS.VIEW_FLEET_TREE,
];

export const ALL_FLEET_TREE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS = {
  [ACCESS_TYPES.READ_WRITE]: FLEET_TREE_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.READ]: FLEET_TREE_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.NONE]: [],
};

export const PROFILE_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.VIEW_PROFILE,
];

export const PROFILE_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.ADD_PROFILE,
  MODEL_GLOBAL_PERMISSIONS.CHANGE_PROFILE,
  MODEL_GLOBAL_PERMISSIONS.DELETE_PROFILE,
  MODEL_GLOBAL_PERMISSIONS.VIEW_PROFILE,
];

// REVERT_POINT
export const ALL_PROFILE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS = {
  [ACCESS_TYPES.READ_WRITE]: PROFILE_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.READ]: PROFILE_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.NONE]: [],
};

export const DEVICE_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.VIEW_DEVICE,
];

export const DEVICE_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.ADD_DEVICE,
  MODEL_GLOBAL_PERMISSIONS.CHANGE_DEVICE,
  MODEL_GLOBAL_PERMISSIONS.DELETE_DEVICE,
  MODEL_GLOBAL_PERMISSIONS.VIEW_DEVICE,
];

export const ALL_DEVICE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS = {
  [ACCESS_TYPES.READ_WRITE]: DEVICE_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.READ]: DEVICE_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.NONE]: [],
};

// v2
export const FLEET_TREE_V2_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.VIEW_FLEET_V2,
];

export const FLEET_TREE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.ADD_FLEET_V2,
  MODEL_GLOBAL_PERMISSIONS.CHANGE_FLEET_V2,
  MODEL_GLOBAL_PERMISSIONS.DELETE_FLEET_V2,
  MODEL_GLOBAL_PERMISSIONS.VIEW_FLEET_V2,
];

export const ALL_FLEET_TREE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS = {
  [ACCESS_TYPES.READ_WRITE]: FLEET_TREE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.READ]: FLEET_TREE_V2_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.NONE]: [],
};

export const PROFILE_V2_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.VIEW_PROFILE_V2,
];

export const PROFILE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS = [
  MODEL_GLOBAL_PERMISSIONS.ADD_PROFILE_V2,
  MODEL_GLOBAL_PERMISSIONS.CHANGE_PROFILE_V2,
  MODEL_GLOBAL_PERMISSIONS.DELETE_PROFILE_V2,
  MODEL_GLOBAL_PERMISSIONS.VIEW_PROFILE_V2,
];

export const ALL_PROFILE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS = {
  [ACCESS_TYPES.READ_WRITE]: PROFILE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.READ]: PROFILE_V2_READ_ONLY_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  [ACCESS_TYPES.NONE]: [],
};

export const globalPermissions = [
  {
    key: 'device',
    title: I18n.t('users.devicePermissionTitle'),
    read: false,
    readWrite: false,
  },
  {
    key: 'fleettree',
    title: I18n.t('users.fleetTreePermissionTitle'),
    read: false,
    readWrite: false,
  },
  {
    key: 'profile',
    title: I18n.t('users.profilePermissionTitle'),
    read: false,
    readWrite: false,
  },
];

export const GLOBAL_READ_READWRITE_PERMISSIONS = {
  PROFILE_DETAILS: 'profile_details',
  FEATURE_CONTROL: 'feature_control',
  PROFILE_ACCESS: 'profile_access',
  // next setting is responsible for applications menu item in the profile menu
  APPLICATIONS: 'applications',
  APP_MANAGEMENT: 'app_management',
  BLACKLIST: 'blacklist',
  CERTIFICATES: 'certificates',
  NETWORK: 'network',
  WIFI: 'WiFi',
  WEB_FILTER: 'web_filter',
  KIOSK_MODE: 'kiosk_mode',
  FILE_SYNC: 'file_sync',
  GEOFENCING: 'geo_fencing',
  PASSWORD: 'password',
  SECURITY: 'security',
  SETTINGS: 'settings',
  WALLPAPER_SETTINGS: 'wallpaper_settings',
  UPLOADS_AND_APP_SETTINGS_ACCESS: 'uploads_and_app_settings_access',
  UPLOADS: 'uploads',
  // The next setting is responsible for the applications page
  APPLICATIONS_ACCESS: 'applications_access',

  // device permissions
  VIEW_DEVICE_LOCATION: 'view_device_location',
  LOCATION_HISTORY: 'location_history',
  CURRENT_LOCATION: 'current_location',
  LIVE_LOCATION_TRACKING: 'live_location_tracking',

  APN: 'apn',

  // add the following on the backend
  LOCATION_SERVICES: 'location_services',
  AUTHENTICATION: 'authentication',
};
export const permissionTableItems = [
  {
    title: I18n.t('users.fleetAccessTitle'),
    level: 0,
    id: 'fleetAccess',
  },
  {
    title: I18n.t('users.profileAccessTitle'),
    level: 0,
    id: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    children: [
      GLOBAL_READ_READWRITE_PERMISSIONS.FEATURE_CONTROL,
      GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS,
      GLOBAL_READ_READWRITE_PERMISSIONS.APP_MANAGEMENT,
      GLOBAL_READ_READWRITE_PERMISSIONS.BLACKLIST,
      GLOBAL_READ_READWRITE_PERMISSIONS.CERTIFICATES,
      GLOBAL_READ_READWRITE_PERMISSIONS.NETWORK,
      GLOBAL_READ_READWRITE_PERMISSIONS.WIFI,
      GLOBAL_READ_READWRITE_PERMISSIONS.WEB_FILTER,
      GLOBAL_READ_READWRITE_PERMISSIONS.APN,
      GLOBAL_READ_READWRITE_PERMISSIONS.KIOSK_MODE,
      GLOBAL_READ_READWRITE_PERMISSIONS.FILE_SYNC,
      GLOBAL_READ_READWRITE_PERMISSIONS.GEOFENCING,
      GLOBAL_READ_READWRITE_PERMISSIONS.PASSWORD,
      GLOBAL_READ_READWRITE_PERMISSIONS.SECURITY,
      GLOBAL_READ_READWRITE_PERMISSIONS.SETTINGS,
      GLOBAL_READ_READWRITE_PERMISSIONS.WALLPAPER_SETTINGS,
    ],
  },
  {
    title: I18n.t('profiles.featureControl.title'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.FEATURE_CONTROL,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.applicationsTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.apps.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.APP_MANAGEMENT,
    level: 2,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.blacklist.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.BLACKLIST,
    level: 2,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.certificates.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.CERTIFICATES,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.networkTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.NETWORK,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.wifi.title'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.WIFI,
    level: 2,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.webFilter.webFilter'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.WEB_FILTER,
    level: 2,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.apn.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.APN,
    level: 2,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.kiosk.title'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.KIOSK_MODE,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.fileSync.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.FILE_SYNC,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.geofencing.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.GEOFENCING,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.password.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.PASSWORD,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.security.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.SECURITY,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.settings.policyTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.SETTINGS,
    level: 1,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('profiles.wallpaperSettings.wallpaperSettings'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.WALLPAPER_SETTINGS,
    level: 2,
    rootPermission: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('users.permissionTitleUploadsAndApplicationsAccessTitle'),
    level: 0,
    id: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS_AND_APP_SETTINGS_ACCESS,
    children: [
      GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
      GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS,
    ],
  },
  {
    title: I18n.t('users.permissionTitleUploadsTitle'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
    level: 1,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('users.permissionTitleApplicationsAccess'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS,
    level: 1,
    read: false,
    readWrite: false,
  },
  {
    title: I18n.t('users.permissionTitleViewDeviceLocation'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.VIEW_DEVICE_LOCATION,
    level: 0,
    read: false,
    readWrite: false,
    hiddenCells: [ACCESS_TYPES.READ_WRITE],
  },
  {
    title: I18n.t('users.permissionTitleViewCurrentLocation'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.CURRENT_LOCATION,
    level: 0,
    read: false,
    readWrite: false,
    hiddenCells: [ACCESS_TYPES.READ_WRITE],
  },
  {
    title: I18n.t('users.permissionTitleViewLocationHistory'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.LOCATION_HISTORY,
    level: 0,
    read: false,
    readWrite: false,
    hiddenCells: [ACCESS_TYPES.READ_WRITE],
  },
  {
    title: I18n.t('users.permissionTitleLiveDeviceLocationTracking'),
    id: GLOBAL_READ_READWRITE_PERMISSIONS.LIVE_LOCATION_TRACKING,
    level: 0,
    read: false,
    readWrite: false,
    hiddenCells: [ACCESS_TYPES.READ_WRITE],
  },
];

export const globalPermissionsHeadings = [
  {
    id: 'title',
    title: I18n.t('users.globalPermissionsHeadingDescription'),
  },
  {
    id: 'access',
    title: I18n.t('users.globalPermissionsHeadingAccess'),
  },
];

export const globalPermissionsAccessTypes = {
  read: {
    id: 'read',
    title: I18n.t('users.globalPermissionsAccessTypeReadOnly'),
  },
  readWrite: {
    id: 'readWrite',
    title: I18n.t('users.globalPermissionsAccessTypeReadWrite'),
  },
  none: {
    id: 'none',
    title: I18n.t('users.globalPermissionsAccessTypeNone'),
  },
};

export const globalPermissionsMock = {
  [GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_DETAILS]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.FEATURE_CONTROL]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.APP_MANAGEMENT]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.BLACKLIST]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.CERTIFICATES]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.WIFI]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.NETWORK]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.WEB_FILTER]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.APN]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.KIOSK_MODE]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.FILE_SYNC]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.GEOFENCING]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.PASSWORD]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.SECURITY]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.SETTINGS]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.WALLPAPER_SETTINGS]:
    ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS]: ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS]:
    ACCESS_TYPES.READ_WRITE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.VIEW_DEVICE_LOCATION]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.CURRENT_LOCATION]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.LOCATION_HISTORY]: ACCESS_TYPES.READ,
  [GLOBAL_READ_READWRITE_PERMISSIONS.LIVE_LOCATION_TRACKING]: ACCESS_TYPES.READ,
};

export const groupGlobalPermissionsConfigDefaultValues = {
  [GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_DETAILS]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.FEATURE_CONTROL]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.APP_MANAGEMENT]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.BLACKLIST]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.CERTIFICATES]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.WIFI]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.NETWORK]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.WEB_FILTER]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.APN]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.KIOSK_MODE]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.FILE_SYNC]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.GEOFENCING]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.PASSWORD]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.SECURITY]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.SETTINGS]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.WALLPAPER_SETTINGS]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.VIEW_DEVICE_LOCATION]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.CURRENT_LOCATION]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.LOCATION_HISTORY]: ACCESS_TYPES.NONE,
  [GLOBAL_READ_READWRITE_PERMISSIONS.LIVE_LOCATION_TRACKING]: ACCESS_TYPES.NONE,
};
