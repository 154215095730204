import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

const AlertCreated = () => {
  return <section>{I18n.t('alerts.alertCreated')}</section>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AlertCreated);
