export const deserializeSSOSAMLSettings = ({
  app_name,
  company,
  entity_id,
  id,
  organization_id,
  provider,
  sso_url,
}) => ({
  organizationId: organization_id,
  applicationName: app_name,
  entityId: entity_id,
  loginUrl: sso_url,

  // company: company,
  id,
  // provider: provider,
});
