import { NetworkStatus } from '../../common/constants';
import {
  SET_UPLOAD_INITIAL_PROGRESS,
  SET_UPLOAD_PROGRESS,
  UPLOAD_FAILED,
  UPLOAD_RESET,
  UPLOAD_STARTED,
  UPLOAD_SUCCESS,
} from './upload.actionTypes';

export default function upload(
  state = {
    uploadingStatus: NetworkStatus.NONE,
    uploadingProgress: undefined,
    uploadingError: undefined,
  },
  action
) {
  switch (action.type) {
    case UPLOAD_STARTED:
      return {
        ...state,
        uploadingStatus: NetworkStatus.STARTED,
        uploadingProgress: [],
        uploadingError: undefined,
      };
    case UPLOAD_FAILED:
      return {
        ...state,
        uploadingStatus: NetworkStatus.ERROR,
        uploadingProgress: [],
        uploadingError: action.payload,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploadingStatus: NetworkStatus.DONE,
        uploadingProgress: [],
        uploadingError: undefined,
      };
    case UPLOAD_RESET:
      return {
        ...state,
        uploadingStatus: NetworkStatus.NONE,
        uploadingProgress: [],
        uploadingError: undefined,
      };
    case SET_UPLOAD_INITIAL_PROGRESS: {
      return {
        ...state,
        uploadingProgress: action.payload,
      };
    }
    case SET_UPLOAD_PROGRESS: {
      let existingIndex = state.uploadingProgress.findIndex(
        (file) => file.key === action.payload.key
      );
      const newState = { ...state };
      const uploadingProgress = [...newState.uploadingProgress];
      if (existingIndex >= 0) {
        uploadingProgress[existingIndex] = {
          ...state.uploadingProgress[existingIndex],
          uploadPercentage: action.payload.uploadPercentage,
        };
      } else {
        uploadingProgress.push(action.payload);
      }
      return {
        ...state,
        uploadingProgress,
      };
    }

    default:
      return state;
  }
}
