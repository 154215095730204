import { I18n } from 'react-redux-i18n';
import client from '../utils/client';
import services from '../utils/services';
import {
  geSavedTableColumnsFailed,
  getSavedTableColumnsStarted,
  getSavedTableColumnsSuccess,
  saveTableColumnsFailed,
  saveTableColumnsStarted,
  saveTableColumnsSuccess,
} from './columnsToggle.actions';
import PopupActions from '../features/popup/popup.actions';

export const saveTableColumns = (page, columns) => {
  return async (dispatch) => {
    try {
      dispatch(saveTableColumnsStarted());
      const { status, data } = await client.put(
        services.updateCustomSettingsForUser,
        {
          settings: {
            [page]: columns,
          },
        }
      );
      if (status === 200 && data) {
        dispatch(saveTableColumnsSuccess());
        PopupActions.showAlert({
          text: I18n.t('columnsToggle.successfullySavedChanges'),
        });
      } else {
        const errorText = I18n.t('columnsToggle.failedToSaveChanges');
        saveTableColumnsFailed(errorText);
        throw new Error(errorText);
      }
    } catch (e) {
      PopupActions.showAlert({
        text: e.message,
      });
    }
  };
};

export const getSavedTableColumns = () => {
  return async (dispatch) => {
    try {
      dispatch(getSavedTableColumnsStarted());
      const { status, data } = await client.get(
        services.getCustomSettingsForUser
      );
      if (status === 200 && data) {
        dispatch(getSavedTableColumnsSuccess(data));
      } else {
        throw new Error(I18n.t('columnsToggle.unableToGetSavedColumns'));
      }
    } catch (e) {
      dispatch(geSavedTableColumnsFailed(e.message));
    }
  };
};
