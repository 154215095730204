import { I18n } from 'react-redux-i18n';
import client, { clientV2 } from '../../utils/client';
import {
  createGroupFailed,
  createGroupStart,
  createGroupSuccess,
  deleteGroupFailed,
  deleteGroupReset,
  deleteGroupStart,
  deleteGroupSuccess,
  editGroupFailed,
  editGroupStart,
  editGroupSuccess,
  loadAllUsersFailed,
  loadAllUsersStart,
  loadAllUsersSuccess,
  loadCompanyFleetsFullListFailed,
  loadCompanyFleetsFullListStart,
  loadCompanyFleetsFullListSuccess,
  loadGroupsFailed,
  loadGroupsPermissionsFailed,
  loadGroupsPermissionsStart,
  loadGroupsPermissionsSuccess,
  loadGroupsStart,
  loadGroupsSuccess,
} from './groups.actions';
import { RootRoutes, UsersRoutes } from '../../utils/routes';
import { history } from '../../utils/history';
import { deserializeUser } from '../user/user.serializer';
import { serializeGroup } from './group.serializer';
import services from '../../utils/services';
import {
  isDataSuccessfullyFetched,
  isDataSuccessfullyPosted,
  isEmpty,
  notEmpty,
} from '../../common/helpers';
import { deserializeFleet } from '../fleet/fleet.serializer';
import RouteHelpers from '../../common/utilities/routeHelpers';
// import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';

export function loadGroups({
  isInitialLoad = true,
  offset,
  loadFullList = false,
} = {}) {
  return async (dispatch) => {
    try {
      dispatch(loadGroupsStart());
      let { status, data = { groups: [] } } = await client.get(
        `user-management/get_groups/`,
        {
          // params: {
          //     offset: isInitialLoad ? 0 : offset,
          //     limit: INFINITE_SCROLL_LIMIT,
          // },
        }
      );
      if (status === 200 && data && Array.isArray(data.results)) {
        const { results, next: nextUrl, count } = data;
        const { offset } = RouteHelpers.getURLQueryParams(nextUrl);
        const hasMore = Boolean(nextUrl);
        dispatch(
          loadGroupsSuccess({
            data: results.sort((groupA = {}, groupB = {}) => {
              const nameA = groupA.name.toUpperCase();
              const nameB = groupB.name.toUpperCase();
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            }),
            nextUrl,
            count,
            isInitialLoad,
            offset,
            hasMore,
            isFullList: loadFullList || !nextUrl,
          })
        );
      } else {
        dispatch(loadGroupsFailed(I18n.t('users.groupsLoadErrorText')));
      }
    } catch (error) {
      dispatch(loadGroupsFailed(I18n.t('users.groupsLoadErrorText')));
    }
  };
}

export function createGroup(groupData) {
  return async (dispatch) => {
    try {
      dispatch(createGroupStart());
      let { status, data = [] } = await client.post(
        `user-management/create_group/`,
        serializeGroup(groupData)
      );
      if (status === 201 && data) {
        dispatch(createGroupSuccess(data));
        dispatch(loadGroups());
        history.push(`${RootRoutes.USERS}${UsersRoutes.GROUPS}`);
      } else {
        let error = I18n.t('users.groupCreationError');
        if (data) {
          let validationErrors = Object.values(data).reduce(
            (result, item, index) => {
              let separator = index ? ', ' : '';
              if (typeof item === 'string') {
                return `${result}${separator}${item}`;
              }
              if (Array.isArray(item)) {
                return `${result}${separator}${item.join(', ')}`;
              }
              return result;
            },
            ''
          );
          if (validationErrors) {
            error = validationErrors;
          }
        }
        dispatch(createGroupFailed(error));
      }
    } catch (error) {
      dispatch(createGroupFailed(I18n.t('users.groupCreationError')));
    }
  };
}

export function editGroup(groupId, groupData) {
  return async (dispatch) => {
    try {
      dispatch(editGroupStart());
      const response = await client.put(
        `user-management/groups/${groupId}/update/`,
        serializeGroup(groupData, true)
      );
      const { data = [] } = response;
      if (isDataSuccessfullyPosted(response)) {
        dispatch(editGroupSuccess());
        dispatch(loadGroups());
        history.push(`${RootRoutes.USERS}${UsersRoutes.GROUPS}`);
      } else {
        let error = I18n.t('users.groupUpdateError');
        if (notEmpty(data)) {
          let validationErrors = Object.values(data).reduce(
            (result, item, index) => {
              let separator = index ? ', ' : '';
              if (typeof item === 'string') {
                return `${result}${separator}${item}`;
              }
              if (Array.isArray(item)) {
                return `${result}${separator}${item.join(', ')}`;
              }
              return result;
            },
            ''
          );
          if (validationErrors) {
            error = validationErrors;
          }
        }
        dispatch(editGroupFailed(error));
      }
    } catch (error) {
      dispatch(editGroupFailed(I18n.t('users.groupUpdateError')));
    }
  };
}

export function deleteGroup(groupId) {
  return async (dispatch) => {
    try {
      dispatch(deleteGroupStart());
      const { status, text } = await client.delete(
        `user-management/groups/${groupId}/delete`
      );
      if (status === 204 || status === 200) {
        dispatch(deleteGroupSuccess());
        dispatch(loadGroups());
        dispatch(deleteGroupReset());
        history.push(`${RootRoutes.USERS}${UsersRoutes.GROUPS}`);
      } else {
        dispatch(deleteGroupFailed(I18n.t('users.groupDeletionError')));
      }
    } catch (e) {
      dispatch(deleteGroupFailed(I18n.t('users.groupDeletionError')));
    }
  };
}

export function loadGroupsModelLevelPermissions() {
  return async (dispatch) => {
    try {
      dispatch(loadGroupsPermissionsStart());
      let response = await client.get(`user-management/model_permissions/`);
      if (response.status === 200 && response.data) {
        dispatch(loadGroupsPermissionsSuccess(response.data));
      } else {
        dispatch(
          loadGroupsPermissionsFailed(
            I18n.t('users.loadGroupsModelLevelPermissionsError')
          )
        );
      }
    } catch (error) {
      dispatch(
        loadGroupsPermissionsFailed(
          I18n.t('users.loadGroupsModelLevelPermissionsError')
        )
      );
    }
  };
}

export function loadAllUsers() {
  return async (dispatch) => {
    try {
      dispatch(loadAllUsersStart());
      let { status, data = [] } = await client.get(`user-management/users/`);
      if (status === 200 && !isEmpty(data)) {
        const result = data.map(deserializeUser);
        dispatch(loadAllUsersSuccess(result));
      } else {
        dispatch(loadAllUsersFailed(I18n.t('users.loadAllUsersError')));
      }
    } catch (error) {
      dispatch(loadAllUsersFailed(I18n.t('users.loadAllUsersError')));
    }
  };
}

export function loadCompanyFleetsFullList() {
  return async (dispatch) => {
    try {
      dispatch(loadCompanyFleetsFullListStart());
      const response = await clientV2.get(services.companyFleetsFullList);
      if (response.status === 200 && response.data?.results?.length >= 0) {
        const result = response.data.results
          .map(deserializeFleet)
          .sort((fleetA = '', fleetB = '') => {
            const nameA = fleetA.name.toUpperCase();
            const nameB = fleetB.name.toUpperCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
          });
        dispatch(loadCompanyFleetsFullListSuccess(result));
      } else {
        dispatch(
          loadCompanyFleetsFullListFailed(
            I18n.t('users.loadCompanyFleetsFullListError')
          )
        );
      }
    } catch (e) {
      dispatch(
        loadCompanyFleetsFullListFailed(
          I18n.t('users.loadCompanyFleetsFullListError')
        )
      );
    }
  };
}
