import { NetworkStatus } from '../../common/constants';
import {
  ENROLLMENTS_DELETE_FAILED,
  ENROLLMENTS_DELETE_RESET_STATUS,
  ENROLLMENTS_DELETE_STARTED,
  ENROLLMENTS_DELETE_SUCCESS,
  ENROLLMENTS_LOAD_FAILED,
  ENROLLMENTS_LOAD_STARTED,
  ENROLLMENTS_LOAD_SUCCESS,
} from './enrollments.actions';

export default function enrollments(
  state = {
    data: [],
    nextUrl: null,
    status: NetworkStatus.NONE,
    error: undefined,
    nextPageError: undefined,
    nextPageStatus: NetworkStatus.NONE,
    deletingStatus: NetworkStatus.NONE,
    deletingError: undefined,
  },
  action
) {
  switch (action.type) {
    case ENROLLMENTS_LOAD_STARTED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              status: NetworkStatus.STARTED,
              error: undefined,
            }
          : {
              nextPageStatus: NetworkStatus.STARTED,
              nextPageError: undefined,
            }),
      };
    case ENROLLMENTS_LOAD_FAILED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              status: NetworkStatus.ERROR,
              error: action.payload?.error,
            }
          : {
              nextPageStatus: NetworkStatus.ERROR,
              nextPageError: action.payload?.error,
            }),
      };
    case ENROLLMENTS_LOAD_SUCCESS:
      return {
        ...state,
        data: [
          ...(action.payload.isInitialLoad ? [] : state.data),
          ...action.payload.data,
        ],
        nextUrl: action.payload.nextUrl,
        count: action.payload.count,
        ...(action.payload.isInitialLoad
          ? {
              status: NetworkStatus.DONE,
              error: undefined,
            }
          : {
              nextPageStatus: NetworkStatus.DONE,
              nextPageError: undefined,
            }),
      };
    case ENROLLMENTS_DELETE_STARTED:
      return Object.assign({}, state, {
        ...state,
        deletingStatus: NetworkStatus.STARTED,
        deletingError: undefined,
      });
    case ENROLLMENTS_DELETE_FAILED:
      return Object.assign({}, state, {
        ...state,
        deletingStatus: NetworkStatus.ERROR,
        deletingError: action.payload,
      });
    case ENROLLMENTS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        deletingStatus: NetworkStatus.DONE,
        deletingError: undefined,
      });
    case ENROLLMENTS_DELETE_RESET_STATUS:
      return Object.assign({}, state, {
        ...state,
        deletingStatus: NetworkStatus.NONE,
        deletingError: undefined,
      });
    default:
      return state;
  }
}
