import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import folderFilled from '../../../assets/images/folder_filled.svg';
import { ReactComponent as PlusCircle } from '../../../assets/images/plus-circle.svg';
import { ReactComponent as Trash } from '../../../assets/images/trash.svg';
import FleetsTree from '../../fleets/components/fleetsTree.component';
import Fence from './Fence';
import { SwitchField } from '../../../components/switchField.component';
import { ActionButton } from '../../../components/actionButton.component';
import { TextButton } from '../../../components/textButton.component';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../../common/constants';
import { getFleetById } from '../../../common/utilities/fleets';
import { isEmpty } from '../../../common/helpers';
import PolicyHeader from './PolicyHeader.component';
import PolicyActivationToggle from './PolicyActivationToggle.component';
import { I18n, Translate } from 'react-redux-i18n';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    flexFlow: 'column nowrap',
    height: '100%',
  },
};

export const initialSettings = {
  fence: undefined,
  active: false,
  move: false,
  fleet: undefined,
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'geofencing',
  isActive: false,
  policyData: null,
};

export class Geofencing extends Component {
  state = {
    fleet: undefined,
  };

  onToggle = (index, key) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings ? item.settings : { ...initialSettings };
    settings[key] = !settings[key];
    item.settings = settings;
    this.props.onChange('geofencing', index, item);
  };

  onChangeSettings = (index, key, value) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings ? item.settings : { ...initialSettings };
    settings[key] = value;
    item.settings = settings;
    this.props.onChange('geofencing', index, item);
  };

  renderSettings = ({ settings }, index) => {
    return (
      <div
        key={`geofencing_policy_${index}`}
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          borderBottom: '1px solid lightgray',
          marginBottom: '15px',
        }}
      >
        <div
          style={{
            marginBottom: '15px',
            display: 'inline-flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
          }}
        >
          <div style={{ whiteSpace: 'nowrap', fontSize: '16px' }}>{`${I18n.t(
            'profiles.geofencing.geofencingRuleTitle'
          )} ${index + 1}`}</div>
          <ActionButton
            title={I18n.t('profiles.geofencing.removeRuleActionButtonTitle')}
            onClick={() => this.props.onChange('geofencing', index, null)}
          >
            <Trash className={'icon'} />
          </ActionButton>
        </div>
        <div
          style={{ display: 'inline-flex', flexWrap: 'nowrap', width: '100%' }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              minHeight: '500px',
              maxHeight: '70vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '600px',
                minWidth: '300px',
              }}
            >
              <SwitchField
                onClick={(e) => this.onToggle(index, 'active')}
                on={settings.active || false}
                title={I18n.t(
                  'profiles.geofencing.notifyWhenDevicesLeaveTheFence'
                )}
              />
              <SwitchField
                onClick={(e) => this.onToggle(index, 'move')}
                on={settings.move || false}
                title={I18n.t(
                  'profiles.geofencing.moveDeviceToFleetIfItLeavesGeofence'
                )}
              />
              {this.renderFleetSection(settings, index)}
            </div>
            <Fence
              index={index}
              fence={settings.fence}
              onChangeSettings={this.onChangeSettings}
            />
          </div>
        </div>
      </div>
    );
  };

  getPolicyStatus = () => {
    const { policy = [] } = this.props;
    return policy.some((item) => item.isActive);
  };

  renderFencingRules = () => {
    const { policy = [], onChangePolicyProperty } = this.props;
    if (policy?.length) {
      const isPolicyActive = this.getPolicyStatus();

      const onEnableButtonToggle = ({
        presetNewToggleValue,
        index,
        propertyName,
      }) =>
        onChangePolicyProperty({
          key: 'geofencing',
          propertyName,
          index,
          value: presetNewToggleValue,
          initialPolicy,
          initialSettings,
        });
      return (
        <>
          <PolicyActivationToggle
            key="policyActivation"
            policy={policy}
            titleCode="profiles.geofencing.policyStatusTitle"
            handleToggle={onEnableButtonToggle}
          />
          {isPolicyActive ? this.props.policy.map(this.renderSettings) : null}
        </>
      );
    } else {
      return this.renderDetails();
    }
  };

  renderDetails = () => {
    return (
      <div
        style={{
          width: '75%',
          display: 'flex',
          height: '100%',
          paddingRight: '20px',
          alignItems: 'center',
        }}
      >
        <div>{I18n.t('profiles.geofencing.hint')}</div>
      </div>
    );
  };

  renderFleetSection = (settings, index) => {
    if (settings.move) {
      if (settings.fleet && !this.state.showFleetSelection) {
        const foundFleet = this.props.fleets.find(
          (fleet) => fleet.id === settings.fleet
        );
        if (foundFleet) {
          return (
            <div
              style={{
                marginBottom: '15px',
                display: 'inline-flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                minWidth: '300px',
                background: '#f2f2f2f2',
                padding: '12px',
              }}
            >
              <div
                style={{
                  display: 'inline-flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  minWidth: '150px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <img
                  alt={''}
                  src={folderFilled}
                  style={{
                    width: 'auto',
                    height: '10px',
                    paddingRight: '10px',
                  }}
                />
                <div style={{ minWidth: '150px' }}>{foundFleet.name || ''}</div>
              </div>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => {
                    this.setState({
                      fleet: settings.fleet,
                      showFleetSelection: true,
                    });
                  }}
                >
                  {I18n.t('profiles.geofencing.changeFleetButtonText')}
                </Button>
                <Button
                  secondary
                  onClick={() => {
                    this.onChangeSettings(index, 'fleet', undefined);
                  }}
                >
                  {I18n.t('profiles.geofencing.removeFleetButtonText')}
                </Button>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div
            style={{
              marginBottom: '15px',
              display: 'inline-flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: '600px',
              minWidth: '300px',
              background: '#f2f2f2f2',
              padding: '12px',
            }}
          >
            {this.state.showFleetSelection
              ? this.renderFleetSelection(settings, index)
              : [
                  <div>
                    {I18n.t('profiles.geofencing.fleetNotDefinedText')}
                  </div>,
                  <Button onClick={() => this.renderDialog(settings, index)}>
                    {I18n.t('profiles.geofencing.selectAFleetButtonText')}
                  </Button>,
                ]}
          </div>
        );
      }
    }
  };

  renderDialog = (settings, index) => {
    this.setState({
      fleet: settings.fleet || undefined,
      showFleetSelection: true,
    });
  };

  renderFleetSelection = (settings, index) => {
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            maxHeight: '400px',
            height: '400px',
          }}
        >
          <FleetsTree
            fleets={this.props.fleets || []}
            onSelect={(fleet) =>
              this.setState({ fleet: fleet ? fleet.id : undefined })
            }
            defaultSelectedKeys={this.state.fleet ? [this.state.fleet] : []}
            selectedKeys={this.state.fleet ? [this.state.fleet] : []}
            multiple={false}
          />
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            minHeight: '32px',
            height: '32px',
            flexFlow: 'row-reverse',
          }}
        >
          <Button
            secondary
            style={{
              display: 'flex',
              margin: '0 10px !important',
            }}
            onClick={() => {
              this.setState({ fleet: undefined, showFleetSelection: false });
            }}
          >
            {I18n.t('common.cancel')}
          </Button>
          <Button
            style={{
              display: 'flex',
              margin: '0 10px',
            }}
            onClick={() => {
              this.onChangeSettings(index, 'fleet', this.state.fleet);
              this.setState({ fleet: undefined, showFleetSelection: false });
            }}
          >
            {I18n.t('common.ok')}
          </Button>
        </div>
      </div>
    );
  };

  renderContent = () => {
    const handleAddClick = () =>
      this.props.onChange('geofencing', this.props.policy.length, {
        ...initialPolicy,
        isActive: true,
        settings: { ...initialSettings },
      });
    return (
      <>
        <PolicyHeader
          descriptionTitle={I18n.t('profiles.geofencing.policyTitle')}
          descriptionHint={I18n.t('profiles.geofencing.policyHint')}
          textButtonProps={{
            handleTextButtonClick: handleAddClick,
            textButtonHint: I18n.t('profiles.geofencing.textButtonHint'),
          }}
        />
        <div style={{ overflowY: 'auto' }}>{this.renderFencingRules()}</div>
      </>
    );
  };

  renderReadOnlyContent = () => {
    const { policy = [] } = this.props;
    if (isEmpty(policy)) {
      return <p>{I18n.t('profiles.geofencing.noRulesInProfile')}</p>;
    }

    const isPolicyActive = this.getPolicyStatus();

    return (
      <>
        <ReadOnlyForm
          items={[
            {
              title: (
                <Translate value="profiles.geofencing.policyStatusTitle" />
              ),
              value: isPolicyActive,
              type: AttributeType.BOOLEAN,
              key: 'isPolicyActive',
            },
          ]}
          headerTitle={I18n.t('profiles.geofencing.policyTitle')}
        />
        {policy.map((geoFencingPolicy, index) =>
          this.renderGeoFencingRuleReadOnly(geoFencingPolicy, index)
        )}
      </>
    );
  };

  renderGeoFencingRuleReadOnly = (geoFencingPolicy, index) => {
    const { fleets } = this.props;
    const {
      settings: { active = false, move = false, fleet = '', fence } = {},
    } = geoFencingPolicy;
    const geoFencingPolicyIndex = index + 1;

    const { name: fleetName = '' } = getFleetById(fleets, fleet);
    return (
      <ReadOnlyForm
        key={`read-only-form-${index}`}
        headerTitle={`${I18n.t(
          'profiles.geofencing.geofencingRuleTitle'
        )} ${geoFencingPolicyIndex}`}
        items={[
          {
            title: I18n.t('profiles.geofencing.notifyWhenDevicesLeaveTheFence'),
            value: active,
            type: AttributeType.BOOLEAN,
            key: 'active',
          },
          {
            title: I18n.t(
              'profiles.geofencing.moveDeviceToFleetIfItLeavesGeofence'
            ),
            value: move,
            type: AttributeType.BOOLEAN,
            key: 'move',
          },
          {
            title: I18n.t('fleets.fleet'),
            value: fleetName,
            key: 'fleet',
            hidden: !fleetName,
          },
          ...(fence
            ? [
                {
                  type: AttributeType.CUSTOM,
                  key: `fence-${geoFencingPolicyIndex}`,
                  component: Fence,
                  onChangeSettings: () => {},
                  index: geoFencingPolicyIndex,
                  fence,
                },
              ]
            : []),
        ]}
      />
    );
  };

  render() {
    const { isEditingMode } = this.props;
    return (
      <div style={styles.root}>
        <div
          style={{
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            height: '100%',
          }}
        >
          {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
        </div>
      </div>
    );
  }
}

export default Geofencing;

Geofencing.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
