import React, { Component, Fragment } from 'react';
import Popup from 'reactjs-popup';
import Dialog from '../../components/Dialog';
import ClassNames from 'classnames';
import { Input, Label, UncontrolledCollapse } from 'reactstrap';
import FleetsTree from '../fleets/components/fleetsTree.component';
import { history } from '../../utils/history';
import Blacklist from './components/Blacklist';
import Certificates from './components/Certificates';
import Password from './components/Password';
import ProfileDetail from './components/ProfileDetail';
import { createProfileReset, updateProfileReset } from './profile.actions';
import Authentication from './components/Authentication';
import FeatureControlComponent from './components/featureControl.component';
import { cloneDeep } from 'lodash';
import WiFi from './components/WiFi';
import LocationServices from './components/LocationServices';
import Kiosk from './components/Kiosk';
import FirmwareUpdate from '../../components/FirmwareUpdate';
import FileSync from './components/FileSync';
import { loadAllFiles } from '../../redux/files.actions';
import AppManagement from './components/AppManagement';
import KioskBuilder from './components/KioskBuilder';
import { companyIdSelector } from '../login/login.selectors';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import Geofencing from './components/Geofencing';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import Security from './components/Security';
import { knownTimezones, NetworkStatus } from '../../common/constants';
import * as moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { Throbber } from '../../components/Throbber';
import { loadApplicationsFullList } from '../applications/applications.thunk';
import { Button } from '../../components/button.component';
import {
  isEmpty,
  isLoadingStatusStarted,
  notEmpty,
  selectForCurrentBrand,
} from '../../common/helpers';
import Settings from './components/Settings';
import PROFILE_CONFIG_CONSTANTS from '../../constants/profileConfigConstants';
import {
  assignMenuStructure,
  defaultProfileMenuStructure,
  profileMenuStructure,
} from './profileMenuStructure';
import { getAccessibleMenuItems } from '../../common/utilities/profile';
import { globalPermissionsForUserSelector } from '../../selectors/accountSelectors';
import { loadGlobalPermissionsForUser } from '../account/account.thunk';
import WebFilter from './components/WebFilter';
import UserHelpers from '../../common/utilities/user';
import HTMLKioskBuilder from './components/HTMLKioskBuilder';
import { loadCertificates } from '../certificates/certificates.thunk';
import DialogHeaderTitleSection from '../../components/DialogHeaderTitleSection/DialogHeaderTitleSection.component';
import WallpaperSettings from './components/WallpaperSettings';
import {
  assignFleetsAndPublish,
  saveAndPublishProfile,
  createBrandNewProfile,
  createNewProfileVersion,
  loadProfileApps,
  loadProfileById,
  saveAsDraft,
  unpublishProfile,
  createBrandNewAndPublishProfile,
  createNewVersionAndPublish,
  assignFleets,
} from './profileV2.thunk';
import { profileV2Selector } from './profile.selectors';
import { profileV2Reset } from './profileV2.actions';
import StatusDisplay from '../../components/StatusDisplay/StatusDisplay.component';
import { SelectField } from '../../components/selectField.component';
import PopupActions from '../popup/popup.actions';
import { loadAllFleetsV2 } from '../fleets/fleetsV2.thunk';
import { fleetsV2Selector } from '../fleets/fleetsV2.selectors';
import { I18n, Translate } from 'react-redux-i18n';
import DropDownMenu from '../../components/DropDownMenu/dropDownMenu.component';
import AsyncRendererWithDataFetching from '../../components/AsyncRenderer/AsyncRendererWithDataFetching.component';
import { DataConstants } from '../../constants/data';
import { globalPermissionsMock } from '../../constants/globalPermissions';
import APN from './components/APN';
import AnalyticsService from '../../services/AnalyticsService';
import {
  AnalyticsCategory,
  AnalyticsProfileConstants,
} from '../../constants/analytics';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { ReactComponent as TimeIconGray } from '../../assets/images/time-gray.svg';
import { ReactComponent as DraftIcon } from '../../assets/images/draft.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/images/check-circle.svg';
import DropDownSelect from '../../components/DropDownSelect/dropDownSelect.component.jsx';
import { applicationsSelector } from '../applications/applications.selectors';

export const initialProfile = (companyId, fleetId) => ({
  companyId,
  fleetId,
  devices: [],
  lastUpdated: null,
  policies: {
    wifi: [],
    webFilter: [],
    authentication: [],
    featureControl: [],
    location: [],
    apps: [],
    applist: [],
    certificates: [],
    apn: [],
    kiosk: [],
    fileSync: [],
    geofencing: [],
    password: [],
    security: [],
    settings: [],
    wallpaperSettings: [],
  },
  fleets: [],
  id: null,
  name: I18n.t('profiles.newProfileDefaultName'),
});

const Tabs = {
  [PROFILE_CONFIG_CONSTANTS.PROFILE_DETAILS]: {
    component: ProfileDetail,
  },
  [PROFILE_CONFIG_CONSTANTS.AUTHENTICATION]: {
    component: Authentication,
    policyKey: 'authentication',
  },
  [PROFILE_CONFIG_CONSTANTS.FEATURE_CONTROL]: {
    component: FeatureControlComponent,
    policyKey: 'featureControl',
  },
  [PROFILE_CONFIG_CONSTANTS.APP_MANAGEMENT]: {
    component: AppManagement,
    policyKey: 'apps',
  },
  [PROFILE_CONFIG_CONSTANTS.BLACKLIST]: {
    component: Blacklist,
    policyKey: 'applist',
  },
  [PROFILE_CONFIG_CONSTANTS.CERTIFICATES]: {
    component: Certificates,
    policyKey: 'certificates',
  },
  [PROFILE_CONFIG_CONSTANTS.APN]: {
    component: APN,
    policyKey: 'apn',
  },
  [PROFILE_CONFIG_CONSTANTS.WIFI]: {
    component: WiFi,
    policyKey: 'wifi',
  },
  [PROFILE_CONFIG_CONSTANTS.WEB_FILTER]: {
    component: WebFilter,
    policyKey: 'webFilter',
  },
  [PROFILE_CONFIG_CONSTANTS.LOCATION_SERVICES]: {
    component: LocationServices,
    policyKey: 'location',
  },
  [PROFILE_CONFIG_CONSTANTS.FIRMWARE_UPDATE]: {
    component: FirmwareUpdate,
    policyKey: '',
  },
  [PROFILE_CONFIG_CONSTANTS.KIOSK_MODE]: {
    component: Kiosk,
    policyKey: 'kiosk',
  },
  [PROFILE_CONFIG_CONSTANTS.FILE_SYNC]: {
    component: FileSync,
    policyKey: 'fileSync',
  },
  [PROFILE_CONFIG_CONSTANTS.GEO_FENCING]: {
    component: Geofencing,
    policyKey: 'geofencing',
  },
  [PROFILE_CONFIG_CONSTANTS.PASSWORD]: {
    component: Password,
    policyKey: 'password',
  },
  [PROFILE_CONFIG_CONSTANTS.SECURITY]: {
    component: Security,
    policyKey: 'security',
  },
  [PROFILE_CONFIG_CONSTANTS.SETTINGS]: {
    component: Settings,
    policyKey: 'settings',
  },
  [PROFILE_CONFIG_CONSTANTS.WALLPAPER_SETTINGS]: {
    component: WallpaperSettings,
    policyKey: 'wallpaperSettings',
  },
};

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: props.accessibleMenuItems[0].key,
      profile: this.initializeProfile(props),
      changed: false,
      isEditingMode: false,
      isFleetsAssignmentButtonActive: false,
    };
  }

  componentDidMount() {
    const {
      globalPermissionsForUserStatus,
      certificatesLoadingStatus,
    } = this.props;
    if (!this.isProfileCreation) {
      this.loadProfileData(this.props.match.params.childRoute);
    } else if (
      this.props.applicationsFullListStatus === NetworkStatus.NONE ||
      this.props.applicationsFullListStatus === NetworkStatus.ERROR
    ) {
      this.props.loadApplicationsFullList();
    }
    if (globalPermissionsForUserStatus === NetworkStatus.NONE) {
      this.props.loadGlobalPermissionsForUser();
    }
    if (this.isProfileCreation) {
      this.setState({ isEditingMode: true });
    }
    this.props.loadAllFiles(this.props.companyId);
    this.props.loadAllFleets();

    if (certificatesLoadingStatus === NetworkStatus.NONE) {
      this.props.loadCertificates(this.props.companyId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.resetProfile();
      if (this.props.match.params.childRoute.toLowerCase() !== 'create') {
        this.loadProfileData(this.props.match.params.childRoute);
      } else {
        this.setState((state) => ({
          ...state,
          profile: initialProfile(this.props.companyId),
          changed: false,
        }));
        this.props.loadApplicationsFullList();
      }
    }
    if (
      this.props.status === NetworkStatus.DONE &&
      prevProps.status === NetworkStatus.STARTED
    ) {
      this.setState((state) => ({
        ...state,
        profile: this.initializeProfile(this.props),
        changed: false,
      }));
    }
    if (
      prevProps.createProfileStatus === NetworkStatus.STARTED &&
      this.props.createProfileStatus === NetworkStatus.ERROR
    ) {
      confirmAlert({
        customUI: this.renderCreateProfileError,
      });
    }
    if (
      prevProps.updateProfileStatus === NetworkStatus.STARTED &&
      this.props.updateProfileStatus === NetworkStatus.ERROR
    ) {
      confirmAlert({
        customUI: this.renderUpdateProfileError,
      });
    }
    if (
      this.props.certificates !== prevProps.certificates &&
      this.props.certificates
    ) {
    }
  }

  componentWillUnmount() {
    this.props.resetProfile();
  }

  get isProfileCreation() {
    const { match: { params: { childRoute } = {} } = {} } = this.props;
    return childRoute === 'create';
  }

  loadProfileData = (id, onSuccess) => {
    this.props.loadProfileById(id, onSuccess);
    this.props.loadProfileApps(id);
    this.props.loadApplicationsFullList();
  };

  getEditingModeToggleIconAvailability = () => {
    const { hasAdminPrivileges, accessibleMenuItems, profile } = this.props;
    return (
      (hasAdminPrivileges ||
        accessibleMenuItems.some(({ readWrite }) => readWrite)) &&
      this.state.selectedCategory !== PROFILE_CONFIG_CONSTANTS.FLEETS
    );
  };

  renderCreateProfileError = ({ onClose }) => {
    const text =
      this.props.createProfileError ||
      I18n.t('profiles.unableToCreateProfileErrorText');
    const onClick = this.onCloseError(onClose, this.props.createProfileReset);
    return (
      <div className={'create-enrollment-alert'}>
        <p>{`${I18n.t('common.error')}:`}</p>
        <p>{text}</p>
        <Button onClick={onClick}>{I18n.t('common.ok')}</Button>
      </div>
    );
  };

  renderUpdateProfileError = ({ onClose }) => {
    const text = this.props.updateProfileError;
    const onClick = this.onCloseError(onClose, this.props.updateProfileReset);
    return (
      <div className={'create-enrollment-alert'}>
        <p>{`${I18n.t('common.error')}:`}</p>
        <p>{text}</p>
        <Button onClick={onClick}>{I18n.t('common.ok')}</Button>
      </div>
    );
  };

  onCloseError = (onClose, resetError) => (e) => {
    resetError();
    onClose();
  };

  initializeProfile = ({
    profile,
    companyId,
    match: {
      params: { childRoute },
    },
  }) => {
    return childRoute.toLowerCase() !== 'create'
      ? profile
      : initialProfile(companyId);
  };

  render() {
    if (this.state.profile) {
      return this.renderProfile();
    }
    return <div>{'...'}</div>;
  }

  renderProfile = () => {
    const { isEditingMode, selectedCategory } = this.state;
    const profile = isEditingMode ? this.state.profile : this.props.profile;
    if (selectedCategory === PROFILE_CONFIG_CONSTANTS.KIOSK_BUILDER) {
      return (
        <KioskBuilder
          policy={
            profile.policies?.kiosk?.length ? [profile.policies.kiosk[0]] : []
          }
          policies={profile.policies}
          profile={profile}
          onChange={this.onChangePolicyDetails}
          applicationsFullList={this.props.applicationsFullList}
          onBack={this.onBack}
          onSave={this.onSave}
          onShowAssignFleets={this.onShowAssignFleets}
          onCreateNewVersion={this.onCreateNewVersion}
          onUndo={this.undoChanges}
          changed={this.state.changed}
        />
      );
    }
    if (selectedCategory === PROFILE_CONFIG_CONSTANTS.HTML_KIOSK_BUILDER) {
      const { files } = this.props;
      return (
        <HTMLKioskBuilder
          policy={
            profile.policies?.kiosk?.length ? [profile.policies.kiosk[0]] : []
          }
          policies={profile.policies}
          profile={profile}
          onChange={this.onChangePolicyDetails}
          files={files}
          applicationsFullList={this.props.applicationsFullList}
          onBack={this.onBack}
          onSave={this.onSave}
          onShowAssignFleets={this.onShowAssignFleets}
          onCreateNewVersion={this.onCreateNewVersion}
          onUndo={this.undoChanges}
          changed={this.state.changed}
        />
      );
    }
    return (
      <Dialog
        header={this.renderProfileHeader}
        menu={this.renderProfileMenu}
        content={this.renderProfileContent}
        isFullViewportVisible={isEditingMode}
        onBack={this.onBack}
        backRoute={
          selectedCategory !== PROFILE_CONFIG_CONSTANTS.FLEETS && !isEditingMode
            ? `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}`
            : undefined
        }
      />
    );
  };

  renderMoreOptionsPopup = () => {
    const linkClass = selectForCurrentBrand({
      original: 'profile-actions__link-button',
      incube:
        'profile-actions__link-button profile-actions__link-button--incube',
      janam: 'profile-actions__link-button profile-actions__link-button--janam',
    });

    return this.state.selectedCategory === PROFILE_CONFIG_CONSTANTS.FLEETS ? (
      <Popup
        closeOnDocumentClick
        position="bottom right"
        contentStyle={{
          minWidth: '200px',
          maxWidth: '80%',
          width: '800px',
          zIndex: 3,
          height: '340px',
        }}
        repositionOnResize
        trigger={
          <div className={linkClass}>
            {I18n.t('profiles.moreOptionsTitleHeader')}
          </div>
        }
      >
        <div
          style={{
            outline: 'none !important',

            boxShadow: 'none',
            minWidth: '200px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              fontSize: '12px',
              letterSpacing: 'normal',
            }}
          >
            <div
              style={{
                marginTop: '38px',
                marginLeft: '21px',

                fontSize: '21px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: '1.1',
              }}
            >
              {I18n.t('profiles.assignmentSettingsTitle')}
            </div>
          </div>
          <form
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              flexFlow: 'row',
              marginLeft: '21px',
              marginTop: '33px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Label
                  check
                  style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: '1.17',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Input
                    type="radio"
                    name="radio1"
                    checked={this.state.profile.datetimeOffset === undefined}
                    onChange={() =>
                      this.setState((state) => ({
                        ...state,
                        profile: {
                          ...state.profile,
                          datetimeOffset: undefined,
                        },
                      }))
                    }
                    style={{ marginRight: '4px' }}
                  />{' '}
                  {I18n.t('profiles.deviceSideTimeLabel')}
                </Label>
              </div>
              <div style={{ marginLeft: '28px' }}>
                <Label
                  check
                  style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: '1.17',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '4px',
                    cursor: 'pointer',
                  }}
                >
                  <Input
                    type="radio"
                    name="radio1"
                    value={'notStrict'}
                    checked={this.state.profile.datetimeOffset !== undefined}
                    onChange={() => {
                      this.setState((state) => ({
                        ...state,
                        profile: {
                          ...state.profile,
                          datetimeOffset: moment.tz.guess(true),
                        },
                      }));
                    }}
                    style={{ marginRight: '4px' }}
                  />{' '}
                  {I18n.t('profiles.timezone')}
                </Label>
              </div>
            </div>
          </form>
          <div
            style={{
              marginTop: '38px',
              marginLeft: '21px',

              fontSize: '21px',
              fontWeight: 'normal',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: '1.1',
            }}
          >
            {I18n.t('profiles.schedules')}
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              padding: '14px 24px',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label
                  check
                  style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: '1.17',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Input
                    type="checkbox"
                    name="check1"
                    value={this.state.profile.beginDate !== undefined}
                    checked={this.state.profile.beginDate !== undefined}
                    onChange={({ target: { checked } }) => {
                      this.setState((state) => {
                        return !checked
                          ? {
                              ...state,
                              profile: {
                                ...state.profile,
                                beginDate: undefined,
                                beginTime: undefined,
                              },
                              changed: true,
                            }
                          : {
                              ...state,
                              profile: {
                                ...state.profile,
                                beginDate: state.profile.endDate
                                  ? state.profile.endDate
                                  : moment().format('YYYY-MM-DD'),
                                beginTime: state.profile.endTime
                                  ? state.profile.endTime
                                  : moment().format('hh:mm A'),
                              },
                              changed: true,
                            };
                      });
                    }}
                    style={{ marginRight: '8px' }}
                  />{' '}
                  <div
                    style={{
                      display: 'flex',
                      width: '80px',
                      marginRight: '14px',
                    }}
                  >
                    {I18n.t('profiles.assignmentStartDateLabel')}
                  </div>
                </Label>
                <Datetime
                  value={
                    moment(this.state.profile.beginDate, 'YYYY-MM-DD').isValid()
                      ? moment(this.state.profile.beginDate, 'YYYY-MM-DD')
                      : undefined
                  }
                  dateFormat={'YYYY-MM-DD'}
                  timeFormat={false}
                  onChange={this.onChangeBeginDate}
                  disabled={this.state.profile.beginDate === undefined}
                  maxDate
                />
                <Datetime
                  value={
                    moment(this.state.profile.beginTime, 'hh:mm A').isValid()
                      ? moment(this.state.profile.beginTime, 'hh:mm A')
                      : undefined
                  }
                  dateFormat={false}
                  timeFormat={'hh:mm A'}
                  onChange={this.onChangeBeginTime}
                />
                <select
                  className="form-control"
                  value={this.state.profile.datetimeOffset || ''}
                  name={'value'}
                  style={{
                    marginLeft: '10px',
                    maxWidth: '300px',
                    width: '45%',
                    minWidth: '45%',
                  }}
                  onChange={({ target: { value } }) => {
                    this.setState((state) => ({
                      profile: {
                        ...state.profile,
                        datetimeOffset: value,
                      },
                      changed: true,
                    }));
                  }}
                  placeholder={I18n.t('profiles.datetimeOffsetPlaceholder')}
                  disabled={this.state.profile.datetimeOffset === undefined}
                >
                  {this.state.profile.datetimeOffset === undefined ? (
                    <option value={''}>{''}</option>
                  ) : null}
                  {[...knownTimezones.entries()].map((tz) => {
                    return (
                      <option key={tz[0]} value={tz[0]}>
                        {tz[1] || tz[0]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label
                  check
                  style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: '1.17',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Input
                    type="checkbox"
                    name="check2"
                    value={this.state.profile.endDate !== undefined}
                    checked={this.state.profile.endDate !== undefined}
                    onChange={({ target: { checked } }) => {
                      this.setState((state) => {
                        return !checked
                          ? {
                              ...state,
                              profile: {
                                ...state.profile,
                                endDate: undefined,
                                endTime: undefined,
                              },
                              changed: true,
                            }
                          : {
                              ...state,
                              profile: {
                                ...state.profile,
                                endDate: state.profile.beginDate
                                  ? state.profile.beginDate
                                  : moment().format('YYYY-MM-DD'),
                                endTime: state.profile.beginTime
                                  ? state.profile.beginTime
                                  : moment().format('hh:mm A'),
                              },
                              changed: true,
                            };
                      });
                    }}
                    style={{ marginRight: '8px' }}
                  />{' '}
                  <div
                    style={{
                      display: 'flex',
                      width: '80px',
                      marginRight: '14px',
                    }}
                  >
                    {I18n.t('profiles.assignmentEndDateLabel')}
                  </div>
                </Label>
                <Datetime
                  dateFormat={'YYYY-MM-DD'}
                  timeFormat={false}
                  value={this.state.profile.endDate}
                  onChange={this.onChangeEndDate}
                />
                <Datetime
                  dateFormat={false}
                  timeFormat={'hh:mm A'}
                  value={this.state.profile.endTime}
                  onChange={this.onChangeEndTime}
                />
                <div
                  style={{
                    marginLeft: '10px',
                    maxWidth: '300px',
                    width: '45%',
                    minWidth: '45%',
                  }}
                >
                  {' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    ) : null;
  };

  getHeaderHint = () => {
    const { isEditingMode, selectedCategory } = this.state;
    let action;
    if (selectedCategory === PROFILE_CONFIG_CONSTANTS.FLEETS) {
      action = 'profiles.profileAssociationHint';
    } else if (this.isProfileCreation) {
      action = 'profiles.profileCreatingHint';
    } else if (isEditingMode) {
      action = 'profiles.profileEditingHint';
    } else {
      action = 'profiles.profileViewingHint';
    }
    return I18n.t(action);
  };

  handleVersionChange = ({ value }) => {
    history.push(`${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/${value}`);
  };

  getProfileVersionIcon = ({ published, newestVersion }) => {
    if (!published && !newestVersion) {
      return <TimeIconGray className="icon--fixed-square-small" />;
    }

    if (newestVersion && !published) {
      return <DraftIcon className="icon--fixed-square-small" />;
    }

    if (published) {
      return <CheckCircleIcon className="icon--fixed-square-small" />;
    }
  };

  renderProfileHeader = () => {
    const { isEditingMode } = this.state;
    const profile = isEditingMode ? this.state.profile : this.props.profile;
    const {
      name = '',
      published: isCurrentPublished = false,
      draft = false,
      versions = [],
      version: currentlyViewedVersion,
      newestVersion: isCurrentNewestVersion,
      id: profileId,
    } = profile ?? {};
    const isNewProfile = !profileId;
    const hint = this.getHeaderHint();
    const isEditingModeToggleIconAvailable = this.getEditingModeToggleIconAvailability();
    let statusTextCode;
    if (!isCurrentPublished && !draft && !this.isProfileCreation) {
      statusTextCode = 'profiles.unpublished';
    } else {
      statusTextCode =
        isCurrentPublished && !this.state.isEditingMode
          ? 'profiles.published'
          : 'profiles.draft';
    }

    const getOption = ({
      version,
      value,
      newestVersion,
      currentlyViewedVersion,
      published,
      text,
    }) => ({
      value,
      text:
        text ??
        `${I18n.t('profiles.version')} ${version} ${
          newestVersion && !published ? `(${I18n.t('profiles.draft')})` : ''
        }`,
      version,
      icon: this.getProfileVersionIcon({
        newestVersion,
        published,
      }),
    });

    const existingProfileOption = getOption({
      version: currentlyViewedVersion,
      value: profileId,
      newestVersion: isCurrentNewestVersion,
      currentlyViewedVersion,
      published: isCurrentPublished,
    });

    let fakeDraftOption = null;

    const newProfileOption = getOption({
      version: 1,
      value: 'new-profile-draft',
      newestVersion: true,
      currentlyViewedVersion: 1,
      published: false,
      text: I18n.t('profiles.draft'),
    });

    if (
      this.state.isEditingMode &&
      isCurrentPublished &&
      isCurrentNewestVersion
    ) {
      fakeDraftOption = getOption({
        version: currentlyViewedVersion + 1,
        value: 'fake-draft',
        newestVersion: true,
        currentlyViewedVersion,
        published: false,
      });
    }

    const currentProfileOption = isNewProfile
      ? newProfileOption
      : existingProfileOption;
    const selectedOption = fakeDraftOption ?? currentProfileOption;

    const profileVersionOptions = isEmpty(versions)
      ? [
          ...(this.state.isEditingMode && isNewProfile
            ? [newProfileOption]
            : [selectedOption]),
        ]
      : [
          ...(fakeDraftOption ? [fakeDraftOption] : []),
          currentProfileOption,
          ...versions.map(({ id, version, published, newestVersion }) =>
            getOption({
              value: id,
              version,
              newestVersion,
              currentlyViewedVersion,
              published,
            })
          ),
        ].sort((a, b) => b.version - a.version);

    return (
      <section className="profile-dialog-header">
        <DialogHeaderTitleSection
          isEditingMode={isEditingMode}
          titleText={name}
          titleHint={hint}
          defaultValue={name}
          placeholder={I18n.t('profiles.profileNameFieldPlaceholder')}
          onInputChange={this.onChangeRename}
          onKeyUp={this.onRenameKeyup}
          onReset={this.resetRenameInput}
          isEditingModeToggleIconAvailable={isEditingModeToggleIconAvailable}
          onEditingModeToggleIconClick={this.toggleEditingMode}
          statusComponent={
            <StatusDisplay
              text={I18n.t(statusTextCode)}
              positive={isCurrentPublished && !this.state.isEditingMode}
            />
          }
          hideSubmitIcon
        />

        <div className="profile-actions-section">
          <div className="profile-actions-section__item">
            {isLoadingStatusStarted(this.props.status) ? null : (
              <DropDownSelect
                options={profileVersionOptions}
                defaultValue={selectedOption}
                value={selectedOption}
                onChange={this.handleVersionChange}
                isDisabled={profileVersionOptions.length === 1 || isEditingMode}
                isSearchable={false}
              />
            )}
          </div>

          {isEditingMode ? (
            <>
              {/*more options*/}
              {/*{this.renderMoreOptionsPopup()}*/}

              {this.state.selectedCategory ===
              PROFILE_CONFIG_CONSTANTS.FLEETS ? (
                <Button
                  disabled={isEmpty(this.state.profile.fleets)}
                  onClick={this.onSaveAndPublish}
                  className="profile-actions-section__item"
                >
                  <Translate value="profiles.saveAndPublishButtonText" />
                </Button>
              ) : (
                <>
                  <Button
                    disabled={
                      !this.state.changed ||
                      this.props.status === NetworkStatus.STARTED ||
                      this.props.createProfileStatus ===
                        NetworkStatus.STARTED ||
                      this.props.updateProfileStatus === NetworkStatus.STARTED
                    }
                    onClick={this.onSave}
                    className="profile-actions-section__item"
                  >
                    <Translate value="profiles.saveButtonText" />
                  </Button>
                  <Button
                    disabled={
                      this.props.status === NetworkStatus.STARTED ||
                      this.props.createProfileStatus ===
                        NetworkStatus.STARTED ||
                      this.props.updateProfileStatus === NetworkStatus.STARTED
                    }
                    onClick={this.onShowAssignFleets}
                    className="profile-actions-section__item"
                  >
                    <Translate value="profiles.associateFleetsTitle" />
                  </Button>
                </>
              )}

              {/*undo button*/}
              <Button
                secondary
                disabled={
                  !this.state.changed ||
                  this.props.status === NetworkStatus.STARTED ||
                  this.props.createProfileStatus === NetworkStatus.STARTED ||
                  this.props.updateProfileStatus === NetworkStatus.STARTED
                }
                className="profile-actions-section__item"
                onClick={this.undoChanges}
              >
                <Translate value="common.undo" />
              </Button>
            </>
          ) : (
            <>
              {/*{published && (*/}
              {/*  <Button*/}
              {/*    className="profile-actions-section__item"*/}
              {/*    onClick={this.handleAssignFleets}*/}
              {/*    disabled={*/}
              {/*      this.state.selectedCategory ===*/}
              {/*        PROFILE_CONFIG_CONSTANTS.FLEETS &&*/}
              {/*      (!this.state.isFleetsAssignmentButtonActive ||*/}
              {/*        isEmpty(this.state.profile.fleets))*/}
              {/*    }*/}
              {/*  >*/}
              {/*    {this.state.selectedCategory ===*/}
              {/*    PROFILE_CONFIG_CONSTANTS.FLEETS ? (*/}
              {/*      <Translate value="profiles.saveAndPublishButtonText" />*/}
              {/*    ) : (*/}
              {/*      <Translate value="profiles.associateFleetsTitle" />*/}
              {/*    )}*/}
              {/*  </Button>*/}
              {/*)}*/}
            </>
          )}
          <>
            {/*actions dropdown*/}
            {/*{!isEditingMode &&*/}
            {/*this.state.selectedCategory !==*/}
            {/*  PROFILE_CONFIG_CONSTANTS.KIOSK_BUILDER &&*/}
            {/*this.state.selectedCategory !==*/}
            {/*  PROFILE_CONFIG_CONSTANTS.HTML_KIOSK_BUILDER ? (*/}
            {/*  <>*/}
            {/*    <DropDownMenu*/}
            {/*      triggerButtonText={*/}
            {/*        <Translate value="profiles.profileActions" />*/}
            {/*      }*/}
            {/*      triggerProps={{*/}
            {/*        secondary: true,*/}
            {/*      }}*/}
            {/*      items={[*/}
            {/*        {*/}
            {/*          onClick: this.onShowAssignFleets,*/}
            {/*          children: (*/}
            {/*            <Translate value="profiles.assignFleetsAndPublish" />*/}
            {/*          ),*/}
            {/*          hidden:*/}
            {/*            this.state.selectedCategory ===*/}
            {/*              PROFILE_CONFIG_CONSTANTS.FLEETS ||*/}
            {/*            published ||*/}
            {/*            this.isProfileCreation,*/}
            {/*        },*/}
            {/*        {*/}
            {/*          onClick: this.unpublishProfile,*/}
            {/*          children: <Translate value="profiles.unPublishProfile" />,*/}
            {/*          hidden: !published,*/}
            {/*        },*/}
            {/*      ]}*/}
            {/*    />*/}
            {/*  </>*/}
            {/*) : null}*/}
          </>
        </div>
      </section>
    );
  };

  onChangeBeginDate = (newDate) =>
    this.setState((state) => {
      const newDateMoment = moment(newDate, 'YYYY-MM-DD');
      let date = newDateMoment.isValid() ? newDateMoment : moment();
      let beginDate;
      let beginTime = '12:00 A';
      if (state.profile.beginTime) {
        const beginTimeMoment = moment(state.profile.beginTime, 'hh:mm A');
        if (beginTimeMoment.isValid()) {
          date
            .hours(beginTimeMoment.hours())
            .minutes(beginTimeMoment.minutes());
          beginTime = beginTimeMoment.format('hh:mm A');
        }
      }
      beginDate = date.format('YYYY-MM-DD');

      if (state.profile.endDate) {
        const endDateMoment = moment(state.profile.endDate, 'YYYY-MM-DD');
        if (state.profile.endTime) {
          const endTimeMoment = moment(this.state.profile.endTime, 'hh:mm A');
          if (endTimeMoment.isValid()) {
            endDateMoment
              .hours(endTimeMoment.hours())
              .minutes(endTimeMoment.minutes());
          }
        }
        if (endDateMoment.isValid() && endDateMoment.isBefore(date)) {
          beginDate = endDateMoment.format('YYYY-MM-DD');
          beginTime = endDateMoment.format('hh:mm A');
        }
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          beginDate,
          beginTime,
        },
        changed: true,
      };
    });

  onChangeBeginTime = (newDate) =>
    this.setState((state) => {
      const newDateMoment = moment(newDate, 'hh:mm A');
      let date = newDateMoment.isValid() ? newDateMoment : moment();
      let beginDate = moment().format('YYYY-MM-DD');
      let beginTime;
      if (state.profile.beginDate) {
        let beginDateMoment = moment(state.profile.beginDate, 'YYYY-MM-DD');
        if (beginDateMoment.isValid()) {
          date
            .year(beginDateMoment.year())
            .month(beginDateMoment.month())
            .date(beginDateMoment.date());
          beginDate = date.format('YYYY-MM-DD');
        }
      }
      beginTime = date.format('hh:mm A');
      if (state.profile.endDate) {
        let endDate = moment(state.profile.endDate, 'YYYY-MM-DD');
        if (state.profile.endTime) {
          const endTime = moment(this.state.profile.endTime, 'hh:mm A');
          if (endTime.isValid()) {
            endDate.hours(endTime.hours()).minutes(endTime.minutes());
          }
        }
        if (endDate.isBefore(date)) {
          beginDate = endDate.format('YYYY-MM-DD');
          beginTime = endDate.format('hh:mm A');
        }
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          beginDate,
          beginTime,
        },
        changed: true,
      };
    });

  onChangeEndDate = (newDate) =>
    this.setState((state) => {
      let date = moment(newDate, 'YYYY-MM-DD');
      let endDate;
      let endTime = '12:00 AM';
      if (state.profile.endTime) {
        const endTimeMoment = moment(state.profile.endTime, 'hh:mm A');
        if (endTimeMoment.isValid()) {
          date.hours(endTimeMoment.hours()).minutes(endTimeMoment.minutes());
          endTime = date.format('hh:mm A');
        }
      }
      endDate = date.format('YYYY-MM-DD');

      if (state.profile.beginDate) {
        const beginDate = moment(state.profile.beginDate, 'YYYY-MM-DD');
        if (state.profile.beginTime) {
          const beginTime = moment(this.state.profile.beginTime, 'hh:mm A');
          if (beginTime.isValid()) {
            beginDate.hours(beginTime.hours()).minutes(beginTime.minutes());
          }
        }
        if (beginDate.isAfter(date)) {
          endDate = beginDate.format('YYYY-MM-DD');
          endTime = beginDate.format('hh:mm A');
        }
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          endDate,
          endTime,
        },
        changed: true,
      };
    });

  onChangeEndTime = (newDate) =>
    this.setState((state) => {
      let date = moment(newDate, 'hh:mm A');
      let endDate = moment().format('YYYY-MM-DD');
      let endTime;
      if (state.profile.endDate) {
        const endDateMoment = moment(state.profile.endDate, 'YYYY-MM-DD');
        if (endDateMoment.isValid()) {
          date
            .year(endDateMoment.year())
            .month(endDateMoment.month())
            .date(endDateMoment.date());
          endDate = endDateMoment.format('YYYY-MM-DD');
        }
      }
      endTime = date.format('hh:mm A');
      if (state.profile.beginDate) {
        const beginDate = moment(state.profile.beginDate, 'YYYY-MM-DD');
        if (state.profile.beginTime) {
          const beginTime = moment(this.state.profile.beginTime, 'hh:mm A');
          if (beginTime.isValid()) {
            beginDate.hours(beginTime.hours()).minutes(beginTime.minutes());
          }
        }
        if (beginDate.isAfter(date)) {
          endDate = beginDate.format('YYYY-MM-DD');
          endTime = beginDate.format('hh:mm A');
        }
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          endDate,
          endTime,
        },
        changed: true,
      };
    });

  onSelectFleet = (selectedKeys) =>
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        fleets: [...selectedKeys],
      },
      changed: true,
      isFleetsAssignmentButtonActive: true,
    }));

  renderProfileMenu = () => {
    if (
      this.props.status === NetworkStatus.STARTED ||
      this.props.createProfileStatus === NetworkStatus.STARTED ||
      this.props.updateProfileStatus === NetworkStatus.STARTED
    ) {
      return null;
    }
    if (this.state.selectedCategory === PROFILE_CONFIG_CONSTANTS.FLEETS) {
      return (
        <React.Fragment>
          {assignMenuStructure.map(this.renderMenuItem)}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.props.accessibleMenuItems.map(this.renderMenuItem)}
      </React.Fragment>
    );
  };

  renderMenuItem = (item) => {
    const hasSubItems = item.sub && item.sub.length;
    const itemClass = selectForCurrentBrand({
      original: 'profile-menu__item',
      incube: 'profile-menu__item--incube',
      janam: 'profile-menu__item--janam',
    });
    const activeItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--active',
      incube: 'profile-menu__item--incube--active',
      janam: 'profile-menu__item--janam--active',
    });
    const intermediateItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--indeterminate',
      incube: 'profile-menu__item--incube--indeterminate',
      janam: 'profile-menu__item--janam--indeterminate',
    });
    const itemClasses = ClassNames(itemClass, {
      [activeItemClass]: this.state.selectedCategory === item.key,
      [intermediateItemClass]:
        hasSubItems &&
        item.sub.some((subItem) => subItem.key === this.state.selectedCategory),
    });
    const clickHandler = (e) =>
      this.selectCategory(hasSubItems ? item.sub[0].key : item.key);
    return (
      <Fragment key={item.key}>
        <h4
          id={hasSubItems ? `${item.key}` : undefined}
          className={itemClasses}
          onClick={clickHandler}
        >
          {item.label ? item.label : item.key}
        </h4>
        {hasSubItems && (
          <div className={'profile-menu__item--sub'}>
            <UncontrolledCollapse toggler={`#${item.key}`}>
              <div className={'profile-menu__items-list'}>
                {item.sub.map((item) => this.renderMenuItem(item))}
              </div>
            </UncontrolledCollapse>
          </div>
        )}
      </Fragment>
    );
  };
  /** TODO: replace with the react router switch **/
  renderProfileContent = () => {
    if (
      this.props.status === NetworkStatus.STARTED ||
      this.props.createProfileStatus === NetworkStatus.STARTED ||
      this.props.updateProfileStatus === NetworkStatus.STARTED ||
      this.props.applicationsFullListStatus === NetworkStatus.STARTED
    ) {
      return <Throbber />;
    }

    const { isEditingMode } = this.state;
    const profile = isEditingMode
      ? this.state.profile
      : this.props.profile ?? {};

    if (this.state.selectedCategory === PROFILE_CONFIG_CONSTANTS.FLEETS) {
      return (
        <AsyncRendererWithDataFetching
          dataList={[
            {
              id: DataConstants.FLEETS_V2,
            },
          ]}
        >
          <FleetsTree
            fleets={this.props.fleets || []}
            onSelect={this.onSelectFleet}
            defaultSelectedKeys={this.state.profile.fleets || []}
            selectedKeys={this.state.profile.fleets || []}
            multiple
          />
        </AsyncRendererWithDataFetching>
      );
    }
    const { component: SelectedComponent, policyKey } = Tabs[
      this.state.selectedCategory
    ];
    const policy = notEmpty(profile.policies?.[policyKey])
      ? profile.policies[policyKey]
      : [];
    return (
      <SelectedComponent
        profile={profile}
        fleetNames={profile.fleetNames}
        policy={policy}
        policies={profile.policies}
        onChange={this.onChangePolicyDetails}
        onChangePolicyProperty={this.onChangePolicyProperty}
        onChangeProfileProperty={this.onChangeProfileProperty}
        isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
        applications={this.props.applicationsFullList}
        applicationsFullList={this.props.applicationsFullList}
        certificates={this.props.certificates || []}
        showBuilder={this.showBuilder}
        showHtmlKioskBuilder={this.showHtmlKioskBuilder}
        files={this.props.files || []}
        fleets={this.props.fleets}
        timezones={this.props.timezones || []}
        policyKey={policyKey}
      />
    );
  };

  getProfileIdForEditing = () => {
    const { profile } = this.state;
    const latestDraftProfile = [...profile.versions]
      .reverse()
      .find(({ published }) => typeof published === 'boolean' && !published);
    const latestProfile = profile.versions[profile.versions.length - 1];
    return latestDraftProfile?.id ?? latestProfile?.id;
  };

  toggleEditingMode = (e) => {
    const { profile: { versions, newestVersion, published } = {} } = this.state;
    if (notEmpty(versions) && !newestVersion) {
      const idForEditing = this.getProfileIdForEditing();
      this.loadProfileData(idForEditing, () => {
        this.setState({ isEditingMode: true });
        RouteHelpers.replaceURLState(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/${idForEditing}`
        );
      });
    } else {
      this.setState({
        isEditingMode: true,
      });
    }
  };

  onChangeRename = ({ target: { value } }) =>
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        name: value,
      },
      changed: true,
    }));

  onRenameKeyup = (e) => {
    if (e.keyCode === 27) {
      // Esc key
      this.resetRenameInput();
    }
  };

  resetRenameInput = () => {
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        name: this.props.profile?.name ?? '',
      },
    }));
  };

  selectCategory = (selectedCategory) => {
    this.setState({ selectedCategory });
  };

  onChangeProfileProperty = (property, value) => {
    this.setState((prevState) => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        [property]: value,
      },
      changed: true,
    }));
  };

  onChangePolicyDetails = (key, index, value) => {
    this.setState((prevState) => {
      let policies = cloneDeep({ ...prevState.profile.policies });
      if (value !== null) {
        policies[key][index] = value;
      } else if (policies[key] && policies[key].length) {
        policies[key].splice(index, 1);
      }
      return {
        ...prevState,
        profile: {
          ...prevState.profile,
          policies,
        },
        changed: true,
      };
    });
  };

  onChangePolicyProperty = ({
    key,
    index,
    propertyName,
    value,
    initialPolicy = {},
    initialSettings = {},
  }) => {
    this.setState((prevState) => {
      let policies = cloneDeep({ ...prevState.profile.policies });
      if (value !== null && policies[key][index]) {
        policies[key][index][propertyName] = value;
      } else {
        policies[key][0] = {
          ...initialPolicy,
          settings: {
            ...initialSettings,
          },
          [propertyName]: value,
        };
      }
      return {
        profile: {
          ...prevState.profile,
          policies,
        },
        changed: true,
      };
    });
  };

  unpublishProfile = () => {
    const { profile: { id } = {} } = this.state;
    PopupActions.showConfirm({
      onConfirm: () => this.props.unpublishProfile(id),
      text: I18n.t('profiles.unpublishProfileConfirmationText'),
    });
  };

  onShowAssignFleets = () =>
    this.setState({ selectedCategory: PROFILE_CONFIG_CONSTANTS.FLEETS });

  assignFleets = () => {
    const { profile: { fleets, id } = {} } = this.state;
    this.setState(
      () => ({ isFleetsAssignmentButtonActive: false }),
      () =>
        this.props.assignFleets({
          id,
          fleets,
        })
    );
  };

  handleAssignFleets = () =>
    this.state.selectedCategory === PROFILE_CONFIG_CONSTANTS.FLEETS
      ? this.assignFleets()
      : this.onShowAssignFleets();

  undoChanges = () => {
    this.setState({
      profile: this.initializeProfile(this.props),
      changed: false,
    });
  };

  onCreateNewVersion = () => {
    this.props.createNewProfileVersion(this.state.profile);
    this.setState({ changed: false });
  };

  onSave = () => {
    const { profile } = this.state;
    let analyticsAction;
    if (profile.id) {
      // is existing profile
      if (profile.published) {
        // is published profile
        analyticsAction = AnalyticsProfileConstants.CREATED_NEW_VERSION;
        // create new version
        this.props.createNewProfileVersion(profile);
      } else {
        // is not published
        analyticsAction = AnalyticsProfileConstants.SAVED_AS_DRAFT;
        // save as draft
        this.props.saveAsDraft(profile);
      }
    } else {
      // is brand new
      analyticsAction = AnalyticsProfileConstants.CREATED_BRAND_NEW;
      this.props.createBrandNewProfile(profile);
    }
    AnalyticsService.event({
      category: AnalyticsCategory.PROFILE,
      action: analyticsAction,
      transport: AnalyticsService.eventTransportMechanism.BEACON,
    });
    this.selectCategory(this.props.accessibleMenuItems[0].key);
    this.setState({ changed: false, isEditingMode: false });
  };

  onSaveAndPublish = () => {
    const { profile } = this.state;
    let analyticsAction;
    if (profile.id) {
      // is existing profile
      if (profile.published) {
        // is published profile
        analyticsAction =
          AnalyticsProfileConstants.CREATED_NEW_VERSION_AND_PUBLISH;
        this.props.createNewVersionAndPublish(profile);
      } else {
        // save_and_publish
        analyticsAction = AnalyticsProfileConstants.SAVED_AND_PUBLISHED;
        this.props.saveAndPublishProfile(profile);
      }
    } else {
      // is brand new profile
      analyticsAction =
        AnalyticsProfileConstants.CREATED_BRAND_NEW_AND_PUBLISHED;
      this.props.createBrandNewAndPublishProfile(profile);
    }
    AnalyticsService.event({
      category: AnalyticsCategory.PROFILE,
      action: analyticsAction,
      transport: AnalyticsService.eventTransportMechanism.BEACON,
    });
    this.selectCategory(this.props.accessibleMenuItems[0].key);
    this.setState({ changed: false, isEditingMode: false });
  };

  onBack = () => {
    const { isEditingMode, selectedCategory } = this.state;
    if (this.state.selectedCategory === PROFILE_CONFIG_CONSTANTS.FLEETS) {
      this.selectCategory(this.props.accessibleMenuItems[0].key);
    } else if (
      selectedCategory === PROFILE_CONFIG_CONSTANTS.KIOSK_BUILDER ||
      selectedCategory === PROFILE_CONFIG_CONSTANTS.HTML_KIOSK_BUILDER
    ) {
      this.selectCategory(PROFILE_CONFIG_CONSTANTS.KIOSK_MODE);
    } else if (isEditingMode && !this.isProfileCreation) {
      this.setState({ isEditingMode: false });
    } else if (!isEditingMode) {
      history.push(`${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}`);
    } else {
      history.goBack();
    }
  };

  showBuilder = () =>
    this.selectCategory(PROFILE_CONFIG_CONSTANTS.KIOSK_BUILDER);

  showHtmlKioskBuilder = () =>
    this.selectCategory(PROFILE_CONFIG_CONSTANTS.HTML_KIOSK_BUILDER);

  confirmProfileCreationOrProfileEditingModeAndEditAccess = () => {
    const { hasAdminPrivileges = false, accessibleMenuItems } = this.props;
    const { isEditingMode } = this.state;
    const isProfileCreationOrEditMode = this.isProfileCreation || isEditingMode;
    const { readWrite: hasReadWriteAccess } =
      accessibleMenuItems.find(
        ({ key }) => key === this.state.selectedCategory
      ) || {};
    const doesUserHaveAccess = hasAdminPrivileges || hasReadWriteAccess;
    return isProfileCreationOrEditMode && doesUserHaveAccess;
  };
}

const mapStateToProps = (state) => {
  const {
    account: { globalPermissionsForUserStatus },
  } = state;
  const globalPermissionsForUser = globalPermissionsForUserSelector(state);
  const hasAdminPrivileges = UserHelpers.hasAdminPrivileges(state);

  const accessibleMenuItems =
    globalPermissionsForUserStatus !== NetworkStatus.DONE
      ? defaultProfileMenuStructure
      : // : getAccessibleMenuItems(profileMenuStructure, globalPermissionsMock);
        getAccessibleMenuItems(
          profileMenuStructure,
          globalPermissionsForUser,
          hasAdminPrivileges
        );

  const {
    data: profile,
    status,
    error,
    createProfileStatus,
    createProfileError,
    updateProfileStatus,
    updateProfileError,
  } = profileV2Selector(state);
  const {
    data: fleets,
    status: fleetsStatus,
    error: fleetsError,
  } = fleetsV2Selector(state);

  const {
    applicationsFullList,
    applicationsFullListError,
    applicationsFullListStatus,
  } = applicationsSelector(state);

  return {
    profile,
    status,
    error,
    createProfileStatus,
    createProfileError,
    updateProfileStatus,
    updateProfileError,
    companyId: companyIdSelector(state),
    fleets,
    fleetsStatus,
    fleetsError,
    files: state.files.data,
    filesLoading: state.files.loading,
    certificates: state.certificates.data,
    certificatesLoadingStatus: state.certificates.loading,
    timezones: state.settings.data.timezones,
    accessibleMenuItems,
    globalPermissionsForUserStatus,
    globalPermissionsForUser,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    applicationsFullList,
    applicationsFullListError,
    applicationsFullListStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadProfileById: (profileId, onSuccess) =>
      dispatch(loadProfileById(profileId, onSuccess)),
    loadProfileApps: (profileId) => dispatch(loadProfileApps(profileId)),
    assignFleets: (params) => dispatch(assignFleets(params)),
    unpublishProfile: (profileId) => dispatch(unpublishProfile(profileId)),
    loadCertificates: (companyId) => dispatch(loadCertificates(companyId)),
    resetProfile: () => dispatch(profileV2Reset()),
    createNewProfileVersion: (newProfile) =>
      dispatch(createNewProfileVersion(newProfile)),
    saveAndPublishProfile: (newProfile) =>
      dispatch(saveAndPublishProfile(newProfile)),
    createBrandNewAndPublishProfile: (newProfile) =>
      dispatch(createBrandNewAndPublishProfile(newProfile)),
    createNewVersionAndPublish: (newProfile) =>
      dispatch(createNewVersionAndPublish(newProfile)),
    saveAsDraft: (newProfile) => dispatch(saveAsDraft(newProfile)),
    createBrandNewProfile: (newProfile) =>
      dispatch(createBrandNewProfile(newProfile)),
    // todo: replace with new version
    createProfileReset: () => dispatch(createProfileReset()),
    // todo: replace with new version
    updateProfileReset: () => dispatch(updateProfileReset()),
    loadAllFleets: () => dispatch(loadAllFleetsV2()),
    loadAllFiles: (companyId) => dispatch(loadAllFiles(companyId)),
    loadApplicationsFullList: () => dispatch(loadApplicationsFullList()),
    loadGlobalPermissionsForUser: () =>
      dispatch(loadGlobalPermissionsForUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
