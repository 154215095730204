import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  AlertTypeOptions,
  DEFAULT_CONDITION_VALUES,
} from '../../features/createAlert/createAlert.constants';
import { SectionDescription } from '../sectionDescription.component';
import { SelectField } from '../selectField.component';

const AlertType = ({ alert, onPropertyChange }) => {
  const onAlertTypeChange = ({ target: { value } = {} }) => {
    const defaultCondition = DEFAULT_CONDITION_VALUES[value];
    // resetting previously set alertCondition value (if any) as well as setting default alertCondition for the selected
    // value was done to make sure that a default value is always set for alertCondition and also to clear the previously selected
    // value in case that the user decides to come back and change the alert type.
    onPropertyChange({
      key: 'alertCondition',
      value: {
        ...defaultCondition,
      },
    });
    onPropertyChange({ key: 'alertType', value });
  };

  return (
    <React.Fragment>
      <SectionDescription title={I18n.t('alerts.alertTypeHeading')} />
      <SelectField
        title={I18n.t('alerts.alertTypeHeading')}
        value={alert.alertType}
        onChange={onAlertTypeChange}
        options={AlertTypeOptions}
      />
    </React.Fragment>
  );
};

export default AlertType;
