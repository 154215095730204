import React, { Component, Fragment } from 'react';
import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import { v4 as uuid } from 'uuid';
import { NetworkStatus } from '../../common/constants';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { companyIdSelector } from '../login/login.selectors';
import { Button } from '../../components/button.component';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import { resetUpload } from './upload.action';
import { sendLocationLinks, uploadX } from './upload.thunk';
import { Redirect } from 'react-router-dom';
import {
  AddFileRoute,
  addFilesMenuStructure,
  addFileSystemItemConfigs,
  saveLocations,
  uploadTypes,
} from './upload.constants';
import PopupActions from '../popup/popup.actions';
import { bytesToSize, isDevServer, notEmpty } from '../../common/helpers';
import { TextButton } from '../../components/textButton.component';
import { ReactComponent as PlusCircle } from '../../assets/images/plus-circle.svg';
import { ReactComponent as Crest } from '../../assets/images/crest.svg';
import { InputField } from '../../components/inputField.component';
import { cloneDeep } from 'lodash';
import RouteHelpers from '../../common/utilities/routeHelpers';
import StyleUtils from '../../utils/styleUtils';

class Upload extends Component {
  state = {
    fileLocationLinkItems: [
      {
        id: uuid(),
        value: '',
      },
    ],
  };

  componentWillUnmount() {
    this.resetUploadingStatus();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.assignationStatus !== prevProps.assignationStatus &&
      (this.props.assignationStatus === 'done' ||
        this.props.assignationStatus === 'error')
    ) {
      this.onChangeStatus();
    }
    // TODO: implement logic to handle the case if the user leave uploading page when uploading in progress
  }

  onChangeStatus = (type, text, status) => {
    if (status) {
      let color =
        status === 'done' ? 'green' : status === 'error' ? 'red' : '#222';
      let title;
      switch (type) {
        case uploadTypes.APK:
          title = I18n.t('fileSystem.apkUploading');
          break;
        case uploadTypes.FILES:
          title = I18n.t('fileSystem.filesUploading');
          break;
        case uploadTypes.CERTIFICATES:
          title = I18n.t('fileSystem.certificatesUploading');
          break;
        case uploadTypes.HTML_KIOSK:
          title = I18n.t('fileSystem.htmlKioskFilesUploading');
          break;
        default:
          title = '';
          break;
      }
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="create-enrollment-alert">
              <p>{title}</p>
              <p style={{ color: color }}>{text}</p>
              <Button onClick={onClose}>
                <Translate value="common.ok" />{' '}
              </Button>
            </div>
          );
        },
      });
      this.props.resetUpload();
    }
  };

  resetUploadingStatus = () => {
    if (
      this.props.uploadingStatus === NetworkStatus.DONE ||
      this.props.uploadingStatus === NetworkStatus.ERROR
    ) {
      this.props.resetUpload();
    }
  };

  uploadApplications = (files) =>
    this.props.upload(files, saveLocations.APPLICATIONS);

  uploadFiles = (files) => this.props.upload(files, saveLocations.FILES);

  uploadCertificates = (certificates) =>
    this.props.upload(certificates, saveLocations.CERTIFICATES);

  uploadHTMLKioskFiles = (htmlFiles) =>
    this.props.upload(htmlFiles, saveLocations.HTML);

  addNewInputForExternalFileSystemLinkItems = () =>
    this.setState((prevState) => ({
      fileLocationLinkItems: [
        ...prevState.fileLocationLinkItems,
        {
          id: uuid(),
          value: '',
        },
      ],
    }));

  removeInputForExternalFileSystemLinkItems = (itemId) =>
    this.setState(() => ({
      fileLocationLinkItems: this.state.fileLocationLinkItems.filter(
        ({ id }) => itemId !== id
      ),
    }));

  renderTitle = () => {
    const type = this.props.match.params.type;
    const { tabTitle } = addFilesMenuStructure.find(
      (currentValue) => currentValue.key === type
    );
    return tabTitle.toUpperCase();
  };

  render() {
    const type = this.props.match.params.type;
    if (!type || addFilesMenuStructure.every((item) => item.key !== type)) {
      return <Redirect to={`${AddFileRoute}/${uploadTypes.APK}`} />;
    }

    return (
      <div className={'app__content'}>
        <div className={'page-content'}>
          <div className={'card card--left-hand'}>
            <div className={'left-hand-pane-card__header'}>{''}</div>
            <div
              className={'left-hand-pane-card__content'}
              style={{ flexFlow: 'column' }}
            >
              <DialogMenu structure={addFilesMenuStructure} />
            </div>
          </div>
          <div className={'card card--right-hand'}>
            <div className={'card-header'}>
              <div className={'card-header__title'}>{this.renderTitle()}</div>
            </div>
            <div
              className={'card-content'}
              style={{ flexDirection: 'column', padding: '20px' }}
            >
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  onUploadRejection = ({ rejectFiles = [], type }) => {
    const { uploadRejectionMessage, maxSize } =
      addFileSystemItemConfigs[type] ?? {};
    const message = uploadRejectionMessage
      ? I18n.t(uploadRejectionMessage, {
          max: bytesToSize(maxSize),
          allowedFileTypes:
            addFileSystemItemConfigs[type]?.allowedTypes?.join(', ') ?? 'any',
        })
      : '';
    const errorList = [message];
    const fileList = [];
    rejectFiles.forEach(({ name, size, type }) =>
      fileList.push(`${name} (${bytesToSize(size)}, ${type})`)
    );
    return PopupActions.showAlert({
      textList: [...errorList, ...fileList],
    });
  };

  renderContent = () => {
    const { uploadingProgress, uploadingStatus } = this.props;
    const { type } = this.props.match.params;

    const acceptedFilesUploadHandlers = {
      [uploadTypes.APK]: this.uploadApplications,
      [uploadTypes.FILES]: this.uploadFiles,
      [uploadTypes.CERTIFICATES]: this.uploadCertificates,
      [uploadTypes.HTML_KIOSK]: this.uploadHTMLKioskFiles,
    };

    const {
      dropZoneTitle,
      addLinkTitle,
      uploadingSuccessMessage,
      maxSize,
      allowMultipleFiles,
      allowedTypes,
    } = addFileSystemItemConfigs[type] ?? {};

    return (
      <>
        {this.renderUploader({
          uploadingStatus: uploadingStatus,
          uploadingSuccessMessage: I18n.t(uploadingSuccessMessage),
          uploadingProgress,
          dropZoneTitle: I18n.t(dropZoneTitle),
          dropZoneProps: {
            multiple: allowMultipleFiles,
            maxSize,
            ...(notEmpty(allowedTypes) ? { accept: allowedTypes } : {}),
            onDropAccepted: acceptedFilesUploadHandlers[type],
            onDropRejected: (rejectFiles) =>
              this.onUploadRejection({
                rejectFiles,
                type,
              }),
          },
        })}
        <>
          <div className="upload__section-separator">{I18n.t('common.or')}</div>
          {this.renderLinkAdder({
            addLinkTitle: I18n.t(addLinkTitle),
          })}
        </>
      </>
    );
  };

  renderFilesProgressBar = (file, index) => (
    <div className={'upload-progress-cell-container'}>
      <div className={'upload-progress-cell-container-cell'}>
        <div className={'upload-progress-cell-container-cell__title'}>
          {file.key || ''}
        </div>
        <ProgressBar
          key={file.key}
          active
          now={file.uploadPercentage}
          className={'upload-progress-cell-container-cell__progress-bar'}
          label={
            !isNaN(Number(file.uploadPercentage)) &&
            Number(file.uploadPercentage) >= 0 &&
            Number(file.uploadPercentage) <= 100
              ? `${file.uploadPercentage} %`
              : undefined
          }
        />
      </div>
    </div>
  );

  onLinkInputChange = (e) => {
    const {
      target: { value, name },
    } = e;
    this.setState((prevState) => {
      const itemIndex = prevState.fileLocationLinkItems.findIndex(
        ({ id }) => id === name
      );
      if (itemIndex > -1) {
        const externalFileSystemLinkItemsClone = cloneDeep(
          prevState.fileLocationLinkItems
        );
        externalFileSystemLinkItemsClone[itemIndex] = { value, id: name };
        return { fileLocationLinkItems: externalFileSystemLinkItemsClone };
      }
      return prevState;
    });
  };

  sendApplicationLocationLinks = () =>
    this.props.sendLocationLinks(
      this.state.fileLocationLinkItems,
      saveLocations.APPLICATIONS
    );

  sendFileLocationLinks = () =>
    this.props.sendLocationLinks(
      this.state.fileLocationLinkItems,
      saveLocations.FILES
    );

  sendCertificateLocationLinks = () =>
    this.props.sendLocationLinks(
      this.state.fileLocationLinkItems,
      saveLocations.CERTIFICATES
    );

  sendHTMLKioskFileLocationLinks = () =>
    this.props.sendLocationLinks(
      this.state.fileLocationLinkItems,
      saveLocations.HTML
    );

  renderLinkAdder = ({ addLinkTitle } = {}) => {
    const type = RouteHelpers.getUrlParameter(this.props, 'type');
    const linksUploadHandlers = {
      [uploadTypes.APK]: this.sendApplicationLocationLinks,
      [uploadTypes.FILES]: this.sendFileLocationLinks,
      [uploadTypes.CERTIFICATES]: this.sendCertificateLocationLinks,
      [uploadTypes.HTML_KIOSK]: this.sendHTMLKioskFileLocationLinks,
    };

    const addClickHandler = linksUploadHandlers[type];

    return (
      <div className="upload__add-link-container">
        <div className="upload__add-link-title">{addLinkTitle}</div>
        <div className="upload__add-link-input-button-container">
          <TextButton
            onClick={this.addNewInputForExternalFileSystemLinkItems}
            title={I18n.t('fileSystem.addTitle')}
            noMargin
          >
            <PlusCircle />
          </TextButton>
        </div>
        <div className="upload__add-link-inputs-wrapper">
          {this.state.fileLocationLinkItems.map((item) => (
            <div className="upload__add-link-input-container">
              <InputField
                value={item.value}
                key={item.id}
                name={item.id}
                onChange={this.onLinkInputChange}
                additionalClassName="upload__add-link-input"
              />
              <Crest
                onClick={() =>
                  this.removeInputForExternalFileSystemLinkItems(item.id)
                }
                className="icon icon--fixed-square icon--danger upload__add-link-input-remove-icon"
              />
            </div>
          ))}
        </div>
        <Button onClick={addClickHandler}>
          {I18n.t('fileSystem.addTitle')}
        </Button>
      </div>
    );
  };

  renderUploader = ({
    uploadingStatus,
    uploadingError,
    uploadingSuccessMessage,
    uploadingProgress,
    dropZoneTitle,
    dropZoneProps,
  }) => {
    return (
      <Fragment>
        {uploadingStatus === NetworkStatus.DONE ? (
          <div>{uploadingSuccessMessage}</div>
        ) : null}

        {uploadingStatus === NetworkStatus.ERROR && !uploadingError ? (
          <div>
            <Translate value="common.error" />
          </div>
        ) : uploadingStatus === NetworkStatus.ERROR && uploadingError ? (
          <div>{uploadingError}</div>
        ) : null}

        {uploadingStatus === NetworkStatus.STARTED && !uploadingProgress ? (
          <div
            style={{
              marginLeft: '20px',
              marginBottom: '20px',
            }}
          >
            <Translate value="fileSystem.registeringFilesOnServer" />
          </div>
        ) : uploadingStatus === NetworkStatus.STARTED && uploadingProgress ? (
          <div
            style={{
              marginLeft: '20px',
              marginBottom: '20px',
            }}
          >
            <Translate value="fileSystem.uploading" />
          </div>
        ) : null}

        {uploadingStatus === NetworkStatus.STARTED &&
        uploadingProgress !== undefined ? (
          <div className={'upload-progress'}>
            {Array.isArray(uploadingProgress)
              ? uploadingProgress.map(this.renderFilesProgressBar)
              : this.renderFilesProgressBar({
                  key: '',
                  uploadPercentage: uploadingProgress,
                })}
          </div>
        ) : (
          <div className="upload">
            <Dropzone {...dropZoneProps} className={'upload-dropzone'}>
              <Glyphicon
                className={'upload-dropzone__icon'}
                glyph="cloud-upload"
              />
              <div className={'upload-dropzone__title'}>{dropZoneTitle}</div>
              <div className={'upload-dropzone__button'}>
                <Button>
                  <Translate value="fileSystem.browse" />
                </Button>
              </div>
            </Dropzone>
          </div>
        )}
      </Fragment>
    );
  };
}

Upload.propTypes = {
  style: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    companyId: companyIdSelector(state),
    uploadingStatus: state.upload.uploadingStatus,
    uploadingProgress: state.upload.uploadingProgress,
    uploadingError: state.upload.uploadingError,
    assignationStatus: state.files.assignationStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendLocationLinks: (files, saveLocation) =>
      dispatch(sendLocationLinks(files, saveLocation)),
    upload: (files, saveLocation, companyId) =>
      dispatch(uploadX(files, saveLocation, companyId)),
    resetUpload: () => dispatch(resetUpload()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
