import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { getDeviceInfoSectionsItems } from '../device.helpers';

export class Overview extends Component {
  renderNewOverview = () => {
    const { fleets, item = {} } = this.props;
    const deviceInfoSections = getDeviceInfoSectionsItems({
      device: item,
      fleets,
    });
    return (
      <section className="overview--container">
        {deviceInfoSections.map(({ id, headerTitle, items }) => (
          <ReadOnlyForm
            additionalClassName="overview--section"
            key={`device-overview-info-section-${id}`}
            headerTitle={headerTitle}
            items={items}
          />
        ))}
      </section>
    );
  };

  render() {
    return <div className={'overview'}>{this.renderNewOverview()}</div>;
  }
}

export default Overview;

Overview.propTypes = {
  item: PropTypes.object,
};
