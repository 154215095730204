import React from 'react';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as TrashEmpty } from '../../../assets/images/trash_empty.svg';
import { ReactComponent as MobileTextMinusCircle } from '../../../assets/images/mobile_text_minus_circle.svg';
import { ReactComponent as DuplicateIcon } from '../../../assets/images/duplicate.svg';
import Tool from '../../../components/ToolBar/Tool';
import ToolsPanel from '../../../components/ToolsPanel';
import PopupActions from '../../popup/popup.actions';
import { isEmpty } from '../../../common/helpers';

const ProfilesActions = ({
  selectedProfiles,
  cloneProfiles,
  deleteProfiles,
  unpublishAndClearFleets,
  profilesData,
  resetSelection,
}) => {
  const hasAdminPrivileges = true;
  const selected = [...selectedProfiles.values()];
  const profileActions = [
    {
      id: 'CLONE_PROFILES',
      titleCode: 'profiles.cloneProfiles',
      confirmationTitleCode: 'profiles.cloneProfiles',
      confirmationTextCode: 'profiles.cloneProfilesConfirmationText',
      onConfirm: () => cloneProfiles(selected),
      isActionAvailable: () => true,
      hidden: !hasAdminPrivileges,
      icon: DuplicateIcon,
    },
    {
      id: 'REMOVE_PROFILES',
      titleCode: 'profiles.removeProfiles',
      confirmationTitleCode: 'profiles.removeProfiles',
      confirmationTextCode: 'profiles.removeProfilesConfirmationText',
      onConfirm: () => deleteProfiles(selected),
      // isActionAvailable: () => true,
      isActionAvailable: () => {
        const selectedPublishedProfiles = profilesData?.filter(
          ({ published, id }) => selected.includes(id) && published
        );
        return isEmpty(selectedPublishedProfiles);
      },
      actionNotAvailableErrorTextCode:
        'profiles.profileRemovalNotAllowedForPublishedProfiles',
      hidden: !hasAdminPrivileges,
      icon: TrashEmpty,
    },
    {
      id: 'UNPUBLISH_PROFILES',
      titleCode: 'profiles.unpublishProfilesTitle',
      confirmationTitleCode: 'profiles.unpublishProfilesTitle',
      confirmationTextCode: 'profiles.unpublishProfilesConfirmationText',
      confirmationTextList: [
        I18n.t('profiles.unpublishProfilesConfirmationConsequence'),
      ],
      isActionAvailable: () => true,
      onConfirm: () => unpublishAndClearFleets(selected),
      hidden: !hasAdminPrivileges,
      icon: MobileTextMinusCircle,
    },
  ];

  const items = profileActions.map(
    ({
      id,
      titleCode,
      confirmationTitleCode,
      confirmationTextCode,
      confirmationTextDescriptionCode,
      confirmationTextList = [],
      onConfirm,
      hidden,
      icon,
      isActionAvailable,
      actionNotAvailableErrorTextCode,
    }) => {
      const confirmationText = [I18n.t(confirmationTextCode)];

      if (confirmationTextDescriptionCode) {
        confirmationText.push(I18n.t(confirmationTextDescriptionCode));
      }

      const clickHandler = () =>
        isActionAvailable()
          ? PopupActions.showConfirm({
              id,
              textList: [...confirmationText, ...confirmationTextList],
              title: I18n.t(confirmationTitleCode),
              onConfirm: () => {
                onConfirm();
                resetSelection();
              },
            })
          : PopupActions.showAlert({
              id,
              title: I18n.t(confirmationTitleCode),
              text: I18n.t(actionNotAvailableErrorTextCode),
            });

      return {
        title: I18n.t(titleCode),
        onClick: clickHandler,
        key: titleCode,
        hidden,
        icon,
      };
    }
  );

  return (
    <ToolsPanel
      selectedItems={selectedProfiles}
      title={I18n.t('profiles.toolsPanelTitle')}
    >
      {items.map(({ key, icon: Icon, ...rest }) => (
        <Tool key={key} {...rest}>
          <Icon className={'icon'} />
        </Tool>
      ))}
    </ToolsPanel>
  );
};

export default ProfilesActions;
