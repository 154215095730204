import {
  FILES_LOAD_ALL_FAILED,
  FILES_LOAD_ALL_STARTED,
  FILES_LOAD_ALL_SUCCESS,
  FILES_ASSIGN_PROFILES_STARTED,
  FILES_ASSIGN_PROFILES_FAILED,
  FILES_ASSIGN_PROFILES_SUCCESS,
  FILES_ASSIGN_PROFILES_RESET,
} from './files.actionTypes';
import { NetworkStatus } from '../common/constants';

export default function files(
  state = {
    error: null,
    status: NetworkStatus.NONE,
    data: [],
    assignationStatus: null,
    assignFilesStatus: null,
  },
  action
) {
  switch (action.type) {
    case FILES_LOAD_ALL_STARTED:
      return Object.assign({}, state, {
        data: [],
        status: NetworkStatus.STARTED,
        error: null,
      });
    case FILES_LOAD_ALL_FAILED:
      return Object.assign({}, state, {
        data: [],
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case FILES_LOAD_ALL_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: null,
      });
    case FILES_ASSIGN_PROFILES_STARTED:
      return Object.assign({}, state, {
        assignFilesStatus: 'started',
      });
    case FILES_ASSIGN_PROFILES_FAILED:
      return Object.assign({}, state, {
        assignFilesStatus: 'error',
      });
    case FILES_ASSIGN_PROFILES_SUCCESS:
      return Object.assign({}, state, {
        assignFilesStatus: 'done',
      });
    case FILES_ASSIGN_PROFILES_RESET:
      return Object.assign({}, state, {
        assignFilesStatus: null,
      });
    default:
      return state;
  }
}
