import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import QRCode from 'qrcode.react';
import { I18n } from 'react-redux-i18n';
import { useSelector } from 'react-redux';
import { pickBy } from 'lodash';

import { SectionDescription } from '../sectionDescription.component';
import { SelectField } from '../selectField.component';
import { AttributeType, TimeZones } from '../../common/constants';
import { isIncubeBrand, isJanamBrand } from '../../utils/env';
import Helpers, {
  isEmpty,
  isLoadingStatusStarted,
  notEmpty,
} from '../../common/helpers';
import { Throbber } from '../Throbber';
import { Button } from '../button.component';
import { useThunks } from '../../hooks/useThunks';
import { profilesSelector, profilesV2Selector } from '../../selectors/profiles';
import {
  profileV1Selector,
  profileV2Selector,
} from '../../features/profile/profile.selectors';
import CheckBox from '../CheckBox';
import { InputField } from '../inputField.component';
import {
  allowedStagingWiFiTypes,
  stagingWiFiTypeOptions,
  wiFiNetworkTypes,
} from '../../common/wifiConstants';
import DateField from '../DateField/DateField.component';
import {
  GENERIC_DPC_DOWNLOAD_LOCATION,
  GENERIC_DPC_SIGNATURE_CHECKSUM,
  JANAM_DPC_DOWNLOAD_LOCATION,
  JANAM_DPC_SIGNATURE_CHECKSUM,
  SPRINGDEL_DPC_DOWNLOAD_LOCATION,
  SPRINGDEL_DPC_SIGNATURE_CHECKSUM,
} from '../../common/brandSpecificConstants';
import { androidSupportedLocales } from '../../constants/androidLocales';
import { DataConstants } from '../../constants/data';
import AsyncRendererWithDataFetching from '../AsyncRenderer/AsyncRendererWithDataFetching.component';
import ReadOnlyForm from '../ReadOnlyForm/ReadOnlyForm.component';

const timeZoneOptions = TimeZones.map(([value, title]) => ({ value, title }));

const prepareWiFiType = (type) => {
  let wifi = type;
  if (
    type === wiFiNetworkTypes.WPA2_PSK ||
    type === wiFiNetworkTypes.WPA2_ENTERPRISE
  ) {
    wifi = 'wpa';
  }
  return wifi?.toUpperCase?.();
};

const Staging = ({ enrollment, onChange, isEditingMode }) => {
  const {
    loadProfile: loadProfileV1,
    loadProfileById: loadProfileV2,
  } = useThunks();
  const [isSelectedProfileV1, setIsSelectedProfileV1] = useState(false);
  const { data: profilesV2 } = useSelector(profilesV2Selector);
  const { data: profilesV1 } = useSelector(profilesSelector);
  const {
    data: selectedV1Profile,
    status: selectedV1ProfileStatus,
  } = useSelector(profileV1Selector);
  const {
    data: selectedV2Profile,
    status: selectedV2ProfileStatus,
  } = useSelector(profileV2Selector);
  const { register, handleSubmit, watch, control, setValue } = useForm({
    defaultValues: {
      ...(enrollment?.stagingQRCodeData ?? {}),
    },
  });
  const [qrString, setQrString] = useState(null);
  const publishedProfiles = [...profilesV2, ...profilesV1].filter(
    (profile) => profile.published
  );
  const profileOptions = [
    { value: '', title: '' },
    ...publishedProfiles.map(({ name, id }) => ({
      value: id,
      title: name,
    })),
  ];
  const selectedProfile = isSelectedProfileV1
    ? selectedV1Profile
    : selectedV2Profile;
  const wifiProfiles =
    selectedProfile?.policies?.wifi?.filter(
      ({ settings: { auth = '' } = {} }) =>
        allowedStagingWiFiTypes.includes(auth.toLowerCase())
    ) ?? [];
  const wifiProfilesOptions = [
    { value: '', title: '' },
    ...wifiProfiles.map(
      ({ settings: { ssid } = {}, policyId: wifiPolicyId }) => ({
        value: wifiPolicyId,
        title: ssid,
      })
    ),
  ];

  const formValues = watch();

  useEffect(() => {
    if (notEmpty(enrollment?.stagingQRCodeData)) {
      setQrString(
        JSON.stringify(generateQRCodeValues(enrollment.stagingQRCodeData))
      );
    } else {
      setQrString('');
    }
  }, [isEditingMode, enrollment?.stagingQRCodeData]);

  useEffect(() => {
    if (formValues.profile) {
      const selectedV1Profile = profilesV1.find(
        ({ id }) => id === formValues.profile
      );
      setIsSelectedProfileV1(Boolean(selectedV1Profile));
      setQrString(null);
      if (selectedV1Profile) {
        loadProfileV1(formValues.profile);
      } else {
        loadProfileV2(formValues.profile);
      }
    }
  }, [formValues.profile]);

  const generateQRCodeValues = ({
    wifiNetworkPolicyId,
    timezone,
    locale,
    localTime,
    useMobileData,
    useManualWifiSettings,
    manualWifiSSID,
    manualWifiPassword,
    manualWiFiType,
    manualHiddenNetwork,
    skipDeviceEncryption,
    leaveAllSystemAppsEnabled,
  }) => {
    const { settings: { hiddenNetwork, ssid, key, auth } = {} } =
      wifiProfiles.find(({ policyId }) => policyId === wifiNetworkPolicyId) ??
      {};
    let DPCSignatureChecksum = SPRINGDEL_DPC_SIGNATURE_CHECKSUM;
    let DPCDownloadLocation = SPRINGDEL_DPC_DOWNLOAD_LOCATION;

    if (isJanamBrand) {
      DPCSignatureChecksum = JANAM_DPC_SIGNATURE_CHECKSUM;
      DPCDownloadLocation = JANAM_DPC_DOWNLOAD_LOCATION;
    } else if (isIncubeBrand) {
      DPCSignatureChecksum = GENERIC_DPC_SIGNATURE_CHECKSUM;
      DPCDownloadLocation = GENERIC_DPC_DOWNLOAD_LOCATION;
    }

    const QR_Fields = {
      'android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME':
        'com.springdel.android.dpc/.DeviceAdminReceiver',
      'android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM': DPCSignatureChecksum,
      'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION': DPCDownloadLocation,
      'android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED': leaveAllSystemAppsEnabled,
      'android.app.extra.PROVISIONING_SKIP_ENCRYPTION': skipDeviceEncryption,
      'android.app.extra.PROVISIONING_WIFI_HIDDEN': hiddenNetwork,
      'android.app.extra.PROVISIONING_WIFI_SSID': ssid,
      'android.app.extra.PROVISIONING_WIFI_PASSWORD': key,
      'android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE': prepareWiFiType(
        auth
      ),
      'android.app.extra.PROVISIONING_LOCALE': locale?.toLowerCase(),
      'android.app.extra.PROVISIONING_TIME_ZONE': timezone,
      'android.app.extra.PROVISIONING_LOCAL_TIME': localTime?.isValid?.()
        ? localTime.valueOf()
        : null,
      'android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE': {
        EnrollmentID: enrollment.shortId,
      },
      'android.app.extra.PROVISIONING_USE_MOBILE_DATA': useMobileData,
      ...(useManualWifiSettings
        ? {
            'android.app.extra.PROVISIONING_WIFI_SSID': manualWifiSSID,
            'android.app.extra.PROVISIONING_WIFI_PASSWORD': manualWifiPassword,
            'android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE': prepareWiFiType(
              manualWiFiType
            ),
            'android.app.extra.PROVISIONING_WIFI_HIDDEN': manualHiddenNetwork,
          }
        : {}),
    };
    return pickBy(QR_Fields, Helpers.isNotNull);
  };

  const handleGenerateQRCode = (values) => {
    setQrString(JSON.stringify(generateQRCodeValues(values)));
    onChange('stagingQRCodeData', {
      ...values,
      localTime: values.localTime?.isValid?.()
        ? values.localTime.valueOf()
        : null,
    });
  };

  if (!enrollment) {
    return null;
  }
  return (
    <section className="enrollment-staging">
      <SectionDescription
        title={I18n.t('enrollments.stagingTabTitle')}
        // hint={I18n.t('enrollments.stagingTabHint')}
      />
      {isLoadingStatusStarted(selectedV1ProfileStatus) ||
      isLoadingStatusStarted(selectedV2ProfileStatus) ? (
        <Throbber />
      ) : (
        <>
          {isEditingMode ? (
            <AsyncRendererWithDataFetching
              dataList={[
                {
                  id: DataConstants.PROFILES_V2,
                  params: {
                    loadFullList: true,
                    isInitialLoad: true,
                  },
                },
                {
                  id: DataConstants.PROFILES_V1,
                },
              ]}
            >
              <form onSubmit={handleSubmit(handleGenerateQRCode)}>
                <Controller
                  name="skipDeviceEncryption"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <CheckBox
                      {...field}
                      label={I18n.t(
                        'enrollments.stagingSkipDeviceEncryptionFieldTitle'
                      )}
                      checked={formValues.skipDeviceEncryption}
                    />
                  )}
                />
                <Controller
                  name="leaveAllSystemAppsEnabled"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <CheckBox
                      {...field}
                      label={I18n.t(
                        'enrollments.stagingLeaveAllSystemAppsEnabledFieldTitle'
                      )}
                      checked={formValues.leaveAllSystemAppsEnabled}
                    />
                  )}
                />
                <Controller
                  name="useMobileData"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <CheckBox
                      {...field}
                      onChange={(event) => {
                        field.onChange(event);
                        setValue('profile', null);
                      }}
                      label={I18n.t(
                        'enrollments.stagingUseMobileDataFieldTitle'
                      )}
                      checked={formValues.useMobileData}
                    />
                  )}
                />
                {formValues.useMobileData ? null : (
                  <>
                    <Controller
                      name="useManualWifiSettings"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <CheckBox
                          {...field}
                          onChange={(event) => {
                            field.onChange(event);
                            setValue('profile', null);
                            setValue('wifiNetworkPolicyId', null);
                          }}
                          label={I18n.t(
                            'enrollments.stagingUseManualWifiSettingsFieldTitle'
                          )}
                          checked={formValues.useManualWifiSettings}
                        />
                      )}
                    />
                    {formValues.useManualWifiSettings ? (
                      <>
                        <InputField
                          label={I18n.t(
                            'enrollments.stagingManualWifiSSIDFieldTitle'
                          )}
                          {...register('manualWifiSSID', { required: true })}
                        />
                        <InputField
                          {...register('manualWifiPassword', {
                            required: true,
                          })}
                          label={I18n.t(
                            'enrollments.stagingManualWifiPasswordFieldTitle'
                          )}
                          type="password"
                        />
                        <SelectField
                          title={I18n.t(
                            'enrollments.stagingManualWiFiTypeFieldTitle'
                          )}
                          {...register('manualWiFiType')}
                          options={stagingWiFiTypeOptions}
                          showEmptyOption
                        />
                        <Controller
                          name="manualHiddenNetwork"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <CheckBox
                              {...field}
                              label={I18n.t(
                                'enrollments.stagingManualHiddenNetworkFieldTitle'
                              )}
                              checked={formValues.manualHiddenNetwork}
                            />
                          )}
                        />
                      </>
                    ) : (
                      <SelectField
                        title={I18n.t('enrollments.stagingProfileFieldTitle')}
                        {...register('profile', { required: true })}
                        options={profileOptions}
                      />
                    )}
                  </>
                )}
                {formValues?.profile && (
                  <SelectField
                    title={I18n.t('enrollments.stagingWiFiNetworkFieldTitle')}
                    {...register('wifiNetworkPolicyId', { required: true })}
                    options={wifiProfilesOptions}
                  />
                )}
                <SelectField
                  title={I18n.t('enrollments.stagingLocaleFieldTitle')}
                  {...register('locale', { required: true })}
                  options={androidSupportedLocales}
                />
                <SelectField
                  title={I18n.t('enrollments.stagingTimezoneFieldTitle')}
                  {...register('timezone', { required: true })}
                  options={timeZoneOptions}
                />
                <Controller
                  name="localTime"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <DateField
                      {...field}
                      selected={field.value}
                      dateFormat={false}
                      showClearButton
                    />
                  )}
                />
                <div className="enrollment-staging__generate-button-container">
                  <Button type="submit">
                    {I18n.t('enrollments.stagingQRCodeGenerationButtonText')}
                  </Button>
                </div>
              </form>
            </AsyncRendererWithDataFetching>
          ) : (
            <ReadOnlyForm
              isListEmpty={isEmpty(enrollment?.stagingQRCodeData)}
              emptyListMessage={I18n.t(
                'enrollments.noStagingConfigurationsMessage'
              )}
              items={[
                {
                  title: I18n.t(
                    'enrollments.stagingSkipDeviceEncryptionFieldTitle'
                  ),
                  value: enrollment.stagingQRCodeData?.skipDeviceEncryption,
                  type: AttributeType.BOOLEAN,
                },
                {
                  title: I18n.t(
                    'enrollments.stagingLeaveAllSystemAppsEnabledFieldTitle'
                  ),
                  value:
                    enrollment.stagingQRCodeData?.leaveAllSystemAppsEnabled,
                  type: AttributeType.BOOLEAN,
                },
                {
                  title: I18n.t('enrollments.stagingUseMobileDataFieldTitle'),
                  value: enrollment.stagingQRCodeData?.useMobileData,
                  type: AttributeType.BOOLEAN,
                },
                {
                  title: I18n.t(
                    'enrollments.stagingUseManualWifiSettingsFieldTitle'
                  ),
                  value: enrollment.stagingQRCodeData?.useManualWifiSettings,
                  hidden: enrollment.stagingQRCodeData?.useMobileData,
                  type: AttributeType.BOOLEAN,
                },

                {
                  title: I18n.t('enrollments.stagingManualWifiSSIDFieldTitle'),
                  value: enrollment.stagingQRCodeData?.manualWifiSSID,
                  hidden: !enrollment.stagingQRCodeData?.useManualWifiSettings,
                  type: AttributeType.TEXT,
                },
                {
                  title: I18n.t(
                    'enrollments.stagingManualWifiPasswordFieldTitle'
                  ),
                  value: enrollment.stagingQRCodeData?.manualWifiPassword,
                  hidden: !enrollment.stagingQRCodeData?.useManualWifiSettings,
                  type: AttributeType.PASSWORD,
                },

                // {
                //   title: I18n.t('enrollments.stagingManualWiFiTypeFieldTitle'),
                //   value: enrollment.stagingQRCodeData?.manualWiFiType,
                //   hidden: !enrollment.stagingQRCodeData?.useManualWifiSettings,
                //   type: AttributeType.ENUMERABLE,
                // },

                {
                  title: I18n.t(
                    'enrollments.stagingManualHiddenNetworkFieldTitle'
                  ),
                  value: enrollment.stagingQRCodeData?.manualHiddenNetwork,
                  hidden: !enrollment.stagingQRCodeData?.useManualWifiSettings,
                  type: AttributeType.BOOLEAN,
                },

                // {
                //   title: I18n.t('enrollments.stagingProfileFieldTitle'),
                // profileOptions
                // profile
                //   value: enrollment.stagingQRCodeData?.profile,
                //   hidden: enrollment.stagingQRCodeData?.useManualWifiSettings,
                //   type: AttributeType.ENUMERABLE,
                // },

                // {
                //   title: I18n.t('enrollments.stagingWiFiNetworkFieldTitle'),
                //   wifiNetworkPolicyId
                //   wifiProfilesOptions
                //   value: enrollment.stagingQRCodeData?.manualHiddenNetwork,
                //   hidden: !enrollment.stagingQRCodeData?.profile,
                //   type: AttributeType.ENUMERABLE,
                // },

                // {
                //   title: I18n.t('enrollments.stagingLocaleFieldTitle'),
                //   locale
                //   androidSupportedLocales
                //   value: enrollment.stagingQRCodeData?.manualHiddenNetwork,
                //   type: AttributeType.ENUMERABLE,
                // },

                // {
                //   title: I18n.t('enrollments.stagingTimezoneFieldTitle'),
                //   value: enrollment.stagingQRCodeData?.manualHiddenNetwork,
                //   // timezone
                //   // timeZoneOptions
                //   type: AttributeType.BOOLEAN,
                // },

                // {
                //   title: I18n.t('enrollments.stagingTimezoneFieldTitle'),
                //   value: enrollment.stagingQRCodeData?.localTime,
                //   type: AttributeType.TIME,
                // },
              ]}
            />
          )}
          <section className="enrollment-staging__qr-code-container">
            {qrString && <QRCode value={qrString} size={256} />}
          </section>
        </>
      )}
    </section>
  );
};

export default Staging;
