import React, { Fragment } from 'react';
import { history } from '../../utils/history';
import Dialog from '../../components/Dialog';
import { Button } from '../../components/button.component';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import NewUserInviteMethods from './components/newUserInviteMethods.component';
import NewUserPermission from './components/newUserPermission.component';
import NewUserEnrollmentEmail from './components/newUserEnrollmentEmail.component';
import { connect } from 'react-redux';
import {
  createUsers,
  loadCompanies,
  loadPartnerNameOptions,
} from './newUser.thunk';
import { NetworkStatus } from '../../common/constants';
import { Throbber } from '../../components/Throbber';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RootRoutes, UsersRoutes } from '../../utils/routes';
import { createUsersReset } from './newUser.actions';
import { confirmAlert } from 'react-confirm-alert';
import { partnerIdSelector, roleSelector } from '../login/login.selectors';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { I18n } from 'react-redux-i18n';

const dialogMenu = [
  {
    key: 'Create Individual User',
    title: I18n.t('users.userCreationTabTitle'),
    route: `${RootRoutes.USERS}${UsersRoutes.GROUPS}/all/users/create/invitemethods`,
  },
  // {
  //   key: 'Permission',
  //   route: `${RootRoutes.USERS}${UsersRoutes.GROUPS}/all/users/create/permission`,
  // },
  // {
  //   key: 'Enrollment Email',
  //   route: `${RootRoutes.USERS}${UsersRoutes.GROUPS}/all/users/create/enrollmentemail`,
  // },
];
class NewUser extends React.Component {
  state = {
    data: {
      username: '',
      firstName: '',
      lastName: '',
      password: '',
      companyName: '',
      partnerName: '',
      groupName: this.groupId,
      isAdmin: false,
      isPartner: false,
    },
  };

  get groupId() {
    return RouteHelpers.getUrlParameter(this.props, 'groupId');
  }

  componentDidMount() {
    if (this.props.role === 'superuser') {
      this.props.loadPartnerNameOptions();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.createUserStatus === NetworkStatus.STARTED &&
      this.props.createUserStatus === NetworkStatus.ERROR
    ) {
      this.onChangeStatus();
    }
  }

  componentWillUnmount() {
    this.props.createUsersReset();
  }

  onChangeStatus = (createError = this.props.createUserError) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="create-enrollment-alert">
            <p>{I18n.t('users.updateUserTitle')}</p>
            <p style={{ color: 'red' }}>{createError}</p>
            <Button onClick={onClose}>{I18n.t('common.ok')}</Button>
          </div>
        );
      },
    });
    this.props.createUsersReset();
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    }));
  };

  createUser = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.createUsers(this.state.data);
  };

  render() {
    return (
      <Dialog
        header={this.renderHeader}
        menu={this.renderMenu}
        content={this.renderContent}
        onBack={() => {
          history.goBack();
        }}
      />
    );
  }

  renderHeader = () => {
    let loading =
      (this.props.createUserStatus &&
        this.props.createUserStatus === NetworkStatus.STARTED) ||
      this.props.partnerNameOptionsStatus === NetworkStatus.STARTED;
    return (
      <Fragment>
        <div className={'profile-header'}>
          <div>
            <h6 className={'profile-header__title'}>
              {I18n.t('users.youAreAdding')}
            </h6>
            <div className={'profile-header__caption-text'}>
              {I18n.t('users.newUserText')}
            </div>
          </div>
        </div>
        <div className={'profile-actions__section'}>
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Button
              form={'new_user'}
              type={'submit'}
              style={{ marginLeft: '5px' }}
              disabled={loading}
            >
              {I18n.t('users.createNewUserButton')}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  };

  renderMenu = () => {
    let loading =
      (this.props.createUserStatus &&
        this.props.createUserStatus === NetworkStatus.STARTED) ||
      this.props.partnerNameOptionsStatus === NetworkStatus.STARTED;
    return loading ? '' : <DialogMenu structure={dialogMenu} />;
  };

  renderContent = () => {
    if (
      (this.props.createUserStatus &&
        this.props.createUserStatus === NetworkStatus.STARTED) ||
      this.props.partnerNameOptionsStatus === NetworkStatus.STARTED
    ) {
      return <Throbber />;
    }
    return (
      <div
        className={'dialog-content'}
        style={{ maxWidth: '670px', padding: '20px 24px' }}
      >
        <Switch>
          <Redirect
            exact
            from={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${this.props.match.params.groupId}/users/create`}
            to={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${this.props.match.params.groupId}/users/create/invitemethods`}
          />
          <Route
            exact
            // path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/all/users/create/invitemethods`}
            path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${this.props.match.params.groupId}/users/create/invitemethods`}
            component={this.renderUserDetails}
          />
          <Route
            exact
            path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${this.props.match.params.groupId}/users/create/permission`}
            component={NewUserPermission}
          />
          <Route
            exact
            path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${this.props.match.params.groupId}/users/create/enrollmentemail`}
            component={NewUserEnrollmentEmail}
          />
        </Switch>
      </div>
    );
  };

  renderUserDetails = () => {
    let {
      partnerNameOptionsData,
      companies,
      loadCompanies,
      role,
      partnerId,
    } = this.props;
    let { data } = this.state;
    return (
      <NewUserInviteMethods
        onChange={this.handleChange}
        partnerNameOptions={partnerNameOptionsData || []}
        companies={companies}
        loadCompanies={loadCompanies}
        partnerId={partnerId}
        data={data}
        role={role}
        createUser={this.createUser}
      />
    );
  };
}
const mapStateToProps = (state) => {
  return {
    partnerNameOptionsData: state.user.partnerNameOptionsData,
    companies: state.user.companies,
    partnerNameOptionsStatus: state.user.partnerNameOptionsStatus,
    createUserStatus: state.user.createUserStatus,
    createUserError: state.user.createUserError,
    role: roleSelector(state),
    partnerId: partnerIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPartnerNameOptions: () => dispatch(loadPartnerNameOptions()),
    loadCompanies: (partnerId) => dispatch(loadCompanies(partnerId)),
    createUsers: (data) => dispatch(createUsers(data)),
    createUsersReset: () => dispatch(createUsersReset()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
