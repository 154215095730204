import React, { Component, Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import File from '../../assets/images/file.svg';
import { NetworkStatus } from '../../common/constants';
import Dialog from '../../components/Dialog';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import NotFound from '../../components/NotFound';
import { history } from '../../utils/history';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import { loadTask, loadTaskDevices } from './task.thunk';
import TaskApplications from './components/taskApplications.component';
import TaskOverview from './components/taskOverview.component';
import { Throbber } from '../../components/Throbber';
import { taskDevicesColumns } from '../tasks/tasks.constants';
import { ReactComponent as FileExportCSV } from '../../assets/images/file_export_csv.svg';
import Tool from '../../components/ToolBar/Tool';
import { downloadFile } from '../../common/helpers';
import { generateTableCSV } from '../../common/utilities/csv';
import DataTable from '../../components/DataTable/DataTable.component';

class Task extends Component {
  taskMenuStructure = [
    {
      key: I18n.t('installationStatus.overviewTitleText'),
      route: `${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/${this.props.match.params.childRoute}/overview`,
    },
    {
      key: I18n.t('installationStatus.successfulInstallationsTitle'),
      route: `${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/${this.props.match.params.childRoute}/success`,
    },
    {
      key: I18n.t('installationStatus.pendingInstallationsTitle'),
      route: `${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/${this.props.match.params.childRoute}/failed`,
    },
  ];

  componentDidMount() {
    // this.props.loadTask(this.props.match.params.childRoute);
    // this.props.loadTaskDevices(this.props.match.params.childRoute);
    let targetTask = this.props.tasks.find(
      (task) => this.props.match.params.childRoute === task.id
    );
    this.props.loadTaskDevices(
      targetTask.devicesFailedId,
      targetTask.devicesDeliveredId
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (this.props.match.params.childRoute && this.props.match.params.childRoute !== prevProps.match.params.childRoute) {
    //     this.props.loadTask(this.props.match.params.childRoute);
    // }
  }

  handleExport = (items, attributes, status, count) => {
    const csvData = generateTableCSV(items, attributes);
    let targetTask =
      this.props.tasks.find(
        (task) => this.props.match.params.childRoute === task.id
      ) || {};
    downloadFile(
      csvData,
      `${targetTask.name}_profile_${status}_devices_(${count}).csv`,
      'text/csv'
    );
  };

  render() {
    if (!this.props.match.params.childRoute) {
      return <Redirect to={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}`} />;
    }

    return (
      <Fragment>
        <Dialog
          header={this.renderHeader}
          menu={this.renderMenu}
          content={this.renderContent}
          onBack={() => {
            history.goBack();
          }}
        />
      </Fragment>
    );
  }

  renderHeader = () => {
    let name = this.props.tasks.find(
      (task) => this.props.match.params.childRoute === task.id
    ).name;
    return (
      <Fragment>
        <div className={'profile-header'}>
          <div>
            <div
              style={{
                marginLeft: '40px',
                height: '30px',
                fontFamily: 'Open Sans',
                fontSize: '30px',
                fontWeight: '300',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: '0.6',
                letterSpacing: 'normal',
                color: '#000000',
                display: 'inline-flex',
                flexWrap: 'nowrap',
              }}
              className={'profile-header__caption-text'}
            >
              <img
                src={File}
                alt={'_blank'}
                style={{
                  marginRight: '12px',
                  height: '22px',
                  maxHeight: '22px',
                  width: 'auto',
                }}
              />
              <div style={{ fontSize: '18px' }}>{name ? name : ''}</div>
            </div>
          </div>
        </div>
        <div className={'profile-actions__section'}>
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <div />
          </div>
        </div>
      </Fragment>
    );
  };

  renderMenu = (props) => {
    if (this.props.taskDevicesStatus !== NetworkStatus.DONE) {
      return '';
    }
    return <DialogMenu structure={this.taskMenuStructure} />;
  };

  renderContent = () => {
    if (this.props.taskDevicesStatus !== NetworkStatus.DONE) {
      return <Throbber />;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Switch>
          <Route
            exact
            path={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/:childRoute/overview`}
            component={this.renderOverview}
          />
          <Route
            exact
            path={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/:childRoute/applications`}
            component={this.renderApplications}
          />
          <Route
            exact
            path={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/:childRoute/success`}
            component={this.renderSuccess}
          />
          <Route
            exact
            path={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/:childRoute/failed`}
            component={this.renderFailed}
          />
          <Redirect
            exact
            from={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/${this.props.match.params.childRoute}`}
            to={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/${this.props.match.params.childRoute}/success`}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  };

  renderOverview = () => {
    let data =
      this.props.tasks.find(
        (task) => this.props.match.params.childRoute === task.id
      ) || {};
    return <TaskOverview item={data} status={NetworkStatus.DONE} />;
  };

  renderApplications = () => (
    <TaskApplications item={this.props.data} status={this.props.status} />
  );

  renderFailed = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          backgroundColor: '#fff',
          flexDirection: 'column',
          flexWrap: 'nowrap',
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '15px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '21px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: 0.86,
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  display: 'inline-flex',
                  color: '#000000',
                }}
              >
                {I18n.t('installationStatus.pendingInstallationsTitle')}
                <div className={'text-button'}>
                  {this.props.devicesFailed.length ? (
                    <Tool
                      title={I18n.t('dataTable.csvExportIconHint')}
                      className={'toolbar-action-icon--squired'}
                      onClick={() =>
                        this.handleExport(
                          this.props.devicesFailed,
                          taskDevicesColumns,
                          'failed',
                          this.props.devicesFailed.length
                        )
                      }
                    >
                      <FileExportCSV className={'icon'} />
                    </Tool>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: 1.5,
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000000',
                  marginTop: '5px',
                }}
              >
                {I18n.t(
                  'installationStatus.allDevicesPendingInstallationOfThisProfileText'
                )}
              </div>
            </div>
            <DataTable
              allColumns={taskDevicesColumns}
              data={this.props.devicesFailed}
              loadingStatus={this.props.taskDevicesStatus}
              useFlex
            />
          </div>
        </div>
      </div>
    );
  };

  renderSuccess = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          backgroundColor: '#fff',
          flexDirection: 'column',
          flexWrap: 'nowrap',
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '15px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '21px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: 0.86,
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  display: 'inline-flex',
                  color: '#000000',
                }}
              >
                {I18n.t('installationStatus.successfulInstallationsTitle')}
                <div className={'text-button'}>
                  {this.props.devicesInstalled.length ? (
                    <Tool
                      title={I18n.t('dataTable.csvExportIconHint')}
                      className={'toolbar-action-icon--squired'}
                      onClick={() =>
                        this.handleExport(
                          this.props.devicesInstalled,
                          taskDevicesColumns,
                          'success',
                          this.props.devicesInstalled.length
                        )
                      }
                    >
                      <FileExportCSV className={'icon'} />
                    </Tool>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: 1.5,
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000000',
                  marginTop: '5px',
                }}
              >
                {I18n.t(
                  'installationStatus.allDevicesThatSuccessfullyInstalledThisProfileText'
                )}
              </div>
            </div>
            <DataTable
              allColumns={taskDevicesColumns}
              data={this.props.devicesInstalled}
              loadingStatus={this.props.taskDevicesStatus}
              useFlex
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    tasks: state.tasks.data,
    tasksStatus: state.tasks.status,
    data: state.task.data,
    status: state.task.status,
    devicesFailed: state.task.devicesFailed,
    devicesInstalled: state.task.devicesInstalled,
    taskDevicesStatus: state.task.devicesStatus,
    taskDevicesError: state.task.devicesError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTask: (id) => dispatch(loadTask(id)),
    loadTaskDevices: (dataFailed, dataSuccess) =>
      dispatch(loadTaskDevices(dataFailed, dataSuccess)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Task);
