import { transform } from 'ol/proj';
import DateHelpers from './date';
import {
  ANGLES_IN_A_CIRCLE,
  EQUATORIAL_CIRCUMFERENCE_OF_EARTH,
  MERIDIONAL_CIRCUMFERENCE_OF_EARTH,
} from '../../constants/map';

function to4326(coord) {
  return transform(
    [parseFloat(coord[0]), parseFloat(coord[1])],
    'EPSG:3857',
    'EPSG:4326'
  );
}

function preparePointsList(points = [], maxNumberOfPoints) {
  if (!maxNumberOfPoints || points.length <= maxNumberOfPoints) {
    return points;
  }
  const step = Math.ceil(points.length / maxNumberOfPoints);
  let itemPosition = 0;
  return points.reduce(
    (pointsAccumulator, currentPoint, currentPointIndex, currentArray) => {
      if (currentArray.length === maxNumberOfPoints) return pointsAccumulator;
      if (currentPointIndex === itemPosition) {
        const nextItemPosition = itemPosition + step + 1;
        itemPosition =
          nextItemPosition >= points.length ? points.length : nextItemPosition;
        return [...pointsAccumulator, currentPoint];
      }
      return pointsAccumulator;
    },
    []
  );
}

function isPointWithinRadius(checkPoint, centerPoint, radius = 1) {
  // ky is km per degree for the latitude
  const ky = EQUATORIAL_CIRCUMFERENCE_OF_EARTH / ANGLES_IN_A_CIRCLE;
  // kx  is km per degree for the longitude
  // kx value gets smaller when you get closer to the poles, as the degree lines are closer together
  const kx =
    Math.cos((Math.PI * centerPoint.latitude) / 180.0) *
    (MERIDIONAL_CIRCUMFERENCE_OF_EARTH / ANGLES_IN_A_CIRCLE);

  const dx = Math.abs(centerPoint.longitude - checkPoint.longitude) * kx;
  const dy = Math.abs(centerPoint.latitude - checkPoint.latitude) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= radius;
}

// const BUS_STATION = { latitude: 53.216221, longitude: 50.186321 };
// const PERCHINI_NOVO_VOKPALNAYA = { latitude: 53.242828, longitude: 50.210046 };
// const KFC = { latitude: 53.217192994563256, longitude: 50.18841717109267 };
// const TOLYATTI = { latitude: 53.52628684824007, longitude: 49.41628833421438 };
//
// const point = isPointWithinRadius(BUS_STATION, PERCHINI_NOVO_VOKPALNAYA, 1);

function removePointsWithinCloseProximity(
  logs = [],
  distanceOptions = {},
  dateOptions = {}
) {
  // to keep track of the position of the last added element
  let centerPointIndex = 0;
  return logs.filter((log, currentIndex) => {
    // first point
    if (currentIndex === 0) {
      return true;
    }

    const withinTrackingTimeProximity = DateHelpers.areDatesInProximity(
      log.actionTimestamp,
      logs[centerPointIndex]?.actionTimestamp,
      dateOptions.trackingTimeLimit
    );

    if (withinTrackingTimeProximity) {
      return true;
    }

    const isWithinRadius = isPointWithinRadius(
      log.coordinates,
      logs[centerPointIndex]?.coordinates,
      distanceOptions.radius
    );

    const withinTimeProximity = DateHelpers.areDatesInProximity(
      log.actionTimestamp,
      logs[centerPointIndex]?.actionTimestamp,
      dateOptions.timeLimit
    );

    if (isWithinRadius && withinTimeProximity) {
      return false;
    } else {
      centerPointIndex = currentIndex;
      return true;
    }
  });
}

export default {
  to4326,
  preparePointsList,
  isPointWithinRadius,
  removePointsWithinCloseProximity,
};
