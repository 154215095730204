export const en_us = {
  loginForm: {
    email: 'Email Address',
    password: 'Password',
    login: 'Login',
    wait: 'Please wait...',
    success: 'Success...',
  },
  login: {
    multiTenantProceedButtonText: 'Login',
    multiTenantCancelButtonText: 'Cancel',
    loginErrorTitle: 'Login Error',
    multiTenantPopupTitle: 'Tenant Selection',
    multiTenantPopupText:
      'Your account has multiple tenants. Please choose the tenant you would like to log into.',
    SAMLLoginModalTitle: 'Log in with SAML',
    loginWithAnSSOProvideModalText: 'Log in with an SSO provider',
    signInWithAnSSOProviderButtonText: 'Sign in with an SSO Provider',
    organizationIDFieldTitle: 'Organization ID',
    loginWithSAMLHint:
      'Please enter your organization ID to login in with SAML',
  },
  session: {
    sessionExpiryWarningMessage:
      'Your session is about to expire due to inactivity.',
    sessionExpiryWarningTitle: 'Session Expiry Warning',
    logoutNow: 'Logout now',
    stayLoggedIn: 'Stay logged in',
    sessionExpiryTitle: 'Session Expiry',
    sessionExpiryMessage: 'Your session has expired. Please log in again.',
    logoutFailure: 'Failed to logout. Please try again.',
  },
  errors: {
    unauthorized: 'Authentication Failed',
    invalidCredentials: 'Invalid email and password',
    serviceUnavailable: 'Service is unavailable',
    loggedOut: 'Logged Out',
    invalidPermissionsOrAccessLevel:
      'You do not have the permission or access level required to perform this action. Please, contact the administrator.',
  },
  back: 'Back',
  search: 'Search',
  searchDialog: {
    hint: 'What are you looking for?',
    resultsInTotal: 'Results in total',
    resultsWith: 'Results with',
    searchInProgress: 'Search in progress',
    fleets: 'FLEETS',
    apps: 'APPS',
    devices: 'DEVICES',
    profiles: 'PROFILES',
    enrollments: 'ENROLLMENTS',
    failedToPerformSearch: 'Failed to perform search',
  },
  profileBar: {
    changeAccountSettings: 'CHANGE ACCOUNT SETTINGS',
    logout: 'LOG OUT',
    addAProfile: 'ADD A PROFILE',
    addAFleet: 'ADD A FLEET',
    uploadAnApp: 'UPLOAD AN APP',
    createEnrollmentMethod: 'CREATE ENROLLMENT METHOD',
    help: 'Help',
  },
  notifications: {
    markAllRead: 'Mark All Read',
    removeAll: 'Remove All',
    noNewNotifications: 'No new notifications',
    markAsRead: 'Mark as Read',
    remove: 'Remove',
    deviceHasLeftTheGeofence: 'Device %{deviceName} has left the geofence',
    deviceHasEnteredTheGeofence:
      'Device %{deviceName} has entered the geofence',
    deviceIsSecurityCompliant: '%{deviceName} is security compliant',
    deviceIsNotSecurityCompliant: '%{deviceName} is not security compliant',
    deviceSecurityComplianceStatusUnknown:
      '%{deviceName} security compliance status is unknown',
    deviceHasALowBatteryLevel: '%{deviceName} has a low battery level',
    deviceHasANormalBatteryLevel: '%{deviceName} has a normal battery level',
    deviceHasAnUnknownBatteryLevel:
      '%{deviceName} has an unknown battery level',
  },
  certificates: {
    filename: 'Certificate File Name',
    lastUpdated: 'Last Updated',
    deletionConfirmation:
      'Are you sure you want to delete the selected certificate(s)?',
    removeFiles: 'Remove Files',
    selectedFiles: 'SELECTED CERTIFICATES',
    certificateDeletionError: 'Error on deleting certificates',
    certificatesLoadError: 'Error on fetching certificates',
    uploadedBy: 'Uploaded By',
    uploadedOn: 'Uploaded On',
  },
  htmlKioskFiles: {
    filename: 'File Name',
    lastUpdated: 'Last Updated',
    createdAt: 'Created',
    deletionConfirmation:
      'Are you sure you want to delete the selected HTML file(s)?',
    removeFiles: 'Remove Files',
    selectedFiles: 'SELECTED FILES',
    uploadedBy: 'Uploaded By',
  },
  attributiveTable: {
    notFound: 'Not found',
    noData: 'No Data',
  },
  dataTable: {
    noMatchingFilterResults: 'No items matching your filter(s).',
    noData: 'No Data',
    csvExportIconHint: 'Export data to a CSV file',
  },
  columnsToggle: {
    successfullySavedChanges: 'Changes successfully saved.',
    failedToSaveChanges: 'Failed to save data.',
    unableToGetSavedColumns: 'Unable to get save table columns for this user.',
  },
  company: {
    failedToLoadInfo: 'Failed to load info',
    failedToSetPeriod: 'Failed to set period',
    failedToLoadCompanySettings: 'Failed to load company settings',
  },
  filter: {
    notContainedIn: 'Not Contained in',
    contains: 'Contains',
    doesNotContain: 'Does Not Contain',
    beginsWith: 'Begins With',
    doesNotBeginWith: 'Does Not Begin With',
    endsWith: 'Ends With',
    doesNotEndWith: 'Does Not End With',
    notEqual: 'Not Equal',
    lessThanOrEqual: 'Less Than or Equal',
    greaterThanOrEqual: 'Greater Than or Equal',
    lessThan: 'Less Than',
    greaterThan: 'Greater Than',
    equal: 'Equal',
  },
  installationStatus: {
    successful: 'Successful',
    relatedApplicationsTitle: 'Applications related to this task',
    pendingInstallationsTitle: 'Pending Installations',
    successfulInstallationsTitle: 'Successful Installations',
    informationRelatedToTask: 'Info related to this task',
    successfulAndPendingInstallationTitle: 'Successful & Pending Installations',
    numberOfSuccessfullyInstalledDevices: 'Devices Successfully Installed',
    numberOfPendingInstallations: 'Devices Pending Installation',
    lastUpdated: 'Last updated',
    overviewTitleText: 'Overview',
    allDevicesPendingInstallationOfThisProfileText:
      'All the devices pending the installation of this profile',
    allDevicesThatSuccessfullyInstalledThisProfileText:
      'All the devices that successfully installed this profile',
    failedToLoadTaskDevices: 'Failed to load task devices',
    failedToLoadTask: 'Failed to load task',
    failedToLoadTasks: 'Failed to load tasks',
    devicesInstalled: 'Devices Installed',
    devicesFailed: 'Devices Failed',
    status: 'Status',
    taskDate: 'Task Date',
    installed: 'Installed',
    pending: 'Pending',
  },
  alerts: {
    title: 'Alerts',
    createAlert: 'CREATE ALERT',
    alertCreated: 'Alert created',

    chooseAFleet: 'CHOOSE A FLEET',
    alertType: 'ALERT TYPE',
    alertCondition: 'ALERT CONDITION',
    alertChannel: 'ALERT CHANNEL',
    previewAndSave: 'PREVIEW AND SAVE',
    done: 'DONE',

    alertName: 'ALERT NAME',
    alertStatus: 'STATUS',
    linkedFleets: 'LINKED FLEETS',
    totalAlerts: 'TOTAL ALERTS',
    inactive: 'Inactive',
    active: 'Active',
    createAnAlert: 'CREATE AN ALERT',
    nameThisAlert: 'Name this alert',
    alertDashboard: 'ALERT DASHBOARD',

    battery: 'Battery',
    outOfContact: 'Out Of Contact',
    exitGeoFence: 'Exit GeoFence',
    enterGeoFence: 'Enter GeoFence',

    numberOfLinkedFleets: '%{numberOfLinkedFleets} Fleets',

    on: 'On',
    off: 'Off',
    chooseFleet: 'Choose Fleet',
    createFleet: 'CREATE FLEET',
    back: 'BACK',
    next: 'NEXT',
    alertTypeHeading: 'Alert Type',
    basicAlerts: 'Basic Alerts',
    setCondition: 'Set Condition',
    condition: 'Condition',
    alertWhenDeviceBatteryIs: 'Alert when device battery is',
    alertWhenDeviceNetworkIs: 'Alert when device Network is',
    alertWhenDeviceExitsGeofence: 'Alert when device exits Geofence',
    alertWhenDeviceEntersGeofence: 'Alert when device enters Geofence',
    alertWhenDeviceIsOutOfContact: 'Alert when device is out of contact',
    alertWhenDeviceBluetoothIs: 'Alert when device Bluetooth is',
    lessThanOrEqualTo: 'Less Than or Equal To',
    greaterThanOrEqualTo: 'Greater Than or Equal To',
    setCommunicationChannel: 'Set Communication Channel',
    singleEmailCommunicationChannel: 'Single Email',
    singleEmailCommunicationChannelHint: 'Please enter one email per line.',
    userGroupsCommunicationChannel: 'User Groups',
    userGroupsCommunicationChannelHint:
      'Hold down "Control", or "Command" on a Mac, to select more than one.',
    communicationChannelEmailLabel: 'Email',
    communicationChannelEmailPlaceholder: 'Email Address',
    communicationChannelGroupLabel: 'Group',
    communicationChannelGroupPlaceholder: 'Select User Groups',
    emailAlertFrequency: 'Email Alert Frequency',
    emailAlertFrequencyDescription:
      'Repeat %{alertFrequencyRepeatValue} times at an interval of %{alertFrequencyHours} hours.',
    repeat: 'Repeat',
    timesAtAnIntervalOf: 'times at an interval of',
    hours: 'Hours',
    minutes: 'Minutes',
    percentage: 'Percentage',
    emailAlertFrequencyValuesDescription:
      'Notify %{alertFrequencyRepeatValue} at an interval of %{alertFrequencyHours} hours.',
    alertChannelSettingFootNote:
      'Alert notifications can be repeated up to 10 times and a maximum interval of one month.',
    alertConditionPreviewDescriptionBattery:
      'Alert when device Battery is %{conditionType} %{percentage}%',
    alertConditionPreviewDescriptionOutOfContact:
      'Alert when device is out of contact is turned %{conditionType}',
    alertConditionPreviewDescriptionExitsGeofence:
      'Alert when device exits Geofence is turned %{conditionType}',
    alertConditionPreviewDescriptionEntersGeofence:
      'Alert when device enters Geofence is turned %{conditionType}',

    edit: 'Edit',
    confirm: 'Confirm',
    alertsDeletionConfirmation:
      'Are you sure you want to delete the selected alert(s)?',
    removeAlerts: 'Remove Alerts',
    alertsSelected: 'Alerts Selected',

    youAreEditing: 'You are editing',
    youAreViewing: 'You are viewing',

    alertLoadFailureMessage: 'Failed to load alert.',
    alertUpdateFailureMessage: 'Failed to load alert.',
    alertsDeletionFailureMessage: 'Alerts deletion failed',
    alertsLoadFailureMessage: 'Error on fetching alerts',
    alertCreationFailureMessage: 'Alert creation failed',
  },
  api: {
    title: 'API Manager',
    usageTabTitle: 'API Usage History',
    usageQuotaTitle: 'Quota',
    usageDateColumnTitle: 'Date',
    usageConsumedColumnTitle: 'Consumed',
    usageRemainderColumnTitle: 'Remainder',
    apiKeyCopiedSuccessMessageText: 'API key successfully copied to clipboard.',
    currentPlanTitle: 'Current Plan',
    currentPlanDetails: 'Plan Details',
    starterPlanDetails:
      'This plan allows for %{numberOfAPICalls} API calls every month, included with your license fee. Usage above this quota will require additional fees.',
    documentationTitle: 'Documentation',
    documentationHint: 'Open API documentation in a new tab',
    APIKeyTitle: 'API Key',
    uniqueIDsTitle: 'Device Unique IDs',
    uniqueIDsDownloadButtonText: 'Download Device Unique IDs',
  },
  enrollments: {
    editingTitle: 'You are editing',
    viewingTitle: 'You are viewing',
    fields: {
      status: 'Status',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      statusDraft: 'Draft',
      createdAt: 'Created At',
      lastUpdated: 'Last Updated',
      name: 'Name',
    },
    enrollmentCreationButtonText: 'Create Enrollment',
    enrollmentsSelected: 'Enrollment(s) Selected',
    removeEnrollments: 'Remove Enrollments',
    enrollmentDeletionConfirmationText:
      'Are you sure you want to delete the selected enrollment(s)?',
    deletingEnrollments: 'Deleting Enrollments',
    enrollmentsList: 'Enrollments list',
    createAnEnrollmentMethod: 'Create an enrollment method',
    nameThisEnrollmentMethod: 'Name this Enrollment Method...',
    chooseAFleet: 'Choose a fleet',
    deviceNaming: 'Device Naming',
    ReviewAndConfirm: 'Review & Confirm',
    done: 'Done',
    loadEnrollmentError: 'Error on fetching selected device enrollment',
    updateEnrollmentError: 'Error on updating this device enrollment',
    updateEnrollmentUniqueNameErrorText: 'Enrollment name should be unique',
    createEnrollmentErrorText: 'Enrollment creation error',
    deleteEnrollmentErrorText: 'Enrollments deleting failed',
    loadEnrollmentsErrorText: 'Error on fetching enrollments data',

    QRCodeEnrollmentIdText: 'Enrollment ID:',
    QRCode: 'QR Code',
    selectedFleet: 'Selected Fleet',
    selectedProfilesText: 'Selected Profiles',
    deviceNamingSectionTitle: 'Device Naming',
    deviceNamingSectionHint:
      'Set up a naming sequence for the devices enrolled here',
    selectedProfilesName: 'Name',
    selectedProfilesLastUpdated: 'Last Update',

    deviceNamingSequence: 'Sequence:',
    deviceNamingSeparator: 'Separator:',
    deviceCurrentCount: ' Current Count:',
    namingExampleHeader: 'Example: ',
    startingSerialNumber: 'Starting Serial Number:',

    // device naming params
    deviceNamingParamNone: 'None',
    deviceNamingParamNumberedSequence: 'Auto-incrementing numbered sequence',
    deviceNamingParamName: 'Custom text',
    deviceNamingParamIMEI: 'IMEI',
    deviceNamingParamMACAddress: 'MAC address',
    deviceNamingParamManufacturer: 'Manufacturer',
    deviceNamingParamModel: 'Model',
    deviceNamingParamDeviceName: 'Personalized Device Name',
    deviceNamingParamPhoneNumber: 'Phone Number',
    deviceNamingParamPlatform: 'Platform',
    deviceNamingParamSerialNumber: 'Serial number',

    // enrollment creation
    allVersionsOptionText: 'All Versions',
    prevStepButton: 'BACK',
    forwardStepButton: 'NEXT',
    exit: 'EXIT',
    edit: 'EDIT',
    confirm: 'CONFIRM',
    namingSequenceHint:
      'Create a naming sequence for the devices enrolled here.',
    enrollmentCreationSuccessTitle: 'Send this Enrollment Method now.',
    enrollmentCreationSuccessSubTitle: 'Enrollment information',
    preview: 'Preview:',
    notAvailable: 'Not Available',
    noQRCodeAvailableText: 'No QR code available.',
    enrollmentName: 'Enrollment Name',
    startingFromPrefix: 'Starting from',

    // staging
    stagingTabTitle: 'Staging',
    stagingTabHint:
      'To generate the staging QR-code, please choose a Profile and then a WiFi network to connect to. Please note that the WiFi field is only displayed after a profile is chosen.',
    stagingProfileFieldTitle: 'Profile',
    stagingLocaleFieldTitle: 'Locale',
    stagingTimezoneFieldTitle: 'Timezone',
    stagingWiFiNetworkFieldTitle: 'WiFi Network',
    stagingSkipDeviceEncryptionFieldTitle: 'Skip Device Encryption',
    stagingLeaveAllSystemAppsEnabledFieldTitle: 'Leave All System Apps Enabled',
    stagingUseMobileDataFieldTitle: 'Use Mobile Data',
    stagingUseManualWifiSettingsFieldTitle: 'Use manual Wifi settings',
    stagingManualWifiSSIDFieldTitle: 'WiFi SSID',
    stagingManualWifiPasswordFieldTitle: 'Wifi Password',
    stagingManualWiFiTypeFieldTitle: 'Wifi Type',
    stagingManualHiddenNetworkFieldTitle: 'Hidden Network',
    stagingQRCodeGenerationButtonText: 'Generate QR Code',
    noStagingConfigurationsMessage:
      'There are currently no staging configurations associated with this enrollment.',
  },
  routes: {
    myFleets: 'My Fleets',
    myFleetsV2: 'My Fleets',
    enrollments: 'Enrollments',
    allDevices: 'All Devices',
    myApplications: 'My Applications',
    upload: 'Add',
    myFiles: 'My Files',
    myCertificates: 'My Certificates',
    myHTMLKioskFiles: 'My HTML Kiosk Files',
    allProfiles: 'All Profiles V1',
    allProfilesV2: 'All Profiles',
    tasks: 'Installation Status',
    accountSettings: 'Account',
    commonSettings: 'Common Settings',
    companyInfo: 'Company Info',
    SSO: 'SSO',
    preferences: 'Preferences',
    dashboard: 'Dashboard',
    mapView: 'Map View',
    usersAll: 'Manage',
    usersLogs: 'Activity Logs',
    alerts: 'Alerts',
    apiOverviewTitle: 'Overview',
    apiUsageHistoryTitle: 'Usage History',
  },
  statistics: {
    numberNewDevices: `%{number} New Devices`,
    numberDevices: `%{number} Devices`,
    numberTasks: `%{number} Tasks`,
    enrolledDevices: 'Enrolled Devices',
    inLowBattery: 'Low Battery',
    online: 'Online',
    recentlyActive: 'Recently Active',
    quickDetails: 'Quick Details',
    onlineDevicesPast: `Recently active devices in the past `,
    lastDay: '24 hours',
    week: 'Week',
    month: 'Month',
    last: 'Last',
    installing: 'Installing',
    enrolling: 'Enrolling',
    lowBattery: 'Low Battery',
    failedEnrollments: 'Failed to enroll',
    nonCompliantDevices: 'Non-compliant Devices',
    androidDevices: 'Android Devices',
    linuxDevices: 'Linux Devices',
    lastConnectDates: `Last Connect Dates (All Time)`,
    manufacturer: 'Manufacturer',
    connections: 'Connections',
    devices: 'Devices',
    deviceFamilyOSVersion: 'Device Family OS Version',
    failedToLoadStatistics: 'Failed to load statistics',
  },
  device: {
    title: 'Device',
    deviceInfo: 'Device Info',
    deviceInfoHint: 'Info related to this device',
    actions: 'Actions',
    overview: 'Overview',
    applications: 'Applications',
    profilesAssigned: 'Profiles Assigned',
    activityLogs: 'Logs',
    location: 'Location',
    updateHistory: 'Update',
    updateRequestConfirmationText: 'Update request sent.',
    noHistoryLogEntries: 'There are currently no location log entries.',
    noHistoryLogEntriesForRange:
      'There are no location log entries for the date range set.',
    locationHistory: 'Location History',
    currentLocation: 'Current Location',
    locationLogsStartDate: 'Start Date',
    locationLogsEndDate: 'End Date',
    csvExportIconHint: 'Export logs to a CSV file',
    locationHistoryMapViewToggleText: 'Map View',
    export: 'Export',
    mapFullScreenControlLabelTip: 'Toggle full-screen',
    remoteControl: 'Remote Control',
    logsMenuItem: 'Logs',
    updatingDeviceProperty: 'Updating Device Property:',
    sendingRemoteCommand: 'Sending remote command:',
    applicationsSelected: 'Applications selected',
    uninstallApps: 'Uninstall Apps',

    security: 'Security',
    compliant: 'Compliant',
    notCompliant: 'Not Compliant',

    encrypted: 'Encrypted',
    OSSecure: 'OS Secure',
    passCodeEnabled: 'Passcode Enabled',
    androidSecurityPatchLevel: 'Android Security Patch Level',
    securityCompliance: 'Security Compliance',
    latestAgent: 'Latest Agent',

    isNowOnline: 'is now online',
    lastKnownLocation: 'Last Known Location',
    lastOnline: 'Last Online',
    fleet: 'Fleet',
    fleetPath: 'Fleet Path',
    active: 'Active',
    inactive: 'Inactive',
    enrolled: 'Enrolled',
    unenrolled: 'Unenrolled',
    offline: 'Offline',
    online: 'Online',
    deviceConfigurationLocked: 'Device Configuration Locked',
    lastSeenDescription: 'Last seen at %{lastSeenFormatted}',
    timeWithAgoAffix: '%{time} ago',

    status: 'Status',
    manufacturer: 'Manufacturer',
    model: 'Model',
    activeConnection: 'Active Connection',
    networkBssid: 'BSSID',
    networkSsid: 'SSID',
    networkDNS: 'DNS',
    networkRssi: 'RSSI (Network Strength)',
    roaming: 'Roaming',
    customNotes: 'Custom Notes',
    developerMode: 'Developer Mode',
    ipAddress: 'IP Address',
    wifiStatus: 'WiFi Status',
    nfcStatus: 'NFC Status',
    batteryLevel: 'Battery Level',
    powerStatus: 'Power Status',
    discharging: 'Discharging',
    charging: 'Charging',
    pauseMode: 'Pause Mode',
    phoneNumber: 'Phone Number',
    platform: 'Platform',
    ethernetMacAddress: 'Ethernet MAC Address',
    macAddress: 'MAC Address',
    checkinPeriod: 'Checkin Period',
    lastUpdated: 'Last Updated',
    updatePeriod: 'Update Period',
    lastFleetCheckin: 'Last Fleet Checkin',
    automaticAgentUpdatesEnabled: 'Automatic Agent Updates Enabled',

    profilesAttachedToThisDevice: 'Profiles attached to this device',
    noProfilesAttachedToDevice:
      'There are currently no profiles attached to this device',
    profileAssignedName: 'Name',
    profileAssignedStatus: 'Status',

    connectingToTheServer: 'Connecting to the server...',
    connectingToTheServerWaitingForTheDevice:
      'Connected to the server, waiting for the device',
    disconnectedFromTheServerTryingReconnect:
      'Disconnected from the server, trying reconnect',
    deviceConnected: 'Device connected',
    deviceDisconnectedWaitingForTheDevice:
      'Device disconnected, waiting for the device',
    remoteControlCurrentFocusTextTitle: 'Current focus text',
    mediaProjection: 'Media Projection',
    screenCaptureType: 'Screen Capture Type:',
    chooseScreenCaptureType: 'Choose Screen Capture Type',
    exitFullScreenMode: 'Exit Full Screen Mode',
    fullScreenMode: 'Full Screen Mode',
    swipeTop: 'Swipe Top',
    volumeUp: 'Volume Up',
    volumeDown: 'Volume Down',
    power: 'Power',
    enterKioskMode: 'Enter Kiosk Mode',
    exitKioskMode: 'Exit Kiosk Mode',
    secureInput: 'Secure input',
    slowNetwork: 'Slow Network',
    imageQuality: 'Image Quality:',
    imageSize: 'Image Size:',

    logsTitle: 'Logs',
    logsTitleHint: 'Logs stored for this device',
    dateTime: 'Date Time',
    type: 'Type',
    details: 'Details',
    generatedBy: 'Generated By',

    addAndUpdateNoteTitle: 'Add/Update Custom Note',
    customNoteTitle: 'Custom Note',
    deviceLoadFailureMessage: 'Failed to load device information',
    logs: {
      dateTime: 'Date/Time',
      message: 'Message',
      coordinates: 'Coordinates',
      longitude: 'Longitude',
      latitude: 'Latitude',
    },

    fields: {
      fleet: 'Fleet',
      serialNumber: 'Serial No.',
      deviceType: 'Device Type',
      osVersion: 'OS Version',
      imei: 'IMEI',
      agentVersion: 'Agent Version',
      availableStorage: 'Available Storage',
      availableMemory: 'Available Memory',
      totalMemory: 'Total Memory',
      totalStorage: 'Total Storage',
      haveUpdates: 'Latest Agent',
      latestAgent: 'Latest Agent',
      compliance: 'Agent Compliance',
    },
  },
  profiles: {
    title: 'Profiles',
    published: 'Published',
    unpublished: 'Unpublished',
    draft: 'Draft',
    draftAvailable: 'Draft Available',
    version: 'Version',
    createProfile: 'CREATE PROFILE',
    newProfileDefaultName: 'New Profile',
    unableToCreateProfileErrorText: 'Unable to create a profile',
    profileNameFieldPlaceholder: 'Enter a profile name',
    moreOptionsTitleHeader: 'More Options',
    assignmentSettingsTitle: 'Association settings',
    deviceSideTimeLabel: 'Device Side Time',
    timezone: 'Timezone',
    schedules: 'Schedules',
    assignmentStartDateLabel: 'Association Beginning on',
    assignmentEndDateLabel: 'End Association on',

    profileViewingHint: 'You are viewing',
    profileAssociationHint: 'You are associating',
    profileEditingHint: 'You are editing',
    profileCreatingHint: 'You are creating',
    datetimeOffsetPlaceholder: 'Select value',
    assignmentButtonText: 'ASSOCIATE NOW',
    saveButtonText: 'Save',
    createButtonText: 'CREATE',

    unassignProfiles: 'Dissociate selected profiles from all fleets associated',
    unpublishAndClearFleetsSuccess:
      'Successfully unpublished the selected profile(s)',
    unpublishAndClearFleetsFailure:
      'Failed to unpublish the selected profile(s)',
    unpublishAndClearListedFleetsSuccess:
      'Successfully unpublished the following profiles:',
    unpublishAndClearListedFleetsFailure:
      'Failed to unpublish the following profiles:',
    cloneProfiles: 'Clone Profiles',
    removeProfiles: 'Remove Profiles',
    profileActions: 'Actions',
    assignFleetsAndPublish: 'Associate Fleets and Publish',
    associateFleetsTitle: 'Associate Fleets',
    unPublishProfile: 'Unpublish',
    assign: 'Associate',
    migrateToVersionTwo: 'Migrate to version 2',
    migrationSuccessText:
      'Successful migrated the selected profile(s) to Profiles V2',
    migrationFailureText:
      'Failed to migrate the selected profile(s) to Profiles V2',
    profileAssignmentToFleetsTitle: 'Associate profiles with fleets',
    unpublishProfilesTitle: 'Unpublish Profiles',
    unpublishProfilesConfirmationText:
      'Are you sure you want to unpublish the selected profile(s)?',
    unpublishProfilesConfirmationConsequence:
      'This action will remove the selected profiles from device(s).',
    unassignProfilesConfirmationText:
      'Are you sure you want to dissociate the selected profile(s)?',
    cloneProfilesConfirmationText:
      'Are you sure you want to clone the selected profile(s)?',
    removeProfilesConfirmationText:
      'Are you sure you want to remove the selected profile(s)?',
    migrateProfilesConfirmationText:
      'Are you sure you want to migrate the selected profile(s) to the new version 2? This action is irreversible!',
    toolsPanelTitle: 'Selected Profiles',
    loadProfilesErrorText: 'Error on loading profiles',
    profileRemovalNotAllowedForPublishedProfiles:
      'One or more of the selected profiles are currently published and cannot be removed. Please unpublish these profiles and try again',
    unavailableFeatureMessage:
      'Feature only available in the new version of Profiles (v2). Please consider migrating or creating a new v2 profile.',
    cannotDeleteProfilesAssignedToFleets:
      'Can not delete profiles which currently have fleets assigned to them.',
    cannotDeletePublishedProfile: 'Can not delete published profiles',
    profileTreeHasMoreThanOnePublishedProfile:
      'Profile tree has more than one published profile',
    profileTreeHasMoreThanOneLatestVersion:
      'Profile tree has more than one latest version profile',
    profileTreeCannotBeDeletedBecauseTheProfileIsPublished:
      'Profile trees with published profiles cannot be deleted.',
    profileHasMoreThanOneNewestVersion:
      'Profile tree has more than one newest profile version',
    cannotAssignFleetsToUnpublishedProfiles:
      'Can not associate fleets with unpublished (draft) profiles. Please publish the profile and try again.',
    profilesSuccessfullyDeleted: 'Profile(s) successfully deleted',
    profilesNotSuccessfullyDeleted: 'Deleting profile(s) failed',
    profilesSuccessfullyCloned: 'Profile(s) successfully cloned',
    profilesNotSuccessfullyCloned: 'Failed to clone profile(s)',
    failedToLoadProfiles: 'Failed to load profiles',
    failedToPublishProfile: 'Failed to publish profile',
    profileSuccessfullyPublished: 'Profile successfully published',
    fleetsToProfileAssignmentSuccess:
      'Fleet successfully assigned to the currently published version',
    fleetsToProfileAssignmentFailure:
      'Failed to associate fleets with profile.',
    failedToUnpublishProfile: 'Failed to unpublish profile',
    profileSuccessfullyUnpublished: 'Profile successfully unpublished',
    unpublishProfileConfirmationText:
      'Are you sure you want to unpublish this profile?',
    profileCreationDefaultErrorText: 'Failed to create profile.',
    profileCreationAndPublishingDefaultErrorText:
      'Failed to create and publish profile.',
    profileCreationSuccessText: 'Profile successfully created.',
    profileCreationAndPublishingSuccessText:
      'Profile successfully created and published.',
    profileNewVersionCreationDefaultErrorText:
      'Failed to create a new version of the profile.',
    profileNewVersionCreationAndPublishingDefaultErrorText:
      'Failed to create and publish a new version of the profile.',
    saveAndPublishProfileDefaultErrorText:
      'Failed to save and publish profile.',
    profileNewVersionCreationSuccessText:
      'A new version of the profile has been successfully created.',
    profileNewVersionCreationAndPublishingSuccessText:
      'A new version of the profile has been successfully created and published.',
    saveAndPublishProfileSuccessText:
      'Profile successfully saved and published.',
    profileSaveAsDraftSuccessText: 'Successfully saved profile as draft.',
    profileSaveAsDraftDefaultErrorText: 'Failed to save profile as draft.',
    saveAsDraftButtonText: 'SAVE AS DRAFT',
    createNewVersionButtonText: 'CREATE NEW VERSION',
    saveAndPublishButtonText: 'Save and Publish',
    unableToPublishProfileWithoutAssignedFleets:
      'Can not publish profile because it has no fleets associated with it. Please associate one or more fleets and try again.',
    profileVersionPublicationConfirmation:
      'Are you sure you want to publish version %{version} of this profile?',

    failedToLoadProfileErrorText: 'Profile loading failed',
    failedToCreateProfileErrorText: 'Profile creation failed',
    failedToUpdateProfileErrorText: 'Profile update failed',
    failedToLoadProfileApplicationsErrorText:
      'Error on fetch profile applications',
    failedToLoadProfileById: 'Failed to load the selected profile',
    failedToLoadProfileApps: 'Failed to load profile applications',
    failedToAssignProfilesToFleets:
      'Unable to associate selected profiles with the selected fleets',
    failedToDeleteProfiles: 'Failed to delete profiles',

    profileDetailsTitle: 'Profile Details',
    profileDetailsHint: 'Info related to this profile',
    addConfigurationLabel: 'Add Configuration',
    addLabel: 'Add',
    assignedFleetsLabel: 'Assigned fleets',

    fields: {
      name: 'Profile name',
      lastUpdated: 'Last Updated',
      updatedBy: 'Updated By',
      createdAt: 'Created At',
      newestVersion: 'Newest Version',
      version: 'Version',
      published: 'Published',
      policies: 'Policies',
      packageId: 'Package Id',
      status: 'Status',
      size: 'Size',
    },

    networkTitle: 'Network',
    applicationsTitle: 'Applications',

    featureControl: {
      title: 'Feature control',
      statusHeader: 'Feature control enabled',
      description: 'Set up feature control.',
      noFeatureControlRules:
        'There are currently no feature control rules in this profile.',
      fields: {
        disableBluetooth: 'Disable Bluetooth',
        allowInstallationsFromUnknownSources:
          'Allow Installation From Unknown Sources',
        disableCamera: 'Disable Camera',
        disableUsb: 'Disable USB',
        disableGooglePlay: 'Disable Google Play',
        disableSafeBoot: 'Disable Safe Boot',
        disableWifiHotspot: 'Disallow or Turn off Tethering',
        disableFactoryReset: 'Disable Factory Reset',
        disableHardReset: 'Block Power Off and Restart (Knox Only)',
        disableTurnOffWifi: 'Block Turning off WiFi',
        disableAirplaneMode: 'Block Airplane Mode',
      },
    },
    kiosk: {
      title: 'Kiosk',
      htmlKioskTitle: 'HTML Kiosk',
      undo: 'UNDO',
      save: 'SAVE',
      htmlKiosk: 'HTML KIOSK',
      htmlKioskWhiteList: 'HTML KIOSK WHITELIST',
      passwordDoesNotMatch: `Password doesn't match`,
      backdoorPasswordDescription: 'Please set a backdoor password.',
      kioskBuilder: 'Kiosk Builder',
      kioskBuilderHint1:
        'Drag and Drop the applications from the list to the device interface.',
      kioskBuilderHint2:
        'Drag the application out of the device interface to remove.',

      HTMLKioskBuilder: 'HTML Kiosk Builder',
      HTMLKioskBuilderHint: `Drag and Drop one HTML file from the 'MY HTML FILES' section on the right.`,
      myHtmlFiles: 'MY HTML FILES',
      enterHtmlKioskBuilder: 'ENTER HTML KIOSK BUILDER',
      enterKioskBuilder: 'ENTER KIOSK BUILDER',
      kioskMode: 'Kiosk Mode',
      statusTitle: 'Kiosk Mode Enabled',
      kioskModeEmptyMessage:
        'There are currently no automatically installed apps.',
      back: 'Back',
      security: 'Security',
      confirmPassword: 'Confirm password',
      password: 'Password',
      addPackageByID: 'Add Package by ID',
      statusBar: 'Status Bar',
      whitelistServices: 'Whitelist services',
      autoLaunchApp: 'Autolaunch App',
      myApplications: 'MY APPLICATIONS',
      useHTMLKioskMode: 'Use HTML Kiosk Mode',
      hideStatusBarSetting: 'Hide Status Bar',
      backButtonSetting: 'Back Button',
      homeButtonSetting: 'Home Button',
      menuButtonSetting: 'Menu Button',
      singleAppModeSetting: 'Single App Mode',
      showExitButtonSetting: 'Show Exit Button',
      onlyKioskNotificationsSetting: 'Sanctioned notifications only',
      hideNavigationBarSetting: 'Hide Navigation Bar (Samsung only)',
      backKeyText: 'Back',
      homeKeyText: 'Home',
      menuKeyText: 'Menu',
    },
    wallpaperSettings: {
      wallpaperSettings: 'Wallpaper Settings',
      policyStatusTitle: 'Wallpaper Enabled',
      homeScreenWallpaperLandscape: 'Home Screen Wallpaper - Landscape',
      homeScreenWallpaperPortrait: 'Home Screen Wallpaper - Portrait',
      lockScreenWallpaperLandscape: 'Lock Screen Wallpaper - Landscape',
      lockScreenWallpaperPortrait: 'Lock Screen Wallpaper - Portrait',
      myImages: 'My Images',
      useHomeScreenWallpaperAsLockScreenWallpaper:
        'Use Home Screen Wallpaper As Lock Screen Wallpaper',
      wallpaperSettingsAvailability:
        'Only available on Android 7.0 (API level 24) and higher.',
    },
    wifi: {
      title: 'WiFi',
      wifiPolicyEnabledTitle: 'WiFi Policy Enabled',
      wifiNetworkTitle: 'WiFi Network',
      policyHint: 'Click the + icon to add a WiFi network',
      descriptionHint: 'Set up WiFi settings for devices.',
      removeConfigurationActionButtonText: 'Remove Configuration',
      proxyBypassTitle: 'Bypass proxy for',
      proxyBypassHint: 'Please enter one hostname/website per line.',
      proxyAutoConfigURLTitle: 'Proxy Auto-Config URL',
      proxyPortTitle: 'Proxy Port',
      proxyHostnameTitle: 'Proxy Hostname',
      proxyTypeTitle: 'Proxy',
      frequencyHint: 'Only available on Android 7 and lower.',
      proxyConfigTypeNone: 'None',
      proxyConfigTypeManual: 'Manual',
      proxyConfigTypeAutoConfig: 'Proxy Auto Config',
      SSID: 'SSID',
      SSIDPlaceholder: 'Enter SSID name',
      SSIDPassword: 'SSID Password',
      SSIDPasswordPlaceholder: 'Enter a password',
      WiFiFrequency: 'WiFi Frequency',
      hiddenNetwork: 'Hidden Network',
      securityType: 'Security type',
      acceptedEAPTypes: 'Accepted EAP Types',
      identity: 'Identity',
      password: 'Password',
      passwordPlaceholder: 'Enter a password',
      clientCertificate: 'Client Certificate',
      CACertificate: 'CA Certificate',
      CACertificateDomain: 'CA Certificate Domain',
      domainSuffixMatch: 'Domain Suffix Match',
      alternateSubjectMatch: 'Alternate Subject Match',
      phase2AuthenticationMethod: 'Phase 2 Authentication Method',
      anonymousIdentity: 'Anonymous Identity',
      publicLandMobileNetwork: 'Public Land Mobile Network',
      realm: 'Realm',
      noWiFiNetworksInProfileText:
        'There are currently WIFI networks in this profile.',
    },
    webFilter: {
      webFilter: 'Web Filter',
      policyStatusTitle: 'Web Filter Enabled',
      noWebFilterPolicies: 'No web filter policies have been created.',
      hint: 'Set up web filter whitelist or blacklist.',
      webFilterType: 'Web filter type',
      urls: 'URLS',
      urlHint:
        'Enter one website URL per line. Please prepend each website with the corresponding protocol e.g. http://',
      webFilterTypeWhitelist: 'Whitelist',
      webFilterTypeBlacklist: 'Blacklist',
    },
    apn: {
      emptyPolicyListMessage:
        'There are currently APN settings in this profile.',
      policyStatusTitle: 'APN Policy Enabled',
      apnSettingsAvailability:
        'Only available on Android 9.0 (API level 28) and higher.',
      policyTitle: 'APN',
      apnNameLabel: 'Access Point Name',
      apnDisplayNameLabel: 'Access Point Display Name',
      mobileCountryCodeLabel: 'Mobile Country Code (MCC)',
      mobileNetworkCodeLabel: 'Mobile Network Code',
      accessPointTypeMMS: 'MMS',
      accessPointTypeInternet: 'Internet',
      accessPointTypeMMSAndInternet: 'MMS + Internet',
      accessPointTypeAny: 'Any',
      accessPointTypeLabel: 'Access Point Type',
      mvnoTypeLabel: 'MVNO Type',
      mvnoValueLabel: 'MVNO Value',
      MVNOTypeNone: 'None',
      MVNOTypeSPN: 'SPN',
      MVNOTypeIMSI: 'IMSI',
      MVNOTypeGID: 'GID',
      defaultNetworkLabel: 'Default Network',
      authenticationTypeLabel: 'Authentication Type',

      authenticationTypeNone: 'None',
      authenticationTypePAP: 'PAP',
      authenticationTypeCHAP: 'CHAP',
      authenticationTypePAPCHAP: 'PAP/CHAP',

      accessPointConnectionUsernameLabel: 'Access Point Connection Username',
      accessPointConnectionPasswordLabel: 'Access Point Connection Password',
      accessPointConnectionHostAddressLabel:
        'Access Point Connection Host Address',
      accessPointConnectionHostPortLabel: 'Access Point Connection Host Port',

      proxyHostAddressLabel: 'Proxy Host Address',
      proxyHostPortLabel: 'Proxy Host Port',
      mmsProxyHostAddressLabel: 'MMS Proxy Host Address',
      mmsHostAddressLabel: 'MMS Host Address',
      mmsHostPortLabel: 'MMS Host Port',
    },
    fileSync: {
      policyStatusTitle: 'File Sync Enabled',
      policyTitle: 'File Sync',
      directionOptionSyncFilesFromServerToDevice:
        'Synchronize file(s) from Server to Device',
      hint: 'Click the + icon to add a File Sync configuration',
      fileSyncConfigurationTitle: 'File Sync Configuration',
      removeConfigurationText: 'Remove Configuration',
      directionFieldTitle: 'Direction',
      devicePathLabelText: 'Device File / Folder Name',
      devicePathPlaceholderText: 'Enter a folder path',
      fileSynchronizationOptionsTitle: 'File Synchronization options',
      syncSubFoldersFieldLabel: 'Sync Sub-Folders',
      filesTitle: 'Files',
      noFileSyncRules:
        'There are currently no file sync rules in this profile.',
      noFilesAssigned: 'No files in this profile.',
      filesAssignmentHint: 'Please associate files from the list to the right.',
    },
    geofencing: {
      policyTitle: 'Geofencing',
      policyStatusTitle: 'Geofencing Enabled',
      clearGeometryText: 'Clear geometry',
      searchFieldPlaceholderText: 'Search for ...',
      geofencingRuleTitle: 'Geofencing Rule',
      removeRuleActionButtonTitle: 'Remove Rule',
      notifyWhenDevicesLeaveTheFence:
        'Notify when devices leave the fence area',
      moveDeviceToFleetIfItLeavesGeofence:
        'Move device to fleet if device leaves geofence',
      hint: 'Click the + icon to add a Geofencing rule',
      changeFleetButtonText: 'Change Fleet',
      removeFleetButtonText: 'Remove Fleet',
      selectAFleetButtonText: 'Select a Fleet',
      fleetNotDefinedText: 'Fleet is not defined',
      policyHint: 'Draw fence area polygons to set up geofencing rules',
      textButtonHint: 'Add Rule',
      noRulesInProfile:
        'There are currently no Geofencing rules in this profile.',
    },
    password: {
      policyTitle: 'Password',
      policyHint: 'Set up password requirements.',
      policyStatusTitle: 'Password Enabled',
      passwordQualityOptionPIN: 'PIN',
      passwordQualityOptionPattern: 'Pattern',
      passwordQualityOptionAlphabetic: 'Alphabetic',
      passwordQualityOptionAlphanumeric: 'Alphanumeric',

      passwordQualityLabel: 'Password Quality',
      minimumLengthLabel: 'Password Length',
      complexCharactersRequiredLabel:
        'Minimum Number of Complex Characters Required',
    },
    settings: {
      policyTitle: 'Settings',
      policyStatusTitle: 'Settings Enabled',
      deviceLanguageTitle: 'Device Language',
      syncNTPLabel: 'Sync time with NTP server',
      setDeviceTimezone: 'Set device time zone',
      timezone: 'Timezone',
      useDaylightSavingsTime: 'Use Daylight Savings Time',
      syncDeviceOverWiFi: 'Sync to device over WiFi only',
      downloadFilesOverWifi: 'Download files over Wi-Fi only',
      policyHint: 'Set up synchronization settings.',
      deviceVolumeTitle: 'Device Volume',
      deviceBrightnessTitle: 'Device Brightness',
      disableAndroidBeamTitle: 'Disable Android Beam',
      autoRotateScreenTitle: 'Auto-rotate Screen',
      displaySectionTitle: 'Display',
      timeSectionTitle: 'Time',
      languageSectionTitle: 'Language',
      synchronizationSectionTitle: 'Synchronization',
      systemSectionTitle: 'System',
    },
    certificates: {
      policyTitle: 'Certificates',
      policyStatusTitle: 'Certificates Management Enabled',
      certificatesTitle: 'Profile Certificates',
      certificateNameTitle: 'Name',
      lastUpdated: 'Last updated',
      noCertificatesAttachedToProfile:
        'There are no certificates attached to this profile.',
      howToAddCertificateRecommendationText:
        'Please associate certificates from the list on your right',
    },
    apps: {
      policyTitle: 'App Management',
      policyStatusTitle: 'App Management Enabled',
      policyTitleHint:
        'Application attached to this profile will install these apps automatically.',
      profileAppsTitle: 'Profile Apps',
      noAppsAttachedToProfile:
        'There are currently no applications attached to this profile.',
      howToAddAppsRecommendationText:
        'Please associate applications from the list on your right.',
      myApplicationsTitle: 'My Applications',
      autoInstalledAppsTitle: 'Automatically installed apps',
      noAutoInstalledAppsText:
        'There are currently no automatically installed apps.',
      networkRestrictionsSectionTitle: 'Network Restrictions',
      installOverWiFiTitle: 'Install Over Wifi',
      installOverCellularTitle: 'Install Over Cellular',
      installOverCellularConditionTitle: 'Install Over Cellular Condition',
      installOverLANTitle: 'Install Over LAN',
      noneInstallOverCellularConditionTitle: 'None',
      cellularInstallOverCellularConditionTitle: 'Cellular',
      cellularWhileRoamingInstallOverCellularConditionTitle:
        'Cellular While Roaming',
    },
    blacklist: {
      policyTitle: 'App Blacklist Management',
      policyStatusTitle: 'App Blacklist Management Enabled',
      blacklistTitle: 'Blacklist',
      blacklistedAppsTitle: 'Blacklisted Package IDs',
      noBlacklistedAppsMessage: 'There are currently no blacklisted apps.',
      blacklistUpdateHint: 'Please associate apps from the list on your right.',
      blacklistDescription:
        'Applications attached to this profile will be blacklisted.',
      addPackageByID: 'Add Package by ID',
    },
    security: {
      policyTitle: 'Security',
      policyStatusTitle: 'Security Management Enabled',
      policyHint: 'Set up security settings.',
      secureOSTitle: 'Secure OS',
    },
    location: {
      policyTitle: 'Location',
      policyStatusTitle: 'Location Settings Enabled',
      locationPolicyHint: 'Set up location services settings.',
      GPSAlwaysOnSetting: 'GPS Always ON',
    },
    locationServices: {
      policyTitle: 'Location Services',
    },
    authentication: {
      policyTitle: 'Authentication',
      policyTitleHint: 'Set up the password for enrolling new devices.',
      passwordFieldLabel: 'Password',
      confirmPasswordFieldLabel: 'Confirm password',
    },
  },
  applications: {
    title: 'Applications',
    myApplicationsTitle: 'My Applications',
    applicationInfoTabHeading: 'Application Info',
    allVersionsListTabHeading: 'List of All Versions',
    allVersionsListTabHint: 'All versions of this application',
    settingsTabHeading: 'Settings',
    assignProfilesHeading: 'Profiles',
    assignProfilesHint: 'Select profiles and press Associate',
    assignProfilesButtonText: 'Associate now',
    assignProfilesFailureDefaultMessage:
      'Unable to associate application with profiles',
    assignProfilesSuccessDefaultMessage:
      'Application associate with the selected profiles',

    applicationCodeNameHint: 'Start typing to define',

    applicationVersionsTitle: 'Applications',
    appsRelatedToTaskTitle: 'Applications related to this task',
    editApplicationDetails: 'Edit application details',

    applicationDataUpdateError: 'Error on updating application data',
    applicationDataFetchError: 'Error on fetch application data',
    applicationAssignmentToProfilesErrorMessage:
      'Error on application association with profiles',

    fields: {
      applicationName: 'Application name',
      applicationFileName: 'Application file name',
      label: 'Label',
      latestVersion: 'Latest Version',
      latestVersionCode: 'Latest Version Code',
      versionCode: 'Version Code',
      version: 'Version',
      packageId: 'Package Id',
      status: 'Status',
      size: 'Size',
      lastUpdated: 'Last Updated',
      uploadedBy: 'Uploaded By',
      uploadedOn: 'Uploaded On',
      installOnly: 'Install Only',
      managed: 'Managed',
      installedOnThisDevice: 'Installed on this device',
    },
    applicationsDeletionTitle: 'Applications Deletion',
    applicationsDeletionFailed: 'Applications deletion failed',
    applicationsFetchFailed: 'Error on fetching applications',
    appsNotDeletedByAssignedToProfileTitle: `The following app(s) was (were) NOT deleted because they are still assigned to a profile (%{profile})`,
    appsDeletedTitle: 'The following app(s) was (were) successfully deleted',
    addBundleTitle: 'Add Package',
    addBundleFieldPlaceholderText: 'Enter Package ID',
  },
  devices: {
    title: 'Devices',
    csvExportIconHint: 'Export table to a CSV file',
    devicesSelected: 'Devices selected',
    removeDevices: 'Remove Devices',
    selectFleet: 'Select Fleet',
    associationHint: 'You are associating',
    assignmentButtonText: 'ASSOCIATE NOW',
    selectedFleet: 'Selected Fleet',
    deviceUpdateTitle: 'Latest Agent',
    deviceLogsLoadingFailed: `Device's logs loading failed`,
    applicationsLoadingFailed: 'Applications loading failed',
    deviceProfilesLoadingFailed: 'Profiles loading failed',
    devicePropertyUpdateFailed: 'Failed to update device property',
    devicePropertyValueMustBeUnique: 'Device property should be unique',
    applicationsUninstallFailed: 'Applications uninstall failed',
    sendingRemoteCommandToSelectedDevicesErrorMessage:
      'Error on sending remote command to selected devices',
    errorOnFetchingAllCompanyDevices: 'Error on fetching devices',
    errorOnDeletingSelectedDevices: 'Error on deleting selected devices',
    removeDeviceTitle: 'Remove devices',
    sendingRemoteCommandTitle: 'Sending remote command',
    fields: {
      fleetName: 'Fleet',
      haveUpdates: 'Latest Agent',
      latestAgent: 'Latest Agent',
      status: 'Status',
      lastSeen: 'Last Seen',
      deviceName: 'Device',
      agentVersion: 'Agent Version',
      platform: 'Platform',
      location: 'Location',
      longitude: 'Longitude',
      latitude: 'Latitude',
      batteryLevel: 'Battery Level',
      lastOnline: 'Last Online',
      enrollmentDatetime: 'Enrollment Date/Time',
      manufacturer: 'Manufacturer',
      model: 'Model',
      macAddress: 'MAC Address',
      ethernetMacAddress: 'Ethernet MAC Address',
      ipAddress: 'IP Address',
      totalMemory: 'Total Memory',
      availableMemory: 'Available Memory',
      totalStorage: 'Total Storage',
      availableStorage: 'Available Storage',
      serialNumber: 'Serial Number',
      imei: 'IMEI',
      sendingAnalyticsData: 'Sending Analytics Data',
      compliance: 'Agent Compliance',
      compliantAndLatestVersion: 'Compliant and latest version',
      compliantAndNotLatestVersion: 'Compliant but not latest version',
      notCompliantVersion: 'Not compliant version',
      deviceConfigurationLockStatus: 'Device Configuration Lock Status',
      automaticAgentUpdatesEnabled: 'Automatic Agent Updates Enabled',
    },
  },
  home: {
    title: 'Home',
    search: 'Search',
  },
  users: {
    userGroups: 'User Groups',
    title: 'Users',
    allUsers: 'All Users',
    admins: 'Admins',
    manage: 'Manage',
    groupName: 'Group',
    moveUsers: 'Move users:',
    userStatusChange: 'User status change',
    makeUsersInactiveConfirmation:
      'Do you want make the selected users inactive?',
    activateUsersConfirmation:
      'Are you sure you want to activate the selected users?',
    cannotDeleteGroupContainingUsersMessage:
      'You cannot delete a group which still has users. Please remove all the users from this group and then try again.',
    deleteGroupConfirmation: 'Are you sure you want to delete %{groupName}? ',

    createAGroup: 'CREATE A GROUP',
    createUser: 'CREATE USER',
    editGroup: 'EDIT GROUP',
    deleteGroup: 'DELETE GROUP',
    chooseAUserGroup: 'Choose a user group',
    move: 'Move',
    changeUserGroup: 'Change user group',
    activateUsers: 'Activate users',
    deactivateUsers: 'Deactivate users',
    removeUsers: 'Remove user(s)',
    usersSelected: 'User(s) selected',

    newUserGroup: 'New User Group',
    permissions: 'Permissions',
    userManagement: 'User Management',
    groupCreationFailureDefaultErrorText: 'Failed to create group',
    groupCreationHeading: 'You are creating',
    groupEditingHeading: 'You are editing',
    update: 'Update',
    create: 'Create',

    globalPermissionsTableDescriptionHeader: 'DESCRIPTION',
    globalPermissionsTableReadOnlyHeader: 'READ ONLY',
    globalPermissionsTableReadWriteHeader: 'READ/WRITE',

    userGroupSectionDescription: 'User Group',
    globalPermissionsSectionDescription: 'Global Permissions',
    fleetAccessSectionDescription: 'Fleet Access Management',
    remoteControlSectionDescription: 'Remote Control Permissions',
    viewSpecificSectionDescription: 'View-Specific Permissions',
    userGroupCreationGroupNameLabel: 'User Group Name:',
    allUsersEmptyText: 'You have selected all users.',
    allUsersListHeaderEmail: 'Email',
    allUsersListHeaderUsername: 'Username',
    noUsersInGroupText: 'There are no users attached to this user group.',
    hintToAddUsers: 'Please associate users from the list on your right.',
    existingUsersTitle: 'Existing Users',
    usernameTitle: 'USERNAME',
    failedToCreateNewUserMessage: 'Failed to create new user',
    updateUserTitle: 'Update user',
    createNewUserButton: 'Create',
    newUserText: 'New user',
    youAreAdding: 'You are adding',

    groupsLoadErrorText: 'Failed to load Groups',
    groupCreationError: 'Failed to create new group',
    groupUpdateError: 'Failed to update group',
    groupDeletionError: 'Failed to delete group',
    loadGroupsModelLevelPermissionsError: 'Failed to load Permissions',
    loadAllUsersError: 'Failed to load Users',
    loadCompanyFleetsFullListError: `Failed to load the company's full list of fleets`,
    loadUsersLogsError: 'Failed to load Users logs',
    updateUserError: 'Failed to update user details',
    loadUserError: 'Failed to load user details',
    loadUsersError: 'Failed to load users',
    deleteUsersError: 'Failed to delete users',
    moveUsersError: 'Failed to move users',
    changeUserStatusError: 'Failed to change user status',
    loadUserGroupPermissionError: 'Failed to load user group permissions',

    userTabHeading: 'Details',
    userDetailsSectionHeading: 'User Details',
    adminControlsSectionHeading: 'Admin Controls',
    adminRoleAssigmentToggleLabel: 'Administrator role',
    partnerRoleAssigmentToggleLabel: 'Partner role',
    userRoleSectionHeading: 'User Roles',

    deleteAccountToggleLabel: 'Delete account',
    userUpdateErrorTitle: 'User Update Error',

    groupListCSVExportIconHint: 'Export group list to a CSV file',

    devicePermissionTitle: 'Device',
    fleetTreePermissionTitle: 'Fleets Tree',
    profilePermissionTitle: 'Profile',

    fleetAccessTitle: 'Fleet Access',
    profileAccessTitle: 'Profile Access',

    permissionTitleUploadsAndApplicationsAccessTitle:
      'Uploads and Applications Access',
    permissionTitleUploadsTitle: 'Uploads (Files, Certificates, Apps)',

    permissionTitleApplicationsAccess: 'Applications Access',
    permissionTitleViewDeviceLocation: 'View Device Location',
    permissionTitleLiveDeviceLocationTracking: 'Live Device Location Tracking',
    permissionTitleViewCurrentLocation: 'View Device Current Location',
    permissionTitleViewLocationHistory: 'View Device Location History',

    globalPermissionsAccessTypeReadOnly: 'READ ONLY',
    globalPermissionsAccessTypeReadWrite: 'READ/WRITE',
    globalPermissionsAccessTypeNone: 'NONE',

    globalPermissionsHeadingDescription: 'Description',
    globalPermissionsHeadingAccess: 'Access',

    userCreationTabTitle: 'Create Individual User',
    failedToLoadPartners: 'Failed to load partners',
    failedToLoadPartnerCompanies: 'Failed to load partner companies',

    enrollmentEmailTemplateTitle: 'Enrollment Email Template',

    fields: {
      username: 'Username',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'E-mail',
      emailPlaceholder: 'Enter an e-mail address',
      password: 'Password',
      active: 'Active',
      inactive: 'Inactive',
      status: 'Status',
      lastLoginTitle: 'Last Login',
      creationDateTitle: 'Created on',
      creatorTitle: 'Created By',
      requirePasswordChangeOnNextLogin:
        'Require user to change password on next sign in',
      disablePasswordChange: 'Disallow user to change password',
      disableEmailAddressChange: 'Disallow user to change email address',
      disableAccount: 'Temporarily disable account',
      companyName: 'Company Name',
      partnerNameFieldText: 'Partner Name',
      enrollmentEmailHint:
        'We have drafted an email that you can send to your employees to have them enroll. They will need to navigate to the enrollment link to download device agent.',
      emailContentFieldTitle: 'Email Content',
      emailSubjectFieldTitle: 'Email Subject',
    },
    userLogs: {
      type: 'Type',
      date: 'Date',
      messageLog: 'Message Log',
      name: 'Name',
      ipAddress: 'IP Address',
      userGroupsTitle: 'Users Groups',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
  },
  user: {
    admin: 'Admin',
    partner: 'Partner',
    partneruser: 'Partner User',
    superuser: 'Super User',
    customer: 'Customer',
    user: 'User',
    unknownAccessLevel: 'Unknown Access Level',
  },
  settings: {
    title: 'Settings',
    accountSettings: 'Account Settings',
    accountSettingsLoadFailure: 'Failed to load Account Info',
    globalPermissionsLoadFailureMessage:
      'Failed to load global permissions for the logged in user.',
    failedToLoadTimezones: 'Failed to load timezones',
    failedToGetSSOSAMLSettings: 'Failed to get SAML Settings',
    fields: {
      name: 'Name',
      namePlaceholder: 'My name',
      email: 'Email Address',
      emailPlaceholder: 'Email',
    },
    passwordChangeTitle: 'Password Change',
    changePassword: 'Change Password',
    changingPassword: 'Changing Password',
    currentPasswordTitle: 'Current Password',
    currentPasswordPlaceholder: 'Enter Current Password',
    newPasswordTitle: 'New Password',
    newPasswordPlaceholder: 'Enter New Password',
    reTypeNewPasswordTitle: 'Re-Type New Password',
    validationAllPasswordFieldRequired: 'All fields Required',
    passwordValidationCantBePreviouslyUsed:
      'New passwords needs to be different from old password',
    passwordValidationDoesNotMatch: 'Password does not match',
    passwordValidationMinimumLength:
      'Password length must be at least 8 characters',
    passwordChangeFailureText: 'Failed to change password',

    companyInfo: 'Company Info',
    companyInfoHint: 'Info related to this company',
    companyName: 'Company name',
    totalNumberOfLicenses: 'Total number of licenses',
    licensesInUse: 'Licenses in use',
    licensesAvailable: 'Licenses available',
    commentTitle: 'Comment',

    commonSettings: 'Common settings',
    commonSettingsHint: 'Devices settings related to this company',
    enableAutomaticAgentUpdates: 'Enable automatic agent updates',
    setupSettings: 'Setup Settings',
    updatePopupTitle: 'Common Setting Update',
    updatePopupDefaultError: 'Cannot set period',

    loginURLLabel: 'Login URL',
    organizationIdLabel: 'Organization ID',
    azureADEntityIdLabel: 'Azure AD Identifier',
    applicationNameLabel: 'Application Name',
    federationMetadataXMLFileLabel: 'Federation Metadata XML',

    invalidXMLFileErrorMessage: 'Invalid XML file',
    ssoAccountLinkSuccessMessage: 'Account linked successfully.',
    ssoAccountSettingsUpdateSuccessMessage: 'Account successfully updated.',
    failedToLinkSSOAccountMessage: `Sorry, we couldn't link your account at the moment`,
    azureSAMLLinkAccountText: 'Link Azure Account (SAML)',
    azureSAMLUpdateAccountText: 'Update Azure Account (SAML)',
    updateWarningText:
      'Updating your Azure Account Settings would affect the following companies',
    azureSAMLLoginButtonText: 'Log in with Microsoft Azure (SAML 2.0)',
    OIDCLinkAccountHeading: 'OIDC 2.0',
    SAMLLinkAccountHeading: 'SAML 2.0',
    azureADLinkHeading: 'Azure AD',
    azureOIDCLinkAccountText: 'Link Azure Account (OIDC)',
    azureOAuthLoginButtonText: 'Log in with Microsoft Azure (OAuth 2.0)',
    SSOSettingsSectionHeading: 'SSO Settings',
    SSOSettingsSectionHint:
      'Please enter the following information in order to link your Azure account using SAML 2.0',

    preferences: 'Preferences',
    setTimezoneFieldTitle: 'Time Zone',
    setLocaleFieldTitle: 'Locale',
    savePreferences: 'Save',
    preferencesFetchFailed: 'Failed to fetch preferences',
    preferencesUpdateFailed: 'Failed to update preferences',
    preferencesUpdateSucceeded: 'Preferences successfully updated ',
  },
  common: {
    noData: 'No data',
    send: 'Send',
    ok: 'OK',
    cancel: 'Cancel',
    saveChanges: 'Save changes',
    save: 'Save',
    undo: 'Undo',
    close: 'Close',
    filter: 'Filter',
    edit: 'Edit',
    set: 'Set',
    yes: 'Yes',
    no: 'No',
    add: 'Add',
    enabled: 'Enabled',
    disabled: 'Disabled',
    connected: 'Connected',
    disconnected: 'Disconnected',
    next: 'Next',
    back: 'Back',
    confirm: 'Confirm',
    continue: 'Continue',
    getStarted: 'Get started',
    inProgress: 'In Progress',
    successful: 'Successful',
    success: 'Success',
    error: 'Error',
    failed: 'Failed',
    filterDescriptionTitle: 'Filter will satisfy the following criteria:',
    filterDescriptionOptionAll: 'All of the following',
    filterDescriptionOptionAny: 'Any of the following',
    addCriteria: 'Add criteria',
    noFiltersApplied: 'No filters applied',
    selectCondition: 'Select condition',
    removeFilter: 'Remove Filter',
    selectValue: 'Select value',
    selectValueType: 'Select value type',
    location: 'Location',
    noLocationSet: 'No location set',
    noLocationData: 'No data',
    completed: 'Completed',
    somethingWentWrong: 'Something went wrong',
    proceedConfirmationText: 'Do you want to continue?',
    confirmationDefaultTitle: 'Confirmation',
    confirmationDefaultText: 'Are you sure you want to do this?',
    alertDefaultTitle: 'Important Information',
    optionModalDefaultTitle: 'Information Required',
    optionModalDefaultText: 'Please select one of the following options',
    select: 'Select',
    promptDefaultTitle: 'Prompt',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    years: 'Years',
    notFound: 'Not Found',
    toolsPanelSelectedTitle: 'Selected',
    or: 'Or',
    startDate: 'Start Date',
    endDate: 'End Date',
    systemMessageLoadFailure: 'Failed to load system message',
  },
  fleets: {
    dropdownToggleText: 'ACTIONS',
    exportTable: 'Export Table',
    rename: 'Rename',
    delete: 'Delete',
    fleet: 'Fleet',
    assignProfiles: 'Associate Profiles',
    profileAssignmentModalConfirmationButtonText: 'Associate',
    fleetDetails: 'Fleet Details',
    profiles: 'Profiles',
    areYouSureYouWantToDeleteThisFleet:
      'Are you sure you want to delete this fleet?',
    assignDevicesToFleet: 'Associate Devices with Fleet:',
    enterAFleetName: 'Enter a fleet name',

    fleetsTitle: 'Fleets',
    createAFleet: 'CREATE A FLEET',
    creationInProgress: 'Creation in process...',
    success: 'Success: ',
    fleetCreated: 'Fleet created',
    error: 'Error: ',
    cannotPerform: 'Cannot perform',
    fleetMovementConfirmation: `Are you sure you want to make '%{fleetName}' a direct child fleet of '%{targetFleetName}'?`,
    fleetMovementSecondaryConfirmation:
      'Retain initial (%{fleetName}) profiles.',
    fleetNamePlaceholder: 'Enter new fleet name',
    createFleet: 'Create fleet',
    allFleets: 'All fleets',
    selectAllFleets: 'Select All Fleets',
    failedToMoveDevicesErrorText: 'Failed to move devices',
    fleetDeletionError: 'Deleting the fleet failed',
    fleetNotEmptyDeletionError:
      'This fleet has profiles associated with it. You must first dissociate all profiles before deleting it.',

    remoteCommandStatusPopupTitle: 'Sending remote command(s)',
    fleetDeletingStatusPopupTitle: 'Deleting fleet',

    fleetSelectionHint:
      'Select a fleet from the tree to display Fleet devices.',
    fleetMovementModalTitle: 'Choose a fleet',
    fleetMovementModalConfirmationButtonText: 'Move devices',
    toolsPanelTitle: 'Device(s) selected',
    moveDevicesToolTitle: 'Move Device(s)',
    unenrollDevicesToolTitle: 'Unenroll Device(s)',
    pauseAgentUpdateToolTitle: 'Pause Agent Update',
    resumeAgentUpdateToolTitle: 'Resume Agent Update',
    updateDPCAppTitle: 'Update DPC to latest version',
    identifyDevicesToolTitle: 'Identify devices',
    increaseLocationTrackingFrequencyToolTitle: 'Track device(s)',
    forceDeviceCheckInToolTitle: 'Force Device Check-in',
    remoteRebootDevicesToolTitle: 'Remote Reboot Device(s)',
    remoteWipeDevicesToolTitle: 'Remote Wipe Device(s)',
    remoteLockDevicesToolTitle: 'Remote Lock Device(s)',
    resetDevicesPasswordToolTitle: 'Reset device password',
    clearApplicationDataAndCacheToolTitle:
      'Clear application data & cache (Samsung only)',
    sendMessageToolTitle: 'Send Message',
    lockDeviceConfigurationToolTitle: 'Lock device configuration',
    unlockDeviceConfigurationToolTitle: 'Unlock device configuration',
    removeDevicesToolTitle: 'Remove Device(s)',

    unEnrollmentConfirmationText:
      'Are you sure you want to unenroll the selected device(s) ?',
    lockDeviceConfigurationConfirmationText:
      'Are you sure you want to lock the current configuration on the selected device(s)?',
    unlockDeviceConfigurationConfirmationText:
      'Are you sure you want to make configuring the selected device(s) available?',
    removeDevicesConfirmationText:
      'Are you sure you want to delete the selected device(s) ?',
    rebootDevicesConfirmationText:
      'Are you sure you want to reboot the selected device(s) ?',
    wipeDevicesPrimaryConfirmationText:
      'Are you sure you want to remotely wipe the selected device(s) ?',
    wipeDevicesSecondaryConfirmationText: 'Also wipe SD card',
    lockDevicesConfirmationText:
      'Are you sure you want to remotely lock the selected device(s) ?',
    pauseAgentUpdatesConfirmationText:
      'Are you sure you want to pause agent updates on the selected device(s) ?',
    resumeAgentUpdatesConfirmationText:
      'Are you sure you want to resume agent updates on the selected device(s) ?',
    increaseLocationTrackingFrequencyConfirmationText:
      'Are you sure you want to increase the location tracking frequency of the selected devices for the next 10 minutes?',
    identifyDevicesConfirmationText:
      'Are you sure you want to identify the selected device(s)',
    updateDPCAppConfirmationText:
      'Are you sure you want to update the DPC App on the selected device(s) to the latest version?',
    forceCheckinConfirmationText:
      'Are you sure you want to force the selected device(s) to check in?',
    clearDevicesAppDataConfirmationText:
      'Are you sure you want to clear app data on the selected device(s) ?',
    resetDevicesPasswordConfirmationText:
      'Are you sure you want to reset passwords on the selected device(s) ?',

    fleetToProfilesAssignmentSuccessMessage:
      'Successfully assigned the newly selected profiles(s).',
    fleetToProfilesUnassignmentSuccessMessage:
      'Profile(s) successfully dissociated.',
    fleetToProfilesAssignmentFailureMessage:
      'Failed to associate one or more profile.',
    fleetToProfilesUnassignmentFailureMessage:
      'Failed to dissociate one or more profile.',
    fleetDeletionFailure: 'Failed to delete fleet.',
    notEmptyFleetTitle: 'Fleet not empty',
    fleetDeletionFailureDueToDevicesContentMessage:
      'This fleet cannot be deleted because it currently contains one or more devices.',
    fleetDeletionFailureDueToDevicesContentTip:
      'Please delete all the devices this fleet contains and try again.',
    fleetDeletionFailureDueToAssignedProfiles:
      'This fleet cannot be deleted because it currently has one or more profiles assigned to it. Please dissociate these profiles and try again.',
    fleetDeletionConfirmation:
      'Deleting this fleet will remove all versions of it. This action is irreversible. Are you sure you want to continue?',
    lockDeviceConfigurationSuccessMessage:
      'Configuration lock command successfully sent to the selected device(s)',
    unlockDeviceConfigurationSuccessMessage:
      'Configuration unlock command successfully sent to the selected device(s)',
    lockDeviceConfigurationErrorMessage:
      'Failed to send configuration lock command to the selected device(s)',
    unlockDeviceConfigurationErrorMessage:
      'Failed to send configuration unlock command to the selected device(s)',
    failedToLoadProfilesAssignedToFleet:
      'Failed to load profiles assigned to this fleet',
    fleetCreationFailedMessage: 'Fleet creation failed',
    fleetCreationTitle: 'Fleet Creation',
    loadFleetConfigByIdErrorMessage: 'Failed to load fleet config',
    loadAllFleetsErrorMessage: 'Failed to load fleets',
    failedToFetchSelectedFleet: 'Failed to load selected fleet',
    unenrollmentFailedText: 'Failed to unenroll selected devices',
  },
  fileSystem: {
    addTitle: 'Add',
    title: 'File System',
    applicationsSuccessfulUploadMessageText:
      'The following application(s) was(were) successfully uploaded:',
    htmlFilesSuccessfulUploadMessageText:
      'The following HTML file(s) was(were) successfully uploaded:',
    certificatesSuccessfulUploadMessageText:
      'The following certificate(s) was(were) successfully uploaded:',
    filesSuccessfulUploadMessageText:
      'The following file(s) was(were) successfully uploaded:',

    applicationsDuplicateUploadFailureMessageText:
      'The following application(s) was(were) not uploaded because the same version(s) of this(these) application(s) has(have) been uploaded earlier. Please try uploading a different version.',
    htmlFilesDuplicateUploadFailureMessageText:
      'The following HTML file(s) was(were) not uploaded because other file(s) with the same file name(s) was(were) earlier uploaded. Please rename the file(s) and try uploading again.',
    certificatesDuplicateUploadFailureMessageText:
      'The following certificate(s) was(were) not uploaded because other certificate(s) with the same file name(s) was(were) earlier uploaded. Please rename the certificate(s) and try uploading again.',
    filesDuplicateUploadFailureMessageText:
      'The following file(s) was(were) not uploaded because other file(s) with the same file name(s) was(were) earlier uploaded. Please rename the file(s) and try uploading again.',

    applicationsUploadFailureMessageText:
      'The following application(s) failed to upload.',
    htmlFilesUploadFailureMessageText:
      'The following HTML file(s) failed to upload.',
    certificatesUploadFailureMessageText:
      'The following certificate(s) failed to upload.',
    filesUploadFailureMessageText: 'The following file(s) failed to upload.',

    apkFilesUploadMenuItem: 'APK Files',
    filesUploadMenuItem: 'Files',
    certificatesUploadMenuItem: 'Certificates',
    HTMLKioskFilesUploadMenuItem: 'HTML Kiosk Files',

    apkFilesUploadTab: 'Add APK Files',
    filesUploadTab: 'Add Files',
    certificatesUploadTab: 'Add Certificates',
    HTMLKioskFilesUploadTab: 'Add HTML Kiosk Files',

    apkUploading: 'APK uploading:',
    filesUploading: 'Files uploading:',
    certificatesUploading: 'Certificates uploading:',
    htmlKioskFilesUploading: 'HTML Kiosk files uploading:',

    apkUploadingSuccessMessage: 'Applications successfully uploaded!',
    filesUploadingSuccessMessage: 'Files successfully uploaded!',
    certificatesUploadingSuccessMessage: 'Certificates successfully uploaded!',
    htmlKioskFilesUploadingSuccessMessage:
      'HTML Kiosk files successfully uploaded!',

    apkDropZoneTitle: 'Drag and drop your applications from your computer',
    filesDropZoneTitle: 'Drag and drop your files from your computer',
    certificatesDropZoneTitle:
      'Drag and drop your certificates from your computer',
    htmlKioskFilesDropZoneTitle:
      'Drag and drop your HTML Kiosk files from your computer',

    apkAddLinkTitle: 'Add link to APK file storage location',
    filesAddLinkTitle: 'Add link to file storage location',
    certificatesAddLinkTitle: 'Add link to certificate file storage location',
    htmlKioskAddLinkTitle: 'Add link to HTML kiosk file storage location',

    uploading: 'Uploading:',
    registeringFilesOnServer: 'Registering files on the server...',
    browse: 'BROWSE',
    uploadFailed: 'Upload failed',

    appUploadRejectionMessage:
      'The following applications could not be uploaded because either their sizes exceed the maximum allowed size (%{max}) or their file types do not match one of the allowed types (%{allowedFileTypes})',
    certificateUploadRejectionMessage:
      'The following certificates could not be uploaded because either their sizes exceed the maximum allowed size (%{max}) or their file types do not match one of the allowed types (%{allowedFileTypes})',
    fileUploadRejectionMessage:
      'The following files could not be uploaded because either their sizes exceed the maximum allowed size (%{max}) or their file types do not match one of the allowed types (%{allowedFileTypes})',
    htmlUploadRejectionMessage:
      'The following HTML files could not be uploaded because either their sizes exceed the maximum allowed size (%{max})  or their file types do not match one of the allowed types (%{allowedFileTypes})',

    applicationsSelected: 'Applications Selected',
    removeApplications: 'Remove Applications',
    deletingApplications: 'Deleting Applications',

    versionsDeletionTitle: 'Versions Deletion',
    versionsDeletionFailed: 'Versions deletion failed',
    versionsSelected: 'Versions Selected',
    removeVersions: 'Remove Versions',
    deletingVersions: 'Deleting Versions',
    deleteVersionsConfirmation:
      'This is going to delete the selected versions. Are you sure that you want to continue?',
    versionsNotDeletedByAssignedToProfileTitle: `The following version(s) was (were) NOT deleted because they are still assigned to a profile (%{profile})`,
    versionsDeletedTitle:
      'The following version(s) was (were) successfully deleted',

    fileName: 'File Name',
    contentType: 'Content Type',
    assignFiles: 'Associate Files:',
    uploadedBy: 'Uploaded By',

    selectedFiles: 'Selected Files',
    removeFiles: 'Remove Files',
    deletionConfirmation:
      'Are you sure you want to delete the selected file(s)?',
    failedToAssignFilesToProfile: 'Failed to associate files with profile',
    failedToDeleteFiles: 'Failed to delete files',
    failedToLoadFiles: 'Failed to load files',

    sendRemoteLinkFailure: 'Failed to send link to server for processing.',
    sendRemoteLinkSuccess:
      'Link(s) successfully sent to the server for processing.',
  },
};
