import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from '../../../common/helpers';
import { I18n } from 'react-redux-i18n';

const styles = {
  root: {
    width: '75%',
    maxWidth: '600px',
    minWidth: '300px',
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
};

export class ProfileDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderProfileFleets = () => {
    const { fleetNames = [] } = this.props;
    if (isEmpty(fleetNames)) {
      return null;
    }
    return (
      <section>
        <p>{`${I18n.t('profiles.assignedFleetsLabel')}:`}</p>
        {fleetNames.map((item) => (
          <div key={item} style={{ fontWeight: '700' }}>
            {item}
          </div>
        ))}
      </section>
    );
  };

  render() {
    const lastUpdated =
      this.props.profile &&
      this.props.profile.lastUpdated &&
      moment(this.props.profile.lastUpdated).isValid()
        ? moment(this.props.profile.lastUpdated).format(
            'MMMM Do YYYY, h:mm:ss a'
          )
        : null;
    const fleet = this.props.profile.fleetName
      ? this.props.profile.fleetName
      : this.props.profile.fleetId
      ? this.props.profile.fleetId
      : null;
    return (
      <div style={styles.root}>
        <div style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '18px' }}>
              {I18n.t('profiles.profileDetailsTitle')}
            </div>
            <div>{I18n.t('profiles.profileDetailsHint')}</div>
          </div>
        </div>
        {fleet ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '15px',
            }}
          >
            <div style={{ marginBottom: '5px' }}>{`${I18n.t(
              'fleets.fleet'
            )}:`}</div>
            <div style={{ fontWeight: 'bold' }}>{fleet}</div>
          </div>
        ) : null}
        {lastUpdated ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '15px',
            }}
          >
            <div style={{ marginBottom: '5px' }}>{`${I18n.t(
              'profiles.fields.lastUpdated'
            )}:`}</div>
            <div style={{ fontWeight: 'bold' }}>{lastUpdated}</div>
          </div>
        ) : null}
        {this.renderProfileFleets()}
      </div>
    );
  }
}

export default ProfileDetail;

ProfileDetail.propTypes = {
  onChange: PropTypes.func,
  profile: PropTypes.object,
  certificates: PropTypes.arrayOf(PropTypes.object),
};
