export const serializeAuthenticationData = ({
  many_companies = false,
  companies = [],
}) => ({
  isMultiTenantUser: many_companies,
  tenants: companies.map(({ company_id, company_name }) => ({
    tenantId: company_id,
    tenantName: company_name,
  })),
});
