import { I18n } from 'react-redux-i18n';
import {
  COMPANY_LOAD_INFO_FAILED,
  COMPANY_LOAD_INFO_STARTED,
  COMPANY_LOAD_INFO_SUCCESS,
  COMPANY_LOAD_SETTINGS_FAILED,
  COMPANY_LOAD_SETTINGS_STARTED,
  COMPANY_LOAD_SETTINGS_SUCCESS,
  COMPANY_SET_SETTINGS_FAILED,
  COMPANY_SET_SETTINGS_RESET,
  COMPANY_SET_SETTINGS_STARTED,
  COMPANY_SET_SETTINGS_SUCCESS,
} from './company.actionTypes';
import {
  getCompanyInfoApi,
  getCompanySettingsApi,
  setCompanySettingsApi,
} from './company.api';
import {
  deserializeCompanyInfo,
  serializeDevicesInfo,
} from './company.serializer';

export function loadCompanyInfoStarted() {
  return {
    type: COMPANY_LOAD_INFO_STARTED,
  };
}

export function loadCompanyInfoFailed(payload) {
  return {
    type: COMPANY_LOAD_INFO_FAILED,
    payload,
  };
}
export function loadCompanyInfoSuccess(payload) {
  return {
    type: COMPANY_LOAD_INFO_SUCCESS,
    payload,
  };
}

export function loadCompanyInfo(id) {
  return async (dispatch) => {
    try {
      dispatch(loadCompanyInfoStarted());
      let response = await getCompanyInfoApi(id);
      if (response.status === 200 && response.data) {
        let result = deserializeCompanyInfo(response.data);
        dispatch(loadCompanyInfoSuccess(result));
      } else {
        dispatch(loadCompanyInfoFailed(I18n.t('company.failedToLoadInfo')));
      }
    } catch (error) {
      dispatch(
        loadCompanyInfoFailed(new Error(I18n.t('company.failedToLoadInfo')))
      );
    }
  };
}

export function setCompanySettingsStarted() {
  return {
    type: COMPANY_SET_SETTINGS_STARTED,
  };
}

export function setCompanySettingsFailed(payload) {
  return {
    type: COMPANY_SET_SETTINGS_FAILED,
    payload,
  };
}

export function setCompanySettingsSuccess(payload) {
  return {
    type: COMPANY_SET_SETTINGS_SUCCESS,
    payload,
  };
}

export function resetSettingsStatus() {
  return {
    type: COMPANY_SET_SETTINGS_RESET,
  };
}

export function loadCompanySettingsStarted() {
  return {
    type: COMPANY_LOAD_SETTINGS_STARTED,
  };
}

export function loadCompanySettingsFailed(payload) {
  return {
    type: COMPANY_LOAD_SETTINGS_FAILED,
    payload,
  };
}
export function loadCompanySettingsSuccess(payload) {
  return {
    type: COMPANY_LOAD_SETTINGS_SUCCESS,
    payload,
  };
}

export function setCompanySettings(id, payload) {
  return async (dispatch) => {
    try {
      dispatch(setCompanySettingsStarted());
      let response = await setCompanySettingsApi(
        id,
        serializeDevicesInfo(payload)
      );
      if (response.status === 200) {
        dispatch(setCompanySettingsSuccess());
        dispatch(loadCompanySettings(id));
      } else {
        dispatch(
          setCompanySettingsFailed(
            new Error(I18n.t('company.failedToSetPeriod'))
          )
        );
      }
    } catch (error) {
      dispatch(
        setCompanySettingsFailed(new Error(I18n.t('company.failedToSetPeriod')))
      );
    }
  };
}

export function loadCompanySettings(id) {
  return async (dispatch) => {
    try {
      dispatch(loadCompanySettingsStarted());
      let response = await getCompanySettingsApi(id);
      if (response.status === 200) {
        dispatch(
          loadCompanySettingsSuccess(deserializeCompanyInfo(response.data))
        );
      } else {
        dispatch(
          loadCompanySettingsFailed(
            new Error(I18n.t('company.failedToLoadCompanySettings'))
          )
        );
      }
    } catch (error) {
      dispatch(
        loadCompanySettingsFailed(
          new Error(I18n.t('company.failedToLoadCompanySettings'))
        )
      );
    }
  };
}
