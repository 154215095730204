import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { companyIdSelector } from '../features/login/login.selectors';
import { loadCompanyInfo } from '../redux/company.actions';
import { NetworkStatus } from '../common/constants';
import { Throbber } from './Throbber';
import ReadOnlyForm from './ReadOnlyForm/ReadOnlyForm.component';

class CompanyInfo extends React.Component {
  componentDidMount() {
    this.props.loadCompanyInfo(this.props.companyId);
  }

  getReadOnlyFormData = () => {
    const {
      companyInfo: {
        companyName,
        licenseInfo: [{ maxQuantity, inUse, available } = {}] = [],
        comments = '',
      } = {},
    } = this.props;
    return [
      {
        value: companyName,
        title: I18n.t('settings.companyName'),
      },
      {
        value: maxQuantity,
        title: I18n.t('settings.totalNumberOfLicenses'),
      },
      {
        value: inUse,
        title: I18n.t('settings.licensesInUse'),
      },
      {
        value: available == 0 ? '0' : available,
        title: I18n.t('settings.licensesAvailable'),
      },
      {
        value: comments,
        title: I18n.t('settings.commentTitle'),
      },
    ];
  };

  renderCompanyInfo = () => {
    if (this.props.status === NetworkStatus.STARTED) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            padding: '38px 60px',
            whiteSpace: 'normal',
            overflowY: 'auto',
          }}
        >
          <Throbber />
        </div>
      );
    }

    if (
      this.props.status !== NetworkStatus.STARTED &&
      !this.props.companyInfo
    ) {
      return null;
    }

    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          padding: '38px 60px',
          whiteSpace: 'normal',
          overflowY: 'auto',
        }}
      >
        <ReadOnlyForm
          key="company-info"
          items={this.getReadOnlyFormData()}
          headerProps={{
            title: I18n.t('settings.companyInfo'),
            hint: I18n.t('settings.companyInfoHint'),
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <div className={'app__content'}>
          <div className={'page-content'}>
            <div className={'card'}>{this.renderCompanyInfo()}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: companyIdSelector(state),
    companyInfo: state.company.companyInfo,
    status: state.company.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCompanyInfo: (companyId) => dispatch(loadCompanyInfo(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
