import { roleSelector } from '../../features/login/login.selectors';
import { userAccessLevelTypes } from '../../constants/user';

const isAdmin = (state) => roleSelector(state) === userAccessLevelTypes.ADMIN;
const isPartner = (state) =>
  roleSelector(state) === userAccessLevelTypes.PARTNER ||
  roleSelector(state) === userAccessLevelTypes.PARTNER_USER;
const isSuperUser = (state) =>
  roleSelector(state) === userAccessLevelTypes.SUPER_USER;

const hasAdminPrivileges = (state) =>
  isAdmin(state) || isPartner(state) || isSuperUser(state);

const isPartnerOrSuperUser = (state) => isPartner(state) || isSuperUser(state);

export default {
  isAdmin,
  isPartner,
  isSuperUser,
  isPartnerOrSuperUser,
  hasAdminPrivileges,
};
