import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../../components/inputField.component';
import { RootRoutes, SettingsRoutes } from '../../utils/routes';
import { SectionDescription } from '../../components/sectionDescription.component';
import { NetworkStatus } from '../../common/constants';
import { Throbber } from '../../components/Throbber';
import { loadAccountInfo } from './account.thunk';
import SSO from '../../utils/SSO';

class AccountSettings extends Component {
  state = {
    name: '',
    email: '',
  };

  componentDidMount() {
    if (
      this.props.status !== NetworkStatus.STARTED &&
      this.props.status !== NetworkStatus.DONE
    ) {
      this.props.loadAccountInfo();
    }
  }

  handleChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { status, data: { firstName, lastName, email } = {} } = this.props;
    if (status === NetworkStatus.STARTED) {
      return (
        <div className={'app__content'}>
          <Throbber />
        </div>
      );
    }

    return (
      <Fragment>
        <div className={'app__content'}>
          <div className={'page-content'}>
            <div className={'card'}>
              <div className={'account-settings'}>
                <SectionDescription
                  title={I18n.t('settings.accountSettings')}
                />
                <InputField
                  name={'name'}
                  label={I18n.t('settings.fields.name')}
                  placeholder={I18n.t('settings.fields.namePlaceholder')}
                  value={`${firstName} ${lastName}`}
                  onChange={this.handleChange}
                  disabled={true}
                />
                <InputField
                  name={'email'}
                  label={I18n.t('settings.fields.email')}
                  placeholder={I18n.t('settings.fields.emailPlaceholder')}
                  value={email}
                  onChange={this.handleChange}
                  disabled={true}
                />
                <NavLink
                  className={'account-settings__link'}
                  to={`${RootRoutes.SETTINGS}${SettingsRoutes.ACCOUNT}${SettingsRoutes.PASSWORD}`}
                >
                  {I18n.t('settings.changePassword')}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.account.data,
    status: state.account.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAccountInfo: () => dispatch(loadAccountInfo()),
    redirectToSSOProvider: (params) =>
      dispatch(SSO.redirectToSSOProvider(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
