import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { SwitchField } from '../../../components/switchField.component';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    flexFlow: 'column nowrap',
    padding: '0 24px 20px',
    height: '100%',
  },
};

export const initialSettings = {
  gpsAlwaysOn: false,
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'location',
  policyData: null,
};

export class LocationServices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onToggle = (index, key) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings ? item.settings : { ...initialSettings };
    settings[key] = !settings[key];
    item.settings = settings;
    this.props.onChange('location', index, item);
  };

  renderSettings = ({ settings }, index) => {
    return (
      <div
        style={{
          width: '75%',
          maxWidth: '600px',
          minWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          height: '100%',
          borderRight: '1px solid #c7c7c7',
          paddingTop: '20px',
          paddingRight: '20px',
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '18px' }}>
              {I18n.t('profiles.location.policyTitle')}
            </div>
            <div>{I18n.t('profiles.location.locationPolicyHint')}</div>
          </div>
        </div>
        <SwitchField
          onClick={(e) => this.onToggle(index, 'gpsAlwaysOn')}
          on={settings.gpsAlwaysOn || false}
          title={I18n.t('profiles.location.GPSAlwaysOnSetting')}
        />
      </div>
    );
  };

  renderContent = () => {
    return this.props.policy.length
      ? this.props.policy.map(this.renderSettings)
      : this.renderSettings(
          { ...initialPolicy, settings: { ...initialSettings } },
          0
        );
  };

  render() {
    return <div style={styles.root}>{this.renderContent()}</div>;
  }
}

export default LocationServices;

LocationServices.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
