import { cloneDeep, get } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { isEmpty, reorder, searchRecursive } from '../helpers';
import { ACCESS_TYPES } from '../../constants/accessTypes';
import {
  policyType as policyTypeList,
  policyTypeTitleCodes,
} from '../../constants/profiles';

export const getProfilesById = (profiles = [], profileIds = []) =>
  profiles.filter(({ id }) => profileIds.includes(id));

export const getPolicyTypes = (policies = []) =>
  policies.reduce((acc, curr) => {
    if (isEmpty(curr)) {
      return acc;
    }
    return [
      ...acc,
      ...curr.map(({ policyType, settings }) => {
        let type;
        if (policyType === policyTypeList.KIOSK) {
          type = settings.useHTMLKioskMode
            ? policyTypeTitleCodes[policyTypeList.HTML_KIOSK]
            : policyTypeTitleCodes[policyTypeList.KIOSK];
        } else {
          type = policyTypeTitleCodes[policyType];
        }
        return I18n.t(type);
      }),
    ];
  }, []);

export const getAccessibleMenuItems = (
  profileMenuItems,
  permissions = {},
  hasAdminPrivileges = false,
  parentCategoryPermissionId = null
) =>
  profileMenuItems.reduce((acc, currentMenuItem) => {
    const permission = permissions[currentMenuItem?.permissionProperty];
    const parentCategoryPermission = permissions[parentCategoryPermissionId];
    const isAccessible =
      hasAdminPrivileges ||
      permission !== ACCESS_TYPES.NONE ||
      parentCategoryPermission !== ACCESS_TYPES.NONE ||
      currentMenuItem.isAlwaysReadWrite ||
      currentMenuItem.isAlwaysRead;
    if (isAccessible) {
      const menuItem = {
        ...currentMenuItem,
        read:
          permission === ACCESS_TYPES.READ ||
          permission === ACCESS_TYPES.READ_WRITE ||
          parentCategoryPermission === ACCESS_TYPES.READ ||
          parentCategoryPermission === ACCESS_TYPES.READ_WRITE,
        readWrite:
          permission === ACCESS_TYPES.READ_WRITE ||
          parentCategoryPermission === ACCESS_TYPES.READ_WRITE,
        ...(currentMenuItem.isAlwaysReadWrite ? { readWrite: true } : {}),
        ...(currentMenuItem.isAlwaysRead ? { read: true } : {}),
      };

      if (currentMenuItem.sub) {
        return [
          ...acc,
          {
            ...menuItem,
            sub: getAccessibleMenuItems(
              currentMenuItem.sub,
              permissions,
              hasAdminPrivileges,
              currentMenuItem?.permissionProperty
            ),
          },
        ];
      }
      return [
        ...acc,
        {
          ...menuItem,
        },
      ];
    }
    return acc;
  }, []);

export const isProfileConfigurationReadOnly = (
  accessibleMenuItems,
  configKey
) => {
  const menuItem =
    searchRecursive(accessibleMenuItems, 'key', configKey, 'sub') || {};
  return menuItem.read;
};

export const policySettingsArrayValueChangeHandler = ({
  index,
  key,
  value,
  doNotRemove,
  policy,
  idSubstitute,
  initialPolicy,
  initialSettings,
  onChange,
  policyDetailKey,
  result: { source = {}, destination = {} } = {},
}) => {
  const isMovedWithinSameList =
    source &&
    destination &&
    source.droppableId &&
    destination.droppableId &&
    source.droppableId === destination.droppableId;
  const policyClone = [...policy];
  const item =
    policyClone.length && policyClone[index]
      ? cloneDeep({ ...policyClone[index] })
      : { ...initialPolicy };
  const settings = item.settings
    ? cloneDeep(item.settings)
    : { ...initialSettings };
  const setting = get(settings, key, []);
  settings[key] = cloneDeep(setting);
  const itemKey = idSubstitute ?? value.id;
  let foundIndex = settings[key].findIndex(
    (item) => item[itemKey] === value[itemKey]
  );
  if (foundIndex === -1) {
    settings[key].splice(destination.index, 0, value);
  } else if (isMovedWithinSameList) {
    settings[key] = reorder(settings[key], source.index, destination.index);
  } else if (!doNotRemove) {
    // this part is for when item is dropped outside the list
    settings[key].splice(foundIndex, 1);
  }
  item.settings = settings;
  onChange(policyDetailKey, index, item);
};

export const policySettingsValueChangeHandler = ({
  index = 0,
  key,
  value,
  customUpdateObject,
  isValueBoolean = false,
  policy,
  initialPolicy,
  initialSettings,
  onChange,
  policyDetailKey,
}) => {
  let policyClone = cloneDeep(policy);
  let item =
    policyClone.length && policyClone[index]
      ? cloneDeep({ ...policyClone[index] })
      : { ...initialPolicy };
  let settings = item.settings
    ? cloneDeep(item.settings)
    : { ...initialSettings };
  if (customUpdateObject) {
    settings = { ...settings, ...customUpdateObject };
  } else {
    settings[key] = isValueBoolean ? !settings[key] : value;
  }
  item.settings = settings;
  onChange(policyDetailKey, index, item);
};
