import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Glyphicon } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { ReactComponent as PlusCircle } from '../../../assets/images/plus-circle.svg';
import ApplicationBundle from './ApplicationBundle';
import {
  getSelectedListStyle,
  getListItemStyle,
  getAvailableListStyle,
} from './Blacklist.styles';
import { TextButton } from '../../../components/textButton.component';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { I18n } from 'react-redux-i18n';
import { AttributeType } from '../../../common/constants';
import PolicyActivationToggle from './PolicyActivationToggle.component';

export const initialSettings = {
  apps: [],
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'applist',
  isActive: false,
  policyData: null,
};

class Blacklist extends Component {
  render() {
    const { isEditingMode } = this.props;
    return (
      <div className={'app-list-wrapper-container'}>
        {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
      </div>
    );
  }

  getBlacklistedApps = (policy = []) => {
    const { settings: { apps = [] } = {} } = policy[0] || initialPolicy;
    return apps;
  };

  renderReadOnlyContent = () => {
    const { policy = [], policy: [{ isActive } = {}] = [] } = this.props;
    const blacklistedApps = this.getBlacklistedApps(policy);
    const items = [
      {
        id: 'policy-status',
        title: I18n.t('profiles.blacklist.policyStatusTitle'),
        value: isActive,
        type: AttributeType.BOOLEAN,
      },
      {
        title: I18n.t('profiles.blacklist.blacklistedAppsTitle'),
        valueList: blacklistedApps.map(({ idName }) => idName),
      },
    ];
    return (
      <ReadOnlyForm
        items={items}
        headerTitle={I18n.t('profiles.blacklist.policyTitle')}
        emptyListMessage={I18n.t('profiles.blacklist.noBlacklistedAppsMessage')}
      />
    );
  };

  renderContent = () =>
    this.props.policy.length
      ? this.renderSettings(this.props.policy[0], 0)
      : this.renderSettings(
          {
            ...initialPolicy,
            settings: {
              ...initialSettings,
            },
          },
          0
        );

  getCustomUI = () => ({ onClose }) => {
    return (
      <ApplicationBundle
        destinationIndex={0}
        onSubmit={this.addBundle}
        onCancel={onClose}
      />
    );
  };

  renderEmptyListStab = (selected) => {
    if (selected.length === 0) {
      return (
        <div className={'app-list-selected-content-empty-list'}>
          <Glyphicon
            className={'app-list-selected-content-upload-icon'}
            glyph={'cloud-upload'}
          />
          <div className={'app-list-selected-content-empty-list-text'}>
            {I18n.t('profiles.blacklist.noBlacklistedAppsMessage')}
            <br />
            {I18n.t('profiles.blacklist.blacklistUpdateHint')}
          </div>
        </div>
      );
    }
  };

  renderSettings = (policy = {}, index) => {
    const { isActive } = policy;
    const selected = this.getBlacklistedApps([policy]);
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      this.props.onChangePolicyProperty({
        key: 'applist',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });
    return (
      <div className={'app-list-wrapper'}>
        <PolicyActivationToggle
          key="policyActivation"
          policy={this.props.policy}
          titleCode="profiles.blacklist.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
        />
        {isActive ? (
          <div className={'app-list'}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className={'app-list-selected'}>
                <div className={'app-list-selected-header'}>
                  <div className={'app-list-selected-header-title-section'}>
                    <div
                      className={
                        'app-list-selected-header-title-section-header-title'
                      }
                    >
                      <div
                        className={
                          'app-list-selected-header-title-section-header-title__switch-title'
                        }
                      >
                        {I18n.t('profiles.blacklist.blacklistTitle')}
                      </div>
                    </div>
                    <div>
                      {I18n.t('profiles.blacklist.blacklistDescription')}
                    </div>
                  </div>
                  <TextButton
                    onClick={() =>
                      confirmAlert({ customUI: this.getCustomUI() })
                    }
                    hint={I18n.t('profiles.blacklist.addPackageByID')}
                    title={I18n.t('profiles.blacklist.addPackageByID')}
                  >
                    <PlusCircle />
                  </TextButton>
                </div>
                <div className={'app-list-selected-content'}>
                  <div className={'app-list-selected-content-list-header'}>
                    <div>{I18n.t('applications.fields.packageId')}</div>
                  </div>
                  <Droppable droppableId="selected">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getSelectedListStyle(snapshot.isDraggingOver)}
                      >
                        {this.renderEmptyListStab(selected)}
                        {selected.map((item, index) => (
                          <Draggable
                            key={item.id + index + 'blacklisted'}
                            draggableId={item.idName + index + 'blacklisted'}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getListItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                className={
                                  'app-list-selected-content-list-item'
                                }
                              >
                                <div
                                  className={
                                    'app-list-selected-content-list-item-left-cell'
                                  }
                                >
                                  {item.idName || 'n/a'}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              <div className={'app-list-available'}>
                <div className={'app-list-available-header'}>
                  <div className={'app-list-available-header-title-section'}>
                    <div
                      className={
                        'app-list-available-header-title-section__title'
                      }
                    >
                      {I18n.t('applications.myApplicationsTitle')}
                    </div>
                    <div> </div>
                  </div>
                </div>
                <div className={'app-list-available-content'}>
                  <div className={'app-list-available-content-list-header'}>
                    <div>{I18n.t('applications.fields.packageId')}</div>
                    <div>{I18n.t('applications.fields.label')}</div>
                  </div>
                  <Droppable droppableId={'items'}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getAvailableListStyle(snapshot.isDraggingOver)}
                      >
                        {this.props.applications
                          .filter(
                            (application) =>
                              !selected.find((s) => s.id === application.id)
                          )
                          .map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.idName}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={
                                    'app-list-available-content-list-item'
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getListItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div
                                    className={
                                      'app-list-available-content-list-item-left-cell'
                                    }
                                  >
                                    {item.idName || 'n/a'}
                                  </div>
                                  <div
                                    className={
                                      'app-list-available-content-list-item-right-cell'
                                    }
                                  >
                                    {item.label ?? ''}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </DragDropContext>
          </div>
        ) : null}
      </div>
    );
  };

  onChange = (index, key, position, value) => {
    const { policy } = this.props;
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings ? cloneDeep(item.settings) : { apps: [] };

    if (value) {
      const alreadyExist = settings[key]?.find(
        (item) => item.idName === value.idName
      );
      if (alreadyExist) {
        return;
      } else {
        settings[key].splice(position, 0, value);
      }
    } else {
      settings[key].splice(position, 1);
    }
    item.settings = settings;
    this.props.onChange('applist', index, item);
  };

  // onToggle = (index, key) => {
  //     let policy = [...this.props.policy];
  //     let item = policy.length && policy[index] ? cloneDeep({...policy[index]}) : {...initialPolicy};
  //     let settings = item.settings ? item.settings : {apps: []};
  //     settings[key] = !settings[key];
  //     item.settings = settings;
  //     this.props.onChange("applist", index, item)
  // };

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (
      source.droppableId === 'selected' &&
      (!destination || source.droppableId !== destination.droppableId)
    ) {
      this.onChange(0, 'apps', source.index, null);
    }
    if (
      source.droppableId === 'items' &&
      destination &&
      destination.droppableId === 'selected'
    ) {
      const { applicationsFullList, policies } = this.props;
      const appManagementPolicy = policies?.apps?.[0] ?? {};
      let appManagementPolicyClone = cloneDeep(appManagementPolicy);
      const { settings: { apps_v2 = [] } = {} } = appManagementPolicyClone;

      const sourceApp =
        apps_v2.find(({ idName }) => draggableId === idName) ??
        applicationsFullList.find(({ idName }) => draggableId === idName);
      if (sourceApp) {
        this.onChange(0, 'apps', destination.index, sourceApp);
      }
    }
  };

  addBundle = (destinationIndex, bundle) => {
    this.onChange(0, 'apps', destinationIndex, bundle);
  };
}

export default Blacklist;

Blacklist.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
  apps: PropTypes.arrayOf(PropTypes.object),
};
