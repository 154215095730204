import * as React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { history } from '../utils/history';
import { NetworkStatus } from '../common/constants';
import { companyIdSelector } from '../features/login/login.selectors';
import {
  performSearch,
  resetSearch,
  setSearchKeys,
  setSearchText,
} from '../redux/search.actions';
import {
  ApplicationsRoutes,
  DevicesRoutes,
  FleetRoute,
  ProfilesRoutes,
  RootRoutes,
} from '../utils/routes';
import Dialog from './Dialog';
import { Throbber } from './Throbber';
import { selectForCurrentBrand } from '../common/helpers';
import { setDeviceData } from '../features/device/device.actions';

export const entities = [
  'devices',
  'fleets',
  'apps',
  'profiles',
  'enrollments',
];

export class Search extends React.PureComponent {
  componentDidMount() {
    this.performSearchDebounced(this.props.searchText);
  }

  render() {
    const inputClass = selectForCurrentBrand({
      original: 'search-dialog-input-section__input',
      incube:
        'search-dialog-input-section__input search-dialog-input-section__input--incube',
      janam:
        'search-dialog-input-section__input search-dialog-input-section__input--janam',
    });
    return (
      <Dialog>
        <div className={'search-dialog'}>
          <div className={'search-dialog-back-button'} onClick={this.onBack}>
            <div className={'search-dialog-back-button__glyph'}>&#x27F5;</div>
            <div className={'search-dialog-back-button__text'}>
              <Translate value={'back'} />
            </div>
          </div>
          <div className={'search-dialog__title'}>
            <Translate value={'search'} />
          </div>
          <div className={'search-dialog-input-section'}>
            <input
              className={inputClass}
              placeholder={(() => I18n.t('searchDialog.hint'))()}
              value={this.props.searchText}
              onChange={this.onChangeText}
            />
          </div>
          <div className={'search-dialog-filters-section'}>
            {entities.map(this.renderEntityFilter)}
          </div>
          <div className={'search-dialog__content'}>
            {this.renderHint()}
            {entities.map(this.renderSearchResults)}
          </div>
        </div>
      </Dialog>
    );
  }

  renderEntityFilter = (entityKey, index) => {
    return (
      <div
        key={`${entityKey}_control_${index}`}
        className={'search-dialog-filter'}
      >
        <input
          id={entityKey}
          name={entityKey}
          type={'checkbox'}
          className={'search-dialog-filter__checkbox'}
          checked={this.props.selectedKeys.has(entityKey)}
          onChange={this.onCheck}
        />
        <label htmlFor={entityKey} className={'search-dialog-filter__label'}>
          {I18n.t(`searchDialog.${entityKey}`)}
        </label>
      </div>
    );
  };

  renderHint = () => {
    if (this.props.searchText) {
      let totalsText;
      if (this.props.status === NetworkStatus.STARTED) {
        totalsText = I18n.t('searchDialog.searchInProgress');
      } else {
        const totalCount = [...this.props.selectedKeys.keys()].reduce(
          (res, key) =>
            this.props[key] && this.props[key].length
              ? res + this.props[key].length
              : res,
          0
        );
        totalsText = `(${totalCount} ${I18n.t('searchDialog.resultsInTotal')})`;
      }
      return (
        <div className={'search-dialog-hint'}>
          <div className={'search-dialog-hint__title'}>
            {`${I18n.t('searchDialog.resultsWith')} ‘ ${
              this.props.searchText
            }‘`}
          </div>
          <div className={'search-dialog-hint__description'}>{totalsText}</div>
        </div>
      );
    }
  };

  renderSearchResults = (entityKey, index) => {
    if (
      this.props.selectedKeys.has(entityKey) &&
      this.props[entityKey] &&
      this.props[entityKey].length
    ) {
      const labelClass = selectForCurrentBrand({
        original: 'search-dialog-results-list__label',
        incube: 'search-dialog-results-list__label--incube',
        janam: 'search-dialog-results-list__label--janam',
      });
      return (
        <div
          key={`${entityKey}_results_${index}`}
          className={'search-dialog-results-list'}
        >
          <div className={labelClass}>{entityKey}</div>
          {this.renderSearchResultContent(entityKey)}
        </div>
      );
    }
  };

  renderSearchResultContent = (entityKey) => {
    if (
      this.props.status === NetworkStatus.STARTED &&
      this.props.devices.length
    ) {
      return <Throbber />;
    }
    if (this.props.status === NetworkStatus.DONE) {
      return this.props[entityKey].map((entity, index) =>
        this.renderSearchResult(entityKey, entity, index)
      );
    }
  };

  renderSearchResult = (entityKey, entity, index) => {
    let route;
    let customOnclick;
    if (entityKey === 'devices') {
      route = `${RootRoutes.DEVICES}${DevicesRoutes.ALL}/${entity.id}`;
      customOnclick = () => this.props.setDeviceData(entity);
    }
    if (entityKey === 'fleets') {
      route = `${FleetRoute}/${entity.id}`;
    }
    if (entityKey === 'enrollments') {
      route = `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/${entity.id}`;
    }
    if (entityKey === 'profiles') {
      route = `${RootRoutes.PROFILES}${ProfilesRoutes.ALL}/${entity.id}`;
    }
    if (entityKey === 'apps') {
      route = `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/${entity.id}`;
    }
    let key = entity.id + entityKey + index;
    let text = entity.name || '';
    if (route) {
      return (
        <div key={key} className={'search-dialog-results-list__item'}>
          <div
            onClick={() => {
              customOnclick?.();
              history.push(route);
            }}
          >
            {text}
          </div>
        </div>
      );
    }
    return (
      <div
        key={key}
        className={
          'search-dialog-results-list__item search-dialog-results-list__item--inactive'
        }
      >
        {text}
      </div>
    );
  };

  onChangeText = ({ target: { value } }) => {
    this.props.setSearchText(value);
    this.performSearchDebounced(value);
  };

  performSearchDebounced = debounce((value) => this.performSearch(value), 300);

  performSearch = (value) => {
    if (value) {
      this.props.performSearch(this.props.companyId, value);
    } else {
      this.props.resetSearch();
    }
  };

  onCheck = ({ target: { checked, name } }) => {
    const selectedKeys = new Set(this.props.selectedKeys);
    if (checked) {
      selectedKeys.add(name);
    } else {
      selectedKeys.delete(name);
    }
    this.props.setSearchKeys(selectedKeys);
  };

  onBack = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    history.goBack();
  };
}

const mapStateToProps = (state) => {
  return {
    companyId: companyIdSelector(state),
    status: state.search.status,
    error: state.search.error,
    devices: state.search.devices,
    fleets: state.search.fleets,
    apps: state.search.apps,
    profiles: state.search.profiles,
    enrollments: state.search.enrollments,
    searchText: state.search.searchText,
    selectedKeys: state.search.selectedKeys,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    performSearch: (companyId, text) =>
      dispatch(performSearch(companyId, text)),
    resetSearch: () => dispatch(resetSearch()),
    setSearchKeys: (keys) => dispatch(setSearchKeys(keys)),
    setSearchText: (text) => dispatch(setSearchText(text)),
    setDeviceData: (data) => dispatch(setDeviceData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
