import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { profilesColumns } from '../../profiles/profiles.constants';
import { companyIdSelector } from '../../login/login.selectors';
import { assignApplicationsToProfiles } from '../application.thunk';
import { resetAssignApplicationToProfiles } from '../application.actions';
import { NetworkStatus } from '../../../common/constants';
import { Button } from '../../../components/button.component';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { showStatusChangePopup } from '../../../common/utilities/common';
import AsyncRendererWithDataFetching from '../../../components/AsyncRenderer/AsyncRendererWithDataFetching.component';
import { DataConstants } from '../../../constants/data';
import {
  profilesSelector,
  profilesV2Selector,
} from '../../../selectors/profiles';
import DataTable from '../../../components/DataTable/DataTable.component';
import { Pages } from '../../../constants/pages';
import { getProfilesById } from '../../../common/utilities/profile';

class ApplicationProfilesSelection extends Component {
  state = {
    selected: new Set(),
  };

  tableRef = React.createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.assignationStatus !== prevProps.assignationStatus &&
      (this.props.assignationStatus === NetworkStatus.DONE ||
        this.props.assignationStatus === NetworkStatus.ERROR)
    ) {
      showStatusChangePopup({
        onClose: () => {
          this.props.resetAssignApplicationToProfiles();
          if (this.props.assignationStatus === NetworkStatus.DONE) {
            this.setState({ selected: new Set() });
          }
        },
        status: this.props.assignationStatus,
        errorText:
          this.props.assignationError ??
          I18n.t('applications.assignProfilesFailureDefaultMessage'),
        successText: I18n.t('applications.assignProfilesSuccessDefaultMessage'),
      });
    }
  }

  render() {
    const { profilesV2, profilesV1 } = this.props;
    const profiles = [...profilesV2, ...profilesV1];

    return (
      <AsyncRendererWithDataFetching
        dataList={[
          {
            id: DataConstants.PROFILES_V2,
            params: {
              loadFullList: true,
              isInitialLoad: true,
            },
          },
          {
            id: DataConstants.PROFILES_V1,
          },
        ]}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SectionDescription
              title={I18n.t('applications.assignProfilesHeading')}
              hint={I18n.t('applications.assignProfilesHint')}
            />
            {this.renderAssignButton()}
          </div>
          <DataTable
            allColumns={profilesColumns}
            data={profiles}
            onSelect={this.select}
            pageId={Pages.APPLICATION_PROFILES_SELECTION}
            tableRef={this.tableRef}
            selectable
            selected={this.state.selected}
            useFlex
          />
        </div>
      </AsyncRendererWithDataFetching>
    );
  }

  renderAssignButton = () => {
    return (
      <Button
        disabled={!this.state.selected.size}
        onClick={this.assignToProfiles}
      >
        {I18n.t('applications.assignProfilesButtonText')}
      </Button>
    );
  };

  assignToProfiles = () => {
    const { profilesV2, profilesV1 } = this.props;
    const selectedProfiles = [...this.state.selected.values()];
    const selectedV1Profiles = getProfilesById(
      profilesV1,
      selectedProfiles
    ).map(({ id }) => id);
    const selectedV2Profiles = getProfilesById(
      profilesV2,
      selectedProfiles
    ).map(({ id }) => id);
    this.props.assignApplicationsToProfiles(
      [this.props.match.params.childRoute],
      selectedV1Profiles,
      selectedV2Profiles
    );
  };

  select = (selectedIds) => {
    const selected = new Set(selectedIds);
    this.setState(() => ({ selected }));
  };
}

const mapStateToProps = (state) => {
  const { data: profilesV2 } = profilesV2Selector(state);
  const { data: profilesV1 } = profilesSelector(state);
  return {
    companyId: companyIdSelector(state),
    data: state.profiles.data,
    status: state.profiles.status,
    error: state.profiles.error,
    assignationStatus: state.application.assignationStatus,
    assignationError: state.application.assignationError,
    profilesV2,
    profilesV1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignApplicationsToProfiles: (apps, profiles, profilesV2) =>
      dispatch(assignApplicationsToProfiles(apps, profiles, profilesV2)),
    resetAssignApplicationToProfiles: () =>
      dispatch(resetAssignApplicationToProfiles()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationProfilesSelection);
