import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { APIRoutes, RootRoutes } from '../utils/routes';
import APIOverview from '../features/apiOverview/apiOverview.component';
import ApiUsageHistory from '../features/apiUsageHistory/apiUsageHistory.component';

const APIManager = () => (
  <Switch>
    <Redirect
      exact
      from={RootRoutes.API}
      to={`${RootRoutes.API}${APIRoutes.OVERVIEW}`}
    />
    <Route
      exact
      path={`${RootRoutes.API}${APIRoutes.OVERVIEW}`}
      component={APIOverview}
    />
    <Route
      exact
      path={`${RootRoutes.API}${APIRoutes.USAGE_HISTORY}`}
      component={ApiUsageHistory}
    />
  </Switch>
);

export default APIManager;
