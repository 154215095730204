import React from 'react';
import { FormGroup, ControlLabel, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class FirmwareUpdate extends React.Component {
  state = {};

  handleInstallUpdate = () => {
    console.log('installation begins...');
  };

  render() {
    const { readOnly } = this.props || [];

    return (
      <div
        style={{
          width: '75%',
          maxWidth: '600px',
          minWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          height: '100%',
          padding: '20px 24px',
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '18px' }}>Firmware Update</div>
            <div>Information Related Firmware Update.</div>
          </div>
        </div>
        <FormGroup controlId="os_label" bsSize="small">
          <ControlLabel>iOS 12 Public Beta 1</ControlLabel>
        </FormGroup>
        <FormGroup controlId="os_installation_btn" bsSize="small">
          <Button onClick={this.handleInstallUpdate} disabled={readOnly}>
            Install
          </Button>
        </FormGroup>
        <FormGroup
          controlId="os_installation_progress"
          bsSize="small"
        ></FormGroup>
      </div>
    );
  }
}

FirmwareUpdate.propTypes = {
  readOnly: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareUpdate);
