export const ENROLLMENT_LOAD_STARTED = 'ENROLLMENT_LOAD_STARTED';
export const ENROLLMENT_LOAD_FAILED = 'ENROLLMENT_LOAD_FAILED';
export const ENROLLMENT_LOAD_SUCCESS = 'ENROLLMENT_LOAD_SUCCESS';
export const ENROLLMENT_RESET = 'ENROLLMENT_RESET';
export const ENROLLMENT_CREATE_STARTED = 'ENROLLMENT_CREATE_STARTED';
export const ENROLLMENT_CREATE_FAILED = 'ENROLLMENT_CREATE_FAILED';
export const ENROLLMENT_CREATE_SUCCESS = 'ENROLLMENT_CREATE_SUCCESS';
export const ENROLLMENT_CHANGE_PROPERTY = 'ENROLLMENT_CHANGE_PROPERTY';

export function enrollmentLoadingStarted() {
  return {
    type: ENROLLMENT_LOAD_STARTED,
  };
}

export function enrollmentLoadingFailed(payload) {
  return {
    type: ENROLLMENT_LOAD_FAILED,
    payload,
  };
}

export function enrollmentLoadingLoaded(payload) {
  return {
    type: ENROLLMENT_LOAD_SUCCESS,
    payload,
  };
}

export function changeEnrollmentProperty(payload) {
  return {
    type: ENROLLMENT_CHANGE_PROPERTY,
    payload,
  };
}

export function resetEnrollmentCreation() {
  return {
    type: ENROLLMENT_RESET,
  };
}

export function createEnrollmentStarted() {
  return {
    type: ENROLLMENT_CREATE_STARTED,
  };
}

export function createEnrollmentFailed(payload) {
  return {
    type: ENROLLMENT_CREATE_FAILED,
    payload,
  };
}

export function createEnrollmentSuccess(payload) {
  return {
    type: ENROLLMENT_CREATE_SUCCESS,
    payload,
  };
}
