export const GLOBAL_PERMISSIONS_FOR_USER_LOAD_STARTED =
  'GLOBAL_PERMISSIONS_FOR_USER_LOAD_STARTED';
export const GLOBAL_PERMISSIONS_FOR_USER_FAILED =
  'GLOBAL_PERMISSIONS_FOR_USER_FAILED';
export const GLOBAL_PERMISSIONS_FOR_USER_SUCCESS =
  'GLOBAL_PERMISSIONS_FOR_USER_SUCCESS';

export const LINK_SSO_ACCOUNT_STARTED = 'LINK_SSO_ACCOUNT_STARTED';
export const LINK_SSO_ACCOUNT_FAILED = 'LINK_SSO_ACCOUNT_FAILED';
export const LINK_SSO_ACCOUNT_SUCCESS = 'LINK_SSO_ACCOUNT_SUCCESS';
