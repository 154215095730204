import { I18n } from 'react-redux-i18n';
import client from '../../utils/client';
import services from '../../utils/services';
import {
  createEnrollmentFailed,
  createEnrollmentStarted,
  createEnrollmentSuccess,
  enrollmentLoadingFailed,
  enrollmentLoadingLoaded,
  enrollmentLoadingStarted,
} from './enrollment.actions';
import {
  deserializeEnrollment,
  serializeEnrollment,
} from './enrollment.serializer';
import { loadEnrollments } from '../enrollments/enrollments.thunk';

export function loadEnrollment(enrollmentId) {
  return async (dispatch) => {
    try {
      dispatch(enrollmentLoadingStarted());
      let response = await client.get(
        `${services.getDeviceEnrollment}${enrollmentId}`,
        {}
      );
      if (response.status === 200 && response.data) {
        let result = deserializeEnrollment(response.data);
        dispatch(enrollmentLoadingLoaded(result));
      } else {
        dispatch(
          enrollmentLoadingFailed(I18n.t('enrollments.loadEnrollmentError'))
        );
      }
    } catch (error) {
      dispatch(
        enrollmentLoadingFailed(
          new Error(I18n.t('enrollments.loadEnrollmentError'))
        )
      );
    }
  };
}

export function updateEnrollment(enrollment) {
  return async (dispatch) => {
    try {
      dispatch(enrollmentLoadingStarted());
      let data = serializeEnrollment(enrollment);
      let response = await client.put(services.updateDeviceEnrollment, data);
      if (response.status === 200 && response.data) {
        dispatch(loadEnrollment(enrollment.id));
      } else if (response.state === 400) {
        dispatch(
          enrollmentLoadingFailed(
            I18n.t('enrollments.updateEnrollmentUniqueNameErrorText')
          )
        );
        //{
        //    "detail": "Name must be unique"
        //}
      } else {
        dispatch(
          enrollmentLoadingFailed(I18n.t('enrollments.updateEnrollmentError'))
        );
      }
    } catch (error) {
      dispatch(
        enrollmentLoadingFailed(
          new Error(I18n.t('enrollments.updateEnrollmentError'))
        )
      );
    }
  };
}

export function createEnrollment(enrollment) {
  return async (dispatch) => {
    try {
      dispatch(createEnrollmentStarted());
      let data = serializeEnrollment(enrollment);
      let response = await client.post(services.createDeviceEnrollment, data);
      if (response.status === 200 && response.data) {
        dispatch(createEnrollmentSuccess(deserializeEnrollment(response.data)));
        dispatch(loadEnrollments({ isInitialLoad: true }));
      } else if (response.status > 399) {
        let errorText = I18n.t('enrollments.createEnrollmentErrorText');
        if (response.data && response.data.detail) {
          errorText = response.data.detail;
        }
        dispatch(createEnrollmentFailed(errorText));
      } else {
        dispatch(
          createEnrollmentFailed(
            I18n.t('enrollments.createEnrollmentErrorText')
          )
        );
      }
    } catch (error) {
      dispatch(
        createEnrollmentFailed(I18n.t('enrollments.createEnrollmentErrorText'))
      );
    }
  };
}
