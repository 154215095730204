import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { I18n, Translate } from 'react-redux-i18n';
import { InputField } from '../../../components/inputField.component';
import { SelectField } from '../../../components/selectField.component';
import { ReactComponent as Trash } from '../../../assets/images/trash.svg';
import { ActionButton } from '../../../components/actionButton.component';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../../common/constants';
import {
  getObjectByValue,
  isEmpty,
  isNotUndefined,
} from '../../../common/helpers';
import { SwitchField } from '../../../components/switchField.component';
import TextArea from '../../../components/TextArea';
import PolicyActivationToggle from './PolicyActivationToggle.component';
import PolicyHeader from './PolicyHeader.component';
import {
  acceptedEAPTypeOptions,
  phase2AuthenticationMethodOptions,
  proxyConfigTypes,
  proxyOptions,
  securitySettingsOptions,
  wiFiNetworkFrequencyOptions,
  wiFiNetworkTypes,
} from '../../../common/wifiConstants';

export const initialSettings = {
  password: '',
  auth: 'WEP',
  wifiFrequency: '0.0',
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'wifi',
  isActive: false,
  policyData: null,
};

export const WiFi = (props) => {
  const isPolicyActive = props.policy.some((item) => item.isActive);

  const onChange = (
    index,
    key,
    { target: { value } } = {},
    isToggle = false,
    presetNewToggleValue = undefined
  ) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    if (isToggle && isNotUndefined(presetNewToggleValue)) {
      settings[key] = presetNewToggleValue;
    } else {
      settings[key] = isToggle ? !settings[key] : value;
    }
    item.settings = settings;
    props.onChange('wifi', index, item);
  };

  const onChangeCertificate = (
    { target: { value } },
    index,
    fieldName,
    fileName = 'filename'
  ) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    if (!value) {
      settings[fieldName] = undefined;
      settings[fileName] = undefined;
      settings['lastUpdated'] = undefined;
    } else {
      let certificate;
      if (props.certificates.length) {
        certificate = props.certificates.find((cert) => cert.id === value);
      }
      settings[fieldName] = value;
      if (certificate) {
        settings[fileName] = certificate.filename;
        settings['lastUpdated'] = certificate.lastUpdated;
      }
    }

    item.settings = settings;
    props.onChange('wifi', index, item);
  };

  const renderSettings = ({ settings }, index) => {
    const certificatesOptions = [
      { value: '', title: '' },
      ...props.certificates.map(({ id: value, filename: title }) => ({
        value,
        title,
      })),
    ];

    return (
      <div key={`wifi_policy_${index}`} className={'wifi-content-settings'}>
        <div className={'wifi-content-settings-section-description'}>
          <div className={'section-description__title--small'}>
            {`${I18n.t('profiles.wifi.wifiNetworkTitle')} ${index + 1}`}
          </div>
          <ActionButton
            title={I18n.t('profiles.wifi.removeConfigurationActionButtonText')}
            onClick={() => props.onChange('wifi', index, null)}
          >
            <Trash className={'icon'} />
          </ActionButton>
        </div>
        <div>
          <InputField
            label={I18n.t('profiles.wifi.SSID')}
            value={settings.ssid || ''}
            onChange={(e) => onChange(index, 'ssid', e)}
            placeholder={I18n.t('profiles.wifi.SSIDPlaceholder')}
          />
          <InputField
            label={I18n.t('profiles.wifi.SSIDPassword')}
            type={'password'}
            value={settings.key || ''}
            onChange={(e) => onChange(index, 'key', e)}
            placeholder={I18n.t('profiles.wifi.SSIDPasswordPlaceholder')}
          />
          <>
            <SelectField
              title={I18n.t('profiles.wifi.WiFiFrequency')}
              hint={I18n.t('profiles.wifi.frequencyHint')}
              value={settings.wifiFrequency || '0.0'}
              onChange={(e) => onChange(index, 'wifiFrequency', e)}
              options={wiFiNetworkFrequencyOptions}
            />
            <SwitchField
              onClick={(e) => onChange(index, 'hiddenNetwork', e, true)}
              on={settings.hiddenNetwork || false}
              title={I18n.t('profiles.wifi.hiddenNetwork')}
            />
            <SelectField
              title={I18n.t('profiles.wifi.proxyTypeTitle')}
              value={settings.proxy}
              onChange={(e) => onChange(index, 'proxy', e)}
              options={proxyOptions}
            />
            {settings.proxy === proxyConfigTypes.MANUAL && (
              <>
                <InputField
                  label={I18n.t('profiles.wifi.proxyHostnameTitle')}
                  value={settings.proxyHostName || ''}
                  onChange={(e) => onChange(index, 'proxyHostName', e)}
                  placeholder={'proxy.example.com'}
                />
                <InputField
                  label={I18n.t('profiles.wifi.proxyPortTitle')}
                  type="number"
                  value={settings.proxyPort || ''}
                  onChange={(e) => onChange(index, 'proxyPort', e)}
                  placeholder={8080}
                />
                <TextArea
                  label={I18n.t('profiles.wifi.proxyBypassTitle')}
                  hint={<Translate value="profiles.wifi.proxyBypassHint" />}
                  onChange={(e) => onChange(index, 'proxyBypassList', e)}
                  name="proxyBypassList"
                  value={settings.proxyBypassList}
                  autoGrow
                />
              </>
            )}
            {settings.proxy === proxyConfigTypes.AUTO_CONFIG && (
              <>
                <InputField
                  label={I18n.t('profiles.wifi.proxyAutoConfigURLTitle')}
                  value={settings.proxyAutoConfigURL || ''}
                  onChange={(e) => onChange(index, 'proxyAutoConfigURL', e)}
                  placeholder={'https://www.example.com/proxy'}
                />
              </>
            )}
          </>
          <SelectField
            title={I18n.t('profiles.wifi.securityType')}
            value={settings.auth || ''}
            onChange={(e) => onChange(index, 'auth', e)}
            options={securitySettingsOptions}
          />
          {settings.auth === wiFiNetworkTypes._802__1X_ENTERPRISE ? (
            <div>
              <SelectField
                title={I18n.t('profiles.wifi.acceptedEAPTypes')}
                value={settings.acceptedEAPType}
                onChange={(e) => onChange(index, 'acceptedEAPType', e)}
                options={acceptedEAPTypeOptions}
              />
              <InputField
                label={I18n.t('profiles.wifi.identity')}
                value={settings.identity || ''}
                onChange={(e) => onChange(index, 'identity', e)}
                placeholder={I18n.t('profiles.wifi.identity')}
              />
              <InputField
                label={I18n.t('profiles.wifi.password')}
                type="password"
                value={settings.password || ''}
                onChange={(e) => onChange(index, 'password', e)}
                placeholder={I18n.t('profiles.wifi.passwordPlaceholder')}
              />
              <SelectField
                title={I18n.t('profiles.wifi.clientCertificate')}
                value={settings.clientCertificateId}
                onChange={(e) =>
                  onChangeCertificate(
                    e,
                    index,
                    'clientCertificateId',
                    'clientCertificateName'
                  )
                }
                options={certificatesOptions}
              />
              <SelectField
                title={I18n.t('profiles.wifi.CACertificate')}
                value={settings.caCertificateId}
                onChange={(e) =>
                  onChangeCertificate(
                    e,
                    index,
                    'caCertificateId',
                    'caCertificateName'
                  )
                }
                options={certificatesOptions}
              />
              <InputField
                label={I18n.t('profiles.wifi.CACertificateDomain')}
                value={settings.caCertificateDomain || ''}
                onChange={(e) => onChange(index, 'caCertificateDomain', e)}
                placeholder={I18n.t('profiles.wifi.CACertificateDomain')}
              />
              <InputField
                label={I18n.t('profiles.wifi.domainSuffixMatch')}
                value={settings.domainSuffixMatch || ''}
                onChange={(e) => onChange(index, 'domainSuffixMatch', e)}
                placeholder={I18n.t('profiles.wifi.domainSuffixMatch')}
              />
              <InputField
                label={I18n.t('profiles.wifi.alternateSubjectMatch')}
                value={settings.alternateSubjectMatch || ''}
                onChange={(e) => onChange(index, 'alternateSubjectMatch', e)}
                placeholder={I18n.t('profiles.wifi.alternateSubjectMatch')}
              />
              <SelectField
                title={I18n.t('profiles.wifi.phase2AuthenticationMethod')}
                value={settings.phase2AuthenticationMethod}
                onChange={(e) =>
                  onChange(index, 'phase2AuthenticationMethod', e)
                }
                options={phase2AuthenticationMethodOptions}
              />
              <InputField
                label={I18n.t('profiles.wifi.anonymousIdentity')}
                value={settings.anonymousIdentity || ''}
                onChange={(e) => onChange(index, 'anonymousIdentity', e)}
                placeholder={I18n.t('profiles.wifi.anonymousIdentity')}
              />
              <InputField
                label={I18n.t('profiles.wifi.publicLandMobileNetwork')}
                value={settings.publicLandMobileNetwork || ''}
                onChange={(e) => onChange(index, 'publicLandMobileNetwork', e)}
                placeholder={I18n.t('profiles.wifi.publicLandMobileNetwork')}
              />
              <InputField
                label={I18n.t('profiles.wifi.realm')}
                value={settings.realm || ''}
                onChange={(e) => onChange(index, 'realm', e)}
                placeholder={I18n.t('profiles.wifi.realm')}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <div className={'wifi-content__details'}>
        {I18n.t('profiles.wifi.policyHint')}
      </div>
    );
  };

  const handleAddClick = () =>
    props.onChange('wifi', props.policy.length, {
      ...initialPolicy,
      isActive: true,
      settings: { ...initialSettings },
    });

  const renderWifiSettings = () => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      props.onChangePolicyProperty({
        key: 'wifi',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });

    return (
      <>
        {props.policy?.length ? (
          <>
            <PolicyActivationToggle
              key="activationAttribute"
              policy={props.policy}
              titleCode="profiles.wifi.wifiPolicyEnabledTitle"
              handleToggle={onEnableButtonToggle}
            />
            {isPolicyActive ? props.policy.map(renderSettings) : null}
          </>
        ) : (
          renderDetails()
        )}
      </>
    );
  };

  const renderContent = () => (
    <React.Fragment>
      <PolicyHeader
        descriptionTitle={I18n.t('profiles.wifi.title')}
        descriptionHint={I18n.t('profiles.wifi.descriptionHint')}
        textButtonProps={{
          handleTextButtonClick: handleAddClick,
        }}
      />
      <div className={'wifi-content'}>{renderWifiSettings()}</div>
    </React.Fragment>
  );

  const renderWifiNetworkSettingsReadOnly = (wifiNetwork, index) => {
    const { certificates } = props;
    const {
      settings: {
        auth,
        ssid,
        acceptedEAPType,
        identity,
        password,
        wifiFrequency,
        hiddenNetwork,
        proxy,
        proxyHostName,
        proxyPort,
        proxyBypassList,
        proxyAutoConfigURL,
        clientCertificateId,
        caCertificateId,
        caCertificateDomain,
        domainSuffixMatch,
        alternateSubjectMatch,
        phase2AuthenticationMethod,
        anonymousIdentity,
        publicLandMobileNetwork,
        realm,
      } = {},
    } = wifiNetwork;
    const { title: securityTypeTitle } = getObjectByValue(
      securitySettingsOptions,
      auth
    );
    const { title: wifiFrequencyTitle } = getObjectByValue(
      wiFiNetworkFrequencyOptions,
      wifiFrequency
    );
    const { title: phase2AuthenticationMethodTitle } = getObjectByValue(
      phase2AuthenticationMethodOptions,
      phase2AuthenticationMethod
    );
    const { title: acceptedEAPTypeTitle } = getObjectByValue(
      acceptedEAPTypeOptions,
      acceptedEAPType
    );
    const { title: proxyTypeTitle } = getObjectByValue(proxyOptions, proxy);
    const { filename: clientCertificateTitle = '' } = getObjectByValue(
      certificates,
      clientCertificateId,
      'id'
    );
    const { filename: caCertificateTitle = '' } = getObjectByValue(
      certificates,
      caCertificateId,
      'id'
    );
    const wifiNetworkSettingsIndex = index + 1;
    const is802_1xEnterprise = auth === wiFiNetworkTypes._802__1X_ENTERPRISE;
    return (
      <ReadOnlyForm
        key={`read-only-form-${index}`}
        headerTitle={`${I18n.t(
          'profiles.wifi.wifiNetworkTitle'
        )} ${wifiNetworkSettingsIndex}`}
        items={[
          {
            title: I18n.t('profiles.wifi.SSID'),
            value: ssid,
            type: AttributeType.TEXT,
            key: 'ssid',
          },
          {
            title: I18n.t('profiles.wifi.SSIDPassword'),
            // value: key,
            value: '********',
            type: AttributeType.TEXT,
            key: 'key',
          },
          {
            title: I18n.t('profiles.wifi.WiFiFrequency'),
            value: wifiFrequencyTitle,
            type: AttributeType.TEXT,
            key: 'wifiFrequency',
          },
          {
            title: I18n.t('profiles.wifi.hiddenNetwork'),
            value: hiddenNetwork,
            type: AttributeType.BOOLEAN,
            key: 'hiddenNetwork',
          },
          {
            title: I18n.t('profiles.wifi.proxyTypeTitle'),
            value: proxyTypeTitle,
            type: AttributeType.TEXT,
            key: 'proxy',
          },
          {
            title: I18n.t('profiles.wifi.proxyHostnameTitle'),
            value: proxyHostName,
            type: AttributeType.TEXT,
            key: 'proxyHostName',
          },
          {
            title: I18n.t('profiles.wifi.proxyPortTitle'),
            value: proxyPort,
            type: AttributeType.TEXT,
            key: 'proxyPort',
          },
          {
            title: I18n.t('profiles.wifi.proxyBypassTitle'),
            value: proxyBypassList,
            valueList: proxyBypassList?.split('\n') ?? [],
            type: AttributeType.TEXT,
            key: 'proxyBypassList',
          },
          {
            title: I18n.t('profiles.wifi.proxyAutoConfigURLTitle'),
            value: proxyAutoConfigURL,
            type: AttributeType.TEXT,
            key: 'proxyAutoConfigURL',
          },
          {
            title: I18n.t('profiles.wifi.securityType'),
            value: securityTypeTitle,
            type: AttributeType.TEXT,
            key: 'securityTypeTitle',
          },
          ...(is802_1xEnterprise
            ? [
                {
                  title: I18n.t('profiles.wifi.acceptedEAPTypes'),
                  value: acceptedEAPTypeTitle,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'acceptedEAPTypeTitle',
                },
                {
                  title: I18n.t('profiles.wifi.identity'),
                  value: identity,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'identity',
                },
                {
                  title: I18n.t('profiles.wifi.password'),
                  value: password,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'password',
                },
                {
                  title: I18n.t('profiles.wifi.clientCertificate'),
                  value: clientCertificateTitle,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'clientCertificateTitle',
                },
                {
                  title: I18n.t('profiles.wifi.CACertificate'),
                  value: caCertificateTitle,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'caCertificateTitle',
                },
                {
                  title: I18n.t('profiles.wifi.CACertificateDomain'),
                  value: caCertificateDomain,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'caCertificateDomain',
                },
                {
                  title: I18n.t('profiles.wifi.domainSuffixMatch'),
                  value: domainSuffixMatch,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'domainSuffixMatch',
                },
                {
                  title: I18n.t('profiles.wifi.alternateSubjectMatch'),
                  value: alternateSubjectMatch,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'alternateSubjectMatch',
                },
                {
                  title: I18n.t('profiles.wifi.phase2AuthenticationMethod'),
                  value: phase2AuthenticationMethodTitle,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'phase2AuthenticationMethodTitle',
                },
                {
                  title: I18n.t('profiles.wifi.anonymousIdentity'),
                  value: anonymousIdentity,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'anonymousIdentity',
                },
                {
                  title: I18n.t('profiles.wifi.publicLandMobileNetwork'),
                  value: publicLandMobileNetwork,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'publicLandMobileNetwork',
                },
                {
                  title: I18n.t('profiles.wifi.realm'),
                  value: realm,
                  defaultValue: '-',
                  type: AttributeType.TEXT,
                  key: 'realm',
                },
              ]
            : []),
        ]}
      />
    );
  };

  const renderReadOnlyContent = () => {
    const { policy = [] } = props;
    if (isEmpty(policy)) {
      return <p>{I18n.t('profiles.wifi.wifiPolicyEnabledTitle')}</p>;
    }
    return [
      <ReadOnlyForm
        key="isPolicyActive"
        headerTitle={I18n.t('profiles.wifi.title')}
        items={[
          {
            title: I18n.t('profiles.wifi.wifiPolicyEnabledTitle'),
            value: isPolicyActive,
            type: AttributeType.BOOLEAN,
            key: 'isPolicyActive',
          },
        ]}
      />,
      ...policy.map(renderWifiNetworkSettingsReadOnly),
    ];
  };

  const { isEditingMode } = props;

  return (
    <div className={'wifi-wrapper'}>
      <div className="wifi">
        {isEditingMode ? renderContent() : renderReadOnlyContent()}
      </div>
    </div>
  );
};

export default WiFi;

WiFi.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
