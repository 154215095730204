import { I18n } from 'react-redux-i18n';
import { filterConditions } from '../constants/filter';

// eslint-disable-next-line no-useless-escape
export const filterWithConditionRegExp = /([^\[\]]*)\[([^\[\]]*)]$/im;

export const filterConditionOptions = [
  { key: filterConditions.EQUAL, titleCode: I18n.t('filter.equal') },
  {
    key: filterConditions.GREATER_THAN,
    titleCode: I18n.t('filter.greaterThan'),
  },
  { key: filterConditions.LESS_THAN, titleCode: I18n.t('filter.lessThan') },
  {
    key: filterConditions.GREATER_THAN_OR_EQUAL,
    titleCode: I18n.t('filter.greaterThanOrEqual'),
  },
  {
    key: filterConditions.LESS_THAN_OR_EQUAL,
    titleCode: I18n.t('filter.lessThanOrEqual'),
  },
  { key: filterConditions.NOT_EQUAL, titleCode: I18n.t('filter.notEqual') },
  { key: filterConditions.CONTAINS, titleCode: I18n.t('filter.contains') },
  {
    key: filterConditions.DOES_NOT_CONTAIN,
    titleCode: I18n.t('filter.doesNotContain'),
  },
  {
    key: filterConditions.NOT_CONTAINED_IN,
    titleCode: I18n.t('filter.notContainedIn'),
  },
  { key: filterConditions.BEGINS_WITH, titleCode: I18n.t('filter.beginsWith') },
  {
    key: filterConditions.DOES_NOT_BEGIN_WITH,
    titleCode: I18n.t('filter.doesNotBeginWith'),
  },
  { key: filterConditions.ENDS_WITH, titleCode: I18n.t('filter.endsWith') },
  {
    key: filterConditions.DOES_NOT_END_WITH,
    titleCode: I18n.t('filter.doesNotEndWith'),
  },
];
