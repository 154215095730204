import * as React from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Redirect } from 'react-router-dom';
import { login } from './login.actions';
import { Button } from '../../components/button.component';
import { selectForCurrentBrand } from '../../common/helpers';

import { azureSAML2, SSOOptions } from '../../constants/login';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { Modal } from '../../components/modal.component';
import StyleUtils from '../../utils/styleUtils';
import { Throbber } from '../../components/Throbber';
import SSO from '../../utils/SSO';
import {
  INCUBE_LOGO_FULL,
  JANAM_LOGO_FULL,
  SPRINGDEL_LOGO_FULL,
} from '../../common/brandSpecificConstants';

class Login extends React.Component {
  state = {
    redirectToReferrer: false,
    email: '',
    password: '',
    organizationId: '',
    isSSOLoginShown: false,
    isOrganizationIdModalShown: false,
  };

  componentDidMount() {
    let { location: { state: { isSSORedirect } = {} } = {} } = this.props;
    if (isSSORedirect) {
      this.handleSSORedirect();
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps.loading && !this.props.loading && this.props.error) {
  //     PopupActions.showAlert({
  //       title: I18n.t('login.loginErrorTitle'),
  //       text: this.props.error?.message,
  //       onClose: () => {
  //         clearAllData();
  //         // the next line forces a reload.
  //         window.location.assign('/');
  //       },
  //     });
  //   }
  // }

  toggleSSOLogin = () =>
    this.setState((prevState) => ({
      isSSOLoginShown: !prevState.isSSOLoginShown,
    }));

  toggleOrganizationIdModal = () =>
    this.setState((prevState) => ({
      isOrganizationIdModalShown: !prevState.isOrganizationIdModalShown,
    }));

  handleSSORedirect = () => {
    let { location: { state: { requestUrl } = {} } = {} } = this.props;
    this.props.handleSSOProviderRedirect(requestUrl);
  };

  renderSSOLoginButtons = () => (
    <section className={StyleUtils.mergeClasses('sso-list', 'sso-login-list')}>
      {SSOOptions.map(
        ({
          id,
          protocol,
          provider,
          icon,
          loginText,
          isLoginEnabled,
          requestOrganizationId,
        }) =>
          isLoginEnabled ? (
            <ButtonWithIcon
              key={id}
              onClick={
                requestOrganizationId
                  ? this.toggleOrganizationIdModal
                  : () =>
                      this.props.redirectToSSOProvider({
                        id,
                        provider,
                        protocol,
                      })
              }
              leftIcon={
                <img
                  src={icon}
                  width={21}
                  height={21}
                  alt={`${provider} ${protocol} icon`}
                />
              }
              text={loginText}
              className={StyleUtils.mergeModifiers(
                'sso-list__item',
                'full-width'
              )}
            />
          ) : null
      )}
    </section>
  );

  renderOrganizationIdRequestForm = () => {
    const { organizationId } = this.state;
    return (
      <form
        className="organization-id-form"
        onSubmit={this.onOrganizationIdSubmit}
      >
        <p>{I18n.t('login.loginWithSAMLHint')}</p>
        <FormGroup controlId="organizationId" bsSize="large">
          <FormControl
            autoFocus
            value={organizationId}
            onChange={this.handleChange}
            placeholder={I18n.t('login.organizationIDFieldTitle')}
            required
            title={I18n.t('login.organizationIDFieldTitle')}
          />
        </FormGroup>
        <FormGroup className={'login-page__actions-section'}>
          <Button type={'submit'} disabled={!organizationId}>
            <Translate
              className={'login-button__text'}
              value={'loginForm.login'}
            />
          </Button>
        </FormGroup>
      </form>
    );
  };

  render() {
    let { email, password } = this.state;
    let {
      isAuthenticated,
      loading,
      error,
      location: { state } = {},
    } = this.props;
    let { from } = state || { from: { pathname: '/' } };

    if (isAuthenticated) {
      return <Redirect to={from} />;
    }

    if (loading) {
      return <Throbber />;
    }

    const logo = selectForCurrentBrand({
      original: SPRINGDEL_LOGO_FULL,
      incube: INCUBE_LOGO_FULL,
      janam: JANAM_LOGO_FULL,
    });
    const pageClass = selectForCurrentBrand({
      original: 'login-page',
      incube: 'login-page login-page--incube',
      janam: 'login-page login-page--janam',
    });

    return (
      <div className={pageClass}>
        <div className={'login-page-dialog'}>
          <div className={'login-page-dialog__content'}>
            <div className={'login-page__logo'}>{logo}</div>
            <form className={'login-page-form'} onSubmit={this.onSubmit}>
              <FormGroup
                className={'login-page-form__group'}
                controlId="email"
                bsSize="large"
              >
                <FormControl
                  autoFocus
                  type="email"
                  value={email}
                  onChange={this.handleChange}
                  placeholder={I18n.t('loginForm.email')}
                  disabled={loading}
                  required
                  autoComplete={'username'}
                  title={I18n.t('loginForm.email')}
                />
              </FormGroup>
              <FormGroup
                className={'login-page-form__group'}
                controlId="password"
                bsSize="large"
              >
                <FormControl
                  value={password}
                  onChange={this.handleChange}
                  type="password"
                  placeholder={I18n.t('loginForm.password')}
                  disabled={loading}
                  required
                  autoComplete={'current-password'}
                />
              </FormGroup>
              <FormGroup className={'login-page__actions-section'}>
                <Button
                  type={'submit'}
                  disabled={!this.validateForm() || loading}
                >
                  <Translate
                    className={'login-button__text'}
                    value={'loginForm.login'}
                  />
                </Button>
              </FormGroup>
            </form>
            <FormGroup>
              <div className="login-status">
                {loading && <Translate value={'loginForm.wait'} />}
                {isAuthenticated && (
                  <Translate
                    value={'loginForm.success'}
                    className={'login-status--success'}
                  />
                )}
                {error && (
                  <div className={'login-status--danger'}>{error.message}</div>
                )}
              </div>
            </FormGroup>
            <div className="login-page-dialog__sso-login-text">
              {I18n.t('common.or')}
            </div>
            <Button onClick={this.toggleSSOLogin}>
              {I18n.t('login.signInWithAnSSOProviderButtonText')}
            </Button>
          </div>
        </div>
        {this.state.isSSOLoginShown ? (
          <Modal
            title={I18n.t('login.loginWithAnSSOProvideModalText')}
            onBack={this.toggleSSOLogin}
            key="SSOLoginOptionsModal"
            actionsSection={
              <Button onClick={this.toggleSSOLogin}>
                {I18n.t('common.cancel')}
              </Button>
            }
          >
            {this.renderSSOLoginButtons()}
          </Modal>
        ) : null}
        {this.state.isOrganizationIdModalShown ? (
          <Modal
            title={I18n.t('login.SAMLLoginModalTitle')}
            onBack={this.toggleOrganizationIdModal}
            actionsSection={
              <Button onClick={this.toggleOrganizationIdModal}>
                {I18n.t('common.cancel')}
              </Button>
            }
            key="OrganizationIdModal"
            numberInStack={2}
          >
            {this.renderOrganizationIdRequestForm()}
          </Modal>
        ) : null}
      </div>
    );
  }

  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  handleChange = ({ target: { value, id } = {} }) => {
    this.setState({
      [id]: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let { email, password } = this.state;
    this.props.login(email, password);
    this.setState({ email: '', password: '' });
  };

  onOrganizationIdSubmit = (e) => {
    e.preventDefault();
    const { id, provider, protocol } = azureSAML2;
    this.props.redirectToSSOProvider({
      id,
      provider,
      protocol,
      data: {
        organization_id: this.state.organizationId,
      },
    });
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.login.loading,
    error: state.login.error,
    isAuthenticated: state.login.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    handleSSOProviderRedirect: (requestUrl) =>
      dispatch(SSO.handleSSOProviderRedirect(requestUrl)),
    redirectToSSOProvider: (params) =>
      dispatch(SSO.redirectToSSOProvider(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
