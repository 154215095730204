export function deserializeCompanyInfo(data) {
  return {
    customerName: data.customer_name || null,
    companyName: data.company_name || null,
    comments: data.comments || '',
    licenseInfo:
      data.license_info && data.license_info.length
        ? data.license_info.map(deserializeLicense)
        : [],
    automaticAgentUpdates:
      typeof data.automatic_agent_updates === 'boolean'
        ? data.automatic_agent_updates
        : false,
  };
}

export function deserializeLicense(data) {
  return {
    maxQuantity: data.license_max_qty || null,
    inUse: data.license_in_use || null,
    type: data.license_type || null,
    expiry: data.license_expiry || null,
    available: data.license_available || null,
  };
}

export function serializeDevicesInfo(data) {
  return {
    automatic_agent_updates: data.automaticAgentUpdates,
  };
}
