import { isEmpty } from '../helpers';
import { getAccessFromGlobalPermissionsForUser } from './users';
import { GLOBAL_READ_READWRITE_PERMISSIONS } from '../../constants/globalPermissions';
import { ACCESS_TYPES } from '../../constants/accessTypes';

// fleets v1 only
export const isRemoteControlEnabled = (fleets = [], { fleetId } = {}) => {
  if (isEmpty(fleetId) || isEmpty(fleets)) return false;
  const fleet = fleets.find((item) => item.id === fleetId) || {};
  return fleet.rc_enabled;
};

export const isRemoteControlAvailable = (fleetConfig = {}) =>
  fleetConfig.rc_enabled;

export const generateDeviceNamingExample = ({
  sequence = [],
  separator = '_',
  startingNumber = '0',
  name = '',
}) => {
  let res = '';
  if (isEmpty(sequence)) return res;
  res = sequence.reduce((result, value) => {
    switch (value) {
      case 'name': {
        if (name) {
          if (result) {
            return `${result}${separator}${name}`;
          }
          return name;
        }
        break;
      }
      case 'imei': {
        if (result) {
          return `${result}${separator}${'01-012345-012345'}`;
        }
        return '01-012345-012345';
      }
      case 'phone_number': {
        if (result) {
          return `${result}${separator}${'1-844-636-6339'}`;
        }
        return '1-844-636-6339';
      }
      case 'numbered_sequence': {
        let sequenceValue = '00001';
        const bitDifference = !isNaN(Number(startingNumber))
          ? sequenceValue.length - String(Number(startingNumber)).length
          : undefined;
        if (bitDifference !== undefined) {
          if (bitDifference > 0) {
            sequenceValue = `${Array(bitDifference).fill('0').join('')}${String(
              Number(startingNumber)
            )}`;
          } else {
            sequenceValue = String(Number(startingNumber));
          }
        }

        if (result) {
          return `${result}${separator}${sequenceValue}`;
        }
        return sequenceValue;
      }
      case 'mac_address': {
        if (result) {
          return `${result}${separator}${'12-34-56-78-9A-BC'}`;
        }
        return '12-34-56-78-9A-BC';
      }
      case 'hardware_serial_number': {
        if (result) {
          return `${result}${separator}${'012802B00000000000'}`;
        }
        return '012802B00000000000';
      }
      case 'os_version': {
        if (result) {
          return `${result}${separator}${'Android SDK: 24 (7.0)'}`;
        }
        return '012802B00000000000';
      }
      case 'model': {
        if (result) {
          return `${result}${separator}${'model'}`;
        }
        return 'model';
      }
      case 'manufacturer': {
        if (result) {
          return `${result}${separator}${'manufacturer'}`;
        }
        return 'manufacturer';
      }
      case 'device_name': {
        if (result) {
          return `${result}${separator}${'ABC'}`;
        }
        return 'ABC';
      }
      default:
        return result;
    }
    return result;
  }, '');

  if (!sequence.some((el) => el === 'numbered_sequence')) {
    let prefix = startingNumber > 99 ? '' : startingNumber > 9 ? '000' : '0000';
    res = res + `_${prefix}${startingNumber}`;
  }

  return res;
};

export const getDevicesTableColumns = (
  globalPermissionsForUser = {},
  getFunction,
  hasAdminPrivileges = false
) => {
  const isCoordinatesColumnVisible =
    hasAdminPrivileges ||
    getAccessFromGlobalPermissionsForUser(
      globalPermissionsForUser,
      GLOBAL_READ_READWRITE_PERMISSIONS.VIEW_DEVICE_LOCATION,
      ACCESS_TYPES.READ
    );
  return getFunction({
    isCoordinatesColumnHidden: !isCoordinatesColumnVisible,
  }).filter(({ hidden }) => !hidden);
};
