import React from 'react';
import { v4 as uuid } from 'uuid';
import StyleUtils from '../utils/styleUtils';

export const InputField = React.forwardRef(
  (
    {
      label,
      type,
      value,
      onChange,
      placeholder,
      id,
      additionalClassName = '',
      ...rest
    },
    ref
  ) => {
    const inputId = id ?? uuid();

    return (
      <div
        className={StyleUtils.mergeClasses('input-field', additionalClassName)}
      >
        <label htmlFor={inputId} className={'input-field__title'}>
          {label}
        </label>
        <input
          {...rest}
          ref={ref}
          id={inputId}
          className={'field-form-control'}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    );
  }
);
