export function deserializeDevice(device) {
  return {
    agentVersion: device.agent_version,
    appList: device.app_list && device.app_list.length ? device.app_list : [],
    availableMemory:
      device.available_memory && device.available_memory >= 0
        ? device.available_memory
        : null,
    availableStorage:
      device.available_storage && device.available_storage >= 0
        ? device.available_storage
        : null,
    batteryLevel: !isNaN(Number(device.battery_level))
      ? Number(device.battery_level)
      : undefined,
    companyId: device.company_id || null,
    coordinates:
      device.latitude && device.longitude
        ? { latitude: device.latitude, longitude: device.longitude }
        : undefined,
    customNotes: device.custom_notes ? device.custom_notes : '',
    developerMode: device.developer_mode === true,
    familyInfo: device.device_family_info ? device.device_family_info : null,
    id: device.device_id || null,
    name: device.device_name || '',
    locked: device.locked ?? false,
    status:
      device.device_status && device.device_status.length
        ? device.device_status.toUpperCase()
        : null,
    type:
      device.device_family_info &&
      device.device_family_info.DISTRIB_ID &&
      device.device_family_info.DISTRIB_ID === 'OpenWrt'
        ? 'IOT'
        : 'MOBILE',
    enrollmentDatetime: device.enrollment_datetime || null,
    fleetId: device.fleetv2_id || device.fleet_id || '',
    fleetName:
      device.fleetv2_name ||
      device.fleetv1_name ||
      device.fleet_name ||
      device.fleet_path ||
      '',
    fleetV2Path: device.fleetv2_path,
    hardwareSerialNumber: device.hardware_serial_number || null,
    imei: device.imei || '',
    ipAddress: device.ip_address || null,
    lastAccessed: device.last_accessed || null,
    lastUpdated: device.last_updated || null,
    lastCheckin: device.last_checkin ? device.last_checkin : null,
    checkinPeriod: device.device_check_in_period
      ? device.device_check_in_period
      : null,
    updatePeriod: device.device_update_period
      ? device.device_update_period
      : null,
    lastFleetCheckin: device.last_checkin_fleet
      ? device.last_checkin_fleet
      : null,
    macAddress: device.mac_address ?? '',
    ethernetMacAddress: device.ethernet_mac_address ?? '',
    manufacturer: device.manufacturer || '',
    model: device.model || '',
    networkBssid: device.network_BSSID || '',
    networkRssi: device.network_RSSI || '',
    networkSsid: device.network_SSID || '',
    networkDNS: device.network_DNS || '',
    networkConnectionType: device.network_connection_type || null,
    nfcStatus: device.nfc_status || null,
    osVersion: device.os_version || null,
    pauseMode: device.pauseMode === true,
    phoneNumber: device.phoneNumber || '',
    powerStatus: device.power_status || undefined,
    roaming: device.roaming === true,
    security: device.security || null,
    totalMemory:
      device.total_memory && device.total_memory >= 0
        ? device.total_memory
        : null,
    totalStorage:
      device.total_storage && device.total_storage >= 0
        ? device.total_storage
        : null,
    compliance: device.compliance || null,
    wifiStatus: device.wifi_status || null,
    latestAgent:
      device.custom_attrs && device.custom_attrs.latest_agent
        ? device.custom_attrs.latest_agent
        : false,
    sendingAnalyticsData: device.custom_attrs
      ? device.custom_attrs.sending_analytical_data
      : undefined,
    enabledAutomaticUpdates: device.automatic_updates || undefined,
    haveUpdates:
      device.custom_attrs && device.custom_attrs.have_updates
        ? device.custom_attrs.have_updates
        : false,
    hasLatestAgent: !device.custom_attrs?.have_updates,
    ...deserializeSecuritySettings(device.security_settings || {}),
  };
}

export const deserializeLogEntry = ({
  action_timestamp,
  longitude,
  latitude,
} = {}) => ({
  actionTimestamp: action_timestamp,
  coordinates: {
    longitude,
    latitude,
  },
});

export const deserializeActivityLogEntry = ({
  action_timestamp,
  context,
  level,
  metadata,
} = {}) => ({
  actionTimestamp: action_timestamp,
  context,
  level,
  metadata,
});

export const serializeDeviceLogRequestParams = ({
  startDate,
  endDate,
  deviceId,
}) => ({
  gte: startDate,
  lte: endDate,
  device_id: deviceId,
});

export const deserializeSecuritySettings = (data = {}) => {
  let {
    encrypted,
    passcode_enabled,
    security_compliance,
    android_security_patch_level,
  } = data;
  return {
    encrypted: encrypted || false,
    passcodeEnabled: passcode_enabled || false,
    securityCompliance: security_compliance || false,
    androidSecurityPatchLevel: android_security_patch_level || '',
  };
};
