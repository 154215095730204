import client from '../utils/client';
import services from '../utils/services';

export async function getCompanyInfoApi(id = '') {
  return client.get(services.companies + id + '/info');
}

export async function getCompanySettingsApi(id = '') {
  return client.get(services.companies + id);
}

export async function setCompanySettingsApi(id = '', data = {}) {
  return client.patch(services.companies + id, data);
}
