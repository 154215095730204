import {
  CERTIFICATES_DELETE_FAILED,
  CERTIFICATES_DELETE_STARTED,
  CERTIFICATES_DELETE_SUCCESS,
} from './certificates.actionTypes';
import {
  CERTIFICATES_LOAD_FAILED,
  CERTIFICATES_LOAD_STARTED,
  CERTIFICATES_LOAD_SUCCESS,
} from './certificates.actionTypes';

export function certificatesLoadingStarted() {
  return {
    type: CERTIFICATES_LOAD_STARTED,
  };
}

export function certificatesLoadingFailed(payload) {
  return {
    type: CERTIFICATES_LOAD_FAILED,
    payload,
  };
}

export function certificatesLoadingSuccess(payload) {
  return {
    type: CERTIFICATES_LOAD_SUCCESS,
    payload,
  };
}

export const deleteCertificatesStarted = () => ({
  type: CERTIFICATES_DELETE_STARTED,
});

export const deleteCertificatesFailed = () => ({
  type: CERTIFICATES_DELETE_FAILED,
});

export const deleteCertificatesSuccess = () => ({
  type: CERTIFICATES_DELETE_SUCCESS,
});
