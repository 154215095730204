export const deserializeCertificate = (data) => {
  return {
    id: data.certificate_id || null,
    companyId: data.company_id || '',
    filename: data.filename || '',
    lastUpdated: data.last_updated || null,
    external_link: data.external_link || null,
    uploadedByName: data.uploaded_by_name,
    uploadedByEmail: data.uploaded_by_email,
    uploadedOn: data.uploaded_on,
  };
};

export const serializeCertificate = (data) => {
  return {
    certificate_id: data.id || undefined,
    filename: data.filename || undefined,
    last_updated: data.lastUpdated || undefined,
    external_link: data.external_link || null,
  };
};
