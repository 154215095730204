import React from 'react';
import { v4 as uuid } from 'uuid';
import StyleUtils from '../utils/styleUtils';

export const SelectField = React.forwardRef(
  (
    {
      title,
      hint,
      value,
      onChange,
      options = [],
      name,
      multiple,
      disabled,
      showEmptyOption = false,
      withNoBottomMargin = false,
      rightIcon = null,
    },
    ref
  ) => {
    const id = uuid();

    const selectOptions = showEmptyOption
      ? [
          {
            title: '',
            value: '',
          },
          ...options,
        ]
      : options;
    const renderOption = (option) => (
      <option key={option.value} value={option.value}>
        {option.title} {option.showRightIcon ? rightIcon : null}
      </option>
    );

    return (
      <div
        className={StyleUtils.mergeModifiers(
          'select-field',
          withNoBottomMargin && 'no-bottom-margin'
        )}
      >
        <label htmlFor={id} className={'select-field__title'}>
          {title}
          <div className="select-field__hint">{hint}</div>
        </label>
        <select
          ref={ref}
          id={id}
          className={'field-form-control'}
          value={value}
          onChange={onChange}
          name={name}
          multiple={multiple}
          disabled={disabled}
        >
          {selectOptions.map(renderOption)}
        </select>
      </div>
    );
  }
);
