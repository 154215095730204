import { NetworkStatus } from '../../common/constants';
import {
  PROFILES_ASSIGN_TO_FLEETS_FAILED,
  PROFILES_ASSIGN_TO_FLEETS_RESET,
  PROFILES_ASSIGN_TO_FLEETS_STARTED,
  PROFILES_ASSIGN_TO_FLEETS_SUCCESS,
  PROFILES_LOAD_FAILED,
  PROFILES_LOAD_STARTED,
  PROFILES_LOAD_SUCCESS,
} from './profiles.actions';

export default function profiles(
  state = {
    data: [],
    status: NetworkStatus.NONE,
    error: undefined,
    assignToFleetsStatus: NetworkStatus.NONE,
    assignToFleetsError: undefined,
  },
  action
) {
  switch (action.type) {
    case PROFILES_LOAD_STARTED:
      return Object.assign({}, state, {
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case PROFILES_LOAD_FAILED:
      return Object.assign({}, state, {
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case PROFILES_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    case PROFILES_ASSIGN_TO_FLEETS_STARTED:
      return Object.assign({}, state, {
        assignToFleetsStatus: NetworkStatus.STARTED,
        assignToFleetsError: undefined,
      });
    case PROFILES_ASSIGN_TO_FLEETS_FAILED:
      return Object.assign({}, state, {
        assignToFleetsStatus: NetworkStatus.ERROR,
        assignToFleetsError: action.payload,
      });
    case PROFILES_ASSIGN_TO_FLEETS_SUCCESS:
      return Object.assign({}, state, {
        assignToFleetsStatus: NetworkStatus.DONE,
        assignToFleetsError: undefined,
      });
    case PROFILES_ASSIGN_TO_FLEETS_RESET:
      return Object.assign({}, state, {
        assignToFleetsStatus: NetworkStatus.NONE,
        assignToFleetsError: undefined,
      });

    default:
      return state;
  }
}
