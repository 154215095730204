import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Button } from '../button.component';
import { history } from '../../utils/history';

const EntityCreationWizardStepsNavigation = ({
  secondaryNav,
  primaryNav: { first: primaryNavFirst, second: primaryNavSecond } = {},
}) => {
  const goToPreviousStep = () => history.goBack();

  const renderNavItem = ({ text, isBack, ...rest }, isSecondary = false) => {
    if (isBack) {
      return (
        <Button onClick={goToPreviousStep} secondary>
          {`${I18n.t('common.back')} ⟵`}
        </Button>
      );
    }
    return (
      <Button secondary={isSecondary} {...rest}>
        {text}
      </Button>
    );
  };

  return (
    <div className="entity-creation-wizard-steps-navigation">
      {secondaryNav ? renderNavItem(secondaryNav, true) : <div />}
      <div className="entity-creation-wizard-steps-navigation__primary">
        {primaryNavFirst ? renderNavItem(primaryNavFirst) : <div />}
        {primaryNavSecond ? renderNavItem(primaryNavSecond) : null}
      </div>
    </div>
  );
};

export default EntityCreationWizardStepsNavigation;
