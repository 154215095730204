export const MAP_VIEW_LOAD_DEVICES_STARTED = 'MAP_VIEW_LOAD_DEVICES_STARTED';
export const MAP_VIEW_LOAD_DEVICES_FAILED = 'MAP_VIEW_LOAD_DEVICES_FAILED';
export const MAP_VIEW_LOAD_DEVICES_SUCCESS = 'MAP_VIEW_LOAD_DEVICES_SUCCESS';
export const MAP_VIEW_RECEIVED_DEVICE_UPDATES =
  'MAP_VIEW_RECEIVED_DEVICE_UPDATES';

export function loadMapViewDevicesStarted(payload) {
  return {
    type: MAP_VIEW_LOAD_DEVICES_STARTED,
    payload,
  };
}

export function loadMapViewDevicesFailed(payload) {
  return {
    type: MAP_VIEW_LOAD_DEVICES_FAILED,
    payload,
  };
}

export function mapViewDevicesLoaded(payload) {
  return {
    type: MAP_VIEW_LOAD_DEVICES_SUCCESS,
    payload,
  };
}

export function mapViewDeviceUpdatesReceived(payload) {
  return {
    type: MAP_VIEW_RECEIVED_DEVICE_UPDATES,
    payload,
  };
}
