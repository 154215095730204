export const fr_fr = {
  loginForm: {
    email: 'Adresse e-mail',
    password: 'Mot de passe',
    login: 'Connexion',
    wait: `S'il vous plaît, attendez...`,
    success: 'Succès...',
  },
  login: {
    multiTenantProceedButtonText: 'Connexion',
    multiTenantCancelButtonText: 'Annuler',
    loginErrorTitle: `Erreur d'identification`,
    multiTenantPopupTitle: 'Sélection du locataire',
    multiTenantPopupText:
      'Votre compte a plusieurs locataires. Veuillez choisir le locataire auquel vous souhaitez vous connecter.',
    SAMLLoginModalTitle: 'Connectez-vous avec SAML',
    loginWithAnSSOProvideModalText: 'Connectez-vous avec un fournisseur SSO',
    signInWithAnSSOProviderButtonText: 'Connectez-vous avec un fournisseur SSO',
    organizationIDFieldTitle: `Identifiant de l'organisation`,
    loginWithSAMLHint: `Veuillez saisir l'ID de votre organisation pour vous connecter avec SAML`,
  },
  session: {
    sessionExpiryWarningMessage: `Votre cession est sur le point d'expirer en raison de votre inactivité.`,
    sessionExpiryWarningTitle: `Avertissement d'expiration de session`,
    logoutNow: 'Déconnectez-vous maintenant',
    stayLoggedIn: 'Rester connecté',
    sessionExpiryTitle: 'Expiration de la session',
    sessionExpiryMessage: 'Votre session a expiré. Veuillez vous reconnecter.',
    logoutFailure: 'Échec de la déconnexion. Veuillez réessayer.',
  },
  errors: {
    unauthorized: 'Authentification échouée',
    invalidCredentials: 'E-mail et mot de passe invalides',
    serviceUnavailable: 'Le service est indisponible',
    loggedOut: 'Logged Out',
    invalidPermissionsOrAccessLevel: `Vous n'avez pas l'autorisation ou le niveau d'accès requis pour effectuer cette action. Veuillez contacter l'administrateur.`,
  },
  back: 'Arrière',
  search: 'Chercher',
  searchDialog: {
    hint: 'Que cherchez-vous?',
    resultsInTotal: 'Résultats au total',
    resultsWith: 'Résultats avec',
    searchInProgress: 'Recherche en cours',
    fleets: 'FLOTTES',
    apps: 'APPS',
    devices: 'DISPOSITIFS',
    profiles: 'PROFILS',
    enrollments: 'INSCRIPTIONS',
    failedToPerformSearch: 'Échec de la recherche',
  },
  profileBar: {
    changeAccountSettings: 'MODIFIER LES PARAMÈTRES DU COMPTE',
    logout: 'SE DÉCONNECTER',
    addAProfile: 'AJOUTER UN PROFIL',
    addAFleet: 'AJOUTER UNE FLOTTE',
    uploadAnApp: 'TÉLÉCHARGER UNE APPLICATION',
    createEnrollmentMethod: `CRÉER UNE MÉTHODE D'INSCRIPTION`,
    help: 'Aider',
  },
  notifications: {
    markAllRead: 'Marquer tout comme lu',
    removeAll: 'Enlever tout',
    noNewNotifications: 'pas de nouvelles notifications',
    markAsRead: 'Marquer comme lu',
    remove: 'Supprimer',
    deviceHasLeftTheGeofence: `L'appareil %{deviceName} a quitté la barrière géographique`,
    deviceHasEnteredTheGeofence: `L'appareil %{deviceName} est entré dans la barrière géographique`,
    deviceIsSecurityCompliant: '%{deviceName} est conforme à la sécurité',
    deviceIsNotSecurityCompliant: `%{deviceName} n'est pas conforme à la sécurité`,
    deviceSecurityComplianceStatusUnknown: `%{deviceName} l'état de conformité de la sécurité est inconnu`,
    deviceHasALowBatteryLevel: '%{deviceName} a un faible niveau de batterie',
    deviceHasANormalBatteryLevel:
      '%{deviceName} a un niveau de batterie normal',
    deviceHasAnUnknownBatteryLevel:
      '%{deviceName} a un niveau de batterie inconnu',
  },
  certificates: {
    filename: 'Nom du fichier de certificat',
    lastUpdated: 'Dernière mise à jour',
    deletionConfirmation:
      'Êtes-vous sûr de vouloir supprimer le(s) certificat(s) sélectionné(s)?',
    removeFiles: 'Supprimer des fichiers',
    selectedFiles: 'CERTIFICATS SÉLECTIONNÉS',
    certificateDeletionError: 'Erreur lors de la suppression des certificats',
    certificatesLoadError: 'Erreur lors de la récupération des certificats',
    uploadedBy: 'Uploaded By',
    uploadedOn: 'Uploaded On',
  },
  htmlKioskFiles: {
    filename: 'Nom de fichier',
    lastUpdated: 'Dernière mise à jour',
    createdAt: 'Établi',
    deletionConfirmation:
      'Êtes-vous sûr de vouloir supprimer le(s) fichier(s) HTML sélectionné(s)?',
    removeFiles: 'Supprimer des fichiers',
    selectedFiles: 'FICHIERS SÉLECTIONNÉS',
    uploadedBy: 'Uploaded By',
  },
  attributiveTable: {
    notFound: 'Pas trouvé',
    noData: 'Pas de données',
  },
  dataTable: {
    noMatchingFilterResults:
      'Aucun élément ne correspond à votre (vos) filtre(s).',
    noData: 'Pas de données',
    csvExportIconHint: 'Exporter les données vers un fichier CSV',
  },
  columnsToggle: {
    successfullySavedChanges: 'Modifications enregistrées avec succès.',
    failedToSaveChanges: `Échec de l'enregistrement des données.`,
    unableToGetSavedColumns: `Impossible d'obtenir les colonnes de table de sauvegarde pour cet utilisateur.`,
  },
  company: {
    failedToLoadInfo: 'Échec du chargement des informations',
    failedToSetPeriod: 'Échec de la définition de la période',
    failedToLoadCompanySettings: `Échec du chargement des paramètres de l'entreprise`,
  },
  filter: {
    notContainedIn: 'Non contenu dans',
    contains: 'Contient',
    doesNotContain: 'Does Not Contain',
    beginsWith: 'Begins With',
    doesNotBeginWith: 'Does Not Begin With',
    endsWith: 'Ends With',
    doesNotEndWith: 'Does Not End With',
    notEqual: 'Inégal',
    lessThanOrEqual: 'Inférieur ou égal',
    greaterThanOrEqual: 'Meilleur que ou égal',
    lessThan: 'Moins que',
    greaterThan: 'Plus grand que',
    equal: 'Égal',
  },
  installationStatus: {
    successful: 'À succès',
    relatedApplicationsTitle: 'Applications liées à cette tâche',
    pendingInstallationsTitle: 'Installations en attente',
    successfulInstallationsTitle: 'Installations réussies',
    informationRelatedToTask: 'Informations relatives à cette tâche',
    successfulAndPendingInstallationTitle:
      'Installations réussies et en attente',
    numberOfSuccessfullyInstalledDevices: 'Périphériques installés avec succès',
    numberOfPendingInstallations: `Périphériques en attente d'installation`,
    lastUpdated: 'Dernière mise à jour',
    overviewTitleText: 'Aperçu',
    allDevicesPendingInstallationOfThisProfileText: `Tous les appareils en attente de l'installation de ce profil`,
    allDevicesThatSuccessfullyInstalledThisProfileText:
      'Tous les appareils qui ont installé avec succès ce profil',
    failedToLoadTaskDevices: 'Échec du chargement des périphériques de tâche',
    failedToLoadTask: 'Échec du chargement de la tâche',
    failedToLoadTasks: 'Échec du chargement des tâches',
    devicesInstalled: 'Périphériques installés',
    devicesFailed: 'Périphériques en panne',
    status: 'Statut',
    taskDate: 'Date de la tâche',
    installed: 'Installé',
    pending: 'En attente',
  },
  alerts: {
    title: 'Alertes',
    createAlert: 'CRÉER UNE ALERTE',
    alertCreated: 'Alerte créée',

    chooseAFleet: 'CHOISISSEZ UNE FLOTTE',
    alertType: `TYPE D'ALERTE`,
    alertCondition: `CONDITION D'ALERTE`,
    alertChannel: `CANAL D'ALERTE`,
    previewAndSave: 'APERÇU ET ENREGISTRER',
    done: 'FINI',

    alertName: `NOM D'ALERTE`,
    alertStatus: 'STATUT',
    linkedFleets: 'FLOTTES LIÉES',
    totalAlerts: 'TOTAL DES ALERTES',
    inactive: 'Inactif',
    active: 'Actif',
    createAnAlert: 'CRÉER UNE ALERTE',
    nameThisAlert: 'Nommez cette alerte',
    alertDashboard: `TABLEAU DE BORD D'ALERTE`,

    battery: 'Batterie',
    outOfContact: 'Hors contact',
    exitGeoFence: 'Quitter GeoFence',
    enterGeoFence: 'Entrez GeoFence',

    numberOfLinkedFleets: '%{numberOfLinkedFleets} Flottes',

    on: 'Au',
    off: 'Désactivé',
    chooseFleet: 'Choisissez',
    createFleet: 'CRÉER UNE FLOTTE',
    back: 'RETOUR',
    next: 'SUIVANT',
    alertTypeHeading: `Type d'alerte`,
    basicAlerts: 'Alertes de base',
    setCondition: 'Définir la condition',
    condition: 'État',
    alertWhenDeviceBatteryIs: `Alerte lorsque la batterie de l'appareil est`,
    alertWhenDeviceNetworkIs: `Alerte lorsque le réseau de l'appareil est`,
    alertWhenDeviceExitsGeofence: `Alerte lorsque l'appareil quitte Geofence`,
    alertWhenDeviceEntersGeofence: `Alerte lorsque l'appareil entre dans Geofence`,
    alertWhenDeviceIsOutOfContact: `Alerte lorsque l'appareil est hors de contact`,
    alertWhenDeviceBluetoothIs: `Alerte lorsque l'appareil Bluetooth est`,
    lessThanOrEqualTo: 'Inférieur ou égal à',
    greaterThanOrEqualTo: 'Plus grand ou égal à',
    setCommunicationChannel: 'Définir le canal de communication',
    singleEmailCommunicationChannel: 'E-mail unique',
    singleEmailCommunicationChannelHint: 'Veuillez saisir un e-mail par ligne.',
    userGroupsCommunicationChannel: `Groupes d'utilisateurs`,
    userGroupsCommunicationChannelHint: `Maintenez "Contrôle" ou "Commande" sur un Mac, pour en sélectionner plus d'un.`,
    communicationChannelEmailLabel: 'E-mail',
    communicationChannelEmailPlaceholder: 'Adresse e-mail',
    communicationChannelGroupLabel: 'Grouper',
    communicationChannelGroupPlaceholder: `Sélectionnez des groupes d'utilisateurs`,
    emailAlertFrequency: 'Fréquence des alertes par e-mail',
    emailAlertFrequencyDescription:
      'Répétez %{alertFrequencyRepeatValue} fois à un intervalle de %{alertFrequencyHours} heures.',
    repeat: 'Répéter',
    timesAtAnIntervalOf: 'fois à un intervalle de',
    hours: 'Les heures',
    minutes: 'Minutes',
    percentage: 'Pourcentage',
    emailAlertFrequencyValuesDescription:
      'Notifier %{alertFrequencyRepeatValue} à un intervalle de %{alertFrequencyHours} heures.',
    alertChannelSettingFootNote: `Les notifications d'alerte peuvent être répétées jusqu'à 10 fois et un intervalle maximum d'un mois.`,
    alertConditionPreviewDescriptionBattery: `Alerte lorsque la batterie de l'appareil est à %{conditionType} %{percentage}%`,
    alertConditionPreviewDescriptionOutOfContact: `Alerte lorsque l'appareil est hors de contact est activé %{conditionType}`,
    alertConditionPreviewDescriptionExitsGeofence: `Alerte lorsque l'appareil quitte Geofence est activé %{conditionType}`,
    alertConditionPreviewDescriptionEntersGeofence: `Alerte lorsque l'appareil entre dans Geofence est activé %{conditionType}`,

    edit: 'Éditer',
    confirm: 'Confirmer',
    alertsDeletionConfirmation:
      'Êtes-vous sûr de vouloir supprimer les alertes sélectionnées?',
    removeAlerts: 'Supprimer les alertes',
    alertsSelected: 'Alertes sélectionnées',

    youAreEditing: 'vous éditez',
    youAreViewing: 'vous regardez',

    alertLoadFailureMessage: `Échec du chargement de l'alerte.`,
    alertUpdateFailureMessage: `Échec du chargement de l'alerte.`,
    alertsDeletionFailureMessage: 'Échec de la suppression des alertes',
    alertsLoadFailureMessage: 'Erreur lors de la récupération des alertes',
    alertCreationFailureMessage: `Échec de la création de l'alerte`,
  },
  api: {
    title: 'API Manager',
    usageTabTitle: 'API Usage History',
    usageQuotaTitle: 'Quota',
    usageDateColumnTitle: 'Date',
    usageConsumedColumnTitle: 'Consumed',
    usageRemainderColumnTitle: 'Remainder',
    apiKeyCopiedSuccessMessageText: 'API key successfully copied to clipboard.',
    currentPlanTitle: 'Current Plan',
    currentPlanDetails: 'Plan Details',
    starterPlanDetails:
      'This plan allows for %{numberOfAPICalls} API calls every month, included with your license fee. Usage above this quota will require additional fees.',
    documentationTitle: 'Documentation',
    documentationHint: 'Open API documentation in a new tab',
    APIKeyTitle: 'API Key',
    uniqueIDsTitle: 'Device Unique IDs',
    uniqueIDsDownloadButtonText: 'Download Device Unique IDs',
  },
  enrollments: {
    editingTitle: 'vous éditez',
    viewingTitle: 'vous regardez',
    fields: {
      status: 'Statut',
      statusActive: 'Actif',
      statusInactive: 'Inactif',
      statusDraft: 'Brouillon',
      createdAt: 'Créé à',
      lastUpdated: 'Dernière mise à jour',
      name: 'Nom',
    },
    enrollmentCreationButtonText: 'Créer une inscription',
    enrollmentsSelected: 'Inscription(s) sélectionnée(s)',
    removeEnrollments: 'Supprimer les inscriptions',
    enrollmentDeletionConfirmationText:
      'Êtes-vous sûr de vouloir supprimer les inscriptions sélectionnées?',
    deletingEnrollments: 'Supprimer des inscriptions',
    enrollmentsList: 'Liste des inscriptions',
    createAnEnrollmentMethod: `Créer une méthode d'inscription`,
    nameThisEnrollmentMethod: `Nommez cette méthode d'inscription...`,
    chooseAFleet: 'Choisissez',
    deviceNaming: `Dénomination de l'appareil`,
    ReviewAndConfirm: 'Examiner et confirmer',
    done: 'Fait',
    loadEnrollmentError: `Erreur lors de la récupération de l'inscription de l'appareil sélectionné`,
    updateEnrollmentError: `Erreur lors de la mise à jour de l'inscription de cet appareil`,
    updateEnrollmentUniqueNameErrorText: `Le nom d'inscription doit être unique`,
    createEnrollmentErrorText: `Erreur de création d'inscription`,
    deleteEnrollmentErrorText: 'Échec de la suppression des inscriptions',
    loadEnrollmentsErrorText: `Erreur lors de la récupération des données d'inscription`,

    QRCodeEnrollmentIdText: `Identifiant d'inscription:`,
    QRCode: 'QR Code',
    selectedFleet: 'Flotte sélectionnée',
    selectedProfilesText: 'Profils sélectionnés',
    deviceNamingSectionTitle: `Dénomination de l'appareil`,
    deviceNamingSectionHint:
      'Configurez une séquence de nommage pour les appareils inscrits ici',
    selectedProfilesName: 'Nom',
    selectedProfilesLastUpdated: 'Dernière mise à jour',

    deviceNamingSequence: 'Séquence:',
    deviceNamingSeparator: 'Séparateur:',
    deviceCurrentCount: ' Nombre actuel:',
    namingExampleHeader: 'Exemple: ',
    startingSerialNumber: 'Numéro de série de départ:',

    // device naming params
    deviceNamingParamNone: 'Rien',
    deviceNamingParamNumberedSequence:
      'Séquence numérotée à incrémentation automatique',
    deviceNamingParamName: 'Texte personnalisé',
    deviceNamingParamIMEI: 'IMEI',
    deviceNamingParamMACAddress: 'Adresse Mac',
    deviceNamingParamManufacturer: 'Fabricant',
    deviceNamingParamModel: 'Modèle',
    deviceNamingParamDeviceName: `Nom de l'appareil personnalisé`,
    deviceNamingParamPhoneNumber: 'Numéro de téléphone',
    deviceNamingParamPlatform: 'Plate-forme',
    deviceNamingParamSerialNumber: 'Numéro de série',

    // enrollment creation
    allVersionsOptionText: 'Toutes les versions',
    prevStepButton: 'RETOUR',
    forwardStepButton: 'SUIVANT',
    exit: 'SORTIR',
    edit: 'ÉDITER',
    confirm: 'CONFIRMER',
    namingSequenceHint:
      'Créez une séquence de nommage pour les appareils inscrits ici.',
    enrollmentCreationSuccessTitle: `Envoyez cette méthode d'inscription maintenant.`,
    enrollmentCreationSuccessSubTitle: `Informations d'inscription`,
    preview: 'Aperçu:',
    notAvailable: 'Indisponible',
    noQRCodeAvailableText: 'Aucun code QR disponible.',
    enrollmentName: `Nom d'inscription`,
    startingFromPrefix: 'Partant de',

    // staging
    stagingTabTitle: 'Mise en scène',
    stagingTabHint: `Pour générer le code QR de mise en scène, veuillez choisir un profil, puis un réseau WiFi auquel vous connecter. Veuillez noter que le champ WiFi ne s'affiche qu'après le choix d'un profil.`,
    stagingProfileFieldTitle: 'Profil',
    stagingLocaleFieldTitle: 'Lieu',
    stagingTimezoneFieldTitle: 'Fuseau horaire',
    stagingWiFiNetworkFieldTitle: 'Réseau Wi-Fi',
    stagingSkipDeviceEncryptionFieldTitle: `Ignorer le chiffrement de l'appareil`,
    stagingLeaveAllSystemAppsEnabledFieldTitle:
      'Laisser toutes les applications système activées',
    stagingUseMobileDataFieldTitle: 'Utiliser les données mobiles',
    stagingUseManualWifiSettingsFieldTitle:
      'Utiliser les paramètres Wi-Fi manuels',
    stagingManualWifiSSIDFieldTitle: 'Wi-Fi SSID',
    stagingManualWifiPasswordFieldTitle: 'Mot de passe WiFi',
    stagingManualWiFiTypeFieldTitle: 'Type de Wi-Fi',
    stagingManualHiddenNetworkFieldTitle: 'Réseau caché',
    stagingQRCodeGenerationButtonText: 'Générer un code QR',
    noStagingConfigurationsMessage: `Il n'y a actuellement aucune configuration de transfert associée à cette inscription.`,
  },
  routes: {
    myFleets: 'Mes flottes',
    myFleetsV2: 'Mes flottes',
    enrollments: 'Inscriptions',
    allDevices: 'Tous les dispositifs',
    myApplications: 'mes applications',
    upload: 'Ajouter',
    myFiles: 'Mes dossiers',
    myCertificates: 'Mes certificats',
    myHTMLKioskFiles: 'Mes fichiers de kiosque HTML',
    allProfiles: 'Tous les profils V1',
    allProfilesV2: 'Tous les profils',
    tasks: `État de l'installation`,
    accountSettings: 'Compte',
    commonSettings: 'Paramètres communs',
    companyInfo: `Information d'entreprise`,
    SSO: 'SSO',
    preferences: 'Preferences',
    dashboard: 'Tableau de bord',
    mapView: 'Vue de la carte',
    usersAll: 'Faire en sorte',
    usersLogs: `Journaux d'activité`,
    alerts: 'Alertes',
    apiOverviewTitle: 'Overview',
    apiUsageHistoryTitle: 'Usage History',
  },
  statistics: {
    numberNewDevices: `%{number} Nouveaux appareils`,
    numberDevices: `%{nombre} appareils`,
    numberTasks: `%{nombre} tâches`,
    enrolledDevices: 'Appareils enregistrés',
    inLowBattery: 'Batterie faible',
    online: 'En ligne',
    recentlyActive: 'Actif récemment',
    quickDetails: 'Détails rapides',
    onlineDevicesPast: `Appareils récemment actifs dans le passé `,
    lastDay: '24 heures',
    week: 'La semaine',
    month: 'Mois',
    last: 'Dernier',
    installing: 'Installation',
    enrolling: 'Inscription',
    lowBattery: 'Batterie faible',
    failedEnrollments: `Échec de l'inscription`,
    nonCompliantDevices: 'Appareils non conformes',
    androidDevices: 'Appareils Android',
    linuxDevices: 'Périphériques Linux',
    lastConnectDates: `Dates de la dernière connexion (tout le temps)`,
    manufacturer: 'Fabricant',
    connections: 'Connexions',
    devices: 'Dispositifs',
    deviceFamilyOSVersion: `Version du système d'exploitation de la famille d'appareils`,
    failedToLoadStatistics: 'Échec du chargement des statistiques',
  },
  device: {
    title: 'Appareil',
    deviceInfo: 'Info appareil',
    deviceInfoHint: 'Informations relatives à cet appareil',
    actions: 'Actions',
    overview: 'Aperçu',
    applications: 'Applications',
    profilesAssigned: 'Profils attribués',
    activityLogs: 'Journaux',
    location: 'Emplacement',
    updateHistory: 'Mettre à jour',
    updateRequestConfirmationText: 'Update request sent.',
    noHistoryLogEntries: `Il n'y a actuellement aucune entrée dans le journal de localisation.`,
    noHistoryLogEntriesForRange: `Il n'y a pas d'entrées de journal de localisation pour la plage de dates définie.`,
    locationHistory: 'Historique des positions',
    currentLocation: 'Localisation actuelle',
    locationLogsStartDate: 'Date de début',
    locationLogsEndDate: 'Date de fin',
    csvExportIconHint: 'Exporter les journaux vers un fichier CSV',
    locationHistoryMapViewToggleText: 'Vue de la carte',
    export: 'Exportation',
    mapFullScreenControlLabelTip: 'Basculer en plein écran',
    remoteControl: 'Télécommande',
    logsMenuItem: 'Journaux',
    updatingDeviceProperty: `Mise à jour de la propriété de l'appareil:`,
    sendingRemoteCommand: 'Envoi de commande à distance:',
    applicationsSelected: 'Candidatures sélectionnées',
    uninstallApps: 'Désinstaller des applications',

    security: 'Sécurité',
    compliant: 'Conforme',
    notCompliant: 'Non conforme',

    encrypted: 'Crypté',
    OSSecure: `Système d'exploitation sécurisé`,
    passCodeEnabled: `Code d'accès activé`,
    androidSecurityPatchLevel: 'Niveau de correctif de sécurité Android',
    securityCompliance: 'Conformité à la sécurité',
    latestAgent: 'Dernier agent',

    isNowOnline: 'est maintenant en ligne',
    lastKnownLocation: 'Dernier emplacement connu',
    lastOnline: 'Dernière connexion',
    fleet: 'Flotte',
    fleetPath: 'Chemin de la flotte',
    active: 'Actif',
    inactive: 'Inactif',
    enrolled: 'Inscrit',
    unenrolled: 'Désinscrit',
    offline: 'Hors ligne',
    online: 'En ligne',
    deviceConfigurationLocked: 'Device Configuration Locked',
    lastSeenDescription: 'Vu pour la dernière fois à %{lastSeenFormatted}',
    timeWithAgoAffix: 'il y a %{time}',

    status: 'Statut',
    manufacturer: 'Fabricant',
    model: 'Modèle',
    activeConnection: 'Connexion',
    networkBssid: 'BSSID',
    networkSsid: 'SSID',
    networkDNS: 'DNS',
    networkRssi: 'RSSI (Force du réseau)',
    roaming: 'Roaming',
    customNotes: 'Notes personnalisées',
    developerMode: 'Mode développeur',
    ipAddress: 'Adresse IP',
    wifiStatus: 'État du Wi-Fi',
    nfcStatus: 'État NFC',
    batteryLevel: 'Niveau de batterie',
    powerStatus: `État de l'alimentation`,
    discharging: 'Décharge',
    charging: 'Mise en charge',
    pauseMode: 'Mode Pause',
    phoneNumber: 'Numéro de téléphone',
    platform: 'Plate-forme',
    ethernetMacAddress: 'Adresse MAC Ethernet',
    macAddress: 'Adresse Mac',
    checkinPeriod: `Période d'enregistrement`,
    lastUpdated: 'Dernière mise à jour',
    updatePeriod: 'Période de mise à jour',
    lastFleetCheckin: 'Dernier enregistrement de flotte',
    automaticAgentUpdatesEnabled: 'Automatic Agent Updates Enabled',

    profilesAttachedToThisDevice: 'Profils associés à cet appareil',
    noProfilesAttachedToDevice: `Il n'y a actuellement aucun profil attaché à cet appareil`,
    profileAssignedName: 'Nom',
    profileAssignedStatus: 'Statut',

    connectingToTheServer: 'Connexion au serveur...',
    connectingToTheServerWaitingForTheDevice: `Connecté au serveur, en attente de l'appareil`,
    disconnectedFromTheServerTryingReconnect:
      'Déconnecté du serveur, essayez de vous reconnecter',
    deviceConnected: 'Appareil connecté',
    deviceDisconnectedWaitingForTheDevice: `Appareil déconnecté, en attente de l'appareil`,
    remoteControlCurrentFocusTextTitle: 'Texte de mise au point actuel',
    mediaProjection: 'Projection des médias',
    screenCaptureType: `Type de capture d'écran:`,
    chooseScreenCaptureType: `Choisissez le type de capture d'écran`,
    exitFullScreenMode: 'Quitter le mode plein écran',
    fullScreenMode: 'Mode plein écran',
    swipeTop: 'Glisser vers le haut',
    volumeUp: 'Monter le son',
    volumeDown: 'Baisser le volume',
    power: 'Pouvoir',
    enterKioskMode: 'Entrer en mode kiosque',
    exitKioskMode: 'Quitter le mode kiosque',
    secureInput: 'Saisie sécurisée',
    slowNetwork: 'Réseau lent',
    imageQuality: `Qualité d'image:`,
    imageSize: 'Image Size:',

    logsTitle: 'Journaux',
    logsTitleHint: 'Journaux stockés pour cet appareil',
    dateTime: 'Date Heure',
    type: 'Taper',
    details: 'Des détails',
    generatedBy: 'Généré par',

    addAndUpdateNoteTitle: 'Ajouter/mettre à jour une note personnalisée',
    customNoteTitle: 'Remarque personnalisée',
    deviceLoadFailureMessage: `Échec du chargement des informations sur l'appareil`,
    logs: {
      dateTime: 'Date/Heure',
      message: 'Message',
      coordinates: 'Coordonnés',
      longitude: 'Longitude',
      latitude: 'Latitude',
    },

    fields: {
      fleet: 'Flotte',
      serialNumber: 'Numéro de série.',
      deviceType: `Type d'appareil`,
      osVersion: `Version du système d'exploitation`,
      imei: 'IMEI',
      agentVersion: `Version de l'agent`,
      availableStorage: 'Stockage disponible',
      availableMemory: 'Mémoire disponible',
      totalMemory: 'Mémoire totale',
      totalStorage: 'Stockage total',
      haveUpdates: 'Dernier agent',
      latestAgent: 'Dernier agent',
      compliance: 'Conformité des agents',
    },
  },
  profiles: {
    title: 'Profils',
    published: 'Publié',
    unpublished: 'Inédit',
    draft: 'Brouillon',
    draftAvailable: 'Brouillon disponible',
    version: 'Version',
    createProfile: 'CRÉER UN PROFIL',
    newProfileDefaultName: 'Nouveau profile',
    unableToCreateProfileErrorText: 'Impossible de créer un profil',
    profileNameFieldPlaceholder: 'Entrez un nom de profil',
    moreOptionsTitleHeader: `Plus d'options`,
    assignmentSettingsTitle: `Paramètres d'association`,
    deviceSideTimeLabel: 'Heure côté appareil',
    timezone: 'Fuseau horaire',
    schedules: 'Des horaires',
    assignmentStartDateLabel: 'Association à partir du',
    assignmentEndDateLabel: `Mettre fin à l'association le`,

    profileViewingHint: 'vous regardez',
    profileAssociationHint: 'vous vous associez',
    profileEditingHint: 'vous éditez',
    profileCreatingHint: 'vous créez',
    datetimeOffsetPlaceholder: 'Sélectionnez la valeur',
    assignmentButtonText: 'ASSOCIEZ-VOUS MAINTENANT',
    saveButtonText: 'Sauver',
    createButtonText: 'CRÉER',

    unassignProfiles:
      'Dissocier les profils sélectionnés de toutes les flottes associées',
    unpublishAndClearFleetsSuccess:
      'Dépublier avec succès le(s) profil(s) sélectionné(s)',
    unpublishAndClearFleetsFailure: `Échec de l'annulation de la publication du ou des profils sélectionnés`,
    unpublishAndClearListedFleetsSuccess:
      'Successfully unpublished the following profiles:',
    unpublishAndClearListedFleetsFailure:
      'Failed to unpublish the following profiles:',
    cloneProfiles: 'Cloner des profils',
    removeProfiles: 'Supprimer des profils',
    profileActions: 'Actions',
    assignFleetsAndPublish: 'Associer des flottes et publier',
    associateFleetsTitle: 'Flottes associées',
    unPublishProfile: 'Dépublier',
    assign: 'Associer',
    migrateToVersionTwo: 'Migrer vers la version 2',
    migrationSuccessText:
      'Migration réussie du ou des profils sélectionnés vers Profiles V2',
    migrationFailureText:
      'Échec de la migration du ou des profils sélectionnés vers Profiles V2',
    profileAssignmentToFleetsTitle: 'Associer des profils aux flottes',
    unpublishProfilesTitle: 'Annuler la publication des profils',
    unpublishProfilesConfirmationText:
      'Êtes-vous sûr de vouloir dépublier le(s) profil(s) sélectionné(s)?',
    unpublishProfilesConfirmationConsequence:
      'Cette action supprimera les profils sélectionnés du ou des appareils.',
    unassignProfilesConfirmationText:
      'Êtes-vous sûr de vouloir dissocier le(s) profil(s) sélectionné(s)?',
    cloneProfilesConfirmationText:
      'Êtes-vous sûr de vouloir cloner le(s) profil(s) sélectionné(s)?',
    removeProfilesConfirmationText:
      'Êtes-vous sûr de vouloir supprimer le(s) profil(s) sélectionné(s)?',
    migrateProfilesConfirmationText:
      'Êtes-vous sûr de vouloir migrer le(s) profil(s) sélectionné(s) vers la nouvelle version 2 ? Cette action est irréversible!',
    toolsPanelTitle: 'Profils sélectionnés',
    loadProfilesErrorText: 'Erreur lors du chargement des profils',
    profileRemovalNotAllowedForPublishedProfiles:
      'Un ou plusieurs des profils sélectionnés sont actuellement publiés et ne peuvent pas être supprimés. Veuillez annuler la publication de ces profils et réessayer',
    unavailableFeatureMessage:
      'Fonctionnalité disponible uniquement dans la nouvelle version de Profiles (v2). Veuillez envisager de migrer ou de créer un nouveau profil v2.',
    cannotDeleteProfilesAssignedToFleets:
      'Impossible de supprimer les profils auxquels des flottes sont actuellement affectées.',
    cannotDeletePublishedProfile: 'Impossible de supprimer les profils publiés',
    profileTreeHasMoreThanOnePublishedProfile: `L'arborescence des profils a plusieurs profils publiés`,
    profileTreeHasMoreThanOneLatestVersion: `L'arborescence des profils a plus d'un profil de dernière version`,
    profileTreeCannotBeDeletedBecauseTheProfileIsPublished:
      'Les arborescences de profils avec des profils publiés ne peuvent pas être supprimées.',
    profileHasMoreThanOneNewestVersion: `L'arbre de profil a plus d'une version de profil plus récente`,
    cannotAssignFleetsToUnpublishedProfiles: `Impossible d'associer des flottes à des profils (projets) non publiés. Veuillez publier le profil et réessayer.`,
    profilesSuccessfullyDeleted: 'Profil(s) supprimé(s) avec succès',
    profilesNotSuccessfullyDeleted: 'Échec de la suppression du ou des profils',
    profilesSuccessfullyCloned: 'Profil(s) cloné(s) avec succès',
    profilesNotSuccessfullyCloned: 'Échec du clonage du ou des profils',
    failedToLoadProfiles: 'Échec du chargement des profils',
    failedToPublishProfile: 'Échec de la publication du profil',
    profileSuccessfullyPublished: 'Profil publié avec succès',
    fleetsToProfileAssignmentSuccess:
      'Flotte attribuée avec succès à la version actuellement publiée',
    fleetsToProfileAssignmentFailure: `Échec de l'association des flottes au profil.`,
    failedToUnpublishProfile: `Échec de l'annulation de la publication du profil`,
    profileSuccessfullyUnpublished: 'Profil non publié avec succès',
    unpublishProfileConfirmationText:
      'Êtes-vous sûr de vouloir annuler la publication de ce profil?',
    profileCreationDefaultErrorText: 'Échec de la création du profil.',
    profileCreationAndPublishingDefaultErrorText:
      'Échec de la création et de la publication du profil.',
    profileCreationSuccessText: 'Profil créé avec succès.',
    profileCreationAndPublishingSuccessText:
      'Profil créé et publié avec succès.',
    profileNewVersionCreationDefaultErrorText: `Échec de la création d'une nouvelle version du profil.`,
    profileNewVersionCreationAndPublishingDefaultErrorText: `Échec de la création et de la publication d'une nouvelle version du profil.`,
    saveAndPublishProfileDefaultErrorText: `Échec de l'enregistrement et de la publication du profil.`,
    profileNewVersionCreationSuccessText:
      'Une nouvelle version du profil a été créée avec succès.',
    profileNewVersionCreationAndPublishingSuccessText:
      'Une nouvelle version du profil a été créée et publiée avec succès.',
    saveAndPublishProfileSuccessText:
      'Profil enregistré et publié avec succès.',
    profileSaveAsDraftSuccessText:
      'Profil enregistré avec succès en tant que brouillon.',
    profileSaveAsDraftDefaultErrorText: `Échec de l'enregistrement du profil en tant que brouillon.`,
    saveAsDraftButtonText: 'ENREGISTRER COMME BROUILLON',
    createNewVersionButtonText: 'CRÉER UNE NOUVELLE VERSION',
    saveAndPublishButtonText: 'Enregistrer et publier',
    unableToPublishProfileWithoutAssignedFleets:
      'Impossible de publier le profil car aucune flotte ne lui est associée. Veuillez associer une ou plusieurs flottes et réessayer.',
    profileVersionPublicationConfirmation:
      'Êtes-vous sûr de vouloir publier la version %{version} de ce profil?',

    failedToLoadProfileErrorText: 'Échec du chargement du profil',
    failedToCreateProfileErrorText: 'Échec de la création du profil',
    failedToUpdateProfileErrorText: 'Échec de la mise à jour du profil',
    failedToLoadProfileApplicationsErrorText:
      'Erreur lors de la récupération des applications de profil',
    failedToLoadProfileById: 'Échec du chargement du profil sélectionné',
    failedToLoadProfileApps: 'Échec du chargement des applications de profil',
    failedToAssignProfilesToFleets: `Impossible d'associer les profils sélectionnés aux flottes sélectionnées`,
    failedToDeleteProfiles: 'Échec de la suppression des profils',

    profileDetailsTitle: 'Détails du profil',
    profileDetailsHint: 'Informations relatives à ce profil',
    addConfigurationLabel: 'Ajouter une configuration',
    addLabel: 'Ajouter',
    assignedFleetsLabel: 'Flottes affectées',

    fields: {
      name: 'Nom de profil',
      lastUpdated: 'Dernière mise à jour',
      updatedBy: 'Updated By',
      createdAt: 'Créé à',
      newestVersion: 'Version la plus récente',
      version: 'Version',
      published: 'Publié',
      policies: 'Stratégies',
      packageId: 'Identifiant du package',
      status: 'Statut',
      size: 'Taille',
    },

    networkTitle: 'Réseau',
    applicationsTitle: 'Applications',

    featureControl: {
      title: 'Contrôle des fonctionnalités',
      statusHeader: 'Contrôle des fonctionnalités activé',
      description: 'Configurer le contrôle des fonctionnalités.',
      noFeatureControlRules: `Il n'y a actuellement aucune règle de contrôle des fonctionnalités dans ce profil.`,
      fields: {
        disableBluetooth: 'Désactiver Bluetooth',
        allowInstallationsFromUnknownSources: `Autoriser l'installation à partir de sources inconnues`,
        disableCamera: 'Désactiver la caméra',
        disableUsb: 'Désactiver USB',
        disableGooglePlay: 'Désactiver Google Play',
        disableSafeBoot: 'Désactiver le démarrage sécurisé',
        disableWifiHotspot: 'Interdire ou désactiver le partage de connexion',
        disableFactoryReset: `Désactiver la réinitialisation d'usine`,
        disableHardReset:
          'Bloquer la mise hors tension et le redémarrage (Knox uniquement)',
        disableTurnOffWifi: 'Bloquer la désactivation du WiFi',
        disableAirplaneMode: 'Bloquer le mode avion',
      },
    },
    kiosk: {
      title: 'Kiosque',
      htmlKioskTitle: 'Kiosque HTML',
      undo: 'ANNULER',
      save: 'SAUVER',
      htmlKiosk: 'Kiosque HTML',
      htmlKioskWhiteList: 'LISTE BLANCHE KIOSQUE HTML',
      passwordDoesNotMatch: `Le mot de passe ne correspond pas`,
      backdoorPasswordDescription:
        'Veuillez définir un mot de passe de porte dérobée.',
      kioskBuilder: 'Constructeur de kiosque',
      kioskBuilderHint1: `Faites glisser et déposez les applications de la liste vers l'interface de l'appareil.`,
      kioskBuilderHint2: `Faites glisser l'application hors de l'interface de l'appareil pour supprimer.`,

      HTMLKioskBuilder: 'Générateur de kiosque HTML',
      HTMLKioskBuilderHint: `Faites glisser et déposez un fichier HTML depuis la section « MES FICHIERS HTML » sur la droite.`,
      myHtmlFiles: 'MES FICHIERS HTML',
      enterHtmlKioskBuilder: 'ENTRER LE CONSTRUCTEUR DE KIOSQUE HTML',
      enterKioskBuilder: 'ENTRER CONSTRUCTEUR DE KIOSQUE',
      kioskMode: 'Mode kiosque',
      statusTitle: 'Mode kiosque activé',
      kioskModeEmptyMessage: `Il n'y a actuellement aucune application installée automatiquement.`,
      back: 'Retour',
      security: 'Sécurité',
      confirmPassword: 'Confirmez le mot de passe',
      password: 'Mot de passe',
      addPackageByID: 'Ajouter un package par ID',
      statusBar: `Barre d'état`,
      whitelistServices: 'Services de liste blanche',
      autoLaunchApp: `Lancement automatique de l'application`,
      myApplications: 'MES APPLICATIONS',
      useHTMLKioskMode: 'Utiliser le mode kiosque HTML',
      hideStatusBarSetting: 'Hide Status Bar',
      backButtonSetting: 'Bouton Retour',
      homeButtonSetting: `Bouton d'accueil`,
      menuButtonSetting: 'Bouton de menu',
      singleAppModeSetting: `Mode d'application unique`,
      showExitButtonSetting: 'Afficher le bouton de sortie',
      onlyKioskNotificationsSetting: 'Notifications autorisées uniquement',
      hideNavigationBarSetting:
        'Masquer la barre de navigation (Samsung uniquement)',
      backKeyText: 'Retour',
      homeKeyText: 'Accueil',
      menuKeyText: 'Menu',
    },
    wallpaperSettings: {
      wallpaperSettings: 'Paramètres du papier peint',
      policyStatusTitle: `Fond d'écran activé`,
      homeScreenWallpaperLandscape: `Fond d'écran d'accueil - Paysage`,
      homeScreenWallpaperPortrait: `Fond d'écran d'accueil - Portrait`,
      lockScreenWallpaperLandscape: `Fond d'écran de verrouillage - Paysage`,
      lockScreenWallpaperPortrait: `Fond d'écran de verrouillage - Portrait`,
      myImages: 'Mes images',
      useHomeScreenWallpaperAsLockScreenWallpaper: `Utiliser le fond d'écran de l'écran d'accueil comme fond d'écran de verrouillage`,
      wallpaperSettingsAvailability:
        'Uniquement disponible sur Android 7.0 (API niveau 24) et supérieur.',
    },
    wifi: {
      title: 'WiFi',
      wifiPolicyEnabledTitle: 'Politique Wi-Fi activée',
      wifiNetworkTitle: 'Réseau Wi-Fi',
      policyHint: `Cliquez sur l'icône + pour ajouter un réseau WiFi`,
      descriptionHint: 'Configurer les paramètres Wi-Fi pour les appareils.',
      removeConfigurationActionButtonText: 'Supprimer la configuration',
      proxyBypassTitle: 'Contourner le proxy pour',
      proxyBypassHint: `Veuillez saisir un nom d'hôte/site Web par ligne.`,
      proxyAutoConfigURLTitle: 'URL de configuration automatique du proxy',
      proxyPortTitle: 'Port proxy',
      proxyHostnameTitle: `Nom d'hôte du proxy`,
      proxyTypeTitle: 'Proxy',
      frequencyHint: 'Uniquement disponible sur Android 7 et inférieur.',
      proxyConfigTypeNone: 'Rien',
      proxyConfigTypeManual: 'Manuel',
      proxyConfigTypeAutoConfig: 'Configuration automatique du proxy',
      SSID: 'SSID',
      SSIDPlaceholder: 'Entrez le nom SSID',
      SSIDPassword: 'Mot de passe SSID',
      SSIDPasswordPlaceholder: 'Entrer un mot de passe',
      WiFiFrequency: 'Fréquence Wi-Fi',
      hiddenNetwork: 'Réseau caché',
      securityType: 'Type de sécurité',
      acceptedEAPTypes: 'Types de PAE acceptés',
      identity: 'Identité',
      password: 'Mot de passe',
      passwordPlaceholder: 'Entrer un mot de passe',
      clientCertificate: 'Certificat client',
      CACertificate: `Certificat d'AC`,
      CACertificateDomain: 'Domaine de certificat CA',
      domainSuffixMatch: 'Correspondance de suffixe de domaine',
      alternateSubjectMatch: 'Correspondance de sujet alternatif',
      phase2AuthenticationMethod: `Méthode d'authentification de phase 2`,
      anonymousIdentity: 'Identité anonyme',
      publicLandMobileNetwork: 'Réseau mobile terrestre public',
      realm: 'Royaume',
      noWiFiNetworksInProfileText:
        'Il y a actuellement des réseaux WIFI dans ce profil.',
    },
    webFilter: {
      webFilter: 'Filtre Web',
      policyStatusTitle: 'Filtre Web activé',
      noWebFilterPolicies: `Aucune politique de filtrage Web n'a été créée.`,
      hint: 'Configurer une liste blanche ou une liste noire de filtres Web.',
      webFilterType: 'Type de filtre Web',
      urls: 'URLS',
      urlHint: `Saisissez une URL de site Web par ligne. Veuillez ajouter au début de chaque site Web le protocole correspondant, par ex. http://`,
      webFilterTypeWhitelist: 'Liste blanche',
      webFilterTypeBlacklist: 'Liste noire',
    },
    apn: {
      emptyPolicyListMessage:
        'Il y a actuellement des paramètres APN dans ce profil.',
      policyStatusTitle: 'Stratégie APN activée',
      apnSettingsAvailability:
        'Uniquement disponible sur Android 9.0 (API niveau 28) et supérieur.',
      policyTitle: 'APN',
      apnNameLabel: `Nom du point d'accès`,
      apnDisplayNameLabel: `Nom d'affichage du point d'accès`,
      mobileCountryCodeLabel: 'Indicatif de pays mobile (MCC)',
      mobileNetworkCodeLabel: 'Indicatif de pays mobile',
      accessPointTypeMMS: 'MMS',
      accessPointTypeInternet: 'Internet',
      accessPointTypeMMSAndInternet: 'MMS + Internet',
      accessPointTypeAny: 'Quelconque',
      accessPointTypeLabel: `Type de point d'accès`,
      mvnoTypeLabel: 'Type de MVNO',
      mvnoValueLabel: 'Valeur MVNO',
      MVNOTypeNone: 'Rien',
      MVNOTypeSPN: 'SPN',
      MVNOTypeIMSI: 'IMSI',
      MVNOTypeGID: 'GID',
      defaultNetworkLabel: 'Réseau par défaut',
      authenticationTypeLabel: `Type d'identification`,

      authenticationTypeNone: 'Rien',
      authenticationTypePAP: 'PAP',
      authenticationTypeCHAP: 'CHAP',
      authenticationTypePAPCHAP: 'PAP/CHAP',

      accessPointConnectionUsernameLabel: `Nom d'utilisateur de connexion au point d'accès`,
      accessPointConnectionPasswordLabel: `Mot de passe de connexion au point d'accès`,
      accessPointConnectionHostAddressLabel: `Adresse hôte de connexion au point d'accès`,
      accessPointConnectionHostPortLabel: `Port hôte de connexion au point d'accès`,

      proxyHostAddressLabel: `Adresse de l'hôte proxy`,
      proxyHostPortLabel: 'Port hôte proxy',
      mmsProxyHostAddressLabel: `Adresse de l'hôte proxy MMS`,
      mmsHostAddressLabel: `Adresse de l'hôte MMS`,
      mmsHostPortLabel: 'Port hôte MMS',
    },
    fileSync: {
      policyStatusTitle: 'Synchronisation de fichiers activée',
      policyTitle: 'Synchronisation de fichiers',
      directionOptionSyncFilesFromServerToDevice: `Synchroniser le(s) fichier(s) du serveur vers l'appareil`,
      hint: `Cliquez sur l'icône + pour ajouter une configuration de synchronisation de fichiers`,
      fileSyncConfigurationTitle:
        'Configuration de synchronisation de fichiers',
      removeConfigurationText: 'Supprimer la configuration',
      directionFieldTitle: 'Direction',
      devicePathLabelText: `Nom du fichier/dossier de l'appareil`,
      devicePathPlaceholderText: 'Entrez un chemin de dossier',
      fileSynchronizationOptionsTitle: 'Options de synchronisation de fichiers',
      syncSubFoldersFieldLabel: 'Synchroniser les sous-dossiers',
      filesTitle: 'Des dossiers',
      noFileSyncRules: `Il n'y a actuellement aucune règle de synchronisation de fichiers dans ce profil.`,
      noFilesAssigned: 'Aucun fichier dans ce profil.',
      filesAssignmentHint: 'Please associate files from the list to the right.',
    },
    geofencing: {
      policyTitle: 'Géolocalisation',
      policyStatusTitle: 'Géolocalisation activée',
      clearGeometryText: 'Géométrie claire',
      searchFieldPlaceholderText: 'Rechercher ...',
      geofencingRuleTitle: 'Règle de géolocalisation',
      removeRuleActionButtonTitle: 'Supprimer la règle',
      notifyWhenDevicesLeaveTheFence:
        'Avertir lorsque les appareils quittent la zone de clôture',
      moveDeviceToFleetIfItLeavesGeofence: `Déplacer l'appareil vers la flotte si l'appareil quitte la barrière géographique`,
      hint: `Cliquez sur l'icône + pour ajouter une règle de géorepérage`,
      changeFleetButtonText: 'Changer de flotte',
      removeFleetButtonText: 'Supprimer la flotte',
      selectAFleetButtonText: 'Sélectionnez une flotte',
      fleetNotDefinedText: `La flotte n'est pas définie`,
      policyHint:
        'Dessinez des polygones de zone de clôture pour configurer des règles de géolocalisation',
      textButtonHint: 'Ajouter une règle',
      noRulesInProfile: `Il n'y a actuellement aucune règle de géofencing dans ce profil.`,
    },
    password: {
      policyTitle: 'Mot de passe',
      policyHint: 'Configurer les exigences de mot de passe.',
      policyStatusTitle: 'Mot de passe activé',
      passwordQualityOptionPIN: 'PIN',
      passwordQualityOptionPattern: 'Schéma',
      passwordQualityOptionAlphabetic: 'Alphabétique',
      passwordQualityOptionAlphanumeric: 'Alphanumérique',

      passwordQualityLabel: 'Qualité du mot de passe',
      minimumLengthLabel: 'Longueur du mot de passe',
      complexCharactersRequiredLabel:
        'Nombre minimum de caractères complexes requis',
    },
    settings: {
      policyTitle: 'Réglages',
      policyStatusTitle: 'Paramètres activés',
      deviceLanguageTitle: `Langue de l'appareil`,
      syncNTPLabel: 'Temps de synchronisation avec le serveur NTP',
      setDeviceTimezone: `Définir le fuseau horaire de l'appareil`,
      timezone: 'Fuseau horaire',
      useDaylightSavingsTime: `Utiliser l'heure d'été`,
      syncDeviceOverWiFi: 'Sync to device over WiFi only',
      downloadFilesOverWifi: 'Download files over Wi-Fi only',
      policyHint: 'Configurer les paramètres de synchronisation.',
      deviceVolumeTitle: `Volume de l'appareil`,
      deviceBrightnessTitle: `Luminosité de l'appareil`,
      disableAndroidBeamTitle: 'Désactiver Android Beam',
      autoRotateScreenTitle: `Rotation automatique de l'écran`,
      displaySectionTitle: 'Affichage',
      timeSectionTitle: 'Temps',
      languageSectionTitle: 'Langue',
      synchronizationSectionTitle: 'Synchronisation',
      systemSectionTitle: 'Système',
    },
    certificates: {
      policyTitle: 'Certificats',
      policyStatusTitle: 'Gestion des certificats activée',
      certificatesTitle: 'Certificats de profil',
      certificateNameTitle: 'Nom',
      lastUpdated: 'Dernière mise à jour',
      noCertificatesAttachedToProfile: `Il n'y a pas de certificats attachés à ce profil.`,
      howToAddCertificateRecommendationText:
        'Veuillez associer les certificats de la liste à votre droite',
    },
    apps: {
      policyTitle: 'Gestion des applications',
      policyStatusTitle: 'Gestion des applications activée',
      policyTitleHint: `L'application attachée à ce profil installera ces applications automatiquement.`,
      profileAppsTitle: 'Applications de profil',
      noAppsAttachedToProfile: `Il n'y a actuellement aucune candidature attachée à ce profil.`,
      howToAddAppsRecommendationText:
        'Veuillez associer les candidatures de la liste à votre droite.',
      myApplicationsTitle: 'mes applications',
      autoInstalledAppsTitle: 'Applications installées automatiquement',
      noAutoInstalledAppsText: `Il n'y a actuellement aucune application installée automatiquement.`,
      networkRestrictionsSectionTitle: 'Restrictions du réseau',
      installOverWiFiTitle: 'Installer via Wifi',
      installOverCellularTitle: 'Installer sur cellulaire',
      installOverCellularConditionTitle:
        'Installer sur la condition cellulaire',
      installOverLANTitle: 'Installer sur le LAN',
      noneInstallOverCellularConditionTitle: 'Rien',
      cellularInstallOverCellularConditionTitle: 'Cellulaire',
      cellularWhileRoamingInstallOverCellularConditionTitle:
        'Cellulaire en itinérance',
    },
    blacklist: {
      policyTitle: 'Gestion de la liste noire des applications',
      policyStatusTitle: 'Gestion de la liste noire des applications activée',
      blacklistTitle: 'Liste noire',
      blacklistedAppsTitle: 'Blacklisted Package IDs',
      noBlacklistedAppsMessage: `Il n'y a actuellement aucune application sur liste noire.`,
      blacklistUpdateHint:
        'Veuillez associer des applications de la liste sur votre droite.',
      blacklistDescription:
        'Les candidatures attachées à ce profil seront blacklistées.',
      addPackageByID: 'Ajouter un package par ID',
    },
    security: {
      policyTitle: 'Sécurité',
      policyStatusTitle: 'Gestion de la sécurité activée',
      policyHint: 'Configurer les paramètres de sécurité.',
      secureOSTitle: `Système d'exploitation sécurisé`,
    },
    location: {
      policyTitle: 'Emplacement',
      policyStatusTitle: 'Paramètres de localisation activés',
      locationPolicyHint:
        'Configurer les paramètres des services de localisation.',
      GPSAlwaysOnSetting: 'GPS toujours activé',
    },
    locationServices: {
      policyTitle: 'Services de location',
    },
    authentication: {
      policyTitle: 'Authentification',
      policyTitleHint: `Configurer le mot de passe pour l'inscription de nouveaux appareils.`,
      passwordFieldLabel: 'Mot de passe',
      confirmPasswordFieldLabel: 'Confirmez le mot de passe',
    },
  },
  applications: {
    title: 'Applications',
    myApplicationsTitle: 'mes applications',
    applicationInfoTabHeading: `Informations sur l'application`,
    allVersionsListTabHeading: 'Liste de toutes les versions',
    allVersionsListTabHint: 'Toutes les versions de cette application',
    settingsTabHeading: 'Réglages',
    assignProfilesHeading: 'Profils',
    assignProfilesHint: 'Sélectionnez les profils et appuyez sur Associer',
    assignProfilesButtonText: 'Associez-vous maintenant',
    assignProfilesFailureDefaultMessage: `Impossible d'associer l'application aux profils`,
    assignProfilesSuccessDefaultMessage:
      'Application associée aux profils sélectionnés',

    applicationCodeNameHint: 'Commencez à taper pour définir',

    applicationVersionsTitle: 'Applications',
    appsRelatedToTaskTitle: 'Applications liées à cette tâche',
    editApplicationDetails: `Modifier les détails de l'application`,

    applicationDataUpdateError: `Erreur lors de la mise à jour des données de l'application`,
    applicationDataFetchError: `Erreur lors de la récupération des données de l'application`,
    applicationAssignmentToProfilesErrorMessage:
      'Error on application association with profiles',

    fields: {
      applicationName: `Nom de l'application`,
      applicationFileName: 'Nom du fichier de candidature',
      label: 'Étiqueter',
      latestVersion: 'Dernière version',
      latestVersionCode: 'Code de la dernière version',
      versionCode: 'Code de version',
      version: 'Version',
      packageId: 'Identifiant du package',
      status: 'Statut',
      size: 'Taille',
      lastUpdated: 'Dernière mise à jour',
      uploadedBy: 'Uploaded By',
      uploadedOn: 'Uploaded On',
      installOnly: 'Installer uniquement',
      managed: 'Géré',
      installedOnThisDevice: 'Installé sur cet appareil',
    },
    applicationsDeletionTitle: `Suppression d'applications`,
    applicationsDeletionFailed: 'Échec de la suppression des applications',
    applicationsFetchFailed: 'Erreur lors de la récupération des applications',
    appsNotDeletedByAssignedToProfileTitle: `Les applications suivantes n'ont PAS été supprimées car elles sont toujours attribuées à un profil (%{profile})`,
    appsDeletedTitle:
      'Les applications suivantes ont été supprimées avec succès',
    addBundleTitle: 'Ajouter un package',
    addBundleFieldPlaceholderText: `Entrez l'ID du package`,
  },
  devices: {
    title: 'Dispositifs',
    csvExportIconHint: 'Exporter le tableau vers un fichier CSV',
    devicesSelected: 'Appareils sélectionnés',
    removeDevices: 'Supprimer des appareils',
    selectFleet: 'Sélectionnez la flotte',
    associationHint: 'You are associating',
    assignmentButtonText: 'ASSOCIATE NOW',
    selectedFleet: 'Flotte sélectionnée',
    deviceUpdateTitle: 'Dernier agent',
    deviceLogsLoadingFailed: `Échec du chargement des journaux de l'appareil`,
    applicationsLoadingFailed: 'Échec du chargement des applications',
    deviceProfilesLoadingFailed: 'Échec du chargement des profils',
    devicePropertyUpdateFailed: `Échec de la mise à jour de la propriété de l'appareil`,
    devicePropertyValueMustBeUnique: `La propriété de l'appareil doit être unique`,
    applicationsUninstallFailed: 'Échec de la désinstallation des applications',
    sendingRemoteCommandToSelectedDevicesErrorMessage: `Erreur lors de l'envoi de la commande à distance aux appareils sélectionnés`,
    errorOnFetchingAllCompanyDevices:
      'Erreur lors de la récupération des appareils',
    errorOnDeletingSelectedDevices:
      'Erreur lors de la suppression des appareils sélectionnés',
    removeDeviceTitle: 'Supprimer des appareils',
    sendingRemoteCommandTitle: 'Envoi de commande à distance',
    fields: {
      fleetName: 'Flotte',
      haveUpdates: 'Dernier agent',
      latestAgent: 'Dernier agent',
      status: 'Statut',
      lastSeen: 'Vu pour la dernière fois',
      deviceName: 'Appareil',
      agentVersion: `Version de l'agent`,
      platform: 'Plate-forme',
      location: 'Emplacement',
      longitude: 'Longitude',
      latitude: 'Latitude',
      batteryLevel: 'Niveau de batterie',
      lastOnline: 'Dernière connexion',
      enrollmentDatetime: `Date/Heure d'inscription`,
      manufacturer: 'Fabricant',
      model: 'Modèle',
      macAddress: 'Adresse Mac',
      ethernetMacAddress: 'Adresse MAC Ethernet',
      ipAddress: 'Adresse IP',
      totalMemory: 'Mémoire totale',
      availableMemory: 'Mémoire disponible',
      totalStorage: 'Stockage total',
      availableStorage: 'Stockage disponible',
      serialNumber: 'Numéro de série',
      imei: 'IMEI',
      sendingAnalyticsData: 'Envoi de données analytiques',
      compliance: 'Conformité des agents',
      compliantAndLatestVersion: 'Conforme et dernière version',
      compliantAndNotLatestVersion: 'Conforme mais pas la dernière version',
      notCompliantVersion: 'Version non conforme',
      deviceConfigurationLockStatus: 'Device Configuration Lock Status',
      automaticAgentUpdatesEnabled: 'Automatic Agent Updates Enabled',
    },
  },
  home: {
    title: 'Accueil',
    search: 'Chercher',
  },
  users: {
    userGroups: `Groupes d'utilisateurs`,
    title: 'Utilisateurs',
    allUsers: 'Tous les utilisateurs',
    admins: 'Administrateurs',
    manage: 'Faire en sorte',
    groupName: 'Grouper',
    moveUsers: 'Déplacer des utilisateurs:',
    userStatusChange: `Changement de statut d'utilisateur`,
    makeUsersInactiveConfirmation:
      'Voulez-vous rendre les utilisateurs sélectionnés inactifs?',
    activateUsersConfirmation:
      'Êtes-vous sûr de vouloir activer les utilisateurs sélectionnés?',
    cannotDeleteGroupContainingUsersMessage:
      'Vous ne pouvez pas supprimer un groupe qui a encore des utilisateurs. Veuillez supprimer tous les utilisateurs de ce groupe, puis réessayer.',
    deleteGroupConfirmation:
      'Êtes-vous sûr de vouloir supprimer %{groupName}? ',

    createAGroup: 'CRÉER UN GROUPE',
    createUser: 'CRÉER UN UTILISATEUR',
    editGroup: 'MODIFIER LE GROUPE',
    deleteGroup: 'SUPPRIMER LE GROUPE',
    chooseAUserGroup: `Choisissez un groupe d'utilisateurs`,
    move: 'Se déplacer',
    changeUserGroup: `Changer de groupe d'utilisateurs`,
    activateUsers: 'Activer les utilisateurs',
    deactivateUsers: 'Désactiver des utilisateurs',
    removeUsers: 'Supprimer des utilisateurs',
    usersSelected: 'Utilisateur(s) sélectionné(s)',

    newUserGroup: `Nouveau groupe d'utilisateurs`,
    permissions: 'Autorisations',
    userManagement: 'Gestion des utilisateurs',
    groupCreationFailureDefaultErrorText: 'Échec de la création du groupe',
    groupCreationHeading: 'vous créez',
    groupEditingHeading: 'vous éditez',
    update: 'Mettre à jour',
    create: 'Créer',

    globalPermissionsTableDescriptionHeader: 'LA DESCRIPTION',
    globalPermissionsTableReadOnlyHeader: 'LECTURE SEULEMENT',
    globalPermissionsTableReadWriteHeader: 'LIRE ÉCRIRE',

    userGroupSectionDescription: `Groupe d'utilisateurs`,
    globalPermissionsSectionDescription: 'Autorisations globales',
    fleetAccessSectionDescription: 'Gestion des accès à la flotte',
    remoteControlSectionDescription: 'Autorisations de contrôle à distance',
    viewSpecificSectionDescription: 'Autorisations spécifiques à la vue',
    userGroupCreationGroupNameLabel: `Nom du groupe d'utilisateurs:`,
    allUsersEmptyText: 'Vous avez sélectionné tous les utilisateurs.',
    allUsersListHeaderEmail: 'E-mail',
    allUsersListHeaderUsername: `Nom d'utilisateur`,
    noUsersInGroupText: `Il n'y a aucun utilisateur attaché à ce groupe d'utilisateurs.`,
    hintToAddUsers:
      'Veuillez associer les utilisateurs de la liste à votre droite.',
    existingUsersTitle: 'Utilisateurs existants',
    usernameTitle: `NOM D'UTILISATEUR`,
    failedToCreateNewUserMessage: `Échec de la création d'un nouvel utilisateur`,
    updateUserTitle: `Mettre à jour l'utilisateur`,
    createNewUserButton: 'Créer',
    newUserText: 'Nouvel utilisateur',
    youAreAdding: 'vous ajoutez',

    groupsLoadErrorText: 'Échec du chargement des groupes',
    groupCreationError: 'Échec de la création du nouveau groupe',
    groupUpdateError: 'Échec de la mise à jour du groupe',
    groupDeletionError: 'Échec de la suppression du groupe',
    loadGroupsModelLevelPermissionsError:
      'Échec du chargement des autorisations',
    loadAllUsersError: 'Échec du chargement des utilisateurs',
    loadCompanyFleetsFullListError: `Échec du chargement de la liste complète des flottes de l'entreprise`,
    loadUsersLogsError: 'Échec du chargement des journaux des utilisateurs',
    updateUserError: `Échec de la mise à jour des détails de l'utilisateur`,
    loadUserError: `Échec du chargement des détails de l'utilisateur`,
    loadUsersError: 'Échec du chargement des utilisateurs',
    deleteUsersError: 'Échec de la suppression des utilisateurs',
    moveUsersError: 'Échec du déplacement des utilisateurs',
    changeUserStatusError: `Échec de la modification du statut de l'utilisateur`,
    loadUserGroupPermissionError: `Échec du chargement des autorisations du groupe d'utilisateurs`,

    userTabHeading: 'Des détails',
    userDetailsSectionHeading: `Détails de l'utilisateur`,
    adminControlsSectionHeading: `Contrôles d'administration`,
    adminRoleAssigmentToggleLabel: `Rôle d'administrateur`,
    partnerRoleAssigmentToggleLabel: 'Rôle de partenaire',
    userRoleSectionHeading: 'Rôles des utilisateurs',

    deleteAccountToggleLabel: 'Supprimer le compte',
    userUpdateErrorTitle: `Erreur de mise à jour de l'utilisateur`,

    groupListCSVExportIconHint:
      'Exporter la liste des groupes vers un fichier CSV',

    devicePermissionTitle: 'Appareil',
    fleetTreePermissionTitle: 'Arbre des flottes',
    profilePermissionTitle: 'Profil',

    fleetAccessTitle: 'Accès à la flotte',
    profileAccessTitle: 'Accès au profil',

    permissionTitleUploadsAndApplicationsAccessTitle:
      'Téléchargements et accès aux applications',
    permissionTitleUploadsTitle:
      'Téléchargements (fichiers, certificats, applications)',

    permissionTitleApplicationsAccess: 'Accès aux applications',
    permissionTitleViewDeviceLocation: `Afficher l'emplacement de l'appareil`,
    permissionTitleLiveDeviceLocationTracking: `Suivi de l'emplacement de l'appareil en direct`,
    permissionTitleViewCurrentLocation: `Afficher l'emplacement actuel de l'appareil`,
    permissionTitleViewLocationHistory: `Afficher l'historique de localisation de l'appareil`,

    globalPermissionsAccessTypeReadOnly: 'LECTURE SEULEMENT',
    globalPermissionsAccessTypeReadWrite: 'LIRE ÉCRIRE',
    globalPermissionsAccessTypeNone: 'RIEN',

    globalPermissionsHeadingDescription: 'La description',
    globalPermissionsHeadingAccess: 'Accès',

    userCreationTabTitle: 'Créer un utilisateur individuel',
    failedToLoadPartners: 'Échec du chargement des partenaires',
    failedToLoadPartnerCompanies:
      'Échec du chargement des entreprises partenaires',

    enrollmentEmailTemplateTitle: `Modèle d'e-mail d'inscription`,

    fields: {
      username: `Nom d'utilisateur`,
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      email: 'E-mail',
      emailPlaceholder: 'Entrez une adresse email',
      password: 'Mot de passe',
      active: 'Actif',
      inactive: 'Inactif',
      status: 'Statut',
      lastLoginTitle: 'Dernière',
      creationDateTitle: 'Créé sur',
      creatorTitle: 'Créé par',
      requirePasswordChangeOnNextLogin: `Demander à l'utilisateur de changer de mot de passe lors de la prochaine connexion`,
      disablePasswordChange: `Interdire à l'utilisateur de changer de mot de passe`,
      disableEmailAddressChange: `Interdire à l'utilisateur de changer d'adresse e-mail`,
      disableAccount: 'Désactiver temporairement le compte',
      companyName: 'Nom de la compagnie',
      partnerNameFieldText: 'Nom du partenaire',
      enrollmentEmailHint: `Nous avons rédigé un e-mail que vous pouvez envoyer à vos employés pour les faire inscrire. Ils devront accéder au lien d'inscription pour télécharger l'agent de l'appareil.`,
      emailContentFieldTitle: `Contenu de l'e-mail`,
      emailSubjectFieldTitle: 'Sujet du courriel',
    },
    userLogs: {
      type: 'Taper',
      date: 'Date',
      messageLog: 'Journal des messages',
      name: 'Nom',
      ipAddress: 'Adresse IP',
      userGroupsTitle: `Groupes d'utilisateurs`,
      startDate: 'Date de début',
      endDate: 'Date de fin',
    },
  },
  user: {
    admin: 'Administrateur',
    partner: 'Partenaire',
    partneruser: 'Utilisateur partenaire',
    superuser: 'Super utilisateur',
    customer: 'Client',
    user: 'Utilisateur',
    unknownAccessLevel: `Niveau d'accès inconnu`,
  },
  settings: {
    title: 'Réglages',
    accountSettings: 'Paramètres du compte',
    accountSettingsLoadFailure:
      'Échec du chargement des informations sur le compte',
    globalPermissionsLoadFailureMessage: `Échec du chargement des autorisations globales pour l'utilisateur connecté.`,
    failedToLoadTimezones: 'Échec du chargement des fuseaux horaires',
    failedToGetSSOSAMLSettings: `Échec de l'obtention des paramètres SAML`,
    fields: {
      name: 'Nom',
      namePlaceholder: 'Mon nom',
      email: 'Adresse e-mail',
      emailPlaceholder: 'E-mail',
    },
    passwordChangeTitle: 'Changement de mot de passe',
    changePassword: 'Changer le mot de passe',
    changingPassword: 'Modification du mot de passe',
    currentPasswordTitle: 'Mot de passe actuel',
    currentPasswordPlaceholder: 'Entrer le mot de passe actuel',
    newPasswordTitle: 'Nouveau mot de passe',
    newPasswordPlaceholder: 'Entrez un nouveau mot de passe',
    reTypeNewPasswordTitle: 'Re-taper le nouveau mot de passe',
    validationAllPasswordFieldRequired: 'Tous les champs sont obligatoires',
    passwordValidationCantBePreviouslyUsed: `Les nouveaux mots de passe doivent être différents de l'ancien mot de passe`,
    passwordValidationDoesNotMatch: 'Le mot de passe ne correspond pas',
    passwordValidationMinimumLength: `La longueur du mot de passe doit être d'au moins 8 caractères`,
    passwordChangeFailureText: 'Échec de la modification du mot de passe',

    companyInfo: `Information d'entreprise`,
    companyInfoHint: 'Informations relatives à cette entreprise',
    companyName: 'Nom de la compagnie',
    totalNumberOfLicenses: 'Nombre total de licences',
    licensesInUse: `Licences en cours d'utilisation`,
    licensesAvailable: 'Licences disponibles',
    commentTitle: 'Comment',

    commonSettings: 'Paramètres communs',
    commonSettingsHint: 'Paramètres des appareils liés à cette entreprise',
    enableAutomaticAgentUpdates:
      'Activer les mises à jour automatiques des agents',
    setupSettings: 'Paramètres de configuration',
    updatePopupTitle: 'Mise à jour des paramètres communs',
    updatePopupDefaultError: 'Impossible de définir la période',

    loginURLLabel: 'URL de connexion',
    organizationIdLabel: `Identifiant de l'organisation`,
    azureADEntityIdLabel: 'Identifiant Azure AD',
    applicationNameLabel: `Nom de l'application`,
    federationMetadataXMLFileLabel: 'XML de métadonnées de fédération',

    invalidXMLFileErrorMessage: 'Fichier XML invalide',
    ssoAccountLinkSuccessMessage: 'Compte lié avec succès.',
    ssoAccountSettingsUpdateSuccessMessage: 'Compte mis à jour avec succès.',
    failedToLinkSSOAccountMessage: `Désolé, nous n'avons pas pu lier votre compte pour le moment`,
    azureSAMLLinkAccountText: 'Lier un compte Azure (SAML)',
    azureSAMLUpdateAccountText: 'Mettre à jour le compte Azure (SAML)',
    updateWarningText:
      'La mise à jour des paramètres de votre compte Azure affecterait les entreprises suivantes',
    azureSAMLLoginButtonText: 'Connectez-vous avec Microsoft Azure (SAML 2.0)',
    OIDCLinkAccountHeading: 'OIDC 2.0',
    SAMLLinkAccountHeading: 'SAML 2.0',
    azureADLinkHeading: 'Azure AD',
    azureOIDCLinkAccountText: 'Lier un compte Azure (OIDC)',
    azureOAuthLoginButtonText:
      'Connectez-vous avec Microsoft Azure (OAuth 2.0)',
    SSOSettingsSectionHeading: 'SSO Réglages',
    SSOSettingsSectionHint: `Veuillez saisir les informations suivantes afin de lier votre compte Azure à l'aide de SAML 2.0`,

    preferences: 'Preferences',
    setTimezoneFieldTitle: 'Time Zone',
    setLocaleFieldTitle: 'Locale',
    savePreferences: 'Save',
    preferencesFetchFailed: 'Failed to fetch preferences',
    preferencesUpdateFailed: 'Failed to update preferences',
    preferencesUpdateSucceeded: 'Preferences successfully updated ',
  },
  common: {
    noData: 'Pas de données',
    send: 'Send',
    ok: 'OK',
    cancel: 'Annuler',
    saveChanges: 'Sauvegarder les modifications',
    save: 'Sauvegarder',
    undo: 'annuler',
    close: 'Fermer',
    filter: 'Filtre',
    edit: 'Éditer',
    set: 'Ensemble',
    yes: 'Oui',
    no: 'Non',
    add: 'Ajouter',
    enabled: 'Activé',
    disabled: 'Désactivé',
    connected: 'Lié',
    disconnected: 'Débranché',
    next: 'Suivant',
    back: 'Retour',
    confirm: 'Confirmer',
    continue: 'Continuez',
    getStarted: 'Commencer',
    inProgress: 'En cours',
    successful: 'À succès',
    success: 'Succès',
    error: 'Erreur',
    failed: 'Manqué',
    filterDescriptionTitle: 'Le filtre satisfera aux critères suivants:',
    filterDescriptionOptionAll: 'Tout ce qui suit',
    filterDescriptionOptionAny: `L'un des suivants`,
    addCriteria: 'Ajouter des critères',
    noFiltersApplied: 'Aucun filtre appliqué',
    selectCondition: 'Sélectionnez la condition',
    removeFilter: 'Supprimer le filtre',
    selectValue: 'Sélectionnez la valeur',
    selectValueType: 'Sélectionnez le type de valeur',
    location: 'Emplacement',
    noLocationSet: 'Aucun emplacement défini',
    noLocationData: 'Pas de données',
    completed: 'Complété',
    somethingWentWrong: `Quelque chose s'est mal passé`,
    proceedConfirmationText: 'Voulez-vous continuer?',
    confirmationDefaultTitle: 'Confirmation',
    confirmationDefaultText: 'Es-tu sûr de vouloir faire ça?',
    alertDefaultTitle: 'Une information important',
    optionModalDefaultTitle: 'Information requise',
    optionModalDefaultText: `Veuillez sélectionner l'une des options suivantes`,
    select: 'Sélectionner',
    promptDefaultTitle: 'Rapide',
    minutes: 'Minutes',
    hours: 'Les heures',
    days: 'Jours',
    weeks: 'Semaines',
    months: 'Mois',
    years: 'Ans',
    notFound: 'Pas trouvé',
    toolsPanelSelectedTitle: 'Choisi',
    or: 'Ou',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    systemMessageLoadFailure: 'Échec du chargement du message système',
  },
  fleets: {
    dropdownToggleText: 'ACTIONS',
    exportTable: `Tableau d'exportation`,
    rename: 'Renommer',
    delete: 'Supprimer',
    fleet: 'Flotte',
    assignProfiles: 'Associate Profiles',
    profileAssignmentModalConfirmationButtonText: 'Associate',
    fleetDetails: 'Détails de la flotte',
    profiles: 'Profils',
    areYouSureYouWantToDeleteThisFleet:
      'Êtes-vous sûr de vouloir supprimer cette flotte?',
    assignDevicesToFleet: 'Associer des appareils à la flotte:',
    enterAFleetName: 'Entrez un nom de flotte',

    fleetsTitle: 'Flottes',
    createAFleet: 'CRÉER UNE FLOTTE',
    creationInProgress: 'Création en cours...',
    success: 'Succès: ',
    fleetCreated: 'Flotte créée',
    error: 'Erreur: ',
    cannotPerform: 'Ne peut pas effectuer',
    fleetMovementConfirmation: `Êtes-vous sûr de vouloir faire de '%{fleetName}' une flotte enfant directe de '%{targetFleetName}'?`,
    fleetMovementSecondaryConfirmation:
      'Conserver les profils initiaux (%{fleetName}).',
    fleetNamePlaceholder: 'Entrez le nouveau nom de la flotte',
    createFleet: 'Créer une flotte',
    allFleets: 'Toutes les flottes',
    selectAllFleets: 'Sélectionner toutes les flottes',
    failedToMoveDevicesErrorText: 'Échec du déplacement des appareils',
    fleetDeletionError: 'Échec de la suppression de la flotte',
    fleetNotEmptyDeletionError: `Cette flotte a des profils qui lui sont associés. Vous devez d'abord dissocier tous les profils avant de le supprimer.`,

    remoteCommandStatusPopupTitle: 'Envoi de commande(s) à distance',
    fleetDeletingStatusPopupTitle: `Suppression d'une flotte`,

    fleetSelectionHint: `Sélectionnez une flotte dans l'arborescence pour afficher les appareils de la flotte.`,
    fleetMovementModalTitle: 'Choisissez une flotte',
    fleetMovementModalConfirmationButtonText: 'Déplacer des appareils',
    toolsPanelTitle: 'Appareil(s) sélectionné(s)',
    moveDevicesToolTitle: 'Déplacer le(s) appareil(s)',
    unenrollDevicesToolTitle: 'Désinscrire le ou les appareils',
    pauseAgentUpdateToolTitle: `Suspendre la mise à jour de l'agent`,
    resumeAgentUpdateToolTitle: `Reprendre la mise à jour de l'agent`,
    updateDPCAppTitle: 'Mettre à jour DPC vers la dernière version',
    identifyDevicesToolTitle: 'Identifier les appareils',
    increaseLocationTrackingFrequencyToolTitle: 'Suivre le(s) appareil(s)',
    forceDeviceCheckInToolTitle: `Forcer l'enregistrement de l'appareil`,
    remoteRebootDevicesToolTitle: 'Périphérique(s) de redémarrage à distance',
    remoteWipeDevicesToolTitle: `Dispositif(s) d'effacement à distance`,
    remoteLockDevicesToolTitle: 'Dispositif(s) de verrouillage à distance',
    resetDevicesPasswordToolTitle: `Réinitialiser le mot de passe de l'appareil`,
    clearApplicationDataAndCacheToolTitle: `Effacer les données d'application et le cache (Samsung uniquement)`,
    sendMessageToolTitle: 'Envoyer le message',
    lockDeviceConfigurationToolTitle: `Verrouiller la configuration de l'appareil`,
    unlockDeviceConfigurationToolTitle: `Déverrouiller la configuration de l'appareil`,
    removeDevicesToolTitle: 'Supprimer le(s) périphérique(s)',

    unEnrollmentConfirmationText:
      'Êtes-vous sûr de vouloir désinscrire le ou les appareils sélectionnés ?',
    lockDeviceConfigurationConfirmationText:
      'Êtes-vous sûr de vouloir verrouiller la configuration actuelle sur le(s) périphérique(s) sélectionné(s)?',
    unlockDeviceConfigurationConfirmationText:
      'Êtes-vous sûr de vouloir rendre disponible la configuration du ou des appareils sélectionnés?',
    removeDevicesConfirmationText:
      'Êtes-vous sûr de vouloir supprimer le ou les appareils sélectionnés ?',
    rebootDevicesConfirmationText:
      'Êtes-vous sûr de vouloir redémarrer le(s) périphérique(s) sélectionné(s) ?',
    wipeDevicesPrimaryConfirmationText:
      'Êtes-vous sûr de vouloir effacer à distance le ou les appareils sélectionnés ?',
    wipeDevicesSecondaryConfirmationText: 'Essuyez également la carte SD',
    lockDevicesConfirmationText:
      'Êtes-vous sûr de vouloir verrouiller à distance le(s) appareil(s) sélectionné(s) ?',
    pauseAgentUpdatesConfirmationText: `Êtes-vous sûr de vouloir suspendre les mises à jour de l'agent sur le ou les appareils sélectionnés ?`,
    resumeAgentUpdatesConfirmationText: `Êtes-vous sûr de vouloir reprendre les mises à jour de l'agent sur le ou les appareils sélectionnés ?`,
    increaseLocationTrackingFrequencyConfirmationText:
      'Êtes-vous sûr de vouloir augmenter la fréquence de suivi de localisation des appareils sélectionnés pour les 10 prochaines minutes?',
    identifyDevicesConfirmationText:
      'Êtes-vous sûr de vouloir identifier le(s) appareil(s) sélectionné(s)',
    updateDPCAppConfirmationText: `Êtes-vous sûr de vouloir mettre à jour l'application DPC sur le ou les appareils sélectionnés vers la dernière version?`,
    forceCheckinConfirmationText: `Êtes-vous sûr de vouloir forcer le(s) appareil(s) sélectionné(s) à s'enregistrer?`,
    clearDevicesAppDataConfirmationText: `Êtes-vous sûr de vouloir effacer les données de l'application sur le ou les appareils sélectionnés ?`,
    resetDevicesPasswordConfirmationText:
      'Êtes-vous sûr de vouloir réinitialiser les mots de passe sur le ou les appareils sélectionnés ?',

    fleetToProfilesAssignmentSuccessMessage:
      'Attribué avec succès le(s) profil(s) nouvellement sélectionné(s).',
    fleetToProfilesUnassignmentSuccessMessage:
      'Profil(s) dissocié(s) avec succès.',
    fleetToProfilesAssignmentFailureMessage: `Échec de l'association d'un ou plusieurs profils.`,
    fleetToProfilesUnassignmentFailureMessage:
      'Impossible de dissocier un ou plusieurs profils.',
    fleetDeletionFailure: 'Échec de la suppression de la flotte.',
    notEmptyFleetTitle: 'Flotte pas vide',
    fleetDeletionFailureDueToDevicesContentMessage:
      'Cette flotte ne peut pas être supprimée car elle contient actuellement un ou plusieurs appareils.',
    fleetDeletionFailureDueToDevicesContentTip:
      'Veuillez supprimer tous les appareils contenus dans cette flotte et réessayer.',
    fleetDeletionFailureDueToAssignedProfiles:
      'Cette flotte ne peut pas être supprimée car elle a actuellement un ou plusieurs profils qui lui sont attribués. Veuillez dissocier ces profils et réessayer.',
    fleetDeletionConfirmation:
      'La suppression de cette flotte en supprimera toutes les versions. Cette action est irréversible. Es-tu sur de vouloir continuer?',
    lockDeviceConfigurationSuccessMessage:
      'Commande de verrouillage de configuration envoyée avec succès au(x) périphérique(s) sélectionné(s)',
    unlockDeviceConfigurationSuccessMessage:
      'Commande de déverrouillage de la configuration envoyée avec succès au(x) périphérique(s) sélectionné(s)',
    lockDeviceConfigurationErrorMessage: `Échec de l'envoi de la commande de verrouillage de configuration au(x) périphérique(s) sélectionné(s)`,
    unlockDeviceConfigurationErrorMessage: `Échec de l'envoi de la commande de déverrouillage de la configuration au(x) périphérique(s) sélectionné(s)`,
    failedToLoadProfilesAssignedToFleet:
      'Échec du chargement des profils attribués à cette flotte',
    fleetCreationFailedMessage: 'Échec de la création de la flotte',
    fleetCreationTitle: 'Création de flotte',
    loadFleetConfigByIdErrorMessage:
      'Échec du chargement de la configuration de la flotte',
    loadAllFleetsErrorMessage: 'Échec du chargement des flottes',
    failedToFetchSelectedFleet: 'Échec du chargement de la flotte sélectionnée',
    unenrollmentFailedText:
      'Échec de la désinscription des appareils sélectionnés',
  },
  fileSystem: {
    addTitle: 'Ajouter',
    title: 'Système de fichiers',
    applicationsSuccessfulUploadMessageText:
      'La ou les candidatures suivantes ont été téléchargées avec succès:',
    htmlFilesSuccessfulUploadMessageText:
      'Le(s) fichier(s) HTML suivant(s) a(ont été) téléchargé(s) avec succès:',
    certificatesSuccessfulUploadMessageText:
      'Le(s) certificat(s) suivant(s) a(ont été) téléchargé(s) avec succès:',
    filesSuccessfulUploadMessageText:
      'Le(s) fichier(s) suivant(s) a(ont été) téléchargé(s) avec succès:',

    applicationsDuplicateUploadFailureMessageText: `La ou les applications suivantes n'ont pas été téléchargées car la ou les mêmes versions de cette (ces) application(s) ont été téléchargées plus tôt. Veuillez essayer de télécharger une version différente.`,
    htmlFilesDuplicateUploadFailureMessageText: `Le ou les fichiers HTML suivants n'ont pas été téléchargés car d'autres fichiers portant le même nom de fichier ont été téléchargés précédemment. Veuillez renommer le(s) fichier(s) et réessayer de télécharger.`,
    certificatesDuplicateUploadFailureMessageText: `Le(s) certificat(s) suivant(s) n'a(ont) pas été téléchargé(s) car d'autres certificats avec le(s) même(s) nom(s) de fichier ont été(ont) été téléchargés plus tôt. Veuillez renommer le(s) certificat(s) et réessayer de télécharger.`,
    filesDuplicateUploadFailureMessageText: `Le(s) fichier(s) suivant(s) n'a(ont) pas été téléchargé(s) car d'autre(s) fichier(s) avec le(s) même(s) nom(s) de fichier(s) a(ont été) téléchargé(s) plus tôt. Veuillez renommer le(s) fichier(s) et réessayer de télécharger.`,

    applicationsUploadFailureMessageText: `Les applications suivantes n'ont pas pu être téléchargées.`,
    htmlFilesUploadFailureMessageText:
      'Échec du téléchargement du ou des fichiers HTML suivants.',
    certificatesUploadFailureMessageText: `Le ou les certificats suivants n'ont pas pu être téléchargés.`,
    filesUploadFailureMessageText: `Le(s) fichier(s) suivant(s) n'a pas pu être téléchargé.`,

    apkFilesUploadMenuItem: 'Fichiers APK',
    filesUploadMenuItem: 'Des dossiers',
    certificatesUploadMenuItem: 'Certificats',
    HTMLKioskFilesUploadMenuItem: 'Fichiers de kiosque HTML',

    apkFilesUploadTab: 'Ajouter des fichiers APK',
    filesUploadTab: 'Ajouter des fichiers',
    certificatesUploadTab: 'Ajouter des certificats',
    HTMLKioskFilesUploadTab: 'Ajouter des fichiers de kiosque HTML',

    apkUploading: 'Ajouter des fichiers de kiosque HTML:',
    filesUploading: 'Téléchargement de fichiers:',
    certificatesUploading: 'Téléchargement de certificats:',
    htmlKioskFilesUploading: 'Téléchargement de fichiers HTML Kiosk:',

    apkUploadingSuccessMessage: 'Applications téléchargées avec succès!',
    filesUploadingSuccessMessage: 'Fichiers téléchargés avec succès!',
    certificatesUploadingSuccessMessage: 'Certificats téléchargés avec succès!',
    htmlKioskFilesUploadingSuccessMessage:
      'Fichiers de kiosque HTML téléchargés avec succès!',

    apkDropZoneTitle:
      'Glissez-déposez vos applications depuis votre ordinateur',
    filesDropZoneTitle:
      'Glissez et déposez vos fichiers depuis votre ordinateur',
    certificatesDropZoneTitle:
      'Glissez-déposez vos certificats depuis votre ordinateur',
    htmlKioskFilesDropZoneTitle:
      'Faites glisser et déposez vos fichiers HTML Kiosk depuis votre ordinateur',

    apkAddLinkTitle: `Ajouter un lien vers l'emplacement de stockage du fichier APK`,
    filesAddLinkTitle: `Ajouter un lien vers l'emplacement de stockage du fichier`,
    certificatesAddLinkTitle: `Ajouter un lien vers l'emplacement de stockage du fichier de certificat`,
    htmlKioskAddLinkTitle: `Ajouter un lien vers l'emplacement de stockage des fichiers du kiosque HTML`,

    uploading: 'Téléchargement:',
    registeringFilesOnServer: 'Enregistrement de fichiers sur le serveur...',
    browse: 'FEUILLETER',
    uploadFailed: 'Échec du téléchargement',

    appUploadRejectionMessage: `Les applications suivantes n'ont pas pu être téléchargées car leur taille dépasse la taille maximale autorisée (%{max}) ou leurs types de fichiers ne correspondent pas à l'un des types autorisés (%{allowedFileTypes})`,
    certificateUploadRejectionMessage: `Les certificats suivants n'ont pas pu être téléchargés car leur taille dépasse la taille maximale autorisée (%{max}) ou leurs types de fichiers ne correspondent pas à l'un des types autorisés (%{allowedFileTypes})`,
    fileUploadRejectionMessage: `Les fichiers suivants n'ont pas pu être téléchargés car leur taille dépasse la taille maximale autorisée (%{max}) ou leurs types de fichiers ne correspondent pas à l'un des types autorisés (%{allowedFileTypes})`,
    htmlUploadRejectionMessage: `Les fichiers HTML suivants n'ont pas pu être téléchargés car leur taille dépasse la taille maximale autorisée (%{max}) ou leurs types de fichiers ne correspondent pas à l'un des types autorisés (%{allowedFileTypes})`,

    applicationsSelected: 'Applications sélectionnées',
    removeApplications: 'Supprimer les applications',
    deletingApplications: `Suppression d'applications`,

    versionsDeletionTitle: 'Versions Deletion',
    versionsDeletionFailed: 'Versions deletion failed',
    versionsSelected: 'Versions Selected',
    removeVersions: 'Remove Versions',
    deletingVersions: 'Deleting Versions',
    deleteVersionsConfirmation:
      'This is going to delete the selected versions. Are you sure that you want to continue?',
    versionsNotDeletedByAssignedToProfileTitle: `The following version(s) was (were) NOT deleted because they are still assigned to a profile (%{profile})`,
    versionsDeletedTitle:
      'The following version(s) was (were) successfully deleted',

    fileName: 'Nom de fichier',
    contentType: 'Type de contenu',
    assignFiles: 'Associate Files:',
    uploadedBy: 'Uploaded By',

    selectedFiles: 'Fichiers sélectionnés',
    removeFiles: 'Supprimer des fichiers',
    deletionConfirmation:
      'Êtes-vous sûr de vouloir supprimer le(s) fichier(s) sélectionné(s)?',
    failedToAssignFilesToProfile: `Échec de l'association des fichiers avec le profil`,
    failedToDeleteFiles: 'Échec de la suppression des fichiers',
    failedToLoadFiles: 'Échec du chargement des fichiers',

    sendRemoteLinkFailure: `Échec de l'envoi du lien au serveur pour traitement.`,
    sendRemoteLinkSuccess:
      'Lien(s) envoyé(s) avec succès au serveur pour traitement.',
  },
};
