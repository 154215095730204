import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect } from 'react-router-dom';
import { NetworkStatus } from '../../common/constants';
import {
  changeEnrollmentProperty,
  resetEnrollmentCreation,
} from '../enrollment/enrollment.actions';
import { companyIdSelector } from '../login/login.selectors';
import {
  CreateEnrollmentRoute,
  EnrollmentWizardRoutes,
  EnrollmentWizardTitles,
} from './CreateEnrollment.constants';
import { history } from '../../utils/history';
import { DevicesRoutes, RootRoutes } from '../../utils/routes';
import EntityCreationWizard from '../../components/EntityCreationWizard/entityCreationWizard.component';
import ChooseFleet from './ChooseFleet';
import DeviceNaming from './components/createEnrollmentDeviceNaming';
import NewEnrollmentReview from './NewEnrollmentReview';
import EnrollmentCreated from './EnrollmentCreated';
import RouteHelpers from '../../common/utilities/routeHelpers';
import PopupActions from '../popup/popup.actions';
import { loadAllFleetsV2 } from '../fleets/fleetsV2.thunk';

const steps = [
  {
    routeProps: {
      path: `${CreateEnrollmentRoute}${EnrollmentWizardRoutes.FLEET}`,
      component: ChooseFleet,
      exact: true,
    },
    stepRoute: EnrollmentWizardRoutes.FLEET,
  },
  {
    routeProps: {
      path: `${CreateEnrollmentRoute}${EnrollmentWizardRoutes.NAMING}`,
      component: DeviceNaming,
      exact: true,
    },
    stepRoute: EnrollmentWizardRoutes.NAMING,
  },
  {
    routeProps: {
      path: `${CreateEnrollmentRoute}${EnrollmentWizardRoutes.REVIEW}`,
      component: NewEnrollmentReview,
      exact: true,
    },
    stepRoute: EnrollmentWizardRoutes.REVIEW,
  },
  {
    routeProps: {
      path: `${CreateEnrollmentRoute}${EnrollmentWizardRoutes.DONE}`,
      component: EnrollmentCreated,
      exact: true,
    },
    stepRoute: EnrollmentWizardRoutes.DONE,
  },
];

class CreateEnrollment extends Component {
  componentDidMount() {
    if (!this.props.fleetsLoading) {
      this.props.loadAllFleets();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.createEnrollmentStatus === NetworkStatus.STARTED &&
      this.props.createEnrollmentStatus === NetworkStatus.ERROR
    ) {
      this.onError();
    }
  }

  componentWillUnmount() {
    this.resetEnrollmentCreation();
  }

  resetEnrollmentCreation = () => this.props.resetEnrollmentCreation();

  render() {
    const { changeEnrollmentProperty, createEnrollmentStatus } = this.props;
    const step = RouteHelpers.getUrlParameter(this.props, 'step');
    if (
      createEnrollmentStatus === NetworkStatus.DONE &&
      `/${step}` !== EnrollmentWizardRoutes.DONE
    ) {
      return (
        <Redirect
          to={`${CreateEnrollmentRoute}${EnrollmentWizardRoutes.DONE}`}
        />
      );
    }

    return (
      <EntityCreationWizard
        backArrowText={I18n.t('enrollments.enrollmentsList')}
        backArrowRoute={`${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}`}
        initialScreenInputFieldLabel={I18n.t(
          'enrollments.createAnEnrollmentMethod'
        )}
        initialScreenInputFieldLabelPlaceholder={I18n.t(
          'enrollments.nameThisEnrollmentMethod'
        )}
        entity={this.props.newEnrollment}
        onProceedClick={this.onProceedClick}
        handleCreationPropertyChange={changeEnrollmentProperty}
        resetEntityCreation={this.resetEnrollmentCreation}
        creationStepTitles={EnrollmentWizardTitles}
        currentStep={step}
        entityCreationStatus={this.props.createEnrollmentStatus}
        entityCreationRoute={CreateEnrollmentRoute}
        steps={steps}
      />
    );
  }

  onProceedClick = () => {
    let path = `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create${EnrollmentWizardRoutes.FLEET}`;
    if (this.props.newEnrollment.fleetId) {
      path = `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/create${EnrollmentWizardRoutes.REVIEW}`;
    }
    history.push(path);
  };

  onError = () =>
    PopupActions.showAlert({
      text: this.props.error || I18n.t('common.somethingWentWrong'),
    });
}

const mapStateToProps = (state) => {
  return {
    newEnrollment: state.enrollment.newEnrollment,
    companyId: companyIdSelector(state),
    createEnrollmentStatus: state.enrollment.createEnrollmentStatus,
    error: state.enrollment.createEnrollmentError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeEnrollmentProperty: ({ key, value }) =>
      dispatch(changeEnrollmentProperty({ key, value })),
    resetEnrollmentCreation: () => dispatch(resetEnrollmentCreation()),
    loadAllFleets: () => dispatch(loadAllFleetsV2()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnrollment);
