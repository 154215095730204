import { I18n } from 'react-redux-i18n';
import {
  loadSystemMessageFailed,
  loadSystemMessageStarted,
  loadSystemMessageSuccess,
} from './actions.systemMessage';
import client from '../../utils/client';
import services from '../../utils/services';
import BannerActions from '../Banner/banner.actions';

export function loadSystemMessage() {
  return async (dispatch) => {
    const errorMessage = I18n.t('common.systemMessageLoadFailure');
    try {
      dispatch(loadSystemMessageStarted());
      const { status, data } = await client.get(services.systemMessage);
      // const status = 200;
      // const data = { message: 'some message' };
      if (status === 200) {
        dispatch(loadSystemMessageSuccess({ ...data, text: data.message }));
        BannerActions.showBanner({ ...data, text: data.message });
      } else {
        dispatch(loadSystemMessageFailed(errorMessage));
      }
    } catch (e) {
      dispatch(loadSystemMessageFailed(errorMessage));
    }
  };
}
