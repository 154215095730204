import services from '../../utils/services';
import client from '../../utils/client';
import { deserializeAlert, serializeAlert } from '../alerts/alerts.serializer';

import {
  editAlertFailed,
  editAlertStart,
  loadAlertFailed,
  loadAlertStart,
  loadAlertSuccess,
} from './alert.action';
import { loadAlerts } from '../alerts/alerts.thunk';
import { history } from '../../utils/history';
import { AlertRoutes, RootRoutes } from '../../utils/routes';
import { I18n } from 'react-redux-i18n';

export function loadAlert(alertId) {
  return async (dispatch) => {
    try {
      dispatch(loadAlertStart());
      const { status, data, text } = await client.get(
        `${services.alertRetrieve}${alertId}`
      );
      if (status === 200 && data) {
        dispatch(loadAlertSuccess(deserializeAlert(data)));
      } else {
        dispatch(loadAlertFailed(text));
      }
    } catch (e) {
      dispatch(loadAlertFailed(I18n.t('alerts.alertLoadFailureMessage')));
    }
  };
}

export function editAlert({ alertId, alert }) {
  return async (dispatch) => {
    try {
      dispatch(editAlertStart());
      const { status, data, text } = await client.put(
        `${services.alertUpdate}${alertId}/`,
        serializeAlert(alert)
      );
      if (status === 200 && data) {
        dispatch(loadAlerts());
        history.push(`${RootRoutes.ALERTS}${AlertRoutes.ALERTS}`);
        // dispatch(editAlertSuccess(data));
      } else {
        dispatch(editAlertFailed(text));
      }
    } catch (e) {
      dispatch(loadAlertFailed(I18n.t('alerts.alertUpdateFailureMessage')));
    }
  };
}
