import React from 'react';
import { I18n } from 'react-redux-i18n';

import './noData.styles.scss';

const NoData = ({ text = I18n.t('common.noData') }) => (
  <div className="no-data">{text}</div>
);

export default NoData;
