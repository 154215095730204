import {
  GLOBAL_PERMISSIONS_FOR_USER_FAILED,
  GLOBAL_PERMISSIONS_FOR_USER_LOAD_STARTED,
  GLOBAL_PERMISSIONS_FOR_USER_SUCCESS,
  LINK_SSO_ACCOUNT_FAILED,
  LINK_SSO_ACCOUNT_STARTED,
  LINK_SSO_ACCOUNT_SUCCESS,
} from './account.actionTypes';

export const ACCOUNT_LOAD_STARTED = 'ACCOUNT_LOAD_STARTED';
export const ACCOUNT_LOAD_FAILED = 'ACCOUNT_LOAD_FAILED';
export const ACCOUNT_LOAD_SUCCESS = 'ACCOUNT_LOAD_SUCCESS';

export function loadAccountInfoStarted() {
  return {
    type: ACCOUNT_LOAD_STARTED,
  };
}

export function loadAccountInfoDone(payload) {
  return {
    type: ACCOUNT_LOAD_SUCCESS,
    payload,
  };
}

export function loadAccountInfoFailed(payload) {
  return {
    type: ACCOUNT_LOAD_FAILED,
    payload,
  };
}

export function loadGlobalPermissionsForUserStarted() {
  return {
    type: GLOBAL_PERMISSIONS_FOR_USER_LOAD_STARTED,
  };
}

export function loadGlobalPermissionsForUserSuccess(payload) {
  return {
    type: GLOBAL_PERMISSIONS_FOR_USER_SUCCESS,
    payload,
  };
}

export function loadGlobalPermissionsForUserFailed(payload) {
  return {
    type: GLOBAL_PERMISSIONS_FOR_USER_FAILED,
    payload,
  };
}

export function linkSSOAccountStarted() {
  return {
    type: LINK_SSO_ACCOUNT_STARTED,
  };
}

export function linkSSOAccountSuccess(payload) {
  return {
    type: LINK_SSO_ACCOUNT_SUCCESS,
    payload,
  };
}

export function linkSSOAccountFailed(payload) {
  return {
    type: LINK_SSO_ACCOUNT_FAILED,
    payload,
  };
}
