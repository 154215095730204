import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import { DEFAULT_DATE_TIME_FORMAT } from '../../common/constants';
import { updateNotifications } from '../../redux/notifications.thunk';
import { notificationUpdateTypes } from '../../constants/notifications';

const styles = {
  notification: {
    boxSizing: 'border-box',
    minHeight: '48px',
    minWidth: '300px',
    width: '50vw',
    maxWidth: '900px',
    borderRadius: '2px',
    padding: '14px 24px',
    backgroundColor: 'white',
    color: '#002340', // --incube dark navi
    border: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '5px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  notificationDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    fontWeight: 'normal',
  },
  notificationLink: {
    textDecoration: 'underline',
    textTransform: 'none',
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
  },
  notificationTextActive: {
    fontWeight: 600,
  },
  notificationActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '80px',
  },
  notificationActionLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontSize: '11px',
    letterSpacing: 'normal',
  },
};

export class NotificationListItem extends React.Component {
  renderNotificationText = () => {
    const {
      notification,
      notification: { type, url, isRead, text },
      updateNotifications,
    } = this.props;
    if (type && type === 'LINK') {
      return (
        <NavLink
          to={url}
          onClick={() =>
            updateNotifications(notification, notificationUpdateTypes.READ)
          }
          style={{
            ...styles.notificationLink,
            ...(!isRead ? styles.notificationTextActive : {}),
          }}
        >
          {text}&nbsp;
        </NavLink>
      );
    }
    return text;
  };

  renderMarkAsReadAction = () => {
    const { notification } = this.props;
    if (!notification.isRead) {
      return (
        <div
          style={styles.notificationActionLink}
          onClick={() =>
            this.props.updateNotifications(
              notification,
              notificationUpdateTypes.READ
            )
          }
        >
          <Translate value="notifications.markAsRead" />
        </div>
      );
    }
  };

  notificationDateStyle = () => {
    return {
      ...styles.notificationDate,
      ...(!this.props.notification.isRead ? styles.notificationTextActive : {}),
    };
  };

  render() {
    const {
      updateNotifications,
      notification,
      hasAdminPrivileges,
    } = this.props;
    const notificationDate =
      notification.timeStamp && moment(notification.timeStamp).isValid()
        ? moment(notification.timeStamp)
            .format(DEFAULT_DATE_TIME_FORMAT)
            .toString()
        : '';

    return (
      <div style={styles.notification}>
        <div style={this.notificationDateStyle()}>{notificationDate}</div>
        {this.renderNotificationText()}
        <div style={styles.notificationActions}>
          {this.renderMarkAsReadAction()}
          {hasAdminPrivileges ? (
            <div
              style={styles.notificationActionLink}
              onClick={() =>
                updateNotifications(
                  notification,
                  notificationUpdateTypes.REMOVE
                )
              }
            >
              <Translate value="notifications.remove" />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateNotifications: (notification, updateType) =>
    dispatch(updateNotifications(notification, updateType)),
});

export default connect(null, mapDispatchToProps)(NotificationListItem);
