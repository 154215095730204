import url from 'url';
import Helpers from '../helpers';
import queryString from './queryString';

function getUrlParameter({ match }, paramName) {
  return match ? Helpers.coalesce((match.params || {})[paramName], '') : '';
}

const formatUrlWithQuery = (pathname, queryObject = {}) => {
  const isQueryObjectEmpty = Object.keys(queryObject).length === 0;
  if (isQueryObjectEmpty) {
    return pathname;
  }
  return `${pathname}?${queryString.stringify(
    Object.keys(queryObject).reduce(
      (acc, key) =>
        queryObject[key] ? { ...acc, [key]: queryObject[key] } : acc,
      {}
    )
  )}`;
};

const getQueryParamFromLocation = (location, paramName) => {
  if (!location || !location.search) return null;
  const searchResult = new RegExp(`[?&]?${paramName}=([^&]+)`).exec(
    location.search
  );
  return searchResult && searchResult.length > 1
    ? decodeURIComponent(searchResult[1])
    : null;
};

const getURLQueryParams = (requestUrl = '') => {
  if (!requestUrl) return {};
  const { query = '' } = url.parse(requestUrl);
  return queryString.parse(query);
};

/*
 * useful for replacing url without triggering a reload
 * @param url
 * @param data
 * @param title
 * */
const replaceURLState = (url, data = null, title = '') =>
  window.history.replaceState?.(data, title, url);

const RouteHelpers = {
  getUrlParameter,
  formatUrlWithQuery,
  getQueryParamFromLocation,
  getURLQueryParams,
  replaceURLState,
};

export default RouteHelpers;
