import React, { FC, ReactNode } from 'react';
import StyleUtils from '../utils/styleUtils';

interface SectionDescriptionProps {
  title: ReactNode;
  hint?: ReactNode;
  isSmall?: boolean;
}

export const SectionDescription: FC<SectionDescriptionProps> = ({
  title,
  hint = '',
  isSmall = false,
}) => {
  return (
    <div className={'section-description'}>
      <div
        className={StyleUtils.mergeModifiers(
          'section-description__title',
          isSmall && 'small'
        )}
      >
        {title}
      </div>
      <div className={'section-description__hint'}>{hint}</div>
    </div>
  );
};
