import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { SwitchField } from '../../../components/switchField.component';
import { SelectField } from '../../../components/selectField.component';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../../common/constants';
import {
  getObjectByValue,
  isDevOrQAEnvironment,
} from '../../../common/helpers';
import PolicyActivationToggle from './PolicyActivationToggle.component';
import { policySettingsValueChangeHandler } from '../../../common/utilities/profile';
import { androidSupportedLocales } from '../../../constants/androidLocales';
import Slider from '../../../components/Slider/slider.component';
import { SectionDescription } from '../../../components/sectionDescription.component';

export const initialSettings = {
  syncNTP: false,
  useDaylightSavingsTime: false,
  syncTZ: undefined,
  deviceLanguage: '',
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'settings',
  isActive: false,
  policyData: null,
};

const DEFAULT_TIME_ZONE = 'UTC';

export class Settings extends Component {
  render() {
    const { isEditingMode } = this.props;
    return (
      <div className={'security-wrapper'}>
        {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
      </div>
    );
  }

  renderContent = () => {
    return this.props.policy.length
      ? this.props.policy.map(this.renderSettings)
      : this.renderSettings(
          { ...initialPolicy, settings: { ...initialSettings } },
          0
        );
  };

  renderReadOnlyContent = () => {
    const { policy: [{ settings = {}, isActive } = {}] = [] } = this.props;
    const { title: selectedLanguage = '' } = getObjectByValue(
      androidSupportedLocales,
      settings.deviceLanguage,
      'value'
    );
    return (
      <ReadOnlyForm
        headerTitle={I18n.t('profiles.settings.policyTitle')}
        items={[
          {
            id: 'policy-status',
            title: I18n.t('profiles.settings.policyStatusTitle'),
            value: isActive,
            type: AttributeType.BOOLEAN,
          },
          {
            title: I18n.t('profiles.settings.timeSectionTitle'),
            type: AttributeType.SUBHEADING,
            key: 'timeSectionTitleSubHeading',
            isSmall: true,
          },
          {
            title: I18n.t('profiles.settings.syncNTPLabel'),
            value: settings['syncNTP'] || false,
            type: AttributeType.BOOLEAN,
            key: 'syncNTP',
          },
          {
            title: I18n.t('profiles.settings.setDeviceTimezone'),
            value: settings['syncTZ'] || false,
            type: AttributeType.BOOLEAN,
            key: 'syncTZ',
          },
          ...(settings['syncTZ']
            ? [
                {
                  title: I18n.t('profiles.settings.timezone'),
                  value: settings['syncTZ'],
                  key: 'syncTZ',
                  type: AttributeType.TEXT,
                },
              ]
            : []),

          ...(isDevOrQAEnvironment()
            ? [
                {
                  title: I18n.t('profiles.settings.useDaylightSavingsTime'),
                  value: settings['useDaylightSavingsTime'] || false,
                  type: AttributeType.BOOLEAN,
                  key: 'useDaylightSavingsTime',
                },
              ]
            : []),

          ...(isDevOrQAEnvironment()
            ? [
                {
                  title: I18n.t('profiles.settings.languageSectionTitle'),
                  type: AttributeType.SUBHEADING,
                  key: 'languageSectionTitleSubHeading',
                  isSmall: true,
                },
                {
                  title: I18n.t('profiles.settings.deviceLanguageTitle'),
                  value: selectedLanguage,
                },
              ]
            : []),

          ...(isDevOrQAEnvironment()
            ? [
                {
                  title: I18n.t(
                    'profiles.settings.synchronizationSectionTitle'
                  ),
                  type: AttributeType.SUBHEADING,
                  key: 'synchronizationSectionTitleSubHeading',
                  isSmall: true,
                },
                {
                  title: I18n.t('profiles.settings.syncDeviceOverWiFi'),
                  value: settings['syncDeviceOverWiFi'] || false,
                  type: AttributeType.BOOLEAN,
                  key: 'syncDeviceOverWiFi',
                },
                {
                  title: I18n.t('profiles.settings.downloadFilesOverWifi'),
                  value: settings['downloadFilesOverWifi'] || false,
                  type: AttributeType.BOOLEAN,
                  key: 'downloadFilesOverWifi',
                },
              ]
            : []),

          ...(isDevOrQAEnvironment()
            ? [
                {
                  title: I18n.t('profiles.settings.systemSectionTitle'),
                  type: AttributeType.SUBHEADING,
                  key: 'systemSectionTitleSubHeading',
                  isSmall: true,
                },
                {
                  title: I18n.t('profiles.settings.disableAndroidBeamTitle'),
                  value: settings['disableAndroidBeam'] || false,
                  type: AttributeType.BOOLEAN,
                  key: 'disableAndroidBeam',
                },
                {
                  title: I18n.t('profiles.settings.deviceVolumeTitle'),
                  value:
                    settings.deviceVolume || settings.deviceVolume == 0
                      ? `${settings.deviceVolume}%`
                      : '',
                  type: AttributeType.TEXT,
                  key: 'deviceVolume',
                },
              ]
            : []),

          ...(isDevOrQAEnvironment()
            ? [
                {
                  title: I18n.t('profiles.settings.displaySectionTitle'),
                  type: AttributeType.SUBHEADING,
                  key: 'displaySectionTitleSubHeading',
                  isSmall: true,
                },
                {
                  title: I18n.t('profiles.settings.autoRotateScreenTitle'),
                  value: settings['autoRotateScreen'] || false,
                  type: AttributeType.BOOLEAN,
                  key: 'autoRotateScreen',
                },
                {
                  title: I18n.t('profiles.settings.deviceBrightnessTitle'),
                  value:
                    settings.deviceBrightness || settings.deviceBrightness == 0
                      ? `${settings.deviceBrightness}%`
                      : '',
                  type: AttributeType.TEXT,
                  key: 'deviceBrightness',
                },
              ]
            : []),
        ]}
      />
    );
  };

  renderSettings = ({ settings = {}, isActive }, index) => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      this.props.onChangePolicyProperty({
        key: 'settings',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });

    return (
      <div className={'security'} key={`secpol_${index}`}>
        <div className={'section-description'}>
          <div className={'section-description__title--small'}>
            {I18n.t('profiles.settings.policyTitle')}
          </div>
          {/*<div>{I18n.t('profiles.settings.policyHint')}</div>*/}
        </div>

        <PolicyActivationToggle
          key="policyActivation"
          policy={this.props.policy}
          titleCode="profiles.settings.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
        />
        {isActive ? (
          <>
            <SectionDescription
              title={I18n.t('profiles.settings.timeSectionTitle')}
              isSmall
            />
            <SwitchField
              onClick={(e) =>
                this.onValueChange({
                  index,
                  key: 'syncNTP',
                  isValueBoolean: true,
                })
              }
              on={settings['syncNTP']}
              title={I18n.t('profiles.settings.syncNTPLabel')}
            />
            <SwitchField
              onClick={(e) =>
                this.onValueChange({
                  index,
                  key: 'syncTZ',
                  value: settings['syncTZ'] ? undefined : DEFAULT_TIME_ZONE,
                })
              }
              on={!!settings['syncTZ']}
              title={I18n.t('profiles.settings.setDeviceTimezone')}
            />
            {this.renderTimezonesSelect(settings, index)}
            {isDevOrQAEnvironment() ? (
              <>
                <SwitchField
                  onClick={(e) =>
                    this.onValueChange({
                      index,
                      key: 'useDaylightSavingsTime',
                      isValueBoolean: true,
                    })
                  }
                  on={settings['useDaylightSavingsTime']}
                  title={I18n.t('profiles.settings.useDaylightSavingsTime')}
                />
              </>
            ) : null}

            <SectionDescription
              title={I18n.t('profiles.settings.languageSectionTitle')}
              isSmall
            />
            {isDevOrQAEnvironment() ? (
              <>
                <SelectField
                  title={I18n.t('profiles.settings.deviceLanguageTitle')}
                  options={androidSupportedLocales}
                  onChange={(e) =>
                    this.onValueChange({
                      index,
                      key: 'deviceLanguage',
                      value: e.target.value,
                    })
                  }
                  value={settings?.deviceLanguage}
                  showEmptyOption
                />
              </>
            ) : null}

            <SectionDescription
              title={I18n.t('profiles.settings.synchronizationSectionTitle')}
              isSmall
            />
            {isDevOrQAEnvironment() ? (
              <>
                <SwitchField
                  onClick={(e) =>
                    this.onValueChange({
                      index,
                      key: 'syncDeviceOverWiFi',
                      isValueBoolean: true,
                    })
                  }
                  on={settings['syncDeviceOverWiFi']}
                  title={I18n.t('profiles.settings.syncDeviceOverWiFi')}
                  enabled={!settings['downloadFilesOverWifi']}
                />
                <SwitchField
                  onClick={(e) =>
                    this.onValueChange({
                      index,
                      key: 'downloadFilesOverWifi',
                      isValueBoolean: true,
                    })
                  }
                  on={settings['downloadFilesOverWifi']}
                  enabled={!settings['syncDeviceOverWiFi']}
                  title={I18n.t('profiles.settings.downloadFilesOverWifi')}
                />
              </>
            ) : null}

            <SectionDescription
              title={I18n.t('profiles.settings.systemSectionTitle')}
              isSmall
            />
            {isDevOrQAEnvironment() ? (
              <>
                <SwitchField
                  onClick={(e) =>
                    this.onValueChange({
                      index,
                      key: 'disableAndroidBeam',
                      isValueBoolean: true,
                    })
                  }
                  on={settings.disableAndroidBeam}
                  title={I18n.t('profiles.settings.disableAndroidBeamTitle')}
                />
                <Slider
                  title={I18n.t('profiles.settings.deviceVolumeTitle')}
                  min={0}
                  max={100}
                  step={5}
                  value={settings.deviceVolume}
                  defaultValue={settings.deviceVolume}
                  onChange={(value) =>
                    this.onValueChange({
                      index,
                      value,
                      key: 'deviceVolume',
                    })
                  }
                />
              </>
            ) : null}

            <SectionDescription
              title={I18n.t('profiles.settings.displaySectionTitle')}
              isSmall
            />
            {isDevOrQAEnvironment() ? (
              <>
                <SwitchField
                  onClick={(e) =>
                    this.onValueChange({
                      index,
                      key: 'autoRotateScreen',
                      isValueBoolean: true,
                    })
                  }
                  on={settings.autoRotateScreen}
                  title={I18n.t('profiles.settings.autoRotateScreenTitle')}
                />
                <Slider
                  title={I18n.t('profiles.settings.deviceBrightnessTitle')}
                  min={0}
                  max={100}
                  step={5}
                  value={settings.deviceBrightness}
                  defaultValue={settings.deviceBrightness}
                  onChange={(value) =>
                    this.onValueChange({
                      index,
                      value,
                      key: 'deviceBrightness',
                    })
                  }
                />
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  };

  renderTimezonesSelect = (settings, index) => {
    if (!!settings['syncTZ']) {
      return (
        <SelectField
          title={I18n.t('profiles.settings.timezone')}
          value={settings['syncTZ'] ?? DEFAULT_TIME_ZONE}
          onChange={(e) =>
            this.onValueChange({ index, key: 'syncTZ', value: e.target.value })
          }
          options={this.props.timezones.map((timezone) => ({
            title: timezone,
            value: timezone,
          }))}
        />
      );
    }
  };

  onValueChange = ({ value, key, isValueBoolean = false }) =>
    policySettingsValueChangeHandler({
      key,
      value,
      initialPolicy,
      initialSettings,
      isValueBoolean,
      onChange: this.props.onChange,
      policy: this.props.policy,
      policyDetailKey: 'settings',
    });
}

export default Settings;
