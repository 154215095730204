import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { devicesSendRemoteCommandReset } from '../devices/devices.actions';
import FleetDevices from '../devices/components/Devices/FleetDevices';
import FleetsTree from './components/fleetsTree.component';
import { debounce } from 'lodash';
import { Button } from '../../components/button.component';
import {
  assignDevicesToFleet,
  createFleet,
  deleteFleet,
  loadFleetDevices,
  updateFleet,
} from './fleets.thunk';
import { companyIdSelector } from '../login/login.selectors';
import {
  subscribeChanges,
  unsubscribeChanges,
} from '../devices/devices.sockets';
import wsServices from '../../utils/websocketsServices';
import { NetworkStatus } from '../../common/constants';
import { Modal } from '../../components/modal.component';
import { loadAllFleets } from './fleets.thunk';
import {
  fleetDeletingReset,
  fleetLoadingReset,
  fleetProfilesReset,
  fleetProfilesV1Reset,
} from './fleets.actions';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';
import UserHelpers from '../../common/utilities/user';
import { FLEET_TREE_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS } from '../../constants/globalPermissions';
import { showStatusChangePopup } from '../../common/utilities/common';
import DeviceCommands from '../devices/components/DeviceCommands';
import {
  fleetsSelector,
  fleetsStatusSelector,
} from '../../selectors/fleetsSelector';
import { setDeviceData } from '../device/device.actions';
import { deviceSelector } from '../device/device.selectors';
import { resetFleetConfigById } from './fleetsV2.thunk';
import { DataConstants } from '../../constants/data';
import AsyncRendererWithDataFetching from '../../components/AsyncRenderer/AsyncRendererWithDataFetching.component';
import { FleetRoute } from '../../utils/routes';
import { isEmpty, notEmpty } from '../../common/helpers';
import { devicesSelector } from '../devices/devices.selectors';
import AnalyticsService from '../../services/AnalyticsService';

class Fleets extends React.Component {
  state = {
    newFleet: null,
    selectedFleet: null,
    showToolPanel: true,
    selectedDevices: new Set(),
    fleetId: undefined,
  };

  tableRef = React.createRef();

  componentDidMount() {
    this.setSelectedFleetFromUrl();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isEmpty(prevProps.fleets) && notEmpty(this.props.fleets)) {
      this.setSelectedFleetFromUrl();
    }
    if (this.state.selectedFleet !== prevState.selectedFleet) {
      if (this.state.selectedFleet && this.state.selectedFleet.id) {
        if (
          !prevState.selectedFleet ||
          prevState.selectedFleet.id !== this.state.selectedFleet.id
        ) {
          this.props.subscribeChanges(this.state.selectedFleet.id);
        }
      }
      if (
        prevState.selectedFleet &&
        ((this.state.selectedFleet &&
          prevState.selectedFleet.id !== this.state.selectedFleet.id) ||
          !this.state.selectedFleet)
      ) {
        this.props.unsubscribeChanges(prevState.selectedFleet.id);
      }
    }
    if (this.props.fleets !== prevProps.fleets && this.state.selectedFleet) {
      this.onSelectFleet(this.state.selectedFleet);
    }
    if (
      this.props.remoteCommandStatus !== prevProps.remoteCommandStatus &&
      this.props.remoteCommandStatus !== NetworkStatus.NONE
    ) {
      showStatusChangePopup({
        status: this.props.remoteCommandStatus,
        title: I18n.t('fleets.remoteCommandStatusPopupTitle'),
        onClose: () => {
          this.props.devicesSendRemoteCommandReset();
          this.resetSelectedDevices();
        },
      });
    }
    if (
      this.props.fleetDeletingStatus !== prevProps.fleetDeletingStatus &&
      this.props.fleetDeletingStatus !== NetworkStatus.NONE
    ) {
      showStatusChangePopup({
        status: this.props.fleetDeletingStatus,
        title: I18n.t('fleets.fleetDeletingStatusPopupTitle'),
        errorText: this.props.fleetDeletingError,
        onClose: () => {
          if (this.props.fleetDeletingStatus === NetworkStatus.DONE) {
            this.onSelectFleet();
          }
          this.props.fleetDeletingReset();
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.devicesLoadingReset();
    if (this.state.selectedFleet) {
      this.props.unsubscribeChanges(this.state.selectedFleet.id);
    }
    this.props.resetFleetConfigById();
    this.props.resetFleetV2Profiles();
    this.props.resetFleetV1Profiles();
  }

  getFleetIdFromUrl = () => this.props.match.params?.childRoute || '';

  setSelectedFleetFromUrl = () => {
    const fleetIdFromUrl = this.getFleetIdFromUrl();
    if (fleetIdFromUrl) {
      const selectedFleet = this.props.fleets.find(
        (fleet) => fleet.id === fleetIdFromUrl
      );
      this.onSelectFleet(selectedFleet);
    }
  };

  resetSelectedDevices = () =>
    this.tableRef.current?.toggleAllRowsSelected?.(false);

  render() {
    const showToolbar =
      this.state.selectedDevices.size &&
      this.state.showToolPanel &&
      !this.props.match.params.deviceId;
    const contentClassName = showToolbar
      ? 'app__content app__content--with-toolbar'
      : 'app__content';

    return (
      <AsyncRendererWithDataFetching
        dataList={[
          {
            id: DataConstants.FLEETS_V2,
          },
        ]}
      >
        <div className={contentClassName}>
          <div className={'page-content'}>
            <div className={'card card--left-hand'}>
              <FleetsTree
                fleets={this.props.fleets ? this.props.fleets : []}
                status={this.props.fleetsStatus}
                onDrag={this.props.updateFleet}
                onDragCancel={this.resetSelectedDevices}
                onSelect={this.onSelectFleet}
                createAvailable
                createFleet={this.props.createFleet}
                selectedKeys={
                  this.state.selectedFleet
                    ? [this.state.selectedFleet.id]
                    : undefined
                }
              />
            </div>
            <div className={'card card--right-hand'}>
              {this.state.selectedFleet ? (
                <FleetDevices
                  devices={this.props.fleetDevices}
                  devicesLoadingStatus={this.props.fleetLoadingStatus}
                  title={this.state.selectedFleet.name || ''}
                  fleet={this.state.selectedFleet}
                  onRename={this.renameFleet}
                  onRemove={this.deleteFleet}
                  selectable
                  selected={this.state.selectedDevices}
                  onSelect={this.select}
                  onOpenItem={this.onOpenItem}
                  filters={this.props.filters}
                  isCriteriaStrict={this.props.isCriteriaStrict}
                  hasMoreFleetDevices={this.props.hasMoreFleetDevices}
                  tableRef={this.tableRef}
                  loadFleetDevices={() =>
                    this.props.getFleetInfo({
                      fleetId: this.state.selectedFleet.id,
                      offset: this.props.fleetDevicesOffset,
                      limit: INFINITE_SCROLL_LIMIT,
                    })
                  }
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#fff',
                  }}
                >
                  <div style={{ color: 'slategray', margin: '30px' }}>
                    {I18n.t('fleets.fleetSelectionHint')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.selectedDevices.size &&
        !this.state.showToolPanel &&
        !this.props.match.params.deviceId ? (
          <Modal
            title={I18n.t('fleets.fleetMovementModalTitle')}
            actionsSection={
              <Button
                disabled={!this.state.fleetId}
                onClick={this.assignDevicesToFleet}
              >
                {I18n.t('fleets.fleetMovementModalConfirmationButtonText')}
              </Button>
            }
            onBack={this.toggleMoveDevicesModal}
          >
            <FleetsTree
              status={this.props.fleetsStatus}
              fleets={this.props.fleets ?? []}
              onDrag={this.props.updateFleet}
              onSelect={this.onSelect}
              selectedKeys={
                this.state.fleetId ? [this.state.fleetId] : undefined
              }
            />
          </Modal>
        ) : null}
        {this.renderToolbar(showToolbar)}
      </AsyncRendererWithDataFetching>
    );
  }

  renderToolbar = (showToolbar) =>
    showToolbar ? (
      <DeviceCommands
        devices={[...this.state.selectedDevices.values()]}
        customConfirmParams={{
          fleetId: this.state.selectedFleet?.id,
          toggleMoveDevicesModal: this.toggleMoveDevicesModal,
          onRemoveDevicesConfirm: this.resetSelectedDevices,
        }}
        selectedDevices={this.state.selectedDevices}
      />
    ) : null;

  debouncedGetFleet = debounce(
    (fleetId, isInitialLoad) =>
      this.props.getFleetInfo({
        fleetId,
        offset: this.props.fleetDevicesOffset,
        limit: INFINITE_SCROLL_LIMIT,
        isInitialLoad,
      }),
    400
  );

  onSelectFleet = (data) => {
    const { fleetDevicesId } = this.props;

    let selectedFleet;
    if (this.props.fleets && data) {
      selectedFleet = this.props.fleets.find((fleet) => fleet.id === data.id);
    }
    AnalyticsService.logPageView(`${FleetRoute}/fleetId`);
    this.setState({ selectedFleet });
    if (selectedFleet && data) {
      RouteHelpers.replaceURLState(`${FleetRoute}/${selectedFleet.id}`);
      if (!fleetDevicesId || selectedFleet.id !== fleetDevicesId) {
        this.debouncedGetFleet(data.id, true);
      }
    }
  };

  deleteFleet = () => {
    this.props.deleteFleet({
      companyId: this.props.companyId,
      id: this.state.selectedFleet.id,
    });
  };

  renameFleet = (data) => {
    this.props.updateFleet({
      ...this.state.selectedFleet,
      name: data,
    });
  };

  select = (selectedIds) => {
    const selectedDevices = new Set(selectedIds);
    this.setState({ selectedDevices });
  };

  toggleMoveDevicesModal = () => {
    this.setState({ showToolPanel: !this.state.showToolPanel });
  };

  assignDevicesToFleet = () => {
    this.props.assignDevicesToFleet({
      devices: [...this.state.selectedDevices.values()],
      fleetId: this.state.fleetId,
    });
    this.resetSelectedDevices();
    this.setState({
      showToolPanel: !this.state.showToolPanel,
    });
  };

  onOpenItem = ({ id: deviceId }) => {
    const device =
      this.props.fleetDevices.find((device) => device.id === deviceId) ?? {};
    this.props.setDeviceData(device);
    this.resetSelectedDevices();
  };

  onSelect = (fleet) => {
    this.setState({
      fleetId: fleet && fleet.id ? fleet.id : undefined,
    });
  };
}

const mapStateToProps = (state) => {
  const { devices: { fleetDevicesNextPageUrl } = {} } = state;
  const { offset } = RouteHelpers.getURLQueryParams(fleetDevicesNextPageUrl);
  const fleetTreeGlobalPermissionsList =
    (state.groups.modelLevelPermissionsData &&
      state.groups.modelLevelPermissionsData.fleettree) ||
    [];
  const isGlobalFleetAccessReadWriteAccess = FLEET_TREE_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS.every(
    (item) => fleetTreeGlobalPermissionsList.includes(item)
  );
  const { data: device } = deviceSelector(state);
  const { fleetId: fleetDevicesId } = devicesSelector(state);
  return {
    fleets: fleetsSelector(state),
    fleetsStatus: fleetsStatusSelector(state),
    fleetDevices: state.devices.fleetDevices,
    fleetDevicesOffset: offset,
    hasMoreFleetDevices: Boolean(fleetDevicesNextPageUrl),
    fleetLoadingStatus: state.devices.fleetLoadingStatus,
    companyId: companyIdSelector(state),
    remoteCommandStatus: state.devices.remoteCommandStatus,
    fleetDeletingStatus: state.devices.fleetDeletingStatus,
    fleetDeletingError: state.devices.fleetDeletingError,
    filters: state.filters.filters,
    isCriteriaStrict: state.filters.isCriteriaStrict,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    isGlobalFleetAccessReadWriteAccess,
    device,
    fleetDevicesId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeviceData: (data) => dispatch(setDeviceData(data)),
    createFleet: (data) => dispatch(createFleet(data)),
    getFleetInfo: (params) => dispatch(loadFleetDevices(params)),
    devicesLoadingReset: () => dispatch(fleetLoadingReset()),
    updateFleet: (data) => dispatch(updateFleet(data)),
    deleteFleet: (data) => dispatch(deleteFleet(data)),
    fleetDeletingReset: () => dispatch(fleetDeletingReset()),
    assignDevicesToFleet: (params) => dispatch(assignDevicesToFleet(params)),
    loadFleets: () => dispatch(loadAllFleets()),
    subscribeChanges: (fleetId) =>
      dispatch(
        subscribeChanges(
          `${wsServices.subscribeFleetDevicesUpdates}${fleetId}/`,
          'fleet.update',
          false,
          { id: fleetId }
        )
      ),
    unsubscribeChanges: (fleetId) =>
      dispatch(
        unsubscribeChanges(
          `${wsServices.subscribeFleetDevicesUpdates}${fleetId}/`
        )
      ),
    devicesSendRemoteCommandReset: () =>
      dispatch(devicesSendRemoteCommandReset()),
    resetFleetConfigById: () => dispatch(resetFleetConfigById()),
    resetFleetV2Profiles: () => dispatch(fleetProfilesReset()),
    resetFleetV1Profiles: () => dispatch(fleetProfilesV1Reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fleets);
