import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from '../../../../components/button.component';
import { connect } from 'react-redux';
import { AttributeType, NetworkStatus } from '../../../../common/constants';
import { confirmAlert } from 'react-confirm-alert';
import { assignDevicesToFleetReset } from '../../../fleets/fleets.actions';
import { globalPermissionsForUserSelector } from '../../../../selectors/accountSelectors';
import { getDevicesTableColumns } from '../../../../common/utilities/devices';
import UserHelpers from '../../../../common/utilities/user';
import DropDownMenu from '../../../../components/DropDownMenu/dropDownMenu.component';
import SubmissionInput from '../../../../components/SubmissionInput/SubmissionInput.component';
import DataTable from '../../../../components/DataTable/DataTable.component';
import { Modal } from '../../../../components/modal.component';
import ReadOnlyForm from '../../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { Pages } from '../../../../constants/pages';
import {
  profilesSelector,
  profilesV2Selector,
} from '../../../../selectors/profiles';
import ProfileAssignmentModal from './ProfileAssignmentModal.component';
import AsyncRendererWithDataFetching from '../../../../components/AsyncRenderer/AsyncRendererWithDataFetching.component';
import { DataConstants } from '../../../../constants/data';
import { devicesSelector } from '../../devices.selectors';
import {
  loadProfilesByFleetV1,
  loadProfilesByFleetV2,
} from '../../../fleets/fleetsV2.thunk';
import { loadProfiles } from '../../../profiles/profiles.thunk';
import { loadProfilesV2 } from '../../../profilesV2/profilesV2.thunk';
import { getFleetDevicesTableColumns } from './devicesColumns';

class FleetDevicesV2 extends Component {
  state = {
    isShowRenameInput: false,
    areFleetDetailsShown: false,
    isProfileAssignmentModalShown: false,
    selectedProfiles: [],
  };

  get allFleetDevicesTableColumns() {
    const {
      globalPermissionsForUser = {},
      hasAdminPrivileges = false,
    } = this.props;
    return getDevicesTableColumns(
      globalPermissionsForUser,
      getFleetDevicesTableColumns,
      hasAdminPrivileges
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selected !== this.props.selected ||
      prevProps.devices !== this.props.devices
    ) {
      this.onResetRenameInput();
    }

    if (
      this.props.devicesMoveStatus !== prevProps.devicesMoveStatus &&
      this.props.devicesMoveStatus !== NetworkStatus.NONE
    ) {
      this.onDevicesAssignResponse();
    }
  }

  // todo: removee
  onDevicesAssignResponse = () => {
    if (this.props.devicesMoveStatus) {
      let color =
        this.props.devicesMoveStatus === NetworkStatus.DONE
          ? 'green'
          : this.props.devicesMoveStatus === NetworkStatus.ERROR
          ? 'red'
          : '#222';
      let text =
        this.props.devicesMoveStatus === NetworkStatus.DONE
          ? I18n.t('common.successful')
          : this.props.devicesMoveStatus === NetworkStatus.ERROR
          ? I18n.t('common.error')
          : I18n.t('common.inProgress');
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="create-enrollment-alert">
              <p>
                <Translate value="fleets.assignDevicesToFleet" />
              </p>
              <p style={{ color: color }}>{text}</p>
              <Button
                onClick={() => {
                  this.props.onSelectFleet();
                  onClose();
                }}
              >
                <Translate value="common.ok" />
              </Button>
            </div>
          );
        },
      });
      this.props.assignDevicesToFleetReset();
    }
  };

  render() {
    const {
      devices,
      onOpenItem,
      devicesLoadingStatus,
      selectable,
      onSelect,
      profiles,
      profilesV2,
      fleet: { id: fleetId } = {},
      tableRef,
      fleetProfilesV2,
      fleetProfilesV2Status,
      fleetProfilesV2Error,

      fleetProfilesV1,
      fleetProfilesV1Status,
      fleetProfilesV1Error,

      loadProfilesByFleetV1,
      loadProfilesByFleetV2,
    } = this.props;
    return (
      <>
        <DataTable
          allColumns={this.allFleetDevicesTableColumns}
          data={devices}
          onOpenItem={onOpenItem}
          loadingStatus={devicesLoadingStatus}
          selectable={selectable}
          onSelect={onSelect}
          sortable
          pageId={Pages.FLEET_DEVICES}
          rightCustomHeaderAction={this.renderDropDownMenu()}
          headerTitle={this.renderFleetTitle(devices)}
          tableRef={tableRef}
          // infiniteScrollProps={{
          //     loadMore: this.props.loadFleetDevices,
          //     hasMore: this.props.hasMoreFleetDevices,
          // }}
          useResizableColumns
          allColumnsResizable
          useFilter
          useColumnsToggle
          useCSVExport
        />
        {this.state.areFleetDetailsShown && (
          <Modal
            title={I18n.t('fleets.fleetDetails')}
            onBack={this.toggleFleetDetails}
          >
            <AsyncRendererWithDataFetching
              dataList={[
                {
                  id: DataConstants.PROFILES_V2,
                  params: {
                    loadFullList: true,
                    isInitialLoad: true,
                  },
                },
                {
                  id: DataConstants.PROFILES_V1,
                },
              ]}
              customDataStatus={{
                [DataConstants.FLEET_PROFILES_V2]: fleetProfilesV2Status,
                [DataConstants.FLEET_PROFILES_V1]: fleetProfilesV1Status,
              }}
              customDataError={{
                [DataConstants.FLEET_PROFILES_V2]: fleetProfilesV2Error,
                [DataConstants.FLEET_PROFILES_V1]: fleetProfilesV1Error,
              }}
              customDataFetchFunctions={{
                [DataConstants.FLEET_PROFILES_V2]: () =>
                  loadProfilesByFleetV2({
                    fleetId,
                  }),
                [DataConstants.FLEET_PROFILES_V1]: () =>
                  loadProfilesByFleetV1({
                    fleetId,
                  }),
              }}
            >
              <ReadOnlyForm
                key={`fleet-details-read-only-form`}
                items={[
                  {
                    title: I18n.t('fleets.profiles'),
                    valueList: [...fleetProfilesV1, ...fleetProfilesV2].map(
                      ({ name }) => name
                    ),
                    type: AttributeType.TEXT,
                    key: 'Profiles',
                  },
                ]}
              />
            </AsyncRendererWithDataFetching>
          </Modal>
        )}

        <ProfileAssignmentModal
          isProfileAssignmentModalShown={
            this.state.isProfileAssignmentModalShown
          }
          toggleProfileAssignmentModal={this.toggleProfileAssignmentModal}
          fleet={this.props.fleet}
        />
      </>
    );
  }

  toggleProfileAssignmentModal = () =>
    this.setState((prevState) => ({
      isProfileAssignmentModalShown: !prevState.isProfileAssignmentModalShown,
    }));

  renderDropDownMenu = () => {
    const { fleet, hasAdminPrivileges } = this.props;
    if (fleet) {
      return (
        <DropDownMenu
          triggerButtonText={<Translate value="fleets.dropdownToggleText" />}
          items={[
            {
              onClick: this.showInput,
              children: <Translate value="fleets.rename" />,
              hidden: !hasAdminPrivileges,
            },
            {
              onClick: this.delete,
              children: <Translate value="fleets.delete" />,
              hidden: !hasAdminPrivileges,
            },
            {
              onClick: this.toggleProfileAssignmentModal,
              children: <Translate value="fleets.assignProfiles" />,
              hidden: !hasAdminPrivileges,
            },
            {
              onClick: this.toggleFleetDetails,
              children: <Translate value="fleets.fleetDetails" />,
            },
          ]}
        />
      );
    }
  };

  toggleFleetDetails = () =>
    this.setState((prevState) => ({
      areFleetDetailsShown: !prevState.areFleetDetailsShown,
    }));

  renderFleetTitle = (items = []) => {
    if (this.state.isShowRenameInput) {
      return (
        <SubmissionInput
          placeholder={I18n.t('fleets.enterAFleetName')}
          defaultValue={this.props.fleet?.name}
          onSubmit={this.onRename}
          onReset={this.onResetRenameInput}
          showResetIcon
        />
      );
    }
    return (
      <div
        className={'card-header__title'}
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ marginRight: '20px' }}>{this.props.title || ''}</div>
        {this.props.devicesLoadingStatus === NetworkStatus.DONE ? (
          <Fragment>
            <div
              style={{
                marginRight: '8px',
                marginTop: '4px',
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.17',
                letterSpacing: 'normal',
                color: '#727272',
              }}
            >
              {`(${items ? items.length : 0}\xA0Devices)`}
            </div>
            {/*<div>*/}
            {/*    <DeviceHaveUpdatesIcon item={{haveUpdates: items && items.some(({haveUpdates}) => haveUpdates)}}/>*/}
            {/*</div>*/}
          </Fragment>
        ) : (
          ''
        )}
      </div>
    );
  };

  showInput = (e) => {
    this.setState({
      isShowRenameInput: true,
    });
  };

  delete = () => this.props.onRemove();

  onRename = (value) => {
    this.props.onRename(value);
    // this.onResetRenameInput();
  };

  onResetRenameInput = () => {
    this.setState({
      isShowRenameInput: false,
    });
  };
}

FleetDevicesV2.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  fleet: PropTypes.object,
  onRename: PropTypes.func,
  onRemove: PropTypes.func,
  manageable: PropTypes.bool,
  devices: PropTypes.arrayOf(PropTypes.object),
  devicesLoadingStatus: PropTypes.string,
  onSelect: PropTypes.func,
  onToggleSelectAll: PropTypes.func,
  selectable: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    data: profiles,
    status: profilesV1Status,
    error: profilesV1Error,
  } = profilesSelector(state);
  const {
    data: profilesV2,
    status: profilesV2Status,
    error: profilesV2Error,
  } = profilesV2Selector(state);
  const {
    fleetProfilesV2,
    fleetProfilesV2Status,
    fleetProfilesV2Error,

    fleetProfilesV1,
    fleetProfilesV1Status,
    fleetProfilesV1Error,
  } = devicesSelector(state);
  return {
    devicesMoveStatus: state.devices.devicesMoveStatus,
    devicesMoveError: state.devices.devicesMoveError,
    visibleColumns:
      state.columnsToggle.visibleColumnsLists[Pages.FLEET_DEVICES],
    globalPermissionsForUser: globalPermissionsForUserSelector(state),
    // globalPermissionsForUser: globalPermissionsMock,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),

    profiles,
    profilesV2,
    profilesV2Status,

    fleetProfilesV2,
    fleetProfilesV2Status,
    fleetProfilesV2Error,

    fleetProfilesV1,
    fleetProfilesV1Status,
    fleetProfilesV1Error,

    profilesV1Status,
    profilesV2Error,
    profilesV1Error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignDevicesToFleetReset: () => dispatch(assignDevicesToFleetReset()),
    loadProfilesByFleetV2: (params) => dispatch(loadProfilesByFleetV2(params)),
    loadProfilesByFleetV1: (params) => dispatch(loadProfilesByFleetV1(params)),
    loadProfilesV2: (params) => dispatch(loadProfilesV2(params)),
    loadProfilesV1: (params) => dispatch(loadProfiles(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetDevicesV2);
