import React, { Component, Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { NetworkStatus } from '../common/constants';
import Dialog from '../components/Dialog';
import { Button } from 'react-bootstrap';
import ClassNames from 'classnames';
import { UncontrolledCollapse } from 'reactstrap';
import { connect } from 'react-redux';
import EnrollmentDeviceNaming from '../components/Enrollment/enrollmentDeviceNaming';
import {
  loadEnrollment,
  updateEnrollment,
} from '../features/enrollment/enrollment.thunk';
import { DevicesRoutes, RootRoutes } from '../utils/routes';
import { DeviceType } from '../components/Enrollment/DeviceType';
import SelectedFleets from '../components/Enrollment/SelectedFleets';
import SelectedProfiles from '../components/Enrollment/SelectedProfiles';
import QRCodePage from '../components/Enrollment/QRCode.component';
import { companyIdSelector } from '../features/login/login.selectors';
import { Throbber } from '../components/Throbber';
import { selectForCurrentBrand } from '../common/helpers';
import ReadOnlyForm from '../components/ReadOnlyForm/ReadOnlyForm.component';
import { getFleetById } from '../common/utilities/fleets';
import { deviceNamingParameterOptions } from '../constants/deviceNaming';
import UserHelpers from '../common/utilities/user';
import { ReactComponent as Pencil } from '../assets/images/pencil.svg';
import { generateDeviceNamingExample } from '../common/utilities/devices';
import { InputField } from '../components/inputField.component';
import Staging from '../components/Enrollment/Staging.component';
import { profilesSelector } from '../features/profiles/profiles.selectors';
import { loadAllFleetsV2 } from '../features/fleets/fleetsV2.thunk';
import { fleetsV2Selector } from '../features/fleets/fleetsV2.selectors';

const ENROLLMENT_CONSTANTS = {
  SELECTED_FLEETS: 'SELECTED_FLEETS',
  SELECTED_PROFILES: 'SELECTED_PROFILES',
  DEVICE_NAMING: 'DEVICE_NAMING',
  DEVICES_ENROLLED: 'DEVICES_ENROLLED',
  QR_CODE: 'QR_CODE',
  STAGING: 'STAGING',
  AUTHENTICATION: 'AUTHENTICATION',
  DEVICE_TYPE: 'DEVICE_TYPE',
};

export const enrollmentMenuStructure = [
  // {
  //     key: ENROLLMENT_CONSTANTS.DEVICE_TYPE,
  //     title: 'Device Type',
  // },
  {
    key: ENROLLMENT_CONSTANTS.SELECTED_FLEETS,
    title: I18n.t('enrollments.selectedFleet'),
  },
  {
    key: ENROLLMENT_CONSTANTS.SELECTED_PROFILES,
    title: I18n.t('enrollments.selectedProfilesText'),
  },
  {
    key: ENROLLMENT_CONSTANTS.DEVICE_NAMING,
    title: I18n.t('enrollments.deviceNamingSectionTitle'),
  },
  // {
  //     key: ENROLLMENT_CONSTANTS.AUTHENTICATION,
  //     title: '"Authentication',
  // },
  {
    key: ENROLLMENT_CONSTANTS.QR_CODE,
    title: I18n.t('enrollments.QRCode'),
  },
  {
    key: ENROLLMENT_CONSTANTS.STAGING,
    title: I18n.t('enrollments.stagingTabTitle'),
  },
];

export const initialEnrollment = (companyId, fleetId) => ({
  companyId: null,
  nfc: null,
  qr: '',
  url: null,
  deviceNaming: {},
  id: null,
  name: '',
  password: '',
  status: null,
  fleetId: null,
  profiles: [],
  shortId: null,
  deviceType: 'All',
  policies: [],
  devices: [],
  stagingQRCodeData: null,
});

export class Enrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: enrollmentMenuStructure[0].key,
      enrollment: this.initializeEnrollment(props),
      changed: false,
      isEditingMode: false,
    };
  }

  componentDidMount() {
    const {
      enrollmentId,
      loadEnrollment,
      fleetsStatus,
      loadFleets,
      companyId,
    } = this.props;
    if (enrollmentId) {
      loadEnrollment(enrollmentId);
    }
    if (fleetsStatus !== NetworkStatus.DONE) {
      loadFleets(companyId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.enrollment !== nextProps.enrollment &&
      nextProps.enrollment
    ) {
      this.setState({
        enrollment: this.initializeEnrollment(nextProps),
        changed: false,
      });
    }
  }

  componentWillUnmount() {
    // this.props.resetEnrollment();
  }

  initializeEnrollment = ({ enrollment }) => {
    return enrollment ?? initialEnrollment();
  };

  selectCategory = (selectedCategory) => {
    this.setState({ selectedCategory });
  };

  onChangeEnrollmentDetails = (detail, value) => {
    this.setState((prevState) => ({
      enrollment: {
        ...prevState.enrollment,
        [detail]: value,
      },
      changed: true,
    }));
  };

  onChangeFleetId = (detail, value) => {
    if (detail === 'id') {
      this.setState((prevState) => ({
        enrollment: {
          ...prevState.enrollment,
          fleetId: value,
        },
        changed: true,
      }));
    }
  };

  undoChanges = (e) => {
    if (this.state.enrollment.id) {
      if (this.props.enrollment) {
        this.setState({ enrollment: this.props.enrollment, changed: false });
      } else {
        this.props.loadEnrollment(this.state.enrollment.id);
      }
    } else {
      this.setState({ enrollment: initialEnrollment(), changed: false });
    }
  };

  onSave = () => {
    if (this.state.enrollment.id) {
      this.props.updateEnrollment(this.state.enrollment);
    } else {
      // this.props.createEnrollment(this.state.enrollment);
    }
    this.setState({ changed: false });
    this.toggleEditModeOff();
  };

  onBack = () => {
    const { isEditingMode } = this.state;
    const { onClose } = this.props;
    if (isEditingMode) {
      this.toggleEditModeOff();
    } else {
      onClose();
    }
  };

  render() {
    return (
      <div>
        {this.props.status === NetworkStatus.STARTED ? (
          <Throbber />
        ) : this.state.enrollment ? (
          this.renderDialog()
        ) : (
          <div> ... </div>
        )}
      </div>
    );
  }

  renderDialog = () => {
    const { isEditingMode } = this.state;
    return (
      <Dialog
        header={this.renderEnrollmentHeader}
        menu={this.renderEnrollmentMenu}
        content={this.renderEnrollmentContent}
        onBack={this.onBack}
        isFullViewportVisible={isEditingMode}
        backRoute={
          isEditingMode
            ? undefined
            : `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}`
        }
      />
    );
  };

  toggleEditMode = () =>
    this.setState((prevState) => ({
      isEditingMode: !prevState.isEditingMode,
    }));

  toggleEditModeOff = () =>
    this.setState(() => ({
      isEditingMode: false,
      enrollment: this.initializeEnrollment(this.props),
    }));

  confirmEnrollmentEditingModeAndEditAccess = () => {
    const { isEditingMode } = this.state;
    const { hasAdminPrivileges = false } = this.props;
    return isEditingMode && hasAdminPrivileges;
  };

  renderEnrollmentHeader = () => {
    const { hasAdminPrivileges } = this.props;
    const { isEditingMode, enrollment: { name } = {} } = this.state;
    const headerTitle = isEditingMode
      ? I18n.t('enrollments.editingTitle')
      : I18n.t('enrollments.viewingTitle');
    return (
      <Fragment>
        <div className={'profile-header'}>
          <h6 className={'profile-header__title'}>{headerTitle}</h6>
          <div className={'profile-header__caption-text'}>
            {isEditingMode ? (
              <InputField
                value={name}
                name="name"
                onChange={({ target: { name, value } }) =>
                  this.onChangeEnrollmentDetails(name, value)
                }
              />
            ) : (
              <React.Fragment>
                {this.state.enrollment.name || ''}
                {hasAdminPrivileges ? (
                  <Pencil
                    onClick={this.toggleEditMode}
                    className={'device-header-caption-text__ending-icon'}
                  />
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>

        {hasAdminPrivileges && isEditingMode ? (
          <div className={'profile-actions__section'}>
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
              <Button
                secondary
                disabled={!this.state.changed}
                onClick={this.undoChanges}
              >
                {I18n.t('common.undo')}
              </Button>
              <Button
                disabled={!this.state.changed}
                onClick={this.onSave}
                style={{ marginLeft: '5px' }}
              >
                {I18n.t('common.save')}
              </Button>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  };

  renderEnrollmentMenu = () => {
    return (
      <Fragment>{enrollmentMenuStructure.map(this.renderMenuItem)}</Fragment>
    );
  };

  renderMenuItem = (item, index) => {
    const hasSubItems = item.sub && item.sub.length;
    const itemClass = selectForCurrentBrand({
      original: 'profile-menu__item',
      incube: 'profile-menu__item--incube',
      janam: 'profile-menu__item--janam',
    });
    const activeItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--active',
      incube: 'profile-menu__item--incube--active',
      janam: 'profile-menu__item--janam--active',
    });
    const intermediateItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--indeterminate',
      incube: 'profile-menu__item--incube--indeterminate',
      janam: 'profile-menu__item--janam--indeterminate',
    });
    const itemClasses = ClassNames([itemClass], {
      [activeItemClass]: this.state.selectedCategory === item.key,
      [intermediateItemClass]:
        hasSubItems &&
        item.sub.some((subItem) => subItem.key === this.state.selectedCategory),
    });
    const clickHandler = (e) =>
      this.selectCategory(hasSubItems ? item.sub[0].key : item.key);
    return (
      <Fragment>
        <h4
          key={item.key + 'headr' + index}
          id={hasSubItems ? `${item.key}` : undefined}
          className={itemClasses}
          onClick={clickHandler}
        >
          {item.title || ''}
        </h4>
        {hasSubItems && (
          <div
            style={{ marginLeft: '10px' }}
            key={item.key + 'subitems' + index}
          >
            <UncontrolledCollapse toggler={`#${item.key}`}>
              {item.sub.map(this.renderMenuItem)}
            </UncontrolledCollapse>
          </div>
        )}
      </Fragment>
    );
  };

  renderSelectedFleetsPreview = () => {
    const { fleets } = this.props;
    const { enrollment: { fleetId = '' } = {} } = this.state;
    const fleet = getFleetById(fleets, fleetId) || {};
    return [
      {
        value: fleet.name,
      },
    ];
  };

  renderDeviceNamingPreview = () => {
    const {
      enrollment: {
        deviceNaming: { separator, startingNumber, name, sequence = [] } = {},
      } = {},
    } = this.state;
    const sequenceValueList = sequence.reduce((acc, item) => {
      if (item === 'None') {
        return acc;
      }
      const deviceNamingParam = deviceNamingParameterOptions.find(
        (option) => option.value === item
      );
      return [...acc, deviceNamingParam.title];
    }, []);
    return [
      {
        title: I18n.t('enrollments.deviceNamingSeparator'),
        value: separator,
      },
      {
        title: I18n.t('enrollments.deviceCurrentCount'),
        value: startingNumber,
      },
      {
        title: I18n.t('enrollments.deviceNamingSequence'),
        valueList: sequenceValueList,
      },
      {
        title: I18n.t('enrollments.namingExampleHeader'),
        value: generateDeviceNamingExample({
          sequence,
          separator,
          startingNumber,
          name,
        }),
      },
    ];
  };

  /** TODO: replace with the react router switch **/
  renderEnrollmentContent = () => {
    const isEnrollmentEditingModeAndHasEditAccess = this.confirmEnrollmentEditingModeAndEditAccess();
    const enrollment = isEnrollmentEditingModeAndHasEditAccess
      ? this.state.enrollment
      : this.props.enrollment;
    switch (this.state.selectedCategory) {
      case ENROLLMENT_CONSTANTS.DEVICE_TYPE: {
        return (
          <DeviceType
            value={enrollment.deviceType}
            onChange={this.onChangeEnrollmentDetails}
          />
        );
      }
      case ENROLLMENT_CONSTANTS.SELECTED_FLEETS: {
        return isEnrollmentEditingModeAndHasEditAccess ? (
          <SelectedFleets
            value={enrollment.fleetId}
            onChange={this.onChangeFleetId}
          />
        ) : (
          <ReadOnlyForm
            headerTitle={I18n.t('enrollments.selectedFleet')}
            items={this.renderSelectedFleetsPreview()}
          />
        );
      }
      case ENROLLMENT_CONSTANTS.SELECTED_PROFILES: {
        const profiles = [...enrollment.profilesV2, ...enrollment.profiles];
        return (
          <SelectedProfiles
            selected={[]}
            items={profiles}
            onChange={this.onChangeEnrollmentDetails}
          />
        );
      }
      case ENROLLMENT_CONSTANTS.DEVICE_NAMING: {
        return isEnrollmentEditingModeAndHasEditAccess ? (
          <EnrollmentDeviceNaming
            settings={enrollment.deviceNaming}
            onChange={this.onChangeEnrollmentDetails}
          />
        ) : (
          <ReadOnlyForm
            headerTitle={I18n.t('enrollments.deviceNaming')}
            items={this.renderDeviceNamingPreview()}
          />
        );
      }
      case ENROLLMENT_CONSTANTS.AUTHENTICATION: {
        return null;
      }
      case ENROLLMENT_CONSTANTS.QR_CODE: {
        return (
          <QRCodePage
            devices={enrollment.devices || []}
            policies={enrollment.policies || []}
            url={enrollment.url || null}
            id={enrollment.shortId}
          />
        );
      }
      case ENROLLMENT_CONSTANTS.STAGING: {
        return (
          <Staging
            enrollment={enrollment}
            onChange={this.onChangeEnrollmentDetails}
            isEditingMode={isEnrollmentEditingModeAndHasEditAccess}
          />
        );
      }
      default:
        return null;
    }
  };
}

Enrollment.propTypes = {
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { data: fleets, status: fleetsStatus } = fleetsV2Selector(state);
  return {
    enrollment: state.enrollment.data,
    status: state.enrollment.status,
    error: state.enrollment.error,
    companyId: companyIdSelector(state),
    fleets,
    fleetsStatus,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    profiles: profilesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadEnrollment: (enrollmentId) => dispatch(loadEnrollment(enrollmentId)),
    updateEnrollment: (enrollment) => dispatch(updateEnrollment(enrollment)),
    loadFleets: () => dispatch(loadAllFleetsV2()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);
