import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import { Link, NavLink, Redirect, Route, Switch } from 'react-router-dom';

import { ReactComponent as BackspaceArrow } from '../../assets/images/backspace-arrow.svg';
import { Button } from '../button.component';
import { selectForCurrentBrand } from '../../common/helpers';
import { NetworkStatus } from '../../common/constants';
import { Throbber } from '../Throbber';
import { ReactComponent as Crest } from '../../assets/images/crest.svg';
import { ReactComponent as CheckCircleFilled } from '../../assets/images/check-circle-filled.svg';
import { EntityCreationWizardRoutes } from './entityCreationWizard.constant';

class EntityCreationWizard extends Component {
  renderInitialScreen = () => {
    const {
      backArrowText,
      backArrowRoute,
      initialScreenInputFieldLabel,
      initialScreenInputFieldLabelPlaceholder,
      entity = {},
      onProceedClick,
    } = this.props;
    return (
      <div className={'app__content'}>
        <div className={'page-content'}>
          <div className={'creation-wizard'}>
            <div className={'creation-wizard__back-button-section'}>
              <Link
                className={'creation-wizard-back-button__link'}
                to={backArrowRoute}
              >
                <BackspaceArrow
                  className={'creation-wizard-back-button__icon'}
                  alt={''}
                  title={backArrowText}
                  style={{ height: '12px' }}
                />
                <div className={'creation-wizard-back-button__title'}>
                  {backArrowText}
                </div>
              </Link>
            </div>

            <div className={'creation-wizard__content'}>
              <div className={'creation-wizard__input-section'}>
                <label className={'creation-wizard__label'} htmlFor={'name'}>
                  {initialScreenInputFieldLabel}
                </label>
                <input
                  className={'creation-wizard__input'}
                  type="text"
                  id="name"
                  value={entity.name || ''}
                  onChange={this.handleCreationPropertyChange}
                  placeholder={initialScreenInputFieldLabelPlaceholder}
                />
              </div>
              <div className={'creation-wizard__button-section'}>
                <Button
                  className={'creation-wizard__button'}
                  disabled={!entity.name}
                  onClick={onProceedClick}
                >
                  {entity.fleetId ? (
                    <Translate value="common.continue" />
                  ) : (
                    <Translate value="common.getStarted" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleCreationPropertyChange = ({ target: { value, id } = {} }) =>
    this.props.handleCreationPropertyChange({ key: id, value });

  renderSteps = () => {
    const {
      creationStepTitles,
      entity,
      entityCreationRoute = '',
      currentStep,
    } = this.props;
    if (!entity.name || !creationStepTitles.has(`/${currentStep}`)) {
      return <Redirect to={entityCreationRoute} />;
    }

    const barClass = selectForCurrentBrand({
      original: 'creation-wizard-wizard-bar',
      incube: 'creation-wizard-wizard-bar creation-wizard-wizard-bar--incube',
      janam: 'creation-wizard-wizard-bar creation-wizard-wizard-bar--janam',
    });

    return (
      <div className={'dialog-base'}>
        <div className={'dialog-fade'} />
        <div className={'dialog'}>
          <div className={'creation-wizard-wizard'}>
            <div className={barClass}>
              {this.renderCloseButton()}
              <div className="creation-wizard-wizard-bar__stepper-section">
                <div className={'creation-wizard-wizard-stepper'}>
                  {this.renderStepper()}
                </div>
                <span className={'creation-wizard-wizard-bar__title'}>
                  {creationStepTitles.get(`/${currentStep}`) || ''}
                </span>
                {this.renderStepperIcon()}
              </div>
              <div className={'creation-wizard-wizard__close-button'} />
            </div>
            <div className={'creation-wizard-wizard__content'}>
              <div className={'creation-wizard-wizard__inner-content'}>
                {this.renderStepsContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderStepsContent = () => {
    const { entityCreationStatus, steps = [] } = this.props;
    if (entityCreationStatus === NetworkStatus.STARTED) {
      return <Throbber />;
    }
    return (
      <Switch>
        {steps.map(({ routeProps, stepRoute }) => (
          <Route key={stepRoute} {...routeProps} />
        ))}
      </Switch>
    );
  };

  renderStepper = () => {
    const { currentStep } = this.props;
    if (`/${currentStep}` !== EntityCreationWizardRoutes.DONE) {
      const {
        entityCreationStatus,
        steps = [],
        entityCreationRoute,
        entity,
      } = this.props;
      const [initialStep, ...remainingSteps] = steps;
      const firstStep =
        entityCreationStatus !== NetworkStatus.STARTED ? (
          <NavLink
            to={`${entityCreationRoute}${initialStep && initialStep.stepRoute}`}
            className={'create-enrollment-wizard-stepper__step'}
            activeClassName={'create-enrollment-wizard-stepper__step--active'}
          >
            1
          </NavLink>
        ) : (
          <span className={'create-enrollment-wizard-stepper__step'}>1</span>
        );
      let otherSteps;

      if (
        (entity.fleetId || entity.fleets) &&
        entityCreationStatus !== NetworkStatus.STARTED
      ) {
        otherSteps = remainingSteps
          ? remainingSteps.map(({ stepRoute }, index) => (
              <NavLink
                key={stepRoute}
                to={`${entityCreationRoute}${stepRoute}`}
                className={'create-enrollment-wizard-stepper__step'}
                activeClassName={
                  'create-enrollment-wizard-stepper__step--active'
                }
              >
                {index + 2}
              </NavLink>
            ))
          : null;
      } else {
        otherSteps = remainingSteps
          ? remainingSteps.map(({ stepRoute }, index) => (
              <span
                className={'create-enrollment-wizard-stepper__step'}
                key={stepRoute}
              >
                {index + 2}
              </span>
            ))
          : null;
      }

      return (
        <Fragment>
          {firstStep}
          {otherSteps}
        </Fragment>
      );
    }
  };

  renderCloseButton = () => {
    const { currentStep, entityCreationRoute } = this.props;
    if (`/${currentStep}` === EntityCreationWizardRoutes.DONE) {
      return (
        <div
          className={'create-enrollment-wizard__close-button'}
          onClick={this.props.resetEntityCreation}
        >
          <Crest
            className={'create-enrollment-wizard__close-icon icon--white'}
            alt={''}
          />
        </div>
      );
    }

    return (
      <Link
        className={'create-enrollment-wizard__close-button'}
        to={entityCreationRoute}
      >
        <Crest
          className={'create-enrollment-wizard__close-icon icon--white'}
          alt={''}
        />
      </Link>
    );
  };

  renderStepperIcon = () => {
    const { currentStep } = this.props;
    if (`/${currentStep}` === EntityCreationWizardRoutes.DONE) {
      return (
        <span>
          <CheckCircleFilled
            className={'create-enrollment-wizard-bar__icon icon--white'}
            alt={''}
          />
        </span>
      );
    }
  };

  renderStepsRoute = () => (
    <Route
      exact
      path={`${this.props.entityCreationRoute}/:step`}
      render={() => this.renderSteps()}
    />
  );

  render() {
    return (
      <Fragment>
        {this.renderInitialScreen()}
        {this.renderStepsRoute()}
      </Fragment>
    );
  }
}

export default EntityCreationWizard;
