import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { cloneDeep } from 'lodash';
import { Glyphicon } from 'react-bootstrap';
import { DragDropContext } from 'react-beautiful-dnd';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import DragNDropList from '../../../components/DragNDropList/DragNDropList.component';
import DragNDropListItem from '../../../components/DragNDropListItemComponents/DragNDropListItem.component';
import StyleUtils from '../../../utils/styleUtils';
import { SectionDescription } from '../../../components/sectionDescription.component';
import PolicyActivationToggle from './PolicyActivationToggle.component';
import { AttributeType } from '../../../common/constants';
import Helpers, {
  getObjectByValue,
  isDevOrQAEnvironment,
} from '../../../common/helpers';
import { kioskPolicyTypes } from '../profile.constants';
import { SwitchField } from '../../../components/switchField.component';
import { policySettingsValueChangeHandler } from '../../../common/utilities/profile';
import { initialSettings } from './Settings';
import { SelectField } from '../../../components/selectField.component';
import { installOverCellularConditionOptions } from '../../../constants/applications';
import DragNDropApplicationItem from '../../../components/DragNDropListItemComponents/DragNDropApplicationItem.component';

const DragNDropIds = {
  SELECTED: 'selected',
  ITEMS: 'items',
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'app',
  isActive: false,
  policyData: null,
};

const profileAppsMultiCellTextKeyAttributes = [
  {
    key: 'idName',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.packageId',
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'applications.fields.lastUpdated',
  },
  {
    key: 'size',
    type: AttributeType.SIZE,
    titleCode: 'applications.fields.size',
  },
  {
    key: 'versionCode',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.versionCode',
  },
  {
    key: 'version',
    type: AttributeType.APPLICATION_VERSION,
    titleCode: 'applications.fields.version',
  },
];

const allAppsMultiCellTextKeyAttributes = [
  {
    key: 'idName',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.packageId',
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'applications.fields.lastUpdated',
  },
  {
    key: 'size',
    type: AttributeType.SIZE,
    titleCode: 'applications.fields.size',
  },
];

class AppManagement extends Component {
  onChange = (index, key, value) => {
    const { applicationsFullList } = this.props;
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { apps: [], apps_v2: [] };

    let foundIndex = settings.apps_v2?.findIndex(
      ({ idName }) => idName === value
    );
    if (foundIndex === -1 || Helpers.isNull(foundIndex)) {
      const newApp = applicationsFullList.find(
        ({ idName }) => idName === value
      );
      settings.apps_v2 = [...(cloneDeep(settings.apps_v2) ?? []), newApp];
    } else {
      settings.apps_v2.splice(foundIndex, 1);

      // sync with kiosk builder
      const kioskPolicy = this.props.policies?.kiosk?.[0] ?? {};
      let kioskPolicyClone = cloneDeep(kioskPolicy);
      const {
        settings: kioskSettings = {},
        settings: {
          apps: kioskApps = [],
          autolaunchApp: kioskAutoLaunchApp = {},
          whitelisted: kioskWhitelisted = [],
        } = {},
      } = kioskPolicyClone;
      const kioskApp = kioskApps.find(({ idName }) => idName === value);
      const kioskWhitelistedApp = kioskWhitelisted.find(
        ({ idName }) => idName === value
      );
      const isKioskAutoLaunchApp = kioskAutoLaunchApp.idName === value;
      if (kioskApp || kioskWhitelistedApp || isKioskAutoLaunchApp) {
        kioskPolicyClone = {
          ...kioskPolicyClone,
          settings: {
            ...kioskSettings,
            ...(isKioskAutoLaunchApp
              ? { apps: kioskApps.filter(({ idName }) => idName !== value) }
              : {}),
            ...(kioskWhitelistedApp
              ? {
                  whitelisted: kioskWhitelisted.filter(
                    ({ idName }) => idName !== value
                  ),
                }
              : {}),
            ...(isKioskAutoLaunchApp ? { autolaunchApp: undefined } : {}),
          },
        };
        this.props.onChange(kioskPolicyTypes.KIOSK, 0, kioskPolicyClone);
      }
    }

    settings[key] = settings.apps_v2.map(({ id }) => id);

    item.settings = settings;
    this.props.onChange('apps', index, item);
  };

  onApplicationVersionChange = ({ value: newVersion, idName: newIdName }) => {
    const { applicationsFullList } = this.props;
    const app =
      applicationsFullList.find(
        ({ idName: appIdName }) => appIdName === newIdName
      ) ?? {};
    const appVersion = app.versions?.find(
      ({ version }) => newVersion === version
    );
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[0]
        ? cloneDeep({ ...policy[0] })
        : { ...initialPolicy };
    const settings = item.settings
      ? cloneDeep(item.settings)
      : { apps: [], apps_v2: [] };

    const selectedIdNames = settings.apps_v2?.map(({ idName }) => idName) ?? [];
    const foundIndex = selectedIdNames.findIndex((name) => newIdName === name);
    const previouslySelectedApps = cloneDeep(settings.apps_v2) ?? [];
    previouslySelectedApps.splice(foundIndex, 1, {
      ...appVersion,
      versions: app.versions,
    });
    settings.apps_v2 = previouslySelectedApps;
    settings.apps = settings.apps_v2.map(({ id }) => id);
    item.settings = settings;
    this.props.onChange('apps', 0, item);

    // sync with kiosk builder
    const kioskPolicy = this.props.policies?.kiosk?.[0] ?? {};
    const {
      settings: kioskSettings = {},
      settings: {
        apps: kioskApps = [],
        autolaunchApp: kioskAutoLaunchApp = {},
        whitelisted: kioskWhitelisted = [],
      } = {},
    } = kioskPolicy;
    const appInKioskAppsIndex = kioskApps.findIndex(
      ({ idName }) => idName === newIdName
    );
    const appInKioskWhiteListedIndex = kioskWhitelisted.findIndex(
      ({ idName }) => idName === newIdName
    );
    const isKioskAutoLaunchApp = newIdName === kioskAutoLaunchApp.idName;

    if (
      appInKioskAppsIndex > -1 ||
      appInKioskWhiteListedIndex > -1 ||
      isKioskAutoLaunchApp
    ) {
      let kioskPolicyClone = cloneDeep(kioskPolicy);

      let newSettings = {};

      if (appInKioskAppsIndex > -1) {
        const kioskAppsClone = cloneDeep(kioskApps);
        kioskAppsClone.splice(appInKioskAppsIndex, 1, { ...appVersion });
        newSettings = {
          ...newSettings,
          apps: kioskAppsClone,
        };
      }

      if (appInKioskWhiteListedIndex > -1) {
        const whiteListedAppsClone = cloneDeep(kioskWhitelisted);
        whiteListedAppsClone.splice(appInKioskWhiteListedIndex, 1, {
          ...appVersion,
        });
        newSettings = {
          ...newSettings,
          whitelisted: whiteListedAppsClone,
        };
      }

      if (isKioskAutoLaunchApp) {
        newSettings = {
          ...newSettings,
          autolaunchApp: { ...appVersion },
        };
      }

      kioskPolicyClone = {
        ...kioskPolicyClone,
        settings: {
          ...kioskSettings,
          ...newSettings,
        },
      };
      this.props.onChange(kioskPolicyTypes.KIOSK, 0, kioskPolicyClone);
    }

    // sync with blacklist
    const blacklistPolicy = this.props.policies?.applist?.[0] ?? {};
    const {
      settings: blacklistSettings = {},
      settings: { apps: blacklistApps = [] } = {},
    } = blacklistPolicy;
    const appInBlacklistedAppsIndex = blacklistApps.findIndex(
      ({ idName }) => idName === newIdName
    );
    if (appInBlacklistedAppsIndex > -1) {
      const blacklistPolicyClone = cloneDeep(blacklistPolicy);
      let blacklistAppsClone = cloneDeep(blacklistApps);
      blacklistAppsClone.splice(appInKioskAppsIndex, 1, { ...appVersion });
      const newSettings = {
        apps: blacklistAppsClone,
      };
      blacklistAppsClone = {
        ...blacklistPolicyClone,
        settings: {
          ...blacklistSettings,
          ...newSettings,
        },
      };
      this.props.onChange('applist', 0, blacklistAppsClone);
    }
  };

  onValueChange = ({ value, key, isValueBoolean = false }) =>
    policySettingsValueChangeHandler({
      key,
      value,
      initialPolicy,
      initialSettings,
      isValueBoolean,
      onChange: this.props.onChange,
      policy: this.props.policy,
      policyDetailKey: 'apps',
    });

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    const expression = new RegExp(`^${source.droppableId}_`);
    const itemId = draggableId.replace(expression, '');

    // dropped outside the list
    if (
      source.droppableId === DragNDropIds.SELECTED &&
      (!destination || source.droppableId !== destination.droppableId)
    ) {
      this.onChange(0, 'apps', itemId);
    }
    if (
      source.droppableId === DragNDropIds.ITEMS &&
      destination &&
      destination.droppableId === DragNDropIds.SELECTED
    ) {
      this.onChange(0, 'apps', itemId);
    }
  };

  prepareSelectedAppsList = (apps) => {
    const { applicationsFullList } = this.props;
    const appsFullList = applicationsFullList.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.idName]: curr,
      }),
      {}
    );

    return apps.map((app) => {
      const appVersions = appsFullList[app.idName]?.versions;
      return {
        ...app,
        ...(appVersions ? { versions: appVersions } : {}),
      };
    });
  };

  renderSettings = ({ settings, isActive }, index) => {
    const selectedApps = this.prepareSelectedAppsList(settings?.apps_v2 ?? []);
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      this.props.onChangePolicyProperty({
        key: 'apps',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
      });

    return (
      <div className={'app-management-wrapper'}>
        <SectionDescription
          title={I18n.t('profiles.apps.policyTitle')}
          hint={I18n.t('profiles.apps.policyTitleHint')}
        />
        <PolicyActivationToggle
          key="policyActivation"
          policy={this.props.policy}
          titleCode="profiles.apps.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
        />
        {isActive ? (
          <div className={'app-management-wrapper-content'}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className={'app-management'}>
                <SectionDescription
                  title={I18n.t('profiles.apps.profileAppsTitle')}
                  isSmall
                />
                <div className={'app-management-list'}>
                  <DragNDropList
                    droppableListId={DragNDropIds.SELECTED}
                    items={selectedApps}
                    hasNoBorders
                    hasNoContentPadding
                    itemComponent={DragNDropApplicationItem}
                    idSubstitute="idName"
                    itemOptions={{
                      multiCellTextKeyAttributes: profileAppsMultiCellTextKeyAttributes,
                      textContainerAdditionalClassName:
                        'app-management-list__text-container',
                      onApplicationVersionChange: this
                        .onApplicationVersionChange,
                    }}
                    listHeader={
                      <div className={'app-management-list-header'}>
                        {profileAppsMultiCellTextKeyAttributes.map(
                          ({ titleCode }) => (
                            <div
                              className={StyleUtils.mergeClasses(
                                'app-management-list-header__title',
                                'app-management-list__text-container'
                              )}
                            >
                              {I18n.t(titleCode)}
                            </div>
                          )
                        )}
                      </div>
                    }
                    emptyListContent={
                      <div
                        className={
                          'app-management-list-content-empty-notification'
                        }
                      >
                        <Glyphicon
                          className={
                            'app-management-list-content-empty-notification__icon'
                          }
                          glyph="cloud-upload"
                        />
                        <div
                          className={
                            'app-management-list-content-empty-notification__description'
                          }
                        >
                          {I18n.t('profiles.apps.noAppsAttachedToProfile')}
                          <br />
                          {I18n.t(
                            'profiles.apps.howToAddAppsRecommendationText'
                          )}
                        </div>
                      </div>
                    }
                  />
                  {isDevOrQAEnvironment() ? (
                    <div className="app-management__network-settings">
                      <SectionDescription
                        title={I18n.t(
                          'profiles.apps.networkRestrictionsSectionTitle'
                        )}
                        isSmall
                      />
                      <SwitchField
                        onClick={(e) =>
                          this.onValueChange({
                            index,
                            key: 'installOverWiFi',
                            isValueBoolean: true,
                          })
                        }
                        on={settings.installOverWiFi}
                        title={I18n.t('profiles.apps.installOverWiFiTitle')}
                      />
                      <SwitchField
                        onClick={(e) =>
                          this.onValueChange({
                            index,
                            key: 'installOverCellular',
                            isValueBoolean: true,
                          })
                        }
                        on={settings.installOverCellular}
                        title={I18n.t('profiles.apps.installOverCellularTitle')}
                      />
                      {settings.installOverCellular ? (
                        <SelectField
                          title={I18n.t(
                            'profiles.apps.installOverCellularConditionTitle'
                          )}
                          options={installOverCellularConditionOptions}
                          onChange={(e) =>
                            this.onValueChange({
                              index,
                              key: 'installOverCellularCondition',
                              value: e.target.value,
                            })
                          }
                          value={settings?.installOverCellularCondition}
                        />
                      ) : null}
                      <SwitchField
                        onClick={(e) =>
                          this.onValueChange({
                            index,
                            key: 'installOverLAN',
                            isValueBoolean: true,
                          })
                        }
                        on={settings.installOverLAN}
                        title={I18n.t('profiles.apps.installOverLANTitle')}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={'app-management-source-section'}>
                <div
                  className={
                    'app-management-source-section-section-description-container'
                  }
                >
                  <div className={'section-description'}>
                    <div className={'section-description__title--small'}>
                      {I18n.t('profiles.apps.myApplicationsTitle')}
                    </div>
                    <div> </div>
                  </div>
                </div>
                <div className={'app-management-source-section-list'}>
                  <DragNDropList
                    listHeader={
                      <div
                        className={'app-management-source-section-list-header'}
                      >
                        {allAppsMultiCellTextKeyAttributes.map(
                          ({ titleCode }) => (
                            <div
                              className={StyleUtils.mergeClasses(
                                'app-management-source-section-list-header-title',
                                'app-management-list__text-container'
                              )}
                            >
                              {I18n.t(titleCode)}
                            </div>
                          )
                        )}
                      </div>
                    }
                    idSubstitute="idName"
                    droppableListId={DragNDropIds.ITEMS}
                    items={this.props.applicationsFullList.filter(
                      (app) =>
                        !selectedApps.find((idName) => idName === app.idName)
                    )}
                    itemComponent={DragNDropListItem}
                    itemOptions={{
                      multiCellTextKeyAttributes: allAppsMultiCellTextKeyAttributes,
                      textContainerAdditionalClassName:
                        'app-management-list__text-container',
                    }}
                    hasNoContentPadding
                  />
                </div>
              </div>
            </DragDropContext>
          </div>
        ) : null}
      </div>
    );
  };

  renderContent = () => {
    return this.props.policy.length
      ? this.renderSettings(this.props.policy[0], 0)
      : this.renderSettings({ ...initialPolicy, settings: { apps: [] } }, 0);
  };

  getApps = () => {
    const { policy: [{ settings: { apps_v2 } = {} } = {}] = [] } = this.props;
    return apps_v2;
  };

  renderReadOnlyContent = () => {
    const { policy } = this.props;
    const { isActive, settings } = policy[0] ?? {};
    const { title: installOverCellularConditionTitle } = getObjectByValue(
      installOverCellularConditionOptions,
      settings?.installOverCellularCondition
    );
    const items = [
      {
        id: 'policy-status',
        title: I18n.t('profiles.apps.policyStatusTitle'),
        value: isActive,
        type: AttributeType.BOOLEAN,
      },
      ...(isActive
        ? [
            ...this.getApps().map(({ idName, version, id }) => ({
              title: idName,
              value: version,
              id,
            })),
            ...(isDevOrQAEnvironment()
              ? [
                  {
                    title: I18n.t(
                      'profiles.apps.networkRestrictionsSectionTitle'
                    ),
                    type: AttributeType.SUBHEADING,
                    key: 'networkRestrictionsSectionTitle',
                    isSmall: true,
                  },
                  {
                    title: I18n.t('profiles.apps.installOverWiFiTitle'),
                    type: AttributeType.BOOLEAN,
                    id: 'installOverWiFi',
                    value: settings.installOverWiFi,
                  },
                  {
                    title: I18n.t('profiles.apps.installOverCellularTitle'),
                    type: AttributeType.BOOLEAN,
                    id: 'installOverCellular',
                    value: settings.installOverCellular,
                  },
                  {
                    title: I18n.t(
                      'profiles.apps.installOverCellularConditionTitle'
                    ),
                    type: AttributeType.TEXT,
                    id: 'installOverCellularCondition',
                    value: installOverCellularConditionTitle,
                  },
                  {
                    title: I18n.t('profiles.apps.installOverLANTitle'),
                    type: AttributeType.BOOLEAN,
                    id: 'installOverLAN',
                    value: settings.installOverLAN,
                  },
                ]
              : []),
          ]
        : []),
    ];
    return (
      <ReadOnlyForm
        items={items}
        headerTitle={I18n.t('profiles.apps.autoInstalledAppsTitle')}
        emptyListMessage={I18n.t('profiles.apps.noAutoInstalledAppsText')}
      />
    );
  };

  render() {
    const { isEditingMode } = this.props;
    return (
      <div className={'app-management-wrapper-container'}>
        {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
      </div>
    );
  }
}

export default AppManagement;

AppManagement.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
