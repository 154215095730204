import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import GeoJSON from 'ol/format/GeoJSON';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import * as AppInfoParser from 'app-info-parser';
import { cloneDeep } from 'lodash';
import { AttributeType, NetworkStatus } from './constants';
import {
  currentBrand,
  DEV_AND_QA_ENVIRONMENTS,
  DEV_ENVIRONMENTS,
} from '../utils/env';
import { filterConditions } from '../constants/filter';

export const parseAppInfo = (appFile) => {
  const parser = new AppInfoParser(appFile);
  return new Promise((resolve) => {
    resolve(parser.parse());
  });
};

export async function resolveMockData(data) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (data !== undefined) {
        resolve({ status: 200, data });
      } else {
        reject({ status: 500 });
      }
    }, 3500);
  });
}

export function bytesToSize(bytes, decimals = 2) {
  if (!bytes || isNaN(Number(bytes))) return '0 Bytes';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const kSize = 1024;
  let dm = decimals <= 0 ? 0 : decimals;
  let sizeIndex = Math.floor(Math.log(bytes) / Math.log(kSize));
  return `${parseFloat((bytes / Math.pow(kSize, sizeIndex)).toFixed(dm))} ${
    sizes[sizeIndex]
  }`;
}

export function coordinatesDDToDMS(lat, long) {
  if (!lat || !long) {
    return I18n.t('common.noLocationData');
  }
  const latAbs = Math.abs(lat);
  const latDegrees = Math.floor(latAbs);
  const latExtendedMinutes = (latAbs - latDegrees) * 60;
  const latMinutes = Math.floor(latExtendedMinutes);
  const latSeconds = Math.floor((latExtendedMinutes - latMinutes) * 60);
  const latDMS = `${latDegrees}°${latMinutes}'${latSeconds}"`;
  const latCardinal = Math.sign(lat) >= 0 ? 'N' : 'S';
  const longAbs = Math.abs(long);
  const longDegrees = Math.floor(longAbs);
  const longExtendedMinutes = (longAbs - longDegrees) * 60;
  const longMinutes = Math.floor(longExtendedMinutes);
  const longSeconds = Math.floor((longExtendedMinutes - longMinutes) * 60);
  const longDMS = `${longDegrees}°${longMinutes}'${longSeconds}"`;
  const longCardinal = Math.sign(long) >= 0 ? 'E' : 'W';

  return `${latDMS}${latCardinal}, ${longDMS}${longCardinal}`;
}

export const isString = (value) => typeof value === 'string';

export function generateHEXColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function downloadFile(
  data,
  fileName = 'attachment',
  mimeType = 'application/octet-stream'
) {
  // IE10 download
  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(new Blob([data], { type: mimeType }), fileName);
  }

  const a = document.createElement('a');
  const dataSource = `data:${mimeType},${encodeURIComponent(data)}`;

  // HTML5 download
  if ('download' in a) {
    a.href = dataSource;
    a.setAttribute('download', fileName);
    a.innerHTML = 'downloading...';
    document.body.appendChild(a);
    setTimeout(function () {
      a.click();
      document.body.removeChild(a);
    }, 66);
    return true;
  }

  // do iframe dataURL download (old ch+FF):
  const f = document.createElement('iframe');
  document.body.appendChild(f);
  f.src = dataSource;

  setTimeout(function () {
    document.body.removeChild(f);
  }, 333);
  return true;
}

export const getTimeDifference = (time, unitOfTime = 'hours') => {
  const offsetPoint = moment(time);
  if (!offsetPoint.isValid()) return;
  const now = moment();
  return now.diff(offsetPoint, unitOfTime);
};

// todo: refactor! why? because it has duplicate code for strict and non-strict filters
// also add valueGetter support. Because of this, filtering custom values wont work
export function filterItemsByAttributes(
  items = [],
  attributes = [],
  strictness = 'notStrict',
  filters = []
) {
  if (!filters.length) {
    return items;
  }
  return items.filter((item) => {
    if (strictness === 'strict') {
      return filters.every((filter) => {
        if (item[filter.attribute] != null) {
          let attributeData = attributes.find(
            (attribute) =>
              attribute.key === filter.attribute && attribute.searchable
          );
          if (attributeData && attributeData.type === AttributeType.TEXT) {
            switch (filter.condition) {
              case filterConditions.CONTAINS: {
                return `${item[filter.attribute]}`
                  .toUpperCase()
                  .includes(filter.value.toUpperCase());
              }
              case filterConditions.DOES_NOT_CONTAIN: {
                return !`${item[filter.attribute]}`
                  .toUpperCase()
                  .includes(filter.value.toUpperCase());
              }
              case filterConditions.BEGINS_WITH: {
                return `${item[filter.attribute]}`
                  .toUpperCase()
                  .startsWith(filter.value.toUpperCase());
              }
              case filterConditions.DOES_NOT_BEGIN_WITH: {
                return !`${item[filter.attribute]}`
                  .toUpperCase()
                  .startsWith(filter.value.toUpperCase());
              }
              case filterConditions.ENDS_WITH: {
                return `${item[filter.attribute]}`
                  .toUpperCase()
                  .endsWith(filter.value.toUpperCase());
              }
              case filterConditions.DOES_NOT_END_WITH: {
                return !`${item[filter.attribute]}`
                  .toUpperCase()
                  .endsWith(filter.value.toUpperCase());
              }
              case filterConditions.EQUAL: {
                return (
                  `${item[filter.attribute]}`.toUpperCase().trim() ===
                  filter.value.toUpperCase().trim()
                );
              }
              case filterConditions.NOT_EQUAL: {
                return !`${item[filter.attribute]}`
                  .toUpperCase()
                  .includes(filter.value.toUpperCase());
              }
              default:
                return '';
            }
          }
          if (attributeData?.type === AttributeType.BOOLEAN) {
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                if (filter.value) {
                  return item[filter.attribute];
                } else {
                  return !item[filter.attribute];
                }
              }
              case filterConditions.NOT_EQUAL: {
                if (filter.value) {
                  return !item[filter.attribute];
                } else {
                  return item[filter.attribute];
                }
              }
              default:
                return false;
            }
          }
          if (
            attributeData &&
            attributeData.type === AttributeType.ENUMERABLE &&
            attributeData.availableOptions &&
            attributeData.availableOptions.length
          ) {
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                return (
                  `${item[filter.attribute]}`.toUpperCase() ===
                  filter.value.toUpperCase()
                );
              }
              case filterConditions.NOT_EQUAL: {
                return (
                  `${item[filter.attribute]}`.toUpperCase() !==
                  filter.value.toUpperCase()
                );
              }
              default:
                return '';
            }
          }
          if (
            attributeData &&
            (attributeData.type === AttributeType.PERCENTAGE ||
              attributeData.type === AttributeType.SIZE) &&
            !isNaN(Number(filter.value))
          ) {
            let value = Number(filter.value);
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                return Number(item[filter.attribute]) === value;
              }
              case filterConditions.GREATER_THAN: {
                return Number(item[filter.attribute]) > value;
              }
              case filterConditions.LESS_THAN: {
                return Number(item[filter.attribute]) < value;
              }
              case filterConditions.GREATER_THAN_OR_EQUAL: {
                return Number(item[filter.attribute]) >= value;
              }
              case filterConditions.LESS_THAN_OR_EQUAL: {
                return Number(item[filter.attribute]) <= value;
              }
              case filterConditions.NOT_EQUAL: {
                return Number(item[filter.attribute]) !== value;
              }
              default:
                return 0;
            }
          }
          if (
            attributeData &&
            attributeData.type === AttributeType.TIME_OFFSET &&
            moment(
              item[filter.customParams?.customValueKey ?? filter.attribute]
            ).isValid() &&
            filter.value &&
            !isNaN(Number(filter.value))
          ) {
            const timeDifference = getTimeDifference(
              item[filter.customParams?.customValueKey ?? filter.attribute],
              filter.filterValueType
            );
            let value = Number(filter.value);
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                return Number(timeDifference) === value;
              }
              case filterConditions.GREATER_THAN: {
                return Number(timeDifference) > value;
              }
              case filterConditions.LESS_THAN: {
                return Number(timeDifference) < value;
              }
              case filterConditions.GREATER_THAN_OR_EQUAL: {
                return Number(timeDifference) >= value;
              }
              case filterConditions.LESS_THAN_OR_EQUAL: {
                return Number(timeDifference) <= value;
              }
              case filterConditions.NOT_EQUAL: {
                return Number(timeDifference) !== value;
              }
              default:
                return 0;
            }
          }
          if (
            attributeData &&
            attributeData.type === AttributeType.DATE &&
            filter.value &&
            !isNaN(Number(filter.value)) &&
            moment(Number(filter.value)).isValid() &&
            moment(item[filter.attribute]).isValid()
          ) {
            let value = moment(Number(filter.value));
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                return (
                  moment(item[filter.attribute]).format('YYYY-MM-DD') ===
                  moment(value).format('YYYY-MM-DD')
                );
              }
              case filterConditions.GREATER_THAN: {
                return moment(item[filter.attribute]).isAfter(moment(value));
              }
              case filterConditions.LESS_THAN: {
                return moment(item[filter.attribute]).isBefore(moment(value));
              }
              case filterConditions.GREATER_THAN_OR_EQUAL: {
                return moment(item[filter.attribute]).isSameOrAfter(
                  moment(value)
                );
              }
              case filterConditions.LESS_THAN_OR_EQUAL: {
                return moment(item[filter.attribute]).isSameOrBefore(
                  moment(value)
                );
              }
              default:
                return (
                  moment(item[filter.attribute]).format('YYYY-MM-DD') ===
                  moment(value).format('YYYY-MM-DD')
                );
            }
          }
          // if (attributeData && attributeData.type === AttributeType.BOOLEAN && filter.value) {
          //     let {value, attribute} = filter;
          //     switch (filter.condition) {
          //         case filterConditions.EQUAL: {
          //             return item[attribute] && value === 'Yes' || !item[attribute] && value === 'No';
          //         }
          //         case filterConditions.NOT_EQUAL: {
          //             return !item[attribute] && value === 'Yes' || item[attribute] && value === 'No';
          //         }
          //     }
          // }
          if (
            attributeData &&
            attributeData.type === AttributeType.COORDINATES &&
            item[filter.attribute] &&
            (!isNaN(Number(item[filter.attribute].latitude)) ||
              !isNaN(Number(item[filter.attribute].longitude)))
          ) {
            if (
              filter.value &&
              filter.value.length &&
              !isNaN(
                Number(item[filter.attribute].latitude) &&
                  !isNaN(Number(item[filter.attribute].longitude))
              )
            ) {
              let features;
              let location;
              try {
                features = new GeoJSON().readFeatures(filter.value);
                location = new Point(
                  fromLonLat([
                    item[filter.attribute].longitude,
                    item[filter.attribute].latitude,
                  ])
                );
              } catch (e) {
                console.warn(
                  'Some of geospatial filters is incorrect and unaffected the result!'
                );
              }
              if (features && features.length && location) {
                const isContainedIn = features[0]
                  .getGeometry()
                  .intersectsCoordinate(location.getCoordinates());
                switch (filter.condition) {
                  case filterConditions.CONTAINS: {
                    return isContainedIn;
                  }
                  case filterConditions.NOT_CONTAINED_IN: {
                    return !isContainedIn;
                  }
                  default:
                    return !isContainedIn;
                }
              }
              return true;
            } else {
              return true;
            }
          }
        }
        return false;
      });
    } else {
      return filters.some((filter) => {
        if (item[filter.attribute] != null) {
          let attributeData = attributes.find(
            (attribute) =>
              attribute.key === filter.attribute && attribute.searchable
          );
          if (attributeData && attributeData.type === AttributeType.TEXT) {
            switch (filter.condition) {
              case filterConditions.CONTAINS: {
                return `${item[filter.attribute]}`
                  .toUpperCase()
                  .includes(filter.value.toUpperCase());
              }
              case filterConditions.DOES_NOT_CONTAIN: {
                return !`${item[filter.attribute]}`
                  .toUpperCase()
                  .includes(filter.value.toUpperCase());
              }
              case filterConditions.BEGINS_WITH: {
                return `${item[filter.attribute]}`
                  .toUpperCase()
                  .startsWith(filter.value.toUpperCase());
              }
              case filterConditions.DOES_NOT_BEGIN_WITH: {
                return !`${item[filter.attribute]}`
                  .toUpperCase()
                  .startsWith(filter.value.toUpperCase());
              }
              case filterConditions.ENDS_WITH: {
                return `${item[filter.attribute]}`
                  .toUpperCase()
                  .endsWith(filter.value.toUpperCase());
              }
              case filterConditions.DOES_NOT_END_WITH: {
                return !`${item[filter.attribute]}`
                  .toUpperCase()
                  .endsWith(filter.value.toUpperCase());
              }
              case filterConditions.EQUAL: {
                return (
                  `${item[filter.attribute]}`.toUpperCase().trim() ===
                  filter.value.toUpperCase().trim()
                );
              }
              case filterConditions.NOT_EQUAL: {
                return !`${item[filter.attribute]}`
                  .toUpperCase()
                  .includes(filter.value.toUpperCase());
              }
              default:
                return '';
            }
          }
          if (attributeData?.type === AttributeType.BOOLEAN) {
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                if (filter.value) {
                  return item[filter.attribute];
                } else {
                  return !item[filter.attribute];
                }
              }
              case filterConditions.NOT_EQUAL: {
                if (filter.value) {
                  return !item[filter.attribute];
                } else {
                  return item[filter.attribute];
                }
              }
              default:
                return false;
            }
          }
          if (
            attributeData &&
            attributeData.type === AttributeType.ENUMERABLE &&
            attributeData.availableOptions &&
            attributeData.availableOptions.length
          ) {
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                return (
                  `${item[filter.attribute]}`.toUpperCase() ===
                  filter.value.toUpperCase()
                );
              }
              case filterConditions.NOT_EQUAL: {
                return (
                  `${item[filter.attribute]}`.toUpperCase() !==
                  filter.value.toUpperCase()
                );
              }
              default:
                return '';
            }
          }
          if (
            attributeData &&
            (attributeData.type === AttributeType.PERCENTAGE ||
              attributeData.type === AttributeType.SIZE) &&
            !isNaN(Number(filter.value))
          ) {
            let value = Number(filter.value);
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                return Number(item[filter.attribute]) === value;
              }
              case filterConditions.GREATER_THAN: {
                return Number(item[filter.attribute]) > value;
              }
              case filterConditions.LESS_THAN: {
                return Number(item[filter.attribute]) < value;
              }
              case filterConditions.GREATER_THAN_OR_EQUAL: {
                return Number(item[filter.attribute]) >= value;
              }
              case filterConditions.LESS_THAN_OR_EQUAL: {
                return Number(item[filter.attribute]) <= value;
              }
              case filterConditions.NOT_EQUAL: {
                return Number(item[filter.attribute]) !== value;
              }
              default:
                return 0;
            }
          }
          if (
            attributeData &&
            attributeData.type === AttributeType.TIME_OFFSET &&
            moment(
              item[filter.customParams?.customValueKey ?? filter.attribute]
            ).isValid() &&
            filter.value &&
            !isNaN(Number(filter.value))
          ) {
            const timeDifference = getTimeDifference(
              item[filter.customParams?.customValueKey ?? filter.attribute],
              filter.filterValueType
            );
            let value = Number(filter.value);
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                return Number(timeDifference) === value;
              }
              case filterConditions.GREATER_THAN: {
                return Number(timeDifference) > value;
              }
              case filterConditions.LESS_THAN: {
                return Number(timeDifference) < value;
              }
              case filterConditions.GREATER_THAN_OR_EQUAL: {
                return Number(timeDifference) >= value;
              }
              case filterConditions.LESS_THAN_OR_EQUAL: {
                return Number(timeDifference) <= value;
              }
              case filterConditions.NOT_EQUAL: {
                return Number(timeDifference) !== value;
              }
              default:
                return 0;
            }
          }
          if (
            attributeData &&
            attributeData.type === AttributeType.DATE &&
            filter.value &&
            !isNaN(Number(filter.value)) &&
            moment(Number(filter.value)).isValid() &&
            moment(item[filter.attribute]).isValid()
          ) {
            let value = moment(Number(filter.value));
            switch (filter.condition) {
              case filterConditions.EQUAL: {
                return (
                  moment(item[filter.attribute]).format('YYYY-MM-DD') ===
                  moment(value).format('YYYY-MM-DD')
                );
              }
              case filterConditions.GREATER_THAN: {
                return moment(item[filter.attribute]).isAfter(moment(value));
              }
              case filterConditions.LESS_THAN: {
                return moment(item[filter.attribute]).isBefore(moment(value));
              }
              case filterConditions.GREATER_THAN_OR_EQUAL: {
                return moment(item[filter.attribute]).isSameOrAfter(
                  moment(value)
                );
              }
              case filterConditions.LESS_THAN_OR_EQUAL: {
                return moment(item[filter.attribute]).isSameOrBefore(
                  moment(value)
                );
              }
              default:
                return (
                  moment(item[filter.attribute]).format('YYYY-MM-DD') ===
                  moment(value).format('YYYY-MM-DD')
                );
            }
          }
          // if (attributeData && attributeData.type === AttributeType.BOOLEAN && filter.value) {
          //     let {value, attribute} = filter;
          //     switch (filter.condition) {
          //         case filterConditions.EQUAL: {
          //             return item[attribute] && value === 'Yes' || !item[attribute] && value === 'No';
          //         }
          //         case filterConditions.NOT_EQUAL: {
          //             return !item[attribute] && value === 'Yes' || item[attribute] && value === 'No';
          //         }
          //     }
          // }
          if (
            attributeData &&
            attributeData.type === AttributeType.COORDINATES &&
            item[filter.attribute] &&
            (!isNaN(Number(item[filter.attribute].latitude)) ||
              !isNaN(Number(item[filter.attribute].longitude)))
          ) {
            if (
              filter.value &&
              filter.value.length &&
              !isNaN(
                Number(item[filter.attribute].latitude) &&
                  !isNaN(Number(item[filter.attribute].longitude))
              )
            ) {
              let features;
              let location;
              try {
                features = new GeoJSON().readFeatures(filter.value);
                location = new Point(
                  fromLonLat([
                    item[filter.attribute].longitude,
                    item[filter.attribute].latitude,
                  ])
                );
              } catch (e) {
                console.warn(
                  'Some of geospatial filters is incorrect and unaffected the result!'
                );
              }
              if (features && features.length && location) {
                const isContainedIn = features[0]
                  .getGeometry()
                  .intersectsCoordinate(location.getCoordinates());
                switch (filter.condition) {
                  case filterConditions.CONTAINS: {
                    return isContainedIn;
                  }
                  case filterConditions.NOT_CONTAINED_IN: {
                    return !isContainedIn;
                  }
                  default:
                    return !isContainedIn;
                }
              }
              return true;
            } else {
              return true;
            }
          }
        }
        return false;
      });
    }
  });
}

export function sortItemsByAttribute({
  items = [],
  attributes = [],
  sortedAttributeKey,
  order,
}) {
  if (!attributes.length || !sortedAttributeKey || !order) {
    return items;
  }

  const attribute = attributes.find(
    (attribute) =>
      attribute.key === sortedAttributeKey &&
      (attribute.searchable || attribute.sortable)
  );

  if (!attribute) return items;

  const valueKey = attribute.customParams?.customValueKey ?? sortedAttributeKey;

  return [...items].sort(function (currentItem, nextItem) {
    const currentValue = currentItem[valueKey];
    const nextValue = nextItem[valueKey];
    if (currentValue == null || nextValue == null) {
      if (order === 'ASC') {
        return currentValue == null && nextValue !== null
          ? -1
          : currentValue != null && nextValue == null
          ? 1
          : 0;
      } else {
        return currentValue == null && nextValue !== null
          ? 1
          : currentValue != null && nextValue == null
          ? -1
          : 0;
      }
    }
    switch (attribute.type) {
      case AttributeType.BOOLEAN: {
        if (order === 'ASC') {
          // true values first
          return currentValue === nextValue ? 0 : currentValue ? -1 : 1;
        }
        // false values first
        return currentValue === nextValue ? 0 : currentValue ? 1 : -1;
      }
      case AttributeType.ENUMERABLE:
      case AttributeType.TEXT: {
        const formattedCurrentValue = isString(currentValue)
          ? currentValue.toUpperCase()
          : currentValue;
        const formattedNextValue = isString(nextValue)
          ? nextValue.toUpperCase()
          : nextValue;
        if (order === 'ASC') {
          return formattedCurrentValue < formattedNextValue
            ? -1
            : formattedCurrentValue > formattedNextValue
            ? 1
            : 0;
        } else {
          return formattedCurrentValue < formattedNextValue
            ? 1
            : formattedCurrentValue > formattedNextValue
            ? -1
            : 0;
        }
      }
      case AttributeType.SIZE:
      case AttributeType.PERCENTAGE: {
        return order === 'ASC'
          ? currentValue - nextValue
          : nextValue - currentValue;
      }
      case AttributeType.TIME_OFFSET:
      case AttributeType.DATE: {
        const formattedCurrentValue = moment(currentValue);
        const formattedNextValue = moment(nextValue);
        if (!formattedCurrentValue.isValid() || !formattedNextValue.isValid()) {
          if (order === 'ASC') {
            return !formattedCurrentValue.isValid() &&
              formattedNextValue.isValid()
              ? -1
              : formattedCurrentValue.isValid() && !formattedNextValue.isValid()
              ? 1
              : 0;
          } else {
            return !formattedCurrentValue.isValid() &&
              formattedNextValue.isValid()
              ? 1
              : formattedCurrentValue.isValid() && !formattedNextValue.isValid()
              ? -1
              : 0;
          }
        } else {
          if (order === 'ASC') {
            return formattedCurrentValue.isBefore(formattedNextValue)
              ? -1
              : formattedCurrentValue.isAfter(formattedNextValue)
              ? 1
              : 0;
          } else {
            return formattedCurrentValue.isBefore(formattedNextValue)
              ? 1
              : formattedCurrentValue.isAfter(formattedNextValue)
              ? -1
              : 0;
          }
        }
      }
      default:
        return 0;
    }
  });
}

export const getAvailableFilters = (filters = []) =>
  filters.filter(
    (filter) =>
      filter.attribute != null && filter.value !== '' && filter.value != null
  );

export const getVisibleTableColumns = (allColumns = [], visibleColumns = []) =>
  visibleColumns.reduce((acc, curr) => {
    if (visibleColumns.includes(curr)) {
      const column = allColumns.find((item) => item.key === curr);
      return [...acc, ...(column ? [column] : [])];
    }
    return acc;
  }, []);

export const selectForCurrentBrand = (payload) => {
  return payload[currentBrand];
};
// const isUndefined = (value) => value === undefined;

// const isNotUndefined = (value) => !isUndefined(value);

const isNull = (value) => value === undefined || value === null;

const isNotNull = (value) => !isNull(value);

const coalesce = (...args) => args.find((value) => isNotNull(value));

export const isEmpty = (value) =>
  isNull(value) ||
  value === false ||
  (Object.keys(value).length === 0 && value.constructor === Object) ||
  value.length === 0;

export const notEmpty = (value) => !isEmpty(value);

export const isDataSuccessfullyFetched = ({ status, data }) =>
  status === 200 && isNotUndefined(data);

export const isDataSuccessfullyPosted = ({ status, data }) =>
  (status === 201 || status === 200) && notEmpty(data);

export const searchRecursive = (
  data,
  key,
  value,
  childListName = 'children'
) => {
  let found = data.find((d) => d[key] === value);
  if (!found) {
    let i = 0;
    while (!found && i < data.length) {
      if (data[i][childListName] && data[i][childListName].length) {
        found = searchRecursive(
          data[i][childListName],
          key,
          value,
          childListName
        );
      }
      i++;
    }
  }
  return found;
};

export const getObjectByMaxValue = (list = [], property) =>
  list.reduce((a, b) => (a[property] > b[property] ? a : b));

export const groupBy = (list, key) =>
  list.reduce(function (acc, curr) {
    (acc[curr[key]] = acc[curr[key]] || []).push(curr);
    return acc;
  }, {});

export const getListRecursive = (
  obj,
  key,
  childListName = 'children',
  results = []
) => {
  const res = results;
  if (obj[childListName] && obj[childListName].length) {
    obj[childListName].forEach((item) => {
      res.push(item[key]);
      if (item[childListName] && item[childListName].length) {
        return getListRecursive(item, key, childListName, res);
      }
    });
  }
  return res;
};

export const getDifferenceBetweenTwoArrays = (
  originalArray = [],
  finalArray = []
) => {
  const removed = originalArray.filter((item) => !finalArray.includes(item));
  const added = finalArray.filter((item) => !originalArray.includes(item));
  return {
    removed,
    added,
    hasDifference: notEmpty(removed) || notEmpty(added),
  };
};

export const didValuesChange = (originalObj, finalObj) =>
  Object.keys(originalObj).some((item) => originalObj[item] !== finalObj[item]);

export const getChangedValues = (originalObj, finalObj) => {
  const result = {};
  Object.keys(originalObj).forEach((item) => {
    if (originalObj[item] !== finalObj[item]) {
      result[item] = finalObj[item];
    }
  });
  return result;
};

export const getObjectFromListBySpecificProperty = (
  list = [],
  property = 'id'
) => {
  const newObject = {};
  list.forEach((item) => {
    newObject[item[property]] = cloneDeep(item);
  });
  return newObject;
};

// useful for reordering draggable items
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getObjectByValue = (options = [], value, valueName = 'value') =>
  options.find((option) => option[valueName] === value) || {};

export const isDevOrQAEnvironment = () =>
  DEV_AND_QA_ENVIRONMENTS.some((item) =>
    window.location.hostname.includes(item)
  );

export const isDevServer = () =>
  DEV_ENVIRONMENTS.some((item) => window.location.hostname.includes(item));

export const isDevEnvironment = () => process.env.NODE_ENV === 'development';

export const isObject = (value) =>
  typeof value === 'object' && value !== null && value.constructor === Object;

export const isUndefined = (value) => typeof value === 'undefined';

export const isNotUndefined = (value) => !isUndefined(value);

// removes falsy values from an object / array
export const compact = (value = {}) => {
  if (!value) {
    return;
  }
  if (Array.isArray(value)) {
    return value.filter(Boolean);
  }
  if (isObject(value)) {
    return Object.keys(value).reduce((acc, currItem) => {
      if (notEmpty(value[currItem])) {
        return { ...acc, [currItem]: value[currItem] };
      }
      return acc;
    }, {});
  }
  return value;
};

// returns a value from an object.
// Also supports returning the first non-undefined /no-null value (keys provided in an array) from an object
export const getValue = (item, key) => {
  if (Array.isArray(key)) {
    for (const k of key) {
      const value = item[k];
      if (value) {
        return value;
      }
    }
  }
  return item[key];
};

export const isLoadingStatusNone = (loadingStatus = '') =>
  loadingStatus === NetworkStatus.NONE;
export const isLoadingStatusStarted = (loadingStatus = '') =>
  loadingStatus === NetworkStatus.STARTED;
export const isLoadingStatusError = (loadingStatus = '') =>
  loadingStatus === NetworkStatus.ERROR;
export const isLoadingStatusDone = (loadingStatus = '') =>
  loadingStatus === NetworkStatus.DONE;

export const isDataLoadingRequired = (loadingStatus) =>
  !isLoadingStatusDone(loadingStatus) &&
  !isLoadingStatusStarted(loadingStatus) &&
  !isLoadingStatusError(loadingStatus);

export const didValueChange = (initialValue, currentValue) =>
  initialValue !== currentValue;

const Helpers = {
  isNull,
  isNotNull,
  coalesce,
  didValuesChange,
};

export default Helpers;
