const PROFILE_CONFIG_CONSTANTS = {
  PROFILE_DETAILS: 'PROFILE_DETAILS',
  AUTHENTICATION: 'AUTHENTICATION',
  FEATURE_CONTROL: 'FEATURE_CONTROL',
  APPLICATIONS: 'APPLICATIONS',
  APP_MANAGEMENT: 'APP_MANAGEMENT',
  BLACKLIST: 'BLACKLIST',
  CERTIFICATES: 'CERTIFICATES',
  NETWORK: 'NETWORK',
  WIFI: 'WIFI',
  WEB_FILTER: 'WEB_FILTER',
  DATA_COLLECTION: 'DATA_COLLECTION',
  LOCATION_SERVICES: 'LOCATION_SERVICES',
  APN: 'APN',
  FIRMWARE_UPDATE: 'FIRMWARE_UPDATE',
  KIOSK_MODE: 'KIOSK_MODE',
  KIOSK_BUILDER: 'KIOSK_BUILDER',
  HTML_KIOSK_BUILDER: 'HTML_KIOSK_BUILDER',
  FILE_SYNC: 'FILE_SYNC',
  FLEETS: 'Fleets',
  GEO_FENCING: 'GEO_FENCING',
  PASSWORD: 'PASSWORD',
  SECURITY: 'SECURITY',
  SETTINGS: 'SETTINGS',
  WALLPAPER_SETTINGS: 'WALLPAPER_SETTINGS',
};

export default PROFILE_CONFIG_CONSTANTS;
