import {
  LOAD_NOTIFICATIONS_FAILED,
  LOAD_NOTIFICATIONS_START,
  LOAD_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_ADD,
  NOTIFICATIONS_HIDE,
  NOTIFICATIONS_HIDE_ALL,
  NOTIFICATIONS_REMOVE,
  NOTIFICATIONS_REMOVE_ALL,
} from './notifications.actionTypes';
import { NetworkStatus } from '../common/constants';

export default function notifications(
  state = {
    data: [],
    persistedNotifications: [],

    loadNotificationsStatus: NetworkStatus.NONE,
    loadNotificationsError: null,
    nextUrl: null,
  },
  action
) {
  switch (action.type) {
    case NOTIFICATIONS_ADD:
      return Object.assign({}, state, {
        data: [action.payload, ...state.data],
        persistedNotifications: [
          action.payload,
          ...state.persistedNotifications,
        ],
      });
    case NOTIFICATIONS_HIDE: {
      let notifications = [...state.data];
      const notificationIndex = notifications.findIndex(
        (notification) => notification.id === action.payload.notification.id
      );
      if (notificationIndex >= 0) {
        notifications.splice(notificationIndex, 1);
      }
      const persistedNotifications = [...state.persistedNotifications];
      if (action.payload.markAsRead) {
        const readNotificationIndex = persistedNotifications.findIndex(
          (notification) => notification.id === action.payload.notification.id
        );
        if (readNotificationIndex >= 0) {
          persistedNotifications[readNotificationIndex] = {
            ...persistedNotifications[readNotificationIndex],
            isRead: true,
          };
        }
      }

      return Object.assign({}, state, {
        data: notifications,
        persistedNotifications,
      });
    }
    case NOTIFICATIONS_REMOVE: {
      let notifications = [...state.data];
      const notificationIndex = notifications.findIndex(
        (notification) => notification.id === action.payload.notification.id
      );
      if (notificationIndex >= 0) {
        notifications.splice(notificationIndex, 1);
      }
      const persistedNotifications = [...state.persistedNotifications];
      const readNotificationIndex = persistedNotifications.findIndex(
        (notification) => notification.id === action.payload.notification.id
      );
      if (readNotificationIndex >= 0) {
        persistedNotifications.splice(readNotificationIndex, 1);
      }

      return Object.assign({}, state, {
        data: notifications,
        persistedNotifications,
      });
    }
    case NOTIFICATIONS_REMOVE_ALL: {
      return Object.assign({}, state, {
        data: [],
        persistedNotifications: [],
      });
    }
    case NOTIFICATIONS_HIDE_ALL: {
      return Object.assign({}, state, {
        data: [],
        persistedNotifications: state.persistedNotifications.map(
          (notification) => ({ ...notification, isRead: true })
        ),
      });
    }

    case LOAD_NOTIFICATIONS_START: {
      return {
        ...state,
        loadNotificationsStatus: NetworkStatus.STARTED,
        loadNotificationsError: null,
      };
    }

    case LOAD_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        loadNotificationsStatus: NetworkStatus.ERROR,
        loadNotificationsError: action.payload,
      };
    }

    case LOAD_NOTIFICATIONS_SUCCESS: {
      const { nextUrl } = action.payload;
      return {
        ...state,
        loadNotificationsStatus: NetworkStatus.DONE,
        loadNotificationsError: null,
        nextUrl,
      };
    }

    default:
      return state;
  }
}
