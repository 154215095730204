import { AttributeType } from '../../common/constants';
import BatteryLevel from '../devices/components/Devices/BatteryLevel';
import { DeviceRoutes } from '../../utils/routes';
import { filterConditions } from '../../constants/filter';

export const applicationRouteConstants = {
  INFO: 'info',
  VERSIONS: 'versions',
  SETTINGS: 'settings',
  ASSIGN: 'assign',
};

export const applicationsVersionsColumns = [
  {
    key: 'version',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.version',
    searchable: false,
  },
  {
    key: 'versionCode',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.versionCode',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'size',
    type: AttributeType.SIZE,
    titleCode: 'applications.fields.size',
    searchable: false,
  },
  {
    key: 'uploadedByName',
    titleKey: 'uploadedByEmail',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.uploadedBy',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'uploadedOn',
    type: AttributeType.DATE,
    titleCode: 'applications.fields.uploadedOn',
    searchable: false,
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'applications.fields.lastUpdated',
    searchable: false,
  },
  // {
  //     key: 'status',
  //     type: AttributeType.TEXT,
  //     titleCode: 'applications.fields.status',
  //     searchable: false,
  // },
  {
    key: 'installOnly',
    type: AttributeType.BOOLEAN,
    titleCode: 'applications.fields.installOnly',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
];

export const applicationDevicesColumns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.device',
    ref: `${DeviceRoutes}`,
    refId: 'id',
  },
  {
    key: 'osVersion',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.platform',
  },
  {
    key: 'coordinates',
    type: AttributeType.COORDINATES,
    titleCode: 'devices.fields.location',
  },
  {
    key: 'batteryLevel',
    type: AttributeType.PERCENTAGE,
    titleCode: 'devices.fields.batteryLevel',
    renderer: BatteryLevel,
  },
  {
    key: 'lastCheckin',
    type: AttributeType.DATE,
    titleCode: 'devices.fields.lastOnline',
  },
  {
    key: 'manufacturer',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.manufacturer',
  },
  {
    key: 'model',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.model',
  },
  {
    key: 'macAddress',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.macAddress',
  },
  {
    key: 'ethernetMacAddress',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.ethernetMacAddress',
  },
  {
    key: 'ipAddress',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.ipAddress',
  },
  {
    key: 'totalMemory',
    type: AttributeType.SIZE,
    titleCode: 'devices.fields.totalMemory',
  },
  {
    key: 'availableMemory',
    type: AttributeType.SIZE,
    titleCode: 'devices.fields.availableMemory',
  },
  {
    key: 'totalStorage',
    type: AttributeType.SIZE,
    titleCode: 'devices.fields.totalStorage',
  },
  {
    key: 'availableStorage',
    type: AttributeType.SIZE,
    titleCode: 'devices.fields.availableStorage',
  },
  {
    key: 'hardwareSerialNumber',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.serialNumber',
  },
  {
    key: 'imei',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.imei',
  },
];
