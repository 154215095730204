import { AttributeType } from '../../common/constants';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import { ReactComponent as Pencil } from '../../assets/images/pencil.svg';
import { filterConditions } from '../../constants/filter';

export const newProfileCreationPath = `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/create`;

export const profilesColumns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'profiles.fields.name',
    ref: `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}`,
    refId: 'id',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'draft',
    type: AttributeType.BOOLEAN,
    isFalseIconHidden: true,
    titleCode: 'profiles.draftAvailable',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
    custom: {
      trueIcon: Pencil,
    },
  },
  {
    key: 'published',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.published',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'version',
    type: AttributeType.TEXT,
    titleCode: 'profiles.version',
  },
  {
    key: 'updatedByName',
    titleKey: 'updatedByEmail',
    type: AttributeType.TEXT,
    titleCode: 'profiles.fields.updatedBy',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'updatedAt',
    type: AttributeType.DATE,
    titleCode: 'profiles.fields.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
];
