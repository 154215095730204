import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { I18n, Translate } from 'react-redux-i18n';
import DragNDropList from '../../../components/DragNDropList/DragNDropList.component';
import DragNDropDestinationListHeader from '../../../components/DragNDropComponents/DragNDropDestinationListHeader.component';
import DragNDropSourceListHeader from '../../../components/DragNDropComponents/DragNDropSourceListHeader.component';
import { SwitchField } from '../../../components/switchField.component';
import { AttributeType } from '../../../common/constants';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { policySettingsValueChangeHandler } from '../../../common/utilities/profile';
import PolicyActivationToggle from './PolicyActivationToggle.component';

const initialSettings = {
  useHomeScreenWallpaperAsLockScreenWallpaper: false,
  homeScreenWallpaperLandscape: undefined,
  homeScreenWallpaperPortrait: undefined,
  lockScreenWallpaperLandscape: undefined,
  lockScreenWallpaperPortrait: undefined,
};

const initialPolicy = {
  policyName: 'new',
  policyType: 'wallpaperSettings',
  isActive: false,
  policyData: null,
};

const DragNDropIds = {
  ALL_VALID_IMAGE_FILES: 'ALL_VALID_IMAGE_FILES',
  HOME_SCREEN_WALLPAPER_LANDSCAPE: 'homeScreenWallpaperLandscape',
  HOME_SCREEN_WALLPAPER_PORTRAIT: 'homeScreenWallpaperPortrait',
  LOCK_SCREEN_WALLPAPER_LANDSCAPE: 'lockScreenWallpaperLandscape',
  LOCK_SCREEN_WALLPAPER_PORTRAIT: 'lockScreenWallpaperPortrait',
};

const destinationIds = [
  DragNDropIds.LOCK_SCREEN_WALLPAPER_PORTRAIT,
  DragNDropIds.LOCK_SCREEN_WALLPAPER_LANDSCAPE,
  DragNDropIds.HOME_SCREEN_WALLPAPER_PORTRAIT,
  DragNDropIds.HOME_SCREEN_WALLPAPER_LANDSCAPE,
];

const validImageFormats = ['image/jpeg', 'image/png'];
const getImagesFiles = (files = []) =>
  files.filter(({ contentType }) => validImageFormats.includes(contentType));

const getWallpaperSelectionsList = ({
  homeScreenWallpaperLandscape,
  homeScreenWallpaperPortrait,
  lockScreenWallpaperLandscape,
  lockScreenWallpaperPortrait,
} = {}) => [
  {
    listHeader: (
      <DragNDropDestinationListHeader
        listTitle={I18n.t(
          'profiles.wallpaperSettings.homeScreenWallpaperLandscape'
        )}
      />
    ),
    droppableListId: DragNDropIds.HOME_SCREEN_WALLPAPER_LANDSCAPE,
    items: homeScreenWallpaperLandscape ? [homeScreenWallpaperLandscape] : null,
  },
  {
    listHeader: (
      <DragNDropDestinationListHeader
        listTitle={I18n.t(
          'profiles.wallpaperSettings.homeScreenWallpaperPortrait'
        )}
      />
    ),
    droppableListId: DragNDropIds.HOME_SCREEN_WALLPAPER_PORTRAIT,
    items: homeScreenWallpaperPortrait ? [homeScreenWallpaperPortrait] : null,
  },
  {
    listHeader: (
      <DragNDropDestinationListHeader
        listTitle={I18n.t(
          'profiles.wallpaperSettings.lockScreenWallpaperLandscape'
        )}
      />
    ),
    droppableListId: DragNDropIds.LOCK_SCREEN_WALLPAPER_LANDSCAPE,
    items: lockScreenWallpaperLandscape ? [lockScreenWallpaperLandscape] : null,
  },
  {
    listHeader: (
      <DragNDropDestinationListHeader
        listTitle={I18n.t(
          'profiles.wallpaperSettings.lockScreenWallpaperPortrait'
        )}
      />
    ),
    droppableListId: DragNDropIds.LOCK_SCREEN_WALLPAPER_PORTRAIT,
    items: lockScreenWallpaperPortrait ? [lockScreenWallpaperPortrait] : null,
  },
];

const getReadOnlyData = ([
  {
    settings: {
      useHomeScreenWallpaperAsLockScreenWallpaper = false,
      homeScreenWallpaperLandscape,
      homeScreenWallpaperPortrait,
      lockScreenWallpaperLandscape,
      lockScreenWallpaperPortrait,
    } = {},
    isActive,
  } = {},
]) => [
  {
    id: 'policy-status',
    title: I18n.t('profiles.wallpaperSettings.policyStatusTitle'),
    informationIconText: I18n.t(
      'profiles.wallpaperSettings.wallpaperSettingsAvailability'
    ),
    value: isActive,
    type: AttributeType.BOOLEAN,
  },
  {
    title: (
      <Translate value="profiles.wallpaperSettings.useHomeScreenWallpaperAsLockScreenWallpaper" />
    ),
    value: useHomeScreenWallpaperAsLockScreenWallpaper,
    type: AttributeType.BOOLEAN,
    key: 'useHomeScreenWallpaperAsLockScreenWallpaper',
  },
  {
    title: (
      <Translate value="profiles.wallpaperSettings.homeScreenWallpaperLandscape" />
    ),
    value: !!homeScreenWallpaperLandscape,
    type: AttributeType.BOOLEAN,
    key: 'homeScreenWallpaperLandscape',
  },
  {
    title: (
      <Translate value="profiles.wallpaperSettings.homeScreenWallpaperPortrait" />
    ),
    value: !!homeScreenWallpaperPortrait,
    type: AttributeType.BOOLEAN,
    key: 'homeScreenWallpaperPortrait',
  },
  {
    title: (
      <Translate value="profiles.wallpaperSettings.lockScreenWallpaperLandscape" />
    ),
    value: !!lockScreenWallpaperLandscape,
    type: AttributeType.BOOLEAN,
    key: 'lockScreenWallpaperLandscape',
  },
  {
    title: (
      <Translate value="profiles.wallpaperSettings.lockScreenWallpaperPortrait" />
    ),
    value: !!lockScreenWallpaperPortrait,
    type: AttributeType.BOOLEAN,
    key: 'lockScreenWallpaperPortrait',
  },
];

const WallpaperSettings = ({
  isEditingMode,
  files,
  policy,
  onChange,
  onChangePolicyProperty,
}) => {
  const imageFiles = getImagesFiles(files);
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    const expression = new RegExp(`^${source.droppableId}_`);
    // if dropped outside any list
    if (
      destinationIds.includes(source.droppableId) &&
      (!destination || source.droppableId !== destination.droppableId)
    ) {
      policySettingsValueChangeHandler({
        key: source.droppableId,
        value: undefined,
        policy,
        initialPolicy,
        initialSettings,
        onChange,
        policyDetailKey: 'wallpaperSettings',
      });
    }

    if (
      destination &&
      destinationIds.includes(destination.droppableId) &&
      source.droppableId !== destination.droppableId &&
      source.droppableId === DragNDropIds.ALL_VALID_IMAGE_FILES
    ) {
      const image = imageFiles.find(
        ({ id }) => draggableId.replace(expression, '') === id
      );
      policySettingsValueChangeHandler({
        key: destination.droppableId,
        value: image,
        policy,
        initialPolicy,
        initialSettings,
        onChange,
        policyDetailKey: 'wallpaperSettings',
      });
    }
  };

  const renderSourceList = () => (
    <section className="wallpaper-settings__source-list">
      <DragNDropList
        listHeader={
          <DragNDropSourceListHeader
            title={I18n.t('profiles.wallpaperSettings.myImages')}
          />
        }
        droppableListId={DragNDropIds.ALL_VALID_IMAGE_FILES}
        items={getImagesFiles(files)}
        itemOptions={{
          displayTextKeys: {
            leftCell: ['filename', 'key'],
          },
        }}
      />
    </section>
  );

  const renderWallpaperSelectionLists = (item) => {
    const wallpaperSelectionsList = getWallpaperSelectionsList(item?.settings);
    return (
      <section className="wallpaper-settings__selections-list">
        {wallpaperSelectionsList.map((selection) => (
          <DragNDropList
            key={selection.droppableListId}
            itemOptions={{
              displayTextKeys: {
                leftCell: ['filename', 'key'],
              },
            }}
            isMedium
            {...selection}
          />
        ))}
      </section>
    );
  };

  const onToggle = (key, currValue) =>
    policySettingsValueChangeHandler({
      key,
      value: !currValue,
      policy,
      initialPolicy,
      initialSettings,
      onChange,
      policyDetailKey: 'wallpaperSettings',
    });

  const renderAdditionalSettings = (
    { settings: { useHomeScreenWallpaperAsLockScreenWallpaper = false } = {} },
    index
  ) => (
    <section
      className="wallpaper-settings__settings-section"
      key={`additional-settings-${index}`}
    >
      <SwitchField
        onClick={() =>
          onToggle(
            'useHomeScreenWallpaperAsLockScreenWallpaper',
            useHomeScreenWallpaperAsLockScreenWallpaper
          )
        }
        on={useHomeScreenWallpaperAsLockScreenWallpaper}
        title={I18n.t(
          'profiles.wallpaperSettings.useHomeScreenWallpaperAsLockScreenWallpaper'
        )}
        key="useHomeScreenWallpaperAsLockScreenWallpaper"
      />
    </section>
  );

  const renderReadOnlyContent = () => {
    const items = getReadOnlyData(policy);
    return (
      <ReadOnlyForm
        items={items}
        headerTitle={
          <Translate value="profiles.wallpaperSettings.wallpaperSettings" />
        }
      />
    );
  };

  const renderSettings = (item = {}, index) => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      onChangePolicyProperty({
        key: 'wallpaperSettings',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });

    return (
      <section className="wallpaper-settings__wrapper" key={index}>
        <PolicyActivationToggle
          key="policyActivation"
          policy={policy}
          titleCode="profiles.wallpaperSettings.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
          informationIconText={I18n.t(
            'profiles.wallpaperSettings.wallpaperSettingsAvailability'
          )}
        />

        {item.isActive ? (
          <>
            {renderAdditionalSettings(item, index)}
            <DragDropContext onDragEnd={onDragEnd} key={index}>
              <section className="wallpaper-settings__editing-view">
                {renderWallpaperSelectionLists(item)}
                {renderSourceList()}
              </section>
            </DragDropContext>
          </>
        ) : null}
      </section>
    );
  };

  const renderContent = () => {
    return policy?.length
      ? policy.map(renderSettings)
      : renderSettings({
          ...initialPolicy,
          settings: {
            ...initialSettings,
          },
        });
  };

  return isEditingMode ? renderContent() : renderReadOnlyContent();
};

export default WallpaperSettings;
