import React from 'react';
import { ReactComponent as Trash } from '../../assets/images/trash.svg';
import { ActionButton } from '../actionButton.component';
import { isEmpty, notEmpty } from '../../common/helpers';
import { formatAttributiveDataFormat } from '../../common/utilities/attributiveDataFormat';
import StyleUtils from '../../utils/styleUtils';
import { AttributeType } from '../../common/constants';
import DropDownSelect from '../DropDownSelect/dropDownSelect.component';

const DragNDropApplicationItem = ({
  item,
  itemOptions: {
    actionButtonProps: {
      onClick: actionButtonOnClick,
      icon = <Trash className={'icon'} />,
    } = {},
    displayTextKeys: { leftCell: leftCellKey, rightCell: rightCellKey } = {},
    multiCellTextKeyAttributes,
    textContainerAdditionalClassName = '',
    onApplicationVersionChange,
  } = {},
}) => {
  const renderApplicationVersion = ({ values }) => {
    const selectedOption = {
      value: values.version,
      text: values.version,
      idName: values.idName,
    };
    const versions = notEmpty(values.versions)
      ? values.versions.map(({ version, idName }) => ({
          value: version,
          text: version,
          idName,
        }))
      : [selectedOption];

    return (
      <DropDownSelect
        options={versions}
        defaultValue={selectedOption}
        value={selectedOption}
        onChange={onApplicationVersionChange}
        isDisabled={versions.length === 1 || isEmpty(versions)}
        isSearchable={false}
      />
    );
  };

  return (
    <>
      {multiCellTextKeyAttributes.map((multiCellTextKeyAttribute) => (
        <div
          key={multiCellTextKeyAttribute.key}
          className={StyleUtils.mergeClasses(
            'drag-n-drop-list-content-item__cell',
            textContainerAdditionalClassName
          )}
        >
          {multiCellTextKeyAttribute.type === AttributeType.APPLICATION_VERSION
            ? renderApplicationVersion({
                values: item,
                attribute: multiCellTextKeyAttribute,
              })
            : formatAttributiveDataFormat({
                values: item,
                attribute: multiCellTextKeyAttribute,
              })}
        </div>
      ))}
      {actionButtonOnClick ? (
        <ActionButton
          onClick={actionButtonOnClick}
          style={{ marginLeft: '0', width: '18px' }}
        >
          {icon}
        </ActionButton>
      ) : null}
    </>
  );
};

export default DragNDropApplicationItem;
