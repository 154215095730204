import { CLOSE_BANNER, SHOW_BANNER } from './banner.actionTypes';
import { dispatch } from '../../store/store';

const showBanner = (payload) => {
  return new Promise(() =>
    dispatch({
      type: SHOW_BANNER,
      payload,
    })
  );
};

const closeBanner = (payload) => {
  return new Promise(() =>
    dispatch({
      type: CLOSE_BANNER,
      payload,
    })
  );
};

export default {
  showBanner,
  closeBanner,
};
