import React from 'react';

const maximumNumberOfAlerts = 9;
const TotalAlerts = ({ item: { totalAlerts = 0 } = {} }) => (
  <div className="alert-row__total-alerts-container">
    <span className="alert-row__total-alerts">
      {totalAlerts > maximumNumberOfAlerts
        ? `${maximumNumberOfAlerts}+`
        : totalAlerts}
    </span>
  </div>
);

export default TotalAlerts;
