import * as ProfilesV2Thunks from '../features/profilesV2/profilesV2.thunk';
import * as ProfilesV1Thunks from '../features/profiles/profiles.thunk';
import * as FleetV2Thunks from '../features/fleets/fleetsV2.thunk';
import * as ProfileV1Thunks from '../features/profile/profile.thunk';
import * as ProfileV2Thunks from '../features/profile/profileV2.thunk';
import * as DeviceThunks from '../features/device/device.thunk';
import * as GroupsThunks from '../features/group/groups.thunk';

export const actionCreators = {
  ...ProfilesV2Thunks,
  ...ProfilesV1Thunks,
  ...FleetV2Thunks,
  ...ProfileV1Thunks,
  ...ProfileV2Thunks,
  ...DeviceThunks,
  ...GroupsThunks,
};
