// sources:
// https://github.com/musalbas/mcc-mnc-table
//  http://mcc-mnc.com/

export const MOBILE_OPERATORS = {
  _202GR: [
    {
      mcc: '202',
      mnc: '07',
      network: 'AMD Telecom SA',
    },
    {
      mcc: '202',
      mnc: '02',
      network: 'Cosmote',
    },
    {
      mcc: '202',
      mnc: '01',
      network: 'Cosmote',
    },
    {
      mcc: '202',
      mnc: '14',
      network: 'CyTa Mobile',
    },
    {
      mcc: '202',
      mnc: '04',
      network: 'Organismos Sidirodromon Ellados (OSE)',
    },
    {
      mcc: '202',
      mnc: '03',
      network: 'OTE Hellenic Telecommunications Organization SA',
    },
    {
      mcc: '202',
      mnc: '10',
      network: 'Tim/Wind',
    },
    {
      mcc: '202',
      mnc: '09',
      network: 'Tim/Wind',
    },
    {
      mcc: '202',
      mnc: '05',
      network: 'Vodafone',
    },
  ],
  _204NL: [
    {
      mcc: '204',
      mnc: '14',
      network: '6GMOBILE BV',
    },
    {
      mcc: '204',
      mnc: '23',
      network: 'Aspider Solutions',
    },
    {
      mcc: '204',
      mnc: '05',
      network:
        'Elephant Talk Communications Premium Rate Services Netherlands BV',
    },
    {
      mcc: '204',
      mnc: '17',
      network: 'Intercity Mobile Communications BV',
    },
    {
      mcc: '204',
      mnc: '08',
      network: 'KPN Telecom B.V.',
    },
    {
      mcc: '204',
      mnc: '69',
      network: 'KPN Telecom B.V.',
    },
    {
      mcc: '204',
      mnc: '10',
      network: 'KPN Telecom B.V.',
    },
    {
      mcc: '204',
      mnc: '12',
      network: 'KPN/Telfort',
    },
    {
      mcc: '204',
      mnc: '28',
      network: 'Lancelot BV',
    },
    {
      mcc: '204',
      mnc: '09',
      network: 'Lycamobile Ltd',
    },
    {
      mcc: '204',
      mnc: '06',
      network: 'Mundio/Vectone Mobile',
    },
    {
      mcc: '204',
      mnc: '21',
      network: 'NS Railinfrabeheer B.V.',
    },
    {
      mcc: '204',
      mnc: '24',
      network: 'Private Mobility Nederland BV',
    },
    {
      mcc: '204',
      mnc: '98',
      network: 'T-Mobile B.V.',
    },
    {
      mcc: '204',
      mnc: '16',
      network: 'T-Mobile B.V.',
    },
    {
      mcc: '204',
      mnc: '20',
      network: 'T-mobile/former Orange',
    },
    {
      mcc: '204',
      mnc: '02',
      network: 'Tele2',
    },
    {
      mcc: '204',
      mnc: '07',
      network: 'Teleena Holding BV',
    },
    {
      mcc: '204',
      mnc: '68',
      network: 'Unify Mobile',
    },
    {
      mcc: '204',
      mnc: '18',
      network: 'UPC Nederland BV',
    },
    {
      mcc: '204',
      mnc: '04',
      network: 'Vodafone Libertel',
    },
    {
      mcc: '204',
      mnc: '03',
      network: 'Voiceworks Mobile BV',
    },
    {
      mcc: '204',
      mnc: '15',
      network: 'Ziggo BV',
    },
  ],
  _206BE: [
    {
      mcc: '206',
      mnc: '20',
      network: 'Base/KPN',
    },
    {
      mcc: '206',
      mnc: '01',
      network: 'Belgacom/Proximus',
    },
    {
      mcc: '206',
      mnc: '06',
      network: 'Lycamobile Belgium',
    },
    {
      mcc: '206',
      mnc: '10',
      network: 'Mobistar/Orange',
    },
    {
      mcc: '206',
      mnc: '02',
      network: 'SNCT/NMBS',
    },
    {
      mcc: '206',
      mnc: '05',
      network: 'Telenet NV',
    },
    {
      mcc: '206',
      mnc: '08',
      network: 'VOO',
    },
  ],
  _208FR: [
    {
      mcc: '208',
      mnc: '27',
      network: 'AFONE SA',
    },
    {
      mcc: '208',
      mnc: '92',
      network: 'Association Plate-forme Telecom',
    },
    {
      mcc: '208',
      mnc: '28',
      network: 'Astrium',
    },
    {
      mcc: '208',
      mnc: '88',
      network: 'Bouygues Telecom',
    },
    {
      mcc: '208',
      mnc: '21',
      network: 'Bouygues Telecom',
    },
    {
      mcc: '208',
      mnc: '20',
      network: 'Bouygues Telecom',
    },
    {
      mcc: '208',
      mnc: '14',
      network: 'Lliad/FREE Mobile',
    },
    {
      mcc: '208',
      mnc: '06',
      network: 'GlobalStar',
    },
    {
      mcc: '208',
      mnc: '05',
      network: 'GlobalStar',
    },
    {
      mcc: '208',
      mnc: '07',
      network: 'GlobalStar',
    },
    {
      mcc: '208',
      mnc: '29',
      network: 'Orange',
    },
    {
      mcc: '208',
      mnc: '17',
      network: 'Legos - Local Exchange Global Operation Services SA',
    },
    {
      mcc: '208',
      mnc: '16',
      network: 'Lliad/FREE Mobile',
    },
    {
      mcc: '208',
      mnc: '15',
      network: 'Lliad/FREE Mobile',
    },
    {
      mcc: '208',
      mnc: '25',
      network: 'Lycamobile SARL',
    },
    {
      mcc: '208',
      mnc: '24',
      network: 'MobiquiThings',
    },
    {
      mcc: '208',
      mnc: '03',
      network: 'MobiquiThings',
    },
    {
      mcc: '208',
      mnc: '31',
      network: 'Mundio Mobile (France) Ltd',
    },
    {
      mcc: '208',
      mnc: '26',
      network: 'NRJ',
    },
    {
      mcc: '208',
      mnc: '89',
      network: 'Virgin Mobile/Omer',
    },
    {
      mcc: '208',
      mnc: '23',
      network: 'Virgin Mobile/Omer',
    },
    {
      mcc: '208',
      mnc: '91',
      network: 'Orange',
    },
    {
      mcc: '208',
      mnc: '02',
      network: 'Orange',
    },
    {
      mcc: '208',
      mnc: '01',
      network: 'Orange',
    },
    {
      mcc: '208',
      mnc: '11',
      network: 'S.F.R.',
    },
    {
      mcc: '208',
      mnc: '10',
      network: 'S.F.R.',
    },
    {
      mcc: '208',
      mnc: '09',
      network: 'S.F.R.',
    },
    {
      mcc: '208',
      mnc: '13',
      network: 'S.F.R.',
    },
    {
      mcc: '208',
      mnc: '04',
      network: 'SISTEER',
    },
    {
      mcc: '208',
      mnc: '00',
      network: 'Tel/Te',
    },
    {
      mcc: '208',
      mnc: '22',
      network: 'Transatel SA',
    },
  ],
  _212MC: [
    {
      mcc: '212',
      mnc: '10',
      network: 'Monaco Telecom',
    },
    {
      mcc: '212',
      mnc: '01',
      network: 'Monaco Telecom',
    },
  ],
  _213AD: [
    {
      mcc: '213',
      mnc: '03',
      network: 'Mobiland',
    },
  ],
  _214ES: [
    {
      mcc: '214',
      mnc: '23',
      network: 'Lycamobile SL',
    },
    {
      mcc: '214',
      mnc: '22',
      network: 'Digi Spain Telecom SL',
    },
    {
      mcc: '214',
      mnc: '15',
      network: 'BT Espana SAU',
    },
    {
      mcc: '214',
      mnc: '18',
      network: 'Cableuropa SAU (ONO)',
    },
    {
      mcc: '214',
      mnc: '08',
      network: 'Euskaltel SA',
    },
    {
      mcc: '214',
      mnc: '20',
      network: 'fonYou Wireless SL',
    },
    {
      mcc: '214',
      mnc: '32',
      network: 'ION Mobile',
    },
    {
      mcc: '214',
      mnc: '21',
      network: 'Jazz Telecom SAU',
    },
    {
      mcc: '214',
      mnc: '26',
      network: 'Lleida',
    },
    {
      mcc: '214',
      mnc: '25',
      network: 'Lycamobile SL',
    },
    {
      mcc: '214',
      mnc: '07',
      network: 'Movistar',
    },
    {
      mcc: '214',
      mnc: '05',
      network: 'Movistar',
    },
    {
      mcc: '214',
      mnc: '03',
      network: 'Orange',
    },
    {
      mcc: '214',
      mnc: '09',
      network: 'Orange',
    },
    {
      mcc: '214',
      mnc: '11',
      network: 'Orange',
    },
    {
      mcc: '214',
      mnc: '17',
      network: 'R Cable y Telec. Galicia SA',
    },
    {
      mcc: '214',
      mnc: '19',
      network: 'Simyo/KPN ',
    },
    {
      mcc: '214',
      mnc: '16',
      network: 'Telecable de Asturias SA',
    },
    {
      mcc: '214',
      mnc: '27',
      network: 'Truphone',
    },
    {
      mcc: '214',
      mnc: '01',
      network: 'Vodafone',
    },
    {
      mcc: '214',
      mnc: '06',
      network: 'Vodafone Enabler Espana SL',
    },
    {
      mcc: '214',
      mnc: '04',
      network: 'Yoigo',
    },
  ],
  _216HU: [
    {
      mcc: '216',
      mnc: '03',
      network: 'DIGI',
    },
    {
      mcc: '216',
      mnc: '01',
      network: 'Pannon/Telenor',
    },
    {
      mcc: '216',
      mnc: '30',
      network: 'T-mobile/Magyar',
    },
    {
      mcc: '216',
      mnc: '71',
      network: 'UPC Magyarorszag Kft.',
    },
    {
      mcc: '216',
      mnc: '70',
      network: 'Vodafone',
    },
  ],
  _218BA: [
    {
      mcc: '218',
      mnc: '90',
      network: 'BH Mobile',
    },
    {
      mcc: '218',
      mnc: '03',
      network: 'Eronet Mobile',
    },
    {
      mcc: '218',
      mnc: '05',
      network: 'M-Tel',
    },
  ],
  _219HR: [
    {
      mcc: '219',
      mnc: '01',
      network: 'T-Mobile/Cronet',
    },
    {
      mcc: '219',
      mnc: '02',
      network: 'Tele2',
    },
    {
      mcc: '219',
      mnc: '10',
      network: 'VIPnet d.o.o.',
    },
  ],
  _220RS: [
    {
      mcc: '220',
      mnc: '03',
      network: 'MTS/Telekom Srbija',
    },
    {
      mcc: '220',
      mnc: '02',
      network: 'Telenor/Mobtel',
    },
    {
      mcc: '220',
      mnc: '01',
      network: 'Telenor/Mobtel',
    },
    {
      mcc: '220',
      mnc: '05',
      network: 'VIP Mobile',
    },
  ],
  _221XK: [
    {
      mcc: '221',
      mnc: '06',
      network: 'Dardafon.Net LLC',
    },
    {
      mcc: '221',
      mnc: '02',
      network: 'IPKO',
    },
    {
      mcc: '221',
      mnc: '03',
      network: 'MTS DOO',
    },
    {
      mcc: '221',
      mnc: '01',
      network: 'Vala',
    },
  ],
  _222IT: [
    {
      mcc: '222',
      mnc: '34',
      network: 'BT Italia SpA',
    },
    {
      mcc: '222',
      mnc: '36',
      network: 'Digi Mobil',
    },
    {
      mcc: '222',
      mnc: '36',
      network: 'Digi Mobil',
    },
    {
      mcc: '222',
      mnc: '02',
      network: 'Elsacom',
    },
    {
      mcc: '222',
      mnc: '08',
      network: 'Fastweb SpA',
    },
    {
      mcc: '222',
      mnc: '00',
      network: 'Fix Line',
    },
    {
      mcc: '222',
      mnc: '99',
      network: 'Hi3G',
    },
    {
      mcc: '222',
      mnc: '50',
      network: 'Iliad',
    },
    {
      mcc: '222',
      mnc: '77',
      network: 'IPSE 2000',
    },
    {
      mcc: '222',
      mnc: '35',
      network: 'Lycamobile Srl',
    },
    {
      mcc: '222',
      mnc: '07',
      network: 'Noverca Italia Srl',
    },
    {
      mcc: '222',
      mnc: '33',
      network: 'PosteMobile SpA',
    },
    {
      mcc: '222',
      mnc: '00',
      network: 'Premium Number(s)',
    },
    {
      mcc: '222',
      mnc: '30',
      network: 'RFI Rete Ferroviaria Italiana SpA',
    },
    {
      mcc: '222',
      mnc: '43',
      network: 'Telecom Italia Mobile SpA',
    },
    {
      mcc: '222',
      mnc: '01',
      network: 'TIM',
    },
    {
      mcc: '222',
      mnc: '48',
      network: 'Telecom Italia Mobile SpA',
    },
    {
      mcc: '222',
      mnc: '10',
      network: 'Vodafone',
    },
    {
      mcc: '222',
      mnc: '06',
      network: 'Vodafone',
    },
    {
      mcc: '222',
      mnc: '00',
      network: 'VOIP Line',
    },
    {
      mcc: '222',
      mnc: '44',
      network: 'WIND (Blu) -',
    },
    {
      mcc: '222',
      mnc: '88',
      network: 'WIND (Blu) -',
    },
  ],
  _226RO: [
    {
      mcc: '226',
      mnc: '03',
      network: 'Telekom Romania',
    },
    {
      mcc: '226',
      mnc: '11',
      network: 'Enigma Systems',
    },
    {
      mcc: '226',
      mnc: '16',
      network: 'Lycamobile',
    },
    {
      mcc: '226',
      mnc: '10',
      network: 'Orange',
    },
    {
      mcc: '226',
      mnc: '05',
      network: 'RCS&amp;RDS Digi Mobile',
    },
    {
      mcc: '226',
      mnc: '02',
      network: 'Romtelecom SA',
    },
    {
      mcc: '226',
      mnc: '06',
      network: 'Telekom Romania',
    },
    {
      mcc: '226',
      mnc: '01',
      network: 'Vodafone',
    },
    {
      mcc: '226',
      mnc: '04',
      network: 'Telekom Romania',
    },
  ],
  _228CH: [
    {
      mcc: '228',
      mnc: '51',
      network: 'BebbiCell AG',
    },
    {
      mcc: '228',
      mnc: '58',
      network: 'Beeone',
    },
    {
      mcc: '228',
      mnc: '09',
      network: 'Comfone AG',
    },
    {
      mcc: '228',
      mnc: '05',
      network: 'Comfone AG',
    },
    {
      mcc: '228',
      mnc: '07',
      network: 'TDC Sunrise',
    },
    {
      mcc: '228',
      mnc: '54',
      network: 'Lycamobile AG',
    },
    {
      mcc: '228',
      mnc: '52',
      network: 'Mundio Mobile AG',
    },
    {
      mcc: '228',
      mnc: '03',
      network: 'Salt/Orange',
    },
    {
      mcc: '228',
      mnc: '01',
      network: 'Swisscom',
    },
    {
      mcc: '228',
      mnc: '12',
      network: 'TDC Sunrise',
    },
    {
      mcc: '228',
      mnc: '02',
      network: 'TDC Sunrise',
    },
    {
      mcc: '228',
      mnc: '08',
      network: 'TDC Sunrise',
    },
    {
      mcc: '228',
      mnc: '53',
      network: 'upc cablecom GmbH',
    },
  ],
  _230CZ: [
    {
      mcc: '230',
      mnc: '08',
      network: 'Compatel s.r.o.',
    },
    {
      mcc: '230',
      mnc: '02',
      network: 'O2',
    },
    {
      mcc: '230',
      mnc: '01',
      network: 'T-Mobile / RadioMobil',
    },
    {
      mcc: '230',
      mnc: '05',
      network: 'Travel Telekommunikation s.r.o.',
    },
    {
      mcc: '230',
      mnc: '04',
      network: 'Ufone',
    },
    {
      mcc: '230',
      mnc: '03',
      network: 'Vodafone',
    },
    {
      mcc: '230',
      mnc: '99',
      network: 'Vodafone',
    },
  ],
  _231SK: [
    {
      mcc: '231',
      mnc: '03',
      network: 'Swan/4Ka',
    },
    {
      mcc: '231',
      mnc: '06',
      network: 'O2',
    },
    {
      mcc: '231',
      mnc: '01',
      network: 'Orange',
    },
    {
      mcc: '231',
      mnc: '05',
      network: 'Orange',
    },
    {
      mcc: '231',
      mnc: '15',
      network: 'Orange',
    },
    {
      mcc: '231',
      mnc: '02',
      network: 'T-Mobile',
    },
    {
      mcc: '231',
      mnc: '04',
      network: 'T-Mobile',
    },
    {
      mcc: '231',
      mnc: '99',
      network: 'Zeleznice Slovenskej republiky (ZSR)',
    },
  ],
  _232AT: [
    {
      mcc: '232',
      mnc: '09',
      network: 'A1 MobilKom',
    },
    {
      mcc: '232',
      mnc: '02',
      network: 'A1 MobilKom',
    },
    {
      mcc: '232',
      mnc: '01',
      network: 'A1 MobilKom',
    },
    {
      mcc: '232',
      mnc: '11',
      network: 'A1 MobilKom',
    },
    {
      mcc: '232',
      mnc: '15',
      network: 'T-Mobile/Telering',
    },
    {
      mcc: '232',
      mnc: '00',
      network: 'Fix Line',
    },
    {
      mcc: '232',
      mnc: '10',
      network: 'H3G',
    },
    {
      mcc: '232',
      mnc: '14',
      network: 'H3G',
    },
    {
      mcc: '232',
      mnc: '20',
      network: 'Mtel',
    },
    {
      mcc: '232',
      mnc: '06',
      network: '3/Orange/One Connect',
    },
    {
      mcc: '232',
      mnc: '12',
      network: '3/Orange/One Connect',
    },
    {
      mcc: '232',
      mnc: '05',
      network: '3/Orange/One Connect',
    },
    {
      mcc: '232',
      mnc: '17',
      network: 'Spusu/Mass Response',
    },
    {
      mcc: '232',
      mnc: '04',
      network: 'T-Mobile/Telering',
    },
    {
      mcc: '232',
      mnc: '13',
      network: 'T-Mobile/Telering',
    },
    {
      mcc: '232',
      mnc: '03',
      network: 'T-Mobile/Telering',
    },
    {
      mcc: '232',
      mnc: '07',
      network: 'T-Mobile/Telering',
    },
    {
      mcc: '232',
      mnc: '19',
      network: 'Tele2',
    },
    {
      mcc: '232',
      mnc: '08',
      network: 'A1 MobilKom',
    },
  ],
  _234GB: [
    {
      mcc: '234',
      mnc: '03',
      network: 'Airtel/Vodafone',
    },
    {
      mcc: '234',
      mnc: '00',
      network: 'BT Group',
    },
    {
      mcc: '234',
      mnc: '76',
      network: 'BT Group',
    },
    {
      mcc: '234',
      mnc: '77',
      network: 'BT Group',
    },
    {
      mcc: '234',
      mnc: '07',
      network: 'Cable and Wireless ',
    },
    {
      mcc: '234',
      mnc: '92',
      network: 'Cable and Wireless ',
    },
    {
      mcc: '234',
      mnc: '18',
      network: 'Cloud9/wire9 Tel.',
    },
    {
      mcc: '234',
      mnc: '999',
      network: 'FIX Line',
    },
    {
      mcc: '234',
      mnc: '17',
      network: 'FlexTel',
    },
    {
      mcc: '234',
      mnc: '14',
      network: 'HaySystems',
    },
    {
      mcc: '234',
      mnc: '94',
      network: 'H3G Hutchinson',
    },
    {
      mcc: '234',
      mnc: '20',
      network: 'H3G Hutchinson',
    },
    {
      mcc: '234',
      mnc: '75',
      network: 'Inquam Telecom Ltd',
    },
    {
      mcc: '234',
      mnc: '35',
      network: 'JSC Ingenicum',
    },
    {
      mcc: '234',
      mnc: '26',
      network: 'Lycamobile',
    },
    {
      mcc: '234',
      mnc: '58',
      network: 'Manx Telecom',
    },
    {
      mcc: '234',
      mnc: '01',
      network: 'Mapesbury C. Ltd',
    },
    {
      mcc: '234',
      mnc: '28',
      network: 'Marthon Telecom',
    },
    {
      mcc: '234',
      mnc: '10',
      network: 'O2 Ltd.',
    },
    {
      mcc: '234',
      mnc: '02',
      network: 'O2 Ltd.',
    },
    {
      mcc: '234',
      mnc: '11',
      network: 'O2 Ltd.',
    },
    {
      mcc: '234',
      mnc: '08',
      network: 'OnePhone',
    },
    {
      mcc: '234',
      mnc: '16',
      network: 'Opal Telecom',
    },
    {
      mcc: '234',
      mnc: '33',
      network: 'Everyth. Ev.wh./Orange',
    },
    {
      mcc: '234',
      mnc: '34',
      network: 'Everyth. Ev.wh./Orange',
    },
    {
      mcc: '234',
      mnc: '19',
      network: 'PMN/Teleware',
    },
    {
      mcc: '234',
      mnc: '12',
      network: 'Railtrack Plc',
    },
    {
      mcc: '234',
      mnc: '22',
      network: 'Routotelecom',
    },
    {
      mcc: '234',
      mnc: '57',
      network: 'Sky UK Limited',
    },
    {
      mcc: '234',
      mnc: '24',
      network: 'Stour Marine',
    },
    {
      mcc: '234',
      mnc: '37',
      network: 'Synectiv Ltd.',
    },
    {
      mcc: '234',
      mnc: '31',
      network: 'Everyth. Ev.wh./T-Mobile',
    },
    {
      mcc: '234',
      mnc: '30',
      network: 'Everyth. Ev.wh./T-Mobile',
    },
    {
      mcc: '234',
      mnc: '32',
      network: 'Everyth. Ev.wh./T-Mobile',
    },
    {
      mcc: '234',
      mnc: '27',
      network: 'Vodafone',
    },
    {
      mcc: '234',
      mnc: '09',
      network: 'Tismi',
    },
    {
      mcc: '234',
      mnc: '25',
      network: 'Truphone',
    },
    {
      mcc: '234',
      mnc: '23',
      network: 'Vectofone Mobile Wifi',
    },
    {
      mcc: '234',
      mnc: '38',
      network: 'Virgin Mobile',
    },
    {
      mcc: '234',
      mnc: '91',
      network: 'Vodafone',
    },
    {
      mcc: '234',
      mnc: '15',
      network: 'Vodafone',
    },
    {
      mcc: '234',
      mnc: '89',
      network: 'Vodafone',
    },
    {
      mcc: '234',
      mnc: '78',
      network: 'Wave Telecom Ltd',
    },
  ],
  _234GG: [
    {
      mcc: '234',
      mnc: '55',
      network: 'Guernsey Telecoms',
    },
  ],
  _234IM: [
    {
      mcc: '234',
      mnc: '36',
      network: 'Cable and Wireless Isle of Man',
    },
  ],
  _234JE: [
    {
      mcc: '234',
      mnc: '50',
      network: 'Jersey Telecom',
    },
    {
      mcc: '234',
      mnc: '51',
      network: 'Jersey Telecom',
    },
  ],
  _235GB: [
    {
      mcc: '235',
      mnc: '02',
      network: 'Everyth. Ev.wh.',
    },
  ],
  _238DK: [
    {
      mcc: '238',
      mnc: '05',
      network: 'ApS KBUS',
    },
    {
      mcc: '238',
      mnc: '23',
      network: 'Banedanmark',
    },
    {
      mcc: '238',
      mnc: '28',
      network: 'CoolTEL ApS',
    },
    {
      mcc: '238',
      mnc: '06',
      network: 'H3G',
    },
    {
      mcc: '238',
      mnc: '12',
      network: 'Lycamobile Ltd',
    },
    {
      mcc: '238',
      mnc: '03',
      network: 'Mach Connectivity ApS',
    },
    {
      mcc: '238',
      mnc: '07',
      network: 'Mundio Mobile',
    },
    {
      mcc: '238',
      mnc: '04',
      network: 'NextGen Mobile Ltd (CardBoardFish)',
    },
    {
      mcc: '238',
      mnc: '10',
      network: 'TDC Denmark',
    },
    {
      mcc: '238',
      mnc: '01',
      network: 'TDC Denmark',
    },
    {
      mcc: '238',
      mnc: '77',
      network: 'Telenor/Sonofon',
    },
    {
      mcc: '238',
      mnc: '02',
      network: 'Telenor/Sonofon',
    },
    {
      mcc: '238',
      mnc: '20',
      network: 'Telia',
    },
    {
      mcc: '238',
      mnc: '30',
      network: 'Telia',
    },
  ],
  _240SE: [
    {
      mcc: '240',
      mnc: '35',
      network: '42 Telecom AB',
    },
    {
      mcc: '240',
      mnc: '16',
      network: '42 Telecom AB',
    },
    {
      mcc: '240',
      mnc: '26',
      network: 'Beepsend',
    },
    {
      mcc: '240',
      mnc: '30',
      network: 'NextGen Mobile Ltd (CardBoardFish)',
    },
    {
      mcc: '240',
      mnc: '28',
      network: 'CoolTEL Aps',
    },
    {
      mcc: '240',
      mnc: '25',
      network: 'Digitel Mobile Srl',
    },
    {
      mcc: '240',
      mnc: '22',
      network: 'Eu Tel AB',
    },
    {
      mcc: '240',
      mnc: '27',
      network: 'Fogg Mobile AB',
    },
    {
      mcc: '240',
      mnc: '18',
      network: 'Generic Mobile Systems Sweden AB',
    },
    {
      mcc: '240',
      mnc: '17',
      network: 'Gotalandsnatet AB',
    },
    {
      mcc: '240',
      mnc: '04',
      network: 'H3G Access AB',
    },
    {
      mcc: '240',
      mnc: '02',
      network: 'H3G Access AB',
    },
    {
      mcc: '240',
      mnc: '36',
      network: 'ID Mobile',
    },
    {
      mcc: '240',
      mnc: '23',
      network: 'Infobip Ltd.',
    },
    {
      mcc: '240',
      mnc: '11',
      network: 'Lindholmen Science Park AB',
    },
    {
      mcc: '240',
      mnc: '12',
      network: 'Lycamobile Ltd',
    },
    {
      mcc: '240',
      mnc: '29',
      network: 'Mercury International Carrier Services',
    },
    {
      mcc: '240',
      mnc: '19',
      network: 'Mundio Mobile (Sweden) Ltd',
    },
    {
      mcc: '240',
      mnc: '03',
      network: 'Netett Sverige AB',
    },
    {
      mcc: '240',
      mnc: '10',
      network: 'Spring Mobil AB',
    },
    {
      mcc: '240',
      mnc: '05',
      network: 'Svenska UMTS-N',
    },
    {
      mcc: '240',
      mnc: '14',
      network: 'TDC Sverige AB',
    },
    {
      mcc: '240',
      mnc: '07',
      network: 'Tele2 Sverige AB',
    },
    {
      mcc: '240',
      mnc: '08',
      network: 'Telenor (Vodafone)',
    },
    {
      mcc: '240',
      mnc: '06',
      network: 'Telenor (Vodafone)',
    },
    {
      mcc: '240',
      mnc: '24',
      network: 'Telenor (Vodafone)',
    },
    {
      mcc: '240',
      mnc: '01',
      network: 'Telia Mobile',
    },
    {
      mcc: '240',
      mnc: '13',
      network: 'Ventelo Sverige AB',
    },
    {
      mcc: '240',
      mnc: '20',
      network: 'Wireless Maingate AB',
    },
    {
      mcc: '240',
      mnc: '15',
      network: 'Wireless Maingate Nordic AB',
    },
  ],
  _242NO: [
    {
      mcc: '242',
      mnc: '09',
      network: 'Com4 AS',
    },
    {
      mcc: '242',
      mnc: '14',
      network: 'ICE Nordisk Mobiltelefon AS',
    },
    {
      mcc: '242',
      mnc: '21',
      network: 'Jernbaneverket (GSM-R)',
    },
    {
      mcc: '242',
      mnc: '20',
      network: 'Jernbaneverket (GSM-R)',
    },
    {
      mcc: '242',
      mnc: '23',
      network: 'Lycamobile Ltd',
    },
    {
      mcc: '242',
      mnc: '02',
      network: 'Telia/Netcom',
    },
    {
      mcc: '242',
      mnc: '22',
      network: 'Telia/Network Norway AS',
    },
    {
      mcc: '242',
      mnc: '05',
      network: 'Telia/Network Norway AS',
    },
    {
      mcc: '242',
      mnc: '06',
      network: 'ICE Nordisk Mobiltelefon AS',
    },
    {
      mcc: '242',
      mnc: '08',
      network: 'TDC Mobil A/S',
    },
    {
      mcc: '242',
      mnc: '04',
      network: 'Tele2',
    },
    {
      mcc: '242',
      mnc: '12',
      network: 'Telenor',
    },
    {
      mcc: '242',
      mnc: '01',
      network: 'Telenor',
    },
    {
      mcc: '242',
      mnc: '03',
      network: 'Teletopia',
    },
    {
      mcc: '242',
      mnc: '07',
      network: 'Ventelo AS',
    },
    {
      mcc: '242',
      mnc: '017',
      network: 'Ventelo AS',
    },
  ],
  _244FI: [
    {
      mcc: '244',
      mnc: '14',
      network: 'Alands',
    },
    {
      mcc: '244',
      mnc: '26',
      network: 'Compatel Ltd',
    },
    {
      mcc: '244',
      mnc: '13',
      network: 'DNA/Finnet',
    },
    {
      mcc: '244',
      mnc: '12',
      network: 'DNA/Finnet',
    },
    {
      mcc: '244',
      mnc: '04',
      network: 'DNA/Finnet',
    },
    {
      mcc: '244',
      mnc: '03',
      network: 'DNA/Finnet',
    },
    {
      mcc: '244',
      mnc: '21',
      network: 'Elisa/Saunalahti',
    },
    {
      mcc: '244',
      mnc: '05',
      network: 'Elisa/Saunalahti',
    },
    {
      mcc: '244',
      mnc: '82',
      network: 'ID-Mobile',
    },
    {
      mcc: '244',
      mnc: '11',
      network: 'Mundio Mobile (Finland) Ltd',
    },
    {
      mcc: '244',
      mnc: '09',
      network: 'Nokia Oyj',
    },
    {
      mcc: '244',
      mnc: '10',
      network: 'TDC Oy Finland',
    },
    {
      mcc: '244',
      mnc: '91',
      network: 'TeliaSonera',
    },
  ],
  _246LT: [
    {
      mcc: '246',
      mnc: '02',
      network: 'Bite',
    },
    {
      mcc: '246',
      mnc: '01',
      network: 'Omnitel',
    },
    {
      mcc: '246',
      mnc: '03',
      network: 'Tele2',
    },
  ],
  _247LV: [
    {
      mcc: '247',
      mnc: '05',
      network: 'Bite',
    },
    {
      mcc: '247',
      mnc: '01',
      network: 'Latvian Mobile Phone',
    },
    {
      mcc: '247',
      mnc: '09',
      network: 'SIA Camel Mobile',
    },
    {
      mcc: '247',
      mnc: '08',
      network: 'SIA IZZI',
    },
    {
      mcc: '247',
      mnc: '07',
      network: 'SIA Master Telecom',
    },
    {
      mcc: '247',
      mnc: '06',
      network: 'SIA Rigatta',
    },
    {
      mcc: '247',
      mnc: '02',
      network: 'Tele2',
    },
    {
      mcc: '247',
      mnc: '03',
      network: 'TRIATEL/Telekom Baltija',
    },
  ],
  _248EE: [
    {
      mcc: '248',
      mnc: '01',
      network: 'EMT GSM',
    },
    {
      mcc: '248',
      mnc: '02',
      network: 'Radiolinja Eesti',
    },
    {
      mcc: '248',
      mnc: '03',
      network: 'Tele2 Eesti AS',
    },
    {
      mcc: '248',
      mnc: '04',
      network: 'Top Connect OU',
    },
  ],
  _250RU: [
    {
      mcc: '250',
      mnc: '12',
      network: 'Baykal Westcom',
    },
    {
      mcc: '250',
      mnc: '99',
      network: 'BeeLine/VimpelCom',
    },
    {
      mcc: '250',
      mnc: '28',
      network: 'BeeLine/VimpelCom',
    },
    {
      mcc: '250',
      mnc: '10',
      network: 'DTC/Don Telecom',
    },
    {
      mcc: '250',
      mnc: '13',
      network: 'Kuban GSM',
    },
    {
      mcc: '250',
      mnc: '35',
      network: 'MOTIV/LLC Ekaterinburg-2000',
    },
    {
      mcc: '250',
      mnc: '02',
      network: 'Megafon',
    },
    {
      mcc: '250',
      mnc: '01',
      network: 'MTS',
    },
    {
      mcc: '250',
      mnc: '03',
      network: 'NCC',
    },
    {
      mcc: '250',
      mnc: '16',
      network: 'NTC',
    },
    {
      mcc: '250',
      mnc: '19',
      network: 'OJSC Altaysvyaz',
    },
    {
      mcc: '250',
      mnc: '11',
      network: 'Orensot',
    },
    {
      mcc: '250',
      mnc: '92',
      network: 'Printelefone',
    },
    {
      mcc: '250',
      mnc: '04',
      network: 'Sibchallenge',
    },
    {
      mcc: '250',
      mnc: '44',
      network: 'StavTelesot',
    },
    {
      mcc: '250',
      mnc: '20',
      network: 'Tele2/ECC/Volgogr.',
    },
    {
      mcc: '250',
      mnc: '93',
      network: 'Telecom XXL',
    },
    {
      mcc: '250',
      mnc: '39',
      network: 'UralTel',
    },
    {
      mcc: '250',
      mnc: '17',
      network: 'UralTel',
    },
    {
      mcc: '250',
      mnc: '05',
      network: 'Tele2/ECC/Volgogr.',
    },
    {
      mcc: '250',
      mnc: '15',
      network: 'ZAO SMARTS',
    },
    {
      mcc: '250',
      mnc: '07',
      network: 'ZAO SMARTS',
    },
  ],
  _255UA: [
    {
      mcc: '255',
      mnc: '06',
      network: 'Astelit/LIFE',
    },
    {
      mcc: '255',
      mnc: '05',
      network: 'Golden Telecom',
    },
    {
      mcc: '255',
      mnc: '39',
      network: 'Golden Telecom',
    },
    {
      mcc: '255',
      mnc: '04',
      network: 'Intertelecom Ltd (IT)',
    },
    {
      mcc: '255',
      mnc: '67',
      network: 'KyivStar',
    },
    {
      mcc: '255',
      mnc: '03',
      network: 'KyivStar',
    },
    {
      mcc: '255',
      mnc: '99',
      network: 'Phoenix',
    },
    {
      mcc: '255',
      mnc: '21',
      network: 'Telesystems Of Ukraine CJSC (TSU)',
    },
    {
      mcc: '255',
      mnc: '07',
      network: 'TriMob LLC',
    },
    {
      mcc: '255',
      mnc: '50',
      network: 'Vodafone/MTS',
    },
    {
      mcc: '255',
      mnc: '02',
      network: 'Beeline',
    },
    {
      mcc: '255',
      mnc: '01',
      network: 'Vodafone/MTS',
    },
    {
      mcc: '255',
      mnc: '68',
      network: 'Beeline',
    },
  ],
  _257BY: [
    {
      mcc: '257',
      mnc: '03',
      network: 'BelCel JV',
    },
    {
      mcc: '257',
      mnc: '04',
      network: 'BeST',
    },
    {
      mcc: '257',
      mnc: '01',
      network: 'MDC/Velcom',
    },
    {
      mcc: '257',
      mnc: '02',
      network: 'MTS',
    },
  ],
  _259MD: [
    {
      mcc: '259',
      mnc: '04',
      network: 'Eventis Mobile',
    },
    {
      mcc: '259',
      mnc: '99',
      network: 'IDC/Unite ',
    },
    {
      mcc: '259',
      mnc: '05',
      network: 'IDC/Unite ',
    },
    {
      mcc: '259',
      mnc: '03',
      network: 'IDC/Unite ',
    },
    {
      mcc: '259',
      mnc: '02',
      network: 'Moldcell',
    },
    {
      mcc: '259',
      mnc: '01',
      network: 'Orange/Voxtel',
    },
  ],
  _260PL: [
    {
      mcc: '260',
      mnc: '17',
      network: 'Aero2 SP',
    },
    {
      mcc: '260',
      mnc: '18',
      network: 'AMD Telecom.',
    },
    {
      mcc: '260',
      mnc: '38',
      network: 'CallFreedom Sp. z o.o.',
    },
    {
      mcc: '260',
      mnc: '12',
      network: 'Cyfrowy POLSAT S.A.',
    },
    {
      mcc: '260',
      mnc: '08',
      network: 'e-Telko',
    },
    {
      mcc: '260',
      mnc: '09',
      network: 'Lycamobile',
    },
    {
      mcc: '260',
      mnc: '16',
      network: 'Mobyland',
    },
    {
      mcc: '260',
      mnc: '36',
      network: 'Mundio Mobile Sp. z o.o.',
    },
    {
      mcc: '260',
      mnc: '07',
      network: 'Play/P4',
    },
    {
      mcc: '260',
      mnc: '11',
      network: 'NORDISK Polska',
    },
    {
      mcc: '260',
      mnc: '05',
      network: 'Orange/IDEA/Centertel',
    },
    {
      mcc: '260',
      mnc: '03',
      network: 'Orange/IDEA/Centertel',
    },
    {
      mcc: '260',
      mnc: '35',
      network: 'PKP Polskie Linie Kolejowe S.A.',
    },
    {
      mcc: '260',
      mnc: '98',
      network: 'Play/P4',
    },
    {
      mcc: '260',
      mnc: '06',
      network: 'Play/P4',
    },
    {
      mcc: '260',
      mnc: '01',
      network: 'Polkomtel/Plus',
    },
    {
      mcc: '260',
      mnc: '14',
      network: 'Sferia',
    },
    {
      mcc: '260',
      mnc: '13',
      network: 'Sferia',
    },
    {
      mcc: '260',
      mnc: '10',
      network: 'Sferia',
    },
    {
      mcc: '260',
      mnc: '34',
      network: 'T-Mobile/ERA',
    },
    {
      mcc: '260',
      mnc: '02',
      network: 'T-Mobile/ERA',
    },
    {
      mcc: '260',
      mnc: '04',
      network: 'Aero2',
    },
    {
      mcc: '260',
      mnc: '15',
      network: 'Aero2',
    },
    {
      mcc: '260',
      mnc: '45',
      network: 'Virgin Mobile',
    },
  ],
  _262DE: [
    {
      mcc: '262',
      mnc: '17',
      network: 'E-Plus',
    },
    {
      mcc: '262',
      mnc: '10',
      network: 'DB Netz AG',
    },
    {
      mcc: '262',
      mnc: '',
      network: 'Debitel',
    },
    {
      mcc: '262',
      mnc: '03',
      network: 'E-Plus',
    },
    {
      mcc: '262',
      mnc: '05',
      network: 'E-Plus',
    },
    {
      mcc: '262',
      mnc: '20',
      network: 'E-Plus',
    },
    {
      mcc: '262',
      mnc: '77',
      network: 'E-Plus',
    },
    {
      mcc: '262',
      mnc: '12',
      network: 'E-Plus',
    },
    {
      mcc: '262',
      mnc: '14',
      network: 'Group 3G UMTS',
    },
    {
      mcc: '262',
      mnc: '43',
      network: 'Lycamobile',
    },
    {
      mcc: '262',
      mnc: '13',
      network: 'Mobilcom',
    },
    {
      mcc: '262',
      mnc: '07',
      network: 'O2',
    },
    {
      mcc: '262',
      mnc: '11',
      network: 'O2',
    },
    {
      mcc: '262',
      mnc: '08',
      network: 'O2',
    },
    {
      mcc: '262',
      mnc: '33',
      network: 'Sipgate',
    },
    {
      mcc: '262',
      mnc: '22',
      network: 'Sipgate',
    },
    {
      mcc: '262',
      mnc: '',
      network: 'Talkline',
    },
    {
      mcc: '262',
      mnc: '06',
      network: 'T-mobile/Telekom',
    },
    {
      mcc: '262',
      mnc: '01',
      network: 'T-mobile/Telekom',
    },
    {
      mcc: '262',
      mnc: '16',
      network: 'Telogic/ViStream',
    },
    {
      mcc: '262',
      mnc: '02',
      network: 'Vodafone D2',
    },
    {
      mcc: '262',
      mnc: '09',
      network: 'Vodafone D2',
    },
    {
      mcc: '262',
      mnc: '04',
      network: 'Vodafone D2',
    },
    {
      mcc: '262',
      mnc: '42',
      network: 'Vodafone D2',
    },
  ],
  _266GI: [
    {
      mcc: '266',
      mnc: '06',
      network: 'CTS Mobile',
    },
    {
      mcc: '266',
      mnc: '09',
      network: 'eazi telecom',
    },
    {
      mcc: '266',
      mnc: '01',
      network: 'Gibtel GSM',
    },
  ],
  _268PT: [
    {
      mcc: '268',
      mnc: '04',
      network: 'Lycamobile',
    },
    {
      mcc: '268',
      mnc: '03',
      network: 'NOS/Optimus',
    },
    {
      mcc: '268',
      mnc: '07',
      network: 'NOS/Optimus',
    },
    {
      mcc: '268',
      mnc: '06',
      network: 'MEO/TMN',
    },
    {
      mcc: '268',
      mnc: '01',
      network: 'Vodafone',
    },
  ],
  _270LU: [
    {
      mcc: '270',
      mnc: '77',
      network: 'Millicom Tango GSM',
    },
    {
      mcc: '270',
      mnc: '01',
      network: 'P+T/Post LUXGSM',
    },
    {
      mcc: '270',
      mnc: '99',
      network: 'Orange/VOXmobile S.A.',
    },
  ],
  _272IE: [
    {
      mcc: '272',
      mnc: '04',
      network: 'Access Telecom Ltd.',
    },
    {
      mcc: '272',
      mnc: '09',
      network: 'Clever Communications Ltd',
    },
    {
      mcc: '272',
      mnc: '07',
      network: 'eircom Ltd',
    },
    {
      mcc: '272',
      mnc: '11',
      network: 'Tesco Mobile/Liffey Telecom',
    },
    {
      mcc: '272',
      mnc: '13',
      network: 'Lycamobile',
    },
    {
      mcc: '272',
      mnc: '03',
      network: 'Meteor Mobile Ltd.',
    },
    {
      mcc: '272',
      mnc: '05',
      network: 'Three',
    },
    {
      mcc: '272',
      mnc: '17',
      network: 'Three',
    },
    {
      mcc: '272',
      mnc: '02',
      network: 'Three',
    },
    {
      mcc: '272',
      mnc: '15',
      network: 'Virgin Mobile',
    },
    {
      mcc: '272',
      mnc: '01',
      network: 'Vodafone Eircell',
    },
  ],
  _274IS: [
    {
      mcc: '274',
      mnc: '09',
      network: 'Amitelo',
    },
    {
      mcc: '274',
      mnc: '07',
      network: 'IceCell',
    },
    {
      mcc: '274',
      mnc: '08',
      network: 'Siminn',
    },
    {
      mcc: '274',
      mnc: '01',
      network: 'Siminn',
    },
    {
      mcc: '274',
      mnc: '11',
      network: 'NOVA',
    },
    {
      mcc: '274',
      mnc: '04',
      network: 'VIKING/IMC',
    },
    {
      mcc: '274',
      mnc: '03',
      network: 'Vodafone/Tal hf',
    },
    {
      mcc: '274',
      mnc: '02',
      network: 'Vodafone/Tal hf',
    },
    {
      mcc: '274',
      mnc: '05',
      network: 'Vodafone/Tal hf',
    },
  ],
  _276AL: [
    {
      mcc: '276',
      mnc: '01',
      network: 'AMC/Cosmote',
    },
    {
      mcc: '276',
      mnc: '03',
      network: 'Eagle Mobile',
    },
    {
      mcc: '276',
      mnc: '04',
      network: 'PLUS Communication Sh.a',
    },
    {
      mcc: '276',
      mnc: '02',
      network: 'Vodafone ',
    },
  ],
  _278MT: [
    {
      mcc: '278',
      mnc: '21',
      network: 'GO Mobile',
    },
    {
      mcc: '278',
      mnc: '77',
      network: 'Melita',
    },
    {
      mcc: '278',
      mnc: '01',
      network: 'Vodafone',
    },
  ],
  _280CY: [
    {
      mcc: '280',
      mnc: '10',
      network: 'MTN/Areeba',
    },
    {
      mcc: '280',
      mnc: '20',
      network: 'PrimeTel PLC',
    },
    {
      mcc: '280',
      mnc: '01',
      network: 'Vodafone/CyTa',
    },
  ],
  _282GE: [
    {
      mcc: '282',
      mnc: '01',
      network: 'Geocell Ltd.',
    },
    {
      mcc: '282',
      mnc: '03',
      network: 'Iberiatel Ltd.',
    },
    {
      mcc: '282',
      mnc: '02',
      network: 'Magti GSM Ltd.',
    },
    {
      mcc: '282',
      mnc: '04',
      network: 'MobiTel/Beeline',
    },
    {
      mcc: '282',
      mnc: '05',
      network: 'Silknet',
    },
  ],
  _283AM: [
    {
      mcc: '283',
      mnc: '01',
      network: 'ArmenTel/Beeline',
    },
    {
      mcc: '283',
      mnc: '04',
      network: 'Karabakh Telecom',
    },
    {
      mcc: '283',
      mnc: '10',
      network: 'Orange',
    },
    {
      mcc: '283',
      mnc: '05',
      network: 'Vivacell',
    },
  ],
  _284BG: [
    {
      mcc: '284',
      mnc: '06',
      network: 'BTC Mobile EOOD (vivatel)',
    },
    {
      mcc: '284',
      mnc: '03',
      network: 'BTC Mobile EOOD (vivatel)',
    },
    {
      mcc: '284',
      mnc: '05',
      network: 'Telenor/Cosmo/Globul',
    },
    {
      mcc: '284',
      mnc: '01',
      network: 'MobilTel AD',
    },
  ],
  _286TR: [
    {
      mcc: '286',
      mnc: '03',
      network: 'AVEA/Aria',
    },
    {
      mcc: '286',
      mnc: '04',
      network: 'AVEA/Aria',
    },
    {
      mcc: '286',
      mnc: '01',
      network: 'Turkcell',
    },
    {
      mcc: '286',
      mnc: '02',
      network: 'Vodafone-Telsim',
    },
  ],
  _288FO: [
    {
      mcc: '288',
      mnc: '03',
      network: 'Edge Mobile Sp/F',
    },
    {
      mcc: '288',
      mnc: '01',
      network: 'Faroese Telecom',
    },
    {
      mcc: '288',
      mnc: '02',
      network: 'Kall GSM',
    },
  ],
  _289ABKHAZIA: [
    {
      mcc: '289',
      mnc: '88',
      network: 'A-Mobile',
    },
    {
      mcc: '289',
      mnc: '68',
      network: 'A-Mobile',
    },
    {
      mcc: '289',
      mnc: '67',
      network: 'Aquafon',
    },
  ],
  _290GL: [
    {
      mcc: '290',
      mnc: '01',
      network: 'Tele Greenland',
    },
  ],
  _292SM: [
    {
      mcc: '292',
      mnc: '01',
      network: 'Prima Telecom',
    },
  ],
  _293SI: [
    {
      mcc: '293',
      mnc: '41',
      network: 'Mobitel',
    },
    {
      mcc: '293',
      mnc: '40',
      network: 'SI.Mobil',
    },
    {
      mcc: '293',
      mnc: '10',
      network: 'Slovenske zeleznice d.o.o.',
    },
    {
      mcc: '293',
      mnc: '64',
      network: 'T-2 d.o.o.',
    },
    {
      mcc: '293',
      mnc: '70',
      network: 'Telemach/TusMobil/VEGA',
    },
  ],
  _294MK: [
    {
      mcc: '294',
      mnc: '75',
      network: 'ONE/Cosmofone',
    },
    {
      mcc: '294',
      mnc: '04',
      network: 'Lycamobile',
    },
    {
      mcc: '294',
      mnc: '02',
      network: 'ONE/Cosmofone',
    },
    {
      mcc: '294',
      mnc: '01',
      network: 'T-Mobile/Mobimak',
    },
    {
      mcc: '294',
      mnc: '03',
      network: 'VIP Mobile',
    },
  ],
  _295LI: [
    {
      mcc: '295',
      mnc: '06',
      network: 'CUBIC (Liechtenstein',
    },
    {
      mcc: '295',
      mnc: '07',
      network: 'First Mobile AG',
    },
    {
      mcc: '295',
      mnc: '02',
      network: 'Orange',
    },
    {
      mcc: '295',
      mnc: '01',
      network: 'Swisscom FL AG',
    },
    {
      mcc: '295',
      mnc: '77',
      network: 'Alpmobile/Tele2',
    },
    {
      mcc: '295',
      mnc: '05',
      network: 'Telecom FL1 AG',
    },
  ],
  _297ME: [
    {
      mcc: '297',
      mnc: '02',
      network: 'Monet/T-mobile',
    },
    {
      mcc: '297',
      mnc: '03',
      network: 'Mtel',
    },
    {
      mcc: '297',
      mnc: '01',
      network: 'Telenor/Promonte GSM',
    },
  ],
  _302CA: [
    {
      mcc: '302',
      mnc: '652',
      network: 'BC Tel Mobility',
    },
    {
      mcc: '302',
      mnc: '630',
      network: 'Bell Aliant',
    },
    {
      mcc: '302',
      mnc: '610',
      network: 'Bell Mobility',
    },
    {
      mcc: '302',
      mnc: '651',
      network: 'Bell Mobility',
    },
    {
      mcc: '302',
      mnc: '670',
      network: 'CityWest Mobility',
    },
    {
      mcc: '302',
      mnc: '361',
      network: 'Clearnet',
    },
    {
      mcc: '302',
      mnc: '360',
      network: 'Clearnet',
    },
    {
      mcc: '302',
      mnc: '380',
      network: 'DMTS Mobility',
    },
    {
      mcc: '302',
      mnc: '710',
      network: 'Globalstar Canada',
    },
    {
      mcc: '302',
      mnc: '640',
      network: 'Latitude Wireless',
    },
    {
      mcc: '302',
      mnc: '370',
      network: 'FIDO (Rogers AT&amp;T/ Microcell)',
    },
    {
      mcc: '302',
      mnc: '320',
      network: 'mobilicity',
    },
    {
      mcc: '302',
      mnc: '702',
      network: 'MT&amp;T Mobility',
    },
    {
      mcc: '302',
      mnc: '655',
      network: 'MTS Mobility',
    },
    {
      mcc: '302',
      mnc: '660',
      network: 'MTS Mobility',
    },
    {
      mcc: '302',
      mnc: '701',
      network: 'NB Tel Mobility',
    },
    {
      mcc: '302',
      mnc: '703',
      network: 'New Tel Mobility',
    },
    {
      mcc: '302',
      mnc: '760',
      network: 'Public Mobile',
    },
    {
      mcc: '302',
      mnc: '657',
      network: 'Quebectel Mobility',
    },
    {
      mcc: '302',
      mnc: '720',
      network: 'Rogers AT&amp;T Wireless',
    },
    {
      mcc: '302',
      mnc: '654',
      network: 'Sask Tel Mobility',
    },
    {
      mcc: '302',
      mnc: '780',
      network: 'Sask Tel Mobility',
    },
    {
      mcc: '302',
      mnc: '680',
      network: 'Sask Tel Mobility',
    },
    {
      mcc: '302',
      mnc: '656',
      network: 'Tbay Mobility',
    },
    {
      mcc: '302',
      mnc: '220',
      network: 'Telus Mobility',
    },
    {
      mcc: '302',
      mnc: '653',
      network: 'Telus Mobility',
    },
    {
      mcc: '302',
      mnc: '500',
      network: 'Videotron',
    },
    {
      mcc: '302',
      mnc: '490',
      network: 'WIND',
    },
  ],
  _308PM: [
    {
      mcc: '308',
      mnc: '01',
      network: 'Ameris',
    },
  ],
  _310GU: [
    {
      mcc: '310',
      mnc: '370',
      network: 'Docomo',
    },
    {
      mcc: '310',
      mnc: '470',
      network: 'Docomo',
    },
    {
      mcc: '310',
      mnc: '140',
      network: 'GTA Wireless',
    },
    {
      mcc: '310',
      mnc: '033',
      network: 'Guam Teleph. Auth',
    },
    {
      mcc: '310',
      mnc: '032',
      network: 'IT&amp;E OverSeas',
    },
  ],
  _310US: [
    {
      mcc: '310',
      mnc: '850',
      network: 'Aeris Comm. Inc.',
    },
    {
      mcc: '310',
      mnc: '640',
      network: '',
    },
    {
      mcc: '310',
      mnc: '510',
      network: 'Airtel Wireless LLC',
    },
    {
      mcc: '310',
      mnc: '190',
      network: 'Unknown',
    },
    {
      mcc: '310',
      mnc: '710',
      network: 'Arctic Slope Telephone Association Cooperative Inc.',
    },
    {
      mcc: '310',
      mnc: '680',
      network: 'AT&amp;T Wireless Inc.',
    },
    {
      mcc: '310',
      mnc: '070',
      network: 'AT&amp;T Wireless Inc.',
    },
    {
      mcc: '310',
      mnc: '560',
      network: 'AT&amp;T Wireless Inc.',
    },
    {
      mcc: '310',
      mnc: '410',
      network: 'AT&amp;T Wireless Inc.',
    },
    {
      mcc: '310',
      mnc: '380',
      network: 'AT&amp;T Wireless Inc.',
    },
    {
      mcc: '310',
      mnc: '170',
      network: 'AT&amp;T Wireless Inc.',
    },
    {
      mcc: '310',
      mnc: '150',
      network: 'AT&amp;T Wireless Inc.',
    },
    {
      mcc: '310',
      mnc: '980',
      network: 'AT&amp;T Wireless Inc.',
    },
    {
      mcc: '310',
      mnc: '900',
      network: 'Cable &amp; Communications Corp.',
    },
    {
      mcc: '310',
      mnc: '830',
      network: 'Caprock Cellular Ltd.',
    },
    {
      mcc: '310',
      mnc: '013',
      network: 'Verizon Wireless',
    },
    {
      mcc: '310',
      mnc: '012',
      network: 'Verizon Wireless',
    },
    {
      mcc: '310',
      mnc: '010',
      network: 'Verizon Wireless',
    },
    {
      mcc: '310',
      mnc: '910',
      network: 'Verizon Wireless',
    },
    {
      mcc: '310',
      mnc: '004',
      network: 'Verizon Wireless',
    },
    {
      mcc: '310',
      mnc: '890',
      network: 'Verizon Wireless',
    },
    {
      mcc: '310',
      mnc: '590',
      network: 'Verizon Wireless',
    },
    {
      mcc: '310',
      mnc: '360',
      network: 'Cellular Network Partnership LLC',
    },
    {
      mcc: '310',
      mnc: '030',
      network: '',
    },
    {
      mcc: '310',
      mnc: '480',
      network: 'Choice Phone LLC',
    },
    {
      mcc: '310',
      mnc: '630',
      network: '',
    },
    {
      mcc: '310',
      mnc: '420',
      network: 'Cincinnati Bell Wireless LLC',
    },
    {
      mcc: '310',
      mnc: '180',
      network: 'Cingular Wireless',
    },
    {
      mcc: '310',
      mnc: '620',
      network: 'Coleman County Telco /Trans TX',
    },
    {
      mcc: '310',
      mnc: '06',
      network: 'Consolidated Telcom',
    },
    {
      mcc: '310',
      mnc: '60',
      network: 'Consolidated Telcom',
    },
    {
      mcc: '310',
      mnc: '26',
      network: '',
    },
    {
      mcc: '310',
      mnc: '930',
      network: '',
    },
    {
      mcc: '310',
      mnc: '080',
      network: '',
    },
    {
      mcc: '310',
      mnc: '700',
      network: 'Cross Valliant Cellular Partnership',
    },
    {
      mcc: '310',
      mnc: '440',
      network: 'Dobson Cellular Systems',
    },
    {
      mcc: '310',
      mnc: '990',
      network: 'E.N.M.R. Telephone Coop.',
    },
    {
      mcc: '310',
      mnc: '750',
      network: 'East Kentucky Network LLC',
    },
    {
      mcc: '310',
      mnc: '090',
      network: 'Edge Wireless LLC',
    },
    {
      mcc: '310',
      mnc: '610',
      network: 'Elkhart TelCo. / Epic Touch Co.',
    },
    {
      mcc: '310',
      mnc: '430',
      network: 'GCI Communication Corp.',
    },
    {
      mcc: '310',
      mnc: '920',
      network: 'Get Mobile Inc.',
    },
    {
      mcc: '310',
      mnc: '970',
      network: '',
    },
    {
      mcc: '310',
      mnc: '770',
      network: 'Iowa Wireless Services LLC',
    },
    {
      mcc: '310',
      mnc: '650',
      network: 'Jasper',
    },
    {
      mcc: '310',
      mnc: '870',
      network: 'Kaplan Telephone Company Inc.',
    },
    {
      mcc: '310',
      mnc: '690',
      network: 'Keystone Wireless LLC',
    },
    {
      mcc: '310',
      mnc: '016',
      network: 'Leap Wireless International Inc.',
    },
    {
      mcc: '310',
      mnc: '040',
      network: 'Matanuska Tel. Assn. Inc.',
    },
    {
      mcc: '310',
      mnc: '780',
      network: 'Message Express Co. / Airlink PCS',
    },
    {
      mcc: '310',
      mnc: '400',
      network: 'Minnesota South. Wirel. Co. / Hickory',
    },
    {
      mcc: '310',
      mnc: '350',
      network: 'Mohave Cellular LP',
    },
    {
      mcc: '310',
      mnc: '570',
      network: 'MTPCS LLC',
    },
    {
      mcc: '310',
      mnc: '290',
      network: 'NEP Cellcorp Inc.',
    },
    {
      mcc: '310',
      mnc: '34',
      network: 'Nevada Wireless LLC',
    },
    {
      mcc: '310',
      mnc: '600',
      network: 'New-Cell Inc.',
    },
    {
      mcc: '310',
      mnc: '130',
      network: 'North Carolina RSA 3 Cellular Tel. Co.',
    },
    {
      mcc: '310',
      mnc: '450',
      network: 'Northeast Colorado Cellular Inc.',
    },
    {
      mcc: '310',
      mnc: '670',
      network: 'Northstar',
    },
    {
      mcc: '310',
      mnc: '011',
      network: 'Northstar',
    },
    {
      mcc: '310',
      mnc: '540',
      network: '',
    },
    {
      mcc: '310',
      mnc: '999',
      network: 'Various Networks',
    },
    {
      mcc: '310',
      mnc: '760',
      network: 'Panhandle Telephone Cooperative Inc.',
    },
    {
      mcc: '310',
      mnc: '580',
      network: 'PCS ONE',
    },
    {
      mcc: '310',
      mnc: '790',
      network: '',
    },
    {
      mcc: '310',
      mnc: '100',
      network: 'Plateau Telecommunications Inc.',
    },
    {
      mcc: '310',
      mnc: '940',
      network: 'Poka Lambro Telco Ltd.',
    },
    {
      mcc: '310',
      mnc: '500',
      network: 'Public Service Cellular Inc.',
    },
    {
      mcc: '310',
      mnc: '46',
      network: 'SIMMETRY',
    },
    {
      mcc: '310',
      mnc: '320',
      network: 'Smith Bagley Inc.',
    },
    {
      mcc: '310',
      mnc: '15',
      network: 'Unknown',
    },
    {
      mcc: '310',
      mnc: '120',
      network: 'Sprint Spectrum',
    },
    {
      mcc: '310',
      mnc: '200',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '250',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '160',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '240',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '660',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '230',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '31',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '220',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '270',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '210',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '260',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '300',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '280',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '330',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '800',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '310',
      network: 'T-Mobile',
    },
    {
      mcc: '310',
      mnc: '740',
      network: 'Telemetrix Inc.',
    },
    {
      mcc: '310',
      mnc: '14',
      network: 'Testing',
    },
    {
      mcc: '310',
      mnc: '950',
      network: 'Unknown',
    },
    {
      mcc: '310',
      mnc: '860',
      network: 'Texas RSA 15B2 Limited Partnership',
    },
    {
      mcc: '310',
      mnc: '460',
      network: 'TMP Corporation',
    },
    {
      mcc: '310',
      mnc: '490',
      network: 'Triton PCS',
    },
    {
      mcc: '310',
      mnc: '960',
      network: 'Uintah Basin Electronics Telecommunications Inc.',
    },
    {
      mcc: '310',
      mnc: '020',
      network: 'Union Telephone Co.',
    },
    {
      mcc: '310',
      mnc: '730',
      network: 'United States Cellular Corp.',
    },
    {
      mcc: '310',
      mnc: '38',
      network: 'USA 3650 AT&amp;T',
    },
    {
      mcc: '310',
      mnc: '520',
      network: 'VeriSign',
    },
    {
      mcc: '310',
      mnc: '003',
      network: 'Unknown',
    },
    {
      mcc: '310',
      mnc: '23',
      network: 'Unknown',
    },
    {
      mcc: '310',
      mnc: '24',
      network: 'Unknown',
    },
    {
      mcc: '310',
      mnc: '25',
      network: 'Unknown',
    },
    {
      mcc: '310',
      mnc: '530',
      network: 'West Virginia Wireless',
    },
    {
      mcc: '310',
      mnc: '26',
      network: 'Unknown',
    },
    {
      mcc: '310',
      mnc: '340',
      network: 'Westlink Communications, LLC',
    },
    {
      mcc: '310',
      mnc: '390',
      network: 'Yorkville Telephone Cooperative',
    },
  ],
  _311GU: [
    {
      mcc: '311',
      mnc: '250',
      network: 'Wave Runner LLC',
    },
  ],
  _311US: [
    {
      mcc: '311',
      mnc: '130',
      network: '',
    },
    {
      mcc: '311',
      mnc: '810',
      network: 'Bluegrass Wireless LLC',
    },
    {
      mcc: '311',
      mnc: '800',
      network: 'Bluegrass Wireless LLC',
    },
    {
      mcc: '311',
      mnc: '440',
      network: 'Bluegrass Wireless LLC',
    },
    {
      mcc: '311',
      mnc: '590',
      network: 'California RSA No. 3 Limited Partnership',
    },
    {
      mcc: '311',
      mnc: '500',
      network: 'Cambridge Telephone Company Inc.',
    },
    {
      mcc: '311',
      mnc: '281',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '486',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '286',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '270',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '275',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '480',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '280',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '485',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '285',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '110',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '274',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '390',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '279',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '484',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '284',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '489',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '273',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '289',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '278',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '483',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '283',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '488',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '272',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '288',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '277',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '482',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '282',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '487',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '287',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '271',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '276',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '481',
      network: 'Verizon Wireless',
    },
    {
      mcc: '311',
      mnc: '190',
      network: '',
    },
    {
      mcc: '311',
      mnc: '120',
      network: 'Choice Phone LLC',
    },
    {
      mcc: '311',
      mnc: '040',
      network: '',
    },
    {
      mcc: '311',
      mnc: '240',
      network: '',
    },
    {
      mcc: '311',
      mnc: '140',
      network: 'Cross Wireless Telephone Co.',
    },
    {
      mcc: '311',
      mnc: '520',
      network: '',
    },
    {
      mcc: '311',
      mnc: '210',
      network: '',
    },
    {
      mcc: '311',
      mnc: '311',
      network: 'Farmers',
    },
    {
      mcc: '311',
      mnc: '460',
      network: 'Fisher Wireless Services Inc.',
    },
    {
      mcc: '311',
      mnc: '370',
      network: 'GCI Communication Corp.',
    },
    {
      mcc: '311',
      mnc: '340',
      network: 'Illinois Valley Cellular RSA 2 Partnership',
    },
    {
      mcc: '311',
      mnc: '030',
      network: '',
    },
    {
      mcc: '311',
      mnc: '410',
      network: 'Iowa RSA No. 2 Limited Partnership',
    },
    {
      mcc: '311',
      mnc: '310',
      network: 'Lamar County Cellular',
    },
    {
      mcc: '311',
      mnc: '090',
      network: '',
    },
    {
      mcc: '311',
      mnc: '660',
      network: '',
    },
    {
      mcc: '311',
      mnc: '330',
      network: 'Michigan Wireless LLC',
    },
    {
      mcc: '311',
      mnc: '000',
      network: '',
    },
    {
      mcc: '311',
      mnc: '920',
      network: 'Missouri RSA No 5 Partnership',
    },
    {
      mcc: '311',
      mnc: '020',
      network: 'Missouri RSA No 5 Partnership',
    },
    {
      mcc: '311',
      mnc: '010',
      network: 'Missouri RSA No 5 Partnership',
    },
    {
      mcc: '311',
      mnc: '380',
      network: '',
    },
    {
      mcc: '311',
      mnc: '100',
      network: '',
    },
    {
      mcc: '311',
      mnc: '300',
      network: 'Nexus Communications Inc.',
    },
    {
      mcc: '311',
      mnc: '610',
      network: 'North Dakota Network Company',
    },
    {
      mcc: '311',
      mnc: '710',
      network: 'Northeast Wireless Networks LLC',
    },
    {
      mcc: '311',
      mnc: '420',
      network: 'Northwest Missouri Cellular Limited Partnership',
    },
    {
      mcc: '311',
      mnc: '170',
      network: 'PetroCom',
    },
    {
      mcc: '311',
      mnc: '670',
      network: 'Pine Belt Cellular, Inc.',
    },
    {
      mcc: '311',
      mnc: '080',
      network: '',
    },
    {
      mcc: '311',
      mnc: '540',
      network: '',
    },
    {
      mcc: '311',
      mnc: '730',
      network: '',
    },
    {
      mcc: '311',
      mnc: '430',
      network: 'RSA 1 Limited Partnership',
    },
    {
      mcc: '311',
      mnc: '350',
      network: 'Sagebrush Cellular Inc.',
    },
    {
      mcc: '311',
      mnc: '910',
      network: '',
    },
    {
      mcc: '311',
      mnc: '260',
      network: 'SLO Cellular Inc / Cellular One of San Luis',
    },
    {
      mcc: '311',
      mnc: '490',
      network: 'Sprint Spectrum',
    },
    {
      mcc: '311',
      mnc: '880',
      network: 'Sprint Spectrum',
    },
    {
      mcc: '311',
      mnc: '870',
      network: 'Sprint Spectrum',
    },
    {
      mcc: '311',
      mnc: '740',
      network: '',
    },
    {
      mcc: '311',
      mnc: '050',
      network: 'Thumb Cellular Limited Partnership',
    },
    {
      mcc: '311',
      mnc: '830',
      network: 'Thumb Cellular Limited Partnership',
    },
    {
      mcc: '311',
      mnc: '860',
      network: 'Uintah Basin Electronics Telecommunications Inc.',
    },
    {
      mcc: '311',
      mnc: '220',
      network: 'United States Cellular Corp.',
    },
    {
      mcc: '311',
      mnc: '650',
      network: 'United Wireless Communications Inc.',
    },
    {
      mcc: '311',
      mnc: '150',
      network: '',
    },
    {
      mcc: '311',
      mnc: '070',
      network: 'Wisconsin RSA #7 Limited Partnership',
    },
  ],
  _312US: [
    {
      mcc: '312',
      mnc: '090',
      network: 'Allied Wireless Communications Corporation',
    },
    {
      mcc: '312',
      mnc: '270',
      network: 'Cellular Network Partnership LLC',
    },
    {
      mcc: '312',
      mnc: '280',
      network: 'Cellular Network Partnership LLC',
    },
    {
      mcc: '312',
      mnc: '380',
      network: '',
    },
    {
      mcc: '312',
      mnc: '030',
      network: 'Cross Wireless Telephone Co.',
    },
    {
      mcc: '312',
      mnc: '040',
      network: 'Custer Telephone Cooperative Inc.',
    },
    {
      mcc: '312',
      mnc: '130',
      network: 'East Kentucky Network LLC',
    },
    {
      mcc: '312',
      mnc: '120',
      network: 'East Kentucky Network LLC',
    },
    {
      mcc: '312',
      mnc: '170',
      network: 'Iowa RSA No. 2 Limited Partnership',
    },
    {
      mcc: '312',
      mnc: '180',
      network: 'Keystone Wireless LLC',
    },
    {
      mcc: '312',
      mnc: '010',
      network: 'Missouri RSA No 5 Partnership',
    },
    {
      mcc: '312',
      mnc: '220',
      network: 'Missouri RSA No 5 Partnership',
    },
    {
      mcc: '312',
      mnc: '230',
      network: 'North Dakota Network Company',
    },
    {
      mcc: '312',
      mnc: '160',
      network: 'RSA 1 Limited Partnership',
    },
    {
      mcc: '312',
      mnc: '530',
      network: 'Sprint Spectrum',
    },
    {
      mcc: '312',
      mnc: '190',
      network: 'Sprint Spectrum',
    },
    {
      mcc: '312',
      mnc: '290',
      network: 'Uintah Basin Electronics Telecommunications Inc.',
    },
  ],
  _316US: [
    {
      mcc: '316',
      mnc: '011',
      network: 'Southern Communications Services Inc.',
    },
    {
      mcc: '316',
      mnc: '010',
      network: 'Sprint Spectrum',
    },
  ],
  _330PR: [
    {
      mcc: '330',
      mnc: '110',
      network: 'Puerto Rico Telephone Company Inc. (PRTC)',
    },
    {
      mcc: '330',
      mnc: '11',
      network: 'Puerto Rico Telephone Company Inc. (PRTC)',
    },
  ],
  _334MX: [
    {
      mcc: '334',
      mnc: '50',
      network: 'AT&amp;T/IUSACell',
    },
    {
      mcc: '334',
      mnc: '050',
      network: 'AT&amp;T/IUSACell',
    },
    {
      mcc: '334',
      mnc: '040',
      network: 'AT&amp;T/IUSACell',
    },
    {
      mcc: '334',
      mnc: '05',
      network: 'AT&amp;T/IUSACell',
    },
    {
      mcc: '334',
      mnc: '04',
      network: 'AT&amp;T/IUSACell',
    },
    {
      mcc: '334',
      mnc: '030',
      network: 'Movistar/Pegaso',
    },
    {
      mcc: '334',
      mnc: '03',
      network: 'Movistar/Pegaso',
    },
    {
      mcc: '334',
      mnc: '090',
      network: 'NEXTEL',
    },
    {
      mcc: '334',
      mnc: '09',
      network: 'NEXTEL',
    },
    {
      mcc: '334',
      mnc: '010',
      network: 'NEXTEL',
    },
    {
      mcc: '334',
      mnc: '01',
      network: 'NEXTEL',
    },
    {
      mcc: '334',
      mnc: '080',
      network: 'Operadora Unefon SA de CV',
    },
    {
      mcc: '334',
      mnc: '070',
      network: 'Operadora Unefon SA de CV',
    },
    {
      mcc: '334',
      mnc: '060',
      network: 'SAI PCS',
    },
    {
      mcc: '334',
      mnc: '02',
      network: 'TelCel/America Movil',
    },
    {
      mcc: '334',
      mnc: '020',
      network: 'TelCel/America Movil',
    },
  ],
  _338JM: [
    {
      mcc: '338',
      mnc: '110',
      network: 'Cable &amp; Wireless',
    },
    {
      mcc: '338',
      mnc: '020',
      network: 'Cable &amp; Wireless',
    },
    {
      mcc: '338',
      mnc: '180',
      network: 'Cable &amp; Wireless',
    },
    {
      mcc: '338',
      mnc: '050',
      network: 'DIGICEL/Mossel',
    },
  ],
  _340GF: [
    {
      mcc: '340',
      mnc: '20',
      network: 'Bouygues/DigiCel',
    },
    {
      mcc: '340',
      mnc: '01',
      network: 'Orange Caribe',
    },
    {
      mcc: '340',
      mnc: '02',
      network: 'Outremer Telecom',
    },
    {
      mcc: '340',
      mnc: '11',
      network: 'TelCell GSM',
    },
    {
      mcc: '340',
      mnc: '03',
      network: 'TelCell GSM',
    },
  ],
  _340GP: [
    {
      mcc: '340',
      mnc: '08',
      network: 'Dauphin Telecom SU (Guadeloupe Telecom)',
    },
    {
      mcc: '340',
      mnc: '10',
      network: '',
    },
  ],
  _340MQ: [
    {
      mcc: '340',
      mnc: '12',
      network: 'UTS Caraibe',
    },
  ],
  _342BB: [
    {
      mcc: '342',
      mnc: '600',
      network: 'LIME',
    },
    {
      mcc: '342',
      mnc: '810',
      network: 'Cingular Wireless',
    },
    {
      mcc: '342',
      mnc: '750',
      network: 'Digicel',
    },
    {
      mcc: '342',
      mnc: '050',
      network: 'Digicel',
    },
    {
      mcc: '342',
      mnc: '820',
      network: 'Sunbeach',
    },
  ],
  _344AG: [
    {
      mcc: '344',
      mnc: '030',
      network: 'APUA PCS',
    },
    {
      mcc: '344',
      mnc: '920',
      network: 'C &amp; W',
    },
    {
      mcc: '344',
      mnc: '930',
      network: 'DigiCel/Cing. Wireless',
    },
  ],
  _346KY: [
    {
      mcc: '346',
      mnc: '050',
      network: 'Digicel Cayman Ltd',
    },
    {
      mcc: '346',
      mnc: '006',
      network: 'Digicel Ltd.',
    },
    {
      mcc: '346',
      mnc: '140',
      network: 'LIME / Cable &amp; Wirel.',
    },
  ],
  _348VG: [
    {
      mcc: '348',
      mnc: '570',
      network: 'Caribbean Cellular',
    },
    {
      mcc: '348',
      mnc: '770',
      network: 'Digicel',
    },
    {
      mcc: '348',
      mnc: '170',
      network: 'LIME',
    },
  ],
  _350BM: [
    {
      mcc: '350',
      mnc: '000',
      network: 'Bermuda Digital Communications Ltd (BDC)',
    },
    {
      mcc: '350',
      mnc: '99',
      network: 'CellOne Ltd',
    },
    {
      mcc: '350',
      mnc: '10',
      network: 'DigiCel / Cingular',
    },
    {
      mcc: '350',
      mnc: '02',
      network: 'M3 Wireless Ltd',
    },
    {
      mcc: '350',
      mnc: '01',
      network:
        'Telecommunications (Bermuda &amp; West Indies) Ltd (Digicel Bermuda)',
    },
  ],
  _352GD: [
    {
      mcc: '352',
      mnc: '110',
      network: 'Cable &amp; Wireless',
    },
    {
      mcc: '352',
      mnc: '030',
      network: 'Digicel',
    },
    {
      mcc: '352',
      mnc: '050',
      network: 'Digicel',
    },
  ],
  _354MS: [
    {
      mcc: '354',
      mnc: '860',
      network: 'Cable &amp; Wireless ',
    },
  ],
  _356KN: [
    {
      mcc: '356',
      mnc: '110',
      network: 'Cable &amp; Wireless',
    },
    {
      mcc: '356',
      mnc: '50',
      network: 'Digicel',
    },
    {
      mcc: '356',
      mnc: '70',
      network: 'UTS Cariglobe',
    },
  ],
  _358LC: [
    {
      mcc: '358',
      mnc: '110',
      network: 'Cable &amp; Wireless',
    },
    {
      mcc: '358',
      mnc: '30',
      network: 'Cingular Wireless',
    },
    {
      mcc: '358',
      mnc: '50',
      network: 'Digicel (St Lucia) Limited',
    },
  ],
  _360VC: [
    {
      mcc: '360',
      mnc: '110',
      network: 'C &amp; W',
    },
    {
      mcc: '360',
      mnc: '10',
      network: 'Cingular',
    },
    {
      mcc: '360',
      mnc: '100',
      network: 'Cingular',
    },
    {
      mcc: '360',
      mnc: '050',
      network: 'Digicel',
    },
    {
      mcc: '360',
      mnc: '70',
      network: 'Digicel',
    },
  ],
  _362AN: [
    {
      mcc: '362',
      mnc: '630',
      network: 'Cingular Wireless',
    },
    {
      mcc: '362',
      mnc: '51',
      network: 'TELCELL GSM',
    },
    {
      mcc: '362',
      mnc: '91',
      network: 'SETEL GSM',
    },
    {
      mcc: '362',
      mnc: '951',
      network: 'UTS Wireless',
    },
  ],
  _362CW: [
    {
      mcc: '362',
      mnc: '95',
      network: 'EOCG Wireless NV',
    },
    {
      mcc: '362',
      mnc: '69',
      network: 'Polycom N.V./ Digicel',
    },
  ],
  _363AW: [
    {
      mcc: '363',
      mnc: '02',
      network: 'Digicel',
    },
    {
      mcc: '363',
      mnc: '20',
      network: 'Digicel',
    },
    {
      mcc: '363',
      mnc: '01',
      network: 'Setar GSM',
    },
  ],
  _364BS: [
    {
      mcc: '364',
      mnc: '490',
      network: 'Aliv/Cable Bahamas',
    },
    {
      mcc: '364',
      mnc: '39',
      network: 'Bahamas Telco. Comp.',
    },
    {
      mcc: '364',
      mnc: '390',
      network: 'Bahamas Telco. Comp.',
    },
    {
      mcc: '364',
      mnc: '30',
      network: 'Bahamas Telco. Comp.',
    },
    {
      mcc: '364',
      mnc: '03',
      network: 'Smart Communications',
    },
  ],
  _365AI: [
    {
      mcc: '365',
      mnc: '840',
      network: 'Cable and Wireless',
    },
    {
      mcc: '365',
      mnc: '010',
      network: 'Digicell / Wireless Vent. Ltd ',
    },
  ],
  _366DM: [
    {
      mcc: '366',
      mnc: '110',
      network: 'C &amp; W',
    },
    {
      mcc: '366',
      mnc: '020',
      network: 'Cingular Wireless/Digicel',
    },
    {
      mcc: '366',
      mnc: '050',
      network: 'Wireless Ventures (Dominica) Ltd (Digicel Dominica)',
    },
  ],
  _368CU: [
    {
      mcc: '368',
      mnc: '01',
      network: 'CubaCel/C-COM',
    },
  ],
  _370DO: [
    {
      mcc: '370',
      mnc: '02',
      network: 'Claro',
    },
    {
      mcc: '370',
      mnc: '01',
      network: 'Orange',
    },
    {
      mcc: '370',
      mnc: '03',
      network: 'TRIcom',
    },
    {
      mcc: '370',
      mnc: '04',
      network: 'Viva',
    },
  ],
  _372HT: [
    {
      mcc: '372',
      mnc: '01',
      network: 'Comcel',
    },
    {
      mcc: '372',
      mnc: '02',
      network: 'Digicel',
    },
    {
      mcc: '372',
      mnc: '03',
      network: 'National Telecom SA (NatCom)',
    },
  ],
  _374TT: [
    {
      mcc: '374',
      mnc: '120',
      network: 'Bmobile/TSTT',
    },
    {
      mcc: '374',
      mnc: '12',
      network: 'Bmobile/TSTT',
    },
    {
      mcc: '374',
      mnc: '130',
      network: 'Digicel',
    },
    {
      mcc: '374',
      mnc: '140',
      network: 'LaqTel Ltd.',
    },
  ],
  _376TC: [
    {
      mcc: '376',
      mnc: '350',
      network: 'Cable &amp; Wireless (TCI) Ltd',
    },
    {
      mcc: '376',
      mnc: '050',
      network: 'Digicel TCI Ltd',
    },
    {
      mcc: '376',
      mnc: '352',
      network: 'IslandCom Communications Ltd.',
    },
  ],
  _376VI: [
    {
      mcc: '376',
      mnc: '50',
      network: 'Digicel',
    },
  ],
  _400AZ: [
    {
      mcc: '400',
      mnc: '01',
      network: 'Azercell Telekom B.M.',
    },
    {
      mcc: '400',
      mnc: '04',
      network: 'Azerfon.',
    },
    {
      mcc: '400',
      mnc: '03',
      network: 'CATEL',
    },
    {
      mcc: '400',
      mnc: '02',
      network: 'J.V. Bakcell GSM 2000',
    },
    {
      mcc: '400',
      mnc: '06',
      network: 'Naxtel',
    },
  ],
  _401KZ: [
    {
      mcc: '401',
      mnc: '01',
      network: 'Beeline/KaR-Tel LLP',
    },
    {
      mcc: '401',
      mnc: '07',
      network: 'Dalacom/Altel',
    },
    {
      mcc: '401',
      mnc: '02',
      network: 'K-Cell',
    },
    {
      mcc: '401',
      mnc: '77',
      network: 'Tele2/NEO/MTS',
    },
  ],
  _402BT: [
    {
      mcc: '402',
      mnc: '11',
      network: 'B-Mobile',
    },
    {
      mcc: '402',
      mnc: '17',
      network: 'Bhutan Telecom Ltd (BTL)',
    },
    {
      mcc: '402',
      mnc: '77',
      network: 'TashiCell',
    },
  ],
  _404IN: [
    {
      mcc: '404',
      mnc: '29',
      network: 'Aircel',
    },
    {
      mcc: '404',
      mnc: '28',
      network: 'Aircel',
    },
    {
      mcc: '404',
      mnc: '25',
      network: 'Aircel',
    },
    {
      mcc: '404',
      mnc: '17',
      network: 'Aircel',
    },
    {
      mcc: '404',
      mnc: '42',
      network: 'Aircel',
    },
    {
      mcc: '404',
      mnc: '33',
      network: 'Aircel',
    },
    {
      mcc: '404',
      mnc: '01',
      network: 'Aircel Digilink India',
    },
    {
      mcc: '404',
      mnc: '15',
      network: 'Aircel Digilink India',
    },
    {
      mcc: '404',
      mnc: '60',
      network: 'Aircel Digilink India',
    },
    {
      mcc: '404',
      mnc: '86',
      network: 'Barakhamba Sales &amp; Serv.',
    },
    {
      mcc: '404',
      mnc: '13',
      network: 'Barakhamba Sales &amp; Serv.',
    },
    {
      mcc: '404',
      mnc: '72',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '77',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '54',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '64',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '71',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '76',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '53',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '62',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '59',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '75',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '51',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '58',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '81',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '74',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '38',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '57',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '80',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '73',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '34',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '55',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '66',
      network: 'BSNL',
    },
    {
      mcc: '404',
      mnc: '10',
      network: 'Bharti Airtel Limited (Delhi)',
    },
    {
      mcc: '404',
      mnc: '045',
      network: 'Bharti Airtel Limited (Karnataka) (India)',
    },
    {
      mcc: '404',
      mnc: '79',
      network: 'CellOne A&amp;N',
    },
    {
      mcc: '404',
      mnc: '82',
      network: 'Escorts Telecom Ltd.',
    },
    {
      mcc: '404',
      mnc: '89',
      network: 'Escorts Telecom Ltd.',
    },
    {
      mcc: '404',
      mnc: '88',
      network: 'Escorts Telecom Ltd.',
    },
    {
      mcc: '404',
      mnc: '87',
      network: 'Escorts Telecom Ltd.',
    },
    {
      mcc: '404',
      mnc: '12',
      network: 'Escotel Mobile Communications',
    },
    {
      mcc: '404',
      mnc: '19',
      network: 'Escotel Mobile Communications',
    },
    {
      mcc: '404',
      mnc: '56',
      network: 'Escotel Mobile Communications',
    },
    {
      mcc: '404',
      mnc: '05',
      network: 'Fascel',
    },
    {
      mcc: '404',
      mnc: '998',
      network: 'Fix Line',
    },
    {
      mcc: '404',
      mnc: '70',
      network: 'Hexacom India',
    },
    {
      mcc: '404',
      mnc: '16',
      network: 'Hexcom India',
    },
    {
      mcc: '404',
      mnc: '22',
      network: 'Idea Cellular Ltd.',
    },
    {
      mcc: '404',
      mnc: '78',
      network: 'Idea Cellular Ltd.',
    },
    {
      mcc: '404',
      mnc: '07',
      network: 'Idea Cellular Ltd.',
    },
    {
      mcc: '404',
      mnc: '04',
      network: 'Idea Cellular Ltd.',
    },
    {
      mcc: '404',
      mnc: '24',
      network: 'Idea Cellular Ltd.',
    },
    {
      mcc: '404',
      mnc: '68',
      network: 'Mahanagar Telephone Nigam',
    },
    {
      mcc: '404',
      mnc: '69',
      network: 'Mahanagar Telephone Nigam',
    },
    {
      mcc: '404',
      mnc: '83',
      network: 'Reliable Internet Services',
    },
    {
      mcc: '404',
      mnc: '52',
      network: 'Reliance Telecom Private',
    },
    {
      mcc: '404',
      mnc: '50',
      network: 'Reliance Telecom Private',
    },
    {
      mcc: '404',
      mnc: '67',
      network: 'Reliance Telecom Private',
    },
    {
      mcc: '404',
      mnc: '18',
      network: 'Reliance Telecom Private',
    },
    {
      mcc: '404',
      mnc: '85',
      network: 'Reliance Telecom Private',
    },
    {
      mcc: '404',
      mnc: '09',
      network: 'Reliance Telecom Private',
    },
    {
      mcc: '404',
      mnc: '36',
      network: 'Reliance Telecom Private',
    },
    {
      mcc: '404',
      mnc: '41',
      network: 'RPG Cellular',
    },
    {
      mcc: '404',
      mnc: '44',
      network: 'Spice',
    },
    {
      mcc: '404',
      mnc: '14',
      network: 'Spice',
    },
    {
      mcc: '404',
      mnc: '11',
      network: 'Sterling Cellular Ltd.',
    },
    {
      mcc: '404',
      mnc: '30',
      network: 'Usha Martin Telecom',
    },
    {
      mcc: '404',
      mnc: '999',
      network: 'Various Networks',
    },
    {
      mcc: '404',
      mnc: '27',
      network: 'Unknown',
    },
    {
      mcc: '404',
      mnc: '43',
      network: 'Vodafone/Essar/Hutch',
    },
    {
      mcc: '404',
      mnc: '20',
      network: 'Unknown',
    },
  ],
  _405IN: [
    {
      mcc: '405',
      mnc: '53',
      network: 'AirTel',
    },
    {
      mcc: '405',
      mnc: '05',
      network: 'Fascel Limited',
    },
    {
      mcc: '405',
      mnc: '87',
      network: 'Reliance Telecom Private',
    },
    {
      mcc: '405',
      mnc: '034',
      network: 'TATA / Karnataka',
    },
  ],
  _410PK: [
    {
      mcc: '410',
      mnc: '08',
      network: 'Instaphone',
    },
    {
      mcc: '410',
      mnc: '01',
      network: 'Mobilink',
    },
    {
      mcc: '410',
      mnc: '06',
      network: 'Telenor',
    },
    {
      mcc: '410',
      mnc: '03',
      network: 'UFONE/PAKTel',
    },
    {
      mcc: '410',
      mnc: '07',
      network: 'Warid Telecom',
    },
    {
      mcc: '410',
      mnc: '04',
      network: 'ZONG/CMPak',
    },
  ],
  _412AF: [
    {
      mcc: '412',
      mnc: '88',
      network: 'Afghan Telecom Corp. (AT)',
    },
    {
      mcc: '412',
      mnc: '80',
      network: 'Afghan Telecom Corp. (AT)',
    },
    {
      mcc: '412',
      mnc: '01',
      network: 'Afghan Wireless/AWCC',
    },
    {
      mcc: '412',
      mnc: '40',
      network: 'Areeba/MTN',
    },
    {
      mcc: '412',
      mnc: '30',
      network: 'Etisalat ',
    },
    {
      mcc: '412',
      mnc: '50',
      network: 'Etisalat ',
    },
    {
      mcc: '412',
      mnc: '20',
      network: 'Roshan/TDCA ',
    },
    {
      mcc: '412',
      mnc: '03',
      network: 'WaselTelecom (WT)',
    },
  ],
  _413LK: [
    {
      mcc: '413',
      mnc: '05',
      network: 'Airtel',
    },
    {
      mcc: '413',
      mnc: '03',
      network: 'Etisalat/Tigo',
    },
    {
      mcc: '413',
      mnc: '08',
      network: 'H3G Hutchison ',
    },
    {
      mcc: '413',
      mnc: '01',
      network: 'Mobitel Ltd.',
    },
    {
      mcc: '413',
      mnc: '02',
      network: 'MTN/Dialog',
    },
  ],
  _414MM: [
    {
      mcc: '414',
      mnc: '01',
      network: 'Myanmar Post &amp; Teleco.',
    },
    {
      mcc: '414',
      mnc: '09',
      network: 'Mytel (Myanmar',
    },
    {
      mcc: '414',
      mnc: '05',
      network: 'Oreedoo',
    },
    {
      mcc: '414',
      mnc: '06',
      network: 'Telenor',
    },
  ],
  _415LB: [
    {
      mcc: '415',
      mnc: '35',
      network: 'Cellis',
    },
    {
      mcc: '415',
      mnc: '33',
      network: 'Cellis',
    },
    {
      mcc: '415',
      mnc: '32',
      network: 'Cellis',
    },
    {
      mcc: '415',
      mnc: '34',
      network: 'FTML Cellis',
    },
    {
      mcc: '415',
      mnc: '39',
      network: 'MIC2/LibanCell/MTC',
    },
    {
      mcc: '415',
      mnc: '38',
      network: 'MIC2/LibanCell/MTC',
    },
    {
      mcc: '415',
      mnc: '37',
      network: 'MIC2/LibanCell/MTC',
    },
    {
      mcc: '415',
      mnc: '01',
      network: 'MIC1 (Alfa)',
    },
    {
      mcc: '415',
      mnc: '03',
      network: 'MIC2/LibanCell/MTC',
    },
    {
      mcc: '415',
      mnc: '36',
      network: 'MIC2/LibanCell/MTC',
    },
  ],
  _416JO: [
    {
      mcc: '416',
      mnc: '77',
      network: 'Orange/Petra',
    },
    {
      mcc: '416',
      mnc: '03',
      network: 'Umniah Mobile Co.',
    },
    {
      mcc: '416',
      mnc: '02',
      network: 'Xpress',
    },
    {
      mcc: '416',
      mnc: '01',
      network: 'ZAIN /J.M.T.S',
    },
  ],
  _417SY: [
    {
      mcc: '417',
      mnc: '02',
      network: 'MTN/Spacetel',
    },
    {
      mcc: '417',
      mnc: '09',
      network: 'Syriatel Holdings',
    },
    {
      mcc: '417',
      mnc: '01',
      network: 'Syriatel Holdings',
    },
  ],
  _418IQ: [
    {
      mcc: '418',
      mnc: '05',
      network: 'Asia Cell',
    },
    {
      mcc: '418',
      mnc: '66',
      network: 'Fastlink',
    },
    {
      mcc: '418',
      mnc: '92',
      network: 'Itisaluna and Kalemat',
    },
    {
      mcc: '418',
      mnc: '82',
      network: 'Korek',
    },
    {
      mcc: '418',
      mnc: '40',
      network: 'Korek',
    },
    {
      mcc: '418',
      mnc: '45',
      network: 'Mobitel (Iraq-Kurdistan) and Moutiny',
    },
    {
      mcc: '418',
      mnc: '30',
      network: 'Orascom Telecom',
    },
    {
      mcc: '418',
      mnc: '20',
      network: 'ZAIN/Atheer/Orascom',
    },
    {
      mcc: '418',
      mnc: '08',
      network: 'Sanatel',
    },
  ],
  _419KW: [
    {
      mcc: '419',
      mnc: '02',
      network: 'Zain',
    },
    {
      mcc: '419',
      mnc: '04',
      network: 'Viva',
    },
    {
      mcc: '419',
      mnc: '03',
      network: 'Ooredoo',
    },
  ],
  _420SA: [
    {
      mcc: '420',
      mnc: '07',
      network: 'Zain',
    },
    {
      mcc: '420',
      mnc: '03',
      network: 'Etihad/Etisalat/Mobily',
    },
    {
      mcc: '420',
      mnc: '06',
      network: 'Lebara Mobile',
    },
    {
      mcc: '420',
      mnc: '01',
      network: 'STC/Al Jawal',
    },
    {
      mcc: '420',
      mnc: '05',
      network: 'Virgin Mobile',
    },
    {
      mcc: '420',
      mnc: '04',
      network: 'Zain',
    },
  ],
  _421YE: [
    {
      mcc: '421',
      mnc: '04',
      network: 'HITS/Y Unitel',
    },
    {
      mcc: '421',
      mnc: '02',
      network: 'MTN/Spacetel',
    },
    {
      mcc: '421',
      mnc: '01',
      network: 'Sabaphone',
    },
    {
      mcc: '421',
      mnc: '03',
      network: 'Yemen Mob. CDMA',
    },
  ],
  _422OM: [
    {
      mcc: '422',
      mnc: '03',
      network: 'Nawras',
    },
    {
      mcc: '422',
      mnc: '02',
      network: 'Oman Mobile/GTO',
    },
  ],
  _424AE: [
    {
      mcc: '424',
      mnc: '03',
      network: 'DU',
    },
    {
      mcc: '424',
      mnc: '02',
      network: 'Etisalat',
    },
  ],
  _425IL: [
    {
      mcc: '425',
      mnc: '14',
      network: 'Alon Cellular Ltd',
    },
    {
      mcc: '425',
      mnc: '02',
      network: 'Cellcom ltd.',
    },
    {
      mcc: '425',
      mnc: '08',
      network: 'Golan Telekom',
    },
    {
      mcc: '425',
      mnc: '15',
      network: 'Home Cellular Ltd',
    },
    {
      mcc: '425',
      mnc: '77',
      network: 'Hot Mobile/Mirs',
    },
    {
      mcc: '425',
      mnc: '07',
      network: 'Hot Mobile/Mirs',
    },
    {
      mcc: '425',
      mnc: '09',
      network: 'We4G/Marathon 018',
    },
    {
      mcc: '425',
      mnc: '01',
      network: 'Orange/Partner Co. Ltd.',
    },
    {
      mcc: '425',
      mnc: '03',
      network: 'Pelephone',
    },
    {
      mcc: '425',
      mnc: '12',
      network: 'Pelephone',
    },
    {
      mcc: '425',
      mnc: '16',
      network: 'Rami Levy Hashikma Marketing Communications Ltd',
    },
    {
      mcc: '425',
      mnc: '19',
      network: 'Telzar/AZI ',
    },
  ],
  _425PS: [
    {
      mcc: '425',
      mnc: '05',
      network: 'Jawwal',
    },
    {
      mcc: '425',
      mnc: '06',
      network: 'Wataniya Mobile',
    },
  ],
  _426BH: [
    {
      mcc: '426',
      mnc: '01',
      network: 'Batelco',
    },
    {
      mcc: '426',
      mnc: '02',
      network: 'ZAIN/Vodafone',
    },
    {
      mcc: '426',
      mnc: '04',
      network: 'VIVA',
    },
  ],
  _427QA: [
    {
      mcc: '427',
      mnc: '01',
      network: 'Ooredoo/Qtel',
    },
    {
      mcc: '427',
      mnc: '02',
      network: 'Vodafone',
    },
  ],
  _428MN: [
    {
      mcc: '428',
      mnc: '98',
      network: 'G-Mobile Corporation Ltd',
    },
    {
      mcc: '428',
      mnc: '99',
      network: 'Mobicom',
    },
    {
      mcc: '428',
      mnc: '91',
      network: 'Skytel Co. Ltd',
    },
    {
      mcc: '428',
      mnc: '00',
      network: 'Skytel Co. Ltd',
    },
    {
      mcc: '428',
      mnc: '88',
      network: 'Unitel',
    },
  ],
  _429NP: [
    {
      mcc: '429',
      mnc: '02',
      network: 'Ncell',
    },
    {
      mcc: '429',
      mnc: '01',
      network: 'NT Mobile / Namaste',
    },
    {
      mcc: '429',
      mnc: '04',
      network: 'Smart Cell',
    },
  ],
  _430AE: [
    {
      mcc: '430',
      mnc: '02',
      network: 'Etisalat',
    },
  ],
  _431AE: [
    {
      mcc: '431',
      mnc: '02',
      network: 'Etisalat',
    },
  ],
  _432IR: [
    {
      mcc: '432',
      mnc: '19',
      network: 'Mobile Telecommunications Company of Esfahan JV-PJS (MTCE)',
    },
    {
      mcc: '432',
      mnc: '70',
      network: 'MTCE',
    },
    {
      mcc: '432',
      mnc: '35',
      network: 'MTN/IranCell',
    },
    {
      mcc: '432',
      mnc: '20',
      network: 'Rightel',
    },
    {
      mcc: '432',
      mnc: '32',
      network: 'Taliya',
    },
    {
      mcc: '432',
      mnc: '11',
      network: 'MCI/TCI',
    },
    {
      mcc: '432',
      mnc: '14',
      network: 'TKC/KFZO',
    },
  ],
  _434UZ: [
    {
      mcc: '434',
      mnc: '04',
      network: 'Bee Line/Unitel',
    },
    {
      mcc: '434',
      mnc: '01',
      network: 'Buztel',
    },
    {
      mcc: '434',
      mnc: '07',
      network: 'MTS/Uzdunrobita',
    },
    {
      mcc: '434',
      mnc: '05',
      network: 'Ucell/Coscom',
    },
    {
      mcc: '434',
      mnc: '02',
      network: 'Uzmacom',
    },
  ],
  _436TJ: [
    {
      mcc: '436',
      mnc: '04',
      network: 'Babilon-M',
    },
    {
      mcc: '436',
      mnc: '05',
      network: 'Bee Line',
    },
    {
      mcc: '436',
      mnc: '02',
      network: 'CJSC Indigo Tajikistan',
    },
    {
      mcc: '436',
      mnc: '12',
      network: 'Tcell/JC Somoncom',
    },
    {
      mcc: '436',
      mnc: '03',
      network: 'Megafon',
    },
    {
      mcc: '436',
      mnc: '01',
      network: 'Tcell/JC Somoncom',
    },
  ],
  _437KG: [
    {
      mcc: '437',
      mnc: '03',
      network: 'AkTel LLC',
    },
    {
      mcc: '437',
      mnc: '01',
      network: 'Beeline/Bitel',
    },
    {
      mcc: '437',
      mnc: '05',
      network: 'MEGACOM',
    },
    {
      mcc: '437',
      mnc: '09',
      network: 'O!/NUR Telecom',
    },
  ],
  _438TM: [
    {
      mcc: '438',
      mnc: '01',
      network: 'MTS/Barash Communication',
    },
    {
      mcc: '438',
      mnc: '02',
      network: 'Altyn Asyr/TM-Cell',
    },
  ],
  _440JP: [
    {
      mcc: '440',
      mnc: '00',
      network: 'Y-Mobile',
    },
    {
      mcc: '440',
      mnc: '88',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '50',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '74',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '70',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '89',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '51',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '75',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '56',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '52',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '76',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '71',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '53',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '77',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '08',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '72',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '54',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '79',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '07',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '73',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '55',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '13',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '23',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '01',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '21',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '34',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '69',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '16',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '25',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '64',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '37',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '27',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '02',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '22',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '31',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '87',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '17',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '65',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '36',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '58',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '28',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '03',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '12',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '32',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '61',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '18',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '66',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '35',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '29',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '09',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '49',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '33',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '60',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '19',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '67',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '14',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '30',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '10',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '62',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '39',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '24',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '68',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '15',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '26',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '11',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '63',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '38',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '99',
      network: 'NTT Docomo',
    },
    {
      mcc: '440',
      mnc: '78',
      network: 'Okinawa Cellular Telephone',
    },
    {
      mcc: '440',
      mnc: '47',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '95',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '41',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '46',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '97',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '42',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '90',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '92',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '98',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '93',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '43',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '48',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '06',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '94',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '44',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '04',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '96',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '45',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '20',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '40',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '440',
      mnc: '83',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '85',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '86',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '81',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '80',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '84',
      network: 'KDDI Corporation',
    },
    {
      mcc: '440',
      mnc: '82',
      network: 'KDDI Corporation',
    },
  ],
  _441JP: [
    {
      mcc: '441',
      mnc: '70',
      network: 'KDDI Corporation',
    },
    {
      mcc: '441',
      mnc: '44',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '99',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '43',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '92',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '91',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '93',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '40',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '90',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '94',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '41',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '45',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '98',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '42',
      network: 'NTT Docomo',
    },
    {
      mcc: '441',
      mnc: '64',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '441',
      mnc: '65',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '441',
      mnc: '61',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '441',
      mnc: '62',
      network: 'SoftBank Mobile Corp',
    },
    {
      mcc: '441',
      mnc: '63',
      network: 'SoftBank Mobile Corp',
    },
  ],
  _450KR: [
    {
      mcc: '450',
      mnc: '02',
      network: 'KT Freetel Co. Ltd.',
    },
    {
      mcc: '450',
      mnc: '04',
      network: 'KT Freetel Co. Ltd.',
    },
    {
      mcc: '450',
      mnc: '08',
      network: 'KT Freetel Co. Ltd.',
    },
    {
      mcc: '450',
      mnc: '06',
      network: 'LG Telecom',
    },
    {
      mcc: '450',
      mnc: '03',
      network: 'SK Telecom',
    },
    {
      mcc: '450',
      mnc: '05',
      network: 'SK Telecom Co. Ltd',
    },
  ],
  _452VN: [
    {
      mcc: '452',
      mnc: '07',
      network: 'Gmobile',
    },
    {
      mcc: '452',
      mnc: '08',
      network: 'I-Telecom',
    },
    {
      mcc: '452',
      mnc: '08',
      network: 'I-Telecom',
    },
    {
      mcc: '452',
      mnc: '01',
      network: 'Mobifone',
    },
    {
      mcc: '452',
      mnc: '03',
      network: 'S-Fone/Telecom',
    },
    {
      mcc: '452',
      mnc: '05',
      network: 'VietnaMobile',
    },
    {
      mcc: '452',
      mnc: '04',
      network: 'Viettel Mobile',
    },
    {
      mcc: '452',
      mnc: '06',
      network: 'Viettel Mobile',
    },
    {
      mcc: '452',
      mnc: '02',
      network: 'Vinaphone',
    },
  ],
  _454HK: [
    {
      mcc: '454',
      mnc: '28',
      network: 'China Mobile/Peoples',
    },
    {
      mcc: '454',
      mnc: '13',
      network: 'China Mobile/Peoples',
    },
    {
      mcc: '454',
      mnc: '12',
      network: 'China Mobile/Peoples',
    },
    {
      mcc: '454',
      mnc: '09',
      network: 'China Motion',
    },
    {
      mcc: '454',
      mnc: '07',
      network: 'China Unicom Ltd',
    },
    {
      mcc: '454',
      mnc: '11',
      network: 'China-HongKong Telecom Ltd (CHKTL)',
    },
    {
      mcc: '454',
      mnc: '01',
      network: 'Citic Telecom Ltd.',
    },
    {
      mcc: '454',
      mnc: '18',
      network: 'CSL Ltd.',
    },
    {
      mcc: '454',
      mnc: '02',
      network: 'CSL Ltd.',
    },
    {
      mcc: '454',
      mnc: '00',
      network: 'CSL Ltd.',
    },
    {
      mcc: '454',
      mnc: '10',
      network: 'CSL/New World PCS Ltd.',
    },
    {
      mcc: '454',
      mnc: '31',
      network: 'CTExcel',
    },
    {
      mcc: '454',
      mnc: '04',
      network: 'H3G/Hutchinson',
    },
    {
      mcc: '454',
      mnc: '03',
      network: 'H3G/Hutchinson',
    },
    {
      mcc: '454',
      mnc: '14',
      network: 'H3G/Hutchinson',
    },
    {
      mcc: '454',
      mnc: '05',
      network: 'H3G/Hutchinson',
    },
    {
      mcc: '454',
      mnc: '19',
      network: 'HKT/PCCW',
    },
    {
      mcc: '454',
      mnc: '20',
      network: 'HKT/PCCW',
    },
    {
      mcc: '454',
      mnc: '29',
      network: 'HKT/PCCW',
    },
    {
      mcc: '454',
      mnc: '16',
      network: 'HKT/PCCW',
    },
    {
      mcc: '454',
      mnc: '47',
      network: 'shared by private TETRA systems',
    },
    {
      mcc: '454',
      mnc: '24',
      network: 'Multibyte Info Technology Ltd',
    },
    {
      mcc: '454',
      mnc: '40',
      network: 'shared by private TETRA systems',
    },
    {
      mcc: '454',
      mnc: '08',
      network: 'Truephone',
    },
    {
      mcc: '454',
      mnc: '17',
      network: 'Vodafone/SmarTone',
    },
    {
      mcc: '454',
      mnc: '15',
      network: 'Vodafone/SmarTone',
    },
    {
      mcc: '454',
      mnc: '06',
      network: 'Vodafone/SmarTone',
    },
  ],
  _455MO: [
    {
      mcc: '455',
      mnc: '01',
      network: 'C.T.M. TELEMOVEL+',
    },
    {
      mcc: '455',
      mnc: '04',
      network: 'C.T.M. TELEMOVEL+',
    },
    {
      mcc: '455',
      mnc: '02',
      network: 'China Telecom',
    },
    {
      mcc: '455',
      mnc: '05',
      network: 'Hutchison Telephone Co. Ltd',
    },
    {
      mcc: '455',
      mnc: '03',
      network: 'Hutchison Telephone Co. Ltd',
    },
    {
      mcc: '455',
      mnc: '06',
      network: 'Smartone Mobile',
    },
    {
      mcc: '455',
      mnc: '00',
      network: 'Smartone Mobile',
    },
  ],
  _456KH: [
    {
      mcc: '456',
      mnc: '04',
      network: 'Cambodia Advance Communications Co. Ltd (CADCOMMS)',
    },
    {
      mcc: '456',
      mnc: '02',
      network: 'Smart Mobile',
    },
    {
      mcc: '456',
      mnc: '08',
      network: 'Viettel/Metfone',
    },
    {
      mcc: '456',
      mnc: '18',
      network: 'Mobitel/Cam GSM',
    },
    {
      mcc: '456',
      mnc: '01',
      network: 'Mobitel/Cam GSM',
    },
    {
      mcc: '456',
      mnc: '03',
      network: 'QB/Cambodia Adv. Comms.',
    },
    {
      mcc: '456',
      mnc: '11',
      network: 'SEATEL',
    },
    {
      mcc: '456',
      mnc: '05',
      network: 'Smart Mobile',
    },
    {
      mcc: '456',
      mnc: '06',
      network: 'Smart Mobile',
    },
    {
      mcc: '456',
      mnc: '09',
      network: 'Sotelco/Beeline',
    },
  ],
  _457LA: [
    {
      mcc: '457',
      mnc: '02',
      network: 'ETL Mobile',
    },
    {
      mcc: '457',
      mnc: '01',
      network: 'Lao Tel',
    },
    {
      mcc: '457',
      mnc: '08',
      network: 'Beeline/Tigo/Millicom',
    },
    {
      mcc: '457',
      mnc: '03',
      network: 'UNITEL/LAT',
    },
  ],
  _460CN: [
    {
      mcc: '460',
      mnc: '07',
      network: 'China Mobile GSM',
    },
    {
      mcc: '460',
      mnc: '02',
      network: 'China Mobile GSM',
    },
    {
      mcc: '460',
      mnc: '00',
      network: 'China Mobile GSM',
    },
    {
      mcc: '460',
      mnc: '04',
      network:
        'China Space Mobile Satellite Telecommunications Co. Ltd (China Spacecom)',
    },
    {
      mcc: '460',
      mnc: '05',
      network: 'China Telecom',
    },
    {
      mcc: '460',
      mnc: '03',
      network: 'China Telecom',
    },
    {
      mcc: '460',
      mnc: '06',
      network: 'China Unicom',
    },
    {
      mcc: '460',
      mnc: '01',
      network: 'China Unicom',
    },
  ],
  _466TW: [
    {
      mcc: '466',
      mnc: '68',
      network: 'ACeS Taiwan - ACeS Taiwan Telecommunications Co Ltd',
    },
    {
      mcc: '466',
      mnc: '05',
      network: 'Asia Pacific Telecom Co. Ltd (APT)',
    },
    {
      mcc: '466',
      mnc: '92',
      network: 'Chunghwa Telecom LDM',
    },
    {
      mcc: '466',
      mnc: '11',
      network: 'Chunghwa Telecom LDM',
    },
    {
      mcc: '466',
      mnc: '02',
      network: 'Far EasTone',
    },
    {
      mcc: '466',
      mnc: '07',
      network: 'Far EasTone',
    },
    {
      mcc: '466',
      mnc: '06',
      network: 'Far EasTone',
    },
    {
      mcc: '466',
      mnc: '01',
      network: 'Far EasTone',
    },
    {
      mcc: '466',
      mnc: '03',
      network: 'Far EasTone',
    },
    {
      mcc: '466',
      mnc: '10',
      network: 'Global Mobile Corp.',
    },
    {
      mcc: '466',
      mnc: '56',
      network: 'International Telecom Co. Ltd (FITEL)',
    },
    {
      mcc: '466',
      mnc: '88',
      network: 'KG Telecom',
    },
    {
      mcc: '466',
      mnc: '90',
      network: 'T-Star/VIBO',
    },
    {
      mcc: '466',
      mnc: '99',
      network: 'TransAsia',
    },
    {
      mcc: '466',
      mnc: '97',
      network: 'Taiwan Cellular',
    },
    {
      mcc: '466',
      mnc: '93',
      network: 'Mobitai',
    },
    {
      mcc: '466',
      mnc: '89',
      network: 'T-Star/VIBO',
    },
    {
      mcc: '466',
      mnc: '09',
      network: 'VMAX Telecom Co. Ltd',
    },
  ],
  _467KP: [
    {
      mcc: '467',
      mnc: '193',
      network: 'Sun Net',
    },
  ],
  _470BD: [
    {
      mcc: '470',
      mnc: '02',
      network: 'Robi/Aktel',
    },
    {
      mcc: '470',
      mnc: '05',
      network: 'Citycell',
    },
    {
      mcc: '470',
      mnc: '06',
      network: 'Citycell',
    },
    {
      mcc: '470',
      mnc: '01',
      network: 'GrameenPhone',
    },
    {
      mcc: '470',
      mnc: '03',
      network: 'Orascom/Banglalink',
    },
    {
      mcc: '470',
      mnc: '04',
      network: 'TeleTalk',
    },
    {
      mcc: '470',
      mnc: '07',
      network: 'Airtel/Warid',
    },
  ],
  _472MV: [
    {
      mcc: '472',
      mnc: '01',
      network: 'Dhiraagu/C&amp;W',
    },
    {
      mcc: '472',
      mnc: '02',
      network: 'Ooredo/Wataniya',
    },
  ],
  _502MY: [
    {
      mcc: '502',
      mnc: '01',
      network: 'Art900',
    },
    {
      mcc: '502',
      mnc: '151',
      network: 'Baraka Telecom Sdn Bhd',
    },
    {
      mcc: '502',
      mnc: '19',
      network: 'CelCom',
    },
    {
      mcc: '502',
      mnc: '198',
      network: 'CelCom',
    },
    {
      mcc: '502',
      mnc: '13',
      network: 'CelCom',
    },
    {
      mcc: '502',
      mnc: '10',
      network: 'Digi Telecommunications',
    },
    {
      mcc: '502',
      mnc: '16',
      network: 'Digi Telecommunications',
    },
    {
      mcc: '502',
      mnc: '20',
      network: 'Electcoms Wireless Sdn Bhd',
    },
    {
      mcc: '502',
      mnc: '12',
      network: 'Maxis',
    },
    {
      mcc: '502',
      mnc: '17',
      network: 'Maxis',
    },
    {
      mcc: '502',
      mnc: '11',
      network: 'MTX Utara',
    },
    {
      mcc: '502',
      mnc: '153',
      network: 'Webe/Packet One Networks (Malaysia) Sdn Bhd',
    },
    {
      mcc: '502',
      mnc: '155',
      network: 'Samata Communications Sdn Bhd',
    },
    {
      mcc: '502',
      mnc: '154',
      network: 'Tron/Talk Focus Sdn Bhd',
    },
    {
      mcc: '502',
      mnc: '150',
      network: 'TuneTalk',
    },
    {
      mcc: '502',
      mnc: '18',
      network: 'U Mobile',
    },
    {
      mcc: '502',
      mnc: '195',
      network: 'XOX Com Sdn Bhd',
    },
    {
      mcc: '502',
      mnc: '152',
      network: 'YES',
    },
  ],
  _505AU: [
    {
      mcc: '505',
      mnc: '14',
      network: 'AAPT Ltd. ',
    },
    {
      mcc: '505',
      mnc: '24',
      network: 'Advanced Comm Tech Pty.',
    },
    {
      mcc: '505',
      mnc: '09',
      network: 'Airnet Commercial Australia Ltd..',
    },
    {
      mcc: '505',
      mnc: '04',
      network: 'Department of Defense',
    },
    {
      mcc: '505',
      mnc: '26',
      network: 'Dialogue Communications Pty Ltd',
    },
    {
      mcc: '505',
      mnc: '12',
      network: 'H3G Ltd.',
    },
    {
      mcc: '505',
      mnc: '06',
      network: 'H3G Ltd.',
    },
    {
      mcc: '505',
      mnc: '88',
      network: 'Pivotel Group Ltd',
    },
    {
      mcc: '505',
      mnc: '19',
      network: 'Lycamobile Pty Ltd',
    },
    {
      mcc: '505',
      mnc: '08',
      network: 'Railcorp/Vodafone',
    },
    {
      mcc: '505',
      mnc: '99',
      network: 'Railcorp/Vodafone',
    },
    {
      mcc: '505',
      mnc: '13',
      network: 'Railcorp/Vodafone',
    },
    {
      mcc: '505',
      mnc: '90',
      network: 'Singtel Optus',
    },
    {
      mcc: '505',
      mnc: '02',
      network: 'Singtel Optus',
    },
    {
      mcc: '505',
      mnc: '01',
      network: 'Telstra Corp. Ltd.',
    },
    {
      mcc: '505',
      mnc: '11',
      network: 'Telstra Corp. Ltd.',
    },
    {
      mcc: '505',
      mnc: '71',
      network: 'Telstra Corp. Ltd.',
    },
    {
      mcc: '505',
      mnc: '72',
      network: 'Telstra Corp. Ltd.',
    },
    {
      mcc: '505',
      mnc: '05',
      network: 'The Ozitel Network Pty.',
    },
    {
      mcc: '505',
      mnc: '16',
      network: 'Victorian Rail Track Corp. (VicTrack)',
    },
    {
      mcc: '505',
      mnc: '07',
      network: 'Vodafone',
    },
    {
      mcc: '505',
      mnc: '03',
      network: 'Vodafone',
    },
  ],
  _510ID: [
    {
      mcc: '510',
      mnc: '08',
      network: 'Axis/Natrindo',
    },
    {
      mcc: '510',
      mnc: '99',
      network: 'Esia (PT Bakrie Telecom) (CDMA)',
    },
    {
      mcc: '510',
      mnc: '07',
      network: 'Flexi (PT Telkom) (CDMA)',
    },
    {
      mcc: '510',
      mnc: '89',
      network: 'H3G CP',
    },
    {
      mcc: '510',
      mnc: '01',
      network: 'Indosat/Satelindo/M3',
    },
    {
      mcc: '510',
      mnc: '21',
      network: 'Indosat/Satelindo/M3',
    },
    {
      mcc: '510',
      mnc: '00',
      network: 'PT Pasifik Satelit Nusantara (PSN)',
    },
    {
      mcc: '510',
      mnc: '27',
      network: 'PT Sampoerna Telekomunikasi Indonesia (STI)',
    },
    {
      mcc: '510',
      mnc: '09',
      network: 'PT Smartfren Telecom Tbk',
    },
    {
      mcc: '510',
      mnc: '28',
      network: 'PT Smartfren Telecom Tbk',
    },
    {
      mcc: '510',
      mnc: '11',
      network: 'PT. Excelcom',
    },
    {
      mcc: '510',
      mnc: '07',
      network: 'Telkomsel',
    },
    {
      mcc: '510',
      mnc: '10',
      network: 'Telkomsel',
    },
  ],
  _514TL: [
    {
      mcc: '514',
      mnc: '01',
      network: 'Telin/ Telkomcel',
    },
    {
      mcc: '514',
      mnc: '02',
      network: 'Timor Telecom',
    },
  ],
  _515PH: [
    {
      mcc: '515',
      mnc: '00',
      network: 'Fix Line',
    },
    {
      mcc: '515',
      mnc: '02',
      network: 'Globe Telecom',
    },
    {
      mcc: '515',
      mnc: '01',
      network: 'Globe Telecom',
    },
    {
      mcc: '515',
      mnc: '88',
      network: 'Next Mobile',
    },
    {
      mcc: '515',
      mnc: '18',
      network: 'RED Mobile/Cure',
    },
    {
      mcc: '515',
      mnc: '03',
      network: 'Smart',
    },
    {
      mcc: '515',
      mnc: '05',
      network: 'SUN/Digitel',
    },
  ],
  _520TH: [
    {
      mcc: '520',
      mnc: '20',
      network: 'ACeS Thailand - ACeS Regional Services Co Ltd',
    },
    {
      mcc: '520',
      mnc: '15',
      network: 'ACT Mobile',
    },
    {
      mcc: '520',
      mnc: '03',
      network: 'AIS/Advanced Info Service',
    },
    {
      mcc: '520',
      mnc: '01',
      network: 'AIS/Advanced Info Service',
    },
    {
      mcc: '520',
      mnc: '23',
      network: 'Digital Phone Co.',
    },
    {
      mcc: '520',
      mnc: '00',
      network: 'Hutch/CAT CDMA',
    },
    {
      mcc: '520',
      mnc: '18',
      network: 'Total Access (DTAC)',
    },
    {
      mcc: '520',
      mnc: '05',
      network: 'Total Access (DTAC)',
    },
    {
      mcc: '520',
      mnc: '99',
      network: 'True Move/Orange',
    },
    {
      mcc: '520',
      mnc: '04',
      network: 'True Move/Orange',
    },
  ],
  _525SG: [
    {
      mcc: '525',
      mnc: '12',
      network: 'GRID Communications Pte Ltd',
    },
    {
      mcc: '525',
      mnc: '03',
      network: 'MobileOne Ltd',
    },
    {
      mcc: '525',
      mnc: '01',
      network: 'Singtel',
    },
    {
      mcc: '525',
      mnc: '07',
      network: 'Singtel',
    },
    {
      mcc: '525',
      mnc: '02',
      network: 'Singtel',
    },
    {
      mcc: '525',
      mnc: '06',
      network: 'Starhub',
    },
    {
      mcc: '525',
      mnc: '05',
      network: 'Starhub',
    },
  ],
  _528BN: [
    {
      mcc: '528',
      mnc: '02',
      network: 'b-mobile',
    },
    {
      mcc: '528',
      mnc: '11',
      network: 'Datastream (DTSCom)',
    },
    {
      mcc: '528',
      mnc: '01',
      network: 'Telekom Brunei Bhd (TelBru)',
    },
  ],
  _530NZ: [
    {
      mcc: '530',
      mnc: '28',
      network: '2degrees',
    },
    {
      mcc: '530',
      mnc: '05',
      network: 'Spark/NZ Telecom',
    },
    {
      mcc: '530',
      mnc: '02',
      network: 'Spark/NZ Telecom',
    },
    {
      mcc: '530',
      mnc: '04',
      network: 'Telstra',
    },
    {
      mcc: '530',
      mnc: '24',
      network: 'Two Degrees Mobile Ltd',
    },
    {
      mcc: '530',
      mnc: '01',
      network: 'Vodafone',
    },
    {
      mcc: '530',
      mnc: '03',
      network: 'Walker Wireless Ltd.',
    },
  ],
  _537PG: [
    {
      mcc: '537',
      mnc: '03',
      network: 'Digicel',
    },
    {
      mcc: '537',
      mnc: '02',
      network: 'GreenCom PNG Ltd',
    },
    {
      mcc: '537',
      mnc: '01',
      network: 'Pacific Mobile',
    },
  ],
  _539TO: [
    {
      mcc: '539',
      mnc: '88',
      network: 'Digicel',
    },
    {
      mcc: '539',
      mnc: '43',
      network: 'Shoreline Communication',
    },
    {
      mcc: '539',
      mnc: '01',
      network: 'Tonga Communications',
    },
  ],
  _540SB: [
    {
      mcc: '540',
      mnc: '02',
      network: 'bemobile',
    },
    {
      mcc: '540',
      mnc: '10',
      network: 'BREEZE',
    },
    {
      mcc: '540',
      mnc: '01',
      network: 'BREEZE',
    },
  ],
  _541VU: [
    {
      mcc: '541',
      mnc: '05',
      network: 'DigiCel',
    },
    {
      mcc: '541',
      mnc: '01',
      network: 'SMILE',
    },
  ],
  _542FJ: [
    {
      mcc: '542',
      mnc: '02',
      network: 'DigiCell',
    },
    {
      mcc: '542',
      mnc: '01',
      network: 'Vodafone',
    },
  ],
  _544AS: [
    {
      mcc: '544',
      mnc: '11',
      network: 'Blue Sky Communications',
    },
  ],
  _545KI: [
    {
      mcc: '545',
      mnc: '09',
      network: 'Kiribati Frigate',
    },
  ],
  _546NC: [
    {
      mcc: '546',
      mnc: '01',
      network: 'OPT Mobilis',
    },
  ],
  _547PF: [
    {
      mcc: '547',
      mnc: '15',
      network: 'Pacific Mobile Telecom (PMT)',
    },
    {
      mcc: '547',
      mnc: '20',
      network: 'Vini/Tikiphone',
    },
  ],
  _548CK: [
    {
      mcc: '548',
      mnc: '01',
      network: 'Telecom Cook Islands',
    },
  ],
  _549WS: [
    {
      mcc: '549',
      mnc: '27',
      network: 'Samoatel Mobile',
    },
    {
      mcc: '549',
      mnc: '01',
      network: 'Telecom Samoa Cellular Ltd.',
    },
  ],
  _550FM: [
    {
      mcc: '550',
      mnc: '01',
      network: 'FSM Telecom',
    },
  ],
  _552PW: [
    {
      mcc: '552',
      mnc: '80',
      network: 'Palau Mobile Corp. (PMC) (Palau',
    },
    {
      mcc: '552',
      mnc: '01',
      network: 'Palau National Communications Corp. (PNCC) (Palau',
    },
    {
      mcc: '552',
      mnc: '02',
      network: 'PECI/PalauTel (Palau',
    },
  ],
  _553TV: [
    {
      mcc: '553',
      mnc: '01',
      network: 'Tuvalu Telecommunication Corporation (TTC)',
    },
  ],
  _555NU: [
    {
      mcc: '555',
      mnc: '01',
      network: 'Niue Telecom',
    },
  ],
  _602EG: [
    {
      mcc: '602',
      mnc: '01',
      network: 'Orange/Mobinil',
    },
    {
      mcc: '602',
      mnc: '03',
      network: 'ETISALAT',
    },
    {
      mcc: '602',
      mnc: '02',
      network: 'Vodafone/Mirsfone ',
    },
    {
      mcc: '602',
      mnc: '04',
      network: 'WE/Telecom',
    },
  ],
  _603DZ: [
    {
      mcc: '603',
      mnc: '01',
      network: 'ATM Mobils',
    },
    {
      mcc: '603',
      mnc: '02',
      network: 'Orascom / DJEZZY',
    },
    {
      mcc: '603',
      mnc: '03',
      network: 'Oreedo/Wataniya / Nedjma ',
    },
  ],
  _604MA: [
    {
      mcc: '604',
      mnc: '04',
      network: 'Al Houria Telecom',
    },
    {
      mcc: '604',
      mnc: '99',
      network: 'Al Houria Telecom',
    },
    {
      mcc: '604',
      mnc: '06',
      network: 'IAM/Itissallat',
    },
    {
      mcc: '604',
      mnc: '01',
      network: 'IAM/Itissallat',
    },
    {
      mcc: '604',
      mnc: '02',
      network: 'INWI/WANA',
    },
    {
      mcc: '604',
      mnc: '05',
      network: 'INWI/WANA',
    },
    {
      mcc: '604',
      mnc: '00',
      network: 'Orange/Medi Telecom',
    },
  ],
  _605TN: [
    {
      mcc: '605',
      mnc: '01',
      network: 'Orange',
    },
    {
      mcc: '605',
      mnc: '03',
      network: 'Oreedo/Orascom',
    },
    {
      mcc: '605',
      mnc: '02',
      network: 'TuniCell/Tunisia Telecom',
    },
    {
      mcc: '605',
      mnc: '06',
      network: 'TuniCell/Tunisia Telecom',
    },
  ],
  _606LY: [
    {
      mcc: '606',
      mnc: '02',
      network: 'Al-Madar',
    },
    {
      mcc: '606',
      mnc: '01',
      network: 'Al-Madar',
    },
    {
      mcc: '606',
      mnc: '06',
      network: 'Hatef',
    },
    {
      mcc: '606',
      mnc: '00',
      network: 'Libyana',
    },
    {
      mcc: '606',
      mnc: '03',
      network: 'Libyana',
    },
  ],
  _607GM: [
    {
      mcc: '607',
      mnc: '02',
      network: 'Africel',
    },
    {
      mcc: '607',
      mnc: '03',
      network: 'Comium',
    },
    {
      mcc: '607',
      mnc: '01',
      network: 'Gamcel',
    },
    {
      mcc: '607',
      mnc: '04',
      network: 'Q-Cell',
    },
  ],
  _608SN: [
    {
      mcc: '608',
      mnc: '03',
      network: 'Expresso/Sudatel',
    },
    {
      mcc: '608',
      mnc: '01',
      network: 'Orange/Sonatel',
    },
    {
      mcc: '608',
      mnc: '02',
      network: 'TIGO/Sentel GSM',
    },
  ],
  _609MR: [
    {
      mcc: '609',
      mnc: '02',
      network: 'Chinguitel SA',
    },
    {
      mcc: '609',
      mnc: '01',
      network: 'Mattel',
    },
    {
      mcc: '609',
      mnc: '10',
      network: 'Mauritel',
    },
  ],
  _610ML: [
    {
      mcc: '610',
      mnc: '03',
      network: 'ATEL SA',
    },
    {
      mcc: '610',
      mnc: '01',
      network: 'Malitel',
    },
    {
      mcc: '610',
      mnc: '02',
      network: 'Orange/IKATEL',
    },
  ],
  _611GN: [
    {
      mcc: '611',
      mnc: '04',
      network: 'MTN/Areeba',
    },
    {
      mcc: '611',
      mnc: '05',
      network: 'Celcom',
    },
    {
      mcc: '611',
      mnc: '03',
      network: 'Intercel',
    },
    {
      mcc: '611',
      mnc: '01',
      network: 'Orange/Sonatel/Spacetel',
    },
    {
      mcc: '611',
      mnc: '02',
      network: 'SotelGui',
    },
  ],
  _612CI: [
    {
      mcc: '612',
      mnc: '07',
      network: 'Aircomm SA',
    },
    {
      mcc: '612',
      mnc: '02',
      network: 'Atlantik Tel./Moov',
    },
    {
      mcc: '612',
      mnc: '04',
      network: 'Comium',
    },
    {
      mcc: '612',
      mnc: '01',
      network: 'Comstar',
    },
    {
      mcc: '612',
      mnc: '05',
      network: 'MTN',
    },
    {
      mcc: '612',
      mnc: '03',
      network: 'Orange',
    },
    {
      mcc: '612',
      mnc: '06',
      network: 'OriCell',
    },
  ],
  _613BF: [
    {
      mcc: '613',
      mnc: '03',
      network: 'TeleCel',
    },
    {
      mcc: '613',
      mnc: '01',
      network: 'TeleMob-OnaTel',
    },
    {
      mcc: '613',
      mnc: '02',
      network: 'Orange/Airte',
    },
  ],
  _614NE: [
    {
      mcc: '614',
      mnc: '03',
      network: 'MOOV/TeleCel',
    },
    {
      mcc: '614',
      mnc: '04',
      network: 'Orange',
    },
    {
      mcc: '614',
      mnc: '01',
      network: 'Sahelcom',
    },
    {
      mcc: '614',
      mnc: '02',
      network: 'Airtel/Zain/CelTel',
    },
  ],
  _615TG: [
    {
      mcc: '615',
      mnc: '02',
      network: 'Telecel/MOOV',
    },
    {
      mcc: '615',
      mnc: '03',
      network: 'Telecel/MOOV',
    },
    {
      mcc: '615',
      mnc: '01',
      network: 'Togo Telecom/TogoCELL',
    },
  ],
  _616BJ: [
    {
      mcc: '616',
      mnc: '04',
      network: 'Bell Benin/BBCOM',
    },
    {
      mcc: '616',
      mnc: '02',
      network: 'Etisalat/MOOV',
    },
    {
      mcc: '616',
      mnc: '05',
      network: 'GloMobile',
    },
    {
      mcc: '616',
      mnc: '01',
      network: 'Libercom',
    },
    {
      mcc: '616',
      mnc: '03',
      network: 'MTN/Spacetel',
    },
  ],
  _617MU: [
    {
      mcc: '617',
      mnc: '10',
      network: 'Emtel Ltd',
    },
    {
      mcc: '617',
      mnc: '02',
      network: 'CHILI/MTML',
    },
    {
      mcc: '617',
      mnc: '03',
      network: 'CHILI/MTML',
    },
    {
      mcc: '617',
      mnc: '01',
      network: 'Orange/Cellplus',
    },
  ],
  _618LR: [
    {
      mcc: '618',
      mnc: '04',
      network: 'Comium BVI',
    },
    {
      mcc: '618',
      mnc: '02',
      network: 'Libercell',
    },
    {
      mcc: '618',
      mnc: '20',
      network: 'LibTelco',
    },
    {
      mcc: '618',
      mnc: '01',
      network: 'Lonestar',
    },
    {
      mcc: '618',
      mnc: '07',
      network: 'Orange',
    },
  ],
  _619SL: [
    {
      mcc: '619',
      mnc: '03',
      network: 'Africel',
    },
    {
      mcc: '619',
      mnc: '01',
      network: 'Orange',
    },
    {
      mcc: '619',
      mnc: '04',
      network: 'Comium',
    },
    {
      mcc: '619',
      mnc: '05',
      network: 'Africel',
    },
    {
      mcc: '619',
      mnc: '02',
      network: 'Tigo/Millicom',
    },
    {
      mcc: '619',
      mnc: '25',
      network: 'Mobitel',
    },
    {
      mcc: '619',
      mnc: '07',
      network: 'Qcell',
    },
  ],
  _620GH: [
    {
      mcc: '620',
      mnc: '03',
      network: 'Airtel/Tigo',
    },
    {
      mcc: '620',
      mnc: '06',
      network: 'Airtel/Tigo',
    },
    {
      mcc: '620',
      mnc: '04',
      network: 'Expresso Ghana Ltd',
    },
    {
      mcc: '620',
      mnc: '07',
      network: 'GloMobile',
    },
    {
      mcc: '620',
      mnc: '01',
      network: 'MTN',
    },
    {
      mcc: '620',
      mnc: '02',
      network: 'Vodafone',
    },
  ],
  _621NG: [
    {
      mcc: '621',
      mnc: '20',
      network: 'Airtel/ZAIN/Econet',
    },
    {
      mcc: '621',
      mnc: '60',
      network: 'ETISALAT',
    },
    {
      mcc: '621',
      mnc: '50',
      network: 'Glo Mobile',
    },
    {
      mcc: '621',
      mnc: '40',
      network: 'M-Tel/Nigeria Telecom. Ltd.',
    },
    {
      mcc: '621',
      mnc: '30',
      network: 'MTN',
    },
    {
      mcc: '621',
      mnc: '99',
      network: 'Starcomms',
    },
    {
      mcc: '621',
      mnc: '25',
      network: 'Visafone',
    },
    {
      mcc: '621',
      mnc: '01',
      network: 'Visafone',
    },
  ],
  _622TD: [
    {
      mcc: '622',
      mnc: '04',
      network: 'Salam/Sotel',
    },
    {
      mcc: '622',
      mnc: '02',
      network: 'Tchad Mobile',
    },
    {
      mcc: '622',
      mnc: '03',
      network: 'Tigo/Milicom/Tchad Mobile',
    },
    {
      mcc: '622',
      mnc: '01',
      network: 'Airtel/ZAIN/Celtel',
    },
  ],
  _623CF: [
    {
      mcc: '623',
      mnc: '01',
      network: 'Centrafr. Telecom+',
    },
    {
      mcc: '623',
      mnc: '04',
      network: 'Nationlink',
    },
    {
      mcc: '623',
      mnc: '03',
      network: 'Orange/Celca',
    },
    {
      mcc: '623',
      mnc: '02',
      network: 'Telecel Centraf. ',
    },
  ],
  _624CM: [
    {
      mcc: '624',
      mnc: '01',
      network: 'MTN',
    },
    {
      mcc: '624',
      mnc: '04',
      network: 'Nextel',
    },
    {
      mcc: '624',
      mnc: '02',
      network: 'Orange',
    },
  ],
  _625CV: [
    {
      mcc: '625',
      mnc: '01',
      network: 'CV Movel',
    },
    {
      mcc: '625',
      mnc: '02',
      network: 'T+ Telecom',
    },
  ],
  _626ST: [
    {
      mcc: '626',
      mnc: '01',
      network: 'CSTmovel',
    },
  ],
  _627GQ: [
    {
      mcc: '627',
      mnc: '03',
      network: 'HiTs-GE',
    },
    {
      mcc: '627',
      mnc: '01',
      network: 'ORANGE/GETESA',
    },
  ],
  _628GA: [
    {
      mcc: '628',
      mnc: '04',
      network: 'Azur/Usan S.A.',
    },
    {
      mcc: '628',
      mnc: '01',
      network: 'Libertis S.A.',
    },
    {
      mcc: '628',
      mnc: '02',
      network: 'MOOV/Telecel',
    },
    {
      mcc: '628',
      mnc: '03',
      network: 'Airtel/ZAIN/Celtel Gabon S.A.',
    },
  ],
  _629CG: [
    {
      mcc: '629',
      mnc: '01',
      network: 'Airtel SA',
    },
    {
      mcc: '629',
      mnc: '02',
      network: 'Azur SA (ETC)',
    },
    {
      mcc: '629',
      mnc: '10',
      network: 'MTN/Libertis',
    },
    {
      mcc: '629',
      mnc: '07',
      network: 'Warid',
    },
  ],
  _630CD: [
    {
      mcc: '630',
      mnc: '90',
      network: 'Africell',
    },
    {
      mcc: '630',
      mnc: '86',
      network: 'Orange RDC sarl',
    },
    {
      mcc: '630',
      mnc: '05',
      network: 'SuperCell',
    },
    {
      mcc: '630',
      mnc: '89',
      network: 'TIGO/Oasis',
    },
    {
      mcc: '630',
      mnc: '01',
      network: 'Vodacom',
    },
    {
      mcc: '630',
      mnc: '88',
      network: 'Yozma Timeturns sprl (YTT)',
    },
    {
      mcc: '630',
      mnc: '02',
      network: 'Airtel/ZAIN',
    },
  ],
  _631AO: [
    {
      mcc: '631',
      mnc: '04',
      network: 'MoviCel',
    },
    {
      mcc: '631',
      mnc: '02',
      network: 'Unitel',
    },
  ],
  _632GW: [
    {
      mcc: '632',
      mnc: '01',
      network: 'GuineTel',
    },
    {
      mcc: '632',
      mnc: '03',
      network: 'Orange',
    },
    {
      mcc: '632',
      mnc: '02',
      network: 'SpaceTel',
    },
  ],
  _633SC: [
    {
      mcc: '633',
      mnc: '10',
      network: 'Airtel',
    },
    {
      mcc: '633',
      mnc: '01',
      network: 'C&amp;W',
    },
    {
      mcc: '633',
      mnc: '02',
      network: 'Smartcom',
    },
  ],
  _634SD: [
    {
      mcc: '634',
      mnc: '00',
      network: 'Canar Telecom',
    },
    {
      mcc: '634',
      mnc: '22',
      network: 'MTN',
    },
    {
      mcc: '634',
      mnc: '02',
      network: 'MTN',
    },
    {
      mcc: '634',
      mnc: '15',
      network: 'Sudani One',
    },
    {
      mcc: '634',
      mnc: '07',
      network: 'Sudani One',
    },
    {
      mcc: '634',
      mnc: '08',
      network: 'Canar Telecom',
    },
    {
      mcc: '634',
      mnc: '05',
      network: 'Canar Telecom',
    },
    {
      mcc: '634',
      mnc: '01',
      network: 'ZAIN/Mobitel',
    },
    {
      mcc: '634',
      mnc: '06',
      network: 'ZAIN/Mobitel',
    },
  ],
  _635RW: [
    {
      mcc: '635',
      mnc: '14',
      network: 'Airtel',
    },
    {
      mcc: '635',
      mnc: '10',
      network: 'MTN/Rwandacell',
    },
    {
      mcc: '635',
      mnc: '13',
      network: 'TIGO',
    },
  ],
  _636ET: [
    {
      mcc: '636',
      mnc: '01',
      network: 'ETH/MTN',
    },
  ],
  _637SO: [
    {
      mcc: '637',
      mnc: '30',
      network: 'Golis',
    },
    {
      mcc: '637',
      mnc: '50',
      network: 'Hormuud',
    },
    {
      mcc: '637',
      mnc: '19',
      network: 'HorTel',
    },
    {
      mcc: '637',
      mnc: '60',
      network: 'Nationlink',
    },
    {
      mcc: '637',
      mnc: '10',
      network: 'Nationlink',
    },
    {
      mcc: '637',
      mnc: '04',
      network: 'Somafone',
    },
    {
      mcc: '637',
      mnc: '82',
      network: 'Somtel',
    },
    {
      mcc: '637',
      mnc: '71',
      network: 'Somtel',
    },
    {
      mcc: '637',
      mnc: '01',
      network: 'Telesom ',
    },
  ],
  _638DJ: [
    {
      mcc: '638',
      mnc: '01',
      network: 'Djibouti Telecom SA (Evatis)',
    },
  ],
  _639KE: [
    {
      mcc: '639',
      mnc: '05',
      network: 'Econet Wireless',
    },
    {
      mcc: '639',
      mnc: '02',
      network: 'Safaricom Ltd.',
    },
    {
      mcc: '639',
      mnc: '07',
      network: 'Telkom fka. Orange',
    },
    {
      mcc: '639',
      mnc: '03',
      network: 'Airtel/Zain/Celtel Ltd.',
    },
  ],
  _640TZ: [
    {
      mcc: '640',
      mnc: '08',
      network: 'Benson Informatics Ltd',
    },
    {
      mcc: '640',
      mnc: '06',
      network: 'Dovetel (T) Ltd',
    },
    {
      mcc: '640',
      mnc: '09',
      network: 'Halotel/Viettel Ltd',
    },
    {
      mcc: '640',
      mnc: '11',
      network: 'Smile Communications Tanzania Ltd',
    },
    {
      mcc: '640',
      mnc: '07',
      network: 'Tanzania Telecommunications Company Ltd (TTCL)',
    },
    {
      mcc: '640',
      mnc: '02',
      network: 'TIGO/MIC',
    },
    {
      mcc: '640',
      mnc: '01',
      network: 'Tri Telecomm. Ltd.',
    },
    {
      mcc: '640',
      mnc: '04',
      network: 'Vodacom Ltd',
    },
    {
      mcc: '640',
      mnc: '05',
      network: 'Airtel/ZAIN/Celtel',
    },
    {
      mcc: '640',
      mnc: '03',
      network: 'Zantel/Zanzibar Telecom',
    },
  ],
  _641UG: [
    {
      mcc: '641',
      mnc: '01',
      network: 'Airtel/Celtel',
    },
    {
      mcc: '641',
      mnc: '66',
      network: 'i-Tel Ltd',
    },
    {
      mcc: '641',
      mnc: '30',
      network: 'K2 Telecom Ltd',
    },
    {
      mcc: '641',
      mnc: '10',
      network: 'MTN Ltd.',
    },
    {
      mcc: '641',
      mnc: '14',
      network: 'Orange',
    },
    {
      mcc: '641',
      mnc: '33',
      network: 'Smile Communications Uganda Ltd',
    },
    {
      mcc: '641',
      mnc: '18',
      network: 'Suretelecom Uganda Ltd',
    },
    {
      mcc: '641',
      mnc: '11',
      network: 'Uganda Telecom Ltd.',
    },
    {
      mcc: '641',
      mnc: '22',
      network: 'Airtel/Warid',
    },
  ],
  _642BI: [
    {
      mcc: '642',
      mnc: '02',
      network: 'Africel / Safaris',
    },
    {
      mcc: '642',
      mnc: '08',
      network: 'Lumitel/Viettel',
    },
    {
      mcc: '642',
      mnc: '03',
      network: 'Onatel / Telecel ',
    },
    {
      mcc: '642',
      mnc: '07',
      network: 'Smart Mobile / LACELL ',
    },
    {
      mcc: '642',
      mnc: '82',
      network: 'Spacetel / Econet / Leo ',
    },
    {
      mcc: '642',
      mnc: '01',
      network: 'Spacetel / Econet / Leo ',
    },
  ],
  _643MZ: [
    {
      mcc: '643',
      mnc: '01',
      network: 'mCel',
    },
    {
      mcc: '643',
      mnc: '03',
      network: 'Movitel',
    },
    {
      mcc: '643',
      mnc: '04',
      network: 'Vodacom',
    },
  ],
  _645ZM: [
    {
      mcc: '645',
      mnc: '03',
      network: 'Zamtel/Cell Z/MTS',
    },
    {
      mcc: '645',
      mnc: '02',
      network: 'MTN/Telecel',
    },
    {
      mcc: '645',
      mnc: '01',
      network: 'Airtel/Zain/Celtel',
    },
  ],
  _646MG: [
    {
      mcc: '646',
      mnc: '01',
      network: 'Airtel/MADACOM',
    },
    {
      mcc: '646',
      mnc: '02',
      network: 'Orange/Soci',
    },
    {
      mcc: '646',
      mnc: '03',
      network: 'Sacel',
    },
    {
      mcc: '646',
      mnc: '04',
      network: 'Telma',
    },
  ],
  _647RE: [
    {
      mcc: '647',
      mnc: '00',
      network: 'Orange',
    },
    {
      mcc: '647',
      mnc: '02',
      network: 'Outremer Telecom',
    },
    {
      mcc: '647',
      mnc: '10',
      network: 'SFR',
    },
  ],
  _648ZW: [
    {
      mcc: '648',
      mnc: '04',
      network: 'Econet',
    },
    {
      mcc: '648',
      mnc: '01',
      network: 'Net One',
    },
    {
      mcc: '648',
      mnc: '03',
      network: 'Telecel',
    },
  ],
  _649NA: [
    {
      mcc: '649',
      mnc: '03',
      network: 'TN Mobile',
    },
    {
      mcc: '649',
      mnc: '01',
      network: 'MTC',
    },
    {
      mcc: '649',
      mnc: '02',
      network: 'Switch/Nam. Telec.',
    },
  ],
  _650MW: [
    {
      mcc: '650',
      mnc: '01',
      network: 'TNM/Telekom Network Ltd.',
    },
    {
      mcc: '650',
      mnc: '10',
      network: 'Airtel/Zain/Celtel ltd.',
    },
  ],
  _651LS: [
    {
      mcc: '651',
      mnc: '02',
      network: 'Econet/Ezi-cel',
    },
    {
      mcc: '651',
      mnc: '01',
      network: 'Vodacom Lesotho',
    },
  ],
  _652BW: [
    {
      mcc: '652',
      mnc: '04',
      network: 'BeMOBILE',
    },
    {
      mcc: '652',
      mnc: '01',
      network: 'Mascom Wireless (Pty) Ltd.',
    },
    {
      mcc: '652',
      mnc: '02',
      network: 'Orange',
    },
  ],
  _653SZ: [
    {
      mcc: '653',
      mnc: '02',
      network: 'Swazi Mobile',
    },
    {
      mcc: '653',
      mnc: '10',
      network: 'Swazi MTN',
    },
    {
      mcc: '653',
      mnc: '01',
      network: 'SwaziTelecom',
    },
  ],
  _654KM: [
    {
      mcc: '654',
      mnc: '01',
      network: 'HURI - SNPT',
    },
    {
      mcc: '654',
      mnc: '02',
      network: 'TELMA TELCO SA',
    },
  ],
  _655ZA: [
    {
      mcc: '655',
      mnc: '02',
      network: 'Telkom/8.ta',
    },
    {
      mcc: '655',
      mnc: '21',
      network: 'Cape Town Metropolitan',
    },
    {
      mcc: '655',
      mnc: '07',
      network: 'Cell C',
    },
    {
      mcc: '655',
      mnc: '10',
      network: 'MTN',
    },
    {
      mcc: '655',
      mnc: '12',
      network: 'MTN',
    },
    {
      mcc: '655',
      mnc: '06',
      network: 'Sentech',
    },
    {
      mcc: '655',
      mnc: '01',
      network: 'Vodacom',
    },
    {
      mcc: '655',
      mnc: '19',
      network: 'Wireless Business Solutions (Pty) Ltd',
    },
  ],
  _657ER: [
    {
      mcc: '657',
      mnc: '01',
      network: 'Eritel',
    },
  ],
  _659SS: [
    {
      mcc: '659',
      mnc: '03',
      network: 'Gemtel Ltd (South Sudan',
    },
    {
      mcc: '659',
      mnc: '02',
      network: 'MTN South Sudan (South Sudan',
    },
    {
      mcc: '659',
      mnc: '04',
      network: 'Network of The World Ltd (NOW) (South Sudan',
    },
    {
      mcc: '659',
      mnc: '06',
      network: 'Zain South Sudan (South Sudan',
    },
  ],
  _702BZ: [
    {
      mcc: '702',
      mnc: '67',
      network: 'DigiCell',
    },
    {
      mcc: '702',
      mnc: '68',
      network: 'International Telco (INTELCO)',
    },
  ],
  _704GT: [
    {
      mcc: '704',
      mnc: '01',
      network: 'Claro',
    },
    {
      mcc: '704',
      mnc: '03',
      network: 'Telefonica',
    },
    {
      mcc: '704',
      mnc: '02',
      network: 'TIGO/COMCEL',
    },
  ],
  _706SV: [
    {
      mcc: '706',
      mnc: '01',
      network: 'CLARO/CTE',
    },
    {
      mcc: '706',
      mnc: '02',
      network: 'Digicel',
    },
    {
      mcc: '706',
      mnc: '05',
      network: 'INTELFON SA de CV',
    },
    {
      mcc: '706',
      mnc: '04',
      network: 'Telefonica',
    },
    {
      mcc: '706',
      mnc: '03',
      network: 'Telemovil',
    },
  ],
  _708HN: [
    {
      mcc: '708',
      mnc: '040',
      network: 'Digicel',
    },
    {
      mcc: '708',
      mnc: '030',
      network: 'HonduTel',
    },
    {
      mcc: '708',
      mnc: '001',
      network: 'SERCOM/CLARO',
    },
    {
      mcc: '708',
      mnc: '002',
      network: 'Telefonica/CELTEL',
    },
  ],
  _710NI: [
    {
      mcc: '710',
      mnc: '21',
      network: 'Empresa Nicaraguense de Telecomunicaciones SA (ENITEL)',
    },
    {
      mcc: '710',
      mnc: '30',
      network: 'Movistar',
    },
    {
      mcc: '710',
      mnc: '73',
      network: 'Claro',
    },
  ],
  _712CR: [
    {
      mcc: '712',
      mnc: '03',
      network: 'Claro',
    },
    {
      mcc: '712',
      mnc: '02',
      network: 'ICE',
    },
    {
      mcc: '712',
      mnc: '01',
      network: 'ICE',
    },
    {
      mcc: '712',
      mnc: '04',
      network: 'Movistar',
    },
    {
      mcc: '712',
      mnc: '20',
      network: 'Virtualis',
    },
  ],
  _714PA: [
    {
      mcc: '714',
      mnc: '01',
      network: 'Cable &amp; W./Mas Movil',
    },
    {
      mcc: '714',
      mnc: '03',
      network: 'Claro',
    },
    {
      mcc: '714',
      mnc: '04',
      network: 'Digicel',
    },
    {
      mcc: '714',
      mnc: '020',
      network: 'Movistar',
    },
    {
      mcc: '714',
      mnc: '02',
      network: 'Movistar',
    },
  ],
  _716PE: [
    {
      mcc: '716',
      mnc: '20',
      network: 'Claro /Amer.Mov./TIM',
    },
    {
      mcc: '716',
      mnc: '10',
      network: 'Claro /Amer.Mov./TIM',
    },
    {
      mcc: '716',
      mnc: '02',
      network: 'GlobalStar',
    },
    {
      mcc: '716',
      mnc: '01',
      network: 'GlobalStar',
    },
    {
      mcc: '716',
      mnc: '06',
      network: 'Movistar',
    },
    {
      mcc: '716',
      mnc: '07',
      network: 'Nextel',
    },
    {
      mcc: '716',
      mnc: '17',
      network: 'Nextel',
    },
    {
      mcc: '716',
      mnc: '15',
      network: 'Viettel Mobile',
    },
  ],
  _722AR: [
    {
      mcc: '722',
      mnc: '310',
      network: 'Claro/ CTI/AMX',
    },
    {
      mcc: '722',
      mnc: '330',
      network: 'Claro/ CTI/AMX',
    },
    {
      mcc: '722',
      mnc: '320',
      network: 'Claro/ CTI/AMX',
    },
    {
      mcc: '722',
      mnc: '010',
      network: 'Compania De Radiocomunicaciones Moviles SA',
    },
    {
      mcc: '722',
      mnc: '007',
      network: 'Movistar/Telefonica',
    },
    {
      mcc: '722',
      mnc: '070',
      network: 'Movistar/Telefonica',
    },
    {
      mcc: '722',
      mnc: '020',
      network: 'Nextel',
    },
    {
      mcc: '722',
      mnc: '340',
      network: 'Telecom Personal S.A.',
    },
    {
      mcc: '722',
      mnc: '341',
      network: 'Telecom Personal S.A.',
    },
  ],
  _724BR: [
    {
      mcc: '724',
      mnc: '26',
      network: 'AmericaNet',
    },
    {
      mcc: '724',
      mnc: '12',
      network: 'Claro/Albra/America Movil',
    },
    {
      mcc: '724',
      mnc: '38',
      network: 'Claro/Albra/America Movil',
    },
    {
      mcc: '724',
      mnc: '05',
      network: 'Claro/Albra/America Movil',
    },
    {
      mcc: '724',
      mnc: '01',
      network: 'Vivo S.A./Telemig ',
    },
    {
      mcc: '724',
      mnc: '33',
      network: 'CTBC Celular SA (CTBC)',
    },
    {
      mcc: '724',
      mnc: '32',
      network: 'CTBC Celular SA (CTBC)',
    },
    {
      mcc: '724',
      mnc: '34',
      network: 'CTBC Celular SA (CTBC)',
    },
    {
      mcc: '724',
      mnc: '08',
      network: 'TIM',
    },
    {
      mcc: '724',
      mnc: '39',
      network: 'Nextel (Telet)',
    },
    {
      mcc: '724',
      mnc: '00',
      network: 'Nextel (Telet)',
    },
    {
      mcc: '724',
      mnc: '24',
      network: 'Amazonia Celular S/A',
    },
    {
      mcc: '724',
      mnc: '30',
      network: 'Oi (TNL PCS / Oi)',
    },
    {
      mcc: '724',
      mnc: '31',
      network: 'Oi (TNL PCS / Oi)',
    },
    {
      mcc: '724',
      mnc: '16',
      network: 'Brazil Telcom',
    },
    {
      mcc: '724',
      mnc: '54',
      network: 'PORTO SEGURO TELECOMUNICACOES',
    },
    {
      mcc: '724',
      mnc: '15',
      network: 'Sercontel Cel',
    },
    {
      mcc: '724',
      mnc: '07',
      network: 'CTBC/Triangulo ',
    },
    {
      mcc: '724',
      mnc: '19',
      network: 'Vivo S.A./Telemig ',
    },
    {
      mcc: '724',
      mnc: '03',
      network: 'TIM',
    },
    {
      mcc: '724',
      mnc: '02',
      network: 'TIM',
    },
    {
      mcc: '724',
      mnc: '04',
      network: 'TIM',
    },
    {
      mcc: '724',
      mnc: '37',
      network: 'Unicel do Brasil Telecomunicacoes Ltda',
    },
    {
      mcc: '724',
      mnc: '06',
      network: 'Vivo S.A./Telemig ',
    },
    {
      mcc: '724',
      mnc: '23',
      network: 'Vivo S.A./Telemig ',
    },
    {
      mcc: '724',
      mnc: '11',
      network: 'Vivo S.A./Telemig ',
    },
    {
      mcc: '724',
      mnc: '10',
      network: 'Vivo S.A./Telemig ',
    },
  ],
  _730CL: [
    {
      mcc: '730',
      mnc: '06',
      network: 'Blue Two Chile SA',
    },
    {
      mcc: '730',
      mnc: '11',
      network: 'Celupago SA',
    },
    {
      mcc: '730',
      mnc: '15',
      network: 'Cibeles Telecom SA',
    },
    {
      mcc: '730',
      mnc: '03',
      network: 'Claro',
    },
    {
      mcc: '730',
      mnc: '10',
      network: 'Entel Telefonia',
    },
    {
      mcc: '730',
      mnc: '01',
      network: 'Entel Telefonia Mov',
    },
    {
      mcc: '730',
      mnc: '14',
      network: 'Netline Telefonica Movil Ltda',
    },
    {
      mcc: '730',
      mnc: '09',
      network: 'Nextel SA',
    },
    {
      mcc: '730',
      mnc: '05',
      network: 'Nextel SA',
    },
    {
      mcc: '730',
      mnc: '04',
      network: 'Nextel SA',
    },
    {
      mcc: '730',
      mnc: '19',
      network: 'Sociedad Falabella Movil SPA',
    },
    {
      mcc: '730',
      mnc: '02',
      network: 'TELEFONICA',
    },
    {
      mcc: '730',
      mnc: '07',
      network: 'TELEFONICA',
    },
    {
      mcc: '730',
      mnc: '12',
      network: 'Telestar Movil SA',
    },
    {
      mcc: '730',
      mnc: '00',
      network: 'TESAM SA',
    },
    {
      mcc: '730',
      mnc: '13',
      network: 'Tribe Mobile SPA',
    },
    {
      mcc: '730',
      mnc: '08',
      network: 'VTR Banda Ancha SA',
    },
  ],
  _732CO: [
    {
      mcc: '732',
      mnc: '130',
      network: 'Avantel SAS',
    },
    {
      mcc: '732',
      mnc: '102',
      network: 'Movistar',
    },
    {
      mcc: '732',
      mnc: '103',
      network: 'TIGO/Colombia Movil',
    },
    {
      mcc: '732',
      mnc: '001',
      network: 'TIGO/Colombia Movil',
    },
    {
      mcc: '732',
      mnc: '101',
      network: 'Comcel S.A. Occel S.A./Celcaribe',
    },
    {
      mcc: '732',
      mnc: '002',
      network: 'Edatel S.A.',
    },
    {
      mcc: '732',
      mnc: '187',
      network: 'eTb',
    },
    {
      mcc: '732',
      mnc: '123',
      network: 'Movistar',
    },
    {
      mcc: '732',
      mnc: '111',
      network: 'TIGO/Colombia Movil',
    },
    {
      mcc: '732',
      mnc: '142',
      network: 'UNE EPM Telecomunicaciones SA ESP',
    },
    {
      mcc: '732',
      mnc: '020',
      network: 'UNE EPM Telecomunicaciones SA ESP',
    },
    {
      mcc: '732',
      mnc: '154',
      network: 'Virgin Mobile Colombia SAS',
    },
  ],
  _734VE: [
    {
      mcc: '734',
      mnc: '03',
      network: 'DigiTel C.A.',
    },
    {
      mcc: '734',
      mnc: '02',
      network: 'DigiTel C.A.',
    },
    {
      mcc: '734',
      mnc: '01',
      network: 'DigiTel C.A.',
    },
    {
      mcc: '734',
      mnc: '06',
      network: 'Movilnet C.A. ',
    },
    {
      mcc: '734',
      mnc: '04',
      network: 'Movistar/TelCel',
    },
  ],
  _736BO: [
    {
      mcc: '736',
      mnc: '02',
      network: 'Entel Pcs',
    },
    {
      mcc: '736',
      mnc: '01',
      network: 'Viva/Nuevatel',
    },
    {
      mcc: '736',
      mnc: '03',
      network: 'Tigo',
    },
  ],
  _738GY: [
    {
      mcc: '738',
      mnc: '02',
      network: 'Cellink Plus',
    },
    {
      mcc: '738',
      mnc: '01',
      network: 'DigiCel',
    },
  ],
  _740EC: [
    {
      mcc: '740',
      mnc: '01',
      network: 'Claro/Port',
    },
    {
      mcc: '740',
      mnc: '02',
      network: 'CNT Mobile',
    },
    {
      mcc: '740',
      mnc: '000',
      network: 'Failed Call(s)',
    },
    {
      mcc: '740',
      mnc: '00',
      network: 'MOVISTAR/OteCel',
    },
    {
      mcc: '740',
      mnc: '03',
      network: 'Tuenti',
    },
  ],
  _744PY: [
    {
      mcc: '744',
      mnc: '02',
      network: 'Claro/Hutchison',
    },
    {
      mcc: '744',
      mnc: '03',
      network: 'Compa',
    },
    {
      mcc: '744',
      mnc: '01',
      network: 'Hola/VOX',
    },
    {
      mcc: '744',
      mnc: '05',
      network: 'TIM/Nucleo/Personal',
    },
    {
      mcc: '744',
      mnc: '04',
      network: 'Tigo/Telecel',
    },
  ],
  _746SR: [
    {
      mcc: '746',
      mnc: '03',
      network: 'Digicel',
    },
    {
      mcc: '746',
      mnc: '01',
      network: 'Telesur',
    },
    {
      mcc: '746',
      mnc: '02',
      network: 'Telecommunicatiebedrijf Suriname (TELESUR)',
    },
    {
      mcc: '746',
      mnc: '04',
      network: 'UNIQA',
    },
  ],
  _748UY: [
    {
      mcc: '748',
      mnc: '00',
      network: 'Ancel/Antel',
    },
    {
      mcc: '748',
      mnc: '01',
      network: 'Ancel/Antel',
    },
    {
      mcc: '748',
      mnc: '03',
      network: 'Ancel/Antel',
    },
    {
      mcc: '748',
      mnc: '10',
      network: 'Claro/AM Wireless',
    },
    {
      mcc: '748',
      mnc: '07',
      network: 'MOVISTAR',
    },
  ],
  _750FK: [
    {
      mcc: '750',
      mnc: '001',
      network: 'Cable and Wireless South Atlantic Ltd (Falkland Islands',
    },
  ],
  _901SATELLITE: [
    {
      mcc: '901',
      mnc: '13',
      network: 'Antarctica',
    },
    {
      mcc: '901',
      mnc: '14',
      network: 'AeroMobile',
    },
    {
      mcc: '901',
      mnc: '11',
      network: 'InMarSAT',
    },
    {
      mcc: '901',
      mnc: '12',
      network: 'Maritime Communications Partner AS',
    },
    {
      mcc: '901',
      mnc: '05',
      network: 'Thuraya Satellite',
    },
  ],
};
