export const TASKS_LOAD_STARTED = 'TASKS_LOAD_STARTED';
export const TASKS_LOAD_FAILED = 'TASKS_LOAD_FAILED';
export const TASKS_LOAD_SUCCESS = 'TASKS_LOAD_SUCCESS';

export function loadTasksStarted() {
  return {
    type: TASKS_LOAD_STARTED,
  };
}

export function loadTasksFailed(payload) {
  return {
    type: TASKS_LOAD_FAILED,
    payload,
  };
}

export function tasksLoaded(payload) {
  return {
    type: TASKS_LOAD_SUCCESS,
    payload,
  };
}
