export const COMPANY_LOAD_INFO_STARTED = 'COMPANY_LOAD_INFO_STARTED';
export const COMPANY_LOAD_INFO_SUCCESS = 'COMPANY_LOAD_INFO_SUCCESS';
export const COMPANY_LOAD_INFO_FAILED = 'COMPANY_LOAD_INFO_FAILED';
export const COMPANY_SET_SETTINGS_FAILED = 'COMPANY_SET_SETTINGS_FAILED';
export const COMPANY_SET_SETTINGS_STARTED = 'COMPANY_SET_SETTINGS_STARTED';
export const COMPANY_SET_SETTINGS_SUCCESS = 'COMPANY_SET_SETTINGS_SUCCESS';
export const COMPANY_SET_SETTINGS_RESET = 'COMPANY_SET_SETTINGS_RESET';
export const COMPANY_LOAD_SETTINGS_FAILED = 'COMPANY_LOAD_SETTINGS_FAILED';
export const COMPANY_LOAD_SETTINGS_STARTED = 'COMPANY_LOAD_SETTINGS_STARTED';
export const COMPANY_LOAD_SETTINGS_SUCCESS = 'COMPANY_LOAD_SETTINGS_SUCCESS';
