import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { setLocale } from 'react-redux-i18n';
import moment from 'moment';
import { localizationLocales } from '../../assets/translations';
import { ReactComponent as LocaleSwitchIcon } from '../../assets/images/switch-language.svg';
import RadioButtonGroup from '../RadioButtonGroup';
import { useTypedSelector } from '../../hooks/useTypedSelector';

const LocaleSwitch = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { locale: currentLocale } = useTypedSelector((state) => state.i18n);
  useEffect(() => {
    const { momentCode } = localizationLocales[currentLocale] ?? {};
    moment.locale(momentCode);
  }, []);

  const localeItems = Object.values(localizationLocales).map(
    ({ code, name: localeName }) => ({
      value: code,
      label: localeName,
      name: 'localeSwitcher',
    })
  );

  const handleLocaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    dispatch(setLocale(value));
    window.location.assign('/');
  };

  return (
    <Popup
      closeOnDocumentClick
      position="bottom right"
      closeOnEscape
      repositionOnResize
      trigger={
        <div>
          <LocaleSwitchIcon className="icon--fixed-square-2x" />
        </div>
      }
    >
      <div className="language-switch">
        <RadioButtonGroup
          checkedValue={currentLocale}
          options={localeItems}
          onChange={handleLocaleChange}
          withColumnView
        />
      </div>
    </Popup>
  );
};

export default LocaleSwitch;
