import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import PopupActions from '../../features/popup/popup.actions';
import { LAST_ACTIVE_CONSTANT } from '../../constants/user';
import { clearAllData } from '../../store/persistedState';

const NUMBER_OF_MILLISECONDS_IN_ONE_MIN = 60_000;
const INACTIVITY_TIMEOUT = 15; // In minutes
const IDLE_WARNING_POPUP_ID = 'IDLE_WARNING_POPUP';

const getLastActiveFromLocalStorage = () =>
  JSON.parse(window.localStorage.getItem(LAST_ACTIVE_CONSTANT));

const SessionTimeout = ({ logout, isAuthenticated }) => {
  const [isTimedOut, setIsTimedOut] = useState(false);

  const { getLastActiveTime, reset } = useIdleTimer({
    timeout: INACTIVITY_TIMEOUT * NUMBER_OF_MILLISECONDS_IN_ONE_MIN,
    onIdle,
    onActive,
    onAction,
    debounce: 500,
  });

  function onAction() {
    setIsTimedOut(false);
  }

  function onActive() {
    setIsTimedOut(false);
  }

  const showSessionTimedOutPopup = () =>
    PopupActions.showAlert({
      id: 'SESSION_TIME_OUT',
      title: <Translate value="session.sessionExpiryTitle" />,
      text: <Translate value="session.sessionExpiryMessage" />,
      onClose: () => {
        clearAllData();
        window.location.assign('/');
      },
    });

  function onIdle() {
    if (isTimedOut) {
      PopupActions.closePopup(IDLE_WARNING_POPUP_ID);
      logout();
      showSessionTimedOutPopup();
    } else {
      PopupActions.showConfirm({
        id: IDLE_WARNING_POPUP_ID,
        onConfirm: () => {
          logout({
            reloadApp: true,
            clearData: true,
          });
        },
        text: <Translate value="session.sessionExpiryWarningMessage" />,
        title: <Translate value="session.sessionExpiryWarningTitle" />,
        confirmButtonText: <Translate value="session.logoutNow" />,
        cancelButtonText: <Translate value="session.stayLoggedIn" />,
      });
      reset?.();
      setIsTimedOut(true);
    }
  }

  const didSessionTimeoutSinceLastActivity = () => {
    const lastActive = getLastActiveFromLocalStorage();
    if (!lastActive) {
      return false;
    }
    const timeDifferenceInMinutes = moment().diff(
      moment(parseInt(lastActive), 'x'),
      'minutes'
    );
    return timeDifferenceInMinutes > INACTIVITY_TIMEOUT * 2;
  };

  useEffect(() => {
    function saveLastActiveToLocalStorage() {
      const lastActive = getLastActiveTime();
      window.localStorage.setItem(
        LAST_ACTIVE_CONSTANT,
        JSON.stringify(lastActive)
      );
    }

    window.addEventListener('beforeunload', saveLastActiveToLocalStorage);
    return () => {
      window.addEventListener('beforeunload', saveLastActiveToLocalStorage);
    };
  }, [getLastActiveTime]);

  useEffect(() => {
    if (isAuthenticated && didSessionTimeoutSinceLastActivity()) {
      function handleSessionTimeoutSinceLastActivity() {
        logout({
          reloadApp: true,
          clearData: true,
        });
        showSessionTimedOutPopup();
      }
      handleSessionTimeoutSinceLastActivity();
    }
  }, [isAuthenticated, logout]);

  return <></>;
};

export default SessionTimeout;
