import React from 'react';
import Tool from '../ToolBar/Tool';
import { ReactComponent as FileExportCSV } from '../../assets/images/file_export_csv.svg';
import { downloadFile } from '../../common/helpers';
import StyleUtils from '../../utils/styleUtils';
import { generateTableCSV } from '../../common/utilities/csv';

const ExportToCSV = ({ title, data, columns, fileName, disabled = false }) => {
  const handleExport = () => {
    if (disabled) {
      return;
    }
    const csvData = generateTableCSV(data, columns);
    downloadFile(csvData, fileName, 'text/csv');
  };

  const disabledClassName = disabled ? 'toolbar-action-icon--disabled' : '';

  return (
    <Tool
      title={title}
      className={StyleUtils.mergeClasses(
        'toolbar-action-icon--squired',
        disabledClassName
      )}
      onClick={handleExport}
    >
      <FileExportCSV className={'icon'} />
    </Tool>
  );
};

export default ExportToCSV;
