import {
  getDeviceActivityLogsApi,
  getDeviceApplicationsApi,
  getDeviceLogsApi,
  getDeviceProfilesApi,
  pingDeviceLocationApi,
  requestDeviceRemoteControlApi,
  uninstallApplicationsApi,
} from './device.api';
import {
  requestDeviceRemoteControlDone,
  requestDeviceRemoteControlFailed,
  requestDeviceRemoteControlStarted,
} from '../devices/devices.actions';
import {
  deserializeActivityLogEntry,
  deserializeDevice,
  deserializeLogEntry,
  serializeDeviceLogRequestParams,
} from './device.serializer';
import { deserializeProfile } from '../profile/profile.serializer';
import { deserializeApplication } from '../application/application.serializer';
import {
  applicationsUninstallFailed,
  applicationsUninstallStarted,
  applicationsUninstallSuccess,
  loadDeviceActivityLogsFailed,
  loadDeviceActivityLogsStarted,
  loadDeviceActivityLogsSuccess,
  loadDeviceApplicationsFailed,
  loadDeviceApplicationsStarted,
  loadDeviceApplicationsSuccess,
  loadDeviceFailed,
  loadDeviceLogsFailed,
  loadDeviceLogsStarted,
  loadDeviceLogsSuccess,
  loadDeviceProfilesFailed,
  loadDeviceProfilesStarted,
  loadDeviceProfilesSuccess,
  loadDeviceStarted,
  setDeviceData,
  updateDevicePropertyFailed,
  updateDevicePropertyStarted,
  updateDevicePropertySuccess,
} from './device.actions';
import client from '../../utils/client';
import services from '../../utils/services';
import { isObject } from '../../common/helpers';
import { I18n } from 'react-redux-i18n';
import { handleRequestResponse } from '../../common/utilities/errorHandling';

export function loadDevice(deviceId) {
  return async (dispatch) => {
    try {
      dispatch(loadDeviceStarted());
      const response = await client.get(`${services.getDevices}${deviceId}`);
      handleRequestResponse({
        response,
        successStatusCode: 200,
        onSuccess: () => {
          dispatch(setDeviceData(deserializeDevice(response.data)));
        },
        showSuccessPopup: false,
        onError: () => {
          dispatch(loadDeviceFailed(response.data));
        },
        defaultErrorMessageTextCode: 'device.deviceLoadFailureMessage',
      });
    } catch (e) {
      dispatch(loadDeviceFailed('Failed to load device information'));
    }
  };
}

export function uninstallApplications(apps, id) {
  return async (dispatch) => {
    try {
      dispatch(applicationsUninstallStarted());
      let response = await uninstallApplicationsApi(
        {
          apps: apps.map((app_id_name) => ({
            app_id_name,
            command: 'uninstall',
          })),
        },
        id
      );
      if (response.status === 200) {
        dispatch(applicationsUninstallSuccess());
      } else {
        dispatch(applicationsUninstallFailed(response.status.text));
      }
    } catch (error) {
      dispatch(
        applicationsUninstallFailed(
          new Error(I18n.t('devices.applicationsUninstallFailed'))
        )
      );
    }
  };
}

export function updateDeviceProperty(id, propertyName, newValue) {
  return async (dispatch) => {
    try {
      dispatch(updateDevicePropertyStarted());
      let response = await client.post(`${services.devices}${id}`, {
        [propertyName]: newValue,
      });
      if (response.status === 200) {
        dispatch(updateDevicePropertySuccess());
        dispatch(setDeviceData(deserializeDevice(response.data)));
      } else if (response.status === 400) {
        dispatch(
          updateDevicePropertyFailed(
            I18n.t('devices.devicePropertyValueMustBeUnique')
          )
        );
      } else {
        dispatch(updateDevicePropertyFailed(response.status.text));
      }
    } catch (error) {
      dispatch(
        updateDevicePropertyFailed(
          new Error(I18n.t('devices.devicePropertyUpdateFailed'))
        )
      );
    }
  };
}

export function pingDeviceLocation(id) {
  return async (dispatch) => {
    try {
      await pingDeviceLocationApi(id);
    } catch (error) {
      dispatch(
        loadDeviceLogsFailed(
          new Error(I18n.t('devices.deviceLogsLoadingFailed'))
        )
      );
    }
  };
}

export function requestDeviceRemoteControl(id) {
  return async (dispatch) => {
    try {
      dispatch(requestDeviceRemoteControlStarted());
      // const instance = axios.create({
      //     headers: {'Content-Type': 'application/json', 'Authorization':
      //     `key=${authorization_key}` },
      // });
      // let response = await instance.post('https://fcm.googleapis.com/fcm/send', {
      //
      //     "data": {
      //     "type": "start_sharing"
      //     },
      //     "registration_ids": [firebaseToken]
      //
      // });
      //if (response.status === 200 && response.data && response.data.results && !response.data.results.error) {
      const res = await requestDeviceRemoteControlApi(id);
      if (res.status === 200) {
        dispatch(requestDeviceRemoteControlDone());
      } else {
        dispatch(requestDeviceRemoteControlFailed());
      }
    } catch (error) {
      dispatch(requestDeviceRemoteControlFailed());
    }
  };
}

export function loadDeviceProfiles(id) {
  return async (dispatch) => {
    try {
      dispatch(loadDeviceProfilesStarted());
      let { status, data } = await getDeviceProfilesApi(id);
      if (status === 200 && data) {
        let result = data.map(deserializeProfile);
        dispatch(loadDeviceProfilesSuccess(result));
      } else {
        dispatch(loadDeviceProfilesFailed(new Error(status.text)));
      }
    } catch (error) {
      dispatch(
        loadDeviceProfilesFailed(
          new Error(I18n.t('devices.deviceProfilesLoadingFailed'))
        )
      );
    }
  };
}

export function loadDeviceApplications(id) {
  return async (dispatch) => {
    try {
      dispatch(loadDeviceApplicationsStarted());
      let response = await getDeviceApplicationsApi(id);
      if (response.status === 200 && response.data) {
        const data = isObject(response.data)
          ? Object.values(response.data)
          : response.data;
        let result = data.map(deserializeApplication);
        dispatch(loadDeviceApplicationsSuccess(result));
      } else {
        dispatch(loadDeviceApplicationsFailed(new Error(response.status.text)));
      }
    } catch (error) {
      dispatch(
        loadDeviceApplicationsFailed(
          new Error(I18n.t('devices.applicationsLoadingFailed'))
        )
      );
    }
  };
}

export function loadDeviceLocationLogs({ deviceId, ...otherParams } = {}) {
  return async (dispatch) => {
    try {
      dispatch(loadDeviceLogsStarted());
      let response = await getDeviceLogsApi(
        deviceId,
        serializeDeviceLogRequestParams(otherParams)
      );
      if (response.status === 200 && response.data) {
        let result = response.data.map(deserializeLogEntry);
        dispatch(loadDeviceLogsSuccess(result));
      } else {
        dispatch(
          loadDeviceLogsFailed(
            new Error(
              response.data?.detail || response.status?.text || response.text
            )
          )
        );
      }
      // dispatch(
      //   loadDeviceLogsSuccess(newSampleLocationLogMay12.map(deserializeLogEntry))
      // );
    } catch (error) {
      dispatch(
        loadDeviceLogsFailed(
          new Error(I18n.t('devices.deviceLogsLoadingFailed'))
        )
      );
    }
  };
}

export function loadDeviceActivityLogs({ deviceId, ...otherParams } = {}) {
  return async (dispatch) => {
    try {
      dispatch(loadDeviceActivityLogsStarted());
      let response = await getDeviceActivityLogsApi(
        deviceId,
        serializeDeviceLogRequestParams(otherParams)
      );
      if (response.status === 200 && response.data) {
        let result = response.data.map(deserializeActivityLogEntry);
        dispatch(loadDeviceActivityLogsSuccess(result));
      } else {
        dispatch(
          loadDeviceActivityLogsFailed(
            new Error(
              response.data?.detail || response.status?.text || response.text
            )
          )
        );
      }

      // dispatch(
      //   loadDeviceActivityLogsSuccess(sampleDeviceActivityLog.map(deserializeActivityLogEntry))
      // );
    } catch (error) {
      dispatch(
        loadDeviceActivityLogsFailed('Failed to load device activity logs')
      );
    }
  };
}
