import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import { logout } from '../features/login/login.actions';
import { store } from '../store/store';
import { getHost, getProtocol } from './env';
import PopupActions from '../features/popup/popup.actions';
import { history } from './history';

export const apiUrl = `${getProtocol()}//${getHost()}`;

// warning!!! do not localize. used for checking string coming from the server
const doNotHavePermissionToPerformThisAction =
  'You do not have permission to perform this action.';

// warning!!! do not localize. used for checking string coming from the server
const authenticationCredentialsWereNotProvided =
  'Authentication credentials were not provided.';

const badCredentials = 'bad credentials';

const createAxiosInstance = ({
  version: versionNumber,
  customVersion,
  service = 'uis',
}) => {
  const version = customVersion ?? `v${versionNumber}`;
  const axiosInstance = axios.create({
    baseURL: `${apiUrl}/${service}/${version}`,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    credentials: 'same-origin',
  });
  axiosInstance.interceptors.response.use(undefined, (err) => {
    if (err.response?.status === 403) {
      // handles session timout. after the session times out, sessionId ceases to exit and is not sent to the backend.
      // In response to that, django send the authenticationCredentialsWereNotProvided error message.
      if (
        err.response?.data?.detail?.includes(
          authenticationCredentialsWereNotProvided
        )
      ) {
        return store.dispatch(
          logout({
            clearData: true,
            reloadApp: true,
          })
        );
      }
      if (
        err.response?.data?.detail?.includes(
          doNotHavePermissionToPerformThisAction
        )
      ) {
        let errorText =
          err?.text ?? I18n.t('errors.invalidPermissionsOrAccessLevel');
        PopupActions.showAlert({
          text: errorText,
          onClose: () => {
            err.response?.data?.detail?.includes(
              authenticationCredentialsWereNotProvided
            )
              ? store.dispatch(
                  logout({
                    clearData: true,
                    reloadApp: true,
                  })
                )
              : history.push('/');
          },
        });
      }
    }

    if (
      err.response &&
      err.response.status === 401 &&
      !err.response?.data?.detail?.includes(badCredentials)
    ) {
      store.dispatch(
        logout({ error: new Error(I18n.t('errors.unauthorized')) })
      );
    }
    return err.response;
  });

  axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken';
  axiosInstance.defaults.xsrfCookieName = 'csrftoken';
  axiosInstance.defaults.withCredentials = true;
  return axiosInstance;
};

const client = createAxiosInstance({
  version: 1,
});
export const clientV2 = createAxiosInstance({
  version: 2,
});

export const clientBC = createAxiosInstance({
  customVersion: 'bc', // backwards compatibility
});

export const clientFS = createAxiosInstance({
  version: 1,
  service: 'dfs',
});

export default client;
