import { v4 as uuid } from 'uuid';
import { I18n } from 'react-redux-i18n';
import { DeviceRoutes } from '../../utils/routes';
import { notificationTypes } from '../../constants/notifications';

export const prepareReceivedNotification = ({
  type,
  deviceId,
  deviceName,
  alertRaisedDatetime,
  inFence,
  securityCompliance,
  lowBattery,
  id,
  ...rest
}) => {
  let text = '';

  switch (type) {
    case notificationTypes.GEOFENCE: {
      text =
        inFence === true
          ? I18n.t('notifications.deviceHasEnteredTheGeofence', { deviceName })
          : I18n.t('notifications.deviceHasLeftTheGeofence', { deviceName });
      break;
    }

    case notificationTypes.SECURITY_COMPLIANCE: {
      text =
        securityCompliance === true
          ? I18n.t('notifications.deviceIsSecurityCompliant', { deviceName })
          : securityCompliance === false
          ? I18n.t('notifications.deviceIsNotSecurityCompliant', { deviceName })
          : I18n.t('notifications.deviceSecurityComplianceStatusUnknown', {
              deviceName,
            });
      break;
    }

    case notificationTypes.LOW_BATTERY: {
      text =
        lowBattery === true
          ? I18n.t('notifications.deviceHasALowBatteryLevel', { deviceName })
          : lowBattery === false
          ? I18n.t('notifications.deviceHasANormalBatteryLevel', { deviceName })
          : I18n.t('notifications.deviceHasAnUnknownBatteryLevel', {
              deviceName,
            });
      break;
    }

    default:
      break;
  }

  return {
    ...rest,
    id: id || uuid(),
    text,
    level: 'INFO',
    type: 'LINK',
    url: `${DeviceRoutes}/${deviceId}`,
    timeStamp: alertRaisedDatetime || new Date().toISOString(),
    read: false,
  };
};
