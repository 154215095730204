import {
  deserializeProfile,
  serializeProfile,
} from '../profile/profile.serializer';

export const deserializeEnrollment = (data) => {
  return {
    companyId: data.company_id || null,
    nfc: data.enrollment_NFC || null,
    url: data.enrollment_URL || null,
    deviceNaming: deserializeEnrollmentDeviceNaming(
      data.enrollment_device_naming || {}
    ),
    id: data.enrollment_id || null,
    name: data.enrollment_name || '',
    password: data.enrollment_password || '',
    status: data.enrollment_status || null,
    fleetId: data.fleet_v2_id || data.fleet_id || null,
    profiles: data.profiles?.length
      ? data.profiles.map(deserializeProfile)
      : [],
    profilesV2: data.profiles_v2?.length
      ? data.profiles_v2.map(deserializeProfile)
      : [],
    shortId: data.short_enrollment_id || null,
    deviceType: data.device_type || 'All',
    devices: data.devices && data.devices.length ? data.devices : [],
    policies: data.policies && data.policies.length ? data.policies : [],
    lastUpdated: data.last_updated || undefined,
    createdAt: data.created_at || undefined,
    stagingQRCodeData: data.staging_qr_code_data || undefined,
  };
};
export const serializeEnrollment = (data) => {
  return {
    company_id: data.companyId || undefined,
    enrollment_NFC: data.nfc || undefined,
    enrollment_URL: data.url || undefined,
    enrollment_device_naming: serializeEnrollmentDeviceNaming(
      data.deviceNaming || {}
    ),
    enrollment_id: data.id || undefined,
    enrollment_name: data.name || '',
    enrollment_password: data.password || '',
    enrollment_status: data.status || undefined,
    fleet_v2: data.fleetId || undefined,
    fleet_id: data.fleetId || undefined,
    fleet_v2_id: data.fleetId || undefined,
    profiles:
      data.profiles && data.profiles.length
        ? data.profiles.map(serializeProfile)
        : [],
    short_enrollment_id: data.shortId || undefined,
    device_type: data.deviceType || 'All',
    staging_qr_code_data: data.stagingQRCodeData,
  };
};

export const deserializeEnrollmentDeviceNaming = (data) => {
  return {
    name: data.name || '',
    sequence: data.sequence
      ? [
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
        ].map((item, index) => {
          let existingItem = data.sequence[index];
          if (
            existingItem &&
            [
              'imei',
              'phone_number',
              'numbered_sequence',
              'mac_address',
              'hardware_serial_number',
              'os_version',
              'model',
              'manufacturer',
              'device_name',
              'name',
            ].some((item) => item === existingItem)
          ) {
            return existingItem;
          }
          return item;
        })
      : [
          'name',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
        ],
    separator: data.separator || '',
    startingNumber:
      data.startingNumber != null && !isNaN(Number(data.startingNumber))
        ? data.startingNumber
        : '0',
  };
};

export const serializeEnrollmentDeviceNaming = (data = {}) => {
  return {
    name: data.name || '',
    sequence: data.sequence
      ? [
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
        ].map((item, index) => {
          let existingItem = data.sequence[index];
          if (
            existingItem &&
            [
              'imei',
              'phone_number',
              'numbered_sequence',
              'mac_address',
              'hardware_serial_number',
              'os_version',
              'model',
              'manufacturer',
              'device_name',
              'name',
            ].some((item) => item === existingItem)
          ) {
            return existingItem;
          }
          return item;
        })
      : [
          'name',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
          'None',
        ],
    separator: data.separator || '',
    startingNumber: data.startingNumber || '0',
  };
};
