import qs from 'qs';

// An artificial arbitrary prefix for 'number' values, since we want to get a number after
// serialization / deserialization, not a string (a known issue of the qs library).
const numberPrefix = '___';
const numberRegex = new RegExp(`^${numberPrefix}(\\d+|\\d*\\.\\d+)$`);

/**
 * custom encoder.
 * @{see customDecoder}
 */
function customEncoder(str, defaultEncoder, charset, type) {
  if (type === 'value') {
    if (typeof str === 'number') {
      return defaultEncoder(
        `${numberPrefix}${str}`,
        defaultEncoder,
        charset,
        type
      );
    }
  }
  return defaultEncoder(str, defaultEncoder, charset, type);
}

/** A customer decoder for url elements. Needed in order to avoid some qs-library known issues
 * (it converts boolean values and numbers to string, which isn't what is required). The solution
 * was supported by the qs library author:
 * @{link https://github.com/ljharb/qs/issues/91#issuecomment-491391000}
 */
function customDecoder(str, defaultDecoder, charset) {
  const strWithoutPlus = str.replace(/\+/g, ' ');
  if (charset === 'iso-8859-1') {
    // unescape never throws, no try...catch needed:
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
  }
  if (numberRegex.test(str)) {
    return parseFloat(str.replace(numberPrefix, ''));
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  };
  if (str in keywords) {
    return keywords[str];
  }

  // utf-8
  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
}

function stringify(obj) {
  return qs.stringify(obj, { allowDots: true, encoder: customEncoder });
}

function parse(queryStr) {
  return qs.parse(queryStr, { allowDots: true, decoder: customDecoder });
}

export default {
  stringify,
  parse,
};
