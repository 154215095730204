import { NetworkStatus } from '../../common/constants';
import {
  TASKS_LOAD_FAILED,
  TASKS_LOAD_STARTED,
  TASKS_LOAD_SUCCESS,
} from './tasks.actions';

export default function tasks(
  state = {
    status: NetworkStatus.NONE,
    error: undefined,
    data: [],
    next: null,
  },
  action
) {
  switch (action.type) {
    case TASKS_LOAD_STARTED:
      return Object.assign({}, state, {
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case TASKS_LOAD_FAILED:
      return Object.assign({}, state, {
        data: [],
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case TASKS_LOAD_SUCCESS: {
      const {
        payload: { data: payloadData, isInitialLoad, ...rest },
      } = action;
      return {
        data: [...(isInitialLoad ? [] : state.data), ...payloadData],
        ...rest,
        status: NetworkStatus.DONE,
        error: undefined,
      };
    }
    default:
      return state;
  }
}
