import React from 'react';
import { Translate } from 'react-redux-i18n';

const LinkedFleets = ({ item: { fleets = [] } = {} }) => {
  const numberOfLinkedFleets = fleets.length;
  return (
    <div className="alert-row__linked-fleets">
      <Translate
        value="alerts.numberOfLinkedFleets"
        numberOfLinkedFleets={numberOfLinkedFleets}
      />
    </div>
  );
};

export default LinkedFleets;
