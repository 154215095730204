import { NetworkStatus } from '../../common/constants';
import { ProfileV2ActionTypes } from './profileV2.actionTypes';

export default function profileV2(
  state = {
    data: undefined,
    status: NetworkStatus.NONE,
    error: undefined,
    createProfileStatus: NetworkStatus.NONE,
    createProfileError: undefined,
    updateProfileStatus: NetworkStatus.NONE,
    updateProfileError: undefined,
    applications: [],
    applicationsStatus: NetworkStatus.NONE,
    applicationsError: undefined,
  },
  action
) {
  switch (action.type) {
    case ProfileV2ActionTypes.PROFILE_V2_CREATION_STARTED:
      return Object.assign({}, state, {
        createProfileStatus: NetworkStatus.STARTED,
        createProfileError: undefined,
      });
    case ProfileV2ActionTypes.PROFILE_V2_CREATION_SUCCESS:
      return Object.assign({}, state, {
        createProfileStatus: NetworkStatus.DONE,
        createProfileError: undefined,
        data: action.payload,
      });
    case ProfileV2ActionTypes.PROFILE_V2_CREATION_FAILED:
      return Object.assign({}, state, {
        createProfileStatus: NetworkStatus.ERROR,
        createProfileError: action.payload,
      });
    case ProfileV2ActionTypes.PROFILE_V2_CREATION_RESET:
      return Object.assign({}, state, {
        createProfileStatus: NetworkStatus.NONE,
        createProfileError: undefined,
      });
    case ProfileV2ActionTypes.PROFILE_V2_UPDATE_STARTED:
      return Object.assign({}, state, {
        updateProfileStatus: NetworkStatus.STARTED,
        updateProfileError: undefined,
      });
    case ProfileV2ActionTypes.PROFILE_V2_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updateProfileStatus: NetworkStatus.DONE,
        updateProfileError: undefined,
        data: action.payload,
      });
    case ProfileV2ActionTypes.PROFILE_V2_UPDATE_FAILED:
      return Object.assign({}, state, {
        updateProfileStatus: NetworkStatus.ERROR,
        updateProfileError: action.payload,
      });
    case ProfileV2ActionTypes.PROFILE_V2_UPDATE_RESET:
      return Object.assign({}, state, {
        updateProfileStatus: NetworkStatus.NONE,
        updateProfileError: undefined,
      });
    case ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_STARTED:
      return Object.assign({}, state, {
        data: undefined,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_FAILED:
      return Object.assign({}, state, {
        data: undefined,
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    case ProfileV2ActionTypes.PROFILE_V2_RESET:
      return Object.assign({}, state, {
        data: undefined,
        status: NetworkStatus.NONE,
        error: undefined,
        applications: [],
        applicationsStatus: NetworkStatus.NONE,
        applicationsError: undefined,
      });
    case ProfileV2ActionTypes.PROFILE_V2_LOAD_APPLICATIONS_STARTED:
      return Object.assign({}, state, {
        applicationsStatus: NetworkStatus.STARTED,
        applicationsError: undefined,
      });
    case ProfileV2ActionTypes.PROFILE_V2_LOAD_APPLICATIONS_SUCCESS:
      return Object.assign({}, state, {
        applicationsStatus: NetworkStatus.DONE,
        applicationsError: undefined,
        applications: action.payload,
      });
    case ProfileV2ActionTypes.PROFILE_V2_LOAD_APPLICATIONS_FAILED:
      return Object.assign({}, state, {
        applicationsStatus: NetworkStatus.ERROR,
        applicationsError: action.payload,
      });

    default:
      return state;
  }
}
