import { NetworkStatus } from '../../common/constants';
import {
  FLEETS_LOAD_ALL_FAILED,
  FLEETS_LOAD_ALL_STARTED,
  FLEETS_LOAD_ALL_SUCCESS,
} from './fleets.actionTypes';

export default function fleets(
  state = {
    status: NetworkStatus.NONE,
    error: undefined,
    data: [],
  },
  action
) {
  switch (action.type) {
    case FLEETS_LOAD_ALL_STARTED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case FLEETS_LOAD_ALL_FAILED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case FLEETS_LOAD_ALL_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });

    default:
      return state;
  }
}
