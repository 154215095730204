export const CHECK_DEVICES_UPDATES_INTERVAL = 3000;

export const DeviceStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  UNENROLLED: 'UNENROLLED',
};

export const DeviceType = {
  IOT: 'IOT',
  MOBILE: 'MOBILE',
};
