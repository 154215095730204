import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import ButtonWithIcon from '../ButtonWithIcon';

const DropDownMenu = ({
  customTrigger: CustomTrigger,
  triggerButtonText,
  items = [],
  triggerProps = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  function handleClickOutside(event) {
    if (container.current && !container.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  const handleTriggerClick = () => setIsOpen((prevState) => !prevState);

  const trigger = CustomTrigger ? (
    <CustomTrigger onClick={handleTriggerClick} />
  ) : (
    <ButtonWithIcon
      {...triggerProps}
      text={triggerButtonText}
      className="drop-down-menu__trigger"
      onClick={handleTriggerClick}
    />
  );

  const handleListClick = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <div className="drop-down-menu" ref={container}>
      {trigger}
      {isOpen ? (
        <div
          onClick={handleListClick}
          className="drop-down-menu__dropdown-list"
        >
          {items.map(
            ({ onClick, children, customMenuItem, id, hidden }) =>
              customMenuItem ??
              (hidden ? null : (
                <div
                  key={id ?? uuid()}
                  onClick={onClick}
                  className="drop-down-menu__list-item"
                >
                  {children}
                </div>
              ))
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DropDownMenu;
