import { ACCESS_TYPES } from '../../constants/accessTypes';
import { convertPermissionsListToObjWithAccessTypes } from '../../common/utilities/users';

export const deserializeGlobalPermissionsForUser = ({
  global_permissions_read_list = [],
  global_permissions_read_write_list = [],
}) => ({
  ...convertPermissionsListToObjWithAccessTypes(
    global_permissions_read_list,
    ACCESS_TYPES.READ
  ),
  ...convertPermissionsListToObjWithAccessTypes(
    global_permissions_read_write_list,
    ACCESS_TYPES.READ_WRITE
  ),
});
