import { NetworkStatus } from '../../common/constants';
import {
  MAP_VIEW_LOAD_DEVICES_FAILED,
  MAP_VIEW_LOAD_DEVICES_STARTED,
  MAP_VIEW_LOAD_DEVICES_SUCCESS,
  MAP_VIEW_RECEIVED_DEVICE_UPDATES,
} from './mapView.actions';

export default function mapView(
  state = {
    status: NetworkStatus.NONE,
    error: undefined,
    data: [],
  },
  action
) {
  switch (action.type) {
    case MAP_VIEW_LOAD_DEVICES_STARTED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case MAP_VIEW_LOAD_DEVICES_FAILED:
      return Object.assign({}, state, {
        data: [],
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case MAP_VIEW_LOAD_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    case MAP_VIEW_RECEIVED_DEVICE_UPDATES: {
      const data = [...state.data];
      const foundIndex = data.findIndex(({ id }) => id === action.payload.id);
      if (foundIndex) {
        data.splice(foundIndex, 1, action.payload);
      } else {
        data.push(action.payload);
      }
      return Object.assign({}, state, {
        ...state,
        data,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    }

    default:
      return state;
  }
}
