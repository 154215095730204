import React, { Fragment } from 'react';
import { selectForCurrentBrand } from '../common/helpers';
import {
  INCUBE_HELP_FILE_LINK,
  JANAM_HELP_FILE_LINK,
  SPRINGDEL_HELP_FILE_LINK,
} from '../common/brandSpecificConstants';

class Help extends React.Component {
  renderContent = () => {
    const src = selectForCurrentBrand({
      original: SPRINGDEL_HELP_FILE_LINK,
      incube: INCUBE_HELP_FILE_LINK,
      janam: JANAM_HELP_FILE_LINK,
    });
    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          whiteSpace: 'normal',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
            overflowY: 'auto',
            border: 'none',
          }}
          dangerouslySetInnerHTML={{
            __html: `
                                <iframe 
                                    src="${src}"
                                    width="100%" 
                                    height="100%"
                                    style="border: none;"
                                >
                                </iframe>
                            `,
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <div className={'app__content'}>
          <div className={'page-content'}>
            <div className={'card'}>{this.renderContent()}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Help;
