import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AlertTypeTypes } from '../../alerts/alerts.constants';
import {
  batteryConditionComparisonOperatorOptions,
  genericOnOffConditionOptions,
} from '../../createAlert/createAlert.constants';
import AlertCondition from '../../../components/alertCreationAndEditing/alertCondition.component';

const AlertConditionTab = ({
  isEditingMode = false,
  editingModeData,
  handlePropertyChangeInEditingMode,
  data: {
    alertType,
    alertCondition: { conditionType, conditionValues = {} } = {},
  } = {},
}) => {
  const renderContent = () => (
    <AlertCondition
      alert={editingModeData}
      onPropertyChange={handlePropertyChangeInEditingMode}
    />
  );

  const getReadOnlyValues = () => {
    switch (alertType) {
      case AlertTypeTypes.BATTERY:
        const operator =
          batteryConditionComparisonOperatorOptions[conditionType].title;
        return [
          {
            value: `${operator} ${conditionValues.percentage}%`,
            title: I18n.t('alerts.alertWhenDeviceBatteryIs'),
          },
        ];
      case AlertTypeTypes.OUT_OF_CONTACT:
        return [
          {
            value: genericOnOffConditionOptions[conditionType].title,
            title: I18n.t('alerts.alertWhenDeviceIsOutOfContact'),
          },
        ];
      case AlertTypeTypes.EXIT_GEOFENCE:
        return [
          {
            value: genericOnOffConditionOptions[conditionType].title,
            title: I18n.t('alerts.alertWhenDeviceExitsGeofence'),
          },
        ];
      case AlertTypeTypes.ENTER_GEOFENCE:
        return [
          {
            value: genericOnOffConditionOptions[conditionType].title,
            title: I18n.t('alerts.alertWhenDeviceEntersGeofence'),
          },
        ];
      default:
        return [];
    }
  };

  const renderReadOnlyContent = () => (
    <ReadOnlyForm
      key="alert-condition-readonly-form"
      items={getReadOnlyValues()}
      headerTitle={
        <Translate
          className={'section-description__title--small'}
          value={'alerts.alertCondition'}
        />
      }
    />
  );

  return (
    <section>
      {isEditingMode ? renderContent() : renderReadOnlyContent()}
    </section>
  );
};

export default AlertConditionTab;
