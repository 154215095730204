import * as SparkMD5 from 'spark-md5';

const computeChecksumMd5 = (file, returnBase64) => {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0; // current cursor in file

    fileReader.onerror = function () {
      reject('MD5 computation failed - error reading the file');
    };

    // read chunk starting at `cursor` into memory
    function processChunk(chunk_start) {
      const chunk_end = Math.min(file.size, chunk_start + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
    }

    // when it's available in memory, process it
    fileReader.onload = function (e) {
      spark.append(e.target.result); // Accumulate chunk to md5 computation
      cursor += chunkSize; // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor);
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        if (returnBase64) {
          resolve(btoa(spark.end(true)));
        } else {
          // hex digest form (looking like
          // '7cf530335b8547945f1a48880bc421b2')
          resolve(spark.end());
        }
      }
    };

    processChunk(0);
  });
};

export default computeChecksumMd5;
