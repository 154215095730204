export const COLUMNS_TOGGLE_INITIALIZE_ALL_AVAILABLE_COLUMNS =
  'COLUMNS_TOGGLE_INITIALIZE_ALL_AVAILABLE_COLUMNS';

export const COLUMNS_TOGGLE_TOGGLE_COLUMN = 'COLUMNS_TOGGLE_TOGGLE_COLUMN';
export const COLUMNS_TOGGLE_REORDER_COLUMNS = 'COLUMNS_TOGGLE_REORDER_COLUMNS';

export const SAVE_TABLE_COLUMNS_STARTED = 'SAVE_TABLE_COLUMNS_STARTED';
export const SAVE_TABLE_COLUMNS_FAILED = 'SAVE_TABLE_COLUMNS_FAILED';
export const SAVE_TABLE_COLUMNS_SUCCESS = 'SAVE_TABLE_COLUMNS_SUCCESS';

export const GET_SAVED_TABLE_COLUMNS_STARTED =
  'GET_SAVED_TABLE_COLUMNS_STARTED';
export const GET_SAVED_TABLE_COLUMNS_FAILED = 'GET_SAVED_TABLE_COLUMNS_FAILED';
export const GET_SAVED_TABLE_COLUMNS_SUCCESS =
  'GET_SAVED_TABLE_COLUMNS_SUCCESS';
