export const complianceTypes = {
  COMPLIANT_AND_LATEST_VERSION: 'compliant_and_latest_version',
  COMPLIANT_AND_NOT_LATEST_VERSION: 'compliant_and_not_latest_version',
  NOT_COMPLIANT_VERSION: 'not_compliant_version',
};

export const complianceTitleCodes = {
  [complianceTypes.COMPLIANT_AND_LATEST_VERSION]:
    'devices.fields.compliantAndLatestVersion',
  [complianceTypes.COMPLIANT_AND_NOT_LATEST_VERSION]:
    'devices.fields.compliantAndNotLatestVersion',
  [complianceTypes.NOT_COMPLIANT_VERSION]: 'devices.fields.notCompliantVersion',
};
