import {
  DEVICES_ASSIGN_FLEET_FAILED,
  DEVICES_ASSIGN_FLEET_RESET,
  DEVICES_ASSIGN_FLEET_STARTED,
  DEVICES_ASSIGN_FLEET_SUCCESS,
  DEVICES_RESET,
  FLEET_CREATE_FAILED,
  FLEET_CREATE_RESET,
  FLEET_CREATE_STARTED,
  FLEET_CREATE_SUCCESS,
  FLEET_DELETE_FAILED,
  FLEET_DELETE_RESET,
  FLEET_DELETE_STARTED,
  FLEET_DELETE_SUCCESS,
  FLEET_LOAD_FAILED,
  FLEET_LOAD_RESET,
  FLEET_LOAD_STARTED,
  FLEET_LOAD_SUCCESS,
  FLEET_PROFILES_V1_LOAD_FAILED,
  FLEET_PROFILES_V1_LOAD_RESET,
  FLEET_PROFILES_V1_LOAD_STARTED,
  FLEET_PROFILES_V1_LOAD_SUCCESS,
  FLEET_PROFILES_V2_LOAD_FAILED,
  FLEET_PROFILES_V2_LOAD_STARTED,
  FLEET_PROFILES_V2_LOAD_SUCCESS,
  FLEET_PROFILES_V2_RESET,
  FLEETS_LOAD_ALL_FAILED,
  FLEETS_LOAD_ALL_STARTED,
  FLEETS_LOAD_ALL_SUCCESS,
} from './fleets.actionTypes';

export function allFleetsLoadingStarted() {
  return {
    type: FLEETS_LOAD_ALL_STARTED,
  };
}

export function allFleetsLoadingFailed(payload) {
  return {
    type: FLEETS_LOAD_ALL_FAILED,
    payload,
  };
}

export function allFleetsLoaded(payload) {
  return {
    type: FLEETS_LOAD_ALL_SUCCESS,
    payload,
  };
}

export function fleetDeletingStarted() {
  return {
    type: FLEET_DELETE_STARTED,
  };
}

export function fleetDeletingFailed(payload) {
  return {
    type: FLEET_DELETE_FAILED,
    payload,
  };
}

export function fleetDeletingSuccess() {
  return {
    type: FLEET_DELETE_SUCCESS,
  };
}

export function fleetDeletingReset() {
  return {
    type: FLEET_DELETE_RESET,
  };
}

export function fleetLoadingStarted(payload) {
  return {
    type: FLEET_LOAD_STARTED,
    payload,
  };
}

export function fleetLoadingFailed() {
  return {
    type: FLEET_LOAD_FAILED,
  };
}

export function fleetLoadingDone(payload) {
  return {
    type: FLEET_LOAD_SUCCESS,
    payload,
  };
}

export function fleetLoadingReset() {
  return {
    type: FLEET_LOAD_RESET,
  };
}

export function assignDevicesToFleetStarted() {
  return {
    type: DEVICES_ASSIGN_FLEET_STARTED,
  };
}

export function assignDevicesToFleetFailed(payload) {
  return {
    type: DEVICES_ASSIGN_FLEET_FAILED,
    payload,
  };
}

export function assignDevicesToFleetDone(payload) {
  return {
    type: DEVICES_ASSIGN_FLEET_SUCCESS,
    payload,
  };
}

export function assignDevicesToFleetReset() {
  return {
    type: DEVICES_ASSIGN_FLEET_RESET,
  };
}

export function createFleetStarted() {
  return {
    type: FLEET_CREATE_STARTED,
  };
}

export function createFleetFailed(payload) {
  return {
    type: FLEET_CREATE_FAILED,
    payload,
  };
}

export function createFleetSuccess() {
  return {
    type: FLEET_CREATE_SUCCESS,
  };
}

export function createFleetReset() {
  return {
    type: FLEET_CREATE_RESET,
  };
}

export function fleetProfilesV1LoadStarted() {
  return {
    type: FLEET_PROFILES_V1_LOAD_STARTED,
  };
}

export function fleetProfilesV1LoadFailed(payload) {
  return {
    type: FLEET_PROFILES_V1_LOAD_FAILED,
    payload,
  };
}

export function fleetProfilesV1LoadSuccess(payload) {
  return {
    type: FLEET_PROFILES_V1_LOAD_SUCCESS,
    payload,
  };
}

export function fleetProfilesV1Reset() {
  return {
    type: FLEET_PROFILES_V1_LOAD_RESET,
  };
}

export function fleetProfilesV2LoadStarted() {
  return {
    type: FLEET_PROFILES_V2_LOAD_STARTED,
  };
}

export function fleetProfilesV2LoadFailed(payload) {
  return {
    type: FLEET_PROFILES_V2_LOAD_FAILED,
    payload,
  };
}

export function fleetProfilesV2LoadSuccess(payload) {
  return {
    type: FLEET_PROFILES_V2_LOAD_SUCCESS,
    payload,
  };
}

export function fleetProfilesReset() {
  return {
    type: FLEET_PROFILES_V2_RESET,
  };
}

export function devicesReset() {
  return {
    type: DEVICES_RESET,
  };
}
