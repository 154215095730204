import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import ttiPolyfill from 'tti-polyfill';
import MainNavBar from './components/MainNavBar';
import NotFound from './components/NotFound';
import Search from './components/Search';
import TopNavBar from './components/TopNavBar';
import {
  subscribeChanges,
  unsubscribeChanges,
} from './features/devices/devices.sockets';
import { logout } from './features/login/login.actions';
import {
  authenticationSelector,
  companyIdSelector,
  logoutStatusSelector,
} from './features/login/login.selectors';
import UserHelpers from './common/utilities/user';
import Applications, { applicationsRoutes } from './routes/_Applications';
import Help from './routes/_Help';
import Profiles from './features/profiles/profiles.routes';
import Settings from './routes/_Settings';
import Devices from './routes/Devices';
import Home from './routes/Home';
import Login from './features/login/Login';
import Users from './routes/Users';
import {
  HomeRoutes,
  PublicRoutes,
  RootRoutes,
  SSOResponseRoutes,
  SystemRoutes,
} from './utils/routes';
// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter';
import wsServices from './utils/websocketsServices';
import { loadTimezones } from './features/settings/settings.thunk';
import { globalPermissionsForUserSelector } from './selectors/accountSelectors';
import Popup from './features/popup/popup.component';
import { loadNotifications } from './redux/notifications.thunk';
import { getSavedTableColumns } from './redux/columnsToggle.thunk';
import { INFINITE_SCROLL_LIMIT } from './constants/infiniteScroll';
import {
  loadAccountInfo,
  loadGlobalPermissionsForUser,
} from './features/account/account.thunk';
import { loadAllFleets } from './features/fleets/fleets.thunk';
import { loadProfiles } from './features/profiles/profiles.thunk';
import { loadEnrollments } from './features/enrollments/enrollments.thunk';
import { getAccessFromGlobalPermissionsForUser } from './common/utilities/users';
import AlertManager from './routes/Alerts';
import APIManager from './routes/API';
import SessionTimeout from './components/SessionTimeout/sessionTimeout.component';
import { isLoadingStatusStarted } from './common/helpers';
import { Throbber } from './components/Throbber';
import { systemMessageSelector } from './features/systemMessage/systemMessage.selector';
import Banner from './features/Banner/banner.component';
import { loadSystemMessage } from './features/systemMessage/systemMessage.thunk';
import { loadAllFleetsV2 } from './features/fleets/fleetsV2.thunk';
import { loadProfilesV2 } from './features/profilesV2/profilesV2.thunk';
import { bannerSelector } from './features/Banner/banner.selector';
import StyleUtils from './utils/styleUtils';
import AnalyticsService from './services/AnalyticsService';
import { history } from './utils/history';
import { sanitizeUrlForAnalytics } from './common/utilities/analytics';
import { loadPreferences } from './features/preferences/preferences.thunk';

ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
  AnalyticsService.timing({
    category: 'Load Performance',
    variable: 'Time to Interactive',
    value: tti,
  });
});

const callback = (list) => {
  list.getEntries().forEach((entry) => {
    console.log(entry);
    AnalyticsService.timing({
      category: 'First Meaningful Paint',
      variable: entry.name,
      value: entry.responseEnd,
    });
  });
};

const observer = new PerformanceObserver(callback);
observer.observe({
  entryTypes: [
    'navigation',
    'paint',
    // 'resource',
    // 'mark',
    // 'measure',
    // 'frame',
    // 'longtask'
  ],
});

export class App extends Component {
  state = {
    showSidebar: true,
    showSearchScreen: false,
  };

  componentDidMount() {
    const { companyId, isAuthenticated } = this.props;
    if (isAuthenticated && companyId) {
      this.initializeApp();
    }
    // Initialize google analytics page view tracking
    this.initializeAnalytics();

    // define listener for route changes
    history.listen((location) => {
      this.initializeAnalytics();
      const path = sanitizeUrlForAnalytics(location.pathname);
      // console.log('location', path, location);
      AnalyticsService.logPageView(path);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isAuthenticated, companyId } = this.props;
    if (
      isAuthenticated &&
      companyId &&
      (!prevProps.isAuthenticated || !prevProps.companyId)
    ) {
      this.initializeApp();
    }
  }

  componentWillUnmount() {
    if (this.props.companyId) {
      // this.props.unsubscribeNotifications(this.props.companyId);
    }
  }

  initializeAnalytics = () => {
    if (!window.GA_INITIALIZED) {
      AnalyticsService.initialize();
      window.GA_INITIALIZED = true;
    }
  };

  initializeApp = () => {
    const {
      loadNotifications,
      loadAllFleets,
      loadAllFleetsV2,
      loadProfiles,
      // loadProfilesV2,
      loadTimezones,
      loadGlobalPermissionsForUser,
      getSavedTableColumns,
      // subscribeNotifications,
      loadEnrollments,
      companyId,
      loadSystemMessage,
      loadPreferences,
    } = this.props;
    loadSystemMessage();

    // subscribeNotifications(companyId);
    loadTimezones();
    loadPreferences();
    // loadNotifications({
    //   isInitialLoad: true,
    //   limit: INFINITE_SCROLL_LIMIT,
    // });
    getSavedTableColumns();
    loadGlobalPermissionsForUser();
    // loadAccountInfo();
    // loadAllFleets(companyId);
    // loadAllFleetsV2();
    // loadProfiles();
    // loadProfilesV2({
    //   loadFullList: true,
    //   isInitialLoad: true,
    // });
    // loadEnrollments({
    //   companyId,
    //   offset: 0,
    //   limit: INFINITE_SCROLL_LIMIT,
    //   isInitialLoad: true,
    // });
  };

  render() {
    const {
      isAuthenticated,
      hasAdminPrivileges,
      location: { pathname },
      globalPermissionsForUser = {},
      logoutStatus,
      bannerData,
    } = this.props;
    const { showSidebar } = this.state;

    if (isLoadingStatusStarted(logoutStatus)) {
      return <Throbber />;
    }

    if (!isAuthenticated && !PublicRoutes.has(pathname)) {
      return this.authenticationRedirect();
    }

    const isSidebarOpen = showSidebar && !SystemRoutes.has(pathname);

    const isApplicationsRouteAccessible =
      hasAdminPrivileges ||
      applicationsRoutes.some(({ permissionKey, accessType }) =>
        getAccessFromGlobalPermissionsForUser(
          globalPermissionsForUser,
          permissionKey,
          accessType
        )
      );

    return (
      <main className="app">
        <div id="system-message-container" />
        <section
          className={StyleUtils.mergeModifiers(
            'app__content-outer-container',
            bannerData?.isVisible && 'with-banner'
          )}
        >
          {isAuthenticated ? (
            <>
              <SessionTimeout
                isAuthenticated={isAuthenticated}
                logout={this.props.logout}
              />
              <MainNavBar
                docked={this.state.docked}
                open={isSidebarOpen}
                isApplicationsMenuItemAccessible={isApplicationsRouteAccessible}
                hasAdminPrivileges={hasAdminPrivileges}
              />
            </>
          ) : null}

          <section
            className={StyleUtils.mergeModifiers(
              'app__content-inner-container',
              !isAuthenticated && 'with-full-width'
            )}
          >
            {isAuthenticated ? (
              <>
                <Route
                  path={'/:parentRoute/:route?'}
                  component={this.renderTopNavBar}
                />
              </>
            ) : null}
            <Switch>
              <Route exact path={RootRoutes.LOGIN} component={Login} />
              <Redirect exact from={RootRoutes.ROOT} to={RootRoutes.HOME} />
              {this.renderSSORedirects()}
              <Route
                path={RootRoutes.HOME}
                render={(props) => (
                  <Home {...props} hasAdminPrivileges={hasAdminPrivileges} />
                )}
              />
              <Route path={RootRoutes.DEVICES} component={Devices} />
              <Route path={RootRoutes.PROFILES} component={Profiles} />
              {isApplicationsRouteAccessible ? (
                <Route
                  path={RootRoutes.FILE_SYSTEM}
                  render={(props) => (
                    <Applications
                      {...props}
                      hasAdminPrivileges={hasAdminPrivileges}
                      globalPermissionsForUser={globalPermissionsForUser}
                    />
                  )}
                />
              ) : null}
              <Route
                path={RootRoutes.SETTINGS}
                render={(props) => (
                  <Settings
                    {...props}
                    hasAdminPrivileges={hasAdminPrivileges}
                  />
                )}
              />
              <Route path={RootRoutes.HELP} component={Help} />
              <Route
                path={RootRoutes.USERS}
                render={(props) => (
                  <Users {...props} hasAdminPrivileges={hasAdminPrivileges} />
                )}
              />
              <Route
                path={RootRoutes.ALERTS}
                render={(props) => (
                  <AlertManager
                    {...props}
                    hasAdminPrivileges={hasAdminPrivileges}
                  />
                )}
              />
              <Route
                path={RootRoutes.API}
                render={(props) => (
                  <APIManager
                    {...props}
                    hasAdminPrivileges={hasAdminPrivileges}
                  />
                )}
              />
              {this.renderDevelopmentOnlyRoutes()}
              <Route component={NotFound} />
            </Switch>
            {this.renderSearchScreen()}
          </section>
        </section>
        <Popup />
        <Banner />
      </main>
    );
  }

  renderDevelopmentOnlyRoutes = () => {
    if (process.env.NODE_ENV === 'development') {
      return [];
    }
  };

  renderSSORedirects = () =>
    Object.values(SSOResponseRoutes).map((route) => (
      <Redirect
        key={route}
        from={route}
        to={{
          pathname: this.props.isAuthenticated
            ? `${RootRoutes.HOME}${HomeRoutes.DASHBOARD}`
            : RootRoutes.LOGIN,
          state: {
            requestUrl: window.location.href,
            isSSORedirect: true,
            from: { pathname: '/' },
          },
        }}
      />
    ));

  authenticationRedirect = () => {
    const direction = {
      pathname: RootRoutes.LOGIN,
      state: {
        from: this.props.location,
      },
      hash: '',
    };

    return <Redirect to={direction} />;
  };

  renderTopNavBar = ({ ...props }) => {
    const {
      globalPermissionsForUser,
      hasAdminPrivileges,
      location: { pathname } = {},
    } = this.props;
    if (!SystemRoutes.has(pathname)) {
      return (
        <TopNavBar
          {...props}
          hasAdminPrivileges={hasAdminPrivileges}
          globalPermissionsForUser={globalPermissionsForUser}
          toggleSidebar={this.toggleSidebar}
        />
      );
    } else {
      return <div />;
    }
  };

  renderSearchScreen = () => {
    if (this.props.location.hash && this.props.location.hash === '#search') {
      return <Search />;
    }
  };

  toggleSidebar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showSidebar: !this.state.showSidebar });
  };
}

const mapStateToProps = (state) => {
  const { data: systemMessage } = systemMessageSelector(state);
  const globalPermissionsForUser = globalPermissionsForUserSelector(state);
  const hasAdminPrivileges = UserHelpers.hasAdminPrivileges(state);
  const isUserManagementEnabled = hasAdminPrivileges;

  return {
    systemMessage,
    bannerData: bannerSelector(state),
    isAuthenticated: authenticationSelector(state),
    companyId: companyIdSelector(state),
    logoutStatus: logoutStatusSelector(state),
    globalPermissionsForUser,
    hasAdminPrivileges,
    isUserManagementEnabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSystemMessage: () => dispatch(loadSystemMessage()),
    logout: (params) => dispatch(logout(params)),
    loadTimezones: () => dispatch(loadTimezones()),
    getSavedTableColumns: () => dispatch(getSavedTableColumns()),
    loadNotifications: (params) => dispatch(loadNotifications(params)),
    loadGlobalPermissionsForUser: () =>
      dispatch(loadGlobalPermissionsForUser()),
    loadAccountInfo: () => dispatch(loadAccountInfo()),
    loadAllFleets: (companyId) => dispatch(loadAllFleets(companyId)),
    loadAllFleetsV2: () => dispatch(loadAllFleetsV2()),
    loadProfiles: () => dispatch(loadProfiles()),
    loadProfilesV2: (params) => dispatch(loadProfilesV2(params)),
    loadEnrollments: (params) => dispatch(loadEnrollments(params)),
    subscribeNotifications: (companyId) =>
      dispatch(
        subscribeChanges(
          `${wsServices.subscribeGeofencingNotifications}${companyId}/`,
          'fencing.update'
        )
      ),
    unsubscribeNotifications: (companyId) =>
      dispatch(
        unsubscribeChanges(
          `${wsServices.subscribeGeofencingNotifications}${companyId}/`
        )
      ),
    loadPreferences: () => dispatch(loadPreferences()),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
