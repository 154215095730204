import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { v4 as uuid } from 'uuid';
import PageContainer from '../../components/PageContainer/PageContainer.component';
import Card from '../../components/Card/Card.component';
import SingleCard from '../../components/Card/SingleCard.component';
import { SectionDescription } from '../../components/sectionDescription.component';
import ReadOnlyForm from '../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../common/constants';
import { ReactComponent as CopyIcon } from '../../assets/images/copy.svg';
import PopupActions from '../popup/popup.actions';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { ReactComponent as FileExportCSV } from '../../assets/images/file_export_csv.svg';
import { I18n } from 'react-redux-i18n';

const APIOverview = () => {
  const apiKey = uuid();

  const onCopyAPIKey = () =>
    PopupActions.showAlert({
      text: I18n.t('api.apiKeyCopiedSuccessMessageText'),
    });

  const apiKeyCopyToClipboard = (
    <CopyToClipboard text={apiKey} onCopy={onCopyAPIKey}>
      <CopyIcon className="icon icon--fixed-square" />
    </CopyToClipboard>
  );

  return (
    <PageContainer>
      <Card withPadding>
        <SingleCard>
          <SectionDescription title="Overview" />
          <ReadOnlyForm
            items={[
              {
                type: AttributeType.TEXT,
                title: I18n.t('api.currentPlanTitle'),
                value: 'Starter',
              },
              {
                type: AttributeType.TEXT,
                title: I18n.t('api.currentPlanDetails'),
                value: I18n.t('api.starterPlanDetails', {
                  numberOfAPICalls: 1000,
                }),
              },
              {
                type: AttributeType.TEXT,
                title: I18n.t('api.documentationTitle'),
                value: (
                  <a href="#" target="_blank">
                    {I18n.t('api.documentationHint')}
                  </a>
                ),
              },
              {
                type: AttributeType.TEXT,
                title: I18n.t('api.APIKeyTitle'),
                value: (
                  <div>
                    {apiKey} {apiKeyCopyToClipboard}
                  </div>
                ),
              },
              {
                type: AttributeType.TEXT,
                title: I18n.t('api.uniqueIDsTitle'),
                value: (
                  <ButtonWithIcon
                    rightIcon={<FileExportCSV className="icon" />}
                    text={I18n.t('api.uniqueIDsDownloadButtonText')}
                    secondary
                  />
                ),
              },
              {
                type: AttributeType.SUBHEADING,
                title: I18n.t('api.usageQuotaTitle'),
                isSmall: true,
              },
              {
                type: AttributeType.TEXT,
                title: I18n.t('api.usageConsumedColumnTitle'),
                value: '600 requests',
              },
              {
                type: AttributeType.TEXT,
                title: I18n.t('api.usageRemainderColumnTitle'),
                value: '400 requests',
              },
            ]}
          />
        </SingleCard>
      </Card>
    </PageContainer>
  );
};

export default APIOverview;
