export const policyType = {
  FEATURE_CONTROL: 'feature_control',
  WIFI: 'wifi',
  WEB_FILTER: 'webFilter',
  APP: 'app', // app management
  APP_LIST: 'applist', // blacklist
  CERTIFICATE: 'certificate',
  LOCATION: 'location',
  KIOSK: 'kiosk',
  HTML_KIOSK: 'html_kiosk',
  FILE_SYNC: 'file_sync',
  GEOFENCING: 'geofencing',
  PASSWORD: 'password',
  SECURITY: 'security',
  SETTINGS: 'settings',
  WALLPAPER_SETTINGS: 'wallpaperSettings',

  LOCATION_SERVICES: 'location_services',
  AUTHENTICATION: 'authentication',
  APN: 'apn',
};

export const policyTypeTitleCodes = {
  [policyType.FEATURE_CONTROL]: 'profiles.featureControl.title',
  [policyType.WIFI]: 'profiles.wifi.title',
  [policyType.WEB_FILTER]: 'profiles.webFilter.webFilter',
  [policyType.APP]: 'profiles.apps.policyTitle',
  [policyType.APP_LIST]: 'profiles.blacklist.policyTitle',
  [policyType.CERTIFICATE]: 'profiles.certificates.policyTitle',
  [policyType.LOCATION]: 'profiles.location.policyTitle',
  [policyType.KIOSK]: 'profiles.kiosk.title',
  [policyType.HTML_KIOSK]: 'profiles.kiosk.htmlKioskTitle',
  [policyType.FILE_SYNC]: 'profiles.fileSync.policyTitle',
  [policyType.GEOFENCING]: 'profiles.geofencing.policyTitle',
  [policyType.PASSWORD]: 'profiles.password.policyTitle',
  [policyType.SECURITY]: 'profiles.security.policyTitle',
  [policyType.SETTINGS]: 'profiles.settings.policyTitle',
  [policyType.WALLPAPER_SETTINGS]:
    'profiles.wallpaperSettings.wallpaperSettings',
  [policyType.LOCATION_SERVICES]: 'profiles.locationServices.policyTitle',
  [policyType.AUTHENTICATION]: 'profiles.authentication.policyTitle',
  [policyType.APN]: 'profiles.apn.policyTitle',
};
