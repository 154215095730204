export const ProfilesV2ActionTypes = {
  LOAD_PROFILES_BY_COMPANY_STARTED: 'LOAD_PROFILES_BY_COMPANY_STARTED',
  LOAD_PROFILES_BY_COMPANY_FAILED: 'LOAD_PROFILES_BY_COMPANY_FAILED',
  LOAD_PROFILES_BY_COMPANY_SUCCESS: 'LOAD_PROFILES_BY_COMPANY_SUCCESS',

  CLONE_PROFILES_V2_STARTED: 'CLONE_PROFILES_V2_STARTED',
  CLONE_PROFILES_V2_FAILED: 'CLONE_PROFILES_V2_FAILED',
  CLONE_PROFILES_V2_SUCCESS: 'CLONE_PROFILES_V2_SUCCESS',

  DELETE_PROFILE_V2_STARTED: 'DELETE_PROFILE_V2_STARTED',
  DELETE_PROFILE_V2_FAILED: 'DELETE_PROFILE_V2_FAILED',
  DELETE_PROFILE_V2_SUCCESS: 'DELETE_PROFILE_V2_SUCCESS',
};
