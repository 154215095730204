import React, { FC } from 'react';
import StyleUtils from '../../utils/styleUtils';

interface SingleCardProps {
  withFullHeight?: boolean;
}

const SingleCard: FC<SingleCardProps> = ({ children, withFullHeight }) => {
  const className = StyleUtils.mergeClasses(
    'single-card-content',
    StyleUtils.mergeModifiers('single-card', withFullHeight && 'full-height')
  );
  return <section className={className}>{children}</section>;
};

export default SingleCard;
