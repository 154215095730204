import { I18n } from 'react-redux-i18n';
import {
  passwordChangeStarted,
  passwordChangeSuccess,
  passwordChangeFailed,
} from './password.actions';
import client from '../../utils/client';
import services from '../../utils/services';

export function changePassword(currentPassword, newPassword) {
  return async (dispatch) => {
    try {
      dispatch(passwordChangeStarted());
      const passwords = {
        old_password: currentPassword,
        new_password: newPassword,
      };
      let { status } = await client.put(
        services.changeCustomerPassword,
        passwords
      );
      if (status === 200) {
        dispatch(passwordChangeSuccess());
      } else {
        dispatch(
          passwordChangeFailed(I18n.t('settings.passwordChangeFailureText'))
        );
      }
    } catch (error) {
      dispatch(
        passwordChangeFailed(I18n.t('settings.passwordChangeFailureText'))
      );
    }
  };
}
