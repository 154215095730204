import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import * as moment from 'moment-timezone';
import { Translate } from 'react-redux-i18n';
import InfiniteScroll from 'react-infinite-scroller';
import { NavLink } from 'react-router-dom';
import {
  AttributeType,
  DEFAULT_DATE_TIME_FORMAT,
} from '../../common/constants';
import {
  bytesToSize,
  coordinatesDDToDMS,
  filterItemsByAttributes,
  getAvailableFilters,
  isLoadingStatusDone,
  isLoadingStatusStarted,
  notEmpty,
  sortItemsByAttribute,
} from '../../common/helpers';
import { Throbber } from '../Throbber';
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle.svg';
import { ReactComponent as CrestCircle } from '../../assets/images/crest-circle.svg';
import RouteHelpers from '../../common/utilities/routeHelpers';
import StyleUtils from '../../utils/styleUtils';

import { ReactComponent as CaretDown } from '../../assets/images/caret_down.svg';
import { ReactComponent as CaretUp } from '../../assets/images/caret_up.svg';
import { ReactComponent as CaretUpDown } from '../../assets/images/caret_up_down.svg';
import { formatAttributiveDataFormat } from '../../common/utilities/attributiveDataFormat';

/**
 * @deprecated Use the DataTable component instead.
 */
const AttributiveTable = ({
  loadingStatus,
  items: data,
  columns,
  selectable,
  selected,
  onSelectAll,
  sortable,
  infiniteScrollProps,
  onOpenItem,
  onSelect,
  filters,
  isFilterCriteriaStrict,
  defaultSortColumn = null,
  defaultSortOrder = null,
}) => {
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const availableFilters = getAvailableFilters(filters);
  let items = filterItemsByAttributes(
    data,
    columns,
    isFilterCriteriaStrict,
    availableFilters
  );

  if (sortColumn) {
    items = sortItemsByAttribute({
      items,
      attributes: columns,
      sortedAttributeKey: sortColumn,
      order: sortOrder,
    });
  }

  const handleSelectAll = (e) => onSelectAll(e, items);

  const renderHeaderCheckbox = () => {
    if (selectable && items?.length) {
      const allPageSelected =
        selected?.size && items?.every((items) => selected.has(items.id));
      return (
        <th className="attributive-table__header-checkbox-container">
          <div className="attributive-table__header-checkbox-inner-container">
            {onSelectAll ? (
              <input
                type="checkbox"
                className="checkbox"
                style={{ margin: 'auto auto' }}
                checked={allPageSelected}
                onChange={handleSelectAll}
              />
            ) : null}
          </div>
        </th>
      );
    }
  };

  const renderCaret = (item) => {
    if (
      sortable &&
      (item.searchable || item.sortable) &&
      item.type !== AttributeType.COORDINATES
    ) {
      if (item.key === sortColumn && sortOrder === 'ASC') {
        return <CaretUp />;
      }
      if (item.key === sortColumn && sortOrder === 'DSC') {
        return <CaretDown />;
      }
      return <CaretUpDown />;
    }
  };

  const renderHeaderCell = (item) => {
    if (item.hidden) {
      return <th />;
    }
    const isSortable = sortable;
    const handleClick = () => {
      const nextKey = sortOrder === 'DSC' ? undefined : item.key;
      const nextOrder = !sortOrder
        ? 'ASC'
        : sortOrder === 'ASC'
        ? 'DSC'
        : undefined;
      setSortColumn(nextKey);
      setSortOrder(nextOrder);
    };
    return (
      <th key={item.key} className="attributive-table__header-cell-container">
        <div
          className={StyleUtils.mergeModifiers(
            'attributive-table__header-cell-inner-container',
            isSortable && 'sortable'
          )}
          onClick={isSortable ? handleClick : undefined}
        >
          <Translate value={item.titleCode} />
          {renderCaret(item)}
        </div>
      </th>
    );
  };

  const renderHeader = () => {
    if (isLoadingStatusDone(loadingStatus) && items?.length)
      return (
        <thead>
          <tr className="attributive-table__header-row">
            {renderHeaderCheckbox()}
            {columns.map(renderHeaderCell)}
          </tr>
        </thead>
      );
  };

  const renderRowCheckbox = (item) => {
    if (selectable) {
      return (
        <td>
          <input
            type="checkbox"
            className="checkbox"
            style={{ margin: 'auto auto' }}
            checked={selected.has(item.id)}
            onChange={(e) => onSelect(e, item)}
            disabled={!items?.length}
          />
        </td>
      );
    }
  };

  const renderItemCell = (item, column, index) => {
    const { hiddenCells = [] } = item;
    if (column.hidden || hiddenCells.includes(column.key)) {
      return <td key={column.key + index} />;
    }
    let content = '';

    if (item[column.key] !== null && item[column.key] !== undefined) {
      if (column.renderer) {
        content = <column.renderer item={item} field={column} />;
      } else {
        content = formatAttributiveDataFormat({
          attribute: column,
          values: item,
        });
        // switch (column.type) {
        //   case AttributeType.TEXT: {
        //     content = item[column.key];
        //     break;
        //   }
        //   case AttributeType.ENUMERABLE: {
        //     const { title = '' } =
        //       column.availableOptions?.find(
        //         ({ title, value }) => item[column.key] === value
        //       ) ?? {};
        //     content = title;
        //     break;
        //   }
        //   case AttributeType.BOOLEAN: {
        //     const isFalseIconHidden = column.isFalseIconHidden;
        //     content = item[column.key] ? (
        //       <CheckCircle className="icon--fixed-square" />
        //     ) : isFalseIconHidden ? null : (
        //       <CrestCircle className="icon--fixed-square" />
        //     );
        //     break;
        //   }
        //   case AttributeType.DATE: {
        //     const timezone = moment.tz?.guess?.();
        //     let momentValue = moment.utc(item[column.key]);
        //     if (momentValue.isValid()) {
        //       content = momentValue
        //         .tz(timezone)
        //         .format(DEFAULT_DATE_TIME_FORMAT)
        //         .toString();
        //     }
        //     break;
        //   }
        //   case AttributeType.COORDINATES: {
        //     content = coordinatesDDToDMS(
        //       item[column.key].latitude,
        //       item[column.key].longitude
        //     );
        //     break;
        //   }
        //   case AttributeType.PERCENTAGE: {
        //     content = `${item[column.key]} %`;
        //     break;
        //   }
        //   case AttributeType.SIZE: {
        //     content = bytesToSize(item[column.key]);
        //     break;
        //   }
        //   default:
        //     break;
        // }
      }
    }

    const hasValidRef = onOpenItem && column.ref;
    const onClick = hasValidRef ? () => onOpenItem(item) : undefined;
    if (column.ref) {
      const dynamicRefParameters =
        column.dynamicRefParams?.reduce(
          (accumulatedParams, currentParam) => ({
            ...accumulatedParams,
            [currentParam.key]: item[currentParam.paramValueKey],
          }),
          {}
        ) ?? {};
      const defaultPathWithoutParams = `${column.ref}${
        item[column.refId] ? '/' + item[column.refId] : ''
      }`;
      const defaultPath =
        notEmpty(column.additionalRefParams) || notEmpty(dynamicRefParameters)
          ? RouteHelpers.formatUrlWithQuery(defaultPathWithoutParams, {
              ...(column.additionalRefParams ?? {}),
              ...dynamicRefParameters,
            })
          : defaultPathWithoutParams;
      const path = column.useQueryParam
        ? RouteHelpers.formatUrlWithQuery(column.ref, {
            ...(column.additionalRefParams ?? {}),
            ...dynamicRefParameters,
            [column.refId]: item[column.refId],
          })
        : defaultPath;
      return (
        <td key={column.key + index} onClick={onClick}>
          <NavLink to={path}>{content}</NavLink>
        </td>
      );
    }
    return (
      <td key={column.key + index} onClick={onClick}>
        {content}
      </td>
    );
  };

  const renderRow = (item, index) => {
    return (
      <tr key={(item.id || 'row_') + index}>
        {renderRowCheckbox(item)}
        {columns.map((column, index) => renderItemCell(item, column, index))}
      </tr>
    );
  };

  const renderTable = () => {
    const hasSomeAlreadyLoadedItems = Boolean(items.length);
    if (isLoadingStatusDone(loadingStatus) || hasSomeAlreadyLoadedItems) {
      if (items?.length) {
        const table = (
          <Table className="attributive-table__inner-container">
            {renderHeader()}
            <tbody>{items.map(renderRow)}</tbody>
          </Table>
        );
        if (infiniteScrollProps) {
          const { loadMore, hasMore } = infiniteScrollProps;
          return (
            <div className="attributive-table__container">
              <InfiniteScroll
                loadMore={loadMore}
                // loading status check here because of request duplication.
                // the same request was always sent twice
                hasMore={hasMore && !isLoadingStatusStarted(loadingStatus)}
                pageStart={0}
                loader={
                  <div
                    key={`infinite-scroll-loader`}
                    className="attributive-table__infinite-scroll-loader-container"
                  >
                    <Throbber />
                  </div>
                }
                useWindow={false}
                initialLoad={false}
              >
                {table}
              </InfiniteScroll>
            </div>
          );
        }
        return <div className="attributive-table__container">{table}</div>;
      }
      const filtered = !!availableFilters;
      const emptyResultTitleCode = filtered
        ? 'attributiveTable.notFound'
        : 'attributiveTable.noData';
      return (
        <div className="attributive-table__empty-message-container">
          <Translate
            value={emptyResultTitleCode}
            className="attributive-table__empty-message-text"
          />
        </div>
      );
    }
    return null;
  };

  if (isLoadingStatusStarted(loadingStatus)) {
    return <Throbber />;
  }

  return <div className="attributive-table">{renderTable()}</div>;
};

AttributiveTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  loadingStatus: PropTypes.string,
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onOpenItem: PropTypes.func,
  sortable: PropTypes.bool,
};

export default AttributiveTable;
