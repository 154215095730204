import {
  STATISTICS_LOAD_FAILED,
  STATISTICS_LOAD_STARTED,
  STATISTICS_LOAD_SUCCESS,
} from './statistics.actionTypes';
import { loadStatisticsApi } from './statistics.api';
import { deserializeStatistics } from './statistics.serializer';
import { I18n } from 'react-redux-i18n';

export function loadStatisticStarted() {
  return {
    type: STATISTICS_LOAD_STARTED,
  };
}

export function loadStatisticFailed(payload) {
  return {
    type: STATISTICS_LOAD_FAILED,
    payload,
  };
}

export function loadStatisticSuccess(payload) {
  return {
    type: STATISTICS_LOAD_SUCCESS,
    payload,
  };
}

export function loadStatistics(companyId, unit, quantity) {
  return async (dispatch) => {
    try {
      dispatch(loadStatisticStarted());
      let response = await loadStatisticsApi(companyId, { unit, quantity });
      if (response.status === 200 && response.data) {
        let result = deserializeStatistics(response.data);
        dispatch(loadStatisticSuccess(result));
      } else {
        dispatch(loadStatisticFailed(response.status.text));
      }
    } catch (error) {
      dispatch(
        loadStatisticFailed(I18n.t('statistics.failedToLoadStatistics'))
      );
    }
  };
}
