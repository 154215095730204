import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import TextArea from '../../../components/TextArea';
import { cloneDeep } from 'lodash';
import { SelectField } from '../../../components/selectField.component';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../../common/constants';
import PolicyActivationToggle from './PolicyActivationToggle.component';
import { isNotUndefined } from '../../../common/helpers';

export const initialSettings = {
  webFilterType: 'whitelist',
  urls: '',
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'webFilter',
  isActive: false,
  policyData: null,
};

const webFilterTypes = [
  {
    value: 'whitelist',
    title: I18n.t('profiles.webFilter.webFilterTypeWhitelist'),
  },
  {
    value: 'blacklist',
    title: I18n.t('profiles.webFilter.webFilterTypeBlacklist'),
  },
];

const WEB_FILTER_CONSTANTS = {
  WEB_FILTER_TYPE: 'webFilterType',
  URLS: 'urls',
  WEB_FILTER: 'webFilter',
};

const getReadOnlyData = ({ urls = '', webFilterType } = {}) => [
  {
    title: <Translate value="profiles.webFilter.webFilterType" />,
    value: webFilterType,
    type: AttributeType.ENUMERABLE,
    key: 'webFilterType',
    availableOptions: webFilterTypes,
  },
  {
    title: <Translate value="profiles.webFilter.urls" />,
    valueList: urls.split('\n'),
    type: AttributeType.TEXT,
    key: 'urls',
  },
];

const WebFilter = (props) => {
  const isPolicyActive = props.policy.some((item) => item.isActive);

  const onChange = (index, key, { target: { value } }) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    settings[key] = value;
    item.settings = settings;
    props.onChange(WEB_FILTER_CONSTANTS.WEB_FILTER, index, item);
  };

  const onToggle = (index, key, presetNewToggleValue = undefined) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings ? item.settings : { ...initialSettings };
    settings[key] = isNotUndefined(presetNewToggleValue)
      ? presetNewToggleValue
      : !settings[key];
    item.settings = settings;
    props.onChange(WEB_FILTER_CONSTANTS.WEB_FILTER, index, item);
  };

  const onChangeUrls = ({ target: { value } }, index) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    if (!value) {
      settings[WEB_FILTER_CONSTANTS.URLS] = undefined;
      settings['lastUpdated'] = undefined;
    } else {
      settings[WEB_FILTER_CONSTANTS.URLS] = value;
    }
    item.settings = settings;
    props.onChange(WEB_FILTER_CONSTANTS.WEB_FILTER, index, item);
  };

  const renderFormFields = ({ settings, isActive }, index) =>
    isActive ? (
      <section key={`web-filter-${index}`}>
        <SelectField
          title={I18n.t('profiles.webFilter.webFilterType')}
          value={settings.webFilterType}
          onChange={(e) =>
            onChange(index, WEB_FILTER_CONSTANTS.WEB_FILTER_TYPE, e)
          }
          options={webFilterTypes}
        />
        <TextArea
          label={I18n.t('profiles.webFilter.urls')}
          hint={I18n.t('profiles.webFilter.urlHint')}
          value={settings.urls}
          onChange={(e) => onChangeUrls(e, index)}
          autoGrow
        />
      </section>
    ) : null;

  const renderContent = () => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      props.onChangePolicyProperty({
        key: WEB_FILTER_CONSTANTS.WEB_FILTER,
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });

    return (
      <>
        <PolicyActivationToggle
          key="policyActivation"
          policy={props.policy}
          titleCode="profiles.webFilter.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
        />
        {props.policy.length
          ? props.policy.map(renderFormFields)
          : renderFormFields(
              {
                ...initialPolicy,
                settings: { ...initialSettings },
              },
              0
            )}
      </>
    );
  };

  const renderReadOnlyContent = () => {
    return props.policy?.length ? (
      [
        <ReadOnlyForm
          items={[
            {
              title: <Translate value="profiles.webFilter.policyStatusTitle" />,
              value: isPolicyActive,
              type: AttributeType.BOOLEAN,
              key: 'isPolicyActive',
            },
          ]}
          headerTitle={I18n.t('profiles.webFilter.webFilter')}
        />,
        ...props.policy.map((item, index) => (
          <ReadOnlyForm
            items={getReadOnlyData(item.settings)}
            headerTitle={`${I18n.t('profiles.webFilter.webFilter')} ${
              index + 1
            }`}
            emptyListMessage={
              <Translate value="profiles.webFilter.noWebFilterPolicies" />
            }
          />
        )),
      ]
    ) : (
      <Translate value="profiles.webFilter.noWebFilterPolicies" />
    );
  };

  return (
    <div className="web-filter">
      {props.isEditingMode ? renderContent() : renderReadOnlyContent()}
    </div>
  );
};

export default WebFilter;
