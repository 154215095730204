import React from 'react';
import StyleUtils from '../../utils/styleUtils';

const StatusDisplay = ({ text, positive }) => {
  return (
    <div
      className={StyleUtils.mergeModifiers(
        'status-display',
        positive && 'positive'
      )}
    >
      {text}
    </div>
  );
};

export default StatusDisplay;
