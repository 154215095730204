export const deserializeDeviceNotification = ({
  device_id,
  device_name,
  alert_type,
  company_id,
  alert_raised_datetime,
  company_alert_id,
  in_fence,
  security_compliance,
  is_read,
  is_display_on_frontend,
} = {}) => ({
  deviceId: device_id || '',
  deviceName: device_name || '',
  inFence: typeof in_fence === 'boolean' ? in_fence : undefined,
  id: company_alert_id,
  type: alert_type,
  lowBattery: alert_type === 'low_battery',
  securityCompliance: security_compliance,
  companyId: company_id,
  alertRaisedDatetime: alert_raised_datetime,
  isRead: is_read,
  isDisplayedOnFrontend: is_display_on_frontend,
});

export const serializeDeviceNotification = ({
  id,
  deviceName,
  type,
  isDisplay,
  isRead,
  isDisplayedOnFrontend,
} = {}) => ({
  company_alert_id: id,
  device_name: deviceName,
  alert_type: type,
  is_display: isDisplay,
  is_read: isRead,
  is_display_on_frontend: isDisplayedOnFrontend,
});
