export const androidSupportedLocales = [
  {
    title: 'Afrikaans (Suid-Afrika)',
    value: 'af_ZA',
  },
  {
    title: 'Azərbaycan (Azərbaycan)',
    value: 'az_AZ',
  },
  {
    title: 'Català (Espanya)',
    value: 'ca_ES',
  },
  {
    title: 'Dansk (Danmark)',
    value: 'da_DK',
  },
  {
    title: 'Deutsch (Deutschland)',
    value: 'de_DE',
  },
  {
    title: 'Deutsch (Liechtenstein)',
    value: 'de_LI',
  },
  {
    title: 'Deutsch (Schweiz)',
    value: 'de_CH',
  },
  {
    title: 'Deutsch (Österreich)',
    value: 'de_AT',
  },
  {
    title: 'Eesti (Eesti)',
    value: 'et_EE',
  },
  {
    title: 'English (Australia)',
    value: 'en_AU',
  },
  {
    title: 'English (Canada)',
    value: 'en_CA',
  },
  {
    title: 'English (India)',
    value: 'en_IN',
  },
  {
    title: 'English (New Zealand)',
    value: 'en_NZ',
  },
  {
    title: 'English (Singapore)',
    value: 'en_SG',
  },
  {
    title: 'English (United Kingdom)',
    value: 'en_GB',
  },
  {
    title: 'English (United States)',
    value: 'en_US',
  },
  {
    title: 'Español (España)',
    value: 'es_ES',
  },
  {
    title: 'Español (Estados Unidos)',
    value: 'es_US',
  },
  {
    title: 'Euskara (Espainia)',
    value: 'eu_ES',
  },
  {
    title: 'Français (Belgique)',
    value: 'fr_BE',
  },
  {
    title: 'Français (Canada)',
    value: 'fr_CA',
  },
  {
    title: 'Français (France)',
    value: 'fr_FR',
  },
  {
    title: 'Français (Suisse)',
    value: 'fr_CH',
  },
  {
    title: 'Galego (España)',
    value: 'gl_ES',
  },
  {
    title: 'Hrvatski (Hrvatska)',
    value: 'hr_HR',
  },
  {
    title: 'Indonesia (Indonesia)',
    value: 'in_ID',
  },
  {
    title: 'IsiZulu (iNingizimu Afrika)',
    value: 'zu_ZA',
  },
  {
    title: 'Italiano (Italia)',
    value: 'it_IT',
  },
  {
    title: 'Italiano (Svizzera)',
    value: 'it_CH',
  },
  {
    title: 'Kiswahili (Tanzania)',
    value: 'sw_TZ',
  },
  {
    title: 'Latviešu (Latvija)',
    value: 'lv_LV',
  },
  {
    title: 'Lietuvių (Lietuva)',
    value: 'lt_LT',
  },
  {
    title: 'Magyar (Magyarország)',
    value: 'hu_HU',
  },
  {
    title: 'Melayu (Malaysia)',
    value: 'ms_MY',
  },
  {
    title: 'Nederlands (België)',
    value: 'nl_BE',
  },
  {
    title: 'Nederlands (Nederland)',
    value: 'nl_NL',
  },
  {
    title: 'Norsk bokmål (Norge)',
    value: 'nb_NO',
  },
  {
    title: 'O‘zbek (Oʻzbekiston)',
    value: 'uz_UZ',
  },
  {
    title: 'Polski (Polska)',
    value: 'pl_PL',
  },
  {
    title: 'Português (Brasil)',
    value: 'pt_BR',
  },
  {
    title: 'Português (Portugal)',
    value: 'pt_PT',
  },
  {
    title: 'Română (România)',
    value: 'ro_RO',
  },
  {
    title: 'Rumantsch (Svizra)',
    value: 'rm_CH',
  },
  {
    title: 'Slovenčina (Slovensko)',
    value: 'sk_SK',
  },
  {
    title: 'Slovenščina (Slovenija)',
    value: 'sl_SI',
  },
  {
    title: 'Suomi (Suomi)',
    value: 'fi_FI',
  },
  {
    title: 'Svenska (Sverige)',
    value: 'sv_SE',
  },
  {
    title: 'Tiếng Việt (Việt Nam)',
    value: 'vi_VN',
  },
  {
    title: 'Türkçe (Türkiye)',
    value: 'tr_TR',
  },
  {
    title: 'Íslenska (Ísland)',
    value: 'is_IS',
  },
  {
    title: 'Čeština (Česko)',
    value: 'cs_CZ',
  },
  {
    title: 'Ελληνικά (Ελλάδα)',
    value: 'el_GR',
  },
  {
    title: 'Български (България)',
    value: 'bg_BG',
  },
  {
    title: 'Кыргызча (Кыргызстан)',
    value: 'ky_KG',
  },
  {
    title: 'Македонски (Република Македонија)',
    value: 'mk_MK',
  },
  {
    title: 'Монгол (Монгол)',
    value: 'mn_MN',
  },
  {
    title: 'Русский (Россия)',
    value: 'ru_RU',
  },
  {
    title: 'Српски (Србија)',
    value: 'sr_RS',
  },
  {
    title: 'Українська (Україна)',
    value: 'uk_UA',
  },
  {
    title: 'Қазақ тілі (Қазақстан)',
    value: 'kk_KZ',
  },
  {
    title: 'Հայերեն (Հայաստան)',
    value: 'hy_AM',
  },
  {
    title: 'עברית (ישראל)',
    value: 'iw_IL',
  },
  {
    title: 'اردو (پاکستان)',
    value: 'ur_PK',
  },
  {
    title: 'العربية (مصر)',
    value: 'ar_EG',
  },
  {
    title: 'فارسی (ایران)',
    value: 'fa_IR',
  },
  {
    title: 'नेपाली (नेपाल)',
    value: 'ne_NP',
  },
  {
    title: 'मराठी (भारत)',
    value: 'mr_IN',
  },
  {
    title: 'हिन्दी (भारत)',
    value: 'hi_IN',
  },
  {
    title: 'বাংলা (বাংলাদেশ)',
    value: 'bn_BD',
  },
  {
    title: 'தமிழ் (இந்தியா)',
    value: 'ta_IN',
  },
  {
    title: 'తెలుగు (భారతదేశం)',
    value: 'te_IN',
  },
  {
    title: 'ಕನ್ನಡ (ಭಾರತ)',
    value: 'kn_IN',
  },
  {
    title: 'മലയാളം (ഇന്ത്യ)',
    value: 'ml_IN',
  },
  {
    title: 'සිංහල (ශ්‍රී ලංකාව)',
    value: 'si_LK',
  },
  {
    title: 'ไทย (ไทย)',
    value: 'th_TH',
  },
  {
    title: 'ລາວ (ລາວ)',
    value: 'lo_LA',
  },
  {
    title: 'မြန်မာ (မြန်မာ)',
    value: 'my_MM',
  },
  {
    title: 'ქართული (საქართველო)',
    value: 'ka_GE',
  },
  {
    title: 'አማርኛ (ኢትዮጵያ)',
    value: 'am_ET',
  },
  {
    title: 'ខ្មែរ (កម្ពុជា)',
    value: 'km_KH',
  },
  {
    title: '中文 (中国)',
    value: 'zh_CN',
  },
  {
    title: '中文 (台灣)',
    value: 'zh_TW',
  },
  {
    title: '中文 (香港)',
    value: 'zh_HK',
  },
  {
    title: '日本語 (日本)',
    value: 'ja_JP',
  },
  {
    title: '한국어 (대한민국)',
    value: 'ko_KR',
  },
];
