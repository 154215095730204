import { getObjectByMaxValue, groupBy, parseAppInfo } from '../helpers';
import { GLOBAL_READ_READWRITE_PERMISSIONS } from '../../constants/globalPermissions';
import { ACCESS_TYPES } from '../../constants/accessTypes';
import computeChecksumMd5 from './checksum';
import { serializeAPKFileMeta } from '../../features/upload/upload.serializer';
import { FILE_TYPES } from '../../constants/fileTypes';

export const groupAppsWithChildrenByKey = (
  apps,
  key,
  childListName = 'children'
) => {
  const grouped = groupBy(apps, key);
  return Object.keys(grouped).reduce((acc, curr) => {
    let item;
    const currentItem = grouped[curr];
    if (currentItem.length > 1) {
      const parent = getObjectByMaxValue(currentItem, 'versionCode');
      const children = currentItem.filter((item) => item.id !== parent.id);
      item = {
        ...parent,
        [childListName]: children,
      };
    } else {
      item = grouped[curr][0];
    }
    return [...acc, item];
  }, []);
};

export const isAppManagementAllowed = (
  globalPermissionsForUser = {},
  hasAdminPrivileges = false
) => {
  const isAppSettingsReadWriteAccessAvailable =
    globalPermissionsForUser[
      GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS
    ] === ACCESS_TYPES.READ_WRITE;
  return hasAdminPrivileges || isAppSettingsReadWriteAccessAvailable;
};

export const sortAppFilesMetaByNameAndVersionCode = (filesMeta) =>
  filesMeta.sort((fileMetaA, fileMetaB) => {
    const appIdNameA = fileMetaA.app_id_name.toUpperCase();
    const appIdNameB = fileMetaB.app_id_name.toUpperCase();
    const versionCodeA = fileMetaA.app_version_code;
    const versionCodeB = fileMetaB.app_version_code;

    if (appIdNameA < appIdNameB) {
      return -1;
    }
    if (appIdNameA > appIdNameB) {
      return 1;
    }
    if (versionCodeA < versionCodeB) {
      return -1;
    }
    if (versionCodeA > versionCodeB) {
      return 1;
    }
    return 0;
  });

export async function getAPKMetaData(apkFile, additionMetaData = {}) {
  const fileChecksum = await computeChecksumMd5(apkFile);
  const {
    versionCode: appVersionCode,
    versionName: appVersion,
    package: appIdName,
    application: { label = [] } = {},
  } = await parseAppInfo(apkFile);
  const { name: filename, size: appSize } = apkFile;
  return serializeAPKFileMeta({
    label: label[0] ?? '',
    appName: filename,
    key: filename,
    file: filename,
    contentType: FILE_TYPES.APK,
    appVersion,
    appVersionCode,
    appIdName,
    fileChecksum,
    appSize,
    ...additionMetaData,
  });
}
