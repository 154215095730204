export const RemoteCommandsTypes = {
  RESET_DEVICE_PASSWORD: 'reset_device_password',
  PAUSE_AGENT_UPDATE: 'pause_agent_update',
  RESUME_AGENT_UPDATE: 'resume_agent_update',
  FORCE_CHECKIN: 'force_checkin',
  CLEAR_DATA_AND_CACHE: 'clear_all_data_and_cache',
  LOCK: 'lock',
  WIPE: 'wipe',
  WIPE_SD_CARD: 'wipe_sd_card',
  REBOOT: 'reboot',
  DPC_UPDATE: 'dpc_update',
  IDENTIFY_DEVICE: 'identify_device',
  INCREASE_LOCATION_TRACKING_FREQUENCY: 'increase_location_tracking_frequency',
  SEND_MESSAGE: 'send_message',
};

export const LockDeviceConfigurationStatuses = {
  ACTIVE: 'ACTIVE',
  LOCKED: 'LOCKED',
};
