import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AppManagement from './AppManagement';
import { Button } from '../../../components/button.component';
import { I18n } from 'react-redux-i18n';

class ApplicationBundle extends Component {
  state = {
    idName: '',
  };

  render() {
    return (
      <div className={'application-bundle'}>
        <div className={'application-bundle-header'}>
          <div className={'application-bundle-header__title'}>
            {I18n.t('applications.addBundleTitle')}
          </div>
        </div>
        <div className={'application-bundle-content'}>
          <div className={'application-bundle-content__label'}>
            {`${I18n.t('application.fields.packageId')}:`}
          </div>
          <input
            name={'idName'}
            className={'form-control'}
            value={this.state.idName}
            onChange={this.onChange}
            placeholder={I18n.t('applications.addBundleFieldPlaceholderText')}
          />
        </div>
        <div className={'application-bundle-footer'}>
          <Button secondary onClick={this.props.onCancel}>
            {I18n.t('common.cancel')}
          </Button>
          <Button onClick={this.onSubmit} disabled={!this.state.idName}>
            {I18n.t('common.add')}
          </Button>
        </div>
      </div>
    );
  }

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  onSubmit = () => {
    this.props.onSubmit(this.props.destinationIndex, {
      idName: this.state.idName,
    });
    this.props.onCancel();
  };
}

export default ApplicationBundle;

AppManagement.propTypes = {
  destinationIndex: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};
