export const FLEET_V2_CREATE_STARTED = 'FLEET_V2_CREATE_STARTED';
export const FLEET_V2_CREATE_SUCCESS = 'FLEET_V2_CREATE_SUCCESS';
export const FLEET_V2_CREATE_FAILED = 'FLEET_V2_CREATE_FAILED';
export const FLEET_V2_CREATE_RESET = 'FLEET_V2_CREATE_RESET';

export const FLEETS_V2_LOAD_ALL_STARTED = 'FLEETS_V2_LOAD_ALL_STARTED';
export const FLEETS_V2_LOAD_ALL_FAILED = 'FLEETS_V2_LOAD_ALL_FAILED';
export const FLEETS_V2_LOAD_ALL_SUCCESS = 'FLEETS_V2_LOAD_ALL_SUCCESS';

export const FLEETS_V2_CONFIG_LOAD_STARTED = 'FLEETS_V2_CONFIG_LOAD_STARTED';
export const FLEETS_V2_CONFIG_LOAD_FAILED = 'FLEETS_V2_CONFIG_LOAD_FAILED';
export const FLEETS_V2_CONFIG_LOAD_SUCCESS = 'FLEETS_V2_CONFIG_LOAD_SUCCESS';
export const FLEETS_V2_CONFIG_LOAD_RESET = 'FLEETS_V2_CONFIG_LOAD_RESET';
