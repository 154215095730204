import {
  COMPANY_LOAD_INFO_FAILED,
  COMPANY_LOAD_INFO_STARTED,
  COMPANY_LOAD_INFO_SUCCESS,
  COMPANY_SET_SETTINGS_FAILED,
  COMPANY_SET_SETTINGS_STARTED,
  COMPANY_SET_SETTINGS_SUCCESS,
  COMPANY_SET_SETTINGS_RESET,
  COMPANY_LOAD_SETTINGS_FAILED,
  COMPANY_LOAD_SETTINGS_SUCCESS,
  COMPANY_LOAD_SETTINGS_STARTED,
} from './company.actionTypes';
import { NetworkStatus } from '../common/constants';

export default function company(
  state = {
    companyInfo: undefined,
    status: NetworkStatus.NONE,
    companyInfoError: undefined,
    companySettings: undefined,
    companySettingStatus: NetworkStatus.NONE,
    companySettingError: undefined,
    gettingCompanySettingsStatus: NetworkStatus.NONE,
    gettingCompanySettingsError: undefined,
  },
  action
) {
  switch (action.type) {
    case COMPANY_LOAD_INFO_STARTED:
      return Object.assign({}, state, {
        status: NetworkStatus.STARTED,
        companyInfoError: undefined,
        companyInfo: undefined,
      });
    case COMPANY_LOAD_INFO_SUCCESS:
      return Object.assign({}, state, {
        status: NetworkStatus.DONE,
        companyInfoError: undefined,
        companyInfo: action.payload,
      });
    case COMPANY_LOAD_INFO_FAILED:
      return Object.assign({}, state, {
        status: NetworkStatus.ERROR,
        companyInfoError: action.payload,
      });
    case COMPANY_SET_SETTINGS_STARTED:
      return Object.assign({}, state, {
        companySettingsError: undefined,
        companySettingStatus: NetworkStatus.STARTED,
      });
    case COMPANY_SET_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        companySettingStatus: NetworkStatus.DONE,
      });
    case COMPANY_SET_SETTINGS_FAILED:
      return Object.assign({}, state, {
        companySettingStatus: NetworkStatus.ERROR,
        companySettingError: action.payload,
      });
    case COMPANY_SET_SETTINGS_RESET:
      return Object.assign({}, state, {
        companySettingStatus: NetworkStatus.NONE,
        companySettingsError: undefined,
      });
    case COMPANY_LOAD_SETTINGS_STARTED:
      return Object.assign({}, state, {
        gettingCompanySettingsStatus: NetworkStatus.STARTED,
      });
    case COMPANY_LOAD_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        gettingCompanySettingsStatus: NetworkStatus.DONE,
        companySettingsError: undefined,
        companySettings: action.payload,
      });
    case COMPANY_LOAD_SETTINGS_FAILED:
      return Object.assign({}, state, {
        gettingCompanySettingsStatus: NetworkStatus.ERROR,
        gettingCompanySettingsError: action.payload,
      });
    default:
      return state;
  }
}
