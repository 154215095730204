import React from 'react';
import { I18n } from 'react-redux-i18n';

export default (props) => (
  <div className={'dialog-base'}>
    <div className={'app__content'} style={{ top: 0 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          backgroundColor: '#fff',
        }}
      >
        {I18n.t('common.notFound')}
      </div>
    </div>
  </div>
);
