import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import EntityCreationWizardStepsNavigation from '../../../components/EntityCreationWizardStepsNavigation/entityCreationWizardStepsNavigation.component';
import { history } from '../../../utils/history';
import { AlertWizardRoutes, CreateAlertRoute } from '../createAlert.constants';
import { newAlertSelector } from '../../alerts/alerts.selectors';
import { changeAlertProperty } from '../../alerts/alerts.actions';
import AlertType from '../../../components/alertCreationAndEditing/alertType.component';

const AlertTypeStep = ({ newAlert, changeAlertProperty }) => {
  const goToNextStep = () =>
    history.push(`${CreateAlertRoute}${AlertWizardRoutes.CONDITION}`);
  return (
    <div className="creation-wizard-step-container">
      <AlertType alert={newAlert} onPropertyChange={changeAlertProperty} />
      <EntityCreationWizardStepsNavigation
        primaryNav={{
          first: {
            isBack: true,
          },
          second: {
            onClick: goToNextStep,
            disabled: !newAlert.alertType,
            text: `${I18n.t('alerts.next')} ⟶`,
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  newAlert: newAlertSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeAlertProperty: ({ key, value }) =>
    dispatch(changeAlertProperty({ key, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertTypeStep);
