import { I18n } from 'react-redux-i18n';
import {
  deleteUsersFailed,
  deleteUsersReset,
  deleteUsersStarted,
  deleteUsersSuccess,
  loadUserGroupPermissionsFailed,
  loadUserGroupPermissionsStart,
  loadUserGroupPermissionsSuccess,
  loadUsersFailed,
  loadUsersStart,
  loadUsersSuccess,
  makeUsersInactiveFailed,
  makeUsersInactiveStarted,
  makeUsersInactiveSuccess,
  moveUsersFailed,
  moveUsersStarted,
  moveUsersSuccess,
} from './users.action';
import client from '../../utils/client';
import { deserializeUser } from '../user/user.serializer';
import services from '../../utils/services';
import { isDataSuccessfullyFetched } from '../../common/helpers';
import { deserializeUserGroupPermissions } from './users.serializer';
import UserHelpers from '../../common/utilities/user';
import { getState } from '../../store/store';
import { RootRoutes, UsersRoutes } from '../../utils/routes';
import { ALL_USERS_GROUP_ID } from '../../constants/users';
import { history } from '../../utils/history';

export function loadUsers(groupId) {
  return async (dispatch) => {
    try {
      dispatch(loadUsersStart());
      const hasAdminPrivileges = UserHelpers.hasAdminPrivileges(getState());
      const endpoint = hasAdminPrivileges
        ? services.loadUsersRequestedByAdmin
        : services.loadUsersRequestedByNonAdmin;
      let { status, data = [] } = await client.get(endpoint, {
        params: { group: groupId },
      });
      if (status === 200 && data) {
        const result = data.map(deserializeUser);
        dispatch(loadUsersSuccess(result));
      } else {
        dispatch(loadUsersFailed(I18n.t('users.loadUsersError')));
      }
    } catch (error) {
      dispatch(loadUsersFailed(I18n.t('users.loadUsersError')));
    }
  };
}

export function deleteUsers(users) {
  return async (dispatch) => {
    try {
      dispatch(deleteUsersStarted());
      let { status } = await client.delete(services.deleteUsers, {
        data: {
          user_ids: users,
        },
      });
      if (status === 204) {
        dispatch(deleteUsersSuccess());
        dispatch(loadUsers());
        dispatch(deleteUsersReset());
      } else {
        dispatch(deleteUsersFailed(I18n.t('users.deleteUsersError')));
      }
    } catch (error) {
      dispatch(deleteUsersFailed(I18n.t('users.deleteUsersError')));
    }
  };
}

export function moveUsersToGroup(users, sourceGroup, targetGroup) {
  return async (dispatch) => {
    try {
      dispatch(moveUsersStarted());
      let { status } = await client.post(
        `user-management/move-users-from-one-group-to-another/`,
        {
          user_list: users,
          in_which_group: targetGroup,
          from_what_group: sourceGroup,
        }
      );
      if (status === 204 || status === 200) {
        dispatch(moveUsersSuccess());
        dispatch(loadUsers());
      } else {
        dispatch(moveUsersFailed(I18n.t('users.moveUsersError')));
      }
    } catch (error) {
      dispatch(deleteUsersFailed(I18n.t('users.moveUsersError')));
    }
  };
}

export function changeUserStatus(users, groupId, deactivateUsers = false) {
  return async (dispatch) => {
    try {
      dispatch(makeUsersInactiveStarted());
      let { status } = await client.post(
        `user-management/users/${
          deactivateUsers ? 'disable_users' : 'enable_users'
        }/`,
        {
          user_list: [...users],
        }
      );
      if (status === 204 || status === 200 || status === 202) {
        dispatch(makeUsersInactiveSuccess());
        if (groupId === ALL_USERS_GROUP_ID) {
          dispatch(loadUsers());
        } else {
          history.push(
            `${RootRoutes.USERS}${UsersRoutes.GROUPS}/${ALL_USERS_GROUP_ID}`
          );
        }
      } else {
        dispatch(
          makeUsersInactiveFailed(I18n.t('users.changeUserStatusError'))
        );
      }
    } catch (error) {
      dispatch(makeUsersInactiveFailed(I18n.t('users.changeUserStatusError')));
    }
  };
}

export const loadUserGroupPermissions = (groupId) => {
  return async (dispatch) => {
    try {
      dispatch(loadUserGroupPermissionsStart());
      const response = await client.get(
        `${services.getUserGroupPermissions}${groupId}`,
        {}
      );
      const { data = {} } = response;
      if (isDataSuccessfullyFetched(response)) {
        const result = deserializeUserGroupPermissions(data);
        dispatch(loadUserGroupPermissionsSuccess(result));
      } else {
        dispatch(
          loadUserGroupPermissionsFailed(
            I18n.t('users.loadUserGroupPermissionError')
          )
        );
      }
    } catch (e) {
      dispatch(
        loadUserGroupPermissionsFailed(
          I18n.t('users.loadUserGroupPermissionError')
        )
      );
    }
  };
};
