import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createSocketMiddleware } from './websockets';

export default () => {
  const socketMiddleWare = createSocketMiddleware();

  if (process.env.NODE_ENV === 'development') {
    return applyMiddleware(
      thunk,
      createLogger({
        collapsed: false,
        diff: false,
        colors: { title: () => '#776e93' },
      }),
      socketMiddleWare
    );
  }

  return applyMiddleware(thunk, socketMiddleWare);
};
