export const TASK_LOAD_STARTED = 'TASK_LOAD_STARTED';
export const TASK_LOAD_FAILED = 'TASK_LOAD_FAILED';
export const TASK_LOAD_SUCCESS = 'TASK_LOAD_SUCCESS';
export const TASK_LOAD_DEVICES_STARTED = 'TASK_LOAD_DEVICES_STARTED';
export const TASK_LOAD_DEVICES_FAILED = 'TASK_LOAD_DEVICES_FAILED';
export const TASK_LOAD_DEVICES_SUCCESS = 'TASK_LOAD_DEVICES_SUCCESS';

export function loadTaskStarted(payload) {
  return {
    type: TASK_LOAD_STARTED,
    payload,
  };
}

export function loadTaskFailed(payload) {
  return {
    type: TASK_LOAD_FAILED,
    payload,
  };
}

export function taskLoaded(payload) {
  return {
    type: TASK_LOAD_SUCCESS,
    payload,
  };
}

export function loadTaskDevicesStarted(payload) {
  return {
    type: TASK_LOAD_DEVICES_STARTED,
  };
}

export function taskDevicesLoaded(payload) {
  return {
    type: TASK_LOAD_DEVICES_SUCCESS,
    payload,
  };
}

export function loadTaskDevicesFailed() {
  return {
    type: TASK_LOAD_DEVICES_FAILED,
  };
}
