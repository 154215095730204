import { I18n } from 'react-redux-i18n';
import {
  assignFilesToProfilesApi,
  deleteFilesApi,
  deserializeFile,
  loadAllFilesApi,
} from './files.api';
import {
  FILES_ASSIGN_PROFILES_FAILED,
  FILES_ASSIGN_PROFILES_RESET,
  FILES_ASSIGN_PROFILES_STARTED,
  FILES_ASSIGN_PROFILES_SUCCESS,
  FILES_DELETE_FAILED,
  FILES_DELETE_STARTED,
  FILES_DELETE_SUCCESS,
  FILES_LOAD_ALL_FAILED,
  FILES_LOAD_ALL_STARTED,
  FILES_LOAD_ALL_SUCCESS,
} from './files.actionTypes';
import { serializeFileSyncPolicy } from '../features/profile/profile.serializer';

export function allFilesLoadingStarted() {
  return {
    type: FILES_LOAD_ALL_STARTED,
  };
}

export function allFilesLoadingFailed(payload) {
  return {
    type: FILES_LOAD_ALL_FAILED,
    payload,
  };
}

export function allFilesLoaded(payload) {
  return {
    type: FILES_LOAD_ALL_SUCCESS,
    payload,
  };
}

export function loadAllFiles() {
  return async (dispatch) => {
    try {
      dispatch(allFilesLoadingStarted());
      let response = await loadAllFilesApi();
      if (
        response.status === 200 &&
        response.data &&
        response.data.length >= 0
      ) {
        let result = response.data.map(deserializeFile);
        dispatch(allFilesLoaded(result));
      } else {
        dispatch(allFilesLoadingFailed(I18n.t('fileSystem.failedToLoadFiles')));
      }
    } catch (error) {
      dispatch(
        allFilesLoadingFailed(new Error(I18n.t('fileSystem.failedToLoadFiles')))
      );
    }
  };
}

export function filesDeletingStarted() {
  return {
    type: FILES_DELETE_STARTED,
  };
}

export function filesDeletingFailed(payload) {
  return {
    type: FILES_DELETE_FAILED,
    payload,
  };
}

export function filesDeletingSuccess(payload) {
  return {
    type: FILES_DELETE_SUCCESS,
    payload,
  };
}

export function assignFilesToProfilesStarted() {
  return {
    type: FILES_ASSIGN_PROFILES_STARTED,
  };
}

export function assignFilesToProfilesFailed(payload) {
  return {
    type: FILES_ASSIGN_PROFILES_FAILED,
    payload,
  };
}

export function assignFilesToProfilesSuccess() {
  return {
    type: FILES_ASSIGN_PROFILES_SUCCESS,
  };
}

export function assignFilesToProfilesReset() {
  return {
    type: FILES_ASSIGN_PROFILES_RESET,
  };
}

export function deleteFiles(fileIds, companyId) {
  return async (dispatch) => {
    try {
      dispatch(filesDeletingStarted());
      let response = await deleteFilesApi(fileIds);
      if (response.status === 204) {
        dispatch(loadAllFiles(companyId));
      }
    } catch (error) {
      dispatch(
        filesDeletingFailed(new Error(I18n.t('fileSystem.failedToDeleteFiles')))
      );
    }
  };
}

export function assignFilesToProfiles(companyId, policy, profiles) {
  return async (dispatch) => {
    try {
      dispatch(assignFilesToProfilesStarted());
      const data = {
        ...serializeFileSyncPolicy(policy),
        profiles,
        company_id: companyId,
      };
      let response = await assignFilesToProfilesApi(data);
      if (response.status === 204) {
        dispatch(assignFilesToProfilesSuccess());
      } else {
        dispatch(
          assignFilesToProfilesFailed(
            I18n.t('fileSystem.failedToAssignFilesToProfile')
          )
        );
      }
    } catch (error) {
      dispatch(
        assignFilesToProfilesFailed(
          new Error(I18n.t('fileSystem.failedToAssignFilesToProfile'))
        )
      );
    }
  };
}
