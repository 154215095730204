import {
  APPLICATIONS_DELETE_FAILED,
  APPLICATIONS_DELETE_RESET,
  APPLICATIONS_DELETE_STARTED,
  APPLICATIONS_DELETE_SUCCESS,
  APPLICATIONS_LOAD_FAILED,
  APPLICATIONS_LOAD_STARTED,
  APPLICATIONS_LOAD_SUCCESS,
  LOAD_APPLICATIONS_FULL_LIST_FAILED,
  LOAD_APPLICATIONS_FULL_LIST_STARTED,
  LOAD_APPLICATIONS_FULL_LIST_SUCCESS,
} from './applications.actionTypes';

// export function allApplicationsLoadingStarted() {
//   return {
//     type: APPLICATIONS_LOAD_ALL_STARTED,
//   };
// }
//
// export function allApplicationsLoadingFailed(payload) {
//   return {
//     type: APPLICATIONS_LOAD_ALL_FAILED,
//     payload,
//   };
// }
//
// export function allApplicationsLoaded(payload) {
//   return {
//     type: APPLICATIONS_LOAD_ALL_SUCCESS,
//     payload,
//   };
// }

// export function loadProfileApplications(profileId) {
//   return async (dispatch) => {
//     try {
//       dispatch(allApplicationsLoadingStarted());
//       let response = await loadAllApplicationsApi(profileId);
//       if (response.status === 200 && response.data) {
//         let result = response.data.map(deserializeApplication);
//         dispatch(allApplicationsLoaded(result));
//       } else {
//         dispatch(allApplicationsLoadingFailed(response.status.text));
//       }
//     } catch (error) {
//       dispatch(
//         allApplicationsLoadingFailed(new Error('Applications loading failed'))
//       );
//     }
//   };
// }

export function applicationsDeletingStarted() {
  return {
    type: APPLICATIONS_DELETE_STARTED,
  };
}

export function applicationsDeletingFailed(payload) {
  return {
    type: APPLICATIONS_DELETE_FAILED,
    payload,
  };
}

export function applicationsDeleted() {
  return {
    type: APPLICATIONS_DELETE_SUCCESS,
  };
}

export function applicationsDeletingReset() {
  return {
    type: APPLICATIONS_DELETE_RESET,
  };
}

// export function uploadApps(apps, companyId) {
//     return async dispatch => {
//         try {
//             dispatch(applicationsUploadStarted());
//             const filesMeta = files.map(file => ({content_type: file.type && file.type.length ? file.type : "text/plain", key: file.name}));
//             let filesToUpload = filesMeta.length || 1;
//             const formData = new FormData();
//             apps.forEach(app => {
//                 formData.append('file', app);
//             });
//             const onUploadProgress = (progressEvent) => {
//                 const uploadPercentage = parseInt(Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total / filesToUpload ), 10);
//                 dispatch(setApplicationsUploadProgress(uploadPercentage));
//             };
//             let response = await uploadApplicationsApi(formData, companyId, onUploadProgress);
//             if (response.status === 200) {
//                 dispatch(applicationsUploadSuccess(response.data));
//             } else {
//                 dispatch(applicationsUploadFailed(response.status.text));
//             }
//         } catch (error) {
//             dispatch(applicationsUploadFailed(new Error('Applications uploading failed')));
//         }
//     }
// }

export function loadApplicationsStarted() {
  return {
    type: APPLICATIONS_LOAD_STARTED,
  };
}

export function loadApplicationsFailed(payload) {
  return {
    type: APPLICATIONS_LOAD_FAILED,
    payload,
  };
}

export function applicationsLoaded(payload) {
  return {
    type: APPLICATIONS_LOAD_SUCCESS,
    payload,
  };
}

export const loadApplicationsFullListStarted = () => ({
  type: LOAD_APPLICATIONS_FULL_LIST_STARTED,
});

export const loadApplicationsFullListFailed = (payload) => ({
  type: LOAD_APPLICATIONS_FULL_LIST_FAILED,
  payload,
});

export const loadApplicationsFullListSuccess = (payload) => ({
  type: LOAD_APPLICATIONS_FULL_LIST_SUCCESS,
  payload,
});
