import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import UserHelpers from '../../common/utilities/user';
import { companyIdSelector } from '../login/login.selectors';
import PopupActions from '../popup/popup.actions';
import { I18n } from 'react-redux-i18n';
import { htmlKioskFilesColumns } from './htmlKioskFiles.constants';
import ToolsPanel from '../../components/ToolsPanel';
import Tool from '../../components/ToolBar/Tool';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import {
  htmlFilesLoadingStatusSelector,
  htmlFilesSelector,
} from './htmlKioskFiles.selectors';
import { deleteFiles, loadAllFiles } from '../../redux/files.actions';
import { isDataLoadingRequired } from '../../common/helpers';
import DataTable from '../../components/DataTable/DataTable.component';

const MyHTMLKioskFiles = ({
  hasAdminPrivileges,
  htmlFiles,
  companyId,
  loadHTMLFiles,
  htmlFilesLoadingStatus,
  deleteHTMLFiles,
}) => {
  const tableRef = useRef();
  const [selectedHTMLFiles, setSelectedHTMLFiles] = useState(() => new Set());
  useEffect(() => {
    if (isDataLoadingRequired(htmlFilesLoadingStatus)) {
      loadHTMLFiles(companyId);
    }
  }, [loadHTMLFiles, companyId, htmlFilesLoadingStatus]);

  const onSelect = (selectedIds) => {
    const selected = new Set(selectedIds);
    setSelectedHTMLFiles(selected);
  };

  const resetSelectedHTMLFiles = () => setSelectedHTMLFiles(() => new Map());

  const onDelete = () =>
    PopupActions.showConfirm({
      onConfirm: () => {
        deleteHTMLFiles([...selectedHTMLFiles.keys()], companyId);
        resetSelectedHTMLFiles();
      },
      text: I18n.t('htmlKioskFiles.deletionConfirmation'),
    });

  const showToolBar = selectedHTMLFiles.size;
  const contentClassName = showToolBar
    ? 'app__content app__content--with-toolbar'
    : 'app__content';

  return (
    <>
      <section className={contentClassName}>
        <section className="page-content">
          <section className="card">
            <DataTable
              allColumns={htmlKioskFilesColumns}
              data={htmlFiles}
              loadingStatus={htmlFilesLoadingStatus}
              sortable
              selectable
              onSelect={onSelect}
              tableRef={tableRef}
              useFlex
              useFilter
            />
          </section>
        </section>
      </section>
      {showToolBar ? (
        <ToolsPanel
          title={I18n.t('htmlKioskFiles.selectedFiles')}
          selectedItems={selectedHTMLFiles}
        >
          <Tool
            title={I18n.t('htmlKioskFiles.removeFiles')}
            onClick={onDelete}
            hidden={!hasAdminPrivileges}
          >
            <TrashEmpty className="icon" />
          </Tool>
        </ToolsPanel>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
  companyId: companyIdSelector(state),
  htmlFiles: htmlFilesSelector(state),
  htmlFilesLoadingStatus: htmlFilesLoadingStatusSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadHTMLFiles: (companyId) => dispatch(loadAllFiles(companyId)),
  deleteHTMLFiles: (files, companyId) =>
    dispatch(deleteFiles(files, companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyHTMLKioskFiles);
