import { deserializeDevice } from '../device/device.serializer';
import client from '../../utils/client';
import services from '../../utils/services';
import {
  applicationDevicesLoaded,
  applicationLoaded,
  applicationUpdated,
  assignApplicationToProfilesFailed,
  assignApplicationToProfilesStarted,
  assignApplicationToProfilesSuccess,
  loadApplicationDevicesFailed,
  loadApplicationDevicesStarted,
  loadApplicationFailed,
  loadApplicationStarted,
  updateApplicationFailed,
  updateApplicationStarted,
} from './application.actions';
import { deserializeApplication } from './application.serializer';
import { loadApplications } from '../applications/applications.thunk';
import { ApplicationsRoutes, RootRoutes } from '../../utils/routes';
import { history } from '../../utils/history';
import { I18n } from 'react-redux-i18n';

export function updateApplication(data, companyId) {
  return async (dispatch) => {
    try {
      dispatch(updateApplicationStarted());
      let response = await client.patch(services.updateApplication, {
        app_id: data.id,
        app_name: data.name,
        install_only: data.installOnly,
      });
      if (response.status === 200) {
        dispatch(applicationUpdated());
        dispatch(loadApplication(data.id));
        if (companyId) {
          dispatch(loadApplications());
        }
      } else {
        dispatch(updateApplicationFailed(response.text));
      }
    } catch (error) {
      dispatch(
        updateApplicationFailed(
          I18n.t('applications.applicationDataUpdateError')
        )
      );
    }
  };
}

export function loadApplication(id) {
  return async (dispatch) => {
    try {
      dispatch(loadApplicationStarted());
      let response = await client.get(`${services.loadApplication}${id}`);
      if (response.status === 200 && response.data) {
        let result = response.data.map(deserializeApplication);
        dispatch(applicationLoaded(result));
      } else {
        dispatch(loadApplicationFailed(response.text));
      }
    } catch (error) {
      dispatch(
        loadApplicationFailed(I18n.t('applications.applicationDataFetchError'))
      );
    }
  };
}

export function assignApplicationsToProfiles(apps, profiles, profilesV2) {
  return async (dispatch) => {
    try {
      dispatch(assignApplicationToProfilesStarted());
      let response = await client.post(services.assignAppsToProfles, {
        apps,
        profiles,
        profilesV2,
      });
      if (response.status === 200) {
        dispatch(assignApplicationToProfilesSuccess());
      } else {
        dispatch(assignApplicationToProfilesFailed(response.text));
      }
    } catch (error) {
      dispatch(
        assignApplicationToProfilesFailed(
          I18n.t('applications.applicationAssignmentToProfilesErrorMessage')
        )
      );
    }
  };
}

export function loadApplicationDevices(id) {
  return async (dispatch) => {
    try {
      dispatch(loadApplicationDevicesStarted());
      let response = await client.get(
        `${services.loadApplication}${id}/devices`
      );
      if (response.status === 200 && response.data) {
        let result = {
          devicesInstalled:
            response.data.devices_installed &&
            response.data.devices_installed.length
              ? response.data.devices_installed.map(deserializeDevice)
              : [],
          devicesFailed:
            response.data.devices_failed && response.data.devices_failed.length
              ? response.data.devices_failed.map(deserializeDevice)
              : [],
        };
        dispatch(applicationDevicesLoaded(result));
      } else {
        dispatch(loadApplicationDevicesFailed(response.text));
      }
    } catch (error) {
      dispatch(
        loadApplicationDevicesFailed(
          I18n.t('applications.applicationDataFetchError')
        )
      );
    }
  };
}
