import { AlertRoutes, RootRoutes } from '../../utils/routes';
import { I18n } from 'react-redux-i18n';
import { AlertTypeTypes } from '../alerts/alerts.constants';

export const CreateAlertRoute = `${RootRoutes.ALERTS}${AlertRoutes.CREATE}`;

export const AlertWizardRoutes = {
  FLEET: '/fleet',
  ALERT_TYPE: '/alertType',
  CONDITION: '/condition',
  COMMUNICATION_CHANNEL: '/communicationChannel',
  REVIEW: '/review',
  DONE: '/done',
};

export const AlertWizardTitles = new Map([
  [AlertWizardRoutes.FLEET, I18n.t('alerts.chooseAFleet')],
  [AlertWizardRoutes.ALERT_TYPE, I18n.t('alerts.alertType')],
  [AlertWizardRoutes.CONDITION, I18n.t('alerts.alertCondition')],
  [AlertWizardRoutes.COMMUNICATION_CHANNEL, I18n.t('alerts.alertChannel')],
  [AlertWizardRoutes.REVIEW, I18n.t('alerts.previewAndSave')],
  [AlertWizardRoutes.DONE, I18n.t('alerts.done')],
]);

export const AlertTypeOptions = [
  {
    id: AlertTypeTypes.OUT_OF_CONTACT,
    value: AlertTypeTypes.OUT_OF_CONTACT,
    title: I18n.t('alerts.outOfContact'),
  },
  {
    id: AlertTypeTypes.ENTER_GEOFENCE,
    value: AlertTypeTypes.ENTER_GEOFENCE,
    title: I18n.t('alerts.enterGeoFence'),
  },
  {
    id: AlertTypeTypes.EXIT_GEOFENCE,
    value: AlertTypeTypes.EXIT_GEOFENCE,
    title: I18n.t('alerts.exitGeoFence'),
  },
  {
    id: AlertTypeTypes.BATTERY,
    value: AlertTypeTypes.BATTERY,
    title: I18n.t('alerts.battery'),
  },
];

export const batteryConditionComparisonOperators = {
  LESS_THAN_OR_EQUAL_TO: 'less_than_or_equal',
  GREATER_THAN_OR_EQUAL_TO: 'greater_than_or_equal',
};

export const genericOnOffConditions = {
  ON: 'on',
  OFF: 'off',
};

export const DEFAULT_BATTERY_CONDITION_PERCENTAGE = '50';

export const DEFAULT_CONDITION_VALUES = {
  [AlertTypeTypes.BATTERY]: {
    conditionType: batteryConditionComparisonOperators.LESS_THAN_OR_EQUAL_TO,
    conditionValues: {
      percentage: DEFAULT_BATTERY_CONDITION_PERCENTAGE,
    },
  },
  [AlertTypeTypes.OUT_OF_CONTACT]: { conditionType: genericOnOffConditions.ON },
  [AlertTypeTypes.EXIT_GEOFENCE]: { conditionType: genericOnOffConditions.ON },
  [AlertTypeTypes.ENTER_GEOFENCE]: { conditionType: genericOnOffConditions.ON },
};

export const batteryConditionComparisonOperatorOptions = {
  [batteryConditionComparisonOperators.LESS_THAN_OR_EQUAL_TO]: {
    value: batteryConditionComparisonOperators.LESS_THAN_OR_EQUAL_TO,
    title: I18n.t('alerts.lessThanOrEqualTo'),
  },
  [batteryConditionComparisonOperators.GREATER_THAN_OR_EQUAL_TO]: {
    value: batteryConditionComparisonOperators.GREATER_THAN_OR_EQUAL_TO,
    title: I18n.t('alerts.greaterThanOrEqualTo'),
  },
};

export const genericOnOffConditionOptions = {
  [genericOnOffConditions.ON]: {
    value: genericOnOffConditions.ON,
    title: I18n.t('alerts.on'),
  },
  [genericOnOffConditions.OFF]: {
    value: genericOnOffConditions.OFF,
    title: I18n.t('alerts.off'),
  },
};

export const alertConditionHints = {
  [AlertTypeTypes.BATTERY]: { text: 'alerts.alertWhenDeviceBatteryIs' },
  [AlertTypeTypes.OUT_OF_CONTACT]: {
    text: 'alerts.alertWhenDeviceIsOutOfContact',
  },
  [AlertTypeTypes.ENTER_GEOFENCE]: {
    text: 'alerts.alertWhenDeviceEntersGeofence',
  },
  [AlertTypeTypes.EXIT_GEOFENCE]: {
    text: 'alerts.alertWhenDeviceExitsGeofence',
  },
};

export const communicationChannels = {
  SINGLE_EMAIL: 'email_list',
  USER_GROUPS: 'user_groups',
};

export const communicationChannelOptions = {
  [communicationChannels.USER_GROUPS]: {
    label: I18n.t('alerts.userGroupsCommunicationChannel'),
    value: communicationChannels.USER_GROUPS,
    name: 'communicationChannel',
  },
  [communicationChannels.SINGLE_EMAIL]: {
    label: I18n.t('alerts.singleEmailCommunicationChannel'),
    value: communicationChannels.SINGLE_EMAIL,
    name: 'communicationChannel',
  },
};

export const alertTypeTitles = {
  [AlertTypeTypes.BATTERY]: I18n.t('alerts.battery'),
  [AlertTypeTypes.OUT_OF_CONTACT]: I18n.t('alerts.outOfContact'),
  [AlertTypeTypes.EXIT_GEOFENCE]: I18n.t('alerts.exitGeoFence'),
  [AlertTypeTypes.ENTER_GEOFENCE]: I18n.t('alerts.enterGeoFence'),
};
