import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { cloneDeep } from 'lodash';

export const initialSettings = {
  password: '',
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'authentication',
  policyData: null,
  isActive: false,
};

export const Authentication = (props) => {
  const onChange = (index, key, { target: { value } }) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    settings[key] = value;
    item.settings = settings;
    props.onChange('authentication', index, item);
  };

  const renderSettings = ({ settings }, index) => {
    return (
      <div className={'authentication'}>
        <div className={'section-description'}>
          <div className={'section-description__title--small'}>
            {I18n.t('profiles.authentication.policyTitle')}
          </div>
          <div>{I18n.t('profiles.authentication.policyTitleHint')}</div>
        </div>
        <div>
          <div className={'authentication-input-block'}>
            <input
              className="form-control"
              value={settings.password || ''}
              onChange={(e) => onChange(index, 'password', e)}
              placeholder={I18n.t('profiles.authentication.passwordFieldLabel')}
            />
          </div>
          <div className={'authentication-input-block'}>
            <input
              className="form-control"
              value={settings.password || ''}
              onChange={(e) => onChange(index, 'password', e)}
              placeholder={I18n.t(
                'profiles.authentication.confirmPasswordFieldLabel'
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return props.policy.length
      ? renderSettings(props.policy[0], 0)
      : renderSettings(
          { ...initialPolicy, settings: { ...initialSettings } },
          0
        );
  };

  return <div className={'authentication-wrapper'}>{renderContent()}</div>;
};

export default Authentication;

Authentication.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
