import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Button } from '../../components/button.component';
import { getUsersTableColumns } from './users.constants';
import { AttributeType, NetworkStatus } from '../../common/constants';
import { notEmpty } from '../../common/helpers';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import ToolsPanel from '../../components/ToolsPanel';
import Tool from '../../components/ToolBar/Tool';
import { ReactComponent as UserGroup } from '../../assets/images/user_group.svg';
import { loadUsers, changeUserStatus } from './users.thunk';
import { Modal } from '../../components/modal.component';
import { UsersRoutes, RootRoutes } from '../../utils/routes';
import { NavLink, Route, Switch } from 'react-router-dom';
import ChangeUserDialog from '../user/user.component';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import { ReactComponent as InactiveUser } from '../../assets/images/inactive_user.svg';
import { ReactComponent as ActiveUser } from '../../assets/images/active_user.svg';
import { confirmAlert } from 'react-confirm-alert';
import NewUser from '../newUser/newUser.component';
import { deleteUsers, moveUsersToGroup } from './users.thunk';
import {
  deleteGroup,
  loadCompanyFleetsFullList,
  loadGroups,
} from '../group/groups.thunk';
import { Glyphicon } from 'react-bootstrap';
import CreateUserGroup from '../group/createGroup.component';
import { companyIdSelector, roleSelector } from '../login/login.selectors';
import { makeUsersInactiveReset, moveUsersReset } from './users.action';
import { allCompanyFleetsSelector } from '../../selectors/fleetsSelector';
import RouteHelpers from '../../common/utilities/routeHelpers';
import PopupActions from '../popup/popup.actions';
import UserHelpers from '../../common/utilities/user';
import { ALL_USERS_GROUP_ID, ADMINS_GROUP_ID } from '../../constants/users';
import { getUserManagementDialogStructure } from '../../common/utilities/users';
import DropDownMenu from '../../components/DropDownMenu/dropDownMenu.component';
import { createGroupRouteConstants } from '../group/group.constants';
import { Pages } from '../../constants/pages';
import DataTable from '../../components/DataTable/DataTable.component';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import ExportToCSV from '../../components/ExportToCSV/ExportToCSV.component';

class Users extends React.Component {
  state = {
    showToolPanel: true,
    selected: new Set(),
    targetGroup: null,
    showMoveUsersToGroupDialog: false,
    sortBy: undefined,
    orderBy: undefined,
  };

  tableRef = React.createRef();

  get currentGroupId() {
    return RouteHelpers.getUrlParameter(this.props, 'groupId');
  }

  get isEditActivity() {
    return Boolean(
      RouteHelpers.getQueryParamFromLocation(this.props.location, 'groupId')
    );
  }

  get isCurrentGroupIdAll() {
    return this.currentGroupId === ALL_USERS_GROUP_ID;
  }

  get isCurrentGroupIdAdmins() {
    return this.currentGroupId === ADMINS_GROUP_ID;
  }

  get hasReadWritePermissions() {
    const { isUserManagementEnabled = false } = this.props;
    return isUserManagementEnabled;
  }

  get usersTableColumns() {
    return getUsersTableColumns({
      isUsernameLinkEnabled: this.hasReadWritePermissions,
      isUserGroupShown: this.isCurrentGroupIdAll,
    });
  }

  componentDidMount() {
    this.loadUsers();
    this.loadGroups();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.deleteStatus === NetworkStatus.STARTED &&
      this.props.deleteStatus === NetworkStatus.ERROR
    ) {
      this.onChangeStatus();
    }
    if (
      this.props.moveStatus !== NetworkStatus.NONE &&
      prevProps.moveStatus !== this.props.moveStatus
    ) {
      if (this.props.moveStatus === NetworkStatus.DONE) {
        this.resetSelectedUsers();
        this.resetSelectedTargetGroup();
      }
      this.onChangeMoveStatus();
    }
    if (
      this.props.makeUsersInactiveStatus !== NetworkStatus.NONE &&
      prevProps.makeUsersInactiveStatus !== this.props.makeUsersInactiveStatus
    ) {
      if (this.props.makeUsersInactiveStatus === NetworkStatus.DONE) {
        this.resetSelectedUsers();
        this.resetSelectedTargetGroup();
      }
      this.onChangeMakeInactiveStatus();
    }
    if (
      RouteHelpers.getUrlParameter(prevProps, 'groupId') !==
        this.currentGroupId &&
      this.currentGroupId !== 'create'
    ) {
      this.loadUsers();
    }
  }

  onChangeStatus = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let error = this.props.deleteError || I18n.t('common.failed');
        return (
          <div className="create-enrollment-alert">
            <p>Delete user:</p>
            <p style={{ color: 'red' }}>{error}</p>
            <Button onClick={onClose}>{I18n.t('common.ok')}</Button>
          </div>
        );
      },
    });
    this.resetSelectedUsers();
  };

  onChangeMoveStatus = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let error = this.props.deleteError || I18n.t('common.failed');
        let color =
          this.props.moveStatus === NetworkStatus.ERROR
            ? 'red'
            : this.props.moveStatus === NetworkStatus.DONE
            ? 'green'
            : '#222';
        let text =
          this.props.moveStatus === NetworkStatus.DONE
            ? I18n.t('common.successful')
            : this.props.moveStatus === NetworkStatus.ERROR
            ? error
            : I18n.t('common.inProgress');
        return (
          <div className="create-enrollment-alert">
            <p>{I18n.t('users.moveUsers')}</p>
            <p style={{ color }}>{text}</p>
            <Button
              onClick={() => {
                onClose();
                this.props.moveUsersReset();
              }}
            >
              {I18n.t('common.ok')}
            </Button>
          </div>
        );
      },
    });
    this.resetSelectedTargetGroup();
  };

  onChangeMakeInactiveStatus = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let error =
          this.props.makeUsersInactiveError || I18n.t('common.failed');
        let color =
          this.props.makeUsersInactiveStatus === NetworkStatus.ERROR
            ? 'red'
            : this.props.makeUsersInactiveStatus === NetworkStatus.DONE
            ? 'green'
            : '#222';
        let text =
          this.props.makeUsersInactiveStatus === NetworkStatus.DONE
            ? I18n.t('common.successful')
            : this.props.makeUsersInactiveStatus === NetworkStatus.ERROR
            ? error
            : I18n.t('common.inProgress');
        return (
          <div className="create-enrollment-alert">
            <p>{`${I18n.t('users.userStatusChange')}:`}</p>
            <p style={{ color }}>{text}</p>
            <Button
              onClick={() => {
                onClose();
                this.props.makeUsersInactiveReset();
              }}
            >
              {I18n.t('common.ok')}
            </Button>
          </div>
        );
      },
    });
    this.resetSelectedTargetGroup();
  };

  deactivateUsers = () =>
    PopupActions.showConfirm({
      text: I18n.t('users.changeUsersStatusConfirmation'),
      onConfirm: () =>
        this.props.changeUserStatus(
          [...this.state.selected.keys()],
          this.currentGroupId,
          true
        ),
    });

  activateUsers = (deactivateUsers) =>
    PopupActions.showConfirm({
      text: I18n.t('users.activateUsersConfirmation'),
      onConfirm: () =>
        this.props.changeUserStatus(
          [...this.state.selected.keys()],
          this.currentGroupId
        ),
    });

  deleteUsers = () =>
    PopupActions.showConfirm({
      text: I18n.t('users.deleteUsersConfirmation'),
      onConfirm: () => {
        this.props.deleteUsers([...this.state.selected.values()]);
        this.resetSelectedUsers();
      },
    });

  loadUsers = () => {
    const { loadUsers } = this.props;
    const parameters =
      this.currentGroupId !== 'create' &&
      this.currentGroupId !== ALL_USERS_GROUP_ID
        ? this.currentGroupId
        : undefined;
    if (!this.isEditActivity) {
      loadUsers(parameters);
    }
  };

  loadGroups = () => this.props.loadGroups();

  onDeleteGroupClick = () => {
    const { users } = this.props;
    if (notEmpty(users)) {
      PopupActions.showAlert({
        text: I18n.t('users.cannotDeleteGroupContainingUsersMessage'),
      });
    } else {
      PopupActions.showConfirm({
        text: I18n.t('users.deleteGroupConfirmation', {
          groupName: this.currentGroupId,
        }),
        onConfirm: () => this.props.deleteGroup(this.currentGroupId),
      });
    }
  };

  resetSelectedUsers = () =>
    this.tableRef.current?.toggleAllRowsSelected?.(false);

  resetSelectedTargetGroup = () =>
    this.setState(() => ({
      targetGroup: null,
    }));

  renderDropDownMenu = () =>
    this.hasReadWritePermissions && !this.isCurrentGroupIdAll ? (
      <DropDownMenu
        triggerButtonText={<Translate value="users.manage" />}
        items={[
          {
            customMenuItem: (
              <NavLink
                className="drop-down-menu__list-item"
                style={{
                  color: '#333',
                  textDecoration: 'none',
                }}
                to={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${this.currentGroupId}/users/create`}
              >
                {I18n.t('users.createUser')}
              </NavLink>
            ),
          },
          ...(!this.isCurrentGroupIdAll
            ? [
                {
                  customMenuItem: (
                    <NavLink
                      className="drop-down-menu__list-item"
                      style={{
                        color: '#333',
                        textDecoration: 'none',
                      }}
                      to={RouteHelpers.formatUrlWithQuery(
                        `${RootRoutes.USERS}${UsersRoutes.GROUPS}${
                          UsersRoutes.CREATE
                        }/${
                          this.isCurrentGroupIdAdmins
                            ? createGroupRouteConstants.USER_MANAGEMENT
                            : createGroupRouteConstants.PERMISSIONS
                        }`,
                        {
                          groupId: this.currentGroupId,
                        }
                      )}
                    >
                      {I18n.t('users.editGroup')}
                    </NavLink>
                  ),
                },
              ]
            : []),

          ...(!this.isCurrentGroupIdAdmins
            ? [
                {
                  customMenuItem: (
                    <div
                      className="drop-down-menu__list-item"
                      onClick={this.onDeleteGroupClick}
                    >
                      {I18n.t('users.deleteGroup')}
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    ) : null;

  render() {
    const showToolbar =
      this.hasReadWritePermissions &&
      this.state.selected.size &&
      this.state.showToolPanel;
    const contentClassName = showToolbar
      ? 'app__content app__content--with-toolbar'
      : 'app__content';
    return (
      <Fragment>
        <div className={contentClassName}>
          <div className={'page-content'}>
            {this.renderGroupsControls()}
            <div className="card-wrapper">
              <div className={'card'}>
                <DataTable
                  allColumns={this.usersTableColumns}
                  data={this.props.users}
                  loadingStatus={this.props.status}
                  onSelect={this.select}
                  pageId={Pages.USERS}
                  tableRef={this.tableRef}
                  selectable={this.hasReadWritePermissions}
                  sortable
                  useFilter
                  useCSVExport
                  useResizableColumns
                  allColumnsResizable
                  useFlex
                  headerTitle={
                    <div className={'card-header__title'}>
                      {this.renderGroupName(this.props.users)}
                    </div>
                  }
                  rightCustomHeaderAction={this.renderDropDownMenu()}
                />
              </div>
            </div>
          </div>
        </div>
        {this.hasReadWritePermissions ? this.renderToolsPanel() : null}
        {this.renderGroupSelectionDialog()}
        <Switch>
          {/*{this.renderCreateGroupRoute()}*/}
          {this.renderEditGroupRoute()}
          <Route
            exact
            path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/:groupId/users/create/:sectionId?`}
            component={NewUser}
          />
          <Route
            exact
            path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/:groupId/users/:username/:sectionId?`}
            component={ChangeUserDialog}
          />
        </Switch>
      </Fragment>
    );
  }

  renderCreateGroupRoute = () => {
    if (this.hasReadWritePermissions) {
      return (
        <Route
          exact
          path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/:sectionId?`}
          component={CreateUserGroup}
        />
      );
    }
  };

  renderEditGroupRoute = () => {
    if (this.hasReadWritePermissions) {
      return (
        <Route
          exact
          path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/:sectionId`}
          // path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}:activityType/:sectionId/:groupId`}
          component={CreateUserGroup}
        />
      );
    }
  };

  renderGroupSelectionDialog = () => {
    if (this.state.showMoveUsersToGroupDialog) {
      let { userGroups = [] } = this.props;
      return (
        <Modal
          onBack={this.onBack}
          title={I18n.t('users.chooseAUserGroup')}
          actionsSection={
            <Button onClick={this.onAssign} disabled={!this.state.targetGroup}>
              {I18n.t('users.move')}
            </Button>
          }
        >
          <section className="users-change-group-wrapper">
            <RadioButtonGroup
              checkedValue={this.state.targetGroup}
              options={userGroups.map((group) => ({
                ...group,
                label: group.name,
                value: group.id.toString(),
              }))}
              onChange={this.onTargetGroupChange}
              withColumnView
            />
          </section>
        </Modal>
      );
    }
  };

  renderToolsPanel = () => {
    if (this.state.selected.size && this.state.showToolPanel) {
      return (
        <ToolsPanel
          selectedItems={this.state.selected}
          title={I18n.t('users.usersSelected')}
        >
          <Tool
            title={I18n.t('users.changeUserGroup')}
            onClick={this.showMoveUsersToGroupDialog}
            hidden={!this.hasReadWritePermissions}
          >
            <UserGroup />
          </Tool>
          <Tool
            title={I18n.t('users.activateUsers')}
            onClick={this.activateUsers}
            hidden={!this.hasReadWritePermissions}
          >
            <ActiveUser className={'icon'} />
          </Tool>
          <Tool
            title={I18n.t('users.deactivateUsers')}
            onClick={this.deactivateUsers}
            hidden={!this.hasReadWritePermissions}
          >
            <InactiveUser className={'icon'} />
          </Tool>
          <Tool
            title={I18n.t('users.removeUsers')}
            onClick={this.deleteUsers}
            hidden={!this.hasReadWritePermissions}
          >
            <TrashEmpty className={'icon'} />
          </Tool>
        </ToolsPanel>
      );
    }
  };

  renderGroupName = (items) => {
    const { groupId } = this.props.match.params;
    const currentGroupName =
      groupId === ALL_USERS_GROUP_ID ? I18n.t('users.allUsers') : groupId;
    const postfix =
      this.props.status === NetworkStatus.DONE && items
        ? `(${items.length})`
        : '';
    return `${currentGroupName || ''} ${postfix}`;
  };

  renderGroupsControls = () => {
    const { userGroups = [], userGroupsStatus } = this.props;
    const groupNames = userGroups.map((group) => group.name);
    const allUsersGroups = getUserManagementDialogStructure({
      hasReadWritePermissions: this.hasReadWritePermissions,
      allUsersProps: {
        route: `${RootRoutes.USERS}${UsersRoutes.GROUPS}/${ALL_USERS_GROUP_ID}`,
        exact: false,
      },
      adminsGroupProps: {
        route: `${RootRoutes.USERS}${UsersRoutes.GROUPS}/${ADMINS_GROUP_ID}`,
        exact: false,
      },
      nonAdminGroupRoute: `${RootRoutes.USERS}${UsersRoutes.GROUPS}`,
      userGroups: groupNames,
    });
    return (
      <div className={'card card--left-hand'}>
        <div className={'left-hand-pane-card__header'}>
          {I18n.t('users.userGroups')}
          <ExportToCSV
            title={I18n.t('users.groupListCSVExportIconHint')}
            data={userGroups}
            columns={[
              {
                key: 'name',
                type: AttributeType.TEXT,
                titleCode: 'users.userGroups',
              },
            ]}
            fileName="user_groups.csv"
            disabled={!userGroups.length}
          />
        </div>
        <div
          className={
            'left-hand-pane-card__content left-hand-pane-card-content__groups-list'
          }
        >
          {userGroupsStatus === NetworkStatus.DONE ? (
            <DialogMenu structure={allUsersGroups} />
          ) : (
            ''
          )}
        </div>
        {this.hasReadWritePermissions ? (
          <NavLink
            className={'left-hand-pane-card__footer'}
            to={`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/${createGroupRouteConstants.PERMISSIONS}`}
          >
            <Button>
              {I18n.t('users.createAGroup')}{' '}
              <Glyphicon style={{ marginLeft: '10px' }} glyph="plus" />
            </Button>
          </NavLink>
        ) : null}
      </div>
    );
  };

  select = (selectedIds) => {
    const selected = new Set(selectedIds);
    this.setState(() => ({ selected }));
  };

  onTargetGroupChange = ({ target: { value } = {} }) =>
    this.setState(() => ({ targetGroup: value }));

  onAssign = () => {
    this.props.moveUsersToGroup(
      [...this.state.selected.keys()],
      this.currentGroupId,
      this.state.targetGroup
    );
    this.setState({
      showMoveUsersToGroupDialog: false,
      showToolPanel: true,
    });
  };

  showMoveUsersToGroupDialog = () =>
    this.setState({ showMoveUsersToGroupDialog: true, showToolPanel: false });

  onBack = () => {
    this.setState({
      showMoveUsersToGroupDialog: false,
      showToolPanel: true,
    });
    this.resetSelectedTargetGroup();
  };
}

const mapStateToProps = (state) => {
  const hasAdminPrivileges = UserHelpers.hasAdminPrivileges(state);
  const role = roleSelector(state);
  const isUserManagementEnabled = hasAdminPrivileges;

  return {
    userGroups: state.groups.userGroups,
    userGroupsStatus: state.groups.userGroupsStatus,
    users: state.users.data,
    status: state.users.status,
    deleteStatus: state.users.deleteStatus,
    deleteError: state.users.deleteError,
    moveStatus: state.users.moveStatus,
    moveError: state.users.moveError,
    makeUsersInactiveStatus: state.users.makeUsersInactiveStatus,
    makeUsersInactiveError: state.users.makeUsersInactiveError,
    error: state.users.error,
    modelLevelPermissionsData: state.groups.modelLevelPermissionsData,
    userGroupPermissions: state.users.userGroupPermissionsData,
    allCompanyFleets: allCompanyFleetsSelector(state),
    companyId: companyIdSelector(state),
    isUserManagementEnabled,
    role,
    hasAdminPrivileges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: (groupId) => dispatch(loadUsers(groupId)),
    loadGroups: () => dispatch(loadGroups()),
    deleteUsers: (users) => dispatch(deleteUsers(users)),
    deleteGroup: (groupId) => dispatch(deleteGroup(groupId)),
    moveUsersToGroup: (users, sourceGroup, targetGroup) =>
      dispatch(moveUsersToGroup(users, sourceGroup, targetGroup)),
    moveUsersReset: () => dispatch(moveUsersReset()),
    makeUsersInactiveReset: () => dispatch(makeUsersInactiveReset()),
    changeUserStatus: (users, groupId, deactivateUsers) =>
      dispatch(changeUserStatus(users, groupId, deactivateUsers)),
    loadCompanyFleetsFullList: () => dispatch(loadCompanyFleetsFullList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
