import React, { Component } from 'react';
import { connect } from 'react-redux';
import { companyIdSelector } from '../features/login/login.selectors';
import { loadStatistics } from '../redux/statistics.actions';
import { DEFAULT_DATE_FORMAT, NetworkStatus } from '../common/constants';
import { Translate, I18n } from 'react-redux-i18n';
import { ReactComponent as PhoneAndroidPrimary } from '../assets/images/phone_android_primary.svg';
import { ReactComponent as Plus } from '../assets/images/plus.svg';
import moment from 'moment';
import { ReactComponent as WarningCircleFilled } from '../assets/images/warning_circle_filled.svg';
import { ReactComponent as CrestCircle } from '../assets/images/crest-circle.svg';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  Cell,
} from 'recharts';
import { DeviceRoutes } from '../utils/routes';
import { NavLink } from 'react-router-dom';
import {
  generateHEXColor,
  isEmpty,
  selectForCurrentBrand,
} from '../common/helpers';
import { Throbber } from './Throbber';
import SSO from '../utils/SSO';
import UserHelpers from '../common/utilities/user';
import NoData from './NoData/NoData.component';

export const StatisticsUnits = {
  HOURS: 'hour',
  DAYS: 'day',
  WEEKS: 'week',
  MONTHS: 'month',
};

export const StatisticsOptions = [
  {
    titleCode: 'statistics.lastDay',
    unit: StatisticsUnits.HOURS,
    quantity: 24,
  },
  {
    titleCode: 'statistics.week',
    unit: StatisticsUnits.DAYS,
    quantity: 7,
  },
  {
    titleCode: 'statistics.month',
    unit: StatisticsUnits.DAYS,
    quantity: 30,
  },
];

const getInfoCardsFirstRow = ({ online, lowBattery, active } = {}) => [
  {
    value: online,
    valueColor: '#56d9fe',
    title: 'statistics.enrolledDevices',
    key: 'statistics.enrolledDevices',
  },
  {
    value: lowBattery,
    valueColor: '#ff5353',
    title: 'statistics.inLowBattery',
    key: 'statistics.inLowBattery',
    link: `${DeviceRoutes}?batteryLevel[LT]=15&strict=1`,
  },
  {
    value: active,
    valueColor: '#16c98d',
    title: 'statistics.recentlyActive',
    key: 'statistics.recentlyActive',
    link: `${DeviceRoutes}?strict=1&customValueKey=lastCheckin&filterValueType=days&status[LTE]=1`,
  },
];

const getInfoCardsSecondRow = ({ online, android, linux, compliant } = {}) => [
  {
    value: android != null ? android : 0,
    valueColor: '#b4b4c6',
    title: 'statistics.androidDevices',
    key: 'statistics.androidDevices',
  },
  {
    value: linux != null ? linux : 0,
    valueColor: '#b4b4c6',
    title: 'statistics.linuxDevices',
    key: 'statistics.linuxDevices',
  },
  {
    value:
      !isNaN(Number(online)) && !isNaN(Number(compliant))
        ? Number(online) - Number(compliant)
        : '',
    valueColor: '#ff5353',
    title: 'statistics.nonCompliantDevices',
    key: 'statistics.nonCompliantDevices',
    link: `${DeviceRoutes}?compliance[EQ]=not_compliant_version&strict=1`,
  },
];

class Dashboard extends Component {
  state = {
    selectedOption: 0,
  };

  componentDidMount() {
    this.handleSSORedirect();
    this.props.loadStatistics(
      this.props.companyId,
      StatisticsOptions[this.state.selectedOption].unit,
      StatisticsOptions[this.state.selectedOption].quantity
    );
  }

  handleSSORedirect = () => {
    let { location: { state: routerState } = {} } = this.props;
    if (routerState?.isSSORedirect) {
      this.props.handleSSOProviderRedirect(routerState?.requestUrl ?? '');
    }
  };

  renderInfoCards = (infoCards) => {
    const { hasAdminPrivileges } = this.props;
    return infoCards.map(({ link, value, valueColor, title }) => {
      const content = (
        <div className="dashboard-row--item">
          {/*<div*/}
          {/*  className={'dashboard-row-card-content-icon-container'}*/}
          {/*  style={{*/}
          {/*    border: `1px solid ${selectForCurrentBrand({*/}
          {/*      original: '#003562',*/}
          {/*      incube: '#1a1e35',*/}
          {/*      janam: '#ff7600',*/}
          {/*    })}`,*/}
          {/*    color: selectForCurrentBrand({*/}
          {/*      original: '#003562',*/}
          {/*      incube: '#1a1e35',*/}
          {/*      janam: '#ff7600',*/}
          {/*    }),*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <PhoneAndroidPrimary*/}
          {/*    className={'dashboard-row-card-content-icon-container__icon'}*/}
          {/*  />*/}
          {/*</div>*/}
          <div
            className={'dashboard-row-card-content-detail__value'}
            style={{
              color: valueColor,
            }}
          >
            {value || '0'}
          </div>
          <Translate value={title} />
        </div>
      );

      if (link && hasAdminPrivileges) {
        return (
          <NavLink
            to={link}
            className={'card dashboard-row-card dashboard-row-card--hoverable'}
          >
            {content}
          </NavLink>
        );
      }

      return <div className={'card dashboard-row-card'}>{content}</div>;
    });
  };

  render() {
    const { statistics, status } = this.props;
    if (status === NetworkStatus.STARTED) {
      return (
        <div className={'app__content'}>
          <div className={'page-content'}>
            <div className={'dashboard'}>
              <Throbber />
            </div>
          </div>
        </div>
      );
    }

    if (isEmpty(statistics)) {
      return (
        <div className={'app__content'}>
          <div className={'page-content'} style={{ overflow: 'auto' }}>
            <NoData />
          </div>
        </div>
      );
    }

    const infoCardsFirstRow = getInfoCardsFirstRow(statistics);
    // const infoCardsSecondRow = getInfoCardsSecondRow(statistics);

    return (
      <div className={'app__content'}>
        <div className={'page-content'} style={{ overflow: 'auto' }}>
          <div className={'dashboard'}>
            <div className={'dashboard-row'}>
              {this.renderInfoCards(infoCardsFirstRow)}
            </div>
            {/*<div className={'dashboard-row'}>*/}
            {/*  {this.renderInfoCards(infoCardsSecondRow)}*/}
            {/*</div>*/}

            {/*<div className={'card card--horizontal dashboard-statistics'}>*/}
            {/*<div*/}
            {/*  style={{*/}
            {/*    width: '70%',*/}
            {/*    display: 'flex',*/}
            {/*    height: '100%',*/}
            {/*    flxDirection: 'column',*/}
            {/*    minWidth: '260px',*/}
            {/*  }}*/}
            {/*>*/}
            {/*<div*/}
            {/*  style={{*/}
            {/*    display: 'flex',*/}
            {/*    width: '100%',*/}
            {/*    flexDirection: 'column',*/}
            {/*  }}*/}
            {/*>*/}
            {/*<div*/}
            {/*  style={{*/}
            {/*    color: '#4d4f5c',*/}
            {/*    fontSize: '21px',*/}
            {/*    display: 'inline-flex',*/}
            {/*    flexWrap: 'wrap',*/}
            {/*    padding: '20px 0',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Translate value={'statistics.onlineDevicesPast'} />*/}
            {/*  &nbsp;*/}
            {/*  <Translate*/}
            {/*    value={*/}
            {/*      StatisticsOptions[this.state.selectedOption].titleCode*/}
            {/*    }*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div style={{*/}
            {/*    margin: '10px 0',*/}
            {/*    display: 'flex',*/}
            {/*    flexWrap: 'nowrap',*/}
            {/*    alignItems: 'center'*/}
            {/*}}>*/}
            {/*    <label*/}
            {/*        style={{*/}
            {/*            width: '18px',*/}
            {/*            height: '10px',*/}
            {/*            borderRadius: '5px',*/}
            {/*            backgroundColor: '#16c98d',*/}
            {/*            margin: '0 10px 0 0',*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <Translate value={'statistics.online'}/>*/}
            {/*</div>*/}
            {/*<select*/}
            {/*  className="form-control"*/}
            {/*  value={this.state.selectedOption}*/}
            {/*  onChange={this.onChange}*/}
            {/*  style={{*/}
            {/*    minWidth: '120px',*/}
            {/*    width: '20%',*/}
            {/*    maxWidth: '200px',*/}
            {/*    height: '30px',*/}
            {/*    padding: '0 12px',*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <option value={0}>{`${I18n.t('statistics.last')} ${I18n.t(*/}
            {/*    StatisticsOptions[0].titleCode*/}
            {/*  )}`}</option>*/}
            {/*  <option value={1}>{`${I18n.t('statistics.last')} ${I18n.t(*/}
            {/*    StatisticsOptions[1].titleCode*/}
            {/*  )}`}</option>*/}
            {/*  <option value={2}>{`${I18n.t('statistics.last')} ${I18n.t(*/}
            {/*    StatisticsOptions[2].titleCode*/}
            {/*  )}`}</option>*/}
            {/*</select>*/}
            {/*<ResponsiveContainer*/}
            {/*  width="100%"*/}
            {/*  height="100%"*/}
            {/*  maxHeight={'calc(100% - 86px)'}*/}
            {/*>*/}
            {/*  {this.props.statistics.prolonged.active.quantity &&*/}
            {/*  this.props.statistics.prolonged.active.quantity.length ? (*/}
            {/*    <LineChart*/}
            {/*      height={300}*/}
            {/*      data={this.props.statistics.prolonged.active.quantity.map(*/}
            {/*        (y, index) => {*/}
            {/*          let format =*/}
            {/*            StatisticsOptions[this.state.selectedOption]*/}
            {/*              .unit === StatisticsUnits.HOURS*/}
            {/*              ? 'HH:mm A'*/}
            {/*              : StatisticsOptions[this.state.selectedOption]*/}
            {/*                  .unit === StatisticsUnits.DAYS*/}
            {/*              ? 'DD ddd MMM'*/}
            {/*              : StatisticsOptions[this.state.selectedOption]*/}
            {/*                  .unit === StatisticsUnits.WEEKS*/}
            {/*              ? 'DD ddd MMM'*/}
            {/*              : 'DD-MMM-YY';*/}
            {/*          let res = {*/}
            {/*            x: moment(*/}
            {/*              this.props.statistics.prolonged.active.start*/}
            {/*            )*/}
            {/*              .add(*/}
            {/*                index,*/}
            {/*                StatisticsOptions[this.state.selectedOption]*/}
            {/*                  .unit*/}
            {/*              )*/}
            {/*              .format(format),*/}
            {/*            pv: y,*/}
            {/*          };*/}
            {/*          return res;*/}
            {/*        }*/}
            {/*      )}*/}
            {/*      margin={{ left: -30, bottom: 10, top: 10, right: 0 }}*/}
            {/*    >*/}
            {/*      <XAxis dataKey="x" />*/}
            {/*      <YAxis*/}
            {/*        allowDecimals={false}*/}
            {/*        type={'number'}*/}
            {/*        minTickGap={1}*/}
            {/*        tickSize={1}*/}
            {/*        interval={1}*/}
            {/*      />*/}
            {/*      <CartesianGrid />*/}
            {/*      <Line*/}
            {/*        type="monotone"*/}
            {/*        dataKey="pv"*/}
            {/*        stroke="#16c98d"*/}
            {/*        dot={false}*/}
            {/*      />*/}
            {/*    </LineChart>*/}
            {/*  ) : (*/}
            {/*    <NoData />*/}
            {/*  )}*/}
            {/*</ResponsiveContainer>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*<div className={'dashboard-statistics-details'}>*/}
            {/*<Translate*/}
            {/*  className={'dashboard-statistics-details__title'}*/}
            {/*  value={'statistics.quickDetails'}*/}
            {/*/>*/}
            {/*<div className={'dashboard-statistics-details-item'}>*/}
            {/*  <div className={'dashboard-statistics-details-item-value'}>*/}
            {/*    <div*/}
            {/*      className={*/}
            {/*        'dashboard-statistics-details-item-value-icon-container'*/}
            {/*      }*/}
            {/*      style={{*/}
            {/*        backgroundColor: '#e8e7ff',*/}
            {/*        color: '#8280ff',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <Plus*/}
            {/*        style={{ maxWidth: '14px', maxHeight: '14px' }}*/}
            {/*        className={*/}
            {/*          'dashboard-statistics-details-item-value-icon-container__icon'*/}
            {/*        }*/}
            {/*      />*/}
            {/*    </div>*/}

            {/*    <Translate*/}
            {/*      value={'statistics.numberNewDevices'}*/}
            {/*      number={*/}
            {/*        !isNaN(*/}
            {/*          Number(this.props.statistics.prolonged.enrolled)*/}
            {/*        ) &&*/}
            {/*        Number(this.props.statistics.prolonged.enrolled) >= 0*/}
            {/*          ? Number(this.props.statistics.prolonged.enrolled)*/}
            {/*          : 0*/}
            {/*      }*/}
            {/*      className={*/}
            {/*        'dashboard-statistics-details-item-value__value'*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className={'dashboard-statistics-details-item__hint'}>*/}
            {/*    <Translate*/}
            {/*      value={*/}
            {/*        StatisticsOptions[this.state.selectedOption].titleCode*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*    style={{*/}
            {/*        width: '100%',*/}
            {/*        color: '#4d4f5c',*/}
            {/*        fontSize: '18px',*/}
            {/*        padding: '20px 0',*/}
            {/*        borderBottom: '1px solid rgba(0, 0, 0, 0.04)',*/}
            {/*        display: 'flex',*/}
            {/*        alignItems: 'center',*/}
            {/*        justifyContent: 'space-between'*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
            {/*    <div*/}
            {/*        style={{*/}
            {/*            minWidth: '38px',*/}
            {/*            height: '38px',*/}
            {/*            backgroundColor: 'rgba(22, 201, 141, 0.33)',*/}
            {/*            display: 'flex',*/}
            {/*            alignItems: 'center',*/}
            {/*            justifyContent: 'center',*/}
            {/*            overflow: 'hidden',*/}
            {/*            borderRadius: '50%',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <img alt={''} src={RefreshSuccess}*/}
            {/*             style={{maxWidth: '16px', maxHeight: '16px', width: 'auto'}}/>*/}
            {/*    </div>*/}
            {/*       */}
            {/*            <Translate value={'statistics.numberTasks'} number={this.props.statistics.prolonged.tasksInstalled || 0}  style={{marginLeft: '10px'}}/>*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        style={{*/}
            {/*            color: '#4d4f5c',*/}
            {/*            fontSize: '13px'*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Translate value={'statistics.installing'}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*    style={{*/}
            {/*        width: '100%',*/}
            {/*        color: '#4d4f5c',*/}
            {/*        fontSize: '18px',*/}
            {/*       rd padding: '20px 0',*/}
            {/*        borderBottom: '1px solid rgba(0, 0, 0, 0.04)',*/}
            {/*        display: 'flex',*/}
            {/*        alignItems: 'center',*/}
            {/*        justifyContent: 'space-between'*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
            {/*    <div*/}
            {/*        style={{*/}
            {/*            minWidth: '38px',*/}
            {/*            height: '38px',*/}
            {/*            backgroundColor: '#f0f0f7',*/}
            {/*            display: 'flex',*/}
            {/*            alignItems: 'center',*/}
            {/*            justifyContent: 'center',*/}
            {/*            overflow: 'hidden',*/}
            {/*            borderRadius: '50%',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <img alt={''} src={TimerInactive}*/}
            {/*             style={{maxWidth: '16px', maxHeight: '16px', width: 'auto'}}/>*/}
            {/*    </div>*/}
            {/*      */}
            {/*            <Translate value={'statistics.numberDevices'} number={this.props.statistics.prolonged.enrolled || 0}  style={{marginLeft: '10px'}}/>*/}
            {/*        */}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        style={{*/}
            {/*            color: '#4d4f5c',*/}
            {/*            fontSize: '13px'*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Translate value={'statistics.enrolling'}/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className={'dashboard-statistics-details-item'}>*/}
            {/*  <div className={'dashboard-statistics-details-item-value'}>*/}
            {/*    <div*/}
            {/*      className={*/}
            {/*        'dashboard-statistics-details-item-value-icon-container'*/}
            {/*      }*/}
            {/*      style={{*/}
            {/*        backgroundColor: 'rgba(255, 223, 90, 0.44)',*/}
            {/*        color: 'rgb(255,223,90)',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <WarningCircleFilled*/}
            {/*        className={*/}
            {/*          'dashboard-statistics-details-item-value-icon-container__icon'*/}
            {/*        }*/}
            {/*      />*/}
            {/*    </div>*/}

            {/*    <Translate*/}
            {/*      value={'statistics.numberDevices'}*/}
            {/*      number={this.props.statistics.prolonged.lowBattery || 0}*/}
            {/*      className={*/}
            {/*        'dashboard-statistics-details-item-value__value'*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className={'dashboard-statistics-details-item__hint'}>*/}
            {/*    <Translate value={'statistics.lowBattery'} />*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className={'dashboard-statistics-details-item'}>*/}
            {/*  <div className={'dashboard-statistics-details-item-value'}>*/}
            {/*    <div*/}
            {/*      className={*/}
            {/*        'dashboard-statistics-details-item-value-icon-container'*/}
            {/*      }*/}
            {/*      style={{*/}
            {/*        backgroundColor: 'rgba(255, 83, 83, 0.44)',*/}
            {/*        color: 'rgb(255,83,83)',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <CrestCircle*/}
            {/*        className={*/}
            {/*          'dashboard-statistics-details-item-value-icon-container__icon icon--danger'*/}
            {/*        }*/}
            {/*      />*/}
            {/*    </div>*/}

            {/*    <Translate*/}
            {/*      value={'statistics.numberDevices'}*/}
            {/*      number={this.props.statistics.prolonged.failed || 0}*/}
            {/*      className={*/}
            {/*        'dashboard-statistics-details-item-value__value'*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className={'dashboard-statistics-details-item__hint'}>*/}
            {/*    <Translate value={'statistics.failedEnrollments'} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            <div
              className={'card card--horizontal'}
              style={{
                padding: '0 20px',
                display: 'flex',
                width: '100%',
                minWidth: '640px',
                height: '100%',
                maxHeight: '450px',
                overflowY: 'hidden',
                overflowX: 'auto',
              }}
            >
              <div
                style={{
                  width: '33.33%',
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  minWidth: '260px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      color: '#4d4f5c',
                      fontSize: '21px',
                      display: 'inline-flex',
                      flexWrap: 'wrap',
                      padding: '20px 0px 20px 0',
                    }}
                  >
                    <Translate value={'statistics.lastConnectDates'} />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    minHeight: '190px',
                    flex: 1,
                  }}
                  // maxHeight={"calc(100% - 86px)"}
                >
                  {this.props.statistics.lastCheckin &&
                  this.props.statistics.lastCheckin.length ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={200}
                        height={160}
                        maxHeight="100%"
                        data={this.props.statistics.lastCheckin.map(
                          (item, index) => {
                            let res = {
                              x: moment(item.last_checkin).format(
                                DEFAULT_DATE_FORMAT
                              ),
                              uv: item.quantity,
                            };
                            return res;
                          }
                        )}
                        margin={{ left: -30, bottom: 10, top: 10, right: 30 }}
                      >
                        <XAxis
                          dataKey="x"
                          height={65}
                          textAnchor="start"
                          interval={2}
                          angle={30}
                          dx={0}
                        />
                        <Tooltip />
                        <YAxis />
                        <CartesianGrid />
                        <Bar
                          dataKey="uv"
                          fill="#16c98d"
                          name={I18n.t('statistics.connections')}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
              <div
                style={{
                  width: '33.33%',
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  minWidth: '260px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      color: '#4d4f5c',
                      fontSize: '21px',
                      display: 'inline-flex',
                      flexWrap: 'wrap',
                      padding: '20px 0px 20px 0',
                    }}
                  >
                    <Translate value={'statistics.manufacturer'} />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    minHeight: '190px',
                    flex: 1,
                  }}
                  // maxHeight={"calc(100% - 86px)"}
                >
                  {this.props.statistics.manufacturer &&
                  this.props.statistics.manufacturer.length ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={200}
                        height={190}
                        maxHeight="100%"
                        data={this.props.statistics.manufacturer || []}
                        margin={{ left: -30, bottom: 10, top: 10, right: 30 }}
                      >
                        <XAxis
                          dataKey="manufacturer"
                          height={65}
                          textAnchor="start"
                          interval={0}
                          angle={30}
                          dx={0}
                        />
                        <Tooltip />
                        <YAxis />
                        <CartesianGrid />
                        <Bar
                          dataKey="quantity"
                          fill="#16c98d"
                          name={I18n.t('statistics.devices')}
                        >
                          {(this.props.statistics.manufacturer || []).map(
                            (entry, index) => (
                              <Cell
                                cursor="pointer"
                                fill={generateHEXColor()}
                                key={`cell-${index}`}
                                onClick={() => {}}
                              />
                            )
                          )}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
              <div
                style={{
                  width: '33.33%',
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  minWidth: '260px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      color: '#4d4f5c',
                      fontSize: '21px',
                      display: 'inline-flex',
                      flexWrap: 'wrap',
                      padding: '20px 0px 20px 0',
                    }}
                  >
                    <Translate value={'statistics.deviceFamilyOSVersion'} />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    minHeight: '190px',
                    flex: 1,
                  }}
                  // maxHeight={"calc(100% - 86px)"}
                >
                  {this.props.statistics.os &&
                  this.props.statistics.os.length ? (
                    <ResponsiveContainer width={'100%'} height="100%">
                      <BarChart
                        width={200}
                        height={190}
                        data={this.props.statistics.os || []}
                        maxHeight="100%"
                        margin={{ left: -30, bottom: 10, top: 10, right: 30 }}
                      >
                        <XAxis
                          dataKey={'os_version'}
                          height={65}
                          textAnchor="start"
                          interval={0}
                          angle={30}
                          dx={0}
                        />
                        <Tooltip cursor={{ fill: '#16c98d', opacity: '0.4' }} />
                        <YAxis />
                        <CartesianGrid />
                        <Bar
                          dataKey="quantity"
                          fill="#16c98d"
                          name={'Devices'}
                          onClick={() => console.log('soon')}
                        >
                          {(this.props.statistics.os || []).map(
                            (entry, index) => (
                              <Cell
                                cursor="pointer"
                                fill={generateHEXColor()}
                                key={`cell-${index}`}
                              />
                            )
                          )}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChange = ({ target: { value } }) => {
    this.props.loadStatistics(
      this.props.companyId,
      StatisticsOptions[Number(value)].unit,
      StatisticsOptions[Number(value)].quantity
    );
    this.setState({ selectedOption: Number(value) });
  };
}

const mapStateToProps = (state) => {
  return {
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    companyId: companyIdSelector(state),
    statistics: state.statistics.data,
    status: state.statistics.status,
    error: state.statistics.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSSOProviderRedirect: (requestUrl) =>
      dispatch(SSO.handleSSOProviderRedirect(requestUrl)),
    loadStatistics: (companyId, unit, quantity) =>
      dispatch(loadStatistics(companyId, unit, quantity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
