import { I18n } from 'react-redux-i18n';
import client from '../../utils/client';
import services from '../../utils/services';
import {
  loadTaskDevicesFailed,
  loadTaskDevicesStarted,
  loadTaskFailed,
  loadTaskStarted,
  taskDevicesLoaded,
  taskLoaded,
} from './task.actions';
import { deserializeTask } from '../tasks/tasks.serializer';

export function loadTask(id, initial) {
  return async (dispatch) => {
    try {
      dispatch(loadTaskStarted(initial));
      let response = await client.get(`${services.tasks}/${id}`);
      if (response.status === 200 && response.data) {
        let result = deserializeTask(response.data);
        dispatch(taskLoaded(result));
      } else {
        dispatch(loadTaskFailed(I18n.t('installationStatus.failedToLoadTask')));
      }
    } catch (error) {
      dispatch(loadTaskFailed(I18n.t('installationStatus.failedToLoadTask')));
    }
  };
}

export function loadTaskDevices(dataFailed, dataSuccess) {
  return async (dispatch) => {
    try {
      dispatch(loadTaskDevicesStarted());
      let responseFailed = await client.post(`devices_detail_info/`, {
        devices_id: dataFailed,
      });
      let responseSuccess = await client.post(`devices_detail_info/`, {
        devices_id: dataSuccess,
      });
      if (
        responseFailed.status === 200 &&
        responseFailed.data &&
        responseSuccess.status === 200 &&
        responseSuccess.data
      ) {
        let resultFailed;
        let resultSuccess;
        if (typeof responseFailed.data === 'object') {
          resultFailed = responseFailed.data.map((device) => ({
            name: device.device_name,
            lastUpdated: device.last_updated,
            fleet: device.fleet_name,
          }));
        } else {
          resultFailed = [];
        }
        if (typeof responseSuccess.data === 'object') {
          resultSuccess = responseSuccess.data.map((device) => ({
            name: device.device_name,
            lastUpdated: device.last_updated,
            fleet: device.fleet_name,
          }));
        } else {
          resultSuccess = [];
        }

        dispatch(
          taskDevicesLoaded({
            devicesInstalled: resultSuccess,
            devicesFailed: resultFailed,
          })
        );
      } else {
        dispatch(loadTaskDevicesFailed());
      }
    } catch (error) {
      dispatch(
        loadTaskDevicesFailed(
          I18n.t('installationStatus.failedToLoadTaskDevices')
        )
      );
    }
  };
}
