let JANAM_DOMAIN = '.janam.com'; // 'beta.springdel.com'; //
let INCUBE_DOMAIN = '.e-incube.com'; // 'dev2.springdel.com'; //
export const hostname = window.location.hostname;
const devServerHost = 'dev.springdel.com';

export const ANTIQUE_ENVIRONMENTS = [
  'ping.e-incube.com',
  'mdm.janam.com',
  'uem.springdel.com',
  'qa-antique.springdel.com',
  'sandbox.janam.com',
];

const OCHE_S_LOCAL_DEV_SERVER = '192.168.50.44';

export const DEV_ENVIRONMENTS = [
  'dev.springdel.com',
  'localhost',
  OCHE_S_LOCAL_DEV_SERVER,
];

export const DEV_AND_QA_ENVIRONMENTS = [
  ...DEV_ENVIRONMENTS,
  'qa.springdel.com',
  'sandbox.janam.com', // janam QA
];

const isDevelopmentEnvironment = process.env.NODE_ENV === 'development';

export const isJanamBrand = hostname.endsWith(JANAM_DOMAIN);
export const isIncubeBrand = hostname.endsWith(INCUBE_DOMAIN);

export const currentBrand = isJanamBrand
  ? 'janam'
  : isIncubeBrand
  ? 'incube'
  : 'original';

let port = window.location.port;
let host = hostname;
let proto = window.location.protocol;
let wsHost = host;
let wsProto = 'wss:';
let wsPort = ':5000';
let rcPort = ':3010';
let bucketName;
let region;

if (process.env.NODE_ENV === 'production') {
  port = '5000';
  region = 'ap-southeast-1';
  bucketName = 'springdel-qa-bucket';
  if (host === 'uem.springdel.com') {
    bucketName = 'springdel-prod-bucket';
  }
  if (proto === 'https:') {
    wsProto = 'wss:';
  }
}

if (process.env.NODE_ENV === 'test') {
  if (proto === 'https:') {
    port = '5443';
    wsPort = ':5443';
    wsProto = 'wss:';
  }
}

if (process.env.NODE_ENV === 'development') {
  rcPort = '';
  wsHost = devServerHost;
  wsPort = '';
  wsProto = 'wss:';
}

export const getProtocol = () => {
  return proto;
};

export const getHost = () => {
  return host;
};

export const getWebSocketHost = () =>
  isDevelopmentEnvironment ? devServerHost : getHost();

export const getPort = () => {
  return port;
};

export const getRcPort = () => {
  return rcPort;
};

export const getWebsocketProto = () => {
  return wsProto;
};

export const getWebsocketHost = () => {
  return wsHost;
};

export const getWebsocketPort = () => {
  return wsPort;
};

export const getBucketName = () => {
  return bucketName;
};

export const getRegion = () => {
  return region;
};
