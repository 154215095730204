import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CreateEnrollment from '../features/createEnrollment/CreateEnrollment';
import _Fleets from './__Fleets';
import Enrollments from '../features/enrollments/enrollments.component';
import AllDevices from '../features/devices/devices.component';
import NotFound from '../components/NotFound';
import {
  DeviceRoutes,
  DevicesRoutes,
  FleetRoute,
  FleetV1Route,
  RootRoutes,
} from '../utils/routes';
import { CreateEnrollmentRoute } from '../features/createEnrollment/CreateEnrollment.constants';
import FleetsV2 from '../features/fleets/fleetsV2.component';
import Device from '../features/device/device.component';

const Devices = (props) => (
  <Switch>
    <Redirect exact from={RootRoutes.DEVICES} to={`${FleetRoute}`} />
    <Route exact path={`${DeviceRoutes}`} component={AllDevices} />
    <Route path={`${DeviceRoutes}/:deviceId`} component={Device} />
    <Route path={`${FleetV1Route}`} component={_Fleets} />
    <Route exact path={`${FleetRoute}/:childRoute?`} component={FleetsV2} />
    <Route path={`${FleetRoute}`} component={FleetsV2} />
    <Route
      exact
      path={`${CreateEnrollmentRoute}/:step?`}
      component={CreateEnrollment}
    />
    <Route
      exact
      path={`${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/:childRoute?`}
      component={Enrollments}
    />
    <Route component={NotFound} />
  </Switch>
);

export default Devices;
