import React from 'react';
import { selectForCurrentBrand } from '../common/helpers';
import { NavLink } from 'react-router-dom';
import StyleUtils from '../utils/styleUtils';

export class Button extends React.Component {
  render() {
    const {
      className = '',
      children,
      primary,
      secondary,
      onClick,
      path,
      ...rest
    } = this.props;

    const buttonClass = selectForCurrentBrand({
      original: 'button--primary',
      janam: 'button--janam--primary',
      incube: 'button--incube--primary',
    });
    const secondaryButtonClass = selectForCurrentBrand({
      original: 'button--secondary',
      janam: 'button--janam--secondary',
      incube: 'button--incube--secondary',
    });

    const mainClass = secondary ? secondaryButtonClass : buttonClass;

    const classNames = StyleUtils.mergeClasses('button', mainClass, className);

    if (path) {
      return (
        <NavLink to={path}>
          <button className={classNames} {...rest}>
            {children}
          </button>
        </NavLink>
      );
    }
    return (
      <button className={classNames} onClick={onClick} {...rest}>
        {children}
      </button>
    );
  }
}
