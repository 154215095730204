export const GROUPS_LOAD_STARTED = 'GROUPS_LOAD_STARTED';
export const GROUPS_LOAD_FAILED = 'GROUPS_LOAD_FAILED';
export const GROUPS_LOAD_SUCCESS = 'GROUPS_LOAD_SUCCESS';

export const GROUP_CREATE_STARTED = 'GROUPS_CREATE_STARTED';
export const GROUP_CREATE_FAILED = 'GROUPS_CREATE_FAILED';
export const GROUP_CREATE_SUCCESS = 'GROUPS_CREATE_SUCCESS';
export const GROUP_CREATE_RESET = 'GROUP_CREATE_RESET';

export const GROUP_EDIT_STARTED = 'GROUP_EDIT_STARTED';
export const GROUP_EDIT_FAILED = 'GROUP_EDIT_FAILED';
export const GROUP_EDIT_SUCCESS = 'GROUP_EDIT_SUCCESS';

export const GROUP_DELETE_STARTED = 'GROUP_DELETE_STARTED';
export const GROUP_DELETE_FAILED = 'GROUP_DELETE_FAILED';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_RESET = 'GROUP_DELETE_RESET';

export const GROUPS_PERMISSIONS_LOAD_STARTED =
  'GROUPS_PERMISSIONS_LOAD_STARTED';
export const GROUPS_PERMISSIONS_LOAD_FAILED = 'GROUPS_PERMISSIONS_LOAD_FAILED';
export const GROUPS_PERMISSIONS_LOAD_SUCCESS =
  'GROUPS_PERMISSIONS_LOAD_SUCCESS';

export const ALL_USERS_LOAD_STARTED = 'ALL_USERS_LOAD_STARTED';
export const ALL_USERS_LOAD_FAILED = 'ALL_USERS_LOAD_FAILED';
export const ALL_USERS_LOAD_SUCCESS = 'ALL_USERS_LOAD_SUCCESS';
export const ALL_USERS_LOAD_RESET = 'ALL_USERS_LOAD_RESET';

export const COMPANY_FLEETS_FULL_LIST_LOAD_STARTED =
  'COMPANY_FLEETS_FULL_LIST_LOAD_STARTED';
export const COMPANY_FLEETS_FULL_LIST_LOAD_FAILED =
  'COMPANY_FLEETS_FULL_LIST_LOAD_FAILED';
export const COMPANY_FLEETS_FULL_LIST_LOAD_SUCCESS =
  'COMPANY_FLEETS_FULL_LIST_LOAD_SUCCESS';
