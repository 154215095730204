import { AttributeType } from '../../common/constants';
import { filterConditions } from '../../constants/filter';

export const certificatesColumns = [
  {
    key: 'filename',
    type: AttributeType.TEXT,
    titleCode: 'certificates.filename',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'uploadedByName',
    titleKey: 'uploadedByEmail',
    type: AttributeType.TEXT,
    titleCode: 'certificates.uploadedBy',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'uploadedOn',
    type: AttributeType.DATE,
    titleCode: 'certificates.uploadedOn',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'certificates.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
];
