import { AttributeType } from '../../common/constants';
import { ApplicationsRoutes, RootRoutes } from '../../utils/routes';
import { I18n } from 'react-redux-i18n';
import { filterConditions } from '../../constants/filter';

export const applicationsColumns = [
  {
    key: 'idName',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.packageId',
    searchable: true,
    ref: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}`,
    refId: 'id',
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.applicationFileName',
    sortable: true,
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'label',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.label',
    sortable: true,
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'version',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.latestVersion',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'uploadedByName',
    titleKey: 'uploadedByEmail',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.uploadedBy',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'uploadedOn',
    type: AttributeType.DATE,
    titleCode: 'applications.fields.uploadedOn',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'versionCode',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.latestVersionCode',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'applications.fields.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'size',
    type: AttributeType.SIZE,
    titleCode: 'applications.fields.size',
    sortable: false,
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  // {
  //     key: 'status',
  //     type: AttributeType.TEXT,
  //     titleCode: 'applications.fields.status',
  //     searchable: true,
  //     availableFilterConditions: [
  //        filterConditions.CONTAINS,
  //        filterConditions.EQUAL,
  //        filterConditions.NOT_EQUAL
  //     ]
  // },
  {
    key: 'installOnly',
    type: AttributeType.BOOLEAN,
    titleCode: 'applications.fields.installOnly',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
];

export const applicationAttributes = [
  //{name: "Application Name", codeName: "app_name", editable: false },
  {
    name: I18n.t('applications.fields.packageId'),
    codeName: 'idName',
    editable: false,
  },
  {
    name: I18n.t('applications.fields.applicationFileName'),
    codeName: 'name',
    editable: false,
  },
  {
    name: I18n.t('applications.fields.label'),
    codeName: 'label',
    editable: false,
  },
  {
    name: I18n.t('applications.fields.version'),
    codeName: 'version',
    editable: false,
  },
  {
    name: I18n.t('applications.fields.versionCode'),
    codeName: 'versionCode',
    editable: false,
  },

  // {name: "Required Mininum OS", codeName: null },
  // {name: "Category", codeName: null },
  // {name: "Latest Version Available", codeName: "latest_version" },
  { name: I18n.t('applications.fields.lastUpdated'), codeName: 'lastUpdated' },
  { name: I18n.t('applications.fields.size'), codeName: 'size' },
  { name: I18n.t('applications.fields.installOnly'), codeName: 'installOnly' },
  //{name: "Permission", codeName: null }
];
