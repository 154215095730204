import React from 'react';
import PropTypes from 'prop-types';
import {
  DevicesRoutes,
  ProfilesRoutes,
  RootRoutes,
  ApplicationsRoutes,
  SettingsRoutes,
  HomeRoutes,
  UsersRoutes,
  AlertRoutes,
  APIRoutes,
} from '../utils/routes';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router';
import { isDevServer, selectForCurrentBrand } from '../common/helpers';
import { GLOBAL_READ_READWRITE_PERMISSIONS } from '../constants/globalPermissions';
import { ACCESS_TYPES } from '../constants/accessTypes';
import { getAccessFromGlobalPermissionsForUser } from '../common/utilities/users';

const breadcrumbs = new Map()
  .set(RootRoutes.PROFILES, [
    { route: ProfilesRoutes.ALL, titleCode: 'routes.allProfiles' },
    { route: ProfilesRoutes.ALL_V2, titleCode: 'routes.allProfilesV2' },
    { route: ProfilesRoutes.TASKS, titleCode: 'routes.tasks' },
  ])
  .set(RootRoutes.DEVICES, [
    // { route: DevicesRoutes.FLEETS, titleCode: 'routes.myFleets' },
    { route: DevicesRoutes.FLEETS_V2, titleCode: 'routes.myFleetsV2' },
    { route: DevicesRoutes.ENROLLMENTS, titleCode: 'routes.enrollments' },
    {
      route: DevicesRoutes.ALL,
      titleCode: 'routes.allDevices',
      adminAccessOnly: true,
    },
  ])
  .set(RootRoutes.FILE_SYSTEM, [
    {
      route: ApplicationsRoutes.UPLOAD,
      titleCode: 'routes.upload',
      adminAccessOnly: true,
      permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
      accessType: ACCESS_TYPES.READ_WRITE,
    },
    {
      route: ApplicationsRoutes.MY,
      titleCode: 'routes.myApplications',
      adminAccessOnly: true,
      permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS,
      accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
    },
    {
      route: ApplicationsRoutes.FILES,
      titleCode: 'routes.myFiles',
      adminAccessOnly: true,
      permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
      accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
    },
    {
      route: ApplicationsRoutes.CERTIFICATES,
      titleCode: 'routes.myCertificates',
      adminAccessOnly: true,
      permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
      accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
    },
    {
      route: ApplicationsRoutes.HTML_KIOSK_FILES,
      titleCode: 'routes.myHTMLKioskFiles',
      adminAccessOnly: true,
      permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
      accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
    },
  ])
  .set(RootRoutes.SETTINGS, [
    {
      route: SettingsRoutes.ACCOUNT,
      titleCode: 'routes.accountSettings',
      adminAccessOnly: false,
    },
    {
      route: SettingsRoutes.INFO,
      titleCode: 'routes.companyInfo',
      adminAccessOnly: true,
    },
    {
      route: SettingsRoutes.COMMON,
      titleCode: 'routes.commonSettings',
      adminAccessOnly: true,
    },
    {
      route: SettingsRoutes.SSO,
      titleCode: 'routes.SSO',
      adminAccessOnly: true,
    },
    ...(isDevServer()
      ? [
          {
            route: SettingsRoutes.PREFERENCES,
            titleCode: 'routes.preferences',
            adminAccessOnly: true,
          },
        ]
      : []),
  ])
  .set(RootRoutes.HOME, [
    { route: HomeRoutes.DASHBOARD, titleCode: 'routes.dashboard' },
    {
      route: HomeRoutes.MAP,
      titleCode: 'routes.mapView',
      adminAccessOnly: true,
    },
  ])
  .set(RootRoutes.USERS, [
    {
      route: UsersRoutes.GROUPS,
      titleCode: 'routes.usersAll',
      adminAccessOnly: true,
    },
    {
      route: UsersRoutes.LOGS,
      titleCode: 'routes.usersLogs',
      adminAccessOnly: true,
    },
  ])
  .set(RootRoutes.ALERTS, [
    { route: AlertRoutes.ALERTS, titleCode: 'routes.alerts' },
  ])
  .set(RootRoutes.API, [
    { route: APIRoutes.OVERVIEW, titleCode: 'routes.apiOverviewTitle' },
    {
      route: APIRoutes.USAGE_HISTORY,
      titleCode: 'routes.apiUsageHistoryTitle',
    },
  ]);

const Breadcrumbs = (props) => {
  const { parentRoute } = props.match.params;
  const data = breadcrumbs.get(`/${parentRoute}`) || [];

  const renderBreadCrumb = (
    { route, titleCode, adminAccessOnly, permissionKey, accessType },
    index
  ) => {
    const className = selectForCurrentBrand({
      original: 'breadcrumbs__item',
      incube: 'breadcrumbs__item breadcrumbs__item--incube',
      janam: 'breadcrumbs__item breadcrumbs__item--janam',
    });
    const activeClass = selectForCurrentBrand({
      original: 'breadcrumbs__item--active',
      incube: 'breadcrumbs__item--incube--active',
      janam: 'breadcrumbs__item--janam--active',
    });

    let hasPermission;
    if (permissionKey) {
      const { globalPermissionsForUser = {} } = props;
      hasPermission = getAccessFromGlobalPermissionsForUser(
        globalPermissionsForUser,
        permissionKey,
        accessType
      );
    }

    if (adminAccessOnly && !props.hasAdminPrivileges && !hasPermission) {
      return null;
    }

    return (
      <NavLink
        key={route + index}
        to={`/${parentRoute}${route}`}
        className={className}
        activeClassName={activeClass}
      >
        <Translate value={titleCode} />
      </NavLink>
    );
  };

  return (
    <div className={'breadcrumbs-section'}>{data.map(renderBreadCrumb)}</div>
  );
};

Breadcrumbs.propTypes = {
  style: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(Breadcrumbs);
