export const KEYBOARD_EXTRA_ACTION = 'action';
export const KEYBOARD_EXTRA_KEY_CODE = 'keyCode';
export const KEYBOARD_EXTRA_SCAN_CODE = 'scanCode';
export const KEYBOARD_EXTRA_META_STATE = 'metaState';
export const KEYBOARD_EXTRA_REPEAT_COUNT = 'repeatCount';
export const KEYBOARD_EXTRA_FLAGS = 'flags';
export const KEYBOARD_EXTRA_DEVICE_ID = 'deviceId';
export const KEYBOARD_EXTRA_SOURCE = 'source';
export const KEYBOARD_EXTRA_UNICODE_CHAR = 'unicodeChar';

export const MOTION_EVENT_X = 'x';
export const MOTION_EVENT_Y = 'y';
export const MOTION_EVENT_ACTION = 'action';
export const MOTION_EVENT_PRESSED_TIME = 'pressed_time';

export const DATA_TYPE = 'DataType';
export const DATA_TYPE_MOTION_EVENT = 'MotionEventType';
export const DATA_TYPE_KEYBOARD_STRING = 'KeyboardStringType';
export const DATA_TYPE_KEYBOARD_EVENT = 'KeyboardEventType';
export const DATA_TYPE_BUTTON_TYPE = 'ButtonType';
export const DATA_TYPE_IMAGE_PARAMS = 'image_params';
export const DATA_TYPE_TOGGLE_SCREEN = 'toggle_screen';
export const DATA_TYPE_VOLUME_UP = 'volume_up';
export const DATA_TYPE_VOLUME_DOWN = 'volume_down';
export const DATA_TYPE_EXIT_FROM_KIOSK = 'exit_kiosk';
export const DATA_TYPE_ENTER_TO_KIOSK = 'enter_kiosk';

export const ACTION_DOWN = 0;
export const ACTION_UP = 1;
export const ACTION_MOVE = 2;
export const ACTION_CANCEL = 3;
export const ACTION_OUTSIDE = 4;
export const ACTION_POINTER_DOWN = 5;
export const ACTION_POINTER_UP = 6;
export const ACTION_HOVER_MOVE = 7;
export const ACTION_SCROLL = 8;
export const ACTION_HOVER_ENTER = 9;
export const ACTION_HOVER_EXIT = 10;
export const ACTION_BUTTON_PRESS = 11;
export const ACTION_BUTTON_RELEASE = 12;

export const GLOBAL_ACTION_BACK = 'back';
export const GLOBAL_ACTION_HOME = 'home';
export const GLOBAL_ACTION_RECENTS = 'recents';

export const IMAGE_SIZE_FACTOR = 0.6;
export const CANVAS_SIZE = 700;

export const TOUCH_DISAPPEARANCE_TIME = 2000.0;
export const TOUCH_MAX_ALPHA = 200;

export const REMOTE_EVENT_OBJECT_TYPE_KEY = 'type';
export const REMOTE_EVENT_OBJECT_DATA_KEY = 'data';
export const REMOTE_EVENT_INPUT_OBJECT_TYPE = 'input-object';
export const REMOTE_EVENT_KEYBOARD_EVENT_TYPE = 'keyboard-event';
export const REMOTE_EVENT_MOTION_EVENT_TYPE = 'motion-event';
export const REMOTE_EVENT_SCREEN_PARAMETERS_TYPE = 'screen-parameters';
export const REMOTE_EVENT_IMAGE_SIZE_PERCENTAGE = 'image-data';
export const REMOTE_EVENT_AVAILABLE_SCREEN_CAPTURES_TYPE =
  'available_screen_captures';
export const REMOTE_EVENT_SCREEN_CAPTURE_CHANGED_TYPE = 'screen_capture';
