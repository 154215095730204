import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { InputField } from '../../../components/inputField.component';
// import {AttributeType} from "../../../common/constants";
import { SelectField } from '../../../components/selectField.component';
import { SwitchField } from '../../../components/switchField.component';
import { filterConditions } from '../../../constants/filter';

// const styles = {
//     uploader: {
//         border: "2px #C6C6C6 dotted",
//         height: "30rem",
//         textAlign: "center",
//         display: "grid",
//         fontSize: "21px",
//         marginTop: "10px"
//     },
// };
// const usersColumn = [
//     {
//         key: 'name',
//         type: AttributeType.TEXT,
//         titleCode: 'name',
//         searchable: true,
//         availableFilterConditions: [
//           filterConditions.CONTAINS,
//           filterConditions.EQUAL,
//         ]
//     },
//     {
//         key: 'email',
//         type: AttributeType.TEXT,
//         titleCode: 'email',
//         searchable: true,
//         availableFilterConditions: [
//           filterConditions.CONTAINS,
//           filterConditions.EQUAL,
//         ]
//     },
//     {
//         key: 'password',
//         type: AttributeType.TEXT,
//         titleCode: 'password',
//         searchable: true,
//         availableFilterConditions: [
//           filterConditions.CONTAINS,
//           filterConditions.EQUAL,
//         ]
//     },
// ];

class NewUserInviteMethods extends React.Component {
  componentDidMount() {
    if (this.props.role === 'superuser' || this.props.role === 'partner') {
      let partnerId =
        this.props.role === 'partner' ? this.props.partnerId : undefined;
      this.props.loadCompanies(partnerId);
    }
  }

  onChangeSelect = (key, { target: { value } }) => {
    this.props.onChange({ target: { value, name: key } });
  };

  renderControls = () => {
    if (this.props.role) {
      if (this.props.role === 'superuser') {
        return (
          <div className={'dialog-content-section'}>
            <SectionDescription
              title={I18n.t('users.userRoleSectionHeading')}
            />
            <SwitchField
              title={I18n.t('users.adminRoleAssigmentToggleLabel')}
              on={this.props.data.isAdmin}
              onClick={() => {
                this.props.onChange({
                  target: { value: !this.props.data.isAdmin, name: 'isAdmin' },
                });
                if (this.props.data.isPartner) {
                  this.props.onChange({
                    target: { value: false, name: 'isPartner' },
                  });
                }
              }}
            />
            <SwitchField
              title={I18n.t('users.partnerRoleAssigmentToggleLabel')}
              on={this.props.data.isPartner}
              onClick={() => {
                this.props.onChange({
                  target: {
                    value: !this.props.data.isPartner,
                    name: 'isPartner',
                  },
                });
                if (this.props.data.isAdmin) {
                  this.props.onChange({
                    target: { value: false, name: 'isAdmin' },
                  });
                }
              }}
            />
          </div>
        );
      }
      if (this.props.role === 'partner') {
        return (
          <div className={'dialog-content-section'}>
            <SectionDescription
              title={I18n.t('users.userRoleSectionHeading')}
            />
            <SwitchField
              title={I18n.t('users.adminRoleAssigmentToggleLabel')}
              on={this.props.data.isAdmin}
              onClick={() =>
                this.props.onChange({
                  target: {
                    value: !this.props.data.isAdmin,
                    name: 'isAdmin',
                  },
                })
              }
            />
          </div>
        );
      }
    }
  };

  render() {
    return (
      <Fragment>
        <form
          id={'new_user'}
          className={'dialog-content-section'}
          onSubmit={this.props.createUser}
        >
          <SectionDescription
            title={I18n.t('users.userDetailsSectionHeading')}
          />
          <InputField
            label={`${I18n.t('users.fields.email')}:`}
            type="email"
            value={this.props.data.username}
            name={'username'}
            placeholder={I18n.t('users.fields.emailPlaceholder')}
            onChange={this.props.onChange}
            required
            title={`${I18n.t('users.fields.email')}:`}
          />
          <InputField
            label={`${I18n.t('users.fields.firstName')}:`}
            value={this.props.data.firstName}
            name={'firstName'}
            onChange={this.props.onChange}
            required
          />
          <InputField
            label={`${I18n.t('users.fields.lastName')}:`}
            value={this.props.data.lastName}
            name={'lastName'}
            onChange={this.props.onChange}
            required
          />
          <InputField
            label={`${I18n.t('users.fields.password')}:`}
            value={this.props.data.password}
            type={'password'}
            name={'password'}
            onChange={this.props.onChange}
            required
          />
          {this.props.role === 'superuser' ? (
            <SelectField
              title={`${I18n.t('users.fields.partnerNameFieldText')}:`}
              value={this.props.data.partnerName}
              onChange={(e) => {
                this.onChangeSelect('partnerName', e);
                this.onChangeSelect('companyName', {
                  target: { value: undefined },
                });
                this.props.loadCompanies(
                  this.props.partnerNameOptions.find(
                    (item) => item.partnerName === e.target.value
                  )
                    ? this.props.partnerNameOptions.find(
                        (item) => item.partnerName === e.target.value
                      ).partnerId
                    : undefined
                );
              }}
              options={[{ value: undefined, title: undefined }].concat(
                this.props.partnerNameOptions.map((currentValue) => {
                  return {
                    value: currentValue.partnerName,
                    title: currentValue.partnerName,
                  };
                })
              )}
            />
          ) : null}
          {this.props.role === 'superuser' || this.props.role === 'partner' ? (
            <SelectField
              title={`${I18n.t('users.fields.companyName')}:`}
              value={this.props.data.companyName}
              onChange={(e) => {
                this.onChangeSelect('companyName', e);
              }}
              options={[{ value: undefined, title: undefined }].concat(
                this.props.companies.map((item) => ({
                  value: item.name,
                  title: item.name,
                }))
              )}
              disabled={!this.props.companies.length}
            />
          ) : null}
          {this.renderControls()}
        </form>
        {/*<div className={'dialog-content-section'}>*/}
        {/*    <SectionDescription*/}
        {/*        title={'Method 2: Bulk Import Users'}*/}
        {/*    />*/}
        {/*    <p className={'users__text'}>*/}
        {/*        You can add new users by uploading a CSV with their Name, Email, and Password (Optional) as seen below.*/}
        {/*    </p>*/}
        {/*    <p className={'users__text'}>*/}
        {/*        To import your users smoothly, follow these formatting guidelines.*/}
        {/*        Include these headers and make sure the emails belong to your organization's domain or a subdomain*/}
        {/*    </p>*/}

        {/*    <div style={{border: '1px solid lightgrey'}}>*/}
        {/*        <AttributiveTable*/}
        {/*            columns={usersColumn}*/}
        {/*            items={tableItems}*/}
        {/*            loadingStatus={NetworkStatus.DONE}*/}
        {/*        />*/}
        {/*    </div>*/}
        {/*    <div style={{...styles.uploader, margin: "20px"}}>*/}
        {/*        <Dropzone*/}
        {/*            style={{*/}
        {/*                display: "flex",*/}
        {/*                flexDirection: "column",*/}
        {/*                alignItems: "center",*/}
        {/*                justifyContent: "center"*/}
        {/*            }}>*/}
        {/*            <Glyphicon style={{color: "lightgray", marginTop: "30px"}}*/}
        {/*                       glyph='cloud-upload'/>*/}
        {/*            <div style={{color: "lightgray", margin: "30px"}}>*/}
        {/*                Drag here or browse to upload*/}
        {/*            </div>*/}
        {/*            <div style={{marginTop: "30px"}}>*/}
        {/*                <Button className={'button--primary'}>*/}
        {/*                    BROWSE*/}
        {/*                </Button>*/}
        {/*            </div>*/}
        {/*        </Dropzone>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className={'dialog-content-section'}>*/}
        {/*    <SectionDescription*/}
        {/*        title={'Method 3: Invite using Single Sing-on SAML through Azure AD'}*/}
        {/*    />*/}
        {/*    <p className={'users__text'}>*/}
        {/*        Using your company's Azure Active Directory (Azure AD) using the SAML 2.0 protocol,*/}
        {/*        you can provide a single sign-on experience to your employees*/}
        {/*    </p>*/}
        {/*    <InputField*/}
        {/*        title={'SAML Parameter #1'}*/}
        {/*    />*/}
        {/*    <InputField*/}
        {/*        title={'SAML Parameter #2'}*/}
        {/*    />*/}
        {/*    <InputField*/}
        {/*        title={'SAML Parameter #3'}*/}
        {/*    />*/}
        {/*    <div style={{height: '40px', width: '100%'}}> </div>*/}
        {/*</div>*/}
      </Fragment>
    );
  }
}

export default NewUserInviteMethods;
