import { NetworkStatus } from '../../common/constants';
import {
  APPLICATION_ASSIGN_TO_PROFILES_FAILED,
  APPLICATION_ASSIGN_TO_PROFILES_RESET,
  APPLICATION_ASSIGN_TO_PROFILES_STARTED,
  APPLICATION_ASSIGN_TO_PROFILES_SUCCESS,
  APPLICATION_LOAD_DEVICES_FAILED,
  APPLICATION_LOAD_DEVICES_STARTED,
  APPLICATION_LOAD_DEVICES_SUCCESS,
  APPLICATION_LOAD_FAILED,
  APPLICATION_LOAD_STARTED,
  APPLICATION_LOAD_SUCCESS,
  APPLICATION_RESET,
} from './application.actions';

export default function application(
  state = {
    data: [],
    status: NetworkStatus.NONE,
    error: undefined,
    devicesInstalled: [],
    devicesFailed: [],
    devicesStatus: NetworkStatus.NONE,
    devicesError: undefined,
    assignationStatus: NetworkStatus.NONE,
    assignationError: undefined,
  },
  action
) {
  switch (action.type) {
    case APPLICATION_LOAD_STARTED:
      return Object.assign({}, state, {
        data: [],
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case APPLICATION_LOAD_FAILED:
      return Object.assign({}, state, {
        data: [],
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case APPLICATION_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    case APPLICATION_RESET:
      return Object.assign({}, state, {
        devices: [],
        devicesStatus: NetworkStatus.NONE,
        devicesError: NetworkStatus.ERROR,
        data: [],
        status: NetworkStatus.NONE,
        error: undefined,
      });
    case APPLICATION_ASSIGN_TO_PROFILES_STARTED:
      return Object.assign({}, state, {
        assignationStatus: NetworkStatus.STARTED,
        assignationError: undefined,
      });
    case APPLICATION_ASSIGN_TO_PROFILES_FAILED:
      return Object.assign({}, state, {
        assignationStatus: NetworkStatus.ERROR,
        assignationError: action.payload,
      });
    case APPLICATION_ASSIGN_TO_PROFILES_SUCCESS:
      return Object.assign({}, state, {
        assignationStatus: NetworkStatus.DONE,
        assignationError: undefined,
      });
    case APPLICATION_ASSIGN_TO_PROFILES_RESET:
      return Object.assign({}, state, {
        assignationStatus: NetworkStatus.NONE,
        assignationError: undefined,
      });

    case APPLICATION_LOAD_DEVICES_STARTED:
      return Object.assign({}, state, {
        devicesInstalled: [],
        devicesFailed: [],
        devicesStatus: NetworkStatus.STARTED,
        devicesError: undefined,
      });
    case APPLICATION_LOAD_DEVICES_FAILED:
      return Object.assign({}, state, {
        devicesInstalled: [],
        devicesFailed: [],
        devicesStatus: NetworkStatus.ERROR,
        devicesError: action.payload,
      });
    case APPLICATION_LOAD_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        devicesInstalled: action.payload.devicesInstalled,
        devicesFailed: action.payload.devicesFailed,
        devicesStatus: NetworkStatus.DONE,
        devicesError: undefined,
      });
    default:
      return state;
  }
}
