import { I18n } from 'react-redux-i18n';
import { deserializeFleet, serializeFleet } from '../fleet/fleet.serializer';
import { deserializeDevice } from '../device/device.serializer';
import { loadAllFleetsApi } from './fleets.api';
import client from '../../utils/client';
import services from '../../utils/services';
import {
  allFleetsLoaded,
  allFleetsLoadingFailed,
  allFleetsLoadingStarted,
  assignDevicesToFleetDone,
  assignDevicesToFleetFailed,
  assignDevicesToFleetStarted,
  createFleetFailed,
  createFleetStarted,
  createFleetSuccess,
  fleetDeletingFailed,
  fleetDeletingStarted,
  fleetDeletingSuccess,
  fleetLoadingDone,
  fleetLoadingFailed,
  fleetLoadingStarted,
} from './fleets.actions';
import {
  enrollmentLoadingFailed,
  enrollmentLoadingStarted,
} from '../enrollment/enrollment.actions';
import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';
import { loadAllFleetsV2 } from './fleetsV2.thunk';

export function loadAllFleets() {
  return async (dispatch) => {
    try {
      dispatch(allFleetsLoadingStarted());
      let response = await loadAllFleetsApi();
      if (
        response.status === 200 &&
        response.data &&
        response.data.length >= 0
      ) {
        let result = response.data.map(deserializeFleet);
        dispatch(allFleetsLoaded(result));
      } else {
        dispatch(
          allFleetsLoadingFailed(I18n.t('fleets.loadAllFleetsErrorMessage'))
        );
      }
    } catch (error) {
      dispatch(
        allFleetsLoadingFailed(
          new Error(I18n.t('fleets.loadAllFleetsErrorMessage'))
        )
      );
    }
  };
}

export function deleteFleet(data) {
  return async (dispatch) => {
    try {
      dispatch(fleetDeletingStarted());
      let response = await client.delete(services.deleteFleet, {
        params: { id: data.id },
      });
      if (response.status === 204) {
        dispatch(fleetDeletingSuccess());
        dispatch(fleetLoadingDone({ devices: [], id: undefined }));
        dispatch(loadAllFleets(data.companyId));
      } else if (response.status === 409) {
        // const errorMessage = response.data?.error_type === 'fleet_has_profiles_assigned' ? I18n.t('fleets.fleetNotEmptyDeletionError') : I18n.t('fleets.fleetDeletionError');
        const errorMessage = I18n.t('fleets.fleetNotEmptyDeletionError');
        dispatch(fleetDeletingFailed(errorMessage));
      } else {
        dispatch(fleetDeletingFailed(I18n.t('fleets.fleetDeletionError')));
      }
    } catch (error) {
      dispatch(fleetDeletingFailed(I18n.t('fleets.fleetDeletionError')));
    }
  };
}

export function updateFleet(data) {
  return async (dispatch) => {
    try {
      let { status } = await client.patch(
        services.updateFleet,
        serializeFleet(data)
      );
      if (status === 200) {
        dispatch(loadAllFleetsV2());
      } else {
        // TODO add error handling:
        // dispatch(updateFleetFailed(text))
      }
    } catch (error) {
      // TODO add error handling:
      // dispatch(updateFleetFailed('Error on updating fleet'))
    }
  };
}

export function assignDevicesToFleet({
  devices,
  fleetId: id,
  isVersion2 = false,
}) {
  return async (dispatch) => {
    try {
      // dispatch(fleetLoadingStarted(id));
      dispatch(assignDevicesToFleetStarted());
      const requestData = {
        devices,
        [isVersion2 ? 'fleetv2_id' : 'fleet_id']: id,
      };
      let { status } = await client.post(services.transferDevices, requestData);
      if (status === 200) {
        dispatch(assignDevicesToFleetDone());
      } else {
        dispatch(
          assignDevicesToFleetFailed(
            I18n.t('fleets.failedToMoveDevicesErrorText')
          )
        );
      }
    } catch (error) {
      dispatch(
        assignDevicesToFleetFailed(
          I18n.t('fleets.failedToMoveDevicesErrorText')
        )
      );
    }
  };
}

export function unenrollDevices(devices, fleetId) {
  return async (dispatch) => {
    try {
      dispatch(enrollmentLoadingStarted(fleetId));
      let response = await client.put(services.unenrollDevices, { devices });
      if (response.status === 204) {
        if (fleetId) {
          let { status, data } = await client.get(
            `${services.getAllDevicesByFleetId}${fleetId}`,
            {
              params: {
                limit: INFINITE_SCROLL_LIMIT,
                offset: 0,
              },
            }
          );
          if (status === 200) {
            const { data: { results, next, count } = {} } = data;
            dispatch(
              fleetLoadingDone({
                devices: results.map(deserializeDevice),
                id: fleetId,
                next,
                count,
                isInitialLoad: true,
              })
            );
          } else {
            dispatch(
              fleetLoadingFailed(
                new Error(I18n.t('fleets.failedToFetchSelectedFleet'))
              )
            );
          }
        }
      } else {
        dispatch(
          enrollmentLoadingFailed(
            new Error(I18n.t('fleets.unenrollmentFailedText'))
          )
        );
      }
    } catch (error) {
      dispatch(
        enrollmentLoadingFailed(
          new Error(I18n.t('fleets.unenrollmentFailedText'))
        )
      );
    }
  };
}

export function loadFleetDevices({
  fleetId,
  offset = 0,
  limit,
  isInitialLoad = false,
}) {
  return async (dispatch) => {
    try {
      dispatch(fleetLoadingStarted(fleetId));
      let { status, data } = await client.get(
        `${services.getAllDevicesByFleetId}${fleetId}`,
        {
          // params: {
          //     offset: isInitialLoad ? 0 : offset,
          //     limit,
          // },
        }
      );
      if (status === 200 && data.data && Array.isArray(data.data.results)) {
        const { data: { results, next, count } = {} } = data;
        dispatch(
          fleetLoadingDone({
            devices: results.map(deserializeDevice),
            id: fleetId,
            next,
            count,
            isInitialLoad,
          })
        );
      } else {
        dispatch(
          fleetLoadingFailed(I18n.t('fleets.failedToFetchSelectedFleet'))
        );
      }
    } catch (error) {
      dispatch(fleetLoadingFailed(I18n.t('fleets.failedToFetchSelectedFleet')));
    }
  };
}

export function createFleet(fleet) {
  return async (dispatch) => {
    try {
      dispatch(createFleetStarted());
      let data = serializeFleet(fleet);
      let response = await client.post(services.createFleet, data);
      if (response.status === 200) {
        dispatch(createFleetSuccess());
        dispatch(loadAllFleetsV2());
      } else if (response.status === 400) {
        dispatch(
          createFleetFailed(I18n.t('fleets.fleetCreationFailedMessage'))
        );
      } else if (response.status > 200) {
        dispatch(
          createFleetFailed(I18n.t('fleets.fleetCreationFailedMessage'))
        );
      }
    } catch (error) {
      dispatch(createFleetFailed(I18n.t('fleets.fleetCreationFailedMessage')));
    }
  };
}
