import React from 'react';
import { ReactComponent as Trash } from '../../assets/images/trash.svg';
import { ActionButton } from '../actionButton.component';
import { getValue } from '../../common/helpers';
import { formatAttributiveDataFormat } from '../../common/utilities/attributiveDataFormat';
import StyleUtils from '../../utils/styleUtils';

const DragNDropListItem = ({
  item,
  itemOptions: {
    actionButtonProps: {
      onClick: actionButtonOnClick,
      icon = <Trash className={'icon'} />,
    } = {},
    displayTextKeys: { leftCell: leftCellKey, rightCell: rightCellKey } = {},
    multiCellTextKeyAttributes,
    textContainerAdditionalClassName = '',
  } = {},
}) => {
  const leftCellText = getValue(item, leftCellKey) ?? '';
  const rightCellText = getValue(item, rightCellKey) ?? '';
  return (
    <>
      {Array.isArray(multiCellTextKeyAttributes) ? (
        multiCellTextKeyAttributes.map((multiCellTextKeyAttribute) => (
          <div
            key={multiCellTextKeyAttribute.key}
            className={StyleUtils.mergeClasses(
              'drag-n-drop-list-content-item__cell',
              textContainerAdditionalClassName
            )}
          >
            {formatAttributiveDataFormat({
              values: item,
              attribute: multiCellTextKeyAttribute,
            })}
          </div>
        ))
      ) : (
        <>
          <div className={'drag-n-drop-list-content-item__left-cell'}>
            {leftCellText}
          </div>
          <div className={'drag-n-drop-list-content-item__right-cell'}>
            {rightCellText}
          </div>
        </>
      )}
      {actionButtonOnClick ? (
        <ActionButton
          onClick={actionButtonOnClick}
          style={{ marginLeft: '0', width: '18px' }}
        >
          {icon}
        </ActionButton>
      ) : null}
    </>
  );
};

export default DragNDropListItem;
