export const allCompanyFleetsSelector = (state) =>
  state.groups.companyFleetsFullListData;

export const allCompanyFleetsStatusSelector = (state) =>
  state.groups.companyFleetsFullListStatus;

export const allCompanyFleetsV2Selector = ({ groups }) => ({
  data: groups.companyFleetsFullListData,
  status: groups.companyFleetsFullListStatus,
  error: groups.companyFleetsFullListError,
});

export const fleetsSelector = (state) => state.fleets.data;

export const fleetsStatusSelector = (state) => state.fleets.status;
