import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { cloneDeep } from 'lodash';
import { SwitchField } from '../../../components/switchField.component';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../../common/constants';
import { SectionDescription } from '../../../components/sectionDescription.component';
import PolicyActivationToggle from './PolicyActivationToggle.component';

export const initialSettings = {
  enableEncryption: false,
  enableSecurityCompliance: false,
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'security',
  isActive: false,
  policyData: null,
};

export class Security extends Component {
  render() {
    const { isEditingMode } = this.props;
    return (
      <div className={'security-wrapper'}>
        {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
      </div>
    );
  }

  renderContent = () => {
    return this.props.policy.length
      ? this.props.policy.map(this.renderSettings)
      : this.renderSettings(
          { ...initialPolicy, settings: { ...initialSettings } },
          0
        );
  };

  renderReadOnlyContent = () => {
    const { policy: [{ settings = {}, isActive } = {}] = [] } = this.props;
    return (
      <ReadOnlyForm
        headerTitle={I18n.t('profiles.security.policyTitle')}
        items={[
          {
            id: 'policy-status',
            title: I18n.t('profiles.security.policyStatusTitle'),
            value: isActive,
            type: AttributeType.BOOLEAN,
          },
          {
            title: I18n.t('profiles.security.secureOSTitle'),
            value: settings['enableSecurityCompliance'] || false,
            type: AttributeType.BOOLEAN,
            key: 'enableSecurityCompliance',
          },
        ]}
      />
    );
  };

  renderSettings = ({ settings, isActive }, index) => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      this.props.onChangePolicyProperty({
        key: 'security',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });
    return (
      <div className={'security'} key={`secpol_${index}`}>
        <SectionDescription
          title={I18n.t('profiles.security.policyTitle')}
          hint={I18n.t('profiles.security.policyHint')}
        />
        <PolicyActivationToggle
          key="policyActivation"
          policy={this.props.policy}
          titleCode="profiles.security.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
        />

        {isActive ? (
          <>
            {/*{this.renderSwitchGroup(index, settings, {key: 'enableEncryption', title: 'Enable Encryption' })}*/}
            <SwitchField
              onClick={(e) => this.onToggle(index, 'enableSecurityCompliance')}
              on={settings['enableSecurityCompliance'] || false}
              title={I18n.t('profiles.security.secureOSTitle')}
            />
          </>
        ) : null}
      </div>
    );
  };

  onToggle = (index, key) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings ? item.settings : { ...initialSettings };
    settings[key] = !settings[key];
    item.settings = settings;
    this.props.onChange('security', index, item);
  };
}

export default Security;

Security.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
