import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  sendDisableDataCollectorAnalytic,
  sendEnableDataCollectorAnalytic,
} from '../../devices.thunk';
import { I18n } from 'react-redux-i18n';

class DeviceAnalyticsCollectorControl extends Component {
  render() {
    if (this.props.item.sendingAnalyticsData) {
      return (
        <div
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() =>
            this.props.sendDisableDataCollectorAnalytic(this.props.item.id)
          }
        >
          {I18n.t('common.yes')}
        </div>
      );
    } else {
      return (
        <div
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() =>
            this.props.sendEnableDataCollectorAnalytic(this.props.item.id)
          }
        >
          {I18n.t('common.no')}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendEnableDataCollectorAnalytic: (id) =>
      dispatch(sendEnableDataCollectorAnalytic(id)),
    sendDisableDataCollectorAnalytic: (id) =>
      dispatch(sendDisableDataCollectorAnalytic(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceAnalyticsCollectorControl);
