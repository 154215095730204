export const SETTINGS_LOAD_TIMEZONES_STARTED =
  'SETTINGS_LOAD_TIMEZONES_STARTED';
export const SETTINGS_LOAD_TIMEZONES_FAILED = 'SETTINGS_LOAD_TIMEZONES_FAILED';
export const SETTINGS_LOAD_TIMEZONES_SUCCESS =
  'SETTINGS_LOAD_TIMEZONES_SUCCESS';

export const SSO_SAML_SETTINGS_LOAD_STARTED = 'SSO_SAML_SETTINGS_LOAD_STARTED';
export const SSO_SAML_SETTINGS_LOAD_FAILED = 'SSO_SAML_SETTINGS_LOAD_FAILED';
export const SSO_SAML_SETTINGS_LOAD_SUCCESS = 'SSO_SAML_SETTINGS_LOAD_SUCCESS';

export const loadTimezonesStarted = () => ({
  type: SETTINGS_LOAD_TIMEZONES_STARTED,
});

export const loadTimezonesFailed = (payload) => ({
  type: SETTINGS_LOAD_TIMEZONES_FAILED,
  payload,
});

export const loadTimezonesSuccess = (payload) => ({
  type: SETTINGS_LOAD_TIMEZONES_SUCCESS,
  payload,
});

export const loadSSOSAMLSettingStarted = () => ({
  type: SSO_SAML_SETTINGS_LOAD_STARTED,
});

export const loadSSOSAMLSettingFailed = (payload) => ({
  type: SSO_SAML_SETTINGS_LOAD_FAILED,
  payload,
});

export const loadSSOSAMLSettingSuccess = (payload) => ({
  type: SSO_SAML_SETTINGS_LOAD_SUCCESS,
  payload,
});
