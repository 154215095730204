import React, { Component } from 'react';

class PermissionCheckBox extends Component {
  render() {
    const { checked, onChange, ...rest } = this.props;
    return (
      <div>
        <input
          type={'checkbox'}
          onChange={onChange}
          checked={checked}
          {...rest}
        />
      </div>
    );
  }
}

export default PermissionCheckBox;
