import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';

import { Button } from '../../components/button.component';

import PopupConstants from './popup.constants';
import PopupActions from './popup.actions';

import RadioButtonGroup from '../../components/RadioButtonGroup';
import CheckBox from '../../components/CheckBox';
import Helpers, { didValueChange, notEmpty } from '../../common/helpers';

import '../../assets/styles/popup.scss';
import StyleUtils from '../../utils/styleUtils';
import { Throbber } from '../../components/Throbber';
import { InputField } from '../../components/inputField.component';
import TextArea from '../../components/TextArea';

class Popup extends Component {
  state = {
    isSecondaryActionConfirmed: false,
    promptValue: '',
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { popup: { defaultValue = '' } = {} } = this.props;
    if (defaultValue && Helpers.isNull(this.state.promptValue)) {
      this.setState(() => ({ promptValue: defaultValue }));
    }
  }

  componentWillUnmount() {
    this.close();
  }

  close = () => {
    const {
      popup: { id, onClose },
    } = this.props;
    PopupActions.closePopup(id);
    this.setState(() => ({
      isSecondaryActionConfirmed: false,
      checkedValue: null,
      checkList: [],
    }));
    if (onClose) {
      onClose();
    }
  };

  confirm = () => {
    const {
      popup: { onConfirm, secondaryConfirmationParams },
    } = this.props;
    const { isSecondaryActionConfirmed } = this.state;
    const params = {
      ...(notEmpty(secondaryConfirmationParams)
        ? {
            secondaryConfirmationParams: {
              ...secondaryConfirmationParams,
              isSecondaryActionConfirmed,
            },
          }
        : {}),
    };
    this.close();
    if (onConfirm) {
      onConfirm(params);
    }
  };

  onCancel = () => {
    const {
      popup: { onCancel },
    } = this.props;
    this.setState({ promptValue: '' });
    this.close();
    if (onCancel) {
      onCancel();
    }
  };

  toggleSecondaryActionConfirmation = () =>
    this.setState((prevState) => ({
      isSecondaryActionConfirmed: !prevState.isSecondaryActionConfirmed,
    }));

  onRadioGroupChange = ({ target: { value } = {} }) =>
    this.setState(() => ({ checkedValue: value }));

  onOptionsProceed = () => {
    const {
      popup: { onProceed, closeOnProceed, selectMultiple },
    } = this.props;
    const { checkedValue, checkList } = this.state;
    onProceed?.({
      ...(selectMultiple ? { checkList } : { checkedValue }),
    });
    if (closeOnProceed) {
      this.close();
    }
  };

  onPromptConfirm = () => {
    const {
      popup: { onConfirm, closeOnConfirm },
    } = this.props;
    const { promptValue } = this.state;
    onConfirm?.({
      promptValue,
    });
    if (closeOnConfirm) {
      this.close();
    }
  };

  onPromptValueChange = ({ target: { value } = {} }) =>
    this.setState(() => ({ promptValue: value }));

  render() {
    const {
      popup: {
        isVisible = false,
        title = '',
        text = '',
        textList = [],
        textType = '',
        options = [],
        optionsListParams = {},
        selectMultiple = false,
        proceedButtonText,
        confirmButtonText = '',
        cancelButtonText = '',
        closeButtonText = '',
        type,
        secondaryConfirmationParams = {},
        useTextArea = false,
        defaultValue = '',
        allowEmptyValue,
      },
    } = this.props;

    if (!isVisible) {
      return null;
    }

    const rootClassName = 'springdel-popup';

    const textClassName = StyleUtils.mergeModifiers(
      `${rootClassName}__text`,
      textType
    );

    const message = (
      <section className={`${rootClassName}__message-container`}>
        {textList.length ? (
          textList.map((textItem) => (
            <p className={textClassName}>{textItem}</p>
          ))
        ) : (
          <p className={textClassName}>{text}</p>
        )}
      </section>
    );

    if (type === PopupConstants.LOADER) {
      return (
        <Portal>
          <section className={rootClassName}>
            <section className={`${rootClassName}__popup-container`}>
              <Throbber />
            </section>
          </section>
        </Portal>
      );
    }

    if (type === PopupConstants.CONFIRM) {
      return (
        <Portal>
          <section className={rootClassName}>
            <section className={`${rootClassName}__popup-container`}>
              <div className={`${rootClassName}__popup-title`}>{title}</div>
              {message}
              {notEmpty(secondaryConfirmationParams) ? (
                <CheckBox
                  label={secondaryConfirmationParams.text}
                  onChange={this.toggleSecondaryActionConfirmation}
                  checked={this.state.isSecondaryActionConfirmed}
                  className={`${rootClassName}__secondary-action`}
                />
              ) : null}
              <footer className={`${rootClassName}__popup-footer`}>
                <Button onClick={this.confirm}>{confirmButtonText}</Button>
                <Button secondary onClick={this.onCancel}>
                  {cancelButtonText}
                </Button>
              </footer>
            </section>
          </section>
        </Portal>
      );
    }

    if (type === PopupConstants.ALERT) {
      return (
        <Portal>
          <section className="springdel-popup">
            <section className="springdel-popup__popup-container">
              <div className={`${rootClassName}__popup-title`}>{title}</div>
              {message}
              <footer className={`${rootClassName}__popup-footer`}>
                <Button onClick={this.close}>{closeButtonText}</Button>
              </footer>
            </section>
          </section>
        </Portal>
      );
    }

    if (type === PopupConstants.OPTIONS) {
      const { checkedValue } = this.state;
      return (
        <Portal>
          <section className="springdel-popup">
            <section className="springdel-popup__popup-container">
              <div className={`${rootClassName}__popup-title`}>{title}</div>
              {message}
              <div className={`${rootClassName}__options-list`}>
                {selectMultiple ? null : (
                  <RadioButtonGroup
                    checkedValue={checkedValue}
                    options={options}
                    onChange={this.onRadioGroupChange}
                    {...optionsListParams}
                  />
                )}
              </div>
              <footer className={`${rootClassName}__popup-footer`}>
                <Button
                  disabled={!checkedValue}
                  onClick={this.onOptionsProceed}
                >
                  {proceedButtonText}
                </Button>
                <Button secondary onClick={this.onCancel}>
                  {cancelButtonText}
                </Button>
              </footer>
            </section>
          </section>
        </Portal>
      );
    }

    if (type === PopupConstants.PROMPT) {
      const { promptValue = '' } = this.state;
      return (
        <Portal>
          <section className="springdel-popup">
            <section className="springdel-popup__popup-container">
              <div className={`${rootClassName}__popup-title`}>{title}</div>
              {message}
              {useTextArea ? (
                <TextArea
                  value={promptValue}
                  onChange={this.onPromptValueChange}
                  name="promptValue"
                />
              ) : (
                <InputField
                  value={promptValue}
                  onChange={this.onPromptValueChange}
                  name="promptValue"
                />
              )}
              <footer className={`${rootClassName}__popup-footer`}>
                <Button
                  disabled={
                    !allowEmptyValue &&
                    !didValueChange(defaultValue, promptValue)
                  }
                  onClick={this.onPromptConfirm}
                >
                  {confirmButtonText}
                </Button>
                <Button secondary onClick={this.onCancel}>
                  {cancelButtonText}
                </Button>
              </footer>
            </section>
          </section>
        </Portal>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ popup: { popupList } }) => {
  return {
    popup: popupList[0] || {},
  };
};

export default connect(mapStateToProps)(Popup);
