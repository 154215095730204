import { ReactComponent as LogoSpringdel } from '../assets/images/logo_springdel.svg';
import React from 'react';
import LogoIncube from '../assets/images/logo_incube.png';
import {
  ReactComponent as JanamLogoFull,
  ReactComponent as LogoJanam,
} from '../assets/images/logo_janam.svg';
import { ReactComponent as SpringdelLogoFull } from '../assets/images/logo_springdell_full.svg';
import IncubeLogoFull from '../assets/images/logo_incube_full.png';
import { ANTIQUE_ENVIRONMENTS } from '../utils/env';

const HOST = window.location.hostname;
const hostAddress = `${HOST}${
  ANTIQUE_ENVIRONMENTS.includes(HOST) ? ':7001' : '/des'
}`;

// Springdel
export const SPRINGDEL_DPC_DOWNLOAD_LOCATION = `https://${hostAddress}/v1/agent?agent_type=springdel.android`;
export const SPRINGDEL_DPC_SIGNATURE_CHECKSUM =
  '2jc5V4c6A55RY-NoZHLECtcV08kDsiHCvQ3uxPn_yno=';
export const SPRINGDEL_HELP_FILE_LINK =
  'https://docs.google.com/document/d/e/2PACX-1vQdvmFOYGt74Uy53seb2ioGstHXz3MeLH4OlczAMye-Gxl6vWIx5g2bRHn2bhaMIa4ul_KhucaBSEdn/pub';
export const SPRINGDEL_LOGO = <LogoSpringdel className={'sidebar-logo__img'} />;
export const SPRINGDEL_LOGO_FULL = (
  <SpringdelLogoFull className={'login-page__logo'} />
);

// Janam
export const JANAM_DPC_DOWNLOAD_LOCATION = `https://${hostAddress}/v1/agent?agent_type=janam.android`;
export const JANAM_DPC_SIGNATURE_CHECKSUM =
  'CrJqlKZevMbtPOdW7yxdHxvim2xH42yMwxMLnmj5Wpw';
export const JANAM_HELP_FILE_LINK =
  'https://docs.google.com/document/d/1cDvCn8sm4AENrco3NAokHF2sl2k9EwB1x5zeq5Nd3mM';
export const JANAM_LOGO = <LogoJanam className={'sidebar-logo__img'} />;
export const JANAM_LOGO_FULL = <JanamLogoFull className={'login-page__logo'} />;

// Generic (Incube)
export const GENERIC_DPC_DOWNLOAD_LOCATION = `https://${hostAddress}/v1/agent?agent_type=generic.android`;
export const GENERIC_DPC_SIGNATURE_CHECKSUM =
  '2jc5V4c6A55RY-NoZHLECtcV08kDsiHCvQ3uxPn_yno=';
export const INCUBE_HELP_FILE_LINK =
  'https://docs.google.com/document/d/1cDvCn8sm4AENrco3NAokHF2sl2k9EwB1x5zeq5Nd3mM';
export const INCUBE_LOGO = (
  <img src={LogoIncube} alt={''} className={'sidebar-logo__img'} />
);
export const INCUBE_LOGO_FULL = (
  <img alt={''} src={IncubeLogoFull} className={'login-page__logo'} />
);
