import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { InputField } from '../../../components/inputField.component';

class NewUserEnrollmentEmail extends React.Component {
  render() {
    return (
      <Fragment>
        <SectionDescription
          title={I18n.t('users.enrollmentEmailTemplateTitle')}
        />
        <p className={'users__text'}>
          {I18n.t('users.fields.enrollmentEmailHint')}
        </p>
        <InputField label={I18n.t('users.fields.emailSubjectFieldTitle')} />
        <p>{I18n.t('users.fields.emailContentFieldTitle')}</p>
        <textarea className={'users-text-area'}></textarea>
      </Fragment>
    );
  }
}
export default NewUserEnrollmentEmail;
