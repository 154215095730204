import React, { Component } from 'react';

class PermissionProperty extends Component {
  render() {
    return (
      <div
        style={{
          paddingLeft: `${15 * this.props.item.level}px`,
          fontWeight: `${this.props.item.level === 0 ? 'bold' : 'normal'}`,
        }}
      >
        {this.props.item.title}
      </div>
    );
  }
}

export default PermissionProperty;
