export const FILES_LOAD_ALL_STARTED = 'FILES_LOAD_ALL_STARTED';
export const FILES_LOAD_ALL_FAILED = 'FILES_LOAD_ALL_FAILED';
export const FILES_LOAD_ALL_SUCCESS = 'FILES_LOAD_ALL_SUCCESS';
export const FILES_DELETE_STARTED = 'FILES_DELETE_STARTED';
export const FILES_DELETE_FAILED = 'FILES_DELETE_FAILED';
export const FILES_DELETE_SUCCESS = 'FILES_DELETE_SUCCESS';
export const FILES_ASSIGN_PROFILES_STARTED = 'FILES_ASSIGN_PROFILES_STARTED';
export const FILES_ASSIGN_PROFILES_SUCCESS = 'FILES_ASSIGN_PROFILES_SUCCESS';
export const FILES_ASSIGN_PROFILES_FAILED = 'FILES_ASSIGN_PROFILES_FAILED';
export const FILES_ASSIGN_PROFILES_RESET = 'FILES_ASSIGN_PROFILES_RESET';
