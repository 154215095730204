import { NetworkStatus } from '../../common/constants';
import {
  SETTINGS_LOAD_TIMEZONES_FAILED,
  SETTINGS_LOAD_TIMEZONES_STARTED,
  SETTINGS_LOAD_TIMEZONES_SUCCESS,
  SSO_SAML_SETTINGS_LOAD_FAILED,
  SSO_SAML_SETTINGS_LOAD_STARTED,
  SSO_SAML_SETTINGS_LOAD_SUCCESS,
} from './settings.actions';

export default function settings(
  state = {
    data: { timezones: [] },
    status: NetworkStatus.NONE,
    error: undefined,
    azureAdSAML: {
      loginUrl: '',
      organizationId: '',
      applicationName: '',
      entityId: '',
      federationMetadataXMLFile: null,
    },
    azureAdSAMLStatus: NetworkStatus.NONE,
    ssoSAMLError: undefined,
    isAzureAdSAMLAlreadySet: false,
  },
  { type, payload }
) {
  switch (type) {
    case SETTINGS_LOAD_TIMEZONES_STARTED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case SETTINGS_LOAD_TIMEZONES_FAILED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.ERROR,
        error: payload,
      });
    case SETTINGS_LOAD_TIMEZONES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.DONE,
        error: undefined,
        data: { ...state.data, timezones: payload },
      });

    case SSO_SAML_SETTINGS_LOAD_STARTED:
      return {
        ...state,
        azureAdSAMLStatus: NetworkStatus.STARTED,
        ssoSAMLError: undefined,
      };
    case SSO_SAML_SETTINGS_LOAD_FAILED:
      return {
        ...state,
        azureAdSAMLStatus: NetworkStatus.ERROR,
        ssoSAMLError: payload,
      };
    case SSO_SAML_SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        azureAdSAML: { ...state.azureAdSAML, ...payload.data },
        isAzureAdSAMLAlreadySet: payload.isAzureAdSAMLAlreadySet,
        azureAdSAMLStatus: NetworkStatus.DONE,
        ssoSAMLError: undefined,
      };

    default:
      return state;
  }
}
