import React from 'react';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { SwitchField } from '../../../components/switchField.component';

const ApplicationSettings = ({
  isEditingMode,
  appData,
  appSettings,
  onToggleSettings,
}) => {
  if (isEditingMode) {
    return (
      <div>
        {appSettings.map(({ key, title }) => (
          <SwitchField
            key={`application-setting-${key}`}
            onClick={() => onToggleSettings(key)}
            on={appData[key] || false}
            title={title}
          />
        ))}
      </div>
    );
  }

  const readOnlyFormData = appSettings.map((setting) => ({
    ...setting,
    value: appData[setting.key],
  }));
  return (
    <ReadOnlyForm
      key={`read-only-form-application-settings`}
      items={readOnlyFormData}
      headerTitle="Application Settings"
    />
  );
};

export default ApplicationSettings;
