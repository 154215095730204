import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { Translate } from 'react-redux-i18n';
import { loadAccountInfo } from '../features/account/account.thunk';
import { connect } from 'react-redux';
import { NetworkStatus } from '../common/constants';
import { logout } from '../features/login/login.actions';
import { ReactComponent as CaretArrowDown } from '../assets/images/caret-arrow-down.svg';
import { ReactComponent as PersonCircle } from '../assets/images/person-circle.svg';
import { Throbber } from './Throbber';
import { RootRoutes, SettingsRoutes } from '../utils/routes';
import { NavLink } from 'react-router-dom';

class ProfileBar extends Component {
  componentDidMount() {
    if (
      this.props.status !== NetworkStatus.STARTED &&
      this.props.status !== NetworkStatus.DONE
    ) {
      this.props.loadAccountInfo();
    }
  }

  render() {
    const {
      status,
      data: { username = '', firstName, lastName },
    } = this.props;

    if (status === NetworkStatus.STARTED) {
      return (
        <div className={'profile-bar__throbber'}>
          <Throbber />
        </div>
      );
    }

    const displayName = `${firstName ?? ''} ${lastName ?? ''}` || username;
    return (
      <Popup
        closeOnDocumentClick
        position="bottom right"
        contentStyle={{
          minWidth: '220px',
          width: '220px',
        }}
        closeOnEscape
        repositionOnResize
        trigger={
          <div className={'profile-bar'}>
            <div className={'profile-bar__delimiter'} />
            <div className={'profile-bar__name'}>{displayName}</div>
            <div className={'profile-bar__icon'}>
              <CaretArrowDown width={'12'} height={'7.41'} />
            </div>
            <div className={'profile-bar__icon'}>
              <PersonCircle width={'20'} height={'20'} />
            </div>
          </div>
        }
      >
        <div
          style={{
            outline: 'none !important',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
          }}
        >
          <div className="profile-bar__dropdown-item profile-bar__user-info">
            <p>{username}</p>
          </div>
          <NavLink
            to={`${RootRoutes.SETTINGS}${SettingsRoutes.ACCOUNT}`}
            className={'profile-bar__dropdown-item profile-bar__name'}
            style={{
              padding: '15px',
              textAlign: 'center',
              borderBottom: '1px solid #f2f2f2',
            }}
          >
            <Translate value="profileBar.changeAccountSettings" />
          </NavLink>
          <div
            onClick={() =>
              this.props.logout({
                reloadApp: true,
                clearData: true,
              })
            }
            className={'profile-bar__dropdown-item profile-bar__name'}
          >
            <Translate value="profileBar.logout" />
          </div>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.account.data,
    status: state.account.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (params) => dispatch(logout(params)),
    loadAccountInfo: () => dispatch(loadAccountInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBar);
