import {
  ALL_USERS_LOAD_FAILED,
  ALL_USERS_LOAD_RESET,
  ALL_USERS_LOAD_STARTED,
  ALL_USERS_LOAD_SUCCESS,
  COMPANY_FLEETS_FULL_LIST_LOAD_FAILED,
  COMPANY_FLEETS_FULL_LIST_LOAD_STARTED,
  COMPANY_FLEETS_FULL_LIST_LOAD_SUCCESS,
  GROUP_CREATE_FAILED,
  GROUP_CREATE_RESET,
  GROUP_CREATE_STARTED,
  GROUP_CREATE_SUCCESS,
  GROUP_DELETE_FAILED,
  GROUP_DELETE_RESET,
  GROUP_DELETE_STARTED,
  GROUP_DELETE_SUCCESS,
  GROUP_EDIT_FAILED,
  GROUP_EDIT_STARTED,
  GROUP_EDIT_SUCCESS,
  GROUPS_LOAD_FAILED,
  GROUPS_LOAD_STARTED,
  GROUPS_LOAD_SUCCESS,
  GROUPS_PERMISSIONS_LOAD_FAILED,
  GROUPS_PERMISSIONS_LOAD_STARTED,
  GROUPS_PERMISSIONS_LOAD_SUCCESS,
} from './group.actionTypes';

export function loadGroupsStart() {
  return {
    type: GROUPS_LOAD_STARTED,
  };
}

export function loadGroupsFailed(payload) {
  return {
    type: GROUPS_LOAD_FAILED,
    payload,
  };
}

export function loadGroupsSuccess(payload) {
  return {
    type: GROUPS_LOAD_SUCCESS,
    payload,
  };
}

export function loadGroupsPermissionsStart() {
  return {
    type: GROUPS_PERMISSIONS_LOAD_STARTED,
  };
}

export function loadGroupsPermissionsFailed(payload) {
  return {
    type: GROUPS_PERMISSIONS_LOAD_FAILED,
    payload,
  };
}

export function loadGroupsPermissionsSuccess(payload) {
  return {
    type: GROUPS_PERMISSIONS_LOAD_SUCCESS,
    payload,
  };
}

export function createGroupStart() {
  return {
    type: GROUP_CREATE_STARTED,
  };
}

export function createGroupFailed(payload) {
  return {
    type: GROUP_CREATE_FAILED,
    payload,
  };
}

export function createGroupSuccess(payload) {
  return {
    type: GROUP_CREATE_SUCCESS,
    payload,
  };
}

export function createGroupReset() {
  return {
    type: GROUP_CREATE_RESET,
  };
}

export function editGroupStart() {
  return {
    type: GROUP_EDIT_STARTED,
  };
}

export function editGroupFailed(payload) {
  return {
    type: GROUP_EDIT_FAILED,
    payload,
  };
}

export function editGroupSuccess(payload) {
  return {
    type: GROUP_EDIT_SUCCESS,
    payload,
  };
}

export function deleteGroupStart() {
  return {
    type: GROUP_DELETE_STARTED,
  };
}

export function deleteGroupFailed(payload) {
  return {
    type: GROUP_DELETE_FAILED,
    payload,
  };
}

export function deleteGroupSuccess() {
  return {
    type: GROUP_DELETE_SUCCESS,
  };
}

export function deleteGroupReset() {
  return {
    type: GROUP_DELETE_RESET,
  };
}

export function loadAllUsersStart() {
  return {
    type: ALL_USERS_LOAD_STARTED,
  };
}

export function loadAllUsersFailed(payload) {
  return {
    type: ALL_USERS_LOAD_FAILED,
    payload,
  };
}

export function loadAllUsersSuccess(payload) {
  return {
    type: ALL_USERS_LOAD_SUCCESS,
    payload,
  };
}

export function loadAllUsersReset() {
  return {
    type: ALL_USERS_LOAD_RESET,
  };
}

export const loadCompanyFleetsFullListStart = () => ({
  type: COMPANY_FLEETS_FULL_LIST_LOAD_STARTED,
});

export const loadCompanyFleetsFullListFailed = (payload) => ({
  type: COMPANY_FLEETS_FULL_LIST_LOAD_FAILED,
  payload,
});

export const loadCompanyFleetsFullListSuccess = (payload) => ({
  type: COMPANY_FLEETS_FULL_LIST_LOAD_SUCCESS,
  payload,
});
