import React from 'react';
import { SwitchField } from '../../../components/switchField.component';

const PolicyActivationToggle = ({
  policy = [],
  handleToggle,
  titleCode,
  propertyName = 'isActive',
  ...rest
}) => {
  const currentValue = policy.length
    ? policy.some((item) => item[propertyName])
    : false;

  const onToggle = (event) => {
    const toggleHandler = (item, index) =>
      handleToggle({
        isToggle: true,
        presetNewToggleValue: !currentValue,
        propertyName,
        index,
        event,
      });

    policy.length ? policy.forEach(toggleHandler) : toggleHandler(null, 0);
  };

  return (
    <SwitchField
      {...rest}
      titleCode={titleCode}
      on={currentValue}
      onClick={onToggle}
    />
  );
};

export default PolicyActivationToggle;
