export const APPLICATIONS_LOAD_ALL_STARTED = 'APPLICATIONS_LOAD_ALL_STARTED';
export const APPLICATIONS_LOAD_ALL_FAILED = 'APPLICATIONS_LOAD_ALL_FAILED';
export const APPLICATIONS_LOAD_ALL_SUCCESS = 'APPLICATIONS_LOAD_ALL_SUCCESS';
export const APPLICATIONS_DELETE_STARTED = 'APPLICATIONS_DELETE_STARTED';
export const APPLICATIONS_DELETE_FAILED = 'APPLICATIONS_DELETE_FAILED';
export const APPLICATIONS_DELETE_SUCCESS = 'APPLICATIONS_DELETE_SUCCESS';
export const APPLICATIONS_DELETE_RESET = 'APPLICATIONS_DELETE_RESET';

export const APPLICATIONS_LOAD_STARTED = 'APPLICATIONS_LOAD_STARTED';
export const APPLICATIONS_LOAD_FAILED = 'APPLICATIONS_LOAD_FAILED';
export const APPLICATIONS_LOAD_SUCCESS = 'APPLICATIONS_LOAD_SUCCESS';

export const LOAD_APPLICATIONS_FULL_LIST_STARTED =
  'LOAD_APPLICATIONS_FULL_LIST_STARTED';
export const LOAD_APPLICATIONS_FULL_LIST_FAILED =
  'LOAD_APPLICATIONS_FULL_LIST_FAILED';
export const LOAD_APPLICATIONS_FULL_LIST_SUCCESS =
  'LOAD_APPLICATIONS_FULL_LIST_SUCCESS';
