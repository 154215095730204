import React from 'react';
import { Translate } from 'react-redux-i18n';
import { SectionDescription } from '../../../components/sectionDescription.component';
import DataTable from '../../../components/DataTable/DataTable.component';
import { deviceProfilesAssignedColumns } from '../device.constants';

const ProfilesAssigned = ({ items }) => {
  return (
    <div className={'profiles-assigned'}>
      <SectionDescription
        title={<Translate value="device.profilesAssigned" />}
        hint={<Translate value="device.profilesAttachedToThisDevice" />}
      />
      <DataTable
        allColumns={deviceProfilesAssignedColumns}
        data={items}
        noDataMessage={<Translate value="device.noProfilesAttachedToDevice" />}
        sortable
        useResizableColumns
        allColumnsResizable
      />
    </div>
  );
};

export default ProfilesAssigned;
