import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import pin from '../../../assets/images/pin.svg';
import { Vector as VectorLayer } from 'ol/layer';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import Draw from 'ol/interaction/Draw';
import Map from 'ol/Map';
import Modify from 'ol/interaction/Modify';
import GeoJSON from 'ol/format/GeoJSON';
import {
  defaults as defaultControls,
  Control,
  FullScreen,
} from 'ol/control.js';
import Geocoder from 'ol-geocoder';
import { I18n } from 'react-redux-i18n';

import 'ol-ext/dist/ol-ext.css';
import PrintDialog from 'ol-ext/control/PrintDialog';
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine';
import CanvasTitle from 'ol-ext/control/CanvasTitle';

export class Fence extends Component {
  state = {};
  mapRef = React.createRef();
  map;
  drawInteraction;
  modifyInteraction;
  defaultStyle = new Style({
    stroke: new Stroke({
      fill: new Fill({
        color: '#003562',
      }),
      color: '#dfe0e4',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(0, 53, 98, 0.33)',
    }),
    image: new Icon({
      anchor: [0.5, 24],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: pin,
      fill: '#003562',
    }),
  });
  source = new VectorSource();
  featureOverlay = new VectorLayer({
    source: this.source,
    style: this.defaultStyle,
  });

  componentDidMount() {
    this.map = new Map({
      target: `${this.props.index}||||||||||||||||||map`,
      controls: defaultControls().extend([
        new FullScreen({
          tipLabel: I18n.t('device.mapFullScreenControlLabelTip'),
        }),
        this.customControls(),
        this.geocoderControl(),
      ]),
      renderer: 'canvas',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([-102.0256307, 57.9999058]),
        zoom: 3,
      }),
    });
    this.featureOverlay.setMap(this.map);
    let features;
    try {
      features = new GeoJSON().readFeatures(this.props.fence);
    } catch (e) {}

    if (this.props.fence && features && features.length) {
      this.initFeatures();
      this.refreshCenter();
    }

    this.rebindModifyInteraction();
    this.rebindDrawInteraction();

    if (!this.props.fence || !features || !features.length) {
      this.map.addInteraction(this.drawInteraction);
    }

    // Add a ScaleLine control
    this.map.addControl(new CanvasScaleLine());

    this.map.addControl(
      new PrintDialog({
        jsPDF,
        saveAs,
        size: 'A4',
        title: I18n.t('device.export'),
      })
    );

    // Add a title control
    this.map.addControl(
      new CanvasTitle({
        title: I18n.t('device.locationHistory'),
        visible: false,
        style: new Style({
          text: new Text({
            font:
              '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
          }),
        }),
      })
    );

    // window.addEventListener('resize', this.refreshCenter);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.fence !== prevProps.fence) {
      this.initFeatures();

      try {
        if (
          !this.props.fence ||
          !new GeoJSON().readFeatures(this.props.fence).length
        ) {
          this.map.addInteraction(this.drawInteraction);
        } else {
          this.map.removeInteraction(this.drawInteraction);
        }
      } catch (e) {
        this.map.addInteraction(this.drawInteraction);
      }
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.refreshCenter);
  }

  refreshCenter = () => {
    let features = this.source ? this.source.getFeatures() : [];
    if (features && features.length) {
      this.map.getView().fit(features[0].getGeometry().getExtent());
    }
  };

  initFeatures = () => {
    try {
      this.source.clear();
      let features = new GeoJSON().readFeatures(this.props.fence);

      if (features && features.length) {
        this.source.addFeatures(features);
      }
    } catch (e) {}
  };

  rebindModifyInteraction = () => {
    if (this.modifyInteraction) {
      this.map.removeInteraction(this.modifyInteraction);
    }
    this.modifyInteraction = new Modify({
      source: this.source,
    });

    this.modifyInteraction.on('modifyend', this.onModifyEnd);
    this.map.addInteraction(this.modifyInteraction);
  };

  rebindDrawInteraction = () => {
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.drawInteraction = new Draw({
      type: 'Polygon',
      source: this.source,
    });
    this.drawInteraction.on('drawend', this.onDrawEnd);
  };

  onModifyEnd = () => {
    this.map.removeInteraction(this.drawInteraction);
    let features = this.source.getFeatures();
    let value;
    if (features && features.length) {
      value = new GeoJSON().writeFeatures(features);
    }
    this.props.onChangeSettings(this.props.index, 'fence', value);
  };

  onDrawEnd = (e) => {
    this.map.removeInteraction(this.drawInteraction);
    let features = e && e.feature ? [e.feature] : undefined;
    let value;
    if (features && features.length) {
      value = new GeoJSON().writeFeatures(features);
    }
    this.props.onChangeSettings(this.props.index, 'fence', value);
  };

  customControls = () => {
    let element = document.createElement('div');
    element.className = 'custom-control ol-unselectable ol-control';
    element.onclick = this.onClearGeometry;
    element.title = I18n.t('profiles.geofencing.clearGeometryText');
    return new Control({
      element: element,
    });
  };

  geocoderControl = () => {
    return new Geocoder('nominatim', {
      provider: 'osm',
      lang: 'en',
      placeholder: I18n.t('profiles.geofencing.searchFieldPlaceholderText'),
      limit: 5,
      debug: false,
      autoComplete: true,
      keepOpen: true,
      // targetType: 'glass-button',
      targetType: 'text-input',
    });
  };

  onClearGeometry = (e) => {
    this.source.clear();
    this.props.onChangeSettings(this.props.index, 'fence');
    this.map.addInteraction(this.drawInteraction);
  };

  render() {
    return (
      <div
        className={'fence-map'}
        id={`${this.props.index}||||||||||||||||||map`}
        ref={this.mapRef}
      />
    );
  }
}

export default Fence;

Fence.propTypes = {
  onChangeSettings: PropTypes.func,
  fence: PropTypes.string,
  index: PropTypes.number,
};
