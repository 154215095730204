import { NetworkStatus } from '../../common/constants';
import { ProfilesV2ActionTypes } from './profilesV2.actionTypes';

const profilesV2 = (
  state = {
    data: [],
    nextUrl: null,
    status: NetworkStatus.NONE,
    error: undefined,
    nextPageStatus: NetworkStatus.NONE,
    nextPageError: undefined,
  },
  action
) => {
  switch (action.type) {
    case ProfilesV2ActionTypes.LOAD_PROFILES_BY_COMPANY_STARTED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              status: NetworkStatus.STARTED,
              error: undefined,
              data: [],
            }
          : {
              nextPageStatus: NetworkStatus.STARTED,
              nextPageError: undefined,
            }),
      };
    case ProfilesV2ActionTypes.LOAD_PROFILES_BY_COMPANY_FAILED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              status: NetworkStatus.ERROR,
              error: action.payload?.error,
            }
          : {
              nextPageStatus: NetworkStatus.ERROR,
              nextPageError: action.payload?.error,
            }),
      };
    case ProfilesV2ActionTypes.LOAD_PROFILES_BY_COMPANY_SUCCESS: {
      const { payload: { data, isInitialLoad, ...rest } = {} } = action;
      return {
        ...state,
        ...rest,
        data: [...(isInitialLoad ? [] : state.data), ...data],
        ...(isInitialLoad
          ? {
              status: NetworkStatus.DONE,
              error: undefined,
            }
          : {
              nextPageStatus: NetworkStatus.DONE,
              nextPageError: undefined,
            }),
      };
    }

    default:
      return state;
  }
};

export default profilesV2;
