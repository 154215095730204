export const filterConditions = {
  EQUAL: 'EQ',
  GREATER_THAN: 'GT',
  LESS_THAN: 'LT',
  GREATER_THAN_OR_EQUAL: 'GTE',
  LESS_THAN_OR_EQUAL: 'LTE',
  NOT_EQUAL: 'NE',
  CONTAINS: 'CN',
  DOES_NOT_CONTAIN: 'NCN',
  // NOT_CONTAINED_IN: for map locations
  NOT_CONTAINED_IN: 'NC',
  BEGINS_WITH: 'BW',
  DOES_NOT_BEGIN_WITH: 'NBW',
  ENDS_WITH: 'EW',
  DOES_NOT_END_WITH: 'NEW',
};
