export const DEVICES_LOAD_STARTED = 'DEVICES_LOAD_STARTED';
export const DEVICES_LOAD_FAILED = 'DEVICES_LOAD_FAILED';
export const DEVICES_LOAD_SUCCESS = 'DEVICES_LOAD_SUCCESS';
export const DEVICES_RECEIVED_UPDATES = 'DEVICES_RECEIVED_UPDATES';
export const DEVICES_SEND_REMOTE_COMMAND_STARTED =
  'DEVICES_SEND_REMOTE_COMMAND_STARTED';
export const DEVICES_SEND_REMOTE_COMMAND_FAILED =
  'DEVICES_SEND_REMOTE_COMMAND_FAILED';
export const DEVICES_SEND_REMOTE_COMMAND_SUCCESS =
  'DEVICES_SEND_REMOTE_COMMAND_SUCCESS';
export const DEVICES_SEND_REMOTE_COMMAND_RESET =
  'DEVICES_SEND_REMOTE_COMMAND_RESET';
export const DEVICES_REMOVE_STARTED = 'DEVICES_REMOVE_STARTED';
export const DEVICES_REMOVE_FAILED = 'DEVICES_REMOVE_FAILED';
export const DEVICES_REMOVE_SUCCESS = 'DEVICES_REMOVE_SUCCESS';
export const DEVICES_REMOVE_RESET = 'DEVICES_REMOVE_RESET';
