import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import './selectedProfiles.scss';
import DataTable from '../DataTable/DataTable.component';
import { AttributeType } from '../../common/constants';
import { SectionDescription } from '../sectionDescription.component';

const selectedProfilesColumns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'profiles.fields.name',
  },
  {
    key: 'updatedAt',
    type: AttributeType.DATE,
    titleCode: 'profiles.fields.lastUpdated',
  },
];

const SelectedProfiles = ({ items }) => (
  <div className="selected-profiles">
    <SectionDescription title={I18n.t('enrollments.selectedProfilesText')} />
    <DataTable allColumns={selectedProfilesColumns} data={items} useFlex />
  </div>
);

SelectedProfiles.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.string),
};

export default SelectedProfiles;
