const services = {
  authentication: '/customer/account/auth',
  SSO: '/sso/',
  SSOLoginByUUID: '/login_by_uuid/',
  SSOPreLoginByUUID: '/pre_login/',
  SSOAzureOAuth2Authentication: '/sso/oauth2/azure',
  SSOAzureOAuth2LinkUserAccount: '/sso/oauth2/azure/bind_user_to_sso/',
  SSOAzureSAML2LinkUserAccount: '/sso/saml/azure/bind_user_to_sso/',
  SSOAzureSAML2LinkUserAccountSettings: '/saml/settings/',
  SSOSAML2GetCompanies: '/saml/get_companies/',
  systemMessage: '/system-message/',
  // todo: rename to get notifications
  getAlerts: '/get_alerts/',
  updateDisplayOnFrontendAlerts: '/update_display_on_frontend_alerts/',
  updateUnreadAlerts: '/update_unread_alerts/',
  createAlert: '/alert/create/',
  alertList: '/alert/list/',
  alertDelete: '/alert/delete/',
  alertUpdate: '/alert/update/',
  alertRetrieve: '/alert/retrieve/',
  loadUsersRequestedByAdmin: 'user-management/users/',
  getUserActivity: '/user-management/get_user_activity/',
  loadUsersRequestedByNonAdmin: 'user-management/users/non-admin/',
  getCustomerInfo: '/user-management/user/info/',
  getUserGroupPermissions: '/user-management/get_group_permissions/',
  getGlobalPermissionsForUser:
    '/user-management/get_global_permissions_for_user/',
  changeCustomerPassword: '/user-management/customer/change_password/',
  updateCustomSettingsForUser:
    '/user-management/update_custom_settings_for_user/', // used by columns toggle feature
  getCustomSettingsForUser: '/user-management/get_custom_settings_for_user/', // used by columns toggle feature
  deleteUsers: '/user-management/delete_users/',
  createProfile: '/profile/create',
  getAllProfiles: '/profile/getall/',
  getProfile: '/profile/get/',
  updateProfile: '/profile/update',
  deleteProfile: '/profile/delete',
  assignProfiles: '/profile/assign',
  unassignProfiles: '/profile/unassign',
  cloneProfiles: '/clone_profiles/',
  createPolicyProfile: '/profile/policy/add',
  getPolicyProfile: '/profile/policy/get/',
  deletePolicyProfile: '/profile/policy/delete/',
  getDeviceProfiles: '/profile/device/',
  assignFilesToProfiles: '/profile/policy/assign',
  getEnrollments: '/enrollment/getall/',
  getDeviceEnrollment: '/enrollment/get/',
  createDeviceEnrollment: '/enrollment/create',
  updateDeviceEnrollment: '/enrollment/update',
  deleteDeviceEnrollment: '/enrollment/delete',
  getAllApplication: '/application/list/',
  getAllApplicationNewProfile: '/application_new_profile_list/',
  getAllApplications: '/application/latest/',
  getProfileApplications: 'application/profile/',
  getApplication: '/application/get/',
  application: '/application',
  applications: '/applications',
  updateApplication: '/application/update',
  deleteApplication: '/application/delete',
  loadApplication: '/application/get/',
  assignAppsToProfles: '/application/assign',
  getDeviceApps: '/application/device/',
  getAllFleets: '/fleets/getall/',
  createFleet: '/fleets/create',
  updateFleet: '/fleets/update/',
  deleteFleet: '/fleets/delete',
  getCompanyFleetsFullList: 'fleets_admin/getall/', // returns a full list of all the company's fleets, without any filters applied.
  getAllDevicesByFleetId: '/devices/getall/',
  getAllDevicesByCompanyId: '/devices/company/',
  getDevices: '/devices/',
  devices: '/devices/',
  removeDevices: '/devices/delete/',
  changeDeviceStatus: '/device/change_device_status/',
  sendRemoteCommand: 'devices/commands',
  unenrollDevices: '/devices/unenroll/',
  transferDevices: '/devices/transfer',
  enrollDevice: '/enroll/',
  uploadFiles: '/files/upload',
  createRemoteFiles: '/remote_files/create/',
  getFiles: '/files/getall',
  deleteFiles: '/files/delete',
  certificates: '/s3/certificates',
  deleteCertificates: '/delete_certs/',
  getStatistics: '/statistic',
  companies: '/companies/',
  search: 'search/all',
  analyticsDataCollector: 'analytics_data_collector',
  tasks: '/device_installation_status',
  timezones: '/tzlist',
  authenticateUser: '/authenticate_user/',
  login: '/login_user/',
  logout: '/logout_user/',
  osrmRoute: '//router.project-osrm.org/route/v1/driving/',

  // v2
  profileListByCompany: '/profile/list/',
  profileListByFleet: '/profile/list_by_fleet/',
  profileById: '/profile/retrieve/',
  assignFleetsAndPublish: '/profile/assign_fleets_and_publish/',
  assignFleets: '/profile/assign_fleets/',
  profileUnpublish: '/profile/unpublish/',
  profilesClone: '/profile/clone/',
  unpublishAndClearFleets: '/profile/unpublish_and_clear_fleets/',
  profilesCreateBrandNew: '/profile/create_brand_new/',
  profilesCreateBrandNewAndPublish: '/profile/create_brand_new_and_publish/',
  profilesCreateNewVersion: '/profile/create_new_version/',
  profilesCreateNewVersionAndPublish:
    '/profile/create_new_version_and_publish/',
  saveAndPublish: '/profile/save_and_publish/',
  saveProfileAsDraft: '/profile/save_as_draft/',
  unassignFleetsFromProfile: '/profile/unassign_fleets/',
  deleteProfileV2: '/profile/delete/',
  createNewFleet: '/fleet/create/',
  fleetById: '/fleet/retrieve/',
  fleetUpdate: '/fleet/update/',
  fleetDelete: '/fleet/delete/',
  fleetListByCompany: '/fleet/list/',
  fleetByProfileId: '/fleet/list_by_profile/',
  fleetsForPublishedProfile: '/fleet/list_for_published_profiles/',
  getFleetDevicesById: '/fleet/get_devices/',
  assignProfilesV2ToFleetV2: '/fleet/assign_profiles/',
  unassignProfilesV2FromFleetV2: '/fleet/unassign_profiles/',
  companyFleetsFullList: '/fleets_admin/list/', // returns a full list of all the company's fleets, without any filters applied.
  moveFleet: '/fleet/moving_fleet/',
  getProfileApps: '/profile/app_list/',
  getPreferences: '/user-management/user/preferences/',

  // v1 backward compatibility
  assignV1ProfilesToV2Fleets: '/fleet_v2/assign_profiles_v1/',
  unassignV1ProfilesFromV2Fleets: '/fleet_v2/unassign_profiles_v1/',
  getV1ProfilesAssignedToV2Fleet: '/fleet_v2/list_profile_v1/',
  getV2FleetsAssignedToV1Profile: '/profile_v1/list_fleet_v2/',
  migrateV1ProfilesToV2: '/migrate_profilesv1_to_profilesv2/',
};

export default services;
