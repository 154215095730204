import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import {
  communicationChannelOptions,
  communicationChannels,
} from '../../features/createAlert/createAlert.constants';
import { SectionDescription } from '../sectionDescription.component';
import RadioButtonGroup from '../RadioButtonGroup';
import { InputField } from '../inputField.component';
import { SelectField } from '../selectField.component';
import { loadGroups } from '../../features/group/groups.thunk';
import TextArea from '../TextArea';

const getUserGroupOptions = (userGroups = []) =>
  userGroups.map(({ id, name }) => ({
    value: id,
    title: name,
  }));

class CommunicationChannel extends Component {
  onCommunicationChannelChange = ({ target: { value } = {} }) => {
    const { onPropertyChange, alert: { alertChannel } = {} } = this.props;
    const newAlertChannelObj = { ...alertChannel, communicationChannel: value };
    delete newAlertChannelObj.singleEmail;
    delete newAlertChannelObj.userGroups;

    onPropertyChange({
      key: 'alertChannel',
      value: newAlertChannelObj,
    });
  };

  onUserGroupsChange = ({ target: { name, selectedOptions } = {} }) => {
    const { onPropertyChange, alert: { alertChannel } = {} } = this.props;
    let value = Array.from(selectedOptions, (option) => option.value);
    onPropertyChange({
      key: 'alertChannel',
      value: { ...alertChannel, [name]: value },
    });
  };

  onChange = ({ target: { value, name } = {} }) => {
    const { onPropertyChange, alert: { alertChannel } = {} } = this.props;
    onPropertyChange({
      key: 'alertChannel',
      value: { ...alertChannel, [name]: value },
    });
  };

  render() {
    const {
      allUserGroups,
      alert: {
        alertChannel: {
          communicationChannel,
          userGroups,
          alertFrequencyRepeatValue = 0,
          alertFrequencyHours = 0,
          singleEmail,
        } = {},
      } = {},
    } = this.props;

    return (
      <React.Fragment>
        <SectionDescription title={I18n.t('alerts.setCommunicationChannel')} />
        <RadioButtonGroup
          checkedValue={communicationChannel}
          options={Object.values(communicationChannelOptions)}
          onChange={this.onCommunicationChannelChange}
        />

        <section>
          {communicationChannel === communicationChannels.SINGLE_EMAIL ? (
            <TextArea
              label={I18n.t('alerts.singleEmailCommunicationChannel')}
              hint={
                <Translate value="alerts.singleEmailCommunicationChannelHint" />
              }
              onChange={this.onChange}
              placeholder={I18n.t(
                'alerts.communicationChannelEmailPlaceholder'
              )}
              name="singleEmail"
              value={singleEmail}
              autoGrow
            />
          ) : null}
          {communicationChannel === communicationChannels.USER_GROUPS ? (
            <SelectField
              title={
                <Translate value="alerts.userGroupsCommunicationChannel" />
              }
              hint={
                <Translate value="alerts.userGroupsCommunicationChannelHint" />
              }
              value={userGroups}
              onChange={this.onUserGroupsChange}
              options={getUserGroupOptions(allUserGroups)}
              name="userGroups"
              multiple
            />
          ) : null}
          <Translate value="alerts.emailAlertFrequency" />
          <section className="alert-creation__communication-channel-repeat-container">
            <Translate value="alerts.repeat" />
            <InputField
              placeholder={0}
              value={alertFrequencyRepeatValue}
              onChange={this.onChange}
              name="alertFrequencyRepeatValue"
              additionalClassName="alert-creation__communication-channel-repeat-input-field"
            />
            <Translate value="alerts.timesAtAnIntervalOf" />
            <InputField
              placeholder={0}
              value={alertFrequencyHours}
              onChange={this.onChange}
              name="alertFrequencyHours"
              additionalClassName="alert-creation__communication-channel-repeat-input-field"
            />
            <Translate value="alerts.hours" />
          </section>
          <Translate
            value="alerts.emailAlertFrequencyValuesDescription"
            alertFrequencyRepeatValue={+alertFrequencyRepeatValue + 1 || 1}
            alertFrequencyHours={+alertFrequencyHours || 0}
          />
          <p>{`**${I18n.t('alerts.alertChannelSettingFootNote')}`}</p>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allUserGroups: state.groups.userGroups,
});

const mapDispatchToProps = (dispatch) => ({
  loadGroups: () => dispatch(loadGroups()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationChannel);
