import {
  COLUMNS_TOGGLE_INITIALIZE_ALL_AVAILABLE_COLUMNS,
  COLUMNS_TOGGLE_REORDER_COLUMNS,
  COLUMNS_TOGGLE_TOGGLE_COLUMN,
  GET_SAVED_TABLE_COLUMNS_FAILED,
  GET_SAVED_TABLE_COLUMNS_STARTED,
  GET_SAVED_TABLE_COLUMNS_SUCCESS,
  SAVE_TABLE_COLUMNS_FAILED,
  SAVE_TABLE_COLUMNS_STARTED,
  SAVE_TABLE_COLUMNS_SUCCESS,
} from './columnsToggle.actionsTypes';

export const toggleColumn = (payload) => ({
  type: COLUMNS_TOGGLE_TOGGLE_COLUMN,
  payload,
});

export const initializeAllAvailableColumns = (payload) => ({
  type: COLUMNS_TOGGLE_INITIALIZE_ALL_AVAILABLE_COLUMNS,
  payload,
});

export const reorderColumns = (payload) => ({
  type: COLUMNS_TOGGLE_REORDER_COLUMNS,
  payload,
});

export const saveTableColumnsStarted = () => ({
  type: SAVE_TABLE_COLUMNS_STARTED,
});

export const saveTableColumnsFailed = (payload) => ({
  type: SAVE_TABLE_COLUMNS_FAILED,
  payload,
});

export const saveTableColumnsSuccess = (payload) => ({
  type: SAVE_TABLE_COLUMNS_SUCCESS,
  payload,
});

export const getSavedTableColumnsStarted = () => ({
  type: GET_SAVED_TABLE_COLUMNS_STARTED,
});

export const geSavedTableColumnsFailed = (payload) => ({
  type: GET_SAVED_TABLE_COLUMNS_FAILED,
  payload,
});

export const getSavedTableColumnsSuccess = (payload) => ({
  type: GET_SAVED_TABLE_COLUMNS_SUCCESS,
  payload,
});
