import { AttributeType } from '../../common/constants';
import {
  ApplicationsRoutes,
  ProfilesRoutes,
  RootRoutes,
} from '../../utils/routes';
import deliveredDevices from './components/deliveredDevices';
import failedDevices from './components/failedDevices';
import { filterConditions } from '../../constants/filter';

export const TaskStatusTypes = {
  'IN PROGRESS': 'IN PROGRESS',
  COMPLETED: 'COMPLETED',
  SCHEDULED: 'SCHEDULED',
};

export const tasksTableColumns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'profiles.fields.name',
    ref: `${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}`,
    refId: 'id',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'profiles.fields.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'devicesDeliveredCount',
    type: AttributeType.TEXT,
    titleCode: 'installationStatus.installed',
    searchable: false,
    renderer: deliveredDevices,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'devicesFailedCount',
    type: AttributeType.TEXT,
    titleCode: 'installationStatus.pending',
    searchable: false,
    renderer: failedDevices,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
];

export const tasksApplicationsColumns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.applicationName',
    ref: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}`,
    refId: 'id',
    searchable: false,
  },
  {
    key: 'idName',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.packageId',
    searchable: false,
  },
  {
    key: 'size',
    type: AttributeType.SIZE,
    titleCode: 'applications.fields.size',
    searchable: false,
  },
  {
    key: 'status',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.status',
    searchable: false,
  },
  {
    key: 'version',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.version',
    searchable: false,
  },
  {
    key: 'devicesInstalled',
    type: AttributeType.TEXT,
    titleCode: 'installationStatus.devicesInstalled',
    searchable: false,
  },
  {
    key: 'devicesFailed',
    type: AttributeType.TEXT,
    titleCode: 'installationStatus.devicesFailed',
    searchable: false,
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'installationStatus.lastUpdated',
    searchable: false,
  },
];

export const taskDevicesColumns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.deviceName',
  },
  {
    key: 'fleet',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.fleetName',
  },

  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'devices.fields.lastSeen',
  },
  // {
  //     key: 'name',
  //     type: AttributeType.TEXT,
  //     titleCode: 'Device',
  //     ref: `${DeviceRoutes}`,
  //     refId: 'id',
  // },
  // {
  //     key: 'osVersion',
  //     type: AttributeType.TEXT,
  //     titleCode: 'Platform',
  // },
  // {
  //     key: 'coordinates',
  //     type: AttributeType.COORDINATES,
  //     titleCode: 'Location',
  // },
  // {
  //     key: 'batteryLevel',
  //     type: AttributeType.PERCENTAGE,
  //     titleCode: 'Battery Level',
  //     renderer: BatteryLevel,
  // },
  // {
  //     key: 'lastCheckin',
  //     type: AttributeType.DATE,
  //     titleCode: 'Last Online',
  // },
  // {
  //     key: 'manufacturer',
  //     type: AttributeType.TEXT,
  //     titleCode: 'Manufacturer',
  // },
  // {
  //     key: 'model',
  //     type: AttributeType.TEXT,
  //     titleCode: 'Model',
  // },
  // {
  //     key: 'macAddress',
  //     type: AttributeType.TEXT,
  //     titleCode: 'MAC Address',
  // },
  // {
  //     key: 'ipAddress',
  //     type: AttributeType.TEXT,
  //     titleCode: 'IP Address',
  // },
  // {
  //     key: 'totalMemory',
  //     type: AttributeType.SIZE,
  //     titleCode: 'Total Memory',
  // },
  // {
  //     key: 'availableMemory',
  //     type: AttributeType.SIZE,
  //     titleCode: 'Available Memory',
  // },
  // {
  //     key: 'totalStorage',
  //     type: AttributeType.SIZE,
  //     titleCode: 'Total Storage',
  // },
  // {
  //     key: 'availableStorage',
  //     type: AttributeType.SIZE,
  //     titleCode: 'Available Storage',
  // },
  // {
  //     key: 'hardwareSerialNumber',
  //     type: AttributeType.TEXT,
  //     titleCode: 'Serial Number',
  // },
  // {
  //     key: 'imei', type:
  //     AttributeType.TEXT,
  //     titleCode: 'IMEI',
  // },
];
