import services from '../../utils/services';
import client, { clientV2 } from '../../utils/client';

export async function loadAllApplicationsApi(profileId) {
  return client.get(services.getAllApplications + profileId, {});
}

export async function deleteApplicationsApi(data) {
  return clientV2.post(services.deleteApplication, data);
}
// export async function uploadApplicationsApi(data, company_id, onUploadProgress) {
//     return client.post(
//         services.application,  data,
//         {
//             headers: {'Content-Type': 'multipart/form-data' },
//             params: {company_id},
//             onUploadProgress,
//         }
//     );
// };
