import React, { Component, Fragment } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { DragDropContext } from 'react-beautiful-dnd';
import { Glyphicon } from 'react-bootstrap';
import DragNDropList from '../../../components/DragNDropList/DragNDropList.component';

class UserManagement extends Component {
  render() {
    return (
      <Fragment>
        <div className={'user-management-wrapper'}>
          <div className={'user-management-wrapper-content'}>
            <div className={'user-management'}>
              <DragDropContext onDragEnd={this.onDragEnd}>
                {this.renderUserManagement()}
                {this.renderAllUsersList()}
              </DragDropContext>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  renderUserManagement = () => {
    const { userList } = this.props;
    return (
      <div className={'user-management-left-column'}>
        <SectionDescription title={I18n.t('users.userManagement')} />
        <div className={'user-management-members-list'}>
          <DragNDropList
            droppableListId="usersList"
            items={userList.map((item) => ({ username: item }))}
            idSubstitute="username"
            itemOptions={{
              displayTextKeys: {
                leftCell: 'username',
              },
            }}
            hasNoBorders
            listHeader={
              <div className={'user-management-members-list-header'}>
                <div className={'user-management-members-list-header__title'}>
                  {I18n.t('users.usernameTitle')}
                </div>
              </div>
            }
            emptyListContent={
              <div className={'app-management-list-content-empty-notification'}>
                <Glyphicon
                  className={
                    'app-management-list-content-empty-notification__icon'
                  }
                  glyph="cloud-upload"
                />
                <div
                  className={
                    'app-management-list-content-empty-notification__description'
                  }
                >
                  {I18n.t('users.noUsersInGroupText')}
                  <br />
                  {I18n.t('users.hintToAddUsers')}
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  };

  renderAllUsersList = () => {
    let existingUsers = this.props.existingUsers
      .map((item) => {
        if (this.props.userList.indexOf(item.username) === -1) {
          return item;
        } else {
          return undefined;
        }
      })
      .filter((item) => {
        return item !== undefined;
      });
    return (
      <div className={'user-management-right-column'}>
        <SectionDescription title={I18n.t('users.existingUsersTitle')} />
        <div className={'user-management-members-list'}>
          <DragNDropList
            droppableListId="existingUser"
            items={existingUsers}
            idSubstitute="username"
            itemOptions={{
              displayTextKeys: {
                leftCell: 'username',
                rightCell: 'email',
              },
            }}
            hasNoBorders
            listHeader={
              <div className={'user-management-members-list-header'}>
                <div className={'user-management-members-list-header__title'}>
                  {I18n.t('users.allUsersListHeaderUsername')}
                </div>
                <div className={'user-management-members-list-header__title'}>
                  {I18n.t('users.allUsersListHeaderEmail')}
                </div>
              </div>
            }
            emptyListContent={
              <div className={'app-management-list-content-empty-notification'}>
                <div
                  className={
                    'app-management-list-content-empty-notification__description'
                  }
                >
                  <Translate value="users.allUsersEmptyText" />
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  };

  onDragEnd = (result) => {
    const expression = new RegExp(`^${result.source.droppableId}_`);
    if (
      result.source.droppableId === 'existingUser' &&
      result.destination !== null &&
      result.destination.droppableId === 'usersList'
    ) {
      this.props.addUser(result.draggableId.replace(expression, ''));
    }
    if (
      result.source.droppableId === 'usersList' &&
      (result.destination === null ||
        result.destination.droppableId === 'existingUser')
    ) {
      this.props.removeUser(result.draggableId.replace(expression, ''));
    }
  };
}

export default UserManagement;
