import { I18n } from 'react-redux-i18n';
import { ACCESS_TYPES } from '../../constants/accessTypes';
import { ADMINS_GROUP_ID, ALL_USERS_GROUP_ID } from '../../constants/users';

const isGlobalPermissionReadWrite = (allPermissions, globalPermissions) =>
  allPermissions.every((item) => globalPermissions.includes(item));

const isGlobalPermissionReadOnly = (allPermissions, globalPermissions) =>
  allPermissions.filter((item) => globalPermissions.includes(item)).length ===
  1;

export const getGlobalPermissions = (
  allAvailablePermissions = {},
  globalPermissions = []
) =>
  Object.keys(allAvailablePermissions).reduce((acc, value) => {
    const readWrite = isGlobalPermissionReadWrite(
      allAvailablePermissions[value],
      globalPermissions
    );
    const read = isGlobalPermissionReadOnly(
      allAvailablePermissions[value],
      globalPermissions
    );
    return {
      ...acc,
      [value]: {
        key: value,
        title: value,
        readWrite,
        read,
      },
    };
  }, {});

export const getGroupGlobalPermissionsWithAccesses = (
  allAvailablePermissions = [],
  globalPermissions = {}
) =>
  allAvailablePermissions.reduce(
    (acc, { id, isTitle, showReadOnlyCheckBox, ...rest }) => [
      ...acc,
      {
        key: id,
        id,
        ...rest,
        ...(isTitle
          ? {}
          : {
              ...(showReadOnlyCheckBox
                ? {
                    [ACCESS_TYPES.READ]:
                      globalPermissions[id] === ACCESS_TYPES.READ,
                  }
                : {
                    [ACCESS_TYPES.READ_WRITE]:
                      globalPermissions[id] === ACCESS_TYPES.READ_WRITE,
                    [ACCESS_TYPES.READ]:
                      globalPermissions[id] === ACCESS_TYPES.READ,
                  }),
            }),
      },
    ],
    []
  );

export const sortGlobalPermissionsByType = (
  allAvailablePermissions = {},
  globalPermissions = []
) =>
  Object.keys(allAvailablePermissions).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: globalPermissions.filter(
        (permission) =>
          allAvailablePermissions[curr] &&
          allAvailablePermissions[curr].includes(permission)
      ),
    }),
    {}
  );

export const sortGroupGlobalPermissionsConfigByAccess = (
  groupGlobalPermissionsConfig = {}
) =>
  Object.keys(groupGlobalPermissionsConfig).reduce(
    (acc, config) => {
      const value = groupGlobalPermissionsConfig[config];
      return {
        ...acc,
        [value]: [...acc[value], config],
      };
    },
    {
      [ACCESS_TYPES.READ]: [],
      [ACCESS_TYPES.READ_WRITE]: [],
      [ACCESS_TYPES.NONE]: [],
    }
  );

export const convertPermissionsListToObjWithAccessTypes = (
  permissionsList = [],
  accessType = ''
) => {
  if (!permissionsList) return {};
  return permissionsList.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: accessType,
    }),
    {}
  );
};

export const getAccessFromGlobalPermissionsForUser = (
  globalPermissionsForUser,
  permissionKey,
  accessType
) => {
  if (!(globalPermissionsForUser && permissionKey && accessType)) {
    return false;
  }
  const permission =
    globalPermissionsForUser[permissionKey] || ACCESS_TYPES.NONE;
  return Array.isArray(accessType)
    ? accessType.includes(permission)
    : permission === accessType;
};

export const getUserManagementDialogStructure = ({
  hasReadWritePermissions = false,
  isAdminsGroupHidden = false,
  allUsersProps = {},
  adminsGroupProps = {},
  userGroups = [],
  nonAdminGroupRoute = '',
}) => [
  ...(hasReadWritePermissions
    ? [
        {
          key: ALL_USERS_GROUP_ID,
          title: I18n.t('users.allUsers'),
          ...allUsersProps,
        },
      ]
    : []),
  ...(isAdminsGroupHidden
    ? []
    : [
        {
          key: ADMINS_GROUP_ID,
          title: I18n.t('users.admins'),
          ...adminsGroupProps,
        },
      ]),
  ...userGroups.reduce((acc, curr) => {
    if (curr === ADMINS_GROUP_ID) {
      return acc;
    }
    return [
      ...acc,
      {
        key: curr,
        title: curr,
        route: `${nonAdminGroupRoute}/${curr}`,
      },
    ];
  }, []),
  // {key: 'Unassigned', route: '/users/all/unassigned', exact: true},
];

export const getGroupById = (groups, groupId) =>
  groups.find((group) => group.id === groupId) || {};

export const getGroupsById = (groups = [], groupIds = []) =>
  groups.filter((group) => groupIds.includes(group.id));
