import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import enrollments from '../features/enrollments/enrollments.reducer';
import mapView from '../features/mapView/mapView.reducer';
import task from '../features/task/task.reducer';
import tasks from '../features/tasks/tasks.reducer';
import login from '../features/login/login.reducer';
import notifications from '../redux/notifications.reducer';
import profile from '../features/profile/profile.reducer';
import devices from '../features/devices/devices.reducer';
import application from '../features/application/application.reducer';
import fleets from '../features/fleets/fleets.reducer';
import files from '../redux/files.reducer';
import enrollment from '../features/enrollment/enrollment.reducer';
import applications from '../features/applications/applications.reducer';
import profiles from '../features/profiles/profiles.reducer';
import profileV2 from '../features/profile/profileV2.reducer';
import profilesV2 from '../features/profilesV2/profilesV2.reducer';
import device from '../features/device/device.reducer';
import company from '../redux/company.reducer';
import statistics from '../redux/statistics.reducer';
import search from '../redux/search.reducer';
import fleet from '../features/fleet/fleet.reducer';
import filters from '../redux/filters.reducer';
import user from '../features/user/user.reducer';
import users from '../features/users/users.reducer';
import groups from '../features/group/groups.reducer';
import usersLogs from '../features/usersLogs/usersLogs.reducer';
import account from '../features/account/account.reducer';
import password from '../features/password/password.reducer';
import settings from '../features/settings/settings.reducer';
import popup from '../features/popup/popup.reducer';
import columnsToggle from '../redux/columnsToggle.reducer';
import alerts from '../features/alerts/alerts.reducer';
import alert from '../features/alert/alert.reducer';
import certificates from '../features/certificates/certificates.reducer';
import systemMessage from '../features/systemMessage/systemMessage.reducer';
import upload from '../features/upload/upload.reducer';
import banner from '../features/Banner/banner.reducer';
import fleetV2 from '../features/fleet/fleetV2.reducer';
import fleetsV2 from '../features/fleets/fleetsV2.reducer';
import preferences from '../features/preferences/preferences.reducer';

export const configureReducer = () =>
  combineReducers({
    login,
    profile,
    profiles,
    profileV2,
    profilesV2,
    fleetsV2,
    fleetV2,
    application,
    applications,
    fleets,
    files,
    device,
    enrollment,
    enrollments,
    devices,
    company,
    statistics,
    search,
    certificates,
    notifications,
    fleet,
    filters,
    tasks,
    task,
    mapView,
    groups,
    user,
    users,
    usersLogs,
    account,
    password,
    settings,
    i18n: i18nReducer,
    popup,
    columnsToggle,
    alerts,
    alert,
    banner,
    systemMessage,
    upload,
    preferences,
  });

export type RootState = ReturnType<typeof configureReducer>;
