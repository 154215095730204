import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import {
  communicationChannelOptions,
  communicationChannels,
} from '../../createAlert/createAlert.constants';
import { getGroupsById } from '../../../common/utilities/users';
import CommunicationChannel from '../../../components/alertCreationAndEditing/CommunicationChannel.component';

const CommunicationChannelTab = ({
  isEditingMode = false,
  allGroups = [],
  data: {
    alertChannel: {
      alertFrequencyRepeatValue,
      alertFrequencyHours,
      singleEmail,
      communicationChannel,
      userGroups,
    } = {},
  } = {},
  editingModeData,
  handlePropertyChangeInEditingMode,
}) => {
  const renderContent = () => (
    <CommunicationChannel
      alert={editingModeData}
      onPropertyChange={handlePropertyChangeInEditingMode}
    />
  );

  const selectedGroups = getGroupsById(allGroups, userGroups);
  const renderReadOnlyContent = () => (
    <ReadOnlyForm
      key="communication-channel-readonly-form"
      items={[
        {
          title: I18n.t('alerts.alertChannel'),
          value: communicationChannelOptions[communicationChannel].label,
        },
        ...(communicationChannel === communicationChannels.SINGLE_EMAIL
          ? [
              {
                title: I18n.t('alerts.singleEmailCommunicationChannel'),
                valueList: singleEmail.split(/\r?\n/),
              },
            ]
          : []),
        ...(communicationChannel === communicationChannels.USER_GROUPS
          ? [
              {
                title: I18n.t('alerts.userGroupsCommunicationChannel'),
                valueList: selectedGroups.map(({ name }) => name),
              },
            ]
          : []),
        {
          title: I18n.t('alerts.emailAlertFrequency'),
          value: I18n.t('alerts.emailAlertFrequencyDescription', {
            alertFrequencyRepeatValue,
            alertFrequencyHours,
          }),
        },
      ]}
      headerTitle={
        <Translate
          className={'section-description__title--small'}
          value={'alerts.alertType'}
        />
      }
    />
  );

  return (
    <section>
      {isEditingMode ? renderContent() : renderReadOnlyContent()}
    </section>
  );
};

export default CommunicationChannelTab;
