export const ENROLLMENTS_LOAD_STARTED = 'ENROLLMENTS_LOAD_STARTED';
export const ENROLLMENTS_LOAD_FAILED = 'ENROLLMENTS_LOAD_FAILED';
export const ENROLLMENTS_LOAD_SUCCESS = 'ENROLLMENTS_LOAD_SUCCESS';
export const ENROLLMENTS_DELETE_STARTED = 'ENROLLMENTS_DELETE_STARTED';
export const ENROLLMENTS_DELETE_FAILED = 'ENROLLMENTS_DELETE_FAILED';
export const ENROLLMENTS_DELETE_SUCCESS = 'ENROLLMENTS_DELETE_SUCCESS';
export const ENROLLMENTS_DELETE_RESET_STATUS =
  'ENROLLMENTS_DELETE_RESET_STATUS';

export function loadEnrollmentsStarted(isInitialLoad = false) {
  return {
    type: ENROLLMENTS_LOAD_STARTED,
    payload: {
      isInitialLoad,
    },
  };
}

export function loadEnrollmentsFailed(payload) {
  return {
    type: ENROLLMENTS_LOAD_FAILED,
    payload,
  };
}

export function enrollmentsLoaded(payload) {
  return {
    type: ENROLLMENTS_LOAD_SUCCESS,
    payload,
  };
}

export function enrollmentsDeletingStarted() {
  return {
    type: ENROLLMENTS_DELETE_STARTED,
  };
}

export function enrollmentsDeletingFailed() {
  return {
    type: ENROLLMENTS_DELETE_FAILED,
  };
}

export function enrollmentsDeleted() {
  return {
    type: ENROLLMENTS_DELETE_SUCCESS,
  };
}

export function enrollmentsDeletingReset() {
  return {
    type: ENROLLMENTS_DELETE_RESET_STATUS,
  };
}
