import { mapViewDeviceUpdatesReceived } from '../mapView/mapView.actions';
import { deserializeDeviceChanges } from '../mapView/mapView.thunk';
import { devicesLoaded } from './devices.actions';
import { createSocketActions } from '../../store/websockets';
import { deserializeDevice } from '../device/device.serializer';
import {
  batteryLevelNotificationReceived,
  fencingNotificationReceived,
  securityComplianceNotificationReceived,
} from '../../redux/nofitications.actions';
import { deserializeDeviceNotification } from '../../redux/notifications.serializer';
import { fleetLoadingDone } from '../fleets/fleets.actions';

const [subscribeChanges, unsubscribeChanges] = createSocketActions(
  '@@WebSockets/DEVICES',
  '',
  function (event, dispatch) {
    switch (event.event) {
      case 'mapView.update': {
        return mapViewDeviceUpdatesReceived(
          deserializeDeviceChanges(JSON.parse(event.data))
        );
      }
      case 'devices.update': {
        return devicesLoaded(event.data.map(deserializeDevice));
      }
      case 'fleet.update': {
        return fleetLoadingDone({
          isUpdateFromSockets: true,
          devices: event.data.map(deserializeDevice),
          id: event.params ? event.params.id : undefined,
        });
      }
      case 'fencing.update': {
        const notification = deserializeDeviceNotification(event.data);
        switch (notification.type) {
          case 'geofence': {
            return fencingNotificationReceived(notification);
          }
          case 'low_battery': {
            return batteryLevelNotificationReceived(notification);
          }
          case 'security_compliance': {
            return securityComplianceNotificationReceived(notification);
          }
          default:
            return {
              type: '@@WebSockets/DEVICES_UNHANDLED_SUBSCRIPTION',
            };
        }
      }

      default: {
        return {
          type: '@@WebSockets/DEVICES_UNHANDLED_SUBSCRIPTION',
        };
      }
    }
  }
);
export { subscribeChanges, unsubscribeChanges };
