import { I18n } from 'react-redux-i18n';

export const LAST_ACTIVE_CONSTANT = 'lastActive';

export const userAccessLevelTypes = {
  ADMIN: 'admin',
  PARTNER: 'partner',
  PARTNER_USER: 'partneruser',
  SUPER_USER: 'superuser',
  CUSTOMER: 'customer',
  USER: 'user',
};

export const userAccessLevelsText = {
  [userAccessLevelTypes.ADMIN]: I18n.t('user.admin'),
  [userAccessLevelTypes.PARTNER]: I18n.t('user.partner'),
  [userAccessLevelTypes.PARTNER_USER]: I18n.t('user.partneruser'),
  [userAccessLevelTypes.SUPER_USER]: I18n.t('user.superuser'),
  [userAccessLevelTypes.CUSTOMER]: I18n.t('user.customer'),
  [userAccessLevelTypes.USER]: I18n.t('user.user'),
  unknownAccessLevel: I18n.t('user.unknownAccessLevel'),
};
