export const isRegionsData = function (message) {
  return isJSONData(message);
};

export const isJSONData = function (message) {
  return message[0] === '{';
};

export const getRegionsFromMessageData = function (data) {
  try {
    return JSON.parse(data).regions;
  } catch (e) {
    console.log('Wrong regions JSON', e);
    return [];
  }
};

export const rgbToHex = function (rgb) {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }
  return hex;
};
