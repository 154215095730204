import { NetworkStatus } from '../../common/constants';
import {
  ALERT_CREATION_FAILED,
  ALERT_CREATION_RESET,
  ALERT_CREATION_STARTED,
  ALERT_CREATION_SUCCESS,
  ALERT_DELETION_FAILED,
  ALERT_DELETION_STARTED,
  ALERT_DELETION_SUCCESS,
  ALERTS_LOAD_FAILED,
  ALERTS_LOAD_STARTED,
  ALERTS_LOAD_SUCCESS,
  CHANGE_ALERT_PROPERTY,
  RESET_ALERT_CREATION,
  SET_NEW_ALERT_DEFAULT_VALUES,
} from './alerts.actionTypes';

export default function alerts(
  state = {
    newAlert: {},
    createAlertData: {},
    createAlertStatus: NetworkStatus.NONE,
    createAlertError: undefined,

    alertsStatus: NetworkStatus.NONE,
    alerts: {
      data: [],
    },
    alertsError: undefined,
    alertsNextPageStatus: NetworkStatus.NONE,
    alertsNextPageError: undefined,

    deletingStatus: NetworkStatus.NONE,
    deletingError: undefined,
  },
  action
) {
  switch (action.type) {
    case RESET_ALERT_CREATION:
      return {
        ...state,
        newAlert: {},
        createAlertStatus: NetworkStatus.NONE,
        createAlertError: undefined,
      };

    case CHANGE_ALERT_PROPERTY:
      const { key, value } = action.payload;
      return {
        ...state,
        newAlert: {
          ...state.newAlert,
          [key]: value,
        },
      };

    case SET_NEW_ALERT_DEFAULT_VALUES:
      return {
        ...state,
        newAlert: {
          ...state.newAlert,
          ...action.payload,
        },
      };

    case ALERT_CREATION_STARTED:
      return {
        ...state,
        createAlertStatus: NetworkStatus.STARTED,
        createAlertError: undefined,
      };

    case ALERT_CREATION_SUCCESS:
      return {
        ...state,
        createAlertData: action.payload,
        createAlertStatus: NetworkStatus.DONE,
        createAlertError: undefined,
      };

    case ALERT_CREATION_FAILED:
      return {
        ...state,
        createAlertStatus: NetworkStatus.ERROR,
        createAlertError: action.payload,
      };

    case ALERT_CREATION_RESET:
      return {
        ...state,
        createAlertStatus: NetworkStatus.NONE,
        createAlertError: undefined,
      };

    case ALERTS_LOAD_STARTED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              alertsStatus: NetworkStatus.STARTED,
              alertsError: undefined,
            }
          : {
              alertsNextPageStatus: NetworkStatus.STARTED,
              alertsNextPageError: undefined,
            }),
      };
    case ALERTS_LOAD_FAILED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              alertsStatus: NetworkStatus.ERROR,
              alertsError: action.payload?.error,
            }
          : {
              alertsNextPageStatus: NetworkStatus.ERROR,
              alertsNextPageError: action.payload?.error,
            }),
      };
    case ALERTS_LOAD_SUCCESS:
      return {
        ...state,
        alerts: {
          data: [
            ...(action.payload.isInitialLoad ? [] : state.alerts.data),
            ...action.payload.data,
          ],
          nextUrl: action.payload.nextUrl,
          count: action.payload.count,
        },
        ...(action.payload.isInitialLoad
          ? {
              alertsStatus: NetworkStatus.DONE,
              alertsError: undefined,
            }
          : {
              alertsNextPageStatus: NetworkStatus.DONE,
              alertsNextPageError: undefined,
            }),
      };

    case ALERT_DELETION_STARTED:
      return {
        ...state,
        deletingStatus: NetworkStatus.STARTED,
        deletingError: undefined,
      };
    case ALERT_DELETION_FAILED:
      return {
        ...state,
        deletingStatus: NetworkStatus.ERROR,
        deletingError: action.payload,
      };
    case ALERT_DELETION_SUCCESS:
      return {
        ...state,
        deletingStatus: NetworkStatus.DONE,
        deletingError: undefined,
      };

    default:
      return state;
  }
}
