import React from 'react';
import QRCode from 'qrcode.react';
import { I18n } from 'react-redux-i18n';
import fileDownload from 'js-file-download';
import { generateEnrollmentConfig } from './enrollment.helpers';

class QRCodePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: this.initSettings(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.policies) {
      if (
        this.props.policies !== nextProps.policies ||
        this.props.id !== nextProps.id
      ) {
        this.setState({ settings: this.initSettings(nextProps) });
      }
    }
  }

  initSettings(props) {
    return generateEnrollmentConfig(props.policies, props.id);
  }

  handleDownloadPayload = () => {
    fileDownload(this.state.settings, 'enrollment.ini');
  };

  renderSettingsSection = () => {
    if (this.state.settings) {
      return (
        <div>
          <div
            style={{
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <QRCode value={this.state.settings} />
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ margin: '10px', fontSize: 18 }}>
              {I18n.t('enrollments.QRCodeEnrollmentIdText')}
            </div>
            <div style={{ margin: '10px', fontSize: 18 }}>{this.props.id}</div>
            {/*<Button*/}
            {/*    onClick={this.handleDownloadPayload}*/}
            {/*    className="create-add-criteria-btn"*/}
            {/*    style={{*/}
            {/*        display: 'flex',*/}
            {/*        alignItems: 'center',*/}
            {/*        justifyContent: 'center',*/}
            {/*        width: '200px',*/}
            {/*        height: '32px'}}*/}
            {/*>*/}
            {/*    DOWNLOAD PAYLOAD*/}
            {/*</Button>*/}
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          height: '100%',
          paddingRight: '20px',
        }}
      >
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '18px' }}>
              {I18n.t('enrollments.QRCode')}
            </div>
          </div>
        </div>
        <div
          className={`devicescontent`}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              width: '100%',
              minWidth: '225px',
              paddingTop: '30px',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            {this.renderSettingsSection()}
          </div>
        </div>
      </div>
    );
  }
}

export default QRCodePage;
