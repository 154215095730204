import {
  USER_GROUP_PERMISSIONS_LOAD_FAILED,
  USER_GROUP_PERMISSIONS_LOAD_STARTED,
  USER_GROUP_PERMISSIONS_LOAD_SUCCESS,
} from './users.actionTypes';

export const USERS_LOAD_STARTED = 'USERS_LOAD_STARTED';
export const USERS_LOAD_FAILED = 'USERS_LOAD_FAILED';
export const USERS_LOAD_SUCCESS = 'USERS_LOAD_SUCCESS';

export function loadUsersStart(payload) {
  return {
    type: USERS_LOAD_STARTED,
    payload,
  };
}

export function loadUsersFailed(payload) {
  return {
    type: USERS_LOAD_FAILED,
    payload,
  };
}

export function loadUsersSuccess(payload) {
  return {
    type: USERS_LOAD_SUCCESS,
    payload,
  };
}

export const USERS_DELETE_STARTED = 'USERS_DELETE_STARTED';
export const USERS_DELETE_FAILED = 'USERS_DELETE_FAILED';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_RESET = 'USERS_DELETE_RESET';
export const USERS_MOVE_STARTED = 'USERS_MOVE_STARTED';
export const USERS_MOVE_FAILED = 'USERS_MOVE_FAILED';
export const USERS_MOVE_SUCCESS = 'USERS_MOVE_SUCCESS';
export const USERS_MOVE_RESET = 'USERS_MOVE_RESET';
export const USERS_MAKE_INACTIVE_STARTED = 'USERS_MAKE_INACTIVE_STARTED';
export const USERS_MAKE_INACTIVE_FAILED = 'USERS_MAKE_INACTIVE_FAILED';
export const USERS_MAKE_INACTIVE_SUCCESS = 'USERS_MAKE_INACTIVE_SUCCESS';
export const USERS_MAKE_INACTIVE_RESET = 'USERS_MAKE_INACTIVE_RESET';

export function deleteUsersStarted() {
  return {
    type: USERS_DELETE_STARTED,
  };
}

export function deleteUsersFailed(payload) {
  return {
    type: USERS_DELETE_FAILED,
    payload,
  };
}

export function deleteUsersSuccess() {
  return {
    type: USERS_DELETE_SUCCESS,
  };
}

export function deleteUsersReset() {
  return {
    type: USERS_DELETE_RESET,
  };
}

export function moveUsersStarted() {
  return {
    type: USERS_MOVE_STARTED,
  };
}

export function moveUsersFailed(payload) {
  return {
    type: USERS_MOVE_FAILED,
    payload,
  };
}

export function moveUsersSuccess() {
  return {
    type: USERS_MOVE_SUCCESS,
  };
}

export function moveUsersReset() {
  return {
    type: USERS_MOVE_RESET,
  };
}

export function makeUsersInactiveStarted() {
  return {
    type: USERS_MAKE_INACTIVE_STARTED,
  };
}

export function makeUsersInactiveFailed(payload) {
  return {
    type: USERS_MAKE_INACTIVE_FAILED,
    payload,
  };
}

export function makeUsersInactiveSuccess() {
  return {
    type: USERS_MAKE_INACTIVE_SUCCESS,
  };
}

export function makeUsersInactiveReset() {
  return {
    type: USERS_MAKE_INACTIVE_RESET,
  };
}

export const loadUserGroupPermissionsStart = () => ({
  type: USER_GROUP_PERMISSIONS_LOAD_STARTED,
});

export const loadUserGroupPermissionsFailed = (payload) => ({
  type: USER_GROUP_PERMISSIONS_LOAD_FAILED,
  payload,
});

export const loadUserGroupPermissionsSuccess = (payload) => ({
  type: USER_GROUP_PERMISSIONS_LOAD_SUCCESS,
  payload,
});
