import { NetworkStatus } from '../../common/constants';
import {
  APPLICATIONS_DELETE_FAILED,
  APPLICATIONS_DELETE_RESET,
  APPLICATIONS_DELETE_STARTED,
  APPLICATIONS_DELETE_SUCCESS,
  APPLICATIONS_LOAD_ALL_FAILED,
  APPLICATIONS_LOAD_ALL_STARTED,
  APPLICATIONS_LOAD_ALL_SUCCESS,
  APPLICATIONS_LOAD_FAILED,
  APPLICATIONS_LOAD_STARTED,
  APPLICATIONS_LOAD_SUCCESS,
  LOAD_APPLICATIONS_FULL_LIST_FAILED,
  LOAD_APPLICATIONS_FULL_LIST_STARTED,
  LOAD_APPLICATIONS_FULL_LIST_SUCCESS,
} from './applications.actionTypes';

export default function applications(
  state = {
    loading: false,
    error: null,
    profile_data: null,
    deletingStatus: null,
    deletingError: undefined,
    data: [],
    status: NetworkStatus.NONE,
    applicationsFullList: [],
    applicationsFullListError: undefined,
    applicationsFullListStatus: NetworkStatus.NONE,
  },
  action
) {
  switch (action.type) {
    case APPLICATIONS_LOAD_ALL_STARTED:
      return Object.assign({}, state, {
        profile_data: null,
        loading: true,
        error: null,
      });
    case APPLICATIONS_LOAD_ALL_FAILED:
      return Object.assign({}, state, {
        profile_data: null,
        loading: false,
        error: action.payload,
      });
    case APPLICATIONS_LOAD_ALL_SUCCESS:
      return Object.assign({}, state, {
        profile_data: action.payload,
        loading: false,
        error: null,
      });
    case APPLICATIONS_DELETE_STARTED:
      return Object.assign({}, state, {
        deletingStatus: 'started',
        deletingError: undefined,
      });
    case APPLICATIONS_DELETE_FAILED:
      return Object.assign({}, state, {
        deletingStatus: 'error',
        deletingError: action.payload,
      });
    case APPLICATIONS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        deletingStatus: 'done',
      });
    case APPLICATIONS_DELETE_RESET:
      return Object.assign({}, state, {
        deletingStatus: null,
        deletingError: undefined,
      });
    case APPLICATIONS_LOAD_STARTED:
      return Object.assign({}, state, {
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case APPLICATIONS_LOAD_FAILED:
      return Object.assign({}, state, {
        status: NetworkStatus.ERROR,
        error: action.payload,
        data: [],
      });
    case APPLICATIONS_LOAD_SUCCESS:
      return Object.assign({}, state, {
        status: NetworkStatus.DONE,
        data: action.payload,
        error: undefined,
      });

    case LOAD_APPLICATIONS_FULL_LIST_STARTED:
      return {
        ...state,
        applicationsFullList: [],
        applicationsFullListError: undefined,
        applicationsFullListStatus: NetworkStatus.STARTED,
      };

    case LOAD_APPLICATIONS_FULL_LIST_FAILED:
      return {
        ...state,
        applicationsFullList: [],
        applicationsFullListError: action.payload,
        applicationsFullListStatus: NetworkStatus.ERROR,
      };

    case LOAD_APPLICATIONS_FULL_LIST_SUCCESS:
      return {
        ...state,
        applicationsFullList: action.payload,
        applicationsFullListError: undefined,
        applicationsFullListStatus: NetworkStatus.DONE,
      };

    default:
      return state;
  }
}
