import { I18n } from 'react-redux-i18n';
import { ProfilesV2ActionTypes } from './profilesV2.actionTypes';
import { clientV2 } from '../../utils/client';
import services from '../../utils/services';
import { deserializeProfile } from '../profile/profile.serializer';
import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { handleRequestResponse } from '../../common/utilities/errorHandling';
import { notEmpty } from '../../common/helpers';
import PopupActions from '../popup/popup.actions';

export function loadProfilesV2({
  offset = 0,
  isInitialLoad = false,
  limit = INFINITE_SCROLL_LIMIT,
  loadFullList = false,
  showErrorPopup = false,
} = {}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ProfilesV2ActionTypes.LOAD_PROFILES_BY_COMPANY_STARTED,
        payload: {
          isInitialLoad,
        },
      });
      const response = await clientV2.get(services.profileListByCompany, {
        params: {
          offset: isInitialLoad ? 0 : offset,
          limit: loadFullList ? undefined : limit,
        },
      });

      handleRequestResponse({
        response,
        customSuccessDeterminer:
          response.status === 200 &&
          response.data &&
          Array.isArray(response.data.results),
        onSuccess: () => {
          const { results, next: nextUrl, count } = response.data;
          const { offset } = RouteHelpers.getURLQueryParams(nextUrl);
          const hasMore = Boolean(nextUrl);
          dispatch({
            type: ProfilesV2ActionTypes.LOAD_PROFILES_BY_COMPANY_SUCCESS,
            payload: {
              data: results.map(deserializeProfile),
              nextUrl,
              count,
              isInitialLoad,
              offset,
              hasMore,
              isFullList: loadFullList || !nextUrl,
            },
          });
        },
        showSuccessPopup: false,
        onError: () => {
          dispatch({
            type: ProfilesV2ActionTypes.LOAD_PROFILES_BY_COMPANY_FAILED,
            payload: {
              text: response.text,
              isInitialLoad,
            },
          });
        },
        defaultErrorMessageTextCode: 'profiles.failedToLoadProfiles',
        showErrorPopup,
      });
    } catch (e) {
      dispatch({
        type: ProfilesV2ActionTypes.LOAD_PROFILES_BY_COMPANY_FAILED,
        payload: {
          text: I18n.t('profiles.loadProfilesErrorText'),
          isInitialLoad,
        },
      });
    }
  };
}

export const cloneProfilesV2 = (ids) => async (dispatch) => {
  try {
    dispatch({
      type: ProfilesV2ActionTypes.CLONE_PROFILES_V2_STARTED,
    });
    const response = await clientV2.post(services.profilesClone, {
      id_list: ids,
    });

    handleRequestResponse({
      response,
      successStatusCode: 204,
      onSuccess: () => {
        dispatch(
          loadProfilesV2({
            loadFullList: true,
            isInitialLoad: true,
          })
        );
      },
      successTextCode: 'profiles.profilesSuccessfullyCloned',
      defaultErrorMessageTextCode: 'profiles.profilesNotSuccessfullyCloned',
    });
  } catch (e) {}
};

export const deleteProfilesV2 = (profileIds) => async (dispatch) => {
  try {
    dispatch({
      type: ProfilesV2ActionTypes.DELETE_PROFILE_V2_STARTED,
    });
    const response = await clientV2.post(services.deleteProfileV2, {
      id_list: profileIds,
    });

    handleRequestResponse({
      response,
      successStatusCode: 204,
      onSuccess: () => {
        dispatch({
          type: ProfilesV2ActionTypes.DELETE_PROFILE_V2_SUCCESS,
        });
        dispatch(
          loadProfilesV2({
            loadFullList: true,
            isInitialLoad: true,
          })
        );
      },
      successTextCode: 'profiles.profilesSuccessfullyDeleted',
      defaultErrorMessageTextCode: 'profiles.profilesNotSuccessfullyDeleted',
    });
  } catch (e) {}
};

export const unpublishAndClearFleets = (profileIds) => async (dispatch) => {
  try {
    PopupActions.showLoader();
    const response = await clientV2.post(services.unpublishAndClearFleets, {
      profiles: profileIds,
    });
    PopupActions.hideLoader();
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        dispatch(
          loadProfilesV2({
            loadFullList: true,
            isInitialLoad: true,
          })
        );
      },
      successPopupAdditionalParams: {
        textList: [
          ...(notEmpty(response.data.success)
            ? [
                I18n.t('profiles.unpublishAndClearListedFleetsSuccess'),
                ...response.data.success.map(
                  ({ name }, index) => `${index + 1}. ${name}`
                ),
              ]
            : []),
          ...(notEmpty(response.data.failure)
            ? [
                I18n.t('profiles.unpublishAndClearListedFleetsFailure'),
                ...response.data.failure.map(
                  ({ name }, index) => `${index + 1}. ${name}`
                ),
              ]
            : []),
        ],
      },
      successTextCode: 'profiles.unpublishAndClearFleetsSuccess',
      defaultErrorMessageTextCode: 'profiles.unpublishAndClearFleetsFailure',
    });
  } catch (e) {}
};
