import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { companyIdSelector } from '../features/login/login.selectors';
import {
  loadCompanySettings,
  resetSettingsStatus,
  setCompanySettings,
} from '../redux/company.actions';
import { debounce } from 'lodash';
import { Button } from './button.component';
import { NetworkStatus } from '../common/constants';
import { Throbber } from './Throbber';
import { SwitchField } from './switchField.component';
import { SectionDescription } from './sectionDescription.component';
import { showStatusChangePopup } from '../common/utilities/common';

class CommonSettings extends Component {
  state = {};

  componentDidMount() {
    this.props.loadCompanySettings(this.props.companyId);
    this.initState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.status !== prevProps.status) {
      if (this.props.status !== NetworkStatus.NONE) {
        showStatusChangePopup({
          onClose: this.props.resetSettingsStatus,
          status: this.props.status,
          errorText:
            this.props.loadingSettingsError?.message ??
            I18n.t('settings.updatePopupDefaultError'),
          title: I18n.t('settings.updatePopupTitle'),
        });
        if (this.props.status === NetworkStatus.ERROR) {
          this.initState();
        }
      }
    }

    if (
      this.props.loadingSettings !== prevProps.loadingSettings &&
      this.props.loadingSettings !== NetworkStatus.STARTED
    ) {
      this.initState();
    }
  }

  initState = () => {
    this.setState({
      automaticAgentUpdates: false,
      ...this.props.companySettings,
    });
  };

  onChange = ({ target: { value } }, key) => {
    this.setState({ [key]: value });
  };

  debouncedChangeSettings = debounce(
    () =>
      this.props.setCompanySettings(this.props.companyId, {
        automaticAgentUpdates: this.state.automaticAgentUpdates,
      }),
    400
  );

  render() {
    return (
      <Fragment>
        <div className={'app__content'}>
          <div className={'page-content'}>
            <div className={'card'}>
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  padding: '38px 60px',
                  whiteSpace: 'normal',
                  overflowY: 'auto',
                }}
              >
                <SectionDescription
                  title={I18n.t('settings.commonSettings')}
                  hint={I18n.t('settings.commonSettingsHint')}
                />
                {this.props.loadingSettings === NetworkStatus.DONE ? (
                  <Fragment>
                    <SwitchField
                      onClick={(e) =>
                        this.onChange(
                          {
                            target: {
                              value: !this.state.automaticAgentUpdates,
                            },
                          },
                          'automaticAgentUpdates'
                        )
                      }
                      on={this.state.automaticAgentUpdates || false}
                      title={I18n.t('settings.enableAutomaticAgentUpdates')}
                    />
                    <Button onClick={this.debouncedChangeSettings}>
                      {I18n.t('settings.setupSettings')}
                    </Button>
                  </Fragment>
                ) : (
                  <Throbber />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: companyIdSelector(state),
    status: state.company.companySettingStatus,
    loadingSettings: state.company.gettingCompanySettingsStatus,
    loadingSettingsError: state.company.gettingCompanySettingsError,
    companySettings: state.company.companySettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCompanySettings: (id, payload) =>
      dispatch(setCompanySettings(id, payload)),
    resetSettingsStatus: () => dispatch(resetSettingsStatus()),
    loadCompanySettings: (companyId) =>
      dispatch(loadCompanySettings(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonSettings);
