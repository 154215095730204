import React from 'react';
import Select from 'react-select';

const DropDownSelect = ({ options, ...otherProps }) => {
  return (
    <Select
      options={options}
      getOptionLabel={(e) => (
        <div className="drop-down-select__label-container">
          {e.icon ?? null}
          <span className="drop-down-select__label">{e.text}</span>
        </div>
      )}
      {...otherProps}
    />
  );
};

export default DropDownSelect;
