import React, { Fragment } from 'react';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { GroupFleets, permissionTableItems } from '../../group/group.constants';
import { deserializeFleet } from '../../fleet/fleet.serializer';
import FleetsTree from '../../fleets/components/fleetsTree.component';
import PermissionCheckBox from '../../users/components/permissionCheckBox.component';
import { AttributeType, NetworkStatus } from '../../../common/constants';
import PermissionProperty from '../../users/components/permissionProperty.component';
import { groupsColumn } from '../../users/users.constants';
import { RootRoutes, UsersRoutes } from '../../../utils/routes';
import { Glyphicon } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable.component';

class NewUserPermission extends React.Component {
  state = {
    fleet: '9d26252a-e71e-413f-811e-3b040ba800f2',
    name: '',
    permissions: [...permissionTableItems()],
  };

  renderPermissionControl = ({ item, field }) => {
    return (
      <PermissionCheckBox
        item={item}
        field={field}
        onChange={(e) => {
          e.persist();
          this.setState((state) => {
            const foundPermissionIndex = state.permissions.findIndex(
              (permission) => permission.permissionItem === item.permissionItem
            );
            if (foundPermissionIndex !== -1) {
              const currentPermission = state.permissions[foundPermissionIndex];
              return {
                ...state,
                permissions: [
                  ...state.permissions.slice(0, foundPermissionIndex),
                  {
                    ...currentPermission,
                    [field.key]: e.target.checked,
                    read:
                      field.key === 'read' && currentPermission.readWrite
                        ? true
                        : field.key === 'read'
                        ? e.target.checked
                        : field.key === 'readWrite' && e.target.checked
                        ? true
                        : currentPermission.read,
                    readWrite:
                      field.key === 'readWrite'
                        ? e.target.checked
                        : currentPermission.readWrite,
                  },
                  ...state.permissions.slice(foundPermissionIndex + 1),
                ],
              };
            }
            return state;
          });
        }}
      />
    );
  };

  permissionTableColumns = [
    {
      key: 'permissionItem',
      type: AttributeType.TEXT,
      titleCode: 'DESCRIPTION',
      renderer: PermissionProperty,
    },
    {
      key: 'read',
      type: AttributeType.TEXT,
      titleCode: 'READ ONLY',
      renderer: this.renderPermissionControl,
    },
    {
      key: 'readWrite',
      type: AttributeType.TEXT,
      titleCode: 'READ/WRITE',
      renderer: this.renderPermissionControl,
    },
  ];

  render() {
    return (
      <Fragment>
        <div className={'dialog-content-section'}>
          <SectionDescription title={'User Group'} />
          <div style={{ height: '80%' }}>
            <DataTable
              allColumns={groupsColumn}
              data={this.props.groups}
              loadingStatus={this.props.groupsStatus}
            />
          </div>
          <NavLink
            to={`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}`}
          >
            <Glyphicon style={{ marginLeft: '10px' }} glyph="plus" />
            {'Create new user group'}
          </NavLink>
        </div>
        <div className={'dialog-content-section'}>
          <SectionDescription title={'Global Permissions'} />
          <DataTable
            allColumns={this.permissionTableColumns}
            data={this.state.permissions}
            loadingStatus={NetworkStatus.DONE}
          />
        </div>
        <div className={'dialog-content-section'}>
          <SectionDescription title={'Fleet Access Management'} />
          <FleetsTree
            fleets={GroupFleets.map(deserializeFleet)}
            OnSelect={this.onSelect()}
            defaultSelectedKeys={this.state.fleet ? [this.state.fleet] : []}
            selectedKeys={this.state.fleet ? [this.state.fleet] : []}
          />
        </div>
      </Fragment>
    );
  }
  onSelect = (fleet) => {
    return '';
  };
}
export default NewUserPermission;
