import { ACCESS_TYPES } from '../../constants/accessTypes';
import { convertPermissionsListToObjWithAccessTypes } from '../../common/utilities/users';
import {
  FLEET_TREE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  MODEL_GLOBAL_PERMISSIONS,
} from '../../constants/globalPermissions';

export const deserializeUserGroupPermissions = ({
  group_name,
  global_permissions_list,
  fleets_v2_rc_enabled_list,
  fleets_v2_view_specific_list,
  global_permissions_read_list,
  global_permissions_read_write_list,
}) => {
  const globalPermissionsList = global_permissions_list || [];

  const isFleetAccessReadWriteAccess = FLEET_TREE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS.every(
    (item) => globalPermissionsList.includes(item)
  );
  const isFleetAccessReadOnlyAccess =
    global_permissions_list.includes(MODEL_GLOBAL_PERMISSIONS.VIEW_FLEET_V2) &&
    !isFleetAccessReadWriteAccess;
  let fleetAccess;
  if (isFleetAccessReadWriteAccess) {
    fleetAccess = ACCESS_TYPES.READ_WRITE;
  } else if (isFleetAccessReadOnlyAccess) {
    fleetAccess = ACCESS_TYPES.READ;
  } else {
    fleetAccess = ACCESS_TYPES.NONE;
  }

  return {
    groupName: group_name,
    globalPermissionsList,
    fleetsRemoteControlEnabled: fleets_v2_rc_enabled_list,
    fleetsViewSpecific: fleets_v2_view_specific_list,
    globalPermissionsReadList: global_permissions_read_list,
    globalPermissionsReadWriteList: global_permissions_read_write_list,
    groupGlobalPermissionsConfig: {
      ...convertPermissionsListToObjWithAccessTypes(
        global_permissions_read_list,
        ACCESS_TYPES.READ
      ),
      ...convertPermissionsListToObjWithAccessTypes(
        global_permissions_read_write_list,
        ACCESS_TYPES.READ_WRITE
      ),
      fleetAccess,
    },
  };
};
