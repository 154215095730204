import { I18n } from 'react-redux-i18n';
import {
  SEARCH_PERFORM_FAILED,
  SEARCH_PERFORM_STARTED,
  SEARCH_PERFORM_SUCCESS,
  SEARCH_RESET,
  SEARCH_SET_KEYS,
  SEARCH_SET_TEXT,
} from './search.actionTypes';
import { performSearchApi } from './search.api';
import { deserializeSearchResults } from './search.serializer';

export function performSearchStarted() {
  return {
    type: SEARCH_PERFORM_STARTED,
  };
}

export function performSearchFailed(payload) {
  return {
    type: SEARCH_PERFORM_FAILED,
    payload,
  };
}

export function performSearchSuccess(payload) {
  return {
    type: SEARCH_PERFORM_SUCCESS,
    payload,
  };
}

export function resetSearch() {
  return {
    type: SEARCH_RESET,
  };
}

export function setSearchKeys(payload) {
  return {
    type: SEARCH_SET_KEYS,
    payload,
  };
}

export function setSearchText(payload) {
  return {
    type: SEARCH_SET_TEXT,
    payload,
  };
}

export function performSearch(companyId, keyword) {
  return async (dispatch) => {
    try {
      dispatch(performSearchStarted());
      let response = await performSearchApi({ company_id: companyId, keyword });
      if (response.status === 200 && response.data) {
        let result = deserializeSearchResults(response.data);
        dispatch(performSearchSuccess(result));
      } else if (response.status === 404) {
        let result = deserializeSearchResults();
        dispatch(performSearchSuccess(result));
      } else {
        dispatch(performSearchFailed(response.status.text));
      }
    } catch (error) {
      dispatch(
        performSearchFailed(I18n.t('searchDialog.failedToPerformSearch'))
      );
    }
  };
}
