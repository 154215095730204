import ReactGA, {
  EventArgs,
  FieldsObject,
  InitializeOptions,
  TimingArgs,
  TrackerNames,
} from 'react-ga';
import { TrackingCodes } from '../constants/analytics';

export default class AnalyticsService {
  static eventTransportMechanism = {
    BEACON: 'beacon',
    XHR: 'xhr',
    IMAGE: 'image',
  };

  static getTrackingCode() {
    return (
      TrackingCodes[window.location.hostname] ??
      TrackingCodes['dev.springdel.com']
    );
  }

  static initialize(
    trackingCode: string = this.getTrackingCode(),
    options?: InitializeOptions
  ): void {
    ReactGA.initialize(trackingCode, options);
  }

  static pageView(
    path: string,
    trackerNames?: TrackerNames,
    title?: string
  ): void {
    ReactGA.pageview(path, trackerNames, title);
  }

  static set(fieldsObject: FieldsObject, trackerNames?: TrackerNames) {
    ReactGA.set(fieldsObject, trackerNames);
  }

  static logPageView(url: string): void {
    this.set({
      page: url,
    }); // Update the user's current page
    this.pageView(url); // Record a pageview for the given page
  }

  static event(params: EventArgs) {
    ReactGA.event(params);
  }

  static timing(params: TimingArgs) {
    ReactGA.timing(params);
  }
}
