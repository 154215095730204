import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import FileSyncConfigurator from './FileSyncConfigurator';
import { SwitchField } from '../../../components/switchField.component';
import { SelectField } from '../../../components/selectField.component';
import { InputField } from '../../../components/inputField.component';
import { ActionButton } from '../../../components/actionButton.component';
import { ReactComponent as Trash } from '../../../assets/images/trash.svg';
import {
  getObjectByValue,
  isEmpty,
  isNotUndefined,
} from '../../../common/helpers';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../../common/constants';
import PolicyHeader from './PolicyHeader.component';
import PolicyActivationToggle from './PolicyActivationToggle.component';
import { I18n, Translate } from 'react-redux-i18n';

const directionOptionConstants = {
  SERVER_TO_DEVICE_ONLY: 'server_to_device_only',
};

const directionOptions = [
  {
    value: directionOptionConstants.SERVER_TO_DEVICE_ONLY,
    title: I18n.t(
      'profiles.fileSync.directionOptionSyncFilesFromServerToDevice'
    ),
  },
];

export const initialSettings = {
  direction: directionOptionConstants.SERVER_TO_DEVICE_ONLY,
  devicePath: '',
  serverPath: '',
  syncWhenDeviceConnect: false,
  onlyTransmitWhen: 'files_are_different',
  syncSubFolders: false,
  networkRestriction: 'wifi',
  syncOnDeviceAdditionAndRelocation: false,
  useCommonCacheMode: false,
  deleteUnmatched: false,
  enableFileSharing: false,
  files: [],
  files_v2: [],
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'file_sync',
  isActive: false,
  policyData: null,
};

export const FileSync = (props) => {
  const isPolicyActive = props.policy.some((item) => item.isActive);

  const onChange = (index, key, { target: { value } }) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings, files: [], files_v2: [] };
    settings[key] = value;
    item.settings = settings;
    props.onChange('fileSync', index, item);
  };

  const onAddFile = (policyIndex, fileKey) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[policyIndex]
        ? cloneDeep({ ...policy[policyIndex] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings, files: [], files_v2: [] };
    let fileIndex = props.files.findIndex((file) => file.key === fileKey);
    if (fileIndex !== -1) {
      settings.files.splice(fileIndex, 0, props.files[fileIndex].key);
      settings.files_v2.splice(
        fileIndex,
        0,
        props.files.find(({ key }) => key === fileKey)
      );
      // settings['files_v2'] = props.files.find(({ key }) => key === fileKey)
      item.settings = settings;
      props.onChange('fileSync', policyIndex, item);
    }
  };

  const onRemoveFile = (policyIndex, fileKey) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[policyIndex]
        ? cloneDeep({ ...policy[policyIndex] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings, files: [], files_v2: [] };
    let fileIndex = settings.files.findIndex((file) => file === fileKey);
    if (fileIndex !== -1) {
      settings.files.splice(fileIndex, 1);
      settings.files_v2.splice(fileIndex, 1);
      item.settings = settings;
      props.onChange('fileSync', policyIndex, item);
    }
  };

  // const onChangePolicy = (index, key, {target: {value}}) => {
  //     let policy = [...props.policy];
  //     let item = policy.length && policy[index] ? cloneDeep({...policy[index]}) : {...initialPolicy};
  //     item[key] = value;
  //     props.onChange("fileSync", index, item)
  // };

  const onToggle = (index, key, presetNewToggleValue = undefined) => {
    let policy = [...props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? item.settings
      : { ...initialSettings, files: [], files_v2: [] };
    settings[key] = isNotUndefined(presetNewToggleValue)
      ? presetNewToggleValue
      : !settings[key];
    item.settings = settings;
    props.onChange('fileSync', index, item);
  };

  const renderHint = () => {
    return (
      <div className={'file-sync-hint'}>{I18n.t('profiles.fileSync.hint')}</div>
    );
  };

  const renderSettings = (policy, index) => {
    return (
      <div className={'file-sync-settings'} key={`file_sync_policy_${index}`}>
        <PolicyHeader
          descriptionTitle={`${I18n.t(
            'profiles.fileSync.fileSyncConfigurationTitle'
          )} ${index + 1}`}
          descriptionProps={{ isSmall: true }}
          actionButton={
            <ActionButton
              title={I18n.t('profiles.fileSync.removeConfigurationText')}
              onClick={() => props.onChange('fileSync', index, null)}
            >
              <Trash className={'icon'} />
            </ActionButton>
          }
        />
        <div className={'file-sync-settings-content'}>
          <div className={'file-sync-settings-content-sidebar'}>
            <SelectField
              title={I18n.t('profiles.fileSync.directionFieldTitle')}
              value={policy.settings.direction || ''}
              onChange={(e) => onChange(index, 'direction', e)}
              options={directionOptions}
            />
            {/**  <option value="device_to_server_only">Synchronize file(s) from Device to Server</option>
                    <option value="both_directions">Synchronize file(s) in both directions</option> **/}
            <InputField
              label={I18n.t('profiles.fileSync.devicePathLabelText')}
              value={policy.settings.devicePath || ''}
              onChange={(e) => onChange(index, 'devicePath', e)}
              placeholder={I18n.t(
                'profiles.fileSync.devicePathPlaceholderText'
              )}
            />
            {/**<div style={{display: 'flex', flexDirection: 'column', marginBottom: '15px'}}>
                        <div style={{marginBottom: '5px'}}>Server File / Folder Name:</div>
                        <input
                            className="form-control"
                            value={policy.settings.serverPath || ''}
                            onChange={e => onChange(index, "serverPath", e)}
                            placeholder="Enter a folder path"
                        />
                    </div>
                     **/}
            {/**}
                    <div style={{
                        marginBottom: '15px',
                        display: 'inline-flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <div style={{whiteSpace: 'nowrap', minWidth: '150px'}}>Sync Files When Devices Connected</div>
                        <Switch onClick={e => onToggle(index, "syncWhenDeviceConnect")} on={policy.settings.syncWhenDeviceConnect || false}/>
                    </div>
                     **/}
            <div className={'file-sync-settings-content-sidebar__title'}>
              {I18n.t('profiles.fileSync.fileSynchronizationOptionsTitle')}
            </div>
            <div className={'file-sync-settings-content-sidebar__margin'}>
              <SwitchField
                title={I18n.t('profiles.fileSync.syncSubFoldersFieldLabel')}
                onClick={(e) => onToggle(index, 'syncSubFolders')}
                on={policy.settings.syncSubFolders || false}
              />
              {/**<div style={{
                                marginBottom: '15px',
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <div style={{whiteSpace: 'nowrap', minWidth: '150px'}}>Sync Online Devices Now</div>
                                <Switch onClick={e => onToggle(index, "syncWhenDeviceConnect")} on={policy.settings.syncWhenDeviceConnect || false}/>
                            </div> **/}
              {/**
                            <div style={{display: 'flex', flexDirection: 'column', marginBottom: '15px'}}>
                                <div style={{marginBottom: '5px'}}>Network Restrictions</div>
                                <select
                                    className="form-control"
                                    value={policy.settings.networkRestriction || ''}
                                    onChange={e => onChange(index, "networkRestriction", e)}
                                >
                                    <option value="wifi">Allow: WiFi</option>
                                    <option value="cellular">Allow: Cellular</option>
                                </select>
                            </div>

                            <div style={{
                                marginBottom: '15px',
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <div style={{whiteSpace: 'nowrap', minWidth: '150px'}}>Sync Device Addition / Relocation</div>
                                <Switch
                                    onClick={e => onToggle(index, "syncOnDeviceAdditionAndRelocation")}
                                    on={policy.settings.syncOnDeviceAdditionAndRelocation || false}
                                />
                            </div>

                            <div style={{
                                marginBottom: '15px',
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <div style={{whiteSpace: 'nowrap', minWidth: '150px'}}>Use Common Cache Mode</div>
                                <Switch
                                    onClick={e => onToggle(index, "useCommonCacheMode")}
                                    on={policy.settings.useCommonCacheMode || false}
                                />
                            </div>
                            <div style={{
                                marginBottom: '15px',
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <div style={{whiteSpace: 'nowrap', minWidth: '150px'}}>Delete Un-matched Files and Folders</div>
                                <Switch
                                    onClick={e => onToggle(index, "deleteUnmatched")}
                                    on={policy.settings.deleteUnmatched || false}
                                />
                            </div>
                            <div style={{
                                marginBottom: '15px',
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <div style={{whiteSpace: 'nowrap', minWidth: '150px'}}>Enable file sharing</div>
                                <Switch
                                    onClick={e => onToggle(index, "enableFileSharing")}
                                    on={policy.settings.enableFileSharing || false}
                                />
                            </div>
                             **/}
            </div>
          </div>
          <div className={'file-sync-settings-content-lists-wrapper'}>
            <FileSyncConfigurator
              files={props.files || []}
              selected={
                props.policy && props.policy.length
                  ? props.policy[index].settings.files
                  : []
              }
              onAddFile={(file) => onAddFile(index, file)}
              onRemoveFile={(file) => onRemoveFile(index, file)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderFileSyncItems = () => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      props.onChangePolicyProperty({
        key: 'fileSync',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });

    if (props.policy?.length) {
      return (
        <>
          <PolicyActivationToggle
            key="policyActivation"
            policy={props.policy}
            titleCode="profiles.fileSync.policyStatusTitle"
            handleToggle={onEnableButtonToggle}
          />
          {isPolicyActive ? props.policy.map(renderSettings) : null}
        </>
      );
    } else {
      return renderHint();
    }
  };

  const renderContent = () => {
    const handleAddClick = () =>
      props.onChange('fileSync', props.policy.length, {
        ...initialPolicy,
        isActive: true,
        settings: { ...initialSettings, files: [], files_v2: [] },
      });

    return (
      <>
        <PolicyHeader
          descriptionTitle={I18n.t('profiles.fileSync.policyTitle')}
          textButtonProps={{
            handleTextButtonClick: handleAddClick,
          }}
        />
        <div className={'file-sync-settings-wrapper'}>
          {renderFileSyncItems()}
        </div>
      </>
    );
  };

  const renderFileSyncRuleReadOnly = (fileSyncPolicy, index) => {
    const {
      settings: {
        direction,
        devicePath = '',
        syncSubFolders = false,
        // networkRestriction,
        // syncOnDeviceAdditionAndRelocation = false,
        // useCommonCacheMode = false,
        // deleteUnmatched = false,
        // enableFileSharing = false,
        files = [],
        // files_v2 = [],
      } = {},
      // policyName,
    } = fileSyncPolicy;
    const fileSyncPolicyIndex = index + 1;
    const { title: directionValueText } = getObjectByValue(
      directionOptions,
      direction
    );

    return (
      <ReadOnlyForm
        key={`read-only-form-${index}`}
        headerTitle={`${I18n.t(
          'profiles.fileSync.fileSyncConfigurationTitle'
        )} ${fileSyncPolicyIndex}`}
        items={[
          {
            title: I18n.t('profiles.fileSync.directionFieldTitle'),
            value: directionValueText,
            type: AttributeType.TEXT,
            key: 'direction',
          },
          {
            title: I18n.t('profiles.fileSync.devicePathLabelText'),
            value: devicePath,
            defaultValue: '-',
            type: AttributeType.TEXT,
            key: 'devicePath',
          },
          {
            title: I18n.t('profiles.fileSync.fileSynchronizationOptionsTitle'),
            type: AttributeType.SUBHEADING,
            key: 'fileSynchronizationOptionsSubHeading',
            isSmall: true,
          },
          {
            title: I18n.t('profiles.fileSync.syncSubFoldersFieldLabel'),
            value: syncSubFolders,
            type: AttributeType.BOOLEAN,
            key: 'syncSubFolders',
          },
          {
            title: I18n.t('profiles.fileSync.filesTitle'),
            valueList: files,
            type: AttributeType.TEXT,
            key: 'files',
            defaultValue: '-',
          },
        ]}
      />
    );
  };

  const renderReadOnlyContent = () => {
    const { policy = [] } = props;
    if (isEmpty(policy)) {
      return <p>{I18n.t('profiles.fileSync.noFileSyncRules')}</p>;
    }

    return (
      <>
        <ReadOnlyForm
          items={[
            {
              title: <Translate value="profiles.fileSync.policyStatusTitle" />,
              value: isPolicyActive,
              type: AttributeType.BOOLEAN,
              key: 'isPolicyActive',
            },
          ]}
          headerTitle={I18n.t('profiles.fileSync.policyTitle')}
        />
        {policy.map((fileSyncPolicy, index) => [
          renderFileSyncRuleReadOnly(fileSyncPolicy, index),
        ])}
      </>
    );
  };

  const { isEditingMode } = props;

  return (
    <div className={'file-sync-wrapper'}>
      <div className={'file-sync'}>
        {isEditingMode ? renderContent() : renderReadOnlyContent()}
      </div>
    </div>
  );
};

export default FileSync;

FileSync.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
