export const initialSettings = {
  backButton: false,
  homeButton: false,
  menuButton: false,
  hideStatusBar: false,
  singleAppMode: false,
  bsSetDimension: '',
  bsExactDimension: '',
  bsSetOrientation: '',
  kioskBackdoorPwd: '',
  hasKioskBackdoorPwd: true,
  showExitButton: false,
  apps: [],
  whitelisted: [],
  autolaunchApp: undefined,
  autolaunch: false,
};

export const getInitialPolicy = (policyType) => ({
  policyName: 'new',
  policyData: null,
  policyType,
  isActive: false,
});

export const kioskPolicyTypes = {
  KIOSK: 'kiosk',
  HTML_KIOSK_V1: 'htmlKiosk_v1',
};
