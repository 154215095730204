import {
  SET_UPLOAD_INITIAL_PROGRESS,
  SET_UPLOAD_PROGRESS,
  UPLOAD_FAILED,
  UPLOAD_RESET,
  UPLOAD_STARTED,
  UPLOAD_SUCCESS,
} from './upload.actionTypes';

export function uploadStarted() {
  return {
    type: UPLOAD_STARTED,
  };
}

export function uploadFailed(payload) {
  return {
    type: UPLOAD_FAILED,
    payload,
  };
}

export function uploadSuccess() {
  return {
    type: UPLOAD_SUCCESS,
  };
}

export function resetUpload() {
  return {
    type: UPLOAD_RESET,
  };
}

export function setUploadInitialProgress(payload) {
  return {
    type: SET_UPLOAD_INITIAL_PROGRESS,
    payload,
  };
}

export function setUploadProgress(payload) {
  return {
    type: SET_UPLOAD_PROGRESS,
    payload,
  };
}
