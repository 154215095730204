import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import HeaderDropdown from './HeaderDropdown';
import { RootRoutes } from '../utils/routes';
import Breadcrumbs from './Breadcrumbs';
import { withRouter } from 'react-router';
import { Translate } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import { ReactComponent as MagnifyingGlass } from '../assets/images/magnifying-glass.svg';
import { Button } from './button.component';

const styles = {
  header: {
    color: 'black',
    fontSize: '21px',
    fontFamily: "'Open Sans', sans-serif",
    fontStyle: 'normal',
    fontStretch: 'normal',
    fontWeight: 300,
    letterSpacing: '2.1px',
    backgroundColor: 'white',
    borderBottom: '2px solid rgb(112, 112, 112, 0.3)',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '56px',
    paddingLeft: '32px',
  },
  taskBar: {
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    fontSize: '12px',
    display: 'inline-flex',
  },
  taskBarItem: {
    color: '#a7a8aa',
    paddingRight: '20px',
    fontSize: '12px',
  },
  firstTaskBarItem: {
    color: '#a7a8aa',
    paddingRight: '20px',
    fontSize: '14px',
  },
  taskBarUser: {
    color: '#a7a8aa',
    paddingRight: '10px',
    fontSize: '12px',
  },
  searchBtn: {
    float: 'right',
    padding: '20px 9px 5px 9px',
    color: 'white',
    backgroundColor: '#1d3c6d',
    height: '60px',
    width: '40px',
    marginTop: '-15px',
    marginRight: '-14px',
  },
  searchBarItem: {
    color: 'white',
  },
  bccontent: {
    padding: '14px 32px 2px',
    backgroundColor: 'white',
    marginBottom: '0',
  },
};

const titles = new Map()
  .set(RootRoutes.HOME, 'home.title')
  .set(RootRoutes.PROFILES, 'profiles.title')
  .set(RootRoutes.DEVICES, 'devices.title')
  .set(RootRoutes.FILE_SYSTEM, 'fileSystem.title')
  .set(RootRoutes.SETTINGS, 'settings.title')
  .set(RootRoutes.HELP, 'help')
  .set(RootRoutes.USERS, 'users.title')
  .set(RootRoutes.ALERTS, 'alerts.title')
  .set(RootRoutes.API, 'api.title');

const TopNavBar = (props) => {
  const { parentRoute } = props.match.params;

  const titleEntry = titles.get(`/${parentRoute}`);

  const renderTitle = () => {
    if (titleEntry) {
      return <Translate value={titleEntry} />;
    }
  };

  return (
    <div className={'app__header'}>
      <div style={styles.header}>
        {/**<span><a
                 onClick={props.toggleSidebar}
                 href="/"
                 style={   {textDecoration: "none",
                      color: "#25282d",
                      padding: 8}}
                 >
                 =
                 </a></span>**/}

        <span>{renderTitle()}</span>

        {/**<div style={styles.searchBtn}>
                 <a href="/home" style={styles.searchBarItem}>
                 <Glyphicon glyph="search" />
                 </a>
                 </div>**/}

        <div style={styles.taskBar}>
          <div style={styles.firstTaskBarItem}>
            <HeaderDropdown />
          </div>
          {/** <a href="/home" style={styles.taskBarItem}>
                     <Glyphicon glyph="bell"  />
                     </a>

                     <span style={styles.taskBarUser}>
                     {"Username"}
                     </span>
                     <a href="/home" style={styles.taskBarItem}>
                     <Glyphicon glyph="menu-down" />
                     </a>
                     <a href="/home" style={styles.taskBarItem}>
                     <Glyphicon glyph="user" />
                     </a>
                     **/}
          <NavLink
            to={'#search'}
            style={{
              width: '48px',
              height: '56px',
              display: 'flex',
              fontSize: '24px',
              cursor: 'pointer',
              overflow: 'hidden',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className={''}
            activeClassName={'sidebar-icon--active'}
            title={I18n.t('home.search')}
          >
            <Button
              style={{
                width: '48px',
                height: '56px',
              }}
            >
              <MagnifyingGlass
                alt={''}
                title={'Search'}
                className={'sidebar-icon__img'}
                style={{
                  width: '17px',
                  height: '17px',
                  verticalAlign: 'bottom',
                }}
              />
            </Button>
          </NavLink>
        </div>
      </div>
      <Breadcrumbs
        hasAdminPrivileges={props.hasAdminPrivileges}
        globalPermissionsForUser={props.globalPermissionsForUser}
      />
    </div>
  );
};

TopNavBar.propTypes = {
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withRouter(TopNavBar);
