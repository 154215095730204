import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import StyleUtils from '../../utils/styleUtils';
import { notEmpty } from '../../common/helpers';
import DragNDropListItem from '../DragNDropListItemComponents/DragNDropListItem.component';

const grid = 4;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgray' : 'white',
  padding: grid,
  width: 250,
});

const getItemStyle = (isDragging, draggableStyle = {}) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  // overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: isDragging ? 'normal' : 'break-word',
  // change background colour if dragging
  background: 'white', // isDragging ? 'lightgray' : 'white',
  borderBottom: '1px solid lightgray',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const DragNDropList = ({
  isShort = false,
  isMedium = false,
  hasNoBorders = false,
  hasNoContentPadding = false,
  listHeader,
  droppableListId,
  items,
  itemComponent: ItemComponent = DragNDropListItem,
  idSubstitute,
  listFooter,
  itemOptions = {},
  emptyListContent,
}) => {
  return (
    <section
      className={StyleUtils.mergeModifiers(
        'drag-n-drop-list',
        isShort && 'short',
        isMedium && 'medium',
        hasNoBorders && 'no-borders'
      )}
    >
      {listHeader}
      <Droppable droppableId={droppableListId}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              ...getListStyle(snapshot.isDraggingOver),
              width: '100%',
              height: '100%',
              padding: hasNoContentPadding ? 0 : '4px 20px',
              overflow: 'hidden',
              overflowY: 'auto',
            }}
          >
            {notEmpty(items) ? (
              items.map((item, index) => {
                const id = idSubstitute ? item[idSubstitute] : item.id;
                return (
                  <Draggable
                    key={id ?? uuid()}
                    draggableId={`${droppableListId}_${id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          ),
                          display: 'inline-flex',
                          justifyContent: 'space-around',
                          width: '100%',
                          maxWidth: snapshot.isDragging ? '320px' : '100%',
                        }}
                      >
                        <ItemComponent item={item} itemOptions={itemOptions} />
                      </div>
                    )}
                  </Draggable>
                );
              })
            ) : (
              <div className={'drag-n-drop-list-content__blank'}>
                {emptyListContent}
              </div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {listFooter}
    </section>
  );
};

export default DragNDropList;
