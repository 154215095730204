import * as moment from 'moment-timezone';
import {
  CHECK_DEVICES_UPDATES_INTERVAL,
  DeviceStatus,
} from '../devices/components/Devices/Devices.constants';
import { I18n } from 'react-redux-i18n';
import {
  bytesToSize,
  coordinatesDDToDMS,
  isDevServer,
} from '../../common/helpers';
import {
  AttributeType,
  DEFAULT_DATE_TIME_FORMAT,
} from '../../common/constants';
import { getFleetById } from '../../common/utilities/fleets';
import { complianceTypes } from '../../constants/device';
import { LockDeviceConfigurationStatuses } from '../devices/devices.constants';

export const getDeviceInfoSectionsItems = ({
  device: {
    agentVersion,
    appList,
    availableMemory,
    availableStorage,
    batteryLevel,
    coordinates: { latitude, longitude } = {},
    customNotes,
    developerMode,
    // familyInfo,
    id,
    name,
    status,
    locked,
    // type,
    enrollmentDatetime,
    fleetId,
    fleetV2Path,
    hardwareSerialNumber,
    imei,
    ipAddress,
    lastAccessed: lastAccessedDateTime,
    lastUpdated: lastUpdatedDateTime,
    lastCheckin,
    // checkinPeriod,
    // updatePeriod,
    lastFleetCheckin,
    macAddress,
    ethernetMacAddress,
    manufacturer,
    model,
    networkBssid,
    networkRssi,
    networkSsid,
    networkDNS,
    networkConnectionType,
    nfcStatus,
    osVersion,
    pauseMode,
    phoneNumber,
    powerStatus,
    roaming,
    // security,
    totalMemory,
    totalStorage,
    wifiStatus,
    // sendingAnalyticsData,
    hasLatestAgent,
    compliance,
    encrypted,
    passcodeEnabled,
    securityCompliance,
    androidSecurityPatchLevel,
    enabledAutomaticUpdates,
  } = {},
  fleets,
}) => {
  const enrolled =
    enrollmentDatetime && moment(enrollmentDatetime).isValid()
      ? moment(enrollmentDatetime).format('DD/MM/YYYY').toString()
      : ' ';

  const lastUpdated =
    lastUpdatedDateTime && moment(lastUpdatedDateTime).isValid()
      ? moment(lastUpdatedDateTime).format('DD/MM/YYYY').toString()
      : ' ';

  const lastAccessed =
    lastAccessedDateTime && moment(lastAccessedDateTime).isValid()
      ? moment(lastAccessedDateTime).format('DD/MM/YYYY').toString()
      : '';

  const isLastCheckinValid = lastCheckin
    ? moment(lastCheckin).isValid()
    : false;
  const checkinStatus =
    moment().diff(moment(lastCheckin)) >= 3 * CHECK_DEVICES_UPDATES_INTERVAL;
  const lastCheckinText =
    isLastCheckinValid && checkinStatus
      ? moment(new Date(lastCheckin)).from(new Date())
      : isLastCheckinValid && !checkinStatus
      ? I18n.t('device.isNowOnline')
      : ' ';

  const lastKnownCoordinates =
    latitude && longitude ? coordinatesDDToDMS(latitude, longitude) : '';

  const overviewItems = [
    {
      title: I18n.t('devices.fields.deviceName'),
      value: name,
      type: AttributeType.TEXT,
      key: 'deviceName',
    },
    {
      title: I18n.t('device.status'),
      value: status,
      type: AttributeType.ENUMERABLE,
      key: 'status',
      availableOptions: [
        {
          title: I18n.t('device.active'),
          value: DeviceStatus.ACTIVE,
        },
        {
          title: I18n.t('device.inactive'),
          value: DeviceStatus.INACTIVE,
        },
        {
          title: I18n.t('device.unenrolled'),
          value: DeviceStatus.UNENROLLED,
        },
      ],
    },
    {
      title: I18n.t('device.deviceConfigurationLocked'),
      value: locked,
      type: AttributeType.BOOLEAN,
      key: 'locked',
    },
    {
      title: I18n.t('device.enrolled'),
      value: enrolled,
      type: AttributeType.TEXT,
      key: 'enrolled',
    },
    // {
    //   title: I18n.t('device.lastUpdated'),
    //   value: lastUpdated,
    //   type: AttributeType.TEXT,
    //   key: 'lastUpdated',
    // },
    // {
    //   title: I18n.t('device.lastAccessed'),
    //   value: lastAccessed,
    //   type: AttributeType.TEXT,
    //   key: 'lastAccessed',
    //   defaultValue: '-',
    // },
    // add unenrolled on the BE
    {
      title: I18n.t('device.lastOnline'),
      value: lastCheckinText,
      type: AttributeType.TEXT,
      key: 'lastCheckinText',
    },
    {
      title: I18n.t('device.lastKnownLocation'),
      value: lastKnownCoordinates,
      type: AttributeType.TEXT,
      key: 'lastKnownCoordinates',
    },
    {
      title: I18n.t('device.fleet'),
      value: getFleetById(fleets, fleetId)?.name ?? '',
      type: AttributeType.TEXT,
      key: 'fleet',
    },
    {
      title: I18n.t('device.fleetPath'),
      value: fleetV2Path,
      type: AttributeType.TEXT,
      key: 'fleetV2Path',
    },
    {
      title: I18n.t('device.fields.agentVersion'),
      value: agentVersion,
      type: AttributeType.TEXT,
      key: 'agentVersion',
    },
    {
      title: I18n.t('device.fields.compliance'),
      value: compliance,
      type: AttributeType.ENUMERABLE,
      key: 'compliance',
      availableOptions: [
        {
          value: complianceTypes.COMPLIANT_AND_LATEST_VERSION,
          title: I18n.t('devices.fields.compliantAndLatestVersion'),
        },
        {
          value: complianceTypes.COMPLIANT_AND_NOT_LATEST_VERSION,
          title: I18n.t('devices.fields.compliantAndNotLatestVersion'),
        },
        {
          value: complianceTypes.NOT_COMPLIANT_VERSION,
          title: I18n.t('devices.fields.notCompliantVersion'),
        },
      ],
    },
    {
      title: I18n.t('device.fields.latestAgent'),
      value: hasLatestAgent,
      type: AttributeType.BOOLEAN,
      key: 'hasLatestAgent',
    },
    {
      title: I18n.t('device.platform'),
      value: osVersion,
      type: AttributeType.TEXT,
      key: 'osVersion',
    },
    // {
    //   title: I18n.t('device.checkinPeriod'),
    //   value: checkinPeriod,
    //   type: AttributeType.TEXT,
    //   key: 'checkinPeriod',
    // },
    // {
    //   title: I18n.t('device.updatePeriod'),
    //   value: updatePeriod,
    //   type: AttributeType.TEXT,
    //   key: 'updatePeriod',
    // },
    {
      title: I18n.t('device.lastFleetCheckin'),
      value: lastFleetCheckin,
      type: AttributeType.TEXT,
      key: 'lastFleetCheckin',
    },
    {
      title: I18n.t('device.customNotes'),
      value: customNotes,
      type: AttributeType.TEXT,
      key: 'customNotes',
    },
    // {
    //   title: I18n.t('device.fields.deviceType'),
    //   value: type,
    //   type: AttributeType.TEXT,
    //   key: 'deviceType',
    // },
    {
      title: I18n.t('device.developerMode'),
      value: developerMode,
      type: AttributeType.BOOLEAN,
      key: 'developerMode',
    },
    {
      title: I18n.t('device.pauseMode'),
      value: pauseMode,
      type: AttributeType.BOOLEAN,
      key: 'pauseMode',
    },
    {
      title: I18n.t('device.automaticAgentUpdatesEnabled'),
      value: enabledAutomaticUpdates,
      type: AttributeType.BOOLEAN,
      key: 'enabledAutomaticUpdates',
    },
  ];

  const hardwareInfo = [
    {
      title: I18n.t('device.manufacturer'),
      value: manufacturer,
      type: AttributeType.TEXT,
      key: 'manufacturer',
    },
    {
      title: I18n.t('device.model'),
      value: model,
      type: AttributeType.TEXT,
      key: 'model',
    },
    {
      title: I18n.t('device.fields.serialNumber'),
      value: hardwareSerialNumber,
      type: AttributeType.TEXT,
      key: 'hardwareSerialNumber',
    },
    {
      title: I18n.t('device.fields.imei'),
      valueList: imei.split(',').filter((item) => item.trim() !== 'null'),
      type: AttributeType.TEXT,
      key: 'imei',
    },
    // {
    //   title: I18n.t('device.phoneNumber'),
    //   value: phoneNumber,
    //   type: AttributeType.TEXT,
    //   key: 'phoneNumber',
    // },
    {
      title: I18n.t('device.batteryLevel'),
      value: batteryLevel,
      type: AttributeType.TEXT,
      key: 'batteryLevel',
    },
    {
      title: I18n.t('device.powerStatus'),
      value: powerStatus,
      type: AttributeType.ENUMERABLE,
      key: 'powerStatus',
      availableOptions: [
        {
          title: I18n.t('device.discharging'),
          value: 'discharging',
        },
        {
          title: I18n.t('device.discharging'),
          value: 'charging',
        },
      ],
    },
    {
      title: I18n.t('device.fields.availableMemory'),
      value: bytesToSize(availableMemory),
      type: AttributeType.TEXT,
      key: 'availableMemory',
    },
    {
      title: I18n.t('device.fields.totalMemory'),
      value: bytesToSize(totalMemory),
      type: AttributeType.TEXT,
      key: 'totalMemory',
    },
    {
      title: I18n.t('device.fields.availableStorage'),
      value: bytesToSize(availableStorage),
      type: AttributeType.TEXT,
      key: 'availableStorage',
    },
    {
      title: I18n.t('device.fields.totalStorage'),
      value: bytesToSize(totalStorage),
      type: AttributeType.TEXT,
      key: 'totalStorage',
    },
  ];

  const networkInfo = [
    // active connection
    {
      title: I18n.t('device.activeConnection'),
      value: networkConnectionType,
      type: AttributeType.TEXT,
      key: 'activeConnection',
    },
    {
      title: I18n.t('device.wifiStatus'),
      value: wifiStatus,
      type: AttributeType.ENUMERABLE,
      key: 'wifiStatus',
      availableOptions: [
        {
          title: I18n.t('common.connected'),
          value: 'connected',
        },
        {
          title: I18n.t('common.disconnected'),
          value: 'disconnected',
        },
      ],
    },
    {
      title: I18n.t('device.nfcStatus'),
      value: nfcStatus,
      type: AttributeType.ENUMERABLE,
      key: 'nfcStatus',
      availableOptions: [
        {
          title: I18n.t('common.enabled'),
          value: 'enabled',
        },
        {
          title: I18n.t('common.disabled'),
          value: 'disabled',
        },
      ],
    },
    {
      title: I18n.t('device.macAddress'),
      value: macAddress,
      type: AttributeType.TEXT,
      key: 'macAddress',
    },
    {
      title: I18n.t('device.ethernetMacAddress'),
      value: ethernetMacAddress,
      type: AttributeType.TEXT,
      key: 'ethernetMacAddress',
    },
    {
      title: I18n.t('device.ipAddress'),
      value: ipAddress,
      type: AttributeType.TEXT,
      key: 'ipAddress',
    },
    {
      title: I18n.t('device.networkRssi'),
      value: networkRssi,
      type: AttributeType.TEXT,
      key: 'networkRssi',
    },
    {
      title: I18n.t('device.networkSsid'),
      value: networkSsid,
      type: AttributeType.TEXT,
      key: 'networkSsid',
    },
    {
      title: I18n.t('device.networkDNS'),
      value: Array.isArray(networkDNS) ? networkDNS.join(', ') : networkDNS,
      type: AttributeType.TEXT,
      key: 'networkDNS',
    },
    {
      title: I18n.t('device.networkBssid'),
      value: networkBssid,
      type: AttributeType.TEXT,
      key: 'networkBssid',
    },
    // add iccid
    // apn
    // subscriber number
    // cellular provider
    {
      title: I18n.t('device.roaming'),
      value: roaming,
      type: AttributeType.BOOLEAN,
      key: 'roaming',
    },
  ];

  const securityInfo = [
    {
      title: I18n.t('device.encrypted'),
      value: encrypted,
      type: AttributeType.BOOLEAN,
      key: 'encrypted',
    },
    {
      title: I18n.t('device.passCodeEnabled'),
      value: passcodeEnabled,
      type: AttributeType.BOOLEAN,
      key: 'passcodeEnabled',
    },
    {
      title: I18n.t('device.androidSecurityPatchLevel'),
      value:
        androidSecurityPatchLevel && moment(androidSecurityPatchLevel).isValid()
          ? moment(androidSecurityPatchLevel).format(DEFAULT_DATE_TIME_FORMAT)
          : '',
      type: AttributeType.TEXT,
      key: 'androidSecurityPatchLevel',
    },
    {
      title: I18n.t('device.securityCompliance'),
      value: securityCompliance,
      type: AttributeType.ENUMERABLE,
      key: 'securityCompliance',
      availableOptions: [
        {
          title: I18n.t('device.compliant'),
          value: true,
        },
        {
          title: I18n.t('device.notCompliant'),
          value: false,
        },
      ],
    },
  ];

  return [
    {
      id: 'overviewItems',
      headerTitle: 'Overview',
      items: overviewItems,
    },
    {
      id: 'hardwareInfo',
      headerTitle: 'Hardware Info',
      items: hardwareInfo,
    },
    {
      id: 'networkInfo',
      headerTitle: 'Network Info',
      items: networkInfo,
    },
    {
      id: 'securityInfo',
      headerTitle: 'Security',
      items: securityInfo,
    },
  ];
};
