import { Component } from 'react';
import PropTypes from 'prop-types';

class BatteryLevel extends Component {
  render() {
    const { item } = this.props;
    const { batteryLevel, powerStatus } = item;
    const level = batteryLevel != null ? `${batteryLevel}%` : '';
    const status = powerStatus ? `(${powerStatus})` : '';

    return `${level}\xA0${status}`;
  }
}

BatteryLevel.propTypes = {
  item: PropTypes.object.isRequired,
};

export default BatteryLevel;
