export const PASSWORD_CHANGE_STARTED = 'PASSWORD_CHANGE_STARTED';
export const PASSWORD_CHANGE_FAILED = 'PASSWORD_CHANGE_FAILED';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_RESET = 'PASSWORD_CHANGE_RESET';

export function passwordChangeStarted() {
  return {
    type: PASSWORD_CHANGE_STARTED,
  };
}

export function passwordChangeFailed(payload) {
  return {
    type: PASSWORD_CHANGE_FAILED,
    payload,
  };
}

export function passwordChangeSuccess(payload) {
  return {
    type: PASSWORD_CHANGE_SUCCESS,
    payload,
  };
}

export function passwordChangeReset(payload) {
  return {
    type: PASSWORD_CHANGE_RESET,
    payload,
  };
}
