import { NetworkStatus } from '../../common/constants';
import {
  FLEETS_V2_CONFIG_LOAD_FAILED,
  FLEETS_V2_CONFIG_LOAD_RESET,
  FLEETS_V2_CONFIG_LOAD_STARTED,
  FLEETS_V2_CONFIG_LOAD_SUCCESS,
  FLEETS_V2_LOAD_ALL_FAILED,
  FLEETS_V2_LOAD_ALL_STARTED,
  FLEETS_V2_LOAD_ALL_SUCCESS,
} from './fleetsV2.actionTypes';

export default function fleetsV2(
  state = {
    status: NetworkStatus.NONE,
    error: undefined,
    data: [],

    fleetConfigStatus: NetworkStatus.NONE,
    fleetConfigError: undefined,
    fleetConfig: {},
  },
  action
) {
  switch (action.type) {
    case FLEETS_V2_LOAD_ALL_STARTED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case FLEETS_V2_LOAD_ALL_FAILED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.ERROR,
        error: action.payload,
      });
    case FLEETS_V2_LOAD_ALL_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });

    case FLEETS_V2_CONFIG_LOAD_STARTED:
      return {
        ...state,
        fleetConfigStatus: NetworkStatus.STARTED,
        fleetConfigError: undefined,
      };
    case FLEETS_V2_CONFIG_LOAD_FAILED:
      return {
        ...state,
        fleetConfigStatus: NetworkStatus.ERROR,
        fleetConfigError: action.payload,
      };
    case FLEETS_V2_CONFIG_LOAD_SUCCESS:
      return {
        ...state,
        fleetConfig: action.payload,
        fleetConfigStatus: NetworkStatus.DONE,
        fleetConfigError: undefined,
      };
    case FLEETS_V2_CONFIG_LOAD_RESET:
      return {
        ...state,
        fleetConfigStatus: NetworkStatus.NONE,
        fleetConfigError: undefined,
        fleetConfig: {},
      };

    default:
      return state;
  }
}
