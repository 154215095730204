import React from 'react';
import { SectionDescription } from '../sectionDescription.component';

const DragNDropSourceListHeader = ({
  title,
  hint,
  isSmall = true,
  ...rest
}) => (
  <section className="drag-n-drop-source-list-header">
    <SectionDescription title={title} hint={hint} isSmall={isSmall} {...rest} />
  </section>
);

export default DragNDropSourceListHeader;
