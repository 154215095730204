import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Map, Feature, View } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Stroke, Fill, Icon, Text } from 'ol/style';
import { Point } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { Polyline } from 'ol/format';
import { FullScreen, defaults as defaultControls } from 'ol/control';
import PrintDialog from 'ol-ext/control/PrintDialog';
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine';
import CanvasTitle from 'ol-ext/control/CanvasTitle';
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import { notEmpty } from '../../../common/helpers';
import services from '../../../utils/services';
import { deviceSelector } from '../device.selectors';
import MapHelpers from '../../../common/utilities/map';
import pin from '../../../assets/images/pin.svg';

import 'ol-ext/dist/ol-ext.css';

const MAP_TARGET_ID = 'location_history_map';
const MAXIMUM_NUMBER_OF_MAP_POINTS = 100;
const SAME_LOCATION_PROXIMITY_RADIUS = 0.15; // km
const SAME_LOCATION_PROXIMITY_TIME = 30; // minutes
const TRACKING_LOCATION_PROXIMITY_TIME = 2; // minutes

class LocationHistoryMap extends React.Component {
  mapRef = React.createRef();
  map;
  source = new VectorSource();
  defaultStyle = new Style({
    stroke: new Stroke({
      fill: new Fill({
        color: '#003562', // --incube primary color
      }),
      color: '#dfe0e4',
      width: 2,
    }),
    fill: new Fill({
      color: '#003562', // --incube primary color
    }),
    image: new Icon({
      anchor: [0.5, 24],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: pin,
      fill: '#003562', // --incube primary color
    }),
  });
  featureOverlay = new VectorLayer({
    source: this.source,
    style: this.defaultStyle,
  });

  componentDidMount() {
    this.map = new Map({
      controls: defaultControls().extend([
        new FullScreen({
          tipLabel: I18n.t('device.mapFullScreenControlLabelTip'),
        }),
      ]),
      view: new View({
        zoom: 5,
      }),
      target: MAP_TARGET_ID,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
    });

    this.map.addControl(
      new PrintDialog({
        jsPDF,
        saveAs,
        size: 'A4',
        title: I18n.t('device.export'),
      })
    );

    // Add a ScaleLine control
    this.map.addControl(new CanvasScaleLine());

    // Add a title control
    this.map.addControl(
      new CanvasTitle({
        title: I18n.t('device.locationHistory'),
        visible: false,
        style: new Style({
          text: new Text({
            font:
              '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
          }),
        }),
      })
    );

    if (notEmpty(this.props.deviceLocationLogs)) {
      const { longitude, latitude } =
        this.props.deviceLocationLogs[0]?.coordinates ?? {};
      const centerCoordinates = fromLonLat([longitude, latitude]);
      this.featureOverlay.setMap(this.map);
      const newView = this.map.getView();
      newView.setCenter(centerCoordinates);
      newView.setZoom(14);
    }
    this.addRoutesAndPointsToMap();
  }

  createFeature = (coordinates, featureProps = {}) => {
    const feature = new Feature({
      type: 'place',
      geometry: new Point(fromLonLat(coordinates)),
      ...featureProps,
    });
    feature.setStyle(
      new Style({
        image: new Icon({
          anchor: [0.5, 24],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: pin,
          fill: '#003562', // --incube primary color
        }),
      })
    );
    this.source.addFeature(feature);
  };

  createRoute = (polyline) => {
    // route is ol.geom.LineString
    const route = new Polyline({
      factor: 1e5,
    }).readGeometry(polyline, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    const feature = new Feature({
      type: 'route',
      geometry: route,
    });
    feature.setStyle(
      new Style({
        stroke: new Stroke({
          width: 6,
          color: [40, 40, 40, 0.8],
        }),
      })
    );
    this.source.addFeature(feature);
  };

  addLogsToMap = (logs = []) => {
    logs.forEach((log) => {
      const { longitude, latitude } = log.coordinates;
      this.createFeature([longitude, latitude], { name: log.actionTimestamp });
    });
  };

  addRoutesAndPointsToMap = () => {
    const that = this;
    const windowedLogs = MapHelpers.preparePointsList(
      that.props.deviceLocationLogs,
      MAXIMUM_NUMBER_OF_MAP_POINTS
    );

    const allLogs = MapHelpers.removePointsWithinCloseProximity(
      windowedLogs,
      { radius: SAME_LOCATION_PROXIMITY_RADIUS },
      {
        timeLimit: SAME_LOCATION_PROXIMITY_TIME,
        trackingTimeLimit: TRACKING_LOCATION_PROXIMITY_TIME,
      }
    );

    if (!allLogs.length) {
      return null;
    }

    // render all points here
    // that.addLogsToMap(that.props.deviceLocationLogs);

    const allCoordinates = allLogs.map(({ coordinates }) => coordinates);
    if (allCoordinates.length === 1) {
      const { longitude, latitude } = allCoordinates[0];
      that.createFeature([longitude, latitude]);
    } else {
      const coordinatesString = allCoordinates
        .map(({ longitude, latitude }) => `${longitude},${latitude}`)
        .join(';');
      fetch(`${services.osrmRoute}${coordinatesString}`)
        .then(function (response) {
          return response.text().then(function (text) {
            return text ? JSON.parse(text) : {};
          });
        })
        .then(function (data) {
          if (data.code !== 'Ok') {
            return;
          }
          that.createRoute(data.routes[0].geometry);
          that.renderWayPoints(data.waypoints);
        });
    }
  };

  renderWayPoints = (points = []) => {
    points.forEach(({ location }) => this.createFeature(location));
  };

  render() {
    return (
      <div
        className="location-history__view"
        id={MAP_TARGET_ID}
        ref={this.mapRef}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { locationLogs: deviceLocationLogs } = deviceSelector(state);
  return {
    deviceLocationLogs,
  };
};

export default connect(mapStateToProps)(LocationHistoryMap);
