import React, { Fragment, Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../../components/inputField.component';
import { Button } from '../../components/button.component';
import { RootRoutes, SettingsRoutes } from '../../utils/routes';
import { NavLink } from 'react-router-dom';
import { SectionDescription } from '../../components/sectionDescription.component';
import { connect } from 'react-redux';
import { changePassword } from './password.thunk';
import { NetworkStatus } from '../../common/constants';
import { logout } from '../login/login.actions';
import { confirmAlert } from 'react-confirm-alert';
import { passwordChangeReset } from './password.actions';

class Password extends Component {
  state = {
    currentPassword: '',
    newPassword: '',
    reTypeNewPassword: '',
    validationError: '',
  };

  handleChange = ({ target: { value, name } }) =>
    this.setState((state) => ({
      ...state,
      [name]: value,
      validationError: '',
    }));

  componentDidUpdate(prevProps) {
    if (this.props.status !== prevProps.status) {
      if (this.props.status !== NetworkStatus.NONE) {
        this.onChangeStatus();
      }
    }
  }

  onChangeStatus = () => {
    if (this.props.status) {
      let text =
        this.props.status === NetworkStatus.DONE
          ? I18n.t('common.successful')
          : this.props.status === NetworkStatus.ERROR
          ? I18n.t('common.failed')
          : `${I18n.t('common.inProgress')}...`;

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="create-enrollment-alert">
              <p>{`${I18n.t('settings.changingPassword')}:`}</p>
              <p>{text}</p>
              <Button
                onClick={() => {
                  onClose();
                  if (
                    this.props.status === NetworkStatus.DONE ||
                    this.props.status === NetworkStatus.ERROR
                  ) {
                    this.props.passwordChangeReset();
                    if (this.props.status === NetworkStatus.DONE) {
                      this.props.logout({
                        reloadApp: true,
                        clearData: true,
                      });
                    }
                    if (this.props.status === NetworkStatus.ERROR) {
                      this.setState((state) => ({
                        ...state,
                        currentPassword: '',
                        newPassword: '',
                        reTypeNewPassword: '',
                        validationError: this.props.error,
                      }));
                    }
                  }
                }}
              >
                {'OK'}
              </Button>
            </div>
          );
        },
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className={'app__content'}>
          <div className={'page-content'}>
            <div className={'card'}>
              <div className={'account-settings'}>
                <SectionDescription
                  title={I18n.t('settings.passwordChangeTitle')}
                />
                <InputField
                  name={'currentPassword'}
                  label={I18n.t('settings.currentPasswordTitle')}
                  value={this.state.currentPassword}
                  type={'password'}
                  placeholder={I18n.t('settings.currentPasswordPlaceholder')}
                  onChange={this.handleChange}
                />
                <InputField
                  name={'newPassword'}
                  label={I18n.t('settings.newPasswordTitle')}
                  value={this.state.newPassword}
                  type={'password'}
                  placeholder={I18n.t('settings.newPasswordPlaceholder')}
                  onChange={this.handleChange}
                />
                <InputField
                  name={'reTypeNewPassword'}
                  label={I18n.t('settings.reTypeNewPasswordTitle')}
                  value={this.state.reTypeNewPassword}
                  type={'password'}
                  placeholder={I18n.t('settings.reTypeNewPasswordTitle')}
                  onChange={this.handleChange}
                />
                <div style={{ color: '#ff3333' }}>
                  {this.state.validationError}
                </div>
                <div className={'account-settings__footer'}>
                  <Button
                    onClick={this.changePassword}
                    disabled={!!this.state.validationError}
                  >
                    {I18n.t('settings.changePassword')}
                  </Button>
                  <NavLink
                    to={`${RootRoutes.SETTINGS}${SettingsRoutes.ACCOUNT}`}
                  >
                    <Button secondary>{I18n.t('common.cancel')}</Button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  changePassword = () => {
    let validationError = this.getValidationError();
    if (validationError) {
      this.setState((state) => ({ ...state, validationError }));
    } else {
      let { currentPassword, newPassword } = this.state;
      this.props.changePassword(currentPassword, newPassword);
    }
  };

  getValidationError = () => {
    let validationError;
    let { currentPassword, newPassword, reTypeNewPassword } = this.state;
    if (!currentPassword || !newPassword || !reTypeNewPassword) {
      validationError = I18n.t('settings.validationAllPasswordFieldRequired');
    } else if (currentPassword && currentPassword === newPassword) {
      validationError = I18n.t(
        'settings.passwordValidationCantBePreviouslyUsed'
      );
    } else if (this.state.newPassword !== this.state.reTypeNewPassword) {
      validationError = I18n.t('settings.passwordValidationDoesNotMatch');
    } else if (newPassword && newPassword.length < 8) {
      validationError = I18n.t('settings.passwordValidationMinimumLength');
    }
    return validationError;
  };
}

const mapStateToProps = (state) => {
  return {
    data: state.password.data,
    status: state.password.status,
    error: state.password.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (params) => dispatch(logout(params)),
    changePassword: (currentPassword, newPassword) =>
      dispatch(changePassword(currentPassword, newPassword)),
    passwordChangeReset: () => dispatch(passwordChangeReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
