import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { TimeZones } from '../../common/constants';
import { I18n } from 'react-redux-i18n';
import { SelectField } from '../../components/selectField.component';
import { localizationLocales } from '../../assets/translations';
import { SectionDescription } from '../../components/sectionDescription.component';
import { Button } from '../../components/button.component';
import PageContainer from '../../components/PageContainer/PageContainer.component';
import Card from '../../components/Card/Card.component';
import SingleCard from '../../components/Card/SingleCard.component';
import { useDispatch, useSelector } from 'react-redux';
import { updatePreferences } from './preferences.thunk';

const Preferences = () => {
  const dispatch = useDispatch();
  const { timezone, locale } = useSelector((state) => state.preferences);

  const timezoneOptions = useMemo(() => {
    return TimeZones.map((timezone) => {
      return {
        value: timezone[0],
        title: timezone[1],
      };
    });
  }, [TimeZones]);

  const localeOptions = useMemo(() => {
    return Object.values(localizationLocales).map((locale) => {
      return {
        value: locale.code,
        title: locale.name,
      };
    });
  }, [localizationLocales]);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      timezone: timezone || timezoneOptions[0].value,
      locale: locale || localeOptions[0].value,
    },
  });

  const submitHandler = (data) => {
    dispatch(updatePreferences(data));
  };

  const resetHandler = () => {
    reset({ locale: locale, timezone: timezone });
  };

  return (
    <PageContainer>
      <Card>
        <SingleCard>
          <div className={'preferences-settings'}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <SectionDescription title={I18n.t('settings.preferences')} />
              <SelectField
                title={I18n.t('settings.setLocaleFieldTitle')}
                {...register('locale')}
                options={localeOptions}
              />
              <SelectField
                title={I18n.t('settings.setTimezoneFieldTitle')}
                {...register('timezone')}
                options={timezoneOptions}
              />
              <div className="preferences-settings__buttons-container">
                <Button type="submit">
                  {I18n.t('settings.savePreferences')}
                </Button>
                <Button type="button" onClick={resetHandler} secondary>
                  {I18n.t('common.cancel')}
                </Button>
              </div>
            </form>
          </div>
        </SingleCard>
      </Card>
    </PageContainer>
  );
};

export default Preferences;
