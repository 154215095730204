import {
  LOAD_NOTIFICATIONS_FAILED,
  LOAD_NOTIFICATIONS_START,
  LOAD_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_ADD,
  NOTIFICATIONS_HIDE,
  NOTIFICATIONS_HIDE_ALL,
  NOTIFICATIONS_REMOVE,
  NOTIFICATIONS_REMOVE_ALL,
} from './notifications.actionTypes';
import { prepareReceivedNotification } from '../common/utilities/notifications';

export function showNotification(payload) {
  return {
    type: NOTIFICATIONS_ADD,
    payload,
  };
}

export function hideNotification(payload) {
  return {
    type: NOTIFICATIONS_HIDE,
    payload,
  };
}

export function removeNotification(payload) {
  return {
    type: NOTIFICATIONS_REMOVE,
    payload,
  };
}

export function removeAllNotifications() {
  return {
    type: NOTIFICATIONS_REMOVE_ALL,
  };
}

export function hideAllNotifications() {
  return {
    type: NOTIFICATIONS_HIDE_ALL,
  };
}

export function loadNotificationsStart() {
  return {
    type: LOAD_NOTIFICATIONS_START,
  };
}

export function loadNotificationsSuccess(payload) {
  return {
    type: LOAD_NOTIFICATIONS_SUCCESS,
    payload,
  };
}

export function loadNotificationsFailed(payload) {
  return {
    type: LOAD_NOTIFICATIONS_FAILED,
    payload,
  };
}

export function fencingNotificationReceived(notification) {
  return async (dispatch) => {
    try {
      dispatch(showNotification(prepareReceivedNotification(notification)));
    } catch (error) {}
  };
}

export function securityComplianceNotificationReceived(notification) {
  return async (dispatch) => {
    try {
      dispatch(showNotification(prepareReceivedNotification(notification)));
    } catch (error) {}
  };
}

export function batteryLevelNotificationReceived(notification) {
  return async (dispatch) => {
    try {
      dispatch(showNotification(prepareReceivedNotification(notification)));
    } catch (error) {}
  };
}
