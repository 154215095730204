import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../../components/inputField.component';
import { SectionDescription } from '../../components/sectionDescription.component';
import { linkAzureSAML2 } from '../../utils/SSO';
import { azureSAML2, SSOProvidersConstants } from '../../constants/login';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import StyleUtils from '../../utils/styleUtils';
import { getSSOSAMLSettings } from './settings.thunk';
import { companyIdSelector } from '../login/login.selectors';
import {
  getChangedValues,
  isLoadingStatusDone,
  isLoadingStatusStarted,
} from '../../common/helpers';
import './sso.settings.scss';

class SSOSettingsComponent extends Component {
  state = {
    azureAdSAML: {
      loginUrl: '',
      organizationId: '',
      applicationName: '',
      entityId: '',
      federationMetadataXMLFile: null,
    },
  };

  componentDidMount() {
    const { getSSOSAMLSettings, companyId } = this.props;
    getSSOSAMLSettings({ companyId, provider: SSOProvidersConstants.AZURE });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { azureAdSAMLStatus, azureAdSAML } = this.props;
    if (
      isLoadingStatusStarted(prevProps.azureAdSAMLStatus) &&
      isLoadingStatusDone(azureAdSAMLStatus)
    ) {
      this.setState({ azureAdSAML });
    }
  }

  get isAzureAdSAMLFormValid() {
    const { isAzureAdSAMLAlreadySet } = this.props;
    const { azureAdSAML } = this.state;
    const formValues = { ...azureAdSAML };
    delete formValues.id;
    if (isAzureAdSAMLAlreadySet) {
      delete formValues.federationMetadataXMLFile;
      return Object.values(formValues).every(Boolean);
    }
    return Object.values(formValues).every(Boolean);
  }

  onFormFieldChange = ({ target: { value, name, type, files } = {} }) =>
    this.setState((prevState) => ({
      azureAdSAML: {
        ...prevState.azureAdSAML,
        [name]: type === 'file' ? files[0] : value,
      },
    }));

  onAzureSAMLSettingsFormSubmit = (event) => {
    const { isAzureAdSAMLAlreadySet, linkAzureSAML2 } = this.props;
    event.preventDefault();
    if (isAzureAdSAMLAlreadySet) {
      const changedFieldValues = getChangedValues(
        this.props.azureAdSAML,
        this.state.azureAdSAML
      );
      return linkAzureSAML2({
        ...changedFieldValues,
        id: this.props.azureAdSAML?.id,
        provider: SSOProvidersConstants.AZURE,
        isAzureAdSAMLAlreadySet,
      });
    }
    return linkAzureSAML2({
      ...this.state.azureAdSAML,
      provider: SSOProvidersConstants.AZURE,
      isAzureAdSAMLAlreadySet,
    });
  };

  renderSSOLinkButtons = () => (
    <section className="sso-list">
      {[].map(
        ({
          id,
          protocol,
          provider,
          icon,
          linkAccountText,
          linkAccountHeading,
          isAccountLinkingEnabled,
        }) =>
          isAccountLinkingEnabled ? (
            <React.Fragment>
              <SectionDescription title={linkAccountHeading} isSmall />
              <ButtonWithIcon
                key={id}
                onClick={() =>
                  this.props.redirectToSSOProvider({ id, protocol, provider })
                }
                leftIcon={
                  <img
                    src={icon}
                    width={21}
                    height={21}
                    alt={`${provider} ${protocol} icon`}
                  />
                }
                text={linkAccountText}
                className={StyleUtils.mergeModifiers(
                  'sso-list__item',
                  'full-width'
                )}
              />
            </React.Fragment>
          ) : null
      )}
    </section>
  );

  renderSAMLAccountLinkForm = () => {
    const {
      azureAdSAML: { loginUrl, organizationId, applicationName, entityId } = {},
    } = this.state;
    const { isAzureAdSAMLAlreadySet } = this.props;
    const {
      id: azureSAML2Id,
      linkAccountText: linkAccountTextSAML,
      updateAccountText: updateAccountTextSAML,
      icon: submitButtonIcon,
      provider,
      protocol,
    } = azureSAML2;
    return (
      <form onSubmit={this.onAzureSAMLSettingsFormSubmit}>
        <InputField
          name="loginUrl"
          label={I18n.t('settings.loginURLLabel')}
          value={loginUrl}
          onChange={this.onFormFieldChange}
        />
        <InputField
          name="organizationId"
          label={I18n.t('settings.organizationIdLabel')}
          value={organizationId}
          onChange={this.onFormFieldChange}
        />
        <InputField
          name="entityId"
          label={I18n.t('settings.azureADEntityIdLabel')}
          value={entityId}
          onChange={this.onFormFieldChange}
        />
        <InputField
          name="applicationName"
          label={I18n.t('settings.applicationNameLabel')}
          value={applicationName}
          onChange={this.onFormFieldChange}
        />
        <InputField
          name="federationMetadataXMLFile"
          label={I18n.t('settings.federationMetadataXMLFileLabel')}
          type="file"
          onChange={this.onFormFieldChange}
        />
        <section className="sso-list">
          <ButtonWithIcon
            key={azureSAML2Id}
            type="submit"
            disabled={!this.isAzureAdSAMLFormValid}
            leftIcon={
              <img
                src={submitButtonIcon}
                width={21}
                height={21}
                alt={`${provider} ${protocol} icon`}
              />
            }
            text={
              isAzureAdSAMLAlreadySet
                ? updateAccountTextSAML
                : linkAccountTextSAML
            }
            className={StyleUtils.mergeModifiers(
              'sso-list__item',
              'full-width'
            )}
          />
        </section>
      </form>
    );
  };

  render() {
    return (
      <div className="app__content">
        <div className="page-content">
          <div className="card">
            <section className="sso-settings">
              <SectionDescription
                title={I18n.t('settings.SSOSettingsSectionHeading')}
              />
              <SectionDescription
                title={I18n.t('settings.SAMLLinkAccountHeading')}
                isSmall
              />
              <SectionDescription
                title={I18n.t('settings.azureADLinkHeading')}
                hint={I18n.t('settings.SSOSettingsSectionHint')}
                isSmall
              />
              {this.renderSAMLAccountLinkForm()}
              <section style={{ marginTop: '40px' }}>
                {this.renderSSOLinkButtons()}
              </section>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: companyIdSelector(state),
  azureAdSAML: state.settings.azureAdSAML,
  azureAdSAMLStatus: state.settings.azureAdSAMLStatus,
  isAzureAdSAMLAlreadySet: state.settings.isAzureAdSAMLAlreadySet,
});

const mapDispatchToProps = (dispatch) => ({
  linkAzureSAML2: (data) => dispatch(linkAzureSAML2(data)),
  getSSOSAMLSettings: (params) => dispatch(getSSOSAMLSettings(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SSOSettingsComponent);
