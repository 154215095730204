import client from '../../utils/client';
import services from '../../utils/services';
import {
  loadTasksFailed,
  loadTasksStarted,
  tasksLoaded,
} from './tasks.actions';
import { deserializeTask } from './tasks.serializer';
import { I18n } from 'react-redux-i18n';

export function loadTasks({ offset = 0, limit, isInitialLoad = false }) {
  return async (dispatch) => {
    try {
      dispatch(loadTasksStarted());
      let response = await client.get(services.tasks, {
        // params: {
        //     offset: isInitialLoad ? 0 : offset,
        //     limit,
        // }
      });
      if (
        response.status === 200 &&
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data.results)
      ) {
        const { data: { data: { results, next, count } = {} } = {} } = response;
        let result = {
          data: (results || []).map(deserializeTask),
          next,
          count,
          isInitialLoad,
        };
        dispatch(tasksLoaded(result));
      } else {
        dispatch(
          loadTasksFailed(I18n.t('installationStatus.failedToLoadTasks'))
        );
      }
    } catch (error) {
      dispatch(
        loadTasksFailed(
          new Error(I18n.t('installationStatus.failedToLoadTasks'))
        )
      );
    }
  };
}
