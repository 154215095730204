import { I18n } from 'react-redux-i18n';
import { deserializeEnrollment } from '../enrollment/enrollment.serializer';
import client from '../../utils/client';
import services from '../../utils/services';
import {
  enrollmentsDeleted,
  enrollmentsDeletingFailed,
  enrollmentsDeletingStarted,
  enrollmentsLoaded,
  loadEnrollmentsFailed,
  loadEnrollmentsStarted,
} from './enrollments.actions';
import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';
import { companyIdSelector } from '../login/login.selectors';
import { getState } from '../../store/store';

export function loadEnrollments({ offset = 0, isInitialLoad = false }) {
  return async (dispatch) => {
    try {
      dispatch(loadEnrollmentsStarted(isInitialLoad));
      const companyId = companyIdSelector(getState());
      let { status, data, text } = await client.get(
        `${services.getEnrollments}${companyId}`,
        {
          params: {
            offset: isInitialLoad ? 0 : offset,
            limit: INFINITE_SCROLL_LIMIT,
          },
        }
      );
      if (status === 200 && data && Array.isArray(data.results)) {
        const { results, next: nextUrl, count } = data;
        dispatch(
          enrollmentsLoaded({
            data: results.map(deserializeEnrollment),
            nextUrl,
            count,
            isInitialLoad,
          })
        );
      } else {
        dispatch(loadEnrollmentsFailed(text));
      }
    } catch (error) {
      dispatch(
        loadEnrollmentsFailed(I18n.t('enrollments.loadEnrollmentsErrorText'))
      );
    }
  };
}

export function deleteEnrollments(enrollments, companyId) {
  return async (dispatch) => {
    try {
      dispatch(enrollmentsDeletingStarted());
      let response = await client.delete(services.deleteDeviceEnrollment, {
        data: { enrollments },
      });
      if (response.status === 204) {
        dispatch(enrollmentsDeleted());
        dispatch(
          loadEnrollments({
            companyId,
            offset: 0,
            limit: INFINITE_SCROLL_LIMIT,
            isInitialLoad: true,
          })
        );
      } else {
        dispatch(enrollmentsDeletingFailed(response.text));
      }
    } catch (error) {
      dispatch(
        enrollmentsDeletingFailed(
          new Error(I18n.t('enrollments.deleteEnrollmentErrorText'))
        )
      );
    }
  };
}
