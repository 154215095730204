import { I18n } from 'react-redux-i18n';
import { AttributeType } from '../../../../common/constants';
import DeviceStatusIcon from './DeviceStatusIcon';
import DeviceHaveUpdatesIcon from './deviceHaveUpdatesIcon.component';
import BatteryLevel from './BatteryLevel';
import { isDevEnvironment, isDevServer } from '../../../../common/helpers';
import DeviceAnalyticsCollectorControl from './DeviceAnalyticsCollectorControl';
import { DeviceRoutes, FleetRoute } from '../../../../utils/routes';
import { complianceTypes } from '../../../../constants/device';
import ComplianceIcon from './ComplianceIcon';
import { filterConditions } from '../../../../constants/filter';
import { attributiveDataCustomFormatters } from '../../../../common/utilities/attributiveDataCustomFormatters';
import { ReactComponent as LockSettings } from '../../../../assets/images/lockSettings.svg';
import { ReactComponent as UnlockSettings } from '../../../../assets/images/unlockSettings.svg';

export const getDevicesTableCommonColumns = ({
  isCoordinatesColumnHidden = true,
}) => [
  // {
  //     key: 'status',
  //     type: AttributeType.ENUMERABLE,
  //     hidden: true,
  //     titleCode: 'devices.fields.status',
  //     searchable: true,
  //     availableFilterConditions: [
  //       filterConditions.EQUAL,
  //       filterConditions.NOT_EQUAL
  //     ],
  //     availableOptions: [
  //         {key: DeviceStatus.ACTIVE, titleCode: 'online'},
  //         {key: DeviceStatus.INACTIVE, titleCode: 'offline'},
  //         {key: DeviceStatus.UNENROLLED, titleCode: 'unenrolled'},
  //     ]
  // },
  {
    key: 'status',
    customParams: {
      customValueKey: 'lastCheckin',
    },
    filterValueTypeOptions: [
      { key: 'minutes', titleCode: 'common.minutes' },
      { key: 'hours', titleCode: 'common.hours' },
      { key: 'days', titleCode: 'common.days' },
      { key: 'weeks', titleCode: 'common.weeks' },
      { key: 'months', titleCode: 'common.months' },
      { key: 'years', titleCode: 'common.years' },
    ],
    type: AttributeType.TIME_OFFSET,
    titleCode: 'devices.fields.lastSeen',
    renderer: DeviceStatusIcon,
    searchable: true,
    sortable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
      filterConditions.GREATER_THAN_OR_EQUAL,
      filterConditions.LESS_THAN_OR_EQUAL,
    ],
  },
  {
    key: 'locked',
    type: AttributeType.BOOLEAN,
    titleCode: 'devices.fields.deviceConfigurationLockStatus',
    custom: {
      trueIcon: LockSettings,
      falseIcon: UnlockSettings,
    },
    isFalseIconHidden: true,
  },
  // {
  //   key: 'hasLatestAgent',
  //   type: AttributeType.ENUMERABLE,
  //   titleCode: 'devices.fields.latestAgent',
  //   renderer: DeviceHaveUpdatesIcon,
  //   sortable: true,
  //   searchable: true,
  //   availableOptions: [
  //     { key: true, titleCode: 'Yes' },
  //     { key: false, titleCode: 'No' },
  //   ],
  //   availableFilterConditions: [
  //     filterConditions.EQUAL,
  //     filterConditions.NOT_EQUAL
  //   ],
  // },
  {
    key: 'compliance',
    type: AttributeType.ENUMERABLE,
    titleCode: 'devices.fields.compliance',
    renderer: ComplianceIcon,
    sortable: true,
    searchable: true,
    availableOptions: [
      {
        value: complianceTypes.COMPLIANT_AND_LATEST_VERSION,
        title: I18n.t('devices.fields.compliantAndLatestVersion'),
      },
      {
        value: complianceTypes.COMPLIANT_AND_NOT_LATEST_VERSION,
        title: I18n.t('devices.fields.compliantAndNotLatestVersion'),
      },
      {
        value: complianceTypes.NOT_COMPLIANT_VERSION,
        title: I18n.t('devices.fields.notCompliantVersion'),
      },
    ],
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'agentVersion',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.agentVersion',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'osVersion',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.platform',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'coordinates',
    type: AttributeType.COORDINATES,
    titleCode: 'devices.fields.location',
    searchable: true,
    hidden: isCoordinatesColumnHidden,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.NOT_CONTAINED_IN,
    ],
    displayOnUIOnly: true,
  },
  {
    key: 'longitude',
    type: AttributeType.CUSTOM,
    titleCode: 'devices.fields.longitude',
    searchable: true,
    hidden: isCoordinatesColumnHidden,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.NOT_CONTAINED_IN,
    ],
    custom: {
      formatterFunction: attributiveDataCustomFormatters.coordinate,
    },
  },
  {
    key: 'latitude',
    type: AttributeType.CUSTOM,
    titleCode: 'devices.fields.latitude',
    searchable: true,
    hidden: isCoordinatesColumnHidden,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.NOT_CONTAINED_IN,
    ],
    custom: {
      formatterFunction: attributiveDataCustomFormatters.coordinate,
    },
  },
  {
    key: 'batteryLevel',
    type: AttributeType.PERCENTAGE,
    titleCode: 'devices.fields.batteryLevel',
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
    renderer: BatteryLevel,
  },
  {
    key: 'lastCheckin',
    type: AttributeType.DATE,
    titleCode: 'devices.fields.lastOnline',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'enrollmentDatetime',
    type: AttributeType.DATE,
    titleCode: 'devices.fields.enrollmentDatetime',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'manufacturer',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.manufacturer',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.BEGINS_WITH,
      filterConditions.ENDS_WITH,
    ],
  },
  {
    key: 'model',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.model',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.BEGINS_WITH,
      filterConditions.ENDS_WITH,
    ],
  },
  {
    key: 'macAddress',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.macAddress',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  {
    key: 'ethernetMacAddress',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.ethernetMacAddress',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
    ],
  },
  {
    key: 'ipAddress',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.ipAddress',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.BEGINS_WITH,
      filterConditions.ENDS_WITH,
    ],
  },
  {
    key: 'totalMemory',
    type: AttributeType.SIZE,
    titleCode: 'devices.fields.totalMemory',
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'availableMemory',
    type: AttributeType.SIZE,
    titleCode: 'devices.fields.availableMemory',
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'totalStorage',
    type: AttributeType.SIZE,
    titleCode: 'devices.fields.totalStorage',
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'availableStorage',
    type: AttributeType.SIZE,
    titleCode: 'devices.fields.availableStorage',
    searchable: true,
    availableFilterConditions: [
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'hardwareSerialNumber',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.serialNumber',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.DOES_NOT_CONTAIN,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
      filterConditions.BEGINS_WITH,
      filterConditions.DOES_NOT_BEGIN_WITH,
      filterConditions.ENDS_WITH,
      filterConditions.DOES_NOT_END_WITH,
    ],
  },
  {
    key: 'imei',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.imei',
    searchable: true,
    availableFilterConditions: [filterConditions.EQUAL],
  },
  {
    key: 'networkSsid',
    type: AttributeType.TEXT,
    titleCode: 'device.networkSsid',
    searchable: true,
    availableFilterConditions: [filterConditions.EQUAL],
  },
  {
    key: 'networkBssid',
    type: AttributeType.TEXT,
    titleCode: 'device.networkBssid',
    searchable: true,
    availableFilterConditions: [filterConditions.EQUAL],
  },
  {
    key: 'networkRssi',
    type: AttributeType.TEXT,
    titleCode: 'device.networkRssi',
    searchable: true,
    availableFilterConditions: [filterConditions.EQUAL],
  },
  {
    key: 'networkDNS',
    type: AttributeType.TEXT,
    titleCode: 'device.networkDNS',
    searchable: true,
    availableFilterConditions: [filterConditions.EQUAL],
  },
  {
    key: 'customNotes',
    type: AttributeType.TEXT,
    titleCode: 'device.customNotes',
    searchable: true,
    titleKey: 'customNotes',
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.BEGINS_WITH,
      filterConditions.ENDS_WITH,
      filterConditions.CONTAINS,
      filterConditions.DOES_NOT_CONTAIN,
    ],
  },
  {
    key: 'sendingAnalyticsData',
    type: AttributeType.BOOLEAN,
    titleCode: 'devices.fields.sendingAnalyticsData',
    renderer: DeviceAnalyticsCollectorControl,
    hidden: !isDevEnvironment(),
  },
  {
    key: 'enabledAutomaticUpdates',
    type: AttributeType.BOOLEAN,
    titleCode: 'devices.fields.automaticAgentUpdatesEnabled',
  },
];

export const getAllDevicesTableColumns = ({
  isCoordinatesColumnHidden = true,
}) => [
  {
    key: 'fleetName',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.fleetName',
    ref: `${FleetRoute}`,
    refId: 'fleetId',
    searchable: true,
    resizable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.BEGINS_WITH,
      filterConditions.ENDS_WITH,
    ],
  },
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.deviceName',
    ref: `${DeviceRoutes}`,
    refId: 'id',
    searchable: true,
    resizable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.DOES_NOT_CONTAIN,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
      filterConditions.BEGINS_WITH,
      filterConditions.DOES_NOT_BEGIN_WITH,
      filterConditions.ENDS_WITH,
      filterConditions.DOES_NOT_END_WITH,
    ],
    customColumnOptions: {
      minWidth: 150,
    },
  },
  ...getDevicesTableCommonColumns({
    isCoordinatesColumnHidden,
  }),
];

export const getFleetDevicesTableColumns = ({
  isCoordinatesColumnHidden = true,
}) => [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'devices.fields.deviceName',
    ref: `${DeviceRoutes}`,
    refId: 'id',
    dynamicRefParams: [
      {
        key: 'fleetId',
        paramValueKey: 'fleetId',
      },
    ],
    searchable: true,
    resizable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.DOES_NOT_CONTAIN,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
      filterConditions.BEGINS_WITH,
      filterConditions.DOES_NOT_BEGIN_WITH,
      filterConditions.ENDS_WITH,
      filterConditions.DOES_NOT_END_WITH,
    ],
    customColumnOptions: {
      minWidth: 150,
    },
  },
  ...getDevicesTableCommonColumns({
    isCoordinatesColumnHidden,
  }),
];

export const getDefaultDevicesTableVisibleColumns = () =>
  getFleetDevicesTableColumns({ isCoordinatesColumnHidden: true }).reduce(
    (acc, curr) => (curr.hidden ? acc : [...acc, curr.key]),
    []
  );
