import React from 'react';
import { I18n } from 'react-redux-i18n';
import * as moment from 'moment-timezone';
import { AttributeType, DEFAULT_DATE_TIME_FORMAT } from '../constants';
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle.svg';
import { ReactComponent as CrestCircle } from '../../assets/images/crest-circle.svg';
import { bytesToSize, coordinatesDDToDMS } from '../helpers';

export const formatAttributiveDataFormat = ({
  attribute = {},
  values = {},
  options: { dataOnly = false } = {},
}) => {
  let formattedValue;
  const valueKey = attribute.custom?.customValueKey ?? attribute.key;
  const value =
    attribute.custom?.valueGetter?.({
      values,
      key: valueKey,
    }) ?? values[valueKey];
  switch (attribute.type) {
    case AttributeType.TEXT: {
      formattedValue = Array.isArray(value)
        ? value.join(attribute.valueSeparator ?? ', ')
        : value;
      break;
    }
    case AttributeType.ENUMERABLE: {
      const { title = '' } =
        attribute.availableOptions?.find(
          ({ title, value: optionValue }) => value === optionValue
        ) ?? {};
      formattedValue = title;
      break;
    }
    case AttributeType.BOOLEAN: {
      if (dataOnly) {
        formattedValue = value ? I18n.t('common.yes') : I18n.t('common.no');
      } else {
        const isFalseIconHidden = attribute.isFalseIconHidden;
        const {
          custom: { trueIcon: CustomTrueIcon, falseIcon: CustomFalseIcon } = {},
        } = attribute;
        const trueIcon = CustomTrueIcon ? (
          <CustomTrueIcon className="icon--fixed-square" />
        ) : (
          <CheckCircle className="icon--fixed-square" />
        );
        const falseIcon = CustomFalseIcon ? (
          <CustomFalseIcon className="icon--fixed-square" />
        ) : (
          <CrestCircle className="icon--fixed-square" />
        );

        formattedValue = value
          ? trueIcon
          : isFalseIconHidden
          ? null
          : falseIcon;
      }
      break;
    }
    case AttributeType.DATE: {
      if (!value) {
        formattedValue = '';
        break;
      }
      const timezone = moment.tz?.guess?.();
      let momentValue = moment.utc(value);
      if (momentValue.isValid()) {
        formattedValue = momentValue
          .tz(timezone)
          .format(attribute.custom?.dateFormat ?? DEFAULT_DATE_TIME_FORMAT)
          .toString();
      }
      break;
    }
    case AttributeType.COORDINATES: {
      formattedValue = coordinatesDDToDMS(value?.latitude, value?.longitude);
      if (dataOnly) {
        console.log(formattedValue);
        console.warn(formattedValue.split(',').join(' '));
        formattedValue = formattedValue.replace(/,/gi, ' ');
      }
      break;
    }
    case AttributeType.PERCENTAGE: {
      formattedValue = `${value ?? 0} %`;
      break;
    }
    case AttributeType.SIZE: {
      formattedValue = bytesToSize(value);
      break;
    }
    case AttributeType.CUSTOM: {
      formattedValue = attribute.custom?.formatterFunction?.({
        values,
        key: valueKey,
      });
      break;
    }
    default:
      formattedValue = value ?? '';
      break;
  }

  return formattedValue;
};
