import { I18n } from 'react-redux-i18n';
import PROFILE_CONFIG_CONSTANTS from '../../constants/profileConfigConstants';
import { GLOBAL_READ_READWRITE_PERMISSIONS } from '../../constants/globalPermissions';

export const defaultProfileMenuStructure = [
  {
    key: PROFILE_CONFIG_CONSTANTS.PROFILE_DETAILS,
    label: I18n.t('profiles.profileDetailsTitle'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_DETAILS,
    isAlwaysRead: true,
  },
];

export const profileMenuStructure = [
  {
    key: PROFILE_CONFIG_CONSTANTS.PROFILE_DETAILS,
    label: I18n.t('profiles.profileDetailsTitle'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_DETAILS,
    isAlwaysRead: true,
  },
  // {
  //     key: PROFILE_CONFIG_CONSTANTS.AUTHENTICATION,
  //     label: "Authentication",
  //     permissionProperty: 'authentication',
  // },
  {
    key: PROFILE_CONFIG_CONSTANTS.FEATURE_CONTROL,
    label: I18n.t('profiles.featureControl.title'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.FEATURE_CONTROL,
  },
  {
    label: I18n.t('profiles.applicationsTitle'),
    key: PROFILE_CONFIG_CONSTANTS.APPLICATIONS,
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS,
    sub: [
      {
        key: PROFILE_CONFIG_CONSTANTS.APP_MANAGEMENT,
        label: I18n.t('profiles.apps.policyTitle'),
        permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.APP_MANAGEMENT,
      },
      {
        key: PROFILE_CONFIG_CONSTANTS.BLACKLIST,
        label: I18n.t('profiles.blacklist.policyTitle'),
        permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.BLACKLIST,
      },
    ],
  },
  {
    key: PROFILE_CONFIG_CONSTANTS.CERTIFICATES,
    label: I18n.t('profiles.certificates.policyTitle'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.CERTIFICATES,
  },
  {
    label: I18n.t('profiles.networkTitle'),
    key: PROFILE_CONFIG_CONSTANTS.NETWORK,
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.NETWORK,
    sub: [
      {
        key: PROFILE_CONFIG_CONSTANTS.WIFI,
        label: I18n.t('profiles.wifi.title'),
        permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.WIFI,
      },
      {
        key: PROFILE_CONFIG_CONSTANTS.WEB_FILTER,
        label: I18n.t('profiles.webFilter.webFilter'),
        permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.WEB_FILTER,
      },
      {
        key: PROFILE_CONFIG_CONSTANTS.APN,
        label: I18n.t('profiles.apn.policyTitle'),
        permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.APN,
      },
    ],
  },
  // {
  //     key: PROFILE_CONFIG_CONSTANTS.LOCATION_SERVICES,
  //     label: "Location Services",
  //     permissionProperty: "locationServices",
  // },
  // {
  //     key: PROFILE_CONFIG_CONSTANTS.DATA_COLLECTION,
  //     label: "Data Collection",
  //     permissionProperty: "dataCollection",
  // },
  // {
  //     key: PROFILE_CONFIG_CONSTANTS.FIRMWARE_UPDATE,
  //     label: "Firmware Update",
  //     permissionProperty: "firmwareUpdate",
  // },
  {
    key: PROFILE_CONFIG_CONSTANTS.KIOSK_MODE,
    label: I18n.t('profiles.kiosk.title'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.KIOSK_MODE,
  },
  {
    key: PROFILE_CONFIG_CONSTANTS.FILE_SYNC,
    label: I18n.t('profiles.fileSync.policyTitle'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.FILE_SYNC,
  },
  {
    key: PROFILE_CONFIG_CONSTANTS.GEO_FENCING,
    label: I18n.t('profiles.geofencing.policyTitle'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.GEOFENCING,
  },
  {
    key: PROFILE_CONFIG_CONSTANTS.PASSWORD,
    label: I18n.t('profiles.password.policyTitle'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.PASSWORD,
  },
  {
    key: PROFILE_CONFIG_CONSTANTS.SECURITY,
    label: I18n.t('profiles.security.policyTitle'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.SECURITY,
  },
  {
    key: PROFILE_CONFIG_CONSTANTS.WALLPAPER_SETTINGS,
    label: I18n.t('profiles.wallpaperSettings.wallpaperSettings'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.WALLPAPER_SETTINGS,
  },
  {
    key: PROFILE_CONFIG_CONSTANTS.SETTINGS,
    label: I18n.t('profiles.settings.policyTitle'),
    permissionProperty: GLOBAL_READ_READWRITE_PERMISSIONS.SETTINGS,
  },
];

export const assignMenuStructure = [
  {
    key: PROFILE_CONFIG_CONSTANTS.FLEETS,
    label: I18n.t('fleets.fleetsTitle'),
  },
];

// for testing
// export const profileMenuStructure = getAccessibleMenuItems(profileMenuStructure1, globalPermissionsMock );
