import React from 'react';
import { I18n } from 'react-redux-i18n';
import ToolsPanel from '../../../components/ToolsPanel';
import Tool from '../../../components/ToolBar/Tool';
import { connect } from 'react-redux';
import { FLEET_TREE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS } from '../../../constants/globalPermissions';
import { companyIdSelector } from '../../login/login.selectors';
import UserHelpers from '../../../common/utilities/user';
import { unenrollDevices } from '../../fleets/fleets.thunk';
import {
  changeDeviceStatus,
  removeDevices,
  sendRemoteCommand,
} from '../devices.thunk';
import { getDeviceCommandItems } from '../devices.helpers';
import { fleetsV2Selector } from '../../fleets/fleetsV2.selectors';

const DeviceCommands = ({ selectedDevices, ...otherProps }) => {
  const deviceCommandItems = getDeviceCommandItems({
    ...otherProps,
  });

  return (
    <ToolsPanel
      selectedItems={selectedDevices}
      title={I18n.t('fleets.toolsPanelTitle')}
    >
      {deviceCommandItems.map(({ key, icon: Icon, ...rest }) => (
        <Tool key={key} {...rest}>
          <Icon className={'icon'} />
        </Tool>
      ))}
    </ToolsPanel>
  );
};

const mapStateToProps = (state) => {
  const fleetTreeGlobalPermissionsList =
    (state.groups.modelLevelPermissionsData &&
      state.groups.modelLevelPermissionsData.fleettree) ||
    [];
  const isGlobalFleetAccessReadWriteAccess = FLEET_TREE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS.every(
    (item) => fleetTreeGlobalPermissionsList.includes(item)
  );
  const { data: fleets } = fleetsV2Selector(state);
  return {
    fleets,
    companyId: companyIdSelector(state),
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    isGlobalFleetAccessReadWriteAccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    unEnrollDevices: (devicesIds, fleetId) =>
      dispatch(unenrollDevices(devicesIds, fleetId)),
    removeDevices: (devicesIds, fleetId) =>
      dispatch(removeDevices(devicesIds, fleetId)),
    changeDeviceStatus: (params) => dispatch(changeDeviceStatus(params)),
    sendRemoteCommand: (params) => dispatch(sendRemoteCommand(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceCommands);
