import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { NetworkStatus } from '../../../common/constants';
import { tasksApplicationsColumns } from '../../tasks/tasks.constants';
import { Throbber } from '../../../components/Throbber';
import DataTable from '../../../components/DataTable/DataTable.component';

export class TaskApplications extends Component {
  state = {
    activeIndex: undefined,
  };

  render() {
    if (!this.props.item) {
      if (this.props.status === NetworkStatus.STARTED) {
        return <Throbber />;
      }
      return null;
    }

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          backgroundColor: '#fff',
          flexDirection: 'column',
          flexWrap: 'nowrap',
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '15px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '21px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: '0.86',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000000',
                }}
              >
                {I18n.t('applications.title')}
              </div>
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: '1.5',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000000',
                  marginTop: '5px',
                }}
              >
                {I18n.t('installationStatus.relatedApplicationsTitle')}
              </div>
            </div>
            <DataTable
              allColumns={tasksApplicationsColumns}
              data={
                this.props.item && this.props.item.apps
                  ? this.props.item.apps
                  : []
              }
              loadingStatus={this.props.status}
              useFlex
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TaskApplications;
