import { I18n } from 'react-redux-i18n';

import {
  loadUsersLogsStart,
  loadUsersLogsFailed,
  loadUsersLogsSuccess,
} from './usersLogs.action';
import client from '../../utils/client';
import services from '../../utils/services';
import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';
import { handleRequestResponse } from '../../common/utilities/errorHandling';
import RouteHelpers from '../../common/utilities/routeHelpers';

export function loadUsersLogs({
  offset = 0,
  limit = INFINITE_SCROLL_LIMIT,
  isInitialLoad = false,
  ...otherParams
}) {
  return async (dispatch) => {
    try {
      dispatch(loadUsersLogsStart(isInitialLoad));
      let response = await client.get(services.getUserActivity, {
        params: {
          offset: isInitialLoad ? 0 : offset,
          limit,
          ...otherParams,
        },
      });
      handleRequestResponse({
        response,
        successStatusCode: 200,
        onSuccess: () => {
          const { results, next: nextUrl, count } = response.data;
          const { offset } = RouteHelpers.getURLQueryParams(nextUrl);
          const hasMore = Boolean(nextUrl);
          const processedData = results
            .map(deserializeDeviceChanges)
            .sort(function (a, b) {
              return new Date(b.actionTimestamp) - new Date(a.actionTimestamp);
            });
          dispatch(
            loadUsersLogsSuccess({
              data: processedData,
              nextUrl,
              count,
              isInitialLoad,
              offset,
              hasMore,
              isFullList: !nextUrl,
            })
          );
        },
        showSuccessPopup: false,
        onError: () => {
          dispatch(loadUsersLogsFailed(I18n.t('users.loadUsersLogsError')));
        },
        defaultErrorMessageTextCode: 'users.loadUsersLogsError',
      });
    } catch (error) {
      dispatch(loadUsersLogsFailed(I18n.t('users.loadUsersLogsError')));
    }
  };
}

export const deserializeDeviceChanges = (data) => {
  return {
    username: data.customer_username,
    logDetails: data.log_details,
    ipAddress: data.ip_address,
    actionTimestamp: data.action_timestamp,

    id: data.id,
    userId: data.userId,
    userName: data.userName || '',
    groupId: data.groupId,
    message: data.message,
    date: data.date,
    type: data.type,
  };
};
