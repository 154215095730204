// warning: do NOT localize!!!
export const AnalyticsCategory = {
  PROFILE: 'profile',
};

export const AnalyticsProfileConstants = {
  PROFILE_CREATION: 'Profile creation',
  PROFILE_V1_CREATION: 'Profile v1 creation',
  PROFILE_V1_UPDATE: 'Profile v1 updated',
  SAVED_AS_DRAFT: 'Saved as draft',
  SAVED_AND_PUBLISHED: 'Saved and published',
  CREATED_BRAND_NEW: 'created brand new',
  CREATED_BRAND_NEW_AND_PUBLISHED: 'created brand new and published',
  CREATED_NEW_VERSION: 'created new version',
  CREATED_NEW_VERSION_AND_PUBLISH: 'created new version and publish',
};

export const TrackingCodes = {
  'dev.springdel.com': 'UA-210818514-1',
  'edge.springdel.com': 'UA-210818514-2',
  'ping.e-incube.com': 'UA-210818514-3',
  'mdm.janam.com': 'UA-210818514-4',
  'uem.springdel.com': 'UA-210818514-5',
  'qa.springdel.com': 'UA-210818514-6',
  'qa-antique.springdel.com': 'UA-210818514-7',
  'sandbox.janam.com': 'UA-210818514-8',
};
