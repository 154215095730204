import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { cloneDeep, get } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import ApplicationBundle from './ApplicationBundle';
import { reorder, selectForCurrentBrand } from '../../../common/helpers';
import {
  initialSettings,
  getInitialPolicy,
  kioskPolicyTypes,
} from '../profile.constants';
import KioskBuilderSideBar from './KioskBuilderSideBar.component';
import DragNDropList from '../../../components/DragNDropList/DragNDropList.component';
import { I18n, Translate } from 'react-redux-i18n';
import DragNDropDestinationListHeader from '../../../components/DragNDropComponents/DragNDropDestinationListHeader.component';
import { REMOTE_CONTROL_APPLICATION_NAME } from '../../../constants/applications';

const grid = 4;

const getItemStyle = (isDragging, draggableStyle = {}) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: isDragging ? 'normal' : 'break-word',
  // change background colour if dragging
  background: 'white', // isDragging ? 'lightgray' : 'white',
  borderBottom: '1px solid lightgray',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgray' : 'white',
  padding: grid,
  width: 250,
});

export class KioskBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordConfirmation: '',
      validationError: null,
    };
  }

  componentDidMount() {
    if (
      this.props.policy[0] &&
      this.props.policy[0].settings &&
      this.props.policy[0].settings.kioskBackdoorPwd
    ) {
      this.setState((state) => ({
        ...state,
        passwordConfirmation: this.props.policy[0].settings.kioskBackdoorPwd,
      }));
    }
  }

  getCustomUI = (destination) => ({ onClose }) => {
    return (
      <ApplicationBundle
        destinationIndex={0}
        onSubmit={(destinationIndex, value) =>
          this.addBundle(destination, destinationIndex, value)
        }
        onCancel={onClose}
      />
    );
  };

  addBundle = (destination, destinationIndex, value) => {
    if (destination === 'apps' || destination === 'whitelisted') {
      const applications = this.removeRemoteControlApp(
        this.props.applicationsFullList
      );
      const managedApp = applications.find(
        ({ idName }) => idName === value?.idName
      );
      if (destination === 'apps' && value?.idName && managedApp) {
        const {
          id,
          name,
          idName,
          version,
          versionCode,
          fileChecksum,
          status,
          installOnly,
        } = managedApp;
        this.onChangeArray({
          index: 0,
          key: 'apps',
          value: {
            id,
            name,
            idName,
            version,
            versionCode,
            fileChecksum,
            status,
            installOnly,
          },
          doNotRemove: true,
          syncWithAppManagement: true,
          result: {
            source: {
              droppableId: 'items',
            },
            destination: {
              droppableId: 'apps',
            },
          },
        });
      } else {
        this.onChangeArray({
          index: 0,
          key: destination,
          value,
          doNotRemove: true,
        });
      }
    } else {
      this.onChange(0, destination, { target: { value } });
    }
  };

  onChangePasswordConfirmation = ({ target: { value } }) => {
    this.setState({ passwordConfirmation: value }, () => this.validate(value));
  };

  validate = (value) => {
    if (
      this.props.policy[0] &&
      this.props.policy[0].settings &&
      this.props.policy[0].settings.kioskBackdoorPwd
    ) {
      if (
        value === this.props.policy[0].settings.kioskBackdoorPwd &&
        this.state.validationError
      ) {
        this.setState({ validationError: null });
      }
      if (
        value !== this.props.policy[0].settings.kioskBackdoorPwd &&
        !this.state.validationError
      ) {
        this.setState({
          validationError: I18n.t('profiles.kiosk.passwordDoesNotMatch'),
        });
      }
    } else {
      this.setState({
        validationError: I18n.t('profiles.kiosk.backdoorPasswordDescription'),
      });
    }
  };

  onSave = (isValid) =>
    isValid
      ? this.props.onSave()
      : this.validate(this.state.passwordConfirmation);

  onShowAssignFleets = (isValid) =>
    isValid
      ? this.props.onShowAssignFleets()
      : this.validate(this.state.passwordConfirmation);

  createNewVersion = (isValid) =>
    isValid
      ? this.props.onCreateNewVersion()
      : this.validate(this.state.passwordConfirmation);

  onChange = (index, key, { target: { value } }) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...getInitialPolicy(kioskPolicyTypes.KIOSK) };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    settings[key] = value;
    item.settings = settings;
    this.props.onChange(kioskPolicyTypes.KIOSK, index, item);
  };

  onChangeArray = ({
    index,
    key,
    value,
    doNotRemove,
    result: { source = {}, destination = {} } = {},
    syncWithAppManagement = false,
  }) => {
    const isMovedWithinSameList =
      source &&
      destination &&
      source.droppableId &&
      destination.droppableId &&
      source.droppableId === destination.droppableId;
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...getInitialPolicy(kioskPolicyTypes.KIOSK) };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    let setting = get(settings, key, []);
    settings[key] = cloneDeep(setting);
    const appKey = 'idName';
    let foundIndex = settings[key].findIndex(
      (item) => item[appKey] === value[appKey]
    );
    if (foundIndex === -1) {
      if (syncWithAppManagement) {
        const appManagementPolicy = this.props.policies?.apps?.[0] ?? {};
        let appManagementPolicyClone = cloneDeep(appManagementPolicy);
        const {
          settings = {},
          settings: { apps = [], apps_v2 = [] } = {},
        } = appManagementPolicyClone;
        if (value.id && !apps.includes(value.id)) {
          const newAppsList = [...apps, value.id];
          const newApp =
            this.props.applicationsFullList.find(({ id }) => id === value.id) ??
            {};
          const newAppV2 = [...apps_v2, newApp];
          appManagementPolicyClone = {
            ...appManagementPolicyClone,
            isActive: true,
            policyType: 'app',
            policyData: null,
            policyName: 'new',
            settings: {
              ...settings,
              apps: newAppsList,
              apps_v2: newAppV2,
            },
          };
          this.props.onChange('apps', 0, appManagementPolicyClone);
        }
      }
      settings[key].splice(destination.index, 0, value);
    } else if (isMovedWithinSameList) {
      settings[key] = reorder(settings[key], source.index, destination.index);
    } else if (!doNotRemove) {
      // this part is for when item is dropped outside the list
      settings[key].splice(foundIndex, 1);
    }
    item.settings = settings;
    this.props.onChange(kioskPolicyTypes.KIOSK, index, item);
  };

  getExistingApp = (packageId) => {
    const { applicationsFullList } = this.props;
    const appManagementPolicy = this.props.policies?.apps?.[0] ?? {};
    let appManagementPolicyClone = cloneDeep(appManagementPolicy);
    const { settings: { apps_v2 = [] } = {} } = appManagementPolicyClone;

    // returning the app found in app management (apps_v2), if any, in order to maintain
    // a sync between kiosk and app management app versions.
    // This means that if a version of the app is already in app management,
    // that version would be used here too, else a new one (default) is added.
    return (
      apps_v2.find(({ idName }) => packageId === idName) ??
      applicationsFullList.find(({ idName }) => packageId === idName)
    );
  };

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    let expression = new RegExp(`^${source.droppableId}_`);

    // if dropped outside the list
    if (
      (source.droppableId === 'apps' || source.droppableId === 'whitelisted') &&
      (!destination || source.droppableId !== destination.droppableId)
    ) {
      const existingApp = this.getExistingApp(
        draggableId.replace(expression, '')
      );
      if (existingApp) {
        const { id, name, idName, version, versionCode } = existingApp;
        this.onChangeArray({
          index: 0,
          key: source.droppableId,
          value: { id, name, idName, version, versionCode },
          doNotRemove: false,
        });
      } else {
        this.onChangeArray({
          index: 0,
          key: source.droppableId,
          value: { idName: draggableId.replace(expression, '') },
          doNotRemove: false,
        });
      }
    }
    if (
      destination &&
      (destination.droppableId === 'apps' ||
        destination.droppableId === 'whitelisted')
    ) {
      const itemIdName =
        source.droppableId !== 'items'
          ? draggableId.replace(expression, '')
          : draggableId;

      const existingApp = this.getExistingApp(itemIdName);
      if (existingApp) {
        const {
          id,
          name,
          idName,
          version,
          versionCode,
          fileChecksum,
          status,
          installOnly,
        } = existingApp;
        this.onChangeArray({
          index: 0,
          key: destination.droppableId,
          value: {
            id,
            name,
            idName,
            version,
            versionCode,
            fileChecksum,
            status,
            installOnly,
          },
          doNotRemove: true,
          syncWithAppManagement: destination.droppableId === 'apps',
          result,
        });
      } else {
        this.onChangeArray({
          index: 0,
          key: destination.droppableId,
          value: { idName: itemIdName },
          doNotRemove: true,
          result,
        });
      }
    }

    if (
      destination &&
      destination.droppableId === 'autolaunch' &&
      source.droppableId !== destination.droppableId
    ) {
      const itemIdName = draggableId.replace(expression, '');
      const existingApp = this.getExistingApp(itemIdName);
      if (existingApp) {
        this.onChange(0, 'autolaunchApp', {
          target: {
            value: {
              id: existingApp.id,
              idName: existingApp.idName,
              name: existingApp.name,
            },
          },
        });
      } else {
        this.onChange(0, 'autolaunchApp', {
          target: { value: { idName: itemIdName } },
        });
      }
    }
  };

  clearAutolaunchApp = () => this.onChange(0, 'autolaunchApp', { target: {} });

  getAutolaunchAppList = ({ autolaunchApp }) => {
    if (autolaunchApp) {
      const { applicationsFullList } = this.props;
      const { id, idName = 'n/a' } = autolaunchApp;
      const foundApplication = applicationsFullList.find(
        ({ id: existingAppId }) => existingAppId === id
      );
      let { name: foundAppName = '', version = '' } = foundApplication || {};
      return [
        {
          ...autolaunchApp,
          idName: idName || foundAppName,
          version,
        },
      ];
    }
    return null;
  };

  removeRemoteControlApp = (items = []) =>
    items.filter(({ idName }) => idName !== REMOTE_CONTROL_APPLICATION_NAME);

  renderSectionDescription = () => (
    <React.Fragment>
      <div className={'section-description__title--small'}>
        {I18n.t('profiles.kiosk.kioskBuilder')}
      </div>
      <div>{I18n.t('profiles.kiosk.kioskBuilderHint1')}</div>
      <div>{I18n.t('profiles.kiosk.kioskBuilderHint2')}</div>
    </React.Fragment>
  );

  renderSettings = ({ settings }, index) => {
    const listFooterClass = selectForCurrentBrand({
      original: 'kiosk-builder-list-footer',
      incube: 'kiosk-builder-list-footer kiosk-builder-list-footer--incube',
      janam: 'kiosk-builder-list-footer kiosk-builder-list-footer--janam',
    });
    return (
      <div className="kiosk-builder">
        <KioskBuilderSideBar
          onBack={this.props.onBack}
          settings={settings}
          validationError={this.state.validationError}
          onKioskBackdoorPwdChange={(e) =>
            this.onChange(index, 'kioskBackdoorPwd', e)
          }
          passwordConfirmation={this.state.passwordConfirmation}
          onChangePasswordConfirmation={this.onChangePasswordConfirmation}
          sectionDescription={this.renderSectionDescription()}
        />
        <div className={'kiosk-builder-content-wrapper-container'}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className={'kiosk-builder-content-wrapper'}>
              <div className={'kiosk-builder-content'}>
                <div className={'kiosk-builder-content-actions-section'}>
                  <div />
                  <div>
                    <Button
                      disabled={!this.props.changed}
                      secondary
                      onClick={this.props.onUndo}
                    >
                      {I18n.t('common.undo')}
                    </Button>
                    <Button
                      style={{ marginLeft: '5px' }}
                      disabled={!this.props.changed}
                      onClick={() =>
                        this.onSave(
                          settings.kioskBackdoorPwd &&
                            settings.kioskBackdoorPwd ===
                              this.state.passwordConfirmation
                        )
                      }
                    >
                      <Translate value="profiles.saveButtonText" />
                    </Button>
                    {this.props.profile?.id ? (
                      <Button
                        style={{ marginLeft: '5px' }}
                        disabled={!this.props.changed}
                        onClick={() =>
                          this.onShowAssignFleets(
                            settings.kioskBackdoorPwd &&
                              settings.kioskBackdoorPwd ===
                                this.state.passwordConfirmation
                          )
                        }
                      >
                        <Translate value="profiles.associateFleetsTitle" />
                      </Button>
                    ) : null}
                  </div>
                </div>
                <DragNDropList
                  listHeader={
                    <DragNDropDestinationListHeader
                      iconProps={{
                        onClick: () =>
                          confirmAlert({
                            customUI: this.getCustomUI('autolaunchApp'),
                          }),
                      }}
                      listTitle={I18n.t('profiles.kiosk.autoLaunchApp')}
                    />
                  }
                  droppableListId="autolaunch"
                  items={this.getAutolaunchAppList(settings)}
                  itemOptions={{
                    actionButtonProps: {
                      onClick: this.clearAutolaunchApp,
                    },
                    displayTextKeys: {
                      leftCell: ['idName', 'name'],
                    },
                  }}
                  idSubstitute="idName"
                  isShort
                />

                <DragNDropList
                  listHeader={
                    <DragNDropDestinationListHeader
                      iconProps={{
                        onClick: () =>
                          confirmAlert({
                            customUI: this.getCustomUI('whitelisted'),
                          }),
                      }}
                      listTitle={I18n.t('profiles.kiosk.whitelistServices')}
                    />
                  }
                  droppableListId="whitelisted"
                  items={get(settings, 'whitelisted', [])}
                  itemOptions={{
                    displayTextKeys: {
                      leftCell: ['idName', 'name'],
                    },
                  }}
                  idSubstitute="idName"
                />

                <DragNDropList
                  listHeader={
                    <DragNDropDestinationListHeader
                      iconProps={{
                        onClick: () =>
                          confirmAlert({ customUI: this.getCustomUI('apps') }),
                      }}
                      listTitle={I18n.t('profiles.kiosk.statusBar')}
                    />
                  }
                  listFooter={
                    <div className={listFooterClass}>
                      <div className={'kiosk-builder-list-footer__label'}>
                        {I18n.t('profiles.kiosk.backKeyText')}
                      </div>
                      <div className={'kiosk-builder-list-footer__label'}>
                        {I18n.t('profiles.kiosk.homeKeyText')}
                      </div>
                      <div className={'kiosk-builder-list-footer__label'}>
                        {I18n.t('profiles.kiosk.menuKeyText')}
                      </div>
                    </div>
                  }
                  droppableListId="apps"
                  items={this.removeRemoteControlApp(get(settings, 'apps', []))}
                  itemOptions={{
                    displayTextKeys: {
                      leftCell: ['idName', 'name'],
                    },
                  }}
                  idSubstitute="idName"
                />
              </div>
              <div className={'kiosk-builder-content-wrapper-side-bar'}>
                <div
                  className={
                    'kiosk-builder-content-wrapper-side-bar-section-description'
                  }
                >
                  <div className={'section-description__title--small'}>
                    {I18n.t('applications.myApplicationsTitle')}
                  </div>
                  <div> </div>
                </div>
                <div className={'kiosk-builder-content-wrapper-side-bar-list'}>
                  <div
                    className={
                      'kiosk-builder-content-wrapper-side-bar-list__header'
                    }
                  >
                    <div>{I18n.t('applications.fields.packageId')}</div>
                  </div>
                  <Droppable droppableId="items">
                    {(provided, snapshot) => (
                      <div
                        className={
                          'kiosk-builder-content-wrapper-side-bar-list-content'
                        }
                        ref={provided.innerRef}
                        style={{ ...getListStyle(snapshot.isDraggingOver) }}
                      >
                        {this.removeRemoteControlApp(
                          this.props.applicationsFullList
                        ).map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.idName}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={
                                  'kiosk-builder-content-wrapper-side-bar-list-content-item'
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  ),
                                  maxWidth: snapshot.isDragging
                                    ? '320px'
                                    : '100%',
                                }}
                              >
                                <div
                                  className={
                                    'kiosk-builder-content-wrapper-side-bar-list-content-item__left-cell'
                                  }
                                >
                                  {item.idName || 'n/a'}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    );
  };

  renderContent = () => {
    return this.props.policy.length
      ? this.renderSettings(
          {
            ...getInitialPolicy(kioskPolicyTypes.KIOSK),
            settings: { ...initialSettings },
            ...this.props.policy[0],
          },
          0
        )
      : this.renderSettings(
          {
            ...getInitialPolicy(kioskPolicyTypes.KIOSK),
            settings: { ...initialSettings },
          },
          0
        );
  };

  render() {
    return <div className={'dialog-base'}>{this.renderContent()}</div>;
  }
}

export default KioskBuilder;

KioskBuilder.propTypes = {
  onChange: PropTypes.func,
  onUndo: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
