import PopupActionTypes from './popup.actionTypes';

const initialData = {
  isVisible: false,
  id: '',
  title: '',
  text: '',
  textList: [],
  confirmButtonText: '',
  cancelButtonText: '',
  onConfirm: null,
  onCancel: null,
  secondaryConfirmationParams: {},
};

const DEFAULT_PRIORITY = 0;
const DEFAULT_POSITION = 0;

export default function (
  state = {
    popupList: [],
  },
  { type = '', payload = {} }
) {
  switch (type) {
    case PopupActionTypes.SHOW_POPUP: {
      const newPopupList = [...state.popupList];
      const dataPriority = payload.priority || DEFAULT_PRIORITY;

      const newElem = {
        ...initialData,
        ...payload,
        priority: dataPriority,
        isVisible: true,
      };
      const existingPopupIndex = newPopupList.findIndex(
        (popup) => popup.id === payload.id
      );
      if (existingPopupIndex >= 0) {
        newPopupList[existingPopupIndex] = newElem;
      } else {
        let counter = 0;
        let position = DEFAULT_POSITION;

        const index = state.popupList.findIndex((v) => {
          counter++;
          return dataPriority >= v.priority;
        });

        if (index !== -1) {
          position = index;
        } else if (counter === state.popupList.length) {
          position = counter;
        }
        newPopupList.splice(position, 0, newElem);
      }

      return {
        ...state,
        popupList: newPopupList,
      };
    }

    case PopupActionTypes.CLOSE_POPUP:
      return {
        ...state,
        popupList: payload.id
          ? state.popupList.filter(({ id: popupId }) => popupId !== payload.id)
          : state.popupList.slice(1),
      };

    default:
      return state;
  }
}
