import {
  CERTIFICATES_DELETE_FAILED,
  CERTIFICATES_DELETE_RESET,
  CERTIFICATES_DELETE_STARTED,
  CERTIFICATES_DELETE_SUCCESS,
  CERTIFICATES_LOAD_FAILED,
  CERTIFICATES_LOAD_STARTED,
  CERTIFICATES_LOAD_SUCCESS,
} from './certificates.actionTypes';
import { NetworkStatus } from '../../common/constants';

export default function certificates(
  state = {
    loading: NetworkStatus.NONE,
    error: null,
    data: null,

    certificatesDeletingStatus: NetworkStatus.NONE,
    certificatesDeletingError: undefined,
  },
  action
) {
  switch (action.type) {
    case CERTIFICATES_LOAD_STARTED:
      return Object.assign({}, state, {
        data: null,
        loading: NetworkStatus.STARTED,
        error: null,
      });
    case CERTIFICATES_LOAD_FAILED:
      return Object.assign({}, state, {
        data: null,
        loading: NetworkStatus.ERROR,
        error: action.payload,
      });
    case CERTIFICATES_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        loading: NetworkStatus.DONE,
        error: null,
      });
    case CERTIFICATES_DELETE_STARTED:
      return {
        ...state,
        certificatesDeletingStatus: NetworkStatus.STARTED,
        certificatesDeletingError: undefined,
      };
    case CERTIFICATES_DELETE_FAILED:
      return {
        ...state,
        certificatesDeletingStatus: NetworkStatus.ERROR,
        certificatesDeletingError: action.payload,
      };
    case CERTIFICATES_DELETE_SUCCESS:
      return {
        ...state,
        certificatesDeletingStatus: NetworkStatus.DONE,
        certificatesDeletingError: undefined,
      };
    case CERTIFICATES_DELETE_RESET:
      return {
        ...state,
        certificatesDeletingStatus: NetworkStatus.NONE,
        certificatesDeletingError: undefined,
      };
    default:
      return state;
  }
}
