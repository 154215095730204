import {
  ApplicationsRoutes,
  DeviceRoutes,
  DevicesRoutes,
  FleetRoute,
  FleetV1Route,
  HomeRoutes,
  ProfilesRoutes,
  RootRoutes,
  UsersRoutes,
} from '../../utils/routes';
import { UUID_VALIDATOR_EXPRESSION } from '../../constants/data';
import { ADMINS_GROUP_ID, ALL_USERS_GROUP_ID } from '../../constants/users';
import { createGroupRouteConstants } from '../../features/group/group.constants';

const routesWithChildRoutes = {
  [`${RootRoutes.HOME}${HomeRoutes.MAP}`]: '',
  [DeviceRoutes]: 'deviceId',
  [FleetV1Route]: '',
  [FleetRoute]: '',
  [`${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}`]: 'enrollmentId',
  [`${RootRoutes.PROFILES}${ProfilesRoutes.ALL}`]: 'profileId',
  [`${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}`]: 'profileId',
  [`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}`]: 'profileId',
  [`${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}`]: 'applicationId',

  [`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${ALL_USERS_GROUP_ID}`]: '',
  [`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${ADMINS_GROUP_ID}`]: '',

  [`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/${createGroupRouteConstants.USER_MANAGEMENT}`]: '',
  [`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/${createGroupRouteConstants.PERMISSIONS}`]: '',
  [`${RootRoutes.USERS}${UsersRoutes.GROUPS}`]: 'groupId',

  [`${RootRoutes.USERS}${UsersRoutes.LOGS}/${ALL_USERS_GROUP_ID}`]: '',
  [`${RootRoutes.USERS}${UsersRoutes.LOGS}/${ADMINS_GROUP_ID}`]: '',
  [`${RootRoutes.USERS}${UsersRoutes.LOGS}`]: 'groupId',
};

export const sanitizeUrlForAnalytics = (url: string) => {
  let path = url;
  const pathWithParameter = Object.keys(routesWithChildRoutes).find((route) =>
    path.includes?.(route)
  );
  if (pathWithParameter && pathWithParameter !== path) {
    path = path.match(UUID_VALIDATOR_EXPRESSION)
      ? path.replace(
          UUID_VALIDATOR_EXPRESSION,
          routesWithChildRoutes[pathWithParameter]
        )
      : `${pathWithParameter}/${routesWithChildRoutes[pathWithParameter]}`;
  }
  return path;
};
