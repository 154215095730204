import { NetworkStatus } from '../../common/constants';
import {
  FLEET_CREATE_FAILED,
  FLEET_CREATE_RESET,
  FLEET_CREATE_STARTED,
  FLEET_CREATE_SUCCESS,
} from '../fleets/fleets.actionTypes';

export default function fleet(
  state = {
    createStatus: NetworkStatus.NONE,
    createError: undefined,
  },
  action
) {
  switch (action.type) {
    case FLEET_CREATE_STARTED:
      return Object.assign({}, state, {
        createStatus: NetworkStatus.STARTED,
        createError: undefined,
      });
    case FLEET_CREATE_FAILED:
      return Object.assign({}, state, {
        createStatus: NetworkStatus.ERROR,
        createError: action.payload,
      });
    case FLEET_CREATE_SUCCESS:
      return Object.assign({}, state, {
        createStatus: NetworkStatus.DONE,
        createError: undefined,
      });
    case FLEET_CREATE_RESET:
      return Object.assign({}, state, {
        createStatus: NetworkStatus.NONE,
        createError: undefined,
      });

    default:
      return state;
  }
}
