import client from '../../utils/client';
import services from '../../utils/services';
import { deserializeDevice } from '../device/device.serializer';
import {
  devicesLoaded,
  devicesRemoveDone,
  devicesRemoveFailed,
  devicesRemoveStarted,
  devicesSendRemoteCommandFailed,
  devicesSendRemoteCommandStarted,
  devicesSendRemoteCommandSuccess,
  loadDevicesFailed,
  loadDevicesStarted,
} from './devices.actions';
import { fleetLoadingDone } from '../fleets/fleets.actions';
import { handleRequestResponse } from '../../common/utilities/errorHandling';
import { LockDeviceConfigurationStatuses } from './devices.constants';
import PopupActions from '../popup/popup.actions';
import { I18n } from 'react-redux-i18n';

export function sendDisableDataCollectorAnalytic(id) {
  return async (dispatch) => {
    try {
      dispatch(devicesSendRemoteCommandStarted());
      let response = await client.post(
        `${services.devices}${id}/${services.analyticsDataCollector}`,
        { command_type: 'stop_sending' }
      );
      if (response.status === 200) {
        dispatch(devicesSendRemoteCommandSuccess());
      } else {
        // TODO add error handling: throw( new Error('Error on sending remote command to selected devices') );
        dispatch(devicesSendRemoteCommandFailed());
      }
    } catch (error) {
      dispatch(
        devicesSendRemoteCommandFailed(
          new Error(
            I18n.t('devices.sendingRemoteCommandToSelectedDevicesErrorMessage')
          )
        )
      );
    }
  };
}

export function sendEnableDataCollectorAnalytic(id) {
  return async (dispatch) => {
    try {
      dispatch(devicesSendRemoteCommandStarted());
      let response = await client.post(
        `${services.devices}${id}/${services.analyticsDataCollector}`,
        { command_type: 'start_sending' }
      );
      if (response.status === 200) {
        dispatch(devicesSendRemoteCommandSuccess());
      } else {
        // TODO add error handling: throw( new Error('Error on sending remote command to selected devices') );
        dispatch(devicesSendRemoteCommandFailed());
      }
    } catch (error) {
      dispatch(
        devicesSendRemoteCommandFailed(
          new Error(
            I18n.t('devices.sendingRemoteCommandToSelectedDevicesErrorMessage')
          )
        )
      );
    }
  };
}

export function loadDevices() {
  return async (dispatch) => {
    try {
      dispatch(loadDevicesStarted());
      let { status, data, text = '' } = await client.get(services.getDevices);
      if (status === 200 && data) {
        dispatch(devicesLoaded(data.map(deserializeDevice)));
      } else {
        dispatch(loadDevicesFailed(text));
      }
    } catch (error) {
      dispatch(
        loadDevicesFailed(I18n.t('devices.errorOnFetchingAllCompanyDevices'))
      );
    }
  };
}

export function sendRemoteCommand({
  companyId,
  command,
  devicesIds: devices,
  fleetId,
  commandDetails = {},
  onSuccess,
}) {
  return async (dispatch) => {
    try {
      dispatch(devicesSendRemoteCommandStarted());
      PopupActions.showLoader();
      let response = await client.post(services.sendRemoteCommand, {
        company_id: companyId,
        devices,
        command_type: command,
        command_details: commandDetails,
      });
      PopupActions.hideLoader();
      if (response.status === 204) {
        if (fleetId) {
          let res = await client.get(
            services.getAllDevicesByFleetId + fleetId,
            {}
          );
          if (res.status === 200 && res.data) {
            let currentData = res.data.map(deserializeDevice); // TODO: add checks, if websocket connected
            dispatch(fleetLoadingDone({ devices: currentData, id: fleetId }));
          } else {
            // TODO add error handling: throw( new Error('Error on fetching selected fleet') );
          }
        } else {
          // do we really need a reload here???
          // if so, what do we reload? the device full list, fleet list or just device?
          // dispatch(loadDevices());
        }
        dispatch(devicesSendRemoteCommandSuccess());
        onSuccess?.();
      } else {
        // TODO add error handling: throw( new Error('Error on sending remote command to selected devices') );
        dispatch(devicesSendRemoteCommandFailed());
      }
    } catch (error) {
      dispatch(
        devicesSendRemoteCommandFailed(
          new Error(
            I18n.t('devices.sendingRemoteCommandToSelectedDevicesErrorMessage')
          )
        )
      );
    }
  };
}

export function removeDevices(devices, fleetId) {
  return async (dispatch) => {
    try {
      dispatch(devicesRemoveStarted());
      let response = await client.put(services.removeDevices, { devices });
      if (response.status === 204) {
        if (fleetId) {
          let res = await client.get(
            services.getAllDevicesByFleetId + fleetId,
            {}
          );
          if (res.status === 200 && res.data) {
            let currentData = res.data.map(deserializeDevice); // TODO: add checks, if websocket connected
            dispatch(fleetLoadingDone({ devices: currentData, id: fleetId }));
          } else {
            // TODO add error handling: throw( new Error('Error on fetching selected fleet') );
          }
        } else {
        }
        dispatch(devicesRemoveDone());
      } else {
        dispatch(
          devicesRemoveFailed(I18n.t('devices.errorOnDeletingSelectedDevices'))
        );
      }
    } catch (error) {
      dispatch(
        devicesRemoveFailed(I18n.t('devices.errorOnDeletingSelectedDevices'))
      );
    }
  };
}

export function changeDeviceStatus({ devicesIds, status, onSuccess }) {
  return async (dispatch) => {
    try {
      PopupActions.showLoader();
      const response = await client.post(services.changeDeviceStatus, {
        devices: devicesIds,
        locked: LockDeviceConfigurationStatuses.LOCKED === status,
      });
      PopupActions.hideLoader();
      handleRequestResponse({
        response,
        successStatusCode: 204,
        onSuccess: () => {
          onSuccess?.();
        },
        successTextCode:
          status === LockDeviceConfigurationStatuses.ACTIVE
            ? 'fleets.unlockDeviceConfigurationSuccessMessage'
            : 'fleets.lockDeviceConfigurationSuccessMessage',
        defaultErrorMessageTextCode:
          status === LockDeviceConfigurationStatuses.ACTIVE
            ? 'fleets.unlockDeviceConfigurationErrorMessage'
            : 'fleets.lockDeviceConfigurationErrorMessage',
      });
    } catch (e) {}
  };
}
