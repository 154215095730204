import client from '../../utils/client';
import services from '../../utils/services';
import {
  loadAccountInfoDone,
  loadAccountInfoFailed,
  loadAccountInfoStarted,
  loadGlobalPermissionsForUserFailed,
  loadGlobalPermissionsForUserStarted,
  loadGlobalPermissionsForUserSuccess,
} from './account.actions';
import { deserializeGlobalPermissionsForUser } from './account.serializer';
import PopupActions from '../popup/popup.actions';
import { I18n } from 'react-redux-i18n';

export function loadAccountInfo() {
  return async (dispatch) => {
    try {
      dispatch(loadAccountInfoStarted());
      let { status, data } = await client.get(services.getCustomerInfo);
      if (status === 200 && data) {
        const result = {
          username: data.username,
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
        };
        dispatch(loadAccountInfoDone(result));
      } else {
        dispatch(
          loadAccountInfoFailed(I18n.t('settings.accountSettingsLoadFailure'))
        );
      }
    } catch (error) {
      dispatch(
        loadAccountInfoFailed(I18n.t('settings.accountSettingsLoadFailure'))
      );
    }
  };
}

export function loadGlobalPermissionsForUser() {
  return async (dispatch) => {
    try {
      dispatch(loadGlobalPermissionsForUserStarted());
      const { status, data = {}, text = '' } = await client.get(
        services.getGlobalPermissionsForUser
      );
      if (status === 200 && data) {
        const result = deserializeGlobalPermissionsForUser(data);
        dispatch(loadGlobalPermissionsForUserSuccess(result));
      } else {
        dispatch(loadGlobalPermissionsForUserFailed(text));
      }
    } catch (error) {
      dispatch(
        loadGlobalPermissionsForUserFailed(
          I18n.t('settings.globalPermissionsLoadFailureMessage')
        )
      );
    }
  };
}

export const linkSSOAccount = (data, url) => {
  return async (dispatch) => {
    try {
      let response = await client.post(url, data);
      if (response.status === 200) {
        PopupActions.showAlert({
          text: I18n.t('settings.ssoAccountLinkSuccessMessage'),
        });
      } else {
        const errorText =
          response.data?.detail ||
          response.statusText ||
          I18n.t('common.somethingWentWrong');
        throw new Error(errorText);
      }
    } catch (e) {
      PopupActions.showAlert({
        text: e.message,
      });
    }
  };
};
