import {
  USER_LOAD_FAILED,
  USER_LOAD_RESET,
  USER_LOAD_STARTED,
  USER_LOAD_SUCCESS,
  USER_UPDATE_FAILED,
  USER_UPDATE_RESET,
  USER_UPDATE_STARTED,
  USER_UPDATE_SUCCESS,
} from './user.actionTypes';

export function loadUserStart() {
  return {
    type: USER_LOAD_STARTED,
  };
}

export function loadUserFailed(payload) {
  return {
    type: USER_LOAD_FAILED,
    payload,
  };
}

export function loadUserSuccess(payload) {
  return {
    type: USER_LOAD_SUCCESS,
    payload,
  };
}

export function loadUserReset() {
  return {
    type: USER_LOAD_RESET,
  };
}

export function updateUserStart() {
  return {
    type: USER_UPDATE_STARTED,
  };
}

export function updateUserFailed(payload) {
  return {
    type: USER_UPDATE_FAILED,
    payload,
  };
}

export function updateUserSuccess(payload) {
  return {
    type: USER_UPDATE_SUCCESS,
    payload,
  };
}

export function updateUserReset() {
  return {
    type: USER_UPDATE_RESET,
  };
}
