export const DataConstants = {
  FLEET_PROFILES_V1: 'FLEET_PROFILES_V1',
  FLEET_PROFILES_V2: 'FLEET_PROFILES_V2',
  PROFILES_V2: 'PROFILES_V2',
  PROFILES_V1: 'PROFILES_V1',
  FLEETS_V2: 'FLEETS_V2',
  ALL_COMPANY_FLEETS_V2: 'ALL_COMPANY_FLEETS_V2',
  FLEETS_V2_CONFIG: 'FLEETS_V2_CONFIG',
  DEVICE_APPLICATIONS: 'DEVICE_APPLICATIONS',
  DEVICE_LOCATION_LOGS: 'DEVICE_LOCATION_LOGS',
  DEVICE_ACTIVITY_LOGS: 'DEVICE_ACTIVITY_LOGS',
  USER_GROUPS: 'USER_GROUPS',
};

export const UUID_VALIDATOR_EXPRESSION = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/gi;
