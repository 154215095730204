import React from 'react';
import omit from 'lodash/omit';
import { ReactComponent as Pencil } from '../../assets/images/pencil.svg';
import SubmissionInput from '../SubmissionInput/SubmissionInput.component';

const DialogHeaderTitleSection = ({
  isEditingMode = false,
  titleHint,
  titleText,
  isEditingModeToggleIconAvailable,
  onEditingModeToggleIconClick,
  statusComponent = null,
  ...otherProps
}) => {
  const submissionInputProps = omit(otherProps, [
    // a list of props NOT to forward to SubmissionInput
    titleText,
    titleHint,
    isEditingMode,
    isEditingModeToggleIconAvailable,
    onEditingModeToggleIconClick,
  ]);
  return (
    <section className="dialog-header-title-section">
      <section className="dialog-header-title-section__wrapper">
        <h6 className="dialog-header-title-section__title-hint">{titleHint}</h6>
        {isEditingMode ? (
          <SubmissionInput {...submissionInputProps} />
        ) : (
          <div className="dialog-header-title-section__caption-text-container">
            <span>{titleText}</span>
            {isEditingModeToggleIconAvailable ? (
              <Pencil
                onClick={onEditingModeToggleIconClick}
                className="dialog-header-title-section__edit-icon"
              />
            ) : null}
          </div>
        )}
      </section>
      {statusComponent}
    </section>
  );
};

export default DialogHeaderTitleSection;
