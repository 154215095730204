import { I18n } from 'react-redux-i18n';
import PopupActions from '../../features/popup/popup.actions';
import { NetworkStatus } from '../constants';
import { PopupTextTypeConstants } from '../../features/popup/popup.constants';

const POPUP_ID = 'STATUS_CHANGE_POPUP';

export const showStatusChangePopup = ({
  status,
  title,
  successText,
  errorText,
  onClose,
  alertOptions = {},
}) => {
  if (!status) {
    return;
  }

  let text = I18n.t('common.inProgress');
  let textType = PopupTextTypeConstants.INFO;

  if (status === NetworkStatus.DONE) {
    text = successText ?? I18n.t('common.successful');
    textType = PopupTextTypeConstants.SUCCESS;
  }

  if (status === NetworkStatus.ERROR) {
    text = errorText ?? I18n.t('common.error');
    textType = PopupTextTypeConstants.DANGER;
  }

  PopupActions.closePopup(POPUP_ID).then(() => {
    PopupActions.showAlert({
      id: POPUP_ID,
      title,
      text,
      textType,
      onClose,
      ...alertOptions,
    });
  });
};
