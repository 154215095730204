export const notificationTypes = {
  GEOFENCE: 'geofence',
  SECURITY_COMPLIANCE: 'security_compliance',
  LOW_BATTERY: 'low_battery',
};

export const notificationUpdateTypes = {
  REMOVE: 'REMOVE',
  READ: 'READ',
};
