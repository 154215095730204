import { history } from '../utils/history';
import {
  setFilters,
  setFiltersStrictness,
  setFiltersUpdating,
} from './filters.actions';
import {
  filterConditionOptions,
  filterWithConditionRegExp,
} from './filters.constants';
import { getState } from '../store/store';

export function initializeFilters(searchQuery = {}, columns) {
  return async (dispatch) => {
    let filters = [];
    let isCriteriaStrict = 'notStrict';
    const existingFilters = getState()?.filters?.filters ?? [];
    const params = new URLSearchParams(searchQuery);
    let entries = [...params.entries()];
    if (existingFilters.length) {
      filters = existingFilters;
    } else if (entries && entries.length) {
      filters = entries.reduce((result, entry) => {
        const match = entry[0].match(filterWithConditionRegExp);
        if (match && match.length) {
          const foundColumn = columns.find(
            (column) => column.key.toLowerCase() === match[1].toLowerCase()
          );
          if (foundColumn) {
            const foundCondition = filterConditionOptions.find(
              (condition) =>
                condition.key.toLowerCase() === match[2].toLowerCase()
            );
            if (foundCondition) {
              const filterValueType = params.get('filterValueType');
              return [
                ...result,
                {
                  attribute: foundColumn.key,
                  customParams: foundColumn.customParams,
                  condition: foundCondition.key,
                  value: entry[1] !== undefined ? entry[1] : '',
                  ...(filterValueType ? { filterValueType } : {}),
                },
              ];
            }
          }
        }
        return result;
      }, []);

      let strictness = params.get('strict');
      if (strictness !== null && strictness === '1') {
        isCriteriaStrict = 'strict';
      }
    }
    dispatch(setFilters(filters));
    dispatch(setFiltersStrictness(isCriteriaStrict));
  };
}

export function updateFilters({
  shouldUpdate,
  filters,
  isCriteriaStrict,
  pathname,
}) {
  return async (dispatch) => {
    dispatch(setFilters(filters));
    if (shouldUpdate) {
      const strict = isCriteriaStrict === 'strict' ? '1' : '0';
      const search = new URLSearchParams(
        filters.reduce(reduceFilters, { strict })
      ).toString();
      history.push({
        pathname,
        search,
      });
    } else {
      // do not update the URL when the view was changed in the 'popstate' handler
      dispatch(setFiltersUpdating(true));
    }
  };
}

export function reduceFilters(
  result,
  { attribute, condition, value, customParams = {}, filterValueType }
) {
  if (attribute && condition != null && condition !== '') {
    return {
      ...result,
      ...customParams,
      ...(filterValueType ? { filterValueType } : {}),
      [`${attribute}[${condition}]`]: value,
    };
  }
  return result;
}
