import client from '../../utils/client';
import {
  mapViewDevicesLoaded,
  loadMapViewDevicesStarted,
  loadMapViewDevicesFailed,
} from './mapView.actions';
import services from '../../utils/services';
import { I18n } from 'react-redux-i18n';

export function loadMapViewDevices(start, end) {
  return async (dispatch) => {
    try {
      dispatch(loadMapViewDevicesStarted());
      const params = {
        start_time: start,
        end_time: end,
      };
      let {
        status,
        data = [],
        text = '',
      } = await client.get(`/dynamodb/all_devices`, { params });
      if (status === 200 && data.data) {
        const result = data.data.map(deserializeDeviceChanges);
        dispatch(mapViewDevicesLoaded(result));
      } else {
        dispatch(loadMapViewDevicesFailed(text));
      }
    } catch (error) {
      dispatch(
        loadMapViewDevicesFailed(
          I18n.t('devices.errorOnFetchingAllCompanyDevices')
        )
      );
    }
  };
}

export const deserializeDeviceChanges = (data) => {
  return {
    id: data.id_device || undefined,
    coordinates:
      data.location &&
      data.location.latitude != null &&
      data.location.longitude != null
        ? {
            latitude: data.location.latitude,
            longitude: data.location.longitude,
          }
        : undefined,
    time: data.location && data.location.time ? data.location.time : undefined,
    history:
      data.locationData &&
      data.locationData.gpsLocation &&
      data.locationData.gpsLocation.history &&
      data.locationData.gpsLocation.history.length
        ? data.locationData.gpsLocation.history.map(deserializeDeviceLocation)
        : [],
  };
};

export const deserializeDeviceLocation = (data) => {
  return {
    latitude:
      data.latitude != null && data.longitude != null
        ? data.latitude
        : undefined,
    longitude:
      data.latitude != null && data.longitude != null
        ? data.longitude
        : undefined,
    time: data.time || undefined,
  };
};

export function sendTrackingPing(id) {
  return async (dispatch) => {
    try {
      let response = await client.post(`${services.companies}${id}/tracking`);
      if (response.status === 200) {
      }
    } catch (error) {}
  };
}
