import React from 'react';
import Icon from '../../Icon';
import { ReactComponent as CheckCircle } from '../../../assets/images/check-circle.svg';
import { ReactComponent as CrestCircle } from '../../../assets/images/crest-circle.svg';

import './ReadOnlyBooleanListItem.styles.scss';

const ReadOnlyBooleanListItem = ({
  title,
  value = false,
  informationIconText,
}) => {
  const rootClassName = 'read-only-boolean-list-item';
  return (
    <li className={rootClassName}>
      <div className={`${rootClassName}__title-area`}>
        {title}
        &nbsp;
        {informationIconText ? <Icon>{informationIconText}</Icon> : null}
      </div>
      <div>
        {value ? (
          <CheckCircle className={`${rootClassName}__icon`} />
        ) : (
          <CrestCircle className={`${rootClassName}__icon`} />
        )}
      </div>
    </li>
  );
};

export default ReadOnlyBooleanListItem;
