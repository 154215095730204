import { AttributeType } from '../../common/constants';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import { filterConditions } from '../../constants/filter';

export const profilesColumns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'profiles.fields.name',
    ref: `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}`,
    refId: 'id',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'updatedAt',
    type: AttributeType.DATE,
    titleCode: 'profiles.fields.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
];

export const profilesV1Columns = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'profiles.fields.name',
    ref: `${RootRoutes.PROFILES}${ProfilesRoutes.ALL}`,
    refId: 'id',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'profiles.fields.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
];
