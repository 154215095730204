import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Glyphicon } from 'react-bootstrap';
import UserHelpers from '../../common/utilities/user';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import {
  alertsNextPageStatusSelector,
  alertsSelector,
  alertsStatusSelector,
} from './alerts.selectors';
import {
  filtersIsCriteriaStrictSelector,
  filtersSelector,
} from '../../redux/filters.selectors';
import { history } from '../../utils/history';
import { CreateAlertRoute } from '../createAlert/createAlert.constants';
import { deleteAlerts, loadAlerts } from './alerts.thunk';
import { NetworkStatus } from '../../common/constants';
import RouteHelpers from '../../common/utilities/routeHelpers';
import ToolsPanel from '../../components/ToolsPanel';
import Tool from '../../components/ToolBar/Tool';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import PopupActions from '../popup/popup.actions';
import Alert from '../alert/alert.component';
import DataTable from '../../components/DataTable/DataTable.component';
import { Pages } from '../../constants/pages';
import { alertsColumns } from './alertsTableColumns';

class Alerts extends Component {
  state = {
    selected: new Set(),
  };

  tableRef = React.createRef();

  componentDidMount() {
    const { alertsStatus, loadAlerts, offset } = this.props;
    if (alertsStatus !== NetworkStatus.STARTED) {
      loadAlerts({ offset, isInitialLoad: true });
    }
  }

  loadAlerts = () => {
    const { offset, hasMore, loadAlerts } = this.props;
    if (hasMore) {
      loadAlerts({ offset, isInitialLoad: false });
    }
  };

  resetSelection = () => this.tableRef.current?.toggleAllRowsSelected?.(false);

  handleCreateAlert = () => history.push(CreateAlertRoute);

  renderCardHeader = () => {
    const { hasAdminPrivileges } = this.props;
    return (
      <>
        {hasAdminPrivileges ? (
          <ButtonWithIcon
            onClick={this.handleCreateAlert}
            rightIcon={<Glyphicon glyph="plus" />}
            text={<Translate value="alerts.createAlert" />}
          />
        ) : null}
      </>
    );
  };

  renderTable = () => {
    const { alertsStatus, alerts } = this.props;

    return (
      <DataTable
        allColumns={alertsColumns}
        data={alerts}
        loadingStatus={alertsStatus}
        infiniteScrollProps={{
          loadMore: this.loadAlerts,
          hasMore: this.props.hasMore,
          count: this.props.count,
          nextPageLoadingStatus: this.props.alertsNextPageStatus,
        }}
        onSelect={this.select}
        tableRef={this.tableRef}
        pageId={Pages.ALERTS}
        rightCustomHeaderAction={this.renderCardHeader()}
        selectable
        sortable
        useFilter
        useFlex
      />
    );
  };

  renderToolPanel = () => {
    const { selected } = this.state;

    return selected.size && !this.props.match.params.childRoute ? (
      <ToolsPanel
        selectedItems={this.state.selected}
        title={I18n.t('alert.alertsSelected')}
      >
        <Tool
          title={I18n.t('alert.removeAlerts')}
          onClick={this.onAlertsDelete}
        >
          <TrashEmpty className={'icon'} />
        </Tool>
      </ToolsPanel>
    ) : null;
  };

  select = (selectedIds) => {
    const selected = new Set(selectedIds);
    this.setState({ selected });
  };

  onAlertsDelete = () =>
    PopupActions.showConfirm({
      onConfirm: () => {
        this.props.deleteAlerts([...this.state.selected.values()]);
        this.resetSelection();
      },
      text: I18n.t('alerts.alertsDeletionConfirmation'),
    });

  render() {
    const contentClassName = this.state.selected.size
      ? 'app__content app__content--with-toolbar'
      : 'app__content';

    return (
      <Fragment>
        <div className={contentClassName}>
          <div className="page-content">
            <div className="card">{this.renderTable()}</div>
          </div>
        </div>
        {this.renderToolPanel()}
        {this.renderAlert()}
      </Fragment>
    );
  }

  renderAlert = () => {
    const childRoute = RouteHelpers.getUrlParameter(this.props, 'childRoute');
    if (childRoute) {
      const id = RouteHelpers.getQueryParamFromLocation(
        this.props.location,
        'id'
      );
      return <Alert id={id} />;
    }
  };
}

const mapStateToProps = (state) => {
  const { data = [], nextUrl, count } = alertsSelector(state);
  const { offset } = RouteHelpers.getURLQueryParams(nextUrl);
  return {
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    alerts: data,
    alertsStatus: alertsStatusSelector(state),
    alertsNextPageStatus: alertsNextPageStatusSelector(state),
    isCriteriaStrict: filtersIsCriteriaStrictSelector(state),
    filters: filtersSelector(state),
    hasMore: Boolean(nextUrl),
    offset,
    count,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadAlerts: (params) => dispatch(loadAlerts(params)),
  deleteAlerts: (alerts) => dispatch(deleteAlerts(alerts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
