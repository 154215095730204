import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { NetworkStatus } from '../../common/constants';
import { connect } from 'react-redux';
import AttributiveTable from '../../components/attributiveTable/AttributiveTable';
import { history } from '../../utils/history';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import Profile from '../profile/profile.component';
import ToolsPanel from '../../components/ToolsPanel';
import { Glyphicon } from 'react-bootstrap';
import FleetsSelection from '../devices/components/Devices/FleetsSelection';
import { assignProfilesToFleetsReset } from './profiles.actions';
import { confirmAlert } from 'react-confirm-alert';
import { companyIdSelector } from '../login/login.selectors';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import { ReactComponent as MobileTextMinusCircle } from '../../assets/images/mobile_text_minus_circle.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/images/duplicate.svg';
import { ReactComponent as MigrationIcon } from '../../assets/images/transfer.svg';
import Tool from '../../components/ToolBar/Tool';
import { profilesV1Columns } from './profiles.constants';
import {
  assignProfilesToFleets,
  cloneProfiles,
  deleteProfiles,
  loadProfiles,
  migrateProfileToNewVersion,
  unassignProfiles,
} from './profiles.thunk';
import { Button } from '../../components/button.component';
import Filter from '../../components/Filter';
import UserHelpers from '../../common/utilities/user';
import PopupActions from '../popup/popup.actions';
import { Translate } from 'react-redux-i18n';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { profilesSelector } from './profiles.selectors';
import { fleetsV2Selector } from '../fleets/fleetsV2.selectors';

class ProfilesComponent extends React.Component {
  state = {
    showToolPanel: true,
    selected: new Map(),
  };

  componentDidMount() {
    // TODO: add preload, network status
    this.loadProfiles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.assignToFleetsStatus === NetworkStatus.STARTED &&
      (this.props.assignToFleetsStatus === NetworkStatus.DONE ||
        this.props.assignToFleetsStatus === NetworkStatus.ERROR)
    ) {
      this.onChangeStatus();
    }
  }

  loadProfiles = () => this.props.loadProfiles();

  searchableColumns = profilesV1Columns.filter((column) => column.searchable);

  render() {
    const { hasAdminPrivileges } = this.props;
    const showToolbar = this.state.selected.size && this.state.showToolPanel;
    const contentClassName = showToolbar
      ? 'app__content app__content--with-toolbar'
      : 'app__content';

    return (
      <Fragment>
        <div className={contentClassName}>
          <div className={'page-content'}>
            <div className={'card'}>
              <div className={'single-card-header'}>
                <div />
                <div />
                <div className="single-card__right-group">
                  <Filter columns={this.searchableColumns || []} />
                  <div className={'card-header-actions-section'}>
                    {hasAdminPrivileges ? (
                      <ButtonWithIcon
                        onClick={this.handleCreateProfile}
                        text={<Translate value="profiles.createProfile" />}
                        rightIcon={<Glyphicon glyph="plus" />}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={'single-card-content'}>
                <AttributiveTable
                  columns={profilesV1Columns}
                  items={this.props.data}
                  loadingStatus={this.props.status}
                  selectable
                  sortable
                  filters={this.props.filters}
                  isFilterCriteriaStrict={this.props.isCriteriaStrict}
                  selected={this.state.selected}
                  onSelect={this.select}
                  onSelectAll={this.toggleSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderToolbar(showToolbar)}
        {this.renderAssignToFleetDialog()}
        <Route
          exact
          path={`${RootRoutes.PROFILES}${ProfilesRoutes.ALL}/:childRoute/:subRoute?`}
          component={Profile}
        />
      </Fragment>
    );
  }

  renderToolbar = (showToolbar) => {
    const { hasAdminPrivileges } = this.props;
    return showToolbar ? (
      <ToolsPanel
        selectedItems={this.state.selected}
        title={I18n.t('profiles.toolsPanelTitle')}
      >
        {/*<Tool*/}
        {/*    title={"Assign Profiles to Fleet"}*/}
        {/*    onClick={this.toggleToolPanel}*/}
        {/*>*/}
        {/*    <MobileTextPlusCircle className={'icon'}/>*/}
        {/*</Tool>*/}
        <Tool
          title={I18n.t('profiles.unassignProfiles')}
          onClick={this.unassignProfiles}
          hidden={!hasAdminPrivileges}
        >
          <MobileTextMinusCircle className={'icon'} />
        </Tool>
        <Tool
          title={I18n.t('profiles.cloneProfiles')}
          onClick={this.cloneProfiles}
          hidden={!hasAdminPrivileges}
        >
          <DuplicateIcon className={'icon'} />
        </Tool>
        <Tool
          title={I18n.t('profiles.removeProfiles')}
          onClick={this.onRemove}
          hidden={!hasAdminPrivileges}
        >
          <TrashEmpty className={'icon'} />
        </Tool>
        <Tool
          title={I18n.t('profiles.migrateToVersionTwo')}
          onClick={this.migrateToNewVersion}
          hidden={!hasAdminPrivileges}
        >
          <MigrationIcon className={'icon'} />
        </Tool>
      </ToolsPanel>
    ) : null;
  };

  renderAssignToFleetDialog = () => {
    return this.state.selected.size && !this.state.showToolPanel ? (
      <FleetsSelection
        selected={this.state.selected}
        onBack={this.toggleToolPanel}
        onAssign={this.assignProfilesToFleet}
        fleets={this.props.fleets}
      />
    ) : null;
  };

  onChangeStatus = () => {
    let color =
      this.props.assignToFleetsStatus === NetworkStatus.DONE
        ? 'green'
        : this.props.assignToFleetsStatus === NetworkStatus.ERROR
        ? 'red'
        : '#222';
    let text =
      this.props.assignToFleetsStatus === NetworkStatus.DONE
        ? I18n.t('common.successful')
        : this.props.assignToFleetsStatus === NetworkStatus.ERROR
        ? I18n.t('common.error')
        : '#222';
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="create-enrollment-alert">
            <p>{`${I18n.t('profiles.profileAssignmentToFleetsTitle')}:`}</p>
            <p style={{ color }}>{text}</p>
            <Button onClick={onClose}>{I18n.t('common.ok')}</Button>
          </div>
        );
      },
    });
    this.props.assignProfilesToFleetsReset();
    this.setState({ selected: new Map() });
  };

  select = ({ target: { checked } }, profile) => {
    const selected = new Map(this.state.selected);
    if (!checked) {
      selected.delete(profile.id);
    } else {
      selected.set(profile.id, profile);
    }
    this.setState({ selected });
  };

  toggleSelectAll = ({ target: { checked } }) => {
    if (this.props.data && this.props.data.length) {
      const selected = checked
        ? new Map(this.props.data.map((profile) => [profile.id, profile]))
        : new Map();
      this.setState({ selected });
    }
  };

  resetSelectedDevices = () =>
    this.setState(() => ({ selected: new Map() }), this.loadProfiles);

  onRemove = () =>
    PopupActions.showConfirm({
      onConfirm: () => {
        this.props.deleteProfiles(
          [...this.state.selected.keys()],
          this.props.companyId
        );
        this.resetSelectedDevices();
      },
      text: I18n.t('profiles.removeProfilesConfirmationText'),
    });

  migrateToNewVersion = () =>
    PopupActions.showConfirm({
      onConfirm: () => {
        this.props.migrateProfileToNewVersion({
          profileIds: [...this.state.selected.keys()],
        });
        this.resetSelectedDevices();
      },
      text: I18n.t('profiles.migrateProfilesConfirmationText'),
    });

  unassignProfiles = () =>
    PopupActions.showConfirm({
      onConfirm: () => {
        this.props.unassignProfiles(
          [...this.state.selected.keys()],
          this.props.companyId
        );
        this.resetSelectedDevices();
      },
      text: I18n.t('profiles.unassignProfilesConfirmationText'),
    });

  cloneProfiles = () =>
    PopupActions.showConfirm({
      onConfirm: () => {
        this.props.cloneProfiles(
          [...this.state.selected.keys()],
          this.props.companyId
        );
        this.resetSelectedDevices();
      },
      text: I18n.t('profiles.cloneProfilesConfirmationText'),
    });

  toggleToolPanel = () => {
    this.setState({ showToolPanel: !this.state.showToolPanel });
  };

  assignProfilesToFleet = (fleetIds) => {
    this.props.assignProfilesToFleets(
      [...new Set([...this.state.selected.keys()])],
      fleetIds
    );
  };

  handleCreateProfile = () => {
    history.push(`${RootRoutes.PROFILES}${ProfilesRoutes.ALL}/create`);
  };
}

const mapStateToProps = (state) => {
  const { data: fleets } = fleetsV2Selector(state);
  return {
    companyId: companyIdSelector(state),
    data: profilesSelector(state),
    status: state.profiles.status,
    fleets,
    assignToFleetsStatus: state.profiles.assignToFleetsStatus,
    filters: state.filters.filters,
    isCriteriaStrict: state.filters.isCriteriaStrict,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    unassignProfiles: (profiles, companyId) =>
      dispatch(unassignProfiles(profiles, companyId)),
    cloneProfiles: (profiles, companyId) =>
      dispatch(cloneProfiles(profiles, companyId)),
    deleteProfiles: (profiles, companyId) =>
      dispatch(deleteProfiles(profiles, companyId)),
    assignProfilesToFleets: (profiles, fleetIds) =>
      dispatch(assignProfilesToFleets(profiles, fleetIds)),
    assignProfilesToFleetsReset: () => dispatch(assignProfilesToFleetsReset()),
    loadProfiles: () => dispatch(loadProfiles()),
    migrateProfileToNewVersion: (params) =>
      dispatch(migrateProfileToNewVersion(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesComponent);
