import Popup from 'reactjs-popup';
import React from 'react';
import { ReactComponent as InfoCircle } from '../assets/images/info-circle.svg';

const defaultPopupStyle = {
  minWidth: '200px',
  width: '200px',
};

const defaultIconStyle = {
  width: '16px',
  height: '16px',
};

class Icon extends React.Component {
  cursorStyle =
    this.props.children || this.props.onClick ? { cursor: 'pointer' } : {};

  render() {
    if (this.props.children) {
      return (
        <Popup
          closeOnDocumentClick
          repositionOnResize
          on={'hover'}
          position={'bottom right'}
          contentStyle={this.props.contentStyle || defaultPopupStyle}
          trigger={this.renderIcon()}
        >
          {this.props.children}
        </Popup>
      );
    }

    return this.renderIcon();
  }

  renderIcon = () => {
    const IconImage = this.props.icon ?? InfoCircle;
    return (
      <div>
        <IconImage
          alt={''}
          className={this.props.className || 'sidebar-icon__img'}
          style={{
            ...defaultIconStyle,
            ...this.cursorStyle,
            ...(this.props.style || {}),
          }}
        />
      </div>
    );
  };
}

export default Icon;
