import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { companyIdSelector } from '../login/login.selectors';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import Task from '../task/task.component';
import { tasksTableColumns } from './tasks.constants';
import { loadTasks } from './tasks.thunk';
import { NetworkStatus } from '../../common/constants';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { INFINITE_SCROLL_LIMIT } from '../../constants/infiniteScroll';
import DataTable from '../../components/DataTable/DataTable.component';
import { Pages } from '../../constants/pages';

class Tasks extends Component {
  state = {
    selected: new Map(),
  };

  tableRef = React.createRef();

  componentDidMount() {
    this.loadTasks(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  loadTasks = () =>
    this.props.loadTasks({
      offset: this.props.offset,
      limit: INFINITE_SCROLL_LIMIT,
      isInitialLoad: true,
    });

  render() {
    return (
      <Fragment>
        <div className={'app__content'}>
          <div className={'page-content'}>
            <div className={'card'}>
              <DataTable
                allColumns={tasksTableColumns}
                data={this.props.data}
                loadingStatus={this.props.status}
                pageId={Pages.INSTALLATION_STATUS}
                sortable
                useFilter
                useFlex
                useCSVExport
                // infiniteScrollProps={{
                //   loadMore: this.loadTasks,
                //   hasMore: this.props.hasMore,
                // }}
              />
            </div>
          </div>
        </div>
        {this.props.status === NetworkStatus.DONE ? (
          <Route
            exact
            path={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/:childRoute/:subRoute?`}
            component={Task}
          />
        ) : (
          ''
        )}
      </Fragment>
    );
  }

  // renderTools = () => {
  //     if (this.state.selected.size) {
  //         return (
  //             <ToolsPanel
  //                 selectedItems={this.state.selected}
  //                 title={"TASKS SELECTED"}
  //             >
  //                 <Tool
  //                     src={undefined}
  //                     title={"Clear selection"}
  //                     onClick={() => this.setState({selected: new Map()})}
  //                 />
  //             </ToolsPanel>
  //         );
  //     }
  // };

  // select = ({target: {checked}}, item) => {
  //     const selected = new Map(this.state.selected);
  //     if (!checked) {
  //         selected.delete(item.id);
  //     } else {
  //         selected.set(item.id, item);
  //     }
  //     this.setState({selected});
  // };
  //
  // toggleSelectAll = ({target: {checked}}) => {
  //     if (this.props.data && this.props.data.length) {
  //         const selected = checked ? new Map(this.props.data.map(item => [item.id, item])) : new Map();
  //         this.setState({selected});
  //     }
  // };
}

const mapStateToProps = (state) => {
  const { tasks: { data, status, next } = {} } = state;
  const { offset } = RouteHelpers.getURLQueryParams(next);
  return {
    companyId: companyIdSelector(state),
    data,
    status,
    offset,
    hasMore: Boolean(next),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTasks: (params) => dispatch(loadTasks(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
