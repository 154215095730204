import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../components/NotFound';
import { HelpRoutes, RootRoutes } from '../utils/routes';
import Help from '../components/Help';

const _Help = (props) => (
  <Switch>
    <Redirect
      exact
      from={RootRoutes.HELP}
      to={`${RootRoutes.HELP}${HelpRoutes.DOCS}`}
    />
    <Route
      exact
      path={`${RootRoutes.HELP}${HelpRoutes.DOCS}`}
      component={Help}
    />
    <Route component={NotFound} />
  </Switch>
);

export default _Help;
