import { NetworkStatus } from '../../common/constants';
import {
  SYSTEM_MESSAGE_LOAD_FAILED,
  SYSTEM_MESSAGE_LOAD_STARTED,
  SYSTEM_MESSAGE_LOAD_SUCCESS,
} from './systemMessage.actionTypes';

export default function systemMessage(
  state = {
    data: {},
    status: NetworkStatus.NONE,
    error: undefined,
  },
  { type, payload }
) {
  switch (type) {
    case SYSTEM_MESSAGE_LOAD_STARTED:
      return {
        data: {},
        error: undefined,
        status: NetworkStatus.STARTED,
      };
    case SYSTEM_MESSAGE_LOAD_FAILED:
      return {
        data: {},
        error: payload,
        status: NetworkStatus.ERROR,
      };
    case SYSTEM_MESSAGE_LOAD_SUCCESS:
      return {
        data: payload,
        error: undefined,
        status: NetworkStatus.DONE,
      };
    default:
      return state;
  }
}
