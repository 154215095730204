import React, { useRef } from 'react';
import { Translate } from 'react-redux-i18n';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { AttributeType } from '../../../common/constants';
import DataTable from '../../../components/DataTable/DataTable.component';
import { Pages } from '../../../constants/pages';
import { REMOTE_CONTROL_APPLICATION_NAME } from '../../../constants/applications';
import { filterConditions } from '../../../constants/filter';

const applicationsColumns = [
  {
    key: 'idName',
    type: AttributeType.CUSTOM,
    titleCode: 'applications.fields.packageId',
    custom: {
      formatterFunction: ({ values, key }) =>
        values.isRemoteControlApp
          ? `${REMOTE_CONTROL_APPLICATION_NAME} ${
              values.signed ? '(Signed for' : ''
            } ${values.signedForDeviceType ?? ''}${
              values.signed ? ')' : ''
            }`.trim()
          : values[key],
    },
  },
  {
    key: 'label',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.label',
    sortable: true,
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'version',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.version',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'versionCode',
    type: AttributeType.TEXT,
    titleCode: 'applications.fields.latestVersionCode',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'size',
    type: AttributeType.SIZE,
    titleCode: 'applications.fields.size',
    sortable: false,
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'applications.fields.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'file',
    type: AttributeType.BOOLEAN,
    titleCode: 'applications.fields.managed',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
];

const Applications = ({ items, select: onSelect, selectable = false }) => {
  const tableRef = useRef();
  return (
    <div className="applications">
      <SectionDescription
        title={<Translate value="applications.title" />}
        hint={<Translate value="applications.fields.installedOnThisDevice" />}
      />
      <DataTable
        allColumns={applicationsColumns}
        data={items}
        onSelect={onSelect}
        pageId={Pages.APPS_INSTALLED_ON_DEVICE}
        tableRef={tableRef}
        selectable={selectable}
        customIdName="idName"
        useResizableColumns
        allColumnsResizable
        bodyHeight={600}
      />
    </div>
  );
};

export default Applications;
