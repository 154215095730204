import { AlertTypeTypes } from './alerts.constants';
import { communicationChannels } from '../createAlert/createAlert.constants';

export const serializeAlert = ({
  name,
  fleets,
  alertType,
  alertCondition: { conditionType, conditionValues: { percentage } = {} } = {},
  alertChannel: {
    alertFrequencyRepeatValue,
    alertFrequencyHours,
    singleEmail,
    communicationChannel,
    userGroups,
  },
}) => {
  return {
    alert_name: name,
    alert_type: alertType,
    communication_channel_type: communicationChannel,
    // communication_channel_type: communicationChannelMap[communicationChannel],
    ...(communicationChannel === communicationChannels.SINGLE_EMAIL
      ? {
          email_list_for_alert: singleEmail.split(/\r?\n/),
        }
      : {}),
    ...(communicationChannel === communicationChannels.USER_GROUPS
      ? {
          groups: userGroups,
        }
      : {}),
    ...(alertType === AlertTypeTypes.BATTERY
      ? {
          condition_type: conditionType,
          condition_values: { percentage },
        }
      : {
          condition_type: conditionType,
          condition_values: {},
        }),
    fleets,
    alert_frequency: {
      repeat_count: alertFrequencyRepeatValue,
      hours: alertFrequencyHours,
    },
  };
};

export const deserializeAlert = ({
  active_status,
  alert_name,
  alert_type,
  condition_type,
  condition_values = {},
  fleets,
  groups = [],
  total_alerts,
  email_list_for_alert = [],
  communication_channel_type,
  alert_frequency: { repeat_count, hours } = {},
  id,
}) => ({
  name: alert_name,
  status: active_status,
  alertType: alert_type,
  totalAlerts: total_alerts,
  alertChannel: {
    alertFrequencyRepeatValue: repeat_count,
    alertFrequencyHours: hours,
    singleEmail: email_list_for_alert?.join('\n'),
    communicationChannel: communication_channel_type,
    userGroups: groups,
  },
  alertCondition: {
    ...(alert_type === AlertTypeTypes.BATTERY
      ? {
          conditionType: condition_type,
          conditionValues: { ...condition_values },
        }
      : {
          conditionType: condition_type,
          conditionValues: {},
        }),
  },
  fleets,
  id,
});
