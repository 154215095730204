import { NetworkStatus } from '../../common/constants';
import {
  ACCOUNT_LOAD_FAILED,
  ACCOUNT_LOAD_STARTED,
  ACCOUNT_LOAD_SUCCESS,
} from './account.actions';
import {
  GLOBAL_PERMISSIONS_FOR_USER_FAILED,
  GLOBAL_PERMISSIONS_FOR_USER_LOAD_STARTED,
  GLOBAL_PERMISSIONS_FOR_USER_SUCCESS,
  LINK_SSO_ACCOUNT_FAILED,
  LINK_SSO_ACCOUNT_STARTED,
  LINK_SSO_ACCOUNT_SUCCESS,
} from './account.actionTypes';

export default function account(
  state = {
    status: NetworkStatus.NONE,
    error: undefined,
    data: {},

    globalPermissionsForUserStatus: NetworkStatus.NONE,
    globalPermissionsForUserError: undefined,
    globalPermissionsForUser: {},
  },
  action
) {
  switch (action.type) {
    case ACCOUNT_LOAD_STARTED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.STARTED,
        error: undefined,
      });
    case ACCOUNT_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });
    case ACCOUNT_LOAD_FAILED:
      return Object.assign({}, state, {
        status: NetworkStatus.ERROR,
        error: action.payload,
      });

    case GLOBAL_PERMISSIONS_FOR_USER_LOAD_STARTED:
      return {
        ...state,
        globalPermissionsForUserStatus: NetworkStatus.STARTED,
        globalPermissionsForUserError: undefined,
      };
    case GLOBAL_PERMISSIONS_FOR_USER_FAILED:
      return {
        ...state,
        globalPermissionsForUser: [],
        globalPermissionsForUserStatus: NetworkStatus.ERROR,
        globalPermissionsForUserError: action.payload,
      };
    case GLOBAL_PERMISSIONS_FOR_USER_SUCCESS:
      return {
        ...state,
        globalPermissionsForUser: action.payload,
        globalPermissionsForUserStatus: NetworkStatus.DONE,
        globalPermissionsForUserError: undefined,
      };
    case LINK_SSO_ACCOUNT_STARTED:
      return {};
    case LINK_SSO_ACCOUNT_FAILED:
      return {};
    case LINK_SSO_ACCOUNT_SUCCESS:
      return {};
    default:
      return state;
  }
}
