import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Dialog from '../../components/Dialog';
import { AlertRoutes, RootRoutes } from '../../utils/routes';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { editAlert, loadAlert } from './alert.thunk';
import { alertSelector, alertStatusSelector } from './alert.selectors';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import { AlertConstants } from '../alerts/alerts.constants';
import AlertTypeTab from './components/alertTypeTab.component';
import DialogHeaderTitleSection from '../../components/DialogHeaderTitleSection/DialogHeaderTitleSection.component';
import UserHelpers from '../../common/utilities/user';
import AlertConditionTab from './components/alertConditionTab.component';
import { allCompanyFleetsSelector } from '../../selectors/fleetsSelector';
import LinkedFleetsTab from './components/linkedFleetsTab.component';
import AlertStatus from '../alerts/components/alertStatus.component';
import { SectionDescription } from '../../components/sectionDescription.component';
import CommunicationChannelTab from './components/communicationChannelTab.component';
import { Button } from 'react-bootstrap';
import { loadGroups } from '../group/groups.thunk';
import { NetworkStatus } from '../../common/constants';

class Alert extends Component {
  state = {
    isEditingMode: false,
    changed: false,
    editingModeData: {},
  };

  componentDidMount() {
    const { userGroupsStatus, loadGroups } = this.props;
    if (userGroupsStatus !== NetworkStatus.DONE) {
      loadGroups();
    }
    this.loadAlert();
  }

  loadAlert = () => {
    const { id } = this.props;
    this.props.loadAlert(id);
  };

  onBack = () => this.setState(() => ({ isEditingMode: false }));

  getAlertMenuStructure = () => {
    const { id } = this.props;
    const rootPath = `${RootRoutes.ALERTS}${AlertRoutes.ALERTS}`;
    return [
      // {
      //   key: AlertConstants.NAME,
      //   route: RouteHelpers.formatUrlWithQuery(`${rootPath}/${AlertConstants.NAME}`, {
      //     id,
      //   }),
      //   title: I18n.t('alerts.alertName'),
      //   isActive: (match, location) => location && location.pathname.includes(AlertConstants.NAME),
      // },
      {
        key: AlertConstants.STATUS,
        route: RouteHelpers.formatUrlWithQuery(
          `${rootPath}/${AlertConstants.STATUS}`,
          {
            id,
          }
        ),
        title: I18n.t('alerts.alertStatus'),
        isActive: (match, location) =>
          location && location.pathname.includes(AlertConstants.STATUS),
      },
      {
        key: AlertConstants.LINKED_FLEETS,
        route: RouteHelpers.formatUrlWithQuery(
          `${rootPath}/${AlertConstants.LINKED_FLEETS}`,
          {
            id,
          }
        ),
        title: I18n.t('alerts.linkedFleets'),
        isActive: (match, location) =>
          location && location.pathname.includes(AlertConstants.LINKED_FLEETS),
      },
      {
        key: AlertConstants.ALERT_TYPE,
        route: RouteHelpers.formatUrlWithQuery(
          `${rootPath}/${AlertConstants.ALERT_TYPE}`,
          {
            id,
          }
        ),
        title: I18n.t('alerts.alertType'),
        isActive: (match, location) =>
          location && location.pathname.includes(AlertConstants.ALERT_TYPE),
      },
      {
        key: AlertConstants.ALERT_CONDITION,
        route: RouteHelpers.formatUrlWithQuery(
          `${rootPath}/${AlertConstants.ALERT_CONDITION}`,
          {
            id,
          }
        ),
        title: I18n.t('alerts.alertCondition'),
        isActive: (match, location) =>
          location &&
          location.pathname.includes(AlertConstants.ALERT_CONDITION),
      },
      {
        key: AlertConstants.COMMUNICATION_CHANNEL,
        route: RouteHelpers.formatUrlWithQuery(
          `${rootPath}/${AlertConstants.COMMUNICATION_CHANNEL}`,
          {
            id,
          }
        ),
        title: I18n.t('alerts.alertChannel'),
        isActive: (match, location) =>
          location &&
          location.pathname.includes(AlertConstants.COMMUNICATION_CHANNEL),
      },
    ];
  };

  undoChanges = () => {
    const { alert } = this.props;
    this.setState(() => ({
      editingModeData: { ...alert },
    }));
  };

  saveChanges = () => {
    const { editAlert, alert: { id } = {} } = this.props;
    const { editingModeData } = this.state;
    editAlert({ alert: editingModeData, alertId: id });
  };

  onEditingModeToggleClick = () => {
    const { alert } = this.props;
    this.setState((prevState) => ({
      isEditingMode: !prevState.isEditingMode,
      editingModeData: { ...alert },
    }));
  };

  handlePropertyChangeInEditingMode = ({ key, value }) => {
    this.setState((prevState) => ({
      changed: true,
      editingModeData: {
        ...prevState.editingModeData,
        [key]: value,
      },
    }));
  };

  handleAlertRename = ({ target: { value } = {} } = {}) =>
    this.handlePropertyChangeInEditingMode({
      key: 'name',
      value,
    });

  renderAlertHeader = () => {
    const { isEditingMode, editingModeData } = this.state;
    const { hasAdminPrivileges, alert: { name } = {} } = this.props;
    const isEditingModeToggleIconAvailable =
      !isEditingMode && hasAdminPrivileges;
    const hint = I18n.t(
      isEditingMode ? 'alerts.youAreEditing' : 'alerts.youAreViewing'
    );
    return (
      <Fragment>
        <DialogHeaderTitleSection
          isEditingMode={isEditingMode}
          titleText={name}
          titleHint={hint}
          defaultValue={editingModeData.name}
          placeholder="placeholder"
          onInputChange={this.handleAlertRename}
          isEditingModeToggleIconAvailable={isEditingModeToggleIconAvailable}
          onEditingModeToggleIconClick={this.onEditingModeToggleClick}
          hideSubmitIcon
        />
        {hasAdminPrivileges && isEditingMode ? (
          <div className="dialog__header-actions-section">
            {/*<Button*/}
            {/*  secondary*/}
            {/*  disabled={!this.state.changed}*/}
            {/*  onClick={this.undoChanges}*/}
            {/*>*/}
            {/*  <Translate value='common.undo' />*/}
            {/*</Button>*/}
            <Button
              disabled={!this.state.changed}
              onClick={this.saveChanges}
              style={{ marginLeft: '5px' }}
            >
              <Translate value="common.save" />
            </Button>
          </div>
        ) : null}
      </Fragment>
    );
  };

  renderAlertMenu = () => (
    <DialogMenu structure={this.getAlertMenuStructure()} />
  );

  renderAlertContent = () => {
    const { alert = {}, allFleets, allGroups } = this.props;
    const { isEditingMode, editingModeData } = this.state;

    const rootPath = `${RootRoutes.ALERTS}${AlertRoutes.ALERTS}`;
    const contentRoutes = [
      // {
      //   path: `${rootPath}/${AlertConstants.NAME}`,
      //   component: () =><div>Component NAME</div>
      // },
      {
        path: `${rootPath}/${AlertConstants.ALERT_TYPE}`,
        component: AlertTypeTab,
      },
      {
        path: `${rootPath}/${AlertConstants.ALERT_CONDITION}`,
        component: AlertConditionTab,
      },
      {
        path: `${rootPath}/${AlertConstants.LINKED_FLEETS}`,
        component: LinkedFleetsTab,
      },
      {
        path: `${rootPath}/${AlertConstants.COMMUNICATION_CHANNEL}`,
        component: CommunicationChannelTab,
      },
      {
        path: `${rootPath}/${AlertConstants.STATUS}`,
        component: () => (
          <section>
            <SectionDescription title={I18n.t('alerts.alertStatus')} isSmall />
            <AlertStatus
              item={{
                status: alert.status,
              }}
            />
          </section>
        ),
      },
    ];

    return (
      <section>
        <Switch>
          {contentRoutes.map(({ path, component: ContentComponent }) => (
            <Route
              key={path}
              path={path}
              render={(props) => (
                <ContentComponent
                  {...props}
                  data={alert}
                  isEditingMode={isEditingMode}
                  allFleets={allFleets}
                  allGroups={allGroups}
                  editingModeData={editingModeData}
                  handlePropertyChangeInEditingMode={
                    this.handlePropertyChangeInEditingMode
                  }
                />
              )}
            />
          ))}
        </Switch>
      </section>
    );
  };

  render() {
    const { isEditingMode } = this.state;
    const { alertStatus } = this.props;
    return (
      <Dialog
        status={alertStatus}
        header={this.renderAlertHeader}
        menu={this.renderAlertMenu}
        content={this.renderAlertContent}
        onBack={this.onBack}
        isFullViewportVisible={isEditingMode}
        backRoute={
          isEditingMode
            ? undefined
            : `${RootRoutes.ALERTS}${AlertRoutes.ALERTS}`
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  alert: alertSelector(state),
  alertStatus: alertStatusSelector(state),
  allFleets: allCompanyFleetsSelector(state),
  hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
  allGroups: state.groups.userGroups,
  userGroupsStatus: state.groups.userGroupsStatus,
  userGroupsError: state.groups.userGroupsError,
});

const mapDispatchToProps = (dispatch) => ({
  loadAlert: (alertId) => dispatch(loadAlert(alertId)),
  editAlert: (params) => dispatch(editAlert(params)),
  loadGroups: () => dispatch(loadGroups()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Alert);
