import React, { Component, Fragment } from 'react';
import Popup from 'reactjs-popup';
import Dialog from '../../components/Dialog';
import ClassNames from 'classnames';
import { Input, Label, UncontrolledCollapse } from 'reactstrap';
import FleetsTree from '../fleets/components/fleetsTree.component';
import { history } from '../../utils/history';
import Blacklist from './components/Blacklist';
import Certificates from './components/Certificates';
import Password from './components/Password';
import ProfileDetail from './components/ProfileDetail';
import {
  createProfile,
  loadProfile,
  loadProfileApplications,
  updateProfile,
} from './profile.thunk';
import {
  createProfileReset,
  profileReset,
  updateProfileReset,
} from './profile.actions';
import Authentication from './components/Authentication';
import FeatureControlComponent from './components/featureControl.component';
import { cloneDeep } from 'lodash';
import WiFi from './components/WiFi';
import LocationServices from './components/LocationServices';
import Kiosk from './components/Kiosk';
import FirmwareUpdate from '../../components/FirmwareUpdate';
import FileSync from './components/FileSync';
import { loadAllFiles } from '../../redux/files.actions';
import AppManagement from './components/AppManagement';
import KioskBuilder from './components/KioskBuilder';
import { companyIdSelector } from '../login/login.selectors';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import Geofencing from './components/Geofencing';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import Security from './components/Security';
import { knownTimezones, NetworkStatus } from '../../common/constants';
import * as moment from 'moment-timezone';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { Throbber } from '../../components/Throbber';
import { loadApplicationsFullList } from '../applications/applications.thunk';
import { Button } from '../../components/button.component';
import {
  isLoadingStatusStarted,
  selectForCurrentBrand,
} from '../../common/helpers';
import Settings from './components/Settings';
import { loadAllFleets } from '../fleets/fleets.thunk';
import PROFILE_CONFIG_CONSTANTS from '../../constants/profileConfigConstants';
import {
  assignMenuStructure,
  defaultProfileMenuStructure,
  profileMenuStructure,
} from './profileMenuStructure';
import { getAccessibleMenuItems } from '../../common/utilities/profile';
import { globalPermissionsForUserSelector } from '../../selectors/accountSelectors';
import { loadGlobalPermissionsForUser } from '../account/account.thunk';
import WebFilter from './components/WebFilter';
import UserHelpers from '../../common/utilities/user';
import HTMLKioskBuilder from './components/HTMLKioskBuilder';
import { loadCertificates } from '../certificates/certificates.thunk';
import DialogHeaderTitleSection from '../../components/DialogHeaderTitleSection/DialogHeaderTitleSection.component';
import WallpaperSettings from './components/WallpaperSettings';
import { I18n } from 'react-redux-i18n';
import { fleetsV2Selector } from '../fleets/fleetsV2.selectors';
import { applicationsSelector } from '../applications/applications.selectors';
import {
  AnalyticsCategory,
  AnalyticsProfileConstants,
} from '../../constants/analytics';
import AnalyticsService from '../../services/AnalyticsService';
import { loadAllFleetsV2 } from '../fleets/fleetsV2.thunk';

export const initialProfile = (companyId, fleetId) => ({
  companyId,
  fleetId,
  devices: [],
  lastUpdated: null,
  policies: {
    wifi: [],
    webFilter: [],
    authentication: [],
    featureControl: [],
    location: [],
    apps: [],
    applist: [],
    certificates: [],
    apn: [],
    kiosk: [],
    fileSync: [],
    geofencing: [],
    password: [],
    security: [],
    settings: [],
    wallpaperSettings: [],
  },
  fleets: [],
  id: null,
  name: I18n.t('profiles.newProfileDefaultName'),
});

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: props.accessibleMenuItems[0].key,
      profile: this.initializeProfile(props),
      changed: false,
      isEditingMode: false,
    };
  }

  componentDidMount() {
    const {
      globalPermissionsForUserStatus,
      certificatesLoadingStatus,
    } = this.props;
    const { childRoute } = this.props.match.params;
    if (!this.isProfileCreation) {
      this.props.loadProfile(childRoute);
      this.props.loadProfileApplications(childRoute);
      this.props.loadApplicationsFullList();
    } else if (
      this.props.applicationsFullListStatus === NetworkStatus.NONE ||
      this.props.applicationsFullListStatus === NetworkStatus.ERROR
    ) {
      this.props.loadApplicationsFullList();
    }
    if (globalPermissionsForUserStatus === NetworkStatus.NONE) {
      this.props.loadGlobalPermissionsForUser();
    }
    if (this.isProfileCreation) {
      this.setState({ isEditingMode: true });
    }
    this.props.loadAllFiles(this.props.companyId);
    this.props.loadAllFleets();

    if (certificatesLoadingStatus === NetworkStatus.NONE) {
      this.props.loadCertificates(this.props.companyId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.resetProfile();
      if (this.props.match.params.childRoute.toLowerCase() !== 'create') {
        this.props.loadProfile(this.props.match.params.childRoute);
        this.props.loadProfileApplications(this.props.match.params.childRoute);
        this.props.loadApplicationsFullList();
      } else {
        this.setState((state) => ({
          ...state,
          profile: this.initializeProfile(this.props),
          changed: false,
        }));
        this.props.loadApplicationsFullList();
      }
    }
    if (
      this.props.status === NetworkStatus.DONE &&
      prevProps.status === NetworkStatus.STARTED
    ) {
      this.setState((state) => ({
        ...state,
        profile: this.initializeProfile(this.props),
        changed: false,
      }));
    }
    if (
      prevProps.createProfileStatus === NetworkStatus.STARTED &&
      this.props.createProfileStatus === NetworkStatus.ERROR
    ) {
      confirmAlert({
        customUI: this.renderCreateProfileError,
      });
    }
    if (
      prevProps.updateProfileStatus === NetworkStatus.STARTED &&
      this.props.updateProfileStatus === NetworkStatus.ERROR
    ) {
      confirmAlert({
        customUI: this.renderUpdateProfileError,
      });
    }
    if (
      this.props.certificates !== prevProps.certificates &&
      this.props.certificates
    ) {
    }
  }

  componentWillUnmount() {
    this.props.resetProfile();
  }

  get isProfileCreation() {
    const { match: { params: { childRoute } = {} } = {} } = this.props;
    return childRoute === 'create';
  }

  getEditingModeToggleIconAvailability = () => {
    const { hasAdminPrivileges, accessibleMenuItems } = this.props;
    return (
      (hasAdminPrivileges ||
        accessibleMenuItems.some(({ readWrite }) => readWrite)) &&
      this.state.selectedCategory !== PROFILE_CONFIG_CONSTANTS.FLEETS
    );
  };

  renderCreateProfileError = ({ onClose }) => {
    const text =
      this.props.createProfileError ||
      I18n.t('profiles.unableToCreateProfileErrorText');
    const onClick = this.onCloseError(onClose, this.props.createProfileReset);
    return (
      <div className={'create-enrollment-alert'}>
        <p>{`${I18n.t('common.error')}:`}</p>
        <p>{text}</p>
        <Button onClick={onClick}>{I18n.t('common.ok')}</Button>
      </div>
    );
  };

  renderUpdateProfileError = ({ onClose }) => {
    const text = this.props.updateProfileError;
    const onClick = this.onCloseError(onClose, this.props.updateProfileReset);
    return (
      <div className={'create-enrollment-alert'}>
        <p>{`${I18n.t('common.error')}:`}</p>
        <p>{text}</p>
        <Button onClick={onClick}>{I18n.t('common.ok')}</Button>
      </div>
    );
  };

  onCloseError = (onClose, resetError) => (e) => {
    resetError();
    onClose();
  };

  initializeProfile = ({
    profile,
    companyId,
    match: {
      params: { childRoute },
    },
  }) => {
    return childRoute.toLowerCase() !== 'create'
      ? profile
      : initialProfile(companyId);
  };

  render() {
    if (this.state.profile) {
      return this.renderProfile();
    }
    return <div>{'...'}</div>;
  }

  renderProfile = () => {
    const { isEditingMode, selectedCategory } = this.state;
    if (selectedCategory === PROFILE_CONFIG_CONSTANTS.KIOSK_BUILDER) {
      return (
        <KioskBuilder
          policy={
            this.state.profile.policies?.kiosk?.length
              ? [this.state.profile.policies.kiosk[0]]
              : []
          }
          policies={this.state.profile.policies}
          onChange={this.onChangePolicyDetails}
          onChangePolicyProperty={this.onChangePolicyProperty}
          applications={this.props.applicationsFullList}
          applicationsFullList={this.props.applicationsFullList}
          onBack={this.onBack}
          onSave={this.onSave}
          onUndo={this.undoChanges}
          changed={this.state.changed}
        />
      );
    }
    if (selectedCategory === PROFILE_CONFIG_CONSTANTS.HTML_KIOSK_BUILDER) {
      const { files } = this.props;
      return (
        <HTMLKioskBuilder
          policy={
            this.state.profile.policies?.kiosk?.length
              ? [this.state.profile.policies.kiosk[0]]
              : []
          }
          policies={this.state.profile.policies}
          onChange={this.onChangePolicyDetails}
          onChangePolicyProperty={this.onChangePolicyProperty}
          files={files}
          applications={this.props.applicationsFullList}
          applicationsFullList={this.props.applicationsFullList}
          onBack={this.onBack}
          onSave={this.onSave}
          onUndo={this.undoChanges}
          changed={this.state.changed}
        />
      );
    }
    return (
      <Dialog
        header={this.renderProfileHeader}
        menu={this.renderProfileMenu}
        content={this.renderProfileContent}
        isFullViewportVisible={isEditingMode}
        onBack={this.onBack}
        backRoute={
          selectedCategory !== PROFILE_CONFIG_CONSTANTS.FLEETS && !isEditingMode
            ? `${RootRoutes.PROFILES}${ProfilesRoutes.ALL}`
            : undefined
        }
      />
    );
  };

  renderProfileHeader = () => {
    const { isEditingMode, selectedCategory } = this.state;
    let action;
    if (selectedCategory === PROFILE_CONFIG_CONSTANTS.FLEETS) {
      action = 'profiles.profileAssociationHint';
    } else if (this.isProfileCreation) {
      action = 'profiles.profileCreatingHint';
    } else if (isEditingMode) {
      action = 'profiles.profileEditingHint';
    } else {
      action = 'profiles.profileViewingHint';
    }
    const hint = I18n.t(action);
    const linkClass = selectForCurrentBrand({
      original: 'profile-actions__link-button',
      incube:
        'profile-actions__link-button profile-actions__link-button--incube',
      janam: 'profile-actions__link-button profile-actions__link-button--janam',
    });

    const isEditingModeToggleIconAvailable = this.getEditingModeToggleIconAvailability();

    return (
      <Fragment>
        <DialogHeaderTitleSection
          isEditingMode={isEditingMode}
          titleText={this.state.profile.name || ''}
          titleHint={hint}
          defaultValue={this.state?.profile?.name}
          placeholder={I18n.t('profiles.profileNameFieldPlaceholder')}
          onInputChange={this.onChangeRename}
          onKeyUp={this.onRenameKeyup}
          onReset={this.resetRenameInput}
          isEditingModeToggleIconAvailable={isEditingModeToggleIconAvailable}
          onEditingModeToggleIconClick={this.showInput}
          hideSubmitIcon
        />

        {isEditingMode ? (
          <div className={'profile-actions__section'}>
            {/*{this.state.selectedCategory === 'Fleets' && (*/}
            {/*  <Popup*/}
            {/*    closeOnDocumentClick*/}
            {/*    position="bottom right"*/}
            {/*    contentStyle={{*/}
            {/*      minWidth: '200px',*/}
            {/*      maxWidth: '80%',*/}
            {/*      width: '800px',*/}
            {/*      zIndex: 3,*/}
            {/*      height: '340px',*/}
            {/*    }}*/}
            {/*    repositionOnResize*/}
            {/*    trigger={*/}
            {/*      <div className={linkClass}>*/}
            {/*        {I18n.t('profiles.moreOptionsTitleHeader')}*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*  >*/}
            {/*    <div*/}
            {/*      style={{*/}
            {/*        outline: 'none !important',*/}

            {/*        boxShadow: 'none',*/}
            {/*        minWidth: '200px',*/}
            {/*        width: '100%',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          display: 'flex',*/}
            {/*          width: '100%',*/}
            {/*          height: '100%',*/}
            {/*          flexDirection: 'column',*/}
            {/*          fontSize: '12px',*/}
            {/*          letterSpacing: 'normal',*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <div*/}
            {/*          style={{*/}
            {/*            marginTop: '38px',*/}
            {/*            marginLeft: '21px',*/}

            {/*            fontSize: '21px',*/}
            {/*            fontWeight: 'normal',*/}
            {/*            fontStyle: 'normal',*/}
            {/*            fontStretch: 'normal',*/}
            {/*            lineHeight: '1.1',*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {I18n.t('profiles.assignmentSettingsTitle')}*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <form*/}
            {/*        style={{*/}
            {/*          display: 'flex',*/}
            {/*          flexWrap: 'nowrap',*/}
            {/*          flexFlow: 'row',*/}
            {/*          marginLeft: '21px',*/}
            {/*          marginTop: '33px',*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <div style={{ display: 'flex', alignItems: 'center' }}>*/}
            {/*          <div>*/}
            {/*            <Label*/}
            {/*              check*/}
            {/*              style={{*/}
            {/*                fontSize: '12px',*/}
            {/*                fontWeight: 'normal',*/}
            {/*                fontStyle: 'normal',*/}
            {/*                fontStretch: 'normal',*/}
            {/*                lineHeight: '1.17',*/}
            {/*                letterSpacing: 'normal',*/}
            {/*                textAlign: 'left',*/}
            {/*                display: 'flex',*/}
            {/*                alignItems: 'center',*/}
            {/*                cursor: 'pointer',*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <Input*/}
            {/*                type="radio"*/}
            {/*                name="radio1"*/}
            {/*                checked={*/}
            {/*                  this.state.profile.datetimeOffset === undefined*/}
            {/*                }*/}
            {/*                onChange={() =>*/}
            {/*                  this.setState((state) => ({*/}
            {/*                    ...state,*/}
            {/*                    profile: {*/}
            {/*                      ...state.profile,*/}
            {/*                      datetimeOffset: undefined,*/}
            {/*                    },*/}
            {/*                  }))*/}
            {/*                }*/}
            {/*                style={{ marginRight: '4px' }}*/}
            {/*              />{' '}*/}
            {/*              {I18n.t('profiles.deviceSideTimeLabel')}*/}
            {/*            </Label>*/}
            {/*          </div>*/}
            {/*          <div style={{ marginLeft: '28px' }}>*/}
            {/*            <Label*/}
            {/*              check*/}
            {/*              style={{*/}
            {/*                fontSize: '12px',*/}
            {/*                fontWeight: 'normal',*/}
            {/*                fontStyle: 'normal',*/}
            {/*                fontStretch: 'normal',*/}
            {/*                lineHeight: '1.17',*/}
            {/*                letterSpacing: 'normal',*/}
            {/*                textAlign: 'left',*/}
            {/*                display: 'flex',*/}
            {/*                alignItems: 'center',*/}
            {/*                marginLeft: '4px',*/}
            {/*                cursor: 'pointer',*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <Input*/}
            {/*                type="radio"*/}
            {/*                name="radio1"*/}
            {/*                value={'notStrict'}*/}
            {/*                checked={*/}
            {/*                  this.state.profile.datetimeOffset !== undefined*/}
            {/*                }*/}
            {/*                onChange={() => {*/}
            {/*                  this.setState((state) => ({*/}
            {/*                    ...state,*/}
            {/*                    profile: {*/}
            {/*                      ...state.profile,*/}
            {/*                      datetimeOffset: moment.tz.guess(true),*/}
            {/*                    },*/}
            {/*                  }));*/}
            {/*                }}*/}
            {/*                style={{ marginRight: '4px' }}*/}
            {/*              />{' '}*/}
            {/*              {I18n.t('profiles.timezone')}*/}
            {/*            </Label>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </form>*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          marginTop: '38px',*/}
            {/*          marginLeft: '21px',*/}

            {/*          fontSize: '21px',*/}
            {/*          fontWeight: 'normal',*/}
            {/*          fontStyle: 'normal',*/}
            {/*          fontStretch: 'normal',*/}
            {/*          lineHeight: '1.1',*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {I18n.t('profiles.schedules')}*/}
            {/*      </div>*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          display: 'flex',*/}
            {/*          flexFlow: 'column nowrap',*/}
            {/*          padding: '14px 24px',*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <div>*/}
            {/*          <div*/}
            {/*            style={{*/}
            {/*              display: 'flex',*/}
            {/*              alignItems: 'center',*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <Label*/}
            {/*              check*/}
            {/*              style={{*/}
            {/*                fontSize: '12px',*/}
            {/*                fontWeight: 'normal',*/}
            {/*                fontStyle: 'normal',*/}
            {/*                fontStretch: 'normal',*/}
            {/*                lineHeight: '1.17',*/}
            {/*                letterSpacing: 'normal',*/}
            {/*                textAlign: 'left',*/}
            {/*                display: 'flex',*/}
            {/*                alignItems: 'center',*/}
            {/*                cursor: 'pointer',*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <Input*/}
            {/*                type="checkbox"*/}
            {/*                name="check1"*/}
            {/*                value={this.state.profile.beginDate !== undefined}*/}
            {/*                checked={this.state.profile.beginDate !== undefined}*/}
            {/*                onChange={({ target: { checked } }) => {*/}
            {/*                  this.setState((state) => {*/}
            {/*                    return !checked*/}
            {/*                      ? {*/}
            {/*                          ...state,*/}
            {/*                          profile: {*/}
            {/*                            ...state.profile,*/}
            {/*                            beginDate: undefined,*/}
            {/*                            beginTime: undefined,*/}
            {/*                          },*/}
            {/*                          changed: true,*/}
            {/*                        }*/}
            {/*                      : {*/}
            {/*                          ...state,*/}
            {/*                          profile: {*/}
            {/*                            ...state.profile,*/}
            {/*                            beginDate: state.profile.endDate*/}
            {/*                              ? state.profile.endDate*/}
            {/*                              : moment().format('YYYY-MM-DD'),*/}
            {/*                            beginTime: state.profile.endTime*/}
            {/*                              ? state.profile.endTime*/}
            {/*                              : moment().format('hh:mm A'),*/}
            {/*                          },*/}
            {/*                          changed: true,*/}
            {/*                        };*/}
            {/*                  });*/}
            {/*                }}*/}
            {/*                style={{ marginRight: '8px' }}*/}
            {/*              />{' '}*/}
            {/*              <div*/}
            {/*                style={{*/}
            {/*                  display: 'flex',*/}
            {/*                  width: '80px',*/}
            {/*                  marginRight: '14px',*/}
            {/*                }}*/}
            {/*              >*/}
            {/*                {I18n.t('profiles.assignmentStartDateLabel')}*/}
            {/*              </div>*/}
            {/*            </Label>*/}
            {/*            <Datetime*/}
            {/*              value={*/}
            {/*                moment(*/}
            {/*                  this.state.profile.beginDate,*/}
            {/*                  'YYYY-MM-DD'*/}
            {/*                ).isValid()*/}
            {/*                  ? moment(*/}
            {/*                      this.state.profile.beginDate,*/}
            {/*                      'YYYY-MM-DD'*/}
            {/*                    )*/}
            {/*                  : undefined*/}
            {/*              }*/}
            {/*              dateFormat={'YYYY-MM-DD'}*/}
            {/*              timeFormat={false}*/}
            {/*              onChange={this.onChangeBeginDate}*/}
            {/*              disabled={this.state.profile.beginDate === undefined}*/}
            {/*              maxDate*/}
            {/*            />*/}
            {/*            <Datetime*/}
            {/*              value={*/}
            {/*                moment(*/}
            {/*                  this.state.profile.beginTime,*/}
            {/*                  'hh:mm A'*/}
            {/*                ).isValid()*/}
            {/*                  ? moment(this.state.profile.beginTime, 'hh:mm A')*/}
            {/*                  : undefined*/}
            {/*              }*/}
            {/*              dateFormat={false}*/}
            {/*              timeFormat={'hh:mm A'}*/}
            {/*              onChange={this.onChangeBeginTime}*/}
            {/*            />*/}
            {/*            <select*/}
            {/*              className="form-control"*/}
            {/*              value={this.state.profile.datetimeOffset || ''}*/}
            {/*              name={'value'}*/}
            {/*              style={{*/}
            {/*                marginLeft: '10px',*/}
            {/*                maxWidth: '300px',*/}
            {/*                width: '45%',*/}
            {/*                minWidth: '45%',*/}
            {/*              }}*/}
            {/*              onChange={({ target: { value } }) => {*/}
            {/*                this.setState((state) => ({*/}
            {/*                  profile: {*/}
            {/*                    ...state.profile,*/}
            {/*                    datetimeOffset: value,*/}
            {/*                  },*/}
            {/*                  changed: true,*/}
            {/*                }));*/}
            {/*              }}*/}
            {/*              placeholder={I18n.t(*/}
            {/*                'profiles.datetimeOffsetPlaceholder'*/}
            {/*              )}*/}
            {/*              disabled={*/}
            {/*                this.state.profile.datetimeOffset === undefined*/}
            {/*              }*/}
            {/*            >*/}
            {/*              {this.state.profile.datetimeOffset === undefined ? (*/}
            {/*                <option value={''}>{''}</option>*/}
            {/*              ) : null}*/}
            {/*              {[...knownTimezones.entries()].map((tz) => {*/}
            {/*                return (*/}
            {/*                  <option key={tz[0]} value={tz[0]}>*/}
            {/*                    {tz[1] || tz[0]}*/}
            {/*                  </option>*/}
            {/*                );*/}
            {/*              })}*/}
            {/*            </select>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*          <div*/}
            {/*            style={{*/}
            {/*              display: 'flex',*/}
            {/*              alignItems: 'center',*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <Label*/}
            {/*              check*/}
            {/*              style={{*/}
            {/*                fontSize: '12px',*/}
            {/*                fontWeight: 'normal',*/}
            {/*                fontStyle: 'normal',*/}
            {/*                fontStretch: 'normal',*/}
            {/*                lineHeight: '1.17',*/}
            {/*                letterSpacing: 'normal',*/}
            {/*                textAlign: 'left',*/}
            {/*                display: 'flex',*/}
            {/*                alignItems: 'center',*/}
            {/*                cursor: 'pointer',*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <Input*/}
            {/*                type="checkbox"*/}
            {/*                name="check2"*/}
            {/*                value={this.state.profile.endDate !== undefined}*/}
            {/*                checked={this.state.profile.endDate !== undefined}*/}
            {/*                onChange={({ target: { checked } }) => {*/}
            {/*                  this.setState((state) => {*/}
            {/*                    return !checked*/}
            {/*                      ? {*/}
            {/*                          ...state,*/}
            {/*                          profile: {*/}
            {/*                            ...state.profile,*/}
            {/*                            endDate: undefined,*/}
            {/*                            endTime: undefined,*/}
            {/*                          },*/}
            {/*                          changed: true,*/}
            {/*                        }*/}
            {/*                      : {*/}
            {/*                          ...state,*/}
            {/*                          profile: {*/}
            {/*                            ...state.profile,*/}
            {/*                            endDate: state.profile.beginDate*/}
            {/*                              ? state.profile.beginDate*/}
            {/*                              : moment().format('YYYY-MM-DD'),*/}
            {/*                            endTime: state.profile.beginTime*/}
            {/*                              ? state.profile.beginTime*/}
            {/*                              : moment().format('hh:mm A'),*/}
            {/*                          },*/}
            {/*                          changed: true,*/}
            {/*                        };*/}
            {/*                  });*/}
            {/*                }}*/}
            {/*                style={{ marginRight: '8px' }}*/}
            {/*              />{' '}*/}
            {/*              <div*/}
            {/*                style={{*/}
            {/*                  display: 'flex',*/}
            {/*                  width: '80px',*/}
            {/*                  marginRight: '14px',*/}
            {/*                }}*/}
            {/*              >*/}
            {/*                {I18n.t('profiles.assignmentEndDateLabel')}*/}
            {/*              </div>*/}
            {/*            </Label>*/}
            {/*            <Datetime*/}
            {/*              dateFormat={'YYYY-MM-DD'}*/}
            {/*              timeFormat={false}*/}
            {/*              value={this.state.profile.endDate}*/}
            {/*              onChange={this.onChangeEndDate}*/}
            {/*            />*/}
            {/*            <Datetime*/}
            {/*              dateFormat={false}*/}
            {/*              timeFormat={'hh:mm A'}*/}
            {/*              value={this.state.profile.endTime}*/}
            {/*              onChange={this.onChangeEndTime}*/}
            {/*            />*/}
            {/*            <div*/}
            {/*              style={{*/}
            {/*                marginLeft: '10px',*/}
            {/*                maxWidth: '300px',*/}
            {/*                width: '45%',*/}
            {/*                minWidth: '45%',*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {' '}*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </Popup>*/}
            {/*)}*/}
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
              <Button
                secondary
                disabled={
                  !this.state.changed ||
                  this.props.status === NetworkStatus.STARTED ||
                  this.props.createProfileStatus === NetworkStatus.STARTED ||
                  this.props.updateProfileStatus === NetworkStatus.STARTED
                }
                onClick={this.undoChanges}
              >
                {I18n.t('common.undo')}
              </Button>
              {this.state.selectedCategory !== 'Fleets' &&
              this.state.selectedCategory !==
                PROFILE_CONFIG_CONSTANTS.KIOSK_BUILDER &&
              this.state.selectedCategory !==
                PROFILE_CONFIG_CONSTANTS.HTML_KIOSK_BUILDER ? (
                <Fragment>
                  <Button onClick={this.onAssign} style={{ marginLeft: '5px' }}>
                    {I18n.t('profiles.assignmentButtonText')}
                  </Button>
                </Fragment>
              ) : null}
              <Button
                disabled={
                  !this.state.changed ||
                  this.props.status === NetworkStatus.STARTED ||
                  this.props.createProfileStatus === NetworkStatus.STARTED ||
                  this.props.updateProfileStatus === NetworkStatus.STARTED
                }
                onClick={this.onSave}
                style={{ marginLeft: '5px' }}
              >
                {this.state.selectedCategory === 'Fleets'
                  ? I18n.t('profiles.assignmentButtonText')
                  : this.state.profile.id
                  ? I18n.t('profiles.saveButtonText')
                  : I18n.t('profiles.createButtonText')}
              </Button>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  };

  onChangeBeginDate = (newDate) =>
    this.setState((state) => {
      const newDateMoment = moment(newDate, 'YYYY-MM-DD');
      let date = newDateMoment.isValid() ? newDateMoment : moment();
      let beginDate;
      let beginTime = '12:00 A';
      if (state.profile.beginTime) {
        const beginTimeMoment = moment(state.profile.beginTime, 'hh:mm A');
        if (beginTimeMoment.isValid()) {
          date
            .hours(beginTimeMoment.hours())
            .minutes(beginTimeMoment.minutes());
          beginTime = beginTimeMoment.format('hh:mm A');
        }
      }
      beginDate = date.format('YYYY-MM-DD');

      if (state.profile.endDate) {
        const endDateMoment = moment(state.profile.endDate, 'YYYY-MM-DD');
        if (state.profile.endTime) {
          const endTimeMoment = moment(this.state.profile.endTime, 'hh:mm A');
          if (endTimeMoment.isValid()) {
            endDateMoment
              .hours(endTimeMoment.hours())
              .minutes(endTimeMoment.minutes());
          }
        }
        if (endDateMoment.isValid() && endDateMoment.isBefore(date)) {
          beginDate = endDateMoment.format('YYYY-MM-DD');
          beginTime = endDateMoment.format('hh:mm A');
        }
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          beginDate,
          beginTime,
        },
        changed: true,
      };
    });

  onChangeBeginTime = (newDate) =>
    this.setState((state) => {
      const newDateMoment = moment(newDate, 'hh:mm A');
      let date = newDateMoment.isValid() ? newDateMoment : moment();
      let beginDate = moment().format('YYYY-MM-DD');
      let beginTime;
      if (state.profile.beginDate) {
        let beginDateMoment = moment(state.profile.beginDate, 'YYYY-MM-DD');
        if (beginDateMoment.isValid()) {
          date
            .year(beginDateMoment.year())
            .month(beginDateMoment.month())
            .date(beginDateMoment.date());
          beginDate = date.format('YYYY-MM-DD');
        }
      }
      beginTime = date.format('hh:mm A');
      if (state.profile.endDate) {
        let endDate = moment(state.profile.endDate, 'YYYY-MM-DD');
        if (state.profile.endTime) {
          const endTime = moment(this.state.profile.endTime, 'hh:mm A');
          if (endTime.isValid()) {
            endDate.hours(endTime.hours()).minutes(endTime.minutes());
          }
        }
        if (endDate.isBefore(date)) {
          beginDate = endDate.format('YYYY-MM-DD');
          beginTime = endDate.format('hh:mm A');
        }
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          beginDate,
          beginTime,
        },
        changed: true,
      };
    });

  onChangeEndDate = (newDate) =>
    this.setState((state) => {
      let date = moment(newDate, 'YYYY-MM-DD');
      let endDate;
      let endTime = '12:00 AM';
      if (state.profile.endTime) {
        const endTimeMoment = moment(state.profile.endTime, 'hh:mm A');
        if (endTimeMoment.isValid()) {
          date.hours(endTimeMoment.hours()).minutes(endTimeMoment.minutes());
          endTime = date.format('hh:mm A');
        }
      }
      endDate = date.format('YYYY-MM-DD');

      if (state.profile.beginDate) {
        const beginDate = moment(state.profile.beginDate, 'YYYY-MM-DD');
        if (state.profile.beginTime) {
          const beginTime = moment(this.state.profile.beginTime, 'hh:mm A');
          if (beginTime.isValid()) {
            beginDate.hours(beginTime.hours()).minutes(beginTime.minutes());
          }
        }
        if (beginDate.isAfter(date)) {
          endDate = beginDate.format('YYYY-MM-DD');
          endTime = beginDate.format('hh:mm A');
        }
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          endDate,
          endTime,
        },
        changed: true,
      };
    });

  onChangeEndTime = (newDate) =>
    this.setState((state) => {
      let date = moment(newDate, 'hh:mm A');
      let endDate = moment().format('YYYY-MM-DD');
      let endTime;
      if (state.profile.endDate) {
        const endDateMoment = moment(state.profile.endDate, 'YYYY-MM-DD');
        if (endDateMoment.isValid()) {
          date
            .year(endDateMoment.year())
            .month(endDateMoment.month())
            .date(endDateMoment.date());
          endDate = endDateMoment.format('YYYY-MM-DD');
        }
      }
      endTime = date.format('hh:mm A');
      if (state.profile.beginDate) {
        const beginDate = moment(state.profile.beginDate, 'YYYY-MM-DD');
        if (state.profile.beginTime) {
          const beginTime = moment(this.state.profile.beginTime, 'hh:mm A');
          if (beginTime.isValid()) {
            beginDate.hours(beginTime.hours()).minutes(beginTime.minutes());
          }
        }
        if (beginDate.isAfter(date)) {
          endDate = beginDate.format('YYYY-MM-DD');
          endTime = beginDate.format('hh:mm A');
        }
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          endDate,
          endTime,
        },
        changed: true,
      };
    });

  onSelectFleet = (selectedKeys) =>
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        fleets: [...selectedKeys],
      },
      changed: true,
    }));

  renderProfileMenu = () => {
    if (
      this.props.status === NetworkStatus.STARTED ||
      this.props.createProfileStatus === NetworkStatus.STARTED ||
      this.props.updateProfileStatus === NetworkStatus.STARTED
    ) {
      return null;
    }
    if (this.state.selectedCategory === 'Fleets') {
      return (
        <React.Fragment>
          {assignMenuStructure.map(this.renderMenuItem)}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.props.accessibleMenuItems.map(this.renderMenuItem)}
      </React.Fragment>
    );
  };

  renderMenuItem = (item) => {
    const hasSubItems = item.sub && item.sub.length;
    const itemClass = selectForCurrentBrand({
      original: 'profile-menu__item',
      incube: 'profile-menu__item--incube',
      janam: 'profile-menu__item--janam',
    });
    const activeItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--active',
      incube: 'profile-menu__item--incube--active',
      janam: 'profile-menu__item--janam--active',
    });
    const intermediateItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--indeterminate',
      incube: 'profile-menu__item--incube--indeterminate',
      janam: 'profile-menu__item--janam--indeterminate',
    });
    const itemClasses = ClassNames(itemClass, {
      [activeItemClass]: this.state.selectedCategory === item.key,
      [intermediateItemClass]:
        hasSubItems &&
        item.sub.some((subItem) => subItem.key === this.state.selectedCategory),
    });
    const clickHandler = (e) =>
      this.selectCategory(hasSubItems ? item.sub[0].key : item.key);
    return (
      <Fragment key={item.key}>
        <h4
          id={hasSubItems ? `${item.key}` : undefined}
          className={itemClasses}
          onClick={clickHandler}
        >
          {item.label ? item.label : item.key}
        </h4>
        {hasSubItems && (
          <div className={'profile-menu__item--sub'}>
            <UncontrolledCollapse toggler={`#${item.key}`}>
              <div className={'profile-menu__items-list'}>
                {item.sub.map((item) => this.renderMenuItem(item))}
              </div>
            </UncontrolledCollapse>
          </div>
        )}
      </Fragment>
    );
  };

  /** TODO: replace with the react router switch **/
  renderProfileContent = () => {
    if (
      this.props.status === NetworkStatus.STARTED ||
      this.props.createProfileStatus === NetworkStatus.STARTED ||
      this.props.updateProfileStatus === NetworkStatus.STARTED
    ) {
      return <Throbber />;
    }

    switch (this.state.selectedCategory) {
      case PROFILE_CONFIG_CONSTANTS.PROFILE_DETAILS: {
        const { profile = {} } = this.state;
        return (
          <ProfileDetail
            profile={profile}
            onChange={this.onChangeProfileProperty}
            fleetNames={profile.fleetNames}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.AUTHENTICATION: {
        return (
          <Authentication
            policy={this.state.profile.policies.authentication || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.FEATURE_CONTROL: {
        return (
          <FeatureControlComponent
            policy={this.state.profile.policies.featureControl || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.APP_MANAGEMENT: {
        return (
          <AppManagement
            policy={this.state.profile.policies.apps || []}
            policies={this.state.profile.policies}
            applications={this.props.applicationsFullList}
            applicationsFullList={this.props.applicationsFullList}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.BLACKLIST: {
        return (
          <Blacklist
            policy={this.state.profile.policies.applist || []}
            applications={this.props.applicationsFullList}
            applicationsFullList={this.props.applicationsFullList}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.CERTIFICATES: {
        return (
          <Certificates
            policy={this.state.profile.policies.certificates || []}
            certificates={this.props.certificates || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.APN: {
        return <p>{I18n.t('profiles.unavailableFeatureMessage')}</p>;
      }
      case PROFILE_CONFIG_CONSTANTS.WIFI: {
        return (
          <WiFi
            policy={this.state.profile.policies.wifi || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            certificates={this.props.certificates || []}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }

      case PROFILE_CONFIG_CONSTANTS.WEB_FILTER: {
        return (
          <WebFilter
            policy={this.state.profile.policies.webFilter || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }

      case PROFILE_CONFIG_CONSTANTS.LOCATION_SERVICES: {
        return (
          <LocationServices
            policy={this.state.profile.policies.location || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case 'Data Collection': {
        /** TODO: just a stub, refactor this code recursive**/
        return null;
      }
      case PROFILE_CONFIG_CONSTANTS.FIRMWARE_UPDATE: {
        /** TODO: just a stub, implement feature**/
        return <FirmwareUpdate />;
      }
      case PROFILE_CONFIG_CONSTANTS.KIOSK_MODE: {
        const { isEditingMode } = this.state;
        const profile = isEditingMode ? this.state.profile : this.props.profile;
        const policy = profile.policies?.kiosk || [];
        return (
          <Kiosk
            policy={policy}
            profile={profile || {}}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            showBuilder={this.showBuilder}
            showHtmlKioskBuilder={this.showHtmlKioskBuilder}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.FILE_SYNC: {
        return (
          <FileSync
            policy={this.state.profile.policies.fileSync || []}
            onChangePolicyProperty={this.onChangePolicyProperty}
            onChange={this.onChangePolicyDetails}
            files={this.props.files || []}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.FLEETS: {
        if (isLoadingStatusStarted(this.props.fleetsStatus)) {
          return <Throbber />;
        }
        return (
          <FleetsTree
            fleets={this.props.fleets || []}
            onSelect={this.onSelectFleet}
            defaultSelectedKeys={this.state.profile.fleets || []}
            selectedKeys={this.state.profile.fleets || []}
            multiple
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.GEO_FENCING: {
        return (
          <Geofencing
            policy={this.state.profile.policies.geofencing || []}
            fleets={this.props.fleets}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.PASSWORD: {
        return (
          <Password
            policy={this.state.profile.policies.password || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.SECURITY: {
        return (
          <Security
            policy={this.state.profile.policies.security || []}
            onChangePolicyProperty={this.onChangePolicyProperty}
            onChange={this.onChangePolicyDetails}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.SETTINGS: {
        return (
          <Settings
            policy={this.state.profile.policies.settings || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            timezones={this.props.timezones || []}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      case PROFILE_CONFIG_CONSTANTS.WALLPAPER_SETTINGS: {
        return (
          <WallpaperSettings
            policy={this.state.profile.policies.wallpaperSettings || []}
            onChange={this.onChangePolicyDetails}
            onChangePolicyProperty={this.onChangePolicyProperty}
            files={this.props.files}
            isEditingMode={this.confirmProfileCreationOrProfileEditingModeAndEditAccess()}
          />
        );
      }
      default:
        return null;
    }
  };

  showInput = (e) => {
    this.setState({
      isEditingMode: true,
    });
  };

  onChangeRename = ({ target: { value } }) =>
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        name: value,
      },
      changed: true,
    }));

  onRenameKeyup = (e) => {
    if (e.keyCode === 27) {
      // Esc key
      this.resetRenameInput();
    }
  };

  resetRenameInput = () => {
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        name: this.props.profile?.name ?? '',
      },
    }));
  };

  selectCategory = (selectedCategory) => {
    this.setState({ selectedCategory });
  };

  onChangeProfileProperty = (property, value) => {
    this.setState((prevState) => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        [property]: value,
      },
      changed: true,
    }));
  };

  onSelectAllDevices = ({ target: { checked } }) => {
    this.setState((prevState) => {
      let currentProfileDevices =
        prevState.profile.devices && prevState.profile.devices.length
          ? prevState.profile.devices
          : [];
      if (checked) {
        return {
          ...prevState,
          profile: {
            ...prevState.profile,
            devices: [
              ...currentProfileDevices,
              ...this.props.fleetDevices.map((device) => device.id),
            ],
          },
          changed: true,
        };
      } else {
        return {
          ...prevState,
          profile: {
            ...prevState.profile,
            devices: currentProfileDevices.filter(
              (id) =>
                !this.props.fleetDevices.some((device) => device.id === id)
            ),
          },
          changed: true,
        };
      }
    });
  };

  onSelectDevice = ({ target: { checked } }, device) => {
    this.setState((prevState) => {
      let devices =
        prevState.profile.devices && prevState.profile.devices.length
          ? prevState.profile.devices
          : [];
      if (checked) {
        return {
          ...prevState,
          profile: {
            ...prevState.profile,
            devices: [...devices, device.id],
          },
          changed: true,
        };
      } else {
        return {
          ...prevState,
          profile: {
            ...prevState.profile,
            devices: devices.filter((id) => id !== device.id),
          },
          changed: true,
        };
      }
    });
  };

  onChangePolicyDetails = (key, index, value) => {
    this.setState((prevState) => {
      let policies = cloneDeep({ ...prevState.profile.policies });
      if (value !== null) {
        policies[key][index] = value;
      } else if (policies[key] && policies[key].length) {
        policies[key].splice(index, 1);
      }
      return {
        ...prevState,
        profile: {
          ...prevState.profile,
          policies,
        },
        changed: true,
      };
    });
  };

  onChangePolicyProperty = ({
    key,
    index,
    propertyName,
    value,
    initialPolicy = {},
    initialSettings = {},
  }) => {
    this.setState((prevState) => {
      let policies = cloneDeep({ ...prevState.profile.policies });
      if (value !== null && policies[key][index]) {
        policies[key][index][propertyName] = value;
      } else {
        policies[key][0] = {
          ...initialPolicy,
          settings: {
            ...initialSettings,
          },
          [propertyName]: value,
        };
      }
      return {
        profile: {
          ...prevState.profile,
          policies,
        },
        changed: true,
      };
    });
  };

  onAssign = (e) => {
    // TODO: add already loaded checks
    if (this.state.selected === 'Fleets') {
      this.setState(
        { selectedCategory: this.props.accessibleMenuItems[0].key },
        this.onSave
      );
    } else {
      this.props.loadAllFleets();
      this.setState({ selectedCategory: 'Fleets' });
    }
  };

  undoChanges = () => {
    this.setState({
      profile: this.initializeProfile(this.props),
      changed: false,
    });
  };

  onSave = () => {
    let analyticsAction;
    // TODO: refactor with router match params instead of state values
    if (this.state.profile.id) {
      analyticsAction = AnalyticsProfileConstants.PROFILE_V1_UPDATE;
      this.props.updateProfile(this.state.profile, this.props.companyId);
    } else {
      analyticsAction = AnalyticsProfileConstants.PROFILE_V1_CREATION;
      this.props.createProfile(this.state.profile, this.props.companyId);
    }
    AnalyticsService.event({
      category: AnalyticsCategory.PROFILE,
      action: analyticsAction,
      transport: AnalyticsService.eventTransportMechanism.BEACON,
    });
    this.setState({ changed: false });
  };

  onBack = () => {
    const { isEditingMode, selectedCategory } = this.state;
    if (this.state.selectedCategory === PROFILE_CONFIG_CONSTANTS.FLEETS) {
      this.selectCategory(this.props.accessibleMenuItems[0].key);
    } else if (
      selectedCategory === PROFILE_CONFIG_CONSTANTS.KIOSK_BUILDER ||
      selectedCategory === PROFILE_CONFIG_CONSTANTS.HTML_KIOSK_BUILDER
    ) {
      this.selectCategory(PROFILE_CONFIG_CONSTANTS.KIOSK_MODE);
    } else if (isEditingMode && !this.isProfileCreation) {
      this.setState({ isEditingMode: false });
    } else {
      history.goBack();
    }
  };

  showBuilder = () =>
    this.selectCategory(PROFILE_CONFIG_CONSTANTS.KIOSK_BUILDER);

  showHtmlKioskBuilder = () =>
    this.selectCategory(PROFILE_CONFIG_CONSTANTS.HTML_KIOSK_BUILDER);

  confirmProfileCreationOrProfileEditingModeAndEditAccess = () => {
    const { hasAdminPrivileges = false, accessibleMenuItems } = this.props;
    const { isEditingMode } = this.state;
    const isProfileCreationOrEditMode = this.isProfileCreation || isEditingMode;
    const { readWrite: hasReadWriteAccess } =
      accessibleMenuItems.find(
        ({ key }) => key === this.state.selectedCategory
      ) || {};
    const doesUserHaveAccess = hasAdminPrivileges || hasReadWriteAccess;
    return isProfileCreationOrEditMode && doesUserHaveAccess;
  };
}

const mapStateToProps = (state) => {
  const {
    account: { globalPermissionsForUserStatus },
  } = state;
  const globalPermissionsForUser = globalPermissionsForUserSelector(state);
  const hasAdminPrivileges = UserHelpers.hasAdminPrivileges(state);

  const accessibleMenuItems =
    globalPermissionsForUserStatus !== NetworkStatus.DONE
      ? defaultProfileMenuStructure
      : // : getAccessibleMenuItems(profileMenuStructure, globalPermissionsMock);
        getAccessibleMenuItems(
          profileMenuStructure,
          globalPermissionsForUser,
          hasAdminPrivileges
        );

  const {
    data: fleets,
    status: fleetsStatus,
    error: fleetsError,
  } = fleetsV2Selector(state);

  const {
    applicationsFullList,
    applicationsFullListError,
    applicationsFullListStatus,
  } = applicationsSelector(state);

  return {
    profile: state.profile.data,
    status: state.profile.status,
    error: state.profile.error,
    companyId: companyIdSelector(state),
    fleetsLoading: state.fleets.loading,
    // fleets: state.fleets.data,
    // fleetsLoading: state.fleets.loading,
    fleets,
    fleetsStatus,
    fleetsError,
    fleetDevices: state.profile.fleetDevices,
    fleetDevicesLoading: state.profile.fleetDevicesLoading,
    fleetDevicesError: state.profile.fleetDevicesError,
    files: state.files.data,
    filesLoading: state.files.loading,
    applicationsFullList,
    applicationsFullListError,
    applicationsFullListStatus,
    createProfileStatus: state.profile.createProfileStatus,
    createProfileError: state.profile.createProfileError,
    updateProfileStatus: state.profile.updateProfileStatus,
    updateProfileError: state.profile.updateProfileError,
    certificates: state.certificates.data,
    certificatesLoadingStatus: state.certificates.loading,
    timezones: state.settings.data.timezones,
    accessibleMenuItems,
    globalPermissionsForUserStatus,
    globalPermissionsForUser,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadProfile: (profileId) => dispatch(loadProfile(profileId)),
    loadCertificates: (companyId) => dispatch(loadCertificates(companyId)),
    resetProfile: () => dispatch(profileReset()),
    createProfile: (profile, companyId) =>
      dispatch(createProfile(profile, companyId)),
    createProfileReset: () => dispatch(createProfileReset()),
    updateProfile: (profile, companyId) =>
      dispatch(updateProfile(profile, companyId)),
    updateProfileReset: () => dispatch(updateProfileReset()),
    loadAllFleets: () => dispatch(loadAllFleetsV2()),
    loadAllFiles: (companyId) => dispatch(loadAllFiles(companyId)),
    loadProfileApplications: (id) => dispatch(loadProfileApplications(id)),
    loadApplicationsFullList: () => dispatch(loadApplicationsFullList()),
    loadGlobalPermissionsForUser: () =>
      dispatch(loadGlobalPermissionsForUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
