import React from 'react';
import { selectForCurrentBrand } from '../../common/helpers';

class Tool extends React.Component {
  render() {
    const { title, onClick, className, children, hidden } = this.props;
    if (hidden) {
      return null;
    }
    let itemClass = selectForCurrentBrand({
      original: 'toolbar-action-icon',
      incube: 'toolbar-action-icon toolbar-action-icon--incube',
      janam: 'toolbar-action-icon toolbar-action-icon--janam',
    });
    const resultClass = className ? `${itemClass} ${className}` : itemClass;

    return (
      <div title={title} onClick={onClick} className={resultClass}>
        {children}
      </div>
    );
  }
}

export default Tool;
