/* eslint-disable */
var io = require('socket.io-client');
export const Springdel = (function (e) {
  var n = {};
  function t(r) {
    if (n[r]) return n[r].exports;
    var i = (n[r] = { i: r, l: !1, exports: {} });
    return e[r].call(i.exports, i, i.exports, t), (i.l = !0), i.exports;
  }
  return (
    (t.m = e),
    (t.c = n),
    (t.d = function (e, n, r) {
      t.o(e, n) || Object.defineProperty(e, n, { enumerable: !0, get: r });
    }),
    (t.r = function (e) {
      'undefined' != typeof Symbol &&
        Symbol.toStringTag &&
        Object.defineProperty(e, Symbol.toStringTag, { value: 'Module' }),
        Object.defineProperty(e, '__esModule', { value: !0 });
    }),
    (t.t = function (e, n) {
      if ((1 & n && (e = t(e)), 8 & n)) return e;
      if (4 & n && 'object' == typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (
        (t.r(r),
        Object.defineProperty(r, 'default', { enumerable: !0, value: e }),
        2 & n && 'string' != typeof e)
      )
        for (var i in e)
          t.d(
            r,
            i,
            function (n) {
              return e[n];
            }.bind(null, i)
          );
      return r;
    }),
    (t.n = function (e) {
      var n =
        e && e.__esModule
          ? function () {
              return e.default;
            }
          : function () {
              return e;
            };
      return t.d(n, 'a', n), n;
    }),
    (t.o = function (e, n) {
      return Object.prototype.hasOwnProperty.call(e, n);
    }),
    (t.p = ''),
    t((t.s = 6))
  );
})([
  function (e, n, t) {
    Object.defineProperty(n, '__esModule', { value: !0 });
    n.Logger = class {
      static e(e, ...n) {
        console.error(e, n);
      }
      static log(e, ...n) {
        console.log(e, n);
      }
      static debug(e, ...n) {
        console.log(e, n);
      }
    };
  },
  function (e, n, t) {
    Object.defineProperty(n, '__esModule', { value: !0 }), t(4), t(5), t(6);
    const r = t(5),
      i = t(2),
      s = t(3);
    n.SpringdelRoom = class {
      constructor(e, n) {
        (this.peers = []),
          (this.onConnectedListeners = new i.Observer()),
          (this.onDisconnectedListeners = new i.Observer()),
          (this.onPeerJoinedListeners = new i.Observer()),
          (this.onPeerLeaveListeners = new i.Observer());
        var t = this;
        (this.room = new r.Room(e, n)),
          (this.room.onConnectedCallback = () => {
            t.onConnectedListeners.emit();
          }),
          (this.room.onDisconnectedCallback = () => {
            t.onDisconnectedListeners.emit();
          }),
          (this.room.onPeerJoinedCallback = (e) => {
            var n = new s.SpringdelPeer(e);
            t.peers.push(n), t.onPeerJoinedListeners.emit(n);
          }),
          (this.room.onPeerLeaveRoomCallback = (e) => {
            for (let n = 0; n < t.peers.length; n++) {
              const r = t.peers[n];
              if (r.id == e.id)
                return (
                  t.peers.splice(n, 1), void t.onPeerLeaveListeners.emit(r)
                );
            }
          });
      }
      getPeerById(e) {
        for (let n = 0; n < this.peers.length; n++) {
          const t = this.peers[n];
          if (t.id == e) return t;
        }
        return null;
      }
      getPeers() {
        return [...this.peers];
      }
      disconnect() {
        this.room.disconnect();
      }
    };
  },
  function (e, n, t) {
    Object.defineProperty(n, '__esModule', { value: !0 });
    n.Observer = class {
      constructor() {
        this.listeners = [];
      }
      addListener(e) {
        this.listeners.push(e);
      }
      emit(e) {
        this.listeners.forEach((n) => {
          n(e);
        });
      }
    };
  },
  function (e, n, t) {
    Object.defineProperty(n, '__esModule', { value: !0 });
    const r = t(2),
      i = t(0);
    class s {
      constructor(e) {
        (this.notifyClosedChannels = !1),
          (this.onReceiveMessageListeners = new r.Observer()),
          (this.onReceiveDataListeners = new r.Observer()),
          (this.onOpenedChannelsListeners = new r.Observer()),
          (this.onClosedChannelsListeners = new r.Observer()),
          (this.onReceiveChannel = new r.Observer()),
          (this.pingPongTimeout = -1),
          (this.pingPongs = new Map()),
          (this.pingId = -1),
          (this.ping = -1),
          (this.lastPingId = -1),
          (this.peer = e),
          (this.id = e.id);
        var n = this;
        (e.onReceivedChannelCallback = (e) => {
          switch (e.getLabel()) {
            case s.DEFAULT_DATA_CHANNEL_NAME:
              (n.receiveDataChannel = e),
                e.onClosedCallback.addListener(() => {
                  n.onClosedChannel();
                }),
                e.onMessageCallback.addListener((e) => {
                  n.onReceiveData(e);
                });
              break;
            case s.DEFAULT_MESSAGES_CHANNEL_NAME:
              (n.receiveMessagesChannel = e),
                e.onClosedCallback.addListener(() => {
                  n.onClosedChannel();
                }),
                e.onMessageCallback.addListener((e) => {
                  n.onReceiveMessage(e);
                });
              break;
            case s.DEFAULT_PING_PONG_CHANNEL_NAME:
              (n.receivePingPongChannel = e),
                e.onMessageCallback.addListener((e) => {
                  n.handlePingPongMessage(e);
                });
              break;
            default:
              i.Logger.e('Undefined channel: ' + e.getLabel()),
                n.onReceiveChannel.emit(e);
          }
        }),
          (this.sendDataChannel = e.createDataChannel(
            s.DEFAULT_DATA_CHANNEL_NAME
          )),
          this.sendDataChannel.onOpenedCallback.addListener(() => {
            n.onOpenedChannel();
          }),
          this.sendDataChannel.onClosedCallback.addListener(() => {
            n.onClosedChannel();
          }),
          this.sendDataChannel.onMessageCallback.addListener((e) => {
            n.onReceiveData(e);
          }),
          (this.sendMessagesChannel = e.createDataChannel(
            s.DEFAULT_MESSAGES_CHANNEL_NAME
          )),
          this.sendMessagesChannel.onOpenedCallback.addListener(() => {
            n.onOpenedChannel();
          }),
          this.sendMessagesChannel.onClosedCallback.addListener(() => {
            n.onClosedChannel();
          }),
          this.sendMessagesChannel.onMessageCallback.addListener((e) => {
            n.onReceiveMessage(e);
          }),
          (this.sendPingPongChannel = e.createDataChannel(
            s.DEFAULT_PING_PONG_CHANNEL_NAME
          )),
          this.sendPingPongChannel.onOpenedCallback.addListener(() => {
            n.startSendingPing();
          }),
          this.sendPingPongChannel.onClosedCallback.addListener(() => {
            n.stopSendingPing();
          });
      }
      startSendingPing() {
        var e = this;
        this.pingPongTimeout = setTimeout(() => {
          e.sendPing();
        }, 100);
      }
      stopSendingPing() {
        -1 != this.pingPongTimeout &&
          (clearTimeout(this.pingPongTimeout), (this.pingPongTimeout = -1));
      }
      sendPing() {
        var e = this,
          n = new Date().getTime(),
          t = ++this.pingId;
        this.pingPongs.set(t, n);
        var r = { type: 'ping', id: t };
        this.sendPingPongChannel.send(JSON.stringify(r)),
          (this.pingPongTimeout = setTimeout(() => {
            e.sendPing();
          }, 100));
      }
      getPing() {
        var e = this.pingPongs.get(this.lastPingId + 1);
        if (e) {
          var n = new Date().getTime() - e;
          if (n > this.ping) return n;
        }
        return this.ping;
      }
      sendPong(e) {
        var n = { type: 'pong', id: e };
        this.sendPingPongChannel.send(JSON.stringify(n));
      }
      handlePingPongMessage(e) {
        try {
          var n = JSON.parse(e),
            t = n.type,
            r = n.id;
          if (!t || void 0 === r)
            return void i.Logger.e(
              `Ping pong handle error: type = ${t}, id = ${r}`
            );
          switch (t) {
            case 'ping':
              this.sendPong(r);
              break;
            case 'pong':
              var s = this.pingPongs.get(r);
              if (!s)
                return void i.Logger.e(
                  'Handle pingPong message',
                  `Received pong of undefined id: ${r}`
                );
              var o = new Date().getTime() - s;
              (this.ping = o), (this.lastPingId = r);
          }
        } catch (e) {
          i.Logger.e('Handle pingPong message error', e);
        }
      }
      onOpenedChannel() {
        'open' == this.sendDataChannel.getState() &&
          'open' == this.sendMessagesChannel.getState() &&
          this.onOpenedChannelsListeners.emit();
      }
      onReceiveMessage(e) {
        this.onReceiveMessageListeners.emit(e);
      }
      onClosedChannel() {
        this.notifyClosedChannels ||
          (this.onClosedChannelsListeners.emit(),
          (this.notifyClosedChannels = !0));
      }
      onReceiveData(e) {
        this.onReceiveDataListeners.emit(e);
      }
      sendData(e) {
        return this.sendDataChannel.send(e);
      }
      sendMessage(e) {
        return this.sendMessagesChannel.send(e);
      }
      createDataChannel(e) {
        return this.peer.createDataChannel(e);
      }
    }
    (s.DEFAULT_DATA_CHANNEL_NAME = 'defaultDataChannel'),
      (s.DEFAULT_MESSAGES_CHANNEL_NAME = 'sendMessagesChannel'),
      (s.DEFAULT_PING_PONG_CHANNEL_NAME = 'pingPongChannel'),
      (n.SpringdelPeer = s);
  },
  function (e, n, t) {
    var r =
      (this && this.__awaiter) ||
      function (e, n, t, r) {
        return new (t || (t = Promise))(function (i, s) {
          function o(e) {
            try {
              c(r.next(e));
            } catch (e) {
              s(e);
            }
          }
          function a(e) {
            try {
              c(r.throw(e));
            } catch (e) {
              s(e);
            }
          }
          function c(e) {
            e.done
              ? i(e.value)
              : new t(function (n) {
                  n(e.value);
                }).then(o, a);
          }
          c((r = r.apply(e, n || [])).next());
        });
      };
    Object.defineProperty(n, '__esModule', { value: !0 });
    const i = t(7),
      s = t(0);
    n.Peer = class {
      constructor(e, n) {
        (this.id = e), (this.room = n);
      }
      createConnection() {
        const e = { iceServers: [] };
        var n = this.getIceServers();
        if (
          (n && n.length > 0 && (e.iceServers = n),
          (this.connection = new RTCPeerConnection(e)),
          !this.connection)
        ) {
          var t = new t('RTC connection failed');
          throw (s.Logger.log('RTC connection failed', t), t);
        }
        var r = this;
        (this.connection.ondatachannel = (e) => {
          r.onReceivedDataChannel(e.channel);
        }),
          (this.connection.onicecandidate = (e) => {
            r.onIceCandidate(e.candidate);
          });
      }
      createOffer(e, n) {
        return r(this, void 0, void 0, function* () {
          try {
            var t = yield this.connection.createOffer();
            this.setLocalDescription(
              t,
              () => {
                e(t);
              },
              (e) => {
                n(e);
              }
            );
          } catch (e) {
            n(e);
          }
        });
      }
      createAnswer(e, n) {
        return r(this, void 0, void 0, function* () {
          try {
            var t = yield this.connection.createAnswer();
            this.setLocalDescription(
              t,
              () => {
                e(t);
              },
              (e) => {
                n(e);
              }
            );
          } catch (e) {
            n(e);
          }
        });
      }
      setLocalDescription(e, n, t) {
        return r(this, void 0, void 0, function* () {
          try {
            yield this.connection.setLocalDescription(e), n();
          } catch (e) {
            t(e);
          }
        });
      }
      setRemoteDescription(e, n, t) {
        return r(this, void 0, void 0, function* () {
          try {
            yield this.connection.setRemoteDescription(e), n();
          } catch (e) {
            t(e);
          }
        });
      }
      setRemoteIceCandidate(e, n, t) {
        return r(this, void 0, void 0, function* () {
          try {
            yield this.connection.addIceCandidate(e), n();
          } catch (e) {
            t(e);
          }
        });
      }
      createDataChannel(e) {
        return new i.Channel(this.connection, e);
      }
      onReceivedDataChannel(e) {
        var n = new i.Channel(e);
        this.onReceivedChannelCallback && this.onReceivedChannelCallback(n);
      }
      onIceCandidate(e) {
        null != e &&
          this.onIceCandidateCallback &&
          this.onIceCandidateCallback(e);
      }
      getIceServers() {
        return this.room.getIceServersList();
      }
      disconnect() {
        this.connection.close();
      }
    };
  },
  function (e, n, t) {
    Object.defineProperty(n, '__esModule', { value: !0 });
    const r = t(4),
      i = t(0);
    n.Room = class {
      constructor(e, n) {
        (this.name = e), (this.peers = []), (this.iceServers = []);
        var t = io.connect(n, {
          path: '/rc/socket.io',
          transports: ['websocket', 'polling'],
          secure: !0,
        });
        this.socket = t;
        var r = this;
        t.on('connect', function () {
          r.onConnected();
        }),
          t.on('disconnect', function () {
            r.onDisconnected();
          }),
          t.on('startOffer', function (e) {
            i.Logger.log('---!!! START OFFER !!!---');
            var n,
              t = 'StartOffer';
            try {
              n = JSON.parse(e);
            } catch (e) {
              return void i.Logger.e(t, 'Parse message error', e);
            }
            n.peerId
              ? r.getPeerById(n.peerId)
                ? i.Logger.e(t, `Peer ${n.peerId} is already exists`)
                : r.initPeerAndCreateOffer(n.peerId)
              : i.Logger.e(t, 'PeerId is not found in message');
          }),
          t.on('offerRequest', function (e) {
            i.Logger.log('---!!! ANSWER OFFER !!!---');
            var n,
              t = 'OfferRequest';
            try {
              n = JSON.parse(e);
            } catch (e) {
              return void i.Logger.e('Parse message error', e);
            }
            n.peerId
              ? n.description
                ? r.getPeerById(n.peerId)
                  ? i.Logger.e(t, `Peer ${n.peerId} is exists`)
                  : r.initPeerAndCreateAnswer(n.peerId, n.description)
                : i.Logger.e(t, 'Description is not found in message')
              : i.Logger.e(t, 'PeerId is not found in message');
          }),
          t.on('answer', function (e) {
            i.Logger.log('---!!! RECEIVED ANSWER !!!---');
            var n,
              t = 'Answer';
            try {
              n = JSON.parse(e);
            } catch (e) {
              return void i.Logger.e(t, 'Parse message error', e);
            }
            if (n.peerId)
              if (n.description) {
                var s = r.getPeerById(n.peerId);
                s
                  ? r.saveAnswer(s, n.description)
                  : i.Logger.log(t, `Peer ${n.peerId} not found`);
              } else i.Logger.e(t, 'Description is not found in message');
            else i.Logger.e(t, 'PeerId is not found in message');
          }),
          t.on('iceCandidate', function (e) {
            i.Logger.log('---!!! ICE CANDIDATE !!!---');
            var n,
              t = 'IceCandidate';
            try {
              n = JSON.parse(e);
            } catch (e) {
              return void i.Logger.e(t, 'Parse message error', e);
            }
            if (n.peerId)
              if (n.candidate) {
                var s = r.getPeerById(n.peerId);
                if (s) {
                  console.log('Received remote ice candidate', n.candidate);
                  var o = new RTCIceCandidate({
                    candidate: n.candidate.candidate,
                    sdpMid: n.candidate.sdpMid,
                    sdpMLineIndex: n.candidate.sdpMLineIndex,
                  });
                  r.saveIceCandidate(s, o);
                } else i.Logger.log(t, `Peer ${n.peerId} not found`);
              } else i.Logger.e(t, 'Candidate is not found in message');
            else i.Logger.e(t, 'PeerId is not found in message');
          }),
          t.on('onJoinedToRoom', function (e) {
            i.Logger.log('---!!! ON JOINED TO ROOM !!!---');
            var n,
              t = 'OnJoinedToRoom.';
            try {
              n = JSON.parse(e);
            } catch (e) {
              return void i.Logger.e(t, 'Parse message error', e);
            }
            n.myId
              ? (r.myId = n.myId)
              : i.Logger.e(t, 'myId is not found in message'),
              r.onJoinedToRoom();
          }),
          t.on('iceServers', function (e) {
            i.Logger.log('---!!! ON ICE SERVERS LIST !!!---');
            var n,
              t = 'IceServers';
            try {
              n = JSON.parse(e);
            } catch (e) {
              return void i.Logger.e(t, 'Parse message error', e);
            }
            n.iceServers
              ? ((r.iceServers = n.iceServers),
                i.Logger.log(`Added ${r.iceServers.length} iceServers`))
              : i.Logger.e(t, 'iceServers not found in message');
          }),
          t.on('onPeerLeaveRoom', function (e) {
            i.Logger.log('---!!! ON PEER LEAVE ROOM !!!---');
            var n,
              t = 'OnPeerLeaveRoom';
            try {
              n = JSON.parse(e);
            } catch (e) {
              return void i.Logger.e(t, 'Parse message error', e);
            }
            if (n.peerId) {
              var s = r.getPeerById(n.peerId);
              s
                ? (s.disconnect(), r.removePeer(s), r._onPeerLeaveRoom(s))
                : i.Logger.e(
                    t,
                    'Peer ' +
                      n.peerId +
                      ' is not exists (or already leave room)'
                  );
            } else i.Logger.e(t, 'PeerId is not found in message');
          });
      }
      removePeer(e) {
        for (let n = 0; n < this.peers.length; n++)
          if (this.peers[n] == e) return void this.peers.splice(n, 1);
      }
      saveIceCandidate(e, n) {
        e.setRemoteIceCandidate(
          n,
          () => {},
          (e) => {
            i.Logger.e('Set remote ice candidate error', e);
          }
        );
      }
      onJoinedToRoom() {
        this.onConnectedCallback && this.onConnectedCallback();
      }
      _onPeerJoined(e) {
        this.onPeerJoinedCallback && this.onPeerJoinedCallback(e);
      }
      _onPeerLeaveRoom(e) {
        this.onPeerLeaveRoomCallback && this.onPeerLeaveRoomCallback(e);
      }
      getPeerById(e) {
        for (var n = 0; n < this.peers.length; n++) {
          var t = this.peers[n];
          if (t.id == e) return t;
        }
        return null;
      }
      initPeer(e) {
        var n = new r.Peer(e, this);
        this.peers.push(n);
        try {
          n.createConnection();
        } catch (e) {
          return i.Logger.log('Init peer', e), n;
        }
        var t = this;
        return (
          (n.onIceCandidateCallback = (e) => {
            t.sendIceCandidate(n, e);
          }),
          this._onPeerJoined(n),
          n
        );
      }
      initPeerAndCreateOffer(e) {
        var n = this.initPeer(e);
        this.createOffer(n);
      }
      initPeerAndCreateAnswer(e, n) {
        var t = this.initPeer(e);
        this.createAnswer(t, n);
      }
      saveAnswer(e, n) {
        e.setRemoteDescription(
          n,
          () => {},
          (e) => {
            i.Logger.e('Failed set remote description', e);
          }
        );
      }
      createOffer(e) {
        var n = this;
        e.createOffer(
          (t) => {
            n.sendCreatedOffer(e, t);
          },
          (e) => {
            i.Logger.log('WebRTC', 'Create offer error', e);
          }
        );
      }
      createAnswer(e, n) {
        var t = this;
        e.setRemoteDescription(
          n,
          () => {
            e.createAnswer(
              (n) => {
                t.sendAnswer(e, n);
              },
              (e) => {
                i.Logger.e('Create answer error', e);
              }
            );
          },
          (e) => {
            i.Logger.e('Create answer [setRemoteDescription] error', e);
          }
        );
      }
      sendIceCandidate(e, n) {
        var t = { peerId: e.id, candidate: n };
        this.socket.emit('iceCandidate', JSON.stringify(t));
      }
      sendCreatedOffer(e, n) {
        var t = { peerId: e.id, description: n };
        this.socket.emit('offerIsCreated', JSON.stringify(t));
      }
      sendAnswer(e, n) {
        i.Logger.log('Sending answered status');
        var t = { peerId: e.id, description: n };
        this.socket.emit('answer', JSON.stringify(t));
      }
      onConnected() {
        i.Logger.log('Connected!');
        var e = { roomName: this.name, peerName: 'Web Client', type: 'Admin' };
        this.socket.emit('connectToRoom', JSON.stringify(e));
      }
      onDisconnected() {
        this.onDisconnectedCallback && this.onDisconnectedCallback();
      }
      disconnect() {
        this.socket.disconnect();
      }
      getPeers() {
        return [...this.peers];
      }
      getIceServersList() {
        return [...this.iceServers];
      }
    };
  },
  function (e, n, t) {
    Object.defineProperty(n, '__esModule', { value: !0 }), t(1), t(3);
    const r = t(1);
    class i {
      static connectToRoom(e, n) {
        return (n = n || i.SERVER_URL), new r.SpringdelRoom(e, n);
      }
    }
    (i.SERVER_URL = 'http://178.128.232.13:3000'),
      (n.SpringdelServerConnection = i);
  },
  function (e, n, t) {
    Object.defineProperty(n, '__esModule', { value: !0 });
    const r = t(0),
      i = t(2),
      s = 65400;
    class o {
      constructor(e, n) {
        if (
          ((this.onOpenedCallback = new i.Observer()),
          (this.onClosedCallback = new i.Observer()),
          (this.onMessageCallback = new i.Observer()),
          (this.waitingBuffer = null),
          e)
        ) {
          if (Object.getPrototypeOf(e).send) this.channel = e;
          else {
            const t = { ordered: !0 };
            this.channel = e.createDataChannel(n, t);
          }
          this.init();
        }
      }
      isInitialized() {
        return !!this.channel;
      }
      setDataChannel(e) {
        (this.channel = e), this.init();
      }
      static arrayBufferToString(e) {
        return new TextDecoder().decode(new Uint8Array(e));
      }
      init() {
        var e = this;
        (this.channel.onmessage = (n) => {
          var t = n.data;
          if (
            ('string' == typeof t ||
              t instanceof String ||
              (t = o.arrayBufferToString(n.data)),
            null != e.waitingBuffer)
          ) {
            var r = e.waitingBuffer + t;
            e.waitingBuffer = r;
          }
          t.length <= s
            ? null == e.waitingBuffer
              ? e.onMessageCallback.emit(t)
              : (e.onMessageCallback.emit(e.waitingBuffer),
                (e.waitingBuffer = null))
            : null == e.waitingBuffer && (e.waitingBuffer = t);
        }),
          (this.channel.onclose = (n) => {
            e.onClosedCallback.emit();
          }),
          (this.channel.onopen = (n) => {
            e.onOpenedCallback.emit();
          });
      }
      close() {
        this.channel.close();
      }
      send(e) {
        if ('open' != this.channel.readyState)
          return (
            r.Logger.debug(
              'Channel ' +
                this.getLabel() +
                ' is not opened (' +
                this.channel.readyState +
                ')'
            ),
            !1
          );
        if (e.length <= s)
          try {
            return this.channel.send(e), !0;
          } catch (e) {
            return !1;
          }
        else e.length == s + 1 && (e += '\0');
        for (var n = e.length, t = n; t > 0; ) {
          var i = t <= s + 1 ? t : s + 1,
            o = e.slice(n - t, n - t + i);
          try {
            this.channel.send(o);
          } catch (e) {
            return !1;
          }
          t -= i;
        }
        return !0;
      }
      getLabel() {
        return this.channel.label;
      }
      getState() {
        return this.channel ? this.channel.readyState : 'connecting';
      }
    }
    n.Channel = o;
  },
]);
