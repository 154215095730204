export const serializeUser = (data) => {
  // TODO: add data from user.component here
  return {
    username: data.username,
    first_name: data.firstName || '',
    last_name: data.lastName || '',
    email: data.email,
    password: data.password,
    groupId: data.groupId,
    status: data.status,
    lastActive: data.lastActive,
    is_admin: typeof data.isAdmin === 'boolean' ? data.isAdmin : false,
    is_partner: typeof data.isPartner === 'boolean' ? data.isPartner : false,
  };
};

export const deserializeUser = (data) => {
  return {
    id: data.id,
    username: data.username,
    firstName: data.first_name || '',
    lastName: data.last_name || '',
    email: data.email,
    lastLogin: data.last_login,
    createdOn: data.created_on,
    createdBy: data.created_by,
    groupId: data.group_id || data.groupId,
    groupName: data.group_name,
    status: data.status,
    lastActive: data.lastActive,
    isAdmin: data.is_admin || false,
    isPartner: data.is_partner || false,
  };
};
