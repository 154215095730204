import { clientV2 } from '../../utils/client';
import services from '../../utils/services';
import { ProfileV2ActionTypes } from './profileV2.actionTypes';
import { deserializeProfile, serializeNewProfile } from './profile.serializer';
import { history } from '../../utils/history';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import { loadProfilesV2 } from '../profilesV2/profilesV2.thunk';
import { isEmpty, notEmpty } from '../../common/helpers';
import { I18n } from 'react-redux-i18n';
import { deserializeApplication } from '../application/application.serializer';
import { handleRequestResponse } from '../../common/utilities/errorHandling';
import PopupActions from '../popup/popup.actions';

export const loadProfileById = (profileId, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_STARTED,
    });
    const { data, status } = await clientV2.get(
      `${services.profileById}${profileId}`
    );
    if (status === 200) {
      let previouslyPublishedProfileFleets = {};
      if (data.draft && isEmpty(data.fleets)) {
        // previously assigned fleets requested for draft profiles because the backend service
        // does not return that list for draft profiles. This list is needed to make sure that the
        // user is able to see previously assigned fleets preselected when assigning fleets to profiles.
        const { data: draftFleets } = await clientV2.get(
          `${services.fleetsForPublishedProfile}${profileId}/`
        );
        previouslyPublishedProfileFleets = draftFleets;
      }
      const profileData = {
        ...deserializeProfile(data),
        ...(notEmpty(previouslyPublishedProfileFleets)
          ? {
              fleets: previouslyPublishedProfileFleets.map(({ id }) => id),
              fleetNames: previouslyPublishedProfileFleets.map(
                ({ name }) => name
              ),
            }
          : {}),
      };
      dispatch({
        type: ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_SUCCESS,
        payload: profileData,
      });
      onSuccess?.();
    } else {
      dispatch({
        type: ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_FAILED,
        payload: I18n.t('profiles.failedToLoadProfileById'),
      });
    }
  } catch (e) {}
};

export const loadProfileApps = (profileId) => async (dispatch) => {
  try {
    dispatch({
      type: ProfileV2ActionTypes.PROFILE_V2_LOAD_APPLICATIONS_STARTED,
    });
    const { status, data } = await clientV2.get(
      `${services.getProfileApps}${profileId}`
    );
    if (status === 200 && notEmpty(data)) {
      dispatch({
        type: ProfileV2ActionTypes.PROFILE_V2_LOAD_APPLICATIONS_SUCCESS,
        payload: data.map(deserializeApplication),
      });
    } else {
      dispatch({
        type: ProfileV2ActionTypes.PROFILE_V2_LOAD_APPLICATIONS_FAILED,
        payload: I18n.t('profiles.failedToLoadProfileApps'),
      });
    }
  } catch (e) {
    dispatch({
      type: ProfileV2ActionTypes.PROFILE_V2_LOAD_APPLICATIONS_FAILED,
      payload: I18n.t('profiles.failedToLoadProfileApps'),
    });
  }
};

export const unpublishProfile = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ProfileV2ActionTypes.UNPUBLISH_PROFILES_V2_STARTED,
    });
    const response = await clientV2.post(services.profileUnpublish, {
      id,
    });
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        history.push(`${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}`);
        dispatch(
          loadProfilesV2({
            isInitialLoad: true,
            loadFullList: true,
          })
        );
      },
      successTextCode: 'profiles.profileSuccessfullyUnpublished',
      defaultErrorMessageTextCode: 'profiles.failedToUnpublishProfile',
    });
  } catch (e) {}
};

export const createBrandNewProfile = (newProfile) => async (dispatch) => {
  try {
    dispatch({
      type: ProfileV2ActionTypes.CREATE_BRAND_NEW_PROFILE_V2_STARTED,
    });
    PopupActions.showLoader();
    const response = await clientV2.post(
      services.profilesCreateBrandNew,
      serializeNewProfile(newProfile)
    );
    PopupActions.hideLoader();
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        const profile = deserializeProfile(response.data);
        dispatch({
          type: ProfileV2ActionTypes.CREATE_BRAND_NEW_PROFILE_V2_SUCCESS,
          payload: profile,
        });
        // dispatch({
        //   type: ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_SUCCESS,
        //   payload: profile,
        // });
        history.push(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/${profile.id}`
        );
        dispatch(
          loadProfilesV2({
            isInitialLoad: true,
            loadFullList: true,
          })
        );
      },
      successTextCode: 'profiles.profileCreationSuccessText',
      onError: () => {
        dispatch({
          type: ProfileV2ActionTypes.CREATE_BRAND_NEW_PROFILE_V2_FAILED,
          payload: I18n.t('profiles.profileCreationDefaultErrorText'),
        });
      },
      defaultErrorMessageTextCode: 'profiles.profileCreationDefaultErrorText',
    });
  } catch (e) {
    dispatch({
      type: ProfileV2ActionTypes.CREATE_BRAND_NEW_PROFILE_V2_FAILED,
      payload: I18n.t('profiles.profileCreationDefaultErrorText'),
    });
  }
};

export const createBrandNewAndPublishProfile = (newProfile) => async (
  dispatch
) => {
  try {
    dispatch({
      type:
        ProfileV2ActionTypes.CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_STARTED,
    });
    PopupActions.showLoader();
    const response = await clientV2.post(
      services.profilesCreateBrandNewAndPublish,
      serializeNewProfile(newProfile)
    );
    PopupActions.hideLoader();
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        const profile = deserializeProfile(response.data);
        dispatch({
          type:
            ProfileV2ActionTypes.CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_SUCCESS,
          payload: profile,
        });
        // dispatch({
        //   type: ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_SUCCESS,
        //   payload: profile,
        // });
        history.push(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/${profile.id}`
        );
        dispatch(
          loadProfilesV2({
            isInitialLoad: true,
            loadFullList: true,
          })
        );
      },
      successTextCode: 'profiles.profileCreationAndPublishingSuccessText',
      onError: () => {
        dispatch({
          type:
            ProfileV2ActionTypes.CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_FAILED,
          payload: I18n.t(
            'profiles.profileCreationAndPublishingDefaultErrorText'
          ),
        });
      },
      defaultErrorMessageTextCode:
        'profiles.profileCreationAndPublishingDefaultErrorText',
    });
  } catch (e) {
    dispatch({
      type: ProfileV2ActionTypes.CREATE_BRAND_NEW_AND_PUBLISH_PROFILE_V2_FAILED,
      payload: I18n.t('profiles.profileCreationAndPublishingDefaultErrorText'),
    });
  }
};

export const saveAndPublishProfile = (newProfile) => async (dispatch) => {
  try {
    dispatch({
      type: ProfileV2ActionTypes.SAVE_AND_PUBLISH_PROFILE_STARTED,
    });
    PopupActions.showLoader();
    const response = await clientV2.post(services.saveAndPublish, {
      id: newProfile.id,
      ...serializeNewProfile(newProfile),
    });
    PopupActions.hideLoader();
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        const profile = deserializeProfile(response.data);
        dispatch({
          type: ProfileV2ActionTypes.SAVE_AND_PUBLISH_PROFILE_SUCCESS,
          payload: profile,
        });
        dispatch({
          type: ProfileV2ActionTypes.PROFILE_V2_LOAD_BY_ID_SUCCESS,
          payload: profile,
        });
        history.push(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/${profile.id}`
        );
        dispatch(
          loadProfilesV2({
            isInitialLoad: true,
            loadFullList: true,
          })
        );
      },
      successTextCode: 'profiles.saveAndPublishProfileSuccessText',
      onError: () => {
        dispatch({
          type: ProfileV2ActionTypes.SAVE_AND_PUBLISH_PROFILE_FAILED,
          payload: I18n.t('profiles.saveAndPublishProfileDefaultErrorText'),
        });
      },
      defaultErrorMessageTextCode:
        'profiles.saveAndPublishProfileDefaultErrorText',
    });
  } catch (e) {
    dispatch({
      type: ProfileV2ActionTypes.SAVE_AND_PUBLISH_PROFILE_FAILED,
      payload: I18n.t('profiles.saveAndPublishProfileDefaultErrorText'),
    });
  }
};

export const createNewProfileVersion = (newProfile) => async (dispatch) => {
  try {
    dispatch({
      type: ProfileV2ActionTypes.CREATE_NEW_PROFILE_VERSION_STARTED,
    });
    PopupActions.showLoader();
    const response = await clientV2.post(services.profilesCreateNewVersion, {
      id: newProfile.id,
      ...serializeNewProfile(newProfile),
    });
    PopupActions.hideLoader();
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        const profile = deserializeProfile(response.data);
        dispatch({
          type: ProfileV2ActionTypes.CREATE_NEW_PROFILE_VERSION_SUCCESS,
          payload: profile,
        });
        history.push(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/${profile.id}`
        );
        dispatch(
          loadProfilesV2({
            isInitialLoad: true,
            loadFullList: true,
          })
        );
      },
      successTextCode: 'profiles.profileNewVersionCreationSuccessText',
      onError: () => {
        dispatch({
          type: ProfileV2ActionTypes.CREATE_NEW_PROFILE_VERSION_FAILED,
          payload: I18n.t('profiles.profileNewVersionCreationDefaultErrorText'),
        });
      },
      defaultErrorMessageTextCode:
        'profiles.profileNewVersionCreationDefaultErrorText',
    });
  } catch (e) {
    dispatch({
      type: ProfileV2ActionTypes.CREATE_NEW_PROFILE_VERSION_FAILED,
      payload: I18n.t('profiles.profileNewVersionCreationDefaultErrorText'),
    });
  }
};

export const createNewVersionAndPublish = (newProfile) => async (dispatch) => {
  try {
    dispatch({
      type: ProfileV2ActionTypes.CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_STARTED,
    });
    PopupActions.showLoader();
    const response = await clientV2.post(
      services.profilesCreateNewVersionAndPublish,
      {
        id: newProfile.id,
        ...serializeNewProfile(newProfile),
      }
    );
    PopupActions.hideLoader();
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        const profile = deserializeProfile(response.data);
        dispatch({
          type:
            ProfileV2ActionTypes.CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_SUCCESS,
          payload: profile,
        });
        history.push(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/${profile.id}`
        );
        dispatch(
          loadProfilesV2({
            isInitialLoad: true,
            loadFullList: true,
          })
        );
      },
      successTextCode:
        'profiles.profileNewVersionCreationAndPublishingSuccessText',
      onError: () => {
        dispatch({
          type:
            ProfileV2ActionTypes.CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_FAILED,
          payload: I18n.t(
            'profiles.profileNewVersionCreationAndPublishingDefaultErrorText'
          ),
        });
      },
      defaultErrorMessageTextCode:
        'profiles.profileNewVersionCreationAndPublishingDefaultErrorText',
    });
  } catch (e) {
    dispatch({
      type: ProfileV2ActionTypes.CREATE_NEW_PROFILE_VERSION_AND_PUBLISH_FAILED,
      payload: I18n.t(
        'profiles.profileNewVersionCreationAndPublishingDefaultErrorText'
      ),
    });
  }
};

export const saveAsDraft = (draftProfile) => async (dispatch) => {
  try {
    dispatch({
      type: ProfileV2ActionTypes.SAVE_AS_DRAFT_STARTED,
    });
    PopupActions.showLoader();
    const isProfileUpdate = !!draftProfile.id;
    const response = await clientV2.post(services.saveProfileAsDraft, {
      ...(isProfileUpdate ? { id: draftProfile.id } : {}),
      ...serializeNewProfile(draftProfile),
    });
    PopupActions.hideLoader();
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        const profile = deserializeProfile(response.data);
        dispatch({
          type: ProfileV2ActionTypes.SAVE_AS_DRAFT_SUCCESS,
          payload: profile,
        });
        history.push(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL_V2}/${profile.id}`
        );
        !isProfileUpdate &&
          dispatch(
            loadProfilesV2({
              isInitialLoad: true,
              loadFullList: true,
            })
          );
      },
      successTextCode: 'profiles.profileSaveAsDraftSuccessText',
      onError: () => {
        dispatch({
          type: ProfileV2ActionTypes.SAVE_AS_DRAFT_FAILED,
          payload: I18n.t('profiles.profileSaveAsDraftDefaultErrorText'),
        });
      },
      defaultErrorMessageTextCode:
        'profiles.profileSaveAsDraftDefaultErrorText',
    });
  } catch (e) {
    dispatch({
      type: ProfileV2ActionTypes.SAVE_AS_DRAFT_FAILED,
      payload: I18n.t('profiles.profileSaveAsDraftDefaultErrorText'),
    });
  }
};

export const assignFleets = ({ id, fleets }) => async (dispatch) => {
  try {
    const response = await clientV2.post(services.assignFleets, {
      id,
      fleets,
    });
    handleRequestResponse({
      response,
      successStatusCode: 200,
      onSuccess: () => {
        dispatch(loadProfileById(id));
        dispatch(
          loadProfilesV2({
            isInitialLoad: true,
            loadFullList: true,
          })
        );
      },
      successTextCode: 'profiles.fleetsToProfileAssignmentSuccess',
      defaultErrorMessageTextCode: 'profiles.fleetsToProfileAssignmentFailure',
    });
  } catch (e) {}
};
