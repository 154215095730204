import React, { Fragment, Component } from 'react';
import { FormControl, FormGroup, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Button } from '../../components/button.component';
import { ReactComponent as Layers } from '../../assets/images/layers.svg';
import { AttributeType, NetworkStatus } from '../../common/constants';
import Dialog from '../../components/Dialog';
import { companyIdSelector } from '../login/login.selectors';
import moment from 'moment';
import { bytesToSize, isDataLoadingRequired } from '../../common/helpers';
import { history } from '../../utils/history';
import { resetApplication } from './application.actions';
import {
  loadApplication,
  loadApplicationDevices,
  updateApplication,
} from './application.thunk';
import { ApplicationsRoutes, RootRoutes } from '../../utils/routes';
import NotFound from '../../components/NotFound';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import {
  applicationRouteConstants,
  applicationsVersionsColumns,
} from './application.constants';
import { Throbber } from '../../components/Throbber';
import { loadProfiles } from '../profiles/profiles.thunk';
import ApplicationProfilesSelection from './components/applicationProfilesSelection.component';
import ApplicationSettings from './components/applicationSettings.component';
import { SectionDescription } from '../../components/sectionDescription.component';
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle.svg';
import { ReactComponent as CrestCircle } from '../../assets/images/crest-circle.svg';
import { GLOBAL_READ_READWRITE_PERMISSIONS } from '../../constants/globalPermissions';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { globalPermissionsForUserSelector } from '../../selectors/accountSelectors';
import { getAccessibleMenuItems } from '../../common/utilities/profile';
import UserHelpers from '../../common/utilities/user';
import { isAppManagementAllowed } from '../../common/utilities/applications';
import DataTable from '../../components/DataTable/DataTable.component';
import { applicationAttributes } from '../applications/applications.constants';
import ToolsPanel from '../../components/ToolsPanel';
import Tool from '../../components/ToolBar/Tool';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import { deleteApplications } from '../applications/applications.thunk';
import PopupActions from '../popup/popup.actions';
import { loadProfilesV2 } from '../profilesV2/profilesV2.thunk';

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'noWrap',
  },
  titleField: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: '20px',
    height: '32px',
    fontSize: '18px',
    padding: '5px',
    margin: '0',
  },
  detailField: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginBottom: '20px',
    paddingRight: '2rem',
  },
  detailsGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxHeight: '300px',
  },
  button: {
    backgroundColor: '#003562', // --incube primary color
    color: 'white',
    marginLeft: '1rem',
  },
  editingButtonsGroup: {
    display: 'inline-flex',
    flexWrap: 'no-wrap',
    margin: '0',
  },
  dropZone: {
    display: 'none',
  },
};

export const initialData = {
  size: 0,
  latestVersion: '',
  version: '',
  name: '',
  installOnly: false,
  lastUpdated: '',
};

const appSettings = [
  {
    key: 'installOnly',
    type: AttributeType.BOOLEAN,
    title: I18n.t('applications.fields.installOnly'),
  },
];

class Application extends Component {
  state = {
    data: initialData,
    editing: false,
    changed: false,
    activeIndex: undefined,
    selected: new Set(),
  };

  getApplicationMenuStructure = () => {
    const { globalPermissionsForUser, hasAdminPrivileges } = this.props;
    const childRoute = RouteHelpers.getUrlParameter(this.props, 'childRoute');
    const menuItems = [
      {
        key: 'applicationInfoTab',
        title: I18n.t('applications.applicationInfoTabHeading'),
        route: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/${childRoute}/${applicationRouteConstants.INFO}`,
        isAlwaysRead: true,
      },
      {
        key: 'allVersionsListTabHeading',
        title: I18n.t('applications.allVersionsListTabHeading'),
        route: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/${childRoute}/${applicationRouteConstants.VERSIONS}`,
        isAlwaysRead: true,
      },
      {
        key: 'settingsTabHeading',
        title: I18n.t('applications.settingsTabHeading'),
        route: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/${childRoute}/${applicationRouteConstants.SETTINGS}`,
        permissionProperty:
          GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS,
      },
    ];
    return getAccessibleMenuItems(
      menuItems,
      globalPermissionsForUser,
      hasAdminPrivileges
    );
  };

  componentDidMount() {
    this.setState(this.initializeState(this.props));
    this.props.loadApplication(this.props.match.params.childRoute);
    this.props.loadApplicationDevices(this.props.match.params.childRoute);
    if (isDataLoadingRequired(this.props.profilesStatus)) {
      this.props.loadProfiles();
    }
    if (isDataLoadingRequired(this.props.profilesStatusV2)) {
      this.props.loadProfilesV2();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.data && prevProps.data !== this.props.data) ||
      this.props.match.params.childRoute !==
        prevProps.match.params.childRoute ||
      (this.props.match.params.category &&
        this.props.match.params.category !== prevProps.match.params.category)
    ) {
      this.setState(this.initializeState(this.props));
      if (
        this.props.match.params.childRoute !== prevProps.match.params.childRoute
      ) {
        this.props.loadApplication(this.props.match.params.childRoute);
        this.props.loadApplicationDevices(this.props.match.params.childRoute);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetApplication();
  }

  initializeState = (props) => {
    const shortData = this.props.applications.length
      ? this.props.applications.find(
          (application) => application.id === props.match.params.childRoute
        )
      : undefined;
    const data = props.data.length
      ? props.data.find(
          (application) => application.id === props.match.params.childRoute
        )
      : undefined;
    return {
      data: {
        ...initialData,
        ...(data ? data : shortData ? shortData : {}),
      },
      editing: false,
      changed: false,
    };
  };

  toggleAppEditable = () => {
    if (!this.props.match.params.profileId && this.state.data.id) {
      this.setState({ editing: !this.state.editing });
    }
  };

  onChange = ({ target: { value, name } }) => {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({
      data,
      changed: true,
    });
  };

  onToggleSettings = (setting) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [setting]: !prevState.data[setting],
      },
    }));
  };

  cancelChanges = () => {
    this.setState(this.initializeState(this.props));
  };

  validateForm = () => {
    return this.state.data.name.length > 0;
  };

  saveChanges = (event) => {
    event.preventDefault();
    this.props.updateApplication({ ...this.state.data }, this.props.companyId);
  };

  renderAppIDName = () => {
    const value = this.state.data.idName || '';
    if (this.props.status === NetworkStatus.DONE || value) {
      return (
        <div
          style={{
            fontSize: '18px',
          }}
        >
          {value}
        </div>
      );
    }
    return '';
  };

  renderField = ({ name, codeName, editable }, index) => {
    const value = this.state.data[codeName] || '';
    if (
      !editable ||
      !!this.props.match.params.profileId ||
      !this.state.editing
    ) {
      let content = codeName && value ? value : '...';
      switch (codeName) {
        case 'lastUpdated': {
          const dateInMoment = moment(content);
          content = dateInMoment.isValid()
            ? dateInMoment.utc().format('MMMM Do YYYY, h:mm:ss a').toString()
            : '';
          break;
        }
        case 'size': {
          content = bytesToSize(content);
          break;
        }
        case 'installOnly': {
          content = value ? (
            <CheckCircle className="icon--fixed-square" />
          ) : (
            <CrestCircle className="icon--fixed-square" />
          );
          break;
        }
        default:
          break;
      }

      return (
        <div key={index} style={styles.detailField}>
          <strong>{name}</strong>
          <span style={{ minHeight: '30px' }}>{content}</span>
        </div>
      );
    }
    if (!this.props.match.params.profileId && this.state.editing) {
      return (
        <FormGroup
          key={index}
          controlId={codeName}
          bsSize="sm"
          style={styles.detailField}
        >
          <strong>{name}</strong>
          <FormControl
            // autoFocus={index === 0}
            componentClass={'input'}
            type="text"
            id={codeName}
            name={codeName}
            value={value}
            onChange={this.onChange}
            placeholder={`${I18n.t(
              'applications.applicationCodeNameHint'
            )} ${name}`}
          />
        </FormGroup>
      );
    }
  };

  render() {
    return this.renderApp();
  }

  renderApp = () => {
    const { editing } = this.state;
    return (
      <Dialog
        header={this.renderHeader}
        menu={this.renderMenu}
        content={this.renderContent}
        onBack={() => {
          history.goBack();
        }}
        isFullViewportVisible={editing}
      />
    );
  };

  renderMenu = () => (
    <DialogMenu structure={this.getApplicationMenuStructure()} />
  );

  renderHeader = () => {
    return (
      <form
        onSubmit={this.saveChanges}
        style={{
          display: 'flex',
          width: '100%',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
        }}
      >
        <div
          className={'profile-header'}
          style={{
            width: '80%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              height: '30px',
              fontFamily: 'Open Sans',
              fontSize: '30px',
              fontWeight: '300',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: 0.6,
              letterSpacing: 'normal',
              color: '#000000',
              display: 'inline-flex',
              flexWrap: 'nowrap',
              marginLeft: '24px',
            }}
            className={'profile-header__caption-text'}
          >
            <Layers
              alt={'_blank'}
              className={'icon'}
              style={{
                width: '20px',
                flex: 'none',
                marginRight: '12px',
              }}
            />
            {this.renderAppIDName()}
          </div>
        </div>
        <div className={'profile-actions__section'}>
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            {this.renderHeaderControls()}
          </div>
        </div>
      </form>
    );
  };

  renderHeaderControls = () => {
    if (this.props.match.params.category === 'assign') {
      return null;
    }

    return (
      <Fragment>
        {this.renderEditButton()}
        {this.renderActions()}
      </Fragment>
    );
  };

  renderActions = () => {
    if (this.state.editing) {
      return (
        <FormGroup style={styles.editingButtonsGroup}>
          <Button secondary onClick={this.cancelChanges}>
            {I18n.t('common.cancel')}
          </Button>
          <Button
            disabled={!this.validateForm() && this.state.changed}
            type={'submit'}
            style={{ marginLeft: '10px' }} // TODO: remove inline style
          >
            {I18n.t('common.save')}
          </Button>
        </FormGroup>
      );
    }
  };

  renderEditButton = () => {
    const {
      status,
      globalPermissionsForUser = {},
      hasAdminPrivileges = false,
    } = this.props;
    const areAppSettingsEditable = isAppManagementAllowed(
      globalPermissionsForUser,
      hasAdminPrivileges
    );
    const profileId = RouteHelpers.getUrlParameter(this.props, 'profileId');
    const isSettingsTab =
      RouteHelpers.getUrlParameter(this.props, 'category') === 'settings';
    if (
      isSettingsTab &&
      !profileId &&
      !this.state.editing &&
      status === NetworkStatus.DONE &&
      areAppSettingsEditable
    ) {
      return (
        <Button
          onClick={this.toggleAppEditable}
          title={I18n.t('applications.editApplicationDetails')}
          style={{ height: '34px', borderRadius: '4px' }} // TODO: remove inline styles
          className={'button--transparent'}
        >
          <Glyphicon glyph="pencil" />
        </Button>
      );
    }
  };

  renderContent = () => {
    if (this.props.status === NetworkStatus.STARTED && !this.state.data.id) {
      return <Throbber />;
    }

    return (
      <Switch>
        <Redirect
          exact
          from={`${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/${this.props.match.params.childRoute}`}
          to={`${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/${this.props.match.params.childRoute}/info`}
        />
        <Route
          exact
          path={`${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/:childRoute/${applicationRouteConstants.INFO}`}
          component={this.renderInfo}
        />
        <Route
          exact
          path={`${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/:childRoute/${applicationRouteConstants.VERSIONS}`}
          component={this.renderVersions}
        />
        <Route
          exact
          path={`${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/:childRoute/${applicationRouteConstants.SETTINGS}`}
          component={this.renderSettings}
        />
        <Route
          exact
          path={`${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/:childRoute/${applicationRouteConstants.ASSIGN}`}
          component={ApplicationProfilesSelection}
        />
        <Route component={NotFound} />
      </Switch>
    );
  };

  renderSettings = () => {
    const { editing, data } = this.state;
    return (
      <ApplicationSettings
        isEditingMode={editing}
        appData={data}
        appSettings={appSettings}
        onToggleSettings={this.onToggleSettings}
      />
    );
  };

  renderVersions = () => {
    const tableRef = React.createRef();
    const {
      globalPermissionsForUser = {},
      hasAdminPrivileges = false,
    } = this.props;
    return (
      <div>
        <SectionDescription
          title={I18n.t('applications.allVersionsListTabHeading')}
          hint={I18n.t('applications.allVersionsListTabHint')}
        />
        <DataTable
          allColumns={applicationsVersionsColumns}
          data={this.props.data}
          loadingStatus={this.props.status}
          useFlex
          onSelect={this.select}
          selectable={isAppManagementAllowed(
            globalPermissionsForUser,
            hasAdminPrivileges
          )}
          tableRef={tableRef}
        />
        {this.renderTools()}
      </div>
    );
  };

  renderTools = () => {
    const { hasAdminPrivileges } = this.props;
    if (this.state.selected.size) {
      return (
        <ToolsPanel
          selectedItems={this.state.selected}
          title={I18n.t('fileSystem.versionsSelected')}
        >
          <Tool
            title={I18n.t('fileSystem.removeVersions')}
            onClick={this.onDelete}
            hidden={!hasAdminPrivileges}
          >
            <TrashEmpty className={'icon'} />
          </Tool>
        </ToolsPanel>
      );
    }
  };

  onDelete = () => {
    const childRoute = RouteHelpers.getUrlParameter(this.props, 'childRoute');
    const appIds = this.props.data.map((app) => app.id);
    PopupActions.showConfirm({
      text: I18n.t('fileSystem.deleteVersionsConfirmation'),
      onConfirm: () => {
        this.props.deleteVersions(
          [...this.state.selected.values()],
          childRoute,
          appIds
        );
      },
    });
  };

  select = (selectedIds) => {
    const selected = new Set(selectedIds);
    this.setState({ selected });
  };

  renderInfo = () => {
    if (this.props.status === NetworkStatus.DONE || this.state.data.id) {
      return <div>{this.props.attributes.map(this.renderField)}</div>;
    }
  };
}

const mapStateToProps = (state) => {
  const globalPermissionsForUser = globalPermissionsForUserSelector(state);
  // const globalPermissionsForUser = globalPermissionsMock;

  return {
    companyId: companyIdSelector(state),
    application: state.application,
    data: state.application.data,
    status: state.application.status,
    error: state.application.error,
    devicesFailed: state.application.devicesFailed,
    devicesInstalled: state.application.devicesInstalled,
    applicationDevicesStatus: state.application.devicesStatus,
    applicationsStatus: state.applications.status,
    applications: state.applications.data,
    profilesData: state.profiles.data,
    profilesStatus: state.profiles.status,
    profilesStatusV2: state.profilesV2.status,
    profilesError: state.profiles.error,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    globalPermissionsForUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadApplication: (id) => dispatch(loadApplication(id)),
    deleteVersions: (versions, applicationId, numberOfVersionsToDelete) =>
      dispatch(
        deleteApplications(versions, applicationId, numberOfVersionsToDelete)
      ),
    updateApplication: (data, companyId) =>
      dispatch(updateApplication(data, companyId)),
    resetApplication: () => dispatch(resetApplication()),
    loadApplicationDevices: (id) => dispatch(loadApplicationDevices(id)),
    loadProfiles: () => dispatch(loadProfiles()),
    loadProfilesV2: (params) => dispatch(loadProfilesV2(params)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Application);
