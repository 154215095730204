import {
  preferencesLoaded,
  preferencesLoadingFailed,
  preferencesLoadingStarted,
  preferencesUpdated,
  preferencesUpdateFailed,
  preferencesUpdateStarted,
} from './preferences.actions';
import client from '../../utils/client';
import services from '../../utils/services';
import { I18n } from 'react-redux-i18n';
import PopupActions from '../popup/popup.actions';

export const loadPreferences = () => {
  return async (dispatch) => {
    try {
      dispatch(preferencesLoadingStarted());
      const { status, data } = await client.get(services.getPreferences);
      if (status === 200) {
        dispatch(preferencesLoaded(data));
      } else {
        dispatch(
          preferencesLoadingFailed(I18n.t('setting.preferencesFetchFailed'))
        );
      }
    } catch (error) {
      dispatch(
        preferencesLoadingFailed(I18n.t('setting.preferencesFetchFailed'))
      );
    }
  };
};

export const updatePreferences = (data) => {
  return async (dispatch) => {
    try {
      dispatch(preferencesUpdateStarted());
      const response = await client.post(services.getPreferences, data);
      if (response.status === 204) {
        dispatch(preferencesUpdated(data));
        await PopupActions.showAlert({
          text: I18n.t('settings.preferencesUpdateSucceeded'),
        });
      } else {
        dispatch(
          preferencesUpdateFailed(I18n.t('setting.preferencesUpdateFailed'))
        );
      }
    } catch (error) {
      dispatch(
        preferencesUpdateFailed(I18n.t('setting.preferencesUpdateFailed'))
      );
    }
  };
};
