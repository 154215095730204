import moment from 'moment';

const areDatesInProximity = (
  dateToCheck,
  referenceDate,
  proximity,
  proximityUnit = 'minutes'
) =>
  Math.abs(moment(dateToCheck).diff(referenceDate, proximityUnit)) < proximity;

export default {
  areDatesInProximity,
};
