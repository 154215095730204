import React, { Component } from 'react';
import { Button } from '../../components/button.component';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import { I18n } from 'react-redux-i18n';
import { resetEnrollmentCreation } from '../enrollment/enrollment.actions';
import { history } from '../../utils/history';
import { DevicesRoutes, RootRoutes } from '../../utils/routes';
import { SectionDescription } from '../../components/sectionDescription.component';

class NewEnrollment extends Component {
  handleDownloadPayload = () => {
    fileDownload(
      `[Enrollment]\nkey=${this.props.newEnrollment.shortId}\n`,
      'enrollment.ini'
    );
  };

  render() {
    return (
      <div
        className=""
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
        }}
      >
        <div
          className=""
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              fontSize: '21px',
              fontWeight: 'normal',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: '1.19',
              letterSpacing: 'normal',
              color: '#003562', // --incube primary color
            }}
          >
            {I18n.t('enrollments.enrollmentCreationSuccessTitle')}
          </div>
          <SectionDescription
            title={I18n.t('enrollments.enrollmentCreationSuccessSubTitle')}
            isSmall
          />
          <div
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: '1.17',
              letterSpacing: 'normal',
              color: '#003562', // --incube primary color
              marginTop: '30px',
              marginLeft: '12px',
            }}
          >
            {I18n.t('enrollments.QRCodeEnrollmentIdText')}
            <div
              style={{
                fontSize: '18px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: '1.17',
                letterSpacing: 'normal',
                color: '#003562', // --incube primary color
                marginTop: '15px',
              }}
            >
              {`${
                this.props.newEnrollment?.shortId ??
                I18n.t('enrollments.notAvailable')
              }`}
            </div>
          </div>
          <div
            style={{
              fontSize: '21px',
              fontWeight: 'normal',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: '1.19',
              letterSpacing: 'normal',
              color: '#003562', // --incube primary color
              marginTop: '24px',
            }}
          >
            {I18n.t('enrollments.preview')}
          </div>
          <div
            className=""
            style={{
              border: 'solid 1px #707070',
              marginTop: '10px',
              padding: '35px',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              minHeight: '313px',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {this.props.newEnrollment.shortId ? (
                <QRCode
                  value={`[Enrollment]\nkey=${this.props.newEnrollment.shortId}\n`}
                />
              ) : (
                I18n.t('enrollments.noQRCodeAvailableText')
              )}
            </div>
            {/*<div
                        // style={{margin: '28px'}}>{this.props.newEnrollment.shortId || this.props.newEnrollment.url ? '- or -' : '- and -'}</div>
                    */}
          </div>
          {/*
                    this.props.newEnrollment.shortId ?
                        <div
                            onClick={this.handleDownloadPayload}
                            style={{
                                fontSize: '12px',
                                fontWeight: 'normal',
                                fontStyle: 'normal',
                                fontStretch: 'normal',
                                lineHeight: '1.17',
                                letterSpacing: 'normal',
                                color: '#003562',
                                textTransform: 'uppercase',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                marginTop: '45px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {'Download Payload'}
                        </div>
                        :  <div
                            style={{
                                fontSize: '12px',
                                fontWeight: 'normal',
                                fontStyle: 'normal',
                                fontStretch: 'normal',
                                lineHeight: '1.17',
                                letterSpacing: 'normal',
                                color: '#003562',
                                marginTop: '45px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {'Payload not available.'}
                        </div>
                */}
          <div />
        </div>
        {this.props.newEnrollment?.id ? (
          <div style={{ display: 'flex', width: '100%', marginTop: 'auto' }}>
            <Button
              secondary
              className={
                'create-enrollment-wizard__control-button create-enrollment-wizard__control-button--first'
              }
              onClick={this.goToEnrollment}
            >
              {I18n.t('enrollments.edit')}
            </Button>
            <Button
              secondary
              className={'create-enrollment-wizard__control-button'}
              onClick={this.onExit}
            >
              {I18n.t('enrollments.exit')}
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', width: '100%', marginTop: 'auto' }}>
            <Button
              secondary
              className={'create-enrollment-wizard__control-button'}
              onClick={this.onExit}
            >
              {I18n.t('enrollments.exit')}
            </Button>
          </div>
        )}
      </div>
    );
  }

  goToEnrollment = () => {
    if (this.props.newEnrollment.id) {
      history.push(
        `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}/${this.props.newEnrollment.id}`
      );
    }
  };

  onExit = () => {
    history.push(`${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}`);
  };
}

const mapStateToProps = (state) => {
  return {
    newEnrollment: state.enrollment.newEnrollment,
    error: state.enrollment.createEnrollmentError,
    status: state.enrollment.createEnrollmentStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetEnrollmentCreation: () => dispatch(resetEnrollmentCreation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewEnrollment);
