import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';

import './styles/textarea.scss';

class TextArea extends Component {
  textAreaRef = React.createRef();

  autoGrow = () => {
    const textArea = this.textAreaRef.current;
    if (textArea.scrollHeight > textArea.clientHeight) {
      textArea.style.height = textArea.scrollHeight + 'px';
    }
  };

  render() {
    const {
      label,
      hint,
      value,
      onChange,
      autoGrow = false,
      id,
      name,
      otherProps,
    } = this.props;
    const containerClassName = 'springdel-text-area';
    const inputId = id !== undefined ? id : uuid();
    return (
      <div className={containerClassName}>
        <label className={`${containerClassName}__label`} htmlFor={inputId}>
          {label}
          <div className="springdel-text-area__hint">{hint}</div>
        </label>
        <textarea
          {...otherProps}
          id={inputId}
          className={`${containerClassName}__input`}
          value={value}
          onChange={onChange}
          ref={this.textAreaRef}
          name={name}
          {...(autoGrow ? { onKeyUp: () => this.autoGrow(this) } : {})}
        />
      </div>
    );
  }
}

export default TextArea;
