import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import sidebarItems, {
  alertsItem,
  APIItem,
  applicationsMenuItem,
  sidebarSettingsItem,
  usersManagementItem,
} from './layout.constants';
import { isDevServer, selectForCurrentBrand } from '../common/helpers';
import {
  INCUBE_LOGO,
  JANAM_LOGO,
  SPRINGDEL_LOGO,
} from '../common/brandSpecificConstants';

class MainNavBar extends Component {
  render() {
    const {
      open,
      isApplicationsMenuItemAccessible,
      hasAdminPrivileges,
    } = this.props;
    if (!open) {
      return null;
    }

    const logo = selectForCurrentBrand({
      original: SPRINGDEL_LOGO,
      incube: INCUBE_LOGO,
      janam: JANAM_LOGO,
    });
    const sidebarClassNames = selectForCurrentBrand({
      original: 'sidebar',
      incube: 'sidebar sidebar--incube',
      janam: 'sidebar sidebar--janam',
    });
    return (
      <div className={sidebarClassNames}>
        <div className={'sidebar-logo-section'}>
          <div className={'sidebar-logo'}>{logo}</div>
        </div>
        {sidebarItems.map(this.renderSidebarItem)}
        {isApplicationsMenuItemAccessible
          ? this.renderSidebarItem(applicationsMenuItem)
          : null}
        {hasAdminPrivileges
          ? this.renderSidebarItem(usersManagementItem)
          : null}
        {isDevServer() ? this.renderSidebarItem(alertsItem) : null}
        {isDevServer() ? this.renderSidebarItem(APIItem) : null}
        <div className={'sidebar-settings-section'}>
          {this.renderSidebarItem(sidebarSettingsItem)}
        </div>
      </div>
    );
  }

  renderSidebarItem = (item) => {
    // TODO: style SVG with gradient instead of using duplicated icons
    const isActive =
      this.props.location.pathname &&
      this.props.location.pathname.startsWith(item.route);
    const icon = isActive ? item.activeIcon : item.icon;

    return (
      <NavLink
        key={`${item.route}`}
        to={item.route}
        title={(() => I18n.t(item.titleCode || ''))()}
        className={'sidebar-icon'}
        activeClassName={'sidebar-icon--active'}
      >
        {icon}
      </NavLink>
    );
  };
}

MainNavBar.propTypes = {
  style: PropTypes.object,
};

export default withRouter(MainNavBar);
