import React from 'react';
import { I18n } from 'react-redux-i18n';
import { selectForCurrentBrand } from '../../common/helpers';
import { TextButton } from '../textButton.component';
import { ReactComponent as PlusCircle } from '../../assets/images/plus-circle.svg';

const listHeaderClass = selectForCurrentBrand({
  original: 'drag-n-drop-destination-list-header',
  incube:
    'drag-n-drop-destination-list-header drag-n-drop-destination-list-header--incube',
  janam:
    'drag-n-drop-destination-list-header drag-n-drop-destination-list-header--janam',
});

const DragNDropDestinationListHeader = ({
  listTitle,
  iconProps,
  iconProps: {
    onClick,
    hint = I18n.t('profiles.kiosk.addPackageByID'),
    title = I18n.t('profiles.kiosk.addPackageByID'),
    className = 'icon--white',
  } = {},
}) => (
  <div className={listHeaderClass}>
    <div className={'drag-n-drop-destination-list-header__margin'}>{''}</div>
    <div className={'drag-n-drop-destination-list-header__title'}>
      {listTitle}
    </div>
    {iconProps ? (
      <TextButton
        onClick={onClick}
        hint={hint}
        title={title}
        className={className}
      >
        <PlusCircle />
      </TextButton>
    ) : null}
  </div>
);

export default DragNDropDestinationListHeader;
