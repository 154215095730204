import { NetworkStatus } from '../../common/constants';
import {
  DEVICES_LOAD_FAILED,
  DEVICES_LOAD_STARTED,
  DEVICES_LOAD_SUCCESS,
  DEVICES_REMOVE_FAILED,
  DEVICES_REMOVE_RESET,
  DEVICES_REMOVE_STARTED,
  DEVICES_REMOVE_SUCCESS,
  DEVICES_SEND_REMOTE_COMMAND_FAILED,
  DEVICES_SEND_REMOTE_COMMAND_RESET,
  DEVICES_SEND_REMOTE_COMMAND_STARTED,
  DEVICES_SEND_REMOTE_COMMAND_SUCCESS,
} from './devices.actionTypes';
import {
  DEVICES_ASSIGN_FLEET_FAILED,
  DEVICES_ASSIGN_FLEET_RESET,
  DEVICES_ASSIGN_FLEET_STARTED,
  DEVICES_ASSIGN_FLEET_SUCCESS,
  DEVICES_RESET,
  FLEET_DELETE_FAILED,
  FLEET_DELETE_RESET,
  FLEET_DELETE_STARTED,
  FLEET_DELETE_SUCCESS,
  FLEET_LOAD_FAILED,
  FLEET_LOAD_RESET,
  FLEET_LOAD_STARTED,
  FLEET_LOAD_SUCCESS,
  FLEET_PROFILES_V1_LOAD_FAILED,
  FLEET_PROFILES_V1_LOAD_RESET,
  FLEET_PROFILES_V1_LOAD_STARTED,
  FLEET_PROFILES_V1_LOAD_SUCCESS,
  FLEET_PROFILES_V2_LOAD_FAILED,
  FLEET_PROFILES_V2_LOAD_STARTED,
  FLEET_PROFILES_V2_LOAD_SUCCESS,
  FLEET_PROFILES_V2_RESET,
} from '../fleets/fleets.actionTypes';

const defaultState = {
  fleetId: null,
  fleetDevices: [],
  fleetDevicesNextPageUrl: null,
  fleetLoadingStatus: null,

  fleetProfilesV1: [],
  fleetProfilesV1Status: NetworkStatus.NONE,
  fleetProfilesV1Error: undefined,

  fleetProfilesV2: [],
  fleetProfilesV2Status: NetworkStatus.NONE,
  fleetProfilesV2Error: undefined,

  devicesMoveStatus: NetworkStatus.NONE,
  devicesMoveError: undefined,

  devices: [],
  devicesLoadingStatus: NetworkStatus.NONE,

  devicesRemoveStatus: NetworkStatus.NONE,
  devicesRemoveError: null,

  remoteCommandStatus: NetworkStatus.NONE,

  fleetDeletingStatus: NetworkStatus.NONE,
  fleetDeletingError: null,
};

export default function devices(state = defaultState, action) {
  switch (action.type) {
    case FLEET_LOAD_STARTED:
      return Object.assign({}, state, {
        fleetLoadingStatus: NetworkStatus.STARTED,
        fleetId: action.payload,
        fleetDevices: [],
      });
    case FLEET_LOAD_FAILED:
      return Object.assign({}, state, {
        fleetLoadingStatus: NetworkStatus.ERROR,
        // TODO: add error message payload
      });
    case FLEET_LOAD_SUCCESS: {
      const {
        payload: {
          isInitialLoad = false,
          id,
          devices,
          isUpdateFromSockets,
          next,
        } = {},
      } = action;
      const fleetDevices =
        id === undefined || id === state.fleetId ? devices : state.fleetDevices;
      return Object.assign({}, state, {
        fleetDevices: isUpdateFromSockets
          ? fleetDevices
          : [...(isInitialLoad ? [] : state.fleetDevices), ...fleetDevices],
        fleetDevicesNextPageUrl: next,
        fleetLoadingStatus:
          id === undefined || id === state.fleetId
            ? NetworkStatus.DONE
            : state.fleetLoadingStatus,
      });
    }
    case FLEET_LOAD_RESET:
      return Object.assign({}, state, {
        fleetLoadingStatus: NetworkStatus.NONE,
      });

    case FLEET_PROFILES_V1_LOAD_STARTED:
      return {
        ...state,
        fleetProfilesV1Status: NetworkStatus.STARTED,
        fleetProfilesV1Error: undefined,
      };

    case FLEET_PROFILES_V1_LOAD_FAILED:
      return {
        ...state,
        fleetProfilesV1Status: NetworkStatus.ERROR,
        fleetProfilesV1Error: action.payload,
      };

    case FLEET_PROFILES_V1_LOAD_SUCCESS:
      return {
        ...state,
        fleetProfilesV1Status: NetworkStatus.DONE,
        fleetProfilesV1Error: undefined,
        fleetProfilesV1: action.payload,
      };

    case FLEET_PROFILES_V1_LOAD_RESET:
      return {
        ...state,
        fleetProfilesV1: [],
        fleetProfilesV1Status: NetworkStatus.NONE,
        fleetProfilesV1Error: undefined,
      };
    case FLEET_PROFILES_V2_LOAD_STARTED:
      return {
        ...state,
        fleetProfilesV2Status: NetworkStatus.STARTED,
        fleetProfilesV2Error: undefined,
      };

    case FLEET_PROFILES_V2_LOAD_FAILED:
      return {
        ...state,
        fleetProfilesV2Status: NetworkStatus.ERROR,
        fleetProfilesV2Error: action.payload,
      };

    case FLEET_PROFILES_V2_LOAD_SUCCESS:
      return {
        ...state,
        fleetProfilesV2Status: NetworkStatus.DONE,
        fleetProfilesV2Error: undefined,
        fleetProfilesV2: action.payload,
      };

    case FLEET_PROFILES_V2_RESET:
      return {
        ...state,
        fleetProfilesV2: [],
        fleetProfilesV2Status: NetworkStatus.NONE,
        fleetProfilesV2Error: undefined,
      };

    case DEVICES_LOAD_STARTED:
      return Object.assign({}, state, {
        devicesLoadingStatus: NetworkStatus.STARTED,
      });
    case DEVICES_LOAD_FAILED:
      return Object.assign({}, state, {
        devicesLoadingStatus: NetworkStatus.ERROR,
      });
    case DEVICES_LOAD_SUCCESS:
      return {
        ...state,
        devices: [...action.payload],
        devicesLoadingStatus: NetworkStatus.DONE,
      };
    case DEVICES_SEND_REMOTE_COMMAND_STARTED:
      return Object.assign({}, state, {
        remoteCommandStatus: NetworkStatus.STARTED,
      });
    case DEVICES_SEND_REMOTE_COMMAND_FAILED:
      return Object.assign({}, state, {
        remoteCommandStatus: NetworkStatus.ERROR,
        // TODO: add error message payload
      });
    case DEVICES_SEND_REMOTE_COMMAND_SUCCESS:
      return Object.assign({}, state, {
        remoteCommandStatus: NetworkStatus.DONE,
      });
    case DEVICES_SEND_REMOTE_COMMAND_RESET:
      return Object.assign({}, state, {
        remoteCommandStatus: NetworkStatus.NONE,
      });
    case FLEET_DELETE_STARTED:
      return Object.assign({}, state, {
        fleetDeletingStatus: NetworkStatus.STARTED,
      });
    case FLEET_DELETE_FAILED:
      return Object.assign({}, state, {
        fleetDeletingStatus: NetworkStatus.ERROR,
        fleetDeletingError: action.payload,
      });
    case FLEET_DELETE_SUCCESS:
      return Object.assign({}, state, {
        fleetDeletingStatus: NetworkStatus.DONE,
      });
    case FLEET_DELETE_RESET:
      return Object.assign({}, state, {
        fleetDeletingStatus: NetworkStatus.NONE,
        fleetDeletingError: null,
      });
    case DEVICES_REMOVE_STARTED:
      return Object.assign({}, state, {
        devicesRemoveStatus: NetworkStatus.STARTED,
        devicesRemoveError: null,
      });
    case DEVICES_REMOVE_FAILED:
      return Object.assign({}, state, {
        devicesRemoveStatus: NetworkStatus.ERROR,
        devicesRemoveError: action.payload,
      });
    case DEVICES_REMOVE_SUCCESS:
      return Object.assign({}, state, {
        devicesRemoveStatus: NetworkStatus.DONE,
        devicesRemoveError: null,
      });
    case DEVICES_REMOVE_RESET:
      return Object.assign({}, state, {
        devicesRemoveStatus: NetworkStatus.NONE,
        devicesRemoveError: null,
      });
    case DEVICES_ASSIGN_FLEET_STARTED:
      return Object.assign({}, state, {
        devicesMoveStatus: NetworkStatus.STARTED,
        devicesMoveError: undefined,
      });
    case DEVICES_ASSIGN_FLEET_FAILED:
      return Object.assign({}, state, {
        devicesMoveStatus: NetworkStatus.ERROR,
        devicesMoveError: action.payload,
      });
    case DEVICES_ASSIGN_FLEET_SUCCESS:
      return Object.assign({}, state, {
        devicesMoveStatus: NetworkStatus.DONE,
        devicesMoveError: undefined,
      });
    case DEVICES_ASSIGN_FLEET_RESET:
      return Object.assign({}, state, {
        devicesMoveStatus: NetworkStatus.NONE,
        devicesMoveError: undefined,
      });
    case DEVICES_RESET:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
}
