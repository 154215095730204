import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import Dialog from '../../components/Dialog';
import { Button } from '../../components/button.component';
import DialogMenu from '../../components/DialogMenu/dialogMenu.component';
import { history } from '../../utils/history';
import UserManagement from './components/userManagement.component';
import Permission from './components/permission.component';
import {
  createGroup,
  editGroup,
  loadAllUsers,
  loadCompanyFleetsFullList,
  loadGroupsModelLevelPermissions,
} from './groups.thunk';
import { connect } from 'react-redux';
import { NetworkStatus } from '../../common/constants';
import { createGroupReset } from './groups.actions';
import { Throbber } from '../../components/Throbber';
import { RootRoutes, UsersRoutes } from '../../utils/routes';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import { companyIdSelector } from '../login/login.selectors';
import { allCompanyFleetsSelector } from '../../selectors/fleetsSelector';
import { getDifferenceBetweenTwoArrays, notEmpty } from '../../common/helpers';
import RouteHelpers from '../../common/utilities/routeHelpers';
import {
  getGroupGlobalPermissionsWithAccesses,
  sortGroupGlobalPermissionsConfigByAccess,
} from '../../common/utilities/users';
import {
  ALL_DEVICE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS,
  ALL_FLEET_TREE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS,
  ALL_PROFILE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS,
  ALL_PROFILE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS,
  GLOBAL_READ_READWRITE_PERMISSIONS,
  groupGlobalPermissionsConfigDefaultValues,
  permissionTableItems,
} from '../../constants/globalPermissions';
import { ACCESS_TYPES } from '../../constants/accessTypes';
import { loadUserGroupPermissions, loadUsers } from '../users/users.thunk';
// import InviteMethods from '../newUser/components/newUserInviteMethods.component';
import EnrollmentEmail from './components/enrollmentEmail.component';
import { ADMINS_GROUP_ID, ALL_USERS_GROUP_ID } from '../../constants/users';
import { createGroupRouteConstants } from './group.constants';
import { showStatusChangePopup } from '../../common/utilities/common';

class CreateUserGroup extends React.Component {
  state = {
    permissions: [],
    existingUsers: [],
    data: {
      name: I18n.t('users.newUserGroup'),
      userList: [],
      groupGlobalPermissionsConfig: {
        ...groupGlobalPermissionsConfigDefaultValues,
      },
      fleetsViewSpecificList: [],
      fleetsRemoteControlEnabledList: [],
      enrollmentEmail: {
        subject: '',
        message: '',
      },
    },
  };

  groupId = RouteHelpers.getQueryParamFromLocation(
    this.props.location,
    'groupId'
  );

  get isEditActivity() {
    return Boolean(this.groupId);
  }

  get isCurrentGroupIdAll() {
    return this.groupId === ALL_USERS_GROUP_ID;
  }

  get isCurrentGroupIdAdmins() {
    return this.groupId === ADMINS_GROUP_ID;
  }

  dialogMenuItems = () => [
    {
      key: createGroupRouteConstants.PERMISSIONS,
      title: I18n.t('users.permissions'),
      route: RouteHelpers.formatUrlWithQuery(
        `${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/${createGroupRouteConstants.PERMISSIONS}`,
        {
          groupId: this.groupId,
        }
      ),
      isActive: (match, location) =>
        location &&
        location.pathname.includes(createGroupRouteConstants.PERMISSIONS),
      hidden: this.isCurrentGroupIdAdmins,
    },
    {
      key: createGroupRouteConstants.USER_MANAGEMENT,
      title: I18n.t('users.userManagement'),
      route: RouteHelpers.formatUrlWithQuery(
        `${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/${createGroupRouteConstants.USER_MANAGEMENT}`,
        {
          groupId: this.groupId,
        }
      ),
      isActive: (match, location) =>
        location &&
        location.pathname.includes(createGroupRouteConstants.USER_MANAGEMENT),
    },
    // {
    //     key: 'Invite Method',
    //     route: RouteHelpers.formatUrlWithQuery(`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/inviteMethod`, {
    //         groupId: this.groupId,
    //     }),
    //     isActive: (match, location) => location && location.pathname.includes('inviteMethod'),
    // },
    // {
    //     key: 'Enrollment Email',
    //     route: RouteHelpers.formatUrlWithQuery(`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/enrollmentEmail`, {
    //         groupId: this.groupId,
    //     }),
    //     isActive: (match, location) => location && location.pathname.includes('enrollmentEmail'),
    // },
  ];

  componentDidMount() {
    const {
      groupUsersStatus,
      loadUserGroupPermissions,
      loadAllUsers,
      loadCompanyFleetsFullList,
      groupUsers,
      loadGroupsModelLevelPermissions,
    } = this.props;
    loadAllUsers();
    loadCompanyFleetsFullList();
    loadGroupsModelLevelPermissions();

    if (!this.isCurrentGroupIdAll && this.isEditActivity) {
      loadUserGroupPermissions(this.groupId);
      if (groupUsersStatus === NetworkStatus.NONE) {
        this.props.loadUsers(this.groupId);
      }
      if (groupUsersStatus === NetworkStatus.DONE) {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            name: this.groupId,
            userList: groupUsers.map((item) => item.username),
          },
        }));
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      allDataForEditLoaded,
      groupUsers = [],
      userGroupPermissions: {
        groupGlobalPermissionsConfig,
        fleetsViewSpecific = [],
        fleetsRemoteControlEnabled = [],
      } = {},
    } = this.props;

    if (
      prevProps.createGroupStatus === NetworkStatus.STARTED &&
      this.props.createGroupStatus === NetworkStatus.ERROR
    ) {
      showStatusChangePopup({
        onClose: this.props.createGroupReset,
        status: this.props.createGroupStatus,
        errorText:
          this.props.error ??
          I18n.t('users.groupCreationFailureDefaultErrorText'),
      });
    }

    if (
      this.isEditActivity &&
      !prevProps.allDataForEditLoaded &&
      allDataForEditLoaded
    ) {
      this.setState((state) => ({
        data: {
          ...state.data,
          name: this.groupId,
          groupGlobalPermissionsConfig: { ...groupGlobalPermissionsConfig },
          fleetsViewSpecificList: [...fleetsViewSpecific],
          fleetsRemoteControlEnabledList: [...fleetsRemoteControlEnabled],
          userList: groupUsers.map((item) => item.username),
        },
      }));
    }
  }

  handleGlobalPermissionsChange = ({ configId, value }) => {
    const { children = [] } =
      permissionTableItems.find((item) => item.id === configId) || {};
    const hasChildren = Boolean(children.length);

    this.setState((prevState) => {
      let newPermissions = {};
      // for selecting/deselecting child permissions
      if (hasChildren) {
        children.forEach((child) => {
          newPermissions[child] =
            prevState.data.groupGlobalPermissionsConfig[configId] === value
              ? ACCESS_TYPES.NONE
              : value;
        });
        newPermissions[configId] =
          prevState.data.groupGlobalPermissionsConfig[configId] === value
            ? ACCESS_TYPES.NONE
            : value;
      } else {
        newPermissions = {
          [configId]:
            prevState.data.groupGlobalPermissionsConfig[configId] === value
              ? ACCESS_TYPES.NONE
              : value,
        };
      }
      return {
        data: {
          ...prevState.data,
          groupGlobalPermissionsConfig: {
            ...prevState.data.groupGlobalPermissionsConfig,
            ...newPermissions,
          },
        },
      };
    });
  };

  onFleetsSelectAllClick = (list) =>
    this.setState((prevState) => {
      const { allCompanyFleets = [] } = this.props;
      const permissionList = prevState.data[list];
      return {
        data: {
          ...prevState.data,
          [list]:
            allCompanyFleets.length === permissionList.length
              ? []
              : [...allCompanyFleets.map((fleet) => fleet.id)],
        },
      };
    });

  handleFleetPermissionsListChange = (fleets, permissionList) =>
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [permissionList]: fleets,
      },
    }));

  handleChangeName = ({ target: { value: name } }) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        name,
      },
    }));
  };

  onChangeEnrollmentEmailField = (fieldName, value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        enrollmentEmail: {
          ...prevState.data.enrollmentEmail,
          [fieldName]: value,
        },
      },
    }));
  };

  addUser = (username) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        userList: [...state.data.userList, username],
      },
    }));
  };

  removeUser = (username) => {
    let newUserList = this.state.data.userList;
    newUserList.splice(
      this.state.data.userList.indexOf(username),
      this.state.data.userList.indexOf(username) + 1
    );
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        userList: newUserList,
      },
    }));
  };

  handleSubmit = () => {
    const { data } = this.state;

    const {
      groupUsers = [],
      userGroupPermissions: {
        groupGlobalPermissionsConfig = {},
        fleetsViewSpecific = [],
        fleetsRemoteControlEnabled = [],
        globalPermissionsReadList = [],
        globalPermissionsReadWriteList = [],
      } = {},
    } = this.props;

    const headings = [
      GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS_AND_APP_SETTINGS_ACCESS,
      GLOBAL_READ_READWRITE_PERMISSIONS.PROFILE_ACCESS,
    ];
    const groupGlobalPermissionsConfigSortedByAccess = sortGroupGlobalPermissionsConfigByAccess(
      data.groupGlobalPermissionsConfig
    );
    const readList =
      groupGlobalPermissionsConfigSortedByAccess[ACCESS_TYPES.READ];
    const readWriteList =
      groupGlobalPermissionsConfigSortedByAccess[ACCESS_TYPES.READ_WRITE];
    const globalPermissionsReadListFiltered = readList.filter(
      (item) => item !== 'fleetAccess' && !headings.includes(item)
    );
    const globalPermissionsReadWriteListFiltered = readWriteList.filter(
      (item) => item !== 'fleetAccess' && !headings.includes(item)
    );

    const hasViewSpecificFleets = notEmpty(data.fleetsViewSpecificList);
    // Why is fleet access auto enabled? Two reasons:
    // 1. To provide model level access to fleets on the backend when profiles have some read/write access toggled.
    // This is needed so as to provide access to fleets for profile editing mode. see MVP-1405 comment from Ivan
    // on 29th September 2021.
    // 2. In case the user doesn't check the fleetAccess checkbox but adds fleets to the view specific list.
    // Without checking that box, view specific won't work.
    const hasSomeProfileReadWriteAccess = notEmpty(
      globalPermissionsReadWriteListFiltered
    );
    const isFleetReadAccessAutoEnabled =
      data.groupGlobalPermissionsConfig.fleetAccess === ACCESS_TYPES.NONE &&
      (hasViewSpecificFleets || hasSomeProfileReadWriteAccess);

    let profileAccess;
    // Why is profile access auto enabled?
    // 1. To provide model level access to profiles on the backend when profiles have some read/write access toggled.
    // This is needed so as to provide access to profiles for assigning profiles to fleets (on fleets page).
    // see MVP-1405 comment from Ivan on 29th September 2021.
    const isProfileReadAccessAutoEnabled =
      data.groupGlobalPermissionsConfig.fleetAccess === ACCESS_TYPES.READ_WRITE;
    const isProfileReadAccessEnabled =
      notEmpty(globalPermissionsReadListFiltered) ||
      isProfileReadAccessAutoEnabled;
    const isProfileReadWriteAccessEnabled = notEmpty(
      globalPermissionsReadWriteListFiltered
    );
    const isProfileAccessEnabled =
      isProfileReadAccessEnabled || isProfileReadWriteAccessEnabled;
    if (isProfileAccessEnabled) {
      if (isProfileReadWriteAccessEnabled) {
        profileAccess = ACCESS_TYPES.READ_WRITE;
      } else {
        profileAccess = ACCESS_TYPES.READ;
      }
    } else {
      profileAccess = ACCESS_TYPES.NONE;
    }

    if (this.isEditActivity) {
      let initialProfileAccess;
      const isProfileReadAccessInitiallyEnabled = notEmpty(
        globalPermissionsReadList
      );
      const isProfileReadWriteAccessInitiallyEnabled = notEmpty(
        globalPermissionsReadWriteList
      );
      const isProfileAccessInitiallyEnabled =
        isProfileReadAccessInitiallyEnabled ||
        isProfileReadWriteAccessInitiallyEnabled;
      if (isProfileAccessInitiallyEnabled) {
        if (isProfileReadWriteAccessInitiallyEnabled) {
          initialProfileAccess = ACCESS_TYPES.READ_WRITE;
        } else {
          initialProfileAccess = ACCESS_TYPES.READ;
        }
      } else {
        initialProfileAccess = ACCESS_TYPES.NONE;
      }
      const {
        removed: removeUserList,
        added: userList,
      } = getDifferenceBetweenTwoArrays(
        groupUsers.map((user) => user.username),
        data.userList
      );
      const {
        removed: removeFleetsViewSpecificList,
        added: fleetsViewSpecificList,
      } = getDifferenceBetweenTwoArrays(
        fleetsViewSpecific,
        data.fleetsViewSpecificList
      );
      const {
        removed: removeFleetsRemoteControlEnabledList,
        added: fleetsRemoteControlEnabledList,
      } = getDifferenceBetweenTwoArrays(
        fleetsRemoteControlEnabled,
        data.fleetsRemoteControlEnabledList
      );
      const {
        removed: removeGlobalReadPermissionsList,
        added: globalReadPermissionsList,
      } = getDifferenceBetweenTwoArrays(
        globalPermissionsReadList,
        globalPermissionsReadListFiltered
      );
      const {
        removed: removeGlobalReadWritePermissionsList,
        added: globalReadWritePermissionsList,
      } = getDifferenceBetweenTwoArrays(
        globalPermissionsReadWriteList,
        globalPermissionsReadWriteListFiltered
      );
      const {
        removed: removeFleetAccessModelGlobalPermissionsList,
        added: fleetAccessModelGlobalPermissionsList,
      } = getDifferenceBetweenTwoArrays(
        ALL_FLEET_TREE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[
          groupGlobalPermissionsConfig.fleetAccess
        ],
        ALL_FLEET_TREE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[
          data.groupGlobalPermissionsConfig.fleetAccess
        ]
      );
      const {
        removed: removeDeviceAccessModelGlobalPermissionsList,
        added: deviceAccessModelGlobalPermissionsList,
      } = getDifferenceBetweenTwoArrays(
        ALL_DEVICE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[
          groupGlobalPermissionsConfig.fleetAccess
        ],
        ALL_DEVICE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[
          data.groupGlobalPermissionsConfig.fleetAccess
        ]
      );
      const {
        removed: removeProfileAccessModelGlobalPermissionsList,
      } = getDifferenceBetweenTwoArrays(
        ALL_PROFILE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[initialProfileAccess],
        ALL_PROFILE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[profileAccess]
      );

      const {
        removed: removeProfileV2AccessModelGlobalPermissionsList,
      } = getDifferenceBetweenTwoArrays(
        ALL_PROFILE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[initialProfileAccess],
        ALL_PROFILE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[profileAccess]
      );

      return this.props.editGroup(this.groupId, {
        ...data,
        modelGlobalPermissionsList: [
          ...(isFleetReadAccessAutoEnabled
            ? ALL_FLEET_TREE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[
                ACCESS_TYPES.READ
              ]
            : fleetAccessModelGlobalPermissionsList),
          ...deviceAccessModelGlobalPermissionsList,
          ...ALL_PROFILE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[profileAccess],
          ...ALL_PROFILE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[profileAccess],
        ],
        removeModelGlobalPermissionsList: [
          ...removeFleetAccessModelGlobalPermissionsList,
          ...removeDeviceAccessModelGlobalPermissionsList,
          ...removeProfileAccessModelGlobalPermissionsList,
          ...removeProfileV2AccessModelGlobalPermissionsList,
        ],
        globalReadPermissionsList,
        globalReadWritePermissionsList,
        removeGlobalReadPermissionsList,
        removeGlobalReadWritePermissionsList,
        removeUserList,
        userList,
        removeFleetsViewSpecificList,
        fleetsViewSpecificList,
        removeFleetsRemoteControlEnabledList,
        fleetsRemoteControlEnabledList,
      });
    }
    return this.props.createGroup({
      ...data,
      globalPermissionsReadListFiltered,
      globalPermissionsReadWriteList: globalPermissionsReadWriteListFiltered,
      modelGlobalPermissionsList: [
        ...(isFleetReadAccessAutoEnabled
          ? ALL_FLEET_TREE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[
              ACCESS_TYPES.READ
            ]
          : ALL_FLEET_TREE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[
              data.groupGlobalPermissionsConfig.fleetAccess || ACCESS_TYPES.NONE
            ]),
        ...ALL_DEVICE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[
          data.groupGlobalPermissionsConfig.fleetAccess || ACCESS_TYPES.NONE
        ],
        ...ALL_PROFILE_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[profileAccess],
        ...ALL_PROFILE_V2_MODEL_GLOBAL_PERMISSIONS_BY_ACCESS[profileAccess],
      ],
    });
  };

  render() {
    return (
      <Dialog
        header={this.renderHeader}
        menu={this.renderMenu}
        content={this.renderContent}
        onBack={() => {
          history.goBack();
        }}
        isFullViewportVisible
      />
    );
  }

  renderHeader = () => {
    return (
      <Fragment>
        <div className={'profile-header'}>
          <div>
            <h6 className={'profile-header__title'}>
              {this.isEditActivity
                ? I18n.t('users.groupEditingHeading')
                : I18n.t('users.groupCreationHeading')}
            </h6>
            <div className={'profile-header__caption-text'}>
              {this.isEditActivity ? this.groupId : this.state.data.name || ''}
            </div>
          </div>
        </div>
        <div className={'profile-actions__section'}>
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Button path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}`}>
              {I18n.t('common.cancel')}
            </Button>
            <Button style={{ marginLeft: '5px' }} onClick={this.handleSubmit}>
              {this.isEditActivity
                ? I18n.t('users.update')
                : I18n.t('users.create')}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  };

  renderMenu = () => {
    const { allDataForCreationLoaded, allDataForEditLoaded } = this.props;
    if (allDataForCreationLoaded || allDataForEditLoaded) {
      return <DialogMenu structure={this.dialogMenuItems()} />;
    }
  };

  renderContent = () => {
    const { allDataForEditLoaded, allDataForCreationLoaded } = this.props;
    if (
      (this.isEditActivity && !allDataForEditLoaded) ||
      !allDataForCreationLoaded
    ) {
      return <Throbber />;
    }
    return (
      <div className={'dialog-content'}>
        <Switch>
          <Route
            exact
            path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}${UsersRoutes.CREATE}/${createGroupRouteConstants.PERMISSIONS}`}
            component={this.renderPermissions}
          />
          <Route
            exact
            path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/create/${createGroupRouteConstants.USER_MANAGEMENT}`}
            component={this.renderUsers}
          />
          {/*<Route exact path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/create/inviteMethod`} component={this.renderInviteMethods} />*/}
          {/*<Route exact path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/create/enrollmentEmail`} component={this.renderEnrollmentEmail} />*/}
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  };

  renderPermissions = () => {
    const {
      data: {
        name = '',
        fleetsRemoteControlEnabledList,
        fleetsViewSpecificList,
        groupGlobalPermissionsConfig = {},
      } = {},
    } = this.state;
    let { modelLevelPermissionsData, allCompanyFleets = [] } = this.props;
    const groupGlobalPermissionsWithAccesses = getGroupGlobalPermissionsWithAccesses(
      permissionTableItems,
      groupGlobalPermissionsConfig
    );
    return (
      <Permission
        name={name}
        onChangeName={this.handleChangeName}
        modelLevelPermissionsData={modelLevelPermissionsData}
        onGlobalPermissionsChange={this.handleGlobalPermissionsChange}
        onFleetsSelectAllClick={this.onFleetsSelectAllClick}
        onFleetPermissionsListChange={this.handleFleetPermissionsListChange}
        globalPermissionsList={groupGlobalPermissionsWithAccesses}
        groupGlobalPermissionsConfig={groupGlobalPermissionsConfig}
        allFleets={allCompanyFleets}
        fleetsViewSpecificList={fleetsViewSpecificList}
        fleetsRemoteControlEnabledList={fleetsRemoteControlEnabledList}
      />
    );
  };

  // renderInviteMethods = () => {
  //     return (
  //       <InviteMethods />
  //     )
  // }
  //

  renderEnrollmentEmail = () => {
    const {
      data: { enrollmentEmail: { subject = '', message = '' } = {} } = {},
    } = this.state;
    return (
      <EnrollmentEmail
        emailSubject={subject}
        message={message}
        onChangeEnrollmentEmailField={this.onChangeEnrollmentEmailField}
      />
    );
  };

  renderUsers = () => {
    let { data: { userList = [] } = {} } = this.state;
    let { allUsers } = this.props;
    return (
      <UserManagement
        existingUsers={allUsers}
        addUser={this.addUser}
        removeUser={this.removeUser}
        userList={userList}
      />
    );
  };
}

const mapStateToProps = (state) => {
  const companyFleetsFullListStatus = state.groups.companyFleetsFullListStatus;
  const allUsersStatus = state.groups.allUsersStatus;
  const groupUsersStatus = state.users.status;
  const modelLevelPermissionsStatus = state.groups.modelLevelPermissionsStatus;
  const userGroupPermissionsStatus = state.users.userGroupPermissionsStatus;
  const allDataForCreationLoaded =
    modelLevelPermissionsStatus === NetworkStatus.DONE &&
    allUsersStatus === NetworkStatus.DONE &&
    companyFleetsFullListStatus === NetworkStatus.DONE;
  const allDataForEditLoaded =
    allDataForCreationLoaded &&
    userGroupPermissionsStatus === NetworkStatus.DONE &&
    groupUsersStatus === NetworkStatus.DONE;

  return {
    createGroupStatus: state.groups.createGroupStatus,
    error: state.groups.createGroupError,
    modelLevelPermissionsError: state.groups.modelLevelPermissionsError,
    allUsers: state.groups.allUsersData,
    allUsersStatus,
    usersError: state.groups.allUsersError,
    companyFleetsFullListStatus,
    allDataForCreationLoaded,
    allDataForEditLoaded,
    userGroupPermissions: state.users.userGroupPermissionsData,
    modelLevelPermissionsData: state.groups.modelLevelPermissionsData,
    modelLevelPermissionsStatus,
    groupUsers: [...state.users.data],
    groupUsersStatus,
    allCompanyFleets: allCompanyFleetsSelector(state),
    companyId: companyIdSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: (groupId) => dispatch(loadUsers(groupId)),
    loadAllUsers: () => dispatch(loadAllUsers()),
    loadCompanyFleetsFullList: () => dispatch(loadCompanyFleetsFullList()),
    loadUserGroupPermissions: (groupId) =>
      dispatch(loadUserGroupPermissions(groupId)),
    createGroup: (data) => dispatch(createGroup(data)),
    createGroupReset: () => dispatch(createGroupReset()),
    editGroup: (groupId, data) => dispatch(editGroup(groupId, data)),
    loadGroupsModelLevelPermissions: () =>
      dispatch(loadGroupsModelLevelPermissions()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateUserGroup);
