import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import {
  alertConditionHints,
  batteryConditionComparisonOperatorOptions,
  genericOnOffConditionOptions,
} from '../../features/createAlert/createAlert.constants';
import { SelectField } from '../selectField.component';
import { AlertTypeTypes } from '../../features/alerts/alerts.constants';
import { SectionDescription } from '../sectionDescription.component';
import { InputField } from '../inputField.component';

const AlertCondition = ({ alert = {}, onPropertyChange }) => {
  const onConditionTypeChange = ({ target: { value, name } = {} }) =>
    onPropertyChange({
      key: 'alertCondition',
      value: { ...(alert.alertCondition || {}), [name]: value },
    });
  const onConditionValueChange = ({ target: { value, name } = {} }) =>
    onPropertyChange({
      key: 'alertCondition',
      value: {
        ...(alert.alertCondition || {}),
        conditionValues: {
          ...((alert.alertCondition && alert.alertCondition.conditionValues) ||
            {}),
          [name]: value,
        },
      },
    });

  const renderBatteryCondition = () => {
    const {
      alertCondition: {
        conditionType,
        conditionValues: { percentage } = {},
      } = {},
    } = alert;
    return (
      <section>
        <SelectField
          title={I18n.t('alerts.condition')}
          value={conditionType}
          onChange={onConditionTypeChange}
          options={Object.values(batteryConditionComparisonOperatorOptions)}
          name="conditionType"
        />
        <InputField
          name="percentage"
          label={I18n.t('alerts.percentage')}
          onChange={onConditionValueChange}
          value={percentage}
          type="number"
          min="0"
          max="100"
          step="1"
        />
      </section>
    );
  };

  const renderGenericOnOffCondition = () => {
    const { alertCondition: { conditionType } = {} } = alert;
    return (
      <section>
        <SelectField
          value={conditionType}
          onChange={onConditionTypeChange}
          options={Object.values(genericOnOffConditionOptions)}
          name="conditionType"
        />
      </section>
    );
  };

  const conditions = {
    [AlertTypeTypes.BATTERY]: renderBatteryCondition,
  };

  return (
    <React.Fragment>
      <SectionDescription
        title={I18n.t('alerts.setCondition')}
        hint={<Translate value={alertConditionHints[alert.alertType].text} />}
      />
      {conditions[alert.alertType]
        ? conditions[alert.alertType]()
        : renderGenericOnOffCondition()}
    </React.Fragment>
  );
};

export default AlertCondition;
