import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { devicesSendRemoteCommandReset } from './devices.actions';
import { subscribeChanges, unsubscribeChanges } from './devices.sockets';
import { loadDevices } from './devices.thunk';
import { companyIdSelector } from '../login/login.selectors';
import wsServices from '../../utils/websocketsServices';
import { confirmAlert } from 'react-confirm-alert';
import { NetworkStatus } from '../../common/constants';
import { Button } from '../../components/button.component';
import { globalPermissionsForUserSelector } from '../../selectors/accountSelectors';
import { getDevicesTableColumns } from '../../common/utilities/devices';
import UserHelpers from '../../common/utilities/user';
import DeviceCommands from './components/DeviceCommands';
import DataTable from '../../components/DataTable/DataTable.component';
import { Pages } from '../../constants/pages';
import { loadFleetConfigById } from '../fleets/fleetsV2.thunk';
import { fleetsV2Selector } from '../fleets/fleetsV2.selectors';
import { bannerSelector } from '../Banner/banner.selector';
import { getAllDevicesTableColumns } from './components/Devices/devicesColumns';
import { setDeviceData } from '../device/device.actions';
import { deviceSelector } from '../device/device.selectors';
import { notEmpty } from '../../common/helpers';

class Devices extends Component {
  state = {
    selectedDevices: new Set(),
  };

  tableRef = React.createRef();

  get allDevicesTableColumns() {
    const {
      globalPermissionsForUser = {},
      hasAdminPrivileges = false,
    } = this.props;
    return getDevicesTableColumns(
      globalPermissionsForUser,
      getAllDevicesTableColumns,
      hasAdminPrivileges
    );
  }

  get items() {
    return this.props.devices;
  }

  componentDidMount() {
    if (
      this.props.loadingStatus !== NetworkStatus.STARTED &&
      this.props.loadingStatus !== NetworkStatus.DONE
    ) {
      this.loadDevices();
    }
    // this.props.subscribeChanges(this.props.companyId);
  }

  loadDevices = () => this.props.loadAllDevices(this.props.companyId);

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      notEmpty(this.props.remoteCommandStatus) &&
      this.props.remoteCommandStatus !== NetworkStatus.NONE &&
      this.props.remoteCommandStatus !== prevProps.remoteCommandStatus
    ) {
      this.onChangeStatus();
    }
    if (
      this.props.removeStatus !== prevProps.removeStatus &&
      this.props.removeStatus !== NetworkStatus.NONE
    ) {
      this.onChangeStatus();
    }
  }

  componentWillUnmount() {
    this.props.unsubscribeChanges(this.props.companyId);
  }

  onOpenItem = ({ id: deviceId }) => {
    const device =
      this.props.devices.find((device) => device.id === deviceId) ?? {};
    this.props.setDeviceData(device);
    this.resetSelectedDevices();
  };

  render() {
    const showToolbar = this.state.selectedDevices.size;
    const contentClassName = showToolbar
      ? 'app__content app__content--with-toolbar'
      : 'app__content';

    return (
      <Fragment>
        <div className={contentClassName}>
          <div className={'page-content'}>
            <div className={'card'}>
              <DataTable
                allColumns={this.allDevicesTableColumns}
                data={this.props.devices}
                loadingStatus={this.props.loadingStatus}
                onOpenItem={this.onOpenItem}
                onSelect={this.select}
                pageId={Pages.ALL_DEVICES}
                tableRef={this.tableRef}
                useResizableColumns
                allColumnsResizable
                selectable
                sortable
                useFilter
                useColumnsToggle
                useCSVExport
              />
            </div>
          </div>
        </div>
        {this.renderToolbar(showToolbar)}
      </Fragment>
    );
  }

  renderToolbar = (showToolbar) => {
    if (showToolbar) {
      return (
        <DeviceCommands
          devices={[...this.state.selectedDevices.values()]}
          customConfirmParams={{
            onRemoveDevicesConfirm: this.resetSelectedDevices,
          }}
          updateListFunction={this.loadDevices}
          clearSelectionOnSuccessFunction={this.resetSelectedDevices}
          selectedDevices={this.state.selectedDevices}
          hideMoveDeviceTool
        />
      );
    }
  };

  renderStatus = ({ onClose }) => {
    // TODO: refactor alerts, wrap with component.
    let status = NetworkStatus.NONE;
    let title = '';
    let text = I18n.t('common.inProgress');
    let color;
    if (this.props.remoteCommandStatus !== NetworkStatus.NONE) {
      status = this.props.remoteCommandStatus;
      title = I18n.t('devices.sendingRemoteCommandTitle');
    } else if (this.props.removeStatus !== NetworkStatus.NONE) {
      status = this.props.removeStatus;
      title = I18n.t('devices.removeDeviceTitle');
    }
    if (status === NetworkStatus.DONE) {
      color = '#00ff00';
      text = I18n.t('common.successful');
    }
    if (status === NetworkStatus.ERROR) {
      color = '#ff0000';
      text = I18n.t('common.error');
    }

    const onClick = () => {
      // TODO: investigate how to compose onClose handler, or rework confirmAlert logic
      onClose();
      this.props.devicesSendRemoteCommandReset();
    };
    return (
      <div className="create-enrollment-alert">
        <p>{title}</p>
        <p style={{ color: color }}>{text}</p>
        <Button onClick={onClick}>{I18n.t('common.ok')}</Button>
      </div>
    );
  };

  onChangeStatus = () => {
    confirmAlert({ customUI: this.renderStatus });
  };

  select = (selectedIds) => {
    const selectedDevices = new Set(selectedIds);
    this.setState(() => ({ selectedDevices }));
  };

  resetSelectedDevices = () =>
    this.tableRef.current?.toggleAllRowsSelected?.(false);
}

const mapStateToProps = (state) => {
  const { fleetConfigStatus, fleetConfigError, fleetConfig } = fleetsV2Selector(
    state
  );
  const { data: device } = deviceSelector(state);
  return {
    fleetConfigStatus,
    fleetConfigError,
    fleetConfig,
    bannerData: bannerSelector(state),
    devices: state.devices.devices,
    loadingStatus: state.devices.devicesLoadingStatus,
    removeStatus: state.devices.devicesRemoveStatus,
    companyId: companyIdSelector(state),
    remoteCommandStatus: state.devices.remoteCommandStatus,
    visibleColumns: state.columnsToggle.visibleColumnsLists[Pages.ALL_DEVICES],
    globalPermissionsForUser: globalPermissionsForUserSelector(state),
    // globalPermissionsForUser: globalPermissionsMock,
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeviceData: (data) => dispatch(setDeviceData(data)),
    loadAllDevices: () => dispatch(loadDevices()),
    subscribeChanges: (companyId) =>
      dispatch(
        subscribeChanges(
          `${wsServices.subscribeDevicesUpdates}${companyId}/`,
          'devices.update'
        )
      ),
    unsubscribeChanges: (companyId) =>
      dispatch(
        unsubscribeChanges(`${wsServices.subscribeDevicesUpdates}${companyId}/`)
      ),
    devicesSendRemoteCommandReset: () =>
      dispatch(devicesSendRemoteCommandReset()),
    loadFleetConfigById: (params) => dispatch(loadFleetConfigById(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
