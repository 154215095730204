import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useSelector } from 'react-redux';
import { Modal } from '../../../../components/modal.component';
import { Button } from '../../../../components/button.component';
import MultiCheckBox from '../../../../components/MultiCheckBox/MultiCheckBox.component';
import { getProfilesById } from '../../../../common/utilities/profile';
import {
  profilesSelector,
  profilesV2Selector,
} from '../../../../selectors/profiles';
import AsyncRendererWithDataFetching from '../../../../components/AsyncRenderer/AsyncRendererWithDataFetching.component';
import { DataConstants } from '../../../../constants/data';
import { devicesSelector } from '../../devices.selectors';
import { useThunks } from '../../../../hooks/useThunks';
import { getDifferenceBetweenTwoArrays } from '../../../../common/helpers';

const ProfileAssignmentModal = ({
  isProfileAssignmentModalShown,
  toggleProfileAssignmentModal,
  fleet: { id: fleetId } = {},
}) => {
  const { data: profiles } = useSelector(profilesSelector);
  const { data: profilesV2 } = useSelector(profilesV2Selector);
  const {
    fleetProfilesV2,
    fleetProfilesV2Status,
    fleetProfilesV2Error,

    fleetProfilesV1,
    fleetProfilesV1Status,
    fleetProfilesV1Error,
  } = useSelector(devicesSelector);

  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [isAssignButtonActive, setIsAssignButtonActive] = useState(false);
  const {
    loadProfilesByFleetV2,
    loadProfilesByFleetV1,
    profilesAssignmentToFleet,
  } = useThunks();
  const allProfiles = [...profilesV2, ...profiles];
  const fleetProfilesV2Ids = fleetProfilesV2.map(({ id }) => id);
  const fleetProfilesV1Ids = fleetProfilesV1.map(({ id }) => id);

  const onProfilesSelectionChange = (profiles) => {
    setSelectedProfiles(profiles);
    setIsAssignButtonActive(true);
  };

  const assignProfiles = () => {
    const selectedV1Profiles = getProfilesById(profiles, selectedProfiles).map(
      ({ id }) => id
    );
    const selectedV2Profiles = getProfilesById(
      profilesV2,
      selectedProfiles
    ).map(({ id }) => id);
    const {
      added: profilesForAssignmentV2,
      removed: profilesForUnassignmentV2,
    } = getDifferenceBetweenTwoArrays(fleetProfilesV2Ids, selectedV2Profiles);
    const {
      added: profilesForAssignmentV1,
      removed: profilesForUnassignmentV1,
    } = getDifferenceBetweenTwoArrays(fleetProfilesV1Ids, selectedV1Profiles);
    setIsAssignButtonActive(false);
    profilesAssignmentToFleet({
      profilesForAssignmentV2,
      profilesForUnassignmentV2,
      profilesForAssignmentV1,
      profilesForUnassignmentV1,
      fleetId,
      onClose: toggleProfileAssignmentModal,
    });
  };

  return (
    <>
      {isProfileAssignmentModalShown ? (
        <Modal
          title={I18n.t('fleets.assignProfiles')}
          actionsSection={
            <Button disabled={!isAssignButtonActive} onClick={assignProfiles}>
              {I18n.t('fleets.profileAssignmentModalConfirmationButtonText')}
            </Button>
          }
          onBack={toggleProfileAssignmentModal}
        >
          <AsyncRendererWithDataFetching
            dataList={[
              {
                id: DataConstants.PROFILES_V2,
                params: {
                  loadFullList: true,
                  isInitialLoad: true,
                },
              },
              {
                id: DataConstants.PROFILES_V1,
              },
            ]}
            customDataStatus={{
              [DataConstants.FLEET_PROFILES_V2]: fleetProfilesV2Status,
              [DataConstants.FLEET_PROFILES_V1]: fleetProfilesV1Status,
            }}
            customDataError={{
              [DataConstants.FLEET_PROFILES_V2]: fleetProfilesV2Error,
              [DataConstants.FLEET_PROFILES_V1]: fleetProfilesV1Error,
            }}
            customDataFetchFunctions={{
              [DataConstants.FLEET_PROFILES_V2]: () =>
                loadProfilesByFleetV2({
                  fleetId,
                }),
              [DataConstants.FLEET_PROFILES_V1]: () =>
                loadProfilesByFleetV1({
                  fleetId,
                }),
            }}
          >
            <section className="profile-assignment">
              <MultiCheckBox
                allOptions={allProfiles.map(({ id, name }) => ({
                  id,
                  label: name,
                }))}
                defaultSelectedOptions={[
                  ...fleetProfilesV2Ids,
                  ...fleetProfilesV1Ids,
                ]}
                onChange={onProfilesSelectionChange}
              />
            </section>
          </AsyncRendererWithDataFetching>
        </Modal>
      ) : null}
    </>
  );
};

export default ProfileAssignmentModal;
