import React, { useState, useCallback } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
import { isInclusivelyAfterDay } from 'react-dates';
import LocationHistoryLogs from './LocationHistoryLogs';
import LocationHistoryMap from './LocationHistoryMap.component';
import { SectionDescription } from '../../../components/sectionDescription.component';
import ExportToCSV from '../../../components/ExportToCSV/ExportToCSV.component';
import { locationLogsTableColumns } from '../device.constants';
import { loadDeviceLocationLogs } from '../device.thunk';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker.component';
import { Button } from '../../../components/button.component';
import { isEmpty, isLoadingStatusDone } from '../../../common/helpers';
import { Throbber } from '../../../components/Throbber';
import { sendRemoteCommand } from '../../devices/devices.thunk';
import { companyIdSelector } from '../../login/login.selectors';
import 'ol/ol.css';
import { deviceSelector } from '../device.selectors';
import PopupActions from '../../popup/popup.actions';

export const LocationHistory = ({
  device: { id } = {},
  loadDeviceLocationLogs,
  deviceLocationLogs,
  loadingStatus,
  sendRemoteCommand,
  companyId,
}) => {
  const [isDateRangeChosen, setIsDateRangeChosen] = useState(false);
  const loadLogs = (startDate, endDate) =>
    loadDeviceLocationLogs({
      deviceId: id,
      startDate: startDate?.format(),
      endDate: endDate?.format(),
    });

  const onRangeChange = useCallback(
    ({ startDate, endDate }) => {
      setIsDateRangeChosen(startDate && endDate);
      loadLogs(startDate, endDate);
    },
    [loadLogs]
  );

  const handleRetrieveHistoryClick = () =>
    sendRemoteCommand({
      companyId,
      command: 'retrieve_location_history',
      devicesIds: [id],
      onSuccess: () => {
        PopupActions.showAlert({
          text: I18n.t('device.updateRequestConfirmationText'),
        });
        setTimeout(() => {
          loadLogs();
        }, 30_000);
      },
    });

  const isActionItemDisabled =
    !isLoadingStatusDone(loadingStatus) || isEmpty(deviceLocationLogs);

  return (
    <section className="location-history">
      <SectionDescription title={I18n.t('device.locationHistory')} />
      <section className="location-history__header-area">
        <DateRangePicker
          onRangeChange={onRangeChange}
          startDatePlaceholderText={I18n.t('device.locationLogsStartDate')}
          endDatePlaceholderText={I18n.t('device.locationLogsEndDate')}
          initialVisibleMonth={() => moment().subtract(1, 'month')}
          minDate={moment().subtract(1, 'months').startOf('month')}
          maxDate={moment().endOf('month')}
          isOutsideRange={(day) =>
            isInclusivelyAfterDay(day, moment().add(1, 'day')) ||
            !isInclusivelyAfterDay(day, moment().subtract(1, 'months'))
          }
          disabled={!isLoadingStatusDone(loadingStatus)}
          showClearDates
          showDefaultInputIcon
          inputIconPosition="after"
          anchorDirection="left"
          allowSameDayRange
          small
        />
        <div />
        <ExportToCSV
          title={I18n.t('device.csvExportIconHint')}
          data={deviceLocationLogs}
          columns={locationLogsTableColumns}
          fileName="locationLogs.csv"
          disabled={isActionItemDisabled}
        />
        <Button secondary onClick={handleRetrieveHistoryClick}>
          {I18n.t('device.updateHistory')}
        </Button>
      </section>
      {!isLoadingStatusDone(loadingStatus) ? (
        <Throbber />
      ) : isEmpty(deviceLocationLogs) ? (
        <Translate
          value={
            isDateRangeChosen
              ? 'device.noHistoryLogEntriesForRange'
              : 'device.noHistoryLogEntries'
          }
        />
      ) : (
        <>
          <LocationHistoryMap />
          <LocationHistoryLogs />
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  const { locationLogs, locationLogsLoadingStatus } = deviceSelector(state);
  return {
    deviceLocationLogs: locationLogs,
    // loadingStatus: 'STARTED',
    loadingStatus: locationLogsLoadingStatus,
    companyId: companyIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadDeviceLocationLogs: (params) => dispatch(loadDeviceLocationLogs(params)),
  sendRemoteCommand: (params) => dispatch(sendRemoteCommand(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationHistory);
