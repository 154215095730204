import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { SwitchField } from '../../../components/switchField.component';
import { AttributeType } from '../../../common/constants';
import { Translate, I18n } from 'react-redux-i18n';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { isEmpty } from '../../../common/helpers';
import PolicyActivationToggle from './PolicyActivationToggle.component';

export const initialSettings = {
  allowInstallationsFromUnknownSources: false,
  disableBluetooth: false,
  disableCamera: false,
  disableUsb: false,
  disableGooglePlay: false,
  disableSafeBoot: false,
  disableWifiHotspot: false,
  disableFactoryReset: false,
  disableHardReset: false,
  disableTurnOffWifi: false,
  disableAirplaneMode: false,
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'feature_control',
  isActive: false,
  policyData: null,
};

export const activationAttribute = [
  {
    key: 'isActive',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.statusHeader',
  },
];

export const featureControlAttributes = [
  {
    key: 'disableBluetooth',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableBluetooth',
  },
  {
    key: 'allowInstallationsFromUnknownSources',
    type: AttributeType.BOOLEAN,
    titleCode:
      'profiles.featureControl.fields.allowInstallationsFromUnknownSources',
    informationIconText: 'Android 8.0+ only',
  },
  {
    key: 'disableCamera',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableCamera',
  },
  {
    key: 'disableUsb',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableUsb',
  },
  {
    key: 'disableGooglePlay',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableGooglePlay',
  },
  {
    key: 'disableSafeBoot',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableSafeBoot',
  },
  {
    key: 'disableWifiHotspot',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableWifiHotspot',
  },
  {
    key: 'disableFactoryReset',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableFactoryReset',
  },
  {
    key: 'disableHardReset',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableHardReset',
  },
  {
    key: 'disableTurnOffWifi',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableTurnOffWifi',
  },
  {
    key: 'disableAirplaneMode',
    type: AttributeType.BOOLEAN,
    titleCode: 'profiles.featureControl.fields.disableAirplaneMode',
  },
];

export class FeatureControlComponent extends Component {
  onToggle = (index, key) => () => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings ? item.settings : { ...initialSettings };
    settings[key] = !settings[key];
    item.settings = settings;
    this.props.onChange('featureControl', index, item);
  };

  renderField = (settings, index) => ({
    key,
    type,
    titleCode,
    informationIconText,
  }) => {
    switch (type) {
      case AttributeType.BOOLEAN: {
        return (
          <SwitchField
            key={`feature-control__${index}__${key}`}
            onClick={this.onToggle(index, key)}
            on={settings?.[key] || false}
            titleCode={titleCode}
            informationIconText={informationIconText}
          />
        );
      }
      default:
        return null;
    }
  };

  renderSettings = ({ settings, isActive }, index) => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      this.props.onChangePolicyProperty({
        key: 'featureControl',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });

    return (
      <div
        key={`feature-control-settings__${index}`}
        className={'feature-control'}
      >
        <div className={'section-description'}>
          <Translate
            className={'section-description__title--small'}
            value={'profiles.featureControl.title'}
          />
          <Translate value={'profiles.featureControl.description'} />
        </div>
        <PolicyActivationToggle
          key="policyActivation"
          policy={this.props.policy}
          titleCode="profiles.featureControl.statusHeader"
          handleToggle={onEnableButtonToggle}
        />
        {isActive
          ? featureControlAttributes.map(this.renderField(settings, index))
          : null}
      </div>
    );
  };

  renderContent = () => {
    return this.props.policy.length
      ? this.props.policy.map(this.renderSettings)
      : this.renderSettings(
          { ...initialPolicy, settings: { ...initialSettings } },
          0
        );
  };

  getField = (settings, index) => ({
    key,
    type,
    titleCode,
    informationIconText,
  }) => ({
    id: `${key}-${index}`,
    title: I18n.t(titleCode),
    value: settings[key],
    type,
    informationIconText,
  });

  getSettings = ({ settings, isActive }, index) =>
    (isActive ? featureControlAttributes : []).map(
      this.getField(settings, index)
    );

  renderReadOnlyContent = () => {
    const { policy = [] } = this.props;
    if (isEmpty(policy)) {
      return <p>{I18n.t('profiles.featureControl.noFeatureControlRules')}</p>;
    }

    return [
      <ReadOnlyForm
        key={`read-only-form-status`}
        items={[
          {
            id: 'policy-status',
            title: I18n.t('profiles.featureControl.statusHeader'),
            value: policy[0]?.isActive ?? false,
            type: AttributeType.BOOLEAN,
          },
        ]}
        headerTitle={
          <Translate
            className={'section-description__title--small'}
            value={'profiles.featureControl.title'}
          />
        }
      />,
      ...this.renderPreviews().map((item, index) => (
        <ReadOnlyForm key={`read-only-form-${index}`} items={item} />
      )),
    ];
  };

  renderPreviews = () => {
    const { policy = [] } = this.props;
    return policy.map(this.getSettings);
  };

  render() {
    const { isEditingMode } = this.props;
    return (
      <div className={'feature-control-wrapper'}>
        {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
      </div>
    );
  }
}

export default FeatureControlComponent;

FeatureControlComponent.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
