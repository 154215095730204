import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { DeviceNaming } from './_deviceNaming';
import { SectionDescription } from '../sectionDescription.component';

export const initialSettings = {
  name: 'Device',
  sequence: ['name', ...Array(9).fill('None')],
  separator: '_',
  startingNumber: '0',
};

export class EnrollmentDeviceNaming extends Component {
  onChange = ({ target: { value, id } }) => {
    let settings = this.props.settings
      ? { ...initialSettings, ...this.props.settings }
      : { ...initialSettings };
    settings[id] = value;
    this.props.onChange('deviceNaming', settings);
  };

  onChangeSequence = (index, { target: { value } }) => {
    let settings = this.props.settings
      ? { ...initialSettings, ...this.props.settings }
      : { ...initialSettings };
    const sequence = [...settings.sequence];
    sequence.splice(index, 1, value);
    this.onChange({ target: { value: sequence, id: 'sequence' } });
  };

  renderSettings = (settings) => {
    return (
      <div className={'enrollment-device-naming'}>
        <SectionDescription
          title={I18n.t('enrollments.deviceNamingSectionTitle')}
          hint={I18n.t('enrollments.deviceNamingSectionHint')}
        />
        <DeviceNaming
          className={'enrollment-device-naming__content'}
          name={settings.name}
          separator={settings.separator}
          startingNumber={settings.startingNumber}
          onChange={this.onChange}
          sequence={settings.sequence}
          onChangeSequence={this.onChangeSequence}
        />
      </div>
    );
  };

  renderContent = () => {
    return this.props.settings
      ? this.renderSettings({ ...initialSettings, ...this.props.settings })
      : this.renderSettings(initialSettings);
  };

  render() {
    return (
      <div className={'enrollment-device-naming__container'}>
        {this.renderContent()}
      </div>
    );
  }
}

export default EnrollmentDeviceNaming;

EnrollmentDeviceNaming.propTypes = {
  onChange: PropTypes.func,
  settings: PropTypes.object,
};
