import React, { memo, useState, useEffect } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import omit from 'lodash/omit';
import 'react-dates/initialize';

const DateRange = ({
  onRangeChange,
  allowSameDayRange = false,
  ...restProps
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [changed, setChanged] = React.useState(false);

  const handleDatesChange = ({ startDate, endDate }) => {
    setChanged(true);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    const isRangeCleared = !startDate && !endDate && changed;
    const isRangeChanged = startDate && endDate && changed;
    if (isRangeCleared || isRangeChanged) {
      // Calling moment() on a moment will clone it.
      // Cloning was is necessary because all moments are mutable.
      // The moment objects were cloned in UTC and adjusted to the beginning and end
      // of the day to make sure that the time sent off to the callback in it UTC
      // since time/dates are saved in UTC.
      const startDateStringProcessed = moment(startDate?.format())
        .hours(0)
        .minutes(0)
        .seconds(0)
        .utc();
      const endDateStringProcessed = moment(endDate?.format())
        .hours(23)
        .minutes(59)
        .seconds(59)
        .utc();
      function change() {
        onRangeChange({
          startDate: startDateStringProcessed,
          endDate: endDateStringProcessed,
        });
      }
      change();
      setChanged(false);
    }
  }, [changed, onRangeChange, endDate, startDate]);

  const customProps = {
    ...(allowSameDayRange ? { minimumNights: 0 } : {}),
  };

  const props = omit(restProps, [
    // a list of props not to forward to DateRangePicker
  ]);

  return (
    <div className="App">
      <DateRangePicker
        {...customProps}
        {...props}
        startDate={startDate}
        startDateId="start-date"
        endDate={endDate}
        endDateId="end-date"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      />
    </div>
  );
};

export default memo(DateRange);
