import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../common/constants';
import { DeviceStatus } from './Devices.constants';
import * as moment from 'moment-timezone';

// In green, show the duration since last seen
// 1s ... <2s....<3s........<59s	update every second until it reaches the first minute
// 1h...2h...3h...4h...5h....	update every minute until it reaches the first hour
// 1	update every hour until it reaches the first day
//
// After the device has reached 24h, switch to red colour and show as below
// 1d..2d..3d..4d..	update every day
//
// Use grey to represent the device is unenrolled
// The same time updates as above, but in grey only.
//
// Please note that this will need to be reflected correctly on the Dashboard as well.

class DeviceStatusIcon extends Component {
  renderIcon = ({
    status,
    isLastCheckinValid,
    beforeLastDay,
    lessThanOneHour,
  }) => {
    if (status === DeviceStatus.UNENROLLED || !isLastCheckinValid) {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground={'new 0 0 24 24'}
          style={{ width: '26px', height: 'auto' }}
        >
          <path
            fill={'#676767'}
            stroke={'#676767'}
            strokeMiterlimit={10}
            d="M12.3,0.7C6,0.7,1,5.8,1,12s5,11.3,11.3,11.3c6.2,0,11.3-5.1,11.3-11.3S18.5,0.7,12.3,0.7z M12.3,19.6
                        c-4.2,0-7.6-3.4-7.6-7.6s3.4-7.6,7.6-7.6s7.6,3.4,7.6,7.6S16.4,19.6,12.3,19.6z"
          />
          <line
            x1="8"
            y1="8"
            x2="16"
            y2="16"
            fill={'none'}
            stroke={'#676767'}
            strokeWidth={2}
            strokeMiterlimit={10}
          />
          <line
            x1="16"
            y1="8"
            x2="8"
            y2="16"
            fill={'none'}
            stroke={'#676767'}
            strokeWidth={2}
            strokeMiterlimit={10}
          />
        </svg>
      );
    } else if (beforeLastDay) {
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground={'new 0 0 24 2'}
          style={{ width: '26px', height: 'auto' }}
        >
          <g fill="#C1282D">
            <path
              d="M12,0.8C5.8,0.8,0.8,5.8,0.8,12s5,11.3,11.3,11.3c6.2,0,11.3-5,11.3-11.3S18.2,0.8,12,0.8z M12,19.3
                    		c-4.1,0-7.3-3.3-7.3-7.3S7.9,4.7,12,4.7c4.1,0,7.4,3.3,7.4,7.3S16.1,19.3,12,19.3z"
            />
            <polygon points="7.9,9.5 7.9,12.5 12,16 16.1,12.5 16.1,9.5 12,13" />
          </g>
        </svg>
      );
    } else {
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground={'new 0 0 24 24'}
          style={{ width: '26px', height: 'auto' }}
        >
          <g fill={lessThanOneHour ? '#52B96F' : '#FFA500'}>
            <polygon points="7.9,11.5 7.9,14.5 12,11 16.1,14.5 16.1,11.5 12,8" />
            <path
              d="M12,0.8C5.8,0.8,0.8,5.8,0.8,12s5,11.3,11.3,11.3s11.3-5,11.3-11.3S18.2,0.8,12,0.8z M12,19.3
                    		c-4.1,0-7.3-3.3-7.3-7.3S7.9,4.7,12,4.7s7.4,3.3,7.4,7.3S16.1,19.3,12,19.3z"
            />
          </g>
        </svg>
      );
    }
  };

  render() {
    const { lastCheckin, status } = this.props.item;

    const lastCheckinMoment = moment(lastCheckin);
    const isLastCheckinValid = lastCheckin && lastCheckinMoment.isValid();
    let fromNow = '';
    let beforeLastDay;
    let lessThanOneHour;

    if (isLastCheckinValid) {
      const now = moment();
      const pastOneHourMoment = now.clone().subtract(1, 'h');
      const pastTwentyFourHoursMoment = now.clone().subtract(24, 'h');

      lessThanOneHour = lastCheckinMoment.isAfter(pastOneHourMoment);
      beforeLastDay = lastCheckinMoment.isBefore(pastTwentyFourHoursMoment); // is Offline
      const units = beforeLastDay
        ? 'd'
        : lastCheckinMoment.isBefore(now.clone().subtract(1, 'h'))
        ? 'h'
        : lastCheckinMoment.isBefore(now.clone().subtract(1, 'm'))
        ? 'm'
        : 's';

      fromNow = `${Math.abs(now.diff(lastCheckinMoment, units))}\xA0${units}`;
    }
    const color =
      status === DeviceStatus.UNENROLLED || !isLastCheckinValid
        ? '#777777'
        : beforeLastDay
        ? '#772222'
        : lessThanOneHour
        ? '#227722'
        : '#FFA500';
    const enrollmentStatus =
      status === DeviceStatus.UNENROLLED
        ? I18n.t('device.unenrolled')
        : I18n.t('device.enrolled');
    const lastSeenFormatted = lastCheckinMoment.format(
      DEFAULT_DATE_TIME_FORMAT
    );
    const titleText = !isLastCheckinValid
      ? ''
      : I18n.t('device.lastSeenDescription', {
          lastSeenFormatted,
        });
    const titlePostfix = !isLastCheckinValid
      ? ''
      : `(${I18n.t('device.timeWithAgoAffix', {
          time: fromNow,
        })})`;
    const title = `${enrollmentStatus}. ${titleText} ${titlePostfix}`;

    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          color,
        }}
        title={title}
      >
        {this.renderIcon({
          status,
          isLastCheckinValid,
          beforeLastDay,
          lessThanOneHour,
        })}
        <div>{fromNow}</div>
      </div>
    );
  }
}

DeviceStatusIcon.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DeviceStatusIcon;
