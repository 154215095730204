import { connect } from 'react-redux';
import React from 'react';
import { SectionDescription } from '../sectionDescription.component';
import { I18n } from 'react-redux-i18n';
import FleetsTree from '../../features/fleets/components/fleetsTree.component';
import { allCompanyFleetsV2Selector } from '../../selectors/fleetsSelector';

const ChooseFleets = ({ allFleets, alert, onPropertyChange, ...props }) => {
  const onChange = (fleets) => {
    onPropertyChange({ key: 'fleets', value: fleets });
  };

  const selectedKeys = alert.fleets || [];

  return (
    <React.Fragment>
      <SectionDescription title={I18n.t('alerts.chooseFleet')} />
      <FleetsTree
        fleets={allFleets || []}
        onSelect={onChange}
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        multiple
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data: allFleets } = allCompanyFleetsV2Selector(state);

  return {
    allFleets,
  };
};

export default connect(mapStateToProps)(ChooseFleets);
