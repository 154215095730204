export const PROFILES_ASSIGN_TO_FLEETS_STARTED =
  'PROFILES_ASSIGN_TO_FLEETS_STARTED';
export const PROFILES_ASSIGN_TO_FLEETS_FAILED =
  'PROFILES_ASSIGN_TO_FLEETS_FAILED';
export const PROFILES_ASSIGN_TO_FLEETS_SUCCESS =
  'PROFILES_ASSIGN_TO_FLEETS_SUCCESS';
export const PROFILES_ASSIGN_TO_FLEETS_RESET = 'PROFILES_ASSIGN_TO_FLEET_RESET';
export const PROFILES_DELETE_STARTED = 'PROFILES_DELETE_STARTED';
export const PROFILES_DELETE_FAILED = 'PROFILES_DELETE_FAILED';
export const PROFILES_DELETE_SUCCESS = 'PROFILES_DELETE_SUCCESS';
export const PROFILES_LOAD_STARTED = 'PROFILES_LOAD_STARTED';
export const PROFILES_LOAD_FAILED = 'PROFILES_LOAD_FAILED';
export const PROFILES_LOAD_SUCCESS = 'PROFILES_LOAD_SUCCESS';

export function loadProfilesStarted(payload) {
  return {
    type: PROFILES_LOAD_STARTED,
    payload,
  };
}

export function loadProfilesFailed(payload) {
  return {
    type: PROFILES_LOAD_FAILED,
    payload,
  };
}

export function profilesLoaded(payload) {
  return {
    type: PROFILES_LOAD_SUCCESS,
    payload,
  };
}

export function assignProfilesToFleetsStarted() {
  return {
    type: PROFILES_ASSIGN_TO_FLEETS_STARTED,
  };
}

export function assignProfilesToFleetsFailed(payload) {
  return {
    type: PROFILES_ASSIGN_TO_FLEETS_FAILED,
    payload,
  };
}

export function assignProfilesToFleetsSuccess() {
  return {
    type: PROFILES_ASSIGN_TO_FLEETS_SUCCESS,
  };
}

export function assignProfilesToFleetsReset() {
  return {
    type: PROFILES_ASSIGN_TO_FLEETS_RESET,
  };
}

export function profilesDeletingStarted() {
  return {
    type: PROFILES_DELETE_STARTED,
  };
}

export function profilesDeletingFailed(payload) {
  return {
    type: PROFILES_DELETE_FAILED,
    payload,
  };
}

export function profilesDeleted(payload) {
  return {
    type: PROFILES_DELETE_SUCCESS,
    payload,
  };
}
