import { NetworkStatus } from '../../common/constants';
import {
  ENROLLMENT_CHANGE_PROPERTY,
  ENROLLMENT_CREATE_FAILED,
  ENROLLMENT_CREATE_STARTED,
  ENROLLMENT_CREATE_SUCCESS,
  ENROLLMENT_LOAD_FAILED,
  ENROLLMENT_LOAD_STARTED,
  ENROLLMENT_LOAD_SUCCESS,
  ENROLLMENT_RESET,
} from './enrollment.actions';

export default function enrollment(
  state = {
    loading: NetworkStatus.NONE,
    error: undefined,
    data: undefined,
    newEnrollment: {},
    createEnrollmentStatus: NetworkStatus.NONE,
    createEnrollmentError: undefined,
  },
  action
) {
  switch (action.type) {
    case ENROLLMENT_CHANGE_PROPERTY:
      const { key, value } = action.payload;
      return {
        ...state,
        newEnrollment: {
          ...state.newEnrollment,
          [key]: value,
        },
      };
    case ENROLLMENT_RESET:
      return {
        ...state,
        newEnrollment: {},
        createEnrollmentStatus: NetworkStatus.NONE,
        createEnrollmentError: undefined,
      };
    case ENROLLMENT_LOAD_STARTED:
      return Object.assign({}, state, {
        data: undefined,
        loading: NetworkStatus.STARTED,
        error: undefined,
      });
    case ENROLLMENT_LOAD_FAILED:
      return Object.assign({}, state, {
        data: undefined,
        loading: NetworkStatus.ERROR,
        error: action.payload,
      });
    case ENROLLMENT_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        loading: NetworkStatus.DONE,
        error: undefined,
      });
    case ENROLLMENT_CREATE_STARTED:
      return Object.assign({}, state, {
        createEnrollmentStatus: NetworkStatus.STARTED,
        createEnrollmentError: undefined,
      });
    case ENROLLMENT_CREATE_FAILED:
      return Object.assign({}, state, {
        createEnrollmentStatus: NetworkStatus.ERROR,
        createEnrollmentError: action.payload,
      });
    case ENROLLMENT_CREATE_SUCCESS:
      return Object.assign({}, state, {
        createEnrollmentStatus: NetworkStatus.DONE,
        createEnrollmentError: undefined,
        newEnrollment: action.payload,
      });
    default:
      return state;
  }
}
