export const createGroupRouteConstants = {
  PERMISSIONS: 'permissions',
  USER_MANAGEMENT: 'users',
};

export const GroupFleets = [
  {
    fleet_id: '56a84eb6-b1a3-43c5-b3e9-c2a5581dd084',
    children: [
      '9d26252a-e71e-413f-811e-3b040ba800f2',
      '99ecc938-4220-4437-a63e-e07bb07da563',
    ],
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Fleet Group 1',
    parent_id: null,
  },
  {
    fleet_id: '9d26252a-e71e-413f-811e-3b040ba800f2',
    children: null,
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Fleet 1 Sub-Group 1',
    parent_id: '56a84eb6-b1a3-43c5-b3e9-c2a5581dd084',
  },
  {
    fleet_id: '99ecc938-4220-4437-a63e-e07bb07da563',
    children: [
      '13afdb0b-2eb6-4b9b-9b53-07ea31188411',
      'c04b2aa2-b73f-40a2-ab74-761553ab9c36',
      '17782c9d-7400-48e0-b020-777b37666deb',
    ],
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Fleet 1 Sub-Group 2',
    parent_id: '56a84eb6-b1a3-43c5-b3e9-c2a5581dd084',
  },
  {
    fleet_id: '13afdb0b-2eb6-4b9b-9b53-07ea31188411',
    children: null,
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Sub-Sub-Group 1',
    parent_id: '99ecc938-4220-4437-a63e-e07bb07da563',
  },
  {
    fleet_id: 'c04b2aa2-b73f-40a2-ab74-761553ab9c36',
    children: null,
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Sub-Sub-Group 2',
    parent_id: '99ecc938-4220-4437-a63e-e07bb07da563',
  },
  {
    fleet_id: '17782c9d-7400-48e0-b020-777b37666deb',
    children: null,
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Sub-Sub-Group 3',
    parent_id: '99ecc938-4220-4437-a63e-e07bb07da563',
  },
  {
    fleet_id: 'bf471252-c497-4460-b07e-8ac0171d68a8',
    children: [
      'edb245e4-cbd8-4296-8c3b-478fc41927d6',
      '75722c9f-ee80-4efd-ac0d-9585c031b9e8',
    ],
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Fleet Group 2',
    parent_id: null,
  },
  {
    fleet_id: 'edb245e4-cbd8-4296-8c3b-478fc41927d6',
    children: null,
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Fleet 2 Sub-Group 1',
    parent_id: 'bf471252-c497-4460-b07e-8ac0171d68a8',
  },
  {
    fleet_id: '75722c9f-ee80-4efd-ac0d-9585c031b9e8',
    children: null,
    company_id: '96b1ad97-2c03-4f7d-9790-754729b43ef9',
    fleet_name: 'Fleet 2 Sub-Group 2',
    parent_id: 'bf471252-c497-4460-b07e-8ac0171d68a8',
  },
];

export const permissionTableItems = () => [
  {
    permissionItem: 'Global Permissions',
    level: 0,
    read: true,
    readWrite: false,
  },
  {
    permissionItem: 'Web Console Access',
    level: 0,
    read: true,
    readWrite: true,
  },
  {
    permissionItem: 'Some Permission Field Parameter 1',
    level: 1,
    read: false,
    readWrite: false,
  },
  {
    permissionItem: 'Some Permission Field Parameter 2',
    level: 1,
    read: true,
    readWrite: true,
  },
  {
    permissionItem: 'Manage Device Management Rules',
    level: 2,
    read: false,
    readWrite: false,
  },
  {
    permissionItem: 'Manage Application Management Rules',
    level: 2,
    read: true,
    readWrite: false,
  },
  {
    permissionItem: 'Manage File Sync Management Rules',
    level: 2,
    read: false,
    readWrite: false,
  },
  {
    permissionItem: 'User Management Access',
    level: 0,
    read: false,
    readWrite: false,
  },
  {
    permissionItem: 'Some Permission Field Parameter',
    level: 1,
    read: false,
    readWrite: false,
  },
];
