import { NetworkStatus } from '../../common/constants';
import {
  PASSWORD_CHANGE_STARTED,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILED,
  PASSWORD_CHANGE_RESET,
} from './password.actions';

export default function password(
  state = {
    status: NetworkStatus.NONE,
    data: undefined,
    error: undefined,
  },
  action
) {
  switch (action.type) {
    case PASSWORD_CHANGE_STARTED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.STARTED,
      });
    case PASSWORD_CHANGE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.DONE,
      });
    case PASSWORD_CHANGE_FAILED:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.ERROR,
        error: action.payload,
      });

    case PASSWORD_CHANGE_RESET:
      return Object.assign({}, state, {
        ...state,
        status: NetworkStatus.NONE,
      });

    default:
      return state;
  }
}
