import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { deviceNamingParameterOptions } from '../../constants/deviceNaming';
import { generateDeviceNamingExample } from '../../common/utilities/devices';

export class CreateEnrollmentDeviceNaming extends Component {
  render() {
    const {
      sequence = [],
      separator = '_',
      startingNumber = '0',
      name = '',
      onChange,
      onChangeSequence,
    } = this.props;
    return (
      <div
        style={{
          marginBottom: '15px',
          display: 'flex',
          flexDirection: 'column',
        }}
        className={this.props.className}
      >
        <ControlLabel
          style={{
            fontSize: '21px',
            lineHeight: '1.19',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#003562', // --incube primary color
            marginBottom: '45px',
            fontWeight: 'normal',
          }}
        >
          {I18n.t('enrollments.namingExampleHeader')}
          <span
            style={{
              fontSize: '21px',
              lineHeight: '1.19',
              letterSpacing: 'normal',
              textAlign: 'left',
              color: '#003562', // --incube primary color
              marginBottom: '45px',
              fontWeight: 'bold',
            }}
          >
            {sequence?.length
              ? generateDeviceNamingExample({
                  sequence,
                  separator,
                  startingNumber,
                  name,
                })
              : ''}
          </span>
        </ControlLabel>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
          }}
        >
          <FormGroup bsSize="small" style={{ display: 'flex' }}>
            <ControlLabel
              style={{
                fontSize: '21px',
                lineHeight: '1.19',
                color: '#003562', // --incube primary color
                fontWeight: 'normal',
              }}
            >
              {I18n.t('enrollments.deviceNamingSeparator')}
            </ControlLabel>
            <FormControl
              type="text"
              id="separator"
              value={separator || ''}
              onChange={onChange}
              style={{ width: '100px', marginLeft: '34px' }}
            />
          </FormGroup>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '15px',
            }}
          >
            {sequence.map((value, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    marginBottom: '32px',
                    minHeight: '8px',
                  }}
                  key={`namin_opt_${index}`}
                >
                  <FormControl
                    type="text"
                    id={value === 'name' ? value : `${index}`}
                    value={value === 'name' ? name : ''}
                    onChange={onChange}
                    disabled={value !== 'name'}
                    style={{ marginRight: '24px' }}
                  />
                  <select
                    className="form-control"
                    value={sequence[index] || ''}
                    onChange={(e) => onChangeSequence(index, e)}
                  >
                    {deviceNamingParameterOptions.map((currentValue, ind) => (
                      <option
                        key={`namin_opt_${index}_${ind}`}
                        disabled={
                          ind &&
                          sequence.some((item) => item === currentValue.value)
                        }
                        value={currentValue.value}
                      >
                        {currentValue.title}
                      </option>
                    ))}
                  </select>
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
            marginTop: '15px',
          }}
        >
          <FormGroup bsSize="small" style={{ display: 'flex' }}>
            <ControlLabel
              style={{
                fontSize: '21px',
                lineHeight: '1.19',
                color: '#003562', // --incube primary color
                fontWeight: 'normal',
              }}
            >
              {I18n.t('enrollments.startingSerialNumber')}
            </ControlLabel>
            <FormControl
              type="number"
              id="startingNumber"
              max={10_000}
              min={0}
              value={startingNumber || ''}
              onChange={onChange}
              style={{ width: '100px', marginLeft: '34px' }}
            />
          </FormGroup>
        </div>
      </div>
    );
  }
}
