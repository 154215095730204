import React from 'react';
import Slider from 'rc-slider';

const SliderWrapper = ({ title, ...otherProps }) => (
  <div className="slider-wrapper">
    <p>{title}</p>
    <Slider {...otherProps} />
  </div>
);

export default SliderWrapper;
