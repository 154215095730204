import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Glyphicon } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  // change background colour if dragging
  background: 'white', // isDragging ? 'lightgray' : 'white',
  borderBottom: '1px solid lightgray',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgray' : 'white',
  margin: '20px',
  border: '1px solid lightgray',
  padding: grid,
  width: 250,
});

class FileSyncConfigurator extends Component {
  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (
      source.droppableId === 'selected' &&
      (!destination || source.droppableId !== destination.droppableId)
    ) {
      this.props.onRemoveFile(draggableId);
      return;
    }
    if (
      source.droppableId === 'items' &&
      destination &&
      destination.droppableId === 'selected'
    ) {
      this.props.onAddFile(draggableId);
      return;
    }
  };

  render() {
    return (
      <div style={{ width: '100%', display: 'flex' }}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="selected">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.props.selected.length === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      minHeight: '250px',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Glyphicon
                      style={{
                        color: 'lightgray',
                        fontSize: '46px',
                        marginTop: '30px',
                      }}
                      glyph="cloud-upload"
                    />
                    <div
                      style={{
                        color: 'lightgray',
                        margin: '30px',
                        textAlign: 'center',
                      }}
                    >
                      {I18n.t('profiles.fileSync.noFilesAssigned')}
                      <br />
                      {I18n.t('profiles.fileSync.filesAssignmentHint')}
                    </div>
                  </div>
                ) : null}
                {this.props.selected.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="items">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  ...getListStyle(snapshot.isDraggingOver),
                  borderLeft: '1px solid lightgray',
                }}
              >
                {this.props.files
                  .filter(
                    (file) =>
                      !this.props.selected.find((key) => key === file.key)
                  )
                  .map((item, index) => (
                    <Draggable
                      key={item.key}
                      draggableId={item.key}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.key}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default FileSyncConfigurator;
