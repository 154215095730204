import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../components/NotFound';
import CompanyInfo from '../components/CompanyInfo';
import CommonSettings from '../components/CommonSettings';
import AccountSettingsComponent from '../features/account/account.component';
import PasswordSettingsComponent from '../features/password/password.component';
import SSOSettingsComponent from '../features/settings/sso.settings.component';
import Preferences from '../features/preferences/Preferences.component';
import { RootRoutes, SettingsRoutes } from '../utils/routes';

const _Settings = (props) => {
  const renderAdminOnlyRoutes = () => [
    <Route
      exact
      path={`${RootRoutes.SETTINGS}${SettingsRoutes.INFO}`}
      component={CompanyInfo}
    />,
    <Route
      exact
      path={`${RootRoutes.SETTINGS}${SettingsRoutes.COMMON}`}
      component={CommonSettings}
    />,
    <Route
      exact
      path={`${RootRoutes.SETTINGS}${SettingsRoutes.SSO}`}
      component={SSOSettingsComponent}
    />,
    <Route
      exact
      path={`${RootRoutes.SETTINGS}${SettingsRoutes.PREFERENCES}`}
      component={Preferences}
    />,
  ];
  return (
    <Switch>
      <Redirect
        exact
        from={RootRoutes.SETTINGS}
        to={`${RootRoutes.SETTINGS}${SettingsRoutes.ACCOUNT}`}
      />
      <Route
        exact
        path={`${RootRoutes.SETTINGS}${SettingsRoutes.ACCOUNT}`}
        component={AccountSettingsComponent}
      />
      <Route
        exact
        path={`${RootRoutes.SETTINGS}${SettingsRoutes.ACCOUNT}${SettingsRoutes.PASSWORD}`}
        component={PasswordSettingsComponent}
      />
      {props.hasAdminPrivileges ? renderAdminOnlyRoutes() : null}
      <Route component={NotFound} />
    </Switch>
  );
};

export default _Settings;
