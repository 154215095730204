import {
  PREFERENCES_LOAD_FAILED,
  PREFERENCES_LOAD_STARTED,
  PREFERENCES_LOAD_SUCCESS,
  PREFERENCES_UPDATE_FAILED,
  PREFERENCES_UPDATE_STARTED,
  PREFERENCES_UPDATE_SUCCESS,
} from './preferences.actionTypes';

const preferences = (
  state = { locale: '', timezone: '', loading: false, error: null },
  action
) => {
  switch (action.type) {
    case PREFERENCES_LOAD_STARTED:
      return { ...state, loading: true, error: null };
    case PREFERENCES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        locale: action.payload.locale,
        timezone: action.payload.timezone,
      };
    case PREFERENCES_LOAD_FAILED:
      return { ...state, loading: false, error: action.payload };
    case PREFERENCES_UPDATE_STARTED:
      return { ...state, loading: true, error: null };
    case PREFERENCES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        locale: action.payload.locale,
        timezone: action.payload.timezone,
      };
    case PREFERENCES_UPDATE_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default preferences;
