import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from '../../../../components/button.component';
import { connect } from 'react-redux';
import { AttributeType, NetworkStatus } from '../../../../common/constants';
import { confirmAlert } from 'react-confirm-alert';
import { assignDevicesToFleetReset } from '../../../fleets/fleets.actions';
import PopupActions from '../../../popup/popup.actions';
import { globalPermissionsForUserSelector } from '../../../../selectors/accountSelectors';
import { getDevicesTableColumns } from '../../../../common/utilities/devices';
import UserHelpers from '../../../../common/utilities/user';
import DropDownMenu from '../../../../components/DropDownMenu/dropDownMenu.component';
import SubmissionInput from '../../../../components/SubmissionInput/SubmissionInput.component';
import DataTable from '../../../../components/DataTable/DataTable.component';
import { Modal } from '../../../../components/modal.component';
import ReadOnlyForm from '../../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { profilesSelector } from '../../../profiles/profiles.selectors';
import { getProfilesById } from '../../../../common/utilities/profile';
import { Pages } from '../../../../constants/pages';
import { getFleetDevicesTableColumns } from './devicesColumns';

class FleetDevices extends Component {
  state = {
    isShowRenameInput: false,
    areFleetDetailsShown: false,
  };

  get allFleetDevicesTableColumns() {
    const {
      globalPermissionsForUser = {},
      hasAdminPrivileges = false,
    } = this.props;
    return getDevicesTableColumns(
      globalPermissionsForUser,
      getFleetDevicesTableColumns,
      hasAdminPrivileges
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selected !== this.props.selected ||
      prevProps.devices !== this.props.devices
    ) {
      this.onResetRenameInput();
    }

    if (
      this.props.devicesMoveStatus !== prevProps.devicesMoveStatus &&
      this.props.devicesMoveStatus !== NetworkStatus.NONE
    ) {
      this.onDevicesAssignResponse();
    }
  }

  // todo: removee
  onDevicesAssignResponse = () => {
    if (this.props.devicesMoveStatus) {
      let color =
        this.props.devicesMoveStatus === NetworkStatus.DONE
          ? 'green'
          : this.props.devicesMoveStatus === NetworkStatus.ERROR
          ? 'red'
          : '#222';
      let text =
        this.props.devicesMoveStatus === NetworkStatus.DONE
          ? I18n.t('common.successful')
          : this.props.devicesMoveStatus === NetworkStatus.ERROR
          ? I18n.t('common.error')
          : I18n.t('common.inProgress');
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="create-enrollment-alert">
              <p>
                <Translate value="fleets.assignDevicesToFleet" />
              </p>
              <p style={{ color: color }}>{text}</p>
              <Button onClick={onClose}>
                <Translate value="common.ok" />
              </Button>
            </div>
          );
        },
      });
      this.props.assignDevicesToFleetReset();
    }
  };

  render() {
    const {
      devices,
      onOpenItem,
      devicesLoadingStatus,
      selectable,
      onSelect,
      profiles,
      fleet: { profileId } = {},
      tableRef,
    } = this.props;
    return (
      <>
        <DataTable
          allColumns={this.allFleetDevicesTableColumns}
          data={devices}
          onOpenItem={onOpenItem}
          loadingStatus={devicesLoadingStatus}
          selectable={selectable}
          onSelect={onSelect}
          sortable
          pageId={Pages.FLEET_DEVICES}
          rightCustomHeaderAction={this.renderDropDownMenu()}
          headerTitle={this.renderFleetTitle(devices)}
          tableRef={tableRef}
          // infiniteScrollProps={{
          //     loadMore: this.props.loadFleetDevices,
          //     hasMore: this.props.hasMoreFleetDevices,
          // }}

          useFilter
          useColumnsToggle
          useCSVExport
        />
        {this.state.areFleetDetailsShown && (
          <Modal
            title={I18n.t('fleets.fleetDetails')}
            onBack={this.toggleFleetDetails}
          >
            <ReadOnlyForm
              key={`fleet-details-read-only-form`}
              items={[
                {
                  title: I18n.t('fleets.profiles'),
                  valueList: getProfilesById(profiles, profileId).map(
                    ({ name }) => name
                  ),
                  type: AttributeType.TEXT,
                  key: 'Profiles',
                },
              ]}
            />
          </Modal>
        )}
      </>
    );
  }

  renderDropDownMenu = () => {
    const { fleet, hasAdminPrivileges } = this.props;
    if (fleet) {
      return (
        <DropDownMenu
          triggerButtonText={<Translate value="fleets.dropdownToggleText" />}
          items={[
            {
              onClick: this.showInput,
              children: <Translate value="fleets.rename" />,
              hidden: !hasAdminPrivileges,
            },
            {
              onClick: this.delete,
              children: <Translate value="fleets.delete" />,
              hidden: !hasAdminPrivileges,
            },
            {
              onClick: this.toggleFleetDetails,
              children: <Translate value="fleets.fleetDetails" />,
            },
          ]}
        />
      );
    }
  };

  toggleFleetDetails = () =>
    this.setState((prevState) => ({
      areFleetDetailsShown: !prevState.areFleetDetailsShown,
    }));

  renderFleetTitle = (items = []) => {
    if (this.state.isShowRenameInput) {
      return (
        <SubmissionInput
          placeholder={I18n.t('fleets.enterAFleetName')}
          defaultValue={this.props.fleet?.name}
          onSubmit={this.onRename}
          onReset={this.onResetRenameInput}
          showResetIcon
        />
      );
    }
    return (
      <div
        className={'card-header__title'}
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ marginRight: '20px' }}>{this.props.title || ''}</div>
        {this.props.devicesLoadingStatus === NetworkStatus.DONE ? (
          <Fragment>
            <div
              style={{
                marginRight: '8px',
                marginTop: '4px',
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.17',
                letterSpacing: 'normal',
                color: '#727272',
              }}
            >
              {`(${items ? items.length : 0}\xA0Devices)`}
            </div>
            {/*<div>*/}
            {/*    <DeviceHaveUpdatesIcon item={{haveUpdates: items && items.some(({haveUpdates}) => haveUpdates)}}/>*/}
            {/*</div>*/}
          </Fragment>
        ) : (
          ''
        )}
      </div>
    );
  };

  showInput = (e) => {
    this.setState({
      isShowRenameInput: true,
    });
  };

  delete = (e) =>
    PopupActions.showConfirm({
      onConfirm: () => this.props.onRemove(),
      text: <Translate value="fleets.areYouSureYouWantToDeleteThisFleet" />,
    });

  onRename = (value) => {
    this.props.onRename(value);
    // this.onResetRenameInput();
  };

  onResetRenameInput = () => {
    this.setState({
      isShowRenameInput: false,
    });
  };
}

FleetDevices.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  fleet: PropTypes.object,
  onRename: PropTypes.func,
  onRemove: PropTypes.func,
  manageable: PropTypes.bool,
  devices: PropTypes.arrayOf(PropTypes.object),
  devicesLoadingStatus: PropTypes.string,
  onSelect: PropTypes.func,
  onToggleSelectAll: PropTypes.func,
  selectable: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  devicesMoveStatus: state.devices.devicesMoveStatus,
  devicesMoveError: state.devices.devicesMoveError,
  visibleColumns: state.columnsToggle.visibleColumnsLists[Pages.FLEET_DEVICES],
  globalPermissionsForUser: globalPermissionsForUserSelector(state),
  // globalPermissionsForUser: globalPermissionsMock,
  hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
  profiles: profilesSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    assignDevicesToFleetReset: () => dispatch(assignDevicesToFleetReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetDevices);
