import { I18n } from 'react-redux-i18n';

export const deviceNamingParameterOptions = [
  { value: 'None', title: I18n.t('enrollments.deviceNamingParamNone') },
  {
    value: 'numbered_sequence',
    title: I18n.t('enrollments.deviceNamingParamNumberedSequence'),
  },
  { value: 'name', title: I18n.t('enrollments.deviceNamingParamName') },
  { value: 'imei', title: I18n.t('enrollments.deviceNamingParamIMEI') },
  {
    value: 'mac_address',
    title: I18n.t('enrollments.deviceNamingParamMACAddress'),
  },
  {
    value: 'manufacturer',
    title: I18n.t('enrollments.deviceNamingParamManufacturer'),
  },
  { value: 'model', title: I18n.t('enrollments.deviceNamingParamModel') },
  {
    value: 'device_name',
    title: I18n.t('enrollments.deviceNamingParamDeviceName'),
  },
  {
    value: 'phone_number',
    title: I18n.t('enrollments.deviceNamingParamPhoneNumber'),
  },
  {
    value: 'os_version',
    title: I18n.t('enrollments.deviceNamingParamPlatform'),
  },
  {
    value: 'hardware_serial_number',
    title: I18n.t('enrollments.deviceNamingParamSerialNumber'),
  },
];
