import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../components/NotFound';
import { ApplicationsRoutes, RootRoutes } from '../utils/routes';
import Applications from '../features/applications/applications.component';
import AllFiles from './AllFiles';
import Upload from '../features/upload/upload.component';
import { getAccessFromGlobalPermissionsForUser } from '../common/utilities/users';
import { GLOBAL_READ_READWRITE_PERMISSIONS } from '../constants/globalPermissions';
import { ACCESS_TYPES } from '../constants/accessTypes';
import Certificates from '../features/certificates/certificates.component';
import MyHTMLKioskFiles from '../features/htmlKioskFiles/htmlKioskFiles.component';
import { AddFileRoute } from '../features/upload/upload.constants';

export const applicationsRoutes = [
  {
    path: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}/:childRoute?/:category?`,
    isRedirect: false,
    isExact: false,
    component: Applications,
    permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS,
    accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
  },
  {
    from: `${AddFileRoute}`,
    to: `${AddFileRoute}/apk`,
    isRedirect: true,
    isExact: true,
    permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
    accessType: ACCESS_TYPES.READ_WRITE,
  },
  {
    path: `${AddFileRoute}/:type`,
    redirectionPath: `${AddFileRoute}/apk`,
    isRedirect: false,
    isExact: true,
    component: Upload,
    permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
    accessType: ACCESS_TYPES.READ_WRITE,
  },
  {
    path: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.FILES}`,
    isRedirect: false,
    isExact: true,
    component: AllFiles,
    permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
    accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
  },
  {
    path: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.CERTIFICATES}`,
    isRedirect: false,
    isExact: true,
    component: Certificates,
    permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
    accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
  },
  {
    path: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.HTML_KIOSK_FILES}`,
    isRedirect: false,
    isExact: true,
    component: MyHTMLKioskFiles,
    permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.UPLOADS,
    accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
  },
];

const applicationsRouteRedirectionConfig = {
  from: RootRoutes.FILE_SYSTEM,
  to: `${RootRoutes.FILE_SYSTEM}${ApplicationsRoutes.MY}`,
  isRedirect: true,
  isExact: true,
  permissionKey: GLOBAL_READ_READWRITE_PERMISSIONS.APPLICATIONS_ACCESS,
  accessType: [ACCESS_TYPES.READ, ACCESS_TYPES.READ_WRITE],
};

const _Applications = ({ hasAdminPrivileges, globalPermissionsForUser }) => {
  const redirectionPathsForApplicationsRoute = [];
  const routes = [];
  applicationsRoutes.forEach(
    ({
      path,
      redirectionPath,
      isRedirect,
      isExact,
      component,
      from,
      to,
      permissionKey,
      accessType,
    }) => {
      const isAccessible =
        hasAdminPrivileges ||
        getAccessFromGlobalPermissionsForUser(
          globalPermissionsForUser,
          permissionKey,
          accessType
        );
      if (isAccessible) {
        if (isRedirect) {
          routes.push(<Redirect from={from} to={to} exact={isExact} />);
        } else {
          routes.push(
            <Route path={path} component={component} exact={isExact} />
          );
          redirectionPathsForApplicationsRoute.push(redirectionPath || path);
        }
      }
    }
  );

  return (
    <Switch>
      <Redirect
        to={redirectionPathsForApplicationsRoute[0] || ''}
        from={applicationsRouteRedirectionConfig.from}
        exact
      />
      {routes}
      <Route component={NotFound} />
    </Switch>
  );
};

export default _Applications;
