import React from 'react';
import { v4 as uuid } from 'uuid';

import './ReadOnlyListItem.styles.scss';
import { isEmpty } from '../../../common/helpers';
import { AttributeType } from '../../../common/constants';

const ReadOnlyListItem = ({
  title,
  value,
  defaultValue = '-',
  valueList,
  availableOptions = [],
  type,
  id,
}) => {
  const rootClassName = 'read-only-list-item';
  const rootId = id || uuid();
  let displayValue;
  if (valueList) {
    displayValue = (
      <ul className={`${rootClassName}__value-list`}>
        {isEmpty(valueList) ? (
          <li className={`${rootClassName}__value-item`}>{defaultValue}</li>
        ) : (
          valueList.map((value, idx) => (
            <li
              key={`${rootId}-${idx}-${value}`}
              className={`${rootClassName}__value-item`}
            >
              {value}
            </li>
          ))
        )}
      </ul>
    );
  } else {
    if (type === AttributeType.ENUMERABLE) {
      const { title: optionTitle } =
        availableOptions.find((option) => option.value === value) ?? {};
      displayValue = (
        <div className={`${rootClassName}__value-item`}>
          {optionTitle || defaultValue}
        </div>
      );
    } else {
      displayValue = (
        <div className={`${rootClassName}__value-item`}>
          {value || defaultValue}
        </div>
      );
    }
  }

  return (
    <li className={rootClassName} key={rootId}>
      <div>{title}</div>
      {displayValue}
    </li>
  );
};

export default ReadOnlyListItem;
