import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as CheckCircle } from '../../../../assets/images/check-circle.svg';

class DeviceHaveUpdatesIcon extends Component {
  renderIcon = (hasLatestAgent) => {
    if (hasLatestAgent) {
      return <CheckCircle />;
    }
    return null;
  };

  render() {
    const { hasLatestAgent } = this.props.item;
    const title = hasLatestAgent
      ? I18n.t('devices.deviceUpdateTitle')
      : undefined;

    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#222222',
          width: '18px',
          height: '18px',
        }}
        title={title}
      >
        {this.renderIcon(hasLatestAgent)}
      </div>
    );
  }
}

DeviceHaveUpdatesIcon.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DeviceHaveUpdatesIcon;
