import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { updateFleet } from '../../features/fleets/fleets.thunk';
import { companyIdSelector } from '../../features/login/login.selectors';
import FleetsTree from '../../features/fleets/components/fleetsTree.component';
import { Throbber } from '../Throbber';
import { fleetsV2Selector } from '../../features/fleets/fleetsV2.selectors';

class SelectedFleets extends React.Component {
  onSelect = (fleet) => {
    const selectedFleetId = fleet && fleet.id ? fleet.id : undefined;
    this.props.onChange('id', selectedFleetId);
  };

  render() {
    if (this.props.loading) {
      return <Throbber />;
    }
    return (
      <div className={'selected-fleets'}>
        <div className={'section-header'}>
          <div className={'section-header__title'}>
            {I18n.t('enrollments.selectedFleet')}
          </div>
        </div>
        <FleetsTree
          fleets={this.props.fleets || []}
          onDrag={this.props.updateFleet}
          onSelect={this.onSelect}
          defaultSelectedKeys={this.props.value ? [this.props.value] : []}
          selectedKeys={this.props.value ? [this.props.value] : []}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { data: fleets, status: fleetsStatus } = fleetsV2Selector(state);
  return {
    fleets,
    fleetsStatus,
    companyId: companyIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFleet: (data) => dispatch(updateFleet(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFleets);
