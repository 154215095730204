import React from 'react';
import Datetime from 'react-datetime';

import { ReactComponent as Cross } from '../../assets/images/crest.svg';
import { v4 as uuid } from 'uuid';

const DateField = React.forwardRef(
  ({ id, label, showClearButton = false, ...otherProps }, ref) => {
    const renderInput = (props) => {
      function clear() {
        props.onChange({ target: { value: '' } });
      }
      const inputId = id ?? uuid();

      return (
        <div className="date-field">
          <label htmlFor={inputId} className="date-field__title">
            {label}
          </label>
          <div className="date-field__input-container">
            <input
              {...props}
              id={inputId}
              className="field-form-control date-field__input"
            />
            {showClearButton ? (
              <Cross className="icon--fixed-square" onClick={clear} />
            ) : null}
          </div>
        </div>
      );
    };

    return (
      <div>
        <Datetime
          dateFormat="YYYY-MM-DD"
          {...otherProps}
          ref={ref}
          renderInput={renderInput}
        />
      </div>
    );
  }
);

export default DateField;
