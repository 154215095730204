import { I18n } from 'react-redux-i18n';
import MicroSoftIcon from '../assets/images/microsoft.svg';
import { SSOResponseRoutes } from '../utils/routes';
import services from '../utils/services';

export const SSOProvidersConstants = {
  AZURE: 'azure',
};

export const SSOProtocols = {
  SAML2: 'saml',
  OAUTH2: 'oauth2',
};

export const SSOOptionIds = {
  AZURE_OAUTH2: 'AZURE_OAUTH2',
  AZURE_SAML2: 'AZURE_SAML2',
};

export const devRedirectUris = {
  [SSOOptionIds.AZURE_OAUTH2]: 'https://localhost/azure_oauth2',
  [SSOOptionIds.AZURE_SAML2]: 'https://localhost/azure_saml',
};

export const azureSAML2 = {
  id: SSOOptionIds.AZURE_SAML2,
  provider: SSOProvidersConstants.AZURE,
  protocol: SSOProtocols.SAML2,
  icon: MicroSoftIcon,
  linkAccountText: I18n.t('settings.azureSAMLLinkAccountText'),
  updateAccountText: I18n.t('settings.azureSAMLUpdateAccountText'),
  loginText: I18n.t('settings.azureSAMLLoginButtonText'),
  responsePath: SSOResponseRoutes.AZURE_SAML2,
  isAccountLinkingEnabled: true,
  isLoginEnabled: true,
  requestOrganizationId: true,
  linkingSubmissionUrl: services.SSOAzureSAML2LinkUserAccount,
  preLoginSubmissionUrl: `${services.SSO}${SSOProtocols.SAML2}${services.SSOPreLoginByUUID}`,
  loginSubmissionUrl: `${services.SSO}${SSOProtocols.SAML2}${services.SSOLoginByUUID}`,
};

export const azureOauth2 = {
  id: SSOOptionIds.AZURE_OAUTH2,
  provider: SSOProvidersConstants.AZURE,
  protocol: SSOProtocols.OAUTH2,
  icon: MicroSoftIcon,
  linkAccountHeading: I18n.t('settings.OIDCLinkAccountHeading'),
  linkAccountText: I18n.t('settings.azureOIDCLinkAccountText'),
  loginText: I18n.t('settings.azureOAuthLoginButtonText'),
  responsePath: SSOResponseRoutes.AZURE_OAUTH2,
  isAccountLinkingEnabled: true,
  isLoginEnabled: true,
  linkingSubmissionUrl: services.SSOAzureOAuth2LinkUserAccount,
  preLoginSubmissionUrl: services.SSOAzureOAuth2Authentication,
};

export const SSOOptions = [
  // {
  //   id: 'OKTA',
  //   icon: OktaIcon,
  //   linkAccountText: 'Link Okta Account',
  //   loginText: 'Log in with Okta',
  // },

  // azureOauth2,
  azureSAML2,
  // Adding a new SSO option
  // add new SSOResponseRoutes, devRedirectUris, payloadSerializers.
];
