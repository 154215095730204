import { I18n } from 'react-redux-i18n';
import client from '../../utils/client';
import {
  loadUserFailed,
  loadUserStart,
  loadUserSuccess,
  updateUserFailed,
  updateUserStart,
  updateUserSuccess,
} from './user.actions';
import { deserializeUser, serializeUser } from './user.serializer';
import { loadUsers } from '../users/users.thunk';

export function loadUser(username) {
  return async (dispatch) => {
    try {
      dispatch(loadUserStart());
      let { status, data = [] } = await client.get(
        `/user-management/users/${username}`
      );
      if (status === 200 && data) {
        dispatch(loadUserSuccess(deserializeUser(data)));
      } else {
        dispatch(loadUserFailed(I18n.t('users.loadUserError')));
      }
    } catch (error) {
      dispatch(loadUserFailed(I18n.t('users.loadUserError')));
    }
  };
}

export function updateUser(username, userData) {
  return async (dispatch) => {
    try {
      dispatch(updateUserStart());
      let { status, data } = await client.put(
        `/user-management/users/${username}/update/`,
        serializeUser(userData)
      );
      if (status === 200 && data) {
        dispatch(updateUserSuccess(data));
        dispatch(loadUsers());
      } else {
        let error = I18n.t('users.updateUserError');
        if (data) {
          let validationErrors = Object.values(data).reduce(
            (result, item, index) => {
              let separator = index ? ', ' : '';
              if (typeof item === 'string') {
                return `${result}${separator}${item}`;
              }
              if (Array.isArray(item)) {
                return `${result}${separator}${item.join(', ')}`;
              }
              return result;
            },
            ''
          );
          if (validationErrors) {
            error = validationErrors;
          }
        }
        dispatch(updateUserFailed(error));
      }
    } catch (error) {
      dispatch(updateUserFailed(I18n.t('users.updateUserError')));
    }
  };
}
