import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import './device.scss';
import Dialog from '../../components/Dialog';
import ClassNames from 'classnames';
import { UncontrolledCollapse } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { ReactComponent as NoteIcon } from '../../assets/images/comment.svg';
import { companyIdSelector } from '../login/login.selectors';
import { connect } from 'react-redux';
import { ReactComponent as PhoneAndroid } from '../../assets/images/phone_android.svg';
import Applications from './components/Applications';
import ProfilesAssigned from './components/ProfilesAssigned';
import Overview from './components/Overview';
import Location from './components/Location';
import RemoteControl from './components/RemoteControl';
import { history } from '../../utils/history';
import ToolsPanel from '../../components/ToolsPanel';
import Tool from '../../components/ToolBar/Tool';
import { ReactComponent as TrashEmpty } from '../../assets/images/trash_empty.svg';
import { ReactComponent as CaretDown } from '../../assets/images/caret_down.svg';
import { ReactComponent as Pencil } from '../../assets/images/pencil.svg';
import { NetworkStatus } from '../../common/constants';
import {
  isDevOrQAEnvironment,
  isEmpty,
  isLoadingStatusStarted,
  selectForCurrentBrand,
} from '../../common/helpers';
import {
  loadDevice,
  loadDeviceActivityLogs,
  loadDeviceApplications,
  loadDeviceLocationLogs,
  pingDeviceLocation,
  requestDeviceRemoteControl,
  uninstallApplications,
  updateDeviceProperty,
} from './device.thunk';
import {
  applicationsUninstallReset,
  loadDeviceActivityLogsReset,
  loadDeviceLogsReset,
  resetDeviceData,
  resetLoadDeviceApplications,
  updateDevicePropertyReset,
} from './device.actions';
import UserHelpers from '../../common/utilities/user';
import { getAccessFromGlobalPermissionsForUser } from '../../common/utilities/users';
import {
  FLEET_TREE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS,
  GLOBAL_READ_READWRITE_PERMISSIONS,
} from '../../constants/globalPermissions';
import { ACCESS_TYPES } from '../../constants/accessTypes';
import { globalPermissionsForUserSelector } from '../../selectors/accountSelectors';
import { menuConstants } from './device.constants';
import { showStatusChangePopup } from '../../common/utilities/common';
import LocationHistory from './components/LocationHistory';
import { deviceSelector } from './device.selectors';
import SubmissionInput from '../../components/SubmissionInput/SubmissionInput.component';
import DropDownMenu from '../../components/DropDownMenu/dropDownMenu.component';
import { getDeviceCommandItems } from '../devices/devices.helpers';
import { unenrollDevices } from '../fleets/fleets.thunk';
import {
  changeDeviceStatus,
  removeDevices,
  sendRemoteCommand,
} from '../devices/devices.thunk';
import StyleUtils from '../../utils/styleUtils';
import { fleetsV2Selector } from '../fleets/fleetsV2.selectors';
import {
  loadFleetConfigById,
  loadProfilesByFleetV1,
  loadProfilesByFleetV2,
  resetFleetConfigById,
} from '../fleets/fleetsV2.thunk';
import { devicesSelector } from '../devices/devices.selectors';
import {
  fleetProfilesReset,
  fleetProfilesV1Reset,
} from '../fleets/fleets.actions';
import AsyncRendererWithDataFetching from '../../components/AsyncRenderer/AsyncRendererWithDataFetching.component';
import { DataConstants } from '../../constants/data';
import { profilesSelector, profilesV2Selector } from '../../selectors/profiles';
import PopupActions from '../popup/popup.actions';
import RouteHelpers from '../../common/utilities/routeHelpers';
import { isRemoteControlAvailable } from '../../common/utilities/devices';
import { Throbber } from '../../components/Throbber';
import { FleetRoute } from '../../utils/routes';
import ActivityLogs from './components/ActivityLogs';

export const initialDevice = (companyId) => ({
  companyId,
  fleetId: null,
});

export class Device extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: this.deviceMenuStructure[0].key,
      device: this.initializeDevice(props),
      changed: false,
      selectedApps: new Set(),
      isShowRenameInput: false,
    };
  }

  get deviceMenuStructure() {
    const { globalPermissionsForUser, hasAdminPrivileges } = this.props;
    return [
      {
        key: menuConstants.OVERVIEW,
        title: <Translate value="device.overview" />,
      },
      {
        key: menuConstants.APPLICATIONS,
        title: <Translate value="device.applications" />,
      },
      {
        key: menuConstants.PROFILES_ASSIGNED,
        title: <Translate value="device.profilesAssigned" />,
      },
      {
        key: menuConstants.ACTIVITY_LOGS,
        title: <Translate value="device.activityLogs" />,
      },
      {
        key: menuConstants.LOCATION,
        title: <Translate value="device.location" />,
        hidden:
          !getAccessFromGlobalPermissionsForUser(
            globalPermissionsForUser,
            GLOBAL_READ_READWRITE_PERMISSIONS.VIEW_DEVICE_LOCATION,
            ACCESS_TYPES.READ
          ) && !hasAdminPrivileges,
        sub: [
          {
            key: menuConstants.CURRENT_LOCATION,
            title: <Translate value="device.currentLocation" />,
            hidden:
              !getAccessFromGlobalPermissionsForUser(
                globalPermissionsForUser,
                GLOBAL_READ_READWRITE_PERMISSIONS.CURRENT_LOCATION,
                ACCESS_TYPES.READ
              ) && !hasAdminPrivileges,
          },
          {
            key: menuConstants.LOCATION_HISTORY,
            title: <Translate value="device.locationHistory" />,
            hidden:
              !getAccessFromGlobalPermissionsForUser(
                globalPermissionsForUser,
                GLOBAL_READ_READWRITE_PERMISSIONS.LOCATION_HISTORY,
                ACCESS_TYPES.READ
              ) && !hasAdminPrivileges,
          },
        ],
      },
      {
        key: menuConstants.REMOTE_CONTROL,
        title: <Translate value="device.remoteControl" />,
        hidden: !this.props.isRemoteControlEnabled,
      },
    ].filter((item) => !item.hidden);
  }

  componentDidMount() {
    const { item } = this.props;
    const deviceId = RouteHelpers.getUrlParameter(this.props, 'deviceId');
    if (deviceId) {
      if (isEmpty(item)) {
        this.props.loadDevice(deviceId);
      } else {
        this.props.loadFleetConfigById({ id: item.fleetId });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.item?.id !== this.props.item?.id &&
      this.props.item?.fleetId
    ) {
      this.props.loadFleetConfigById({ id: this.props.item.fleetId });
    }

    if (
      this.props.applicationsUninstallStatus !== NetworkStatus.NONE &&
      this.props.applicationsUninstallStatus !==
        prevProps.applicationsUninstallStatus
    ) {
      showStatusChangePopup({
        status: this.props.applicationsUninstallStatus,
        title: I18n.t('device.uninstallApps'),
        onClose: this.props.applicationsUninstallReset,
      });
    }
    if (this.state.selectedCategory !== prevState.selectedCategory) {
      this.resetRenameInput();
    }
    if (
      prevProps.updateDevicePropertyStatus !==
        this.props.updateDevicePropertyStatus &&
      this.props.updateDevicePropertyStatus !== NetworkStatus.NONE
    ) {
      showStatusChangePopup({
        status: this.props.updateDevicePropertyStatus,
        title: I18n.t('device.updatingDeviceProperty'),
        errorText: this.props.updateDevicePropertyError,
        onClose: () => {
          this.props.updateDevicePropertyReset();
          if (this.props.updateDevicePropertyStatus === NetworkStatus.DONE) {
            this.resetRenameInput();
          }
        },
      });
    }
  }

  componentWillUnmount() {
    const fleetId = RouteHelpers.getQueryParamFromLocation(
      this.props.location,
      'fleetId'
    );
    const isPageOpenedFromFleetPage = Boolean(fleetId);
    if (!isPageOpenedFromFleetPage) {
      this.props.fleetProfilesReset();
      this.props.resetFleetConfigById();
      this.props.resetFleetV2Profiles();
      this.props.resetFleetV1Profiles();
      this.props.loadDeviceActivityLogsReset();
    }
    this.props.resetDeviceLogs();
    this.props.resetLoadDeviceApplications();
  }

  render() {
    const { deviceDataLoadingStatus } = this.props;

    const originatingFleetId = RouteHelpers.getQueryParamFromLocation(
      this.props.location,
      'fleetId'
    );
    const originatingFleetPath = originatingFleetId
      ? `${FleetRoute}/${originatingFleetId}`
      : '';

    if (isLoadingStatusStarted(deviceDataLoadingStatus)) {
      return <Throbber />;
    }

    return (
      <>
        <Dialog
          header={this.renderDeviceHeader}
          showMiniHeader
          menu={this.renderDeviceMenu}
          content={this.renderDeviceContent}
          isFullViewportVisible
          onBack={() => {
            this.setState({ selectedApps: new Set() });
            originatingFleetId
              ? history.push(originatingFleetPath)
              : history.goBack();
            this.props.resetDeviceData();
          }}
        />
        {this.renderTools()}
      </>
    );
  }

  renderTools = () => {
    const { hasAdminPrivileges, item } = this.props;
    if (this.state.selectedApps.size && item?.id && hasAdminPrivileges) {
      return (
        <ToolsPanel
          selectedItems={this.state.selectedApps}
          title={I18n.t('device.applicationsSelected')}
        >
          <Tool
            title={I18n.t('device.uninstallApps')}
            onClick={this.uninstallApps}
          >
            <TrashEmpty className={'icon'} />
          </Tool>
        </ToolsPanel>
      );
    }
  };

  renderDeviceActionsDropdownMenu = () => {
    const {
      item: { id, fleetId, customNotes } = {},
      hasAdminPrivileges,
      companyId,
      sendRemoteCommand,
      isGlobalFleetAccessReadWriteAccess,
      unEnrollDevices,
      removeDevices,
    } = this.props;

    const customDeviceActions = [
      {
        title: I18n.t('device.addAndUpdateNoteTitle'),
        onClick: () =>
          PopupActions.showPrompt({
            title: I18n.t('device.customNoteTitle'),
            id: 'DEVICE_CUSTOM_NOTE',
            onConfirm: ({ promptValue }) =>
              this.props.updateDeviceProperty(
                this.state.device.id,
                'custom_notes',
                promptValue
              ),
            allowEmptyValue: true,
            defaultValue: customNotes,
          }),
        hidden: !hasAdminPrivileges,
        key: 'device_custom_note',
        icon: NoteIcon,
      },
    ];

    const deviceCommandItems = getDeviceCommandItems({
      devices: [id],
      hideMoveDeviceTool: true,
      customConfirmParams: {
        fleetId,
      },
      changeDeviceStatus: this.props.changeDeviceStatus,
      hasAdminPrivileges,
      companyId,
      sendRemoteCommand,
      isGlobalFleetAccessReadWriteAccess,
      unEnrollDevices,
      removeDevices,
    });

    const menuItems = [...customDeviceActions, ...deviceCommandItems].reduce(
      (acc, { title, onClick, hidden, key, icon: Icon }) => {
        if (hidden) {
          return acc;
        }
        return [
          ...acc,
          {
            customMenuItem: (
              <div
                key={key}
                className={StyleUtils.mergeModifiers(
                  'drop-down-menu__list-item',
                  'aligned-left'
                )}
                onClick={onClick}
              >
                <Icon
                  className={StyleUtils.mergeClasses(
                    'icon--fixed-square',
                    'drop-down-menu__list-item-icon'
                  )}
                />
                <div className="drop-down-menu__list-item-text">{title}</div>
              </div>
            ),
          },
        ];
      },
      []
    );

    return (
      <DropDownMenu
        triggerButtonText={
          <Translate
            value="device.actions"
            className="device-header--action-dropdown-text"
          />
        }
        triggerProps={{
          secondary: true,
          rightIcon: <CaretDown />,
        }}
        items={menuItems}
      />
    );
  };

  renderDeviceHeader = () => {
    return (
      <Fragment>
        <div className={'device-header'}>
          <div className={'device-header-caption-text'}>
            <div className={'device-header-caption-text__prefix-icon'}>
              <PhoneAndroid alt={'_blank'} />
            </div>
            {this.renderDeviceNameSection()}
          </div>
          {this.renderDeviceActionsDropdownMenu()}
        </div>
      </Fragment>
    );
  };

  renderDeviceNameSection = () => {
    if (this.state.isShowRenameInput) {
      return (
        <SubmissionInput
          placeholder={I18n.t('device.enterAFleetName')}
          onKeyUp={this.onRenameKeyup}
          defaultValue={this.props.item?.name || ''}
          onSubmit={this.onRename}
          onReset={this.resetRenameInput}
          showResetIcon
          noEmptyValueAllowed
        />
      );
    }

    return (
      <Fragment>
        <div className={'device-header-caption-text__title'}>
          {this.state.device.name || ''}
        </div>
        {this.props.hasAdminPrivileges ? (
          <Pencil
            onClick={this.showInput}
            className={'device-header-caption-text__ending-icon'}
          />
        ) : null}
      </Fragment>
    );
  };

  renderDeviceMenu = () => {
    return (
      <Fragment>{this.deviceMenuStructure.map(this.renderMenuItem)}</Fragment>
    );
  };

  renderMenuItem = (item) => {
    const hasSubItems = item.sub && item.sub.length;
    const itemClass = selectForCurrentBrand({
      original: 'profile-menu__item',
      incube: 'profile-menu__item--incube',
      janam: 'profile-menu__item--janam',
    });
    const activeItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--active',
      incube: 'profile-menu__item--incube--active',
      janam: 'profile-menu__item--janam--active',
    });
    const intermediateItemClass = selectForCurrentBrand({
      original: 'profile-menu__item--indeterminate',
      incube: 'profile-menu__item--incube--indeterminate',
      janam: 'profile-menu__item--janam--indeterminate',
    });
    const itemClasses = ClassNames(itemClass, {
      [activeItemClass]: this.state.selectedCategory === item.key,
      [intermediateItemClass]:
        hasSubItems &&
        item.sub.some((subItem) => subItem.key === this.state.selectedCategory),
    });
    const clickHandler = (e) =>
      this.selectCategory(hasSubItems ? item.sub[0].key : item.key);
    return (
      <Fragment>
        <h4
          id={hasSubItems ? `${item.key.replace(/\s/, '_')}` : undefined}
          className={itemClasses}
          onClick={clickHandler}
        >
          {item.title ? item.title : item.key}
        </h4>
        {this.renderSubItems(hasSubItems, item)}
      </Fragment>
    );
  };

  renderSubItems = (hasSubItems, item) => {
    return (
      hasSubItems && (
        <div style={{ marginLeft: '10px' }}>
          <UncontrolledCollapse toggler={`${item.key.replace(/\s/, '_')}`}>
            {item.sub.map((item) => this.renderMenuItem(item))}
          </UncontrolledCollapse>
        </div>
      )
    );
  };

  renderDeviceContent = () => {
    const {
      hasAdminPrivileges,
      item: device = {},
      fleets,
      fleetProfilesV2Status,
      fleetProfilesV1Status,
      fleetProfilesV2Error,
      fleetProfilesV1Error,
      profilesV2,
      profiles,
    } = this.props;

    if (isEmpty(device)) {
      return null;
    }

    const { id: deviceId, fleetId } = device;

    switch (this.state.selectedCategory) {
      case menuConstants.OVERVIEW: {
        return <Overview item={this.props.item} fleets={fleets} />;
      }
      case menuConstants.APPLICATIONS: {
        return (
          <AsyncRendererWithDataFetching
            customDataStatus={{
              [DataConstants.DEVICE_APPLICATIONS]: this.props
                .applicationsLoadingStatus,
            }}
            customDataError={{
              [DataConstants.DEVICE_APPLICATIONS]: this.props
                .applicationsLoadingError,
            }}
            customDataFetchFunctions={{
              [DataConstants.DEVICE_APPLICATIONS]: () =>
                this.props.loadDeviceApplications(deviceId),
            }}
          >
            <Applications
              items={this.props.applications || []}
              status={this.props.applicationsLoadingStatus}
              selected={this.state.selectedApps}
              select={this.selectApp}
              selectable={!!this.props.item.id && hasAdminPrivileges}
            />
          </AsyncRendererWithDataFetching>
        );
      }
      case menuConstants.PROFILES_ASSIGNED: {
        const deviceProfiles = [
          ...this.props.deviceProfilesV2,
          ...this.props.deviceProfilesV1,
        ];

        return (
          <AsyncRendererWithDataFetching
            dataList={[
              {
                id: DataConstants.PROFILES_V2,
                params: {
                  loadFullList: true,
                  isInitialLoad: true,
                },
              },
              {
                id: DataConstants.PROFILES_V1,
              },
            ]}
            customDataStatus={{
              [DataConstants.FLEET_PROFILES_V2]: fleetProfilesV2Status,
              [DataConstants.FLEET_PROFILES_V1]: fleetProfilesV1Status,
            }}
            customDataError={{
              [DataConstants.FLEET_PROFILES_V2]: fleetProfilesV2Error,
              [DataConstants.FLEET_PROFILES_V1]: fleetProfilesV1Error,
            }}
            customDataFetchFunctions={{
              [DataConstants.FLEET_PROFILES_V2]: () =>
                this.props.loadProfilesByFleetV2({
                  fleetId,
                }),
              [DataConstants.FLEET_PROFILES_V1]: () =>
                this.props.loadProfilesByFleetV1({
                  fleetId,
                }),
            }}
          >
            <ProfilesAssigned items={deviceProfiles} />
          </AsyncRendererWithDataFetching>
        );
      }
      case menuConstants.ACTIVITY_LOGS: {
        return (
          <AsyncRendererWithDataFetching
            customDataStatus={{
              [DataConstants.DEVICE_ACTIVITY_LOGS]: this.props
                .activityLogsLoadingStatus,
            }}
            customDataError={{
              [DataConstants.DEVICE_ACTIVITY_LOGS]: this.props
                .activityLogsLoadingError,
            }}
            customDataFetchFunctions={{
              [DataConstants.DEVICE_ACTIVITY_LOGS]: () =>
                this.props.loadDeviceActivityLogs({ deviceId }),
            }}
            ignoreRerenders
          >
            <ActivityLogs device={device} />
          </AsyncRendererWithDataFetching>
        );
      }
      case menuConstants.CURRENT_LOCATION: {
        return (
          <Location
            coordinates={this.props.item.coordinates}
            pingDeviceLocation={() =>
              this.props.pingDeviceLocation(this.props.item.id)
            }
          />
        );
      }

      case menuConstants.LOCATION_HISTORY: {
        return (
          <AsyncRendererWithDataFetching
            customDataStatus={{
              [DataConstants.DEVICE_LOCATION_LOGS]: this.props
                .deviceLocationLogsLoadingStatus,
            }}
            customDataError={{
              [DataConstants.DEVICE_LOCATION_LOGS]: this.props
                .deviceLocationLogsLoadingError,
            }}
            customDataFetchFunctions={{
              [DataConstants.DEVICE_LOCATION_LOGS]: () =>
                this.props.loadDeviceLocationLogs({ deviceId }),
            }}
            ignoreRerenders
          >
            <LocationHistory
              items={this.props.deviceLocationLogs}
              loadingStatus={this.props.deviceLocationLogsLoadingStatus}
              device={device}
            />
          </AsyncRendererWithDataFetching>
        );
      }
      case menuConstants.SECURITY: {
        return <div />;
      }
      case menuConstants.REMOTE_CONTROL: {
        return (
          <RemoteControl
            item={this.props.item}
            requestDeviceRemoteControl={this.props.requestDeviceRemoteControl}
            requestDeviceRemoteControlStatus={
              this.props.requestDeviceRemoteControlStatus
            }
          />
        );
      }
      default:
        return null;
    }
  };

  resetSelectedApps = () =>
    this.tableRef.current?.toggleAllRowsSelected?.(false);

  uninstallApps = () => {
    this.props.uninstallApplications(
      [...this.state.selectedApps.values()],
      this.props.item.id
    );
    this.resetSelectedApps();
  };

  initializeDevice = ({ item }) => {
    return item ? cloneDeep(item) : initialDevice();
  };

  selectCategory = (selectedCategory) => {
    this.setState({ selectedCategory, selectedApps: new Set() });
  };

  selectApp = (selectedIds) => {
    const selectedApps = new Set(selectedIds);
    this.setState(() => ({ selectedApps }));
  };

  showInput = (e) => {
    this.setState({
      isShowRenameInput: true,
    });
  };

  onRenameKeyup = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.onRename();
    }
    if (e.keyCode === 27) {
      // Esc key
      this.resetRenameInput();
    }
  };

  onRename = (newName) =>
    this.props.updateDeviceProperty(
      this.state.device.id,
      'device_name',
      newName
    );

  resetRenameInput = () => {
    this.setState(() => ({
      isShowRenameInput: false,
    }));
  };
}

const mapStateToProps = (state) => {
  const hasAdminPrivileges = UserHelpers.hasAdminPrivileges(state);
  const fleetTreeGlobalPermissionsList =
    (state.groups.modelLevelPermissionsData &&
      state.groups.modelLevelPermissionsData.fleettree) ||
    [];
  const isGlobalFleetAccessReadWriteAccess = FLEET_TREE_V2_READ_WRITE_ACCESS_MODEL_GLOBAL_PERMISSIONS.every(
    (item) => fleetTreeGlobalPermissionsList.includes(item)
  );
  const { data: fleets } = fleetsV2Selector(state);
  const { fleetConfigStatus, fleetConfigError, fleetConfig } = fleetsV2Selector(
    state
  );
  const {
    fleetProfilesV2: deviceProfilesV2,
    fleetProfilesV1: deviceProfilesV1,
    fleetProfilesV1Status,
    fleetProfilesV1Error,
    fleetProfilesV2Status,
    fleetProfilesV2Error,
  } = devicesSelector(state);
  const {
    data: item,
    deviceDataLoadingStatus,
    deviceDataLoadingError,
    locationLogs,
    locationLogsLoadingStatus,
    locationLogsLoadingError,
    activityLogs,
    activityLogsLoadingStatus,
    activityLogsLoadingError,
  } = deviceSelector(state);
  const { data: profilesV2 } = profilesV2Selector(state);
  const { data: profiles } = profilesSelector(state);
  const isRemoteControlEnabled = isRemoteControlAvailable(fleetConfig);

  return {
    profilesV2,
    profiles,
    fleetProfilesV2Status,
    fleetProfilesV2Error,
    fleetProfilesV1Status,
    fleetProfilesV1Error,
    fleetConfigStatus,
    fleetConfigError,
    fleetConfig,
    isRemoteControlEnabled,
    deviceDataLoadingStatus,
    deviceDataLoadingError,
    companyId: companyIdSelector(state),
    fleets,
    item,
    applications: state.device.applications,
    applicationsLoadingStatus: state.device.applicationsLoadingStatus,
    applicationsLoadingError: state.device.applicationsLoadingError,
    deviceProfiles: state.device.profiles,
    deviceProfilesV2,
    deviceProfilesV1,
    deviceLocationLogs: locationLogs,
    deviceLocationLogsLoadingError: locationLogsLoadingError,
    deviceLocationLogsLoadingStatus: locationLogsLoadingStatus,
    activityLogs,
    activityLogsLoadingStatus,
    activityLogsLoadingError,
    requestDeviceRemoteControlStatus:
      state.device.requestDeviceRemoteControlStatus,
    applicationsUninstallStatus: state.device.applicationsUninstallStatus,
    updateDevicePropertyStatus: state.device.updateDevicePropertyStatus,
    updateDevicePropertyError: state.device.updateDevicePropertyError,
    globalPermissionsForUser: globalPermissionsForUserSelector(state),
    // globalPermissionsForUser: globalPermissionsMock,
    hasAdminPrivileges,
    isGlobalFleetAccessReadWriteAccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDevice: (id) => dispatch(loadDevice(id)),
    loadFleetConfigById: (params) => dispatch(loadFleetConfigById(params)),
    loadProfilesByFleetV2: (params) => dispatch(loadProfilesByFleetV2(params)),
    loadProfilesByFleetV1: (params) => dispatch(loadProfilesByFleetV1(params)),
    fleetProfilesReset: () => dispatch(fleetProfilesReset()),
    loadDeviceApplications: (id) => dispatch(loadDeviceApplications(id)),
    loadDeviceLocationLogs: (params) =>
      dispatch(loadDeviceLocationLogs(params)),
    loadDeviceActivityLogs: (params) =>
      dispatch(loadDeviceActivityLogs(params)),
    requestDeviceRemoteControl: (id) =>
      dispatch(requestDeviceRemoteControl(id)),
    pingDeviceLocation: (id) => dispatch(pingDeviceLocation(id)),
    uninstallApplications: (apps, id) =>
      dispatch(uninstallApplications(apps, id)),
    applicationsUninstallReset: () => dispatch(applicationsUninstallReset()),
    updateDeviceProperty: (id, propertyName, value) =>
      dispatch(updateDeviceProperty(id, propertyName, value)),
    updateDevicePropertyReset: () => dispatch(updateDevicePropertyReset()),
    resetDeviceData: () => dispatch(resetDeviceData()),
    unEnrollDevices: (devicesIds, fleetId) =>
      dispatch(unenrollDevices(devicesIds, fleetId)),
    removeDevices: (devicesIds, fleetId) =>
      dispatch(removeDevices(devicesIds, fleetId)),
    sendRemoteCommand: (params) => dispatch(sendRemoteCommand(params)),
    resetFleetConfigById: () => dispatch(resetFleetConfigById()),
    resetLoadDeviceApplications: () => dispatch(resetLoadDeviceApplications()),
    resetFleetV2Profiles: () => dispatch(fleetProfilesReset()),
    resetFleetV1Profiles: () => dispatch(fleetProfilesV1Reset()),
    resetDeviceLogs: () => dispatch(loadDeviceLogsReset()),
    loadDeviceActivityLogsReset: () => dispatch(loadDeviceActivityLogsReset()),
    changeDeviceStatus: (params) => dispatch(changeDeviceStatus(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Device);
