import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Glyphicon } from 'react-bootstrap';
import {
  AttributeType,
  DEFAULT_DATE_TIME_FORMAT,
} from '../../../common/constants';
import * as moment from 'moment';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { isEmpty } from '../../../common/helpers';
import PolicyActivationToggle from './PolicyActivationToggle.component';
import PolicyHeader from './PolicyHeader.component';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { I18n } from 'react-redux-i18n';

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  // change background colour if dragging
  // isDragging ? 'lightgray' : 'white',
  borderBottom: '1px solid lightgray',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgray' : '',
  padding: grid,
  width: 250,
});

export const initialSettings = {
  certificates: [],
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'certificate',
  isActive: false,
  policyData: null,
};

class Certificates extends Component {
  onChange = (index, key, value) => {
    const { certificates } = this.props;
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { certificates: [] };
    let foundIndex = settings[key].findIndex((item) => item.id === value);
    let certificate = certificates.find((item) => item.id === value);
    if (certificate) {
      if (foundIndex === -1) {
        settings[key].push(certificate);
      } else {
        settings[key].splice(foundIndex, 1);
      }
      item.settings = settings;
      this.props.onChange('certificates', index, item);
    }
  };

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (
      source.droppableId === 'selected' &&
      (!destination || source.droppableId !== destination.droppableId)
    ) {
      this.onChange(0, 'certificates', draggableId);
    }
    if (
      source.droppableId === 'items' &&
      destination &&
      destination.droppableId === 'selected'
    ) {
      this.onChange(0, 'certificates', draggableId);
    }
  };

  renderSettings = ({ settings, isActive } = {}, index) => {
    const selected = settings?.certificates ?? [];
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      this.props.onChangePolicyProperty({
        key: 'certificates',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });
    return (
      <div className={'certificates-wrapper'}>
        <SectionDescription
          title={I18n.t('profiles.certificates.policyTitle')}
        />
        <PolicyActivationToggle
          key="policyActivation"
          policy={this.props.policy}
          titleCode="profiles.certificates.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
        />
        {isActive ? (
          <div className={'certificates'}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className={'certificates-management'}>
                <div className={'section-description'}>
                  <div className={'section-description__title--small'}>
                    {I18n.t('profiles.certificates.certificatesTitle')}
                  </div>
                  <div>{''}</div>
                </div>
                <div className={'certificates-management-list'}>
                  <div className={'certificates-management-list-header'}>
                    <div>
                      {I18n.t('profiles.certificates.certificateNameTitle')}
                    </div>
                    <div>{I18n.t('profiles.certificates.lastUpdated')}</div>
                  </div>
                  <Droppable droppableId="selected">
                    {(provided, snapshot) => (
                      <div
                        className={'certificates-management-list-content'}
                        ref={provided.innerRef}
                        style={{
                          ...getListStyle(snapshot.isDraggingOver),
                          width: '100%',
                        }}
                      >
                        {selected.length === 0 ? (
                          <div
                            className={
                              'certificates-management-list-content-empty-notification'
                            }
                          >
                            <Glyphicon
                              className={
                                'certificates-management-list-content-empty-notification__icon'
                              }
                              glyph="cloud-upload"
                            />
                            <div
                              className={
                                'certificates-management-list-content-empty-notification__description'
                              }
                            >
                              {I18n.t(
                                'profiles.certificates.noCertificatesAttachedToProfile'
                              )}
                              <br />
                              {I18n.t(
                                'profiles.certificates.howToAddCertificateRecommendationText'
                              )}
                            </div>
                          </div>
                        ) : null}
                        {selected.map((item, index) => (
                          <Draggable
                            key={item.id || index + 'cert'}
                            draggableId={item.id || index + 'cert'}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={
                                  'certificates-management-list-content-item'
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  ),
                                  maxWidth: snapshot.isDragging
                                    ? '250px'
                                    : '100%',
                                }}
                              >
                                <div
                                  className={
                                    'certificates-management-list-content-item__left-cell'
                                  }
                                >
                                  {this.props.certificates.find(
                                    (certificate) => certificate.id === item.id
                                  )
                                    ? this.props.certificates.find(
                                        (certificate) =>
                                          certificate.id === item.id
                                      ).filename
                                    : 'n/a'}
                                </div>
                                <div
                                  className={
                                    'certificates-management-list-content-item__right-cell'
                                  }
                                >
                                  {this.props.certificates.find(
                                    (certificate) => certificate.id === item.id
                                  ) &&
                                  moment(
                                    this.props.certificates.find(
                                      (certificate) =>
                                        certificate.id === item.id
                                    )?.lastUpdated
                                  ).isValid()
                                    ? moment(
                                        this.props.certificates.find(
                                          (certificate) =>
                                            certificate.id === item.id
                                        ).lastUpdated
                                      )
                                        .format(DEFAULT_DATE_TIME_FORMAT)
                                        .toString()
                                    : 'n/a'}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              <div className={'certificates-management-source-section'}>
                <div
                  className={
                    'certificates-management-source-section-section-description-container'
                  }
                >
                  <div className={'section-description'}>
                    <div className={'section-description__title--small'}>
                      {I18n.t('routes.myCertificates')}
                    </div>
                    <div> </div>
                  </div>
                </div>
                <div className={'certificates-management-source-section-list'}>
                  <div
                    className={
                      'certificates-management-source-section-list-header'
                    }
                  >
                    <div>
                      {I18n.t('profiles.certificates.certificateNameTitle')}
                    </div>
                    <div>{I18n.t('profiles.certificates.lastUpdated')}</div>
                  </div>
                  <Droppable droppableId="items">
                    {(provided, snapshot) => (
                      <div
                        className={
                          'certificates-management-source-section-list-content'
                        }
                        ref={provided.innerRef}
                        style={{
                          ...getListStyle(snapshot.isDraggingOver),
                          width: '100%',
                        }}
                      >
                        {this.props.certificates
                          .filter(
                            (certificate) =>
                              !selected.find(
                                (selectedCert) =>
                                  selectedCert.id === certificate.id
                              )
                          )
                          .map((item, index) => (
                            <Draggable
                              key={item.id || index + 'my'}
                              draggableId={item.id || index + 'my'}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={
                                    'certificates-management-source-section-list-content-item'
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    ),
                                    maxWidth: snapshot.isDragging
                                      ? '250px'
                                      : '100%',
                                  }}
                                >
                                  <div
                                    className={
                                      'certificates-management-source-section-list-content-item__left-cell'
                                    }
                                  >
                                    {item.filename || 'n/a'}
                                  </div>
                                  <div
                                    className={
                                      'certificates-management-source-section-list-content-item__right-cell'
                                    }
                                  >
                                    {item.lastUpdated &&
                                    moment(item.lastUpdated).isValid()
                                      ? moment(item.lastUpdated)
                                          .format(DEFAULT_DATE_TIME_FORMAT)
                                          .toString()
                                      : 'n/a'}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </DragDropContext>
          </div>
        ) : null}
      </div>
    );
  };

  getProfileCertificates = () => {
    const {
      policy: [{ settings: { certificates = [] } = {} } = {}] = [],
    } = this.props;
    return certificates;
  };

  prepareReadOnlyCertificatesData = () => {
    const { policy: [{ isActive } = {}] = [] } = this.props;
    const profileCertificates = this.getProfileCertificates();
    if (isEmpty(profileCertificates)) {
      return [];
    }
    return [
      {
        id: 'policy-status',
        title: I18n.t('profiles.certificates.policyStatusTitle'),
        value: isActive,
        type: AttributeType.BOOLEAN,
      },
      {
        valueList: profileCertificates.map(({ filename }) => filename),
      },
    ];
  };

  renderReadOnlyContent = () => (
    <ReadOnlyForm
      items={this.prepareReadOnlyCertificatesData()}
      headerTitle={I18n.t('profiles.certificates.certificatesTitle')}
      emptyListMessage={I18n.t(
        'profiles.certificates.noCertificatesAttachedToProfile'
      )}
    />
  );

  renderContent = () => {
    return this.props.policy.length
      ? this.renderSettings(this.props.policy[0], 0)
      : this.renderSettings(
          { ...initialPolicy, settings: { certificates: [] } },
          0
        );
  };

  render() {
    const { isEditingMode } = this.props;
    return (
      <div className={'certificates-wrapper-container'}>
        {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
      </div>
    );
  }
}

export default Certificates;

Certificates.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
  certificates: PropTypes.arrayOf(PropTypes.object),
};
