import {
  ALERT_EDITING_FAILED,
  ALERT_EDITING_STARTED,
  ALERT_EDITING_SUCCESS,
  ALERT_LOAD_FAILED,
  ALERT_LOAD_STARTED,
  ALERT_LOAD_SUCCESS,
} from './alert.actionTypes';
import { NetworkStatus } from '../../common/constants';

export default function alert(
  state = {
    editAlertStatus: NetworkStatus.NONE,
    editAlertError: undefined,

    alertStatus: NetworkStatus.NONE,
    alertError: undefined,
    alertData: {},
  },
  action
) {
  switch (action.type) {
    case ALERT_LOAD_STARTED:
      return {
        ...state,
        alertStatus: NetworkStatus.STARTED,
        alertError: undefined,
      };

    case ALERT_LOAD_FAILED:
      return {
        ...state,
        alertStatus: NetworkStatus.ERROR,
        alertError: action.payload,
      };

    case ALERT_LOAD_SUCCESS:
      return {
        ...state,
        alertStatus: NetworkStatus.DONE,
        alertError: undefined,
        alert: action.payload,
      };

    case ALERT_EDITING_STARTED:
      return {
        ...state,
        editAlertStatus: NetworkStatus.STARTED,
        editAlertError: undefined,
      };

    case ALERT_EDITING_FAILED:
      return {
        ...state,
        editAlertStatus: NetworkStatus.ERROR,
        editAlertError: action.payload,
      };

    case ALERT_EDITING_SUCCESS:
      return {
        ...state,
        editAlertStatus: NetworkStatus.DONE,
        editAlertError: undefined,
      };

    default:
      return state;
  }
}
