import { I18n } from 'react-redux-i18n';

export const REMOTE_CONTROL_APPLICATION_PACKAGE_ID =
  'com.springdel.rc.agent.dpc';

// warning: do NOT localize!!!
export const REMOTE_CONTROL_APPLICATION_NAME = 'Remote Control App';

export const installOverCellularConditionConstants = {
  NONE: 'none',
  CELLULAR: 'cellular',
  CELLULAR_WHILE_ROAMING: 'cellular_while_roaming',
};

export const installOverCellularConditionOptions = [
  {
    title: I18n.t('profiles.apps.noneInstallOverCellularConditionTitle'),
    value: installOverCellularConditionConstants.NONE,
  },
  {
    title: I18n.t('profiles.apps.cellularInstallOverCellularConditionTitle'),
    value: installOverCellularConditionConstants.CELLULAR,
  },
  {
    title: I18n.t(
      'profiles.apps.cellularWhileRoamingInstallOverCellularConditionTitle'
    ),
    value: installOverCellularConditionConstants.CELLULAR_WHILE_ROAMING,
  },
];
