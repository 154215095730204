import React from 'react';
import { v4 as uuid } from 'uuid';
import StyleUtils from '../../utils/styleUtils';

import './styles/checkbox.scss';

const CheckBox = React.forwardRef(
  (
    {
      id,
      label = '',
      checked = false,
      name = '',
      onChange,
      className = '',
      inputRef = null,
      ...otherProps
    },
    ref
  ) => {
    const checkboxId = id || uuid();
    return (
      <div className={StyleUtils.mergeClasses('springdel-checkbox', className)}>
        <input
          {...otherProps}
          ref={ref}
          type="checkbox"
          onChange={onChange}
          id={checkboxId}
          name={name}
          checked={checked}
        />
        <label htmlFor={checkboxId}>{label}</label>
      </div>
    );
  }
);

export default CheckBox;
