import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { ProfilesRoutes, RootRoutes } from '../../../utils/routes';

class deliveredDevices extends Component {
  render() {
    const { devicesDeliveredCount, id } = this.props.item;
    return (
      <NavLink
        to={`${RootRoutes.PROFILES}${ProfilesRoutes.TASKS}/${id}/success`}
      >
        {`${devicesDeliveredCount}`}
      </NavLink>
    );
  }
}

export default deliveredDevices;
