import { NetworkStatus } from '../../common/constants';
import {
  USERS_LOGS_LOAD_FAILED,
  USERS_LOGS_LOAD_STARTED,
  USERS_LOGS_LOAD_SUCCESS,
} from './usersLogs.action';

export default function usersLogs(
  state = {
    status: NetworkStatus.NONE,
    error: undefined,
    nextPageStatus: NetworkStatus.NONE,
    nextPageError: undefined,
    data: [],
  },
  action
) {
  switch (action.type) {
    case USERS_LOGS_LOAD_STARTED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              status: NetworkStatus.STARTED,
              error: undefined,
              data: [],
            }
          : {
              nextPageStatus: NetworkStatus.STARTED,
              nextPageError: undefined,
            }),
      };
    case USERS_LOGS_LOAD_FAILED:
      return {
        ...state,
        ...(action.payload?.isInitialLoad
          ? {
              status: NetworkStatus.ERROR,
              error: action.payload?.error,
              data: [],
            }
          : {
              nextPageStatus: NetworkStatus.ERROR,
              nextPageError: action.payload?.error,
            }),
      };
    case USERS_LOGS_LOAD_SUCCESS:
      const { payload: { data, isInitialLoad, ...rest } = {} } = action;
      return {
        ...state,
        ...rest,
        data: [...(isInitialLoad ? [] : state.data), ...data],
        ...(isInitialLoad
          ? {
              status: NetworkStatus.DONE,
              error: undefined,
            }
          : {
              nextPageStatus: NetworkStatus.DONE,
              nextPageError: undefined,
            }),
      };

    default:
      return state;
  }
}
