import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// TODO: move into route sublayer
import { UsersRoutes, RootRoutes } from '../utils/routes';
import Users from '../features/users/users.component';
import UsersLogs from '../features/usersLogs/usersLogs.component';

import NotFound from '../components/NotFound';
import { ADMINS_GROUP_ID, ALL_USERS_GROUP_ID } from '../constants/users';

const _Users = ({ hasAdminPrivileges }) => {
  const defaultGroupForRedirection = hasAdminPrivileges
    ? ALL_USERS_GROUP_ID
    : ADMINS_GROUP_ID;
  return (
    <Switch>
      <Redirect
        exact
        from={`${RootRoutes.USERS}`}
        to={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${defaultGroupForRedirection}`}
      />
      <Redirect
        exact
        from={`${RootRoutes.USERS}${UsersRoutes.GROUPS}`}
        to={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/${defaultGroupForRedirection}`}
      />
      <Redirect
        exact
        from={`${RootRoutes.USERS}${UsersRoutes.LOGS}`}
        to={`${RootRoutes.USERS}${UsersRoutes.LOGS}/${defaultGroupForRedirection}`}
      />
      <Route
        exact
        path={`${RootRoutes.USERS}${UsersRoutes.GROUPS}/:groupId?/:childRoute?/:sectionId?/:sectionId2?`}
        component={Users}
      />
      <Route
        exact
        path={`${RootRoutes.USERS}${UsersRoutes.LOGS}/:groupId?`}
        component={UsersLogs}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default _Users;
