import client from '../utils/client';
import services from '../utils/services';
import {
  hideAllNotifications,
  hideNotification,
  loadNotificationsFailed,
  loadNotificationsStart,
  loadNotificationsSuccess,
  removeAllNotifications,
  removeNotification,
  showNotification,
} from './nofitications.actions';
import { deserializeDeviceNotification } from './notifications.serializer';
import { prepareReceivedNotification } from '../common/utilities/notifications';
import { notificationUpdateTypes } from '../constants/notifications';

export const loadNotifications = ({ offset, limit, isInitialLoad = false }) => {
  return async (dispatch) => {
    try {
      dispatch(loadNotificationsStart());
      const {
        status,
        data: { results = [], next },
        text = '',
      } = await client.get(services.getAlerts, {
        params: {
          offset: isInitialLoad ? 0 : offset,
          limit,
        },
      });
      if (status === 200 && results && results.length) {
        dispatch(loadNotificationsSuccess({ nextUrl: next }));
        results.forEach((notification) =>
          dispatch(
            showNotification(
              prepareReceivedNotification(
                deserializeDeviceNotification(notification)
              )
            )
          )
        );
      }
      if (status !== 200) {
        throw new Error(text);
      }
    } catch (error) {
      dispatch(loadNotificationsFailed(error.message));
    }
  };
};

export const updateNotifications = (notifications, updateType) => {
  return async (dispatch) => {
    try {
      const notificationsList = Array.isArray(notifications)
        ? notifications
        : [notifications];
      const ids = notificationsList.map(({ id }) => id);
      const isSingleNotification = notificationsList.length === 1;

      let updatedNotifications;
      let requestUrl;
      switch (updateType) {
        case notificationUpdateTypes.READ:
          updatedNotifications = notificationsList.map((notification) => ({
            ...notification,
            isRead: true,
          }));
          requestUrl = services.updateUnreadAlerts;
          break;

        case notificationUpdateTypes.REMOVE:
          updatedNotifications = notificationsList.map((notification) => ({
            ...notification,
            isDisplayedOnFrontend: false,
          }));
          requestUrl = services.updateDisplayOnFrontendAlerts;
          break;

        default:
          break;
      }

      const { status } = await client.put(requestUrl, {
        alert_list: ids,
      });

      if (status === 200) {
        if (isSingleNotification) {
          switch (updateType) {
            case notificationUpdateTypes.READ:
              dispatch(
                hideNotification({
                  notification: updatedNotifications,
                  markAsRead: true,
                })
              );
              break;

            case notificationUpdateTypes.REMOVE:
              dispatch(
                removeNotification({ notification: updatedNotifications })
              );
              break;

            default:
              break;
          }
        } else {
          switch (updateType) {
            case notificationUpdateTypes.READ:
              dispatch(hideAllNotifications());
              break;

            case notificationUpdateTypes.REMOVE:
              dispatch(removeAllNotifications());
              break;

            default:
              break;
          }
        }
      }
    } catch (error) {}
  };
};
