import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import AlertType from '../../../components/alertCreationAndEditing/alertType.component';

const AlertTypeTab = ({
  isEditingMode = false,
  editingModeData,
  handlePropertyChangeInEditingMode,
  data: { alertType } = {},
}) => {
  const renderContent = () => (
    <AlertType
      alert={editingModeData}
      onPropertyChange={handlePropertyChangeInEditingMode}
    />
  );

  const renderReadOnlyContent = () => (
    <ReadOnlyForm
      key="alert-type-readonly-form"
      items={[
        {
          value: I18n.t(`alerts.${alertType}`),
        },
      ]}
      headerTitle={
        <Translate
          className={'section-description__title--small'}
          value={'alerts.alertType'}
        />
      }
    />
  );

  return (
    <section>
      {isEditingMode ? renderContent() : renderReadOnlyContent()}
    </section>
  );
};

export default AlertTypeTab;
