import { NetworkStatus } from '../../common/constants';
import {
  TASK_LOAD_DEVICES_FAILED,
  TASK_LOAD_DEVICES_STARTED,
  TASK_LOAD_DEVICES_SUCCESS,
  TASK_LOAD_FAILED,
  TASK_LOAD_STARTED,
  TASK_LOAD_SUCCESS,
} from './task.actions';

export default function task(
  state = {
    status: NetworkStatus.NONE,
    error: undefined,
    data: undefined,
    devicesInstalled: [],
    devicesFailed: [],
    devicesStatus: NetworkStatus.NONE,
    devicesError: undefined,
  },
  action
) {
  switch (action.type) {
    case TASK_LOAD_STARTED:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.STARTED,
        error: undefined,
      });

    case TASK_LOAD_FAILED:
      return Object.assign({}, state, {
        data: [],
        status: NetworkStatus.ERROR,
        error: action.payload,
      });

    case TASK_LOAD_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: NetworkStatus.DONE,
        error: undefined,
      });

    case TASK_LOAD_DEVICES_STARTED:
      return Object.assign({}, state, {
        ...state,
        devicesInstalled: [],
        devicesFailed: [],
        devicesStatus: NetworkStatus.STARTED,
        devicesError: undefined,
      });

    case TASK_LOAD_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        devicesInstalled: action.payload.devicesInstalled,
        devicesFailed: action.payload.devicesFailed,
        devicesStatus: NetworkStatus.DONE,
        devicesError: undefined,
      });

    case TASK_LOAD_DEVICES_FAILED:
      return Object.assign({}, state, {
        devicesInstalled: [],
        devicesFailed: [],
        devicesStatus: NetworkStatus.ERROR,
        devicesError: action.payload,
      });
    default:
      return state;
  }
}
