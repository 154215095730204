import { createStore } from 'redux';
import { throttle } from 'lodash';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import { configureReducer } from './reducer';
import configureMiddleware from './middlware';
import { rehydrateState, persistState } from './persistedState';
import { locales, translations } from '../assets/translations';
import {
  authenticationSelector,
  loginDataSelector,
} from '../features/login/login.selectors';

export const configureStore = () => {
  const reducer = configureReducer();
  const persistedState = rehydrateState();
  const middleware = configureMiddleware();

  const store = createStore(reducer, persistedState, middleware);

  store.subscribe(
    throttle(() => {
      persistState({
        login: {
          data: loginDataSelector(store.getState()),
          isAuthenticated: authenticationSelector(store.getState()),
        },
        i18n: store.getState().i18n,
      });
    }, 1000)
  );

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translations));
  if (!store.getState().i18n.locale) {
    store.dispatch(setLocale(locales.EN_US));
  }

  return store;
};

export let store = configureStore();
export const dispatch = store.dispatch;
export const getState = store.getState;
