import React from 'react';
import Switch from 'react-toggle-switch';
import { Translate } from 'react-redux-i18n';
import Icon from '../components/Icon';
import { selectForCurrentBrand } from '../common/helpers';
import StyleUtils from '../utils/styleUtils';

export const SwitchField = ({
  title,
  titleCode,
  onClick,
  on,
  informationIconText,
  additionalClasses = [],
  ...rest
}) => {
  const itemClass = selectForCurrentBrand({
    original: 'switch-field',
    incube: 'switch-field switch-field--incube',
    janam: 'switch-field switch-field--janam',
  });
  return (
    <div className={StyleUtils.mergeClasses(itemClass, ...additionalClasses)}>
      <div className={'switch-field__title'}>
        {titleCode ? <Translate value={titleCode} /> : title}
        &nbsp;
        {informationIconText ? <Icon>{informationIconText}</Icon> : null}
      </div>
      <Switch onClick={onClick} on={on} {...rest} />
    </div>
  );
};
