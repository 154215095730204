export const APPLICATION_UPDATE_STARTED = 'APPLICATION_UPDATE_STARTED';
export const APPLICATION_UPDATE_FAILED = 'APPLICATION_UPDATE_FAILED';
export const APPLICATION_UPDATE_SUCCESS = 'APPLICATION_UPDATE_SUCCESS';
export const APPLICATION_LOAD_STARTED = 'APPLICATION_LOAD_STARTED';
export const APPLICATION_LOAD_FAILED = 'APPLICATION_LOAD_FAILED';
export const APPLICATION_LOAD_SUCCESS = 'APPLICATION_LOAD_SUCCESS';
export const APPLICATION_RESET = 'APPLICATION_RESET';
export const APPLICATION_LOAD_DEVICES_STARTED =
  'APPLICATION_LOAD_DEVICES_STARTED';
export const APPLICATION_LOAD_DEVICES_FAILED =
  'APPLICATION_LOAD_DEVICES_FAILED';
export const APPLICATION_LOAD_DEVICES_SUCCESS =
  'APPLICATION_LOAD_DEVICES_SUCCESS';
export const APPLICATION_ASSIGN_TO_PROFILES_STARTED =
  'APPLICATION_ASSIGN_TO_PROFILES_STARTED';
export const APPLICATION_ASSIGN_TO_PROFILES_FAILED =
  'APPLICATION_ASSIGN_TO_PROFILES_FAILED';
export const APPLICATION_ASSIGN_TO_PROFILES_SUCCESS =
  'APPLICATION_ASSIGN_TO_PROFILES_SUCCESS';
export const APPLICATION_ASSIGN_TO_PROFILES_RESET =
  'APPLICATION_ASSIGN_TO_PROFILES_RESET';

export function updateApplicationStarted() {
  return {
    type: APPLICATION_UPDATE_STARTED,
  };
}

export function updateApplicationFailed(payload) {
  return {
    type: APPLICATION_UPDATE_FAILED,
    payload,
  };
}

export function applicationUpdated(payload) {
  return {
    type: APPLICATION_UPDATE_SUCCESS,
    payload,
  };
}

export function loadApplicationStarted() {
  return {
    type: APPLICATION_LOAD_STARTED,
  };
}

export function loadApplicationFailed(payload) {
  return {
    type: APPLICATION_LOAD_FAILED,
    payload,
  };
}

export function applicationLoaded(payload) {
  return {
    type: APPLICATION_LOAD_SUCCESS,
    payload,
  };
}

export function resetApplication() {
  return {
    type: APPLICATION_RESET,
  };
}

export function assignApplicationToProfilesStarted() {
  return {
    type: APPLICATION_ASSIGN_TO_PROFILES_STARTED,
  };
}

export function assignApplicationToProfilesFailed(payload) {
  return {
    type: APPLICATION_ASSIGN_TO_PROFILES_FAILED,
    payload,
  };
}

export function assignApplicationToProfilesSuccess(payload) {
  return {
    type: APPLICATION_ASSIGN_TO_PROFILES_SUCCESS,
    payload,
  };
}

export function resetAssignApplicationToProfiles() {
  return {
    type: APPLICATION_ASSIGN_TO_PROFILES_RESET,
  };
}

export function loadApplicationDevicesStarted() {
  return {
    type: APPLICATION_LOAD_DEVICES_STARTED,
  };
}

export function loadApplicationDevicesFailed(payload) {
  return {
    type: APPLICATION_LOAD_DEVICES_FAILED,
    payload,
  };
}

export function applicationDevicesLoaded(payload) {
  return {
    type: APPLICATION_LOAD_DEVICES_SUCCESS,
    payload,
  };
}
