import React, { FC, ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import ReadOnlyListItem from './components/ReadOnlyListItem.component';
import { SectionDescription } from '../sectionDescription.component';
import { isEmpty } from '../../common/helpers';
import { AttributeType } from '../../common/constants';
import ReadOnlyBooleanListItem from './components/ReadOnlyBooleanListItem.component';

import './ReadOnlyForm.styles.scss';
import StyleUtils from '../../utils/styleUtils';

interface ReadOnlyFormProps {
  items: any[];
  headerTitle?: ReactNode;
  hint?: ReactNode;
  headerProps?: object;
  emptyListMessage?: ReactNode;
  additionalClassName?: string;
  isListEmpty?: boolean;
}

const ReadOnlyForm: FC<ReadOnlyFormProps> = ({
  items = [],
  headerTitle = '',
  hint,
  headerProps = {},
  emptyListMessage = '',
  additionalClassName,
  isListEmpty = false,
}) => {
  const rootClassName = 'read-only-form';

  return (
    <section
      className={StyleUtils.mergeClasses(rootClassName, additionalClassName)}
    >
      {headerTitle ? (
        <SectionDescription title={headerTitle} hint={hint} {...headerProps} />
      ) : null}
      {isEmpty(items) || isListEmpty ? (
        <p>{emptyListMessage}</p>
      ) : (
        <ul className={`${rootClassName}__list`}>
          {items.map((item) => {
            if (item.hidden) {
              return null;
            }
            const key = item.id || uuid();
            let ItemComponent;
            switch (item.type) {
              case AttributeType.BOOLEAN:
                ItemComponent = ReadOnlyBooleanListItem;
                break;
              case AttributeType.CUSTOM:
                ItemComponent = item.component;
                break;
              case AttributeType.SUBHEADING:
                ItemComponent = SectionDescription;
                break;
              case AttributeType.TEXT:
                ItemComponent = ReadOnlyListItem;
                break;
              default:
                ItemComponent = ReadOnlyListItem;
            }
            // todo: use attributive data format to format values and to get values from enumerable
            // and other types. pass already processed value in the next line
            return <ItemComponent key={key} {...item} />;
          })}
        </ul>
      )}
    </section>
  );
};

export default ReadOnlyForm;
