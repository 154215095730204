import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { newAlertSelector } from '../../alerts/alerts.selectors';
import { changeAlertProperty } from '../../alerts/alerts.actions';
import { history } from '../../../utils/history';
import { AlertWizardRoutes, CreateAlertRoute } from '../createAlert.constants';
import { FleetRoute } from '../../../utils/routes';
import EntityCreationWizardStepsNavigation from '../../../components/EntityCreationWizardStepsNavigation/entityCreationWizardStepsNavigation.component';
import ChooseFleets from '../../../components/alertCreationAndEditing/chooseFleets.component';
import { isEmpty } from '../../../common/helpers';

const ChooseAFleetStep = ({ newAlert, changeAlertProperty }) => {
  const goToFleetsPage = () => history.push(`${FleetRoute}`);
  const goToNextStep = () =>
    history.push(`${CreateAlertRoute}${AlertWizardRoutes.ALERT_TYPE}`);
  return (
    <div className="creation-wizard-step-container">
      <ChooseFleets alert={newAlert} onPropertyChange={changeAlertProperty} />
      <EntityCreationWizardStepsNavigation
        secondaryNav={{
          onClick: goToFleetsPage,
          text: I18n.t('alerts.createFleet'),
        }}
        primaryNav={{
          first: {
            isBack: true,
          },
          second: {
            onClick: goToNextStep,
            disabled: isEmpty(newAlert.fleets),
            text: `${I18n.t('alerts.next')} ⟶`,
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  newAlert: newAlertSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeAlertProperty: ({ key, value }) =>
    dispatch(changeAlertProperty({ key, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAFleetStep);
