import client from '../../utils/client';
import { history } from '../../utils/history';
import { ProfilesRoutes, RootRoutes } from '../../utils/routes';
import services from '../../utils/services';
import { deserializeApplication } from '../application/application.serializer';
import { loadProfiles } from '../profiles/profiles.thunk';
import {
  createProfileError,
  createProfileStarted,
  createProfileSuccess,
  loadProfileApplicationsFailed,
  loadProfileApplicationsStarted,
  profileApplicationLoaded,
  profileLoaded,
  profileLoadingFailed,
  profileLoadingStarted,
  updateProfileError,
  updateProfileStarted,
  updateProfileSuccess,
} from './profile.actions';
import { deserializeProfile, serializeProfile } from './profile.serializer';
import { I18n } from 'react-redux-i18n';

export function loadProfile(profileId) {
  return async (dispatch) => {
    try {
      dispatch(profileLoadingStarted());
      let response = await client.get(services.getProfile + profileId, {});
      if (response.status === 200) {
        let result = response.data;
        const profile = deserializeProfile(result);
        dispatch(profileLoaded(profile));
      } else {
        dispatch(
          profileLoadingFailed(I18n.t('profiles.failedToLoadProfileErrorText'))
        );
      }
    } catch (error) {
      dispatch(
        profileLoadingFailed(
          new Error(I18n.t('profiles.failedToLoadProfileErrorText'))
        )
      );
    }
  };
}

export function createProfile(profile, companyId) {
  return async (dispatch) => {
    try {
      dispatch(createProfileStarted());
      let data = serializeProfile(profile);
      let response = await client.post(services.createProfile, data);
      if (response.status === 200) {
        const profile = deserializeProfile(response.data);
        dispatch(createProfileSuccess(profile));
        history.push(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL}/${profile.id}`
        );
        if (companyId) {
          dispatch(loadProfiles());
        }
      } else if (response.status === 400) {
        dispatch(
          createProfileError(I18n.t('profiles.failedToCreateProfileErrorText'))
        );
      } else if (response.status > 200) {
        dispatch(
          createProfileError(I18n.t('profiles.failedToCreateProfileErrorText'))
        );
      }
    } catch (error) {
      dispatch(
        createProfileError(I18n.t('profiles.failedToCreateProfileErrorText'))
      );
    }
  };
}

export function updateProfile(profile, companyId) {
  return async (dispatch) => {
    try {
      dispatch(updateProfileStarted());
      let data = serializeProfile(profile);
      let response = await client.patch(services.updateProfile, data);
      if (response.status === 200) {
        const updatedProfile = deserializeProfile(response.data);
        dispatch(updateProfileSuccess(updatedProfile));
        history.replace(
          `${RootRoutes.PROFILES}${ProfilesRoutes.ALL}/${updatedProfile.id}`
        );
        if (companyId) {
          dispatch(loadProfiles());
        }
      } else if (response.status === 400) {
        dispatch(
          updateProfileError(I18n.t('profiles.failedToUpdateProfileErrorText'))
        );
      } else if (response.status > 200) {
        dispatch(
          updateProfileError(I18n.t('profiles.failedToUpdateProfileErrorText'))
        );
      }
    } catch (error) {
      dispatch(
        updateProfileError(I18n.t('profiles.failedToUpdateProfileErrorText'))
      );
    }
  };
}

export function loadProfileApplications(id) {
  return async (dispatch) => {
    try {
      dispatch(loadProfileApplicationsStarted());
      let response = await client.get(
        `${services.getProfileApplications}${id}`
      );
      if (response.status === 200 && response.data) {
        let result = response.data.map(deserializeApplication);
        dispatch(profileApplicationLoaded(result));
      } else {
        dispatch(
          loadProfileApplicationsFailed(
            I18n.t('profiles.failedToLoadProfileApplicationsErrorText')
          )
        );
      }
    } catch (error) {
      dispatch(
        loadProfileApplicationsFailed(
          I18n.t('profiles.failedToLoadProfileApplicationsErrorText')
        )
      );
    }
  };
}
