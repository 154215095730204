import {
  FILTERS_SET,
  FILTERS_SET_CRITERIA_STRICTNESS,
} from './filters.actionTypes';

export default function filters(
  state = {
    filters: [],
    isCriteriaStrict: 'strict',
    shouldUpdate: true,
  },
  action
) {
  switch (action.type) {
    case FILTERS_SET:
      return Object.assign({}, state, {
        filters: action.payload,
      });
    case FILTERS_SET_CRITERIA_STRICTNESS:
      return Object.assign({}, state, {
        isCriteriaStrict: action.payload,
      });
    default:
      return state;
  }
}
