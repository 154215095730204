import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { SelectField } from '../../../components/selectField.component';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { AttributeType } from '../../../common/constants';
import { I18n } from 'react-redux-i18n';
import PolicyActivationToggle from './PolicyActivationToggle.component';

export const initialSettings = {
  complexCharactersRequired: '0',
  minimumLength: '4',
  quality: 'alphanumeric',
};

export const initialPolicy = {
  policyName: 'new',
  policyType: 'password',
  isActive: false,
  policyData: null,
};

export const passwordLengthOptions = [
  { value: 4, title: 4 },
  { value: 5, title: 5 },
  { value: 6, title: 6 },
  { value: 7, title: 7 },
  { value: 8, title: 8 },
  { value: 9, title: 9 },
  { value: 10, title: 10 },
  { value: 11, title: 11 },
  { value: 12, title: 12 },
  { value: 13, title: 13 },
  { value: 14, title: 14 },
  { value: 15, title: 15 },
];

export const passwordQualityOptions = [
  {
    value: 'alphanumeric',
    title: I18n.t('profiles.password.passwordQualityOptionAlphanumeric'),
  },
  {
    value: 'alphabetic',
    title: I18n.t('profiles.password.passwordQualityOptionAlphabetic'),
  },
  {
    value: 'pattern',
    title: I18n.t('profiles.password.passwordQualityOptionPattern'),
  },
  { value: 'pin', title: I18n.t('profiles.password.passwordQualityOptionPIN') },
];

export const passwordComplexityOptions = [
  { value: 0, title: 0 },
  { value: 1, title: 1 },
  { value: 2, title: 2 },
  { value: 3, title: 3 },
];

export class Password extends Component {
  onToggle = (index, key) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings ? item.settings : { ...initialSettings };
    settings[key] = !settings[key];
    item.settings = settings;
    this.props.onChange('password', index, item);
  };

  onChange = (index, key, { target: { value } }) => {
    let policy = [...this.props.policy];
    let item =
      policy.length && policy[index]
        ? cloneDeep({ ...policy[index] })
        : { ...initialPolicy };
    let settings = item.settings
      ? cloneDeep(item.settings)
      : { ...initialSettings };
    settings[key] = value;
    item.settings = settings;
    this.props.onChange('password', index, item);
  };

  renderSettings = ({ settings, isActive }, index) => {
    const onEnableButtonToggle = ({
      presetNewToggleValue,
      index,
      propertyName,
    }) =>
      this.props.onChangePolicyProperty({
        key: 'password',
        propertyName,
        index,
        value: presetNewToggleValue,
        initialPolicy,
        initialSettings,
      });

    return (
      <div className={'password'}>
        <div className={'section-description'}>
          <div className={'section-description__title--small'}>
            {I18n.t('profiles.password.policyTitle')}
          </div>
          <div>{I18n.t('profiles.password.policyHint')}</div>
        </div>
        <PolicyActivationToggle
          key="policyActivation"
          policy={this.props.policy}
          titleCode="profiles.password.policyStatusTitle"
          handleToggle={onEnableButtonToggle}
        />
        {settings.passwordEnforcement || isActive ? (
          <>
            <SelectField
              title={`${I18n.t('profiles.password.passwordQualityLabel')}:`}
              value={settings.quality || ''}
              onChange={(e) => this.onChange(index, 'quality', e)}
              options={passwordQualityOptions}
            />
            <SelectField
              title={`${I18n.t('profiles.password.minimumLengthLabel')}:`}
              value={settings.minimumLength || ''}
              onChange={(e) => this.onChange(index, 'minimumLength', e)}
              options={passwordLengthOptions}
            />
            <SelectField
              title={`${I18n.t(
                'profiles.password.complexCharactersRequiredLabel'
              )}:`}
              value={settings.complexCharactersRequired || ''}
              onChange={(e) =>
                this.onChange(index, 'complexCharactersRequired', e)
              }
              options={passwordComplexityOptions}
            />
          </>
        ) : null}
      </div>
    );
  };

  renderContent = () => {
    return this.props.policy.length
      ? this.props.policy.map(this.renderSettings)
      : this.renderSettings(
          { ...initialPolicy, settings: { ...initialSettings } },
          0
        );
  };

  renderReadOnlyContent = () => {
    const { policy: [{ settings = {}, isActive } = {}] = [] } = this.props;
    const { quality = '' } = settings;
    const passwordQualityOption =
      passwordQualityOptions.find((option) => option.value === quality) || {};
    const passwordQualityOptionText = passwordQualityOption.title || '';

    return (
      <ReadOnlyForm
        headerTitle="Password"
        items={[
          {
            id: 'policy-status',
            title: I18n.t('profiles.password.policyStatusTitle'),
            value: settings.passwordEnforcement || isActive,
            type: AttributeType.BOOLEAN,
          },
          {
            title: I18n.t('profiles.password.passwordQualityLabel'),
            value: passwordQualityOptionText,
            key: 'quality',
          },
          {
            title: I18n.t('profiles.password.minimumLengthLabel'),
            value: settings['minimumLength'],
            key: 'minimumLength',
          },
          {
            title: I18n.t('profiles.password.complexCharactersRequiredLabel'),
            value: settings['complexCharactersRequired'],
            key: 'complexCharactersRequired',
          },
        ]}
      />
    );
  };

  render() {
    const { isEditingMode } = this.props;
    return (
      <div className={'password-wrapper'}>
        {isEditingMode ? this.renderContent() : this.renderReadOnlyContent()}
      </div>
    );
  }
}

export default Password;

Password.propTypes = {
  onChange: PropTypes.func,
  policy: PropTypes.arrayOf(PropTypes.object),
};
