import React, { Component } from 'react';
import { isEqual } from 'lodash';
import Tree, { TreeNode } from 'rc-tree';
import * as _ from 'lodash';
import { Glyphicon } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { get } from 'lodash';
import { ReactComponent as Folder } from '../../../assets/images/folder.svg';
import { ReactComponent as FolderFilled } from '../../../assets/images/folder_filled.svg';
import { ReactComponent as FolderFilledStar } from '../../../assets/images/folder_filled_star.svg';
import { history } from '../../../utils/history';
import { FleetRoute } from '../../../utils/routes';
import { companyIdSelector } from '../../login/login.selectors';
import { connect } from 'react-redux';
import { NetworkStatus } from '../../../common/constants';
import { confirmAlert } from 'react-confirm-alert';
import { Throbber } from '../../../components/Throbber';
import { Button } from '../../../components/button.component';
import {
  getListRecursive,
  isLoadingStatusStarted,
  searchRecursive,
  selectForCurrentBrand,
} from '../../../common/helpers';
import CheckBox from '../../../components/CheckBox';
import PopupActions from '../../popup/popup.actions';
import UserHelpers from '../../../common/utilities/user';
import SubmissionInput from '../../../components/SubmissionInput/SubmissionInput.component';

class FleetsTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fleetsTree: this.createStructuredTree(this.props.fleets),
      newFleet: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.fleets &&
      (prevProps.fleets !== this.props.fleets ||
        prevProps.rootId !== this.props.rootId)
    ) {
      this.setState({
        fleetsTree: this.createStructuredTree(this.props.fleets),
        newFleet: null,
      });
    }
    if (
      this.props.fleets &&
      this.props.fleets.length &&
      !isEqual(this.props.defaultSelectedKeys, prevProps.defaultSelectedKeys)
    ) {
      if (this.props.defaultSelectedKeys && !this.props.multiple) {
        const fleet = this.props.fleets.find(
          (fleet) => fleet.id === this.props.defaultSelectedKeys[0]
        );
        this.props.onSelect(
          fleet &&
            this.props.defaultSelectedKeys &&
            this.props.defaultSelectedKeys.length
            ? fleet
            : undefined
        );
      }
    }
    if (
      prevProps.createStatus === NetworkStatus.STARTED &&
      (this.props.createStatus === NetworkStatus.ERROR ||
        this.props.createStatus === NetworkStatus.DONE)
    ) {
      confirmAlert({
        customUI: this.renderCreateFleetStatus,
      });
    }
  }

  renderCreateFleetStatus = ({ onClose }) => {
    let description = <Translate value="fleets.creationInProgress" />;
    let text = '';
    if (this.props.createStatus === NetworkStatus.DONE) {
      description = <Translate value="fleets.success" />;
      text = <Translate value="fleets.fleetCreated" />;
    } else if (this.props.createStatus === NetworkStatus.ERROR) {
      text = this.props.createError || (
        <Translate value="fleets.cannotPerform" />
      );
      description = <Translate value="fleets.error" />;
    }

    const onClick = () => {
      onClose();
      if (this.props.createStatus === NetworkStatus.DONE) {
        this.cancelCreate();
      }
    };
    return (
      <div className={'create-enrollment-alert'}>
        <p>{description}</p>
        <p>{text}</p>
        <Button onClick={onClick}>
          <Translate value="common.ok" />
        </Button>
      </div>
    );
  };

  createNodes = (
    parentNode,
    flattenTree,
    idAlias,
    childIdsAlias = 'children',
    childAlias = 'sub'
  ) => {
    const children = get(parentNode, childIdsAlias, null);
    if (!children || !children.length) {
      return [];
    }
    return children
      .map((childNodeId) => {
        const childNode = flattenTree.find(
          (node) => node[idAlias] === childNodeId
        );
        return {
          ...childNode,
          [childAlias]: this.createNodes(
            childNode,
            flattenTree,
            idAlias,
            childIdsAlias,
            childAlias
          ),
        };
      })
      .filter((node) => node.id)
      .sort((childA = '', childB = '') => {
        const nameA = childA.name.toUpperCase();
        const nameB = childB.name.toUpperCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
  };

  createStructuredTree = (fleets) => {
    if (!fleets || !fleets.length) {
      return [];
    }
    return fleets
      .filter((fleet) =>
        this.props.rootId && this.props.rootId !== 'root'
          ? fleet.id === this.props.rootId
          : fleet.parentId === null
      )
      .map((rootNode) =>
        Object.assign({}, rootNode, {
          sub: this.createNodes(rootNode, fleets, 'id', 'children'),
        })
      );
  };

  createFleet = () => {
    this.setState({
      newFleet: { name: '', parentId: null, companyId: this.props.companyId },
    });
  };

  confirmCreate = () => {
    const { isCheckTree, selectedKeys = [], checkedKeys = [] } = this.props;
    const keys = isCheckTree ? checkedKeys : selectedKeys;
    this.props.createFleet({
      ...this.state.newFleet,
      parentId: keys && keys.length ? keys[0] : null,
    });
  };

  cancelCreate = () => {
    this.setState({ newFleet: undefined });
  };

  onChange = (e) => {
    let newFleet = { ...this.state.newFleet };
    newFleet.name = e.target.value;
    this.setState({ newFleet });
  };

  onKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Enter
      this.confirmCreate();
    }
    if (e.keyCode === 27) {
      // Esc
      this.cancelCreate();
    }
  };

  onMoveFleet = (event) => {
    const { fleets } = this.props;
    let parentId = _.get(event, 'node.props.eventKey', null);
    const isTargetRoot = parentId === 'root';
    let targetFleetName = _.get(event, 'node.props.title', null);
    let id = _.get(event, 'dragNode.props.eventKey', null);
    let name = _.get(event, 'dragNode.props.title', '');
    if (id) {
      let data = {
        companyId: this.props.companyId,
        parentId: isTargetRoot ? null : parentId,
        id,
        name,

        // v2 props
        fleetId: id,
        targetFleetId: isTargetRoot ? 'root' : parentId,
      };
      if (typeof this.props.onDrag === 'function') {
        PopupActions.showConfirm({
          onConfirm: ({
            secondaryConfirmationParams: {
              isSecondaryActionConfirmed = false,
            } = {},
          }) =>
            this.props.onDrag({
              ...data,
              retainProfiles: isSecondaryActionConfirmed,
            }),
          text: I18n.t('fleets.fleetMovementConfirmation', {
            fleetName: name,
            targetFleetName,
          }),
          ...(isTargetRoot
            ? {}
            : {
                secondaryConfirmationParams: {
                  text: I18n.t('fleets.fleetMovementSecondaryConfirmation', {
                    fleetName: name,
                  }),
                },
              }),
          onCancel: this.props.onDragCancel,
        });
      }
    }
  };

  onSelect = (selectedKeys, info) => {
    const {
      selectedKeys: prevSelectedKeys = [],
      onSelect,
      multiple,
    } = this.props;
    if (onSelect && info.node.props.eventKey !== 'root') {
      const { node: { props: { eventKey } = {} } = {} } = info;
      const fleet = searchRecursive(
        this.state.fleetsTree,
        'id',
        eventKey,
        'sub'
      );
      if (!multiple) {
        return onSelect(fleet);
      }
      const childFleets = getListRecursive(fleet, 'id', 'sub') || [];
      if (fleet === undefined) {
        return onSelect([]);
      }

      if (prevSelectedKeys?.length && prevSelectedKeys.includes(fleet.id)) {
        const uniqueKeys = [...new Set(prevSelectedKeys)];
        return onSelect(
          uniqueKeys.filter(
            (id) => id !== fleet.id && !childFleets.includes(id)
          )
        );
      }
      return onSelect([...selectedKeys, ...childFleets]);
    }
  };

  onCheck = (checkedKeys, info) => {
    if (this.props.onCheck && info.node.props.eventKey !== 'root') {
      const { node: { props: { eventKey } = {} } = {} } = info;
      const fleet = this.props.fleets.find((fleet) => fleet.id === eventKey);
      this.props.onCheck(
        checkedKeys && checkedKeys.length ? fleet : undefined,
        checkedKeys
      );
    }
  };

  onDoubleClick = (e, node) => {
    if (e && this.props.onSelect) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onSelect();
      const fleet = this.props.fleets.find(
        (fleet) => this.props.rootId && fleet.id === this.props.rootId
      );
      if (fleet || !this.props.rootId) {
        if (this.props.rootId && node.id === 'root') {
          // 1) -->  "One level up (to the parent fleet)" // history.push(`${RootRoutes.DEVICES}${DevicesRoutes.FLEETS}/${fleet.parent_id}`);
          // 2) "To the root fleet"
          // |
          // v
          history.push(`${FleetRoute}`);
        } else if (node.id !== 'root') {
          history.push(`${FleetRoute}/${node.id}`);
        }
      } else {
        history.push(`${FleetRoute}/fleet-not-found`);
      }
    }
  };

  renderIcon = (node) => {
    const imgSrc = node.expanded
      ? 'Folder'
      : node.children && node.children.length
      ? 'FolderFilledStar'
      : 'FolderFilled';

    switch (imgSrc) {
      case 'Folder':
        return (
          <Folder
            className={'icon icon--fleet'}
            onDoubleClick={(e) => this.onDoubleClick(e, node)}
            onClick={(e) => {
              if (node.id === 'root') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />
        );
      case 'FolderFilledStar':
        return (
          <FolderFilledStar
            className={'icon icon--fleet'}
            onDoubleClick={(e) => this.onDoubleClick(e, node)}
            onClick={(e) => {
              if (node.id === 'root') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />
        );
      case 'FolderFilled':
        return (
          <FolderFilled
            className={'icon icon--fleet'}
            onDoubleClick={(e) => this.onDoubleClick(e, node)}
            onClick={(e) => {
              if (node.id === 'root') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />
        );
      default:
        return '';
    }
  };

  renderFleet = (fleet) => {
    return (
      <TreeNode
        className={fleet.sub ? 'fleet-node' : 'fleet-node fleet-node--empty'}
        id={fleet.id}
        title={fleet.name || 'n/a'}
        key={fleet.id}
        icon={<div />}
        switcherIcon={this.renderIcon}
        disableCheckbox={!this.props.isCheckTree}
      >
        {fleet.sub && fleet.sub.map(this.renderFleet)}
      </TreeNode>
    );
  };

  renderNewNode = () => {
    if (this.state.newFleet && !this.props.multiple && this.props.createFleet) {
      return (
        <div className={'submission-input fleets-tree-submission-input'}>
          <span>
            <FolderFilled className={'fleets-tree-submission-input__image'} />
          </span>
          <SubmissionInput
            placeholder={I18n.t('fleets.fleetNamePlaceholder')}
            onInputChange={this.onChange}
            onKeyUp={this.onKeyUp}
            autoFocus
            onSubmit={this.confirmCreate}
            submitIconTitle={I18n.t('fleets.createFleet')}
            onReset={this.cancelCreate}
            submitDisabled={isLoadingStatusStarted(this.props.createStatus)}
            showResetIcon
          />
        </div>
      );
    }
  };

  expandNodes = (node, res) => {
    const {
      isCheckTree = false,
      defaultSelectedKeys = [],
      defaultCheckedKeys = [],
    } = this.props;
    const keys = isCheckTree ? defaultCheckedKeys : defaultSelectedKeys;
    if (node.sub && node.sub.length) {
      node.sub.forEach((n) => this.expandNodes(n, res));
      if (
        node.sub.some((ns) => {
          return (
            keys.some((k) => k === ns.id) ||
            (res && res.length && res.some((r) => r === ns.id))
          );
        })
      ) {
        res.push(node.id);
      }
    }
  };

  renderFooter = () => {
    const {
      createFleet,
      multiple,
      createAvailable,
      hasAdminPrivileges,
    } = this.props;
    if (createFleet && !multiple && createAvailable) {
      return (
        <div className={'has-feedback fleets-tree__footer'}>
          {hasAdminPrivileges ? (
            <Button onClick={this.createFleet}>
              <Translate value="fleets.createAFleet" />
              <Glyphicon style={{ marginLeft: '10px' }} glyph="plus" />
            </Button>
          ) : null}
        </div>
      );
    }
  };

  renderContent = () => {
    const {
      isCheckTree,
      checkedKeys,
      defaultCheckedKeys,
      multiple,
    } = this.props;

    if (this.props.status === NetworkStatus.STARTED) {
      return <Throbber />;
    }

    if (this.props.status === NetworkStatus.DONE || !this.props.status) {
      const defaultExpanded = [
        'root',
        ...(this.props.defaultKeys ? this.props.defaultKeys : []),
        ...(this.props.multiple &&
        this.state.fleetsTree &&
        this.state.fleetsTree.length
          ? this.state.fleetsTree.reduce((result, node) => {
              let expanded = [];
              this.expandNodes(node, expanded);
              return [...result, ...expanded];
            }, [])
          : []),
      ];
      const treeClass = selectForCurrentBrand({
        original: 'fleet-tree',
        incube: 'fleet-tree fleet-tree--incube',
        janam: 'fleet-tree fleet-tree--janam',
      });

      if (isCheckTree) {
        return (
          <Tree
            checkable
            showLine
            onCheck={this.onCheck}
            defaultCheckedKeys={defaultCheckedKeys}
            checkedKeys={checkedKeys || undefined}
            draggable
            icon={<div />}
            onDrop={this.onMoveFleet}
            className={treeClass}
            defaultExpandedKeys={defaultExpanded}
            multiple={multiple}
            selectable={false}
          >
            <TreeNode
              id={'root'}
              title={this.props.rootId ? ' ... ' : 'All fleets'}
              key={'root'}
              expanded={true}
              icon={<div />}
              className={'fleet-node fleet-node--root'}
              switcherIcon={this.renderIcon}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {this.state.fleetsTree.map(this.renderFleet)}
            </TreeNode>
          </Tree>
        );
      }

      return (
        <Tree
          showLine
          onSelect={this.onSelect}
          defaultSelectedKeys={this.props.defaultSelectedKeys}
          selectedKeys={this.props.selectedKeys || undefined}
          draggable
          onDrop={this.onMoveFleet}
          className={treeClass}
          defaultExpandedKeys={defaultExpanded}
          multiple={this.props.multiple}
        >
          <TreeNode
            selectable={false}
            id={'root'}
            title={this.props.rootId ? ' ... ' : I18n.t('fleets.allFleets')}
            key={'root'}
            expanded={true}
            icon={<div />}
            className={'fleet-node fleet-node--root'}
            switcherIcon={this.renderIcon}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {this.state.fleetsTree.map(this.renderFleet)}
          </TreeNode>
        </Tree>
      );
    }
  };

  render() {
    const {
      fleets = [],
      isCheckTree,
      selectedKeys = [],
      checkedKeys = [],
    } = this.props;
    const keys = isCheckTree ? checkedKeys : selectedKeys;
    return (
      <div className={'fleets-tree'}>
        {!this.props.hideHeader ? (
          <div className={'fleets-tree__header'}>
            <div />
          </div>
        ) : null}
        {this.renderNewNode()}
        {this.props.showSelectAll ? (
          <CheckBox
            onChange={this.props.onSelectAllClick}
            checked={Boolean(fleets.length) && fleets.length === keys.length}
            label={<Translate value="fleets.selectAllFleets" />}
          />
        ) : null}
        <div className={'fleets-tree__content'}>{this.renderContent()}</div>
        {this.renderFooter()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasAdminPrivileges: UserHelpers.hasAdminPrivileges(state),
    createStatus: state.fleetV2.createStatus,
    createError: state.fleetV2.createError,
    companyId: companyIdSelector(state),
  };
};

export default connect(mapStateToProps)(FleetsTree);
