import React from 'react';
import { SectionDescription } from '../../../components/sectionDescription.component';
import { InputField } from '../../../components/inputField.component';
import TextArea from '../../../components/TextArea';
import { I18n } from 'react-redux-i18n';

const EnrollmentEmail = ({
  emailSubject,
  message,
  onChangeEnrollmentEmailField,
}) => (
  <section className="enrollment-email">
    <SectionDescription title={I18n.t('users.enrollmentEmailTemplateTitle')} />
    <p>{I18n.t('users.fields.enrollmentEmailHint')}</p>
    <InputField
      value={emailSubject}
      name="emailSubject"
      onChange={({ target: { value } }) =>
        onChangeEnrollmentEmailField('emailSubject', value)
      }
      label={I18n.t('users.fields.emailSubjectFieldTitle')}
    />
    <TextArea
      label={I18n.t('users.fields.emailContentFieldTitle')}
      value={message}
      onChange={({ target: { value } }) =>
        onChangeEnrollmentEmailField('message', value)
      }
      autoGrow
    />
  </section>
);

export default EnrollmentEmail;
