import React from 'react';
import { Translate } from 'react-redux-i18n';
import ReadOnlyForm from '../../../components/ReadOnlyForm/ReadOnlyForm.component';
import { getFleetsById } from '../../../common/utilities/fleets';
import ChooseFleets from '../../../components/alertCreationAndEditing/chooseFleets.component';

const LinkedFleetsTab = ({
  isEditingMode = false,
  editingModeData,
  handlePropertyChangeInEditingMode,
  allFleets = [],
  data: { fleets: linkedFleets } = {},
}) => {
  const renderContent = () => (
    <ChooseFleets
      alert={editingModeData}
      onPropertyChange={handlePropertyChangeInEditingMode}
    />
  );

  const fleetList = getFleetsById(allFleets, linkedFleets);
  const fleetNames = fleetList.map(({ name }) => name);
  const renderReadOnlyContent = () => (
    <ReadOnlyForm
      key="linked-fleets-readonly-form"
      items={[
        {
          valueList: fleetNames,
        },
      ]}
      headerTitle={
        <Translate
          className={'section-description__title--small'}
          value={'alerts.linkedFleets'}
        />
      }
    />
  );

  return (
    <section>
      {isEditingMode ? renderContent() : renderReadOnlyContent()}
    </section>
  );
};

export default LinkedFleetsTab;
