import { I18n } from 'react-redux-i18n';
import client from '../../utils/client';
import { unsubscribeChanges } from '../devices/devices.sockets';
import {
  LOGGED_IN,
  LOG_IN_RESET,
  LOGGED_OUT,
  LOGGING_IN_FAILED,
  LOGGING_IN_STARTED,
  LOG_OUT_STARTED,
} from './login.actionTypes';
import { loginApi } from './login.api';
import PopupActions from '../popup/popup.actions';
import { serializeAuthenticationData } from './login.serializer';
import services from '../../utils/services';
import { clearAllData } from '../../store/persistedState';
import { LAST_ACTIVE_CONSTANT } from '../../constants/user';

export function loggingInStarted() {
  return {
    type: LOGGING_IN_STARTED,
  };
}

export function loggedIn(payload) {
  return {
    type: LOGGED_IN,
    payload,
  };
}

export function loggingInFailed(payload) {
  return {
    type: LOGGING_IN_FAILED,
    payload,
  };
}

export function loggedInReset() {
  return {
    type: LOG_IN_RESET,
  };
}

export function loggedOut(payload) {
  return {
    type: LOGGED_OUT,
    payload,
  };
}

export function logoutStarted() {
  return {
    type: LOG_OUT_STARTED,
  };
}

const onLoginSuccess = (dispatch, { data = {} }) => {
  window.localStorage.setItem(LAST_ACTIVE_CONSTANT, null);
  dispatch(loggedIn(deserializeUserData(data)));
};

const TENANT_SELECTION_MODAL_ID = 'TENANT_SELECTION_MODAL_ID';

const onPreLoginSuccess = async ({
  dispatch,
  loginUrl,
  loginParams,
  preLoginResponse,
}) => {
  try {
    const { isMultiTenantUser, tenants } = serializeAuthenticationData(
      preLoginResponse.data
    );
    if (isMultiTenantUser) {
      await PopupActions.showOptions({
        id: TENANT_SELECTION_MODAL_ID,
        title: I18n.t('login.multiTenantPopupTitle'),
        text: I18n.t('login.multiTenantPopupText'),
        options: tenants.map(({ tenantId, tenantName }) => ({
          id: tenantId,
          label: tenantName,
          value: tenantId,
        })),
        optionsListParams: {
          withColumnView: true,
        },
        closeOnProceed: true,
        proceedButtonText: I18n.t('login.multiTenantProceedButtonText'),
        cancelButtonText: I18n.t('login.multiTenantCancelButtonText'),
        onProceed: async ({ checkedValue }) => {
          const multiTenantResponse = await client.post(loginUrl, {
            company_id: checkedValue,
            ...loginParams,
          });
          if (multiTenantResponse.status === 200) {
            onLoginSuccess(dispatch, multiTenantResponse);
          } else {
            throw new Error(multiTenantResponse.data?.detail);
          }
        },
        onCancel: () => {
          dispatch(loggedInReset());
        },
        resolveOnCancel: true,
      });
    } else {
      const singleTenantResponse = await client.post(loginUrl, {
        company_id: tenants[0]?.tenantId,
        ...loginParams,
      });
      if (singleTenantResponse.status === 200) {
        onLoginSuccess(dispatch, singleTenantResponse);
      } else {
        throw new Error(singleTenantResponse?.data?.detail);
      }
    }
  } catch (error) {
    PopupActions.closePopup(TENANT_SELECTION_MODAL_ID).then(() => {
      PopupActions.showAlert({
        title: I18n.t('login.loginErrorTitle'),
        text: error.message,
      });
    });
  }
};

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch(loggingInStarted());
      const data = {
        username: email,
        password: password,
      };
      const preLoginResponse = await loginApi(data);
      if (preLoginResponse.status === 200 && preLoginResponse.data) {
        await onPreLoginSuccess({
          dispatch,
          loginUrl: services.login,
          loginParams: { username: email, password },
          preLoginResponse,
        });
      } else if (preLoginResponse.status === 401) {
        dispatch(
          loggingInFailed(new Error(I18n.t('errors.invalidCredentials')))
        );
      } else {
        dispatch(
          loggingInFailed(new Error(I18n.t('errors.serviceUnavailable')))
        );
      }
    } catch (error) {
      dispatch(loggingInFailed(new Error(I18n.t('errors.serviceUnavailable'))));
    }
  };
}

export const SSOLogin = ({ data, preLoginUrl, loginUrl }) => {
  return async (dispatch) => {
    try {
      dispatch(loggingInStarted());
      const preLoginResponse = await client.post(preLoginUrl, data);
      if (preLoginResponse.status === 200 && preLoginResponse.data) {
        await onPreLoginSuccess({
          dispatch,
          loginUrl,
          loginParams: data,
          preLoginResponse,
        });
      } else {
        const errorText =
          preLoginResponse.data?.detail ||
          preLoginResponse.statusText ||
          I18n.t('common.somethingWentWrong');
        dispatch(loggingInFailed(errorText));
        throw new Error(errorText);
      }
    } catch (e) {
      PopupActions.showAlert({
        text: e.message,
      });
    }
  };
};

export function logout({ error, clearData = false, reloadApp = false } = {}) {
  return async (dispatch) => {
    try {
      dispatch(logoutStarted());
      await client.post(services.logout);
      // const { status } = await client.post(services.logout);
      // if (status === 204) {
      //   dispatch(unsubscribeChanges());
      //   dispatch(loggedOut(error));
      //   if (clearData) {
      //     clearAllData();
      //   }
      //   if (reloadApp) {
      //     // the next line forces a reload.
      //     window.location.assign('/');
      //   }
      // } else {
      //   PopupActions.showAlert({
      //     text: I18n.t('session.logoutFailure'),
      //   });
      // }

      dispatch(unsubscribeChanges());
      dispatch(loggedOut(error));
      if (clearData) {
        clearAllData();
      }
      if (reloadApp) {
        // the next line forces a reload.
        window.location.assign('/');
      }
    } catch (er) {
      dispatch(loggedOut(error));
    }
  };
}

export const deserializeUserData = ({
  company_id,
  partner_id,
  role,
  activated_providers,
} = {}) => ({
  companyId: company_id,
  partnerId: partner_id,
  role: role,
  activatedProviders: activated_providers,
});
