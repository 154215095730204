import { SYSTEM_MESSAGE_LOAD_STARTED } from './systemMessage.actionTypes';

export const loadSystemMessageStarted = () => ({
  type: SYSTEM_MESSAGE_LOAD_STARTED,
});

export const loadSystemMessageFailed = (payload) => ({
  type: SYSTEM_MESSAGE_LOAD_STARTED,
  payload,
});

export const loadSystemMessageSuccess = (payload) => ({
  type: SYSTEM_MESSAGE_LOAD_STARTED,
  payload,
});
