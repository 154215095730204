import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    flexFlow: 'column nowrap',
    padding: '20px 24px',
  },
};
const options = [
  ['All', I18n.t('enrollments.allVersionsOptionText')],
  ['8.0', 'Oreo 8.0'],
  ['8.1', 'Oreo 8.1'],
  ['7.0', 'Nougat 7.0'],
  ['7.1.0', 'Nougat 7.1.0'],
  ['7.1.2', 'Nougat 7.1.2'],
  ['6.0', 'Marshmallow 6.0'],
  ['6.0.1', 'Marshmallow 6.0.1'],
  ['5.0', 'Lollipop 5.0'],
  ['5.1.0', 'Lollipop 5.1.0'],
  ['5.1.1', 'Lollipop 5.1.1'],
];

export const DeviceType = (props) => {
  const renderContent = () => {
    return (
      <div
        style={{
          width: '75%',
          maxWidth: '600px',
          minWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          height: '100%',
          paddingRight: '20px',
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '18px' }}>Device Types</div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '15px',
            }}
          >
            <div style={{ marginBottom: '5px' }}>Selected type:</div>
            <select
              className="form-control"
              value={props.value || ''}
              onChange={(e) => props.onChange('deviceType', e.target.value)}
            >
              {options.map((currentValue, index) => (
                <option value={currentValue[0]}>{currentValue[1]}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  return <div style={styles.root}>{renderContent()}</div>;
};

export default DeviceType;

DeviceType.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
