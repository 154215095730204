import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AlertRoutes, RootRoutes } from '../utils/routes';

import Alerts from '../features/alerts/alerts.component';
import CreateAlert from '../features/createAlert/createAlert.component';
import { CreateAlertRoute } from '../features/createAlert/createAlert.constants';
import NotFound from '../components/NotFound';

const AlertManager = () => (
  <Switch>
    <Redirect
      exact
      from={RootRoutes.ALERTS}
      to={`${RootRoutes.ALERTS}${AlertRoutes.ALERTS}`}
    />
    <Route
      exact
      path={`${RootRoutes.ALERTS}${AlertRoutes.ALERTS}/:childRoute?`}
      component={Alerts}
    />
    <Route exact path={`${CreateAlertRoute}/:step?`} component={CreateAlert} />
    <Route component={NotFound} />
  </Switch>
);

export default AlertManager;
