import { AttributeType } from '../../common/constants';
import { DevicesRoutes, RootRoutes } from '../../utils/routes';
import { I18n } from 'react-redux-i18n';
import { filterConditions } from '../../constants/filter';

export const enrollmentsAttributes = [
  {
    key: 'name',
    type: AttributeType.TEXT,
    titleCode: 'enrollments.fields.name',
    ref: `${RootRoutes.DEVICES}${DevicesRoutes.ENROLLMENTS}`,
    refId: 'id',
    searchable: true,
    availableFilterConditions: [
      filterConditions.CONTAINS,
      filterConditions.EQUAL,
      filterConditions.NOT_EQUAL,
    ],
  },
  {
    key: 'status',
    type: AttributeType.ENUMERABLE,
    titleCode: 'enrollments.fields.status',
    searchable: true,
    availableFilterConditions: [filterConditions.EQUAL],
    availableOptions: [
      {
        title: I18n.t('enrollments.fields.statusDraft'),
        value: 'draft',
      },
      {
        title: I18n.t('enrollments.fields.statusActive'),
        value: 'active',
      },
      {
        title: I18n.t('enrollments.fields.statusInactive'),
        value: 'inactive',
      },
    ],
  },
  {
    key: 'lastUpdated',
    type: AttributeType.DATE,
    titleCode: 'enrollments.fields.lastUpdated',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
  {
    key: 'createdAt',
    type: AttributeType.DATE,
    titleCode: 'enrollments.fields.createdAt',
    searchable: true,
    availableFilterConditions: [
      filterConditions.EQUAL,
      filterConditions.GREATER_THAN,
      filterConditions.LESS_THAN,
    ],
  },
];
