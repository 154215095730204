import {
  PREFERENCES_LOAD_FAILED,
  PREFERENCES_LOAD_STARTED,
  PREFERENCES_LOAD_SUCCESS,
  PREFERENCES_UPDATE_FAILED,
  PREFERENCES_UPDATE_STARTED,
  PREFERENCES_UPDATE_SUCCESS,
} from './preferences.actionTypes';

export const preferencesLoadingStarted = () => {
  return {
    type: PREFERENCES_LOAD_STARTED,
  };
};

export const preferencesLoaded = (payload) => {
  return {
    type: PREFERENCES_LOAD_SUCCESS,
    payload,
  };
};

export const preferencesLoadingFailed = (payload) => {
  return {
    type: PREFERENCES_LOAD_FAILED,
    payload,
  };
};

export const preferencesUpdateStarted = () => {
  return {
    type: PREFERENCES_UPDATE_STARTED,
  };
};

export const preferencesUpdated = (payload) => {
  return {
    type: PREFERENCES_UPDATE_SUCCESS,
    payload,
  };
};

export const preferencesUpdateFailed = (payload) => {
  return {
    type: PREFERENCES_UPDATE_FAILED,
    payload,
  };
};
