import * as React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import { ReactComponent as BackArrow } from '../../../assets/images/back-arrow.svg';
import { ReactComponent as HomeBlack } from '../../../assets/images/house_black.svg';
import { ReactComponent as Rectangle } from '../../../assets/images/reactangle.svg';
import { ReactComponent as PlusCircleFilled } from '../../../assets/images/plus-circle-filled.svg';
import { ReactComponent as MinusCircleFilled } from '../../../assets/images/minus-circle-filled.svg';
import { ReactComponent as Power } from '../../../assets/images/power.svg';
import { ReactComponent as SwipeTopPhone } from '../../../assets/images/swipe_top_phone.svg';
import { ReactComponent as Kiosk } from '../../../assets/images/kiosk.svg';
import { ReactComponent as KioskClose } from '../../../assets/images/kiosk_close.svg';
import { ReactComponent as FullScreen } from '../../../assets/images/full-screen.svg';
import { ReactComponent as ExitFullScreen } from '../../../assets/images/exit-full-screen.svg';
import { Throbber } from '../../../components/Throbber';
import {
  ACTION_DOWN,
  ACTION_MOVE,
  ACTION_UP,
  DATA_TYPE_BUTTON_TYPE,
  GLOBAL_ACTION_BACK,
  GLOBAL_ACTION_HOME,
  GLOBAL_ACTION_RECENTS,
  IMAGE_SIZE_FACTOR,
  REMOTE_EVENT_INPUT_OBJECT_TYPE,
  REMOTE_EVENT_KEYBOARD_EVENT_TYPE,
  REMOTE_EVENT_MOTION_EVENT_TYPE,
  REMOTE_EVENT_OBJECT_DATA_KEY,
  REMOTE_EVENT_OBJECT_TYPE_KEY,
  DATA_TYPE_KEYBOARD_STRING,
  DATA_TYPE_MOTION_EVENT,
  KEYBOARD_EXTRA_ACTION,
  KEYBOARD_EXTRA_DEVICE_ID,
  KEYBOARD_EXTRA_FLAGS,
  KEYBOARD_EXTRA_KEY_CODE,
  KEYBOARD_EXTRA_META_STATE,
  KEYBOARD_EXTRA_REPEAT_COUNT,
  KEYBOARD_EXTRA_SCAN_CODE,
  KEYBOARD_EXTRA_SOURCE,
  KEYBOARD_EXTRA_UNICODE_CHAR,
  CANVAS_SIZE as CANVAS_SIZE_DEFAULT,
  DATA_TYPE_IMAGE_PARAMS,
  DATA_TYPE_TOGGLE_SCREEN,
  DATA_TYPE_VOLUME_UP,
  DATA_TYPE_VOLUME_DOWN,
  REMOTE_EVENT_SCREEN_PARAMETERS_TYPE,
  REMOTE_EVENT_IMAGE_SIZE_PERCENTAGE,
  DATA_TYPE_KEYBOARD_EVENT,
  REMOTE_EVENT_AVAILABLE_SCREEN_CAPTURES_TYPE,
  REMOTE_EVENT_SCREEN_CAPTURE_CHANGED_TYPE,
  DATA_TYPE_EXIT_FROM_KIOSK,
  DATA_TYPE_ENTER_TO_KIOSK,
} from './RemoteControl.constants';
import {
  getRegionsFromMessageData,
  isRegionsData,
} from './RemoteControl.helpers';
import { Springdel } from '../webrtc';
import { getHost, getProtocol, getRcPort } from '../../../utils/env';
import Slider from 'rc-slider';
import { SwitchField } from '../../../components/switchField.component';
import { selectForCurrentBrand } from '../../../common/helpers';
import { SelectField } from '../../../components/selectField.component';

import './remoteControl.scss';

const CONTROL_PANEL_WIDTH = 32;

const ALLOWANCE_FOR_CONTROL_ELEMENTS_AT_THE_BOTTOM = 115;

export class KeyboardEventObject {
  action;
  keyCode;
  scanCode;
  metaState;
  repeatCount;
  deviceId;
  flags;
  source;
  unicodeChar;

  constructor(jsonObject) {
    this.action = jsonObject[KEYBOARD_EXTRA_ACTION] || -1;
    this.keyCode = jsonObject[KEYBOARD_EXTRA_KEY_CODE] || 0;
    this.scanCode = jsonObject[KEYBOARD_EXTRA_SCAN_CODE] || 0;
    this.metaState = jsonObject[KEYBOARD_EXTRA_META_STATE] || 0;
    this.repeatCount = jsonObject[KEYBOARD_EXTRA_REPEAT_COUNT] || 0;
    this.deviceId = jsonObject[KEYBOARD_EXTRA_DEVICE_ID] || -1;
    this.flags = jsonObject[KEYBOARD_EXTRA_FLAGS] || 0;
    this.source = jsonObject[KEYBOARD_EXTRA_SOURCE] || 0;
    this.unicodeChar = jsonObject[KEYBOARD_EXTRA_UNICODE_CHAR] || '\0';
  }
}

export class KeyboardStringObject {
  data;

  constructor(data) {
    this.data = data;
  }

  toJsonString() {
    const obj = {
      DataType: DATA_TYPE_KEYBOARD_STRING,
      data: this,
    };
    return JSON.stringify(obj);
  }
}

export class MotionEventObject {
  x;
  y;
  pressTime;
  action;

  constructor(x, y, pressTime, action) {
    this.x = x;
    this.y = y;
    this.pressTime = pressTime;
    this.action = action;
  }

  toJsonString() {
    const obj = {
      DataType: DATA_TYPE_MOTION_EVENT,
      data: this,
    };
    return JSON.stringify(obj);
  }
}

const styles = {
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
};

export class RemoteControl extends React.Component {
  connection = Springdel.SpringdelServerConnection;
  listener;

  remoteControlRef = React.createRef();

  state = {
    peers: [],
    inputText: '',
    circles: [],
    connectionStatus: I18n.t('device.connectingToTheServer'),
    imageSizePercentage: 100,
    imageMaxQuality: 100,
    canReceiveRegions: true,
    factor: IMAGE_SIZE_FACTOR,
    screenCaptureTypes: [],
    isFullScreenEnabled: false,
  };

  componentDidMount() {
    this.initRoom();
    this.props.requestDeviceRemoteControl(this.props.item.id);
  }

  componentWillUnmount() {
    this.room.disconnect();
    this.unCaptureKeyboard();
  }

  get screenAvailableHeight() {
    return window.screen.availHeight;
  }

  get fullScreenCanvasHeight() {
    return (
      this.screenAvailableHeight - ALLOWANCE_FOR_CONTROL_ELEMENTS_AT_THE_BOTTOM
    );
  }

  initRoom = () => {
    this.connection.SERVER_URL = `${getProtocol()}//${getHost()}`;
    this.room = this.connection.connectToRoom(this.props.item.id);
    this.room.onConnectedListeners.addListener(() => {
      this.setState({
        connectionStatus: I18n.t(
          'device.connectingToTheServerWaitingForTheDevice'
        ),
      });
    });
    this.room.onPeerJoinedListeners.addListener(this.onPeerJoined);
    this.room.onPeerLeaveListeners.addListener(this.onPeerLeave);
    this.room.onDisconnectedListeners.addListener(() => {
      this.setState({
        connectionStatus: I18n.t(
          'device.disconnectedFromTheServerTryingReconnect'
        ),
      });
    });
  };

  onPeerJoined = (peer) => {
    peer.onReceiveMessageListeners.addListener((message) => {
      this.handleMessages(message, peer);
    });
    peer.onReceiveDataListeners.addListener((message) => {
      this.onDataReceived(message, peer);
    });
    this.setState((state) => ({
      peers: [...state.peers, peer],
      connectionStatus: I18n.t('device.deviceConnected'),
    }));
  };

  onPeerLeave = (peer) => {
    this.setState({
      connectionStatus: I18n.t('device.deviceDisconnectedWaitingForTheDevice'),
    });
    this.setState((state) => {
      let peers = [...state.peers];
      let peerIndex = state.peers.indexOf(peer);
      if (peerIndex) {
        peers.splice(peerIndex, 1);
        return { peers };
      }
      return state;
    });
  };

  handleMessages = (message, peer) => {
    const { isFullScreenEnabled } = this.state;
    const CANVAS_SIZE = isFullScreenEnabled
      ? this.fullScreenCanvasHeight
      : CANVAS_SIZE_DEFAULT;

    let jsonData;
    try {
      jsonData = JSON.parse(message);
      const objectType = jsonData[REMOTE_EVENT_OBJECT_TYPE_KEY];
      const dataObject = jsonData[REMOTE_EVENT_OBJECT_DATA_KEY];
      switch (objectType) {
        case REMOTE_EVENT_MOTION_EVENT_TYPE:
          // const {x, y} = dataObject;
          // this.drawTouch(peer, layout, x, y);
          break;
        case REMOTE_EVENT_KEYBOARD_EVENT_TYPE:
          // const keyboardEventObject = new KeyboardEventObject(dataObject);
          break;
        case REMOTE_EVENT_INPUT_OBJECT_TYPE:
          const inputElement = document.getElementById(`focus_${peer.id}`);
          if (inputElement) {
            inputElement.innerHTML = `${I18n.t(
              'device.remoteControlCurrentFocusTextTitle'
            )}: ${dataObject.data}`;
          }
          break;
        case REMOTE_EVENT_SCREEN_PARAMETERS_TYPE:
          const imageWidth = dataObject.width;
          const imageHeight = dataObject.height;
          let canvasWidth;
          let canvasHeight;
          if (imageWidth > imageHeight) {
            canvasWidth = CANVAS_SIZE;
            canvasHeight = (imageHeight * CANVAS_SIZE) / imageWidth;
          } else {
            canvasWidth = (imageWidth * CANVAS_SIZE) / imageHeight;
            canvasHeight = CANVAS_SIZE;
          }
          this.setState(
            (state) => ({
              ...state,
              factor: 1,
              mouseFactor: 1,
              canvasWidth,
              canvasHeight,
            })
            // () => {
            //     if (!this.state.currentImage) {
            //         const layout = document.getElementById(`canvas_${peer.id}`);
            //         if (layout) {
            //             const image = new Image();
            //             image.onload = () => {
            //                 // Here is loading preview
            //                 console.warn("GOT IT)");
            //                 layout
            //                     .getContext("2d")
            //                     .drawImage(
            //                         image,
            //                         canvasWidth / 2 - image.naturalWidth / 2,
            //                         canvasHeight / 2 - image.naturalHeight / 2,
            //                         image.naturalWidth,
            //                         image.naturalHeight
            //                     );
            //             };
            //             image.src = rocketAnimation;
            //         }
            //     }
            // }
          );
          break;
        case REMOTE_EVENT_IMAGE_SIZE_PERCENTAGE:
          const imageMaxQuality = dataObject.quality;
          const imageSizePercentage = dataObject.size_percentage;
          this.setState((state) => ({
            ...state,
            imageSizePercentage,
            imageMaxQuality,
          }));
          break;
        case REMOTE_EVENT_AVAILABLE_SCREEN_CAPTURES_TYPE:
          let screenCaptureTypes = (
            dataObject || []
          ).map(({ value, name: title }) => ({ value, title }));
          this.setState((state) => ({
            ...state,
            screenCaptureTypes,
            screenCaptureType:
              screenCaptureTypes && screenCaptureTypes[0]
                ? screenCaptureTypes[0].value
                : undefined,
          }));
          break;
        case REMOTE_EVENT_SCREEN_CAPTURE_CHANGED_TYPE:
          let screenCaptureType = dataObject.type;
          console.log('Changed screen capture type', screenCaptureType);
          this.setState((state) => ({
            ...state,
            screenCaptureType,
          }));
          break;
        default:
          console.info('Received undefined object type: ', objectType);
      }
    } catch (e) {
      console.warn('Parse message json data error', message, e);
    }
  };

  onDataReceived = (message, peer) => {
    const layout = document.getElementById(`canvas_${peer.id}`);
    if (isRegionsData(message)) {
      const regions = getRegionsFromMessageData(message);
      regions.forEach((region, index) => {
        const image = new Image();
        image.onload = () => {
          try {
            if (layout) {
              layout
                .getContext('2d')
                .drawImage(
                  image,
                  region.x * (this.state.factor || IMAGE_SIZE_FACTOR),
                  region.y * (this.state.factor || IMAGE_SIZE_FACTOR),
                  region.width * (this.state.factor || IMAGE_SIZE_FACTOR),
                  region.height * (this.state.factor || IMAGE_SIZE_FACTOR)
                );
            }
          } catch (e) {
            console.warn(e);
          }
        };
        image.src = `data:image/jpg;base64,${region.bytes}`;
      });
    } else {
      const { isFullScreenEnabled } = this.state;
      const CANVAS_SIZE = isFullScreenEnabled
        ? this.fullScreenCanvasHeight
        : CANVAS_SIZE_DEFAULT;

      const image = new Image();
      image.onload = () => {
        const layout = document.getElementById(`canvas_${peer.id}`);
        let canvasWidth = (image.width * CANVAS_SIZE) / image.height;
        let canvasHeight = CANVAS_SIZE;
        let factor = CANVAS_SIZE / image.height;
        let mouseFactor =
          image.naturalHeight /
          CANVAS_SIZE /
          (this.state.imageSizePercentage / 100);

        if (image.width > image.height) {
          canvasWidth = CANVAS_SIZE;
          canvasHeight = (image.height * CANVAS_SIZE) / image.width;
          factor = CANVAS_SIZE / image.width;
          mouseFactor =
            image.naturalWidth /
            CANVAS_SIZE /
            (this.state.imageSizePercentage / 100);
        }
        if (layout) {
          layout
            .getContext('2d')
            .drawImage(image, 0, 0, canvasWidth, canvasHeight);
        }
        this.setState((state) => {
          return {
            ...state,
            currentImage: image,
            canvasWidth,
            canvasHeight,
            factor,
            mouseFactor,
          };
        });
      };
      image.src = `data:image/jpg;base64,${message}`;
    }
  };

  onMouseAction = (event, peer, action) => {
    if (
      action === ACTION_UP ||
      action === ACTION_DOWN ||
      this.state.lastMotionAction === ACTION_DOWN ||
      (this.state.lastMotionAction === ACTION_MOVE &&
        (action === ACTION_UP || action === ACTION_MOVE))
    ) {
      const motionEventObject = new MotionEventObject(
        this.state.mouseFactor *
          (event.detail && event.detail.offsetX != null
            ? event.detail.offsetX
            : event.nativeEvent.offsetX),
        this.state.mouseFactor *
          (event.detail && event.detail.offsetY != null
            ? event.detail.offsetY
            : event.nativeEvent.offsetY),
        Date.now(),
        action
      );
      this.sendMotionEvent(peer, motionEventObject);
      this.setState({ lastMotionAction: action });
    }
  };

  onMouseLeave = (event, peer) => {
    if (this.state.lastMotionAction === ACTION_MOVE) {
      const customEventObject = {
        detail: {
          offsetX:
            event.nativeEvent.offsetX < 0 ? 0 : event.nativeEvent.offsetX,
          offsetY:
            event.nativeEvent.offsetY < 0 ? 0 : event.nativeEvent.offsetY,
        },
      };
      const onMouseMoveOutside = (e) => {
        if (this.state.lastMotionAction !== ACTION_UP) {
          this.onMouseAction(
            new CustomEvent('mousemove', customEventObject),
            peer,
            ACTION_MOVE
          );
        } else {
          document.removeEventListener('mousemove', onMouseMoveOutside);
        }
      };
      const onMouseUpOutside = (e) => {
        if (this.state.lastMotionAction === ACTION_MOVE) {
          this.onMouseAction(
            new CustomEvent('mouseup', customEventObject),
            peer,
            ACTION_UP
          );
        } else {
          document.removeEventListener('mouseup', onMouseUpOutside);
        }
      };
      document.addEventListener('mousemove', onMouseMoveOutside);
      document.addEventListener('mouseup', onMouseUpOutside);
      window.getSelection().removeAllRanges(); // To prevent text selection
    }
  };

  onKeyDown = (event, peer) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.sendKeyboardString(peer, this.state.inputText);
      this.setState({ inputText: '' });
    }
  };

  onChangeText = (e) => {
    const { target: { value } = {} } = ({} = e);
    e.stopPropagation();
    console.count('onChangeText called');
    this.setState(() => ({ inputText: value }));
  };

  sendP2PMessage = (msg, peer) => {
    peer.sendMessage(msg);
  };

  sendMotionEvent = (peer, motionEvent) => {
    console.log(
      `SendMotionEvent to ${peer.id}: ${motionEvent.action}`,
      motionEvent
    );
    this.sendP2PMessage(motionEvent.toJsonString(), peer);
  };

  sendKeyboardString = (peer, str) => {
    console.log(`SendKeyboardString to ${peer.id}: ${str}`);
    this.sendP2PMessage(new KeyboardStringObject(str).toJsonString(), peer);
  };

  sendGlobalAction = (peer, action) => {
    const obj = {
      DataType: DATA_TYPE_BUTTON_TYPE,
      data: { action },
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendImageMaxQuality = (peer, value) => {
    console.log(`sendImageMaxQuality (${peer.id}, ${value})`);
    const obj = {
      DataType: DATA_TYPE_IMAGE_PARAMS,
      data: {
        quality: value,
      },
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendImageSizePercentage = (peer, value) => {
    console.log(`sendImageSizePercentage (${peer.id}, ${value})`);
    const obj = {
      DataType: DATA_TYPE_IMAGE_PARAMS,
      data: {
        size_percentage: value,
      },
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendPowerButtonClick = (peer) => {
    console.log(`sendPowerButtonClick (${peer.id})`);
    const obj = {
      DataType: DATA_TYPE_TOGGLE_SCREEN,
      data: {},
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendVolumeUpButtonClick = (peer) => {
    console.log(`sendVolumeUpButtonClick (${peer.id})`);
    const obj = {
      DataType: DATA_TYPE_VOLUME_UP,
      data: {},
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendVolumeDownButtonClick = (peer) => {
    console.log(`sendVolumeDownButtonClick (${peer.id})`);
    const obj = {
      DataType: DATA_TYPE_VOLUME_DOWN,
      data: {},
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendCanReceiveRegions = (peer, value) => {
    console.log(`sendCanReceiveRegions (${peer.id}, ${value})`);
    const obj = {
      DataType: DATA_TYPE_IMAGE_PARAMS,
      data: {
        accept_regions: value,
      },
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendSwipeUpAction = (peer) => {
    console.log(`sendSwipeUpAction (${peer.id})`);
    const obj = {
      DataType: 'swipe_up',
      data: {},
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendChangeCaptureTypeEvent = (peer, value) => {
    console.log(`sendChangeCaptureTypeEvent to ${peer.id}: ${value}`);
    const obj = {
      DataType: 'screen_capture',
      data: {
        type: value,
      },
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendExitKioskModeButtonClick = (peer) => {
    console.log(`sendExitKioskModeButtonClick (${peer.id})`);
    const obj = {
      DataType: DATA_TYPE_EXIT_FROM_KIOSK,
      data: {},
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  sendEnterKioskButtonClick = (peer) => {
    console.log(`sendEnterKioskButtonClick (${peer.id})`);
    const obj = {
      DataType: DATA_TYPE_ENTER_TO_KIOSK,
      data: {},
    };
    this.sendP2PMessage(JSON.stringify(obj), peer);
  };

  captureKeyboard = (peer) => () => {
    if (this.state.currentImage) {
      this.unCaptureKeyboard();
      this.listener = (event) => {
        switch (event.key) {
          case 'Backspace': {
            // let obj = {
            //     DataType: DATA_TYPE_KEYBOARD_EVENT,
            //     data: {
            //         action: 0,
            //         keyCode: 67,
            //     }
            // }
            // this.sendP2PMessage(JSON.stringify(obj), peer);
            const obj1 = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 0,
                keyCode: 67,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj1), peer);
            const obj = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 1,
                keyCode: 67,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj), peer);
            break;
          }
          case 'Delete': {
            const obj1 = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 0,
                keyCode: 112,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj1), peer);
            const obj = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 1,
                keyCode: 112,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj), peer);
            break;
          }
          case 'ArrowLeft': {
            const obj1 = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 0,
                keyCode: 21,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj1), peer);
            const obj = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 1,
                keyCode: 21,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj), peer);
            break;
          }
          case 'ArrowRight': {
            const obj1 = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 0,
                keyCode: 22,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj1), peer);
            const obj = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 1,
                keyCode: 22,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj), peer);
            break;
          }
          case 'Enter': {
            const obj1 = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 0,
                keyCode: 66,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj1), peer);
            const obj = {
              DataType: DATA_TYPE_KEYBOARD_EVENT,
              data: {
                action: 1,
                keyCode: 66,
              },
            };
            this.sendP2PMessage(JSON.stringify(obj), peer);
            break;
          }
          default: {
            if (!event.isComposing && event.keyCode !== 229) {
              event.preventDefault();
              if (typeof event.key === 'string' && event.key.length === 1) {
                console.count('from default');
                this.sendKeyboardString(peer, event.key);
              }
            }
            break;
          }
        }
      };
      document.addEventListener('keydown', this.listener);
    }
  };

  unCaptureKeyboard = () => {
    if (this.listener) {
      document.removeEventListener('keydown', this.listener);
      this.listener = undefined;
    }
  };

  // drawTouch = (peer, layout, x, y) => {
  //     const time = Date.now();
  //     const circle = {
  //         peerId: peer.id,
  //         x: layout.width * x / this.state.currentImage.naturalWidth,
  //         y: layout.height * y / this.state.currentImage.naturalHeight,
  //         time,
  //         alpha: 0
  //     };
  //     this.setState(state => ({circles: [...state.circles, circle]}), () => {
  //         setTimeout(() => {
  //                 this.setState(state => ({
  //                     circles: state.circles.filter((circle) => circle.time !== time),
  //                 }), this.updateCanvases);
  //         }, TOUCH_DISAPPEARANCE_TIME);
  //     });
  //     this.updateCanvases();
  //     console.log("Pushing circle: " + circle.x)
  // };

  // updateCanvases = () => {
  //     let peer = this.state.peers.length && this.state.showPeer ?
  //         this.state.peers.find(({id}) => id === this.state.showPeer.id)
  //         : undefined;
  //     if (peer) {
  //         const layout = document.getElementById(`canvas_${peer.id}`);
  //         if (layout != null) {
  //             layout
  //                 .getContext('2d')
  //                 .clearRect(0, 0, layout.width, layout.height);
  //             const currentTime = Date.now();
  //             this.state.circles.forEach((circle, index) => {
  //                     if (layout.height == 0) {
  //                         layout.height = document.getElementById(`img_${peer.id}`).height;
  //                         let context = layout.getContext('2d');
  //                         // console.log("Drawing circle for " + peerId)
  //                         let alpha = TOUCH_MAX_ALPHA - (TOUCH_MAX_ALPHA * (Math.max(0, currentTime - circle.time) / TOUCH_DISAPPEARANCE_TIME));
  //                         this.setState( state => {
  //                             let circles = [...state.circles];
  //                             circles.splice(index, 1, {
  //                                 ...circles[index],
  //                                 alpha: Math.max(0, alpha),
  //                             });
  //                             return ({...state, circles});
  //                         });
  //
  //                         context.beginPath();
  //                         context.arc(circle.x, circle.y, 15, 0, 2 * Math.PI, false);
  //                         context.closePath();
  //                         const alphaHex = rgbToHex(Math.round(Math.max(0, alpha)));
  //                         // console.log(`Alpha hex = ${circle.alpha} => ${alphaHex}`)
  //                         context.fillStyle = `#0000ff${alphaHex}`; //
  //                         context.fill();
  //                     }
  //             });
  //
  //         }
  //     }
  // };

  renderScreenCaptureTypeField = (peer) => {
    let { screenCaptureTypes, currentScreenCaptureType } = this.state;
    if (this.state.screenCaptureTypes.length > 1) {
      return (
        <SelectField
          title={I18n.t('device.screenCaptureType')}
          placeholder={I18n.t('device.chooseScreenCaptureType')}
          value={currentScreenCaptureType || ''}
          onChange={({ target: value } = {}) => {
            this.sendChangeCaptureTypeEvent(peer, value);
            this.setState((state) => ({ ...state, currentScreenCaptureType }));
          }}
          options={screenCaptureTypes}
        />
      );
    }
    return (
      <div
        style={{
          marginBottom: '15px',
          display: 'inline-flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
          <Translate value="device.screenCaptureType" />
        </div>
        <div>
          {screenCaptureTypes[0]
            ? screenCaptureTypes[0].name
            : I18n.t('device.mediaProjection')}
        </div>
      </div>
    );
  };

  renderControlPanel = (peer) =>
    this.state.currentImage ? (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: `${CONTROL_PANEL_WIDTH}px`,
          color: selectForCurrentBrand({
            original: '#003562',
            janam: '#ff7600',
            incube: '#1a1e35',
          }),
        }}
      >
        {this.state.isFullScreenEnabled ? (
          <div title={I18n.t('device.exitFullScreenMode')}>
            <ExitFullScreen
              style={{
                width: '14px',
                height: 'auto',
                cursor: 'pointer',
                userSelect: 'none',
                marginBottom: '40px',
              }}
              onClick={this.toggleFullScreenMode}
            />
          </div>
        ) : (
          <div title={I18n.t('device.fullScreenMode')}>
            <FullScreen
              style={{
                width: '14px',
                height: 'auto',
                cursor: 'pointer',
                userSelect: 'none',
                marginBottom: '40px',
              }}
              onClick={this.toggleFullScreenMode}
            />
          </div>
        )}
        <div title={I18n.t('device.swipeTop')}>
          <SwipeTopPhone
            style={{
              width: '14px',
              height: 'auto',
              cursor: 'pointer',
              userSelect: 'none',
              marginBottom: '40px',
            }}
            onClick={(e) => this.sendSwipeUpAction(peer)}
          />
        </div>
        <div title={I18n.t('device.volumeUp')}>
          <PlusCircleFilled
            style={{
              cssFloat: 'left',
              width: '18px',
              height: 'auto',
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={(e) => this.sendVolumeUpButtonClick(peer)}
          />
        </div>
        <div title={I18n.t('device.volumeDown')}>
          <MinusCircleFilled
            style={{
              width: '18px',
              height: 'auto',
              cursor: 'pointer',
              marginTop: '20px',
              userSelect: 'none',
            }}
            onClick={(e) => this.sendVolumeDownButtonClick(peer)}
          />
        </div>
        <div title={I18n.t('device.power')}>
          <Power
            style={{
              cssFloat: 'right',
              marginTop: '40px',
              width: '18px',
              height: 'auto',
              cursor: 'pointer',
              border: '2px solig #002340', // --incube dark navi
              borderRadius: '50%',
              userSelect: 'none',
            }}
            onClick={(e) => this.sendPowerButtonClick(peer)}
          />
        </div>
        <div title={I18n.t('device.enterKioskMode')}>
          <Kiosk
            style={{
              cssFloat: 'right',
              marginTop: '40px',
              width: '20px',
              height: 'auto',
              cursor: 'pointer',
              border: '2px solig #002340', // --incube dark navi
              userSelect: 'none',
            }}
            onClick={(e) => this.sendEnterKioskButtonClick(peer)}
          />
        </div>
        <div title={I18n.t('device.exitKioskMode')}>
          <KioskClose
            style={{
              cssFloat: 'right',
              marginTop: '18px',
              width: '24px',
              height: 'auto',
              cursor: 'pointer',
              border: '2px solig #002340', // --incube dark navi
              userSelect: 'none',
            }}
            onClick={(e) => this.sendExitKioskModeButtonClick(peer)}
          />
        </div>
      </div>
    ) : null;

  renderGlobalActions = (peer) =>
    this.state.currentImage ? (
      <div
        id={'global-actions'}
        style={{
          backgroundColor: selectForCurrentBrand({
            original: '#003562',
            incube: '#1a1e35',
            janam: '#ff7600',
          }), // --incube dark navi
          textAlign: 'center',
          width: `calc(100% - ${CONTROL_PANEL_WIDTH}px)`,
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <BackArrow
          style={{
            cssFloat: 'left',
            marginLeft: '40px',
            width: '18px',
            height: 'auto',
            cursor: 'pointer',
          }}
          onClick={(e) => this.sendGlobalAction(peer, GLOBAL_ACTION_BACK)}
        />
        <HomeBlack
          style={{
            width: '18px',
            height: 'auto',
            cursor: 'pointer',
          }}
          onClick={(e) => this.sendGlobalAction(peer, GLOBAL_ACTION_HOME)}
        />
        <Rectangle
          style={{
            cssFloat: 'right',
            marginRight: '40px',
            width: '18px',
            height: 'auto',
            cursor: 'pointer',
          }}
          onClick={(e) => this.sendGlobalAction(peer, GLOBAL_ACTION_RECENTS)}
        />
      </div>
    ) : null;

  renderSecureInput = (peer) =>
    this.state.currentImage ? (
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <div>
          <Translate value="device.secureInput" />
        </div>
        <input
          type={'text'}
          onKeyDown={(e) => this.onKeyDown(e, peer)}
          value={this.state.inputText}
          onChange={this.onChangeText}
        />
      </div>
    ) : null;

  renderConnectionStatusAndSettings = (peer) => {
    const { isFullScreenEnabled } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '350px',
          ...(isFullScreenEnabled
            ? {
                marginRight: '20px',
              }
            : {}),
        }}
      >
        {this.renderConnectionStatus()}
        <SwitchField
          onClick={(e) => {
            this.setState(
              (state) => ({
                ...state,
                canReceiveRegions: !state.canReceiveRegions,
              }),
              () =>
                this.sendCanReceiveRegions(peer, this.state.canReceiveRegions)
            );
          }}
          on={this.state.canReceiveRegions}
          title={<Translate value="device.slowNetwork" />}
        />
        <div
          style={{
            marginBottom: '15px',
            display: 'inline-flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
            {`${I18n.t('device.imageQuality')} ${this.state.imageMaxQuality}%`}
          </div>
          <Slider
            min={0}
            max={100}
            step={5}
            value={this.state.imageMaxQuality}
            onChange={(value) => {
              this.setState(
                (state) => ({ ...state, imageMaxQuality: value }),
                () => this.sendImageMaxQuality(peer, this.state.imageMaxQuality)
              );
            }}
            trackStyle={{
              backgroundColor: selectForCurrentBrand({
                original: '#003562',
                incube: '#1a1e35',
                janam: '#ff7600',
              }), // --incube primary color
              height: 14,
            }}
            handleStyle={{
              borderColor: selectForCurrentBrand({
                original: '#003562',
                incube: '#1a1e35',
                janam: '#ff7600',
              }), // --incube primary color
              height: 18,
              width: 18,
              marginLeft: 0,
              marginTop: -2,
              backgroundColor: '#fff',
            }}
            railStyle={{
              backgroundColor: '#e9e9e9',
              height: 14,
              border: '1px solid #ccc',
            }}
          />
        </div>
        <div
          style={{
            marginBottom: '15px',
            display: 'inline-flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
            {`${I18n.t('device.imageSize')} ${this.state.imageSizePercentage}%`}
          </div>
          <Slider
            min={0}
            max={100}
            step={5}
            value={this.state.imageSizePercentage}
            onChange={(value) => {
              if (value) {
                this.setState(
                  (state) => ({ ...state, imageSizePercentage: value }),

                  () =>
                    this.sendImageSizePercentage(
                      peer,
                      this.state.imageSizePercentage
                    )
                );
              }
            }}
            trackStyle={{
              backgroundColor: selectForCurrentBrand({
                original: '#003562',
                incube: '#1a1e35',
                janam: '#ff7600',
              }), // --incube primary color
              height: 14,
            }}
            handleStyle={{
              borderColor: selectForCurrentBrand({
                original: '#003562',
                incube: '#1a1e35',
                janam: '#ff7600',
              }),
              height: 18,
              width: 18,
              marginLeft: 0,
              marginTop: -2,
              backgroundColor: '#fff',
            }}
            railStyle={{
              backgroundColor: '#e9e9e9',
              height: 14,
              border: '1px solid #ccc',
            }}
          />
        </div>
        {this.renderScreenCaptureTypeField(peer)}
      </div>
    );
  };

  renderScreen = (peer) => {
    const { canvasWidth } = this.state;
    const { id } = peer;
    return (
      <React.Fragment>
        {this.renderConnectionStatusAndSettings(peer)}
        <div
          id={'div_' + id}
          style={{
            display: 'inline-grid',
            userSelect: 'none',
            width: `${canvasWidth + CONTROL_PANEL_WIDTH}px`,
          }}
          className={`remote-control-device ${
            this.state.currentImage ? 'remote-control-device--active' : ''
          }`}
        >
          {!this.state.currentImage ? <Throbber /> : null}
          <div
            style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              alignItems: 'center',
              userSelect: 'none',
            }}
          >
            <canvas
              id={'canvas_' + id}
              width={this.state.canvasWidth}
              height={this.state.canvasHeight}
              className={'remote-control-device__screen'}
              onMouseDown={(e) => this.onMouseAction(e, peer, ACTION_DOWN)}
              onMouseUp={(e) => this.onMouseAction(e, peer, ACTION_UP)}
              onMouseMove={(e) => this.onMouseAction(e, peer, ACTION_MOVE)}
              onMouseLeave={(e) => this.onMouseLeave(e, peer)}
              onMouseEnter={this.captureKeyboard(peer)}
              onMouseOut={this.unCaptureKeyboard}
            />
            {this.renderControlPanel(peer)}
          </div>
          {this.renderGlobalActions(peer)}
          {this.renderSecureInput(peer)}
          {/*{this.state.currentImage ?*/}
          {/*    <div*/}
          {/*    id={"focus_" + id}*/}
          {/*>*/}
          {/*    {"Current focus text: "}*/}
          {/*</div>*/}
          {/*    : null }*/}
        </div>
      </React.Fragment>
    );
  };

  toggleFullScreenMode = () => {
    const remoteControlRootElement = this.remoteControlRef.current;
    const isFullScreenEnabled = document.fullscreenElement;
    if (isFullScreenEnabled) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      this.setState(() => ({ isFullScreenEnabled: false }));
    } else {
      if (remoteControlRootElement.requestFullscreen) {
        remoteControlRootElement.requestFullscreen();
      } else if (remoteControlRootElement.webkitRequestFullscreen) {
        /* Safari */
        remoteControlRootElement.webkitRequestFullscreen();
      } else if (remoteControlRootElement.msRequestFullscreen) {
        /* IE11 */
        remoteControlRootElement.msRequestFullscreen();
      }
      this.setState(() => ({ isFullScreenEnabled: true }));
    }
  };

  renderConnectionStatus = () => (
    <div
      style={{
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '0.86',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#000000',
        margin: '10px 0',
      }}
    >
      {this.state.connectionStatus || ''}
    </div>
  );

  render() {
    const { isFullScreenEnabled } = this.state;
    return (
      <div style={styles.root} ref={this.remoteControlRef}>
        <div
          style={{
            padding: '38px 60px 15px',
            display: 'flex',
            flexDirection: 'column',
            ...(isFullScreenEnabled
              ? {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }
              : {}),
          }}
        >
          {this.state.peers.map(this.renderScreen)}
        </div>
      </div>
    );
  }
}

export default RemoteControl;

RemoteControl.propTypes = {
  device: PropTypes.object,
};
